import React from 'react';
import cn from 'classnames';
import {Icon} from 'ht-styleguide';
import styles from '../taskChecklist.styles.scss';

interface IDragHandle {
  mouseIn: boolean;
  dragHandleRef: React.RefObject<HTMLDivElement>;
}

const DragHandle = ({mouseIn, dragHandleRef}: IDragHandle) => {
  return (
    <div ref={dragHandleRef} className={cn(styles.dragHandleWrapper, mouseIn && styles.active)}>
      <Icon name="drag-n-drop-handle" className={styles.dragHandleIcon} />
    </div>
  );
};

export default DragHandle;
