import React, {useCallback, useMemo} from 'react';
import cn from 'classnames';
// Utils
import {noop} from 'utils/event';
import {formatPhoneBasic} from 'utils/formatter/phone';
import {sortAlphabeticallyOrNumerically} from 'components/Elements/DataTable/utils/sort.utils';
import {actionMenuColumn} from 'components/Elements/DataTable/utils/columns.utils';
// Types
import {TechProfileObject} from 'features/MultiDwellingUnits/MDU.types';
import {TDataTableColumnDef} from 'components/Elements/DataTable/dataTable.types';
// Components && Styles

import DataTable from 'components/Elements/DataTable';
import {Button, BUTTON_THEMES, Icon} from 'ht-styleguide';
import AvatarUser from 'components/Elements/AvatarUser';

import styles from './currentProjectTeamManagement.styles.scss';

type TeamManagementMemberListProps = {
  teamMembers: TechProfileObject[];
  onAddMember?: BaseAnyFunction;
  onDeleteMember?: BaseAnyFunction;
  toggleModal?: BaseAnyFunction;
};

/** Style list item based on tech's properties */
export const getTableCellStyles = (member: TechProfileObject) => {
  return cn(styles.memberTableCell, {[styles.inactive]: !member.active});
};

const TeamMemberLineItem = ({member}: {member: TechProfileObject}) => {
  const showLeadIcon = (member.lead_tech && member.active) || (member.was_lead_tech && !member.active);
  return (
    <div className="flex align-items-center">
      <AvatarUser name={member.name} image={member.profile_picture.thumb} small />
      <div className="marginLeft-tiny1">{member.name}</div>
      {showLeadIcon && <Icon name="crown-fill" className="marginLeft-tiny1" />}
    </div>
  );
};

const TeamManagementMemberList = ({teamMembers = [], onDeleteMember = noop, onAddMember = noop, toggleModal = noop}: TeamManagementMemberListProps) => {
  const numActiveTeamMembers = teamMembers.filter(m => m.active).length;

  const getListItems = useCallback(
    (teamMember: TechProfileObject) => {
      if (!teamMember.active)
        return [
          {
            text: 'Return to Team',
            key: 0,
            onClick: () => onAddMember([teamMember.id]),
          },
        ];
      return [{text: 'Delete', key: 0, onClick: () => onDeleteMember(teamMember)}];
    },
    [onAddMember, onDeleteMember]
  );

  type TData = (typeof teamMembers)[number];

  const columns = useMemo(() => {
    const columnDefs: TDataTableColumnDef<TData>[] = [
      {
        accessorKey: 'name',
        header: 'Tech Name',
        Cell: ({row}) => {
          return (
            <div className="flex align-items-center">
              <TeamMemberLineItem member={row.original} />
            </div>
          );
        },
        muiTableBodyCellProps: ({row}) => ({
          className: getTableCellStyles(row.original),
        }),
        sortingFn: sortAlphabeticallyOrNumerically,
        columnWidthMode: 'fill',
        columnWidthSize: 'md',
      },
      {
        accessorKey: 'id',
        header: 'Tech ID',
        muiTableBodyCellProps: ({row}) => ({
          className: getTableCellStyles(row.original),
        }),
        sortingFn: sortAlphabeticallyOrNumerically,
        columnWidthMode: 'fill',
        columnWidthSize: 'md',
      },
      {
        id: 'phone',
        accessorFn: originalRow => formatPhoneBasic(originalRow.phone, '-'),
        header: 'Contact',
        muiTableBodyCellProps: ({row}) => ({
          className: getTableCellStyles(row.original),
        }),
        sortingFn: sortAlphabeticallyOrNumerically,
        columnWidthMode: 'fill',
        columnWidthSize: 'md',
      },
      actionMenuColumn<TData>({getListItems}),
    ];
    return columnDefs;
  }, [getListItems]);

  return (
    <>
      <div className="flex justify-content-space-between">
        <p className="h6 n700 marginBottom-medium">{`${numActiveTeamMembers} Active Team Members`}</p>
        <Button theme={BUTTON_THEMES.SECONDARY} className={styles.ctaButton} onClick={toggleModal} small>
          <Icon name="team" />
          <span className="marginLeft-tiny1">Add Team Members</span>
        </Button>
      </div>
      <DataTable<TData> tableKey="CurrentProject-TeamManagement-MemberList" columns={columns} data={teamMembers} isZebra />
    </>
  );
};

export default TeamManagementMemberList;
