import {useEffect} from 'react';

import {$insertNodeToNearestRoot} from '@lexical/utils';
import {$createParagraphNode, $createTextNode, $insertNodes, COMMAND_PRIORITY_NORMAL, createCommand, LexicalCommand, LexicalNode} from 'lexical';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';

export const INSERT_CUSTOM_TEXT: LexicalCommand<string> = createCommand();

/**
 * This allows us to "insert" text into the editor. Our example is the "@" sign for mentions.
 *
 * @constructor
 */
const UpdateTextPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.registerCommand(
      INSERT_CUSTOM_TEXT,
      // @ts-ignore
      (payload: string) => {
        const paragraphNode = $createParagraphNode();
        const textNode = $createTextNode(payload);
        $insertNodes(textNode as unknown as LexicalNode[]);
        paragraphNode.append(textNode);
        $insertNodeToNearestRoot(paragraphNode);
      },
      COMMAND_PRIORITY_NORMAL
    );
  }, []);

  return null;
};

export default UpdateTextPlugin;
