import React, {ReactElement} from 'react';
import {Form} from 'ht-styleguide';
import cn from 'classnames';

/* Components */
import FieldsGenerator from 'features/Issues/Parts/IssuesFieldsGenerator';
import CloudinaryAttachmentsField from 'features/Cloudinary/components/CloudinaryAttachmentsField';

/* Constants */
import {ISSUE_ENTITY_TYPES, ISSUE_FIELDS_OPERATION_TYPE} from 'features/Issues/Issues.constants';

/* Queries */
import {useUserCurrentQuerySelect} from 'queries/User/query.user.current';
import {useGetIssueTemplatesQuery} from 'features/Issues/queries/query.issues.fields';

import {MaybeAttachments} from 'features/Cloudinary/cloudinary.types';
import {TField, IssuesSearchFieldNames} from 'features/Issues/issues.types';

import styles from 'features/Issues/Parts/SideSheets/SideSheet.Issue.styles.scss';

export const cloudinaryNamespaceEdit = 'update-issue';
const descriptionName = IssuesSearchFieldNames.Description;

/**
 * @description This is a wrapper function that will be used to display the consuming component, with
 *              specific UI column/row etc..
 *              This is required now because our designs have a specific layout for the fields that are not
 *              consistent. shocking.
 * @param fieldName
 */
const displayConsumingWrapper = (fieldName: string) => {
  /* Do we need to accomodate special views? */
  if (fieldName === descriptionName)
    return (label: string, C: ReactElement) => (
      <div className="flex flex-direction-column border-top marginTop-small paddingTop-small2 p1 font-weight-bold">
        <div>{label}</div>
        <span className={styles.columnTwoLineItemUpdateSidebar}>{C}</span>
      </div>
    );

  return (label: string, C: ReactElement) => (
    <div className="flex flex-direction-row">
      <div className={cn(styles.columnOneLineItemUpdateSidebar, 'caption font-weight-bold')}>{label}</div>
      <span className={styles.columnTwoLineItemUpdateSidebar}>{C}</span>
    </div>
  );
};

const IssueProperties = ({issue, formik, preseedAttachments, id}: {issue: TField[]; formik: any; preseedAttachments: MaybeAttachments; id: number | string}) => {
  const currentUser = useUserCurrentQuerySelect();
  /* Queries */
  const {data: issueTemplateTypesData} = useGetIssueTemplatesQuery({entity_type: `${ISSUE_ENTITY_TYPES.project}`});

  return (
    <Form>
      <Form.Row>
        <Form.Column lg={12} md={8} sm={4}>
          {issue &&
            issue
              .sort((a, b) => (a.position < b.position ? -1 : 1))
              .sort((a, b) => {
                if (a.name === descriptionName) return 1;
                if (b.name === descriptionName) return -1;

                return 0;
              })
              .map(field => {
                /* Another non-dynamic need we have to address for. */
                /* If templates only have 1 entry (response from api), don't show it. */
                if (field.name.toLowerCase() === 'template_id' && issueTemplateTypesData?.length === 1) {
                  return null;
                }
                return (
                  <React.Fragment key={field.name}>
                    <FieldsGenerator
                      key={field.name}
                      formik={formik}
                      field={field}
                      suppressLabel
                      consumer={displayConsumingWrapper(field.name)}
                      operationType={ISSUE_FIELDS_OPERATION_TYPE.update}
                      currentUser={currentUser}
                      /*
                        Not happy about this. More design hoops that conflict with the dynamic paradigm.
                        Possible TODO: Talk with diego about returning a "hidden" field for edit views.
                      */
                      useHidden={['summary', 'status_id'].includes(field.name)}
                      id={id}
                    />
                    {field.name === descriptionName ? (
                      <div key={`${field.name}-container`}>
                        <CloudinaryAttachmentsField
                          label="Attachments"
                          withLightbox
                          showDownloadButton
                          preseedAttachments={preseedAttachments}
                          namespace={cloudinaryNamespaceEdit}
                          folder="issues"
                          withBold
                          withCount
                        />
                      </div>
                    ) : null}
                  </React.Fragment>
                );
              })}
        </Form.Column>
      </Form.Row>
    </Form>
  );
};

export default IssueProperties;
