import React, {useEffect} from 'react';
import cn from 'classnames';
import get from 'lodash/get';
// Hooks
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useUploadFileValidation} from 'features/MultiDwellingUnits/MDU.hooks';
// Utils
import {noop} from 'utils/event';
// Queries && Mutations
import {useGetProjectAttachmentsQuery} from 'features/MultiDwellingUnits/queries/query.project.attachments';
import {useDeleteProjectAttachmentMutation} from 'features/MultiDwellingUnits/queries/mutation.project.deleteAttachment';
import {useCreateProjectAttachmentMutation} from 'features/MultiDwellingUnits/queries/mutation.project.createAttachment';
import {showErrorToast, logResponseError} from 'queries/query.utils';

// Components && Styles
import PageHeader from 'components/Elements/PageHeader';
import {EmptyState, Panel, Button, BUTTON_THEMES, Icon} from 'ht-styleguide';
import FileUploadErrors from 'features/MultiDwellingUnits/Parts/FileUploadErrors';
import FileDisplay from './CurrentProject.FilesPage.FileDisplay';
import styles from './filesPage.styles.scss';

const FilesPageEmptyState = ({onUploadButtonClick = noop}) => {
  return (
    <Panel className={cn(styles.panel, styles.withBorder, 'paddingY-large')} noShadow largeBorderRadius>
      <EmptyState title="No Files Uploaded Yet" text="If the project has any associated files, upload them here." iconName="upload" showButtonContainer>
        <Button theme={BUTTON_THEMES.SECONDARY} onClick={onUploadButtonClick}>
          Upload
        </Button>
      </EmptyState>
    </Panel>
  );
};

export const TEST_IDS = {FILE_INPUT: 'file-input'};

const FilesPage = () => {
  /* Hooks */
  const dispatch = useAppDispatch();
  const {onFileInputChange, uploadedFiles, resetUploadedFiles, errorStatusInfo, setErrorStatusInfo, resetErrorStatus} = useUploadFileValidation({skipValidation: true});

  /* Queries & Mutations */
  const {data: attachments, isFetched} = useGetProjectAttachmentsQuery();
  const {mutateAsync: createAttachment} = useCreateProjectAttachmentMutation();
  const {mutate: deleteAttachment} = useDeleteProjectAttachmentMutation();

  /* Event Handlers */
  const fileInputRef = React.useRef<HTMLInputElement>(null); // Control <input type="file" /> element
  const resetFileInputValue = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  const handleUploadButtonClick = () => {
    resetUploadedFiles();
    resetFileInputValue();
    fileInputRef.current?.click();
  };

  /* Callbacks */
  const onDeleteFile = async (id: number) => deleteAttachment(id);
  const onCloseFileUploadError = () => {
    resetErrorStatus();
  };

  /* Effects */
  useEffect(() => {
    // There is no intermediate step to "stage" uploaded files; let's upload them immediately. BE will handle all validation, as opposed to the FE validation we do for Notes Page.
    (async () => {
      if (uploadedFiles?.length) {
        /*
         * We need to handle the error here in the component instead of the query because
         * we need access to the response object in order to display errors properly
         */
        try {
          const formDataArray: FormData[] = [];
          for (let i = 0; i < uploadedFiles.length; i += 1) {
            const formData = new FormData();
            formData.append(`attachments[][file]`, uploadedFiles[i]);
            formDataArray.push(formData);
          }

          /**
           * Call createAttachment for each file we want to upload. We need to do this because the BE does not return an error for each invalid
           * file if we send multiple files in one request. It will only send an error for the first file that fails.
           */
          const resp = await Promise.all(formDataArray.map(formData => createAttachment(formData)));
          // Append the filename to the error object so that we can display it in the error state
          const uploadErrors = resp
            .map((r, i) => {
              if (!r?.err) return null;
              const fileObject = formDataArray[i].get('attachments[][file]');
              return {...r, filename: fileObject instanceof File ? fileObject.name : ''};
            })
            .filter(Boolean);
          if (uploadErrors.length) {
            // Error info may show up in different places depending on the error
            // get error status and statusText from each error
            const errorStatusInfoArray = uploadErrors.map(error => {
              const status = get(error, 'err.response.status', '') || get(error, 'err.status', '');
              const statusText = get(error, 'err.response.statusText', '') || get(error, 'err.statusText', '');
              const fileAttachmentErrors = get(error, 'err.errors.attachments', null);
              const fileName = get(error, 'filename', '');
              return {status, statusText, fileAttachmentErrors, fileName};
            });
            showErrorToast(dispatch)({errors: 'Error uploading files'});
            setErrorStatusInfo(errorStatusInfoArray);
            throw new Error(String(uploadErrors));
          }
        } catch (error) {
          logResponseError('File Upload Error')(error);
        }
      }
    })();
  }, [uploadedFiles, createAttachment, dispatch, setErrorStatusInfo]);

  return (
    <section className="marginBottom-medium2">
      <PageHeader title="Files" />
      <div className="flex justify-content-space-between align-items-end marginBottom-medium">
        <div>
          <p className="h6 text-weight-med marginBottom-small">Upload File or Image</p>
          <p className="p2 n700">Supported file types include: PDF, JPEG, PNG, CSV, and XLS. Maximum file size is 5 MB.</p>
        </div>

        {attachments?.length ? (
          <Button theme={BUTTON_THEMES.SECONDARY} className={styles.uploadButton} onClick={handleUploadButtonClick} small>
            <Icon name="upload" className="marginRight-tiny1" />
            <span>Upload</span>
          </Button>
        ) : null}
      </div>
      {isFetched ? (
        <>
          {errorStatusInfo && <FileUploadErrors errorStatusInfo={errorStatusInfo} onClose={onCloseFileUploadError} className="marginBottom-medium1" />}
          <div>{attachments?.length ? <FileDisplay attachments={attachments} onDeleteFile={onDeleteFile} /> : <FilesPageEmptyState onUploadButtonClick={handleUploadButtonClick} />}</div>
        </>
      ) : null}
      <input type="file" ref={fileInputRef} style={{display: 'none'}} data-testid={TEST_IDS.FILE_INPUT} multiple onChange={onFileInputChange} />
    </section>
  );
};

export default FilesPage;
