import React, {SetStateAction, Dispatch} from 'react';
import {ELEMENT_SIZE, SideSheet, CalloutBox, CalloutBoxThemes, Icon} from 'ht-styleguide';
import cn from 'classnames';
import ChipStatus from 'features/MultiDwellingUnits/Parts/Chip';
import {useSelector} from 'hooks/useAppSelector';
import {orderDetailsPath} from 'global/paths';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {useGetSingleUnit} from 'features/MultiDwellingUnits/queries/query.jobs.singleUnit';

import {BillingStatus, JobService, MDUUnitStatuses, ProjectGroup, TUnit} from 'features/MultiDwellingUnits/MDU.types';
import {shortDayMonthWithYearTime} from 'global/constants/common';
import {CallOutBoxStatus} from 'features/MultiDwellingUnits/MDU.constants';
import {pluralize} from 'utils/text';

import styles from './jobs.styles.scss';

type TUnitSideSheet = {
  selectedGroup: ProjectGroup | TUnit | null;
  setSelectedGroup: Dispatch<SetStateAction<ProjectGroup | TUnit | null>>;
};

type TCalloutBoxStatuses = `${MDUUnitStatuses}`;
type EnumValuedKeys = {[K in MDUUnitStatuses | BillingStatus]: {header: string; text: string; theme?: string}};

const UnitSideSheet = ({selectedGroup, setSelectedGroup}: TUnitSideSheet) => {
  const {data: unitData} = useGetSingleUnit({unitId: selectedGroup?.id!});

  /* Constants */
  const {id, unitName, billingStatus, approvedAt, unserviceableAt, cancelledAt, paidAt, cancellationReason, unserviceableReason, projectGroup, services, status, approvedBy} = unitData || {};

  /* Selectors */
  // const tech = useSelector(mduProjectsSlice.selectors.getLeadTechInfo);
  const formattedCompletedDate = useSelector(mduProjectsSlice.selectors.getFormattedDateByUserTimezone({format: shortDayMonthWithYearTime, date: approvedAt}));
  const formattedCancelledDate = useSelector(mduProjectsSlice.selectors.getFormattedDateByUserTimezone({format: shortDayMonthWithYearTime, date: cancelledAt}));
  const formattedUnserviceableDate = useSelector(mduProjectsSlice.selectors.getFormattedDateByUserTimezone({format: shortDayMonthWithYearTime, date: unserviceableAt}));
  const formattedPaidAtDate = useSelector(mduProjectsSlice.selectors.getFormattedDateByUserTimezone({format: shortDayMonthWithYearTime, date: paidAt}));

  /**
   * Call out box display items. The "text" serves as a show/hide
   * Since we can have more than one callout box, express that with an iterable.
   * @type {
   *   '[BillingStatus.paid]': {header: string, theme: string, text: string},
   *   '[StatusesJob.cancelled]': {header: string, text: string},
   *   '[StatusesJob.unserviceable]': {header: string, text: string},
   *   '[StatusesJob.completed]': {header: string, text: string}}}
   */
  const callOutText: Partial<EnumValuedKeys> = {
    [MDUUnitStatuses.COMPLETED]: {header: `Approved by ${approvedBy?.name || 'n/a'}`, text: formattedCompletedDate},
    [MDUUnitStatuses.CANCELLED]: {header: `Cannot Complete`, text: [formattedCancelledDate, cancellationReason?.text].join('<br /><br />')},
    [MDUUnitStatuses.UNSERVICEABLE]: {header: 'Unserviceable', text: [formattedUnserviceableDate, unserviceableReason?.text].join('<br /><br />')},
    [BillingStatus.PAID]: {header: `Paid`, text: formattedPaidAtDate, theme: CalloutBoxThemes.SUCCESS},
  };

  /* IF NO SELECT GROUP - LEAVE */
  if (!selectedGroup) return null;

  /* ------------------------- Methods -------------------------- */

  /* Display Status line item Icon */
  const iconDisplay = (unitStatus: MDUUnitStatuses) => {
    switch (unitStatus) {
      case MDUUnitStatuses.COMPLETED:
        return <Icon className={styles.successIcon} name="round-check-fill" />;
      case MDUUnitStatuses.UNSERVICEABLE:
        return <Icon className={styles.inErrorIcon} name="flag-filled" />;
      default:
        return '';
    }
  };

  /* Services status Display */
  const ServicesStatusDisplay = () => {
    const sharedHeaderStyles = 'uppercase paddingRight-tiny n700 font-weight-medium overline-medium';
    const columnRightClassHeader = cn(styles.servicesStatusContainer_right, sharedHeaderStyles);
    const columnLeftClassHeader = cn(sharedHeaderStyles, 'paddingLeft-small');

    const columnRightClassContext = cn(styles.servicesStatusContainer_right, 'p2 n700 font-weight-regular');
    const columnContent = 'p2 font-weight-regular n900 paddingLeft-small ellipses';

    return (
      <div className="flex flex-direction-column">
        <div className="flex justify-content-space-between">
          <p className={columnLeftClassHeader}>Service</p>
          <p className={columnRightClassHeader}>Status</p>
        </div>
        {services.map((service: JobService) => {
          return (
            <div key={service.id} className="flex justify-content-space-between border-bottom-2 paddingY-small1">
              <p className={columnContent}>{service.sku.name}</p>
              <p className={columnRightClassContext}>{iconDisplay(service.status)}</p>
            </div>
          );
        })}
      </div>
    );
  };

  /* Group Heading */
  const GroupHeading = ({title}: {title: string}) => {
    return <p className="p1 n700 font-weight-medium paddingBottom-medium">{title}</p>;
  };

  /* Displays the Unit Services Box */
  const UnitServices = () => {
    return (
      <>
        <div className={styles.unitServicesContainer}>
          <div className="overline-medium n700 uppercase paddingBottom-small">{projectGroup?.name}</div>
          {projectGroup?.projectServices?.map((service: JobService) => {
            return (
              <p key={service.id} className="caption n800 paddingBottom-tiny">
                {service.quantity}x {service.sku.name}
              </p>
            );
          })}
        </div>
        <p className="caption paddingTop-tiny n700">* Job template services can be modified.</p>
      </>
    );
  };
  /* Displays the Callout Boxes */
  const DisplayCallOuts = () => {
    const callOutTextByVariableStatus = [billingStatus, status];
    const display = callOutTextByVariableStatus
      .map((mapStatus: TCalloutBoxStatuses) => {
        const callOutTxt = callOutText[mapStatus];
        return !status || !callOutTxt?.header ? null : (
          <CalloutBox key={mapStatus} className={styles.calloutBox} header={callOutTxt?.header} text={callOutTxt?.text ?? ''} theme={callOutTxt?.theme || CallOutBoxStatus[mapStatus]} />
        );
      })
      .filter(Boolean);

    if (display.length === 0) return null;

    return (
      <div>
        {display}
        <BorderBottom />
      </div>
    );
  };

  const BorderBottom = () => {
    return <div className="border-bottom-2 marginY-medium" />;
  };

  const SubHeaderTextDisplay = () => {
    const orderLink = orderDetailsPath(unitData.id);
    return (
      <div className="flex">
        <a href={orderLink} target="_blank" rel="noreferrer" className={styles.techAppLink}>
          Job ID {id}
        </a>
        &nbsp;&nbsp;
        <ChipStatus size={ELEMENT_SIZE.SMALL} statusType="job" status={status} />
      </div>
    );
  };

  return (
    <SideSheet hide={() => setSelectedGroup(null)} showFooter={false} headerText={unitName} subheaderText={(<SubHeaderTextDisplay />) as unknown as string} isOpen={Boolean(id)}>
      <DisplayCallOuts />
      <GroupHeading title="Job Template" />
      <UnitServices />
      <BorderBottom />
      <GroupHeading title={`${services?.length} ${pluralize('Service', services?.length)}`} />
      <ServicesStatusDisplay />
    </SideSheet>
  );
};

export default UnitSideSheet;
