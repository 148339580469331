import React, {useState, useRef, useCallback} from 'react';
// Hooks
import useOutsideClick from 'hooks/useOutsideClick';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useSelector} from 'hooks/useAppSelector';
// Ducks && Types
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {TBulkActionTypes} from 'features/MultiDwellingUnits/MDU.types';
// Components
import {MenuListItem, MenuListItemCalloutGroup, MenuPanel, MenuUnorderedList} from 'components/Elements/Menu';
import ToolbarLink from 'components/Table/TableToolbar/TableToolbar.Link';
import {SideSheetBulkEditAddServices} from 'features/MultiDwellingUnits/Parts/SideSheets/BulkEdit';
import styles from './MDUProjectsFilters.styles.scss';

const EditJobsLink = () => {
  /* Local State */
  const [menu, setMenu] = useState(false);
  const [isAddServiceSidesheetOpen, setIsAddServiceSidesheetOpen] = useState(false);
  const wrapperRef = useRef(null);

  /* Hooks */
  const dispatch = useAppDispatch();

  /* Selectors */
  const totalSelected = useSelector(mduProjectsSlice.selectors.getPaginationTotalSelected);

  /* Methods */
  const handleClickOutside = () => {
    setMenu(false);
  };
  const onHandleSetMenu = useCallback(() => {
    setMenu(prevMenu => !prevMenu);
  }, []);

  const onHandleDispatch = (key: TBulkActionTypes) => {
    dispatch(mduProjectsSlice.actions.setActionItemBulkModalSlide({key}));
    onHandleSetMenu();
  };

  const openSidesheetAddBulkServices = () => {
    onHandleDispatch(TBulkActionTypes.ADD_BULK_SERVICES);
    setIsAddServiceSidesheetOpen(false);
  };

  useOutsideClick(wrapperRef, handleClickOutside);

  if (totalSelected <= 0) return null;

  return (
    <>
      <span ref={wrapperRef} className="position relative">
        <ToolbarLink onClick={onHandleSetMenu} iconName="edit-plain" label={`Edit Jobs (${totalSelected})`} />
        {menu ? (
          <MenuPanel className={styles.menuPosition}>
            <MenuUnorderedList>
              <MenuListItem dataTestId="menu-approve-jobs" onClick={() => onHandleDispatch(TBulkActionTypes.APPROVE_BULK_JOBS)}>
                Approve Jobs
              </MenuListItem>
              <MenuListItem dataTestId="menu-reopen-jobs" onClick={() => onHandleDispatch(TBulkActionTypes.REOPEN_BULK_JOBS)}>
                Reopen Jobs
              </MenuListItem>
              <MenuListItem dataTestId="menu-cannot-complete-jobs" onClick={() => onHandleDispatch(TBulkActionTypes.CANNOT_COMPLETE_BULK_JOBS)}>
                Cannot Complete
              </MenuListItem>
              <MenuListItem dataTestId="menu-cannot-complete-jobs" onClick={() => onHandleDispatch(TBulkActionTypes.SWAP_BULK_TEMPLATES)}>
                Swap Template
              </MenuListItem>
              <MenuListItem dataTestId="menu-delete-jobs" onClick={() => onHandleDispatch(TBulkActionTypes.DELETE_BULK_JOBS)}>
                Delete Jobs
              </MenuListItem>
              <MenuListItemCalloutGroup dataTestId="menu-delete-jobs" onClick={() => setIsAddServiceSidesheetOpen(true)}>
                Add Service
              </MenuListItemCalloutGroup>
            </MenuUnorderedList>
          </MenuPanel>
        ) : null}
      </span>
      <SideSheetBulkEditAddServices
        isOpen={isAddServiceSidesheetOpen}
        hide={() => setIsAddServiceSidesheetOpen(false)}
        onOpen={onHandleSetMenu}
        onSuccess={openSidesheetAddBulkServices}
        totalSelected={totalSelected}
      />
    </>
  );
};

export default EditJobsLink;
