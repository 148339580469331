import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useMutation} from 'react-query';

/* Hooks */
import useApi from 'hooks/useApi';

/* Utils */
import {logger} from 'utils/logger';
import {handleResponseError, showErrorToast} from 'queries/query.utils';

import {MduUseParamsTypes} from '../MDU.types';
import {usePerformProjectActionAsyncMutation} from './mutation.projects.bulkActions';

/**
 * This bulk adds units to a job
 * - Usages
 *   - Bulk Add Units to Job (/jobs/bulk-create)
 */

type TParamKeys = {
  id: string | number;
  units_number: string | number;
};

export const useAddUnitsToJobMutation = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const {projectId} = useParams<MduUseParamsTypes>();
  const {mutate: mutatePerform} = usePerformProjectActionAsyncMutation();

  return useMutation(
    async (units: TParamKeys[]) => {
      const response = await api.mdu.addUnitsToJob(
        {project_id: projectId},
        {
          project_groups: units,
          async: true,
          forceCase: 'camel',
        }
      );
      handleResponseError(response);

      return response?.data?.projectAction;
    },
    {
      onSuccess: ({id}) => {
        mutatePerform({projectActionId: id});
      },
      onError: err => {
        logger('Error Approve Projects Action Mutation: ')(err as Error);
        showErrorToast(dispatch)({errors: `Error Add Units Mutation: ${err}`});
      },
    }
  );
};
