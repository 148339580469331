import React from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, NavigateFunction} from 'react-router-dom';
import {TBadgeVariants} from 'components/Elements/NotificationBadge';
import {ISidebar, ISidebarEntry} from 'components/Layouts/AdminHub/Sidebar/Sidebar.types';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {ProjectDetails, CurrentProjectStages, NavTypes, NavPageTypes} from 'features/MultiDwellingUnits/MDU.types';
import {isPreLaunchStatus} from 'features/MultiDwellingUnits/MDU.utils';
import {mduProjectPagePath, mduProjectsPagePath} from 'global/paths';
import SidebarActionsMenu from './Parts/SidebarActionsMenu/CurrentProject.SidebarActionsMenu';

type GetEntriesArgs = {navigate: NavigateFunction; project: ProjectDetails; stage: CurrentProjectStages};

const getPreLaunchEntries = ({navigate, project, stage}: GetEntriesArgs): {entries: ISidebarEntry[]} => {
  const getChecklistStage = () => {
    const {total, totalCompleted} = project.stats || {};
    if (total === undefined || totalCompleted === undefined) return '';
    return `(${totalCompleted}/${total})`;
  };
  return {
    entries: [
      {
        label: `Launch Checklist ${getChecklistStage()}`,
        key: 'launch-checklist',
        icon: 'checkmark-square',
        active: stage === CurrentProjectStages.CHECKLIST,
        onClick: () => navigate(mduProjectPagePath(String(project.id), CurrentProjectStages.CHECKLIST)),
      },
      {
        label: 'Project Details',
        key: 'project-details',
        icon: 'list',
        active: [CurrentProjectStages.DETAILS, CurrentProjectStages.EDIT].includes(stage),
        onClick: () => navigate(mduProjectPagePath(String(project.id), CurrentProjectStages.DETAILS)),
      },
      {
        label: 'Team Management',
        key: 'team-management',
        icon: 'team',
        active: stage === CurrentProjectStages.TEAM_MANAGEMENT,
        onClick: () => navigate(mduProjectPagePath(String(project.id), CurrentProjectStages.TEAM_MANAGEMENT)),
      },
      {
        label: 'Templates & Jobs',
        key: 'templates',
        icon: 'layers',
        active: stage === CurrentProjectStages.TEMPLATES,
        onClick: () => navigate(mduProjectPagePath(String(project.id), CurrentProjectStages.TEMPLATES)),
      },
      {
        label: 'Payment Settings',
        key: 'payment',
        icon: 'dollar-outline',
        active: stage === CurrentProjectStages.PAYMENT,
        onClick: () => navigate(mduProjectPagePath(String(project.id), CurrentProjectStages.PAYMENT)),
      },
      {
        label: 'Issues',
        key: 'issues',
        icon: 'clipboard-plain',
        active: stage === CurrentProjectStages.ISSUES,
        onClick: () => navigate(mduProjectPagePath(String(project.id), CurrentProjectStages.ISSUES)),
        badge: {
          value: project.openIssuesCount,
          variant: TBadgeVariants.SUCCESS,
        },
      },
      {
        label: 'Notes',
        key: 'notes',
        icon: 'edit-plain',
        active: stage === CurrentProjectStages.NOTES,
        onClick: () => navigate(mduProjectPagePath(String(project.id), CurrentProjectStages.NOTES)),
      },
      {
        label: 'Files',
        key: 'files',
        icon: 'file',
        active: stage === CurrentProjectStages.FILES,
        onClick: () => navigate(mduProjectPagePath(String(project.id), CurrentProjectStages.FILES)),
      },
    ],
  };
};

const getPostLaunchEntries = ({navigate, project, stage}: GetEntriesArgs): {entries: ISidebarEntry[]} => {
  return {
    entries: [
      {
        label: 'Project Overview',
        key: 'project-overview',
        icon: 'dashboard',
        active: stage === CurrentProjectStages.PROJECT_OVERVIEW,
        onClick: () => navigate(mduProjectPagePath(String(project.id), CurrentProjectStages.PROJECT_OVERVIEW)),
      },
      {
        label: 'Project Details',
        key: 'project-details',
        icon: 'list',
        active: [CurrentProjectStages.DETAILS, CurrentProjectStages.EDIT].includes(stage),
        onClick: () => navigate(mduProjectPagePath(String(project.id), CurrentProjectStages.DETAILS)),
      },
      {
        label: 'Jobs',
        key: 'jobs',
        icon: 'briefcase',
        active: stage === CurrentProjectStages.JOBS,
        onClick: () => navigate(mduProjectPagePath(String(project.id), CurrentProjectStages.JOBS)),
      },
      {
        label: 'Team Management',
        key: 'team-management',
        icon: 'team',
        active: stage === CurrentProjectStages.TEAM_MANAGEMENT,
        onClick: () => navigate(mduProjectPagePath(String(project.id), CurrentProjectStages.TEAM_MANAGEMENT)),
      },
      {
        label: 'Templates',
        key: 'templates',
        icon: 'box',
        active: stage === CurrentProjectStages.TEMPLATES,
        onClick: () => navigate(mduProjectPagePath(String(project.id), CurrentProjectStages.TEMPLATES)),
      },
      {
        label: 'Payment Settings',
        key: 'payment',
        icon: 'dollar-outline',
        active: stage === CurrentProjectStages.PAYMENT,
        onClick: () => navigate(mduProjectPagePath(String(project.id), CurrentProjectStages.PAYMENT)),
      },
      {
        label: 'Issues',
        key: 'issues',
        icon: 'clipboard-plain',
        active: stage === CurrentProjectStages.ISSUES,
        onClick: () => navigate(mduProjectPagePath(String(project.id), CurrentProjectStages.ISSUES)),
        badge: {
          value: project.openIssuesCount,
          variant: TBadgeVariants.SUCCESS,
        },
      },
      {
        label: 'Notes',
        key: 'notes',
        icon: 'edit-plain',
        active: stage === CurrentProjectStages.NOTES,
        onClick: () => navigate(mduProjectPagePath(String(project.id), CurrentProjectStages.NOTES)),
      },
      {
        label: 'Files',
        key: 'files',
        icon: 'file',
        active: stage === CurrentProjectStages.FILES,
        onClick: () => navigate(mduProjectPagePath(String(project.id), CurrentProjectStages.FILES)),
      },
    ],
  };
};

/** Sidebar Navigation */
export const NavigationItems = ({project, stage}: {project: ProjectDetails; stage: CurrentProjectStages}) => {
  const navigate = useNavigate();
  const parentLocation = useSelector(mduProjectsSlice.selectors.getParentLocation);

  const getEntries = () => {
    if (!project.status) return {entries: []};
    return isPreLaunchStatus(project.status) ? getPreLaunchEntries({navigate, project, stage}) : getPostLaunchEntries({navigate, project, stage});
  };

  /** The address comes from BE as STREET, CITY, STATE, ZIP */
  const getProjectCityAndState = () => {
    const [, city, state] = (project?.address || '').split(',');
    return city && state ? `${city.trim()}, ${state.trim()}` : '';
  };

  const sidebarProps: ISidebar = {
    headerProps: {
      title: project.name || '',
      subtitle: getProjectCityAndState(),
      chipText: project?.status,
      onBackClick: () => {
        if (parentLocation) {
          navigate(parentLocation);
        } else {
          navigate(mduProjectsPagePath(NavTypes.ALL, NavPageTypes.ALL_STATUSES));
        }
      },
      SidebarActionsComponent: <SidebarActionsMenu />,
    },
    menu: [getEntries()],
  };

  return sidebarProps;
};
