import getValuesByKey from 'utils/object/getValuesByKey';

type Status = number | string;
type Statuses = Array<Status>;

/**
 * Given a status, lets determine if the request status matches.
 * @param requestedStatus
 */
const sortFilterResults = (requestedStatus: Status) => (status: Statuses) => status.some(s => s === requestedStatus);

export default <T extends object>(response: T, status: Status): boolean => {
  if (!status) return false;
  const unauthorized = (getValuesByKey(response, ['status'], sortFilterResults(status)) as unknown) as boolean;

  return unauthorized;
};
