import React, {ReactNode, createContext, useCallback, useContext, useState} from 'react';

interface AdminHubLayoutState {
  isLeftColumnCollapsed: boolean;
  toggleLeftColumnCollapsed: () => void;
}

const AdminHubLayoutStateContext = createContext<AdminHubLayoutState | undefined>(undefined);

export const AdminHubLayoutStateProvider = ({children}: {children: ReactNode}) => {
  // On initialization, try to get the value from localStorage.
  const initialIsLeftColumnCollapsed = localStorage.getItem('leftColumnCollapseStatus') === 'true' || false;

  const [isLeftColumnCollapsed, setLeftColumnCollapsed] = useState(initialIsLeftColumnCollapsed);

  const toggleLeftColumnCollapsed = useCallback(() => {
    setLeftColumnCollapsed(prev => {
      const newValue = !prev;
      // Whenever the collapse status changes, save it to localStorage
      localStorage.setItem('leftColumnCollapseStatus', newValue ? 'true' : 'false');
      return newValue;
    });
  }, []);

  return <AdminHubLayoutStateContext.Provider value={{isLeftColumnCollapsed, toggleLeftColumnCollapsed}}>{children}</AdminHubLayoutStateContext.Provider>;
};

export const useAdminHubLayoutState = () => {
  const context = useContext(AdminHubLayoutStateContext);
  if (!context) {
    throw new Error('useAdminHubLayoutState must be used within a AdminHubLayoutStateProvider');
  }
  return context;
};
