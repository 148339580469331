import React from 'react';
import {MRT_ColumnSizingState, MRT_SortingState} from 'material-react-table';
import TableActionMenu from 'components/Elements/DataTable/TableActionMenu';
import {TTableActionMenuLineItem, TDataTableColumnDef, TDataTable} from '../dataTable.types';
import {ACTION_MENU_COL_ID, CUSTOM_ROW_SELECT_COL_ID} from '../dataTable.constants';
import styles from '../dataTable.styles.scss';

/**
 * Helper function to create a column definition for a table action menu
 */
export const actionMenuColumn = <TData extends Record<string, any> = {}>({getListItems = () => []}: {getListItems?: (data: TData) => TTableActionMenuLineItem[]} = {}) => {
  const columnDef: TDataTableColumnDef<TData> = {
    id: ACTION_MENU_COL_ID,
    header: 'Action Menu',
    Header: <></>,
    accessorFn: () => '',
    enableSorting: false,
    enableResizing: false,
    Cell: ({row, table}) => <TableActionMenu<TData> items={getListItems(row.original)} table={table} />,
    iconColumn: true,
  };
  return columnDef;
};

/**
 * Helper function to create a column definition for custom row selection.
 * `Header` and `Cell` should be defined per usage
 */
export const customRowSelectColumn = <TData extends Record<string, any> = {}>() => {
  const columnDef: TDataTableColumnDef<TData> = {
    id: CUSTOM_ROW_SELECT_COL_ID,
    header: 'Row Select',
    Header: <></>,
    accessorFn: () => '',
    enableSorting: false,
    enableResizing: false,
    muiTableBodyCellProps: () => {
      return {
        className: styles.customCheckboxCell,
      };
    },
    muiTableHeadCellProps: () => {
      return {
        className: styles.customCheckboxCell,
      };
    },
  };
  return columnDef;
};

/**
 ********************************************************
 * Column Width Sizing
 *
 * Reusable helper function to run the callback function when
 * a column's width changes.
 ********************************************************
 */

type TOnColumnSizingChangeUtilParams = {
  currentColumnsWidthState: MRT_ColumnSizingState;
  onChangeCB: (newColumWidths: MRT_ColumnSizingState) => void;
};

export const onColumnSizingChangeUtil = ({currentColumnsWidthState, onChangeCB}: TOnColumnSizingChangeUtilParams) => {
  const tableOnColumnSizingChangeCB: TDataTable['onColumnSizingChange'] = updater => {
    if (typeof updater === 'function') {
      const newColumWidths = updater(currentColumnsWidthState);
      onChangeCB(newColumWidths);
    }
  };
  return tableOnColumnSizingChangeCB;
};

/**
 ********************************************************
 * Column Ordering via Drag and Drop
 *
 * Reusable helper function to run the callback function when
 * a column's order changes.
 *
 * Note: Pass all the possible column keys to the table's columnOrder state
 ********************************************************
 */

type TOnColumnOrderChangeUtilParams = {
  onChangeCB: (newColumnOrderArr: string[]) => void;
};

export const onColumnOrderChangeUtil = ({onChangeCB}: TOnColumnOrderChangeUtilParams) => {
  const onColumnChangeCB: TDataTable['onColumnOrderChange'] = newColumnOrderArr => {
    onChangeCB(newColumnOrderArr as string[]);
  };
  return onColumnChangeCB;
};

/**
 ********************************************************
 * Column Sorting (Simple)
 *
 * Note: For more complex sorting, see `IssuesTable`'s implementation. Perhaps
 *       that may need to be util'ed out as well.
 ********************************************************
 */

type TOnSortingChangeUtilParams = {
  currentColumnSortState: MRT_SortingState;
  onChangeCB: (newSort: MRT_SortingState) => void;
};

export const onColumnSortingChangeUtil = ({currentColumnSortState, onChangeCB}: TOnSortingChangeUtilParams) => {
  const onSortingChangeCB: TDataTable['onSortingChange'] = updater => {
    if (typeof updater === 'function') {
      const newSort = updater(currentColumnSortState);
      onChangeCB(newSort);
    }
  };
  return onSortingChangeCB;
};
