import React, {ComponentProps} from 'react';
import {InputField} from 'ht-styleguide';
import {TSharedFormFieldProps} from '../../dataFormFields.types';

type TBaseFilterInputField = TSharedFormFieldProps & Partial<ComponentProps<typeof InputField>>;

/**
 * Intended to be used with table filter modals.
 */
const BaseFilterInputField = ({dataTestId = 'DataBase-InputField', fieldName, formik, onChangeCB, label, ...rest}: TBaseFilterInputField) => {
  const onChange: ComponentProps<typeof InputField>['onChange'] = e => {
    formik.handleChange(e);
    onChangeCB?.({name: fieldName, label: label || fieldName, values: [{label: e.target.value, value: e.target.value}]});
  };

  const value = formik.values[fieldName];
  const error = formik.errors[fieldName]?.toString() || undefined;

  return <InputField dataTestId={dataTestId} label={label} name={fieldName} onChange={onChange} error={error} value={value} {...rest} />;
};

export default BaseFilterInputField;
