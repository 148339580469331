import React, {useState, useCallback} from 'react';
import cn from 'classnames';
import {Icon, Panel} from 'ht-styleguide';
import {checkCanModifyTask, generateTaskPanelId} from 'features/Orders/Pages/OrderTaskChecklist/orderTaskChecklist.utils';
import {IServiceTaskItem} from '../serviceTasksList.types';
import ServiceTask from './ServiceTask';
import styles from './serviceTaskItem.styles.scss';

const ServiceTaskItem = ({editTaskAnswerSideSheetRef, order, serviceId, task, taskCompletionStatusModalRef}: IServiceTaskItem) => {
  const {id: taskId, title, instructions, answer} = task;
  const isCompletedTask = answer?.complete || false;

  // Shared states
  const canModifyTask = checkCanModifyTask(order);
  const panelTaskId = generateTaskPanelId(taskId);

  // For toggling the content
  const [shouldShowContent, setShouldShowContent] = useState(false);
  const toggleContent = () => setShouldShowContent(!shouldShowContent);
  const toggleContentIcon = shouldShowContent ? 'chevron-up' : 'chevron';

  // For opening edit task sidesheet
  const openEditTaskSideSheet = useCallback(() => editTaskAnswerSideSheetRef.current?.openEditTaskSideSheet(taskId), [editTaskAnswerSideSheetRef, taskId]);

  // Task completion status related
  const toggleCompletionStatusModal = useCallback(() => {
    if (canModifyTask) {
      const type = isCompletedTask ? 'singleTaskIncomplete' : 'singleTaskComplete';
      taskCompletionStatusModalRef.current?.toggleTaskCompletionStatusModal({type, serviceId, taskId});
    }
  }, [canModifyTask, isCompletedTask, taskCompletionStatusModalRef, serviceId, taskId]);

  const checkmarkButtonStyles = cn('plainButton', !canModifyTask && 'pointerEventsNone');
  const checkmarkIconStyles = cn(styles.icon, styles.iconCheckmark, {
    [styles.completed]: isCompletedTask,
  });

  return (
    <Panel lowShadow largeBorderRadius className="marginBottom-small padding-small1 fullWidth">
      <div id={panelTaskId} className="flex justify-content-space-between">
        <div className="marginRight-small">
          <h6>{title}</h6>
          {instructions && <p className="caption n700 marginTop-tiny">{instructions}</p>}
        </div>
        <div className="marginLeft-small flex align-items-flex-start">
          <button type="button" onClick={toggleCompletionStatusModal} disabled={!canModifyTask} className={checkmarkButtonStyles}>
            <Icon name="round-check-fill" aria-label="round-check-fill" className={checkmarkIconStyles} />
          </button>
          {canModifyTask && (
            <button type="button" onClick={openEditTaskSideSheet} className="plainButton">
              <Icon name="edit-plain" aria-label="pencil" className={cn(styles.icon, 'marginLeft-small n900')} />
            </button>
          )}
          <button type="button" onClick={toggleContent} className="plainButton">
            <Icon name={toggleContentIcon} aria-label="chevron" className={cn(styles.icon, 'marginLeft-small')} />
          </button>
        </div>
      </div>
      {shouldShowContent && <ServiceTask order={order} serviceId={serviceId} task={task} />}
    </Panel>
  );
};

export default ServiceTaskItem;
