import React from 'react';

// Components
import {Panel} from 'ht-styleguide';
import DeviceLine from 'features/Profile/components/DeviceLine';
import EmptyState from 'features/Profile/components/EmptyState';

// Constants
import {DEVICES_TABLE_HEADERS, SECTION_HEADERS, EMPTY_STATES} from 'features/Profile/profile.constants';

// Types
import {Device} from 'features/Profile/profile.types';

// Styles
import styles from 'features/Profile/profile.styles.scss';

interface DeviceSectionProps {
  devices: Device[];
}

const DevicesSection = ({devices}: DeviceSectionProps) => {
  if (!devices.length) return <EmptyState header={SECTION_HEADERS.EMPTY_DEVICES} text={EMPTY_STATES.EMPTY_DEVICES} />;

  return (
    <Panel className="padding-medium">
      <div className="paddingBottom-small2">
        <h4>Devices</h4>
      </div>

      <table className={styles.devicesTable}>
        <thead>
          <tr className={styles.tableHeader}>
            {DEVICES_TABLE_HEADERS.map(header => (
              <th key={header} className={styles.deviceHeaders}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {devices.map(device => (
            <DeviceLine key={device.id} device={device} />
          ))}
        </tbody>
      </table>
    </Panel>
  );
};

export default DevicesSection;
