import {createAsyncThunk, createSlice, createSelector, AnyAction, Dictionary} from '@reduxjs/toolkit';
import APIS from 'global/apis';
import {RootState} from 'store';
import {ResponseError} from 'types/base.types';
import * as requestLoaderSlice from 'features/App/RequestLoader/requestLoader.ducks';
import * as cartSlice from 'features/Booking/Parts/Cart/cart.ducks';
import * as TS from './client.types';

/*
*******************************************************
  ASYNC ACTION THUNKS
*******************************************************
*/
export const asyncActions = {
  getClientProfile: createAsyncThunk<TS.ClientResponse, {client_id: number}, {rejectValue: ResponseError; state: RootState}>(
    'client/getClientProfile',
    async ({client_id}, {rejectWithValue, dispatch}) => {
      dispatch(requestLoaderSlice.actions.loading(true));
      const response = (await APIS.booking.client.getClient({client_id})) ?? {};
      dispatch(requestLoaderSlice.actions.loading(false));

      return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.ClientResponse);
    },
    {
      condition: ({client_id}) => {
        if (!client_id) {
          // We don't have a client id, don't bother.
          return false;
        }
        return true;
      },
    }
  ),
  updateClientProfile: createAsyncThunk<TS.ClientResponse, {userParams: TS.Client}, {rejectValue: ResponseError; state: RootState}>(
    'client/updateClientProfile',
    async ({userParams}, {rejectWithValue, getState}) => {
      const response = (await APIS.booking.client.updateClient(cartSlice.applyCartClientIds({params: {client: userParams}, state: getState}))) ?? {};
      return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.ClientResponse);
    }
  ),
  createClientProfile: createAsyncThunk<TS.ClientResponse, {userParams: TS.NewClientParams | Dictionary<string>}, {rejectValue: ResponseError; state: RootState}>(
    'client/createClientProfile',
    async ({userParams}, {rejectWithValue}) => {
      const response = (await APIS.booking.client.createClient({user: userParams})) ?? {};
      return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.ClientResponse);
    }
  ),
};

/*
*******************************************************
  INITIAL STATE
*******************************************************
*/
const initialState: TS.Client = {} as TS.Client;

/*
*******************************************************
  CREATE SLICE
*******************************************************
*/
const ClientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    updateClient: (state, action) => {
      return {...state, ...action.payload.user};
    },
    removeClient: () => {
      return {} as TS.Client;
    },
    updateClientPhone: (state, action) => {
      const {phone} = action.payload;

      return {...state, phone};
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      (action): action is AnyAction =>
        [asyncActions.getClientProfile.fulfilled, asyncActions.updateClientProfile.fulfilled, asyncActions.createClientProfile.fulfilled].some(actionCreator => actionCreator.match(action)),
      (state, action: AnyAction) => {
        return {...state, ...action.payload.data.user};
      }
    );
    // need to add rejected too
  },
});

/*
*******************************************************
  EXPORTS
*******************************************************
*/
const getClientState = (state: RootState) => state.booking.client;
const getClientStateByKey = (key: keyof TS.Client) => (client: TS.Client) => {
  return client[key];
};

const getClientStateSelector = createSelector(getClientState, client => client);
const getKeyInUserStateSelector = (key: keyof TS.Client) => createSelector(getClientStateSelector, getClientStateByKey(key));

export const selectors = {
  getClientState: getClientStateSelector,
  getKeyInUserState: getKeyInUserStateSelector,
};

export const actions = {...ClientSlice.actions, ...asyncActions};
export default ClientSlice.reducer;
