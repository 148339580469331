import {buildProjectsPage, convertSnakeToCamelAndCapitalize} from 'features/MultiDwellingUnits/MDU.utils';
import {useNavigate, NavigateFunction, useParams} from 'react-router-dom';
import {ACTION_MENU_COL_ID} from 'components/Elements/DataTable/dataTable.constants';
import {ISidebarEntry} from 'components/Layouts/AdminHub/Sidebar/Sidebar.types';
import {TFilterKey, MDUStatuses, MduUseParamsTypes, NavPageTypes, NavTypes, PausedStatusTypes} from '../../MDU.types';
import {SelectOption} from '../../Parts/FormFields/FormFields.types';
import {IMDUProjectsFilters} from './Parts/MDUProjectsFilters/MDUProjectsFilters.types';
import {TColumnKey} from './Parts/MDUProjectsTable/MDUProjectsTable.types';
import {TStatKey} from './Parts/MDUProjectsStats/MDUProjectsStats.types';
import getMenuItemsByStatus from './utils/utils.getMenuItemsByStatus';
import {TStatusPageAttributes} from './Projects.types';

type CustomNavigationByNavType = {
  title: string;
  entries: ISidebarEntry[];
  rank: number;
};

export const SEARCH_PAGINATION_COUNT = 400;

/* Repeatable & Reusable for both Page & Status attributes. Commonalities */
export const STATUS_PAGES_ATTRIBUTES: Omit<TStatusPageAttributes, NavPageTypes.SEARCH> = {
  [NavPageTypes.ALL_STATUSES]: {
    title: (projectsType: string) => {
      return projectsType === NavTypes.ALL ? 'All Projects' : 'All My Projects';
    },
    label: 'All Statuses',
    labelSecondary: '',
    key: NavPageTypes.ALL_STATUSES,
    icon: 'grid',
    colorVariant: 'blue',
  },
  [MDUStatuses.DRAFT]: {
    title: () => 'Draft Projects',
    label: 'Draft',
    labelSecondary: '',
    key: NavPageTypes.DRAFT,
    icon: 'draft',
    colorVariant: 'subdued-blue',
    actionMenu: getMenuItemsByStatus(['deleteProject']),
    sidebarActionMenu: getMenuItemsByStatus(['deleteProject']),
  },
  [MDUStatuses.READY]: {
    title: () => 'Ready To Launch Projects',
    label: 'Ready To Launch',
    labelSecondary: '',
    key: MDUStatuses.READY,
    icon: 'launch',
    colorVariant: 'purple',
    actionMenu: getMenuItemsByStatus(['deleteProject']),
    sidebarActionMenu: getMenuItemsByStatus(['deleteProject']),
  },
  [NavPageTypes.PAUSED]: {
    title: () => 'Paused Projects',
    label: 'Paused',
    labelSecondary: '',
    key: NavPageTypes.PAUSED,
    icon: 'pause',
    colorVariant: 'blue',
    actionMenu: getMenuItemsByStatus(['getPauseDisplay', 'deleteProject']),
  },
  [MDUStatuses.UPCOMING]: {
    title: () => 'Upcoming Projects',
    label: 'Upcoming',
    labelSecondary: '',
    key: NavPageTypes.UPCOMING,
    icon: 'clock',
    colorVariant: 'light-blue',
    actionMenu: getMenuItemsByStatus(['getPauseDisplay', 'deleteProject']),
    sidebarActionMenu: getMenuItemsByStatus(['getPauseDisplay', 'pauseHistory']),
  },
  [MDUStatuses.IN_PROGRESS]: {
    title: () => 'In Progress Projects',
    label: 'In Progress',
    labelSecondary: '',
    key: NavPageTypes.IN_PROGRESS,
    icon: 'hourglass',
    colorVariant: 'blue',
    actionMenu: getMenuItemsByStatus(['getPauseDisplay', 'deleteProject']),
    sidebarActionMenu: getMenuItemsByStatus(['getPauseDisplay', 'pauseHistory']),
  },
  [MDUStatuses.NEEDS_APPROVAL]: {
    title: () => 'Projects Needing Approval',
    label: 'Needs Approval',
    labelSecondary: '',
    key: NavPageTypes.NEEDS_APPROVAL,
    icon: 'note',
    colorVariant: 'yellow',
    actionMenu: getMenuItemsByStatus(['getPauseDisplay', 'approveProject']),
    sidebarActionMenu: getMenuItemsByStatus(['getPauseDisplay', 'pauseHistory']),
  },
  [NavPageTypes.APPROVED]: {
    title: () => 'Approved Projects',
    label: 'Done',
    labelSecondary: '',
    key: NavPageTypes.APPROVED,
    icon: 'round-check-fill',
    colorVariant: 'blue',
    sidebarActionMenu: getMenuItemsByStatus(['pauseHistory']),
  },
  [NavPageTypes.ISSUES]: {
    title: () => 'Projects with open issues', // Page title
    label: 'Has Open Issues', // Title for tab in sidebar
    labelSecondary: '',
    key: NavPageTypes.ISSUES,
    icon: 'alert', // Icon for tab in sidebar
    colorVariant: 'red',
  },
};

/* Helper to forward navi clicks */
const handleNavigateToProjectsPage = (page: NavPageTypes, navigate: NavigateFunction, projectsType: NavTypes) => () => navigate(buildProjectsPage({projectsType, projectsPage: page}));

/**
 * This hands back a custom object that allows for merging with defined navigation.
 *
 * @param dispatch
 * @param projectsType
 * @param projectsPage
 */
type ProjectsNavigationProps = {
  projectsPage: NavPageTypes;
  navigate: NavigateFunction;
  projectsType: NavTypes;
};
const projectsNavigation = ({navigate, projectsType, projectsPage}: ProjectsNavigationProps): CustomNavigationByNavType => {
  const {OWN, ALL, ARCHIVE} = NavTypes;

  const projectsAdditionalNavItems = {
    [ALL]: {
      title: 'All Projects',
      rank: 1,
      entries: [
        {
          ...STATUS_PAGES_ATTRIBUTES[NavPageTypes.ALL_STATUSES],
          active: projectsPage === NavPageTypes.ALL_STATUSES,
          onClick: handleNavigateToProjectsPage(NavPageTypes.ALL_STATUSES, navigate, projectsType),
        },
      ],
    },
    [OWN]: {
      title: 'My Projects',
      rank: 2,
      entries: [
        {
          ...STATUS_PAGES_ATTRIBUTES[NavPageTypes.ALL_STATUSES],
          active: projectsPage === NavPageTypes.ALL_STATUSES,
          onClick: handleNavigateToProjectsPage(NavPageTypes.ALL_STATUSES, navigate, projectsType),
        },
      ],
    },
    // @ts-ignore
    [ARCHIVE]: {
      title: 'Archive',
      entries: [],
    },
  };

  // @ts-ignore
  return projectsAdditionalNavItems[projectsType];
};

export const NavigationItems = ({projectsType, projectsPage}: Pick<MduUseParamsTypes, 'projectsType' | 'projectsPage'>) => {
  const navigate = useNavigate();
  const projectsNavigationObject = projectsNavigation({navigate, projectsType, projectsPage});
  const {projectsType: paramProjectsType} = useParams<MduUseParamsTypes>();
  const matchedProjectsType = paramProjectsType === projectsType;

  return {
    menu: [
      {
        label: projectsNavigationObject.title,
        rank: projectsNavigationObject.rank,
        entries: [
          {
            ...STATUS_PAGES_ATTRIBUTES[NavPageTypes.ALL_STATUSES],
            active: projectsPage === NavPageTypes.ALL_STATUSES && matchedProjectsType,
            onClick: handleNavigateToProjectsPage(NavPageTypes.ALL_STATUSES, navigate, projectsType),
          },
          {
            ...STATUS_PAGES_ATTRIBUTES[NavPageTypes.DRAFT],
            active: projectsPage === NavPageTypes.DRAFT && matchedProjectsType,
            onClick: handleNavigateToProjectsPage(NavPageTypes.DRAFT, navigate, projectsType),
          },
          {
            ...STATUS_PAGES_ATTRIBUTES[MDUStatuses.READY],
            active: projectsPage === NavPageTypes.READY && matchedProjectsType,
            onClick: handleNavigateToProjectsPage(NavPageTypes.READY, navigate, projectsType),
          },
          {
            ...STATUS_PAGES_ATTRIBUTES[NavPageTypes.UPCOMING],
            active: projectsPage === NavPageTypes.UPCOMING && matchedProjectsType,
            onClick: handleNavigateToProjectsPage(NavPageTypes.UPCOMING, navigate, projectsType),
          },
          {
            ...STATUS_PAGES_ATTRIBUTES[MDUStatuses.IN_PROGRESS],
            active: projectsPage === NavPageTypes.IN_PROGRESS && matchedProjectsType,
            onClick: handleNavigateToProjectsPage(NavPageTypes.IN_PROGRESS, navigate, projectsType),
          },
          {
            ...STATUS_PAGES_ATTRIBUTES[MDUStatuses.NEEDS_APPROVAL],
            active: projectsPage === NavPageTypes.NEEDS_APPROVAL && matchedProjectsType,
            onClick: handleNavigateToProjectsPage(NavPageTypes.NEEDS_APPROVAL, navigate, projectsType),
          },
          {
            ...STATUS_PAGES_ATTRIBUTES[MDUStatuses.APPROVED],
            active: projectsPage === NavPageTypes.APPROVED && matchedProjectsType,
            onClick: handleNavigateToProjectsPage(NavPageTypes.APPROVED, navigate, projectsType),
          },
          {
            ...STATUS_PAGES_ATTRIBUTES[NavPageTypes.PAUSED],
            active: projectsPage === NavPageTypes.PAUSED && matchedProjectsType,
            onClick: handleNavigateToProjectsPage(NavPageTypes.PAUSED, navigate, projectsType),
          },
          {
            ...STATUS_PAGES_ATTRIBUTES[NavPageTypes.ISSUES],
            active: projectsPage === NavPageTypes.ISSUES && matchedProjectsType,
            onClick: handleNavigateToProjectsPage(NavPageTypes.ISSUES, navigate, projectsType),
          },
        ],
      },
    ],
  };
};

export type IProjectPageParams = {
  [key in NavPageTypes]: {
    title: (projectsType: NavTypes) => string;
    defaultLabel?: string;
    filterKeys?: TFilterKey[];
    /** Column that appears in the table */
    columnKeys?: TColumnKey[];
    statKeys?: TStatKey[];
    pageFilters?: Partial<IMDUProjectsFilters>;
    statsFilters?: Partial<IMDUProjectsFilters>;
    /**
     * Options to use in the filters modal for the statuses filter
     */
    statuses?: {
      keys: SelectOption[];
      multiple: boolean;
    };
  };
};

export const PROJECT_PAGE_PARAMS: Omit<IProjectPageParams, NavPageTypes.SEARCH> = {
  [NavPageTypes.UPCOMING]: {
    ...STATUS_PAGES_ATTRIBUTES[NavPageTypes.UPCOMING],
    filterKeys: ['partnerId', 'propertyOwnerId', 'leadTechId', 'startDate', 'estimatedEndDate', 'projectManagerId', 'search', 'project_types'],
    columnKeys: ['name', 'projecttype', 'partner', 'start', 'end', 'pm', 'teamlead', ACTION_MENU_COL_ID],
    pageFilters: {
      statuses: [MDUStatuses.UPCOMING],
      paused: PausedStatusTypes.ONLY_NOT_PAUSED,
    },
  },
  [NavPageTypes.READY]: {
    ...STATUS_PAGES_ATTRIBUTES[MDUStatuses.READY],
    filterKeys: ['partnerId', 'propertyOwnerId', 'projectManagerId', 'leadTechId', 'startDate', 'estimatedEndDate', 'search', 'project_types'],
    columnKeys: ['name', 'projecttype', 'partner', 'start', 'end', 'pm', 'teamlead', ACTION_MENU_COL_ID],
    pageFilters: {
      statuses: [MDUStatuses.READY],
      paused: PausedStatusTypes.ONLY_NOT_PAUSED,
    },
  },
  [NavPageTypes.IN_PROGRESS]: {
    ...STATUS_PAGES_ATTRIBUTES[MDUStatuses.IN_PROGRESS],
    filterKeys: ['partnerId', 'propertyOwnerId', 'projectManagerId', 'leadTechId', 'startDate', 'estimatedEndDate', 'flagged', 'search', 'project_types'],
    columnKeys: ['name', 'projecttype', 'partner', 'start', 'end', 'pm', 'teamlead', 'flagged', ACTION_MENU_COL_ID],
    statKeys: ['in_progress', 'total_unit_progress', 'starting_today', 'flagged', 'late', 'project_manager_projects_average'],
    pageFilters: {
      statuses: [MDUStatuses.IN_PROGRESS],
      paused: PausedStatusTypes.ONLY_NOT_PAUSED,
    },
    statsFilters: {
      statuses: [MDUStatuses.IN_PROGRESS],
      paused: PausedStatusTypes.ONLY_NOT_PAUSED,
    },
  },
  [NavPageTypes.NEEDS_APPROVAL]: {
    ...STATUS_PAGES_ATTRIBUTES[MDUStatuses.NEEDS_APPROVAL],
    filterKeys: ['partnerId', 'propertyOwnerId', 'projectManagerId', 'leadTechId', 'startDate', 'estimatedEndDate', 'flagged', 'search', 'project_types'],
    columnKeys: ['name', 'projecttype', 'partner', 'start', 'end', 'pm', 'teamlead', 'flagged', ACTION_MENU_COL_ID],
    pageFilters: {
      statuses: [MDUStatuses.NEEDS_APPROVAL],
      paused: PausedStatusTypes.ONLY_NOT_PAUSED,
    },
  },
  [NavPageTypes.PAUSED]: {
    ...STATUS_PAGES_ATTRIBUTES[NavPageTypes.PAUSED],
    filterKeys: ['partnerId', 'propertyOwnerId', 'projectManagerId', 'leadTechId', 'startDate', 'estimatedEndDate', 'statuses', 'search', 'project_types'],
    columnKeys: ['name', 'projecttype', 'partner', 'start', 'end', 'pm', 'teamlead', 'status', ACTION_MENU_COL_ID],
    statuses: {
      keys: [
        {value: MDUStatuses.UPCOMING, label: convertSnakeToCamelAndCapitalize(MDUStatuses.UPCOMING)},
        {value: MDUStatuses.IN_PROGRESS, label: convertSnakeToCamelAndCapitalize(MDUStatuses.IN_PROGRESS)},
        {value: MDUStatuses.NEEDS_APPROVAL, label: convertSnakeToCamelAndCapitalize(MDUStatuses.NEEDS_APPROVAL)},
      ],
      multiple: false,
    },
    pageFilters: {
      paused: PausedStatusTypes.ONLY_PAUSED,
    },
  },
  [NavPageTypes.ALL_STATUSES]: {
    ...STATUS_PAGES_ATTRIBUTES[NavPageTypes.ALL_STATUSES],
    filterKeys: ['partnerId', 'propertyOwnerId', 'projectManagerId', 'leadTechId', 'startDate', 'estimatedEndDate', 'statuses', 'search', 'project_types', 'paused'],
    columnKeys: ['name', 'projecttype', 'partner', 'start', 'end', 'pm', 'teamlead', 'status', 'paused', ACTION_MENU_COL_ID],
    statKeys: [NavPageTypes.ALL_STATUSES, MDUStatuses.DRAFT, MDUStatuses.READY, MDUStatuses.UPCOMING, MDUStatuses.IN_PROGRESS, MDUStatuses.NEEDS_APPROVAL],
    statuses: {
      keys: [
        {value: MDUStatuses.DRAFT, label: convertSnakeToCamelAndCapitalize(MDUStatuses.DRAFT)},
        {value: MDUStatuses.READY, label: convertSnakeToCamelAndCapitalize(MDUStatuses.READY)},
        {value: MDUStatuses.UPCOMING, label: convertSnakeToCamelAndCapitalize(MDUStatuses.UPCOMING)},
        {value: MDUStatuses.IN_PROGRESS, label: convertSnakeToCamelAndCapitalize(MDUStatuses.IN_PROGRESS)},
        {value: MDUStatuses.NEEDS_APPROVAL, label: convertSnakeToCamelAndCapitalize(MDUStatuses.NEEDS_APPROVAL)},
      ],
      multiple: true,
    },
    pageFilters: {
      statuses: [MDUStatuses.DRAFT, MDUStatuses.READY, MDUStatuses.UPCOMING, MDUStatuses.IN_PROGRESS, MDUStatuses.NEEDS_APPROVAL],
    },
    statsFilters: {
      statuses: [MDUStatuses.DRAFT, MDUStatuses.READY, MDUStatuses.UPCOMING, MDUStatuses.IN_PROGRESS, MDUStatuses.NEEDS_APPROVAL],
      paused: PausedStatusTypes.ONLY_NOT_PAUSED,
    },
  },
  [NavPageTypes.APPROVED]: {
    // aka "Done"
    title: () => 'Approved Projects',
    defaultLabel: 'Approved',
    filterKeys: ['partnerId', 'propertyOwnerId', 'projectManagerId', 'leadTechId', 'startDate', 'approvedAt', 'search', 'project_types'],
    columnKeys: ['name', 'projecttype', 'partner', 'start', 'approved', 'pm', 'teamlead'],
    pageFilters: {
      statuses: [MDUStatuses.APPROVED],
    },
  },
  [NavPageTypes.DRAFT]: {
    ...STATUS_PAGES_ATTRIBUTES[NavPageTypes.DRAFT],
    filterKeys: ['partnerId', 'propertyOwnerId', 'projectManagerId', 'leadTechId', 'startDate', 'estimatedEndDate', 'search', 'project_types'],
    columnKeys: ['name', 'projecttype', 'partner', 'start', 'end', 'pm', 'teamlead', ACTION_MENU_COL_ID],
    pageFilters: {
      statuses: [MDUStatuses.DRAFT],
      paused: PausedStatusTypes.ONLY_NOT_PAUSED,
    },
  },
  [NavPageTypes.ISSUES]: {
    ...STATUS_PAGES_ATTRIBUTES[NavPageTypes.ISSUES],
    filterKeys: ['partnerId', 'propertyOwnerId', 'projectManagerId', 'leadTechId', 'startDate', 'estimatedEndDate', 'search', 'paused'],
    columnKeys: ['name', 'partner', 'openIssuesCount', 'start', 'end', 'teamlead', 'pm', 'status', 'paused', ACTION_MENU_COL_ID],
    pageFilters: {
      statuses: [],
      has_open_issues: true,
    },
  },
};
