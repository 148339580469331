import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import get from 'lodash/get';
import {Form} from 'ht-styleguide';

/* Hooks && Queries */
import {useFetchSkuAndQuestions} from 'features/MultiDwellingUnits/queries/query.sku.questions';
import {useCurrentProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/CurrentProject.hooks';

/* Ducks */
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';

/* Components */
import postQuestionActions from 'features/Questions/postQuestionActions';
import PostQuestion from 'features/Booking/Parts/QA/PostQuestion';
import RunningTotal from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/AddSku/MDU.AddSku.Summary';

/* Types */
import {CurrentProjectStages, MduUseParamsTypes, SkuQuestionQuery} from 'features/MultiDwellingUnits/MDU.types';
import {ErrorHashProp, QuestionsAPIByQuestion} from 'features/Questions/types';

type TAddSkuPageProps = {
  errors: ErrorHashProp;
  isJobBulkDraftMode?: boolean;
  selectedSkuDraftArray?: boolean;
  isJobDraftUUID?: boolean;
  /** Option to pass a sku id instead of getting the id from URL params */
  skuId?: string;
  showSkuName?: boolean;
};

const AddSkuPage = ({errors = {}, isJobBulkDraftMode = false, selectedSkuDraftArray = false, isJobDraftUUID = false, skuId: skuIdProp, showSkuName = true}: TAddSkuPageProps) => {
  const [runPostItemPrice, setRunPostItemPrice] = useState(false);
  /* Hooks */
  const {groupId, skuId, serviceId, mode} = useParams<MduUseParamsTypes>();
  const dispatch = useDispatch();
  const isEditMode = mode === CurrentProjectStages.EDIT;
  const {data: skuQuestionQuery} = useFetchSkuAndQuestions({skuId: skuIdProp || skuId || '', isEditMode, groupId: groupId!, serviceId: serviceId!});
  const {id: loadedProjectId} = useCurrentProject();
  const selectedSku = useSelector(mduProjectsSlice.selectors.getAddSkuSelectedSku);

  /* Constants */
  const adjustPrice = () => {
    setRunPostItemPrice(true);
  };

  const fullSkuById = (skuQuestionQuery?.sku ?? []) as SkuQuestionQuery['sku'];
  const skuQuestions = (skuQuestionQuery?.questions ?? []) as SkuQuestionQuery['questions'];

  useEffect(() => {
    if (loadedProjectId && get(skuQuestionQuery, 'sku.id', 0) && skuId) {
      dispatch(mduProjectsSlice.actions.setSelectedSku({skuId, isEditMode, groupId, serviceId, isJobBulkDraftMode, isJobDraftUUID}));
      adjustPrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedProjectId, skuQuestionQuery, skuId, serviceId, isEditMode, groupId]);

  useEffect(() => {
    if (runPostItemPrice) {
      dispatch(mduProjectsSlice.actions.postItemPrice({selectedSku, selectedSkuDraftArray}));
      setRunPostItemPrice(false);
    }
  }, [runPostItemPrice, selectedSku]);

  if (!selectedSku) return null;

  return (
    <>
      {showSkuName && <div className="h6 paddingBottom-medium paddingTop-small n800">{selectedSku.name}</div>}
      <Form>
        {skuQuestions.map((q: QuestionsAPIByQuestion) => {
          const actions = postQuestionActions({actions: mduProjectsSlice.actions, selectedSku, question: q, adjustPrice, dispatch});
          return (
            <Form.Row key={q.id}>
              <Form.Column md={12} lgOffset={0} lg={12}>
                <PostQuestion question={q} sku={fullSkuById} error={errors[q.id]} actions={actions} />
              </Form.Column>
            </Form.Row>
          );
        })}
      </Form>
      <RunningTotal skuId={skuId!} />
    </>
  );
};

export default AddSkuPage;
