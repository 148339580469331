import {EntityState, PayloadAction} from '@reduxjs/toolkit';
import {TColorVariant} from '../../components/Elements/DataWidget/dataWidget.types';
import {BreakDown} from '../Booking/Parts/Cart/cart.types';
import {QAErrors, QuestionTypes, QuestionsAPIByQuestion, SelectedSku, Sku} from '../Questions/types';
import {IHash} from '../../types/base.types';
import {ProjectDropdownDetails} from './Parts/FormFields/FormFields.types';
import {TStatKey} from './Pages/Projects/Parts/MDUProjectsStats/MDUProjectsStats.types';
import {IMDUProjectsFilters} from './Pages/Projects/Parts/MDUProjectsFilters/MDUProjectsFilters.types';

/**
 * Params used in MDU urls
 */
export type MduUseParamsTypes = {
  projectsType: NavTypes; // Projects only routes
  projectsPage: NavPageTypes; // Projects only routes
  stage: CurrentProjectStages;
  projectId: string;
  groupId: string;
  skuId: string;
  serviceId: string;
  mode: string;
  createTemplateType: 'existing-templates' | 'new-templates' | 'draft';
  createType: 'create' | 'bulk-create';
};

export enum NavTypes {
  OWN = 'own',
  ALL = 'all',
  ARCHIVE = 'archive',
}

/* This is an expansion onto filters. Those are derived by navType. But this is a standalone. */
export enum CustomFilterTypes {
  JOBS = 'jobs',
}

/* Join our enums */
export const SearchFilters = {
  ...NavTypes,
  ...CustomFilterTypes,
};

/* Type it so we can ref by key */
export type SearchFilterKeys = typeof SearchFilters;

/**
 * LETS LINE UP OUR ROUTE NAMES FOR PAGES
 * These are not projectId specific. These are global projects
 */
/* These are pages - mostly they syphon from a status, but some are not. ie. jobs/search/all_statuses */
export enum NavPageTypes {
  ALL_STATUSES = 'all_statuses',
  APPROVED = 'approved',
  IN_PROGRESS = 'in_progress',
  READY = 'ready_to_launch',
  DRAFT = 'draft',
  UPCOMING = 'upcoming',
  NEEDS_APPROVAL = 'needs_approval',
  PAUSED = 'paused',
  SEARCH = 'search',
  ISSUES = 'issues',
}

/**
 * These are used in the URL routing /mdu/project/:projectid/:stage
 * These are routes within a specific project
 * */
export enum CurrentProjectStages {
  CHECKLIST = 'checklist',
  DETAILS = 'details',
  TEAM_MANAGEMENT = 'team-management',
  TEMPLATES = 'templates',
  PAYMENT = 'payment',
  NOTES = 'notes',
  FILES = 'files',
  PROJECT_OVERVIEW = 'project-overview',
  JOBS = 'jobs',
  EDIT = 'edit',
  LAUNCH_REVIEW = 'launch-review',
  ISSUES = 'issues',
}

/**
 * PROJECT STATUSES
 */
export enum MDUStatuses {
  /** Initial state, requirements missing */
  DRAFT = 'draft',
  /** All requirements satisfied */
  READY = 'ready_to_launch',
  /** After launch, tech didn't start any units yet. */
  UPCOMING = 'upcoming',
  /**  After launch, tech has already started at least one uni */
  IN_PROGRESS = 'in_progress',
  /** After launch, All units completed */
  NEEDS_APPROVAL = 'needs_approval',
  /** All units completed and lead tech has already approved the project */
  APPROVED = 'approved',
}

/* UNIT/JOB STATUSES */
export enum MDUUnitStatuses {
  NOT_STARTED = 'not_started',
  UNSERVICEABLE = 'unserviceable',
  CANCELLED = 'cancelled',
  IN_PROGRESS = 'in_progress',
  PENDING_APPROVAL = 'pending_approval',
  COMPLETED = 'completed',
}

/* Keys of the Statutes */
export type ProjectAndUnitStatuses = Record<MDUStatuses | MDUUnitStatuses, string>;

/**
 * FILTER KEYS
 * Keys of filters to apply on endpoints and form fields
 */
export type TFilterKey =
  | 'propertyOwnerId'
  | 'propertyOwnerName'
  | 'leadTechId'
  | 'partnerId'
  | 'startDate'
  | 'estimatedEndDate'
  | 'projectManagerId'
  | 'statuses'
  | 'flagged'
  | 'only_flagged'
  | 'project_group_ids'
  | 'approvedAt'
  | 'lastModifiedAt'
  | 'search'
  | 'project_types'
  | 'paused';

/* Billing status */
export enum BillingStatus {
  PAID = 'paid',
  NOT_PAID = 'not_paid',
}

/**
 * Values used for endpoints and UI
 *
 * `only_paused` - Only show paused projects
 * `only_not_paused` - Only show projects that are not paused
 *  rest - projects regardless of paused status
 *
 * Pausing action is only allowed for in progress, upcoming, and needs approval project statuses
 */
export enum PausedStatusTypes {
  ONLY_PAUSED = 'only_paused',
  ONLY_NOT_PAUSED = 'only_not_paused',
  ALL = '',
}

/** Person object */
export type EntityIdBaseObject = {
  id: number;
  name: string;
};

export type TechProfilePicture = {
  medium: string;
  retina: string;
  retina_thumb: string;
  thumb: string;
};

export type TechProfileObject = EntityIdBaseObject & {
  active: boolean;
  billing_lead: boolean;
  email: string;
  lead_tech: boolean;
  name: string;
  phone: string;
  profile_picture: TechProfilePicture;
  was_lead_tech: boolean;
};

export type ProjectsPagination = {
  current_page: number;
  has_more: boolean;
  projects_on_current_page: number;
  projects_per_page: number;
  projects_viewed: number;
  total_pages: number;
  total_projects: number;
};

export type UnitsPagination = {
  current_page: number;
  has_more: boolean;
  total_pages: number;
  total_units: number;
  units_on_current_page: number;
  units_per_page: number;
  units_viewed: number;
};

export type TPagination = ProjectsPagination | UnitsPagination;

export type MDUProjectTypeSlugs = 'accelerated_retrofit' | 'new_construction' | 'rolling_retrofit' | 'property_inspection';
export type MDUProjectType = {
  id: number;
  name: string;
  slug: MDUProjectTypeSlugs;
};

/** Shallow Project Details from getAllProjects() API: I see an issue between useCamel and some that don't */
export type ProjectDetailsShallow = {
  id: number;
  name: string;
  start_date: string;
  estimated_end_date: string;
  approved_at: string | null;
  lead_tech: EntityIdBaseObject | null;
  partner: EntityIdBaseObject | null;
  project_manager: EntityIdBaseObject | null;
  project_type: MDUProjectType | null;
  property_owner: EntityIdBaseObject | null;
  status: MDUStatuses;
  flagged: string;
  is_flagged: boolean;
  paused: boolean;
  open_issues_count: number;
};

export type ProjectCreationFormFields = {
  name: string;
  contactName: string;
  partnerId: string;
  contactPhone: string;
  startDate: string;
  estimatedEndDate: string;
  approvedAt: string;
  leadTechId: string;
  unitsNumber: string;
  floorsNumber: string;
  accessInstructions: string;
  notes: string;
  fullAddress: string; // this is not a BE field. fullAddress is meant to work with AddressAutoComplete component
  [key: string]: string;
};

/* API response types */
type ResponseErrorKey = {err?: {}};
export type MduGetAllProjectsResponse = {
  data: {
    pagination: ProjectsPagination;
    projects: ProjectDetailsShallow[];
  };
} & ResponseErrorKey;

export type MduGetProjectTypesResponse = {
  data: {
    project_types: TProjectType[];
  };
};

export type MduGetAllJobsResponse = {
  data: {
    pagination: UnitsPagination;
    projects: ProjectDetailsShallow[];
    units: TUnit[];
  };
} & ResponseErrorKey;

type ProjectsStats = {
  approved: number;
  draft: number;
  flagged: number;
  has_open_issues: number;
  in_progress: number;
  late: number;
  needs_approval: number;
  paused: number;
  project_manager_projects_average: number;
  ready_to_launch: number;
  starting_today: number;
  total: number;
  upcoming: number;
};

type ProjectsStatsProgress = {
  completed_units_percent: number;
  completed_units_total: number;
  flagged_units_percent: number;
  flagged_units_total: number;
  needs_approval_units_total: number;
  open_units_percent: number;
  open_units_total: number;
  paid_units_percent: number;
  paid_units_total: number;
  ready_units_percent: number;
  ready_units_total: number;
  units_total: number;
  unserviceable_units_percent: number;
  unserviceable_units_total: number;
};

export type MduCreateProjectResponse = {
  data: {
    project: ProjectDetails;
    status: string | number;
  };
} & ResponseErrorKey;

export type MduGetProjectsStatsResponse = WithErrorResponse<{
  stats: ProjectsStats;
  progress: ProjectsStatsProgress;
}>;

export enum TSingleActionTypes {
  DELETE = 'delete',
  APPROVE = 'approve',
  REOPEN = 'reopen',
  CANNOT_COMPLETE = 'cannot_complete',
  ADD_SINGLE_JOB = 'add_single_job',
  PAUSE = 'pause',
  LINK_ISSUE_PAUSED_PROJECT = 'link_issue_to_paused_project',
  RESUME = 'resume',
}

export enum TBulkActionTypes {
  DELETE_BULK_PROJECTS = 'delete_bulk_projects',
  APPROVE_BULK_PROJECTS = 'approve_bulk_projects',

  DELETE_BULK_JOBS = 'delete_bulk_jobs',
  APPROVE_BULK_JOBS = 'approve_bulk_jobs',
  REOPEN_BULK_JOBS = 'reopen_bulk_jobs',
  CANNOT_COMPLETE_BULK_JOBS = 'cannot_complete_bulk_jobs',

  ADD_BULK_JOBS = 'add_bulk_jobs',
  SWAP_BULK_TEMPLATES = 'swap_bulk_templates',

  ADD_BULK_SERVICES = 'add_bulk_services',
}

/* Current actionable project user selects via actionMenu on lineItems */
export type TActionModalTypes = 'Project' | 'Job';

export type TActionItem = {
  // entity: | ProjectDetailsShallow | TUnit;
  entity: Pick<ProjectDetailsShallow, 'id' | 'name'> & Pick<TUnit, 'unit_name' | 'id'>;
  modalKey: TSingleActionTypes;
  modalType: TActionModalTypes;
} | null;

/* As is usual the case, everything needs to do everthing. So we need to be able to filter on certain navi items */
export type WideNavTypeForFilter = NavTypes | NavPageTypes | CustomFilterTypes;

/* Represents the keys we save to redux with the raw select values */
export type TRawFilterKeys = 'partnerId' | 'propertyOwnerId' | 'projectManagerId' | 'leadTechId' | 'project_group_ids';
export type TSelectedFlatRows = IHash<number[]>;

export type TProjectType = {
  id: number;
  slug: string;
  name: string;
};

export interface IMduProjectsState {
  rawFilters: {
    [key in TRawFilterKeys]: {
      [key: string]: any;
    };
  };
  filters: {
    [key in NavTypes | CustomFilterTypes]: {
      [key: string]: any;
    };
  };
  bulkActions: {
    selectAll: boolean;
    showBanner: boolean;
    selectedFlatRows: TSelectedFlatRows;
    exclusionIds: IHash<number[]>;
    totalRowsSelected: number;
  };
  filteredProjects: any;
  pagination: any;
  currentProject: any;
  searchedProjects: ProjectDetailsShallow[];
  searchTerm: string;
  actionItem: TActionItem | null;
  actionItemBulk: {key: TBulkActionTypes} | null;
  addSku: AddSkuDetails;
  parentLocation: string | null;
  projectTypes: TProjectType[] | null;
  templateCreationDraft: {
    existing: ProjectGroup[];
    new: ProjectGroup[];
  };
  sideSheetKey: 'pauseHistory' | null;
}

export type MduGenericReponse = {
  data: {
    project: ProjectDetails;
  };
} & ResponseErrorKey;

export type MduFetchProjectResponse = {
  data: {
    project: ProjectDetails;
    status?: string;
    errors?: any;
  };
} & ResponseErrorKey;

export type MduFetchPayoutAdjustmentResponse = {
  data: {
    payoutAdjustment: ProjectPaymentAdjustment;
    status?: string;
  };
} & ResponseErrorKey;

export type MduFetchProjectTechsListResponse = {
  data: {
    techs: TechProfileObject[];
    status?: string;
  };
} & ResponseErrorKey;

/* Payload Types */
export type CreateProjectPayloadAction = PayloadAction<{errors?: {[key: string]: string[]}[]; status?: number; data?: {project?: ProjectDetails}}>;

export type ProjectService = {
  id: number | string;
  questions?: {};
  pricePerUnit?: number;
  quantity?: number | string;
  totalPerUnit?: number;
  sku?: {
    id: number;
    name: string;
    price: number;
  };
};

/* Project Service Response */
export type ProjectServiceResponse = {
  projectServce: ProjectService;
};

export type JobService = {
  id: number;
  approvedAt: string;
  productSerialNumber: number;
  quantity: number;
  serviceFlag: string;
  status: MDUUnitStatuses;
  name: string;
  sku: {
    id: number;
    name: string;
    icon: {
      [key: string]: string;
    };
  };
  tech: {
    email: string;
    id: number;
    name: string;
    phone: string;
  };
};

export type ProjectGroup = {
  id: string | number;
  unitsNumber?: number | string | null;
  pricePerUnit?: string | null;
  total?: number | undefined;
  totalWithoutSubsidyFormatted?: string;
  perUnitTotalTechPayoutFormatted?: string;
  perUnitTotalWithoutSubsidyFormatted?: string;
  projectServices?: ProjectService[] | TGroupSelectSkuServicesRoot[];
  breakdown?: BreakDown;
  name: string;
};

export type TProjectGroupDraft = ProjectGroup & {
  draftType?: 'new' | 'existing';
  unitCount?: number;
};

export type ProjectDetails = {
  id: number | string;
  name?: string | null;
  /** Estimated start date */
  startDate?: string | null;
  /** Actual start date of project; when the first job was started */
  startedAt?: string | null;
  endDate?: string | null;
  estimatedEndDate: string | null;
  unitsNumber?: number | null | undefined;
  floorsNumber?: number | null;
  status: MDUStatuses;
  contactName?: string | null;
  contactPhone?: string | null;
  accessInstructions?: string | null;
  notes: string | null;
  address?: string | null;
  usedUnits?: number | undefined;
  total?: number;
  totalWithoutSubsidy?: number;
  leadTech: ProjectLeadPerson | null;
  billingLead: ProjectLeadPerson | null;
  partner: ProjectDropdownDetails | null;
  client: ProjectDropdownDetails | null;
  projectManager?: ProjectDropdownDetails;
  propertyOwner?: ProjectDropdownDetails;
  projectType?: MDUProjectType;
  projectGroups?: ProjectGroup[];
  openIssuesCount: number;
  contactTitle: string | null;
  stats?: ProjectLaunchStats;
  progress?: {
    completedUnitsPercent: number;
    completedUnitsTotal: number;
    flaggedUnitsPercent: number;
    flaggedUnitsTotal: number;
    needsApprovalUnitsTotal: number;
    openUnitsPercent: number;
    openUnitsTotal: number;
    readyUnitsPercent: number;
    readyUnitsTotal: number;
    unitsTotal: number;
    unserviceableUnitsPercent: number;
    unserviceableUnitsTotal: number;
  };
  paused: boolean;
  activePause: {
    /**
     * id of project hold
     */
    id: number;
    /**
     * In datetime
     */
    startedAt: string;
    /**
     * Relevant issue data
     */
    ticket: {
      id: number;
      summary: string;
    } | null;
  } | null;
  [key: string]: number | string | null | {} | undefined;
} & AdditionalPayoutAdjustmentTypes;

// Payout Adjustment

type AdditionalPayoutAdjustmentTypes = {
  payoutAdjustments: ProjectPaymentAdjustment[];
  originalTechPayout?: number;
  originalTechPayoutFormatted?: string;
  totalTechPayoutAdjustment?: number;
  totalTechPayoutAdjustmentFormatted?: string;
  totalTechPayout?: number;
  totalTechPayoutFormatted?: string;
  margin?: number;
  marginFormatted?: string;
  percentMargin?: number;
  percentMarginFormatted?: string;
  percentTechPayoutAdjustment?: number;
  percentTechPayoutAdjustmentFormatted?: string;
};

export type ProjectPaymentAdjustment = {
  id: number;
  kind: ProjectPayoutAdjustmentTypes;
  name: string;
  value: number;
  valueFormatted: string;
  totalTechPayoutAdjustment: number;
  totalTechPayoutAdjustmentFormatted: string;
  payoutAdjustmentType?: {
    id: number;
    name: string;
  };
};

export enum PaymentAdjustmentRadioOptions {
  Increase = 'Increase',
  Decrease = 'Decrease',
}

export type SelectOption = {
  label: string;
  value: string | number;
  isDisabled?: boolean;
};

export type ProjectDetailsFormFields = {
  accessInstructions: string;
  contactName: string;
  contactPhone: string;
  contactTitle: string;
  estimatedEndDate: string;
  approvedAt: string;
  lastModifiedAt: string;
  floorsNumber: string;
  name: string;
  notes: string;
  partnerId: string;
  projectManagerId: string;
  projectType: string;
  propertyOwnerId: string;
  propertyOwnerName: string;
  startDate: string;
  leadTechId: string;
  unitsNumber: string;
  fullAddress: string; // this is not a BE field. fullAddress is meant to work with AddressAutoComplete component
  [key: string]: string;
};

export enum ProjectPayoutAdjustmentTypes {
  Fixed = 'fixed',
  Percent = 'percent',
}

export type ProjectPaymentAdjRequestObj = {
  project_payout_adjustment: {
    payout_adjustment_type_id: number | null;
    name: string | null; // Ignored by BE when payout_adjustment_type_id is present, otherwise is required
    kind: ProjectPayoutAdjustmentTypes;
    value: number;
    id?: number; // only used when editing an adjustment
  };
};

export type IStatProps = {
  [key in TStatKey | NavPageTypes]: Partial<{
    title: (projectsType: NavTypes) => string;
    label: string;
    icon: string;
    iconColor: string;
    colorVariant: TColorVariant;
    filters: Partial<IMDUProjectsFilters>;
    /**
     * Specify a key in stats data object to reference when extracting value
     */
    statRef: keyof ProjectsStats;
  }>;
};

export type ProjectLeadPerson = {
  id: number;
  name: string;
  phone: string;
  email: string;
  profilePicture: {
    medium: string;
    retina: string;
    thumb: string;
    retinaThumb: string;
  };
};

// Project Notes and Attachments
export type ProjectNote = {
  attachments: ProjectNoteAttachment[];
  author: ProjectNoteAuthor;
  content: string;
  created_at: string;
  entry_type: string;
  entry_type_label: string;
  id: number;
};

export type ProjectNoteAuthor = {
  email: string;
  id: number;
  name: string;
  phone: string;
  profile_picture: {
    medium: string;
    retina: string;
    thumb: string;
    retina_thumb: string;
  };
};

export type ProjectNoteAttachment = {
  created_at: string;
  file_size: number;
  file_size_formatted: string;
  file_type: string;
  id: number;
  url: string;
};

/* Standalone apis used: conver these to queries */
export type MduApi = {
  searchTechs: BaseAnyFunction;
  updateProject: BaseAnyFunction;

  deleteGroup: BaseAnyFunction;
  addGroup: BaseAnyFunction;
  updateGroup: BaseAnyFunction;
};

export type ProjectLaunchStats = {
  completed: boolean;
  total: number;
  totalCompleted: number;
  payment: {
    completed: boolean;
    billingLead: {
      completed: boolean;
    };
  };
  projectDetails: {
    completed: boolean;
    requiredFields: {
      completed: boolean;
      missingFields: string[];
    };
  };
  teamManagement: {
    completed: boolean;
    teamLead: {
      completed: boolean;
    };
  };
  templatesJobs: {
    completed: boolean;
    unitCount: {
      completed: boolean;
    };
    unitCountMatchTemplates: {
      completed: boolean;
      projectUnitCount: number | null;
      templatesUnitCount: number;
    };
    jobTemplates: {
      completed: boolean;
    };
  };
};

export type TProjectProgressResponse = WithErrorResponse<{
  project: {
    id: number;
    name: string;
    status: string;
    unitsTotal: number;
    completedUnitsTotal: number;
    unserviceableUnitsTotal: number;
    readyUnitsTotal: number;
    openUnitsTotal: number;
    paidUnitsTotal: number;
    flaggedUnitsTotal: number;
    needsApprovalUnitsTotal: number;
    completedUnitsPercent: number;
    unserviceableUnitsPercent: number;
    readyUnitsPercent: number;
    openUnitsPercent: number;
    paidUnitsPercent: number;
    flaggedUnitsPercent: number;
    estimatedRevenue: number;
    estimatedRevenueFormatted: string;
    techPaidPayout: number;
    techPaidPayoutFormatted: string;
    jobsPerDay: number;
    daysOnSite: number;
    daysPaused: number;
  };
}>;

/* Update group params */
export type UpdateGroupParams = {
  units_number?: number;
  name?: string;
};

export type SkuSearchResult = {
  id: number;
  name: string;
  price: number;
};

/* Add Sku Concerns */
export type AddSkuDetails = {
  questions: EntityState<any>;
  skus: EntityState<any>;
  errors: QAErrors;
  selectedSku: SelectedSku;
  selectedSkuArray: SelectedSku[];
};

/*  Query To get sku and sku questions */
export type SkuQuestionQuery = {
  questions: QuestionsAPIByQuestion[];
  sku: Sku;
};

/* Misc User Item */
export type FormattedDateByUserTimezoneInputs = {
  date?: string | number | null;
  format?: string;
};

/* Menu Actions for table line items & sidebar menu */
export type TMenuActionItems = {
  key: string;
  text: string;
  onClick: BaseAnyFunction;
};

// Unit
export type TServicesResume = {
  add_ons: string[];
  quantity: number;
  sku: {id: number; name: string};
};

export type TUnit = {
  billing_status: BillingStatus;
  created_at: string;
  ended_at: string | null;
  flagged: boolean;
  id: number;
  last_modified_at: string;
  project_group: ProjectGroup;
  service_count: number;
  services_resume: TServicesResume[];
  started_at: string | null;
  status: MDUUnitStatuses;
  unit_floor: string;
  unit_name: string;
};

export type TUnitApiParams = {
  projectId: string;
  unitId?: string | number;
};

export type TMduFetchJobResponse = {
  data: {
    job: TUnit;
    status?: string;
    pagination: any;
  };
} & ResponseErrorKey;

/* Reason types for a cancelation */
export type TReasonType = {
  id: number;
  text: string;
  type: string;
};

/**
 * https://github.com/HelloTech/hellotech/pull/5524
 * https://github.com/HelloTech/hellotech/pull/5568
 */
export type TProjectActionResponse = {
  id: number;
  action: 'approve_projects' | 'delete_projects' | 'approve_units' | 'delete_units' | 'unserviceable_units' | 'reopen_units';
  status: 'draft' | 'verifying' | 'verified' | 'started' | 'finished';
  items_type: 'Project' | 'Order';
  started_at: string | null;
  finished_at: string | null;
  error_count: number;
  success_count: number;
  executed_count: number;
  /**
   * Based on "applicable_count"
   */
  executed_percent: number;
  /**
   * Based on "applicable_count"
   */
  executed_percent_formatted: string;
  applicable_count: number;
  /**
   * Total including "not_applicable"
   */
  total_count: number;
  items: Array<{
    id: number;
    project?: {
      id: number;
      name: string;
      partner_name: string;
      status: string;
    };
    unit?: {
      id: number;
      unit_name: string | null;
      status: string;
    };
    status: 'not_applicable' | 'applicable' | 'draft' | 'started' | 'success' | 'error';
    started_at: string | null;
    finished_at: string | null;
    finished: boolean;
    success: boolean;
    result:
      | {}
      | {
          success: boolean;
          errors?: {};
        };
  }>;
};

/* This is needed in various apis that allow for blank template creation */
export type TGroupSelectSkuServices = {
  sku: {
    id: number;
    quantity: number;
    questions: {
      [key: string]: {
        id: number;
      };
    };
  };
};

/* Our draft services. Returned mostly on draft calls */
export type TGroupSelectSkuServicesRoot = TGroupSelectSkuServices['sku'] & {
  sku: {
    id: number;
    name: string;
    price: number;
  };
};

/* Lets just hard value the group exists modal since the user takes responsibility for what is passed in */
export type TGroupExistsModalService = {
  quantity: number;
  name: string;
  uuidDraft?: string;
};
/**
 * The endpoint to bulk add a service to multiple jobs expects the sku questions and
 * their answers to be formatted in a specific way.
 */
export type TServiceBulkAddQAFormat = {
  /** question id */
  id: string | number;
  /** BE needs input type to perform its internal logic */
  input_type: QuestionTypes;
  /**
   * Input and Textarea. For legacy reasons the BE only accepts an answer key
   * Device. For legacy reasons the BE only accepts an answer key with a product details
   */
  answer?:
    | string
    | {
        product_make_id: number | string;
        product_id: number | string;
      };
  /**
   * Checkbox. BE expects a number[]; LEGACY REASONS.
   * Dropdown. BE expects an object[]; LEGACY REASONS.
   */
  answers?: number[] | {preAnswerId: number}[];
};

export type TServiceBulkAddSkuRequestFormat = {
  id: number;
  pre_questions: TServiceBulkAddQAFormat[];
};

export type ProjectPauseResponse = {
  id: number;
  started_at: string;
  ended_at: string;
  days_paused: number;
  ticket: {
    id: number;
    summary: string;
  };
  started_by: {
    id: number;
    phone: string;
    email: string;
    name: string;
    profile_picture: {
      medium: string;
      retina: string;
      thumb: string;
      retina_thumb: string;
    };
  };
  ended_by: string;
};

export type TPauseProjectResponse = WithErrorResponse<{
  project_pause: ProjectPauseResponse;
}>;

export type TUpdateProjectPauseResponse = WithErrorResponse<{
  project_pause: ProjectPauseResponse;
}>;

export type TResumeProjectResponse = WithErrorResponse<{
  project_pause: ProjectPauseResponse;
}>;

export type TProjectPauseHistoryResponse = WithErrorResponse<{
  project_pauses: ProjectPauseResponse[];
}>;
