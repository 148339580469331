import * as Types from '../issues.types';
import {useDispatch} from 'react-redux';
import {useQuery} from 'react-query';
import useApi from 'hooks/useApi';
import APIS from 'global/apis';
import {showErrorToast} from 'utils/query';
import {logger} from 'utils/logger';

import {ISSUES_QUERY_KEYS} from './Issues.query.keys';

/**
 *
 * @param ticketId
 *
 * ref: https://hellotech-internal.readme.io/reference/tickets-list-notes
 */

type TGGetIssueNotes = {
  ticketId: number;
};

type TGGetIssueNotesDataResponse = {
  attachments: {}[];
  content: any; // UPDATE with editor type
  created_at: string;
  id: number;
  user: {
    email: string;
    id: number;
    name: string;
    phone: string;
  };
};

export const useGetIssuesNotesQuery = ({ticketId}: TGGetIssueNotes) => {
  const dispatch = useDispatch();
  const api = useApi();

  return useQuery<Types.TGetIssueNotesDataResponse[]>(
    ISSUES_QUERY_KEYS.getNotes(ticketId),
    async () => {
      api.toggleLoader(true);
      const params = {ticket_id: ticketId};
      const response = await APIS.issues.getNotes(params);
      api.toggleLoader(false);

      if (response.err) {
        showErrorToast(dispatch)({errors: `Error Retrieving Notes for ticket ${ticketId}`});
        throw new Error(response.err);
      }

      return response?.data?.notes;
    },
    {
      enabled: !!ticketId,
      onError: response => {
        logger('Ticket Notes')(response as Error);
      },
    }
  );
};
