import {createSlice, createSelector, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'store';
import * as TS from './booking.ui.types';

/*
 *****************************************
 ************ INITIAL STATE  *************
 *****************************************
 */

const initialState: TS.IIniitalState = {
  creditAppliedToastShown: {},
};

/*
 ****************************************
 ************ CREATE SLICE  *************
 *****************************************
 */
const {actions: bookingUIActions, reducer: _reducer} = createSlice({
  name: 'booking/ui',
  initialState,
  reducers: {
    updateCreditAppliedToastShown: (state, action: PayloadAction<{cartId: number; shown: boolean}>) => {
      state.creditAppliedToastShown = {
        ...state.creditAppliedToastShown,
        [action.payload.cartId]: action.payload.shown,
      };
    },
  },
});

/*
*******************************************************
  SELECTORS & SELECTOR METHODS
*******************************************************
*/
const getBookingUIState = (state: RootState) => state.booking.ui;
const selectors = {
  getCreditAppliedToastShown: createSelector(getBookingUIState, state => state.creditAppliedToastShown),
};

/*
*******************************************************
  EXPORTS
*******************************************************
*/
export const reducer = _reducer;

export default {
  actions: bookingUIActions,
  selectors,
};
