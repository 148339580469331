import {combineReducers} from '@reduxjs/toolkit';
import {splitReducer} from '@splitsoftware/splitio-redux';
import {login} from 'features/Login/login.ducks';
import {notifications} from 'components/Notification/notification.ducks';
import servicesReducer from 'features/Booking/Parts/Services/services.ducks';
import cartReducer from 'features/Booking/Parts/Cart/cart.ducks';
import {reducer as bookingUIReducer} from 'features/Booking/ui/booking.ui.ducks';
import bookingTypeReducer from 'features/Booking/Parts/BookingType/bookingtype.ducks';
import followupOrderReducer from 'features/Booking/Parts/BookingType/FollowupOrders/followup.ducks';
import qaReducer from 'features/Booking/Parts/QA/qa.ducks';
import orderReducer from 'features/Booking/Parts/Order/order.ducks';
import {reducer as bookingPlanUpsellReducer} from 'features/Booking/Parts/Cart/PlanUpsell/planUpsell.ducks';
import clientReducer from 'features/Booking/Client/client.ducks';
import {reducer as requestLoadingReducer} from 'features/App/RequestLoader/requestLoader.ducks';
import mduProjectsReducer from 'features/MultiDwellingUnits/MDU.ducks';
import uiSlice from 'features/ui/ui.ducks';
import editorReducer from 'components/Editor/state/editor.ducks';
import cloudinaryReducer from 'features/Cloudinary/state/cloudinary.ducks';
import issuesReducer from 'features/Issues/Issues.ducks';
import dispatchReducer from 'features/Dispatch/dispatch.ducks';

// Membership
import membership from 'features/Membership/membership.ducks';
import profile from 'features/Profile/profile.ducks';

const bookingReducer = combineReducers({
  bookingType: bookingTypeReducer,
  services: servicesReducer,
  cart: cartReducer,
  followupOrder: followupOrderReducer,
  qa: qaReducer,
  planUpsell: bookingPlanUpsellReducer,
  order: orderReducer,
  client: clientReducer,
  ui: bookingUIReducer,
});

const mduReducer = combineReducers({
  projects: mduProjectsReducer.reducer,
});

export default () =>
  combineReducers({
    auth: login.reducer,
    booking: bookingReducer,
    memberships: membership,
    profile,
    notification: notifications.reducer,
    requestLoading: requestLoadingReducer,
    splitio: splitReducer,
    mdu: mduReducer,
    ui: uiSlice.reducer,
    editor: editorReducer.reducer,
    cloudinary: cloudinaryReducer.reducer,
    issues: issuesReducer.reducer,
    dispatch: dispatchReducer.reducer,
  });
