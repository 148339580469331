import React, {ReactNode} from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import environment from 'global/environments';

const stripePromise = loadStripe(environment.STRIPE_API_KEY);

const StripeProvider = ({children}: {children: ReactNode}) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeProvider;

/*
  Stripe recommends wrapping the entire app in the provider.
*/
