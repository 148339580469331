/* eslint-disable @typescript-eslint/naming-convention */
import React, {useEffect} from 'react';
// Hooks
import useApi from 'hooks/useApi';
import {useCurrentProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/CurrentProject.hooks';
// Queries
import {useProjectProgressQuery} from 'features/MultiDwellingUnits/queries/query.project.progress';
// Components && Styles
import JobsStats from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/Jobs/Jobs.Stats';
import Grid from 'components/Grid';
import PageHeader from 'components/Elements/PageHeader';
import WidgetDashboard from 'components/Elements/WidgetDashboard';
import styles from './projectOverview.styles.scss';

const OverlineSectionHeader = ({text}: {text: string}) => <h2 className="overline-medium marginBottom-small2 n700">{text}</h2>;

export const CATEGORIES = {
  PROJECT_PROGRESS: 'PROJECT PROGRESS',
  FINANCIALS: 'FINANCIALS',
  TEAM_PERFORMANCE: 'TEAM PERFORMANCE',
};

const DateStatusChip = () => {
  const currentProject = useCurrentProject();
  const {startDate, estimatedEndDate} = currentProject;

  const now = new Date();
  const start = new Date(startDate || '');
  const end = new Date(estimatedEndDate || '');

  const isScheduled = start > now;
  const isOnTime = start < now && end > now;
  const isLate = start < now && end < now;

  const getStatusText = () => {
    if (isScheduled) return 'Scheduled';
    if (isOnTime) return 'On-Time';
    if (isLate) return 'Late';
    return 'Unknown';
  };

  return <PageHeader.Chip label={getStatusText()} />;
};

const ProjectOverviewPage = () => {
  /* This view is cobbled together with data from 2 different endpoints. So return null until both sources are ready */
  const api = useApi();

  const {data: projectProgress, isLoading: isQueryLoading} = useProjectProgressQuery(); // Using this endpoint to grab data for the remaining widgets
  const {estimatedRevenueFormatted, techPaidPayoutFormatted, jobsPerDay, daysOnSite, daysPaused} = projectProgress || {};

  const currentProject = useCurrentProject();
  const isCurrentProjectDataFetched = Object.keys(currentProject).length; // <JobStats> is using this to populate itself

  const isPageLoading = isQueryLoading || !isCurrentProjectDataFetched;

  useEffect(() => {
    if (isPageLoading) {
      api.toggleLoader(true);
    } else {
      api.toggleLoader(false);
    }
  }, [isPageLoading, api]);

  if (isPageLoading) return null;

  return (
    <section className={styles.page}>
      <PageHeader title="Project Overview" HeaderRightContent={<DateStatusChip />} withGridMargins />
      <Grid.Fluid>
        <div className="marginTop-medium marginBottom-medium1">
          <OverlineSectionHeader text={CATEGORIES.PROJECT_PROGRESS} />
          <JobsStats suppressActions noHorizontalPadding noVerticalPadding />
        </div>
        <div className="marginBottom-medium1">
          <OverlineSectionHeader text={CATEGORIES.FINANCIALS} />
          <WidgetDashboard numCols={2} numRows={1} noHorizontalPadding noVerticalPadding>
            <WidgetDashboard.Widget colorVariant="teal" icon="bar-chart" label="Estimated Revenue" value={estimatedRevenueFormatted} suppressActions />
            <WidgetDashboard.Widget colorVariant="teal" icon="dollar-outline" label="PAYOUT SO FAR" value={techPaidPayoutFormatted} suppressActions />
          </WidgetDashboard>
        </div>
        <div className="marginBottom-medium1">
          <OverlineSectionHeader text={CATEGORIES.TEAM_PERFORMANCE} />
          <WidgetDashboard numCols={2} numRows={1} noHorizontalPadding noVerticalPadding>
            <WidgetDashboard.Widget colorVariant="blue" icon="layers" label="AVG Jobs PER DAY" value={jobsPerDay} suppressActions />
            <WidgetDashboard.Widget colorVariant="blue" icon="clock" label="DAYS ON SITE" value={daysOnSite} suppressActions />
            <WidgetDashboard.Widget colorVariant="yellow" icon="pause" label="DAYS PAUSED" value={daysPaused} suppressActions />
          </WidgetDashboard>
        </div>
      </Grid.Fluid>
    </section>
  );
};

export default ProjectOverviewPage;
