import React from 'react';
import {useMatch} from 'react-router-dom';
import SideSheetIssue from 'features/Issues/Parts/SideSheets/SideSheet.Issue';
import {ISSUE_PARAMS_TYPES, ISSUE_SLIDESHEET_TYPES, ISSUE_ENTITY_TYPES} from 'features/Issues/Issues.constants';
import {getValidIssueMode} from 'features/Issues/Issues.utils';
import {MDU_ROUTES} from 'features/MultiDwellingUnits/MDU.constants';
import {CurrentProjectStages} from 'features/MultiDwellingUnits/MDU.types';
import {useSearchParams} from 'hooks/useSearchParam';

/**
 *****************************************************
 * useCreateButtonIssue
 *
 * The issues sidesheet is triggered by changes in the url query. This hook listens
 * for changes in the url query with specific parameters and opens the issues sidesheet.
 *****************************************************
 */
export const useCreateButtonIssue = () => {
  /**
   * If the user is on a single project page, the issues create side sheet should populate
   * with the current project id. Here, we're grabbing the project id from the route.
   */
  const singleProjectRouteMatch = useMatch(MDU_ROUTES.ProjectPathBase);
  const {projectId = ''} = singleProjectRouteMatch?.params || ({} as {projectId: string; stage: CurrentProjectStages});
  const isOnSingleProjectRoute = !!projectId;

  /**
   * Build search params helpers based on the route.
   */
  const {getParam, deleteParam, setParam} = useSearchParams({
    [ISSUE_PARAMS_TYPES.issue_mode]: ISSUE_SLIDESHEET_TYPES.issue_create,
    ...(isOnSingleProjectRoute && {
      [ISSUE_PARAMS_TYPES.entity_type]: ISSUE_ENTITY_TYPES.project,
      [ISSUE_PARAMS_TYPES.ticket_id]: projectId,
    }),
  });

  const issueMode = getValidIssueMode(getParam(ISSUE_PARAMS_TYPES.issue_mode));

  return {
    closeSideSheet: deleteParam,
    issueMode: issueMode as ReturnType<typeof getValidIssueMode>,
    openSideSheet: setParam,
  };
};

/**
 *****************************************************
 * <CreateButtonIssue />
 *****************************************************
 */
const CreateButtonIssue = () => {
  const {issueMode, closeSideSheet} = useCreateButtonIssue();

  return issueMode ? <SideSheetIssue issueMode={issueMode} closeSideSheet={closeSideSheet} isOpenIssueSideSheet /> : null;
};

export default CreateButtonIssue;
