import {useState, useMemo} from 'react';
import chunk from 'lodash/chunk';
import {TUseInternalPagination} from '../dataTable.types';
import {PAGE_SELECT_OPTIONS} from './paginator.constants';

/**
 * Pagination attributes if using pagination driven by the `data` prop.
 *
 * TODO: Consolidate with logic in `Paginator.tsx`
 */
const useInternalPagination = <TData extends Record<string, any> = {}>({internalPagination, data = [], showPaginator, pagination}: TUseInternalPagination<TData>) => {
  const pageSelectOptions = internalPagination?.pageSelectOptions || PAGE_SELECT_OPTIONS;
  const initialPageSelectOptionsIndex = internalPagination?.initialPageSelectOptionsIndex || 0;
  const [internalPageIndex, setInternalPageIndex] = useState(0);
  const [internalPageItemCount, setInternalPageItemCount] = useState(pageSelectOptions[initialPageSelectOptionsIndex]);

  const {onPaginationChange} = internalPagination || {};
  const dataLength = data.length;
  const internalTotalPages = Math.ceil(dataLength / internalPageItemCount);

  const canPreviousPage = internalPageIndex > 0;
  const previousPage = () => {
    if (canPreviousPage) {
      const newIndex = internalPageIndex - 1;
      onPaginationChange?.({pageIndex: newIndex, itemCount: internalPageItemCount});
      setInternalPageIndex(newIndex);
    }
  };

  const canNextPage = internalPageIndex < internalTotalPages - 1;
  const nextPage = () => {
    if (canNextPage) {
      const newIndex = internalPageIndex + 1;
      onPaginationChange?.({pageIndex: newIndex, itemCount: internalPageItemCount});
      setInternalPageIndex(newIndex);
    }
  };

  const setPageCount = (newPageItemCount: number) => {
    onPaginationChange?.({pageIndex: 0, itemCount: newPageItemCount});
    setInternalPageIndex(0);
    setInternalPageItemCount(newPageItemCount);
  };

  const goToPage = (newPageIndex: number) => {
    onPaginationChange?.({pageIndex: newPageIndex, itemCount: internalPageItemCount});
    setInternalPageIndex(newPageIndex);
  };

  const pageCountSelectOptions = pageSelectOptions.map(ps => ({value: ps, label: ps}));
  const pageCountSelectValue = pageCountSelectOptions.find(option => option.value === internalPageItemCount) || pageCountSelectOptions[0];

  const slicedValues = useMemo(() => {
    return chunk(data, internalPageItemCount);
  }, [data, internalPageItemCount]);

  if (!showPaginator || !!pagination) return {data, internalPaginatorAttr: null};

  const visibleData = slicedValues[internalPageIndex] || [];

  return {
    internalPaginatorAttr: {
      canPreviousPage,
      previousPage,
      canNextPage,
      nextPage,
      setPageCount,
      goToPage,
      pageIndex: internalPageIndex,
      pageItemCount: internalPageItemCount,
      totalPages: internalTotalPages,
      pageCountSelectOptions,
      pageCountSelectValue,
    },
    data: visibleData,
  };
};

export default useInternalPagination;
