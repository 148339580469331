export enum RouterStateMode {
  'EDIT_SKU' = 'editSku',
  'REPLACE_SKU' = 'replaceSku',
}

export type RouterWithStateModeId = {
  mode: RouterStateMode;
  id: number;
  index: number;
};

export enum RouterStateBookingTypeMode {
  'FOLLOWUP' = 'followup',
}
