import React from 'react';
import {CalloutBox, CalloutBoxThemes} from 'ht-styleguide';
import {useSelector} from 'hooks/useAppSelector';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {pluralize} from 'utils/text';
import mduProjectsSlice from '../../MDU.ducks';
import {ProjectsPagination, TPagination, UnitsPagination} from '../../MDU.types';

type TCalloutBoxTableInfoProps = {
  isJobs?: boolean;
  rowsLength: number;
  pagination: TPagination;
};

const CalloutBoxTableInfo = ({isJobs = false, rowsLength, pagination}: TCalloutBoxTableInfoProps) => {
  /* Hooks */
  const dispatch = useAppDispatch();

  /* Selectors */
  const totalSelected = useSelector(mduProjectsSlice.selectors.getPaginationTotalSelected);
  const isSelectAll = useSelector(mduProjectsSlice.selectors.getBulkActionsSelectAll);
  const itemsPerPage = useSelector(mduProjectsSlice.selectors.getPaginationItemsPerPage);
  const getBulkActionsShowBanner = useSelector(mduProjectsSlice.selectors.getBulkActionsShowBanner);
  const getBulkActionIdsByPage = useSelector(mduProjectsSlice.selectors.getBulkActionIdsByPage);

  /* Constants */
  const type = isJobs ? 'job' : 'project';
  const totals = isJobs ? (pagination as UnitsPagination).total_units : (pagination as ProjectsPagination).total_projects;
  const pageSelected = getBulkActionIdsByPage.length;
  const countOfSelectedOverride = pageSelected === rowsLength;
  const totalNotGreaterThanSelected = totals <= pageSelected;

  /* Methods */
  const clearAllSelections = () => {
    if (!isJobs) {
      // temp check
      dispatch(mduProjectsSlice.actions.updateClearBulkFlatRows({}));
    } else {
      dispatch(mduProjectsSlice.actions.updateClearAllBulkOperation({}));
    }
  };

  const selectAllSelections = () => {
    dispatch(mduProjectsSlice.actions.updateSelectAllBulkOperation({selectAll: true}));
  };
  /* Components */

  const ButtonDisplay = ({text = 'Clear Selections', fn = clearAllSelections}) => {
    return (
      <button type="button" className="plainButton p2 text-link text-weight-book" onClick={fn}>
        <span>{text}</span>
      </button>
    );
  };
  const SelectAllDisplay = () => {
    return (
      <>
        <div>
          All{' '}
          <strong>
            {totalSelected} {type}s
          </strong>{' '}
          are selected.
        </div>
        <div>
          <ButtonDisplay />
        </div>
      </>
    );
  };

  const PartialSelectionDisplay = () => {
    const text = `Select all ${totals} ${pluralize(type, totals)}`;
    const fn = selectAllSelections;
    const suppressSelectAllButton = itemsPerPage >= totals;

    return (
      <>
        <div>
          All{' '}
          <strong>
            {rowsLength} {pluralize(type, rowsLength)}
          </strong>{' '}
          on this page are selected. {!suppressSelectAllButton && !totalNotGreaterThanSelected && <ButtonDisplay text={text} fn={fn} />}
        </div>
        <div>
          <ButtonDisplay />
        </div>
      </>
    );
  };

  const displayText = () => {
    switch (true) {
      case isSelectAll:
        return <SelectAllDisplay />;
      case totalSelected > 0:
        return <PartialSelectionDisplay />;
      case totalSelected <= 0:
      default:
        return null;
    }
  };

  if (totalSelected <= 0 || (!getBulkActionsShowBanner && !countOfSelectedOverride)) return null;

  return (
    <div className="paddingBottom-tiny">
      <CalloutBox theme={CalloutBoxThemes.PRIMARY} text="">
        <div className="flex justify-content-space-between paddingBottom-tiny1">{displayText()}</div>
      </CalloutBox>
    </div>
  );
};

export default CalloutBoxTableInfo;
