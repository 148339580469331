import React, {useMemo} from 'react';

// Components
import YARLightbox, {Plugin} from 'yet-another-react-lightbox';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

// Types
import {HTLightboxProps} from './types';

// Styles
import './styles.scss';

/**
 * The component is not visible by default. You need to set `open` to `true` in order to show the lightbox.
 */
const HTLightbox: React.FC<React.PropsWithChildren<HTLightboxProps>> = ({onClose, showThumbnails = false, ...rest}) => {
  const plugins: Plugin[] = useMemo(() => {
    const memo = [];
    memo.push(Fullscreen as Plugin);
    memo.push(Captions as Plugin);
    memo.push(Zoom as Plugin);
    if (showThumbnails) memo.push(Thumbnails as Plugin);
    return memo;
  }, [showThumbnails]);

  // @ts-ignore
  return <YARLightbox carousel={{finite: true}} close={onClose} plugins={plugins} zoom={{maxZoomPixelRatio: 10}} {...rest} />;
};

export default React.memo(HTLightbox);
