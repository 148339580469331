import React, {memo} from 'react';
import {useSelector} from 'react-redux';
import {LoaderComponent} from 'ht-styleguide';
import {selectors as requestLoaderSelectors} from 'features/App/RequestLoader/requestLoader.ducks';
import styles from './requestLoader.styles.scss';

type RequestLoaderType = {
  primary?: true;
};

/**
 * Primary will denote a different spinner type. We could config this out, but at this time,
 * can't imagine having more than two spinners.
 *
 * @param primary
 * @constructor
 */
const RequestLoader: React.FC<RequestLoaderType> = ({primary = false}) => {
  const isLoading = useSelector(requestLoaderSelectors.getLoadingState);

  return isLoading ? (
    <div className={styles.requestLoader}>
      <LoaderComponent primary={primary} />
    </div>
  ) : null;
};

export default memo(RequestLoader);
