import {CustomFilterTypes, MDUProjectTypeSlugs, MDUStatuses, NavPageTypes, TFilterKey, PausedStatusTypes} from 'features/MultiDwellingUnits/MDU.types';
import {SelectOption} from 'features/MultiDwellingUnits/Parts/FormFields/FormFields.types';

export enum FlaggedEnums {
  ONLY_FLAGGED = 'only_flagged',
  NON_ONLY_FLAGGED = 'only_non_flagged',
  TRUE = 'true',
  FALSE = 'false',
}

export interface IMDUProjectsFilters {
  statuses?: MDUStatuses[];
  propertyOwnerId?: number;
  propertyOwnerName?: number;
  leadTechId?: number;
  partnerId?: number;
  projectManagerId?: number;
  myProjects?: boolean;
  startDate?: {
    from: string;
    to: string;
  };
  estimatedEndDate?: {
    from: string;
    to: string;
  };
  approvedAt?: {
    from: string;
    to: string;
  };
  lastModifiedAt?: {
    from: string;
    to: string;
  };
  flagged?: FlaggedEnums;
  only_flagged?: FlaggedEnums;
  search?: string;
  project_group_ids?: string[];
  has_open_issues?: boolean;
  project_types?: MDUProjectTypeSlugs[];
  paused?: PausedStatusTypes | undefined | null;
}

export interface IMDUProjectsFiltersProps {
  pageType: NavPageTypes | CustomFilterTypes;

  /**
   * Specify which filters fields to render in the modal.
   */
  filterKeys?: TFilterKey[];
  /* Silly design of multiple views of status dropwdown. Need to handle */
  statusSelectTypeMultiple?: boolean;
  /*  If we want to have filtering applied to a unique page outside of all/own/archive, notifier receiver and pass in key for pageType */
  customFilterKey?: CustomFilterTypes;
  /* There could be cases where we might need unit statuses or a subset of statuses */
  customStatuses?: Array<SelectOption>;
  /* This will add a search attribute */
  isJobs?: boolean;
}

export type FilterChip = {
  filter: any;
  label: string;
  onClear: BaseAnyFunction;
  className?: string;
};

export interface IEditProjectsLink {
  pageType: IMDUProjectsFiltersProps['pageType'];
}
