import useApi from 'hooks/useApi';
import APIS from 'global/apis';
import {useMutation, useQueryClient} from 'react-query';
import {useInvalidateProjectsQuery} from 'features/MultiDwellingUnits/MDU.hooks';
import {TCreateIssuePayload} from 'features/Issues/Parts/SideSheets/SideSheet.Issue.types';
import {ISSUE_ENTITY_TYPES} from 'features/Issues/Issues.constants';
import {ISSUES_QUERY_KEYS} from './Issues.query.keys';
import {TField} from '../issues.types';
import {normalizeValuesForFields} from '../Parts/SideSheets/Parts/SideSheet.Issue.dataFormatting.Helpers';
import {invalidateTableQueries} from './issues.helpers';

/**
 * @description Update ticket issue
 */
export const useUpdateIssueMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const {invalidateProjectDataWithIssues} = useInvalidateProjectsQuery();

  return useMutation(
    async (issue: TCreateIssuePayload & {ticket_id: string | number}) => {
      const {ticket_id, ...rest} = issue;

      api.toggleLoader(true);
      const response = await APIS.issues.updateIssue({ticket_id}, rest);
      api.toggleLoader(false);
      if (response.status >= 400) throw new Error(`error creating issue: ${response.statusText}`);
      return response;
    },
    {
      onSuccess: async ({data}) => {
        const {ticket} = data;
        const {id: ticketId, entity_type, entity_id} = ticket || {};

        if (ticketId) {
          /*
             UPDATE fields data with the response from the ticket response.
             This is necessary because the response from the create issue endpoint and fields work together to
             tell us what the display values are for the fields & their respective values.
             It also prevents us from having to refetch the ticket fields data, which runs 3-5 seconds.
          */
          const currentFields: TField[] | undefined = queryClient.getQueryData(ISSUES_QUERY_KEYS.getTicketWithValues(ticketId));
          /* Move thru the fields data and assign the ticket values to the fields values */
          const updatedFields = currentFields?.map(field => {
            /* Get corresponding ticket key */
            const ticketValueByKey = ticket[field.name];
            /*
              Now we need to properly seed into the fields value. Slightly tricky as the values present themselves differently.
            */
            return {
              ...field,
              value: normalizeValuesForFields({value: ticketValueByKey, field}),
            };
          });

          /* Update with new ticket fields data. */
          queryClient.setQueryData(ISSUES_QUERY_KEYS.getTicketWithValues(ticketId), updatedFields);
          // Update specific ticket data
          queryClient.setQueryData(ISSUES_QUERY_KEYS.getTicket(ticketId), ticket);
          // invalidate table queries
          invalidateTableQueries(queryClient);
        }

        if (entity_type === ISSUE_ENTITY_TYPES.project && entity_id) {
          // refresh projects data as issue creation can affect project data
          await invalidateProjectDataWithIssues({projectId: entity_id});
        }
      },
    }
  );
};
