import React, {useState, useCallback, RefObject, useRef} from 'react';
import {useSelector} from 'react-redux';
import {HTDropzone} from 'components/Elements/HTDropzone';

/* Utils */
import {logger} from 'utils/logger';

/* Plugins */
import {$createParagraphNode, $getRoot, EditorState} from 'lexical';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {BeautifulMentionsPlugin, BeautifulMentionNode, ZeroWidthNode} from 'lexical-beautiful-mentions';
import LexicalClickableLinkPlugin from '@lexical/react/LexicalClickableLinkPlugin';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import {LinkPlugin} from '@lexical/react/LexicalLinkPlugin';
import {AutoLinkNode, LinkNode} from '@lexical/link';

/* Custom Plugins */
import {AutoLinkPlugin} from './plugins/AutoLink.Plugin';
import ToolbarMenuPlugin from './plugins/ToolbarMenu.Plugin';
import {StateSavePlugin} from './plugins/StateSave.Plugin';
import {EditorFooterPlugin} from './plugins/Footer.Plugin';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditor.Plugin';
import {LocalStoragePlugin} from './plugins/LocalStorage.Plugin';
import UpdateTextPlugin from './plugins/UpdateText.Plugin';
import {CommandPlugin} from './plugins/Command.Plugin';
import {AutoFocusPlugin} from './plugins/AutoFocus.Plugin';

/* Hooks & Ducks */
import EditorDuck from './state/editor.ducks';
import useCloudinaryHooks from 'features/Cloudinary/hooks/useCloudinary';
import {SharedHistoryContext, useSharedHistoryContext} from './context/EditorHistoryState';

/* Queries */
import {useGetUsersForMentions} from './queries/query.mentions';

/* Components */
import ToolbarCompactView from './Components/ToolbarCompactView';
import FileSelectionDisplay from './Components/FileDisplay/FileSelectionDisplay';

/* Constants */
import {STORAGE_TYPES} from './editor.schemas';

import {TEditor} from './editor.types';

/* Styles */
import styles from './editor.styles.scss';
import pluginStyles from './plugins/plugin.styles.scss';
import {FILE_UPLOAD_VALIDATIONS} from '../../utils/files/constants';

const EDITOR_NODES = [AutoLinkNode, LinkNode, BeautifulMentionNode, ZeroWidthNode];

function Editor<T>(props: TEditor<T>) {
  const {editorType, editorNamespace = '', entityAttributes} = props || {};
  const namespace = editorNamespace || editorType.namespace;
  const [editorViewable, setEditorViewable] = useState(false);
  const editorRef: RefObject<HTMLDivElement> = useRef(null);
  const reduxEditor = useSelector(EditorDuck.selectors.getEditorContentState(namespace));
  const content = editorType.storageType === STORAGE_TYPES.REDUX ? reduxEditor : localStorage?.getItem(namespace);

  /* This is the reduced view. If we have more than one, add it by type. */
  if (!editorViewable) {
    return <ToolbarCompactView setEditorViewable={setEditorViewable} localStorageContent={content} />;
  }

  return (
    <div className={styles.editorContainer} ref={editorRef}>
      <SharedHistoryContext>
        <LexicalEditor<T>
          entityAttributes={entityAttributes}
          actions={{
            setEditorViewable,
          }}
          config={{
            namespace,
            nodes: EDITOR_NODES,
            editorState:
              content ||
              (() => {
                /* For autoFocus to work, the editor needs to have a node present in the root. */
                const root = $getRoot();
                if (root.isEmpty()) {
                  const paragraph = $createParagraphNode();
                  root.append(paragraph);
                }
              }),
            theme: {
              link: 'cursorPointer',
              // Note: we could create another context and use that.
              attributes: {
                ...editorType,
                /* This allows for an override of a namespace */
                namespace,
              },
            },
            onError: error => {
              logger('Editor error')(error);
            },
          }}
        />
      </SharedHistoryContext>
    </div>
  );
}

export default Editor;

type LexicalEditorProps<T> = {
  config: Parameters<typeof LexicalComposer>['0']['initialConfig'];
  actions: {
    [key: string]: BaseAnyFunction;
  };
  entityAttributes: TEditor<T>['entityAttributes'];
};

export function LexicalEditor<T>(props: LexicalEditorProps<T>) {
  const {config, actions, entityAttributes} = props;
  const {historyState} = useSharedHistoryContext();
  const isEditable = true; // useLexicalEditable();
  const {uploadFiles, uploadedSuccessFiles, handleDeleteFile, totalSuccessFilesCount, handleDeleteAllFromCloudinary} = useCloudinaryHooks({namespace: config.namespace});
  const fileInputRef = useRef<HTMLInputElement>(null);

  /* Query */
  const {data} = useGetUsersForMentions();

  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);
  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChange = useCallback((editorState: EditorState) => {
    // not used
  }, []);

  const onDrop = (newFiles: File[]) => {
    uploadFiles(newFiles);
  };

  const onAttachAsset = () => {
    fileInputRef.current?.click();
  };

  return (
    <div id="editor-wrapper">
      <HTDropzone noClick onDrop={onDrop}>
        <LexicalComposer initialConfig={config}>
          <div className={styles.contentEditor}>
            <ToolbarMenuPlugin onAttachAsset={onAttachAsset} />
            {/* Official Plugins */}
            <RichTextPlugin
              contentEditable={
                <div className="editor" ref={onRef}>
                  <ContentEditable spellCheck={false} />
                </div>
              }
              placeholder={<Placeholder />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin externalHistoryState={historyState} />
            {/* Custom Plugins */}
            <AutoLinkPlugin />
            <LinkPlugin />
            <BeautifulMentionsPlugin items={{'@': data?.mentions ?? []}} allowSpaces menuAnchorClassName={pluginStyles.mentions} menuItemLimit={false} creatable={false} />
            {!isEditable && <LexicalClickableLinkPlugin />}
            {floatingAnchorElem && <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />}
            <UpdateTextPlugin />
            {config?.theme?.attributes.storageType === STORAGE_TYPES.LOCAL_STORAGE && <LocalStoragePlugin editorNamespace={config.namespace} />}
            {config?.theme?.attributes.storageType === STORAGE_TYPES.REDUX && <StateSavePlugin editorNamespace={config.namespace} />}
            <FileSelectionDisplay totalSuccessFilesCount={totalSuccessFilesCount} handleDeleteFile={handleDeleteFile} uploadedFiles={uploadedSuccessFiles!} />
            <OnChangePlugin onChange={handleChange} />
            <AutoFocusPlugin autoFocus />
          </div>
          <EditorFooterPlugin handleDeleteAllFromCloudinary={handleDeleteAllFromCloudinary} uploadedSuccessFiles={uploadedSuccessFiles} />
          <CommandPlugin<T> setEditorViewable={actions.setEditorViewable} entityAttributes={entityAttributes} />
        </LexicalComposer>
      </HTDropzone>

      <input
        type="file"
        ref={fileInputRef}
        style={{display: 'none'}}
        accept={FILE_UPLOAD_VALIDATIONS.ALLOWED_FILE_ACCEPT().join(',')}
        multiple
        onChange={e => {
          const {files} = e.target;
          if (files) {
            uploadFiles(files);
          }
        }}
      />
    </div>
  );
}

const Placeholder = () => {
  return <div />;
};
