import {EntityState} from '@reduxjs/toolkit';
import {IHash} from 'types/base.types';
import {CartState, Questions as CartQuestions} from 'features/Booking/Parts/Cart/cart.types';
import {BREAKDOWN_PRICE_TYPES} from 'features/Booking/Parts/Cart/cart.constants';

export interface AddSku {
  questions: EntityState<any>;
  skus: EntityState<any>;
  errors: QAErrors;
  selectedSku: SelectedSku;
}

export interface State {
  addSku: AddSku;
}

export enum CustomDropdownValues {
  negOne = -1,
  empty = '',
  zero = 0,
}

export type QAErrors = IHash<string> | IHash<IHash<string>>;
export type ErrorHashProp = IHash<string>;

type Partner = {
  partner_id: number;
  name: string;
} | null;

export type SelectedSkuQuestions = {
  [key: string]: any;
};

export type SelectedSku = {
  autoApplyCoupon: boolean | null;
  totalPrice: string;
  startsAtPrice: string | null;
  questions: SelectedSkuQuestions;
  partner: Partner;
  name: string;
  overrideBasePrice: number | null;
  skuId: number;
  lens: boolean;
  category: string;
  quantity: number;
  uuidDraft?: string;
  index?: number;
};

export type Answer = {
  adjAmount: number;
  adjAmountFormatted: AdjAmountFormatted | string | null;
  adjDescription: string;
  default: boolean;
  hasQuantity: boolean;
  id: number | string;
  text: string;
  warning: string | null;
  forbidRemote?: boolean;
  subsidizedToPartner?: number;
};

export type ModelAnswer = Answer & {
  productQuestion: ProductQuestion;
};

export type ProductQuestion = {
  id: number;
  text: string;
  answers: {
    id: number;
    name: string;
  }[];
};

export type InputType = QuestionTypes;

export enum QuestionTypes {
  Dropdown = 'dropdown',
  Checkbox = 'checkbox',
  Textarea = 'textarea',
  Input = 'input',
  Device = 'device',
}

export type EntityQuestions = {
  id: number | string;
  questions: QuestionsAPIByQuestion[];
};

export type QuestionsAPIByQuestion = {
  answers: Array<Answer | ModelAnswer>;
  hint: string | null;
  id: number | string;
  inputType: InputType;
  required: boolean;
  serviceId: number | null;
  textDirect: string;
};

export type QuestionsAPI = {
  data: {
    questions: QuestionsAPIByQuestion[];
  };
};

export type Sku = {
  subsidizingBasePriceOnly: Boolean;
  autoApplyCoupon: boolean | null;
  basePrice: number;
  id: number;
  lens: boolean;
  name: string;
  partner: Partner;
  priceType: keyof typeof BREAKDOWN_PRICE_TYPES;
  remote: boolean;
  remoteOnly: boolean;
  remoteSavings: number;
  seoName: string;
  skuBullets: {id: number; bullet: string}[];
  skuMessagings: {
    banner: string;
    disclaimer: string;
    bullets: string[];
  };
  startsAtPrice: string;
  remoteSavingsFormatted: boolean;
};

export type PostItem = {
  data: {
    price: string;
    questions: QuestionsAPIByQuestion[];
    breakdown: {
      amount_without_subsidy_formatted: string;
    };
  };
};

export type CheckBoxValue = Array<{
  id: number;
  quantity?: number;
}>;

export type SelectedSkuAsyncAction = {
  mode: string;
  cart: CartState;
  id: string | number;
  index: number;
  forcedEditMode?: boolean;
};

export type AdjAmountFormatted = {
  formatted: string;
  value: number;
};

export type QuestionDeviceProps = {
  question: QuestionsAPIByQuestion;
  sku: Sku;
  error: ErrorHashProp;
  value: string | number;
  modelValue: number | string;
  makeInputValue: string | null | undefined;
  modelInputValue: number | string;
  onMakeDropdownChange: BaseAnyFunction;
  onModelDropdownChange: BaseAnyFunction;
  onMakeInputChange: BaseAnyFunction;
  onModelInputChange: BaseAnyFunction;
  onIconClick: BaseAnyFunction;
};

/*
  For Hooks
*/
export type SameServiceExistsInCart = {
  selectedSku: SelectedSku;
  editingItemIndex: null | number;
  cart: CartState;
};

export type SameCartItemInfo = {
  cartItemQuantity: number | null | undefined;
  cartItemIndex: number | null | undefined;
};

export type ModifiedCartItemQuestions = CartQuestions & {remote: {id: string}};
