import React, {ReactNode, memo, useEffect, useRef, useState} from 'react';
import cn from 'classnames';
import {Button, Icon} from 'ht-styleguide';
import SearchInput from 'features/MultiDwellingUnits/Parts/SearchInput';
import TableToolbarLink from './TableToolbar.Link';
import styles from './tableToolbar.styles.scss';

type TTableToolbarProps = {
  className?: string;
  /**
   * A callback for clearing a filter. If no filterKey is passed, all filters will be cleared (the `Clear All` button was clicked)
   */
  clearFilter: (filterKey?: string | any) => void;
  dataTestId?: string;
  /**
   * Data used to display which filters were used. Data is displayed as chips.
   * Due to the way filters/rawFilters are stored and used, it is up to the dev to
   * generate the list of filtersChips. `filterKey` should match the key of the filter
   * used in `filters` as this key is used to clear the filter.
   */
  filtersChips: Array<{
    filterKey: string;
    label: string;
  }>;
  /**
   * Number of filters are that currently applied.
   */
  filtersCount: number | null;
  /**
   * The key of the filter that is used for searching. This is used to clear the search input.
   */
  searchFilterKey: string;
  /**
   * Value of the search input.
   */
  searchInputValue: string | null | undefined;
  /**
   * Called when the user enters text into the search input or clears the search input.
   */
  searchOnInputChange: (value: any) => void;
  searchPlaceholderText: string;
  /**
   * A callback for toggling a filters modal. It is up to the dev to create the filters modal.
   */
  toggleFiltersModal: (value: boolean) => void;
  /**
   * A ReactNode of TableToolbarLink(s) that will be rendered in the right side of the toolbar and next to the Filters link. Use `<TableToolLink />` for the link component.
   */
  ToolbarLinks?: ReactNode;
};

/**
 * A dumb component that renders a search input and a filters link. Primarily used with
 * `<DataTable />`.
 */
const TableToolbar = ({
  className = '',
  clearFilter,
  dataTestId = 'TableToolbar',
  filtersChips = [],
  filtersCount,
  searchInputValue = '',
  searchOnInputChange,
  searchPlaceholderText = 'Search',
  searchFilterKey,
  ToolbarLinks,
  toggleFiltersModal,
}: TTableToolbarProps) => {
  const [searchFocus, setSearchFocus] = useState(false);
  const [showFilterChipsDisplay, setShowFilterChipsDisplay] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!filtersCount || filtersCount === 0) {
      setShowFilterChipsDisplay(false);
    }
  }, [filtersCount]);

  const hasSearchInputValue = searchInputValue && searchInputValue.length > 0;
  const onInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => searchOnInputChange?.(evt.target.value);
  const onInputFocus = () => setSearchFocus(true);
  const onInputBlur = () => setSearchFocus(false);
  const onApplyFilter = () => toggleFiltersModal(true);

  const onClearFilter = (filterKey?: string) => () => {
    clearFilter(filterKey);
  };

  const onSearchInputClose = () => {
    if (searchInputRef.current) {
      searchInputRef.current.value = '';
      searchOnInputChange('');
    }
    clearFilter(searchFilterKey);
  };

  const filtersCountCopy = filtersCount && Number.isInteger(filtersCount) && filtersCount > 0 ? ` (${filtersCount})` : '';
  const filterAction = () => {
    if (!filtersCountCopy) {
      toggleFiltersModal(true);
    } else {
      setShowFilterChipsDisplay(!showFilterChipsDisplay);
    }
  };

  return (
    <div className={cn('flex justify-content-space-between flex-direction-column border-bottom paddingY-small1', className)} data-testid={dataTestId}>
      <div className="flex justify-content-space-between">
        <SearchInput
          focus={searchFocus}
          value={searchInputValue}
          inputPlaceholder={searchPlaceholderText}
          inputRef={searchInputRef}
          onInputIconClick={onSearchInputClose}
          onInputChange={onInputChange}
          onInputFocus={onInputFocus}
          onInputBlur={onInputBlur}
          {...(hasSearchInputValue && {inputOuterContainerClass: 'border-right'})}
        />
        <div className="flex flex-direction-row">
          {ToolbarLinks}
          <TableToolbarLink onClick={filterAction} iconName="filter" className={cn(showFilterChipsDisplay && styles.filterLinkActive)} label={`Filters${filtersCountCopy}`} />
        </div>
      </div>
      {showFilterChipsDisplay && filtersChips.length > 0 && (
        <div className={cn('flex flex-direction-row justify-content-space-between marginTop-small', styles.chipDisplayContainer)}>
          <div className="padding-small">
            {filtersChips.map(({filterKey, label}) => {
              return (
                <button type="button" onClick={onClearFilter(filterKey)} className={cn('plainButton', styles.filterChip)} key={`${filterKey}-${label}`}>
                  <p className="p2 n800 font-weight-medium">{label}</p>
                  <Icon name="v2-close-icon" className={styles.filterChipCloseIcon} />
                </button>
              );
            })}
            <button className="plainButton nowrap" onClick={onClearFilter()} type="button">
              <p className="text-link small-link">Clear All</p>
            </button>
          </div>
          <div className={cn('flex padding-small', styles.applyFiltersContainer)}>
            <Button onClick={onApplyFilter} icon="v2-plus-icon" className={cn(styles.applyFiltersCtaButton)} theme="secondary" small inlineBlock>
              Apply Filters
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(TableToolbar);
