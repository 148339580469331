/* eslint-disable camelcase */
import React, {Component} from 'react';
import omit from 'lodash/omit';
import trim from 'lodash/trim';
// Utils
import {Smarty} from 'utils/address';
// Components & Styles
import {InputField} from 'ht-styleguide';
import PlacesAutocompleteBasic, {AutoCompleteOptions} from './Parts/PlacesAutocompleteBasic';
import ConfirmAddressModal from './Parts/ConfirmAddress.modal';

type PlaceResult = {
  address_components: any[];
};

type AddressAutoCompleteProps = {
  onChange: (arg: {address: string}) => void;
  input?: {};
  options?: AutoCompleteOptions;
  value?: string;
  clearItemsOnError?: any;
  forceItemSelection?: boolean;
  autocompleteItem?: any;
  className?: string;
  dataTestId?: string;
};

type State = {
  addressProxy: string;
  clearPlacesInput: boolean;
  setPlacesInput: string;
  modalIsVisible: boolean;
};

type DefaultProps = {
  input: {};
  options: AutoCompleteOptions;
};
export default class AddressAutocomplete extends Component<AddressAutoCompleteProps, State> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps: DefaultProps = {
    input: {},
    options: {
      types: ['address'],
      componentRestrictions: {country: 'us'},
    },
  };

  placeService: any;

  constructor(props: AddressAutoCompleteProps) {
    super(props);
    this.state = {
      addressProxy: '',
      clearPlacesInput: false,
      setPlacesInput: '',
      modalIsVisible: false,
    };
    this.placeService = new google.maps.places.PlacesService(document.createElement('div'));
  }

  onSelect = (address: string, placeId: string) => {
    const {onChange} = this.props;
    if (onChange) {
      this.setState(
        {
          addressProxy: address,
        },
        () => {
          if (placeId) {
            this.placeService.getDetails({placeId}, (placeResult: PlaceResult) => {
              /**
               * When an address has an unverified type, it does not return that attribute from placeResult (eg: administrative_area_level_1).
               * Therefore, in getAddressComponent method, result (placeResult) is empty string, and the function returns empty string.
               * To circumvent this issue, the solution is to get alternate 'types' from placeResult.
               * After testing multiple 'unverified' addresses, it seems like only the locality, administrative_area_level_1, & zip return null values.
               */
              const streetNumber = this.getAddressComponent(placeResult, 'street_number');
              const streetName = this.getAddressComponent(placeResult, 'route');
              const city = this.getAddressComponent(placeResult, 'locality') || this.getAddressComponent(placeResult, 'sublocality');
              const state = this.getAddressComponent(placeResult, 'administrative_area_level_1') || this.getAddressComponent(placeResult, 'administrative_area_level_2');
              const zip = this.getAddressComponent(placeResult, 'postal_code') || this.getAddressComponent(placeResult, 'country');
              const street = trim(`${streetNumber || ''} ${streetName || ''}`);
              const newAddress = `${street}, ${city}, ${state}, ${zip}`;
              onChange({address: newAddress});
              this.setAddressState(newAddress);
              new Smarty().geocode({street, city, state, zip}).then((candidates: any) => {
                if (!candidates.geo || candidates?.geo?.length === 0) {
                  this.openModal();
                }
              });
            });
          } else {
            this.openModal();
          }
        }
      );
    }
  };

  getAddressComponent = (placeResult: PlaceResult, type: string) => {
    const result = placeResult.address_components.find(c => c.types.includes(type));
    return result?.short_name ?? '';
  };

  getDefaultAutocomplete = () => {
    return ({formattedSuggestion}: {formattedSuggestion: {mainText: string; secondaryText: string}}) => (
      <p className="p1">
        <span className="text-weight-bold">{formattedSuggestion.mainText}</span> <span>{formattedSuggestion.secondaryText}</span>
      </p>
    );
  };

  getInput = () => {
    const {input} = this.props;
    return React.isValidElement(input) ? input : <InputField {...input} />;
  };

  setAddressState = (address: string) => {
    this.setState({setPlacesInput: address});
  };

  closeModal = () => this.setState({modalIsVisible: false});

  openModal = () => this.setState({modalIsVisible: true});

  clearAddress = () => {
    const {onChange} = this.props;
    if (onChange) onChange({address: ''});
    this.setState({clearPlacesInput: true}, () => {
      this.setState({clearPlacesInput: false});
    });
    this.closeModal();
  };

  keepAddress = () => {
    const {onChange} = this.props;
    const {addressProxy} = this.state;
    if (onChange) onChange({address: addressProxy});
    this.closeModal();
  };

  render() {
    const {clearPlacesInput, setPlacesInput, modalIsVisible, addressProxy} = this.state;
    const {onChange, autocompleteItem, className} = this.props;
    const propsToRender = omit(this.props, ['label', 'autocompleteItem', 'options', 'onChange']);
    const autocompleteItemProp = autocompleteItem || this.getDefaultAutocomplete();
    const input = this.getInput();
    const {options, dataTestId} = this.props;
    return (
      <div className={className} data-testid={dataTestId}>
        <PlacesAutocompleteBasic
          autocompleteItem={autocompleteItemProp}
          inputElement={input}
          options={options}
          onChange={onChange}
          onSelect={this.onSelect}
          clearInput={clearPlacesInput}
          setInput={setPlacesInput}
          {...propsToRender}
        />
        <ConfirmAddressModal isVisible={modalIsVisible} hide={this.closeModal} onConfirm={this.keepAddress} onCancel={this.clearAddress} address={addressProxy} />
      </div>
    );
  }
}
