import React, {useRef, useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import cn from 'classnames';
import {Panel, Button, BUTTON_THEMES, InputField, ELEMENT_SIZE, Icon, CalloutBoxThemes, CalloutBox} from 'ht-styleguide';
import {mduProjectPageGroupServicesPath} from 'global/paths';

/* Utils */
import {filterTextFromNumberInputs} from 'utils/form';
import {noop, stopImmediatePropagation, stopPropagationOnDiv} from 'utils/event';

/* Components */
import {MenuListItem, MenuPanel, MenuUnorderedList} from 'components/Elements/Menu';
import PageHeader from 'components/Elements/PageHeader';
import ProjectLevelUnitUpdate from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/Parts/TemplateJobs.ProjectUnit';
import DeleteGroupModal from 'features/MultiDwellingUnits/Parts/Modals/MDU.Modal.DeleteGroupModal';
import GroupNameEdit from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/Parts/TemplateJobs.EditName';

/* Hooks */
import {useMduApi} from 'features/MultiDwellingUnits/MDU.hooks';
import useOutsideClick from 'hooks/useOutsideClick';
import {useDebounceUnitQty} from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/TemplateJobs.hooks';
import {useCurrentProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/CurrentProject.hooks';

import {MduUseParamsTypes, ProjectGroup} from 'features/MultiDwellingUnits/MDU.types';

import styles from '../templatejobs.styles.scss';

/* Text display for error conditions in unit count */
const errorUnitText = (unitCount: number) => ({
  tooMany: {
    header: 'Too Many Units',
    text: `${unitCount} ${unitCount > 1 ? 'extra units allocated' : 'extra unit allocated'}`,
  },
  tooFew: {
    header: 'Not Enough Units',
    text: `${unitCount} ${unitCount > 1 ? 'units are not allocated' : 'unit is not allocated'}`,
  },
});

/* Inline Types */
type TGroupId = number | string | null;
type TonHandleInputTemplate = {
  currentQty: string | number | null | undefined;
  groupId: string | number;
};

/**
 * - Display a list of templates for a project
 *   - Control the qty of units for each template
 * - Display total units for the project
 *   - Control total units for the project
 * - Be able to add a new template
 */
const TemplatesAndJobsPreLaunchTemplates = () => {
  /* Local State */
  const [activeGroupId, setActiveGroupId] = useState<TGroupId>();
  const [activeGroupMenuId, setActiveGroupMenuId] = useState<TGroupId>();

  /* Hooks */
  const api = useMduApi();
  const navigate = useNavigate();
  const groupMenuRef: React.RefObject<HTMLDivElement> = useRef(null);
  const {projectId = '', stage = ''} = useParams<MduUseParamsTypes>();
  const {projectGroups, unitsNumber, usedUnits} = useCurrentProject();
  const {updateUnitQtyTemplate, updateUnitQtyProject} = useDebounceUnitQty();

  useOutsideClick(groupMenuRef, () => (activeGroupMenuId ? setActiveGroupMenuId(null) : setActiveGroupId(null)), [activeGroupMenuId]);

  /* ------- METHODS --------- */
  const onHandleCreateTemplate = () => {
    api.addGroup(projectId, 0);
  };

  const onHandleDeleteTemplate = (projectGroupId: string) => () => {
    api.deleteGroup({projectId, projectGroupId});
  };

  const onHandleInputTemplate = (e: React.ChangeEvent<HTMLInputElement>, {currentQty, groupId}: TonHandleInputTemplate) => {
    const newInput = e.target.value;
    updateUnitQtyTemplate({quantity: newInput, currentQty, groupId});
  };

  const onHandleInputTotal = (inputValue: string) => {
    updateUnitQtyProject(inputValue, unitsNumber);
  };

  const onHandleClickTemplate = (group: ProjectGroup) => () => {
    navigate(mduProjectPageGroupServicesPath(projectId, stage, group.id as string));
  };

  const TotalDisplay = () => {
    const isInError = (() => {
      const unitCount = unitsNumber - usedUnits;
      switch (true) {
        case unitCount > 0:
          return errorUnitText(Math.abs(unitCount)).tooFew;
        case unitCount < 0:
          return errorUnitText(Math.abs(unitCount)).tooMany;
        default:
          return false;
      }
    })();
    const errorClass = cn({
      alertRed700: isInError,
    });

    return {
      Text: <span className={errorClass}>{usedUnits}</span>,
      Error: isInError && (
        <div>
          <CalloutBox header={isInError.header} text={isInError.text} className="marginBottom-medium" theme={CalloutBoxThemes.CRITICAL} />
        </div>
      ),
    };
  };

  /*
   * After we "create" a new template we are put to this page as this is the templates page.
   * Problem arise because we do not want to have any templates sitting in this que unless they've
   * added services. So, Assuming the "new" flow, we land on this page with a template with no services,
   * we jump to that services page. Old projects "might" have tempaltes w/o services.
   * */
  useEffect(() => {
    /* Iterate thru groups. Find first one with no services length */
    const findEmptyProjectServices = projectGroups.find((group: ProjectGroup) => (group.projectServices ?? []).length === 0);

    if (findEmptyProjectServices) {
      onHandleClickTemplate(findEmptyProjectServices)();
    }
  }, [projectGroups]);

  return (
    <div className="marginBottom-medium2">
      <PageHeader
        title="Templates & Jobs"
        HeaderRightContent={<ProjectLevelUnitUpdate inputValue={unitsNumber} text={<>Unit Count&nbsp; &nbsp;{TotalDisplay().Text}&nbsp;/</>} valueSetter={onHandleInputTotal} withIcon />}
      />
      {TotalDisplay().Error}
      <div className="flex flex-direction-row justify-content-space-between paddingBottom-medium">
        <div className="h5 n700">Job Templates</div>
        <div>
          <Button theme={BUTTON_THEMES.SECONDARY} onClick={onHandleCreateTemplate} small>
            <Icon name="v2-plus-icon" />
            &nbsp;&nbsp;Add New Template
          </Button>
        </div>
      </div>
      {projectGroups?.map((group: ProjectGroup) => {
        return (
          <Panel key={group.id} noShadow largeBorderRadius className={cn(styles.panel, 'padding-small2 marginBottom-small')}>
            <div className="paddingBottom-small2 flex flex-direction-row justify-content-space-between cursorPointer" onClick={onHandleClickTemplate(group)}>
              <GroupNameEdit group={group} titleStyles="h6" iconStyles={styles.editIconSmall} />
              <div className="position relative">
                <div ref={groupMenuRef} onClick={stopPropagationOnDiv}>
                  <Icon name="more" className={styles.icon} onClick={() => setActiveGroupMenuId(group.id)} />
                </div>
                {activeGroupMenuId === group.id && (
                  <MenuPanel>
                    <MenuUnorderedList>
                      <MenuListItem
                        dataTestId="menu-edit-job-info"
                        onClick={e => {
                          stopImmediatePropagation(noop)(e);
                          setActiveGroupId(group.id);
                        }}
                      >
                        Delete Template
                      </MenuListItem>
                    </MenuUnorderedList>
                  </MenuPanel>
                )}
              </div>
            </div>
            <div className="flex flex-direction-row justify-content-space-between cursorPointer" onClick={onHandleClickTemplate(group)}>
              <div className="flex flex-direction-column" onClick={stopPropagationOnDiv}>
                <div>
                  <InputField
                    type="number"
                    onKeyDown={filterTextFromNumberInputs}
                    elementSize={ELEMENT_SIZE.S}
                    defaultValue={group.unitsNumber}
                    onChange={e => onHandleInputTemplate(e, {groupId: group.id, currentQty: group.unitsNumber})}
                    containerClass={styles.inputContainer}
                    iconComponent={<div className="p2 n700">Units</div>}
                  />
                </div>
              </div>
              <div>{group.perUnitTotalWithoutSubsidyFormatted} per unit</div>
            </div>
          </Panel>
        );
      })}
      <DeleteGroupModal isVisible={Boolean(activeGroupId)} hide={() => setActiveGroupId(null)} deleteGroup={onHandleDeleteTemplate(String(activeGroupId))} header="Delete Template" />
    </div>
  );
};

export default TemplatesAndJobsPreLaunchTemplates;
