import {CalloutBoxThemes} from 'ht-styleguide';
import {MDUStatuses} from './MDU.types';

export const STATUSES_PRE_LAUNCH: MDUStatuses[] = [MDUStatuses.DRAFT, MDUStatuses.READY];
export const STATUSES_POST_LAUNCH: MDUStatuses[] = [MDUStatuses.NEEDS_APPROVAL, MDUStatuses.IN_PROGRESS, MDUStatuses.UPCOMING, MDUStatuses.APPROVED];
// Pausing a project is allowed for these statuses
export const STATUSES_PAUSE: MDUStatuses[] = [MDUStatuses.UPCOMING, MDUStatuses.IN_PROGRESS, MDUStatuses.NEEDS_APPROVAL];

export const MDU_ROUTES = {
  MDUPathProjectBase: '/mdu/project',
  MDUPathProjectsBase: '/mdu/projects',

  ProjectsPathBase: `/mdu/projects/:projectsType`, // `projectsType` is a `NavType`
  ProjectPathBase: `/mdu/project/:projectId/:stage`,
};

/* test constants */
export const EMPTY_STATE_TEST_ID = 'EMPTY_STATE_TEST_ID';
export const CREATE_PROJECT_MODAL_TEST_ID = 'CREATE_PROJECT_MODAL_TEST_ID';

// File Uploads

// Map Statuses to theme calloutbox statuses
export const CallOutBoxStatus = {
  cancelled: CalloutBoxThemes.CRITICAL,
  unserviceable: CalloutBoxThemes.CRITICAL,
  completed: CalloutBoxThemes.PRIMARY,
  not_started: CalloutBoxThemes.DEFAULT,
  pending_approval: CalloutBoxThemes.DEFAULT,
  needs_approval: CalloutBoxThemes.DEFAULT,
  in_progress: CalloutBoxThemes.DEFAULT,
  flagged: CalloutBoxThemes.CRITICAL,
  paid: CalloutBoxThemes.SUCCESS,
};

export const DEFAULT_UNIT_NAME = 'this job';

export const PROJECT_STARTED_TOOLTIP_TEXT = 'The start date can no longer be changed once work has begun.';
