import React from 'react';
import cn from 'classnames';
// Utils && Hoks
import {useIsImageLoading, useThumbnailTooltip} from './thumbnail.display.hooks';
import {truncateLongFilename} from 'features/MultiDwellingUnits/MDU.utils';
import {noop} from 'utils/event';
import useDoesImageExist from 'hooks/useDoesImageExist';
import {getImageElementConfig, isVideoFileType} from './thumbnail.display.utils';
// Types
import {TThumbnailProps} from './thumbnail.types';
import {THUMBNAIL_TEST_IDS} from './thumbnail.constants';
// Components
import styles from './thumbnailDisplay.styles.scss';
import {Icon, LoaderComponent} from 'ht-styleguide';
import ThumbnailTooltip from './Thumbnail.Tooltip';
import {ThumbnailActionButtons} from './Thumbnail.ActionButtons';

export const TEST_IDS = {
  DOWNLOAD_BUTTON: 'thumbnail-download-button',
  DELETE_BUTTON: 'thumbnail-delete-button',
  THUMBNAIL: 'thumbnail',
  LOADING_INDICATOR: 'thumbnail-loading-indicator',
};

/** char length 18 is an eyeballed value that looks good */
export const MAX_FILENAME_LENGTH = 18;

export const Thumbnail = React.memo(({useFileDimensions = false, file, className, tooltipPortalElement, suppressNameplate = false, thumbnailClassName}: TThumbnailProps) => {
  const {ref, tooltipVisible, tooltipPosition, handleMouseEnter, handleMouseLeave} = useThumbnailTooltip();
  const {isImageLoading, handleImageLoad} = useIsImageLoading();
  const imageExists = useDoesImageExist(file?.url || '');

  if (!file) return null;

  const {name, showDownloadButton, showDeleteButton, onDownloadClick = noop, onDeleteClick = noop, onThumbnailClick = noop} = file;
  const {imgSrc, altText, imgClass, transformedFile} = getImageElementConfig(file);
  const isVideo = isVideoFileType(file.file_type);

  const handleDownloadClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDownloadClick(file);
  };

  const handleDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDeleteClick(file);
  };

  const handleThumbnailClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onThumbnailClick(file);
  };

  // Style declarations
  const customThumbnailStyles = {
    width: useFileDimensions && file.width ? `${file.width}px` : undefined, // Leave undefined to use the CSS default
    height: useFileDimensions && file.height ? `${file.height}px` : undefined // Leave undefined to use the CSS default
  };
  const overlayStyles = cn(styles.thumbnailOverlay, {
    [styles.thumbnailOverlayVideo]: isVideo,
  });

  return (
    <div
      className={cn(styles.thumbnail, className, thumbnailClassName)}
      style={customThumbnailStyles}
      onClick={handleThumbnailClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={ref}
      data-testid={THUMBNAIL_TEST_IDS.THUMBNAIL}
    >
      <div className={styles.thumbnailContent}>
        {isVideo && <Icon name="play" className={styles.thumbnailPlayButtonIcon} />}
        {!imageExists && !transformedFile && (
          <div className={styles.thumbnailNonImageDisplay}>
            <Icon name="file" className={styles.icon} />
          </div>
        )}
        {isImageLoading && (
          <div data-testid={THUMBNAIL_TEST_IDS.LOADING_INDICATOR} className="fullheight fullwidth">
            <LoaderComponent containerClassName="fullheight fullwidth" />
          </div>
        )}
        {imageExists || transformedFile ? (
          <img src={transformedFile || imgSrc} alt={altText} className={imgClass} onLoad={handleImageLoad} />
        ) : (
          <img alt="placeholder" className={imgClass} onLoad={handleImageLoad} src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" />
        )}
        <div className={overlayStyles} />
        <ThumbnailActionButtons showDownloadButton={showDownloadButton} showDeleteButton={showDeleteButton} handleDownloadClick={handleDownloadClick} handleDeleteClick={handleDeleteClick} />
      </div>

      {!suppressNameplate && (
        <div className={cn(styles.thumbnailFileName, 'caption')} data-testid="thumbnail-filename">
          {truncateLongFilename(name, MAX_FILENAME_LENGTH)}
        </div>
      )}

      <ThumbnailTooltip visible={tooltipVisible} position={tooltipPosition} portalElement={tooltipPortalElement}>
        {name}
      </ThumbnailTooltip>
    </div>
  );
});
