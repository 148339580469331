import dayjs from 'dayjs';
import dayjsUTC from 'dayjs/plugin/utc';
import dayjsTimezone from 'dayjs/plugin/timezone';
import {numericDayMonthYear} from 'global/constants/common';

dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsUTC);

/**
 * @param date Assumes date is in UTC
 * @param timezone
 * @returns dayjs object with timezone
 */
export const dayjsWithTimezone = (date: string, timezone: string) => dayjs(date).tz(timezone);

export const formatDate = (date: string) => dayjs(date).format(numericDayMonthYear);
