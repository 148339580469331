import React from 'react';

// Utils
import cn from 'classnames';
import {customerProfilePath} from 'global/paths';

// Components
import {Panel, Button, BUTTON_THEMES} from 'ht-styleguide';
import OrderLine from 'features/Profile/components/OrderLine';
import EmptyState from 'features/Profile/components/EmptyState';

// Constants
import {ORDERS_TABLE_HEADERS, SECTION_HEADERS, EMPTY_STATES} from 'features/Profile/profile.constants';

// Types
import {Order} from 'features/Profile/profile.types';

// Styles
import styles from 'features/Profile/profile.styles.scss';

interface OrderSectionProps {
  orders: Order[];
  clientId: number;
}

const OrdersSection = ({orders, clientId}: OrderSectionProps) => {
  if (!orders.length) return <EmptyState header={SECTION_HEADERS.EMPTY_ORDERS} text={EMPTY_STATES.EMPTY_ORDERS} clientId={clientId} />;

  return (
    <Panel className="padding-medium">
      <div className="paddingBottom-small2 flex justify-content-space-between">
        <h4>{SECTION_HEADERS.ORDERS}</h4>
        <a href={customerProfilePath(clientId)} target="_blank" rel="noreferrer">
          <Button small theme={BUTTON_THEMES.SECONDARY} inlineBlock>
            See All Orders
          </Button>
        </a>
      </div>

      <table className={styles.ordersTable}>
        <thead>
          <tr className={cn(styles.tableHeader)}>
            {ORDERS_TABLE_HEADERS.map(header => (
              <th key={header} className={styles.orderHeaders}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {orders
            .slice(-5)
            .reverse()
            .map(order => (
              <OrderLine key={order.id} order={order} />
            ))}
        </tbody>
      </table>
    </Panel>
  );
};

export default OrdersSection;
