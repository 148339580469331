import {QueryClient} from 'react-query';
import {BASE_ISSUES_QUERY_KEY, getIssues, getStats} from './Issues.query.keys';

/**
 * Invalidate all queries that are used in the Issues table and Stats header.
 */
export const invalidateTableQueries = (queryClient: QueryClient) => {
  queryClient.invalidateQueries({queryKey: [...BASE_ISSUES_QUERY_KEY, getIssues], exact: false});
  queryClient.invalidateQueries({queryKey: [...BASE_ISSUES_QUERY_KEY, getStats], exact: false});
};
