import {KeyboardEvent} from 'react';

export const filterTextFromNumberInputs = (event: Partial<React.KeyboardEvent<KeyboardEvent | HTMLInputElement>>) => {
  /*
    Restrict input to integers only. Per HTML spec the invalidChars are actually valid for uses
    such as exponents, e.g. 2e2
  */
  if (!event.key || !event.preventDefault) return;
  const invalidChars = ['-', '+', 'e'];
  if (invalidChars.includes(event.key)) {
    event.preventDefault();
  }
};
