import React from 'react';
import cn from 'classnames';
import {Icon, Panel} from 'ht-styleguide';
import {TDropzoneOverlayProps} from './HTDropzone.types';
import {DEFAULT_MAX_FILE_SIZE} from './htdropzone.constants';
import styles from './htdropzone.styles.scss';

export const TEST_ID = 'dropzone-overlay';

const HTDropzoneOverlay = ({isVisible, maxFileSize = DEFAULT_MAX_FILE_SIZE}: TDropzoneOverlayProps) => {
  return isVisible ? (
    <div className={styles.overlayContainer} data-testid={TEST_ID}>
      <Panel className={styles.panel} lowShadow largeBorderRadius>
        <Icon name="upload" className={cn(styles.icon, 'n900 marginBottom-small')} />
        <p className="p2 n900 marginBottom-tiny1 text-weight-med">Drag & Drop Files</p>
        <p className="p2 n700">{`${maxFileSize.maxSizeDisplayString} maximum`}</p>
      </Panel>
    </div>
  ) : null;
};

export default HTDropzoneOverlay;
