// Just pulling these images from the client's cdn for now

export default {
  bbb: 'https://cdn.hellotech.com/client-assets/bbb_f2e18d607d5dc840e830f5095e413eb5.svg',
  goDaddy: 'https://cdn.hellotech.com/client-assets/godaddy_93341fc9986428451e36a0817922da27.svg',
  stripe: 'https://cdn.hellotech.com/client-assets/stripe_02544c419aad924e75a0a498049b5bd0.svg',
  lock: 'https://cdn.hellotech.com/assets/payment-lock.svg',
  fileIcon: 'https://cdn.hellotech.com/assets/file-icon.png',
  spreadsheetIcon: 'https://cdn.hellotech.com/assets/spreadsheet-icon.png',
  imageIcon: 'https://cdn.hellotech.com/assets/image-icon.png',
};
