import {useState, useCallback} from 'react';
import omit from 'lodash/omit';
import compact from 'lodash/compact';
import {htToast} from 'ht-styleguide';
import useGetSkuTaskGroupsQuery from 'features/Products/queries/query.taskGroups.get';
import useMutateTaskChecklist from 'features/Products/queries/mutations.mutateTaskChecklist';
import useGetSkuQuery from 'queries/skus/query.sku.get';
import {TSkuChecklist, TUpsertTaskChecklist, TUpsertTaskFunction} from 'features/Products/types/taskGroups.types';
import {noop} from 'utils/event';
import {TASK_CHECKLIST_MODAL_VARIANTS, TASK_CHECKLIST_TOAST_MESSAGES} from '../TaskChecklist.constants';
import {getSuggestedGroupName, getInsertPositionForNewGroup} from '../TaskSideSheet/taskChecklist.utils';
import {TTaskChecklistModalVariants} from '../TaskSideSheet/taskChecklist.types';
import {TDeleteGroupFunction, TDeleteTaskFunction, TOpenEditTaskSideSheetFunction, TReorderTaskFunction, TReorderGroupFunction} from '../taskChecklist.types';

/**
 * The brains of the TaskChecklist page.
 *
 * - Fetch sku data and task groups data
 * - Control add/edit sidesheet
 * - Mutate the task checklist
 *   - Upsert/Delete a task
 *   - Upsert/Remove a task group
 *   - Grouping tasks
 *   - Reordering of tasks and task group
 */
const useTaskChecklistPage = ({skuId}: {skuId: number}) => {
  // Fetch sku data
  const {data: skuData} = useGetSkuQuery({id: skuId, useLoader: true});
  // Fetch task groups
  const {data: taskChecklistData, isError: isErrorGetTaskGroup} = useGetSkuTaskGroupsQuery({id: skuId, useLoader: true});
  // Mutate Checklist
  const {mutateAsync: mutateAsyncTaskChecklist} = useMutateTaskChecklist();

  // ################
  // TASK LOGIC
  // ################

  /**
   * ==== TaskSideSheet logic ====
   */
  const [sideSheetIsVisible, setSideSheetIsVisible] = useState(false);
  const [taskIDsForEdit, setTaskIDsForEdit] = useState<{taskGroupId: number; taskId: number} | null>(null);
  const openAddTaskSideSheet = () => {
    setTaskIDsForEdit(null);
    setSideSheetIsVisible(true);
  };

  const openEditTaskSideSheet: TOpenEditTaskSideSheetFunction = ({taskGroupId, taskId}) => {
    setTaskIDsForEdit({taskGroupId, taskId});
    setSideSheetIsVisible(true);
  };
  const closeTaskSideSheet = () => {
    setSideSheetIsVisible(false);
    setTaskIDsForEdit(null);
  };

  const taskToEdit = taskIDsForEdit ? taskChecklistData?.task_groups.find(group => group.id === taskIDsForEdit.taskGroupId)?.tasks.find(task => task.id === taskIDsForEdit.taskId) : null;

  /** Prepares a task checklist for mutation by removing fields that the BE doesn't need. */
  const prepareTaskChecklistForMutation = (taskChecklist: TSkuChecklist) => {
    return omit(taskChecklist, ['pagination']) as TUpsertTaskChecklist;
  };

  /**
   * ==== Mutation - Upsert Task ====
   *
   * For adding and editing a task - Modify the existing task checklist data by inserting a new task
   * or replacing an updated task.
   */
  const upsertTask: TUpsertTaskFunction = async (task, onSuccess) => {
    if (!taskChecklistData) return;

    const newTaskChecklist = prepareTaskChecklistForMutation(taskChecklistData);

    const updateExistingTask = () => {
      if (!taskIDsForEdit) return;
      const {taskGroupId, taskId} = taskIDsForEdit;
      const groupIndex = newTaskChecklist.task_groups.findIndex(group => group.id === taskGroupId);
      const taskIndex = newTaskChecklist.task_groups[groupIndex].tasks.findIndex(t => t.id === taskId);
      newTaskChecklist.task_groups[groupIndex].tasks[taskIndex] = task;
    };

    const addNewTaskToAppropriateGroup = () => {
      // Find all editable (unlocked) groups
      const editableTaskGroups = (newTaskChecklist?.task_groups ?? []).filter(group => !group.locked);
      const lastEditableGroupIndex = editableTaskGroups.length - 1;
      const newGroupName = getSuggestedGroupName(newTaskChecklist);

      if (lastEditableGroupIndex < 0) {
        /* No editable groups exist. Create a new one.
            New group position:
            • Multiple locked groups? Insert before the last one
            • Otherwise? Append to the end */
        const insertIndex = newTaskChecklist.task_groups.length > 1 ? newTaskChecklist.task_groups.length - 1 : newTaskChecklist.task_groups.length;
        newTaskChecklist.task_groups.splice(insertIndex, 0, {
          group_name: newGroupName,
          tasks: [task],
        });
      } else {
        // Add the task to the last editable group
        editableTaskGroups[lastEditableGroupIndex].tasks.push(task);
      }
    };

    const isEditMode = !!taskIDsForEdit;
    if (isEditMode) {
      updateExistingTask();
    } else {
      addNewTaskToAppropriateGroup();
    }

    await mutateAsyncTaskChecklist(
      {skuId, taskChecklist: newTaskChecklist},
      {
        onSuccess: () => {
          onSuccess();
          htToast(isEditMode ? TASK_CHECKLIST_TOAST_MESSAGES.TASK_UPDATED : TASK_CHECKLIST_TOAST_MESSAGES.TASK_ADDED);
        },
      }
    );
  };

  /**
   * ==== Mutation - Delete Task ====
   */
  const deleteTask: TDeleteTaskFunction = async ({taskGroupId, taskId, onSuccess = noop}) => {
    if (taskChecklistData) {
      const newTaskChecklist = prepareTaskChecklistForMutation(taskChecklistData);
      const groupIndex = newTaskChecklist.task_groups.findIndex(group => group.id === taskGroupId);
      newTaskChecklist.task_groups[groupIndex].tasks = newTaskChecklist.task_groups[groupIndex].tasks.filter(task => task.id !== taskId);

      await mutateAsyncTaskChecklist(
        {skuId, taskChecklist: newTaskChecklist},
        {
          onSuccess: () => {
            onSuccess();
            htToast(TASK_CHECKLIST_TOAST_MESSAGES.TASK_DELETED);
          },
        }
      );
    }
  };

  /**
   * ==== Mutation - Reorder Task ====
   */
  const reorderTask: TReorderTaskFunction = useCallback(
    async ({taskIdToMove, taskGroupIdToMove, targetTaskId, targetTaskGroupId, isBefore}) => {
      if (taskIdToMove === targetTaskId || !taskChecklistData) {
        return;
      }
      const newTaskChecklist = prepareTaskChecklistForMutation(taskChecklistData);
      const groupWithTaskToMove = newTaskChecklist.task_groups.find(group => group.id === taskGroupIdToMove);
      if (!groupWithTaskToMove) {
        return;
      }

      const taskToMoveIndex = groupWithTaskToMove.tasks.findIndex(task => task.id === taskIdToMove);
      const [taskToMove] = groupWithTaskToMove.tasks.splice(taskToMoveIndex, 1);

      // If the task was hovering over the task list and not the group name, `targetTaskId` is known.
      // Let's reorder within the task list.
      if (targetTaskId) {
        const groupWithTargetTask = newTaskChecklist.task_groups.find(group => group.id === targetTaskGroupId);
        if (!groupWithTargetTask || groupWithTargetTask.locked) {
          return;
        }
        // Place `taskToMove` before or after the target task
        const targetTaskIndex = groupWithTargetTask.tasks.findIndex(task => task.id === targetTaskId);
        const insertIndex = isBefore ? targetTaskIndex : targetTaskIndex + 1;
        groupWithTargetTask.tasks.splice(insertIndex, 0, taskToMove);
      } else {
        // If the task was hovering only the group name, `targetTaskId` is null. This means we only have
        // the targetTaskGroupId as the target reference, so we'll make a few assumptions. If `isBefore` is `true`, we'll believe
        // the intent is to place the task in the group before the target group. If not, we'll place the task
        // in the target group as the first task in the list.
        const targetGroupIndex = newTaskChecklist.task_groups.findIndex(group => group.id === targetTaskGroupId);
        const insertIndex = isBefore ? targetGroupIndex - 1 : targetGroupIndex;

        const groupToUse = newTaskChecklist.task_groups[insertIndex];
        if (!groupToUse || (groupToUse && groupToUse.locked)) {
          return;
        }

        if (isBefore) {
          newTaskChecklist.task_groups[insertIndex].tasks.push(taskToMove);
        } else {
          newTaskChecklist.task_groups[insertIndex].tasks.unshift(taskToMove);
        }
      }

      await mutateAsyncTaskChecklist(
        {skuId, taskChecklist: newTaskChecklist},
        {
          onError: () => {
            htToast(TASK_CHECKLIST_TOAST_MESSAGES.TASK_NOT_ORDERED);
          },
        }
      );
    },
    [mutateAsyncTaskChecklist, skuId, taskChecklistData]
  );

  // ################
  // GROUP LOGIC
  // ################

  const toggleGrouping = async ({shouldMergeTasks}: {shouldMergeTasks: boolean}) => {
    if (!taskChecklistData) return;

    const newTaskChecklist = prepareTaskChecklistForMutation(taskChecklistData);

    // #################
    // LIST MANIPULATION
    // #################

    // Per Product/Design, when toggling On to Off, merge all unlocked tasks into one group
    if (shouldMergeTasks) {
      let hasMergedTasks = false;
      newTaskChecklist.task_groups = compact(
        newTaskChecklist.task_groups.map(group => {
          if (group.locked) {
            return group;
          }
          if (!hasMergedTasks) {
            hasMergedTasks = true;
            const unlockedGroups = newTaskChecklist.task_groups.filter(g => !g.locked);
            return {...group, tasks: [...unlockedGroups.flatMap(ug => ug.tasks)]};
          }
          return null;
        })
      );
    }

    // ##########
    // MUTATION
    // ##########
    newTaskChecklist.grouping = !newTaskChecklist.grouping;

    await mutateAsyncTaskChecklist(
      {skuId, taskChecklist: newTaskChecklist},
      {
        onSuccess: data => {
          htToast(data.grouping ? TASK_CHECKLIST_TOAST_MESSAGES.GROUPING_ENABLED : TASK_CHECKLIST_TOAST_MESSAGES.GROUPING_DISABLED);
        },
      }
    );
  };

  const addGroup = async ({newGroupName, onSuccess = noop}: {newGroupName?: string; onSuccess?: () => void}) => {
    if (!taskChecklistData) return;
    const newTaskChecklist = prepareTaskChecklistForMutation(taskChecklistData);
    const suggestedGroupName = getSuggestedGroupName(newTaskChecklist);
    const insertPosition = getInsertPositionForNewGroup(newTaskChecklist);

    newTaskChecklist.task_groups.splice(insertPosition, 0, {group_name: newGroupName || suggestedGroupName, tasks: []});

    await mutateAsyncTaskChecklist(
      {skuId, taskChecklist: newTaskChecklist},
      {
        onSuccess: () => {
          onSuccess();
          htToast(TASK_CHECKLIST_TOAST_MESSAGES.NEW_GROUP_ADDED);
        },
      }
    );
  };

  const editGroup = async ({taskGroupId, newGroupName, onSuccess = noop}: {taskGroupId: number; newGroupName: string; onSuccess?: () => void}) => {
    if (!taskChecklistData) return;
    const newTaskChecklist = prepareTaskChecklistForMutation(taskChecklistData);
    const groupIndex = newTaskChecklist.task_groups.findIndex(group => group.id === taskGroupId);
    newTaskChecklist.task_groups[groupIndex].group_name = newGroupName;

    await mutateAsyncTaskChecklist(
      {skuId, taskChecklist: newTaskChecklist},
      {
        onSuccess: () => {
          onSuccess();
          htToast(TASK_CHECKLIST_TOAST_MESSAGES.GROUP_UPDATED);
        },
      }
    );
  };

  const deleteGroup: TDeleteGroupFunction = async ({taskGroupId, onSuccess = noop}: {taskGroupId: number; onSuccess?: () => void}) => {
    if (!taskChecklistData) return;
    const newTaskChecklist = prepareTaskChecklistForMutation(taskChecklistData);
    const groupIndex = newTaskChecklist.task_groups.findIndex(group => group.id === taskGroupId);
    newTaskChecklist.task_groups.splice(groupIndex, 1);

    await mutateAsyncTaskChecklist(
      {skuId, taskChecklist: newTaskChecklist},
      {
        onSuccess: () => {
          onSuccess();
          htToast(TASK_CHECKLIST_TOAST_MESSAGES.GROUP_DELETED);
        },
      }
    );
  };

  const reorderGroup: TReorderGroupFunction = useCallback(
    async ({groupIdToMove, targetGroupId, isBefore}) => {
      if (groupIdToMove === targetGroupId || !taskChecklistData) {
        return;
      }
      const newTaskChecklist = prepareTaskChecklistForMutation(taskChecklistData);
      const groupToMoveIndex = newTaskChecklist.task_groups.findIndex(group => group.id === groupIdToMove);
      const [groupToMove] = newTaskChecklist.task_groups.splice(groupToMoveIndex, 1);

      if (groupToMove.locked) {
        return;
      }

      const targetGroupIndex = newTaskChecklist.task_groups.findIndex(group => group.id === targetGroupId);
      const insertIndex = isBefore ? targetGroupIndex : targetGroupIndex + 1;
      newTaskChecklist.task_groups.splice(insertIndex, 0, groupToMove);

      await mutateAsyncTaskChecklist(
        {skuId, taskChecklist: newTaskChecklist},
        {
          onError: () => {
            htToast(TASK_CHECKLIST_TOAST_MESSAGES.GROUP_NOT_ORDERED);
          },
        }
      );
    },
    [mutateAsyncTaskChecklist, skuId, taskChecklistData]
  );

  return {
    skuData,
    taskChecklistData,
    // If there's an error fetching the task group data
    isErrorGetTaskGroup,
    // Sidesheet-related
    sideSheetIsVisible,
    openAddTaskSideSheet,
    closeTaskSideSheet,
    openEditTaskSideSheet,
    taskToEdit,
    // Mutations
    upsertTask,
    deleteTask,
    reorderTask,
    toggleGrouping,
    addGroup,
    editGroup,
    deleteGroup,
    reorderGroup,
  };
};

/** Manages TaskChecklist modal states and visibility. */
export const useTaskChecklistModals = () => {
  const [modalVariant, setModalVariant] = useState<TTaskChecklistModalVariants | null>(null);

  const openAddEditGroupModal = () => setModalVariant(TASK_CHECKLIST_MODAL_VARIANTS.ADD_EDIT_GROUP);
  const openDeleteItemModal = () => setModalVariant(TASK_CHECKLIST_MODAL_VARIANTS.DELETE_ITEM);
  const closeModals = () => setModalVariant(null);

  const isAddEditGroupModalVisible = modalVariant === TASK_CHECKLIST_MODAL_VARIANTS.ADD_EDIT_GROUP;
  const isDeleteItemModalVisible = modalVariant === TASK_CHECKLIST_MODAL_VARIANTS.DELETE_ITEM;

  return {modalVariant, openAddEditGroupModal, openDeleteItemModal, closeModals, isAddEditGroupModalVisible, isDeleteItemModalVisible};
};

export default useTaskChecklistPage;
