import {useQuery} from 'react-query';
import useApi from 'hooks/useApi';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {showErrorToast, logResponseError, stringifyQueryParams} from 'queries/query.utils';
import {DATA_SELECT_QUERY_KEYS} from './query.keys';

type TGetSkuCategoriesQueryParams = {
  /**
   * Search on the sku_category id or name
   */
  search?: number | string;
  pagination?: TBasePaginationQuery;
};

type TGetSkuCategoriesQueryResponse = WithErrorResponse<{
  sku_categories: Array<{id: number; name: string}>;
  pagination: TBasePagination;
}>;

const useGetSkuCategoriesQuery = ({search, pagination}: TGetSkuCategoriesQueryParams = {}) => {
  const dispatch = useAppDispatch();
  const api = useApi();

  const searchParam = search ? {search} : {};
  const paginationParam = pagination ? {pagination} : {};

  const searchQuery = stringifyQueryParams({...searchParam, ...paginationParam});

  return useQuery(DATA_SELECT_QUERY_KEYS.getSkuCategories([searchQuery]), async () => {
    const response: TGetSkuCategoriesQueryResponse = await api.skuCategories.list({search: searchQuery});
    if (response.err) {
      showErrorToast(dispatch)({errors: 'Error Retrieving Sku Categories'});
      logResponseError('DataSelect - Sku Categories')(response.err);
    }
    return response?.data;
  });
};

export default useGetSkuCategoriesQuery;
