import {useQuery} from 'react-query';
import useApi from 'hooks/useApi';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {showErrorToast, logResponseError, stringifyQueryParams} from 'queries/query.utils';
import {DATA_SELECT_QUERY_KEYS} from './query.keys';

type TGetSkusQueryParams = {
  /**
   * Search on the sku_category id or name
   */
  search?: number | string;
  pagination?: TBasePaginationQuery;
  partnerId?: number | string;
};

type TGetSkuCategoriesQueryResponse = WithErrorResponse<{
  skus: Array<{id: number; name: string}>;
  pagination: TBasePagination;
}>;

const useGetSkuCategoriesQuery = ({search, pagination, partnerId}: TGetSkusQueryParams = {}) => {
  const dispatch = useAppDispatch();
  const api = useApi();

  const partnerIdParam = partnerId ? {partner_id: partnerId} : {};
  const searchParam = search ? {search} : {};
  const paginationParam = pagination ? {pagination} : {};

  const searchQuery = stringifyQueryParams({...partnerIdParam, ...searchParam, ...paginationParam});

  return useQuery(DATA_SELECT_QUERY_KEYS.getSkus([searchQuery]), async () => {
    const response: TGetSkuCategoriesQueryResponse = await api.skus.list({search: searchQuery});
    if (response.err) {
      showErrorToast(dispatch)({errors: 'Error Retrieving Skus'});
      logResponseError('DataSelect - Skus')(response.err);
    }
    return response?.data;
  });
};

export default useGetSkuCategoriesQuery;
