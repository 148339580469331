import {Row} from '@tanstack/react-table';
import get from 'lodash/get';
import {unwrapFalsyValueFromString} from 'utils/string';

/**
 * Only for the purposes of this file
 */
const checkIsFalsey = (val: any) => [null, undefined, ''].includes(val);

function resolveSorting<T>(value1: T, value2: T) {
  const value1IsFalsey = checkIsFalsey(value1);
  const value2IsFalsey = checkIsFalsey(value2);

  // Send falsey values to the end
  if (value1IsFalsey) return 1;
  if (value2IsFalsey) return -1;
  if (value1IsFalsey && value2IsFalsey) return 0;

  return `${value1}`.localeCompare(`${value2}`, 'en', {numeric: true});
}

const isNumber = (value: string) => !Number.isNaN(Number(value)) && !Number.isNaN(parseFloat(value));

/**
 * @param rowA MRT's row object
 * @param rowB MRT's row object
 * @param colId The `accessor` or `id` props in the columns definition. This is the key to the data fed into the table.
 * If the data is nested, then `id` should be set to a string of dot notation, e.g. 'property_owner.name'
 * @returns Sorting value
 */
export const sortAlphabeticallyOrNumerically = <TData extends Record<string, any> = {}>(rowA: Row<TData>, rowB: Row<TData>, colId: string) => {
  const rowAData = rowA.original;
  const rowBData = rowB.original;

  const str1 = get(rowAData, colId);
  const str2 = get(rowBData, colId);

  const firstValue = isNumber(str1) ? Number(str1) : str1?.toLowerCase?.() ?? '';
  const secondValue = isNumber(str2) ? Number(str2) : str2?.toLowerCase?.() ?? '';
  return resolveSorting(firstValue, secondValue);
};

/**
 * @param rowA MRT's row object
 * @param rowB MRT's row object
 * @param colId The `accessor` or `id` props in the columns definition. This is the key to the data fed into the table.
 * If the data is nested, then `id` should be set to a string of dot notation, e.g. 'property_owner.name'
 * @returns Sorting value
 */
export const sortNumerically = <TData extends Record<string, any> = {}>(rowA: Row<TData>, rowB: Row<TData>, colId: string) => {
  const rowAData = rowA.original;
  const rowBData = rowB.original;

  const val1 = get(rowAData, colId);
  const val2 = get(rowBData, colId);
  const num1 = Number.parseFloat(`${val1}`);
  const num2 = Number.parseFloat(`${val2}`);

  // Send non-numeric values to the end
  if (Number.isNaN(num1)) return 1;
  if (Number.isNaN(num2)) return -1;
  if (Number.isNaN(num1) && Number.isNaN(num2)) return 0;

  return num1 - num2;
};

/**
 * @param rowA MRT's row object
 * @param rowB MRT's row object
 * @param colId The `accessor` or `id` props in the columns definition. This is the key to the data fed into the table.
 * If the data is nested, then `id` should be set to a string of dot notation, e.g. 'property_owner.name'
 * @returns Sorting value
 */
export const sortByDate = <TData extends Record<string, any> = {}>(rowA: Row<TData>, rowB: Row<TData>, colId: string) => {
  const rowAData = rowA.original;
  const rowBData = rowB.original;

  // Dates that are empty will come in wrapped as strings, e.g. 'null', 'undefined', etc. Convert to proper type before parsing.
  const date1 = unwrapFalsyValueFromString(get(rowAData, colId));
  const date2 = unwrapFalsyValueFromString(get(rowBData, colId));

  const firstValue = date1 ? Date.parse(date1 as string) : 0;
  const secondValue = date2 ? Date.parse(date2 as string) : 0;

  return resolveSorting(firstValue, secondValue);
};

/**
 * @param rowA MRT's row object
 * @param rowB MRT's row object
 * @param colId The `accessor` or `id` props in the columns definition. This is the key to the data fed into the table.
 * If the data is nested, then `id` should be set to a string of dot notation, e.g. 'property_owner.name'
 * @returns Sorting value
 */
export const sortByBoolean = <TData extends Record<string, any> = {}>(rowA: Row<TData>, rowB: Row<TData>, colId: string) => {
  const data1 = rowA?.original?.[colId];
  const data2 = rowB?.original?.[colId];

  const value1IsBoolean = typeof data1 === 'boolean';
  const value2IsBoolean = typeof data2 === 'boolean';

  // send non-boolean values to the end
  if (!value1IsBoolean) return 1;
  if (!value2IsBoolean) return -1;
  if (!value1IsBoolean && !value2IsBoolean) return 0;

  // sort booleans where true comes first
  if (data1 === data2) return 0;
  return data1 ? -1 : 1;
};
