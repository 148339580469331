import {useMutation, useQueryClient} from 'react-query';
import {TGenericCloudinaryResponse} from 'features/Cloudinary/cloudinary.types';
import useApi from 'hooks/useApi';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {showErrorToast} from 'queries/query.utils';
import {logger} from 'utils/logger';
import ORDER_TASK_CHECKLIST_QUERY_KEYS from './orderTaskChecklist.query.keys';
import {TOrderTaskChecklist} from '../types/orderTaskChecklist.types';

export type TUpdateTaskAnswerParams = {
  orderId: number;
  serviceId: number;
  taskAnswerPayload: {
    options: Array<{id: number}> | null | undefined;
    response: string | null;
    attachments: TGenericCloudinaryResponse[];
    device_response: {
      /**
       * This can be the product_make.id, -1 for "I don't know", or a string for custom make entered
       * by the user
       */
      product_make_id: number | string;
      /**
       * This can be the product.id, -1 for "I don't know", or a string for custom make entered
       * by the user
       */
      product_id: number | string;
    } | null;
  };
  taskId: number;
};

type TUpdateTaskAnswerResponse = WithErrorResponse<TOrderTaskChecklist>;

const useUpdateTaskAnswerMutation = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  const dispatch = useAppDispatch();

  return useMutation(
    async ({orderId, serviceId, taskAnswerPayload, taskId}: TUpdateTaskAnswerParams) => {
      api.toggleLoader(true);
      const response: TUpdateTaskAnswerResponse = await api.services.updateTaskAnswer({id: orderId, serviceId, taskId}, {task: taskAnswerPayload});

      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error updating task answer'});
        logger('useUpdateTaskAnswerMutation')(`Error updating task answer: ${(response.data?.errors ?? []).join(' ')}`);
        throw new Error('Error updating task answer');
      }
      return response?.data;
    },
    {
      onSuccess: (data, {serviceId}) => {
        queryClient.setQueryData(ORDER_TASK_CHECKLIST_QUERY_KEYS.getOrderTaskChecklist(serviceId), data);
      },
      onSettled: () => {
        api.toggleLoader(false);
      },
    }
  );
};

export default useUpdateTaskAnswerMutation;
