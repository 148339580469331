import {Partner, Plan} from 'features/Booking/Parts/Cart/cart.types';
import {FormattedPlan} from 'features/Booking/Parts/Cart/PlanUpsell/planUpsell.types';

import ClassSegment from '../Segment';

/**
 * -------------------------------------------------------------------------------------
 * Define the "event name" here, in UPSELL_TRACKING_TYPES
 */
export enum TrackingTypes {
  upsellAdded = 'Upsell Added',
  upsellViewed = 'Upsell Viewed',
  upsellRemoved = 'Upsell Removed',
}

export enum UpsellReferrers {
  ADMIN_UPSELL = 'ADMIN_UPSELL',
  ADMIN_STANDALONE = 'ADMIN_STANDALONE',
}

type PlanUpsellProps = {referrer?: string; plan: FormattedPlan | Plan | null; partner: Partner | null};

/**
 * -------------------------------------------------------------------------------------
 * These are just "actions" that are consolidated in one place that
 * will return your properties structure. Just "method" out what you
 * need to handle.
 */

export const SegmentPlansTrackingAction = {
  /* Plan Upsells */
  planUpsell({plan, partner, referrer = ''}: PlanUpsellProps) {
    if (!plan) return {};
    // we could account for all the ways someone might pass "plan", lets not worry about it.
    return {
      upsell_name: referrer || UpsellReferrers.ADMIN_UPSELL,
      plan: {
        amount: plan.amount,
        name: plan.name,
        plan_id: plan.id,
        partner,
      },
    };
  },
};

const Segment = new ClassSegment();
export default Segment;
