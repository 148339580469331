import React from 'react';
import cn from 'classnames';
import {useStickyContainer} from 'components/Elements/StickyContainer/useStickyContainer';
import PageHeader from 'components/Elements/PageHeader';
import BaseGrid from 'components/Grid';
import IssuesTableToolbar from 'features/Issues/Parts/IssuesTableToolbar';
import IssuesStats from 'features/Issues/Parts/IssuesStats';
import IssuesTable from 'features/Issues/Parts/IssuesTable';
import useIssuesRouteParams from 'features/Issues/hooks/useIssuesRouteParams';

const Issues = () => {
  const {StickyContainer} = useStickyContainer({});
  const {pageTitle, statsKeys} = useIssuesRouteParams();

  const hasStats = !!statsKeys.length;

  return (
    <div>
      <PageHeader title={pageTitle} collapsible={hasStats} outerClassName={cn(hasStats && 'bg-n100')} withGridMargins>
        {hasStats && <IssuesStats />}
      </PageHeader>
      <BaseGrid.Fluid>
        <StickyContainer>
          <IssuesTableToolbar />
        </StickyContainer>
        <IssuesTable />
      </BaseGrid.Fluid>
    </div>
  );
};

export default Issues;
