import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useMutation} from 'react-query';

import useApi from 'hooks/useApi';
import APIS from 'global/apis';
import {logger} from 'utils/logger';
import {showErrorToast} from 'queries/query.utils';

import {TGroupSelectSkuServices} from '../MDU.types';

/** QUERY: Is group configuration you are trying to submit currently existing? */
export const useCheckGroupCurrentlyExistsMutation = () => {
  const dispatch = useDispatch();
  const api = useApi();
  const {projectId} = useParams<{projectId: string}>();
  const errorPreText = 'Error Checking for Group Existence';

  return useMutation(
    async (services: TGroupSelectSkuServices[], id?: string | number) => {
      api.toggleLoader(true);
      const response = await APIS.mdu.checkGroupCurrentlyExists({id: id || projectId}, {unit: {services}});
      api.toggleLoader(false);

      if (response.err) {
        showErrorToast(dispatch)({errors: errorPreText});
        throw new Error(response.err);
      }

      return response;
    },
    {
      onError: response => {
        logger(`${errorPreText} `)(response as Error);
      },
    }
  );
};

export const useUnserviceableQuery = () => {};
