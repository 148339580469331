export const PLAN_IDS = {
  HELLOTECH_HOME_YEARLY: 1,
  HELLOTECH_HOME_MONTHLY: 6,
  HELLOTECH_HOME_YEARLY_NEW: 7,
  HELLOTECH_HOME_MONTHLY_NEW: 8,
  WALMART_YEARLY: 4,
  ONLINE_PLAN_ID: 12,
  ONLINE_PLAN_ID_MONTHLY: 11,
};
export const ONLINE_PLAN_IDS = [PLAN_IDS.ONLINE_PLAN_ID, PLAN_IDS.ONLINE_PLAN_ID_MONTHLY];

// Plan names
export const HELLOTECH_HOME_NAME = 'HelloTech Home';

// Plan Product ids
export const HELLOTECH_HOME_PLAN_PRODUCT_ID = 1;
export const ONLINE_PLAN_PRODUCT_ID = 4;

// Legacy plan
export const UPSELL_ONLINE_SEO_NAME = 'Online';

/*
  - The values here align with the plans/support_plans table
  - These are payment cycles for SHP subscriptions.
*/

export enum UpsellTypes {
  MAIN = 'main',
}

export const SMART_HOME_BENEFITS_DETAILS = {
  homeTech: {
    id: 'homeTech',
    header: 'Home Tech Specialist',
    alternateHeader: 'Home Tech Specialist',
    subheader: 'Unlimited, instant help with any connected device. No appointments needed.',
    badgeCopy: 'save up to $100',
  },
  inHome: (truckRollFee = 69) => ({
    id: 'inHome',
    header: `$${truckRollFee} In-Home Services`,
    alternateHeader: 'Discounts on In-Home Services',
    subheader: `Exclusive rates for any in-home service, just $${truckRollFee}`,
    badgeCopy: 'membership exclusive',
  }),
  productDiscount: (discount = 10) => ({
    id: 'productDiscount',
    header: `${discount}% Savings on Products`,
    subheader: `Save ${discount}% on curated smart home products, with or without installation.`,
    badgeCopy: 'membership exclusive',
  }),
  online: {
    id: 'online',
    header: 'Unlimited 24/7 Online Support',
    subheader: 'Get free and unlimited online support for any connected device, from the comfort of home',
    badgeCopy: 'save up to $100',
  },
  checkup: {
    id: 'checkup',
    header: 'Home Technology Checkup',
    subheader: 'A comprehensive evaluation of your home technology ecosystem, free for members',
    badgeCopy: 'valued at $99',
  },
  antivirus: {
    id: 'antivirus',
    header: 'Premium Antivirus Software',
    subheader: "We've partnered with Malwarebytes to offer you the best in protection against computer threats",
    badgeCopy: 'valued at $40',
  },
  password: {
    id: 'password',
    header: 'Premium Password Protection',
    subheader: 'Manage your passwords securely and effortlessly across all your devices with Dashlane',
    badgeCopy: 'valued at $60',
  },
};

export const ONLINE_DETAILS = {
  homeTechSpecialist: {
    id: 'homeTech',
    header: 'Home Tech Specialist',
    subheader: 'Unlimited, instant help with any connected device. No appointments needed.',
    badgeCopy: 'save up to $100',
  },
  inHomeDiscount: (discount: string | number) => ({
    id: 'inHomeDiscount',
    header: `${discount}% Off all In-Home Services`,
    subheader: 'Save on all in-home services',
    badgeCopy: 'membership exclusive',
  }),
  online: {
    id: 'online',
    header: 'Unlimited 24/7 Online Support',
    subheader: 'Get free and unlimited online support for any connected device, from the comfort of home',
    badgeCopy: 'save up to $100',
  },
};
