// StringAction.tsx
import React from 'react';
import cn from 'classnames';

import {TActionBaseOmitType, TActionStringProps, TBaseAction} from './actionGroup.types';

import styles from './styles.scss';

const StringAction = ({text, action, dataTestId, customClassName = ''}: TBaseAction & TActionBaseOmitType<TActionStringProps>) => {
  if (!text) return null;

  const stringClassName = cn(styles.stringAction, customClassName);

  return (
    <div className={stringClassName} onClick={action} data-testid={dataTestId}>
      <span className={customClassName}>{text}</span>
    </div>
  );
};

export default StringAction;
