import React, {ReactNode} from 'react';
import cn from 'classnames';
import {Panel, Icon, Avatar} from 'ht-styleguide';
import {noop} from 'utils/event';
import {ProjectLeadPerson} from 'features/MultiDwellingUnits/MDU.types';
import {formatPhoneBasic} from 'utils/formatter/phone';
import styles from './leadPanel.styles.scss';

type LeadPanelProps = {
  className?: string;
  panelIcon: string;
  panelHeaderText: string;
  changeButtonContent?: ReactNode;
  leadPerson?: ProjectLeadPerson | null;
  onChangeLeadPerson?: BaseAnyFunction;
  changeLeadPersonText?: string;
};

const LeadPanel = ({className, panelIcon, panelHeaderText, changeButtonContent = null, leadPerson, onChangeLeadPerson = noop, changeLeadPersonText}: LeadPanelProps) => {
  return (
    <div onClick={onChangeLeadPerson} className="cursorPointer">
      <Panel noShadow largeBorderRadius className={cn(styles.panel, 'padding-small2', className)}>
        <div className="flex justify-content-space-between">
          <p className="overline-medium n700 marginBottom-small1 flex align-items-center">
            <Icon name={panelIcon} className={cn('marginRight-tiny1', styles.titleIcon)} />
            {panelHeaderText}
          </p>
          {changeButtonContent}
        </div>
        {leadPerson ? (
          <div className="flex align-items-center">
            <Avatar name={leadPerson.name} image={leadPerson.profilePicture?.thumb} />
            <div className="marginLeft-small">
              <p className="h6 n800 marginBottom-tiny1">{`${leadPerson.name} #${leadPerson.id}`}</p>
              <Icon name="phone-outlined p2 n700" /> <span className="n700">{formatPhoneBasic(leadPerson.phone, '-')}</span>
            </div>
          </div>
        ) : (
          <div className="flex align-items-center">
            <div className={cn(styles.addBillingLead, 'n300 marginRight-small')}>
              <Icon name="v2-plus-icon" />
            </div>
            <button type="button" className="plainButton h6 text-link text-weight-med">
              {changeLeadPersonText}
            </button>
          </div>
        )}
      </Panel>
    </div>
  );
};
export default LeadPanel;
