import {useParams} from 'react-router-dom';
import {DISPATCH_UNASSIGNED_LIST_PARAMS} from '../dispatch.constants';
import {TDispatchRouteParams} from '../dispatch.types';

/**
 * Return particular data for the unassigned list pages
 */
const useUnassignedListRouteParams = () => {
  const {dispatchNavType} = useParams<TDispatchRouteParams>();
  const {pageTitle, pageFilters, omitColumnKeys, filterKeys, statsFilters} = DISPATCH_UNASSIGNED_LIST_PARAMS[dispatchNavType!];

  return {
    /**
     * Which page (all or assigned-to-me) the user is on
     */
    dispatchNavType: dispatchNavType!,
    filterKeys,
    omitColumnKeys,
    pageFilters,
    /**
     * The title of the page
     */
    pageTitle,
    statsFilters,
  };
};

export default useUnassignedListRouteParams;
