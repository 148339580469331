import React, {ReactNode} from 'react';
import cn from 'classnames';
import {Tooltip, Label, Icon} from 'ht-styleguide';
import styles from './labelWithTooltip.styles.scss';

export const TooltipWrapper = ({children, tooltipContent}: {children: ReactNode; tooltipContent: string}) => (
  <Tooltip content={tooltipContent} position="right">
    <div className="flex align-items-center">
      {children}
      <Icon name="info-outline" className={cn(styles.labelIcon, 'n300 marginLeft-tiny')} />
    </div>
  </Tooltip>
);

const LabelWithTooltip = ({id, label, tooltipContent}: {id: string; label: string; tooltipContent: string}) => (
  <TooltipWrapper tooltipContent={tooltipContent}>
    <Label inputId={id} label={label} />
  </TooltipWrapper>
);

export default LabelWithTooltip;
