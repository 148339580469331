import React, {useEffect, useState, useCallback, useMemo} from 'react';
import get from 'lodash/get';

import {SelectField, ELEMENT_SIZE} from 'ht-styleguide';

import {useAppDispatch} from 'hooks/useAppDispatch';
import usePrevious from 'hooks/usePrevious';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {useGetPartnersQuery} from 'features/MultiDwellingUnits/queries/query.project.fields';

import {MduFormElementProps, PartnerSearchResult, SelectOption} from './FormFields.types';

import styles from './FormFields.styles.scss';

import {FIELD_LABELS} from './FormFields.constants';

const PartnerSelect = ({formik, project, label = FIELD_LABELS.partner, fieldName = 'partnerId', multiple = false, dataTestId = FIELD_LABELS.partner}: MduFormElementProps) => {
  const {data: partnersData} = useGetPartnersQuery();
  const [selectedPartnerDetails, setSelectedPartnerDetails] = useState<SelectOption | SelectOption[] | string>('');
  const [partnerChanged, setPartnerChanged] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const showPartnerMessage = project?.partner && selectedPartnerDetails && partnerChanged;

  const resetPartnerDetails = () => setSelectedPartnerDetails('');

  const partnersList = useMemo(() => {
    if (partnersData) {
      return partnersData.map((elem: PartnerSearchResult) => ({
        value: elem.id,
        label: `${elem.companyName} #${elem.id}`,
      }));
    }
    return [];
  }, [partnersData]);

  const handleSelectedPartnerDetailsChange = useCallback(
    (partner: SelectOption | SelectOption[] | null) => {
      if (!partner) {
        formik.handleChange({target: {name: fieldName, value: ''}});
        return resetPartnerDetails();
      }
      setSelectedPartnerDetails(partner);
      setPartnerChanged(true);

      const newFormikValue = Array.isArray(partner) ? partner.map(p => p.value) : partner.value;
      return formik.handleChange({
        target: {name: fieldName, value: newFormikValue},
      });
    },
    [fieldName, formik]
  );

  // Restore details state from formikValue
  const formikValue = get(formik.values, fieldName);
  const previousFormikValue = usePrevious(formikValue);

  useEffect(() => {
    if (!selectedPartnerDetails && formikValue) {
      const found = partnersList.filter(entry => (multiple ? formikValue.includes(entry.value) : entry.value === formikValue));
      if (found) {
        setSelectedPartnerDetails(multiple ? found : found[0]);
      }
    }
    // When formik is cleared from parent container
    if (!formikValue && previousFormikValue) {
      resetPartnerDetails();
    }
  }, [formikValue, handleSelectedPartnerDetailsChange, multiple, partnersList, previousFormikValue, selectedPartnerDetails]);

  return (
    <>
      <SelectField
        id={fieldName}
        placeholder="Type or Select"
        options={partnersList}
        label={label}
        multiple={multiple}
        value={selectedPartnerDetails}
        onChange={(partner: SelectOption) => {
          handleSelectedPartnerDetailsChange(partner);
          dispatch(mduProjectsSlice.actions.setRawFilterValues({[fieldName]: partner}));
        }}
        elementSize={ELEMENT_SIZE.MEDIUM}
        clearable
        searchable
        error={formik.errors[fieldName]}
        reactSelectClassName={styles.placeholder}
        dataTestId={dataTestId}
      />
      {showPartnerMessage && <p className="n700 p2">SKU groups will be reset when you save this change</p>}
    </>
  );
};

export default PartnerSelect;
