import {useDispatch} from 'react-redux';
import {useQuery} from 'react-query';
import {ProjectDetails, MduFetchProjectResponse} from 'features/MultiDwellingUnits/MDU.types';
import useApi from 'hooks/useApi';
import {showErrorToast} from 'queries/query.utils';
import {logger} from 'utils/logger';
import {MDU_PROJECT_QUERY_KEYS} from '../MDU.query.keys';

type TUseGetProjectDetailsQuery = {
  projectId: string | number | null | undefined;
  queryOpts?: Parameters<typeof useQuery<ProjectDetails>>['2'];
};

const useGetProjectDetailsQuery = ({projectId, queryOpts = {}}: TUseGetProjectDetailsQuery) => {
  const dispatch = useDispatch();
  const api = useApi();
  const {enabled, ...restOpts} = queryOpts;

  return useQuery<ProjectDetails>(
    MDU_PROJECT_QUERY_KEYS.getDetailsByProjectId(`${projectId}`),
    async () => {
      const response: MduFetchProjectResponse = await api.mdu.getProject({id: projectId});

      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Retrieving Project Details'});
        logger('useGetProjectDetailsQuery')(new Error(JSON.stringify(response.data.errors)));
        throw new Error('Error retrieving project details');
      }
      return response?.data?.project;
    },
    {
      enabled: !!projectId && enabled,
      ...restOpts,
    }
  );
};

export default useGetProjectDetailsQuery;
