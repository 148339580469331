import {useMutation, useQueryClient} from 'react-query';
import useApi from 'hooks/useApi';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {showErrorToast} from 'queries/query.utils';
import {logger} from 'utils/logger';
import ORDER_TASK_CHECKLIST_QUERY_KEYS from './orderTaskChecklist.query.keys';
import {TOrderTaskChecklist} from '../types/orderTaskChecklist.types';

type TMarkTaskIncompleteParams = {
  serviceId: number;
  /** Ids of tasks to mark as incomplete */
  taskIds: number[];
};

type TUseMarkTaskIncompleteResponse = WithErrorResponse<TOrderTaskChecklist>;

const useMarkTaskIncompleteMutation = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  const dispatch = useAppDispatch();

  return useMutation(
    async ({serviceId, taskIds}: TMarkTaskIncompleteParams) => {
      api.toggleLoader(true);
      const response: TUseMarkTaskIncompleteResponse = await api.services.markTaskIncomplete({serviceId}, {task_ids: taskIds});

      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error marking task incomplete'});
        logger('markTaskIncompleteMutation')(`Error marking task incomplete: ${(response.data?.errors ?? []).join(' ')}`);
        throw new Error('Error marking task incomplete');
      }
      return response?.data;
    },
    {
      onSuccess: (data, {serviceId}) => {
        queryClient.setQueryData(ORDER_TASK_CHECKLIST_QUERY_KEYS.getOrderTaskChecklist(serviceId), data);
      },
      onSettled: () => {
        api.toggleLoader(false);
      },
    }
  );
};

export default useMarkTaskIncompleteMutation;
