import {useEffect, useRef} from 'react';
import {useLocation, matchPath} from 'react-router-dom';
import {autoScrollForElements} from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import {Routes} from 'global/routes/routes';

const productPageRoutesWithDnD = [Routes.PRODUCTS_TASK_CHECKLIST()];

const checkPageHasDnDFeature = (pathname: string) => productPageRoutesWithDnD.some(route => matchPath({path: route}, pathname));

/**
 * Hook to enable auto-scrolling for drag and drop elements on the products page.
 * Only enable for product pages that have drag and drop feature.
 */
const useProductsDnDScroll = () => {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const {pathname} = useLocation();

  useEffect(() => {
    const scrollableContainer = scrollableContainerRef.current;
    const pageHasDnDFeature = checkPageHasDnDFeature(pathname);

    if (pageHasDnDFeature && scrollableContainer) {
      return autoScrollForElements({
        element: scrollableContainer,
      });
    }
    return () => {};
  }, [pathname]);

  return {
    scrollableContainerRef,
  };
};

export default useProductsDnDScroll;
