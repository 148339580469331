import React from 'react';
import cn from 'classnames';
import {Button, BUTTON_THEMES, Icon, Modal} from 'ht-styleguide';

/* Components */
import GroupServicesBoxInfo from 'features/MultiDwellingUnits/Parts/GroupServicesBoxInfo';

/* Types */
import {SelectedSku} from 'features/Questions/types';
import {TConfirmAddTemplateActionModalProps} from './MDU.Jobs.CreateSingleConfirm';

/* styles */
import styles from './modals.styles.scss';

export const ConfirmDuplicateTemplateModalType = 'confirmDuplicateTemplateModalType';

export const ModalGroupExistsType = {
  multiJob: 'multiJob',
  templateJobs: 'templateJobs',
  singleJob: 'singleJob',
};

const GroupExistsAlertModal = ({modalGroupExistsType, services, templateName, isVisible, onCloseActionItem, dispatchAction}: TConfirmAddTemplateActionModalProps<SelectedSku>) => {
  const servicesDisplay = services?.map(service => {
    return {
      quantity: service.quantity || 1,
      name: service.name || '',
      key: JSON.stringify(service),
      id: service.uuidDraft || '',
    };
  });

  const modalTypeDisplay = () => {
    if (ModalGroupExistsType.multiJob === modalGroupExistsType)
      return {
        text: 'A template with the same configuration already exists. Make your new template unique before saving it, or use the existing one below when adding new jobs.',
        footer: {
          footerElement3: (
            <Button theme={BUTTON_THEMES.PRIMARY} dataTestId="button_close_modal" className={styles.ctaButton} onClick={onCloseActionItem}>
              OK
            </Button>
          ),
        },
      };

    if (ModalGroupExistsType.singleJob === modalGroupExistsType)
      return {
        text: 'A template with the same service configuration already exists. It will be applied to this job.',
        footer: {
          footerElement2: (
            <Button theme={BUTTON_THEMES.SECONDARY} dataTestId="button_cancel_job" className={styles.ctaButton} onClick={onCloseActionItem}>
              Discard Changes
            </Button>
          ),
          footerElement3: (
            <Button theme={BUTTON_THEMES.PRIMARY} dataTestId="button_confirm_template_add" className={styles.ctaButton} onClick={dispatchAction}>
              Confirm
            </Button>
          ),
        },
      };

    if (ModalGroupExistsType.templateJobs === modalGroupExistsType)
      return {
        textOnly: true,
        text: `The template <strong>${templateName}</strong> is already using this service configuration. Discard template or make changes to the service configuration to save this template.`,
        footer: {
          footerElement2: (
            <Button theme={BUTTON_THEMES.DANGER_SECONDARY} dataTestId="button_cancel_job" className={styles.ctaButton} onClick={dispatchAction}>
              Discard Template
            </Button>
          ),
          footerElement3: (
            <Button theme={BUTTON_THEMES.SECONDARY} dataTestId="button_confirm_template_add" className={styles.ctaButton} onClick={onCloseActionItem}>
              Close
            </Button>
          ),
        },
      };

    return {};
  };

  const {footer, text, textOnly} = modalTypeDisplay();

  return (
    <Modal isVisible={isVisible} modalClassName={cn(styles.container, styles.modalContents)} hide={onCloseActionItem} header="Template Already Exists" {...footer}>
      <div className="position relative">
        <p className="p1 n900">
          <div className="paddingBottom-small" dangerouslySetInnerHTML={{__html: `${text}`}} />
          {!textOnly && (
            <>
              <div className="flex align-items-center border marginTop-small padding-small1 border-radius-4 font-weight-medium">
                <Icon name="layers" className={styles.icon32} /> {templateName}
              </div>
              <GroupServicesBoxInfo services={servicesDisplay} />
            </>
          )}
        </p>
      </div>
    </Modal>
  );
};

export default GroupExistsAlertModal;
