import {createSlice, createSelector, createAsyncThunk, AnyAction} from '@reduxjs/toolkit';
import {ResponseError} from 'types/base.types';
import {RootState} from 'store';
import APIS from 'global/apis';
import * as requestLoaderSlice from 'features/App/RequestLoader/requestLoader.ducks';
import {applyCartClientIds} from 'features/Booking/Parts/Cart/cart.ducks';
import * as TS from './order.types';

/*
 ****************************************
 ************ ASYNC ACTIONS *************
 ****************************************
 */

export const asyncActions = {
  createOrderAndConfirm: createAsyncThunk<TS.OrderState, void, {rejectValue: ResponseError; state: RootState}>('order/createOrderAndConfirm', async (_, {rejectWithValue, dispatch, getState}) => {
    dispatch(requestLoaderSlice.actions.loading(true));

    const responseOrder = (await APIS.booking.cart.completeBooking(applyCartClientIds({params: {}, state: getState}))) ?? {};
    const orderId = responseOrder.data?.id;
    const responseConfirmation = (await APIS.booking.order.confirmation({orderId}, applyCartClientIds({params: {}, state: getState}))) ?? {};
    const error = responseOrder.err || responseConfirmation.err;
    const mergedData: TS.OrderState = {
      ...(responseOrder?.data ?? {}),
      ...(responseConfirmation?.data?.order ?? {}),
    };

    dispatch(requestLoaderSlice.actions.loading(false));

    return error ? rejectWithValue(error as ResponseError) : mergedData;
  }),
  confirmation: createAsyncThunk<TS.OrderState, {orderId: number}, {rejectValue: ResponseError; state: RootState}>('order/confirmation', async ({orderId}, {rejectWithValue}) => {
    const response = (await APIS.booking.order.confirmation({orderId})) ?? {};

    return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.OrderState);
  }),
};

/*
 *****************************************
 ************ INITIAL STATE  *************
 *****************************************
 */

const initialState: TS.OrderState = {} as TS.OrderState;

/*
 ****************************************
 ************ CREATE SLICE  *************
 *****************************************
 */
const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      (action): action is AnyAction => [asyncActions.createOrderAndConfirm.fulfilled].some(actionCreator => actionCreator.match(action)),
      (state, action) => {
        return action.payload;
      }
    );
  },
});

/*
*******************************************************
  SELECTORS & SELECTOR METHODS
*******************************************************
*/
const getOrderState = (state: RootState): TS.OrderState => state.booking.order;
const getOrderStateByKey = (key: keyof TS.OrderState) => (order: TS.OrderState) => order[key];

/*
*******************************************************
  EXPORTS
*******************************************************
*/
export const selectors = {
  getOrderState: createSelector(getOrderState, cart => cart),
  getKeyInOrderState: (key: keyof TS.OrderState) => createSelector(getOrderState, getOrderStateByKey(key)),
};

export const actions = {...asyncActions, ...orderSlice.actions};

export default orderSlice.reducer;
