/**
 * We will have different defining characteristics for each type of editor.
 * We will try to keep it normalized, but inevitably there will be differences.
 *
 * Schema out the differences by type. This "could" get redundant, because there are a myriad of ways to have a display.
 */

export const STORAGE_TYPES = {
  REDUX: 'redux',
  LOCAL_STORAGE: 'localStorage',
};
export const editorSchemas = {
  NOTE: {
    namespace: 'issue-note-editor',
    storageType: STORAGE_TYPES.REDUX,
    initialState: {},
    toolbarOmissions: [],
  },
  COMMENTS: (append: string) => {
    return {
      namespace: `issue-comment-editor-${append}`,
      storageType: STORAGE_TYPES.REDUX,
      initialState: {},
      toolbarOmissions: [],
    };
  },
};
