import React, {useMemo} from 'react';
import cn from 'classnames';
import {Link} from 'react-router-dom';
import {orderTaskChecklistByServicePath} from 'global/paths';
import {checkOrderHasClosed} from 'features/Orders/utils/status/order.status.utils';
import {ISharedDataProps} from '../../orderTaskChecklistPage.types';
import {getServiceTasksCount, checkCanModifyTask} from '../../orderTaskChecklist.utils';
import styles from './servicesList.styles.scss';

/**
 * ServiceTab =============================================
 * For the individual service tab
 */

interface IServiceTab extends Pick<ISharedDataProps, 'checklistsForOrder' | 'taskCompletionStatusModalRef'> {
  order: NonNullable<ISharedDataProps['order']>;
  service: NonNullable<ISharedDataProps['order']>['breakdown']['services'][0];
  currentServiceId: number;
}

const ServiceTab = ({service, currentServiceId, checklistsForOrder, order, taskCompletionStatusModalRef}: IServiceTab) => {
  const {id: orderId} = order;
  const {id, name} = service;
  const isCurrentService = id === currentServiceId;

  const pathToServiceChecklist = orderTaskChecklistByServicePath(String(orderId), String(id));
  const checklistForService = checklistsForOrder[id]?.data;

  const serviceAction = useMemo(() => {
    switch (true) {
      // When all tasks for the service are completed
      case checklistForService?.all_tasks_passed: {
        return <span className="successGreen500 font-weight-medium">All tasks completed</span>;
      }
      // When the order is in the started state
      case checkCanModifyTask(order): {
        const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.preventDefault();
          if (!isCurrentService) {
            return;
          }
          taskCompletionStatusModalRef.current?.toggleTaskCompletionStatusModal({type: 'allTasksOnServiceComplete', serviceId: id});
        };
        const buttonStyles = cn('plainButton text-underline', {
          pointerEventsNone: !isCurrentService,
          cursorPointer: isCurrentService,
        });
        return (
          <button onClick={onClick} type="button" className={buttonStyles}>
            Mark all tasks complete
          </button>
        );
      }
      // When the order has been closed
      case checkOrderHasClosed({order}) && !!checklistForService: {
        const {total, completed} = getServiceTasksCount(checklistForService!) || {};
        return total ? (
          <span>
            {completed}/{total} tasks completed
          </span>
        ) : null;
      }
      default:
        return null;
    }
  }, [checklistForService, id, isCurrentService, order, taskCompletionStatusModalRef]);

  // Styles
  const containerStyles = cn(styles.serviceContainer, '-no-decoration', {
    [styles.active]: isCurrentService,
  });
  const currentServiceMarkerStyles = cn({
    [styles.currentServiceMarker]: isCurrentService,
  });

  return (
    <Link key={id} className={containerStyles} to={pathToServiceChecklist}>
      <div className={currentServiceMarkerStyles} />
      <h6>{name}</h6>
      {serviceAction && <p className="caption n700 marginTop-tiny">{serviceAction}</p>}
    </Link>
  );
};

/**
 * ServicesList =============================================
 * Handles the scrolling list of services
 */

interface IServicesList extends ISharedDataProps {
  currentServiceId: number;
}

/**
 * The left-hand side of the main content. This component is responsible for rendering
 * the list of services
 */
const ServicesList = ({checklistsForOrder, order, currentServiceId, taskCompletionStatusModalRef}: IServicesList) => {
  const services = order?.breakdown?.services || [];

  return order ? (
    <div className={styles.listContainer}>
      {services.map(service => (
        <ServiceTab
          key={service.id}
          checklistsForOrder={checklistsForOrder}
          order={order}
          service={service}
          currentServiceId={currentServiceId}
          taskCompletionStatusModalRef={taskCompletionStatusModalRef}
        />
      ))}
    </div>
  ) : null;
};

export default ServicesList;
