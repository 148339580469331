export const THUMBNAIL_TEST_IDS = {
  DOWNLOAD_BUTTON: 'thumbnail-download-button',
  DELETE_BUTTON: 'thumbnail-delete-button',
  THUMBNAIL: 'thumbnail',
  THUMBNAIL_MINI: 'thumbnail-mini',
  LOADING_INDICATOR: 'thumbnail-loading-indicator',
};

export const VIDEO_FILE_EXTENSIONS = ['mp4', 'webm', 'ogg', 'mov'];
export const IMAGE_FILE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'image/jpeg', 'image/png'];
export const SPREADSHEET_FILE_EXTENSIONS = ['csv', 'xls', 'xlsx', 'text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
