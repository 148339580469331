import React, {ComponentProps, useMemo} from 'react';
import {SideSheetContentSection} from 'ht-styleguide';
import DeviceSelector from 'components/DeviceSelector';
import useGetDevicesQuery from 'queries/skus/query.skus.getDevices';
import {UpdateTaskAnswerFieldName, CustomUpdateTaskAnswerFieldName} from '../../editTaskAnswerSideSheet.types';
import {IEditTaskAnswerSectionShareProps} from '../editTaskAnswerContent.types';

const DeviceEditTaskAnswerSection = ({formik, task}: IEditTaskAnswerSectionShareProps) => {
  const {device} = task;
  const productCategoryId = device?.original_product_category.id;

  const {data} = useGetDevicesQuery({
    productCategoryId,
    queryOpts: {
      enabled: !!productCategoryId,
    },
  });

  const deviceList = useMemo(() => {
    if (!data) return null;
    return data.makes.map(({id: makeId, name: makeName, products}) => ({
      id: makeId,
      name: makeName,
      products: products.map(({id: modelId, name: modelName}) => ({
        id: modelId,
        name: modelName,
      })),
    }));
  }, [data]);

  const onChange: ComponentProps<typeof DeviceSelector>['onValueChange'] = ({makeValue, makeText, modelValue, modelText}) => {
    formik.setFieldValue(UpdateTaskAnswerFieldName.DeviceResponse, {
      [UpdateTaskAnswerFieldName.DeviceProductMakeId]: makeValue,
      [CustomUpdateTaskAnswerFieldName.DeviceProductMakeName]: makeText,
      [UpdateTaskAnswerFieldName.DeviceProductId]: modelValue,
      [CustomUpdateTaskAnswerFieldName.DeviceProductModelName]: modelText,
    });
    formik.setErrors({});
  };

  const value: ComponentProps<typeof DeviceSelector>['value'] = {
    makeValue: formik.values.device_response?.product_make_id,
    makeText: formik.values.device_response?.product_make_name,
    modelValue: formik.values.device_response?.product_id,
    modelText: formik.values.device_response?.product_model_name,
  };

  // @ts-ignore
  const makeFieldErrorMessage = formik.errors.device_response?.product_make_id;
  // @ts-ignore
  const modelFieldErrorMessage = formik.errors.device_response?.product_id;

  return (
    <SideSheetContentSection title="Device">
      <DeviceSelector
        deviceList={deviceList}
        makeFieldErrorMessage={makeFieldErrorMessage}
        makeSelectFieldPlaceholder="Make"
        modelFieldErrorMessage={modelFieldErrorMessage}
        modelSelectFieldPlaceholder="Model"
        onValueChange={onChange}
        value={value}
      />
    </SideSheetContentSection>
  );
};

export default DeviceEditTaskAnswerSection;
