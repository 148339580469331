import React from 'react';
import {Form, InputField} from 'ht-styleguide';
import {TaskFieldNames} from 'features/Products/types/taskGroups.types';
import {SHARED_FIELD_ERRORS} from '../constants/main';
import {TTaskChecklistFormik} from '../taskChecklist.types';
import {BASE_OPTION} from './optionsBuilder.constants';
import {IBinaryOptionsProps} from './optionsBuilder.types';

const binaryIndexArray = [0, 1]; // Used to map over the two options
const initialValues = [BASE_OPTION, BASE_OPTION];

const getValueForInput = (index: number, formik: TTaskChecklistFormik) => {
  const options = formik.values[TaskFieldNames.Options] || [];
  return options[index]?.description || '';
};

const onChange = (index: number, formik: TTaskChecklistFormik) => (e: React.ChangeEvent<HTMLInputElement>) => {
  const options = formik.values[TaskFieldNames.Options] || [];
  // options is an empty array on first form render so seed it with the initial values
  const newOptions = options.length ? [...options] : initialValues;
  newOptions[index] = {
    ...(newOptions[index] ?? {}),
    description: e.target.value,
  };
  formik.setFieldValue(TaskFieldNames.Options, newOptions);
};

/**
 * For the `binary` task type. The user must provide two options, which will be shown
 * as radio options in the worker app.
 *
 * Notes:
 * - Retain the entire `options` array if there are more than 2 options. Need to consider the scenario
 * where the user flips from multi-select to binary to multislect again. Retaining is easier.
 * We'll clean up the `options` prior to submission since the API only accepts 2 options
 * for binary tasks.
 */
const BinaryOptions = ({formik}: IBinaryOptionsProps) => {
  return (
    <>
      {binaryIndexArray.map(index => {
        // This error hack is used since formik.values.options is an empty array when the form is first rendered
        let error = formik.errors?.options === SHARED_FIELD_ERRORS.min2Options ? SHARED_FIELD_ERRORS.descriptionCannotBeEmpty : '';
        // @ts-ignore
        error = formik.errors?.options?.[index]?.description ?? error;
        return (
          <Form.Row key={index}>
            <Form.Column lg={12} md={8} sm={4}>
              <InputField id={`binaryOption${index}`} label={`Option ${index + 1}`} onChange={onChange(index, formik)} value={getValueForInput(index, formik)} error={error} />
            </Form.Column>
          </Form.Row>
        );
      })}
    </>
  );
};

export default BinaryOptions;
