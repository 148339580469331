import React, {forwardRef} from 'react';
import cn from 'classnames';
import {useTooltipSetup, useCarousel, useLightboxDisplay} from './thumbnail.display.hooks';
import {TThumbnailDisplayProps, TThumbnailFile} from './thumbnail.types';
import {Thumbnail} from './Thumbnail';
import ThumbnailPreload from './Thumbnail.Preload';
import CarouselButton from './Thumbnail.Display.CarouselButton';
import HTLightbox from '../HTLightbox';
import styles from './thumbnailDisplay.styles.scss';

const DEFAULT_BUTTON_OFFSET_IN_PX = 32;

const calculateStyles = (parentPadding: TThumbnailDisplayProps['parentPadding']) => {
  let styleObj = {
    marginLeft: 0, // Negative margin to stretch the carousel to the left
    marginRight: 0, // Negative margin to stretch the carousel to the right
    paddingLeft: 0, // Padding to offset the negative margin
    paddingRight: 0, // Padding to offset the negative margin
    buttonLeftPosition: DEFAULT_BUTTON_OFFSET_IN_PX, // Position the left carousel button
    buttonRightPosition: DEFAULT_BUTTON_OFFSET_IN_PX, // Position the right carousel button
  };

  if (typeof parentPadding === 'number') {
    styleObj = {
      ...styleObj,
      marginLeft: parentPadding ? -parentPadding : 0,
      marginRight: parentPadding ? -parentPadding : 0,
      paddingLeft: parentPadding,
      paddingRight: parentPadding,
      buttonLeftPosition: parentPadding + DEFAULT_BUTTON_OFFSET_IN_PX,
      buttonRightPosition: parentPadding + DEFAULT_BUTTON_OFFSET_IN_PX,
    };
  } else if (typeof parentPadding === 'object') {
    styleObj = {
      ...styleObj,
      marginLeft: parentPadding ? -parentPadding.left : 0,
      marginRight: parentPadding ? -parentPadding.right : 0,
      paddingLeft: parentPadding.left,
      paddingRight: parentPadding.right,
      buttonLeftPosition: parentPadding.left + DEFAULT_BUTTON_OFFSET_IN_PX,
      buttonRightPosition: parentPadding.right + DEFAULT_BUTTON_OFFSET_IN_PX,
    };
  }

  return styleObj;
};

/**
 * This is a carousel component.
 *
 * It's named ThumbnailDisplay to have parity with the name the Design team uses.
 */
export const ThumbnailDisplay = forwardRef<HTMLDivElement, TThumbnailDisplayProps>(
  ({files, className, parentPadding = 0, thumbnailClassName, totalSuccessFilesCount, withLightbox, useFileDimensions}, ref) => {
    const tooltipPortalElement = useTooltipSetup();
    /* Lightbox concerns */
    const {isLightboxOpen, toggleLightbox, targetIndex, slides, carouselProps, modifiedFiles} = useLightboxDisplay({files, withLightbox});

    /* Carousel concerns */
    const {carouselRef, handleArrowClick, translateX, isLeftArrowVisible, isRightArrowVisible} = useCarousel({numFiles: modifiedFiles.length});
    const {marginLeft, marginRight, paddingLeft, paddingRight, buttonLeftPosition, buttonRightPosition} = calculateStyles(parentPadding);
    const containerStyles = {marginLeft, marginRight, paddingLeft, paddingRight};
    const carouselStyles = {transform: `translateX(${translateX}px)`}; // Apply translateX

    return (
      <div className={cn(styles.thumbnailCarouselContainer, className)} ref={ref} style={containerStyles}>
        <div ref={carouselRef} className={styles.thumbnailCarousel} style={carouselStyles}>
          {[...Array(totalSuccessFilesCount)?.keys()].map(preloadNumber => {
            return <ThumbnailPreload key={preloadNumber} thumbnailClassName={thumbnailClassName} />;
          })}
          {modifiedFiles.map((file: TThumbnailFile) => {
            return (
              <Thumbnail
                useFileDimensions={useFileDimensions}
                key={`${file.name}${file.url}`}
                file={file}
                tooltipPortalElement={tooltipPortalElement}
                suppressNameplate={file.suppressNameplate}
                thumbnailClassName={thumbnailClassName}
              />
            );
          })}
        </div>
        <CarouselButton direction="left" visible={isLeftArrowVisible} onClick={() => handleArrowClick('left')} style={{left: buttonLeftPosition}} />
        <CarouselButton direction="right" visible={isRightArrowVisible} onClick={() => handleArrowClick('right')} style={{right: buttonRightPosition}} />
        {isLightboxOpen && <HTLightbox slides={slides} index={targetIndex} open={isLightboxOpen} onClose={toggleLightbox} showThumbnails={slides.length > 1} carousel={carouselProps} />}
      </div>
    );
  }
);
