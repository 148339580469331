import {useQuery} from 'react-query';
import useApi from 'hooks/useApi';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {showErrorToast, logResponseError} from 'queries/query.utils';
import {SKUS_QUERY_KEYS} from './skus.query.keys';
import {Sku} from './types/sku.types';

type TUseGetSkuQueryResponse = WithErrorResponse<{sku: Sku}>;

type TUseGetSkuQueryParams = {
  /**
   * sku id to fetch on
   */
  id: number;
  useLoader?: boolean;
  queryOpts?: Parameters<typeof useQuery<TUseGetSkuQueryResponse['data']>>['2'];
};

/**
 * Fetch sku details by id
 */
const useGetSkuQuery = ({id, useLoader = false, queryOpts}: TUseGetSkuQueryParams) => {
  const api = useApi();
  const dispatch = useAppDispatch();

  return useQuery<TUseGetSkuQueryResponse['data']>(
    SKUS_QUERY_KEYS.getSku([id]),
    async () => {
      if (useLoader) {
        api.toggleLoader(true);
      }
      const response: TUseGetSkuQueryResponse = await api.skus.get({id});
      if (useLoader) {
        api.toggleLoader(false);
      }
      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Retrieving Sku'});
        logResponseError('Skus')(response.err);
        throw new Error('Error Retrieving Sku');
      }
      return response?.data;
    },
    queryOpts
  );
};

export default useGetSkuQuery;
