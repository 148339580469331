import React, {memo} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Button, BUTTON_THEMES, Modal, htToast, ELEMENT_SIZE} from 'ht-styleguide';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {TSingleActionTypes, CurrentProjectStages} from 'features/MultiDwellingUnits/MDU.types';
import {useResumeProjectMutation} from 'features/MultiDwellingUnits/queries/mutation.project.pauseActions';
import {mduProjectPathByStage} from 'global/paths';
import {useAppDispatch} from 'hooks/useAppDispatch';

interface IResumeProjectModal {
  /**
   * Used for slight variations in UI. Another alternative is to parse the url, but
   * the boolean was more convenient atm.
   */
  isSingleProjectView?: boolean;
}

const ResumeProjectModal = ({isSingleProjectView = false}: IResumeProjectModal) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const actionItem = useSelector(mduProjectsSlice.selectors.getActionItem);

  const {id: projectId, name: projectName} = actionItem?.entity || {};
  const modalVisible = actionItem?.modalKey === TSingleActionTypes.RESUME;

  const {mutate} = useResumeProjectMutation();

  const closeModal = () => dispatch(mduProjectsSlice.actions.removeActionItemModalSlide());

  const redirectToProjectView = () => navigate(mduProjectPathByStage(String(projectId), CurrentProjectStages.PROJECT_OVERVIEW));

  const onSubmit = () => {
    if (projectId) {
      mutate(
        {projectId},
        {
          onSuccess: () => {
            closeModal();
            htToast(
              <div className="h6 n000">
                {projectName} Resumed.
                {!isSingleProjectView && (
                  <>
                    {' '}
                    <span className="text-underline" onClick={redirectToProjectView}>
                      View Project
                    </span>
                  </>
                )}
              </div>,
              {autoClose: false}
            );
          },
        }
      );
    }
  };

  if (!modalVisible) return null;

  return (
    <Modal
      elementSize={ELEMENT_SIZE.MEDIUM}
      isVisible={modalVisible}
      hide={closeModal}
      header="Resume project"
      footerElement2={
        <Button onClick={closeModal} theme={BUTTON_THEMES.SECONDARY}>
          Nevermind
        </Button>
      }
      footerElement3={
        <Button onClick={onSubmit} theme={BUTTON_THEMES.PRIMARY}>
          Resume Project
        </Button>
      }
    >
      <p className="p1">Are you sure you want to resume {projectName}?</p>
    </Modal>
  );
};

export default memo(ResumeProjectModal);
