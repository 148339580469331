import {STATUSES_PAUSE} from 'features/MultiDwellingUnits/MDU.constants';
import useGetProjectDetailsQuery from 'features/MultiDwellingUnits/queries/query.project.getDetails';
import {ISSUE_ENTITY_TYPES} from 'features/Issues/Issues.constants';

type TUseProjectEntityBehaviors = {
  issueEntity: (typeof ISSUE_ENTITY_TYPES)[keyof typeof ISSUE_ENTITY_TYPES];
  entityId: string | number | undefined | null;
};

/**
 * Separating into own hook as we are forecasting multiple entity types.
 */
export const useProjectEntityBehaviors = ({issueEntity, entityId}: TUseProjectEntityBehaviors) => {
  const isProjectEntity = issueEntity === ISSUE_ENTITY_TYPES.project;

  const {data, isLoading, refetch} = useGetProjectDetailsQuery({projectId: entityId, queryOpts: {enabled: isProjectEntity}});
  const {paused, status} = data || {};

  const withinPauseOptionEligibility = STATUSES_PAUSE.includes(status!);
  const disablePauseProjectOpt = paused || !withinPauseOptionEligibility;

  return {
    showPauseProjectOpt: isProjectEntity,
    disablePauseProjectOpt,
    isLoadingProjectDetails: isLoading,
    refetchProjectDetails: refetch,
  };
};
