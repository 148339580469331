/**
 * Flatten nested object so keys are in dot notation. Will not flatten arrays.
 *
 * {
 *   a: { b: 1, c: 2 },
 *   d: 3,
 *   e: [4, 5, 6],
 * }
 *
 * ----
 *
 * {
 *   'a.b': 1,
 *   'a.c': 2,
 *   d: 3,
 *   e: [4, 5, 6],
 * }
 */
const flatten = (data: object, prefix = '', restrictedKeys: string[] = []) => {
  const result: Record<string, any> = {};

  Object.entries(data).forEach(([key, value]) => {
    if (typeof value === 'object' && !Array.isArray(value)) {
      if (restrictedKeys.includes(key)) {
        result[`${prefix}${key}`] = value;
      } else {
        Object.assign(result, flatten(value, `${prefix}${key}.`));
      }
    } else {
      result[`${prefix}${key}`] = value;
    }
  });

  return result;
};

export default flatten;
