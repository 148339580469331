import React from 'react';
import ReactTooltip from 'react-tooltip';
import JobsServicesTooltip from './Jobs.TooltipDisplay';

const JobsUnitToolTip = () => {
  return (
    <ReactTooltip
      effect="float"
      place="right"
      backgroundColor="white"
      offset={{right: 0, top: 0}}
      getContent={unitId => {
        if (!unitId) return null;

        return <JobsServicesTooltip unitId={unitId} />;
      }}
      id="unitAttributes"
    />
  );
};

export default JobsUnitToolTip;
