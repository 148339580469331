import React from 'react';

// Utils
import {bookingPreFill} from 'global/paths';

// Components
import {Panel, Button, BUTTON_THEMES} from 'ht-styleguide';

// Constants

interface EmptyStateProps {
  header: string;
  text: string;
  clientId?: number | undefined;
  name?: string;
}

const EmptyState = ({header, text, clientId = undefined, name}: EmptyStateProps) => {
  return (
    <Panel className="paddingY-large paddingX-medium">
      <div className="flex align-items-center flex-direction-column">
        <h4 className="marginBottom-tiny1">{header}</h4>
        <p className="p1 n700 marginBottom-tiny1">
          {name} {text}
        </p>
        {clientId && (
          <Button medium theme={BUTTON_THEMES.SECONDARY} isLink goTo={bookingPreFill(clientId)} target="_blank" rel="noreferrer" inlineBlock>
            Create New Order
          </Button>
        )}
      </div>
    </Panel>
  );
};

export default EmptyState;
