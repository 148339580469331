export const AUTH_TOKEN_KEY = 'HT_ADMIN_AUTH_TOKEN';

/* Auth Token helper to make it clear what we are doing */
export const authToken = (() => {
  const {localStorage} = window;
  const operations = (type: string, arg?: string) => {
    return localStorage[`${type}Item`](AUTH_TOKEN_KEY, arg);
  };

  return {
    getAuthToken: () => operations('get'),
    removeAuthToken: () => operations('remove'),
    setAuthToken: (arg: string) => operations('set', arg),
    getAuthTokenId: () => authToken.isValidJWT(true),
    isValidJWT: (raw = false): boolean | number => {
      const token = authToken.getAuthToken();

      try {
        const {id, email}: {id: number; email: string} = JSON.parse(atob(token.split('.')[1]));
        if (raw) {
          return id;
        }
        return Boolean(id || email);
      } catch (e) {
        return false;
      }
    },
  };
})();
