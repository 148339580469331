import React from 'react';

// feature items
import GeneralLayout from 'components/Layouts/General/General.layout';
import ProfileRoutes from './profile.routes';

const ProfilePage = () => {
  return (
    <GeneralLayout>
      <ProfileRoutes />
    </GeneralLayout>
  );
};

export default ProfilePage;
