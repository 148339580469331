// @ts-nocheck  // <--- temp
/**
 * This is for global commands that work better in a global environment.
 */
import {Dispatch, SetStateAction, useEffect} from 'react';
import {CLEAR_EDITOR_COMMAND, COMMAND_PRIORITY_NORMAL, createCommand, LexicalCommand} from 'lexical';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {htToast} from 'ht-styleguide';

/* Ducks / Hooks */
import EditorDuck from 'components/Editor/state/editor.ducks';
import {useAppDispatch} from 'hooks/useAppDispatch';

/* Utils */
import {getTextByNode} from 'components/Editor/utils/getTextByNode';

/* Types */
import {TEditor, TEditorToJson} from 'components/Editor/editor.types';
import {TFileSignCloudinaryResponse} from 'features/Cloudinary/cloudinary.types';

/* Subscriber commands */
export const SAVE_EDITOR_CONTENTS: LexicalCommand<string> = createCommand();
export const CANCEL_EDITOR_CONTENTS: LexicalCommand<string> = createCommand();
export const TRASH_EDITOR_COMMAND: LexicalCommand<string> = createCommand();

/**
 * Rather than having disparate commands, we can use this as a sorta command pattern.
 * Not really, but lets just coordinate them here.
 *
 * @param setEditorViewable
 * @constructor
 */
type TCommandPlugin<T> = {
  entityAttributes: TEditor<T>['entityAttributes'];
  setEditorViewable: Dispatch<SetStateAction<boolean>>;
};

export const COMMAND_CANCEL_OPERATIONS = {
  CLEAR_ALL_CLOSE: 'clearAllClose',
  CLEAR_ALL: 'clearAll',
} as const;

export const CommandPlugin = <T,>({setEditorViewable, entityAttributes}: TCommandPlugin<T>) => {
  const {useSaveMutation, entityType, entityId} = entityAttributes;
  /* Hooks */
  const [editor, getters] = useLexicalComposerContext();
  const dispatch = useAppDispatch();
  const saveMutation = useSaveMutation();

  /* Constants */
  const editorAttributes = getters?.getTheme()?.attributes || {};

  const RemoveAllState = () => {
    /* 1. remove any internal editor saved state */
    editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);

    /* 2. Remove data from state */
    dispatch(EditorDuck.actions.setEditorContents({editorNamespace: editorAttributes.namespace, content: null}));

    /* 3. Remove data form localStorage */
    localStorage?.removeItem(editorAttributes.namespace);
  };

  useEffect(() => {
    editor.registerCommand(
      SAVE_EDITOR_CONTENTS,
      (files: TFileSignCloudinaryResponse[] = []) => {
        /* Get the editor state */
        const editorState = editor.getEditorState();
        /* Convert to json for BE and js object */
        const lexical = editorState.toJSON() as TEditorToJson;
        /* Derive a stringed version of the input with mentions/links stringed out */
        const lexicalTextNodes = getTextByNode(lexical);

        /* Check to see if we can even submit. No text and no files */
        if (!lexicalTextNodes && !files.length) return true;

        /* Update json to supply backend with an id association */
        // @ts-ignore
        saveMutation.mutate(
          {
            entityId,
            entityType,
            lexicalTextNodes,
            files,
            lexical,
          },
          {
            onSuccess: () => {
              htToast('Comment posted');
              editor.dispatchCommand(CANCEL_EDITOR_CONTENTS, COMMAND_CANCEL_OPERATIONS.CLEAR_ALL_CLOSE);
            },
            onError: () => {
              htToast('Error saving editor contents.');
              editor.dispatchCommand(CANCEL_EDITOR_CONTENTS, COMMAND_CANCEL_OPERATIONS.CLEAR_ALL);
            },
          }
        );

        return true;
      },
      COMMAND_PRIORITY_NORMAL
    );

    editor.registerCommand(
      CANCEL_EDITOR_CONTENTS,
      (action: string = COMMAND_CANCEL_OPERATIONS.CLEAR_ALL_CLOSE) => {
        if (action === COMMAND_CANCEL_OPERATIONS.CLEAR_ALL_CLOSE) {
          /* 1. remove any internal editor saved state */
          RemoveAllState();
          /* 2. Close editor */
          setEditorViewable(false);
        }

        if (action === COMMAND_CANCEL_OPERATIONS.CLEAR_ALL) {
          /* 1. remove any internal editor saved state */
          RemoveAllState();
        }

        return true;
      },
      COMMAND_PRIORITY_NORMAL
    );

    editor.registerCommand(
      TRASH_EDITOR_COMMAND,
      // @ts-ignore
      () => {
        /* 1. remove any internal editor saved state */
        RemoveAllState();
      },
      COMMAND_PRIORITY_NORMAL
    );
  }, []);

  return null;
};
