import React, {useEffect} from 'react';
import {useSelector} from 'hooks/useAppSelector';
// Ducks
import cloudinarySlice from 'features/Cloudinary/state/cloudinary.ducks';
// Utils
import {isArraysEqual} from './attachmentsBuilder.utils';
import {useHTDropzoneValidatorHelpers} from 'components/Elements/HTDropzone/htDropzone.hooks';
// Types
import {ReferenceTypes, TaskFieldNames} from 'features/Products/types/taskGroups.types';
import {TTaskChecklistFormik, ReferenceFieldNames} from '../../taskChecklist.types';
// Constants
import {SHARED_CLOUDINARY_FOLDERS} from 'features/Cloudinary/cloudinary.constants';
import {CLOUDINARY_TASK_SIDESHEET_NAMESPACE} from '../../constants/main';
import {TASK_MAX_FILE_SIZE, ACCEPTED_FILE_TYPES} from './attachmentsBuilder.constants';
// Components
import CloudinaryAttachmentsField from 'features/Cloudinary/components/CloudinaryAttachmentsField';
import {Form, InputField} from 'ht-styleguide';

interface IAttachmentsBuilderProps {
  formik: TTaskChecklistFormik;
}

const AttachmentsBuilder = ({formik}: IAttachmentsBuilderProps) => {
  const {reference} = formik.values;
  const {reference_type, attachments, title} = reference || {};
  const isFileReference = reference_type === ReferenceTypes.File;
  const pathToReferenceTitle = `${TaskFieldNames.Reference}.${ReferenceFieldNames.Title}`;
  const cloudinaryAttachments = useSelector(cloudinarySlice.selectors.getFileSuccessUploads(CLOUDINARY_TASK_SIDESHEET_NAMESPACE));
  const {reactDropzoneValidator, onDropRejected} = useHTDropzoneValidatorHelpers({acceptedFileTypes: ACCEPTED_FILE_TYPES});
  /**
   * Sync cloudinary attachments with formik values. This implementation is a inefficient since we could just
   * add the attachments to the payload upon submission. But, this implementation provides an easier way to
   * do validation.
   */
  useEffect(() => {
    if (Array.isArray(cloudinaryAttachments) && Array.isArray(attachments)) {
      if (!isArraysEqual(attachments, cloudinaryAttachments)) {
        formik.setFieldValue(`${TaskFieldNames.Reference}.${ReferenceFieldNames.Attachments}`, cloudinaryAttachments);
      }
    }
  }, [attachments, cloudinaryAttachments, formik]);

  return isFileReference ? (
    <>
      <Form.Row>
        <Form.Column lg={12} md={8} sm={4}>
          <InputField
            id={pathToReferenceTitle}
            label="Display title"
            onChange={formik.handleChange}
            value={title}
            // @ts-ignore
            error={formik.errors.reference?.title}
          />
        </Form.Column>
      </Form.Row>
      <Form.Row>
        <Form.Column lg={12} md={8} sm={4}>
          <CloudinaryAttachmentsField
            maxFileSize={TASK_MAX_FILE_SIZE}
            namespace={CLOUDINARY_TASK_SIDESHEET_NAMESPACE}
            preseedAttachments={attachments}
            // @ts-ignore
            error={formik.errors.reference?.attachments}
            validator={reactDropzoneValidator}
            onDropRejected={onDropRejected}
            folder={SHARED_CLOUDINARY_FOLDERS.OrderTasks}
          />
        </Form.Column>
      </Form.Row>
    </>
  ) : null;
};

export default AttachmentsBuilder;
