import React, {useMemo, ComponentProps, useCallback} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {CHIP_TYPES} from 'ht-styleguide';
import cn from 'classnames';
import get from 'lodash/get';
import isPlainObject from 'lodash/isPlainObject';
import DataTable from 'components/Elements/DataTable';
import {onColumnSizingChangeUtil, onColumnOrderChangeUtil} from 'components/Elements/DataTable/utils/columns.utils';
import {AvatarCellComponent, ChipCellComponent, IconCellComponent} from 'components/Elements/DataTable/CellComponent';
import {TPagination, TDataTableColumnDef} from 'components/Elements/DataTable/dataTable.types';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useSelector} from 'hooks/useAppSelector';
import issuesSlice from 'features/Issues/Issues.ducks';
import {ISSUE_FIELD_ENTITY_TYPE, ISSUE_SLIDESHEET_TYPES} from 'features/Issues/Issues.constants';
import {IssuesSearchFieldNames, TIssuesTicketsData} from 'features/Issues/issues.types';
import useGetTableIssuesQuery from 'features/Issues/queries/query.issues.table.getIssues';
import useIssuesRouteParams from 'features/Issues/hooks/useIssuesRouteParams';
import useVisibleTableSearchFields from 'features/Issues/hooks/useVisibleTableSearchFields';
import useResetIssuesTableStates from 'features/Issues/hooks/useResetIssuesTableStates';
import {getAttributesByPriorityStatus, buildSideSheetQueryParam} from 'features/Issues/Issues.utils';
import {formatDate} from 'utils/date';
import {MAX_COLUMN_SORT_COUNT_WITH_GROUPING, MAX_COLUMN_SORT_COUNT_DEFAULT} from './issuesTable.constants';

const IssuesTable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const {data: issuesListData} = useGetTableIssuesQuery();

  const {issuesNavType} = useIssuesRouteParams();
  const {columnState, columnOrder, columnSort, columnGrouping, pagination: paginationState} = useSelector(issuesSlice.selectors.getPagesStateByKey(issuesNavType!)) || {};
  const columnGroupingKey = columnGrouping[0];

  useResetIssuesTableStates();

  /**
   ********************************************************
   * Column Visibility and Rendering
   ********************************************************
   */

  const {visibleSearchFieldsData} = useVisibleTableSearchFields();

  /**
   * Transform searchFieldsData into tableColumnData for DataTable component. This will be used to
   * render the table columns - column label, format column data, special cases, etc.
   */
  const tableColumnData: TDataTableColumnDef<TIssuesTicketsData>[] = visibleSearchFieldsData.map(fieldData => {
    const {name, label, type, entity_type, search} = fieldData;

    const columnData: TDataTableColumnDef<TIssuesTicketsData> = {
      id: name,
      header: label || '',
      enableSorting: search?.sortable ?? false,
    };

    /**
     * In most cases, dataKey can be used as is to access data from the row object.
     * Special cases:
     *   - `entity.blah_id` -> If the key has a period, we can assume that it will access a nested
     *     object. We can also assume that if it ends with `_id` or `_ids`, we can remove them from
     *     the dataKey to create a new key that will access the nested object.
     *   - `entity_id` -> We're going to use this to access `entity.name` instead.
     */
    let dataKey = name;
    dataKey = dataKey === IssuesSearchFieldNames.EntityId ? 'entity' : dataKey;
    dataKey = dataKey.includes('.') ? dataKey.replace(/_ids?$/, '') : dataKey;

    /**
     * For displaying data within the cell. Try to return data as an array, string, or number.
     * The value will be used in the `Cell` component. For special cases, let `Cell` handle the rendering.
     */
    const accessorFn = (originalRow: TIssuesTicketsData) => {
      const valueAtKey = get(originalRow, dataKey, '');

      switch (type) {
        case 'datetime': {
          return valueAtKey ? formatDate(valueAtKey) : '';
        }
        case 'multi_select': {
          return (valueAtKey || []).map((val: any) => val?.name);
        }
        default: {
          break;
        }
      }

      /**
       * Not all object-shaped values will have the same structure. This is a bit of hard-coding, and needs
       * alignment with the BE, but it's the best we can do for now.
       */
      if (isPlainObject(valueAtKey)) {
        if (name === IssuesSearchFieldNames.IssueType) {
          return valueAtKey?.label;
        }
        return valueAtKey?.name;
      }

      return valueAtKey;
    };

    /**
     * Use this to customize the cell rendering. Most data will be rendered as a string/number. However,
     * there are some special cases that need to be handled differently.
     * Note: `renderedCellValue` is what is returned from `accessorFn` or `accessorKey` if `accessorFn` is not defined.
     */
    const Cell: TDataTableColumnDef<TIssuesTicketsData>['Cell'] = ({row, renderedCellValue, column, table}) => {
      if (entity_type === ISSUE_FIELD_ENTITY_TYPE.admin) {
        const {profile_picture, name: adminName} = get(row.original, dataKey, {}) || {};

        let avatarComponent = null;
        if (profile_picture) {
          const {retina_thumb} = profile_picture;
          const imageUrl = retina_thumb.includes('/default') ? null : retina_thumb;
          avatarComponent = <AvatarCellComponent image={imageUrl} name={adminName} imageAlt={adminName} table={table} />;
        }

        return (
          <div className="flex align-items-center">
            {avatarComponent}
            <span className="marginLeft-tiny1">{adminName}</span>
          </div>
        );
      }

      if (Array.isArray(renderedCellValue)) {
        if (column.id === IssuesSearchFieldNames.TagIds) {
          return renderedCellValue.map((tag: string) => (
            <ChipCellComponent key={tag} variant="outlined" type={CHIP_TYPES.neutral} classes="marginRight-tiny" table={table}>
              {tag}
            </ChipCellComponent>
          ));
        }
        return <>{renderedCellValue.join(', ')}</>;
      }

      if (column.id === IssuesSearchFieldNames.PriorityId) {
        const attributes = getAttributesByPriorityStatus(row.original.priority_id?.id);
        return attributes ? <IconCellComponent name={attributes.icon.iconDefault} className={cn(attributes.icon.className)} table={table} /> : null;
      }

      return renderedCellValue;
    };

    columnData.accessorFn = accessorFn;
    columnData.Cell = Cell;

    return columnData;
  });

  /**
   ********************************************************
   * Column Ordering via Drag and Drop
   ********************************************************
   */
  const tableColumnOrdering = useMemo(() => {
    const ordered = [...columnOrder];
    const visibleColumnsKeys = visibleSearchFieldsData.map(fieldData => fieldData.name);
    const leftOverColumns = visibleColumnsKeys.filter((key: string) => !ordered.includes(key));
    return [...ordered, ...leftOverColumns];
  }, [columnOrder, visibleSearchFieldsData]);

  const onColumnOrderChange = onColumnOrderChangeUtil({
    onChangeCB: newColumnOrderArr => dispatch(issuesSlice.actions.updateColumnOrder({columnOrder: newColumnOrderArr, issuesNavType: issuesNavType!})),
  });

  /**
   ********************************************************
   * Column Width Sizing
   ********************************************************
   */
  const columnsWidth = columnState
    ? Object.keys(columnState).reduce((acc, key: string) => {
        const {columnWidth} = columnState[key];
        if (columnWidth) {
          acc[key] = columnWidth;
        }
        return acc;
      }, {} as {[key: string]: number})
    : {};

  const onColumnSizingChange = onColumnSizingChangeUtil({
    currentColumnsWidthState: columnsWidth,
    onChangeCB: newColumnWidths => dispatch(issuesSlice.actions.updateColumnWidths({columnWidths: newColumnWidths, issuesNavType: issuesNavType!})),
  });

  /**
   ********************************************************
   * Column Sorting
   *
   * There's grouping logic here because grouping and sorting are the same idea in the BE.
   * So, to "group" on a column, use the `columnSort` redux state, which is used directly
   * in the query.
   * When grouping is enabled, we always want it to the be first sort parameter. However, due
   * to constraints with the `DataTable` component, we cannot permanently set the first sort
   * on the grouped column without manual intervention.
   ********************************************************
   */
  type TOnSortingChange = ComponentProps<typeof DataTable>['onSortingChange'];
  const onSortingChange: TOnSortingChange = updater => {
    if (typeof updater === 'function') {
      const newColumnSort = updater(columnSort);

      /**
       * If there's a column grouping, we want to make sure that the grouped column is always
       * the first sort parameter.
       */
      if (columnGroupingKey) {
        const columnGroupingKeyIndex = newColumnSort.findIndex((sort: any) => sort.id === columnGroupingKey);
        if (columnGroupingKeyIndex === -1) {
          newColumnSort.unshift({id: columnGroupingKey, desc: false});
        } else {
          const columnGroupingKeySort = newColumnSort[columnGroupingKeyIndex];
          newColumnSort.splice(columnGroupingKeyIndex, 1);
          newColumnSort.unshift(columnGroupingKeySort);
        }

        /**
         * If there's more than `MAX_COLUMN_SORT_COUNT_WITH_GROUPING` sort parameters, we need to remove the middle sort parameters.
         * First one will always be the grouped parameter
         */
        if (newColumnSort.length > MAX_COLUMN_SORT_COUNT_WITH_GROUPING) {
          newColumnSort.splice(1, newColumnSort.length - MAX_COLUMN_SORT_COUNT_WITH_GROUPING);
        }
      }

      dispatch(issuesSlice.actions.updateColumnSort({columnSort: newColumnSort, issuesNavType: issuesNavType!}));
    }
  };

  // Let's enable multi-sorting if there's a column grouping
  const enableMultiSort = !!columnGroupingKey;
  // The value when `enableMultiSort` just needs to be higher than 1. We're doing some
  // manual logic to make sure that the grouped column is always the first sort parameter.
  const maxMultiSortColCount = enableMultiSort ? MAX_COLUMN_SORT_COUNT_WITH_GROUPING : MAX_COLUMN_SORT_COUNT_DEFAULT;

  /**
   ********************************************************
   * Pagination
   ********************************************************
   */

  const tablePagination = useMemo(() => {
    const pagination: TPagination = {
      pageIndex: paginationState.current_page - 1,
      pageItemCount: paginationState.items_per_page,
      totalPages: paginationState.total_pages,
      onPaginationChange: ({pageIndex, itemCount}) => {
        dispatch(issuesSlice.actions.updatePagination({issuesNavType: issuesNavType!, pagination: {current_page: pageIndex + 1, items_per_page: itemCount}}));
      },
    };
    return pagination;
  }, [dispatch, issuesNavType, paginationState.current_page, paginationState.items_per_page, paginationState.total_pages]);

  /**
   ********************************************************
   * Others
   ********************************************************
   */

  const muiTableBodyRowProps = useCallback<Extract<ComponentProps<typeof DataTable<TIssuesTicketsData>>['muiTableBodyRowProps'], Function>>(
    ({row}) => {
      const ticket = row.original;
      return {
        onClick: () => {
          navigate(`${location.pathname}?${buildSideSheetQueryParam({issueMode: ISSUE_SLIDESHEET_TYPES.issue_edit, entityType: ticket.entity_type, ticketId: ticket.id})}`);
        },
      };
    },
    [location.pathname, navigate]
  );

  return (
    <div>
      <DataTable<TIssuesTicketsData>
        columns={tableColumnData}
        data={issuesListData}
        enableColumnResizing
        enableColumnDragging
        enableColumnOrdering
        enableMultiSort={enableMultiSort}
        hasActionableRows
        isZebra
        isMultiSortEvent={() => true}
        manualSorting
        maxMultiSortColCount={maxMultiSortColCount}
        muiTableBodyRowProps={muiTableBodyRowProps}
        onColumnOrderChange={onColumnOrderChange}
        onColumnSizingChange={onColumnSizingChange}
        onSortingChange={onSortingChange}
        pagination={tablePagination}
        showPaginator
        state={{sorting: columnSort, columnPinning: {left: [IssuesSearchFieldNames.Summary]}, columnSizing: columnsWidth, columnOrder: tableColumnOrdering, grouping: columnGrouping}}
        tableKey={issuesNavType}
      />
    </div>
  );
};

export default IssuesTable;
