import {STATES_ARRAY} from 'utils/states';

export const YES_NO_OPTIONS = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
];

export const TIER_OPTIONS = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '4',
    value: '4',
  },
];

export const STATE_OPTIONS = STATES_ARRAY.map(([abbr, stateName]) => ({label: stateName, value: abbr}));
