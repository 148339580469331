import React from 'react';
import cn from 'classnames';
import DataWidget from 'components/Elements/DataWidget';
import {IWidgetDashboard, TWidgetItem} from './widgetDashboard.types';
import styles from './widgetDashboard.styles.scss';

/**
 * WidgetDashboard
 *
 * This component is relying on `display: grid` to lay out its children. Implementation is quite
 * basic, but it should be sufficient for the needs of this component.
 *
 * Specify the number of columns and rows in the grid, and then use `WidgetDashboard.Widget` and fill out
 * its props to determine its grid placement.
 *
 * Example:
 * <WidgetDashboard numCols={3} numRows={1}>
 *  <WidgetDashboard.Widget gridColumn="1 / 2" {...dataWidgetProps} />
 *  <WidgetDashboard.Widget gridColumn="2 / 4" {...dataWidgetProps} />
 * </WidgetDashboard>
 */
const WidgetDashboard = ({children, className, dataTestId, noVerticalPadding = false, noHorizontalPadding = false, numCols = 1, numRows = 1}: IWidgetDashboard) => {
  // Outer container styles
  const outerStyles = cn(
    'bg-n100',
    {
      'paddingX-medium': !noHorizontalPadding,
      'paddingY-small1': !noVerticalPadding,
    },
    className
  );

  // Build grid container via props
  const gridStyles = {
    gridTemplateColumns: `repeat(${numCols}, 1fr)`,
    gridTemplateRows: `repeat(${numRows}, 1fr)`,
  };

  return (
    <div className={outerStyles} data-testid={dataTestId}>
      <div className={styles.widgetItemsContainer} style={gridStyles}>
        {children}
      </div>
    </div>
  );
};

/**
 * A wrapper around `<DataWidget />` that can be used as a child of `<WidgetDashboard />`.
 * Pass props to determine its grid placement.
 *
 * <WidgetDashboard.Widget gridColumn="1 / 3" gridRow="1 / 2" />
 */
const Widget = ({gridColumn, gridRow, ...dataWidgetProps}: TWidgetItem) => {
  const containerInlineStyle = {
    gridColumn,
    gridRow,
  };

  return (
    <div style={containerInlineStyle}>
      <DataWidget className="fullheight" {...dataWidgetProps} />
    </div>
  );
};

WidgetDashboard.Widget = Widget;

export default WidgetDashboard;
