import Cookies from 'js-cookie';

const PROJECT_CREATION_VALIDATE_UNITS = '_project_creation_validate_units';

export const setProjectUnitValidatedCookie = (pid: string, value = '') => {
  const currentCookie = JSON.parse(Cookies.get(PROJECT_CREATION_VALIDATE_UNITS) || '{}');

  Cookies.set(PROJECT_CREATION_VALIDATE_UNITS, JSON.stringify({...currentCookie, [pid]: value}));
};

export const getProjectUnitValidatedCookie = (pid: string) => {
  const value = Cookies.get(PROJECT_CREATION_VALIDATE_UNITS);
  return value ? JSON.parse(value)?.[pid] : null;
};
