import {IDeviceSelectorState, CustomDropdownValues, TIDKText} from './deviceSelector.types';

export const DEVICE_SELECTOR_INITIAL_STATE: IDeviceSelectorState = {
  isModelFieldDisabled: false,
  makeFilterInput: '',
  modelFilterInput: '',
  showMakeInput: false,
  showModelInput: false,
};

export const CUSTOM_DROPDOWN_OPTIONS = {
  I_DONT_KNOW: {value: CustomDropdownValues.negOne, label: TIDKText, searchable: false},
  OTHER: {value: CustomDropdownValues.empty, label: 'Other', searchable: false},
  NO_OPTIONS: {value: CustomDropdownValues.zero, label: 'Sorry, we were unable to find a match', isDisabled: true, searchable: false},
};
