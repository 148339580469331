import React from 'react';
import cn from 'classnames';
import {useNavigate} from 'react-router-dom';
// Hooks && Utils
import {useSelector} from 'react-redux';
import {useFilteredProjects} from 'features/MultiDwellingUnits/queries/query.project.paginatedFiltered';
import {noop} from 'utils/event';
import {routeToProjectPage} from 'features/MultiDwellingUnits/Pages/Projects/utils/utils.routeToProjectPage';
import {ACTION_MENU_COL_ID} from 'components/Elements/DataTable/dataTable.constants';
// Types && Ducks
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {NavPageTypes, ProjectDetailsShallow} from 'features/MultiDwellingUnits/MDU.types';
import {TColumnKey} from 'features/MultiDwellingUnits/Pages/Projects/Parts/MDUProjectsTable/MDUProjectsTable.types';
// Components & Styles
import MDUProjectsTable from 'features/MultiDwellingUnits/Pages/Projects/Parts/MDUProjectsTable/MDUProjectsTable';
import {EmptyState} from 'ht-styleguide';
import styles from './search.styles.scss';

export const EMPTY_STATE_TEXT_DEFAULT = {HEADER: 'Search for a project', TEXT: 'Type in keywords to find a project by name, partner, etc.'};
export const EMPTY_STATE_TEXT_NO_RESULTS = {HEADER: (searchText = '') => `No results for ${searchText}`, TEXT: 'Type in keywords to find a project by name, partner, etc.'};

/** Show a different message if the user has attempted a search and received no results */
export const getEmptyStateText = (isSearchComplete = false, searchString = '') => {
  if (isSearchComplete) return {header: EMPTY_STATE_TEXT_NO_RESULTS.HEADER(searchString), text: EMPTY_STATE_TEXT_NO_RESULTS.TEXT};
  return {header: EMPTY_STATE_TEXT_DEFAULT.HEADER, text: EMPTY_STATE_TEXT_DEFAULT.TEXT};
};

export const columnKeys: TColumnKey[] = ['name', 'partner', 'start', 'end', 'pm', 'teamlead', 'status', ACTION_MENU_COL_ID];

const SearchResults = ({toggleSearch = noop}) => {
  const navigate = useNavigate();
  const searchTerm = useSelector(mduProjectsSlice.selectors.getSearchTerm) as string;
  /* This should be a bit more robust. It's a little haphazard. But I assumed they'd want pagi on search but not sure. */
  const {results, isFetched, pagination} = useFilteredProjects({pageFilters: {search: searchTerm}, options: {enabled: Boolean(searchTerm)}});
  const validSearchNoResults = isFetched && !results.length;
  const emptyStateText = getEmptyStateText(Boolean(isFetched && searchTerm), searchTerm);

  const handleRouteToProjectPage = (project: ProjectDetailsShallow) => {
    toggleSearch();
    routeToProjectPage({navigate, project});
  };

  return (
    <div className={cn(styles.searchResultsContainer, 'paddingBottom-huge1 paddingLeft-huge1 paddingRight-huge1')}>
      {searchTerm && !validSearchNoResults ? (
        <MDUProjectsTable isActionableRow pageType={NavPageTypes.SEARCH} columnKeys={columnKeys} projectData={results} paginationDataProp={pagination} onRowClickCb={handleRouteToProjectPage} />
      ) : (
        <div style={{overflowY: 'auto', height: '100%', paddingTop: '202px'}}>
          <EmptyState title={emptyStateText.header} text={emptyStateText.text} iconName="search" />
        </div>
      )}
    </div>
  );
};

export default React.memo(SearchResults);
