// Hooks
import {useDispatch} from 'react-redux';
import {useMutation} from 'react-query';
import useApi from 'hooks/useApi';
import {useInvalidateQueriesPagination} from 'features/MultiDwellingUnits/Pages/Projects/hooks';
// Types
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {TEntity} from 'features/MultiDwellingUnits/Parts/SideSheets/BulkEdit/mdu.sideSheet.bulkEdit.types';
import {TProjectActionResponse, TServiceBulkAddSkuRequestFormat} from 'features/MultiDwellingUnits/MDU.types';
// Constants
import {FULFILLED} from 'global/constants/common';
// Utils
import {logger} from 'utils/logger';
import unWrapError from 'utils/request/requestError';
import {showErrorToast} from 'queries/query.utils';

/**
 * Collect applicable/non-applicable list of projects that can be approved.
 */
export const useApproveProjectActionMutation = () => {
  const api = useApi();
  const dispatch = useDispatch();

  return useMutation<TProjectActionResponse, unknown, {selectAll: boolean; projectIds: number[]; filters: {}}>(
    async ({selectAll, projectIds, filters = {}}) => {
      api.toggleLoader(true);
      const response = await api.mdu.approveProjectsAction({
        selection: {
          select_all: selectAll,
          project_ids: projectIds,
          filters,
        },
      });
      api.toggleLoader(false);

      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Approve Projects Action Mutation'});
        throw new Error(`error approving projects: ${String(response.err)}`);
      }
      return response?.data?.project_action;
    },
    {
      onError: response => {
        logger('Error Approve Projects Action Mutation: ')(response as Error);
      },
    }
  );
};

/**
 * Collect applicable/non-applicable deletable projects. Projects are not "deleted" with this
 * endpoint. See `usePerformProjectActionMutation` for performing the associated action.
 */
export const useDeleteProjectActionMutation = () => {
  const api = useApi();
  const dispatch = useDispatch();

  return useMutation<TProjectActionResponse, unknown, {selectAll: boolean; projectIds: number[]; filters: {}}>(
    async ({selectAll, projectIds, filters = {}}) => {
      api.toggleLoader(true);
      const response = await api.mdu.deleteProjectsAction({
        selection: {
          select_all: selectAll,
          project_ids: projectIds,
          filters,
        },
      });
      api.toggleLoader(false);

      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Delete Projects Action Mutation'});
        throw new Error(`error deleting projects: ${String(response.err)}`);
      }
      return response?.data?.project_action;
    },
    {
      onError: response => {
        logger('Error Delete Projects Action Mutation: ')(response as Error);
      },
    }
  );
};

/**
 * Collect applicable/non-applicable approvable projects
 */
export const useApproveUnitActionMutation = () => {
  const api = useApi();
  const dispatch = useDispatch();

  return useMutation<TProjectActionResponse, unknown, {selectAll: boolean; unitIds: number[]; projectId: number; filters: {}}>(
    async ({selectAll, unitIds, projectId, filters = {}}) => {
      api.toggleLoader(true);
      const response = await api.mdu.approveUnitsAction(
        {projectId},
        {
          selection: {
            select_all: selectAll,
            unit_ids: unitIds,
            filters,
          },
        }
      );
      api.toggleLoader(false);

      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Approve Units Action Mutation'});
        throw new Error(`error approving units: ${String(response.err)}`);
      }
      return response?.data?.project_action;
    },
    {
      onError: response => {
        logger('Error Approve Units Action Mutation: ')(response as Error);
      },
    }
  );
};

export const useDeleteUnitActionMutation = () => {
  const api = useApi();
  const dispatch = useDispatch();

  return useMutation<TProjectActionResponse, unknown, {selectAll: boolean; unitIds: number[]; projectId: number; filters: {}}>(
    async ({selectAll, unitIds, projectId, filters = {}}) => {
      api.toggleLoader(true);
      const response = await api.mdu.deleteUnitsAction(
        {projectId},
        {
          selection: {
            select_all: selectAll,
            unit_ids: unitIds,
            filters,
          },
        }
      );
      api.toggleLoader(false);

      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Delete Units Action Mutation'});
        throw new Error(`error deleting units: ${String(response.err)}`);
      }
      return response?.data?.project_action;
    },
    {
      onError: response => {
        logger('Error Delete Units Action Mutation: ')(response as Error);
      },
    }
  );
};

export const useCannotCompleteUnitActionMutation = () => {
  const api = useApi();
  const dispatch = useDispatch();

  return useMutation<TProjectActionResponse, unknown, {selectAll: boolean; unitIds: number[]; projectId: number; filters: {}}>(
    async ({selectAll, unitIds, projectId, filters = {}}) => {
      api.toggleLoader(true);
      const response = await api.mdu.cannotCompleteUnitsAction(
        {projectId},
        {
          selection: {
            select_all: selectAll,
            unit_ids: unitIds,
            filters,
          },
        }
      );
      api.toggleLoader(false);

      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Marking Units Cannot Complete Action Mutation'});
        throw new Error(`error marking cannot complete units: ${String(response.err)}`);
      }
      return response?.data?.project_action;
    },
    {
      onError: response => {
        logger('Error Marking Units Cannot Complete Action Mutation: ')(response as Error);
      },
    }
  );
};

export const useReopenUnitActionMutation = () => {
  const api = useApi();
  const dispatch = useDispatch();

  return useMutation<TProjectActionResponse, unknown, {selectAll: boolean; unitIds: number[]; projectId: number; filters: {}}>(
    async ({selectAll, unitIds, projectId, filters = {}}) => {
      api.toggleLoader(true);
      const response = await api.mdu.reopenUnitsAction(
        {projectId},
        {
          selection: {
            select_all: selectAll,
            unit_ids: unitIds,
            filters,
          },
        }
      );
      api.toggleLoader(false);

      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Reopen Action Mutation'});
        throw new Error(`error reopening units: ${String(response.err)}`);
      }
      return response?.data?.project_action;
    },
    {
      onError: response => {
        logger('Error Reopen Action Mutation: ')(response as Error);
      },
    }
  );
};

export const useSwapTemplateActionMutation = () => {
  const api = useApi();
  const dispatch = useDispatch();

  return useMutation<TProjectActionResponse, unknown, {selectAll: boolean; projectId: number; unitIds: number[]; filters: {}}>(
    async ({selectAll, projectId, unitIds, filters = {}}) => {
      api.toggleLoader(true);
      const response = await api.mdu.swapTemplateAction(
        {projectId},
        {
          selection: {
            select_all: selectAll,
            unit_ids: unitIds,
            filters,
          },
        }
      );
      api.toggleLoader(false);

      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Swap Template Action Mutation'});
        throw new Error(`error swapping template: ${String(response.err)}`);
      }
      return response?.data?.project_action;
    },
    {
      onError: response => {
        logger('Error Swap Template Action Mutation: ')(response as Error);
      },
    }
  );
};

export const useAddServiceToUnitsMutation = () => {
  const api = useApi();
  const dispatch = useDispatch();

  return useMutation<TProjectActionResponse, unknown, {selectAll: boolean; unitIds: number[]; projectId: number; sku: TServiceBulkAddSkuRequestFormat; filters: {}}>(
    async ({unitIds, projectId, selectAll, sku, filters = {}}) => {
      api.toggleLoader(true);
      const response = await api.mdu.addServiceToUnitsAction(
        {projectId},
        {
          selection: {
            select_all: selectAll,
            unit_ids: unitIds,
            params: {
              service: {
                sku,
              },
            },
            filters,
          },
        }
      );
      api.toggleLoader(false);

      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Add Service To Units Action Mutation'});
        throw new Error(`error adding service to units: ${String(response.err)}`);
      }
      return response?.data?.project_action;
    }
  );
};

/**
 * Perform the action specified in the project action model (approve, delete, etc).
 * Applies to both projects and units.
 */
export const usePerformProjectActionMutation = ({entity, currentProjectId}: {entity: TEntity | ''; currentProjectId: number | null | undefined}) => {
  const api = useApi();
  const dispatch = useDispatch();
  const {invalidatePaginatedQueries} = useInvalidateQueriesPagination();

  return useMutation<TProjectActionResponse, unknown, {projectActionId: number | null; params: object | null}>(
    async ({projectActionId, params}) => {
      if (projectActionId) {
        api.toggleLoader(true);
        const response = await api.mdu.performProjectAction({id: projectActionId}, {async: false, params});
        api.toggleLoader(false);

        if (response.err) {
          showErrorToast(dispatch)({errors: `Project Action Mutation: ${String(unWrapError(response.err))}`});
          throw new Error(response.err);
        }
        return response?.data?.project_action;
      }
      return null;
    },
    {
      onSuccess: async () => {
        // TODO: revisit implementation
        const entitiesTriggeringJobsInvalidation = ['jobs', 'services', 'templates'];
        if (entity === 'projects') {
          invalidatePaginatedQueries(FULFILLED);
        } else if (entitiesTriggeringJobsInvalidation.includes(entity)) {
          invalidatePaginatedQueries('jobs');
          if (currentProjectId) {
            await dispatch(mduProjectsSlice.actions.fetchProjectDetails({projectId: currentProjectId}));
          }
        }
      },
      onError: response => {
        logger('Error Perform Project Action Mutation: ')(response as Error);
      },
    }
  );
};

/**
 * Specific Multi Jobs Bulk operation. Always async, therefor no need to invaliate or refresh.
 * - we could poll or use web sockets. But for now, they didn't want it.
 *
 * @param projectActionId
 */
export const usePerformProjectActionAsyncMutation = () => {
  const api = useApi();
  const dispatch = useDispatch();

  return useMutation<TProjectActionResponse, unknown, {projectActionId: number}>(
    async ({projectActionId}) => {
      const response = await api.mdu.performProjectAction({id: projectActionId}, {async: true, forceCase: 'camel'});
      if (response.err) {
        throw new Error(`error performing project action: ${String(unWrapError(response.err))}`);
      }
      return response?.data?.projectAction;
    },
    {
      onError: response => {
        logger('Error Perform Project Action Mutation: ')(response as Error);
        showErrorToast(dispatch)({errors: 'Error Performing Project Action Mutation'});
      },
    }
  );
};
