import React from 'react';
import cn from 'classnames';
// Components && Styles
import {Icon} from 'ht-styleguide';
import {ProjectDetails, ProjectLaunchStats} from 'features/MultiDwellingUnits/MDU.types';
import styles from './launchChecklist.styles.scss';
// Constants
import {CHECKLIST_GROUPS_CONFIG} from './launchChecklist.constants';

export enum ProjectTypesChecklistEnum {
  projectDetails = 'projectDetails',
  teamManagement = 'teamManagement',
  templatesJobs = 'templatesJobs',
  payment = 'payment',
}

type LaunchChecklistGroupProps = {
  groupName: ProjectTypesChecklistEnum;
  project: ProjectDetails;
};

export const TEST_IDS = {REQUIREMENT_ICON: 'requirementIcon', GROUP_ICON: 'groupIcon'};

export const getCompletionIconStyles = (isComplete: boolean) =>
  cn({
    [styles.complete]: isComplete,
    [styles.incomplete]: !isComplete,
  });

const LaunchChecklistGroup = ({groupName, project = {} as ProjectDetails}: LaunchChecklistGroupProps) => {
  const {stats = {} as ProjectLaunchStats} = project;
  const config = CHECKLIST_GROUPS_CONFIG[groupName] || CHECKLIST_GROUPS_CONFIG.projectDetails;
  return (
    <div className="marginBottom-large">
      <div className={cn('flex justify-content-space-between align-items-center paddingBottom-small1 marginBottom-small1', styles.sectionHeader)}>
        <div className="flex align-items-center">
          <Icon name={config.icon} className={styles.sectionIcon} />
          <h5 className="marginLeft-tiny1">{config.text}</h5>
        </div>
        <Icon name="checkmark-rounded" className={cn(styles.icon, styles.groupCompletion, getCompletionIconStyles(stats[groupName]?.completed))} dataTestId={TEST_IDS.GROUP_ICON} />
      </div>

      {config.requirements.map(req => (
        <div className="flex marginBottom-small1" key={req.key}>
          <Icon name="round-check-fill" className={cn('marginRight-tiny1', styles.icon, styles.lineItem, getCompletionIconStyles(req.isComplete(project)))} dataTestId={TEST_IDS.REQUIREMENT_ICON} />
          <div>
            <p className="p1 n800 marginBottom-tiny1">{req.text}</p>
            <p className="caption n700">{req.subText}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LaunchChecklistGroup;
