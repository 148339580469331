import React from 'react';
import cn from 'classnames';
import {Panel} from 'ht-styleguide';

import ProjectLevelUnitUpdate from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/Parts/TemplateJobs.ProjectUnit';

export const SectionTitle = ({title, className}: {title?: string; className?: string}) => (title ? <div className={cn('p1 text-weight-med paddingBottom-tiny', className)}>{title}</div> : null);

export const JobQuantitySection = ({setUnitCount}: {setUnitCount: BaseAnyFunction}) => {
  return (
    <div>
      <SectionTitle className="paddingY-medium" title="Job Quantity" />
      <Panel noShadow className="border padding-small flex flex-direction-column align-items-center">
        <SectionTitle className="paddingY-small" title="How many additional jobs will be created?" />
        <ProjectLevelUnitUpdate withIcon={false} text="Unit Count" valueSetter={setUnitCount} />
      </Panel>
    </div>
  );
};
