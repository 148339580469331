import * as yup from 'yup';
import {HELLOTECH_PARTNER_ID} from 'components/FilterFormFields/DataSelect/dataSelect.constants';
import {TCreateFormValues} from './createCreditSideSheet.types';

// `skuOrPlanSelected` is used to validate that at least one SKU or Plan is selected
export const FORM_SCHEMA = yup.object({
  credit_name: yup.string().nullable().required('Display name is required'),
  expiration_date: yup.string().nullable().required('Expiration date is required'),
  amount: yup.string().nullable().required('Credit amount is required'),
  partner_id: yup.number().required('Partner is required'),
  skuOrPlanSelected: yup.bool().required('Please select at least one SKU or Plan').oneOf([true], 'Please select at least one SKU or Plan'),
});

export const FORM_INITIAL_VALUES: TCreateFormValues = {
  credit_name: '',
  expiration_date: '',
  amount: '',
  partner_id: HELLOTECH_PARTNER_ID,
  skuOrPlanSelected: false,
  credit_items_attributes: [],
};

export const HELLOTECH_PARTNER_LABEL = 'HelloTech D2C';
