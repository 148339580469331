import {authToken} from 'utils/auth';
import {Middleware} from 'redux';
import {login} from 'features/Login/login.ducks';
import isRequestedStatus from 'utils/request/isRequestedStatus';
import {loginPath} from 'global/paths';
import {PENDING, REJECTED, PUBLIC} from 'global/constants/common';
import router from 'global/routes';

const AUTH_TARGET_ACTIONS = [PENDING, REJECTED];

const authenticationMiddleware: Middleware = api => next => action => {
  const permission = action?.meta?.arg?._permissionsType;
  const apiGuardedRequest = AUTH_TARGET_ACTIONS.some(target => action.type.endsWith(target));
  const forbidden = !authToken.isValidJWT();
  let isUnauthorized = false;

  if (action.type.endsWith('/rejected')) {
    isUnauthorized = isRequestedStatus(action, 401);
  }

  if ((forbidden && permission !== PUBLIC && apiGuardedRequest) || isUnauthorized) {
    // reconcile storage and state
    authToken.removeAuthToken();
    api.dispatch(login.actions.logoutAdministrator());
    router.navigate(loginPath());
  }

  return next(action);
};

export default authenticationMiddleware;
