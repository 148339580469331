import React, {useMemo} from 'react';
import {CheckboxGroup, Checkbox, SideSheetContentSection, ELEMENT_SIZE} from 'ht-styleguide';
import {UpdateTaskAnswerFieldName} from '../../editTaskAnswerSideSheet.types';
import {IEditTaskAnswerSectionShareProps} from '../editTaskAnswerContent.types';

const MultiSelectEditTaskAnswerSection = ({formik, task}: IEditTaskAnswerSectionShareProps) => {
  const {options} = task;

  const checkboxGroupSelectedValues = useMemo(() => formik.values.options.map(({id}) => id), [formik.values.options]);
  const onChange = (newValues: any[] | null) =>
    formik.setFieldValue(
      `${UpdateTaskAnswerFieldName.Options}`,
      (newValues || []).map(id => ({id}))
    );

  return (
    <SideSheetContentSection title="Select all that apply">
      <CheckboxGroup elementSize={ELEMENT_SIZE.MEDIUM} value={checkboxGroupSelectedValues} onChange={onChange} disableSort>
        {options.map(({id, description}) => (
          <Checkbox key={id} value={id} label={description} />
        ))}
      </CheckboxGroup>
    </SideSheetContentSection>
  );
};

export default MultiSelectEditTaskAnswerSection;
