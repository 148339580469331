// @ts-nocheck
import forEach from 'lodash/forEach';
import environment from 'global/environments';
import request, {toQueryString, postJson, postFormData, putJson, patchJson, deleteJson} from './request';

export enum ApiHostType {
  DEFAULT = 'api',
}

type Options = {
  replacements: boolean;
  params?: string;
  apiHostType?: ApiHostType;
};

const baseUrls = {
  [ApiHostType.DEFAULT]: `${environment.PROTOCOL}://${environment.API_HOST || 'api.hellotech.com'}`,
};

function makePath(path: string, {replacements, params, apiHostType}: Options) {
  let newPath = path;
  if (replacements) {
    if (!(typeof replacements === 'object')) {
      throw new Error(`Replacements need to be object, ${typeof replacements} provided for ${newPath}`);
    }
    forEach(replacements, (value: string, name: string) => {
      if (newPath.includes(`:${name}`)) {
        newPath = newPath.replace(`:${name}`, value);
      } else {
        console.warn(`Can't find :${name} in ${newPath}`);
      }
    });
    if (newPath.includes(':')) {
      throw new Error(`Not all replacements were provided: ${newPath}`);
    }
  }

  let query = '';
  if (params) {
    query = `?${toQueryString(params)}`;
  }

  return `${baseUrls[apiHostType]}${newPath}${query}`;
}

function resolvePathArgs(path: string, args: Array<string>) {
  const shouldHaveReplacements = path.includes(':');
  let replacements;
  let data;
  let options;

  if (shouldHaveReplacements) {
    replacements = args[0];
    data = args[1];
    options = args[2] || {};
    if (!replacements) {
      throw new Error(`Please provide attributes for path: ${path}`);
    }
  } else {
    data = args[0];
    options = args[1] || {};
  }

  return {shouldHaveReplacements, data, options, replacements};
}

function makeGetPath(path: string, apiHostType: ApiHostType, args: Array<string>) {
  const {replacements, data: params, shouldHaveReplacements} = resolvePathArgs(path, args);
  return makePath(path, {
    replacements: shouldHaveReplacements && replacements,
    params,
    apiHostType,
  });
}

export default function () {
  return {
    get(path, apiHostType = ApiHostType.DEFAULT) {
      const curry = (...args) => {
        const {options} = resolvePathArgs(path, args);
        return request(makeGetPath(path, apiHostType, args), options);
      };
      curry.getPath = args => makeGetPath(path, apiHostType, args);
      return curry;
    },

    post(path, apiHostType = ApiHostType.DEFAULT) {
      return (...args) => {
        const {replacements, data, options, shouldHaveReplacements} = resolvePathArgs(path, args);
        const result = makePath(path, {
          replacements: shouldHaveReplacements && replacements,
          apiHostType,
        });
        if (options.files) return postFormData(result, data);
        return postJson(result, data, options);
      };
    },

    put(path, apiHostType = ApiHostType.DEFAULT) {
      return (...args) => {
        const {replacements, data, options, shouldHaveReplacements} = resolvePathArgs(path, args);
        const result = makePath(path, {
          replacements: shouldHaveReplacements && replacements,
          apiHostType,
        });
        return putJson(result, data, options);
      };
    },

    patch(path, apiHostType = ApiHostType.DEFAULT) {
      return (...args) => {
        const {replacements, data, options, shouldHaveReplacements} = resolvePathArgs(path, args);
        const result = makePath(path, {
          replacements: shouldHaveReplacements && replacements,
          apiHostType,
        });
        return patchJson(result, data, options);
      };
    },

    // delete is a reserved word
    destroy(path, apiHostType = ApiHostType.DEFAULT) {
      return (...args) => {
        const {replacements, data, options, shouldHaveReplacements} = resolvePathArgs(path, args);
        const result = makePath(path, {
          replacements: shouldHaveReplacements && replacements,
          apiHostType,
        });
        return deleteJson(result, data, options);
      };
    },
  };
}
