import {useAppDispatch} from 'hooks/useAppDispatch';
import {useParams} from 'react-router-dom';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {MduUseParamsTypes, TUnitApiParams} from 'features/MultiDwellingUnits/MDU.types';
import {useInvalidateQueriesPagination} from 'features/MultiDwellingUnits/Pages/Projects/hooks';
import {TMutationReasonsParams, useCannotCompleteMutation, useUnserviceableMutation} from 'features/MultiDwellingUnits/queries/mutation.reasons';

/**
 * Both projects and currentProject use these behaviors. Just hook it out.
 *
 * These methods interface with the ducks file for operations.
 *
 * deleteJob
 * approveJob
 * [ReopenJob]
 * onCloseActionItem
 *
 * Invalidation will update the query calls since we modified the data.
 */
export const useJobsActionsItems = () => {
  /* Hooks */
  const dispatch = useAppDispatch();
  const {projectId} = useParams<MduUseParamsTypes>();
  const {invalidatePaginatedQueries} = useInvalidateQueriesPagination();

  /* Queries / Mutations */
  const {mutate: mutateCannotComplete} = useCannotCompleteMutation();
  const {mutate: mutateUnserviceable} = useUnserviceableMutation();

  const onCloseActionItem = () => {
    dispatch(mduProjectsSlice.actions.removeActionItemModalSlide());
  };

  /* ------------- Site Wide Actions: Units/Jobs ------------- */
  const approveUnitAction = async () => {
    await dispatch(mduProjectsSlice.actions.approveUnit({projectId: projectId!}));
    invalidatePaginatedQueries('jobs');
    onCloseActionItem();
  };

  const reopenUnitAction = async (params?: TUnitApiParams) => {
    const p = {projectId: projectId!, ...(params && {...params})};
    await dispatch(mduProjectsSlice.actions.reopenUnit(p));
    invalidatePaginatedQueries('jobs');
    onCloseActionItem();
  };

  const deleteUnitAction = async () => {
    await dispatch(mduProjectsSlice.actions.deleteUnit({projectId: projectId!}));
    invalidatePaginatedQueries('jobs');
    onCloseActionItem();
  };

  const cancelUnitAction = async (params: TMutationReasonsParams) => {
    mutateCannotComplete(params, {
      onSuccess: () => {
        onCloseActionItem();
      },
    });
  };

  const unserviceUnitAction = async (params: TMutationReasonsParams) => {
    mutateUnserviceable(params, {
      onSuccess: () => {
        onCloseActionItem();
      },
    });
  };

  return {
    approveUnitAction,
    reopenUnitAction,
    deleteUnitAction,
    cancelUnitAction,
    unserviceUnitAction,
  };
};
