import {useQuery} from 'react-query';
import useApi from 'hooks/useApi';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {showErrorToast, logResponseError, stringifyQueryParams} from 'queries/query.utils';
import {DATA_SELECT_QUERY_KEYS} from './query.keys';

type TGetCBSAQueryParams = {
  /**
   * To apply to the filter
   */
  partnerId?: number | string;
  /**
   * Search on the CBSA id or name
   */
  search?: number | string;
  pagination?: TBasePaginationQuery;
};

type TGetCBSAQueryResponse = WithErrorResponse<{
  cbsas: Array<{id: number; name: string}>;
  pagination: TBasePagination;
}>;

const useGetCBSAQuery = ({partnerId, search, pagination}: TGetCBSAQueryParams = {}) => {
  const dispatch = useAppDispatch();
  const api = useApi();

  const partnerIdParam = partnerId ? {partner_id: partnerId} : {};
  const searchParam = search ? {search} : {};
  const paginationParam = pagination ? {pagination} : {};

  const searchQuery = stringifyQueryParams({...partnerIdParam, ...searchParam, ...paginationParam});

  return useQuery(DATA_SELECT_QUERY_KEYS.getCBSA([searchQuery]), async () => {
    const response: TGetCBSAQueryResponse = await api.cbsas.list({search: searchQuery});
    if (response.err) {
      showErrorToast(dispatch)({errors: 'Error Retrieving CBSA'});
      logResponseError('DataSelect - CBSA')(response.err);
    }
    return response?.data;
  });
};

export default useGetCBSAQuery;
