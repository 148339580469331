import {TEditorToJson} from '../editor.types';

/**
 * Join up all the text nodes in the editor, including links/mentions.
 * @param editor
 */
export function getTextByNode(editor: TEditorToJson) {
  const recursiveByChildren = (children: any) => {
    return children.map((child: any) => {
      if (child.children) {
        return recursiveByChildren(child.children);
      }

      if (child.trigger === '@') {
        return child.value;
      }

      return child.text;
    });
  };

  const joinedByChildren = recursiveByChildren(editor?.root.children?.[0]?.children || []);

  return joinedByChildren.join('');
}
