import React, {memo} from 'react';
import {Modal, ELEMENT_SIZE, Button, BUTTON_THEMES, CheckboxGroup, Checkbox} from 'ht-styleguide';
import styles from './columnEditingModal.scss';

type TColumnEditingModal = {
  checkboxOptions: {label: string; value: string; disabled?: boolean}[];
  isVisible: boolean;
  onSaveChange: () => void;
  onSelectChange: (selectedValues: string[]) => void;
  selectedValues: string[];
  subheaderCopy?: string;
  toggleModal: () => void;
};

const defaultSubheaderCopy = 'Selected columns will be displayed in the table and can be used for grouping and filtering';

const ColumnEditingModal = ({checkboxOptions, isVisible, onSaveChange, onSelectChange, selectedValues, subheaderCopy = defaultSubheaderCopy, toggleModal}: TColumnEditingModal) => {
  return (
    <Modal
      elementSize={ELEMENT_SIZE.L}
      header="Customize Columns"
      hide={toggleModal}
      isVisible={isVisible}
      bodyContainerClasses={styles.modalClass}
      footerElement2={
        <Button theme={BUTTON_THEMES.SECONDARY} onClick={toggleModal}>
          Cancel
        </Button>
      }
      footerElement3={<Button onClick={onSaveChange}>Save Changes</Button>}
    >
      <p className="p2 marginBottom-small">{subheaderCopy}</p>
      <CheckboxGroup value={selectedValues} onChange={onSelectChange} disableGrouping={false} disableSort={false}>
        {checkboxOptions.map(opt => (
          <Checkbox {...opt} key={opt.value} />
        ))}
      </CheckboxGroup>
    </Modal>
  );
};

export default memo(ColumnEditingModal);
