import {useState, useEffect, useRef, useCallback} from 'react';
import get from 'lodash/get';
import {TThumbnailFile, TUseCarouselProps} from './thumbnail.types';
import {getFileNameFromAttachmentUrl, isAttachmentImage} from 'features/Cloudinary/cloudinary.utils';

/**
 * Hook for handling the thumbnail tooltip.
 * @returns {object} - An object containing the following values:
 *   - ref: a reference to the thumbnail element in the DOM
 *   - tooltipVisible: a boolean indicating whether the tooltip should be visible
 *   - tooltipPosition: an object containing the top and left positions for the tooltip
 *   - handleMouseEnter: a function to handle the mouse enter event on the thumbnail
 *   - handleMouseLeave: a function to handle the mouse leave event on the thumbnail
 */
export const useThumbnailTooltip = () => {
  const thumbnailRef = useRef<HTMLDivElement | null>(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState<{top: number; left: number} | null>(null);

  /** reference to the tooltip timeout (to clear it if necessary) */
  const tooltipTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const updateTooltipPosition = () => {
    const domRect = thumbnailRef.current!.getBoundingClientRect();

    // Calculate the center and top positions for the tooltip. We want the tooltip to be centered.
    const thumbnailCenter = domRect.left + domRect.width / 2;
    const thumbnailTop = domRect.bottom + 4;

    setTooltipPosition({top: thumbnailTop, left: thumbnailCenter});
  };

  useEffect(() => {
    // set a delay before setting the tooltip position (to allow for animations)
    if (thumbnailRef.current && tooltipVisible) {
      tooltipTimeoutRef.current = setTimeout(updateTooltipPosition, 200);
    }
    if (!tooltipVisible) {
      setTooltipPosition(null);
    }
  }, [tooltipVisible]);

  /** handler for mouse enter event on the thumbnail */
  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };

  /** handler for mouse leave event on the thumbnail */
  const handleMouseLeave = () => {
    setTooltipVisible(false);
    // clear the timeout if one has been set
    if (tooltipTimeoutRef.current) {
      clearTimeout(tooltipTimeoutRef.current);
    }
  };

  return {
    ref: thumbnailRef,
    tooltipVisible,
    tooltipPosition,
    handleMouseEnter,
    handleMouseLeave,
  };
};

/**
 * Hook for handling the image loading state.
 * @returns {object} - An object containing the following values:
 *   - isImageLoading: a boolean indicating whether the image is loading
 *   - handleImageLoad: a function to handle the image load event
 */
export const useIsImageLoading = () => {
  const [isLoading, setIsLoading] = useState(true);
  const handleImageLoad = () => {
    setIsLoading(false); // set loading to false when image is loaded
  };
  return {isImageLoading: isLoading, handleImageLoad};
};

/**
 * Hook for handling the carousel.
 *
 * @param {object} props - An object containing the following properties:
 *   - externalRef {React.RefObject<HTMLDivElement> | null}: This is an optional parameter,
 *     which should be a ref to an HTMLDivElement or null. It's used for testing purposes.
 *     When this ref is provided, the carousel will be based on the element this ref points to.
 *
 * @returns {object} - An object containing the following values:
 *   - isCarousel: a boolean indicating whether the thumbnails are in a carousel
 *   - carouselRef: a reference to the carousel element in the DOM
 *   - handleArrowClick: a function to handle the arrow click event
 *   - translateX: the current translateX value for the carousel
 *   - isLeftArrowVisible: a boolean indicating whether the left arrow should be visible
 *   - isRightArrowVisible: a boolean indicating whether the right arrow should be visible
 */
export const useCarousel = ({externalRef = null, numFiles = 0}: TUseCarouselProps = {}) => {
  const [isCarousel, setIsCarousel] = useState(false);
  const [thumbnailsWidth, setThumbnailsWidth] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const carouselRef = useRef<HTMLDivElement | null>(null);
  const arrayLength = Array.from(carouselRef?.current?.children || []).length || 0;

  useEffect(() => {
    if (externalRef) {
      carouselRef.current = externalRef.current;
    }
  }, [externalRef, numFiles]);

  const calcWidths = () => {
    if (!carouselRef.current) return;
    const containerW = carouselRef.current.offsetWidth;
    const thumbnailsW = Array.from(carouselRef.current.children).reduce((totalWidth, child) => totalWidth + (child as HTMLElement).offsetWidth, 0);
    setIsCarousel(thumbnailsW > containerW);
    setThumbnailsWidth(thumbnailsW);
    setContainerWidth(containerW);
  };

  const handleArrowClick = (direction: 'left' | 'right') => {
    if (!carouselRef.current || !get(carouselRef, 'current.children.0')) return;

    // All of the thumbnails have the same width and margin, so we can just use the first one to calculate the scroll position.
    const thumbnail = carouselRef.current.children[0] as HTMLElement;
    const thumbnailStyle = window.getComputedStyle(thumbnail);
    const thumbnailMarginRight = parseFloat(thumbnailStyle.marginRight);
    const extraSpaceToClearEdges = 10;
    const scrollAmount = thumbnail.offsetWidth + thumbnailMarginRight + extraSpaceToClearEdges;

    const newTranslateX = direction === 'left' ? translateX + scrollAmount : translateX - scrollAmount;
    setTranslateX(newTranslateX);
  };

  useEffect(() => {
    calcWidths();
    window.addEventListener('resize', calcWidths);
    return () => window.removeEventListener('resize', calcWidths);
  }, [arrayLength]);

  useEffect(() => {
    calcWidths();
  }, [numFiles]);

  useEffect(() => {
    if (translateX < 0) {
      handleArrowClick('left');
    }
  }, [numFiles]);

  const isLeftArrowVisible = translateX < 0;
  const isRightArrowVisible = isCarousel && Math.abs(translateX) < thumbnailsWidth - containerWidth;

  return {isCarousel, carouselRef, handleArrowClick, translateX, isLeftArrowVisible, isRightArrowVisible};
};

/**
 * Hook for setting up the tooltip portal.
 * @param {object} options - An object containing the following values:
 *   - portalId: the id to use for the tooltip portal
 * @returns {Element | null} - A reference to the tooltip portal element in the DOM.
 */
export const useTooltipSetup = ({portalId = 'thumbnail-tooltip-portal'} = {}) => {
  const [tooltipPortalElement, setTooltipPortalElement] = useState<Element | null>(null);

  useEffect(() => {
    const div = document.createElement('div');
    div.id = portalId;
    document.body.appendChild(div);
    setTooltipPortalElement(div);
    return () => {
      document.body.removeChild(div);
    };
  }, [portalId]);

  return tooltipPortalElement;
};

export const useLightboxDisplay = ({files, withLightbox = false}: {files: TThumbnailFile[]; withLightbox?: boolean}) => {
  /* Lightbox concerns */
  const [targetIndex, setTargetIndex] = useState(0); // Index of the image to open in the lightbox
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [modifiedFiles, setModifiedFiles] = useState<TThumbnailFile[]>([]); // Files with the onThumbnailClick function modified to open the lightbox

  const carouselProps = {finite: true};
  const toggleLightbox = () => setIsLightboxOpen(!isLightboxOpen);
  const filteredFiles = files.filter(isAttachmentImage);
  const slides = filteredFiles.map(attachment => {
    const filename = getFileNameFromAttachmentUrl(attachment, {withoutQueryParams: true});
    return {
      src: attachment.url,
      alt: filename,
      title: filename,
    };
  });

  const imageFunction = (fn: ((file: TThumbnailFile) => void) | undefined) => (file: TThumbnailFile) => {
    const index = filteredFiles.findIndex(image => image.url === file.url);

    setTargetIndex(index);
    toggleLightbox();
    fn?.(file);
  };

  const documentFunction = useCallback(
    (fn: BaseAnyFunction) => (file: TThumbnailFile) => {
      window.open(file.url, '_blank');
      fn?.(file);
    },
    []
  );

  const onThumbnailClick = (file: TThumbnailFile) => {
    const originalClick = file.onThumbnailClick;
    const openAttachment = isAttachmentImage(file) ? imageFunction(originalClick) : documentFunction(originalClick as BaseAnyFunction);

    file.onThumbnailClick = openAttachment;

    return file;
  };

  const lightBoxFilterFiles = (f: TThumbnailFile[]): TThumbnailFile[] => {
    return f.map(onThumbnailClick);
  };

  useEffect(() => {
    if (withLightbox) {
      setModifiedFiles(lightBoxFilterFiles(files));
    } else {
      setModifiedFiles(files);
    }
  }, [files.length]);

  return {
    isLightboxOpen,
    targetIndex,
    slides,
    carouselProps,
    toggleLightbox,
    modifiedFiles,
  };
};
