import React from 'react';
import {useLocation} from 'react-router-dom';

import useQueryParams from 'hooks/useQueryParams';
import {useCurrentProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/CurrentProject.hooks';
import {isPreLaunchStatus} from 'features/MultiDwellingUnits/MDU.utils';
import TemplatesJobsPreLaunch from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/PreLaunch/TemplateJobs.PreLaunch.Templates';
import TemplatesJobsPostLaunch from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/PostLaunch/TemplateJobs.PostLaunch.Templates';
import InitialProjectGroups from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/PreLaunch/TemplateJobs.Template.Initial';
import EmptyProjectGroups from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/PreLaunch/TemplateJobs.Template.Empty';

import {InitialTemplateStages} from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/TemplateJobs.constants';

const TemplatesPage = () => {
  /* Hooks */
  const project = useCurrentProject();
  const {stage} = useQueryParams(useLocation());
  const isPreLaunch = isPreLaunchStatus(project.status);
  const noProjectGroups = project?.projectGroups?.length === 0;
  const showEmptyState = stage === InitialTemplateStages.EMPTY;

  if (!project?.id) return null;

  /* Prelaunch and lets bypass initial step to goto empty */
  if (isPreLaunch && noProjectGroups && showEmptyState) return <EmptyProjectGroups />;
  /* Prelaunch but no project groups */
  if (isPreLaunch && noProjectGroups) return <InitialProjectGroups />;

  /* Prelaunch but we have templates/groups */
  if (isPreLaunch) return <TemplatesJobsPreLaunch />;

  return <TemplatesJobsPostLaunch />;
};

export default TemplatesPage;
