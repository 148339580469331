import React from 'react';

// Utils
import cn from 'classnames';

// Components
import {Panel} from 'ht-styleguide';

// Constants
import {STATS_HEADERS} from 'features/Profile/profile.constants';

// Types
import {PricingBreakdown} from 'types/pricing.types';
import {Stat} from 'features/Profile/profile.types';

// Styles
import styles from 'features/Profile/profile.styles.scss';

type StatBlockProps = {
  stat: string;
  value: number | PricingBreakdown;
};

const StatBlock = ({stat, value}: StatBlockProps) => {
  const statHeader = STATS_HEADERS[stat as keyof Stat];

  return (
    <Panel className={cn(styles.statsBlock, 'paddingY-small1')}>
      <h3 className="marginBottom-tiny1">{typeof value === 'number' ? value : value?.formatted}</h3>
      <p className="p2 n700">{statHeader}</p>
    </Panel>
  );
};

export default StatBlock;
