import React, {ReactText} from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import cn from 'classnames';
import {Grid} from 'ht-styleguide';
import {useFetchProjectTypes} from 'features/MultiDwellingUnits/MDU.hooks';
import CardDashboard from 'components/Elements/Cards/Card.dashboard';
import {formatPrice} from 'utils/currency';
import {ProjectDetails} from 'features/MultiDwellingUnits/MDU.types';
import {ProjectDropdownDetails} from 'features/MultiDwellingUnits/Parts/FormFields/FormFields.types';
import {getProjectType} from 'features/MultiDwellingUnits/MDU.utils';
import {longDayMonthWithYear} from 'global/constants/common';
import styles from './launchReviewPage.styles.scss';

// export const OrderHeader = (data: ProjectDetails) => {
export const OrderHeader: React.FC<{project: ProjectDetails}> = ({project}) => {
  const {name, partner, startDate, estimatedEndDate, unitsNumber, totalWithoutSubsidy, leadTech, billingLead, projectType, projectManager, propertyOwner} = project;
  dayjs.extend(utc);

  const startDateFormatted = dayjs.utc(startDate || Date.now()).format(longDayMonthWithYear);
  const endDateFormatted = dayjs.utc(estimatedEndDate || Date.now()).format(longDayMonthWithYear);
  const generateNameIdDisplay = (propertyObject: ProjectDropdownDetails | undefined) => {
    if (!propertyObject) return '';

    return `${propertyObject?.name} <span class='n700'>#${propertyObject?.id}</span>`;
  };
  const allProjectTypes = useFetchProjectTypes();

  return (
    <>
      <Grid.FullWidth classes="paddingRight-medium paddingBottom-medium1 flex justify-content-space-between align-items-center">
        <div>
          <h1 className="h3 marginBottom-tiny1">{name}</h1>
          <p className="p1 n800">a {propertyOwner?.name || 'n/a'} property</p>
        </div>
        <table className={styles.headerTable}>
          <thead>
            <tr className="text-left">
              <th className="n700 p2 paddingRight-medium paddingBottom-tiny1">Project Starts</th>
              <th className="n700 p2 paddingLeft-small2 paddingBottom-tiny1">Estimated Completion</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p2 paddingRight-small">{startDateFormatted}</td>
              <td className="p2 paddingLeft-small2 ">{endDateFormatted}</td>
            </tr>
          </tbody>
        </table>
      </Grid.FullWidth>
      <Grid.FullWidth>
        <div className={styles.container_cardDetails}>
          <CardDashboard
            primary={[['Partner', partner?.name as ReactText]]}
            secondary={[
              ['Project Type', getProjectType(projectType, allProjectTypes) || 'n/a'],
              ['Number of Units', unitsNumber as ReactText],
              ['Estimated Revenue', formatPrice(totalWithoutSubsidy)],
            ]}
          />
          <CardDashboard
            primary={[['Project Manager', generateNameIdDisplay(projectManager) || 'n/a']]}
            secondary={[
              ['Technician Team Lead', generateNameIdDisplay(leadTech!)],
              ['Pay To', generateNameIdDisplay(billingLead!)],
            ]}
          />
        </div>
      </Grid.FullWidth>
    </>
  );
};

const OrderDetails: React.FC<{project: ProjectDetails}> = ({project}) => {
  const {contactName, contactPhone, accessInstructions, notes} = project;
  const phone = !contactPhone?.toString().length ? 'None' : contactPhone;
  const name = !contactName ? 'None' : contactName;

  return (
    <div className="paddingTop-medium1">
      <h2 className="h5 marginBottom-tiny1">Project Notes</h2>
      <p className="p2 n700"> Visible on every job</p>
      <table className={cn('marginTop-small2', styles.contact)}>
        <thead>
          <tr>
            <th>
              <p className="p2 n700 paddingBottom-tiny1">On-site Contact</p>
            </th>
            <th>
              <p className="p2 n700 paddingBottom-tiny1">Phone</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p className="p1"> {name} </p>
            </td>
            <td>
              <p className="p1"> {phone} </p>
            </td>
          </tr>
        </tbody>
      </table>
      {accessInstructions && (
        <>
          <p className="p2 n700 paddingTop-small2 paddingBottom-tiny1">Instructions</p>
          <p className="p1">{accessInstructions}</p>
        </>
      )}
      {notes && (
        <>
          <p className="p2 n700 paddingTop-small2 paddingBottom-tiny1">Other Notes</p>
          <p className="p1">{notes}</p>
        </>
      )}
    </div>
  );
};

export default OrderDetails;
