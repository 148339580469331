import React, {useState, useEffect, useCallback} from 'react';
import {Cloudinary, CloudinaryImage, CloudinaryVideo} from '@cloudinary/url-gen';
import {thumbnail} from '@cloudinary/url-gen/actions/resize';
import {Icon} from 'ht-styleguide';

/* hooks */
import {useMutationSignedToken} from 'features/Cloudinary/queries/mutation.signedToken';
import {useAppDispatch} from 'hooks/useAppDispatch';
import useApi from 'hooks/useApi';

/* Constants/Utils  */
import environment from 'global/environments';
import {getFilesGroupedByMaxFileSize} from 'utils/files';
import APIS from 'global/apis';
import {logger, LoggerErrorType} from 'utils/logger';
import {ALLOWED_FILES, FILE_UPLOAD_VALIDATIONS} from 'utils/files/constants';
import {shortenStringByNumber} from 'utils/string';

/* Queries / Ducks */
import {useUserCurrentQuerySelect} from 'queries/User/query.user.current';
import CloudinaryDuck from 'features/Cloudinary/state/cloudinary.ducks';
import {notifications} from 'components/Notification/notification.ducks';
import {useGetTransformMutation} from 'features/Cloudinary/hooks/useCloudinary.transform';

import {MaybeAttachments, TFileSignCloudinaryResponse, TGenericCloudinaryResponse} from '../cloudinary.types';
import {CLOUDINARY_TRANSFORM_FILE_TYPES} from '../cloudinary.constants';

/**
 * Cloudinary hook to allow for uploading, transforming, deleting, and signing files. There are two mechanisms
 *    for state. Local & redux. This depends on the use case if your upload icon sits in the same space as your image
 *    display. If it does, you can use local state. If it doesn't, you can use redux state.
 *
 * @param namespace - If images are attached to an Editor. Same namespace as editor. Not exclusive to editor.
 *                    If you need to save in redux, also pass in namespace.
 * @param height - Height of transformed image to display
 * @param width - Width of transformed width to display
 * @param preseedAttachments - if you want to seed the attachments. For edit or view pages with actionable behavior on cloudinary.
 */
const useCloudinaryHooks = ({
  /* If images are attached to an Editor. Same namespace as editor */
  namespace,
  /* Height of transformed image to display */
  height = 200,
  /* Width of transformed width to display */
  width = 200,
  /* Preseeded attachments */
  preseedAttachments = null,
  overrideStateOnPreseedChange = false,
  /* Max file size per file upload */
  maxFileSize = {
    maxSizeInBytes: FILE_UPLOAD_VALIDATIONS.MAX_FILE_SIZE,
    maxSizeDisplayString: FILE_UPLOAD_VALIDATIONS.READABLE_MAX_FILE_SIZE,
  },
  /* New instantiation of the hook should pass this in */
  folder,
  /**
   * Currently used in `handleDeleteFile`. If true, the asset will be removed from Cloudinary when the file
   * is removed from local states. Otherwise, the asset will remain in Cloudinary.
   */
  deleteCloudinaryAssetOnSingleRemove = true,
}: Partial<{
  namespace: string;
  height?: number;
  width: number;
  preseedAttachments: MaybeAttachments;
  /**
   * If true, when `preseedAttachments` changes, reset the cloudinary hook's and redux's state to the
   * new `preseedAttachments`.
   * Defaults to false. When false, a change in `preseedAttachments` will append to the existing states.
   */
  overrideStateOnPreseedChange?: boolean;
  maxFileSize?: {
    maxSizeInBytes: number;
    maxSizeDisplayString: string;
  };
  folder?: string;
  deleteCloudinaryAssetOnSingleRemove?: boolean;
}> = {}) => {
  /* Local State */
  const [uploadedSuccessFiles, setUploadedSuccessFiles] = useState<TGenericCloudinaryResponse[]>();
  const [uploadErrorFiles, setUploadErrorFiles] = useState<PromiseRejectedResult[]>();
  const [cloudinaryLoading, setLoading] = useState(false);
  const [totalSuccessFilesCount, setTotalSuccessFilesCount] = useState(0);

  /* Hooks | Queries */
  const generateCloudinaryAuthMutation = useMutationSignedToken();
  const userCurrent = useUserCurrentQuerySelect();
  const {mutateAsync: mutateTransform} = useGetTransformMutation();
  const dispatch = useAppDispatch();
  const api = useApi();

  /* Common params for APIs */
  const params = {
    type: 'authenticated',
    access_control: JSON.stringify([{access_type: 'token'}]),
    context: `ht_owner_id=${userCurrent?.id}`,
    ...(folder && {folder}),
  };

  /* -------------------------------------------------------------------------------- */
  /* ---------------------- STATE: Local & Redux Actions ---------------------------- */
  /* -------------------------------------------------------------------------------- */

  /**
   * Set redux state for successful uploads
   * @param data
   */
  const setFileUploadReduxSuccess = useCallback(
    (data: TGenericCloudinaryResponse | TGenericCloudinaryResponse[], append: boolean = true) => {
      if (namespace) {
        dispatch(
          CloudinaryDuck.actions.setFileUploadSuccess({
            success: data,
            append,
            editorNamespace: namespace,
          })
        );
      }
    },
    [dispatch, namespace]
  );

  /**
   * Set redux state for failed uploads or file size errors
   * @param data
   */
  const setFileUploadReduxError = (data: PromiseRejectedResult[]) => {
    if (namespace) {
      dispatch(
        CloudinaryDuck.actions.setFileUploadError({
          error: data,
          append: false,
          editorNamespace: namespace,
        })
      );
    }
  };

  /**
   * Set local state for total number of successful uploads
   * @param result
   */
  const handleUploadSuccess = (result: TGenericCloudinaryResponse | TGenericCloudinaryResponse[]) => {
    // @ts-ignore
    setUploadedSuccessFiles((prevFiles: TGenericCloudinaryResponse[]) => {
      return (prevFiles || [])
        .filter(obj => {
          if (Array.isArray(result)) {
            return !result.some(r => r.asset_id === obj.asset_id);
          }

          return obj.asset_id !== result.asset_id;
        })
        .concat(result);
    });
  };

  /**
   * Update local and global concerns. All files have been either errored' or uplaoded.
   * So, Remove them from state.
   *
   * When: when user "saves" from the editor or "cancels" from the editor
   */
  const handleRemoveSuccessFiles = useCallback(() => {
    /* 1. clear local */
    setUploadedSuccessFiles([]);

    /* 2. clear redux */
    if (namespace) {
      dispatch(
        CloudinaryDuck.actions.setFileUploadSuccess({
          editorNamespace: namespace,
          append: false,
          success: [],
        })
      );
    }
  }, [dispatch, namespace]);

  /* Update total success files on deck to be uploaded. Can't ever be below 0 */
  const handleSetTotalSuccessFilesCount = (total?: number) => {
    if (total) {
      setTotalSuccessFilesCount(total);
    } else {
      // lets use previous settings
      setTotalSuccessFilesCount(prevTotal => {
        if (prevTotal > 0) {
          return prevTotal - 1;
        }
        return 0;
      });
    }
  };
  /* -------------------------------------------------------------------------------- */
  /* -------------------- MISCELLANEOUS CLOUDINARY METHODS -------------------------- */
  /* -------------------------------------------------------------------------------- */

  /* Get a new cloudinary instance */
  const newCloudinary = new Cloudinary({
    cloud: {
      cloudName: environment.CLOUDINARY.cloud_name,
    },
  });

  /**
   * Transform the image. This is a single transform. We'd use this outside of the editor usage.
   * This should be used in conjuntion with our Thumbnail component.
   *
   * @param file
   * @param options - {h,w}, representing height/width. Only needed if you are using this with an editor and
   *                  need flexibility in variation. Otherwise, just pass it in when creating the hook.
   *
   * @returns {string[]} - Array of transformed urls with their original url}
   */
  const handleTransformImage = async (file: TFileSignCloudinaryResponse[]) => {
    const transformFiles = Array.isArray(file) ? file : [file];
    /* 1. Number of transforms raw and image we want the thumbnail component to fake preload */
    setTotalSuccessFilesCount(file.length);

    /* 2. Kick off the promise maps */
    transformFiles.map(async f => {
      /* 3. Only transform images. Raw passes thru */
      if (f.resource_type === CLOUDINARY_TRANSFORM_FILE_TYPES.IMAGE || CLOUDINARY_TRANSFORM_FILE_TYPES.VIDEO) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const transformedImage = await getAssetPath(f);
        f.transformedImage = transformedImage;
      }
      /* 4. Retrieval (fail or success) so no need to fake preload */
      handleSetTotalSuccessFilesCount();

      /* 5. Store it locally */
      handleUploadSuccess(f);
      return f;
    });
  };

  /**
   * Transform the video. This is a single transform. We'd use this outside of the editor usage.
   * This should be used in conjuntion with our Thumbnail component.
   *
   * Specific use case. Pass in the file object, and you'll get back a tranformed image. We assume 200x200
   *
   * @returns {string[]} - Array of transformed urls with their original url}
   * @param url
   * @param reduxCache
   */
  const handleTransformVideo = async (file: TFileSignCloudinaryResponse, reduxCache: boolean = true) => {
    const cachedImage = reduxCache ? CloudinaryDuck.selectors.getCachedAsset(file.asset_id) : null;
    if (typeof cachedImage === 'string') return cachedImage;

    /* This is a video transform, if not video, return out */
    if (file.resource_type !== CLOUDINARY_TRANSFORM_FILE_TYPES.VIDEO) return file;

    const transformUrl = () => {
      const parsedUrl = new URL(file.url);
      /*
         Modify url for api requirements/transform.
         ParsedUrl gives us the ability to omit the host w/o replace
      */
      parsedUrl.pathname = parsedUrl.pathname.replace('authenticated', `authenticated/h_${height},w_${width}/f_jpg/so_1`);

      return parsedUrl.pathname;
    };

    /* Get the transformed image url */
    const response = await mutateTransform([transformUrl()]);

    /* Can store it in cache so subsequent views don't trigger an api call */
    if (reduxCache) {
      dispatch(
        CloudinaryDuck.actions.setFileCachedImage({
          id: file.asset_id,
          image: response,
        })
      );
    }

    return response;
  };

  /* Transform the image, simply. Just by height/width. Many options available */
  const assetTransformation = (asset: CloudinaryImage | CloudinaryVideo) => {
    if (asset instanceof CloudinaryVideo) {
      return (
        asset
          .resize(thumbnail().width(width).height(height))
          .setDeliveryType('authenticated')
          .format('jpg')
          /* offset to a second of the clip - we want the frame at 1 second */
          .addTransformation('so_1')
      );
    }

    // If the asset is an image, simply apply the transformations
    return asset.resize(thumbnail().width(width).height(height)).setDeliveryType('authenticated');
  };

  /* This uses the rails as a proxy and hands back our pathing for the cloudinary transformed image */
  const getTransformedUrl = async (urls: string[] | string) => {
    const sanitizedUrls = (Array.isArray(urls) ? urls : [urls]).map(url => {
      if (url.includes('video') && url.includes('f_jpg')) {
        return (url as string).replace('c_thumb,', '');
      }
      return url;
    });

    const response = await mutateTransform(sanitizedUrls);

    return response;
  };

  /* Image Preview */
  const getAssetPath = async (data: TFileSignCloudinaryResponse) => {
    const asset = (() => {
      switch (data.resource_type) {
        case CLOUDINARY_TRANSFORM_FILE_TYPES.IMAGE:
          return newCloudinary.image(data.public_id);
        case CLOUDINARY_TRANSFORM_FILE_TYPES.VIDEO:
          return newCloudinary.video(data.public_id);
        default:
          return newCloudinary.image(data.public_id);
      }
    })();
    // Apply the transformation.
    await assetTransformation(asset);
    const url = new URL(asset.toURL());

    const transformedImageUrl = await getTransformedUrl(url.pathname);
    return transformedImageUrl;
  };

  /* -------------------------------------------------------------- */
  /* -------------------- DELETE ACTIONS -------------------------- */
  /* -------------------------------------------------------------- */

  /*
    Delete from the server: We need a rails wrapper for this. Used for single item deletion. Usually from a delete icon: TODO
    Note: Deletes on file at a time.
  */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const deleteCloudinaryFiles = async (files: TGenericCloudinaryResponse[]) => {
    const deletePromises = files.map(({type, public_id}) => APIS.cloudinary.deleteResource({type, public_id}));

    /* We don't care if get a fail, lets delete as many as we can */
    const allDeleteResponses = await Promise.allSettled(deletePromises);
    // @ts-ignore
    const hasErrors = allDeleteResponses.some(filterError => filterError.value?.err);

    /* If erroring, log it. */
    if (hasErrors) {
      logger('Delete Cloudinary Files: ')('');
    }
  };

  /* Delete all cloudinary assets associated with current interaction/session (not saved yet) */
  const handleDeleteAllFromCloudinary = async () => {
    if (!uploadedSuccessFiles || uploadedSuccessFiles.length === 0) return;

    /* 1. Batch delete */
    await deleteCloudinaryFiles(uploadedSuccessFiles);

    /* 2. Lets delete from state successes */
    handleRemoveSuccessFiles();
  };

  /*
     Delete Single Asset from client & cloudinary*: Clear our all state & remove from cloudinary.
     Note: Used for a single item deletion. Ie. delete icon on a thumbnail

     public
  */
  const handleDeleteFile = <T = TFileSignCloudinaryResponse,>(cloudFile: T) => {
    const files = Array.isArray(cloudFile) ? cloudFile : [cloudFile];
    const successFiles = uploadedSuccessFiles?.filter(file => files.find(f => f.public_id !== file.public_id));

    /* Delete from local */
    setUploadedSuccessFiles(successFiles);

    /* Delete from redux */
    if (namespace) {
      const ids = files.map(file => file.public_id);
      dispatch(
        CloudinaryDuck.actions.deleteFileUploadSuccess({
          ids,
          editorNamespace: namespace,
        })
      );
    }
    /* Update successful file numbers (for preload thumbnails) */
    handleSetTotalSuccessFilesCount();

    /* Delete from cloudinary */
    if (deleteCloudinaryAssetOnSingleRemove) {
      deleteCloudinaryFiles(files as TFileSignCloudinaryResponse[]);
    }
  };

  /* ------------------------------------------------------------------------------------- */
  /* --------------------- ERROR ACTIONS & ERROR NOTIFICATIONS --------------------------- */
  /* ------------------------------------------------------------------------------------- */

  /**
   * Upload files to cloudinary that exhibit errors, we push into the local error state.
   *
   * @param errors
   *
   * private
   */
  const handleUploadErrors = (errors: PromiseRejectedResult[]) => {
    if (!Array.isArray(errors) || (Array.isArray(errors) && errors.length === 0)) return;

    /* 1. set it locally */
    setUploadErrorFiles(errors);

    /* 2. set it globally. Probably not needed as we surface the error from here */
    setFileUploadReduxError(errors);

    /* 3. Any errors: Log them */
    errors.forEach(error => {
      const err = error.reason?.message;
      logger('Cloudinary')(err as LoggerErrorType);
    });

    /* 4. We'll handle the errors here. This means we can remove from local/global. Lets see how this fleshes out */
    const concatErrors = errors.map(error => error.reason?.message);

    dispatch(
      notifications.actions.notificationCustomError({
        source: <Notification concatErrors={concatErrors} />,
        customizedProps: {autoClose: 5000 + concatErrors.length * 1500, id: `cloudinary_error${concatErrors.length}`},
      })
    );
  };

  /* NOTIFICATION COMPONENT: Display a custom notification */
  const Notification = ({concatErrors}: {concatErrors: string[]}) => {
    const title = concatErrors.length > 1 ? `${concatErrors.length} files have issues and have not been uploaded` : 'Your file(s) could not be uploaded';

    return (
      <div>
        <div className="paddingBottom-tiny1 strong">
          <Icon name="attachment" /> {title}:{' '}
        </div>
        {concatErrors.map((error: string) => (
          <div key={error} className="paddingLeft-small2 paddingTop-tiny1">
            {' '}
            &#183; {shortenStringByNumber(error, 70, '...')}
          </div>
        ))}
      </div>
    );
  };

  /**
   * IF the caller has previous saved attachments, we need to seed them into our state.
   */
  useEffect(() => {
    if (overrideStateOnPreseedChange) {
      handleRemoveSuccessFiles();
    }

    if (preseedAttachments && Array.isArray(preseedAttachments) && preseedAttachments.length > 0) {
      preseedAttachments.forEach(attachment => {
        // TODO -- Check against redux state to see if we already have it.
        // FILTER OUT THE DUPES
        // We do this for situations in which there could be multiple "attachment" intents. As some
        // might be outside the "preseeding".. more of the design needs a thing to be done many times.
        handleUploadSuccess(attachment);
        setFileUploadReduxSuccess(attachment, true);
      });
    }
  }, [preseedAttachments, overrideStateOnPreseedChange, setFileUploadReduxSuccess, handleRemoveSuccessFiles]);

  /* ------------------------------------------------------------------- */
  /* --------------------- API FILE UPLOADS  --------------------------- */
  /* ------------------------------------------------------------------- */

  /*
    Upload a single file

    private
  */
  const uploadFile = async ({file, timestamp, signature, bulkUpload = false}: {file: File; timestamp: string; signature: string; bulkUpload?: boolean}) => {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('api_key', environment.CLOUDINARY.api_key);
    formData.append('timestamp', timestamp);
    formData.append('signature', signature);
    formData.append('allowedFormats', ALLOWED_FILES.join(','));

    Object.entries(params).forEach(([key, value]) => {
      formData.append(key, value);
    });

    /* Uploading into cloudinary */
    return fetch(environment.CLOUDINARY.url, {
      method: 'POST',
      body: formData,
    })
      .then(response => response.text())
      .then(async data => {
        const parsedData = JSON.parse(data);

        // transform the image
        if (parsedData.resource_type === CLOUDINARY_TRANSFORM_FILE_TYPES.IMAGE || CLOUDINARY_TRANSFORM_FILE_TYPES.VIDEO) {
          const tranformedImage = await getAssetPath(parsedData);
          // eslint-disable-next-line no-underscore-dangle
          parsedData.transformedImage = tranformedImage;
        }

        /* Retrieval (fail or success) so no need to fake preload */
        handleSetTotalSuccessFilesCount();

        /* Update States Success */
        if (parsedData.error) {
          return Promise.reject(parsedData.error);
        }

        /**
         * If we are bulk uploading, we do want to seed the various state machines in one go.
         * Use Case: Updating the diff to a backend that allows for multiple files to be uploaded.
         *
         */
        if (!bulkUpload) {
          /* Store it locally */
          handleUploadSuccess(parsedData);
          /* Store it globally */
          setFileUploadReduxSuccess(parsedData);
        }

        return JSON.stringify(parsedData);
      });
  };

  /*
     Upload a multi files. This is the api you interface with.

     public
  */
  const uploadFiles = async (files: File[] | FileList, bulkUpload: boolean = false) => {
    /* Get our signed token */
    generateCloudinaryAuthMutation.mutate(params, {
      /* Signed token response is good. Lets upload */
      onSuccess: async ({timestamp, signature}) => {
        try {
          /* Start Loader */
          api.toggleLoader(true);

          /* Prelim error test against file size. Two objects {success, error } */
          const groupedFiles = getFilesGroupedByMaxFileSize(files, maxFileSize);
          /* Promise of fetches on the success group */
          const promises = groupedFiles?.success.map(file => uploadFile({file, timestamp, signature, bulkUpload}));
          /* This tells us how many thumbnail placeholder loaders to have */
          const groupedFilesLength = groupedFiles?.success?.length ?? 0;

          handleSetTotalSuccessFilesCount(groupedFilesLength);

          setLoading(true);
          const allResponses = await Promise.allSettled(promises).then(resp => resp);
          /* End Loader */
          api.toggleLoader(false);

          /*
            Iterate thru the promises and gobble up
              1. Seed errors with any base file size errors
              2. Seed the successes
         */
          const {succcesses, errors} = allResponses.reduce(
            (groups, response) => {
              if (response.status === 'fulfilled') {
                groups.succcesses.push(JSON.parse(response.value));
              }
              if (response.status === 'rejected') {
                groups.errors.push(response.reason);
              }

              return groups;
            },
            {succcesses: [], errors: []} as {succcesses: TGenericCloudinaryResponse[]; errors: PromiseRejectedResult[]}
          );

          /* Combine the api errors with the FE errors */
          const combinedErrors = errors.concat((groupedFiles?.error ?? []) as PromiseRejectedResult[]);

          if (bulkUpload) {
            handleUploadSuccess(succcesses);
            setFileUploadReduxSuccess(succcesses);
          }

          /* State Assignments for errors */
          handleUploadErrors(combinedErrors);
          setLoading(false);
        } catch (err) {
          /* State Assignments for errors */
          handleUploadErrors([{status: 'rejected', reason: {message: 'useCloudinary: Could not upload images'}}]);
          logger('useCloudinary')(JSON.stringify(err, Object.getOwnPropertyNames(err)) as LoggerErrorType);
          setLoading(false);

          /* End Global Loader */
          api.toggleLoader(false);
        }
      },
    });
  };

  return {
    /* Image/Docs Assets that got uploaded */
    uploadedSuccessFiles,
    /* Files that did not match requirement. Our case, size & api errors */
    uploadErrorFiles,
    /* Method to upload your chosen files from input */
    uploadFiles,
    /* Method to delete an entry from our state & cloudinary. string[] */
    handleDeleteFile,
    /* All the files that are in our successful bucket. We can use this if we want to display a loading or placeholder */
    totalSuccessFilesCount,
    /* Have all the selected files finished uploading to cloudinary? */
    cloudinaryLoading,
    /* If a user saves from the editor, we need to clear out the saves */
    handleRemoveSuccessFiles,
    /* If a user cancels from the editor, we need to clear the state and delete from cloudinary */
    handleDeleteAllFromCloudinary,
    /* This is a standalone usage. If using with editor, this happens automatically */
    handleTransformImage,
    /* This is a standalone usage. You can cache it into redux too */
    handleTransformVideo,
  };
};

export default useCloudinaryHooks;
