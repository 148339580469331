import React, {ComponentProps} from 'react';
import RangeDatePicker from 'components/Elements/RangeDatePicker';
import {isoFormatSlash} from 'global/constants/common';
import {TSharedFormFieldProps} from '../../dataFormFields.types';

type TBaseFilterRangeDatePicker = TSharedFormFieldProps & Partial<ComponentProps<typeof RangeDatePicker>>;

const BaseFilterRangeDatePicker = ({dataTestId, fieldName, formik, label, onChangeCB, ...rest}: TBaseFilterRangeDatePicker) => {
  const onChange: ComponentProps<typeof RangeDatePicker>['onChange'] = v => {
    formik.handleChange({target: {name: fieldName, value: v}});
    const onChangeCallbackValues = (v || []).map(date => {
      return {
        value: date,
        label: date,
      };
    });
    onChangeCB?.({name: fieldName, label: label || fieldName, values: onChangeCallbackValues});
  };

  const value = formik.values[fieldName];
  const error = formik.errors[fieldName]?.toString() || undefined;

  return <RangeDatePicker error={error} format={isoFormatSlash} isRangeSelect label={label} onChange={onChange} placeholder="Select" range={value} {...rest} />;
};

export default BaseFilterRangeDatePicker;
