import {useDispatch} from 'react-redux';
import {useQuery} from 'react-query';

import useApi from 'hooks/useApi';
import APIS from 'global/apis';
import {logger} from 'utils/logger';

import {MDU_PROJECT_QUERY_KEYS} from '../MDU.query.keys';
import {showErrorToast} from 'queries/query.utils';

/** QUERY: Get Project Note Entry Types */
export const useGetProjectNoteEntryTypesQuery = () => {
  const dispatch = useDispatch();
  const api = useApi();

  return useQuery(
    MDU_PROJECT_QUERY_KEYS.getNoteEntryTypes(),
    async () => {
      api.toggleLoader(true);
      const response = await APIS.mdu.getProjectNoteEntryTypes();
      api.toggleLoader(false);
      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Retrieving Note Entry Types'});
        throw new Error(response.err);
      }
      return response?.data?.entry_types;
    },
    {
      onError: response => {
        logger('Projects Note Entry Types: ')(response as Error);
      },
    }
  );
};
