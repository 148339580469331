import React, {useEffect, useState, ComponentProps} from 'react';
import {ELEMENT_SIZE, SelectField} from 'ht-styleguide';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {FIELD_LABELS} from 'features/MultiDwellingUnits/Parts/FormFields/FormFields.constants';
import {PausedStatusTypes} from 'features/MultiDwellingUnits/MDU.types';
import {MduFormElementProps} from 'features/MultiDwellingUnits/Parts/FormFields/FormFields.types';
import {useAppDispatch} from 'hooks/useAppDispatch';
import usePrevious from 'hooks/usePrevious';
import styles from './FormFields.styles.scss';

const PAUSED_OPTIONS = [
  {
    label: 'Yes',
    value: PausedStatusTypes.ONLY_PAUSED,
  },
  {
    label: 'No',
    value: PausedStatusTypes.ONLY_NOT_PAUSED,
  },
];

const PausedSelect = ({formik, fieldName = 'paused', label = FIELD_LABELS.paused, dataTestId = FIELD_LABELS.paused}: MduFormElementProps) => {
  const dispatch = useAppDispatch();
  const [selectedPausedStatus, setSelectedPausedStatus] = useState<string | undefined>();

  const formikValue = formik?.values?.[fieldName];
  const previousFormikValue = usePrevious(formikValue);

  useEffect(() => {
    // Preseed selected value from formik value
    if (!selectedPausedStatus && formikValue) {
      const found = PAUSED_OPTIONS.find(opt => opt.value === formikValue);
      if (found) {
        setSelectedPausedStatus(found.value);
      }
    }
    // When formik is cleared from parent container
    if (!formikValue && previousFormikValue) {
      setSelectedPausedStatus(undefined);
    }
  }, [selectedPausedStatus, formikValue, previousFormikValue]);

  const handleOnChange: ComponentProps<typeof SelectField>['onChange'] = selected => {
    if (!selected) {
      formik.handleChange({target: {name: fieldName, value: ''}});
      setSelectedPausedStatus(undefined);
    } else {
      setSelectedPausedStatus(selected?.value);
      dispatch(mduProjectsSlice.actions.setRawFilterValues({[fieldName]: selected}));
      formik.handleChange({target: {name: fieldName, value: selected?.value}});
    }
  };

  return (
    <SelectField
      id={fieldName}
      clearable
      placeholder="Select"
      label={label}
      value={selectedPausedStatus}
      options={PAUSED_OPTIONS}
      elementSize={ELEMENT_SIZE.MEDIUM}
      error={formik.errors[fieldName]}
      onChange={handleOnChange}
      reactSelectClassName={styles.placeholder}
      dataTestId={dataTestId}
    />
  );
};

export default PausedSelect;
