import React, {useRef, useState} from 'react';
import {MenuListItem, MenuListItemCalloutGroup, MenuPanel, MenuUnorderedList} from 'components/Elements/Menu';
import ToolbarLink from 'components/Table/TableToolbar/TableToolbar.Link';
import useOutsideClick from 'hooks/useOutsideClick';
import {useSelector} from 'hooks/useAppSelector';
import {useAppDispatch} from 'hooks/useAppDispatch';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {TBulkActionTypes, NavPageTypes} from 'features/MultiDwellingUnits/MDU.types';
import {IEditProjectsLink} from './MDUProjectsFilters.types';

import styles from './MDUProjectsFilters.styles.scss';

const EditProjectsLink = ({pageType}: IEditProjectsLink) => {
  /* Local State */
  const [menu, setMenu] = useState(false);
  const wrapperRef = useRef(null);
  const showApproveProjects = pageType !== NavPageTypes.PAUSED;

  /* Hooks */
  const dispatch = useAppDispatch();

  /* Selectors */
  const totalSelected = useSelector(mduProjectsSlice.selectors.getPaginationTotalSelected);

  /* Methods */
  const handleClickOutside = () => {
    setMenu(false);
  };
  const onHandleSetMenu = () => {
    setMenu(!menu);
  };

  const onHandleDispatch = (key: TBulkActionTypes) => {
    dispatch(mduProjectsSlice.actions.setActionItemBulkModalSlide({key}));
    onHandleSetMenu();
  };

  useOutsideClick(wrapperRef, handleClickOutside);

  if (totalSelected <= 0) return null;

  return (
    <span ref={wrapperRef} className="position relative">
      <ToolbarLink onClick={onHandleSetMenu} iconName="edit-plain" label={`Edit Projects (${totalSelected})`} />
      {menu ? (
        <MenuPanel className={styles.menuPosition}>
          <MenuUnorderedList>
            {showApproveProjects && (
              <MenuListItem dataTestId="menu-approve-projects" onClick={() => onHandleDispatch(TBulkActionTypes.APPROVE_BULK_PROJECTS)}>
                Approve Projects
              </MenuListItem>
            )}
            <MenuListItemCalloutGroup dataTestId="menu-delete-projects" onClick={() => onHandleDispatch(TBulkActionTypes.DELETE_BULK_PROJECTS)}>
              Delete Projects
            </MenuListItemCalloutGroup>
          </MenuUnorderedList>
        </MenuPanel>
      ) : null}
    </span>
  );
};

export default EditProjectsLink;
