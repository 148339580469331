import React, {useEffect, useState} from 'react';
import {ELEMENT_SIZE, SelectField} from 'ht-styleguide';

// utils & constants
import {FIELD_LABELS} from 'features/MultiDwellingUnits/Parts/FormFields/FormFields.constants';
import {IProjectPageParams, PROJECT_PAGE_PARAMS} from 'features/MultiDwellingUnits/Pages/Projects/Projects.constants';

// hooks
import {useProjectsRouteParams} from 'features/MultiDwellingUnits/Pages/Projects/hooks';

// types
import {SelectOption} from 'features/MultiDwellingUnits/MDU.types';
import {MduFormElementProps} from 'features/MultiDwellingUnits/Parts/FormFields/FormFields.types';

// styles
import styles from './FormFields.styles.scss';

const filterKey = 'statuses';
const StatusesSelector = ({customStatuses, formik, fieldName = FIELD_LABELS.status, label = FIELD_LABELS.status, dataTestId = FIELD_LABELS.status}: MduFormElementProps) => {
  const [selectedDetails, setSelectedDetails] = useState<SelectOption | SelectOption[]>();
  const {projectsPage} = useProjectsRouteParams();
  const statusOptions = customStatuses ? {keys: customStatuses, multiple: true} : (PROJECT_PAGE_PARAMS as IProjectPageParams)[projectsPage]?.statuses;
  const {keys, multiple} = statusOptions || {};

  const handleDetailsChange = (pt: any) => {
    setSelectedDetails(pt);

    const getValues = !multiple
      ? [pt.value]
      : ([].concat(pt) as any).filter(Boolean).reduce((all: string[], current: SelectOption) => {
          return [...all, current.value];
        }, []);

    return formik.handleChange({
      target: {name: filterKey, value: getValues},
    });
  };

  useEffect(() => {
    /* Seed project type */
    if (formik?.values?.[filterKey]) {
      const selections = formik.values[filterKey];
      const findMatchingStatus = keys?.reduce((all: SelectOption[], status: SelectOption) => {
        if (selections.includes(status.value)) {
          return all.concat({
            value: status.value,
            label: status.label,
          });
        }

        return all;
      }, []);

      if (findMatchingStatus && findMatchingStatus.length) {
        const selected = multiple ? findMatchingStatus : findMatchingStatus[0];

        setSelectedDetails(selected);
      }
    }
  }, [formik]);

  if (!keys) return null;

  return (
    <SelectField
      id="statuses"
      placeholder="Select Status Type"
      options={keys}
      label={label}
      value={selectedDetails}
      onChange={type => handleDetailsChange(type)}
      elementSize={ELEMENT_SIZE.MEDIUM}
      error={formik.errors[fieldName]}
      reactSelectClassName={styles.placeholder}
      dataTestId={dataTestId}
      multiple={multiple}
      /* @ts-ignore */
      menuPosition="fixed"
    />
  );
};

export default StatusesSelector;
