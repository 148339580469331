import {createSlice, createSelector} from '@reduxjs/toolkit';
import {RootState} from 'store';

/*
 *****************************************
 ************ INITIAL STATE  *************
 *****************************************
 */

const initialState = {
  bookingType: 'default',
  bookingTypeId: '',
};

/*
 ****************************************
 ************ CREATE SLICE  *************
 *****************************************
 */
const bookingTypeSlice = createSlice({
  name: 'bookingType',
  initialState,
  reducers: {
    setBookingType: (state, action) => {
      return action.payload;
    },
  },
});

/*
*******************************************************
  SELECTORS & SELECTOR METHODS
*******************************************************
*/
const getBookingTypeState = (state: RootState) => state.booking.bookingType;

/*
*******************************************************
  EXPORTS
*******************************************************
*/
export const selectors = {
  getBookingTypeState: createSelector(getBookingTypeState, bookingType => bookingType),
};

export const actions = {...bookingTypeSlice.actions};

export default bookingTypeSlice.reducer;
