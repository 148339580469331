import React from 'react';
import {Route, Routes, Navigate, Outlet} from 'react-router-dom';
import AdminHubLayout from 'components/Layouts/AdminHub/AdminHub.layout';
import Header from 'components/Layouts/AdminHub/Header';
import Sidebar from 'components/Layouts/AdminHub/Sidebar';
import {Routes as HTRoutes} from 'global/routes/routes';
// Pages
import OrderTaskChecklistPage from './Pages/OrderTaskChecklist';

const OrdersRoutesStructureLayout = () => {
  // Redo when we have more pages
  const tempMenu = [
    {
      entries: [
        {
          key: 'All Orders',
          label: 'All Orders',
          active: true,
          icon: 'grid',
        },
      ],
    },
  ];

  return (
    <AdminHubLayout>
      <Header />
      <AdminHubLayout.ContentTwoColumnContainer>
        <AdminHubLayout.ContentLeftContainer>
          <Sidebar menu={tempMenu} headerProps={{title: 'Orders'}} isCollapsible />
        </AdminHubLayout.ContentLeftContainer>
        <AdminHubLayout.ContentRightContainer>
          <Outlet />
        </AdminHubLayout.ContentRightContainer>
      </AdminHubLayout.ContentTwoColumnContainer>
    </AdminHubLayout>
  );
};

const OrdersRoutesStructure = () => (
  <Routes key="OrdersRoutes">
    <Route element={<OrdersRoutesStructureLayout />}>
      {/* Temporary until there are more pages */}
      <Route index element={null} />
      {/* Task Checklist */}
      <Route path={HTRoutes.ORDERS_TASK_CHECKLIST().replace(`${HTRoutes.ORDERS_PATH_BASE()}/`, '')} element={<OrderTaskChecklistPage />} />
      <Route path={HTRoutes.ORDERS_TASK_CHECKLIST_BY_SERVICE_ID().replace(`${HTRoutes.ORDERS_PATH_BASE()}/`, '')} element={<OrderTaskChecklistPage />} />
    </Route>
    <Route path="*" element={<Navigate to={HTRoutes.ORDERS_PATH_BASE()} replace />} />
  </Routes>
);

export default OrdersRoutesStructure;
