import React, {useCallback} from 'react';
import WidgetDashboard from 'components/Elements/WidgetDashboard';
import {useGetProjectsStats} from 'features/MultiDwellingUnits/queries/query.project.stats';
import {useSetFilters} from 'features/MultiDwellingUnits/Pages/Projects/Parts/MDUProjectsFilters/MDUProjectsFilters.hooks';
import {IMDUProjectsStatsProps} from 'features/MultiDwellingUnits/Pages/Projects/Parts/MDUProjectsStats/MDUProjectsStats.types';
import {NavPageTypes, NavTypes} from 'features/MultiDwellingUnits/MDU.types';
import StatWidget from './MDUProjectsStats.StatWidget';
import {buildInProgressStatWidgetProps} from './MDUProjectsStats.utils';

const MDUProjectsStats = ({pageType, statKeys = [], defaultLabel = 'Projects', projectsType}: IMDUProjectsStatsProps) => {
  const {setFilters} = useSetFilters(pageType);
  useGetProjectsStats();

  const onFiltersChange = useCallback(
    (newFilters: any) => {
      setFilters({
        ...newFilters,
      });
    },
    [setFilters]
  );

  /* ---------------------- UNIQUE STAT WIDGET VIEWS ---------------------- */
  /* All status projects page */
  if (pageType === NavPageTypes.ALL_STATUSES) {
    return (
      <WidgetDashboard numCols={3} numRows={2} dataTestId="projects_stats" className="Components-Grid_escapeMargin">
        <StatWidget statKey={statKeys[0]} label={defaultLabel} onFiltersChange={onFiltersChange} />
        <StatWidget statKey={statKeys[1]} label={defaultLabel} onFiltersChange={onFiltersChange} />
        <StatWidget statKey={statKeys[2]} label={defaultLabel} onFiltersChange={onFiltersChange} />
        <StatWidget statKey={statKeys[3]} label={defaultLabel} onFiltersChange={onFiltersChange} />
        <StatWidget statKey={statKeys[4]} label={defaultLabel} onFiltersChange={onFiltersChange} />
        <StatWidget statKey={statKeys[5]} label={defaultLabel} onFiltersChange={onFiltersChange} />
      </WidgetDashboard>
    );
  }

  // Only NavePageTypes.IN_PROGRESS for now
  const isViewingOwn = projectsType === NavTypes.OWN;

  return (
    <WidgetDashboard numCols={4} numRows={2} className="Components-Grid_escapeMargin">
      {statKeys.map(_statKey => (
        <StatWidget key={_statKey} {...buildInProgressStatWidgetProps({_statKey, isViewingOwn, defaultLabel, onFiltersChange})} />
      ))}
    </WidgetDashboard>
  );
};

export default React.memo(MDUProjectsStats);
