import React, {useState, useEffect, memo} from 'react';
import cn from 'classnames';
// elements
import {Checkbox, CheckboxGroup, ErrorMessage} from 'ht-styleguide';
import {WarningHint, WarningHintIcon} from 'components/Elements/Messages';
// types
import {BaseAnyFunction} from 'types/base.types';
import {Answer, Sku, QuestionsAPIByQuestion, CheckBoxValue, AdjAmountFormatted} from 'features/Questions/types';
// components
import CheckboxQuantitySelector from './CheckboxQuantitySelector';
// uii
import styles from '../qa.styles.scss';

type PreQuestionCheckboxProps = {
  question: QuestionsAPIByQuestion;
  sku: Sku;
  value?: CheckBoxValue;
  error?: string;
  onChange: BaseAnyFunction;
  onQuantityChange: BaseAnyFunction;
};

type Hints = {
  [key: string]: boolean;
};

const PreQuestionCheckbox: React.FC<PreQuestionCheckboxProps> = props => {
  const [hints, setHints] = useState<Hints>({});
  const {question, value: asValue = [], onChange, sku, error} = props;
  const value = asValue || [];
  const values = (Array.isArray(value) && value.map(v => v.id)) || [];
  const checkboxStyles = cn(styles.preQuestionCheckbox, {
    [styles.preQuestionCheckboxError]: error,
  });
  const answerStyles = cn(styles.checkboxWrapper, styles.answer);

  useEffect(() => {
    if (Array.isArray(value) && value.length) {
      const qaHints = value.reduce((a, v) => ({...a, [v.id]: true}), {});
      setHints({...qaHints});
    }
  }, []);

  const onQuantityChange = async (answer: Answer, qtyValue: number) => {
    await props.onQuantityChange({id: answer.id, quantity: qtyValue});
  };

  const onHintClick = (answer: Answer, event: React.SyntheticEvent) => {
    event.preventDefault();
    setHints({...hints, [answer.id]: !hints[answer.id]});
  };

  const renderWarningHint = (answer: Answer) => {
    const visible = Boolean(hints && hints[answer.id]);

    return (
      <div className={styles.answerHint}>
        <WarningHint message={answer.warning} visible={visible} className="marginX-small" />
      </div>
    );
  };

  const renderAnswer = ({answer, value: cbv, sku: qaSku}: {answer: Answer; value: CheckBoxValue; sku: Sku}) => {
    let label = answer.text;

    if (answer.adjAmountFormatted) {
      const amount = (answer.adjAmountFormatted as AdjAmountFormatted)?.formatted ?? answer.adjAmountFormatted;
      const partnerText = answer.subsidizedToPartner ? ` Paid by ${qaSku.partner?.name && 'Partner'}` : '';
      label += ` (+${amount}${partnerText})`;
    }
    const selectedValue = cbv.find(v => v.id === answer.id);
    const isHintVisible = !!answer.warning;
    /* have to manage here for ts acceptance */
    const Warn = () => (isHintVisible && <WarningHintIcon onClick={event => onHintClick(answer, event)} />) || null;
    const CQS = () => (answer.hasQuantity && selectedValue && <CheckboxQuantitySelector answer={answer} selectedValue={selectedValue} onChange={onQuantityChange} />) || null;

    return (
      <div className={styles.checkboxContainer} key={answer.id}>
        <Checkbox value={answer.id} label={label} classes={styles.check} dataTestId={`qa-checkbox-answer-${answer.id}`}>
          <CQS />
          <Warn />
        </Checkbox>
        <div className="clear" />
      </div>
    );
  };

  return (
    <div className={checkboxStyles}>
      <div className={answerStyles}>
        <CheckboxGroup value={values} label={question.textDirect} onChange={onChange}>
          {question.answers.map(a => {
            return (
              <div key={a.id}>
                {renderAnswer({answer: a, value, sku})}
                {!!a.warning && renderWarningHint(a)}
              </div>
            );
          })}
        </CheckboxGroup>
        <ErrorMessage error={error as string} />
      </div>
    </div>
  );
};

export default memo(PreQuestionCheckbox);
