import React, {useCallback, useEffect, useMemo, useState} from 'react';
import get from 'lodash/get';
import {ELEMENT_SIZE, InputField, SelectField} from 'ht-styleguide';

// utils
import {sleep} from 'utils/core';

// hooks & constants
import {useAppDispatch} from 'hooks/useAppDispatch';
import usePrevious from 'hooks/usePrevious';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {useGetProjectPropertyOwnersQuery} from 'features/MultiDwellingUnits/queries/query.project.fields';
import {FIELD_LABELS} from './FormFields.constants';

// styles
import styles from './FormFields.styles.scss';

// Types
import {MduFormElementProps, ProjectDropdownDetails, SelectOption} from './FormFields.types';

const Other = 'Other';

const defaultDetails = {value: '', label: 'Type to search', isDisabled: true};

const PropertyOwnerSelect = ({
  callback,
  formik,
  project,
  label = FIELD_LABELS.propertyOwner,
  fieldName = 'propertyOwnerId',
  dataTestId = FIELD_LABELS.propertyOwner,
  readOnly = false,
  multiple = false,
  includeOther = true,
}: MduFormElementProps) => {
  const dispatch = useAppDispatch();

  // Property Owner Details
  const {data: propertyOwnersData = []} = useGetProjectPropertyOwnersQuery({includeOther});
  const [selectedDetails, setSelectedDetails] = useState<SelectOption | SelectOption[] | string>('');
  const [showOtherInput, setShowOtherInput] = useState<boolean>(false);

  const propertyOwnersList = useMemo(() => {
    /* When in filter mode, we do not want an 'Other' display */
    return propertyOwnersData
      .filter((po: ProjectDropdownDetails) => (readOnly && po.id) || !readOnly)
      .map((elem: ProjectDropdownDetails) => ({
        value: elem.id,
        label: `${elem.name} ${elem.id ? `#${elem.id}` : ''}`,
      }));
  }, [propertyOwnersData, readOnly]);

  const formikValue = get(formik.values, fieldName);
  const previousFormikValue = usePrevious(formikValue);
  useEffect(() => {
    // Preseed select if parent already has formik value
    if (!selectedDetails && formikValue) {
      const found = propertyOwnersList.filter(entry => (multiple ? formikValue.includes(entry.value) : entry.value === formikValue));
      if (found) {
        setSelectedDetails(multiple ? found : found[0]);
      }
    }
    // When formik is clear from parent container
    if (!formikValue && previousFormikValue) {
      setSelectedDetails('');
    }
  }, [formikValue, multiple, previousFormikValue, propertyOwnersList, selectedDetails]);

  // helper since use it 2x+
  const setFormikValue = useCallback(
    (name: string, value: number | string | Array<number | string>) => {
      formik.handleChange({
        target: {name, value},
      });
    },
    [formik]
  );

  // Property Owner handleOtherChange
  const handleDetailsChange = (po: SelectOption | SelectOption[] | null | undefined) => {
    if (!po) {
      setSelectedDetails(defaultDetails);
      return setFormikValue(fieldName, '');
    }
    setSelectedDetails(po);

    if (!Array.isArray(po) && po.label?.trim() === Other) {
      setShowOtherInput(true);
      callback?.(true);
      setSelectedDetails(defaultDetails);
    }

    if (Array.isArray(po)) {
      return setFormikValue(
        fieldName,
        po.map(p => p.value)
      );
    }
    return setFormikValue(fieldName, po.value);
  };

  const handleOtherChange = (name: string) => {
    setSelectedDetails(defaultDetails);
    // clear dropdown selection
    setFormikValue('propertyOwnerName', '');
    // set input entry
    return setFormikValue('propertyOwnerName', name);
  };

  const revertToDropdown = () => {
    setShowOtherInput(false);
    callback?.(false);
    // clear input entry
    return setFormikValue('propertyOwnerName', '');
  };

  const getDisplay = () => {
    return showOtherInput ? (
      <InputField
        id="propertyOwnerName"
        label={label}
        onChange={po => handleOtherChange(po.target.value)}
        value={formik.values.propertyOwnerName}
        autoFocus
        iconOnClick={revertToDropdown}
        iconName="v2-close-icon"
        iconClass={styles.closeIcon}
        inputWrapperClass={styles.placeholder}
        error={formik.errors.propertyOwnerName}
      />
    ) : (
      <SelectField
        id={fieldName}
        placeholder="Search by Name"
        options={[...propertyOwnersList]}
        filterOption={() => true}
        label={label}
        value={selectedDetails}
        onChange={po => {
          handleDetailsChange(po);
          dispatch(mduProjectsSlice.actions.setRawFilterValues({propertyOwnerId: po}));
        }}
        elementSize={ELEMENT_SIZE.MEDIUM}
        clearable
        searchable
        multiple={multiple}
        reactSelectClassName={styles.placeholder}
        dataTestId={dataTestId}
        error={formik.errors.propertyOwnerId}
      />
    );
  };

  useEffect(() => {
    /* Seed property owner */
    if (project?.propertyOwner) {
      const value = project.propertyOwner.id;
      handleDetailsChange({
        value,
        label: project.propertyOwner.name,
      });

      sleep(1000).then(() => {
        setFormikValue(fieldName, value);
      });
    }
  }, [project]);

  return getDisplay();
};

export default PropertyOwnerSelect;
