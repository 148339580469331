import React, {useState} from 'react';
import {Chip, Tooltip, ChipContentShape} from 'ht-styleguide';
import {IChipProps} from 'ht-styleguide/build/Chip/Chip.types';
import {MenuListItem, MenuPanel, MenuUnorderedList} from 'components/Elements/Menu';
import styles from './chipMenu.styles.scss';

export type TChipMenuItem = {
  /** Menu item text */
  text: string;
  /** Unique key for the menu item */
  key: string | number;
  /** Function to call when the menu item is clicked */
  onClick: (args: any) => void;
};

export type TChipMenu = {
  /** In the minimized state the Chip will show a circle instead of text */
  showMinimized?: boolean;
  /** Text that shows in the Chip and on hover */
  text?: string;
  /** List of menu items to show when the Chip is clicked */
  menuList?: Array<TChipMenuItem>;
  /** Props to pass to the Chip component */
  chipProps?: Partial<IChipProps>;
};

// This is a placeholder for now. We will need to implement this later.
const ChipMenu: React.FC<TChipMenu> = ({showMinimized, text = '', menuList = [], chipProps}) => {
  const [showMenu, setShowMenu] = useState(false);

  const handleChipClick = () => {
    setShowMenu(!showMenu);
  };

  const chipContent = showMinimized ? <ChipContentShape type="circle" /> : <>{text}</>;

  // We need to add the open/closed chevron items, but they're not in the styleguide yet.
  const baseContent = (
    <div onClick={handleChipClick} className={styles.wrapper}>
      <Chip {...chipProps}>{chipContent}</Chip>
      {showMenu && (
        <MenuPanel className={styles.menuPanel}>
          <MenuUnorderedList>
            {menuList.map(item => (
              <MenuListItem key={item.key} onClick={item.onClick} dataTestId="ChipMenu">
                {item.text}
              </MenuListItem>
            ))}
          </MenuUnorderedList>
        </MenuPanel>
      )}
    </div>
  );

  const canShowTooltip = !showMenu;
  return canShowTooltip ? <Tooltip content={text}>{baseContent}</Tooltip> : baseContent;
};

export default ChipMenu;
