import React, {ComponentProps, ReactNode} from 'react';
import {FormikProps} from 'formik';
import {InputField, SelectArrayOptions, SelectBaseOption, SelectField, TextArea} from 'ht-styleguide';
import RangeDatePicker from 'components/Elements/RangeDatePicker';

/* Utils */
import {getAttributesByPriorityStatus} from 'features/Issues/Issues.utils';
import {determineFieldDisableStatusByOperation} from 'features/Issues/Parts/IssuesFieldsGenerator/issues.fieldsGenerator.utils';

/* Queries | Selectors */
import {useDynamicIssueTicketFields} from 'features/Issues/queries/query.issues.fields';
import {TUserCurrent, useUserCurrentQuerySelect} from 'queries/User/query.user.current';

/* Constants */
import {isoFormatSlash} from 'global/constants/common';
import {ISSUE_FIELD_ENTITY_TYPE, ISSUE_FIELDS_OPERATION_TYPE, ReadOnly} from 'features/Issues/Issues.constants';

/* Types */
import {TField, TFieldForTable} from 'features/Issues/issues.types';

import styles from 'components/Elements/AvatarUser/avataruser.styles.scss';

type ISeedProps = string | SelectArrayOptions | undefined;

/**
 * This callback is used when a field's value changes.
 */
type TFieldOnChangeCallback = ({name, label, values}: {name: string; label: string; values: Array<{value: any; label: any}>}) => void;

type TFieldsGenerator<T = ISeedProps> = Partial<{
  field: TField | TFieldForTable;
  formik: FormikProps<any>;
  consumer: (...props: any) => ReactNode;
  seed: T;
  isLoading: boolean;
  currentUser: TUserCurrent;
  operationType: keyof typeof ISSUE_FIELDS_OPERATION_TYPE;
  /**
   * Force single select to be multi-select.
   * This is used for the filter modal to allow for multiple selections while preserving the single select
   * behavior for the issue sidesheet.
   */
  forceMultiSelect?: boolean;
}>;

type TFieldsGeneratorProps = {
  field: TField | TFieldForTable;
  formik: FormikProps<any>;
  suppressLabel?: boolean;
  onChangeCallback?: TFieldOnChangeCallback;
  useHidden?: boolean;
  /* This allows us to identify uniqueness within the fields themselves */
  id: number | string;
} & TFieldsGenerator;

type IFieldsGeneratorBaseProps = {
  field: TField | TFieldForTable;
  formik: FormikProps<any>;
  onChangeCallback?: TFieldOnChangeCallback;
} & TFieldsGenerator;

type IFieldsGeneratorSelectProp<T> = {
  field: TField | TFieldForTable;
  formik: FormikProps<any>;
  multiSelect?: boolean;
  onChangeCallback?: TFieldOnChangeCallback;
  id?: string | number;
} & TFieldsGenerator<T>;

/**
 * @description This is a generic component that will generate the fields for the issue side sheet and filters modal.
 *              This should be generic enough to handle create/edit/filter types.
 *
 *              Note: This will pass up to the formik schema its default and yup validation. Since its dynamic, we don't
 *                    know ahead of time what it is.
 * @param field
 * @param consumer
 * @param formik
 * @param suppressLabel
 * @param operationType
 * @constructor
 */
const FieldsGenerator = ({field, consumer, formik, operationType, onChangeCallback, useHidden = false, forceMultiSelect = false, id}: TFieldsGeneratorProps): any => {
  const {data, isLoading} = useDynamicIssueTicketFields({field, operationType, id});
  const currentUser = useUserCurrentQuerySelect();

  if (!(field.type in FieldsGenerator)) {
    return null;
  }

  /* Append unique field(s) to field */
  const label = field.required && operationType !== 'filter' ? `${field.label}*` : field.label;
  /* Operation types to suppress label */
  const updateOperationTypes: (keyof typeof ISSUE_FIELDS_OPERATION_TYPE)[] = [ISSUE_FIELDS_OPERATION_TYPE.update];
  const disableElementField = determineFieldDisableStatusByOperation({operationType, field, key: ReadOnly});

  /* Field to pass down that represents the current iterated field with custom additions */
  const newField = {
    ...field,
    /* custom fields to pass to all elements as it relates to the internals of the element */
    commonProps: {
      label: updateOperationTypes.includes(operationType!) ? '' : label,
      /**
       * Needs this special formatting for formik. If formik sees dot notation, it will populate
       * the values object as a nested object. https://formik.org/docs/guides/arrays#avoid-nesting
       */
      name: `['${field.name}']`,
      error: formik.errors[field.name],
      value: formik.values[field.name],
      disabled: disableElementField,
      elementSize: updateOperationTypes.includes(operationType!) ? 'xsmall' : 'medium',
      inlineEditable: updateOperationTypes.includes(operationType!) || false,
      ...(field.allowCustomEntry && {showAddCustomValue: true}),
    },
  };

  /* Component reference by key (field.type): This builds the component dynamically as needed */
  const fieldType = field.type === 'select' && forceMultiSelect ? 'multi_select' : field.type;
  const ComponentRender = FieldsGenerator[(useHidden ? 'hidden' : fieldType) as keyof typeof FieldsGenerator];
  /* This is the result of the apis/options to preseed the values */
  const seedValue = data && Object.keys(data).length > 0 ? (data as SelectArrayOptions) : [];

  /* Consolidate base level reusable props */
  const baseLevelProps = {field: newField, formik, seed: seedValue, isLoading, currentUser, id, onChangeCallback, operationType};
  const ElemComponent = ComponentRender(baseLevelProps);

  if (useHidden) return ElemComponent;

  /* Do we have a wrapper/consuming component for special displays? */
  if (consumer) {
    /* Test against arity */
    switch (consumer.length) {
      case 1:
        return consumer(ElemComponent);
      default:
        return consumer(field.label, ElemComponent);
    }
  }

  return ElemComponent;
};

FieldsGenerator.hidden = ({field}: IFieldsGeneratorBaseProps) => {
  const inputProps = Object.fromEntries(Object.entries(field.commonProps).filter(([key]) => !['elementSize', 'inlineEditable'].includes(key)));

  return <input type="hidden" {...inputProps} />;
};

FieldsGenerator.text = ({field, formik, onChangeCallback}: IFieldsGeneratorBaseProps) => {
  const {label} = field.commonProps;

  const onChange: ComponentProps<typeof InputField>['onChange'] = e => {
    const {value} = e.target || {};
    formik.handleChange(e);
    onChangeCallback?.({name: field.name, label, values: [{value, label: value}]});
  };

  return <InputField onChange={onChange} {...field.commonProps} />;
};

FieldsGenerator.numeric = ({field, formik, onChangeCallback}: IFieldsGeneratorBaseProps) => {
  const {label} = field.commonProps;

  const onChange: ComponentProps<typeof InputField>['onChange'] = e => {
    const {value} = e.target || {};
    formik.handleChange(e);
    onChangeCallback?.({name: field.name, label, values: [{value, label: value}]});
  };

  return <InputField onChange={onChange} {...field.commonProps} />;
};

FieldsGenerator.select = ({field, formik, multiSelect = false, seed, currentUser, isLoading, id, onChangeCallback}: IFieldsGeneratorSelectProp<SelectBaseOption[]>) => {
  let seedUpdate = field.options || seed;

  if (isLoading) return <div className="padding-tiny1 caption">Loading {field.name} data</div>;

  /* You can only clear out our a non-required select */
  const clearable = !field.required;
  /* Select uses 'isDisabled' not disabled. Lets not */
  field.commonProps.isDisabled = field.commonProps.disabled;
  /* Only select cares about customizable - user enters their own entry */
  field.commonProps.showAddCustomValue = 'customizable' in field ? field.customizable : false;

  /* This is to show the Avatar to the left of the selected Name */
  if (field.entity_type === ISSUE_FIELD_ENTITY_TYPE.admin && seed) {
    /* Display Coloring based on ownership */
    const nonOwner = styles.nonOwnerAvatar;
    const owner = styles.ownerAvatar;

    // @ts-ignore
    seedUpdate = seed?.map(option => {
      return {
        ...option,
        avatarProps: {
          name: option.label,
          className: option.value === currentUser?.id ? owner : nonOwner,
        },
      };
    });
  }

  if (field.entity_type === ISSUE_FIELD_ENTITY_TYPE.priority) {
    // @ts-ignore
    seedUpdate = seed?.map(option => {
      const attributes = getAttributesByPriorityStatus(option.label);
      return {
        ...option,
        iconProps: {
          name: attributes?.icon?.iconDefault,
          className: attributes?.icon?.className,
        },
      };
    });
  }

  return (
    <SelectField
      key={`${field.name}-${field.options?.length}-${id}`}
      options={seedUpdate!}
      onChange={v => {
        const singleValue = v?.value ? [v.value] : undefined;
        const value = multiSelect ? v?.map((val: {value: string | number}) => val.value) : singleValue;
        formik.handleChange({target: {name: field.commonProps.name, value: value || []}});
        const onChangeCallbackValues = (() => {
          if (!v) return [];
          return multiSelect ? v : [v];
        })();
        onChangeCallback?.({name: field.name, label: field.commonProps.label, values: onChangeCallbackValues});
      }}
      multiple={multiSelect}
      clearable={clearable}
      searchable
      {...field.commonProps}
    />
  );
};

/**
 * FYI: WE haven't been able to "test" this since the BE doesn't accept dates for this feature, at this time.
 *
 * @param field
 * @param formik
 */
FieldsGenerator.datetime = ({field, formik, onChangeCallback, operationType}: IFieldsGeneratorBaseProps) => {
  return (
    <RangeDatePicker
      {...field.commonProps}
      key="startDateRange"
      format={isoFormatSlash}
      range={formik.values[field.name]}
      isRangeSelect={operationType === 'filter'}
      placeholder="Select"
      onChange={v => {
        formik.handleChange({target: {name: field.commonProps.name, value: v}});
        const onChangeCallbackValues = (v || []).map(date => {
          return {
            value: date,
            label: date,
          };
        });
        onChangeCallback?.({name: field.name, label: field.commonProps.label, values: onChangeCallbackValues});
      }}
    />
  );
};

FieldsGenerator.multi_select = (props: IFieldsGeneratorSelectProp<SelectArrayOptions>) => {
  // eslint-disable-next-line react/jsx-pascal-case
  return <FieldsGenerator.select {...props} multiSelect />;
};

FieldsGenerator.long_text = ({field, formik, onChangeCallback}: IFieldsGeneratorBaseProps) => {
  const {label} = field.commonProps;

  const onChange: ComponentProps<typeof TextArea>['onChange'] = e => {
    const {value} = e.target || {};
    formik.handleChange(e);
    onChangeCallback?.({name: field.name, label, values: [{value, label: value}]});
  };

  return <TextArea onChange={onChange} {...field.commonProps} placeholder={`Add a ${field.name.toLowerCase()}`} />;
};

FieldsGenerator.rich_content = (props: IFieldsGeneratorBaseProps) => {
  // eslint-disable-next-line react/jsx-pascal-case
  return <FieldsGenerator.long_text {...props} />;
};

export default FieldsGenerator;
