import React, {ReactNode} from 'react';
import {EXPAND_COLUMN_WIDTH} from '../dataTable.constants';
import styles from './detailPanel.styles.scss';

interface IDetailPanel {
  children: ReactNode;
}

const placeholderColumnWidth = `${EXPAND_COLUMN_WIDTH}px`;

const DetailPanel = ({children}: IDetailPanel) => {
  return (
    <div className={styles.outerContainer}>
      <div className={styles.innerContainer}>
        <div className={styles.placeholderColumn} style={{maxWidth: placeholderColumnWidth, width: placeholderColumnWidth, minWidth: placeholderColumnWidth}} />
        <div className={styles.childrenColumn}>{children}</div>
      </div>
    </div>
  );
};

export default DetailPanel;
