import isEmpty from 'lodash/isEmpty';
import xorWith from 'lodash/xorWith';
import {TGenericCloudinaryResponse} from './cloudinary.types';
import {IMAGE_ALLOWED_FILES} from 'utils/files/constants';

/**
 * Sorts an array of Cloudinary responses by most recent, first.
 * @param a
 * @param b
 */
export const sortByMostRecentCloudinaryResponse = (a: TGenericCloudinaryResponse, b: TGenericCloudinaryResponse) => {
  const dateA = new Date(a.created_at);
  const dateB = new Date(b.created_at);

  if (dateB < dateA) {
    return -1;
  }
  if (dateB > dateA) {
    return 1;
  }

  return 0; // Dates are equal
};

/**
 * Returns true if the attachment is an image.
 * @param attachment
 *
 * todo: remove the one in mdu.utils
 */
export const isAttachmentImage = <T>(attachment: T & {file_type: string}) => IMAGE_ALLOWED_FILES.includes(attachment?.file_type?.toLowerCase());

/**
 * Derive a readable file name from a Cloudinary response to display in the UI.
 * @param attachment
 * @param withoutQueryParams
 *
 * todo: remove the one in mdu.utils
 */
export const getFileNameFromAttachmentUrl = <T>(attachment: T & {url: string}, {withoutQueryParams = false} = {}) => {
  if (!attachment.url) return '';
  const splitUrl = attachment.url.split('/');
  if (withoutQueryParams) {
    return splitUrl[splitUrl.length - 1].split('?')[0];
  }
  return splitUrl[splitUrl.length - 1];
};

export const isCloudinaryResponseEqual = (arr1: TGenericCloudinaryResponse[], arr2: TGenericCloudinaryResponse[]) => {
  const comparer = (a: TGenericCloudinaryResponse, b: TGenericCloudinaryResponse) => a.asset_id === b.asset_id;
  return isEmpty(xorWith(arr1, arr2, comparer));
};
