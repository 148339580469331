import {SpringConfig} from '@react-spring/web';
import {Routes} from 'global/routes/routes';
import {MDU_ROUTES} from 'features/MultiDwellingUnits/MDU.constants';
import {DispatchNavTypes} from 'features/Dispatch/dispatch.types';
import {IssuesNavTypes} from 'features/Issues/issues.types';
import {LinkItemProps} from './adminHub.types';

export const TOP_NAV_ITEMS_DEFAULT: LinkItemProps[] = [
  {
    text: 'Projects',
    key: 0,
    link: '/mdu/projects/all/all_statuses',
    active: [MDU_ROUTES.MDUPathProjectsBase, MDU_ROUTES.MDUPathProjectBase],
  },
  {
    text: 'Dispatch',
    key: 1,
    link: `${Routes.DISPATCH_PATH_BASE()}/${DispatchNavTypes.All}`,
    active: Routes.DISPATCH_PATH_BASE(),
  },
  {
    text: 'Issues',
    key: 2,
    link: `${Routes.ISSUES_PATH_BASE()}/${IssuesNavTypes.OPEN}`,
    active: Routes.ISSUES_PATH_BASE(),
  },
];

export const ADMIN_HUB_LAYOUT_ANIMATION_CONFIG: SpringConfig = {
  tension: 200,
  friction: 30,
  precision: 0.99,
  duration: 200, // milliseconds
};

export const LAYOUT_CONTAINER_ID = 'adminHubContainer';
export const RIGHT_CONTAINER_PORTAL_TOP_ID = 'rightContainerPortalTop';
export const RIGHT_CONTAINER_PORTAL_BOTTOM_ID = 'rightContainerPortalBottom';
