import React, {useCallback, useEffect, useMemo, useRef, useImperativeHandle} from 'react';
import {MRT_ColumnOrderState, MRT_TableInstance, MaterialReactTable} from 'material-react-table';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import cn from 'classnames';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import usePrevious from 'hooks/usePrevious';
import Paginator from './Paginator';
import useInternalPagination from './Paginator/paginator.useInternalPagination';
import ExpandAllIcon from './Icons/ExpandAllIcon';
import ExpandIcon from './Icons/ExpandIcon';
import SortIcon from './Icons/SortIcon';
import {getLeftPinnedColumns, determineIsLeftPinnedColumn, determineIsRightPinnedColumn, overrideMuiComponentsOnCol, setColumnWidth, extractDensityState} from './utils/dataTable.utils';
import {MRT_COLUMN_WIDTH, EXPAND_COLUMN_WIDTH} from './dataTable.constants';
import {useResetPagination} from './dataTable.hooks';
import {TDataTable, TDisplayColumnDefOptions} from './dataTable.types';
import styles from './dataTable.styles.scss';

/**
 * `DataTable` is a wrapper around `material-react-table`. Please refer to MRT's documentation for more
 * info: https://www.material-react-table.com/docs/api/props.
 *
 * Notes:
 * - Most props passed to MRT are for design purposes.
 * - This table uses its own paginator component as MRT's built-in paginator was not flexible enough.
 * - This table has a concept of mass selection. When `setMassSelectAll` is provided, the table will
 *   call it when there is a change to the row selection.
 * - When working with checkbox selection, MRT's state.rowSelection should include all selected rows
 *   across all pages. This is so that the massSelectAll hook will not turn off massSelectAll mode
 *   on page changes.
 */
const DataTable = <TData extends Record<string, any> = {}>({
  columns,
  data,
  enableColumnActions = false,
  enableColumnDragging = false,
  enableTopToolbar = false,
  enableExpandAll = false,
  enableExpanding = false,
  hasActionableRows = false,
  initialState = {},
  isZebra = false,
  internalPagination,
  massSelectAll = false,
  muiTableBodyRowProps,
  onRowSelectionChange,
  pagination,
  selectAllMode = 'page',
  setMassSelectAll = () => {},
  showPaginator = false,
  state = {},
  tableInstanceRef,
  tableKey,
  transparentBG = false,
  ...rest
}: TDataTable<TData>) => {
  const {rowSelection} = state || {};
  const previousRowSelection = usePrevious(rowSelection);
  const internalTableRef = useRef<MRT_TableInstance<TData>>(null);

  useImperativeHandle(tableInstanceRef, () => internalTableRef.current!);

  // Reset pagination when tableKey changes
  useResetPagination({tableKey});

  // Column Data overrides
  const columnData = useMemo(() => {
    return columns.map(column => {
      const newCol = {...column};

      overrideMuiComponentsOnCol({column: newCol, tableState: internalTableRef.current?.getState()});
      setColumnWidth({newCol, density: extractDensityState({table: internalTableRef.current})});
      omit(newCol, ['columnWidthMode', 'columnWidthSize', 'columnWidthSizeCollapse', 'iconColumn', 'tooltipContent']);

      return newCol;
    });
  }, [columns]);

  /* --------------------------- massSelectAll mode ---------------------------- */
  /**
   * When massSelectAll is true, we want to select all rows in the that is currently displayed as a visual
   * cue. Due to the complexity of a massSelectAll mode and maintaining the state across pages, we will turn
   * off massSelectAll mode when the user selects/deselects a row or changes pagination state. How the
   * massSelectAll flag is used is up to the consumer.
   */
  const turnOffMassSelectAll = useCallback(() => {
    setMassSelectAll(false);
  }, [setMassSelectAll]);

  useEffect(() => {
    if (massSelectAll) {
      internalTableRef?.current?.toggleAllRowsSelected(true);
    }
  }, [massSelectAll, internalTableRef]);

  useEffect(() => {
    const previousRowSelectionCount = Object.keys(previousRowSelection || {}).length;
    const rowSelectionCount = Object.keys(rowSelection || {}).length;
    const isAllSelected = internalTableRef?.current?.getIsAllRowsSelected() || false;

    // Turn off massSelectAll mode if there's a change in row selection
    if (massSelectAll && !isAllSelected && !!previousRowSelectionCount && rowSelectionCount !== previousRowSelectionCount) {
      turnOffMassSelectAll();
    }
  }, [massSelectAll, rowSelection, previousRowSelection, internalTableRef, setMassSelectAll, turnOffMassSelectAll]);
  /* --------------------------- END massSelectAll mode ---------------------------- */

  const {data: finalData, internalPaginatorAttr} = useInternalPagination<TData>({data, showPaginator, internalPagination, pagination});

  // Turn off zebra in grouped mode
  const shouldApplyZebra = isZebra && !(state?.grouping ?? []).length;

  // Override options for MRT's built-in columns
  const displayColumnDefOptions = useMemo(() => {
    const opts: TDisplayColumnDefOptions = {
      'mrt-row-select': {
        enablePinning: true,
        maxSize: MRT_COLUMN_WIDTH,
        size: MRT_COLUMN_WIDTH,
        minSize: MRT_COLUMN_WIDTH,
        muiTableBodyCellProps: ({row}) => {
          const isGroupedRow = row.getIsGrouped();
          return {
            className: cn(isGroupedRow && styles.groupedCellInRow),
          };
        },
      },
      'mrt-row-expand': {
        enablePinning: true,
        maxSize: EXPAND_COLUMN_WIDTH,
        size: EXPAND_COLUMN_WIDTH,
        minSize: EXPAND_COLUMN_WIDTH,
        muiTableBodyCellProps: ({row}) => {
          const isGroupedRow = row.getIsGrouped();
          return {
            className: cn(isGroupedRow && styles.groupedCellInRow, styles.expandIconCell),
          };
        },
        muiTableHeadCellProps: () => {
          return {
            className: styles.expandIconCell,
          };
        },
      },
    };
    return opts;
  }, []);

  // Keeping for now
  const finalInitialState = useMemo(() => {
    const defaults: TDataTable['initialState'] = {};
    return merge(defaults, initialState);
  }, [initialState]);

  const finalState = useMemo(() => {
    let pinnedLeftColumns: MRT_ColumnOrderState = [];
    if (internalTableRef && internalTableRef.current) {
      const table = internalTableRef.current;
      pinnedLeftColumns = getLeftPinnedColumns({table, stateFromProp: state});
    }

    // If declaring column order, need to manually add in MRT's special columns
    if (state.columnOrder) {
      if (rest.enableRowSelection) {
        state.columnOrder.unshift('mrt-row-select');
      }
      if (enableExpanding) {
        state.columnOrder.unshift('mrt-row-expand');
      }
    }

    return merge(state, {columnPinning: {left: pinnedLeftColumns}});
  }, [state, rest.enableRowSelection, enableExpanding]);

  const icons: TDataTable<TData>['icons'] = {
    ArrowDownwardIcon: SortIcon,
    ExpandMoreIcon: ExpandIcon<TData>({internalTableRef}),
    KeyboardDoubleArrowDownIcon: ExpandAllIcon<TData>({internalTableRef}),
  };

  const dataTableStyles = cn(transparentBG && styles.transparentBG);

  return (
    <div className={dataTableStyles}>
      <StyledEngineProvider injectFirst>
        <MaterialReactTable<TData>
          columns={columnData}
          data={finalData}
          displayColumnDefOptions={displayColumnDefOptions}
          enableBottomToolbar={false}
          enableColumnActions={enableColumnActions}
          enableColumnDragging={enableColumnDragging}
          enableExpandAll={enableExpandAll}
          enableExpanding={enableExpanding}
          enableGrouping
          enablePagination={false}
          enablePinning
          enableSortingRemoval
          enableTopToolbar={enableTopToolbar}
          icons={icons}
          initialState={finalInitialState}
          manualPagination
          muiSelectAllCheckboxProps={{
            disableRipple: true,
          }}
          muiSelectCheckboxProps={({row}) => {
            return {
              disableRipple: true,
              className: cn(row.getIsGrouped() && styles.groupedCheckbox),
            };
          }}
          // muiTableBodyCellProps={} ...See `overrideMuiComponentsOnCol` function
          muiTableBodyRowProps={args => {
            const baseProps = typeof muiTableBodyRowProps === 'function' ? muiTableBodyRowProps(args) : muiTableBodyRowProps;
            const isGroupHeaderRow = args.row.getIsGrouped();
            return {
              ...baseProps,
              className: cn(
                baseProps?.className,
                isGroupHeaderRow ? styles.groupSubheader : '',
                !isGroupHeaderRow && shouldApplyZebra ? styles.zebra : '',
                hasActionableRows ? styles.actionableRow : ''
              ),
            };
          }}
          muiTableHeadCellDragHandleProps={() => {
            return {
              className: styles.dragHandle,
            };
          }}
          muiTableHeadCellProps={({column, table}) => {
            const isLastLeftPinnedColumn = determineIsLeftPinnedColumn({column, table});
            const isFirstRightPinnedColumn = determineIsRightPinnedColumn({column, table});
            return {
              className: cn(isLastLeftPinnedColumn && styles.leftPinnedColumn, isFirstRightPinnedColumn && styles.rightPinnedColumn),
            };
          }}
          muiTablePaperProps={{
            elevation: 0,
          }}
          muiTableContainerProps={({table}) => {
            const density = extractDensityState<TData>({table});
            const densityStyle = density === 'compact' ? styles.compact : '';
            return {className: cn(densityStyle, transparentBG && styles.blah)};
          }}
          onRowSelectionChange={onRowSelectionChange}
          selectAllMode={selectAllMode}
          state={finalState}
          tableInstanceRef={internalTableRef}
          {...rest}
        />
      </StyledEngineProvider>
      <Paginator internalPaginatorAttr={internalPaginatorAttr} showPaginator={showPaginator} pagination={pagination} />
    </div>
  );
};

export default DataTable;
