import React from 'react';
import cn from 'classnames';
import {DonutChart, Icon, ProgressBarChart} from 'ht-styleguide';
import {noop} from 'utils/event';
import {DataWidgetProps} from './dataWidget.types';
import styles from './dataWidget.scss';

const DataWidget = ({
  actionIcon = 'filter',
  actionOnClick,
  className,
  colorVariant = 'default',
  icon = 'bar-chart',
  label,
  noImage = false,
  progress,
  prominent = false,
  suppressActions = false,
  value = '',
}: DataWidgetProps) => {
  const hasAction = !suppressActions && actionOnClick;
  const containerStyles = cn(styles.container, prominent && styles.prominent, hasAction && styles.hover, className);
  const valueStyles = cn({h4: prominent, h5: !prominent});

  return (
    <div className={containerStyles} onClick={hasAction ? actionOnClick : noop}>
      {hasAction && actionIcon && <Icon name={actionIcon} className={styles.actionIcon} />}
      {!noImage && (
        <div className={cn(styles.imageContainer, styles[`iconVariant-${colorVariant}`])}>
          {progress && prominent ? (
            <DonutChart size={56} {...progress} />
          ) : (
            <>
              <div className={styles.iconBackground}>
                <Icon name={icon} className={styles.icon} />
              </div>
            </>
          )}
        </div>
      )}
      <div className={styles.content}>
        <div className={cn(styles.label, 'marginBottom-tiny n700')}>{label}</div>
        <p className={valueStyles}>{value}</p>
        {progress && !prominent && <ProgressBarChart {...progress} />}
      </div>
    </div>
  );
};

export default React.memo(DataWidget);
