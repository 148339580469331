import * as Yup from 'yup';
import {TFilterKey} from 'features/MultiDwellingUnits/MDU.types';

type TFilters = {
  [key in TFilterKey]: Yup.StringSchema | Yup.NumberSchema | Yup.ObjectSchema<any> | Yup.ArraySchema<any> | Yup.NotRequiredArraySchema<any>;
};
/* Added a test on the dates, but you won't see it. If you do not select a "to", we auto-populate with same value as 'from' */
export const FILTERS: TFilters = {
  propertyOwnerId: Yup.array().of(Yup.number()),
  propertyOwnerName: Yup.string(),
  leadTechId: Yup.array().of(Yup.number()),
  partnerId: Yup.array().of(Yup.number()),
  projectManagerId: Yup.array().of(Yup.number()),
  startDate: Yup.object({
    from: Yup.string().nullable(),
    to: Yup.string().nullable(),
  }).test("No 'to' date", "Select a 'to' date", value => {
    if (value.from && !value.to) {
      return false;
    }
    return true;
  }),
  estimatedEndDate: Yup.object({
    from: Yup.string().nullable(),
    to: Yup.string().nullable(),
  }).test("No 'to' date", "Select a 'to' date", value => {
    if (value.from && !value.to) {
      return false;
    }
    return true;
  }),
  approvedAt: Yup.object({
    from: Yup.string().nullable(),
    to: Yup.string().nullable(),
  }).test("No 'to' date", "Select a 'to' date", value => {
    if (value.from && !value.to) {
      return false;
    }
    return true;
  }),
  lastModifiedAt: Yup.object({
    from: Yup.string().nullable(),
    to: Yup.string().nullable(),
  }).test("No 'to' date", "Select a 'to' date", value => {
    if (value.from && !value.to) {
      return false;
    }
    return true;
  }),
  flagged: Yup.string(),
  only_flagged: Yup.string(),
  statuses: Yup.array().of(Yup.string()),
  project_group_ids: Yup.array().of(Yup.string()),
  project_types: Yup.array().of(Yup.string()),
  search: Yup.string(),
  paused: Yup.string(),
};

export const CHIP_LABEL_PREFIX: Omit<Record<TFilterKey, string>, 'propertyOwnerName' | 'search'> = {
  propertyOwnerId: 'Property Owner',
  partnerId: 'Partner',
  projectManagerId: 'Project Manager',
  leadTechId: 'Team Lead',
  project_group_ids: 'Job Template ID',
  startDate: 'Start Date Range',
  approvedAt: 'Approved Date Range',
  estimatedEndDate: 'End Date Range',
  lastModifiedAt: 'Last Modified',
  statuses: 'Status',
  only_flagged: 'Flagged',
  flagged: 'Flagged',
  project_types: 'Project Type',
  paused: 'Paused',
};
