import React from 'react';
import {Route, Routes, Outlet} from 'react-router-dom';
import AdminHubLayout from 'components/Layouts/AdminHub/AdminHub.layout';
import Header from 'components/Layouts/AdminHub/Header';
import Sidebar from 'components/Layouts/AdminHub/Sidebar';
import {Routes as HTRoutes} from 'global/routes/routes';
import TaskChecklistPage from './Pages/TaskChecklist';
import useProductsDnDScroll from './hooks/useProductsDnDScroll';

const ProductsRoutesStructureLayout = () => {
  // Redo when we have more pages
  const tempMenu = [
    {
      entries: [
        {
          key: 'All Products',
          label: 'All Products',
          active: true,
          icon: 'grid',
        },
      ],
    },
  ];

  const {scrollableContainerRef} = useProductsDnDScroll();

  return (
    <AdminHubLayout>
      <Header />
      <AdminHubLayout.ContentTwoColumnContainer>
        <AdminHubLayout.ContentLeftContainer>
          <Sidebar menu={tempMenu} headerProps={{title: 'Products'}} isCollapsible />
        </AdminHubLayout.ContentLeftContainer>
        <AdminHubLayout.ContentRightContainer ref={scrollableContainerRef}>
          <Outlet />
        </AdminHubLayout.ContentRightContainer>
      </AdminHubLayout.ContentTwoColumnContainer>
    </AdminHubLayout>
  );
};

const ProductsRoutesStructure = () => (
  <Routes key="ProductsRoutes">
    <Route element={<ProductsRoutesStructureLayout />}>
      <Route index element={null} />
      <Route path={HTRoutes.PRODUCTS_TASK_CHECKLIST().replace(`${HTRoutes.PRODUCTS_PATH_BASE()}/`, '')} element={<TaskChecklistPage />} />
    </Route>
  </Routes>
);

export default ProductsRoutesStructure;
