import React from 'react';
import {useSelector} from 'react-redux';
import dayjs from 'dayjs';
// Hooks
import {useNavigate, useParams} from 'react-router-dom';
import {useFetchProjectTypes} from 'features/MultiDwellingUnits/MDU.hooks';

// Utils
import {numericDayMonthYear} from 'global/constants/common';
import {getProjectType, getStartDateLabel} from 'features/MultiDwellingUnits/MDU.utils';
// Routes
import {mduProjectPagePath} from 'global/paths';
// Ducks & Types
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {CurrentProjectStages, ProjectDetails, MduUseParamsTypes} from 'features/MultiDwellingUnits/MDU.types';
import {FormCategories, ProjectDetailsPageHeaderTypes} from './CurrentProject.Details.types';
// Components & Styles
import {Grid} from 'ht-styleguide'; // eslint-disable-line import/order
import ReadOnlySection, {ReadOnlyField} from './CurrentProject.Details.ReadOnlySection';
import ProjectDetailsPageHeader from './CurrentProject.Details.PageHeader';

const getRequiredFieldsByCategory = (project: ProjectDetails, category: FormCategories) => {
  // An empty array indicates that there are no required fields
  const fieldsByCat = {
    [FormCategories.Project]: [project.name, project.partner?.id, project.projectManager?.id, project.propertyOwner?.id, project.projectType, project.startDate, project.estimatedEndDate],
    [FormCategories.Property]: [project.address],
    [FormCategories.Contact]: [project.contactName, project.contactPhone, project.contactTitle],
    [FormCategories.Other]: [],
  };
  return fieldsByCat[category] || [];
};

export const hasRequiredFields = (fields: any[]) => {
  if (!fields || !fields.length) return false;
  return fields.some(val => Boolean(val) === false);
};

/* Read-only view of Project Details */
const CurrentProjectDetailsReadOnlyPage = () => {
  /* Hooks */
  const navigate = useNavigate();
  const allProjectTypes = useFetchProjectTypes();

  /* Data */
  const currentProject: ProjectDetails = useSelector(mduProjectsSlice.selectors.getCurrentProject);
  const {projectId = ''} = useParams<MduUseParamsTypes>();

  /* Methods */
  const formatDate = (date: string) => dayjs(date).format(numericDayMonthYear);
  const onEditButtonClick = () => navigate(mduProjectPagePath(projectId, CurrentProjectStages.EDIT));

  return (
    <div className="paddingBottom-medium1">
      <ProjectDetailsPageHeader onClick={onEditButtonClick} type={ProjectDetailsPageHeaderTypes.Edit} />

      <ReadOnlySection headerText={FormCategories.Project} hasRequiredFields={hasRequiredFields(getRequiredFieldsByCategory(currentProject, FormCategories.Project))}>
        <Grid.FullWidth>
          <Grid.Row classes="marginTop-small2">
            <Grid.Column lg={12} md={8} sm={4}>
              <ReadOnlyField headerText="Project Name*" valueText={currentProject.name || ''} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row classes="marginTop-small2">
            <Grid.Column lg={6} md={8} sm={4}>
              <ReadOnlyField headerText="Partner*" valueText={currentProject.partner?.name} />
            </Grid.Column>
            <Grid.Column lg={6} md={8} sm={4}>
              <ReadOnlyField headerText="Project Manager*" valueText={currentProject.projectManager?.name} isAvatarField />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row classes="marginTop-small2">
            <Grid.Column lg={6} md={8} sm={4}>
              <ReadOnlyField headerText="Property Owner*" valueText={currentProject.propertyOwner?.name} />
            </Grid.Column>
            <Grid.Column lg={6} md={8} sm={4}>
              <ReadOnlyField headerText="Project Type*" valueText={currentProject.projectType ? getProjectType(currentProject.projectType, allProjectTypes) : ''} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row classes="marginTop-small2">
            <Grid.Column lg={6} md={8} sm={4}>
              <ReadOnlyField
                headerText={getStartDateLabel({project: currentProject, isRequiredField: true}) as string}
                valueText={currentProject.startDate ? formatDate(currentProject.startDate) : ''}
                missingValueText="---"
                valueIconName="calendar-plain"
              />
            </Grid.Column>
            <Grid.Column lg={6} md={8} sm={4}>
              <ReadOnlyField
                headerText="Estimated End Date*"
                valueText={currentProject.estimatedEndDate ? formatDate(currentProject.estimatedEndDate) : ''}
                missingValueText="---"
                valueIconName="calendar-plain"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid.FullWidth>
      </ReadOnlySection>

      <ReadOnlySection headerText={FormCategories.Property} hasRequiredFields={hasRequiredFields(getRequiredFieldsByCategory(currentProject, FormCategories.Property))}>
        <Grid.FullWidth>
          <Grid.Row classes="marginTop-small2">
            <Grid.Column lg={6} md={8} sm={4}>
              <ReadOnlyField headerText="Address*" valueText={currentProject.address || ''} missingValueText="---" />
            </Grid.Column>
            <Grid.Column lg={6} md={8} sm={4}>
              <ReadOnlyField headerText="Number of Floors" valueText={currentProject.floorsNumber ? String(currentProject.floorsNumber) : ''} missingValueText="Optional" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row classes="marginTop-small2">
            <Grid.Column lg={12} md={8} sm={4}>
              <ReadOnlyField headerText="Access and Parking Instructions" valueText={currentProject.accessInstructions || ''} missingValueText="---" />
            </Grid.Column>
          </Grid.Row>
        </Grid.FullWidth>
      </ReadOnlySection>

      <ReadOnlySection headerText={FormCategories.Contact} hasRequiredFields={hasRequiredFields(getRequiredFieldsByCategory(currentProject, FormCategories.Contact))}>
        <Grid.FullWidth>
          <Grid.Row classes="marginTop-small2">
            <Grid.Column lg={6} md={8} sm={4}>
              <ReadOnlyField headerText="Name*" valueText={currentProject.contactName || ''} missingValueText="---" />
            </Grid.Column>
            <Grid.Column lg={6} md={8} sm={4}>
              <ReadOnlyField headerText="Phone Number*" valueText={currentProject.contactPhone || ''} missingValueText="---" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row classes="marginTop-small2">
            <Grid.Column lg={12} md={8} sm={4}>
              <ReadOnlyField headerText="Job Title*" valueText={currentProject.contactTitle || ''} missingValueText="---" />
            </Grid.Column>
          </Grid.Row>
        </Grid.FullWidth>
      </ReadOnlySection>

      <ReadOnlySection headerText={FormCategories.Other} hasRequiredFields={hasRequiredFields(getRequiredFieldsByCategory(currentProject, FormCategories.Other))}>
        <Grid.FullWidth>
          <Grid.Row classes="marginTop-small2">
            <Grid.Column lg={12} md={8} sm={4}>
              <ReadOnlyField valueText={currentProject.notes || ''} missingValueText="---" />
            </Grid.Column>
          </Grid.Row>
        </Grid.FullWidth>
      </ReadOnlySection>
    </div>
  );
};

export default CurrentProjectDetailsReadOnlyPage;
