import {createSelector} from '@reduxjs/toolkit';
import {RootState} from 'store';

const getNotificationSelector = (state: RootState) => state?.notification;
const notificationSelector = createSelector(
  getNotificationSelector,
  notification => notification
);

export default notificationSelector;
