import {useState, useEffect} from 'react';

/**
 * Check if an image exists hook
 * @param imageUrl
 *
 * note: Using native new Image because locally fetch can cause cors issues.
 */
const useDoesImageExist = (imageUrl: string = '') => {
  const [imageExists, setImageExists] = useState(false);

  useEffect(() => {
    const image = new Image();

    const handleLoad = () => {
      setImageExists(true);
    };

    const handleError = () => {
      setImageExists(false);
    };

    image.addEventListener('load', handleLoad);
    image.addEventListener('error', handleError);

    image.src = imageUrl;

    /* Clean up the effect */
    return () => {
      image.removeEventListener('load', handleLoad);
      image.removeEventListener('error', handleError);
    };
  }, [imageUrl]);

  return imageExists;
};

export default useDoesImageExist;
