import {useCallback, useEffect, useMemo, useState} from 'react';
import {useSpring} from '@react-spring/web';
import {noop} from 'utils/event';
import {ISidebar} from './Sidebar.types';
import {ADMIN_HUB_LAYOUT_ANIMATION_CONFIG} from '../AdminHub.layout.constants';
import {SHOW_HIDE_BUTTON_SIZE, SIDEBAR_WIDTH} from './Sidebar.constants';

/**
 * Some components may support controlled and uncontrolled state.
 *
 * - Controlled state is when its value is handled by its parent (props).
 * - Uncontrolled state is when its value is handled by the component itself. It's uncontrolled by its parent.
 */
interface IuseControlledStateArgs<T> {
  preventChange?: boolean;
  initialValue?: T;
}

export function useControlledState<T>(controlled: T | undefined, setControlled: ((arg0: T) => void) | undefined, paramsArg?: IuseControlledStateArgs<T>): [T | undefined, (arg0: T) => void] {
  const params = useMemo(
    () => ({
      preventChange: false,
      initialValue: undefined,
      ...(paramsArg || {}),
    }),
    [paramsArg]
  );
  const withControl = controlled !== undefined;

  const [uncontrolled, setUncontrolled] = useState(withControl ? controlled : params.initialValue);

  const value = withControl ? controlled : uncontrolled;

  const setValue = useCallback(
    (newValue: T) => {
      if (params.preventChange) {
        return; // Do nothing
      }
      setControlled?.(newValue);
      setUncontrolled(newValue);
    },
    [params, setControlled]
  );

  return [value, setValue];
}

export const useSidebarStateController = ({isParentCollapsed: isCollapsed, toggleParentCollapsed: toggleIsCollapsed = noop}: Partial<ISidebar>) => {
  const [showCollapseButton, setShowCollapseButton] = useState(false);

  /**
   * `isPeeking` determines if the sidebar is in a "peeking" state - partially visible on hover
   * when the sidebar is collapsed.
   */
  const [isPeeking, setIsPeeking] = useState(false);

  /* This is an eye-balled value to adjust the position of the sidebar when it is animated */
  const manualOverridePixels = 2;

  /**
   * This animation handles the "peeking" behavior of the sidebar.
   * - If `isPeeking` is true, the sidebar will slide right.
   * - If `isPeeking` is false, the sidebar will slide left.
   *
   * The animation works in combination with `isParentCollapsed`
   * - If `isParentCollapsed` is true, the sidebar will be hidden, and peeking will be enabled.
   * - If `isParentCollapsed` is false, the sidebar will be visible, and peeking will be disabled.
   */
  const animationStyleProps = useSpring({
    transform: isPeeking ? `translateX(${SIDEBAR_WIDTH + manualOverridePixels - SHOW_HIDE_BUTTON_SIZE}px)` : `translateX(0px)`,
    config: ADMIN_HUB_LAYOUT_ANIMATION_CONFIG,
  });

  const onMouseEnter = useCallback(() => {
    if (isCollapsed) {
      setIsPeeking(true);
    } else {
      setShowCollapseButton(true);
    }
  }, [isCollapsed]);

  const onMouseLeave = useCallback(() => {
    if (isCollapsed) {
      setIsPeeking(false);
    } else {
      setShowCollapseButton(false);
    }
  }, [isCollapsed]);

  const onCollapseButtonClick = () => {
    toggleIsCollapsed();
    if (isCollapsed) {
      setIsPeeking(false);
    }
  };

  useEffect(() => {
    if (isCollapsed) {
      setShowCollapseButton(true);
    }
  }, [isCollapsed]);

  return {isPeeking, setIsPeeking, showCollapseButton, onMouseEnter, onMouseLeave, onCollapseButtonClick, animationStyleProps};
};
