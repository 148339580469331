import React from 'react';
import cn from 'classnames';
import {ProjectDetails} from 'features/MultiDwellingUnits/MDU.types';
import {Panel} from 'ht-styleguide';
import styles from './paymentAdjustment.styles.scss';
import {getTotalPayoutStyles} from './utils';

const AdjustmentSummary = ({project, className}: {project: ProjectDetails; className?: string}) => {
  const flexStyles = 'flex justify-content-space-between';
  return (
    <Panel noShadow largeBorderRadius className={cn(styles.panel, 'padding-small2', className)}>
      <p className="overline-medium n700 marginBottom-small1">Summary</p>
      <p className={cn('p1 n800 paddingBottom-small1', flexStyles)}>
        <span>Original Project Payout</span>
        <span>{project.originalTechPayoutFormatted}</span>
      </p>
      <p className={cn('p1 n800 paddingBottom-small1 marginBottom-small1', flexStyles, styles.borderBottom)}>
        <span>Total Payout Adjustment</span>
        <span>
          {project.totalTechPayoutAdjustmentFormatted} ({project.percentTechPayoutAdjustmentFormatted})
        </span>
      </p>
      <p className={cn('p1 n800 text-weight-med paddingBottom-small1', flexStyles)}>
        <span>Total Project Payout</span>
        <span className={getTotalPayoutStyles(project)}>{project.totalTechPayoutFormatted}</span>
      </p>
      <p className={cn('p1 n800', flexStyles)}>
        <span>Margin</span>
        <span>{project.percentMarginFormatted}</span>
      </p>
    </Panel>
  );
};

export default AdjustmentSummary;
