import cdnImages from 'global/constants/cdnImages';
import styles from './thumbnailDisplay.styles.scss';
import {TThumbnailFile} from './thumbnail.types';
import {VIDEO_FILE_EXTENSIONS, IMAGE_FILE_EXTENSIONS, SPREADSHEET_FILE_EXTENSIONS} from './thumbnail.constants';

/** Higher-order function that returns a function to check if a file's type matches any of the given extensions */
const isFileOfType = (extensions: string[]) => (fileType: string) => {
  return extensions.includes(fileType.toLowerCase());
};

export const isVideoFileType = isFileOfType(VIDEO_FILE_EXTENSIONS);
export const isImageFileType = isFileOfType(IMAGE_FILE_EXTENSIONS);
export const isSpreadsheetFileType = isFileOfType(SPREADSHEET_FILE_EXTENSIONS);

/**
 * Determines the image source, alt text, and class for a thumbnail image based on the file type.
 *
 * @param {Object} options - The options object containing `url`, `file_type`, and `altText` properties.
 * @returns {Object} - The image configuration object with `imgSrc`, `altText`, and `imgClass` properties.
 */
export const getImageElementConfig = ({url = '', file_type = '', altText = '', isCustomIcon = false, transformedFile = ''}: Partial<TThumbnailFile>) => {
  if (isCustomIcon) {
    // Custom icons use the same styling as file/spreadsheet icons but are user-uploaded.
    return {
      imgSrc: url,
      altText,
      imgClass: styles.thumbnailIcon,
    };
  }

  if (isImageFileType(file_type) || isVideoFileType(file_type)) {
    return {
      imgSrc: url,
      imgClass: styles.thumbnailImage,
      altText,
      transformedFile,
    };
  }

  if (isSpreadsheetFileType(file_type)) {
    return {
      imgSrc: cdnImages.spreadsheetIcon,
      imgClass: styles.thumbnailIcon,
      altText,
    };
  }

  // Default case for all other file types
  return {
    imgSrc: cdnImages.fileIcon,
    imgClass: styles.thumbnailIcon,
    altText,
    transformedFile,
  };
};
