import React, {useState, useRef, RefObject} from 'react';
import debounce from 'lodash/debounce';
import {ELEMENT_SIZE, Icon, InputField, Panel} from 'ht-styleguide';
import cn from 'classnames';
import {useAppDispatch} from 'hooks/useAppDispatch';

/* Ducks */
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';

/* Hooks */
import useOutsideClick from 'hooks/useOutsideClick';

/* Components */
import {MenuListItem, MenuPanel, MenuUnorderedList} from 'components/Elements/Menu';
import TemplateJobsGroupServicesToolTip from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/Parts/TemplateJobs.GroupServicesToolTip';

/* Utils */
import {filterTextFromNumberInputs} from 'utils/form';
import {noop, stopImmediatePropagation, stopPropagationOnDiv} from 'utils/event';

import styles from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/templatejobs.styles.scss';

import {TProjectGroupDraft} from 'features/MultiDwellingUnits/MDU.types';

type TGroupId = number | string | null;
const JobsCreateTemplatesView = ({projectGroupsDraft}: {projectGroupsDraft: TProjectGroupDraft[]}) => {
  /* Local State */
  const [activeGroupMenuId, setActiveGroupMenuId] = useState<TGroupId>();

  /* Hooks */
  const dispatch = useAppDispatch();
  const groupMenuRef: RefObject<HTMLDivElement> = useRef(null);

  /* Methods */
  const onHandleInputDebounce = debounce(
    (e, group) => {
      dispatch(mduProjectsSlice.actions.updateTemplateCreationQtyDraft({id: group.id, unitsNumber: parseInt(e.target.value, 10)}));
    },
    300,
    {trailing: true, leading: false}
  );

  const handleClickOutside = () => {
    setActiveGroupMenuId(null);
  };

  useOutsideClick(groupMenuRef, handleClickOutside);

  return (
    <div className="paddingTop-medium fullwidth">
      {projectGroupsDraft.filter(Boolean).map((group: TProjectGroupDraft) => (
        <Panel key={group.id} noShadow largeBorderRadius className={cn(styles.panel, 'padding-small2 marginBottom-small')}>
          <div className="flex flex-direction-row justify-content-space-between cursorPointer">
            <h6>{group.name}</h6>
            <div data-for="groupAttributes" data-tip={group.id} className={styles.hoverArea} />
            <div className="position relative">
              <div ref={groupMenuRef} onClick={stopPropagationOnDiv}>
                <Icon name="more" className={styles.icon} onClick={() => setActiveGroupMenuId(group.id)} />
              </div>
              {activeGroupMenuId === group.id && (
                <MenuPanel>
                  <MenuUnorderedList>
                    <MenuListItem
                      dataTestId="menu-edit-job-info"
                      onClick={e => {
                        stopImmediatePropagation(noop)(e);
                        dispatch(mduProjectsSlice.actions.deleteGroupFromList({id: group.id}));
                      }}
                    >
                      Delete Template
                    </MenuListItem>
                  </MenuUnorderedList>
                </MenuPanel>
              )}
            </div>
          </div>
          <div className={styles.explicitHeight24} data-for="groupAttributes" data-tip={group.id} />
          <div className="flex flex-direction-row justify-content-space-between cursorPointer">
            <div className="flex flex-direction-column" onClick={stopPropagationOnDiv}>
              <div>
                <InputField
                  type="number"
                  onKeyDown={filterTextFromNumberInputs}
                  elementSize={ELEMENT_SIZE.S}
                  defaultValue={group.unitsNumber}
                  onChange={e => {
                    e?.persist?.();
                    onHandleInputDebounce(e, group);
                  }}
                  containerClass={styles.inputContainer}
                  iconComponent={<div className="p2 n700">Units</div>}
                />
              </div>
            </div>
            <div data-for="groupAttributes" data-tip={group.id} className={styles.hoverArea} />
            <div>{group.perUnitTotalWithoutSubsidyFormatted} per unit</div>
          </div>
        </Panel>
      ))}
      <TemplateJobsGroupServicesToolTip draft />
    </div>
  );
};

export default JobsCreateTemplatesView;
