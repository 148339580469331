import React from 'react';
import * as Yup from 'yup';
import get from 'lodash/get';
// Hooks
import {useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import {useAppDispatch} from 'hooks/useAppDispatch';
// Utils
import {noop} from 'utils/event';
import {getStartDateLabel} from 'features/MultiDwellingUnits/MDU.utils';
// Routes
import {mduProjectPagePath} from 'global/paths';
// Ducks & Types
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {CreateProjectPayloadAction, CurrentProjectStages, ProjectDetails} from 'features/MultiDwellingUnits/MDU.types';
// Constants
import {CREATE_PROJECT_MODAL_TEST_ID} from 'features/MultiDwellingUnits/MDU.constants';
// Components & Styles
import PartnerSelect from 'features/MultiDwellingUnits/Parts/FormFields/PartnerSelect';
import PropertyOwnerSelect from 'features/MultiDwellingUnits/Parts/FormFields/PropertyOwnerSelect';
import ProjectTypeSelect from 'features/MultiDwellingUnits/Parts/FormFields/ProjectTypeSelect';
import ProjectManagerSelect from 'features/MultiDwellingUnits/Parts/FormFields/ProjectManagerSelect';
import {Modal, ELEMENT_SIZE, Form, InputField, Button, BUTTON_THEMES} from 'ht-styleguide';
import styles from './modals.styles.scss';

export const FORM_FIELDS: {[key: string]: {id: string; label: string}} = {
  PROJECT_NAME: {id: 'name', label: 'Project Name*'},
  PARTNER: {id: 'partnerId', label: 'Partner*'},
  PROPERTY_OWNER: {id: 'propertyOwnerId', label: 'Property Owner*'},
  PROPERTY_OWNER_NAME: {id: 'propertyOwnerName', label: 'Property Owner*'},
  PROJECT_MANAGER: {id: 'projectManagerId', label: 'Project Manager*'},
  PROJECT_TYPE: {id: 'projectType', label: 'Project Type*'},
  /** The label text is being set by getStartDateLabel(); */
  START_DATE: {id: 'startDate', label: ''},
  UNIT_COUNT: {id: 'unitsNumber', label: 'Unit Count'},
};

const createNewProjectSchema = Yup.object().shape(
  {
    [FORM_FIELDS.PROJECT_NAME.id]: Yup.string().required('Required'),
    [FORM_FIELDS.PARTNER.id]: Yup.string().required('Required'),
    [FORM_FIELDS.PROPERTY_OWNER.id]: Yup.number().when(FORM_FIELDS.PROPERTY_OWNER_NAME.id, {
      is: value => Boolean(value),
      then: Yup.number().typeError('').notRequired(),
      otherwise: Yup.number().typeError('Required').required('Required'),
    }),
    [FORM_FIELDS.PROPERTY_OWNER_NAME.id]: Yup.string().when(FORM_FIELDS.PROPERTY_OWNER.id, {
      is: value => Number.isNaN(value),
      then: Yup.string().required('Required'),
      otherwise: Yup.string().nullable(true),
    }),
    [FORM_FIELDS.PROJECT_MANAGER.id]: Yup.number().required('Required'),
    [FORM_FIELDS.PROJECT_TYPE.id]: Yup.string().required('Required'),
    [FORM_FIELDS.START_DATE.id]: Yup.string(),
    [FORM_FIELDS.UNIT_COUNT.id]: Yup.number().typeError('Must be a number'),
  },
  [
    [FORM_FIELDS.PROPERTY_OWNER.id, FORM_FIELDS.PROPERTY_OWNER_NAME.id],
    [FORM_FIELDS.PROPERTY_OWNER.id, FORM_FIELDS.PROPERTY_OWNER_NAME.id],
  ]
);

const initialFormValues = Object.values(FORM_FIELDS).reduce((acc, elem) => {
  acc[elem.id] = '';
  return acc;
}, {} as {[key: string]: string});

type ModalCreateNewProjectTypes = {
  isVisible: boolean;
  toggleModal?: BaseAnyFunction;
  onFormSubmit?: BaseAnyFunction;
  skipValidation?: boolean; // for testing
};

const ModalCreateNewProject = ({isVisible, toggleModal = noop, onFormSubmit = noop, skipValidation = false}: ModalCreateNewProjectTypes) => {
  /* Hooks */
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  /* Form Validation */
  const {PROJECT_NAME, PARTNER, PROPERTY_OWNER, PROJECT_MANAGER, PROJECT_TYPE, START_DATE, UNIT_COUNT} = FORM_FIELDS;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialFormValues,
    validationSchema: skipValidation ? null : createNewProjectSchema,
    validateOnChange: false,
    onSubmit: async projectParams => {
      /* Trim the name. Can do all the values. But only name seems to have the issue */
      projectParams.name = projectParams.name.trim();
      // @ts-ignore
      projectParams.unitsNumber = projectParams.unitsNumber || 1;

      // @ts-ignore
      const response: CreateProjectPayloadAction = await dispatch(mduProjectsSlice.actions.createProject({project: projectParams}));

      // Handle BE validation errors on form fields
      if (response.payload.errors && response.payload.status === 400) {
        const errorMessagesObj = response.payload.errors;
        const fields = Object.keys(errorMessagesObj);
        fields.forEach(field => {
          const messages = get(errorMessagesObj, field, '').join(', ');
          formik.setFieldError(field, messages);
        });
        return;
      }
      const project = response.payload.data?.project || ({} as ProjectDetails);

      navigate(mduProjectPagePath(String(project.id), CurrentProjectStages.CHECKLIST));
      toggleModal();
      onFormSubmit(project);
    },
  });

  const handleModalClose = () => {
    toggleModal();
    formik.resetForm();
  };

  const handleSubmit = () => {
    if (!formik.isSubmitting) {
      formik.submitForm();
    }
  };

  /** Used to generate the proper label for Start Date */
  const nullProject = {startedAt: null} as ProjectDetails;

  return (
    <Modal
      elementSize={ELEMENT_SIZE.LARGE}
      dataTestId={CREATE_PROJECT_MODAL_TEST_ID}
      isVisible={isVisible}
      hide={handleModalClose}
      header="New Project"
      bodyContainerClasses={styles.overflowInitial}
      footerElement2={
        <Button theme={BUTTON_THEMES.SECONDARY} onClick={handleModalClose}>
          Cancel
        </Button>
      }
      footerElement3={
        <Button theme={BUTTON_THEMES.PRIMARY} onClick={handleSubmit}>
          Create Project
        </Button>
      }
    >
      <div>
        <p className="p1 n800 marginBottom-small2">
          Fill out the required fields to create a new project. All new projects will start out in the <span className="text-weight-med">Draft</span> status.
        </p>
        <Form onSubmit={handleSubmit} classes={styles.form}>
          <Form.Row>
            <Form.Column sm={4} md={8} lg={12}>
              <InputField id={PROJECT_NAME.id} label={PROJECT_NAME.label} value={formik.values[PROJECT_NAME.id]} error={formik.errors[PROJECT_NAME.id]} onChange={formik.handleChange} />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column sm={4} md={8} lg={6}>
              <PartnerSelect fieldName={PARTNER.id} formik={formik} label={PARTNER.label} />
            </Form.Column>
            <Form.Column sm={4} md={8} lg={6}>
              <PropertyOwnerSelect fieldName={PROPERTY_OWNER.id} formik={formik} label={PROPERTY_OWNER.label} />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column sm={4} md={8} lg={6}>
              <ProjectManagerSelect formik={formik} fieldName={PROJECT_MANAGER.id} label={PROJECT_MANAGER.label} preselect />
            </Form.Column>
            <Form.Column sm={4} md={8} lg={6}>
              <ProjectTypeSelect label={PROJECT_TYPE.label} fieldName={PROJECT_TYPE.id} formik={formik} />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column sm={4} md={8} lg={6}>
              <InputField
                id={START_DATE.id}
                label={getStartDateLabel({project: nullProject, isRequiredField: false})}
                type="date"
                placeholder="Optional"
                value={formik.values[START_DATE.id]}
                error={formik.errors[START_DATE.id]}
                onChange={formik.handleChange}
              />
            </Form.Column>
            <Form.Column sm={4} md={8} lg={6}>
              <InputField id={UNIT_COUNT.id} label={UNIT_COUNT.label} placeholder="Optional" value={formik.values[UNIT_COUNT.id]} error={formik.errors[UNIT_COUNT.id]} onChange={formik.handleChange} />
            </Form.Column>
          </Form.Row>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalCreateNewProject;
