export const COMP_STATUS = {
  FULLY_COMPED: 'fully_comped',
  NO_COMP: 'no_comped',
  ITEMIZED_COMP: 'itemized_comp',
};

export const RADIO_OPTIONS = {
  FULL_COMP: {label: 'Fully comped', value: COMP_STATUS.FULLY_COMPED},
  NO: {label: 'No', value: COMP_STATUS.NO_COMP},
  ITEMIZED_COMP: {label: 'Itemized Comp', value: COMP_STATUS.ITEMIZED_COMP},
};
export const FIELD_REQUIRED_ERROR = 'This field is required';
export const CALLOUT_HEADER = 'This follow-up cannot be comped';
export const CALLOUT_TEXT = 'This follow-up is more than 90 days from the closing of the original order';
