import React from 'react';
import cn from 'classnames';
import {TCarouselButtonProps} from './thumbnail.types';
import {Icon} from 'ht-styleguide';
import styles from './thumbnailDisplay.styles.scss';

export const TEST_IDS = {
  CAROUSEL_BUTTON_LEFT: 'carousel-button-left',
  CAROUSEL_BUTTON_RIGHT: 'carousel-button-right',
};

const getTestId = (direction: string) => {
  return direction === 'left' ? TEST_IDS.CAROUSEL_BUTTON_LEFT : TEST_IDS.CAROUSEL_BUTTON_RIGHT;
};
const CarouselButton: React.FC<TCarouselButtonProps> = ({direction, visible, onClick, style}) => {
  const buttonStyles = cn('plainButton', styles.carouselButton, {
    [styles.visible]: visible,
  });
  const iconStyles = cn(styles.carouselButtonIcon, {
    [styles.left]: direction === 'left',
    [styles.right]: direction === 'right',
  });

  const iconName = direction === 'left' ? 'chevron-left' : 'chevron-right';

  return (
    <button className={buttonStyles} onClick={onClick} type="button" data-testid={getTestId(direction)} style={style}>
      <Icon name={iconName} className={iconStyles} />
    </button>
  );
};

export default CarouselButton;
