import React, {useMemo, ComponentProps} from 'react';
import useGetCBSAQuery from '../queries/query.dataSelect.getCBSA';
import {TSharedFormFieldProps} from '../../dataFormFields.types';
import BaseFilterSelectField from '../../BaseFilter/SelectField';

type TCBSASelect = TSharedFormFieldProps &
  Partial<ComponentProps<typeof BaseFilterSelectField>> & {
    /**
     * Filter cbsa by partnerId
     */
    partnerId?: number;
    /**
     * Whether to pull all data or use pagination
     */
    frontLoadData?: boolean;
  };

const CBSASelect = ({dataTestId = 'DataSelect-CBSASelect', fieldName, formik, frontLoadData = true, label, onChangeCB, options, partnerId, ...rest}: TCBSASelect) => {
  const pagination: TBasePaginationQuery | undefined = frontLoadData ? {per_page: 9999, page: 1} : undefined;
  const {data, isFetching} = useGetCBSAQuery({partnerId, pagination});

  const cbsaOpts = useMemo(() => {
    return (data?.cbsas || []).map(cbsa => ({label: cbsa.name, value: cbsa.id}));
  }, [data?.cbsas]);

  const disabled = isFetching;

  return <BaseFilterSelectField dataTestId={dataTestId} isDisabled={disabled} fieldName={fieldName} formik={formik} label={label} onChangeCB={onChangeCB} options={cbsaOpts} {...rest} />;
};

export default CBSASelect;
