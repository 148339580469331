import React, {useEffect, useCallback, useState} from 'react';
import cn from 'classnames';
// Hooks
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch} from 'hooks/useAppDispatch';
import useApi from 'hooks/useApi';
// Paths
import {mduProjectPagePath} from 'global/paths';
// Utils
import {isPostLaunchStatus} from 'features/MultiDwellingUnits/MDU.utils';
import {showErrorToast, logResponseError} from 'queries/query.utils';
// Ducks && Types
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {CurrentProjectStages, ProjectDetails} from 'features/MultiDwellingUnits/MDU.types';
// Components & Styles
import {Button, BUTTON_THEMES, Icon, Panel} from 'ht-styleguide';
import {ModalLaunchProject} from 'features/MultiDwellingUnits/Parts/Modals';
import OrderDetails, {OrderHeader} from './CurrentProject.LaunchReview.OrderDetails';
import OrderSummaryTable from './CurrentProject.LaunchReview.OrderSummaryTable';
import styles from './launchReviewPage.styles.scss';

const LaunchReviewPage = () => {
  const navigate = useNavigate();
  const currentProject: ProjectDetails = useSelector(mduProjectsSlice.selectors.getCurrentProject);
  const dispatch = useAppDispatch();
  const api = useApi();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const toggleModal = () => setIsModalVisible(!isModalVisible);

  /* Callbacks */
  const returnToProjectView = useCallback(() => {
    window.history.back();
  }, []);

  const goToProjectOverview = useCallback(() => {
    navigate(mduProjectPagePath(String(currentProject.id), CurrentProjectStages.PROJECT_OVERVIEW));
  }, [currentProject.id, navigate]);

  const launchProject = async () => {
    try {
      api.toggleLoader(true);
      const response = await api.mdu.launchProject({id: currentProject.id}); // Success repsonse will include the updated project
      if (response.err) {
        showErrorToast(dispatch)(response.err);
        throw new Error(response.err);
      }
      await dispatch(mduProjectsSlice.actions.fetchProjectDetails({projectId: currentProject.id}));
      goToProjectOverview();
    } catch (error: any) {
      logResponseError('LaunchReviewPage')(String(error));
    } finally {
      api.toggleLoader(false);
    }
  };

  useEffect(() => {
    if (currentProject?.id && isPostLaunchStatus(currentProject.status)) {
      goToProjectOverview();
    }
  }, [currentProject?.id, currentProject?.status, goToProjectOverview]);

  return (
    <section className={styles.page}>
      <div className={cn(styles.pageContent, 'paddingY-large1')}>
        <button className="plainButton" type="button" onClick={returnToProjectView}>
          <div className={cn('marginBottom-small', styles.backIconContainer)}>
            <Icon name="v2-skinny-arrow" className={styles.icon} />
          </div>
        </button>

        <h1 className="h4 marginBottom-medium2">Project Review</h1>

        <Panel className={cn(styles.panel, 'paddingX-large paddingTop-large paddingBottom-large1')} noShadow>
          <OrderHeader project={currentProject} />
          <OrderSummaryTable project={currentProject} />
          <OrderDetails project={currentProject} />

          <div className="flex marginTop-huge">
            <Button theme={BUTTON_THEMES.SECONDARY} className="marginRight-small" onClick={returnToProjectView} huge>
              Return to Project View
            </Button>
            <Button theme={BUTTON_THEMES.PRIMARY} onClick={toggleModal} huge>
              Launch Project
            </Button>
          </div>
        </Panel>
      </div>
      <ModalLaunchProject isVisible={isModalVisible} toggleModal={toggleModal} project={currentProject} onConfirm={launchProject} />
    </section>
  );
};

export default LaunchReviewPage;
