import React from 'react';
import {Icon} from 'ht-styleguide';
import styles from '../plugins/plugin.styles.scss';
import {TEditorToJson} from '../editor.types';

type TToolbarCompactView = {
  setEditorViewable: React.Dispatch<React.SetStateAction<boolean>>;
  localStorageContent: string;
};
const ToolbarCompactView = ({setEditorViewable, localStorageContent}: TToolbarCompactView) => {
  const localStorage: TEditorToJson = localStorageContent ? JSON.parse(localStorageContent) : null;

  /* Constants */
  const baseText = 'Post a comment';
  const baseTextEditing = '• You have an unsaved comment';

  /* Methods */
  const determineDisplayText = () => {
    if (!localStorage) return baseText;

    const hasContent = localStorage.root?.children?.some(child => child?.children?.length! > 0);

    if (hasContent) return baseTextEditing;

    return baseText;
  };

  return (
    <div className={styles.containerEmptyEditor} onClick={() => setEditorViewable(true)}>
      <div>{determineDisplayText()}</div>
      <div>
        <Icon name="link" className="paddingRight-tiny1" />
        <Icon name="at-sign" />
      </div>
    </div>
  );
};

export default ToolbarCompactView;
