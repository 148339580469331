import React, {ComponentProps} from 'react';
import {Form, InputField, Checkbox} from 'ht-styleguide';
import DataTable from 'components/Elements/DataTable';
import RangeDatePicker from 'components/Elements/RangeDatePicker';
import PartnerBookingSelect from 'components/FilterFormFields/DataSelect/PartnerBookingSelect';
import {HELLOTECH_PARTNER_ID} from 'components/FilterFormFields/DataSelect/dataSelect.constants';
import {l} from 'global/constants/common';
import createNumberMask from 'utils/currency/createNumberMask';
import {HELLOTECH_PARTNER_LABEL} from './createCreditSideSheet.constants';
import {CreateCreditSideSheetFormFields, TUseFormikReturn, TNormalizedItem, TSearchTableNormalizedItem} from './createCreditSideSheet.types';
import {useCreateCreditTable} from './createCreditSideSheet.hooks';

const CreateForm = ({formik}: {formik: TUseFormikReturn}) => {
  const onExpDatePickerValue = [formik.values[CreateCreditSideSheetFormFields.ExpirationDate]].filter(Boolean);
  const onExpDateChange: ComponentProps<typeof RangeDatePicker>['onChange'] = v => {
    formik.handleChange({target: {name: CreateCreditSideSheetFormFields.ExpirationDate, value: v[0]}});
  };

  const amountInputMask = createNumberMask({allowDecimal: true, prefix: '', decimalLimit: 2, integerLimit: 4, allowNegative: false});

  let partnerId = formik.values[CreateCreditSideSheetFormFields.PartnerId];
  // Need to null out as `useSkusFilterQuery` does not recognize `HELLOTECH_PARTNER_ID` as a valid partnerId
  partnerId = partnerId === HELLOTECH_PARTNER_ID ? null : partnerId;

  // Table related
  const {
    normalizedItemsData,
    mainColumns,
    renderDetailPanel,
    onAllServicesChange,
    allServicesCheckboxState,
    onAllMembershipsChange,
    allMembershipsCheckboxState,
    onClearAllRows,
    searchValue,
    onSearchChange,
    searchTableDataResult,
    searchTableColumns,
    searchInputProps,
  } = useCreateCreditTable({partnerId, formik});

  return (
    <div>
      <p className="p1 n800 marginTop-small marginBottom-small1">Store credits are associated to customer accounts and are used like cash on eligible products and services.</p>
      <Form withoutFormTag classes="marginBottom-small2">
        <Form.Row>
          <Form.Column lg={12} md={8} sm={4}>
            <InputField
              label="Display Name"
              placeholder="This will display to the customer"
              name={CreateCreditSideSheetFormFields.CreditName}
              value={formik.values.credit_name}
              onChange={formik.handleChange}
              error={formik.touched.credit_name ? formik.errors.credit_name : ''}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column lg={6} md={8} sm={4}>
            <RangeDatePicker
              format={l}
              label="Expiration Date"
              onChange={onExpDateChange}
              range={onExpDatePickerValue}
              error={formik.touched.expiration_date ? formik.errors.expiration_date : ''}
              minDate={new Date()}
            />
          </Form.Column>
          <Form.Column lg={6} md={8} sm={4}>
            <InputField
              label="Credit Amount"
              prependedNode="$"
              placeholder="00.00"
              // @ts-expect-error
              mask={amountInputMask}
              name={CreateCreditSideSheetFormFields.Amount}
              value={formik.values.amount}
              error={formik.touched.amount ? formik.errors.amount : ''}
              onChange={formik.handleChange}
            />
          </Form.Column>
        </Form.Row>
        <h5 className="marginTop-tiny1">Select eligible products and services</h5>
        <Form.Row classes="marginTop-small1">
          <Form.Column lg={12} md={8} sm={4}>
            <PartnerBookingSelect fieldName={CreateCreditSideSheetFormFields.PartnerId} formik={formik} htPartnerLabel={HELLOTECH_PARTNER_LABEL} clearable={false} />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column lg={12} md={8} sm={4} classes="flex justify-content-space-between align-items-center">
            <div className="flex align-items-center">
              {allServicesCheckboxState.visible && <Checkbox label="All Services" value="" classes="marginRight-small1" onCheckboxChange={onAllServicesChange} {...allServicesCheckboxState.props} />}
              {allMembershipsCheckboxState.visible && <Checkbox label="All Memberships" value="" onCheckboxChange={onAllMembershipsChange} {...allMembershipsCheckboxState.props} />}
            </div>
            <button type="button" className="plainButton text-link small-link" onClick={onClearAllRows}>
              Clear All
            </button>
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column lg={12} md={8} sm={4}>
            <InputField value={searchValue} onChange={onSearchChange} placeholder="Search SKUs by name or ID" {...searchInputProps} />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column lg={12} md={8} sm={4}>
            {!searchValue ? (
              <DataTable<TNormalizedItem>
                key="mainTable"
                data={normalizedItemsData}
                renderDetailPanel={renderDetailPanel}
                columns={mainColumns}
                enableTableHead={false}
                enableExpanding
                state={{density: 'compact'}}
              />
            ) : (
              <DataTable<TSearchTableNormalizedItem>
                key="searchTable"
                data={searchTableDataResult}
                columns={searchTableColumns}
                enableTableHead={false}
                enableExpanding={false}
                state={{density: 'compact'}}
              />
            )}
          </Form.Column>
        </Form.Row>
      </Form>
    </div>
  );
};

export default CreateForm;
