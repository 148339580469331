import * as Yup from 'yup';
import {TUtilTableFiltersState} from './table.types';

type TTransformFormikValuesToFilterValuesArgs = {
  formikValues: {};
  isDateRangeCondition: (args: {fieldName: string}) => boolean;
};

/**
 * Transforms formik values to filter values.
 *
 * - Date Range type
 *   - Expected formik value for date range: [from, to]
 *   - Expected filter value for date range: {from, to}
 */
export const transformFormikToFilterValues = ({formikValues, isDateRangeCondition}: TTransformFormikValuesToFilterValuesArgs) => {
  const transformed: TUtilTableFiltersState = Object.entries(formikValues).reduce((ret, [fieldName, value]) => {
    if (isDateRangeCondition({fieldName})) {
      const from = Array.isArray(value) ? value[0] : null;
      const to = Array.isArray(value) ? value[1] : null;

      return {
        ...ret,
        [fieldName]: from && to ? {from, to} : '',
      };
    }

    return {...ret, [fieldName]: value};
  }, {});
  return transformed;
};

export const transformDateRangeToFormikValue = ({value, isDateRange}: {value: any | {from: Date | undefined; to: Date | undefined}; isDateRange: boolean}) => {
  if (isDateRange) {
    if (typeof value === 'object' && 'to' in value) {
      return [value?.from, value?.to];
    }
    return '';
  }
  return value;
};

type TGenerateSchemaForFiltersArgs = {
  filterKeyMap: {[filterKey: string]: 'singleSelect' | 'multiSelect' | 'input' | 'dateRange' | Yup.Schema<any>};
};

export const generateSchemaForFilters = ({filterKeyMap}: TGenerateSchemaForFiltersArgs) => {
  const schema = Object.entries(filterKeyMap).reduce((ret, [filterKey, type]) => {
    if (type === 'singleSelect') {
      return {...ret, [filterKey]: Yup.string().nullable().notRequired()};
    }
    if (type === 'multiSelect') {
      return {...ret, [filterKey]: Yup.array().nullable().notRequired()};
    }
    if (type === 'input') {
      return {...ret, [filterKey]: Yup.string().nullable().notRequired()};
    }
    if (type === 'dateRange') {
      return {
        ...ret,
        [filterKey]: Yup.array()
          .compact()
          .test('is-range', 'Invalid range', value => value?.length === 0 || value?.length === 2 || !value),
      };
    }
    return {...ret, [filterKey]: type};
  }, {});
  return Yup.object(schema);
};
