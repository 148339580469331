// @ts-nocheck
import React from 'react';
import {Chip, CHIP_TYPES} from 'ht-styleguide';

/**
 * Converts the editor content to a comment object.
 * There is a toHTML method on the editor object, but the "comment" diverges,
 * so need a custom solution.
 *
 * We could use $generateHtmlFromNodes and pass in editor, but we need to use chip with the mention, so
 * we need to do it manually.
 *
 * @param content
 */
const convertEditorToComment = (content: {children: {children: any}[]}) => {
  const recursiveByChildren = (children, c) => {
    if (c.type === 'paragraph' && c.children?.length === 0) {
      return <br />;
    }

    return children.map((child: any) => {
      if (child.type === 'linebreak' || (child.type === 'paragraph' && child.children?.length === 0)) {
        return <br />;
      }

      /* Deals with Links */
      if (['link', 'autolink'].includes(child.type)) {
        return (
          <a href={`//${child.url}`} target="_blank" rel="noreferrer" key={child.url}>
            {child.children?.[0]?.text}
          </a>
        );
      }

      if (child.children) {
        return recursiveByChildren(child.children, child);
      }

      /* Deals with Mentions */
      if (child.trigger === '@') {
        return (
          <Chip type={CHIP_TYPES.neutral} variant="solid" key={child.value}>
            {child.value}
          </Chip>
        );
      }

      return child.text;
    });
  };

  const joinedByChildren = content?.children.map(child => {
    return recursiveByChildren(child?.children || [], child);
  });

  return joinedByChildren;
};

export default convertEditorToComment;
