import cn from 'classnames';
import {Icon} from 'ht-styleguide';
import React from 'react';
import {ChildrenProp} from 'types/base.types';

export type MenuLineItemProps = {
  children: ChildrenProp | Element;
  className?: string;
  icon?: string;
  dataTestId?: string;
  onClick?: BaseAnyFunction;
};

const MenuLI = ({children, className, icon, dataTestId = '', onClick}: MenuLineItemProps) => (
  <li data-testid={`${dataTestId}-li`} role="presentation" className={cn('p2 paddingY-small paddingRight-medium2 paddingLeft-small', className)} onClick={onClick}>
    <>
      {icon && <Icon dataTestId={`${dataTestId}-icon`} className="paddingRight-small icon-large" name={icon} />}
      {children}
    </>
  </li>
);

export default MenuLI;
