import React, {forwardRef, memo, Ref, useState, ComponentProps, useEffect, ReactNode} from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';

import {InputField} from 'ht-styleguide';

import DatePicker from 'react-datepicker';

import './react-datepicker-cssmodules.css';

import styles from './RangeDatePicker.styles.scss';

interface ICustomInputProps {
  value?: string;
  onClick?: any;
  label?: string | ReactNode;
  placeholder?: string;
  error?: string;
}

const CustomInput = forwardRef(({value, onClick, label, placeholder, error}: ICustomInputProps, ref: Ref<HTMLInputElement>) => {
  return <InputField error={error} ref={ref} onClick={onClick} value={value} label={label} placeholder={placeholder} iconName="calendar-plain" />;
});

interface IRangeDatePickerProps extends Omit<ComponentProps<typeof DatePicker>, 'onChange'> {
  range?: (Date | string | undefined)[];
  label?: string | ReactNode;
  format?: string;
  onChange: (range: (Date | undefined)[]) => void;
  placeholder?: string;
  isRangeSelect?: boolean;
  error?: string;
}

const toFormat = (date?: Date | undefined, format?: string): Date | string | undefined => (format && date ? dayjs(date).format(format) : date);

const fromFormat = (date?: Date | string): Date | undefined => {
  if (date instanceof Date) return date;
  return date ? dayjs(date).toDate() : undefined;
};

const RangeDatePicker = ({range = [], format, label, onChange, placeholder, isRangeSelect = false, error, ...rest}: IRangeDatePickerProps) => {
  const [dateFrom, setDateFrom] = useState<Date | undefined>(fromFormat(range?.[0]));
  const [dateTo, setDateTo] = useState<Date | undefined>(fromFormat(range?.[1]));

  useEffect(() => {
    setDateFrom(fromFormat(range?.[0]));
    setDateTo(fromFormat(range?.[1]));
  }, [range]);

  const onChangeFN: ComponentProps<typeof DatePicker>['onChange'] = values => {
    if (!values) {
      onChange([]);
      setDateFrom(undefined);
      setDateTo(undefined);
      return;
    }

    if (isRangeSelect) {
      const [first, second] = values as Date[];
      setDateFrom(first);
      setDateTo(second);
      const formattedValues = (values as Date[]).map((date: Date | string | undefined) => {
        return date instanceof Date ? toFormat(date, format) : date;
      });

      onChange(formattedValues as Date[]);
      return;
    }

    setDateFrom(values as Date);
    onChange([toFormat(values as Date, format)] as Date[]);
  };

  const customInput = <CustomInput label={label} error={error} />;

  if (isRangeSelect) {
    return (
      <div>
        <DatePicker
          startDate={dateFrom}
          endDate={dateTo}
          onChange={onChangeFN}
          selectsRange={isRangeSelect}
          placeholderText={placeholder}
          customInput={customInput}
          calendarContainer={({className, children}: any) => <div className={cn(className, styles.popper)}>{children}</div>}
          isClearable
          {...rest}
        />
      </div>
    );
  }

  return (
    <div>
      <DatePicker
        selected={dateFrom}
        onChange={onChangeFN}
        placeholderText={placeholder}
        customInput={customInput}
        calendarContainer={({className, children}: any) => <div className={cn(className, styles.popper)}>{children}</div>}
        isClearable
        {...rest}
      />
    </div>
  );
};

export default memo(RangeDatePicker);
