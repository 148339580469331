import {useCurrentProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/CurrentProject.hooks';

import {ProjectGroup, ProjectService} from 'features/MultiDwellingUnits/MDU.types';
import {SelectBaseOption} from 'types/base.types';

/**
 * Generate GroupSelectionInfo Display.
 * - Lists services and their counts for a group.
 */

export const useGenerateGroupSelectionInfoDisplay = (selectedGroup?: SelectBaseOption): {servicesByGroup: ProjectService[]} => {
  /* hooks */
  const currentProject = useCurrentProject();

  /* constants */
  const {projectGroups = []} = currentProject;
  const filteredGroups = projectGroups.filter((pg: ProjectGroup) => pg.projectServices?.length);

  /* Get the matched services */
  const servicesByGroup = filteredGroups.reduce((services: ProjectService[] | undefined, pgs: ProjectGroup) => {
    if (pgs.id === selectedGroup?.value) {
      // eslint-disable-next-line no-param-reassign
      services = pgs.projectServices;
    }

    return services;
  }, [] as ProjectService[]);

  return {
    servicesByGroup: servicesByGroup || [],
  };
};
