import {createAsyncThunk, createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';

// APIS
import API from 'global/apis';
// Ducks
import * as requestLoaderSlice from 'features/App/RequestLoader/requestLoader.ducks';

// Types
import {RootState} from 'store';
import {ResponseError} from 'types/base.types';
import * as WholeHome from 'features/Profile/profile.types';

/*
*******************************************************
  ASYNC ACTIONS
*******************************************************
*/
// Remove keys with no values
// const sanitizedValues = (apiParams: IHash<any>) => omitBy({...apiParams}, isNil);

export const asyncActions = {
  /* load client information data */
  getClientInformation: createAsyncThunk<WholeHome.ResponseProfileState, {id: string}, {rejectValue: ResponseError; state: RootState}>(
    'profile/getClientInformation',
    async ({id}, {rejectWithValue, dispatch}) => {
      dispatch(requestLoaderSlice.actions.loading(true));
      const response = (await API.wholeHome.customerDetails({id})) ?? {};
      dispatch(requestLoaderSlice.actions.loading(false));

      try {
        return response.err ? rejectWithValue(response.err as ResponseError) : (response as WholeHome.ResponseProfileState);
      } catch (e) {
        return rejectWithValue(response as ResponseError);
      }
    }
  ),
  /* load devices lists data */
  getDevicesList: createAsyncThunk<WholeHome.ResponseProfileState, {id: string}, {rejectValue: ResponseError; state: RootState}>(
    'profile/getDevicesList',
    async ({id}, {rejectWithValue, dispatch}) => {
      dispatch(requestLoaderSlice.actions.loading(true));
      const response = (await API.wholeHome.devices({id})) ?? {};
      dispatch(requestLoaderSlice.actions.loading(false));
      try {
        return response.err ? rejectWithValue(response.err as ResponseError) : (response as WholeHome.ResponseProfileState);
      } catch (e) {
        return rejectWithValue(response as ResponseError);
      }
    }
  ),
  /* load orders lists data */
  getOrdersList: createAsyncThunk<WholeHome.ResponseProfileState, {id: string}, {rejectValue: ResponseError; state: RootState}>('profile/getOrdersList', async ({id}, {rejectWithValue, dispatch}) => {
    dispatch(requestLoaderSlice.actions.loading(true));
    const response = (await API.wholeHome.orders({id})) ?? {};
    dispatch(requestLoaderSlice.actions.loading(false));
    try {
      return response.err ? rejectWithValue(response.err as ResponseError) : (response as WholeHome.ResponseProfileState);
    } catch (e) {
      return rejectWithValue(response as ResponseError);
    }
  }),
  /* load client stats lists data */
  getClientStats: createAsyncThunk<WholeHome.ResponseProfileState, {id: string}, {rejectValue: ResponseError; state: RootState}>(
    'profile/getClientStats',
    async ({id}, {rejectWithValue, dispatch}) => {
      dispatch(requestLoaderSlice.actions.loading(true));
      const response = (await API.wholeHome.statistics({id})) ?? {};
      dispatch(requestLoaderSlice.actions.loading(false));
      try {
        return response.err ? rejectWithValue(response.err as ResponseError) : (response as WholeHome.ResponseProfileState);
      } catch (e) {
        return rejectWithValue(response as ResponseError);
      }
    }
  ),
  getMembershipInfo: createAsyncThunk<WholeHome.ResponseProfileState, {id: string; subscriptionId: number | undefined}, {rejectValue: ResponseError; state: RootState}>(
    'profile/getMembershipInfo',
    async ({id, subscriptionId}, {rejectWithValue, dispatch}) => {
      dispatch(requestLoaderSlice.actions.loading(true));
      const response = (await API.wholeHome.membershipInformation({id, subscriptionId})) ?? {};
      dispatch(requestLoaderSlice.actions.loading(false));
      try {
        return response.err ? rejectWithValue(response.err as ResponseError) : (response as WholeHome.ResponseProfileState);
      } catch (e) {
        return rejectWithValue(response as ResponseError);
      }
    }
  ),
};

/*
 ********* INITIAL STATE
 */
const initialState: WholeHome.ProfileState = {
  client: {} as WholeHome.Client,
  devices: [],
  orders: [],
  stats: {} as WholeHome.Stat,
  subscription: null,
};
/*
 ************ SLICE
 */
const wholeHomeSlice = createSlice({
  name: 'wholeHomeContext',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(asyncActions.getClientInformation.fulfilled, (state, action: PayloadAction<any>) => {
        const {client} = action?.payload?.data;
        state.client = client;
      })
      .addCase(asyncActions.getDevicesList.fulfilled, (state, action: PayloadAction<any>) => {
        const {devices} = action?.payload?.data;
        state.devices = devices;
      })
      .addCase(asyncActions.getOrdersList.fulfilled, (state, action: PayloadAction<any>) => {
        const {orders} = action?.payload?.data;
        state.orders = orders;
      })
      .addCase(asyncActions.getClientStats.fulfilled, (state, action: PayloadAction<any>) => {
        const {stats} = action?.payload?.data;
        state.stats = stats;
      })
      .addCase(asyncActions.getMembershipInfo.fulfilled, (state, action: PayloadAction<any>) => {
        const {subscription} = action?.payload?.data;
        state.subscription = subscription;
      });
  },
});

/*
*******************************************************
  SELECTORS
*******************************************************
*/

const getProfileState = (state: RootState) => state.profile;
const getProfileStateByKey = (key: keyof WholeHome.ProfileState) => (profile: WholeHome.ProfileState) => {
  return profile?.[key];
};

const getProfileStateSelector = createSelector(getProfileState, profile => profile);
const getKeyInProfileStateSelector = (key: keyof WholeHome.ProfileState) => createSelector(getProfileStateSelector, getProfileStateByKey(key));

/*
*******************************************************
  EXPORTS
*******************************************************
*/

export const profileSelectors = {
  getProfileState: getProfileStateSelector,
  getKeyInProfileState: getKeyInProfileStateSelector,
};

const wholeHomeActions = {...asyncActions, ...wholeHomeSlice.actions};
const wholeHomeInitialState = initialState;

export {wholeHomeActions, wholeHomeInitialState};
export default wholeHomeSlice.reducer;
