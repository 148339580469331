import React from 'react';
import dayjs from 'dayjs';
import {useNavigate, useLocation} from 'react-router-dom';
import PageStatusBar from 'components/Elements/PageStatusBar';
import {buildSideSheetQueryParam} from 'features/Issues/Issues.utils';
import {ISSUE_ENTITY_TYPES, ISSUE_SLIDESHEET_TYPES} from 'features/Issues/Issues.constants';
import {useCurrentProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/CurrentProject.hooks';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {ProjectDetails, TSingleActionTypes} from 'features/MultiDwellingUnits/MDU.types';
import {useAppDispatch} from 'hooks/useAppDispatch';

export const BASE_HEADER_TEXT = 'Paused';

/**
 * Displayed when the project was paused.
 *
 * - User can resume the project via a modal
 * - User can link an issue or display the issue if it was already linked
 */
const PausedStatusBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const currentProject: ProjectDetails = useCurrentProject();
  const {paused: projectIsPaused, activePause} = currentProject || {};
  const {startedAt, ticket} = activePause || {};
  const issueId = ticket?.id;
  const issueSummary = ticket?.summary;

  const pausedStartDate = startedAt ? dayjs(startedAt).format('M/D/YY') : '';
  const headerText = `${BASE_HEADER_TEXT} ${pausedStartDate}`;

  const linkProps = (() => {
    if (issueId) {
      return {
        linkText: issueSummary,
        linkOnClick: () => {
          // Open issue sidehseet to view the linked issue
          // TODO need entity type data when more issues entity are added to the system
          navigate(`${location.pathname}?${buildSideSheetQueryParam({issueMode: ISSUE_SLIDESHEET_TYPES.issue_edit, entityType: ISSUE_ENTITY_TYPES.project, ticketId: issueId})}`);
        },
      };
    }
    return {
      linkText: 'Link an issue',
      linkOnClick: () => {
        // Open modal to link an issue
        dispatch(mduProjectsSlice.actions.setActionItemModalSlide({entity: currentProject, modalKey: TSingleActionTypes.LINK_ISSUE_PAUSED_PROJECT, modalType: 'Project'}));
      },
    };
  })();

  const openResumeModal = () => dispatch(mduProjectsSlice.actions.setActionItemModalSlide({entity: currentProject, modalKey: TSingleActionTypes.RESUME, modalType: 'Project'}));

  const shouldRenderComponent = projectIsPaused;

  return shouldRenderComponent ? <PageStatusBar headerText={headerText} ctaButtonLabel="Resume Project" theme="neutral" ctaButtonOnClick={openResumeModal} {...linkProps} /> : null;
};

export default PausedStatusBar;
