import React, {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import Notification, {notify, NotifyType} from 'components/Notification';
import Meta from 'components/Meta';
import notificationSelector from 'components/Notification/selectors';
import {useUserCurrentQuery} from 'queries/User/query.user.current';

interface PublicPage {
  children: ReactElement;
}

const PublicPageLayout: React.FC<PublicPage> = ({children}) => {
  const {type, message, id = ''} = useSelector(notificationSelector);

  useUserCurrentQuery();

  if (type && message) {
    const t = type as NotifyType;
    if (!notify.isActive(id)) {
      notify[t](message, {
        toastId: id,
      });
    }
  }

  return (
    <>
      <Meta />
      {children}
      <Notification />
    </>
  );
};

export default PublicPageLayout;
