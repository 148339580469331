import React, {memo} from 'react';
// components
import {SelectField} from 'ht-styleguide';
// types
import {BaseAnyFunction} from 'types/base.types';
import {Answer} from 'features/Questions/types';
// ui
import styles from '../qa.styles.scss';

const QTY_OPTIONS = [...'x'.repeat(25)].map((_, i) => {
  const value = 1 + i;
  return {value, label: value};
});

type QTYSelectorValue = {
  id: number;
  quantity?: number;
};

type CheckboxQuantitySelectorProps = {
  selectedValue?: QTYSelectorValue;
  answer?: Answer;
  onChange: BaseAnyFunction;
};

const CheckboxQuantitySelector: React.FC<CheckboxQuantitySelectorProps> = ({selectedValue, answer, onChange}) => {
  const value = selectedValue ? selectedValue.quantity || 1 : 1;
  return (
    <div className={styles.quantityWrapper}>
      <SelectField className={styles.quantity} options={QTY_OPTIONS} value={value} label="QTY" clearable={false} searchable={false} onChange={({value: newValue}) => onChange(answer, newValue)} />
    </div>
  );
};

export default memo(CheckboxQuantitySelector);
