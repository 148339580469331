import {useFormik} from 'formik';
import {TaskTypes, TaskFieldNames, TUpsertTaskOption, TUpsertTaskReference} from 'features/Products/types/taskGroups.types';
import {TASK_CHECKLIST_ITEM_TYPES, TASK_CHECKLIST_MODAL_VARIANTS} from '../TaskChecklist.constants';

/**
 * The sidesheet for creating/edit a task does need to know about `device` and `incident` task types.
 */
export type TaskTypesForChecklist = Exclude<TaskTypes, 'device' | 'incident'>;

/**
 * The custom field names for the task checklist formik form.
 * Should not be passed to the BE
 */
export enum TaskChecklistCustomFieldNames {
  HasReference = 'hasReference',
}

/**
 * Near mirror of what's expected from the BE
 */
export type TTaskChecklistFormValues = {
  [TaskFieldNames.CreatedAt]: string | null;
  [TaskFieldNames.ID]: number | null;
  [TaskFieldNames.IncludeOtherOption]: boolean;
  [TaskFieldNames.Instructions]: string;
  [TaskFieldNames.MultiplePhotos]: boolean;
  [TaskFieldNames.Options]: TUpsertTaskOption[];
  [TaskFieldNames.Reference]: TUpsertTaskReference | null;
  [TaskFieldNames.RequiredTask]: boolean;
  [TaskFieldNames.ShowOptionalNote]: boolean;
  [TaskFieldNames.ShowOptionalPhoto]: boolean;
  [TaskFieldNames.TaskType]: TaskTypesForChecklist;
  [TaskFieldNames.Title]: string;
  [TaskFieldNames.UpdatedAt]: string | null;
  [TaskChecklistCustomFieldNames.HasReference]?: boolean;
};

/**
 * Placing here since usage is contained to the sidesheet
 */
export const ReferenceFieldNames = {
  ReferenceType: 'reference_type' as keyof TUpsertTaskReference,
  SkillGuideId: 'skill_guide_id' as keyof TUpsertTaskReference,
  Title: 'title' as keyof TUpsertTaskReference,
  Url: 'url' as keyof TUpsertTaskReference,
  Attachments: 'attachments' as keyof TUpsertTaskReference,
} as const;

export type TTaskChecklistFormik = ReturnType<typeof useFormik<TTaskChecklistFormValues>>;

export type TTaskChecklistItemType = (typeof TASK_CHECKLIST_ITEM_TYPES)[keyof typeof TASK_CHECKLIST_ITEM_TYPES];

export type TTaskChecklistModalVariants = (typeof TASK_CHECKLIST_MODAL_VARIANTS)[keyof typeof TASK_CHECKLIST_MODAL_VARIANTS];
