import {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {initSplitSdk, destroySplitSdk, getTreatments} from '@splitsoftware/splitio-redux';
import environment from 'global/environments';

// Utils & Constants
import {logger} from 'utils/logger';
import {authToken} from 'utils/auth';
import {SPLIT_NAMES, SPLITS_ON_USER_LOAD} from './splitio.constants';
import {getAjsAnonymousCookie} from '../../utils/cookies';
import authSelector from '../../selectors/auth';

/**
 * useLoadSplitio
 * 1. Initialize the split sdk
 * 2. Load treatments that are based on the 'user' traffic type and are needed ASAP.
 */
export const useLoadSplitio = () => {
  const dispatch = useDispatch();
  const userToken = authToken.getAuthToken();
  const calledInit = useRef(false); // being cautious
  const client = useSelector(authSelector);

  // Initialize and load treatments on user load
  useEffect(() => {
    if (userToken && !calledInit.current) {
      const id = authToken.getAuthTokenId();

      const onReady = () => {
        dispatch(
          getTreatments({
            splitNames: SPLITS_ON_USER_LOAD,
            key: id.toString(),
            attributes: {
              anonymousId: getAjsAnonymousCookie(),
             ...(client?.user && client.user),
            }
          })
        );
      };

      const onTimedout = () => {
        logger('useInitSplitSdkAndLoad')('Split SDK Timed Out');
      };

      dispatch(
        initSplitSdk({
          config: {
            core: {
              authorizationKey: environment.SPLITIO_KEY,
              key: id.toString(),
            },
            startup: {
              readyTimeout: 18,
              requestTimeoutBeforeReady: 4,
              retriesOnFailureBeforeReady: 3,
            },
            sync: {
              splitFilters: [
                {
                  type: 'byName',
                  values: Object.values(SPLIT_NAMES),
                },
              ],
            },
            // debug: true,
          },
          onReady,
          onTimedout,
        })
      );
      calledInit.current = true;
    }
  }, [dispatch, userToken]);

  // Destroy the SDK
  useEffect(() => {
    return () => {
      if (calledInit.current) {
        calledInit.current = false;
        dispatch(destroySplitSdk());
      }
    };
  }, [dispatch]);
};
