import React, {useCallback, useEffect, useState} from 'react';
import get from 'lodash/get';
import {ELEMENT_SIZE, SelectField} from 'ht-styleguide';

// Api
import APIS from 'global/apis';
import {useGetProjectManagerQuery} from 'features/MultiDwellingUnits/queries/query.project.fields';

// hooks & constants
import {useAppDispatch} from 'hooks/useAppDispatch';
import usePrevious from 'hooks/usePrevious';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';

// utils
import {logger} from 'utils/logger';

// types
import {FIELD_LABELS} from './FormFields.constants';
import {IProjectManagerSelectProps, ProjectDropdownDetails, SelectOption} from './FormFields.types';

// styles
import styles from './FormFields.styles.scss';

const ProjectManagerSelect = ({
  formik,
  project,
  label = FIELD_LABELS.projectManager,
  fieldName = 'projectManagerId',
  preselect = false,
  clearable = true,
  placeholder = 'Search by Manager or ID',
  multiple = false,
  includeAvatar = false,
}: IProjectManagerSelectProps) => {
  const dispatch = useAppDispatch();

  // Project Manager Details
  const {data: projectManagersData} = useGetProjectManagerQuery();
  const [projectManagersList, setProjectManagersList] = useState<SelectOption[]>([]); // List we get from BE
  const [selectedDetails, setSelectedDetails] = useState<SelectOption | SelectOption[]>();

  const handleDetailsChange = useCallback(
    (pm: SelectOption | SelectOption[]) => {
      setSelectedDetails(pm);
      return formik.handleChange({
        target: {name: fieldName, value: Array.isArray(pm) ? pm.map(p => p.value) : pm.value},
      });
    },
    [fieldName, formik]
  );

  useEffect(() => {
    /* Seed project manager */
    if (project?.projectManager) {
      setSelectedDetails({
        value: project.projectManager.id,
        label: project.projectManager.name,
      });
    }
  }, [project]);

  const formikValue = get(formik.values, fieldName);
  const previousFormikValue = usePrevious(formikValue);

  useEffect(() => {
    (async () => {
      if (!projectManagersData || !Array.isArray(projectManagersData)) {
        return;
      }

      try {
        const current = await APIS.users.current();
        const formattedProjectManagers = projectManagersData.map((elem: ProjectDropdownDetails) => ({
          value: elem.id,
          label: `${elem.name} #${elem.id}`,
          ...(includeAvatar ? {avatarProps: {name: elem.name}} : {}),
        }));

        /* Deal with current user */
        const user = get(current, `data.user`, {});
        const userOption = {
          value: user.id,
          label: user.name,
          ...(includeAvatar ? {avatarProps: {name: user.name}} : {}),
        };

        /* Merge the response into the list */
        const formattedProjectManagersWithCurrentUser = [...formattedProjectManagers, userOption];
        setProjectManagersList(formattedProjectManagersWithCurrentUser);

        if (!selectedDetails) {
          /* Seed it with current user */
          if (preselect) {
            handleDetailsChange(multiple ? [userOption] : userOption);
            /* Seed it with formik values */
          } else if (formikValue) {
            const found = formattedProjectManagersWithCurrentUser.filter(entry => (multiple ? formikValue.includes(entry.value) : entry.value === formikValue));
            if (found) {
              handleDetailsChange(multiple ? found : found[0]);
            }
          }
        }

        // When formik is cleared from parent container
        if (!formikValue && previousFormikValue) {
          setSelectedDetails(undefined);
        }
      } catch (error) {
        logger('fetchProjectDetails & Current User')(String(error));
      }
    })();
  }, [handleDetailsChange, preselect, projectManagersData, selectedDetails, includeAvatar, multiple, formikValue, previousFormikValue]);

  return (
    <SelectField
      id={fieldName}
      placeholder={placeholder}
      options={projectManagersList}
      label={label}
      multiple={multiple}
      value={selectedDetails}
      onChange={pm => {
        const pmSelection = pm ?? {value: '', label: ''};
        handleDetailsChange(pmSelection);
        dispatch(mduProjectsSlice.actions.setRawFilterValues({[fieldName]: pmSelection}));
      }}
      elementSize={ELEMENT_SIZE.MEDIUM}
      clearable={clearable}
      searchable
      error={formik.errors.projectManagerId}
      reactSelectClassName={styles.placeholder}
      dataTestId={FIELD_LABELS.projectManager}
    />
  );
};

export default ProjectManagerSelect;
