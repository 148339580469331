import React from 'react';
// Hooks
import {useDropzone} from 'react-dropzone';
import {useHtDropzone} from './useHtDropzone';
// Utils & Types
import {noop} from 'utils/event';
import {THTDropzoneProps} from './HTDropzone.types';
// Components
import {DEFAULT_MAX_FILE_SIZE} from './htdropzone.constants';
import HTDropzoneOverlay from './HTDropzone.Overlay';
import styles from './htdropzone.styles.scss';

const HTDropzone: (props: THTDropzoneProps) => JSX.Element = ({
  includeFileSizeCheck = true,
  children,
  onDrop = noop,
  onDropRejected = noop,
  onDragEnter = noop,
  onDragLeave = noop,
  maxFileSize = DEFAULT_MAX_FILE_SIZE,
  multiple = true,
  noClick = true,
  validator,
}) => {
  const {isHovering, handleOnDrop, handleOnDragEnter, handleOnDragLeave} = useHtDropzone({onDrop, onDragEnter, onDragLeave});

  const {getRootProps, getInputProps} = useDropzone({
    onDrop: handleOnDrop,
    onDropRejected,
    onDragEnter: handleOnDragEnter,
    onDragLeave: handleOnDragLeave,
    ...(includeFileSizeCheck && {maxSize: maxFileSize.maxSizeInBytes}),
    multiple,
    noClick,
    validator,
  });

  return (
    <div {...getRootProps()} className="position relative">
      <input {...getInputProps()} />
      <HTDropzoneOverlay isVisible={isHovering} maxFileSize={maxFileSize} />
      <div className={styles.childContainer}>{children}</div>
    </div>
  );
};

export default HTDropzone;

/*
 * Example Usage:
 *
 * import Dropzone from '<path-to-dropzone-component>';
 *
 * <Dropzone
 *   maxFileSize={{maxSizeInBytes: 1024 * 1024, maxSizeDisplayString: '1MB'}}
 *   multiple={true}
 *   onDrop={file => console.log('File dropped: ', file)}
 *   onDragEnter={() => console.log('File drag entered')}
 *   onDragLeave={() => console.log('File drag left')}
 * >
 *   <p>Your regular component or any JSX here.</p>
 * </Dropzone>
 *
 * Note:
 * The Dropzone component provides visual feedback during the file dragging process.
 * When a file is being dragged over the component, an overlay appears to indicate that files can be dropped for upload.
 * When no files are being dragged over, the children components are displayed normally.
 */
