import React from 'react';
import {ToastContainer} from 'react-toastify';
import NotificationCloseIcon from './NotificationCloseIcon';
import styles from './styles.scss';

export type NotifyType = 'info' | 'warn' | 'error';

export type Notify = {
  type: string;
  message: string;
  level?: string;
  id: string;
  autoClose?: number;
};

const Notification = ({...props}) => {
  return (
    <ToastContainer
      data-testid="notification_error"
      position="top-center"
      autoClose={5000}
      closeOnClick
      pauseOnFocusLoss
      draggable
      pauseOnHover
      className={styles.htToastContainer}
      {...props}
      closeButton={<NotificationCloseIcon />}
    />
  );
};

export default React.memo(Notification);
