import {useEffect} from 'react';
import {useQuery} from 'react-query';
import {login as loginSlice} from 'features/Login/login.ducks';
import useApi from 'hooks/useApi';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useSelector} from 'hooks/useAppSelector';
import {showErrorToast, logResponseError, stringifyQueryParams} from 'queries/query.utils';
import useUnassignedListRouteParams from '../hooks/useUnassignedListRouteParams';
import dispatchSlice from '../dispatch.ducks';
import * as DispatchTypes from '../dispatch.types';
import {DISPATCH_QUERY_KEYS} from './query.dispatch.keys';

const useGetDispatchUnassignedOrders = () => {
  const api = useApi();
  const dispatch = useAppDispatch();
  const {dispatchNavType, pageFilters} = useUnassignedListRouteParams();
  const user = useSelector(loginSlice.selectors.getUser);
  const {filters} = useSelector(dispatchSlice.selectors.getUnassignedListPageStateByKey(dispatchNavType)) || {};

  const createSearchQuery = () => {
    const {useCurrentUser} = pageFilters;

    // Page level filter - user
    const userParams: {agent_id?: number} = {};
    if (useCurrentUser && user) {
      userParams.agent_id = user.id;
    }

    // Pagination params - Front-load all the data. Table pagination will be done client-side.
    const paginationParams = {
      per_page: 9999,
      page: 1,
    };

    return stringifyQueryParams({...userParams, ...filters, pagination: paginationParams});
  };

  const urlSearchQuery = createSearchQuery();
  const queryKeys = [dispatchNavType, urlSearchQuery];

  const {data} = useQuery(DISPATCH_QUERY_KEYS.getUnassignedOrders(queryKeys), async () => {
    api.toggleLoader(true);
    const response: DispatchTypes.TGetUnassignedOrdersQueryResponse = await api.dispatch.getUnassignedOrders({search: urlSearchQuery});
    api.toggleLoader(false);

    if (response.err) {
      showErrorToast(dispatch)(response.err);
      logResponseError('Unassigned orders')(response.err);
    }

    return response.data;
  });

  const {orders, pagination} = data || {};

  useEffect(() => {
    if (pagination) {
      const {total_items} = pagination;
      // Since we're front-loading all the data, we only need to update the total items and pages.
      const partialPagination = {total_items};
      dispatch(dispatchSlice.actions.updateUnassignedListPagination({navType: dispatchNavType, pagination: partialPagination}));
    }
  }, [dispatch, dispatchNavType, pagination]);

  return {
    orders,
  };
};

export default useGetDispatchUnassignedOrders;
