import {QuestionsAPIByQuestion, SelectedSku} from 'features/Questions/types';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {IHash} from 'ht-styleguide/build/base.types';
import {ChangeEvent, useCallback} from 'react';
import {QUESTION_TYPES} from './constants';

interface PostQuestionActionsProps {
  selectedSku: SelectedSku;
  question: QuestionsAPIByQuestion;
  dispatch?: React.Dispatch<any>;
  adjustPrice?: Function;
  actions: any;
}

const postQuestionActions =
  ({actions, selectedSku, question, dispatch: argDispatch, adjustPrice}: PostQuestionActionsProps) =>
  (makeInputValue: string, setMakeInputValue: React.Dispatch<React.SetStateAction<string>>) => {
    const defaultDispatch = useAppDispatch();

    const dispatch = argDispatch || defaultDispatch;

    const postItemPrice = useCallback(() => {
      if (adjustPrice) return adjustPrice();
      return dispatch(actions.postItemPrice());
    }, [dispatch]);

    const questionRef = selectedSku.questions[question.id];
    const makeValue = questionRef?.answerValue;
    const modelValue = questionRef?.model;
    return {
      inputChange(event: Event) {
        event.preventDefault();
        const target = event.target as HTMLInputElement;
        dispatch(actions.answerChange({question, value: target.value}));
      },
      inputValue() {
        return selectedSku.questions[question.id]?.text ?? '';
      },
      dropdownChange: async (value: number) => {
        await dispatch(actions.answerChange({question, value}));
        postItemPrice();
      },
      dropdownValue() {
        return selectedSku.questions[question.id];
      },
      checkboxChange: async (value: number[]) => {
        await dispatch(
          actions.answerChange({
            question,
            value,
          })
        );
        postItemPrice();
      },
      checkboxQuantityChange: async ({id, quantity}: IHash<number>) => {
        await dispatch(
          actions.answerQuantityChange({
            question,
            id,
            quantity,
          })
        );
        postItemPrice();
      },
      checkboxValue() {
        return selectedSku.questions[question.id];
      },
      /*
    ----------------------------------------------
      START DEVICE CATALOGING onchange & values
    ----------------------------------------------
    */
      makeValue,
      modelValue,
      makeDropdownChange(value: number) {
        /*
      If any change to make dropdown is made,
      reset the local state makeInputValue to ''
    */
        setMakeInputValue('');
        return dispatch(
          actions.deviceAnswerChange({
            question,
            questionType: QUESTION_TYPES.MAKE_DROPDOWN,
            makeValue: value,
            modelValue: null,
          })
        );
      },
      modelDropdownChange(value: number) {
        return dispatch(
          actions.deviceAnswerChange({
            questionType: QUESTION_TYPES.MODEL_DROPDOWN,
            question,
            makeValue,
            modelValue: value,
          })
        );
      },
      makeInputChange(event: ChangeEvent<HTMLInputElement>) {
        setMakeInputValue(event.target.value);
        dispatch(
          actions.deviceInputAnswerChange({
            questionType: QUESTION_TYPES.MAKE_INPUT,
            question,
            makeInputValue: event.target.value,
            modelInputValue: modelValue,
          })
        );
      },
      modelInputChange(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        /*
      When on edit, check if make input is present.
      If yes, it will send makeInputValue to PreQuestionDevice component which renders inputfield
      If not, render dropdown with make value.
    */
        if (makeInputValue) {
          dispatch(
            actions.deviceInputAnswerChange({
              questionType: QUESTION_TYPES.MAKE_INPUT,
              question,
              makeInputValue,
              modelInputValue: event.target.value,
            })
          );
        } else {
          dispatch(
            actions.deviceInputAnswerChange({
              questionType: QUESTION_TYPES.MODEL_INPUT,
              question,
              makeInputValue: makeValue,
              modelInputValue: event.target.value,
            })
          );
        }
      },
      resetDeviceQuestion() {
        /*
      This function triggers when X icon on make input is clicked.
      It resets all values to null.
    */
        dispatch(
          actions.deviceAnswerChange({
            questionType: QUESTION_TYPES.MAKE_DROPDOWN,
            question,
            makeValue: null,
            modelValue: null,
          })
        );
      },
    };
  };

export default postQuestionActions;
