import {Routes} from 'global/routes/routes';
import {IssuesNavTypes, IssuesStatsKeys, IssuesSearchFieldNames} from './issues.types';

/*
*******************************************************
  General
*******************************************************
*/
export const BASE_ISSUES_OPEN_PATH = `${Routes.ISSUES_PATH_BASE()}/${IssuesNavTypes.OPEN}`;

/**
 * @description The types of entities that can be created in the system.
 *
 * @example PROJECTS: "Projects"
 */
export const ISSUE_ENTITY_TYPES = {
  project: 'Project',
} as const;

/*
*******************************************************
  SlideSheet Types
*******************************************************
*/

/**
 * @description The types of Issue slide sheet types. So far, we only have project.
 */
export const ISSUE_SLIDESHEET_TYPES = {
  issue_create: 'issue_create',
  issue_edit: 'issue_edit',
  issue_view: 'issue_view',
} as const;

/*
*******************************************************
  Initial State
*******************************************************
*/
export const INITIAL_COLUMN_ORDER_STATE = [
  IssuesSearchFieldNames.Summary,
  IssuesSearchFieldNames.EntityId,
  IssuesSearchFieldNames.IssueType,
  IssuesSearchFieldNames.CreatedAt,
  IssuesSearchFieldNames.AssignedToId,
  IssuesSearchFieldNames.PriorityId,
];

/*
*******************************************************
  Page Params
*******************************************************
*/

type TIssuesPageParams = {
  [key in IssuesNavTypes]: {
    /**
     * Field names that should not appear anywhere on the page.
     */
    omitFilterKeys?: (IssuesSearchFieldNames | string)[];
    pageTitle: string;
    /**
     * Filters that are applied to the page for table view.
     * These are persistent filters that are applied to the table view.
     */
    pageFilters: {
      status?: 'open' | 'completed';
      [IssuesSearchFieldNames.AssignedToMe]?: boolean;
      [IssuesSearchFieldNames.EntityType]?: (typeof ISSUE_ENTITY_TYPES)[keyof typeof ISSUE_ENTITY_TYPES];
    };
    /**
     * Filters that are applied to the stats endpoint
     */
    statsFilters:
      | {
          [IssuesSearchFieldNames.AssignedToMe]?: boolean;
          [IssuesSearchFieldNames.EntityType]?: (typeof ISSUE_ENTITY_TYPES)[keyof typeof ISSUE_ENTITY_TYPES];
          status?: 'open';
        }
      | null
      | false;
    /**
     * Which stats widgets to show on the page
     */
    statsKeys: Array<IssuesStatsKeys>;
  };
};

export const ISSUES_PAGE_PARAMS: TIssuesPageParams = {
  [IssuesNavTypes.OPEN]: {
    omitFilterKeys: [IssuesSearchFieldNames.StatusId, IssuesSearchFieldNames.CompletedAt],
    pageTitle: 'Open Issues',
    pageFilters: {
      status: 'open',
    },
    statsFilters: {
      status: 'open',
    },
    statsKeys: [IssuesSearchFieldNames.Open, IssuesSearchFieldNames.HighPriority, IssuesSearchFieldNames.OlderThan24h],
  },
  [IssuesNavTypes.OWN]: {
    omitFilterKeys: [IssuesSearchFieldNames.StatusId, IssuesSearchFieldNames.AssignedToId, IssuesSearchFieldNames.CompletedAt],
    pageTitle: 'Issues Assigned to Me',
    pageFilters: {
      [IssuesSearchFieldNames.AssignedToMe]: true,
      status: 'open',
    },
    statsFilters: {
      [IssuesSearchFieldNames.AssignedToMe]: true,
      status: 'open',
    },
    statsKeys: [IssuesSearchFieldNames.Open, IssuesSearchFieldNames.HighPriority, IssuesSearchFieldNames.OlderThan24h],
  },
  [IssuesNavTypes.DONE]: {
    omitFilterKeys: [IssuesSearchFieldNames.StatusId],
    pageTitle: 'Done Issues',
    pageFilters: {
      status: 'completed',
    },
    statsFilters: false,
    statsKeys: [],
  },
  [IssuesNavTypes.PROJECTS_SINGLE_VIEW]: {
    omitFilterKeys: [IssuesSearchFieldNames.CompletedAt, IssuesSearchFieldNames.EntityId, IssuesSearchFieldNames.EntityType, IssuesSearchFieldNames.EntityPartnerId],
    pageTitle: 'Issues',
    pageFilters: {
      entity_type: ISSUE_ENTITY_TYPES.project,
    },
    statsFilters: {
      status: 'open',
      entity_type: ISSUE_ENTITY_TYPES.project,
    },
    statsKeys: [IssuesSearchFieldNames.Open, IssuesSearchFieldNames.HighPriority, IssuesSearchFieldNames.OlderThan24h],
  },
};

/*
*******************************************************
  Others
*******************************************************
*/

/**
 * @description Controls where the field should show.
 * @example
 *  - template: This field should be displayed when showing the details of a template.
 *  - ticket: This field should be displayed when showing the details of a ticket.
 *  - always: This field should be always displayed (when showing the details of a template or ticket).
 *  - never: This field should never be displayed.
 *
 */
export const FIELDS_DISPLAY_TYPES = {
  template: 'template',
  ticket: 'ticket',
  always: 'always',
  never: 'never',
  none: 'none',
  create: 'create',
  update: 'update',
} as const;

/**
 * @description Controls whether the field is editable.
 * @example
 * - always: The user will be able to change the value of the field on a ticket.
 * - update: The user will be able to change the value of the field on a ticket after creation.
 * - never: The user will not be able to change the value of the field.
 */
export const FIELDS_READ_ONLY_TYPES = {
  always: 'always',
  update: 'update',
  never: 'never',
  create: 'create',
  show: 'show',
} as const;

/**
 * @description The types of fields that can be created in the system as our params for "issues".
 */
export const ISSUE_PARAMS_TYPES = {
  issue_mode: 'issue_mode',
  entity_type: 'entity_type',
  /**
   * Depending on issue mode, this can be entity id or or ticket(issue) id
   */
  ticket_id: 'ticket_id',
} as const;

/**
 * @description The types of entities that can be created in the system, on a field level.
 */
export const ISSUE_FIELD_ENTITY_TYPE = {
  user: 'User',
  admin: 'Admin',
  partner: 'Partner',
  geek: 'Geek',
  priority: 'Issues::Priority',
  status: 'Issues::Status',
  template: 'Issues::Template',
  tag: 'Issues::Tag',
  propertyOwner: 'PropertyOwner',
  ...ISSUE_ENTITY_TYPES,
} as const;

/**
 * @description This tells us what mode (edit/create/filter) are we in.
 */
export const ISSUE_FIELDS_OPERATION_TYPE = {
  create: 'create',
  update: 'update',
  filter: 'filter',
} as const;

/**
 * @description This gives us all the entity names that are available in the system.
 *              The fields should be completely dynaimc, so we should not have to hardcode,
 *              but design needs custom behavior/views so we now have to be concerned with it.
 */
export const ISSUE_FIELDS_ENTITY_NAMES = {
  text: 'text',
  long_text: 'long_text',
  select: 'select',
  multi_select: 'multi_select',
  rich_content: 'rich_content',
  numeric: 'numeric',
  datetime: 'datetime',
} as const;

/* A constant representation of the feature we are in */
export const featureTypeIssues = 'Issues';

/**
 * Modal Action Types
 */
export const ISSUES_MODAL_TYPES = {
  delete: 'delete',
} as const;

/**
 * @description This is a map of the operations and the fields that should be disabled for that operation type
 *
 * Note: This works by matching the key with the field key. This is a negative test. IF the field key is in the array,
 *       it will be disabled or hidden or omitted.
 */
export const ReadOnly = 'read_only';
export const Display = 'display';
type TFieldArray = Partial<keyof typeof FIELDS_READ_ONLY_TYPES>[];

type TOperationMap = {
  [K in keyof typeof ISSUE_FIELDS_OPERATION_TYPE]: {
    [ReadOnly]: TFieldArray;
    [Display]: TFieldArray;
  };
};
export const operationMapBy: TOperationMap = {
  [ISSUE_FIELDS_OPERATION_TYPE.create]: {
    [ReadOnly]: [FIELDS_READ_ONLY_TYPES.always],
    [Display]: [FIELDS_READ_ONLY_TYPES.create, FIELDS_READ_ONLY_TYPES.always],
  },
  [ISSUE_FIELDS_OPERATION_TYPE.update]: {
    [ReadOnly]: [FIELDS_READ_ONLY_TYPES.always, FIELDS_READ_ONLY_TYPES.update],
    [Display]: [FIELDS_READ_ONLY_TYPES.show, FIELDS_READ_ONLY_TYPES.always],
  },
  [ISSUE_FIELDS_OPERATION_TYPE.filter]: {
    [ReadOnly]: [FIELDS_READ_ONLY_TYPES.always],
    [Display]: [FIELDS_READ_ONLY_TYPES.always],
  },
};
