import {createSlice, createAsyncThunk, PayloadAction, createSelector} from '@reduxjs/toolkit';
import APIS from 'global/apis';
import {RootState} from 'store';
import {ResponseError} from 'types/base.types';
import {PlanIntervals} from 'types/plan.types';
import {logger} from 'utils/logger';
import * as cartSlice from '../cart.ducks';
import {UpsellTypes} from './planUpsell.constants';
import * as TS from './planUpsell.types';
import {formatPlansForReducer, formatOnlinePlanForReducer, filterBenefits} from './planUpsell.utils';
import {PlainPlanSet} from './planUpsell.types';

const {ANNUAL, MONTHLY} = PlanIntervals;

/*
 *****************************************
 ************ INITIAL STATE  *************
 *****************************************
 */
const initialState: TS.PlanUpsellState = {
  plansInfo: [],
  estimatedCarts: [],
  error: '',
  upsellType: null,
};

/*
 ****************************************
 ************ ASYNC ACTIONS *************
 ****************************************
 */
export const asyncActions = {
  fetchSubscriptions: createAsyncThunk<TS.FormattedPlans[], {showNewPlans?: boolean}, {rejectValue: ResponseError}>(
    'planUpsell/fetchSubscriptions',
    async ({showNewPlans = false}, {rejectWithValue}) => {
      const response = await APIS.subscriptions.all();
      try {
        if (response.err) return rejectWithValue(response.err as ResponseError);
        const {
          data: {plans: planProducts},
        } = response;
        const hellotechHomePlans = formatPlansForReducer({planProducts, showNewPlans});
        const onlinePlan = formatOnlinePlanForReducer({planProducts});
        return hellotechHomePlans || onlinePlan ? ([hellotechHomePlans, onlinePlan] as TS.FormattedPlans[]) : [];
      } catch (e) {
        return rejectWithValue(response as ResponseError);
      }
    },
    {
      condition: (_, {getState}) => {
        const {
          booking: {
            planUpsell: {upsellType},
          },
        } = getState() as RootState;
        return upsellType !== 'main';
      },
    }
  ),
  fetchPlanInfoByIdSet: createAsyncThunk<TS.FormattedPlans[], {upsellType: TS.PlanUpsellState['upsellType']; idSet: TS.PlanInfoByIdSet}, {rejectValue: ResponseError; state: RootState}>(
    'planUpsell/fetchPlanInfoByIdSet',
    async ({idSet}, {rejectWithValue, getState}) => {
      try {
        // Given a set of plan ids, fetch data for the corresponding plans.
        const plansInfo: TS.PlainPlansInfo = await Promise.all(
          idSet.map(async oneSet => {
            const monthlyId = oneSet[MONTHLY];
            const yearlyId = oneSet[ANNUAL];

            const fetchPlan = async (id: number | undefined) => {
              if (id) {
                const result = await APIS.subscriptions.show(cartSlice.applyCartClientIds({params: {id}, state: getState}));
                return result;
              }
              return null;
            };

            const [monthlyResponse, annualResponse] = await Promise.all([fetchPlan(monthlyId), fetchPlan(yearlyId)]);
            const planSetInfo: TS.PlainPlanSet = {
              [MONTHLY]: null,
              [ANNUAL]: null,
            };

            if (monthlyResponse) {
              if (!monthlyResponse.err) {
                const {
                  data: {plan: monthlyPlan},
                } = monthlyResponse;
                planSetInfo[MONTHLY] = monthlyPlan;
              } else {
                logger('Error fetching upsell by idSet (monthly)')(monthlyResponse);
              }
            }
            if (annualResponse && !annualResponse.err) {
              if (!annualResponse.err) {
                const {
                  data: {plan: yearlyPlan},
                } = annualResponse;
                planSetInfo[ANNUAL] = yearlyPlan;
              } else {
                logger('Error fetching upsell by idSet (annual)')(annualResponse);
              }
            }

            return planSetInfo;
          })
        );

        // Filtered and formatted (append benefits)
        const filteredPlansInfo = plansInfo
          .map(planSet => {
            const plans = Object.values(planSet).filter(v => v);
            if (plans.length) {
              const formattedPlanSet: TS.FormattedPlans = {
                [MONTHLY]: null,
                [ANNUAL]: null,
              };
              const keys = Object.keys(planSet) as Array<PlanIntervals>;
              keys.forEach(cycle => {
                if (planSet[cycle]) {
                  const benefits = filterBenefits({plan: planSet[cycle]});
                  formattedPlanSet[cycle] = {...planSet[cycle]!, benefits};
                } else {
                  formattedPlanSet[cycle] = null;
                }
              });
              return formattedPlanSet;
            }
            return null;
          })
          .filter(planSet => planSet);
        return filteredPlansInfo as TS.FormattedPlans[];
      } catch (e) {
        return rejectWithValue(e as ResponseError);
      }
    },
    {
      condition: ({upsellType}, {getState}) => {
        const {
          booking: {
            planUpsell: {upsellType: currentUpsellType},
          },
        } = getState() as RootState;
        return currentUpsellType !== upsellType;
      },
    }
  ),
  fetchEstimatedCarts: createAsyncThunk<TS.EstimatedCart[], void, {rejectValue: ResponseError; state: RootState}>(
    'planUpsell/fetchEstimatedCarts',
    async (_, {rejectWithValue, getState}) => {
      const {
        booking: {
          planUpsell: {plansInfo},
        },
      } = getState() as RootState;

      try {
        const estimatedCarts = await Promise.all(
          plansInfo
            .filter((plans: TS.PlainPlanSet) => plans)
            .map(async (plans: TS.PlainPlanSet) => {
              const monthlyId = plans[MONTHLY]?.id;
              const yearlyId = plans[ANNUAL]?.id;

              const fetchEstimatedCart = async (planId: number | undefined | null) => {
                if (planId) {
                  const result = await APIS.booking.cart.estimatePlan(
                    cartSlice.applyCartClientIds({
                      params: {plan_id: planId},
                      state: getState,
                    })
                  );
                  return result;
                }
                return null;
              };

              const [monthlyResponse, annualResponse] = await Promise.all([fetchEstimatedCart(monthlyId), fetchEstimatedCart(yearlyId)]);

              let monthlyCart = null;
              let annualCart = null;
              if (monthlyResponse) {
                const {
                  data: {cart},
                  err,
                } = monthlyResponse;
                if (err) {
                  throw new Error('Error in fetching estimate carts');
                }
                monthlyCart = cart;
              }

              if (annualResponse) {
                const {
                  data: {cart},
                  err,
                } = annualResponse;
                if (err) {
                  throw new Error('Error in fetching estimate carts');
                }
                annualCart = cart;
              }

              return {monthly: monthlyCart, yearly: annualCart};
            })
        );
        return estimatedCarts;
      } catch (e) {
        return rejectWithValue(e as ResponseError);
      }
    },
    {
      condition: (_, {getState}) => {
        const {
          booking: {
            planUpsell: {plansInfo},
          },
        } = getState() as RootState;
        if (plansInfo.length) return true;
        return false;
      },
    }
  ),
};

/*
 ****************************************
 ************ CREATE SLICE  *************
 *****************************************
 */
const planUpsellSlice = createSlice({
  name: 'planUpsell',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(asyncActions.fetchSubscriptions.fulfilled, (state, action) => {
        state.plansInfo = action.payload;
        state.upsellType = UpsellTypes.MAIN;
        state.estimatedCarts = initialState.estimatedCarts;
        state.error = '';
      })
      .addCase(asyncActions.fetchPlanInfoByIdSet.fulfilled, (state, action) => {
        const {
          meta: {
            arg: {upsellType},
          },
        } = action;
        state.plansInfo = action.payload;
        state.upsellType = upsellType;
        state.estimatedCarts = initialState.estimatedCarts;
        state.error = '';
      })
      .addCase(asyncActions.fetchEstimatedCarts.fulfilled, (state, action) => {
        state.estimatedCarts = action.payload;
        state.error = '';
      })
      .addCase(asyncActions.fetchEstimatedCarts.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload.message;
      });
  },
});

/*
*******************************************************
  SELECTORS & SELECTOR METHODS
*******************************************************
*/
const getPlansInfo = (state: RootState) => state.booking.planUpsell.plansInfo;
const getEstimatedCarts = (state: RootState) => state.booking.planUpsell.estimatedCarts;
const getUpsellError = (state: RootState) => state.booking.planUpsell.error;

/*
*******************************************************
  EXPORTS
*******************************************************
*/
export const selectors = {
  getPlansInfoState: createSelector(getPlansInfo, plansInfo => plansInfo),
  getEstimatedCarts: createSelector(getEstimatedCarts, estimatedCarts => estimatedCarts),
  getUpsellError: createSelector(getUpsellError, error => error),
};

export const actions = {...asyncActions, ...planUpsellSlice.actions};

export const {reducer} = planUpsellSlice;
