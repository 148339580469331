import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useQuery} from 'react-query';

import useApi from 'hooks/useApi';
import APIS from 'global/apis';
import {logger} from 'utils/logger';
import {showErrorToast} from 'queries/query.utils';
import {MduUseParamsTypes, TProjectProgressResponse} from '../MDU.types';
import {MDU_PROJECT_QUERY_KEYS} from '../MDU.query.keys';

/** QUERY: Get ProjectProgress */
export const useProjectProgressQuery = (id = '', {suppressLoader = false} = {}) => {
  const dispatch = useDispatch();
  const api = useApi();
  const {projectId: idFromParams = ''} = useParams<MduUseParamsTypes>();

  // If a project id is passed as an argument then let's use that to override whatever we find in useParams
  const projectId = id || idFromParams;
  return useQuery(
    MDU_PROJECT_QUERY_KEYS.getProjectProgress(projectId),
    async () => {
      /*
        Let's give the consumer the option to suppress the page loader component. This may be useful in cases where
        the consumer has its own loader that it wants to show, e.g. a tooltip
      */
      if (!suppressLoader) api.toggleLoader(true);
      const response: TProjectProgressResponse = await APIS.mdu.getProjectProgress({id: projectId});
      if (!suppressLoader) api.toggleLoader(false);
      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Retrieving Project Progress'});
        throw new Error(response.err as any);
      }
      return response?.data?.project;
    },
    {
      enabled: Boolean(projectId),
      onError: response => {
        logger('Projects Progress: ')(response as Error);
      },
    }
  );
};
