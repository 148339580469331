import methods from '../buildMethods';
import apiOrders from './apis.orders';

/*
  Consolidate all booking-related routes under booking
*/

const apis = () => {
  const m = methods();

  return {
    /* Front load everything on the user.. WHAT! */
    membership: {},
    booking: {
      client: {
        addClient: m.post('/api/clients/carts/add_client'),
        removeClient: m.post('/api/clients/carts/remove_client'),
        find: m.get('/api2/users/find'),
        getClient: m.get('/api/clients/users/profile'),
        updateClient: m.post('/api/clients/users/update_profile'),
        createClient: m.post('/api/clients/users'),
      },
      cart: {
        startBooking: m.post('/api/clients/carts/start_booking'),
        getClientCart: m.get('/api/clients/carts'),
        itemPrice: m.post('/api/clients/carts/item_price'),
        addSku: m.post('/api/clients/carts/add_item'),
        updateSku: m.post('/api/clients/carts/update_item'),
        updateQuantity: m.post('/api/clients/carts/update_quantity'),
        deleteSku: m.post('/api/clients/carts/remove_item'),
        deletePlan: m.destroy('/api/clients/carts/remove_plan'),
        getAvailableTimes: m.get('/api2/availability'),
        checkZipCodeRestriction: m.get('/api/clients/geo_locations/check?zip=:zip'),
        goToPayment: m.post('/api/clients/carts/go_to_payment'),
        addCard: m.post('/api/clients/carts/add_card'),
        goToSummary: m.post('/api/clients/carts/go_to_summary'),
        addCoupon: m.post('/api/clients/carts/add_coupon'),
        ensureCouponValid: m.post('/api/clients/carts/ensure_coupon_valid'),
        removeAdjustment: m.post('/api/clients/carts/remove_adjustment'),
        estimatePlan: m.post('/api/clients/carts/estimate_plan'),
        selectPlan: m.post('/api/clients/carts/select_plan'),
        /**
         * Checks if a service can be added to the cart based on subsidy limits
         * Note: This does not check the total number of items in the cart.
         */
        canAddService: m.post('/api/clients/carts/can_add_service'),
        /**
         * Checks if more services can be added to the cart
         * Returns true if the cart does not have any subsidized services
         */
        canAddMoreServices: m.get('/api/clients/carts/can_add_more_services'),
        bookedBy: m.post('/api/clients/carts/booked_by'),
        createEmailLink: m.post('/api/clients/carts/finish_link'),
        sendFinishLink: m.post('/api/clients/carts/send_finish_link'),
        checkGiftCard: m.get('/api/clients/carts/check_gift_card'),
        initializeCart: m.post('/api/clients/carts/preinitialized_cart'),
        completeBooking: m.post('/api/clients/carts/complete_booking'),
        /** Returns a human-readable string describing a recurring service schedule. */
        parseRecurringService: m.get('/api/recurring_services/parse'),
      },
      order: {
        confirmation: m.get(`/api/clients/orders/:orderId/confirmation`),
        info: m.get('/api/clients/orders/:orderId/order_info'),
        getOrder: m.get('/api/admin/orders/:orderId'),
      },
      zipCodeLookup: {
        getAdjustments: m.get('/api2/zipcode/adjustment'),
      },
      skus: {
        find: m.get('/api/clients/skus/:id'),
        questions: m.get('/api/clients/skus/:id/questions'),
        filter: m.get('/api/admin/skus/filter'),
      },
      legacyPlans: {
        show: m.get('/api/clients/support_plans/:name'),
      },
      followUp: {
        setReason: m.put('/api/admin/follow_ups/:id'),
        bulkAddClonedSkus: m.post('/api/clients/carts/add_follow_up_items'),
      },
    },
    subscriptions: {
      all: m.get('/api/clients/plans'),
      show: m.get('/api/clients/plans/:id'),
      client: m.get('/api/admin/subscriptions/:id'),
      setAutoRenew: m.post('/api/admin/subscriptions/:id/set_auto_renew'),
      cancel: m.post('/api/admin/subscriptions/:id/cancel'),
      refund: m.post('/api/admin/subscriptions/:id/refund'),
      charge: m.post('/api/admin/subscriptions/:id/charge'),
      cancelReasons: m.get('/api/admin/subscriptions/reasons'),
      setExternalEnrollment: m.post('/api/admin/subscriptions/:id/set_external_enrollment'),
    },
    wholeHome: {
      customerDetails: m.get('/api/admin/clients/:id'),
      statistics: m.get('/api/admin/clients/:id/stats'),
      orders: m.get('/api/admin/clients/:id/orders/summary'),
      devices: m.get('/api/admin/clients/:id/devices'),
      membershipInformation: m.get('/api/admin/clients/:id/subscriptions/:subscriptionId/summary'),
    },
    users: {
      login: m.post('/service/auth/sessions'),
      logout: m.destroy('/logout'),
      current: m.get('/api/admin/users/current'),
      getUsers: m.get('/api/admin/users?:search'),
      list: m.get('/api/admin/users'),
      checkUserEmail: m.get('/api/clients/users/check_user'),
    },
    geo: {
      geocode: m.get('/api/geo/geocode'),
    },
    partners: {
      getAll: m.get('/api/admin/partners'),
    },
    mdu: {
      searchTechs: m.get('/api/admin/projects/techs?search=:name'),
      searchSkus: m.get('/api/admin/skus?partner_id=:partnerId&search=:search'),
      searchPropertyOwners: m.get('/api/admin/property_owners?include_other=:includeOther'),
      searchProjectManagers: m.get('/api/admin/admins/project_managers'),
      searchProjectTypes: m.get('/api/admin/projects/project_types'),
      createProject: m.post('/api/admin/projects?forceCase=camel'),
      launchProject: m.post('/api/admin/projects/:id/launch'),
      getAllProjects: m.get('/api/admin/projects?:search'),
      getProjectsStats: m.get('/api/admin/projects/stats?:search'),
      getProject: m.get('/api/admin/projects/:id?forceCase=camel'),
      updateProject: m.put('/api/admin/projects/:id?forceCase=camel'),
      createGroup: m.post('/api/admin/projects/:projectId/groups?forceCase=camel'),
      updateGroup: m.put('/api/admin/projects/:projectId/groups/:id?forceCase=camel'),
      deleteGroup: m.destroy('/api/admin/projects/:projectId/groups/:id?forceCase=camel'),
      getGroup: m.get('/api/admin/projects/:projectId/groups/:id'),
      createService: m.post('/api/admin/projects/:projectId/groups/:groupId/services?forceCase=camel'),
      updateService: m.put('/api/admin/projects/:projectId/groups/:groupId/services/:serviceId?forceCase=camel'),
      deleteService: m.destroy('/api/admin/projects/:projectId/groups/:groupId/services/:serviceId?forceCase=camel'),
      confirmDraftNewTemplates: m.post('/api/admin/projects/:projectId/groups/:groupId/confirm?forceCase=camel'),

      // Pause/Resume Project
      pauseProject: m.post('/api/admin/projects/:project_id/pause'),
      updateProjectPause: m.patch('/api/admin/projects/:project_id/pauses/:project_pause_id'),
      resumeProject: m.post('/api/admin/projects/:project_id/pause/finish'),
      getProjectPauseHistory: m.get('/api/admin/projects/:project_id/pauses'),

      // Bulk actions
      approveProjectsAction: m.post('/api/admin/projects/actions/approve'),
      deleteProjectsAction: m.post('/api/admin/projects/actions/delete'),
      approveUnitsAction: m.post('/api/admin/projects/:projectId/units/actions/approve'),
      deleteUnitsAction: m.post('/api/admin/projects/:projectId/units/actions/delete'),
      cannotCompleteUnitsAction: m.post('/api/admin/projects/:projectId/units/actions/unserviceable'),
      reopenUnitsAction: m.post('/api/admin/projects/:projectId/units/actions/reopen'),
      swapTemplateAction: m.post('/api/admin/projects/:projectId/units/actions/swap_template'),
      addServiceToUnitsAction: m.post('/api/admin/projects/:projectId/units/actions/add_service_to_units'),
      performProjectAction: m.post('/api/admin/projects/actions/:id/perform'),

      // DEPRECATED ON BE: use launchProject instead of createOrders
      createOrders: m.post('/api/admin/projects/:projectId/create_orders?forceCase=camel'),
      getPaymentAdjustmentTypes: m.get('/api/admin/payout_adjustment_types?forceCase=camel'),
      createProjectPayoutAdjustment: m.post('/api/admin/projects/:projectId/payout_adjustments?forceCase=camel'),
      updateProjectPayoutAdjustment: m.put('/api/admin/projects/:projectId/payout_adjustments/:payoutAdjustmentId?forceCase=camel'),
      deleteProjectPayoutAdjustment: m.destroy('/api/admin/projects/:projectId/payout_adjustments/:payoutAdjustmentId?forceCase=camel'),
      getTechsAssignedToProject: m.get('/api/admin/projects/:projectId/techs'),
      addTechsToProject: m.post('/api/admin/projects/:projectId/techs'),
      removeTechFromProject: m.destroy('/api/admin/projects/:projectId/techs/:id'),
      deleteProject: m.destroy('/api/admin/projects/:id?forceCase=camel'),
      approveProject: m.post('/api/admin/projects/:id/approve?forceCase=camel'),
      getProjectProgress: m.get('/api/admin/projects/:id/progress?forceCase=camel'),
      getProjectNoteEntryTypes: m.get('/api/admin/projects/notes/entry_types'),
      getNotesByProjectId: m.get('/api/admin/projects/:id/notes'),
      createNote: m.post('/api/admin/projects/:id/notes'),
      getAttachmentsByProjectId: m.get('/api/admin/projects/:id/attachments'),
      createAttachment: m.post('/api/admin/projects/:id/attachments'),
      deleteAttachment: m.destroy('/api/admin/projects/:id/attachments/:attachmentId'),
      getProjectTypes: m.get('/api/admin/projects/project_types'),
      reasons: m.get('/api/admin/reasons'),
      checkGroupCurrentlyExists: m.post('/api/admin/projects/:id/units/actions/add_unit/check_group'),

      // UNITS / JOBS
      unit: m.get('/api/admin/projects/:id/units/:unitId?forceCase=camel'),
      searchJobs: m.get('/api/admin/projects/:id/units?:search'),
      approveUnit: m.post('/api/admin/projects/:project_id/units/:unit_id/approve?forceCase=camel'),
      reopenUnit: m.post('/api/admin/projects/:project_id/units/:unit_id/reopen?forceCase=camel'),
      deleteUnit: m.destroy('/api/admin/projects/:project_id/units/:unit_id?forceCase=camel'),
      cancelUnit: m.post('/api/admin/projects/:project_id/units/:unit_id/cancel?forceCase=camel'),
      unserviceableUnit: m.post('/api/admin/projects/:project_id/units/:unit_id/unserviceable?forceCase=camel'),
      addUnitsToJob: m.post('/api/admin/projects/:project_id/units/actions/add_units'),
      addUnitToJob: m.post('/api/admin/projects/:project_id/units/actions/add_unit'),
      duplicateTemplate: m.post('/api/admin/projects/:project_id/groups/:group_id/duplicate?forceCase=camel'),

      // Validate Names
      validateTemplateName: m.get('/api/admin/projects/:project_id/groups/check_name'),
      validateUnitName: m.get('/api/admin/projects/:project_id/units/check_name'),
    },
    cloudinary: {
      getFileAssets: m.post('/api/assets/cloudinary/generate_signed_urls'),
      getAuthToken: m.post('/api/assets/cloudinary/generate_signature'),
      deleteResource: m.destroy('/api/assets/cloudinary/:type/:public_id'),
    },
    issues: {
      getStats: m.get('/api/admin/issues/tickets/stats?:search'),
      getSearchFields: m.get('/api/admin/issues/tickets/search_fields?:search'),
      getTypes: m.get('/api/admin/issues/tickets/types'),
      getTemplates: m.get('/api/admin/issues/templates'),
      getTemplateFields: m.get('/api/admin/issues/templates/:template_id/fields'),
      getCategories: m.get('/api/admin/issues/tickets/categories'),
      getTags: m.get('/api/admin/issues/tickets/tags'),
      createIssue: m.post('/api/admin/issues/tickets'),
      updateIssue: m.put('/api/admin/issues/tickets/:ticket_id'),
      deleteIssue: m.destroy('/api/admin/issues/tickets/:ticket_id'),
      createNote: m.post('/api/admin/issues/tickets/:ticket_id/notes'),
      getNotes: m.get('/api/admin/issues/tickets/:ticket_id/notes'),
      getTickets: m.get('/api/admin/issues/tickets?:search'),
      getTicket: m.get('/api/admin/issues/tickets/:ticket_id'),
      getTicketFieldsWithValues: m.get('/api/admin/issues/tickets/:ticket_id/fields'),
      getStatuses: m.get('/api/admin/issues/statuses'),
      getEntityTypes: m.get('/api/admin/issues/entity_types'),
    },
    dispatch: {
      getUnassignedOrders: m.get('/api/admin/orders/unassigned?:search'),
      getUnassignedStats: m.get('/api/admin/orders/unassigned/stats?:search'),
    },
    credits: {
      createCredit: m.post('/api/admin/store/credits'),
    },
    cbsas: {
      list: m.get('/api/admin/cbsas?:search'),
    },
    skus: {
      get: m.get('/api/admin/skus/:id'),
      list: m.get('/api/admin/skus?:search'),
      getSkillGuides: m.get('/api/admin/skus/:id/skill_guides'),
      getDevices: m.get('/api/admin/products/:product_category_id'),
      /* For task checklist */
      getTaskGroups: m.get('/api/admin/skus/:id/task_groups'),
      upsertTaskChecklist: m.post('/api/admin/skus/:id/task_groups'),
    },
    skuCategories: {
      list: m.get('/api/admin/sku_categories?:search'),
    },
    tracking: {
      leadGeneration: m.post('/api/segment/identify'),
    },
    ...apiOrders(m),
  };
};

export default apis();
