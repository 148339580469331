import React from 'react';

// Components
import {Grid} from 'ht-styleguide';
import StatsSection from 'features/Profile/Parts/Blocks/profile.Stats';
import MembershipSection from 'features/Profile/Parts/Blocks/profile.Membership';
import OrdersSection from 'features/Profile/Parts/Blocks/profile.Orders';
import DeviceSection from 'features/Profile/Parts/Blocks/profile.Devices';

// Constants
import {SECTION_HEADERS} from 'features/Profile/profile.constants';

// Types
import {ProfileState} from 'features/Profile/profile.types';

// Styles
import styles from 'features/Profile/profile.styles.scss';

const WholeHomeDashboard = ({profile}: {profile: ProfileState}) => {
  const {client, devices, orders, stats, subscription} = profile;

  return (
    <div className={styles.dashboard}>
      <Grid.Fluid classes="paddingY-medium2">
        <Grid.Row classes="paddingBottom-medium">
          <Grid.Column lg={12}>
            <h3>{SECTION_HEADERS.WHOLE_HOME_DASHBOARD}</h3>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column lg={12}>
            <StatsSection stats={stats} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column sm={4} md={4} lg={4}>
            <div className={styles.firstColumn}>
              <MembershipSection subscription={subscription} name={client.firstName} />
            </div>
          </Grid.Column>
          <Grid.Column sm={8} md={8} lg={8}>
            <div className={styles.secondColumn}>
              <OrdersSection orders={orders} clientId={client.id} />
              <DeviceSection devices={devices} />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid.Fluid>
    </div>
  );
};

export default WholeHomeDashboard;
