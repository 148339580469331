import React, {forwardRef} from 'react';
import cn from 'classnames';
import {Icon} from 'ht-styleguide';
import {noop} from 'utils/event';
import {NavItemProps, IconItemProps} from 'components/Layouts/AdminHub/adminHub.types';
import styles from './adminHub.header.styles.scss';

/**
 * For nav links in the header
 */
export const NavItem = ({text, isActive, onClick = noop}: NavItemProps) => {
  const itemStyles = cn('marginRight-small p1 text-weight-med', styles.navItem, {
    [styles.isActive]: isActive,
  });

  return (
    <button type="button" onClick={onClick} className={itemStyles}>
      {text}
    </button>
  );
};

/**
 * For the icon items in the header
 */
export const IconItem = forwardRef<HTMLDivElement, IconItemProps>(({children, containerClassName, dataTestId, iconName, isActive, noRightMargin = false, onClick = noop}, ref) => {
  const containerStyles = cn(styles.actionIconContainer, containerClassName, {
    [styles.isActive]: isActive,
    [styles.noRightMargin]: noRightMargin,
  });

  return (
    <div ref={ref} className={containerStyles} data-testid={dataTestId} onClick={onClick}>
      <Icon name={iconName} className={styles.icon} />
      {children}
    </div>
  );
});
