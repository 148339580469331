import React from 'react';
import {TREATMENTS} from 'features/Splitio/splitio.constants';
import {PlanIntervals} from '../../../../types/plan.types';
import {BREAKDOWN_PRICE_TYPES} from './cart.constants';
import {TSchedulingProperties} from '../Services/serviceScheduling/serviceScheduling.types';
import {TRecurrence} from '../Availability/AvailabilitySelector/availabilitySelector.types';

export enum StatusId {
  Items = 0,
  Address,
  Availability,
  Payment,
  Summary,
  Finished,
}

type QText = {
  text: string;
};

type QID = {
  id: number;
};

export type Questions = {
  [key: string]: QID | QID[] | QText;
};

export type TBreakdownPriceTypes = keyof typeof BREAKDOWN_PRICE_TYPES;

export type CartItem = {
  priceType: TBreakdownPriceTypes;
  followUpServiceId?: number | undefined;
  autoAdded?: boolean;
  hasUnansweredQuestions: boolean;
  heroImageUrl: string;
  lens: boolean;
  physicalProduct: boolean;
  questions: Questions;
  quantity?: number;
  remote: boolean;
  remoteOnly: boolean;
  skuId: number;
  followUp?: FollowUpItem;
};

export enum Prepaid {
  None = 'none',
  Full = 'full',
  Base = 'base',
}

export type CartOrder = {
  id: number;
  fromApi: boolean;
} | null;

// Should match AdjustmentType in rails' cart.rb file
export enum AdjustmentType {
  REMOTE = 'remote',
  SUBSIDY = 'subsidy',
  ANSWER = 'answer',
  EXTRA_HOURS = 'EXTRA_HOURS',
}

export type ItemAdjustments = {
  amount: string | number;
  amountFormatted?: string;
  lineThroughText?: React.ReactNode;
  name: string;
  type: AdjustmentType;
};

export type BreakdownItem = {
  adjustments: ItemAdjustments[];
  amount: number;
  amountFormatted: string;
  amountWithoutSubsidy: number;
  amountWithoutSubsidyFormatted: string;
  category: string;
  isProduct: boolean;
  lens: boolean;
  name: string;
  prepaid: Prepaid;
  products: number[];
  quantity: number;
  remote: boolean;
  remoteOnly: boolean;
  requiresInstallation: boolean;
  skuAmount: number;
  skuAmountFormatted: string;
  skuId: number;
  unlistedProducts?: null | number[];
  variantsIds: number[];
  [key: string]: any;
};

type Availability = {value: string}[];

type Card = {
  id?: number;
  lastDigits?: number;
  brand: string;
  expMonth: string;
  expYear: string;
  number?: string;
};

export type Plan = {
  id: number;
  name: string;
  amount: number;
  truckRollFee: number;
  pricingType: string;
  planInterval: string;
};

export type GlobalAdjustment = {
  name: string;
  amount: number;
  originalAmount?: number;
  type: 'coupon' | 'follow-up-discount' | 'credit';
  amountFormatted: string;
  originalAmountFormatted?: string;
  description?: string;
  skuId?: number;
  explanation?: string;
  feeId?: number;
  version?: number;
};

export type Coupon = {
  id: number;
  text: string;
  autoApply: boolean;
};

export type Partner = {
  id: number;
  partnerName: string;
  landingSeoName: string | null;
  layoutName: string | null;
  cobrandedLogo: null | Array<string>;
};

// USER TYPES
interface Referrals {
  referralCode: string;
  availableCredit: string;
  pendingCredit: string;
  usedCredit: string;
  withdrawnCredit: string;
  lifetimeCredit: string;
}

interface Stats {
  ordersCount: number;
}

interface Subscription {
  id: number;
  planId: number;
  planInterval: PlanIntervals;
  planName: string;
}

interface AddressObj {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  details: string;
}
interface Account {
  availableBonus: number;
  availableBonusFormatted: string;
  subscribeForUpdates: boolean;
  subscribeForFeedback: boolean;
  card: Card;
  plan?: any;
  referrals: Referrals;
  stats: Stats;
  hasSubscription: boolean;
  hasSuspendedSubscription: boolean;
  planHolder: boolean;
  subscription: Subscription;
}

interface User {
  id: number;
  name: string;
  phone: string;
  firstName: string;
  lastName: string;
  type: string;
  email: string;
  authToken: string;
  subscriptionPreferencesSet: boolean;
  kustomerJwt: string;
  account: Account;
  address: string;
  address2: string;
  addressInstructions: string;
  addressObj: AddressObj;
  hearAboutUs?: any;
}

export type FollowUp = {
  id: number;
  parentOrderId: number;
  orderId: number | null;
  details: string | null;
  comped: boolean | null;
  discountType: string | null;
  canComp: boolean;
};

export type FollowUpItem = {
  index: number;
  isProduct: boolean;
  name: string;
  quantity: number;
  skuId: number;
  comp: boolean;
};

/**
 * Split treatment values that are stored on the BE in the `treatments` table and sent as a property of `cart`
 */
export type SplitTreatmentValue = (typeof TREATMENTS)[keyof typeof TREATMENTS];

export type CartState = {
  availability: Availability;
  bonusAmount: number;
  breakdown: BreakDown;
  card: Card | null;
  cartError: CartError;
  clientId: number | null;
  coupon: Coupon | null;
  errors?: GenericAPIError;
  hasOnlyProducts: boolean;
  hasProducts: boolean;
  id: number;
  items: CartItem[];
  lat: number | null;
  lng: number | null;
  order: CartOrder;
  partner: Partner | null;
  partnerName: string | null;
  plan?: Plan | null;
  remote: boolean;
  remotePossible: boolean;
  skipCart: boolean;
  skusIncludedInUpsellPlan: boolean;
  statusId: StatusId;
  supportPlan?: unknown | undefined | null; // needs optional typing
  timezone: string | null;
  user?: User;
  followUp?: FollowUp;
  token: string;
  treatments: null | {[key: string]: SplitTreatmentValue};
  /** True if the cart is restricted to a single service */
  singleService: boolean | null;
  recurrence: TRecurrence;
  recurrenceText: string | null;
  recurring: boolean;
} & TSchedulingProperties;

export type BreakDown = {
  adjustments: GlobalAdjustment[];
  subscriptionAdjustments: GlobalAdjustment[] | null | undefined;
  items: BreakdownItem[];
  itemsTotal: number; // total of only items array
  itemsTotalFormatted: string;
  serviceTax: number;
  serviceTaxFormatted: string;
  serviceTotal: number; // includes tax and global adjustments
  serviceTotalFormatted: string;
  subscriptionTax: number;
  subscriptionTaxFormatted: string;
  subscriptionTotal: number; // includes tax
  subscriptionTotalFormatted: string;
  tax: number; // serviceTax and subscriptionTax
  taxFormatted: string;
  total: number; // grand total
  totalFormatted: string;
  totalSavings: number; // savings with plan applied
  totalSavingsFormatted: string;
  totalTechPayoutFormatted?: string;
  totalTechPayout?: number;
  totalAmountWithoutSubsidy?: number | undefined;
};

export type FollowUpBreakDown = {
  adjustments: GlobalAdjustment[];
  subscriptionAdjustments: GlobalAdjustment[] | null | undefined;
  services: BreakdownItem[];
  itemsTotal: number; // total of only items array
  itemsTotalFormatted: string;
  serviceTax: number;
  serviceTaxFormatted: string;
  serviceTotal: number; // includes tax and global adjustments
  serviceTotalFormatted: string;
  subscriptionTax: number;
  subscriptionTaxFormatted: string;
  subscriptionTotal: number; // includes tax
  subscriptionTotalFormatted: string;
  tax: number; // serviceTax and subscriptionTax
  taxFormatted: string;
  total: number; // grand total
  totalFormatted: string;
  totalSavings: number; // savings with plan applied
  totalSavingsFormatted: string;
  totalTechPayoutFormatted?: string;
  totalTechPayout?: number;
  totalAmountWithoutSubsidy?: number | undefined;
};

/**
 * For context, thunks, etc
 */
export type GenericAPIError = Partial<{
  [key: string]: string;
}>;

export type BookingCartState = {
  booking: {
    cart: CartState;
  };
};

export type CartSkuToSelect = Pick<BreakdownItem, 'amountFormatted' | 'category' | 'isProduct' | 'name' | 'skuId' | 'variantsIds' | 'quantity'> & {
  adjustments: {
    name: string;
    amount: string | undefined;
  }[];
  amount?: string | undefined;
  index: number;
  lineThroughText?: string | undefined;
};

export type CartError = {
  coupon?: string;
};

export type CartStateResponsePayload = {
  data: {
    cart: CartState;
  };
};
