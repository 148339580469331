import React from 'react';
import cn from 'classnames';
import {Chip, ELEMENT_SIZE, CHIP_TYPES} from 'ht-styleguide';

import {readableStatusDisplay} from '../../MDU.utils';
import {MDUStatuses, MDUUnitStatuses} from '../../MDU.types';

import styles from './chip.styles.scss';

/**
 * This is abstracted because, as usual, there are a lot of different ways to use the Chip component,
 * as it relates to color and as it relates this this project. The colors don't even align with like-minded
 * statuses across job vs project. Why does one have an outline, and the other not?
 */

type ChipProps = {
  status: MDUStatuses | MDUUnitStatuses | string;
  text?: string;
  size?: ELEMENT_SIZE;
  children?: string;
  statusType?: 'project' | 'job';
};

/* Project has outline, unit does not. So, have to seperate them out. :-( */
const statuses: Record<string, any> = {
  project: {
    [MDUStatuses.DRAFT]: {
      display: MDUStatuses.DRAFT,
      type: null,
    },
    [MDUStatuses.READY]: {
      display: MDUStatuses.READY,
      type: null,
      backgroundColor: '',
      foregroundColor: '',
    },
    [MDUStatuses.UPCOMING]: {
      display: MDUStatuses.UPCOMING,
      type: null,
      backgroundColor: '',
      foregroundColor: '',
    },
    [MDUStatuses.IN_PROGRESS]: {
      display: MDUStatuses.IN_PROGRESS,
      type: CHIP_TYPES.primary,
    },
    [MDUStatuses.NEEDS_APPROVAL]: {
      display: MDUStatuses.NEEDS_APPROVAL,
      type: CHIP_TYPES.warning,
    },
    [MDUStatuses.APPROVED]: {
      display: MDUStatuses.APPROVED,
      type: CHIP_TYPES.success,
    },
  },
  job: {
    [MDUUnitStatuses.NOT_STARTED]: {
      display: MDUUnitStatuses.NOT_STARTED,
      type: null,
      variant: 'solid',
      backgroundColor: '',
      foregroundColor: '',
    },
    [MDUUnitStatuses.UNSERVICEABLE]: {
      display: MDUUnitStatuses.UNSERVICEABLE,
      type: null,
      variant: 'solid',
      backgroundColor: '',
      foregroundColor: '',
    },
    [MDUUnitStatuses.CANCELLED]: {
      display: MDUUnitStatuses.CANCELLED,
      type: CHIP_TYPES.muted,
      variant: 'solid',
    },
    [MDUUnitStatuses.IN_PROGRESS]: {
      display: MDUUnitStatuses.IN_PROGRESS,
      type: CHIP_TYPES.primary,
      variant: 'solid',
    },
    [MDUStatuses.NEEDS_APPROVAL]: {
      display: MDUStatuses.NEEDS_APPROVAL,
      type: CHIP_TYPES.warning,
      variant: 'solid',
    },
    [MDUUnitStatuses.PENDING_APPROVAL]: {
      display: MDUUnitStatuses.PENDING_APPROVAL,
      type: CHIP_TYPES.warning,
      variant: 'solid',
    },
    [MDUUnitStatuses.COMPLETED]: {
      display: MDUUnitStatuses.COMPLETED,
      type: CHIP_TYPES.success,
      variant: 'solid',
    },
  },
};

/**
 * @param status
 * @param text - something custom to display within the chip, string.
 * @param statusType - project or unit
 * @param size
 * @param rest
 * @constructor
 */

/* Our entities can be outside the scope of a status intent. So, we need to align it back to something tangeable */
const ACT_AS_JOBS_STATUS = ['job', 'template', 'service'];
const ACT_AS_PROJECT_STATUS = ['project'];

function getChipType(statusType: string) {
  if (ACT_AS_JOBS_STATUS.includes(statusType)) return 'job';
  if (ACT_AS_PROJECT_STATUS.includes(statusType)) return 'project';
  return 'project';
}
const ChipStatus = ({status, text, statusType = 'project', size = ELEMENT_SIZE.SMALL, ...rest}: ChipProps) => {
  if (!status) return null;
  const statusInfoByType = statuses[getChipType(statusType)]?.[status];
  const textToDisplay = readableStatusDisplay([text, statusInfoByType?.display, status].find(Boolean));

  return (
    <Chip size={size} classes={cn('capitalize', styles[status])} {...rest} {...statusInfoByType}>
      {textToDisplay}
    </Chip>
  );
};

export default ChipStatus;
