import React from 'react';
import cn from 'classnames';
import {Icon, InputField, ELEMENT_SIZE} from 'ht-styleguide';
import styles from './searchInput.styles.scss';

type SearchInputProps = {
  inputIconName?: string;
  inputRef?: React.MutableRefObject<any>;
  inputPlaceholder?: string;
  onInputChange?: BaseAnyFunction;
  onInputIconClick?: BaseAnyFunction;
  onInputFocus?: BaseAnyFunction;
  onInputBlur?: BaseAnyFunction;
  inputContainerClass?: string;
  inputOuterContainerClass?: string;
  inputWrapperClass?: string;
  dataTestId?: string;
  focus?: boolean;
  /**
   * Only used to trigger the close icon to show
   */
  value: string | null | undefined;
};
/**
 * Currently used in `TableToolbar`. Different from `../Search/MultiDwellingUnits.SearchInput`
 */
const SearchInput = ({
  inputIconName = 'v2-close-icon',
  inputRef,
  inputPlaceholder,
  onInputChange,
  onInputIconClick,
  onInputFocus,
  onInputBlur,
  dataTestId = 'keyword-search',
  inputContainerClass,
  inputOuterContainerClass,
  inputWrapperClass,
  focus,
  value,
}: SearchInputProps) => (
  <div className={cn(styles.container, inputOuterContainerClass)}>
    <Icon name="search" className={cn('paddingLeft-tiny1', styles.searchIcon, focus && styles.focus)} />
    <InputField
      dataTestId={dataTestId}
      ref={inputRef}
      elementSize={ELEMENT_SIZE.SMALL}
      containerClass={cn(styles.inputContainer, inputContainerClass)}
      inputWrapperClass={cn(styles.input_override, inputWrapperClass)}
      onChange={onInputChange}
      onBlur={onInputBlur}
      onFocus={onInputFocus}
      iconName={value ? inputIconName : undefined}
      iconClass={styles.closeIcon}
      iconOnClick={onInputIconClick}
      placeholder={inputPlaceholder}
    />
  </div>
);

export default SearchInput;
