import {createSelector, createSlice} from '@reduxjs/toolkit';
import {TFileSignCloudinaryResponse, TFileUploadErrorResponse} from 'features/Cloudinary/cloudinary.types';

export type CloudinaryState = {
  fileUploadSuccess: {
    [key: string]: TFileSignCloudinaryResponse[];
  };
  fileUploadError: {
    [key: string]: TFileUploadErrorResponse[];
  };
  cachedAssets: Record<string, string>;
};

/*
 ********* INITIAL STATE
 */
const initialState: CloudinaryState = {
  fileUploadSuccess: {},
  fileUploadError: {},
  cachedAssets: {},
};

/*
 ************ SLICE
 */
const {actions, reducer} = createSlice({
  name: 'cloudinary',
  initialState,
  reducers: {
    setFileCachedImage: (state, action) => {
      const {id, image} = action.payload;
      state.cachedAssets = {
        ...state.cachedAssets,
        [id]: image,
      };
    },
    setFileUploadSuccess: (state, action) => {
      const {success, append = false, editorNamespace} = action.payload;
      if (!append) {
        state.fileUploadSuccess = {
          ...state.fileUploadSuccess,
          [editorNamespace]: [].concat(success),
        };
      } else {
        state.fileUploadSuccess = {
          ...state.fileUploadSuccess,
          [editorNamespace]: (state.fileUploadSuccess?.[editorNamespace] || []).filter(obj => obj.asset_id !== success.asset_id).concat(success),
        };
      }
    },
    deleteFileUploadSuccess: (state, action) => {
      const {ids, editorNamespace} = action.payload;

      state.fileUploadSuccess = {
        ...state.fileUploadSuccess,
        [editorNamespace]: (state.fileUploadSuccess?.[editorNamespace] || []).filter((item: TFileSignCloudinaryResponse) => !ids.includes(item.public_id)),
      };
    },
    deleteAllFilesByNamespace: (state, action) => {
      const {editorNamespace} = action.payload;

      state.fileUploadSuccess = {
        ...state.fileUploadSuccess,
        [editorNamespace]: [],
      };

      state.fileUploadError = {
        ...state.fileUploadSuccess,
        [editorNamespace]: [],
      };
    },
    setFileUploadError: (state, action) => {
      const {error, append = false, editorNamespace} = action.payload;

      if (!append) {
        state.fileUploadError = {
          ...state.fileUploadError,
          [editorNamespace]: [error],
        };
      } else {
        state.fileUploadError = {
          ...state.fileUploadError,
          [editorNamespace]: [...state.fileUploadError[editorNamespace], error],
        };
      }
    },
  },
  extraReducers: () => {},
});

/*
 ************ EXPORTS
 */

/*
*******************************************************
  SELECTORS & SELECTOR METHODS
*******************************************************
*/
const getEditorState = (state: any): CloudinaryState => state.cloudinary;

/*
*******************************************************
  EXPORTS
*******************************************************
*/

const selectors = {
  getAllFileSuccessUploads: createSelector(getEditorState, cloudinary => cloudinary.fileUploadSuccess ?? null),
  getFileSuccessUploads: (editorNamespace: string) => createSelector(getEditorState, cloudinary => cloudinary.fileUploadSuccess?.[editorNamespace] ?? null),
  getFileFailureUploads: (editorNamespace: string) => createSelector(getEditorState, cloudinary => cloudinary.fileUploadError?.[editorNamespace] ?? null),
  getCachedAsset: (id: string) => createSelector(getEditorState, cloudinary => cloudinary.cachedAssets[id] ?? null),
};

export default {
  actions,
  reducer,
  initialState,
  selectors,
};
