import {useDispatch} from 'react-redux';
import {useQuery} from 'react-query';

import APIS from 'global/apis';
import {logger} from 'utils/logger';

import {MDU_PROJECT_QUERY_KEYS} from '../MDU.query.keys';
import {showErrorToast} from 'queries/query.utils';

/**
 * Get All Projects Stats Query
 */

type TQueryGetReasons = {
  options?: {
    enabled: boolean;
  };
};

export enum TReasonTypes {
  CANCELLATION = 'cancellation',
  SERVICE_FLAG = 'service_flag',
  UNSERVICEABLE = 'unserviceable',
}

type TReason<T> = {
  id: number;
  text: string;
  type: T;
};

type TQueryGetReasonsResponse = {
  cancellation: TReason<TReasonTypes.CANCELLATION>[];
  service_flag: TReason<TReasonTypes.SERVICE_FLAG>[];
  unserviceable: TReason<TReasonTypes.UNSERVICEABLE>[];
};

export const useGetReasons = ({options}: TQueryGetReasons) => {
  const dispatch = useDispatch();

  return useQuery<TQueryGetReasonsResponse>(
    MDU_PROJECT_QUERY_KEYS.reasons(),
    async () => {
      const response = await APIS.mdu.reasons();
      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Retrieving Reasons'});
        throw new Error(response);
      }

      return {...response?.data?.reasons};
    },
    {
      ...options,
      onError: response => {
        logger('Projects Stats: ')(response as Error);
      },
    }
  );
};
