import React from 'react';
import uiSlice from 'features/ui/ui.ducks';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useSelector} from 'hooks/useAppSelector';
import {ModalCreateNewProject} from 'features/MultiDwellingUnits/Parts/Modals';

const CreateButtonProject = () => {
  const dispatch = useAppDispatch();
  const modalState = useSelector(uiSlice.selectors.getModalState);
  const showCreateProjectModal = modalState.createProject.visible;
  const toggleModal = () => dispatch(uiSlice.actions.setModalVisibility({modal: 'createProject'}));

  return <ModalCreateNewProject isVisible={showCreateProjectModal} toggleModal={toggleModal} />;
};

export default CreateButtonProject;
