import {useQuery} from 'react-query';
import useApi from 'hooks/useApi';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {showErrorToast, logResponseError} from 'queries/query.utils';
import {SKUS_QUERY_KEYS} from './skus.query.keys';
import {TSkillGuide} from './types/skillGuide.types';

type TUseGetSkillGuidesQueryResponse = WithErrorResponse<{skill_guides: TSkillGuide[]}>;

type TUseGetSkillGuidesQueryParams = {
  /**
   * sku id to fetch on
   */
  id: number;
  useLoader?: boolean;
  queryOpts?: Parameters<typeof useQuery<TUseGetSkillGuidesQueryResponse['data']>>['2'];
};

/**
 * Fetch skill guides for sku
 */
const useGetSkillGuidesQuery = ({id, useLoader = false, queryOpts}: TUseGetSkillGuidesQueryParams) => {
  const api = useApi();
  const dispatch = useAppDispatch();

  return useQuery<TUseGetSkillGuidesQueryResponse['data']>(
    SKUS_QUERY_KEYS.getSkillGuides(id),
    async () => {
      if (useLoader) {
        api.toggleLoader(true);
      }
      const response: TUseGetSkillGuidesQueryResponse = await api.skus.getSkillGuides({id});
      if (useLoader) {
        api.toggleLoader(false);
      }
      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Retrieving Skill Guides'});
        logResponseError('Skill guides')(response.err);
        throw new Error('Error Retrieving Skill Guides');
      }
      return response?.data;
    },
    queryOpts
  );
};

export default useGetSkillGuidesQuery;
