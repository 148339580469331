import React from 'react';
import cn from 'classnames';
import {TaskTypes} from 'types/tasks.types';
import {IBaseServiceTask} from '../../serviceTasksList.types';
import BlockWrapper from './BlockWrapper';
import ServiceTaskAttachmentField from './ServiceTaskAttachmentField';

// These types should never have the optional notes section
const HAS_NO_OPTIONAL_NOTES = [TaskTypes.Incident];

const OPTIONAL_NOTES_NAMESPACE = 'taskItemOptionalNotes';

interface IOptionalNotes extends Pick<IBaseServiceTask, 'serviceId' | 'task' | 'dataTestId'> {}

/**
 * This component displays the answers of the optional fields for the task. So far, there are
 * only two optional fields: notes and photos.
 */
const OptionalNotes = ({serviceId, task, dataTestId = 'OptionalNotes'}: IOptionalNotes) => {
  if (HAS_NO_OPTIONAL_NOTES.includes(task.task_type)) {
    return null;
  }

  const {show_optional_note, show_optional_photo, answer} = task;
  const {response, attachments} = answer || {};
  const hasAttachments = (attachments || []).length > 0;
  const shouldShowSection = (show_optional_note && response) || (show_optional_photo && hasAttachments);

  // Styles
  const responseStyles = cn('p2 n800', {
    'marginBottom-small': hasAttachments,
  });

  return shouldShowSection ? (
    <BlockWrapper title="Notes" dataTestId={dataTestId}>
      {show_optional_note && response && <p className={responseStyles}>{response}</p>}
      {show_optional_photo && <ServiceTaskAttachmentField serviceId={serviceId} namespace={OPTIONAL_NOTES_NAMESPACE} task={task} />}
    </BlockWrapper>
  ) : null;
};

export default OptionalNotes;
