import React from 'react';
import cn from 'classnames';
import {Panel} from 'ht-styleguide';
import ProjectDetailsSectionHeader from './CurrentProject.Details.SectionHeader';
import {EditSectionProps} from './CurrentProject.Details.types';
import styles from './currentProjectDetails.styles.scss';

export const EditSection = ({children, headerText}: EditSectionProps) => {
  return (
    <Panel noShadow largeBorderRadius className={cn(styles.panel, 'marginTop-small1 paddingY-medium paddingX-small2')}>
      <ProjectDetailsSectionHeader className="marginBottom-small2" headerText={headerText} />
      {children}
    </Panel>
  );
};
