import {ChangeEvent, useState, useEffect, useCallback} from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import {useAppDispatch} from 'hooks/useAppDispatch';
import usePrevious from 'hooks/usePrevious';
import uiSlice from 'features/ui/ui.ducks';
import flatten from 'utils/object/flatten';

/**
 * On change of table key, reset pagination data.
 * `key` should be unique to the data set being used.
 */
export const useResetPagination = ({tableKey}: {tableKey: string | null | undefined}) => {
  const dispatch = useAppDispatch();
  const [currentKey, setCurrentKey] = useState(tableKey);
  const previousKey = usePrevious(currentKey);

  useEffect(() => {
    if (tableKey) {
      setCurrentKey(tableKey);
    }
  }, [tableKey]);

  useEffect(() => {
    if (currentKey && previousKey && currentKey !== previousKey) {
      dispatch(uiSlice.actions.resetPagination());
    }
  }, [currentKey, dispatch, previousKey]);

  useEffect(() => {
    return () => {
      dispatch(uiSlice.actions.resetPagination());
    };
  }, [dispatch]);
};

/**
 * Custom hook to handle manual row/subrow selection.
 *
 * - Used when row selection feature through MRT is not sufficient.
 * - This hook assumes that there is only two levels of nesting. Modifications are needed for more levels.
 * - See `datatable-guide.md` for set up.
 */
export const useCustomRowSelection = () => {
  /**
   * {
   *   1: false,
   *   2: {
   *    14: false,
   *    15: false,
   *   }
   * }
   */
  type TSelectedRows = Record<string, boolean | Record<string, boolean>>;
  /**
   * Object path defined in dot notation, which is used to accommodate for nested objects.
   */
  type TPath = string;

  /**
   * Pre-seed `selectedRows` in callee component as data format is unknown.
   */
  const [selectedRows, setSelectedRows] = useState<TSelectedRows>();

  /**
   * Select a single row or all child rows depending on the path.
   */
  const onSelectChange = useCallback(
    (path?: TPath) => (_: any, onChangeEvent: ChangeEvent<HTMLInputElement>) => {
      const newSelectedRows = {...selectedRows};
      const checkedState = onChangeEvent.target.checked;

      // The master header row of the main table. This will select all rows including subrows.
      if (!path) {
        const flattenedRows = flatten(newSelectedRows);
        Object.keys(flattenedRows).forEach(key => {
          set(newSelectedRows, key, checkedState);
        });
      } else {
        const value = get(newSelectedRows, path);
        if (typeof value === 'boolean') {
          set(newSelectedRows, path, checkedState);
        } else if (!isEmpty(value)) {
          // Sub rows
          const newSubRow = Object.keys(value).reduce((acc, key) => {
            return {
              ...acc,
              [key]: checkedState,
            };
          }, {});
          set(newSelectedRows, path, newSubRow);
        }
      }
      setSelectedRows(newSelectedRows);
    },
    [selectedRows]
  );

  /**
   * Based on the path provided, determine the state of the checkbox.
   */
  type TDetermineCheckboxState = (path?: TPath, rowObject?: TSelectedRows | undefined) => {checked: boolean; indeterminate?: boolean};
  const determineCheckboxState: TDetermineCheckboxState = useCallback(
    (path, rowObject) => {
      const selectedRowObject = rowObject || selectedRows;

      if (isEmpty(selectedRowObject)) {
        return {
          checked: false,
          indeterminate: false,
        };
      }

      // For the parent row with child rows
      if (!path) {
        const flattenedSelectedRows = Object.values(selectedRowObject!)
          .map(v => {
            if (typeof v === 'object') {
              return Object.values(v);
            }
            return v;
          })
          .flat();

        let allSelected = true;
        let someSelected = false;

        flattenedSelectedRows.forEach(v => {
          if (v) {
            someSelected = true;
          }
          if (!v) {
            allSelected = false;
          }
        });

        return {
          checked: allSelected,
          indeterminate: !allSelected && someSelected,
        };
      }

      // Parent rows can be boolean or object
      const parentRow = get(selectedRowObject, path);

      if (typeof parentRow === 'boolean') {
        return {
          checked: parentRow,
          indeterminate: false,
        };
      }

      return determineCheckboxState(undefined, parentRow);
    },
    [selectedRows]
  );

  return {
    determineCheckboxState,
    onSelectChange,
    selectedRows,
    setSelectedRows,
  };
};
