import React from 'react';
import {Route, Routes, Navigate, Outlet, useParams} from 'react-router-dom';
/* Components */
import AdminHubLayout from 'components/Layouts/AdminHub/AdminHub.layout';
import Sidebar from 'components/Layouts/AdminHub/Sidebar';
import UnassignedListPage from 'features/Dispatch/Pages/UnassignedList';
import DispatchTopNav from 'features/Dispatch/Parts/DispatchTopNav';
/* Hooks */
import useDispatchSidebarMenu from 'features/Dispatch/hooks/useDispatchSidebarMenu';
/* Constants */
import {Routes as HTRoutes} from 'global/routes/routes';
import {DispatchNavTypes, TDispatchRouteParams} from 'features/Dispatch/dispatch.types';

const DispatchRoutesStructureLayout = () => {
  const menu = useDispatchSidebarMenu();

  return (
    <AdminHubLayout>
      <DispatchTopNav />
      <AdminHubLayout.ContentTwoColumnContainer>
        <AdminHubLayout.ContentLeftContainer>
          <Sidebar menu={menu} headerProps={{title: 'Dispatch'}} isCollapsible />
        </AdminHubLayout.ContentLeftContainer>
        <AdminHubLayout.ContentRightContainer>
          <Outlet />
        </AdminHubLayout.ContentRightContainer>
      </AdminHubLayout.ContentTwoColumnContainer>
    </AdminHubLayout>
  );
};

/**
 * Determine which page to render based on the dispatchNavType
 * `/dispatch/:dispatchNavType`
 */
const DispatchNavTypeRoutes = () => {
  const {dispatchNavType} = useParams<TDispatchRouteParams>();

  switch (dispatchNavType) {
    case DispatchNavTypes.All:
    case DispatchNavTypes.AssignedToMe:
      return <UnassignedListPage />;
    default:
      return <Navigate to={`${HTRoutes.DISPATCH_PATH_BASE()}/${DispatchNavTypes.All}`} replace />;
  }
};

const DispatchRouteStructure = () => (
  <Routes key="DispatchRoutes">
    <Route element={<DispatchRoutesStructureLayout />}>
      <Route path={`${HTRoutes.DISPATCH_NAV_TYPE_KEY()}`} element={<DispatchNavTypeRoutes />} />
      <Route path="*" element={<Navigate to={`${HTRoutes.DISPATCH_PATH_BASE()}/${DispatchNavTypes.All}`} replace />} />
    </Route>
  </Routes>
);

export default DispatchRouteStructure;
