import React, {useMemo, ComponentProps} from 'react';
import useGetSkuCategoriesQuery from '../queries/query.dataSelect.getSkuCategories';
import {TSharedFormFieldProps} from '../../dataFormFields.types';
import BaseFilterSelectField from '../../BaseFilter/SelectField';

type TSkuCategorySelect = TSharedFormFieldProps &
  Partial<ComponentProps<typeof BaseFilterSelectField>> & {
    frontLoadData?: boolean;
  };

const SkuCategorySelect = ({dataTestId = 'DataSelect-SkuCategorySelect', fieldName, formik, frontLoadData = true, label, onChangeCB, options, ...rest}: TSkuCategorySelect) => {
  const pagination: TBasePaginationQuery | undefined = frontLoadData ? {per_page: 9999, page: 1} : undefined;
  const {data, isFetching} = useGetSkuCategoriesQuery({pagination});

  const skuCategoryOpts = useMemo(() => {
    return (data?.sku_categories || []).map(skuCategory => ({label: skuCategory.name, value: skuCategory.id}));
  }, [data?.sku_categories]);

  const disabled = isFetching;

  return (
    <BaseFilterSelectField dataTestId={dataTestId} isDisabled={disabled} fieldName={fieldName} formik={formik} label={label} onChangeCB={onChangeCB} multiple options={skuCategoryOpts} {...rest} />
  );
};

export default SkuCategorySelect;
