import React, {useRef, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {Button, Icon, BUTTON_THEMES} from 'ht-styleguide';

/* Slices/Ducks */
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';

/* Hooks / queries */
import useOutsideClick from 'hooks/useOutsideClick';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useCreateJobTemplateDraftMutation} from 'features/MultiDwellingUnits/queries/mutation.jobs.drafts';

/* Components */
import {MenuListItem, MenuPanel, MenuUnorderedList} from 'components/Elements/Menu';

/* Constants */
import {mduProjectBulkJobCreatePath, mduProjectBulkJobCreateExisting} from 'global/paths';

import {MduUseParamsTypes} from 'features/MultiDwellingUnits/MDU.types';
import styles from './jobs.styles.scss';

type TMenuWrapper = {
  buttonText: string;
  buttonTheme?: string;
  icon?: string;
  plainButton?: boolean;
};

const MenuWrapper = ({icon, plainButton = false, buttonText, buttonTheme = BUTTON_THEMES.SECONDARY}: TMenuWrapper) => {
  /* Local State */
  const [menu, setMenu] = useState(false);
  const wrapperRef = useRef(null);

  /* Hooks */
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {mutateAsync} = useCreateJobTemplateDraftMutation();
  const {projectId = ''} = useParams<MduUseParamsTypes>();

  /* Constants */
  const menuAdjustment = plainButton ? styles.menuPositionCreatePlainButton : styles.menuPositionCreate;

  /* Methods */
  const onHandleCreateTemplate = async () => {
    const data = await mutateAsync();
    /* 1. Update draft for "new" template */
    dispatch(mduProjectsSlice.actions.createTemplateCreationNewDraft(data.projectGroup));
    /* get group id and redirect */
    navigate(mduProjectBulkJobCreatePath(projectId, data.projectGroup?.id as string));
  };
  const handleClickOutside = () => {
    setMenu(false);
  };
  const onHandleSetMenu = () => {
    setMenu(!menu);
  };

  useOutsideClick(wrapperRef, handleClickOutside);

  return (
    <span className="position relative" ref={wrapperRef}>
      {plainButton ? (
        <button type="button" className="flex align-items-center plainButton teal" onClick={onHandleSetMenu}>
          {icon ? <Icon className="paddingRight-tiny" name={icon} /> : null}
          {buttonText}
        </button>
      ) : (
        <Button onClick={onHandleSetMenu} theme={buttonTheme} style={{width: '200px'}}>
          {buttonText}
        </Button>
      )}
      {menu ? (
        <MenuPanel className={menuAdjustment}>
          <MenuUnorderedList>
            <MenuListItem dataTestId="menu-approve-jobs" onClick={() => navigate(mduProjectBulkJobCreateExisting(projectId))}>
              Add Existing Template
            </MenuListItem>
            <MenuListItem dataTestId="menu-reopen-jobs" onClick={onHandleCreateTemplate}>
              Create New Template
            </MenuListItem>
          </MenuUnorderedList>
        </MenuPanel>
      ) : null}
    </span>
  );
};

export default MenuWrapper;
