import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {BASE_CURRENT_PROJECT_KEY, BASE_PROJECTS_QUERY_KEY} from 'features/MultiDwellingUnits/MDU.query.keys';
import {NavTypes} from 'features/MultiDwellingUnits/MDU.types';

import {useProjectsRouteParams} from 'features/MultiDwellingUnits/Pages/Projects/hooks/projects.useProjecsRouteParams';

/**
 * This is just a helper so we don't have to repeat all of this stuff in two places.
 * We could use context too, to get some dependnecy injection, but as of now. This will do.
 */
export const useHookHelpersForInvalidation = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {projectsType} = useProjectsRouteParams();
  const queryKeysPagi = [...BASE_PROJECTS_QUERY_KEY, ...['pagination', projectsType].filter(Boolean)];
  const queryKeysJobsPagi = [...BASE_CURRENT_PROJECT_KEY, ...['paginationJobs'].filter(Boolean)];
  const queryKeysStats = [...BASE_PROJECTS_QUERY_KEY, ...['stats', NavTypes.ALL].filter(Boolean)];

  return {
    dispatch,
    navigate,
    queryClient,
    queryKeysPagi,
    queryKeysStats,
    queryKeysJobsPagi,
  };
};
