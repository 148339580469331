import React, {useState, useEffect} from 'react';
import {SelectField} from 'ht-styleguide';
import GroupServicesBoxInfo from 'features/MultiDwellingUnits//Parts/GroupServicesBoxInfo';
import {useCurrentProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/CurrentProject.hooks';
import {ProjectGroup} from 'features/MultiDwellingUnits/MDU.types';
import {SelectBaseOption} from 'types/base.types';
import {useGenerateGroupSelectionInfoDisplay} from '../../Jobs.hooks';
import {convertProjectServicesToTGroupServices} from 'features/MultiDwellingUnits/MDU.utils';

export type TCreatingBulkExistingTemplatesProps = {
  actions: {
    setJobTemplate: (jobTemplate: ProjectGroup) => void;
    setBlankTemplate?: (arg: boolean) => void;
  };
  reset?: boolean;
  blankTemplateSelected?: boolean;
};

const BLANK_DEFAULT = {label: 'Blank', value: 'blank'};

const CreatingExistingTemplates = ({actions, reset, blankTemplateSelected}: TCreatingBulkExistingTemplatesProps) => {
  /* local state */
  const [selectedGroup, setSelectedGroup] = useState<SelectBaseOption | undefined>();

  /* hooks */
  const currentProject = useCurrentProject();
  const {servicesByGroup} = useGenerateGroupSelectionInfoDisplay(selectedGroup);

  /* constants */
  const {projectGroups = []} = currentProject;
  const filteredGroups = projectGroups.filter((pg: ProjectGroup) => pg.projectServices?.length);
  const appendBlankEntry = actions.setBlankTemplate ? BLANK_DEFAULT : [];
  const projectGroupsSelectionOptions = filteredGroups
    .map((pgs: ProjectGroup) => ({label: pgs.name, value: pgs.id}))
    .concat(appendBlankEntry)
    .filter(Boolean);

  const servicesDisplay = convertProjectServicesToTGroupServices(servicesByGroup);

  useEffect(() => {
    if (reset) {
      setSelectedGroup(undefined);
    }
  }, [reset]);

  useEffect(() => {
    if (blankTemplateSelected) {
      setSelectedGroup(BLANK_DEFAULT);
    }
  }, [blankTemplateSelected, selectedGroup]);

  return (
    <div>
      <SelectField
        value={selectedGroup}
        options={projectGroupsSelectionOptions}
        onChange={evt => {
          setSelectedGroup(evt);
          /* get matched template */
          const group = filteredGroups.find((g: ProjectGroup) => +g.id === +evt.value);

          if (evt.value === 'blank') {
            actions.setBlankTemplate?.(true);
          } else {
            actions.setJobTemplate(group as ProjectGroup);
          }
        }}
        label="Job Template"
      />
      <GroupServicesBoxInfo services={servicesDisplay} />
    </div>
  );
};

export default CreatingExistingTemplates;
