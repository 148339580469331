import {useMutation, useQueryClient} from 'react-query';
import {useParams} from 'react-router-dom';

import useApi from 'hooks/useApi';
import {MDU_PROJECT_QUERY_KEYS} from '../MDU.query.keys';
import {MduUseParamsTypes} from '../MDU.types';

/** MUTATION: Create New Project Attachment */
export const useCreateProjectAttachmentMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const {projectId = ''} = useParams<MduUseParamsTypes>();
  return useMutation(
    async (attachment: FormData) => {
      api.toggleLoader(true);
      const response = await api.mdu.createAttachment({id: projectId}, attachment, {files: true});
      api.toggleLoader(false);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(MDU_PROJECT_QUERY_KEYS.getAttachmentsByProjectId(projectId));
      },
    }
  );
};
