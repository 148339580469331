import React from 'react';
import ReactTooltip from 'react-tooltip';
import {useSelector} from 'react-redux';
import {Panel} from 'ht-styleguide';
import cn from 'classnames';

/* Slice / Ducks */
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';

/* Hooks */
import {useCurrentProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/CurrentProject.hooks';

import {BreakdownItem} from 'features/Booking/Parts/Cart/cart.types';
import {ProjectGroup} from 'features/MultiDwellingUnits/MDU.types';
import styles from '../templatejobs.styles.scss';

const TemplateJobsGroupServicesToolTip = ({draft}: {draft?: boolean}) => {
  const {projectGroups = []} = useCurrentProject();
  const projectGroupsDraft = useSelector(mduProjectsSlice.selectors.getDraftProjectGroups);
  const pg = draft ? projectGroupsDraft : projectGroups;

  return (
    <ReactTooltip
      effect="float"
      place="right"
      backgroundColor="white"
      offset={{right: 0, top: 0}}
      getContent={groupId => {
        const group = pg.find((pgroup: ProjectGroup) => +pgroup.id === +groupId);
        const items = group?.breakdown?.items;

        if (!items || items?.length === 0) return null;

        return (
          <Panel largeBorderRadius className={cn(styles.tooltipGroup, styles.panel, 'n800')}>
            {items.map((item: BreakdownItem, idx: number) => {
              const serviceId = item.projectServiceId || idx;

              return (
                <div key={`${item.skuId}-${serviceId}`} className="flex flex-direction-column justify-content-space-between">
                  <div className="flex flex-direction-column">
                    {item.name} ({item.skuId}) x{item.quantity}
                  </div>
                  {item.adjustments?.map(adjustment => {
                    return (
                      <div className="paddingTop-tiny1" key={`${adjustment.name}-${serviceId}`}>
                        +{adjustment.name}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </Panel>
        );
      }}
      id="groupAttributes"
    />
  );
};

export default TemplateJobsGroupServicesToolTip;
