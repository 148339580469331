/* eslint-disable camelcase */
import React, {useEffect, useMemo} from 'react';
import {EmptyState} from 'ht-styleguide';
import ReactTooltip from 'react-tooltip';
import compact from 'lodash/compact';
import {useAppDispatch} from 'hooks/useAppDispatch';

/* Components */
import StatsTooltip from 'features/MultiDwellingUnits/Pages/Projects/Parts/MDUProjectsTable/MDUProjectsTable.Tooltip';
import CalloutBoxTableInfo from 'features/MultiDwellingUnits/Parts/Table/CalloutBox';
import DataTable from 'components/Elements/DataTable';
import MDUBulkEditSideSheet from 'features/MultiDwellingUnits/Parts/SideSheets/BulkEdit';
import {ACTION_MENU_COL_ID} from 'components/Elements/DataTable/dataTable.constants';

/* Utils & Constants  */
import {noop} from 'utils/event';
import {createColumnsByKey} from 'features/MultiDwellingUnits/Pages/Projects/Parts/MDUProjectsTable/MDUProjectsTable.columns';
import useMDUProjectsTableUtil from './MDUProjectsTable.useMDUProjectsTableUtil';

/* Types */
import {ProjectDetailsShallow} from 'features/MultiDwellingUnits/MDU.types';
import {IMDUProjectsTableProps} from './MDUProjectsTable.types';

import styles from './MDUProjectsTable.styles.scss';

const MDUProjectsTable = ({
  emptyStateHeader = 'Your search returned no results',
  emptyStateText = '',
  pageType,
  columnKeys = [],
  isActionableRow,
  projectData = [] as ProjectDetailsShallow[],
  paginationDataProp,
  onRowClickCb = noop,
}: IMDUProjectsTableProps) => {
  /* Hooks */
  const dispatch = useAppDispatch();

  const filteredProjects: ProjectDetailsShallow[] = projectData;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [filteredProjects.length]);

  const tableColumnsByKey = createColumnsByKey({onCellClick: onRowClickCb, dispatch});

  const columnData = useMemo(() => {
    return columnKeys
      .map(key => {
        return tableColumnsByKey[key];
      })
      .filter(col => col);
  }, [tableColumnsByKey, columnKeys]);

  const columnOrder = useMemo(() => {
    return compact(columnData.map(c => c.id || c.accessorKey));
  }, [columnData]);

  const {tableInstanceRef, bulkSelectAll, onRowChange, onSetMassSelectAll, paginationData, totalRowSelection} = useMDUProjectsTableUtil<ProjectDetailsShallow>({
    tableData: filteredProjects,
    tableType: 'projects',
    pageType,
  });

  return (
    <div>
      {filteredProjects.length ? (
        <>
          <CalloutBoxTableInfo pagination={paginationDataProp!} rowsLength={filteredProjects.length} />
          <DataTable<ProjectDetailsShallow>
            tableKey={`projects-${pageType}`}
            columns={columnData}
            data={filteredProjects}
            hasActionableRows={isActionableRow}
            tableInstanceRef={tableInstanceRef}
            isZebra
            state={{
              rowSelection: totalRowSelection,
              columnOrder,
              columnPinning: {right: [ACTION_MENU_COL_ID]},
            }}
            getRowId={row => row.id.toString()}
            enableRowSelection
            massSelectAll={bulkSelectAll}
            setMassSelectAll={onSetMassSelectAll}
            onRowSelectionChange={onRowChange}
            pagination={paginationData}
            showPaginator
          />
          <StatsTooltip />
          <MDUBulkEditSideSheet />
        </>
      ) : (
        <div className={styles.emptyStateContainer}>
          <EmptyState title={emptyStateHeader} text={emptyStateText} iconName="alert" />
        </div>
      )}
    </div>
  );
};

export default React.memo(MDUProjectsTable);
