import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useMutation} from 'react-query';

/* Hooks */
import useApi from 'hooks/useApi';

/* Utils */
import {logger} from 'utils/logger';
import {handleResponseError, showErrorToast} from 'queries/query.utils';

import {MduUseParamsTypes} from '../MDU.types';

type TVerifyUnitNameArgs = {
  projectId?: string | number;
  templateName: string;
};

export const useValidateTemplateNameMutation = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const {projectId: projectIdParam} = useParams<MduUseParamsTypes>();

  return useMutation(
    async ({templateName, projectId}: TVerifyUnitNameArgs) => {
      const response = await api.mdu.validateTemplateName(
        {project_id: projectId || projectIdParam},
        {
          name: templateName,
        }
      );

      handleResponseError(response);

      return response?.data;
    },
    {
      onError: err => {
        logger('Error validating template name: ')(err as Error);
        showErrorToast(dispatch)({errors: `Error validating templat name: ${err}`});
      },
    }
  );
};
