import React from 'react';
import {useStickyContainer} from 'components/Elements/StickyContainer/useStickyContainer';
import PageHeader from 'components/Elements/PageHeader';
import BaseGrid from 'components/Grid';
import DispatchStats from 'features/Dispatch/Pages/UnassignedList/Parts/UnassignedStats';
import UnassignedTableToolbar from 'features/Dispatch/Pages/UnassignedList/Parts/UnassignedTableToolbar';
import UnassignedTable from 'features/Dispatch/Pages/UnassignedList/Parts/UnassignedTable';
import useUnassignedListRouteParams from 'features/Dispatch/hooks/useUnassignedListRouteParams';
import useInvalidateUnassignedListQuery from 'features/Dispatch/hooks/useInvalidateUnassignedListQuery';

const DispatchUnassignedListPage = () => {
  const {StickyContainer} = useStickyContainer({});
  const {pageTitle} = useUnassignedListRouteParams();
  const {invalidateUnassignedListQuery} = useInvalidateUnassignedListQuery();

  const HeaderRightContent = <PageHeader.TextLink onClick={invalidateUnassignedListQuery} iconName="refresh" label="Refresh Data" />;

  return (
    <>
      <PageHeader title={pageTitle} collapsible withGridMargins HeaderRightContent={HeaderRightContent}>
        <DispatchStats />
      </PageHeader>
      <BaseGrid.Fluid>
        <StickyContainer>
          <UnassignedTableToolbar />
        </StickyContainer>
        <UnassignedTable />
      </BaseGrid.Fluid>
    </>
  );
};

export default DispatchUnassignedListPage;
