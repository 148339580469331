import React from 'react';
import {Size} from 'types/base.types';
import {Button, BUTTON_THEMES} from 'ht-styleguide';
import {Modal, ModalProps} from 'components/Elements/Modal';

type ConfirmAddressModalProps = {
  onConfirm: () => void;
  onCancel: () => void;
  address: string;
};

const ConfirmAddressModal: React.FC<ModalProps & ConfirmAddressModalProps> = ({isVisible, hide = () => {}, onConfirm, onCancel, address}) => {
  const closeModal = (e: React.ChangeEvent) => hide(e);
  return (
    <Modal size={Size.M} isVisible={isVisible} hide={closeModal}>
      <h4 className="marginBottom-medium text-center">Verify Address</h4>
      <p className="text-center">The address you entered cannot be located:</p>
      <p className="p1 text-weight-bold text-center marginBottom-medium">{address}</p>
      <div className="flex">
        <Button className="marginRight-small2" onClick={onCancel}>
          &lt;&nbsp;Edit Address
        </Button>
        <Button theme={BUTTON_THEMES.SECONDARY} onClick={onConfirm}>
          Use Address As Entered
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmAddressModal;
