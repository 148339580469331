import React from 'react';
import * as Yup from 'yup';
// Hooks
import {useFormik} from 'formik';
import {useAppDispatch} from 'hooks/useAppDispatch';
// Utils
import {noop} from 'utils/event';
// Types && Ducks
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {ProjectDetails} from 'features/MultiDwellingUnits/MDU.types';
// Components && Styles
import {Modal, ELEMENT_SIZE, Form, Button, BUTTON_THEMES} from 'ht-styleguide';
import TechLeadSelect from 'features/MultiDwellingUnits/Parts/FormFields/LeadTechSelect';
import styles from './modals.styles.scss';

type ModalSetLeadTechProps = {
  isVisible: boolean;
  toggleModal?: BaseAnyFunction;
  onSubmitSuccess?: BaseAnyFunction;
  project: ProjectDetails;
  type: 'NEW' | 'EDIT';
};

export const FORM_FIELDS: {[key: string]: {id: string; label: string}} = {
  LEAD_TECH: {id: 'leadTechId', label: 'New Team Lead'},
};

const leadTechSchema = Yup.object().shape({
  [FORM_FIELDS.LEAD_TECH.id]: Yup.string().required('Required'),
});

export const HEADER_AND_BUTTON_TEXT = {
  NEW: 'Assign Team Lead',
  EDIT: 'Change Team Lead',
};
export const BODY_TEXT = {
  NEW: 'Assign one of our registered technicians to be the main point of contact for the project. The team lead will have team management access within the field service app.',
  EDIT: 'Assign a new team lead to replace the current one. After a new team lead is assigned, the current team lead will be removed from the team.',
};

const ModalSetLeadTech = ({isVisible, toggleModal = noop, onSubmitSuccess = noop, project, type = 'NEW'}: ModalSetLeadTechProps) => {
  const dispatch = useAppDispatch();

  const handleModalClose = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    formik.resetForm();
    toggleModal();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {[FORM_FIELDS.LEAD_TECH.id]: ''},
    validationSchema: leadTechSchema,
    onSubmit: async leadTechParams => {
      await dispatch(
        mduProjectsSlice.actions.updateProject({
          projectId: project.id,
          projectDetails: {project: {...leadTechParams}},
          onSuccess: onSubmitSuccess,
        })
      );
      handleModalClose();
    },
  });

  const headerAndButtonText = HEADER_AND_BUTTON_TEXT[type];
  const bodyText = BODY_TEXT[type];

  const handleSubmit = () => {
    if (!formik.isSubmitting) {
      formik.submitForm();
    }
  };

  return (
    <Modal
      elementSize={ELEMENT_SIZE.MEDIUM}
      isVisible={isVisible}
      hide={handleModalClose}
      header={headerAndButtonText}
      bodyContainerClasses={styles.overflowInitial}
      modalClassName={styles.overflowInitial}
      footerElement3={
        <Button theme={BUTTON_THEMES.PRIMARY} onClick={handleSubmit}>
          {headerAndButtonText}
        </Button>
      }
    >
      <p className="p1 marginBottom-small2">{bodyText}</p>
      <Form>
        <Form.Row>
          <Form.Column lg={12} md={8} sm={4}>
            <TechLeadSelect key={FORM_FIELDS.LEAD_TECH.id} fieldName={FORM_FIELDS.LEAD_TECH.id} formik={formik} label={FORM_FIELDS.LEAD_TECH.label} />
          </Form.Column>
        </Form.Row>
      </Form>
    </Modal>
  );
};

export default ModalSetLeadTech;
