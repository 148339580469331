import {useDispatch} from 'react-redux';
import {useQuery} from 'react-query';
import useApi from 'hooks/useApi';
import APIS from 'global/apis';
import {showErrorToast} from 'queries/query.utils';
import {logger} from 'utils/logger';
import {EntityIdBaseObject} from '../MDU.types';
import {PartnerSearchResult} from '../Parts/FormFields/FormFields.types';
import {MDU_PROJECT_QUERY_KEYS} from '../MDU.query.keys';

export const generatePropertyOwnerKey = (includeOther: boolean) => MDU_PROJECT_QUERY_KEYS.projectField(`propertyOwners-includeOther${includeOther}`);

export const useGetProjectPropertyOwnersQuery = ({includeOther = false}) => {
  const dispatch = useDispatch();
  const api = useApi();

  return useQuery<Array<EntityIdBaseObject>>(
    generatePropertyOwnerKey(includeOther),
    async () => {
      api.toggleLoader(true);
      const response = await APIS.mdu.searchPropertyOwners({includeOther});
      api.toggleLoader(false);
      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Retrieving Property Owners'});
        throw new Error(response.err);
      }
      return response?.data?.property_owners;
    },
    {
      placeholderData: [],
      onError: response => {
        logger('Property Owners: ')(response as Error);
      },
    }
  );
};

export const useGetProjectManagerQuery = () => {
  const dispatch = useDispatch();
  const api = useApi();

  return useQuery<Array<EntityIdBaseObject>>(
    MDU_PROJECT_QUERY_KEYS.projectField('projectManagers'),
    async () => {
      api.toggleLoader(true);
      const response = await APIS.mdu.searchProjectManagers();
      api.toggleLoader(false);
      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Retrieving Project Managers'});
        throw new Error(response.err);
      }
      return response?.data?.project_managers;
    },
    {
      onError: response => {
        logger('Project Managers: ')(response as Error);
      },
    }
  );
};

export const useGetProjectTypesQuery = () => {
  const dispatch = useDispatch();
  const api = useApi();

  return useQuery<Array<EntityIdBaseObject & {slug: string}>>(
    MDU_PROJECT_QUERY_KEYS.projectField('projectTypes'),
    async () => {
      api.toggleLoader(true);
      const response = await APIS.mdu.searchProjectTypes();
      api.toggleLoader(false);
      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Retrieving Project Types'});
        throw new Error(response.err);
      }
      return response?.data?.project_types;
    },
    {
      onError: response => {
        logger('Project Types: ')(response as Error);
      },
    }
  );
};

export const useGetPartnersQuery = () => {
  const dispatch = useDispatch();
  const api = useApi();

  return useQuery<Array<PartnerSearchResult>>(
    MDU_PROJECT_QUERY_KEYS.projectField('partners'),
    async () => {
      api.toggleLoader(true);
      const response = await APIS.partners.getAll({allow_projects: true});
      api.toggleLoader(false);
      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Retrieving Project Partners'});
        throw new Error(response.err);
      }
      return response?.data?.partners;
    },
    {
      onError: response => {
        logger('Project Partners: ')(response as Error);
      },
    }
  );
};
