import React, {ReactText} from 'react';
import {ChildrenProp} from 'types/base.types';
import cn from 'classnames';
import CardBase from './Card.base';

import styles from './styles.scss';

type CardDashboardProps = {
  children?: ChildrenProp;
  primary: [string, ReactText][];
  secondary: [string, ReactText][];
  containerClasses?: string;
  primaryClasses?: string;
  secondaryClasses?: string;
};

/**
 * This is a plain bordered card that shows a "top" row and secondary "row".
 * The top row is a title and a value, the secondary row is a title and a value.
 *
 * The idea is a two column view, but there is no border between the columns.
 *
 * Currently used in LaunchReview.OrderDetails
 *
 * @param children
 * @param primary
 * @param secondary
 * @param containerClasses
 * @param primaryClasses
 * @param secondaryClasses
 * @constructor
 */
const CardDashboard = ({children = null, primary = [], secondary = [], containerClasses = '', primaryClasses = '', secondaryClasses = ''}: CardDashboardProps) => {
  const showPrimaryDisplay = () => {
    if (!primary.length) return null;

    return (
      <div className={cn(styles.cardDetails, primaryClasses)}>
        {primary.map(([title, content]) => (
          <div className="paddingY-small1" key={`${title}`}>
            <div className="caption n700 paddingBottom-tiny1" dangerouslySetInnerHTML={{__html: `${title}`}} />
            <div className="p2 n900 h6 font-weight-medium" dangerouslySetInnerHTML={{__html: `${content}`}} />
          </div>
        ))}
      </div>
    );
  };

  const showSecondaryDisplay = () => {
    if (!secondary.length) return null;

    return (
      <div className={cn(styles.cardDetails, secondaryClasses)}>
        {secondary.map(([title, content]) => (
          <div className="paddingY-small1" key={`${title}`}>
            <div className="caption n700 paddingBottom-tiny1" dangerouslySetInnerHTML={{__html: `${title}`}} />
            <div className="p2 n900" dangerouslySetInnerHTML={{__html: `${content}`}} />
          </div>
        ))}
      </div>
    );
  };

  if (!children && !primary.length && !secondary.length) return null;

  return (
    <CardBase classes={containerClasses}>
      {showPrimaryDisplay()}
      {showSecondaryDisplay()}
      {children}
    </CardBase>
  );
};

export default CardDashboard;
