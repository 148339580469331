import React, {useRef} from 'react';
import cn from 'classnames';
import * as Yup from 'yup';
import get from 'lodash/get';
// Hooks
import {useFormik} from 'formik';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useUploadFileValidation} from 'features/MultiDwellingUnits/MDU.hooks';
// Utils
import {noop} from 'utils/event';
// Queries && Mutations
import {logResponseError, showErrorToast} from 'queries/query.utils';
import {useCreateProjectNoteMutation} from 'features/MultiDwellingUnits/queries/mutation.project.createNote';
import {useGetProjectNoteEntryTypesQuery} from 'features/MultiDwellingUnits/queries/query.project.noteEntryTypes';

// Components && Styles
import FileUploadErrors from 'features/MultiDwellingUnits/Parts/FileUploadErrors';
import {SideSheet, Panel, TextArea, EmptyState, Button, BUTTON_THEMES, SelectField, ELEMENT_SIZE} from 'ht-styleguide';
import FileDisplay from './MDU.SideSheet.AddNote.FileDisplay';
import styles from '../sidesheets.styles.scss';

/* Types */
type SideSheetAddNoteProps = {
  isOpen: boolean;
  hide: () => void;
};

/* Constants */
export const TEST_IDS = {FILE_INPUT: 'file-input', ENTRY_TYPE_SELECT: 'entry-type-select'};
export const FORM_FIELDS: {[key: string]: {label: string; id: string}} = {
  ENTRY_TYPE: {
    label: 'Entry Type*',
    id: 'entryType',
  },
  NOTE: {
    label: 'Note*',
    id: 'note',
  },
};

/* Validation Schema */
const addNoteValidationSchema = Yup.object().shape({
  [FORM_FIELDS.ENTRY_TYPE.id]: Yup.string().required('Required'),
  [FORM_FIELDS.NOTE.id]: Yup.string().required('Required'),
});

export const SideSheetAddNoteEmptyState = ({onUploadButtonClick = noop}) => {
  return (
    <Panel className={cn(styles.panel, styles.withBorder, 'paddingY-large')} noShadow largeBorderRadius>
      <EmptyState iconName="upload" title="No Files Uploaded Yet" text="If the project has any associated files, upload them here." showButtonContainer>
        <Button theme={BUTTON_THEMES.SECONDARY} onClick={onUploadButtonClick}>
          Upload
        </Button>
      </EmptyState>
    </Panel>
  );
};

/*
 * Per Design when a file is uploaded it should be validated before being added to the list of files to be uploaded.
 * Any invalid files should be displayed in the error state; only valid files should show in the DataTable component.
 * Clicking the "Send Note" button should submit the form regardless of the error state.
 */
const SideSheetAddNote = ({isOpen, hide}: SideSheetAddNoteProps) => {
  /* Hooks */
  const dispatch = useAppDispatch();
  const {onFileInputChange, uploadedFiles, setUploadedFiles, resetUploadedFiles, errorStatusInfo, setErrorStatusInfo, resetErrorStatus} = useUploadFileValidation();

  /* Queries and Mutations */
  const {mutateAsync: createProjectNote} = useCreateProjectNoteMutation();
  const {data: entryTypes} = useGetProjectNoteEntryTypesQuery();

  /* Refs */
  const fileInputRef = useRef<HTMLInputElement>(null); // Used to open the file input dialog
  const resetFileInputValue = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  const openFileDialog = () => {
    resetFileInputValue();
    fileInputRef.current?.click();
  };

  /* File Validation */
  const formik = useFormik({
    validationSchema: addNoteValidationSchema,
    enableReinitialize: true,
    initialValues: {
      [FORM_FIELDS.ENTRY_TYPE.id]: '',
      [FORM_FIELDS.NOTE.id]: '',
    },
    validateOnChange: false,
    onSubmit: async values => {
      const {entryType, note} = values;
      const formData = new FormData();
      formData.append('note[entryType]', entryType);
      formData.append('note[content]', note);

      // Add uploaded files to FormData if they exist
      if (uploadedFiles?.length) {
        for (let i = 0; i < uploadedFiles.length; i += 1) {
          formData.append(`note[attachments][][file]`, uploadedFiles[i]);
        }
      }
      try {
        resetErrorStatus();
        const resp = await createProjectNote(formData);
        if (resp?.err) {
          /**
           * Handle the BE validation errors here. If BE file validations fail then the note will not be created.
           * Errors info may show up in different places depending on the error (e.g. too large vs invalid file type)
           */
          const status = get(resp, 'err.response.status', '') || get(resp, 'err.status', '');
          const statusText = get(resp, 'err.response.statusText', '') || get(resp, 'err.statusText', '');
          const fileAttachmentErrors = [get(resp, 'err.errors', null)].filter(Boolean); // If no errors are found it will be an empty array
          setErrorStatusInfo([{status, statusText, fileAttachmentErrors, fileName: ''}]);
          showErrorToast(dispatch)(resp.err);
          throw new Error(`Error creating attachment: ${String(resp.err)}`);
        }
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        closeModal();
      } catch (error) {
        logResponseError('Error Saving Project Note')(error);
      }
    },
  });

  const onSidesheetCtaButtonClick = () => {
    if (!formik.isSubmitting) {
      formik.submitForm();
    }
  };
  const onSelectChange = (selectedOption: {label: string; value: string}) => {
    formik.setFieldValue(FORM_FIELDS.ENTRY_TYPE.id, selectedOption.value);
  };

  /* Event Handlers */
  const removeFile = (fileIndex: number) => {
    if (uploadedFiles) {
      const newFileList = new DataTransfer();
      for (let i = 0; i < uploadedFiles.length; i += 1) {
        if (i !== fileIndex) {
          newFileList.items.add(uploadedFiles[i]);
        }
      }
      setUploadedFiles(newFileList.files);
    }
  };

  const closeModal = () => {
    hide();
    formik.resetForm();
    resetUploadedFiles();
  };
  const onCloseFileUploadError = () => {
    resetErrorStatus();
  };

  return (
    <SideSheet headerText="New Note" ctaButtonText="Send Note" onCtaButtonClick={onSidesheetCtaButtonClick} isOpen={isOpen} hide={closeModal}>
      <div className="paddingBottom-small" />
      <SelectField
        id={FORM_FIELDS.ENTRY_TYPE.id}
        options={entryTypes}
        label={FORM_FIELDS.ENTRY_TYPE.label}
        value={formik.values[FORM_FIELDS.ENTRY_TYPE.id]}
        onChange={onSelectChange}
        error={formik.errors[FORM_FIELDS.ENTRY_TYPE.id]}
        dataTestId={TEST_IDS.ENTRY_TYPE_SELECT}
        elementSize={ELEMENT_SIZE.MEDIUM}
      />
      <div className="paddingBottom-medium" />

      <TextArea
        name={FORM_FIELDS.NOTE.id}
        label={FORM_FIELDS.NOTE.label}
        onChange={formik.handleChange}
        value={formik.values[FORM_FIELDS.NOTE.id]}
        error={formik.errors[FORM_FIELDS.NOTE.id]}
        rows={6}
      />
      <div className="paddingBottom-medium" />

      <p className="h6 n800 text-weight-med marginBottom-small">Upload File or Image</p>
      <p className="p2 n700 marginBottom-small1">Supported file types include: PDF, JPEG, PNG, CSV, and XLS. Maximum file size is 5 MB.</p>
      {!uploadedFiles?.length && errorStatusInfo?.length ? <FileUploadErrors errorStatusInfo={errorStatusInfo} className="marginBottom-huge" onClose={onCloseFileUploadError} /> : null}
      {uploadedFiles?.length ? (
        <FileDisplay onUploadButtonClick={openFileDialog} uploadedFiles={uploadedFiles} onRemoveFile={removeFile}>
          {errorStatusInfo?.length ? <FileUploadErrors errorStatusInfo={errorStatusInfo} className="marginBottom-small" onClose={onCloseFileUploadError} /> : null}
        </FileDisplay>
      ) : (
        <SideSheetAddNoteEmptyState onUploadButtonClick={openFileDialog} />
      )}
      <input type="file" data-testid={TEST_IDS.FILE_INPUT} multiple style={{display: 'none'}} ref={fileInputRef} onChange={onFileInputChange} />
    </SideSheet>
  );
};

export default SideSheetAddNote;
