import {SetStateAction, Dispatch, ReactNode} from 'react';

/* Base any function. */
export type BaseAnyFunction = (...any: any[]) => any;

/* Use this to type when you want to pass down a useState setter function */
export type Dispatcher<S> = Dispatch<SetStateAction<S>>;

/* Simple hash Interface. "key/value" value being the generic */
export interface IHash<T> {
  [key: string]: T;
}

/* Our typical response error from an api call */
export type BaseResponseError = {
  message: string;
  status: number;
  statusText: string;
  error?: string;
  errors?: string[];
};
export type ResponseError =
  | BaseResponseError
  | {
      err?: BaseResponseError;
      data: BaseResponseError;
    };

/* Size enum that should be used to denote element sizing S|M|L|SMALL|MEDIUM|LARGE */
export enum Size {
  S = 'small',
  M = 'medium',
  L = 'large',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

/*
  When accessing responses outside reducers referencing the proper types is not good enough as we also get
  then wrapper by payload object.
*/
export type ResponseWrapper<T> = {
  payload: T;
};

export type SelectOptions<T, E = {}> = {
  label: string;
  value: T | T[];
} & E;

export type SelectArrayOptions<E = {}> = SelectOptions<string | boolean | number, E>[];

export type SelectBaseOption<E = {}> = SelectOptions<string | boolean | number, E>;

export type ChildrenProp = ReactNode | ReactNode[];
