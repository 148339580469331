// @ts-nocheck
import qs from 'qs';
import {authToken} from 'utils/auth';

class ResponseError extends Error {
  response?: Response;
}

interface RequestOptions {
  credentials?: string;
  headers: {[name: string]: string};
}

function parseResponse(response: Response): Promise<string> {
  return response[response.status === 204 ? 'text' : 'json']();
}

function checkStatus(response: Response) {
  if (response && response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new ResponseError(response ? response.statusText : 'Offline');
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           An object containing either "data" or "err"
 */

export default function request(url: string, options = {}) {
  const requestOptions: RequestOptions = {
    credentials: 'omit',
    headers: {
      Accept: 'application/json',
    },
  };

  const tokenAuth = authToken.getAuthToken();

  if (tokenAuth) {
    requestOptions.headers['auth-token'] = tokenAuth; // eslint-disable-line
  }

  Object.assign(requestOptions, options);
  return fetch(url, requestOptions)
    .then(checkStatus)
    .then(parseResponse)
    .then(data => {
      let dataObject = data;
      if (dataObject === '') {
        dataObject = {};
      }

      return {data: dataObject};
    })
    .catch(err => {
      if (!err.response) {
        console.log('offline'); // eslint-disable-line
        return {err, data: {}, offline: true, status: 500};
      }
      return err.response
        .json()
        .then(data => {
          const status = err?.response?.status ?? 500;
          const statusText = err?.response?.statusText ?? 'Generic Server Error';

          return {
            err: {...data, status, statusText},
            data: {...data, status, statusText},
          };
        })
        .catch(() =>
          // parse error
          ({err, data: {}})
        );
    });
}

export function jsonWithBody(url, body, options, method) {
  const tokenAuth = authToken.getAuthToken();
  const requestOptions = {
    method,
    credentials: 'omit',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  if (tokenAuth) {
    requestOptions.headers['auth-token'] = tokenAuth;
  }

  return request(url, {
    // eslint-disable-next-line prefer-object-spread
    body: JSON.stringify({
      authenticity_token: tokenAuth,
      ...body,
    }),
    ...requestOptions,
  });
}

// Use this for uploading files
export const postFormData = (url, body) => {
  const tokenAuth = authToken.getAuthToken();
  const requestOptions = {
    method: 'POST',
    credentials: 'omit',
    headers: {
      Accept: 'application/json',
      'auth-token': tokenAuth,
    },
  };
  return request(url, {
    body,
    ...requestOptions,
  });
};

export function postJson(url: string, body = {}, options = {}) {
  return jsonWithBody(url, body, options, 'POST');
}

export function putJson(url: string, body = {}, options = {}) {
  return jsonWithBody(url, body, options, 'PUT');
}

export function patchJson(url: string, body = {}, options = {}) {
  return jsonWithBody(url, body, options, 'PATCH');
}

export function deleteJson(url: string, body = {}, options = {}) {
  return jsonWithBody(url, body, options, 'DELETE');
}

export function toQueryString(obj) {
  return qs.stringify(obj, {arrayFormat: 'brackets'});
}
