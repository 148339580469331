import React from 'react';
import styles from './qa.styles.scss';

type AddSkuPageBannerProps = {
  notifications?: string[];
  title?: string;
};

const QANotifications: React.FC<AddSkuPageBannerProps> = ({title, notifications = []}) => {
  const isArray = Array.isArray(notifications);
  if (!isArray || (isArray && !notifications.filter(Boolean).length)) return null;

  return (
    <div className={styles.qa_notification}>
      <h6 className="marginBottom-small">{title || 'Note'}</h6>
      <ul>
        {notifications.map((bullet: string) => {
          return (
            <li key={bullet}>
              <p className="p1">{bullet}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default QANotifications;
