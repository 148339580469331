import React, {useState} from 'react';
import classNames from 'classnames';
import {InputField} from 'ht-styleguide';
import {WarningHint, WarningHintIcon} from 'components/Elements/Messages';
import {BaseAnyFunction} from 'types/base.types';
import {QuestionsAPIByQuestion} from 'features/Questions/types';
import styles from './qa.styles.scss';

type QAInput = {
  question: QuestionsAPIByQuestion;
  error?: string;
  value: string;
  onChange: BaseAnyFunction;
};

const PreQuestionInput: React.FC<QAInput> = props => {
  const [hintVisible, setHintVisible] = useState<boolean>(false);
  const {question, value, onChange, error} = props;
  const isHintVisible = !!question.hint;

  const onHintClick = (event: Event) => {
    event.preventDefault();
    setHintVisible(!hintVisible);
  };

  const renderWarningHint = () => {
    const hintIconStyles = classNames(styles.answerHintIcon, styles.answerHintIconInput);
    return (
      <div>
        <WarningHint message={question.hint} visible={hintVisible} />
        <div className={hintIconStyles}>
          <WarningHintIcon onClick={onHintClick} />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className={styles.answer}>
        <InputField label={question.textDirect} type="text" value={value} onChange={onChange} error={error} dataTestId={`qa-input-${question.id}`} />
        {isHintVisible && renderWarningHint()}
      </div>
    </div>
  );
};

export default PreQuestionInput;
