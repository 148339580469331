import {Client} from 'features/Booking/Client/client.types';
import * as CartTS from 'features/Booking/Parts/Cart/cart.types';
import {PLAN_IDS, HELLOTECH_HOME_PLAN_PRODUCT_ID, ONLINE_PLAN_PRODUCT_ID, SMART_HOME_BENEFITS_DETAILS, ONLINE_DETAILS} from 'features/Booking/Parts/Cart/PlanUpsell/planUpsell.constants';
import {PlanIntervals} from 'types/plan.types';
import {roundToNearestTen} from 'utils/math';
import * as PlanUpsellTS from './planUpsell.types';

export const shouldIncludeInHomeBenefit = (plan: PlanUpsellTS.Plan | null) => {
  if (!plan) return true;
  const hasZeroDollarTruckRollFee = plan.truckRollFee <= 0;
  return !hasZeroDollarTruckRollFee;
};

/*
  Given a single subscription plan, let's accurately show the benefits
  based on the plan's included_skus.
*/
export const filterBenefits = ({plan}: {plan: PlanUpsellTS.Plan | null}) => {
  if (!plan) return [];
  const {includedSkus = [], truckRollFee, productDiscount: discount} = plan || {};
  const {inHome, online, checkup, antivirus, password, productDiscount, homeTech} = SMART_HOME_BENEFITS_DETAILS;
  const benefits = [];

  if (shouldIncludeInHomeBenefit(plan)) {
    benefits.push(inHome(truckRollFee));
  }

  if (discount) {
    benefits.push(productDiscount(discount.amount));
  }
  benefits.push(homeTech);
  benefits.push(online);

  includedSkus.forEach(sku => {
    const skuName = sku.name.toLowerCase();

    if (skuName.includes('checkup')) {
      benefits.push(checkup);
    } else if (skuName.includes('malwarebytes')) {
      benefits.push(antivirus);
    } else if (skuName.includes('dashlane')) {
      benefits.push(password);
    }
  });

  return benefits;
};

/*
  Filter a list of planProducts to get HelloTech home plans.
  Grab plan with id of 1 and the first occurrence of a monthly plan.
*/
type HelloTechHomePlans = {
  [PlanIntervals.ANNUAL]: PlanUpsellTS.Plan;
  [PlanIntervals.MONTHLY]: PlanUpsellTS.Plan;
} | null;
const getHelloTechHomePlans = ({planProducts = [], showNewPlans = false}: PlanUpsellTS.PlanProducts): HelloTechHomePlans => {
  if (!Array.isArray(planProducts) || !planProducts.length) return null;
  const hellotechHomePlanProduct = planProducts.find(product => product.id === HELLOTECH_HOME_PLAN_PRODUCT_ID);

  if (hellotechHomePlanProduct) {
    const helloTechHomePlans = hellotechHomePlanProduct.prices.reduce(
      (accu, current) => {
        const {id, planInterval} = current;
        const isMonthlyPlan = id === (showNewPlans ? PLAN_IDS.HELLOTECH_HOME_MONTHLY_NEW : PLAN_IDS.HELLOTECH_HOME_MONTHLY);
        const isYearlyPlan = id === (showNewPlans ? PLAN_IDS.HELLOTECH_HOME_YEARLY_NEW : PLAN_IDS.HELLOTECH_HOME_YEARLY);
        if (isYearlyPlan || isMonthlyPlan) {
          return {...accu, [planInterval]: current};
        }
        return accu;
      },
      {[PlanIntervals.ANNUAL]: {} as PlanUpsellTS.Plan, [PlanIntervals.MONTHLY]: {} as PlanUpsellTS.Plan}
    );
    return helloTechHomePlans;
  }
  return null;
};

/*
  Filter a list of planProducts to get HelloTech online plans.
  Grab plan with id of 12
*/

const getOnlinePlans = ({planProducts = []}: PlanUpsellTS.PlanProducts): HelloTechHomePlans => {
  if (!Array.isArray(planProducts) || !planProducts.length) return null;
  const onlinePlanProduct = planProducts.find(product => product.id === ONLINE_PLAN_PRODUCT_ID);

  if (onlinePlanProduct) {
    const onlinePlan = onlinePlanProduct.prices.reduce(
      (accu, current) => {
        const {id, planInterval} = current;
        const isMonthlyPlan = id === PLAN_IDS.ONLINE_PLAN_ID_MONTHLY;
        const isYearlyPlan = id === PLAN_IDS.ONLINE_PLAN_ID;
        if (isYearlyPlan || isMonthlyPlan) {
          return {...accu, [planInterval]: current};
        }
        return accu;
      },
      {[PlanIntervals.ANNUAL]: {} as PlanUpsellTS.Plan, [PlanIntervals.MONTHLY]: {} as PlanUpsellTS.Plan}
    );
    return onlinePlan;
  }
  return null;
};

/*
  Given a list of plan products, get the HelloTech home plan product and filter it
  for the yearly and monthly subscription plans. With those yearly/monthly
  plans, format in the desired shape for usage.

  The return value
  {
    yearly: {
      id: 1,
      name: 'HelloTech home',
      amount: 149,
      benefits: [],
      ...,
    },
    monthly: {
      id: 5,
      name: 'HelloTech home',
      amount: 14.99,
      benefits: [],
      ...,
    }
  }
*/

export const formatPlansForReducer = ({planProducts, showNewPlans}: PlanUpsellTS.PlanProducts): PlanUpsellTS.FormattedPlans | null => {
  const hellotechHomePlans = getHelloTechHomePlans({planProducts, showNewPlans});
  if (!hellotechHomePlans) return null;
  const {ANNUAL, MONTHLY} = PlanIntervals;
  let formatted = {[ANNUAL]: {} as PlanUpsellTS.FormattedPlan, [MONTHLY]: {} as PlanUpsellTS.FormattedPlan};

  [ANNUAL, MONTHLY].forEach(cycle => {
    formatted = {
      ...formatted,
      [cycle]: {
        ...hellotechHomePlans[cycle],
        benefits: filterBenefits({plan: hellotechHomePlans[cycle]}),
      },
    };
  });

  return {...formatted};
};

export const formatOnlinePlanForReducer = ({planProducts = []}: PlanUpsellTS.PlanProducts) => {
  const {homeTechSpecialist, online} = ONLINE_DETAILS;
  const {ANNUAL, MONTHLY} = PlanIntervals;
  const onlinePlans = getOnlinePlans({planProducts});
  if (!onlinePlans) return null;

  const benefits = [homeTechSpecialist, online];
  let formatted = {};
  [ANNUAL, MONTHLY].forEach(cycle => {
    formatted = {
      ...formatted,
      [cycle]: {
        ...onlinePlans[cycle],
        benefits,
      },
    };
  });
  return {...formatted};
};

/*
  Get a rough estimate of savings between yearly and monthly HelloTech home plans
*/
export const getAnnualSavings = (hellotechHomePlans = {}) => {
  if (Object.values(hellotechHomePlans).filter(v => v).length < 2) return null;
  const {ANNUAL, MONTHLY} = PlanIntervals;
  const {[ANNUAL]: yearlyPlan, [MONTHLY]: monthlyPlan} = hellotechHomePlans as PlanUpsellTS.FormattedPlans;
  const {amount: yearlyAmount} = yearlyPlan!;
  const {amount: monthlyAmount} = monthlyPlan!;

  const savings = ((monthlyAmount * 12 - yearlyAmount) / (monthlyAmount * 12)) * 100;
  return `${roundToNearestTen(savings)}%`;
};

/*
*******************************************************
  Controlling when to show the upsell
*******************************************************
*/
const baseUpsellCheck = (cart: CartTS.CartState, client: Client) => {
  const {plan, items} = cart || {};
  const isPlanHolder = client?.account?.planHolder ?? false;
  return !!items?.length && !plan && !isPlanHolder;
};

export const showMainUpsell = (cart: CartTS.CartState, client: Client) => {
  const {skusIncludedInUpsellPlan} = cart;
  const passedBaseCheck = baseUpsellCheck(cart, client);
  return passedBaseCheck && skusIncludedInUpsellPlan;
};

/*
*******************************************************
  Others
*******************************************************
*/
type FinalPlansFilterType = {
  cart: CartTS.CartState;
  client: Client;
  plansInfo: PlanUpsellTS.FormattedPlans[];
  estimatedCarts: PlanUpsellTS.EstimatedCart[];
};
export const finalPlansFilter = ({cart, client, plansInfo, estimatedCarts}: FinalPlansFilterType) => {
  let filteredPlansInfo = [...plansInfo];
  let filteredEstimatedCarts = [...estimatedCarts];

  if (showMainUpsell(cart, client)) {
    // only show the online plan if cart has remote skus
    const hasRemoteOnlyItemsInCart = cart.remote;

    if (!hasRemoteOnlyItemsInCart) {
      filteredPlansInfo = filteredPlansInfo.filter(plan => plan?.monthly && plan?.monthly.id !== PLAN_IDS.ONLINE_PLAN_ID_MONTHLY);
      filteredEstimatedCarts = filteredEstimatedCarts.filter(cartItem => cartItem?.monthly && cartItem?.monthly.plan?.id !== PLAN_IDS.ONLINE_PLAN_ID_MONTHLY);
    }
  }

  return {filteredPlansInfo, filteredEstimatedCarts};
};
