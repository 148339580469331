import React, {useEffect} from 'react';
import {Route, Routes, useLocation, useParams, Outlet, Navigate} from 'react-router-dom';

// Constants
import {NavigationItems} from 'features/MultiDwellingUnits/Pages/Projects/Projects.constants';

// Ducks & Hooks
import {useDisplayNoProjectsByPage} from 'features/MultiDwellingUnits/Pages/Projects/hooks';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import uiSlice from 'features/ui/ui.ducks';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useSelector} from 'hooks/useAppSelector';

// Types
import {NavPageTypes, NavTypes, MduUseParamsTypes} from 'features/MultiDwellingUnits/MDU.types';
import {ISidebarEntryGroup} from 'components/Layouts/AdminHub/Sidebar/Sidebar.types';

// Components
import BaseGrid from 'components/Grid';
import Sidebar from 'components/Layouts/AdminHub/Sidebar';
import TopNav from 'features/MultiDwellingUnits/Parts/TopNav';
import AdminHubLayout from 'components/Layouts/AdminHub/AdminHub.layout';
import GenericPage from 'features/MultiDwellingUnits/Pages/Projects/Projects.Generic';
import AllStatusPage from 'features/MultiDwellingUnits/Pages/Projects/Projects.AllStatus';
import {SearchInput, SearchResults} from 'features/MultiDwellingUnits/Parts/Search';
import MDUDeleteConfirmModal from 'features/MultiDwellingUnits/Parts/Modals/MDU.Modal.Delete';
import MDUApproveProjectConfirmModal from 'features/MultiDwellingUnits/Parts/Modals/MDU.Modal.Approve';
import MDUPauseProjectModal from 'features/MultiDwellingUnits/Parts/Modals/MDU.Modal.PauseProject';
import MDUResumeProjectModal from 'features/MultiDwellingUnits/Parts/Modals/MDU.Modal.ResumeProject';

import {mduProjectsPagePath} from 'global/paths';

/**
 * *******************************************************
 * BASE ROUTE DISPLAY.
 * *******************************************************
 */
const MDUProjectsRoutesLayout = () => {
  /* Redux State */
  const projectSearchIsVisible = useSelector(uiSlice.selectors.getSearchState).projects.visible;

  /* Hooks */
  const dispatch = useAppDispatch();
  const location = useLocation();

  /* Toggle Search Input */
  const setIsSearchActive = () => dispatch(uiSlice.actions.setSearchVisibility({search: 'projects'}));

  /* Param manipulation */
  const {projectsPage} = useParams<MduUseParamsTypes>();
  /* Hook to display the no projects display */
  const {EmptyStateNoProjects, noResultsDisplay} = useDisplayNoProjectsByPage(projectsPage!);

  /* Gather up the menus for all and own */
  const menusAllOwn = [NavTypes.OWN, NavTypes.ALL].reduce(
    (naviGroups: {menu: ISidebarEntryGroup[]}, pt: NavTypes) => {
      const {menu} = NavigationItems({projectsType: pt, projectsPage: projectsPage!});

      naviGroups.menu = naviGroups.menu.concat(menu).sort((a, b) => (a.rank || 0) - (b.rank || 0));
      return naviGroups;
    },
    {menu: []} as {menu: ISidebarEntryGroup[]}
  );

  useEffect(() => {
    dispatch(mduProjectsSlice.actions.setParentLocation({location: location.pathname}));
  }, [projectsPage, location.pathname, dispatch]);

  /* If we move across stages, we should close search if its open */
  useEffect(() => {
    dispatch(uiSlice.actions.setSearchVisibility({search: 'projects', visible: false}));
  }, [dispatch, location]);

  return (
    <AdminHubLayout>
      <TopNav />
      {projectSearchIsVisible ? (
        <>
          <SearchInput onSearchClose={setIsSearchActive} />
          <SearchResults />
        </>
      ) : (
        <AdminHubLayout.ContentTwoColumnContainer>
          <AdminHubLayout.ContentLeftContainer>
            <Sidebar {...menusAllOwn} headerProps={{title: 'Projects'}} useAccordion isCollapsible />
          </AdminHubLayout.ContentLeftContainer>
          <AdminHubLayout.ContentRightContainer>
            <BaseGrid.Fluid>{noResultsDisplay ? <EmptyStateNoProjects /> : <Outlet />}</BaseGrid.Fluid>
          </AdminHubLayout.ContentRightContainer>
        </AdminHubLayout.ContentTwoColumnContainer>
      )}
      <MDUDeleteConfirmModal />
      <MDUApproveProjectConfirmModal />
      <MDUPauseProjectModal />
      <MDUResumeProjectModal />
    </AdminHubLayout>
  );
};

/**
 * Based on `projectsPage` value, render the correct page.
 * `/mdu/projects/:projectsTypes/:projectsPage`
 */
const GetProjectsPage = () => {
  const {projectsPage} = useParams<MduUseParamsTypes>();
  switch (true) {
    case projectsPage === NavPageTypes.ALL_STATUSES:
      return <AllStatusPage />;
    default:
      return <GenericPage />;
  }
};

/**
 * Ensure that only known `projectsTypes` and `projectPage` values are allowed so we can
 * redirect to the correct page. In migrating react-router-dom v6, regex matching is not
 * supported so we have to do this manually. Otherwise, invalid `projectPage` and `projectsType`
 * values will be passed to children comopnents, which will cause errors as they rely on
 * valid values only.
 */
const BaseGuard = () => {
  const {projectsPage, projectsType} = useParams<MduUseParamsTypes>();
  if (projectsType && projectsPage) {
    if (Object.values(NavPageTypes).includes(projectsPage)) {
      return <Outlet />;
    }
  }
  return <Navigate to={`${mduProjectsPagePath(NavTypes.ALL, NavPageTypes.ALL_STATUSES)}`} replace />;
};

const MDUProjectsRoutesStructure = () => (
  <Routes>
    <Route element={<BaseGuard />}>
      <Route element={<MDUProjectsRoutesLayout />}>
        <Route path=":projectsPage" element={<GetProjectsPage />} />
      </Route>
    </Route>
  </Routes>
);

export default MDUProjectsRoutesStructure;
