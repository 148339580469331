import React, {useRef, useState, RefObject} from 'react';
import {Button, BUTTON_THEMES} from 'ht-styleguide';
import {MenuListItem, MenuPanel, MenuUnorderedList} from 'components/Elements/Menu';
import {useExcludeLink} from 'features/Splitio/splitio.hooks';
import CreateCreditSideSheet from 'features/StoreCredits/Parts/CreateCreditSideSheet';
import uiSlice from 'features/ui/ui.ducks';
import {TModalVisibilityPayload} from 'features/ui/ui.types';
import useOutsideClick from 'hooks/useOutsideClick';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {noop, stopImmediatePropagation} from 'utils/event';
import {TEST_IDS} from '../adminHub.header.constants';
import styles from '../adminHub.header.styles.scss';
import CreateButtonIssue, {useCreateButtonIssue} from './CreateButton.Issue';
import CreateButtonProject from './CreateButton.Project';

const CreateButton = () => {
  /* Local State */
  const [menu, setMenu] = useState(false);

  /* Hooks */
  const dispatch = useAppDispatch();
  const menuRef: RefObject<HTMLDivElement> = useRef(null);
  const {isExcluded} = useExcludeLink('issues');
  const {openSideSheet: openIssuesSideSheet} = useCreateButtonIssue();

  /* Methods */
  const handleClickOutside = () => setMenu(false);
  const onHandleSetMenu = () => setMenu(!menu);
  const toggleModal = (modal: TModalVisibilityPayload['modal']) => dispatch(uiSlice.actions.setModalVisibility({modal}));

  /* Close dropdown menu when clicked outside */
  useOutsideClick(menuRef, handleClickOutside);

  return (
    <>
      <div ref={menuRef} className="position relative marginRight-small1">
        <Button small theme={BUTTON_THEMES.SECONDARY} onClick={onHandleSetMenu} icon="v2-plus-icon">
          Create
        </Button>
        {menu && (
          <MenuPanel className={styles.iconItemMenuPanel}>
            <MenuUnorderedList>
              <MenuListItem
                dataTestId={TEST_IDS.NEW_PROJECT}
                onClick={e => {
                  stopImmediatePropagation(noop)(e);
                  toggleModal('createProject');
                }}
              >
                New Project
              </MenuListItem>
              {isExcluded() ? null : (
                <MenuListItem
                  dataTestId={TEST_IDS.NEW_ISSUE}
                  onClick={e => {
                    stopImmediatePropagation(noop)(e);
                    openIssuesSideSheet();
                  }}
                >
                  New Issue
                </MenuListItem>
              )}
            </MenuUnorderedList>
          </MenuPanel>
        )}
      </div>
      <CreateButtonIssue />
      <CreateButtonProject />
      <CreateCreditSideSheet />
    </>
  );
};

export default CreateButton;
