import * as yup from 'yup';
import {TaskFieldNames, TaskTypes, ReferenceTypes, TUpsertTaskReference} from 'features/Products/types/taskGroups.types';
import {TTaskChecklistFormValues, TaskChecklistCustomFieldNames, ReferenceFieldNames} from '../taskChecklist.types';
import {MINIMUM_OPTIONS_COUNT, SHARED_FIELD_ERRORS} from './main';

export const DEFAULT_INITIAL_VALUES: TTaskChecklistFormValues = {
  [TaskFieldNames.CreatedAt]: null,
  [TaskFieldNames.ID]: null,
  [TaskFieldNames.IncludeOtherOption]: false,
  [TaskFieldNames.MultiplePhotos]: false,
  [TaskFieldNames.Options]: [],
  [TaskFieldNames.Instructions]: '',
  [TaskFieldNames.Reference]: null,
  [TaskFieldNames.RequiredTask]: true,
  [TaskFieldNames.ShowOptionalNote]: false,
  [TaskFieldNames.ShowOptionalPhoto]: false,
  [TaskFieldNames.TaskType]: TaskTypes.Confirm,
  [TaskFieldNames.Title]: '',
  [TaskFieldNames.UpdatedAt]: null,
  [TaskChecklistCustomFieldNames.HasReference]: false,
};

export const DEFAULT_REFERENCE_OBJ: TUpsertTaskReference = {
  reference_type: ReferenceTypes.FromSkill,
  skill_guide_id: null,
  title: '',
  url: '',
  attachments: [],
};

/**
 * This schema is a near mirror of what is expected from the BE. Check the mutation for adding/editing a
 * checklist for more details. `TaskChecklistCustomFieldNames` is a custom field to help with
 * validation and should be removed before sending to the BE. Be sure that this aligns with `TTasksChecklistFormValues`.
 *
 * `options`
 *   - Ensure only two options are sent for binary tasks
 *   - For MultiSelect and SingleSelect tasks, "other" option counts towards the minimum count,
 *     so modify the minimum count accordingly in the schema.
 * `reference`
 *   - Depending on the reference type, ensure the required fields are present
 */
export const YUP_SCHEMA = yup.object({
  [TaskFieldNames.ID]: yup.number().nullable().notRequired(),
  [TaskFieldNames.Title]: yup.string().required('Title is required'),
  [TaskFieldNames.Instructions]: yup.string().required('Instructions are required'),
  [TaskFieldNames.MultiplePhotos]: yup.boolean().required('Photo limit selection is required'),
  [TaskFieldNames.Options]: yup.array().when([TaskFieldNames.TaskType, TaskFieldNames.IncludeOtherOption], (tasktype, includeOtherOption, schema) => {
    if ([TaskTypes.MultiSelect, TaskTypes.SingleSelect, TaskTypes.Binary].includes(tasktype)) {
      const minimumCount = [TaskTypes.MultiSelect, TaskTypes.SingleSelect].includes(tasktype) && includeOtherOption ? MINIMUM_OPTIONS_COUNT - 1 : MINIMUM_OPTIONS_COUNT;
      return schema
        .of(
          yup.object({
            description: yup.string().required(SHARED_FIELD_ERRORS.descriptionCannotBeEmpty),
          })
        )
        .min(minimumCount, SHARED_FIELD_ERRORS.min2Options);
    }
    return schema.nullable();
  }),
  [TaskFieldNames.Reference]: yup.object().when(TaskChecklistCustomFieldNames.HasReference, {
    is: true,
    then: yup.object().shape({
      [ReferenceFieldNames.ReferenceType]: yup.string().required(),
      [ReferenceFieldNames.SkillGuideId]: yup.number().when(ReferenceFieldNames.ReferenceType, {
        is: ReferenceTypes.FromSkill,
        then: yup.number().nullable().required('Reference is required'),
        otherwise: yup.number().nullable(),
      }),
      [ReferenceFieldNames.Title]: yup.string().when(ReferenceFieldNames.ReferenceType, {
        is: referenceType => [ReferenceTypes.Link, ReferenceTypes.File].includes(referenceType),
        then: yup.string().nullable().required('Title is required'),
        otherwise: yup.string().nullable(),
      }),
      [ReferenceFieldNames.Url]: yup.string().when(ReferenceFieldNames.ReferenceType, {
        is: ReferenceTypes.Link,
        then: yup
          .string()
          .nullable()
          .matches(/^(http|https):\/\//, 'URL must start with http:// or https://')
          .url('URL must be a valid url')
          .required('URL is required'),
        otherwise: yup.string().nullable(),
      }),
      [ReferenceFieldNames.Attachments]: yup.array().when(ReferenceFieldNames.ReferenceType, {
        is: ReferenceTypes.File,
        then: yup.array().min(1, 'Attachment is required'),
        otherwise: yup.array().nullable(),
      }),
    }),
    otherwise: yup.object().nullable(),
  }),
  [TaskFieldNames.TaskType]: yup.string().required(),
  [TaskFieldNames.RequiredTask]: yup.boolean().required('Required task is required'),
  [TaskFieldNames.ShowOptionalNote]: yup.boolean(),
  [TaskFieldNames.ShowOptionalPhoto]: yup.boolean(),
  [TaskChecklistCustomFieldNames.HasReference]: yup.boolean(),
});
