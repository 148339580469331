import {useQuery, useQueries, UseQueryOptions, UseQueryResult} from 'react-query';
import useApi from 'hooks/useApi';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {showErrorToast, logResponseError} from 'queries/query.utils';
import ORDER_TASK_CHECKLIST_QUERY_KEYS from './orderTaskChecklist.query.keys';
import {TOrderTaskChecklist} from '../types/orderTaskChecklist.types';

type TUseGetTaskChecklistByServiceQueryResponse = WithErrorResponse<TOrderTaskChecklist>;

type TUseGetTaskChecklistByServiceQueryParams = {
  orderId: number;
  serviceId: number | undefined | null;
  useLoader?: boolean;
  queryOpts?: Parameters<typeof useQuery<TUseGetTaskChecklistByServiceQueryResponse['data'] | null>>['2'];
};

/**
 * Fetches the task checklist for a single service Id for the given order
 */
export const useGetTaskChecklistByService = ({orderId, serviceId, useLoader, queryOpts}: TUseGetTaskChecklistByServiceQueryParams) => {
  const api = useApi();
  const dispatch = useAppDispatch();

  return useQuery<TUseGetTaskChecklistByServiceQueryResponse['data'] | null>(
    ORDER_TASK_CHECKLIST_QUERY_KEYS.getOrderTaskChecklist(serviceId),
    async () => {
      if (!orderId || !serviceId) {
        return null;
      }
      if (useLoader) {
        api.toggleLoader(true);
      }
      const response: TUseGetTaskChecklistByServiceQueryResponse = await api.services.getTaskChecklist({id: orderId, serviceId});
      if (useLoader) {
        api.toggleLoader(false);
      }
      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error retrieving order task checklist'});
        logResponseError('Order task checklist')(response.err);
        throw new Error('Error retrieving order task checklist');
      }
      return response?.data;
    },
    queryOpts
  );
};

type TUseQueriesChecklistResponse = UseQueryOptions<TUseGetTaskChecklistByServiceQueryResponse['data'] | null>[];

type TUseGetTaskChecklistByServicesQueryParams = {
  orderId: number;
  serviceIds: Array<number>;
  useLoader?: boolean;
  queryOpts?: Parameters<typeof useQuery<TUseGetTaskChecklistByServiceQueryResponse['data'] | null>>['2'];
};

/**
 * Fetch the task checklist for multiple services for the given order
 */
const useGetTaskChecklistByServices = ({orderId, serviceIds = [], useLoader, queryOpts}: TUseGetTaskChecklistByServicesQueryParams) => {
  const api = useApi();
  const dispatch = useAppDispatch();

  const checklistQueries = useQueries<TUseQueriesChecklistResponse>(
    serviceIds.map((serviceId: number) => {
      return {
        queryKey: ORDER_TASK_CHECKLIST_QUERY_KEYS.getOrderTaskChecklist(serviceId),
        queryFn: async () => {
          if (!orderId || !serviceId) {
            return null;
          }
          if (useLoader) {
            api.toggleLoader(true);
          }
          const response: TUseGetTaskChecklistByServiceQueryResponse = await api.services.getTaskChecklist({id: orderId, serviceId});
          if (useLoader) {
            api.toggleLoader(false);
          }
          if (response.err) {
            showErrorToast(dispatch)({errors: 'Error retrieving order task checklist'});
            logResponseError('Order task checklist')(response.err);
            throw new Error('Error retrieving order task checklist');
          }
          return response?.data;
        },
        queryOpts,
      };
    })
  );

  /**
   * Return an object with serviceId as key and the query result as value.
   */
  return serviceIds.reduce((acc, serviceId, index) => {
    acc[serviceId] = checklistQueries[index];
    return acc;
  }, {} as Record<number, UseQueryResult<TUseGetTaskChecklistByServiceQueryResponse['data'] | null>>);
};

export default useGetTaskChecklistByServices;
