import {useQuery} from 'react-query';
import {useDispatch} from 'react-redux';
import qs from 'qs';
import omit from 'lodash/omit';
import useApi from 'hooks/useApi';
import APIS from 'global/apis';
import request from 'global/apis/request';
import {logger, LoggerErrorType} from 'utils/logger';
import {showErrorToast} from 'utils/query';
import {useUserCurrentQuery} from 'queries/User/query.user.current';

import {ISSUES_QUERY_KEYS} from './Issues.query.keys';
import {ISSUE_FIELDS_OPERATION_TYPE, operationMapBy, Display} from '../Issues.constants';
import * as Types from '../issues.types';

export const useGetIssueTypesQuery = ({search}: {search?: string} = {}) => {
  const dispatch = useDispatch();
  const api = useApi();

  return useQuery<Types.TGetIssueTypesDataResponse>(
    ISSUES_QUERY_KEYS.types(search),
    async () => {
      api.toggleLoader(true);
      const params = search ? {search} : undefined;
      const response = await APIS.issues.getTypes(params);
      api.toggleLoader(false);
      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Retrieving Issue Types'});
        throw new Error(response.err);
      }
      return response?.data?.types;
    },
    {
      onError: response => {
        logger('Issue Types: ')(response as Error);
      },
    }
  );
};

/**
 * @description This returns the templates/entity types for the issue form
 * Currently, only have "Project". No passed args returns all. Good for top level create issue form.
 *
 * @param search: Key value pair of search params, see:
 * https://hellotech.atlassian.net/wiki/spaces/ENGINEERIN/pages/1670905857/API+Docs+with+dynamic+fields+-+Issue+Ticketing+System#Templates
 */
export const useGetIssueTemplatesQuery = (search?: {[key: string]: any}) => {
  const dispatch = useDispatch();
  const api = useApi();

  return useQuery<Types.TTemplateType[]>(
    ISSUES_QUERY_KEYS.templates(search),
    async () => {
      api.toggleLoader(true);
      const params = search || null;
      const response = await APIS.issues.getTemplates(params);
      api.toggleLoader(false);
      if (response.err) {
        showErrorToast(dispatch)({errors: `Error Retrieving Issue Template Types ${JSON.stringify(search)}`});
        throw new Error(response.err);
      }
      return response?.data?.templates;
    },
    {
      onError: response => {
        logger('Issue Types: ')(response as Error);
      },
    }
  );
};

/**
 * @description This returns the fields for a specific template/entity type, so we know what fields to render in the form.
 * @param template_id
 * @param excludeFields: Exclude fields from the display form.
 * @param operationType: The operation type of the form, create or edit. This is used to determine if we should include
 */
type TGetIssueTemplateFieldsQuery = {
  operationType: keyof typeof ISSUE_FIELDS_OPERATION_TYPE;
  excludeFields?: string[];
} & (
  | {
      template_id: number | string | undefined;
      ticket_id?: never;
    }
  | {
      ticket_id: number;
      template_id?: never;
    }
);

export const useGetIssueTemplateFieldsQuery = ({template_id, excludeFields = [], operationType}: TGetIssueTemplateFieldsQuery) => {
  const dispatch = useDispatch();
  const {data: user} = useUserCurrentQuery();
  const api = useApi();

  return useQuery<Types.TField[]>(
    ISSUES_QUERY_KEYS.templateFields(template_id as number),
    async () => {
      api.toggleLoader(true);
      const response = await APIS.issues.getTemplateFields({template_id});
      api.toggleLoader(false);
      if (response.err) {
        showErrorToast(dispatch)({errors: `Error Retrieving Issue Template Fields ${JSON.stringify(template_id)}`});
        throw new Error(response.err);
      }

      /* Return only the visible fields */
      const validDisplayTypes: string[] = operationMapBy[operationType][Display];
      const fields = response?.data?.fields.filter((field: Types.TField) => {
        return validDisplayTypes.includes(field.display as string) && !excludeFields.includes(field.name);
      });

      /* Design wants us to seed/default certain field values, thus removing the "dynamic" aspect. Oh well :-( */
      const seededFields = fields.map((field: Types.TField) => {
        /* Don't overwrite an existing default_values */
        if (field.default_values) return field;
        /* If we have a "create" intent. Seed Requester/Assignee with creator of issuee */
        if (operationType === ISSUE_FIELDS_OPERATION_TYPE.create) {
          if (['assigned_to_id', 'reported_by_id'].includes(field.name)) {
            return {...field, default_values: [{value: user?.id || 0}]};
          }
        }

        return field;
      });

      return seededFields;
    },
    {
      enabled: !!(template_id && user?.id),
      onError: response => {
        logger('Issue Types: ')(response as Error);
      },
    }
  );
};

/**
 * @description This returns the ticket issue by id, single return
 * @param search
 */
export const useGetIssueByIdQuery = (search: {ticket_id: number}) => {
  const dispatch = useDispatch();
  const api = useApi();

  return useQuery<Types.TIssueTicket>(
    ISSUES_QUERY_KEYS.getTicket(search?.ticket_id),
    async () => {
      api.toggleLoader(true);
      const params = search || undefined;
      const response = await APIS.issues.getTicket(params);
      api.toggleLoader(false);
      if (response.err) {
        showErrorToast(dispatch)({errors: `Error Retrieving Issue Ticket by Id: ${search.ticket_id}`});
        throw new Error(response.err);
      }
      return response?.data?.ticket;
    },
    {
      enabled: !!search?.ticket_id,
      onError: response => {
        logger('Issue Types: ')(response as Error);
      },
    }
  );
};

/**
 * @description This returns the ticket issue by id, single return with all fields and values.
 *              This saves us having to merge the fields and values together from two seperate queries.
 * @param search
 */
export const useGetIssueFieldsWithValuesQuery = ({ticket_id, excludeFields = [], operationType}: TGetIssueTemplateFieldsQuery) => {
  const dispatch = useDispatch();
  const api = useApi();

  return useQuery<Types.TField[]>(
    ISSUES_QUERY_KEYS.getTicketWithValues(ticket_id!),
    async () => {
      api.toggleLoader(true);
      const params = ticket_id ? {ticket_id} : undefined;
      const response = await APIS.issues.getTicketFieldsWithValues(params);
      api.toggleLoader(false);
      if (response.err) {
        showErrorToast(dispatch)({errors: `Error Retrieving Issue Ticket by Id: ${ticket_id}`});
        throw new Error(response.err);
      }

      /* Return only the visible fields */
      const validDisplayTypes: string[] = operationMapBy[operationType][Display];
      const fields = response?.data?.fields.filter((field: Types.TField) => {
        /* Normalize out for readability */
        const display = validDisplayTypes.includes(field.display as string);
        const exclude = !excludeFields.includes(field.name);

        return display && exclude;
      });

      return fields;
    },
    {
      enabled: !!ticket_id,
      onError: response => {
        logger('Issue Types: ')(response as Error);
      },
    }
  );
};

/**
 * Unfortunatly, we need to know about statuses about the dynamic intent of the fields because design needs to have a
 * different layout for the status. So,of course, we need to now have to explicitly know about statuses in the code.

 * @description This returns the available statuses for the ticket.
 * @param search
 */
export const useGetStatusesQuery = () => {
  const dispatch = useDispatch();
  const api = useApi();

  return useQuery<Types.TIssueStatus[]>(
    ISSUES_QUERY_KEYS.getStatuses(),
    async () => {
      api.toggleLoader(true);
      const response = await APIS.issues.getStatuses();
      api.toggleLoader(false);
      if (response.err) {
        showErrorToast(dispatch)({errors: `Error Retrieving statuses`});
        throw new Error(response.err);
      }
      return response?.data?.statuses;
    },
    {
      onError: response => {
        logger('Issues Statuses: ')(response as Error);
      },
    }
  );
};

/**
 * @description This returns the fetch response for a dynamic fields.
 * @param field
 */
type TUseDynamicIssueTicketFields = {field: Types.TField | Types.TFieldForTable; operationType?: keyof typeof ISSUE_FIELDS_OPERATION_TYPE; id: number | string};
export const useDynamicIssueTicketFields = ({field, operationType, id}: TUseDynamicIssueTicketFields) => {
  return useQuery(
    ISSUES_QUERY_KEYS.dynamicIssueTicketFields(field.name, id),
    async () => {
      /*
        If we are in an update mode and the field is read_only AND it is tmeplate_id then just return the value.
        Why? Whenever a new template is created, it misalignes the template_id and the template_name. So, we just
        need to return the previous saved value, since its uneditable. Its just a display.
      */
      if (operationType === ISSUE_FIELDS_OPERATION_TYPE.update && field.name === 'template_id' && field.read_only === ISSUE_FIELDS_OPERATION_TYPE.update) return field.value;

      const params = qs.stringify(field.fetch?.params, {arrayFormat: 'brackets', encode: false});
      /* BE is returning a non-secure http. They know. Can't wait for the fix */
      const host = field.fetch?.url?.replace('http:', 'https:');
      /* These are dynamic apis, so we don't know about them. Thus borrow from our request method */
      const res = await request(`${host}?${params}`, {});

      try {
        /* Remove the fields that we do not need and flatten the last field */
        const response = omit(res.data, ['status', 'pagination', 'stats']);
        /* Remove the named key and use "data" */
        return Object.keys(response).reduce((acc, curr) => {
          /* If the value is an object.  */
          return response[curr].map((b: {name: string; id: string}) => ({label: b.name, value: b.id}));
        }, {});
      } catch (error) {
        logger('Issue Field Generation')(error as LoggerErrorType);
        throw new Error(error as string);
      }
    },
    {
      enabled: !!field.fetch,
      onError: response => {
        logger('Issue Field Generation')(response as Error);
      },
    }
  );
};

/**
 * @description This returns the available entity types for the ticket.
 */
export const useGetEntityTypesQuery = () => {
  const dispatch = useDispatch();
  const api = useApi();

  return useQuery<Types.TGetEntityTypesDataResponseGeneric[]>(
    ISSUES_QUERY_KEYS.getEntityTypes(),
    async () => {
      api.toggleLoader(true);
      const response = await APIS.issues.getEntityTypes();
      api.toggleLoader(false);
      if (response.err) {
        showErrorToast(dispatch)({errors: `Error retrieving entity types`});
        throw new Error(response.err);
      }

      return response?.data?.entity_types;
    },
    {
      onError: response => {
        logger('Entity Types: ')(response as Error);
      },
    }
  );
};
