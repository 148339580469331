import React, {useState} from 'react';
import classNames from 'classnames';
import {TextArea} from 'ht-styleguide';
import {WarningHint, WarningHintIcon} from 'components/Elements/Messages';
import {BaseAnyFunction} from 'types/base.types';
import {QuestionsAPIByQuestion} from 'features/Questions/types';
import styles from './qa.styles.scss';

type QATextArea = {
  question: QuestionsAPIByQuestion;
  error?: string;
  value: string;
  onChange: BaseAnyFunction;
};

const PreQuestionTextArea: React.FC<QATextArea> = props => {
  const [hintVisible, setHintVisible] = useState<boolean>(false);
  const {question, value, onChange, error} = props;
  const isHintVisible = !!question.hint;

  const onHintClick = (event: Event) => {
    event.preventDefault();
    setHintVisible(!hintVisible);
  };

  const renderWarningHint = () => {
    const hintIconStyles = classNames(styles.answerHintIcon, styles.answerHintIconInput);
    return (
      <div>
        <WarningHint message={question.hint} visible={hintVisible} />
        <div className={hintIconStyles}>
          <WarningHintIcon onClick={onHintClick} />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.answer}>
      <TextArea label={question.textDirect} value={value} onChange={onChange} error={error} />
      {isHintVisible && renderWarningHint()}
    </div>
  );
};

export default PreQuestionTextArea;
