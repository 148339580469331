import {useEffect, useState} from 'react';

type TUnsavedChangesWarningHook = {
  isUnsavedChangesModalVisible: boolean;
  onUnsavedChangesConfirmClick: () => void;
  onUnsavedChangesCancelClick: () => void;
  handleCloseSideSheet: () => void;
};

export const useUnsavedChangesWarning = (isDirty: boolean, closeSideSheet: () => void): TUnsavedChangesWarningHook => {
  const [isUnsavedChangesModalVisible, setIsUnsavedChangesWarningVisible] = useState(false);

  const onUnsavedChangesCancelClick = () => {
    setIsUnsavedChangesWarningVisible(false);
  };

  const onUnsavedChangesConfirmClick = () => {
    setIsUnsavedChangesWarningVisible(false);
    closeSideSheet();
  };

  const handleCloseSideSheet = () => {
    if (isDirty) {
      setIsUnsavedChangesWarningVisible(true);
    } else {
      closeSideSheet();
    }
  };

  /**
   * The popstate event listener in React is triggered when the browser's history navigation changes.
   * Allows to respond to back and forward button clicks.
   *
   * Set up an event listener to detect when the user navigates in the browser.
   * 1. Activate the handlePopState function, which sets the ssUnsavedChangesWarningVisible state to true.
   *    - This ensures the modal opens when the user interacts with the browser's navigation (e.g., clicks the back button).
   * 2. Return a function that removes the event listener when the component unmounts.
   *
   * Note: We don't use block here becase this is a param url change.
   */
  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      event.preventDefault();
      setIsUnsavedChangesWarningVisible(true);
    };
    window.addEventListener('popstate', handlePopState);
    window.history.pushState({modalOpened: false}, '');

    return () => {
      window.removeEventListener('popstate', handlePopState);
      setIsUnsavedChangesWarningVisible(true);
    };
  }, []);

  /*
    Hook to handle the browser's beforeunload event, which is fired when the user tries to refresh or close the page.
    This is necessary because the history block function doesn't handle the browser's back/forward buttons, refreshes,
    or closing the tab. It's important to note that the beforeunload event will show a native browser confirmation
    instead of a custom modal. This is due to browser restrictions around the beforeunload event to prevent
    'navigational hostage' situations. Custom text in the confirmation is also no longer supported in modern browsers
    for the same reason. As a result, we can only choose whether to show this confirmation or not by calling
    event.preventDefault() and setting event.returnValue.
  */
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = ' ';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  return {
    isUnsavedChangesModalVisible,
    onUnsavedChangesConfirmClick,
    onUnsavedChangesCancelClick,
    handleCloseSideSheet,
  };
};

/**
 * Hook that manages the list of files that have been staged for upload.
 */
export const useStagedFiles = (initialFiles = [] as File[]) => {
  const [stagedFiles, setStagedFiles] = useState(initialFiles);

  const addToStagedFiles = (newFiles: File[]) => {
    setStagedFiles(prevFiles => [...prevFiles, ...newFiles]);
  };

  /** Delete a single file */
  const deleteStagedFile = (index: number) => {
    setStagedFiles(prevFiles => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  /** List of files formatted for the ThumbnailDisplay component */
  const formattedStagedFiles = stagedFiles.map((file, index) => {
    return {
      name: file.name,
      file_type: file.type,
      url: URL.createObjectURL(file),
      showDownloadButton: false,
      showDeleteButton: true,
      onDeleteClick: () => {
        deleteStagedFile(index);
      },
    };
  });

  useEffect(() => {
    return () => {
      // revoke the blob URLs when the component using the hook unmounts
      formattedStagedFiles.forEach(file => URL.revokeObjectURL(file.url));
    };
  }, [formattedStagedFiles]);

  return {stagedFiles, addToStagedFiles, deleteStagedFile, formattedStagedFiles};
};
