import {DragEvent, useCallback, useState} from 'react';
import {DropEvent, FileRejection} from 'react-dropzone';
import {noop} from 'utils/event';
import {TUseHTDropzoneProps} from './HTDropzone.types';

/**
 * useHtDropzone is a custom hook that provides callbacks and state that we use in the HTDropzone component.
 *
 * @param {function} onDrop - Function to execute when files are dropped. Default is a noop function.
 * @param {function} onDragEnter - Function to execute when a draggable item enters the dropzone. Default is a noop function.
 * @param {function} onDragLeave - Function to execute when a draggable item leaves the dropzone. Default is a noop function.
 *
 * @returns {Object} The hovering state and the three event handlers:
 * - isHovering: Boolean indicating whether a draggable item is currently over the dropzone.
 * - handleOnDrop: Function to execute when files are dropped.
 * - handleOnDragEnter: Function to execute when a draggable item enters the dropzone.
 * - handleOnDragLeave: Function to execute when a draggable item leaves the dropzone.
 */
export const useHtDropzone = ({onDrop = noop, onDragEnter = noop, onDragLeave = noop}: TUseHTDropzoneProps) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleOnDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
      setIsHovering(false);
      onDrop(acceptedFiles, fileRejections, event);
    },
    [onDrop]
  );

  const handleOnDragEnter = useCallback(
    (event: DragEvent<Element>) => {
      setIsHovering(true);
      onDragEnter(event);
    },
    [onDragEnter]
  );

  const handleOnDragLeave = useCallback(
    (event: DragEvent<Element>) => {
      setIsHovering(false);
      onDragLeave(event);
    },
    [onDragLeave]
  );

  return {isHovering, handleOnDrop, handleOnDragEnter, handleOnDragLeave};
};
