import React from 'react';
import dayjs from 'dayjs';

/* Queries */
import {useUserCurrentQuery} from 'queries/User/query.user.current';

/* Components */
import AvatarUser from 'components/Elements/AvatarUser';
import convertEditorToComment from 'components/Editor/utils/convertEditorToComment';
import {CommentThumbnail} from 'features/Issues/Parts/CommentFeed/Comment.Thumbnail';

import {TCommentProp} from './commentFeed.types';

export const Comment = ({comment, withAvatar = true, withLightbox = true}: TCommentProp & {withAvatar: boolean}) => {
  /* Queries */
  const client = useUserCurrentQuery();

  /* Constants */
  const {
    content: {content},
    user,
  } = comment;
  const owner = client.data?.id === user.id;

  /* Design wants an "at" within a prescribed format. No need to break out yet. Seems to be a one off. */
  const formatDate = (date: string) => {
    return dayjs(date)
      .format('MMMM D, YYYY h:mm A')
      .replace(/(\d{4})/, `$1 at `);
  };

  return (
    <div className="paddingBottom-medium">
      <div className="flex flex-direction-row fullwidth">
        <div className="paddingRight-small">{withAvatar ? <AvatarUser small isOwner={owner} name={user.name} /> : null}</div>
        <div className="flex flex-direction-column marginRight-small fullwidth">
          <div className="flex justify-content-space-between paddingBottom-tiny">
            <div className="p2 text-weight-med">{user.name}</div>
            <div className="caption n700">{formatDate(comment.created_at)}</div>
          </div>
          <div key={content.id} className="p2">
            {convertEditorToComment(content.root)}
          </div>
          {comment.attachments.length > 0 && <CommentThumbnail withLightbox={withLightbox} comment={comment} />}
        </div>
      </div>
    </div>
  );
};
