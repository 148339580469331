import React, {ComponentProps} from 'react';
import cn from 'classnames';
import {Grid} from 'ht-styleguide';
import styles from './grid.styles.scss';

const BaseFluidGrid = ({children, classes, ...rest}: ComponentProps<typeof Grid.Fluid>) => (
  <Grid.Fluid {...rest} classes={cn(styles.grid, classes)}>
    {children}
  </Grid.Fluid>
);

const BaseGridRow = ({children, ...rest}: ComponentProps<typeof Grid.Row>) => (
  <Grid.Row {...rest} classes={styles.gridRow}>
    {children}
  </Grid.Row>
);

const BaseGrid = {
  /** Wrapper around Grid.Fluid to use custom side margins */
  Fluid: BaseFluidGrid,
  /** Re-exported Grid.FullWidth from ht-styleguide */
  FullWidth: Grid.FullWidth,
  /** Wrapper around Grid.Row to allow custom gutters */
  Row: BaseGridRow,
  /** Re-exported Grid.Column from ht-styleguide */
  Column: Grid.Column,
};

export default BaseGrid;
