import React from 'react';
import cn from 'classnames';
import {noop} from 'utils/event';
import {Panel, EmptyState, Button, BUTTON_THEMES} from 'ht-styleguide';
import styles from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TeamManagement/currentProjectTeamManagement.styles.scss';

type PaymentAdjustmentEmptyStateProps = {
  onButtonClick?: BaseAnyFunction;
  className?: string;
};

const TeamManagementEmptyState = ({onButtonClick = noop, className = ''}: PaymentAdjustmentEmptyStateProps) => {
  const title = 'No team members added';
  const subtext = 'Make sure to add team members and have a team lead assigned before dispatching the project.';

  return (
    <>
      <p className="h5 n700 marginBottom-medium">0 Active Team Members</p>
      <Panel noShadow largeBorderRadius className={cn(styles.panel, 'padding-large', className)}>
        <EmptyState iconName="team" title={title} text={subtext} showButtonContainer>
          <Button theme={BUTTON_THEMES.SECONDARY} onClick={onButtonClick}>
            Add Team Members
          </Button>
        </EmptyState>
      </Panel>
    </>
  );
};

export default TeamManagementEmptyState;
