import {useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {notifications} from 'components/Notification/notification.ducks';
import useGetOrderDetails from 'features/Orders/queries/query.getOrderDetails';
import {ServiceStatusId} from 'features/Orders/types/orders.types';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {orderTaskChecklistByServicePath} from 'global/paths';
import {checkIfOrderUsesTaskFlow} from '../orderTaskChecklist.utils';
import useGetTaskChecklistByServices from '../queries/query.getTaskChecklistByServices';

type TUseOrderTaskChecklistFlowParams = {
  orderId: number;
  serviceId: number | null | undefined;
};

/**
 * Main responsibilities of this hook:
 * - Fetch key data needed for this page - order and checklist data
 * - Redirect user to the correct route if needed
 */
const useOrderTaskChecklistFlow = ({orderId, serviceId}: TUseOrderTaskChecklistFlowParams) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  /**
   * Order-related =============================================
   * - Fetch order details and modify services to exclude cancelled ones
   */
  const {data: orderDetailsData} = useGetOrderDetails({id: orderId, full: true, useLoader: true});
  const order = useMemo(() => {
    if (!orderDetailsData) {
      return null;
    }
    const orderDetails = orderDetailsData?.order;
    orderDetails.breakdown.services = orderDetails.breakdown.services.filter(({statusId}) => statusId !== ServiceStatusId.CANCELLED);
    return orderDetails;
  }, [orderDetailsData]);

  // Fetch task checklist data
  const serviceIds = order?.breakdown?.services.map(service => service.id) || [];
  // Checklist data for each service on the order
  const checklistQueryData = useGetTaskChecklistByServices({orderId, serviceIds, useLoader: true, queryOpts: {enabled: serviceIds.length > 0}});
  // Checklist data for the current service
  const checklistData = serviceId ? checklistQueryData[serviceId]?.data : null;
  // Order uses the task flow
  const orderUsesTaskFlow = useMemo(() => checkIfOrderUsesTaskFlow(checklistData), [checklistData]);

  /**
   * If the route only contains the orderId, grab the first serviceId from the order and
   * then redirect the user to the route with orderId and serviceId
   */
  useEffect(() => {
    if (orderId && !serviceId && order) {
      const {breakdown} = order;
      const firstServiceId = breakdown?.services[0]?.id;
      if (firstServiceId) {
        navigate(orderTaskChecklistByServicePath(String(orderId), String(firstServiceId)));
      } else {
        dispatch(notifications.actions.notificationCustomError({source: 'Order has no services'}));
      }
    }
  }, [orderId, serviceId, order, navigate, dispatch]);

  /**
   * Alert the user if the order does not have tasks as legacy orders will not have any.
   */
  useEffect(() => {
    if (checklistData && !orderUsesTaskFlow) {
      dispatch(notifications.actions.notificationCustomError({source: 'Order does not have tasks'}));
    }
  }, [checklistData, dispatch, orderUsesTaskFlow]);

  return {
    order,
    orderUsesTaskFlow,
    checklistData,
    checklistsForOrder: checklistQueryData,
  };
};

export default useOrderTaskChecklistFlow;
