import React from 'react';
import {EmptyState, Panel} from 'ht-styleguide';

type TEmptyAddSkus = {
  children?: React.ReactNode;
}
const EmptyAddSkus = ({children = null}: TEmptyAddSkus) => {

  return (
    <Panel noShadow largeBorderRadius className='border padding-large marginTop-medium flex flex-direction-column align-items-center'>
      <EmptyState iconName="box" title="You haven't added any SKUs to this job template yet" text="Search by SKU ID or Name in the field above to start adding SKUs." />
      {children}
    </Panel>
  )
}

export default EmptyAddSkus;
