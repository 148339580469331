import React, {useEffect, useMemo, useState} from 'react';
import * as Yup from 'yup';
import cn from 'classnames';
// Hooks
import {useFormik} from 'formik';
// Utils
import {noop} from 'utils/event';
// Types && Ducks
import {SelectOption} from 'features/MultiDwellingUnits/MDU.types';
import {TSelectFieldValue} from 'ht-styleguide/build/SelectField/SelectField.types';
import {TechSearchResult} from 'features/MultiDwellingUnits/Parts/FormFields/FormFields.types';
// Components && Styles
import {Modal, ELEMENT_SIZE, Form, Button, BUTTON_THEMES, SelectField, Icon, EmptyState} from 'ht-styleguide';
import AvatarUser from 'components/Elements/AvatarUser';
import {logger} from 'utils/logger';
import useDebounce from 'hooks/useDebounce';
import usePrevious from 'hooks/usePrevious';
import {useMduApi} from 'features/MultiDwellingUnits/MDU.hooks';
import styles from './modals.styles.scss';

type ModalAddTeamMembersProps = {
  isVisible: boolean;
  toggleModal?: BaseAnyFunction;
  onAddMember?: (techIds: number[]) => void;
};

export const FORM_FIELDS: {[key: string]: {id: string; label: string}} = {
  TEAM_MEMBERS: {id: 'teamMembers', label: 'New Team Member'},
};

const teamMembersSchema = Yup.object().shape({
  [FORM_FIELDS.TEAM_MEMBERS.id]: Yup.array().required('Required').min(1),
});

type TechSearchResultWithSelectField = {label: string; value: number} & TechSearchResult;

const SelectedTechsList = ({techs, removeTech = noop}: {techs: TechSearchResultWithSelectField[]; removeTech: BaseAnyFunction}) => {
  return (
    <div className={cn('marginTop-small2', styles.selectedTechsList)}>
      {techs.map(t => (
        <div className={styles.teamMemberLineItem} key={t.value}>
          <div className="flex">
            <AvatarUser name={t.name} image={t.profile_picture.thumb} small />
            <div className="marginLeft-tiny1 p2 n800">
              <div>{t.name}</div>
              <div>{t.value}</div>
            </div>
          </div>
          <Icon name="v2-close-icon" className="n300 p2 cursorPointer" onClick={() => removeTech(t.value)} />
        </div>
      ))}
    </div>
  );
};

const SelectedTechsEmptyState = () => (
  <div className="marginTop-medium1 marginBottom-tiny1">
    <EmptyState iconName="team" title="Search for technicians to add" />
  </div>
);

export const formatTechLabel = (tech: TechSearchResult) => ({
  ...tech,
  value: tech.id,
  label: `${tech.name} #${tech.id}`,
});

const ModalAddTeamMembers = ({isVisible, toggleModal = noop, onAddMember = noop}: ModalAddTeamMembersProps) => {
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [techSearchString, setTechSearchString] = useState(''); // Send this string to BE for search
  const [techsList, setTechsList] = useState<SelectOption[]>([{value: '', label: 'Type to search', isDisabled: true}]); // List we get from BE
  const [selectedTech, setSelectedTech] = useState<TSelectFieldValue | null>(null); // The only function of this is to display the tech name in the dropdown.
  const [runningList, setRunningList] = useState<{value: number; label: string}[]>([]); // Keep a list of tech ids to send to BE to add to project

  const mduApi = useMduApi();

  const handleModalClose = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    formik.resetForm();
    setRunningList([]);
    setSelectedTech(null);
    toggleModal();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {[FORM_FIELDS.TEAM_MEMBERS.id]: [] as TechSearchResultWithSelectField[]},
    validationSchema: teamMembersSchema,
    onSubmit: async membersParams => {
      await onAddMember(membersParams.teamMembers.map(m => m.id));
      handleModalClose();
    },
  });

  const handleSubmit = () => {
    if (!formik.isSubmitting) {
      formik.submitForm();
    }
  };

  /** Add new tech to running list of techs to add */
  const handleDetailsChange = (tech: {label: string; value: number}) => {
    // Hitting Backspace key with an empty select field causes tech to be null. Filter out falsy values to avoid this
    const newList = [...runningList, tech].filter(Boolean);

    const isTechAlreadyInList = runningList.find(t => t.value === tech.value);
    if (!isTechAlreadyInList) setRunningList(newList);
    setSelectedTech(tech);
    return formik.handleChange({
      target: {name: FORM_FIELDS.TEAM_MEMBERS.id, value: newList},
    });
  };

  /** Remove tech from the running list of techs to add, not the list saved to the project  */
  const removeTech = (id: number) => {
    const newList = [...runningList];
    const foundIndex = newList.findIndex(elem => elem.value === id);
    if (foundIndex >= 0) newList.splice(foundIndex, 1);
    setRunningList(newList);
    formik.handleChange({
      target: {name: FORM_FIELDS.TEAM_MEMBERS.id, value: newList},
    });
  };

  const hasSelectedTechs = Boolean((runningList || []).length);
  const buttonText = hasSelectedTechs ? `Add ${runningList.length} members` : 'Add Member';

  /* Search */
  const debouncedTechSearchString = useDebounce(techSearchString, 800, {
    trailing: true,
    leading: false,
  });

  const previousTechSearchString = usePrevious(debouncedTechSearchString);

  const shouldNotSearch = useMemo(
    () => isSearching || !debouncedTechSearchString.trim() || previousTechSearchString === debouncedTechSearchString,
    [debouncedTechSearchString, isSearching, previousTechSearchString]
  );

  /* Tech Search  */
  useEffect(() => {
    if (shouldNotSearch) return;
    const fetchTechs = async () => {
      setIsSearching(true);
      try {
        const techs = await mduApi.searchTechs(debouncedTechSearchString);
        const formattedTechs = techs.map((elem: TechSearchResult) => formatTechLabel(elem));
        setTechsList(formattedTechs);
      } catch (error) {
        logger('TechleadSelect')(String(error));
      } finally {
        setIsSearching(false);
      }
    };
    if (debouncedTechSearchString.length) fetchTechs();
    if (!debouncedTechSearchString.length) setTechsList([]);
  }, [isSearching, shouldNotSearch, debouncedTechSearchString, mduApi]);

  return (
    <Modal
      elementSize={ELEMENT_SIZE.MEDIUM}
      isVisible={isVisible}
      hide={handleModalClose}
      header="Add Team Member"
      bodyContainerClasses={styles.overflowInitial}
      modalClassName={styles.overflowInitial}
      footerElement3={
        <Button theme={BUTTON_THEMES.PRIMARY} onClick={handleSubmit} disabled={!hasSelectedTechs}>
          {buttonText}
        </Button>
      }
    >
      <p className="p1 marginBottom-small2">Add a new member to the team from our pool of registered technicians.</p>
      <Form>
        <Form.Row>
          <Form.Column lg={12} md={8} sm={4}>
            <SelectField
              label="New Team Member"
              searchable
              clearable
              id={FORM_FIELDS.TEAM_MEMBERS.id}
              onInputChange={setTechSearchString}
              options={[...techsList]}
              onChange={handleDetailsChange}
              value={selectedTech}
              error={formik.errors[FORM_FIELDS.TEAM_MEMBERS.id] as string}
            />
          </Form.Column>
        </Form.Row>
      </Form>
      {/* @ts-expect-error */}
      {hasSelectedTechs ? <SelectedTechsList techs={runningList} removeTech={removeTech} /> : <SelectedTechsEmptyState />}
    </Modal>
  );
};

export default ModalAddTeamMembers;
