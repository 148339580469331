export const formatter = (digits = 0) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits,
  });

/**
 * Added the +().toFixed(2)
 *   - New goe pricing is creating long floats. So, we toFixed it and add the + to trim off zeros.
 *   - Then allow the secondary part 'cents...' to do its thing.
 * @param price
 * @param cents
 * @returns {string}
 */
export const formatPriceTrim = (price = 0, cents = true) => `$${+price.toFixed(2) / ((cents && 100) || 1)}`;

// This does not trim the trailing zeros -> 15800 will become $158.00
export const formatPrice = (price = 0) => `$${(price / 100).toFixed(2)}`;

/**
 * Remove trailing zeros from price
 * @param price in cents
 */
export const formatPriceTrimZero = (price = 0) => {
  const formattedPrice = formatPrice(price);
  return formattedPrice.replace(/\.00+$/, '');
};
