import React from 'react';
import {useSelector} from 'react-redux';
import {useParams, useNavigate} from 'react-router-dom';
import {Button, BUTTON_THEMES, Panel} from 'ht-styleguide';
import {Player} from '@lottiefiles/react-lottie-player';
import {useAppDispatch} from 'hooks/useAppDispatch';

/* Ducks / Slices */
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';

/* Components */
import JobsCreateTakeOver from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/Jobs/AddJobs/Multi/Jobs.Create.TakeOver';

/* Constants */
import actionInProgress from 'assets/lottie/action-in-progress.json';
import {mduProjectPathByStage} from 'global/paths';

import {MduUseParamsTypes} from 'features/MultiDwellingUnits/MDU.types';

const JobsCreateProcessing = () => {
  /* Hooks */
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const totalJobCount = useSelector(mduProjectsSlice.selectors.getDraftTotalJobs);
  const {projectId = '', stage = ''} = useParams<MduUseParamsTypes>();

  /* Methods */
  const onHandleClose = () => {
    dispatch(mduProjectsSlice.actions.deleteAllDraftGroups());
    navigate(mduProjectPathByStage(projectId, stage));
  };

  return (
    <JobsCreateTakeOver>
      <Panel noShadow largeBorderRadius className="padding-large marginTop-medium flex flex-direction-column align-items-center">
        <div style={{width: '500px'}} className="flex align-items-center flex-direction-column">
          <Player autoplay loop src={actionInProgress} style={{width: '248px', height: '248px'}} />
          <h4 className="marginTop-medium1">Adding {totalJobCount} jobs...</h4>
          <p className="p1 n700 marginBottom-small2 marginTop-small2 text-center">
            The jobs will be added to the project as they're created, which may take several minutes. You're free to close this page and continue working while your request is being processed.
          </p>
          <div style={{width: '330px'}}>
            <Button medium theme={BUTTON_THEMES.PRIMARY} onClick={onHandleClose}>
              Close
            </Button>
          </div>
        </div>
      </Panel>
    </JobsCreateTakeOver>
  );
};

export default JobsCreateProcessing;
