import React from 'react';
import cn from 'classnames';
import {Icon} from 'ht-styleguide';
import {TThumbnailActionButtonsParams} from './thumbnail.types';
import styles from './thumbnailDisplay.styles.scss';
import {THUMBNAIL_TEST_IDS} from './thumbnail.constants';

export const ThumbnailActionButtons = ({showDownloadButton, showDeleteButton, handleDownloadClick, handleDeleteClick}: TThumbnailActionButtonsParams) => {
  const actionButtonStyles = cn(styles.thumbnailActionButton, 'plainButton');
  return (
    <div className={styles.thumbnailButtonContainer}>
      {showDownloadButton && (
        <button type="button" onClick={handleDownloadClick} className={actionButtonStyles} data-testid={THUMBNAIL_TEST_IDS.DOWNLOAD_BUTTON}>
          <Icon name="download" />
        </button>
      )}
      {showDeleteButton && (
        <button type="button" onClick={handleDeleteClick} className={actionButtonStyles} data-testid={THUMBNAIL_TEST_IDS.DELETE_BUTTON}>
          <Icon name="trash" />
        </button>
      )}
    </div>
  );
};
