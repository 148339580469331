import {PayloadAction, createSelector, createSlice} from '@reduxjs/toolkit';
import * as Types from './ui.types';
import {PAGINATION_INITIAL_STATE} from './ui.constants';

const initialState: Types.IInitialState = {
  pagination: PAGINATION_INITIAL_STATE,
  modal: {
    createProject: {
      visible: false,
    },
  },
  search: {
    projects: {
      visible: false,
    },
  },
};

/*
*******************************************************
  SLICE
*******************************************************
*/
const {actions: uiActions, reducer} = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    /*
    *******************************************************
      Pagination
    *******************************************************
    */
    updatePagination: (state, action: PayloadAction<Types.TUpdatePaginationPayload>) => {
      const old = state.pagination;
      state.pagination = {...old, ...(action.payload || {})};
    },
    resetPagination: state => {
      state.pagination = PAGINATION_INITIAL_STATE;
    },
    setModalVisibility: (state, action: PayloadAction<Types.TModalVisibilityPayload>) => {
      const {modal, visible} = action.payload;
      const currentVisibility = state.modal[modal].visible;
      state.modal[modal].visible = typeof visible === 'boolean' ? visible : !currentVisibility;
    },
    setSearchVisibility: (state, action: PayloadAction<Types.TSearchVisibilityPayload>) => {
      const {search, visible} = action.payload;
      const currentVisibility = state.search[search].visible;
      state.search[search].visible = typeof visible === 'boolean' ? visible : !currentVisibility;
    },
  },
});

/*
*******************************************************
  States and Selectors
*******************************************************
*/

const getPaginationState = (state: any): Types.IInitialState['pagination'] => state.ui.pagination;
const getModalState = (state: any): Types.IInitialState['modal'] => state.ui.modal;
const getSearchState = (state: any): Types.IInitialState['search'] => state.ui.search;

const selectors = {
  getPaginationState: createSelector(getPaginationState, state => state),
  getModalState: createSelector(getModalState, state => state),
  getSearchState: createSelector(getSearchState, state => state),
};

/*
*******************************************************
  Export
*******************************************************
*/
export default {
  actions: uiActions,
  reducer,
  selectors,
};
