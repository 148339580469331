import React, {ComponentProps, useMemo} from 'react';
import {useFormik} from 'formik';
import {ELEMENT_SIZE, SelectField} from 'ht-styleguide';
import {useGetTicketsGenericQuery, TUseGetTicketsGenericQueryArgs} from 'features/Issues/queries/query.issues.getTicketsGeneric';
import {ISSUE_ENTITY_TYPES} from 'features/Issues//Issues.constants';

type TQueryFilters = {
  entity_id?: number;
  entity_type?: (typeof ISSUE_ENTITY_TYPES)[keyof typeof ISSUE_ENTITY_TYPES];
  pagination?: {
    per_page: number;
    page: number;
  };
};

interface IIssuesSelect {
  dataTestId?: string;
  formik: ReturnType<typeof useFormik<any>>;
  /**
   * Key in formik value object
   */
  fieldName: string;
  label?: string;
  /**
   * Filters to pass to the issues list fetch request
   */
  queryFilters?: TQueryFilters;
  /**
   * Options to apply to react-query's `useQuery`
   */
  queryOpts?: TUseGetTicketsGenericQueryArgs['queryOpts'];
  /**
   * Overrides to `SelectField`
   */
  selectFieldProps?: Partial<ComponentProps<typeof SelectField>>;
}

/**
 * A customizable select that fetches a list of issues.
 */
const IssuesSelect = ({dataTestId = 'Issues-IssuesSelect', formik, fieldName, label, queryFilters = {}, queryOpts = {}, selectFieldProps = {}}: IIssuesSelect) => {
  const {data, isLoading} = useGetTicketsGenericQuery({
    filters: queryFilters,
    queryOpts,
  });

  const issueOpts = useMemo(() => {
    if (Array.isArray(data)) {
      return data
        .sort(({created_at: created_at_A}, {created_at: created_at_B}) => new Date(created_at_A).getTime() - new Date(created_at_B).getTime())
        .map(({id, summary}) => ({
          value: id,
          label: summary,
        }));
    }
    return [];
  }, [data]);

  const onChange: ComponentProps<typeof SelectField>['onChange'] = selected => {
    const selectedValue = selected?.value;
    formik.handleChange({target: {name: fieldName, value: selectedValue}});
  };

  const value = formik.values[fieldName];
  const error = formik.errors[fieldName]?.toString() || undefined;
  const disableSelectField = isLoading || !issueOpts.length;

  return (
    <SelectField
      clearable
      dataTestId={dataTestId}
      elementSize={ELEMENT_SIZE.L}
      error={error}
      isDisabled={disableSelectField}
      label={label}
      onChange={onChange}
      options={issueOpts}
      placeholder="Select or start typing"
      searchable
      value={value}
      {...selectFieldProps}
    />
  );
};

export default IssuesSelect;
