/* API RESPONSES */
export const REJECTED = 'rejected';
export const PENDING = 'pending';
export const FULFILLED = 'fulfilled';

// Permission types
export const ADMIN = 'Admin';
export const CLIENT = 'Client';
export const PROTECTED = 'protected';
export const PUBLIC = 'public';
export const USCS = 'USCS';

// Action Level Page | component
export const PAGE = 'page';
export const COMPONENT = 'component';

// DATE FORMATS
export const LLL = 'MMMM D, YYYY h:mm A';
export const LL = 'MMMM D, YYYY';
export const l = 'MM/DD/YYYY';
export const shortMonthDayYear = 'MM/DD/YY';
export const longDayMonthWithYear = 'dddd, MMM D, YYYY';
export const shortDayMonthWithYearTime = 'ddd, MMM D, YYYY h:mm A'; // Thu, Aug 16, 2018 8:02 PM
export const shortMonthWithDay = 'MMM D, YYYY'; // Jan 7, 2022
export const shortMonthWithDayAndHour = 'MMM D, YYYY [at] ha'; // Jan 7, 2022 at 8pm
export const shortMonthWithDayAndHourMinute = 'MMM D, YYYY [at] h:mma'; // Jan 7, 2022 at 8:12pm
export const shortDayMonthWithYear = 'ddd, MMM D, YYYY'; // Thu, Aug 16, 2018
export const numericDayMonthYear = 'M/D/YYYY'; // 7/13/22
export const isoFormat = 'YYYY-MM-DD';
export const isoFormatSlash = 'YYYY/MM/DD';

// Cache and Stale times
export const TIME_1_MINUTE = 1000 * 60 * 1;
export const TIME_2_MINUTES = 1000 * 60 * 2;
export const TIME_3_MINUTES = 1000 * 60 * 3;
export const TIME_5_MINUTES = 1000 * 60 * 5;
export const TIME_15_MINUTES = 1000 * 60 * 10;
export const TIME_20_MINUTES = 1000 * 60 * 15;

/* Default Select Option */
export const DEFAULT_SELECT_OPTION = {value: '', label: 'Select'};

/**
 * Credit Feature - Credit is always referred to as "HelloTech Cash"
 */
export const CREDIT_LABEL = 'HelloTech Cash';

export const DEFAULT_TIME_ZONE = 'America/Los_Angeles';
