import React from 'react';
import {useCurrentProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/CurrentProject.hooks';
import {isPreLaunchStatus} from 'features/MultiDwellingUnits/MDU.utils';
import ServicesPreLaunch from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/PreLaunch/TemplateJobs.PreLaunch.Services';
import ServicesPostLaunch from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/PostLaunch/TemplateJobs.PostLaunch.Services';

const ServicesPage = () => {
  const project = useCurrentProject();
  const isPreLaunch = isPreLaunchStatus(project.status);
  /* No project. Get out */
  if (!project?.id) return null;

  /* We have prelaunch project status */
  if (isPreLaunch) return <ServicesPreLaunch />;

  /* We have postlaunch status */
  return <ServicesPostLaunch />;
};

export default ServicesPage;
