import {createEntityAdapter, PayloadAction} from '@reduxjs/toolkit';
import {REMOTE, REMOTE_FALSE, REMOTE_MISMATCH, REMOTE_TRUE} from 'features/Booking/constants/sku';
import * as TS from 'features/Questions/types';
import {RouterStateMode} from 'global/constants/router';
import {IHash} from 'types/base.types';

import get from 'lodash/get';
import trim from 'lodash/trim';
import {logger} from 'utils/logger';

export const defaultSelectedSku = {
  questions: {},
  autoApplyCoupon: null,
  totalPrice: '',
  startsAtPrice: null,
  partner: null,
  name: '',
  overrideBasePrice: null,
  skuId: 0,
  lens: false,
  category: '',
  quantity: 1,
  uuidDraft: '',
};

export default function createQuestionsDucks(context: string) {
  const questionsAdapter = createEntityAdapter<TS.EntityQuestions>();
  const questionsSkuAdapter = createEntityAdapter<TS.Sku>();

  const questionsInitialState = questionsAdapter.getInitialState({});
  const questionsSkuInitialState = questionsSkuAdapter.getInitialState({});

  return {
    questionsAdapter,
    questionsSkuAdapter,
    getAddSkuInitialState: () => ({
      questions: questionsInitialState,
      skus: questionsSkuInitialState,
      errors: {},
      selectedSku: defaultSelectedSku,
    }),
    getReducers: () => ({
      answerChange: (state: {addSku: TS.AddSku}, {payload}: PayloadAction<{question: any; value: any}>) => {
        const {questions} = state.addSku.selectedSku;
        const {question, value} = payload;

        switch (question.inputType) {
          case TS.QuestionTypes.Input:
            questions[question.id].text = value;
            break;
          case TS.QuestionTypes.Textarea:
            questions[question.id].text = value;
            break;
          case TS.QuestionTypes.Dropdown:
            questions[question.id] = value ? {id: value} : null;
            break;
          case TS.QuestionTypes.Checkbox:
            questions[question.id] = value.map((v: number) => ({id: v}));
            break;
          default: {
            logger(`${context} Answer Change`)(new Error(`Unknown inputType: ${question.inputType}`));
          }
        }
      },
      answerQuantityChange: (state: {addSku: TS.AddSku}, {payload}: PayloadAction<{question: any; id: number; quantity: number}>) => {
        const {questions} = state.addSku.selectedSku;
        const {question, id, quantity} = payload;
        const answerIndex = questions[question.id].findIndex((a: TS.QuestionsAPIByQuestion) => a.id === id);
        questions[question.id][answerIndex].quantity = quantity;
      },
      deviceAnswerChange: (state: {addSku: TS.AddSku}, {payload}: PayloadAction<{question: any; questionType: string; makeValue: any; modelValue: number}>) => {
        const {questions} = state.addSku.selectedSku;
        const {questionType, question, makeValue, modelValue} = payload;
        const answerMatch = question.answers.find((a: {id: number}) => {
          return a.id === makeValue;
        });
        /*
          BE needs the id of the productQuestion, not the answer id.
          But when editing answer, FE needs answer id.
        */
        let makeVal = get(answerMatch, 'productQuestion.id', null);
        let modelVal = modelValue;
        let answerId = makeValue;
        /*
          If answerMatch is not present, it means its either "other" or "idk" option
          The "other" option opens an inputfield which is addressed in "DEVICE_INPUT_ANSWER_CHANGE"
        */
        if (makeValue === TS.CustomDropdownValues.negOne) {
          answerId = null;
          makeVal = TS.CustomDropdownValues.negOne;
          modelVal = TS.CustomDropdownValues.negOne;
        }
        const data = {answerId, answerValue: makeValue, make: makeVal, model: modelVal};
        const values = makeValue || modelValue ? data : null;
        switch (questionType) {
          case 'makeDropdown':
            questions[question.id.toString()] = values;
            break;
          case 'modelDropdown':
            questions[question.id.toString()].model = modelVal;
            break;
          default:
          // nothing
        }
      },
      deviceInputAnswerChange: (state: {addSku: TS.AddSku}, {payload}: PayloadAction<{question: any; questionType: string; makeInputValue: any; modelInputValue: any}>) => {
        const {questions} = state.addSku.selectedSku;
        const {questionType, question, makeInputValue, modelInputValue} = payload;
        const data = {answerId: null, answerValue: makeInputValue, make: makeInputValue, model: modelInputValue};
        const values = makeInputValue || modelInputValue ? data : null;

        switch (questionType) {
          case 'makeInput':
            questions[question.id.toString()] = values;
            break;
          case 'modelInput':
            questions[question.id.toString()].model = modelInputValue;
            break;
          default:
          // nothing
        }
      },
      setSelectedSkuOverride: (state: {addSku: TS.AddSku}, {payload}: PayloadAction<any>) => {
        state.addSku.selectedSku = payload;
      },
      deleteErrors: (state: {addSku: TS.AddSku}) => {
        state.addSku.errors = {};
      },
      setQaFormErrors: (state: {addSku: TS.AddSku}, {payload}: PayloadAction<any>) => {
        state.addSku.errors = payload;
      },
    }),
  };
}

export function validateForm({addSku, cart = {}, mode}: {addSku: TS.AddSku; cart?: any; mode?: string}): TS.QAErrors {
  const {selectedSku} = addSku;
  const {questions} = addSku.questions.entities[selectedSku.skuId!];
  /* This is the full sku that the selectedSku is derived from */
  const sku = addSku.skus.entities[selectedSku.skuId!];
  /* we need to know if we are in edit mode */
  // we need cart to compare added items to hellotechNow
  const {items = []} = cart;

  const itemAdjustment = mode === RouterStateMode.EDIT_SKU ? 1 : 0;
  const itemsIsOneOrMore = items.length - itemAdjustment > 0;
  const requiredQuestions = questions.filter((q: TS.QuestionsAPIByQuestion) => q.required);
  const errors: TS.QAErrors = {};
  /*
    This is to make sure there is no mismatch between service types: remote/non-remote
  */
  const itemRemoteValue = cart?.remote ? REMOTE_TRUE : REMOTE_FALSE;

  requiredQuestions.forEach((q: TS.QuestionsAPIByQuestion) => {
    const answer: TS.Answer = selectedSku.questions[q.id as number] || null;
    let error = null;

    switch (q.inputType) {
      case TS.QuestionTypes.Input:
        if (trim(answer.text) === '') {
          error = 'This field is required';
        }
        break;
      case TS.QuestionTypes.Textarea:
        if (trim(answer.text) === '') {
          error = 'This field is required';
        }
        break;
      case TS.QuestionTypes.Dropdown:
        if (answer === null) {
          error = 'This field is required';
        }
        /*
           Our remote selection is selected, so now lets verify against
           our cart items to make sure no mismatch. Of course, if nothing is
           carted, then a user can choose whichever they want.
         */
        if (q.id === REMOTE && answer) {
          if (answer.id !== itemRemoteValue && itemsIsOneOrMore) {
            errors[REMOTE_MISMATCH] = REMOTE_MISMATCH;
            error = 'It seems you have a carted item that mismatches with your selection.';
          }
        }

        break;
      case TS.QuestionTypes.Checkbox:
        if (Array.isArray(answer)) {
          if (!answer.length) {
            error = 'Please select at least one option';
          }
          /*
           Our remote selection is selected, so now lets verify against
           our cart items to make sure no mismatch. Of course, if nothing is
           carted, then a user can choose whichever they want.
         */
          if (REMOTE_TRUE === itemRemoteValue && itemsIsOneOrMore) {
            /* Do any of our selected checkboxes have forbidRemote */
            const hasAddonPrecludesInHome = answer.some((a: {id: number}) => {
              return q.answers.filter(qa => qa.id === a.id).some(qa => get(qa, `forbidRemote`, false));
            });

            if (hasAddonPrecludesInHome) {
              errors[REMOTE_MISMATCH] = REMOTE_MISMATCH;
              error = 'Your add-ons mismatch with your carted items.';
            }
          }
        }
        break;
      case TS.QuestionTypes.Device: {
        const required = ['make', 'model'];
        const getErrors = required.reduce((seed, key) => {
          const keyRef = trim(get(answer, key) ?? '');
          if (!keyRef) seed[key] = 'This field is required';

          return seed;
        }, {} as IHash<string>);

        error = Object.values(getErrors).length ? getErrors : null;
        break;
      }
      default:
        throw new Error(`Unknown input type: ${q.inputType}`);
    }
    if (error) {
      errors[q.id] = error;
    }
  });

  /* We know this is an in-home item but we have carted a remote item */
  if (sku.remote === false && itemRemoteValue === REMOTE_TRUE && itemsIsOneOrMore) {
    errors[REMOTE_MISMATCH] = REMOTE_MISMATCH;
  }

  return errors;
}
