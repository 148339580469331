import get from 'lodash/get';
import {Subscription} from 'features/Profile/profile.types';

export const SECTION_HEADERS = {
  WHOLE_HOME_DASHBOARD: 'Whole Home DashBoard',
  ORDERS: 'Orders',
  DEVICES: 'Devices',
  ZILLOW: 'Home Information',
  EMPTY_ORDERS: 'No orders',
  EMPTY_DEVICES: 'No devices',
  EMPTY_ZILLOW: 'No home information',
  EMPTY_MEMBERSHIP: 'Not a member',
};

export const CARD_HEADERS = {
  ADDRESS: 'Address',
  EMAIL: 'Email',
  PHONE: 'Phone',
  BILLING: 'Billing',
};

export const STATS_HEADERS = {
  completedOrders: 'Completed Orders',
  cancelledOrders: 'Cancelled Orders',
  unserviceableOrders: 'Unserviceable Orders',
  revenueGenerated: 'Revenue Generated',
  averageOrderValue: 'Average Order Value',
};

export const MEMBERSHIP_BENEFIT_TYPES = {
  PRODUCT_DISCOUNT: 'productDiscount',
  REMOTE_SERVICE: 'remoteService',
  DASHLANE: 'dashlane',
  MALWAREBYTES: 'malwarebytes',
  HOME_TECHNOLOGY_CHECKUP: 'htcService',
  IN_HOME_DISCOUNT: 'inHomeDiscount',
  HOME_TECH_SPECIALIST: 'homeTechSpecialist',
};

export const BENEFITS_HEADERS = {
  htcService: 'Home Technology Checkup',
  dashlane: 'Dashlane Software',
  inHomeDiscount: (subscription: Subscription) => `${get(subscription, 'planRollupFee.formatted')} In-Home Service`,
  homeTechSpecialist: 'Home Tech Specialist',
  productDiscount: (subscription: Subscription) => `${get(subscription, 'planProductDiscount')} Product Discount`,
  malwarebytes: 'MalwareBytes Software',
  remoteService: 'Unlimited 24/7 Remote Support',
};

export const SEGMENTS = {
  MEMBER: 'member',
  LEAD: 'lead',
  BOOKER: 'booker',
  CUSTOMER: 'customer',
};

export const EMPTY_STATES = {
  EMPTY_ORDERS: 'There are currently no orders placed for this user.',
  EMPTY_DEVICES: 'There are currently no devices added to this user’s inventory.',
  EMPTY_MEMBERSHIP: 'is currently not a HelloTech member',
};

export const MEMBERSHIP_TABLE_HEADERS = ['Benefits', 'Used'];
export const ORDERS_TABLE_HEADERS = ['ID', 'Status', 'Scope of Work', 'Action'];
export const DEVICES_TABLE_HEADERS = ['Image', 'Make', 'Model', 'Category', 'Added'];
