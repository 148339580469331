import {useSelector} from 'react-redux';
import * as splitioSlice from 'features/Splitio/splitio.ducks';
import {SPLIT_NAMES} from 'features/Splitio/splitio.constants';

/**
 * useExcludeLink
 *
 * Seed the method with the nav item you want to check for exclusion.
 * Given the partial fn, pass in the exclusion you want, usually thru an iterator, to check against.
 *
 * @param seedExclusion
 */
export const useExcludeLink = (seedExclusion: string) => {
  const {config} = useSelector(splitioSlice.selectors.getTreatmentValueByKey(SPLIT_NAMES.HTH_ADMIN_FEATURE_EXCLUSION_LIST));
  const navExclusions = config?.exclusions || [];

  const isExcluded = (exclusion: string = seedExclusion) => {
    return seedExclusion === exclusion && navExclusions.includes(exclusion);
  };

  return {isExcluded};
};
