/*
  Format a string representation of a phone number
  Example:
    '8006409005' -> (800) 640 9005
*/
import {logger, LoggerErrorType} from 'utils/logger';

export const formatPhoneBasic = (phone = '', seperator = ' ') => {
  if (!phone) return '';
  // Extract only the digits
  const reg = /(\d{3})(\d{3})(\d{4})/;
  try {
    return phone.replace(/\D/g, '').replace(reg, `($1) $2${seperator}$3`);
  } catch (e) {
    logger('formatPhoneBasic: ')(e as LoggerErrorType);
    return phone;
  }
};
