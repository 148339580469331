import React, {memo} from 'react';
import cn from 'classnames';
import {Icon, Tooltip} from 'ht-styleguide';
import styles from './lineItem.styles.scss';

type LineItemBreakdownProps = {
  name?: React.ReactNode;
  lineThroughText?: React.ReactNode;
  amount?: React.ReactNode;
  caption?: React.ReactNode;
  captionCallout?: React.ReactNode;
  className?: string;
  secondary?: boolean;
  success?: boolean;
  boldName?: boolean;
  boldAmount?: boolean;
  largeText?: boolean;
  nameEllipsis?: boolean;
  tooltipText?: string;
  dataid?: string;
};

const LineItemBreakdown: React.FC<LineItemBreakdownProps> = ({
  name,
  lineThroughText,
  amount,
  caption,
  captionCallout,
  className,
  secondary,
  success,
  boldName,
  boldAmount,
  largeText,
  nameEllipsis,
  tooltipText,
  dataid,
}) => {
  const colorOptions = {secondary, success};
  const lineThroughStyles = cn('marginRight-tiny1 table-row secondary line-through');
  const nameStyles = cn(styles.itemNameContainer, {
    'table-row': !largeText,
    p0: largeText,
    'text-weight-med': boldName,
    [styles.ellipsis]: nameEllipsis,
    ...colorOptions,
  });
  const amountStyles = cn({
    'table-row': !largeText,
    p0: largeText,
    'text-weight-med': boldAmount,
    success,
  });

  return (
    <div className={className} data-testid={dataid || ''}>
      <div className={styles.itemInfo}>
        <div className={nameStyles}>
          <span data-testid={`line-item-name-${name}`}>{name}</span>
          {tooltipText && (
            <Tooltip content={tooltipText} wrapperClass="marginLeft-tiny">
              <Icon name="question" />
            </Tooltip>
          )}
        </div>
        <div className={styles.itemAmount}>
          {lineThroughText && <span className={lineThroughStyles}>{lineThroughText}</span>}
          <span data-testid={`line-item-amount-${amount}`} className={amountStyles}>
            {amount}
          </span>
        </div>
      </div>
      {caption && (
        <div data-testid="line-item-caption" className={cn('marginTop-tiny', styles.itemInfo)}>
          <div className="caption n700 capitalize">{caption}</div>
          <div>{captionCallout}</div>
        </div>
      )}
    </div>
  );
};
export default memo(LineItemBreakdown);
