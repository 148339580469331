import React, {useMemo} from 'react';
import {TaskTypes} from 'types/tasks.types';
import {IBaseServiceTask} from '../../serviceTasksList.types';
import DetailsBlock from './DetailsBlock';
import DeviceBlock from './DeviceBlock';
import IncidentBlock from './IncidentBlock';
import OptionalNotes from './OptionalNotes';
import PhotosBlock from './PhotosBlock';
import UpdatedByFooter from './UpdatedByFooter';

const MAIN_CONTENT_MAPPING: {
  [type in (typeof TaskTypes)[keyof typeof TaskTypes]]: typeof DetailsBlock | null;
} = {
  [TaskTypes.Confirm]: DetailsBlock,
  [TaskTypes.Text]: DetailsBlock,
  [TaskTypes.Binary]: DetailsBlock,
  [TaskTypes.MultiSelect]: DetailsBlock,
  [TaskTypes.SingleSelect]: DetailsBlock,
  [TaskTypes.Photo]: PhotosBlock,
  [TaskTypes.Video]: null,
  [TaskTypes.Device]: DeviceBlock,
  [TaskTypes.Incident]: IncidentBlock,
};

const ServiceTask = ({order, serviceId, task, dataTestId = 'ServiceTask'}: IBaseServiceTask) => {
  const {task_type} = task;

  const MainBlockToRender = useMemo(() => {
    const component = MAIN_CONTENT_MAPPING[task_type] || null;
    return component;
  }, [task_type]);

  return (
    <>
      {MainBlockToRender && <MainBlockToRender serviceId={serviceId} task={task} dataTestId={dataTestId} />}
      <OptionalNotes serviceId={serviceId} task={task} dataTestId={dataTestId} />
      <UpdatedByFooter order={order} task={task} dataTestId={dataTestId} />
    </>
  );
};

export default ServiceTask;
