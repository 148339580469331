import React, {useState} from 'react';
import cn from 'classnames';
import {Icon} from 'ht-styleguide';
import {formatPrice} from 'utils/currency';
import {ItemAdjustments, BreakdownItem} from 'features/Booking/Parts/Cart/cart.types';
import {ProjectDetails, ProjectGroup, ProjectPaymentAdjustment, ProjectPayoutAdjustmentTypes} from 'features/MultiDwellingUnits/MDU.types';
import styles from './launchReviewPage.styles.scss';

export const getAdjustmentName = (adj: ProjectPaymentAdjustment) => {
  if (adj.kind === ProjectPayoutAdjustmentTypes.Percent) return `${adj.valueFormatted} ${adj.name}`;
  return adj.name;
};
export const getAdjustmentValueStyles = (adj: ProjectPaymentAdjustment) => cn('p2 text-weight-med text-right', {blue700: adj.value > 0, alertRed700: adj.value < 0});

export const SERVICE_DETAIL_TEST_ID_PREFIX = 'SERVICE-DETAIL-ROW-';
type ServiceDetailsProps = {
  projectGroup: ProjectGroup;
  groupId: String;
  project: ProjectDetails;
};
const ServiceDetails = ({projectGroup, groupId, project}: ServiceDetailsProps) => {
  const [isActive, setIsActive] = useState(false);
  const numberOfServices = projectGroup.projectServices?.length;
  const serviceText = numberOfServices && numberOfServices > 1 ? 'Services' : 'Service';
  const iconName = isActive ? 'chevron-up' : 'chevron';
  const serviceTextStyles = isActive ? 'p2 text-weight-bold' : 'p2';
  const baseInfoTableRowStyles = cn('cursorPointer p2', {'border-bottom': !isActive});

  return (
    <>
      <tr className={baseInfoTableRowStyles} onClick={() => setIsActive(!isActive)} data-testid={`${SERVICE_DETAIL_TEST_ID_PREFIX}${groupId}`}>
        <td className="text-left paddingRight-small">{projectGroup.name}</td>
        <td className={cn('text-left', {'paddingY-small1': !isActive, 'paddingTop-small1 paddingBottom-small': isActive})}>
          <p className={serviceTextStyles}>
            {numberOfServices} {serviceText}
          </p>
        </td>
        <td className="text-right">{projectGroup.unitsNumber}</td>
        <td className="text-right">{formatPrice(projectGroup?.breakdown?.totalAmountWithoutSubsidy)}</td>
        <td className="text-right">{formatPrice(projectGroup?.breakdown?.totalTechPayout)}</td>
        <td>
          <Icon className={cn('paddingLeft-small2 n700', styles.chevronIcon)} name={iconName} />
        </td>
      </tr>
      {isActive && (
        <>
          {projectGroup.breakdown?.items?.map((item: BreakdownItem) => {
            const itemNameText = `${item.name} (${item.skuId}) x${item.quantity}`;
            return (
              <tr key={item.skuId} className="p2 cursorPointer" onClick={() => setIsActive(!isActive)}>
                <td aria-label="blank cell" />
                <td className="paddingBottom-small">
                  <p className="p2 n700">{itemNameText}</p>
                  {!!item.adjustments &&
                    item.adjustments.map((a: ItemAdjustments) => (
                      <p key={`adjustment-${a.name}`} className="p2 n700">
                        + {a.name}
                      </p>
                    ))}
                </td>
                <td aria-label="blank cell" />
                <td className="text-right n700">{formatPrice(item.amountWithoutSubsidy)}</td>
                <td className="text-right n700">{item.techPayoutFormatted}</td>
                <td aria-label="blank cell" />
              </tr>
            );
          })}
          {project.payoutAdjustments.map(adj => {
            const adjustmentValueStyles = getAdjustmentValueStyles(adj);
            const adjustmentName = getAdjustmentName(adj);
            return (
              <tr key={`adj-${adj.id}`} className="p2 cursorPointer" onClick={() => setIsActive(!isActive)}>
                <td aria-label="blank cell" />
                <td className="paddingBottom-small text-weight-med n800">{adjustmentName}</td>
                <td aria-label="blank cell" />
                <td aria-label="blank cell" />
                <td className={adjustmentValueStyles}>{adj.totalTechPayoutAdjustmentFormatted}</td>
                <td aria-label="blank cell" />
              </tr>
            );
          })}
          <tr className="border-bottom" />
        </>
      )}
    </>
  );
};

export default ServiceDetails;
