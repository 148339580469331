import React, {ReactNode} from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';
import {ELEMENT_SIZE, Icon} from 'ht-styleguide';
import {isPreLaunchStatus} from 'features/MultiDwellingUnits/MDU.utils';
import ChipStatus from 'features/MultiDwellingUnits/Parts/Chip';
import {numericDayMonthYear} from 'global/constants/common';
import TableActionMenu from 'components/Elements/DataTable/TableActionMenu';
import {sortAlphabeticallyOrNumerically, sortByDate, sortByBoolean} from 'components/Elements/DataTable/utils/sort.utils';
import {actionMenuColumn} from 'components/Elements/DataTable/utils/columns.utils';

/* Types */
import {ProjectDetailsShallow} from 'features/MultiDwellingUnits/MDU.types';
import {TDataTableColumnDef} from 'components/Elements/DataTable/dataTable.types';

import styles from './MDUProjectsTable.styles.scss';
import getCustomActionByStatus from '../../utils/utils.getCustomActionByStatus';

// TODO: The lowercase 'l' is technically incorrect; is actually uppercase 'L';
const formatDate = (date: string) => dayjs(date).format(numericDayMonthYear);

/**
 * Generates column configurations for the MDUProjectsTable based on the specified column keys.
 * Each key corresponds to a column defined in PROJECT_PAGE_PARAMS.
 * The function prepares a "master list" of data to be used in each configuration.
 *
 * For detailed column definitions, refer to src/features/MultiDwellingUnits/Pages/Projects/Projects.constants.ts.
 */
export const createColumnsByKey = ({onCellClick, dispatch}: {onCellClick: (arg0: any) => void; dispatch: any}): {[tableKey: string]: TDataTableColumnDef<ProjectDetailsShallow>} => {
  const keyedColumnData: {[tableKey: string]: TDataTableColumnDef<ProjectDetailsShallow>} = {
    name: {
      header: 'Name',
      id: 'property_owner.name',
      accessorFn: originalRow => originalRow.property_owner?.name || '',
      Cell: ({cell, row}) => {
        const p = row.original;
        return (
          <span data-for="overridePosition" data-tip={`${p.status}|${p.id}|${cell.getValue()}`}>
            {p.name}
            <div className={styles.propertOwner}>{cell.getValue() as ReactNode}</div>
          </span>
        );
      },
      muiTableBodyCellProps: ({row}) => {
        return {
          className: isPreLaunchStatus(row.original.status!) ? '' : 'cursorPointer',
          onClick: () => onCellClick(row.original),
        };
      },
      sortingFn: sortAlphabeticallyOrNumerically,
      columnWidthMode: 'fill',
      columnWidthSize: 'lg',
    },
    projecttype: {
      header: 'Project Type',
      id: 'project_type.name',
      accessorFn: originalRow => originalRow.project_type?.name || '',
      muiTableBodyCellProps: ({row}) => {
        return {
          onClick: () => onCellClick(row.original),
        };
      },
      sortingFn: sortAlphabeticallyOrNumerically,
      columnWidthMode: 'fill',
      columnWidthSize: 'sm',
    },
    partner: {
      header: 'Partner',
      id: 'partner.name',
      accessorFn: originalRow => originalRow.partner?.name || '',
      muiTableBodyCellProps: ({row}) => {
        return {
          onClick: () => onCellClick(row.original),
        };
      },
      sortingFn: sortAlphabeticallyOrNumerically,
      columnWidthMode: 'fill',
      columnWidthSize: 'sm',
    },
    start: {
      header: 'Start',
      id: 'start_date',
      accessorFn: originalRow => originalRow.start_date && formatDate(originalRow.start_date),
      muiTableBodyCellProps: ({row}) => {
        return {
          onClick: () => onCellClick(row.original),
        };
      },
      sortingFn: sortByDate,
      columnWidthMode: 'fill',
      columnWidthSize: 'sm',
    },
    end: {
      header: 'End',
      id: 'estimated_end_date',
      accessorFn: originalRow => originalRow.estimated_end_date && formatDate(originalRow.estimated_end_date),
      muiTableBodyCellProps: ({row}) => {
        return {
          onClick: () => onCellClick(row.original),
        };
      },
      sortingFn: sortByDate,
      columnWidthMode: 'fill',
      columnWidthSize: 'sm',
    },
    pm: {
      header: 'PM',
      id: 'project_manager.name',
      accessorFn: originalRow => originalRow.project_manager?.name || '',
      muiTableBodyCellProps: ({row}) => {
        return {
          onClick: () => onCellClick(row.original),
        };
      },
      sortingFn: sortAlphabeticallyOrNumerically,
      columnWidthMode: 'fill',
      columnWidthSize: 'sm',
    },
    teamlead: {
      header: 'Team Lead',
      id: 'lead_tech.name',
      accessorFn: originalRow => originalRow?.lead_tech?.name || '',
      muiTableBodyCellProps: ({row}) => {
        return {
          onClick: () => onCellClick(row.original),
        };
      },
      sortingFn: sortAlphabeticallyOrNumerically,
      columnWidthMode: 'fill',
      columnWidthSize: 'sm',
    },
    approved: {
      header: 'Approved',
      id: 'approved_at',
      accessorFn: originalRow => originalRow?.approved_at && formatDate(originalRow?.approved_at),
      muiTableBodyCellProps: ({row}) => {
        return {
          onClick: () => onCellClick(row.original),
        };
      },
      sortingFn: sortByDate,
      columnWidthMode: 'fill',
      columnWidthSize: 'sm',
    },
    flagged: {
      header: 'flagged',
      Header: <Icon name="flag-outline" className={styles.flag} />,
      accessorKey: 'flagged',
      Cell: ({cell}) => {
        return cell.getValue() ? <Icon name="flag-outline" className={cn(styles.flag, styles.red)} /> : null;
      },
      muiTableBodyCellProps: ({row}) => {
        return {
          className: styles.flagIconTable,
          onClick: () => onCellClick(row.original),
        };
      },
      columnWidthMode: 'collapse',
    },
    status: {
      header: 'Status',
      accessorKey: 'status',
      Cell: ({row}) => {
        const {status} = row.original;
        return (
          <ChipStatus status={status!} size={ELEMENT_SIZE.SMALL}>
            {status}
          </ChipStatus>
        );
      },
      muiTableBodyCellProps: ({row}) => {
        return {
          onClick: () => onCellClick(row.original),
        };
      },
      sortingFn: sortAlphabeticallyOrNumerically,
      columnWidthMode: 'fill',
      columnWidthSize: 'md',
    },
    openIssuesCount: {
      header: 'Open Issues',
      accessorKey: 'open_issues_count',
      muiTableBodyCellProps: ({row}) => {
        return {
          onClick: () => onCellClick(row.original),
        };
      },
      sortingFn: sortAlphabeticallyOrNumerically,
      columnWidthMode: 'fill',
      columnWidthSize: 'sm',
    },
    paused: {
      header: 'Paused',
      id: 'paused',
      accessorFn: ({paused}) => (paused ? 'Yes' : 'No'),
      muiTableBodyCellProps: ({row}) => {
        return {
          onClick: () => onCellClick(row.original),
        };
      },
      sortingFn: sortByBoolean,
      columnWidthMode: 'collapse',
    },
    actionMenu: {
      ...actionMenuColumn(),
      Cell: ({row}) => {
        const {actionMenu: ca} = getCustomActionByStatus({project: row.original as ProjectDetailsShallow, dispatch}) || [];
        const isDisabled = !Array.isArray(ca) || (ca && !ca.length);
        return <TableActionMenu isDisabled={isDisabled} items={ca} />;
      },
    },
  };

  return keyedColumnData;
};
