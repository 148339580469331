import React, {useState} from 'react';
import debounce from 'lodash/debounce';
import TableToolbar from 'components/Table/TableToolbar';
import TableToolbarLink from 'components/Table/TableToolbar/TableToolbar.Link';
import {UnassignedFilterKeys} from 'features/Dispatch/dispatch.types';
import useTableToolbarChips from 'utils/table/useTableToolbarFilterChips';
import useSetUnassignedFilters from '../../hooks/useSetUnassignedFilters';
import UnassignedTableToolbarColumnEditing from './UnassignedTableToolbar.ColumnEditing';
import UnassignedFiltersModal from './UnassignedTableToolbar.FiltersModal';

const UnassignedTableToolbar = () => {
  const [isFiltersModalVisible, setIsFiltersModalVisible] = useState(false);
  const toggleFiltersModal = () => setIsFiltersModalVisible(!isFiltersModalVisible);

  const {filters: stateFilters, rawFilters: stateRawFilters, updateFilters} = useSetUnassignedFilters();
  const {filtersChips, filtersCount} = useTableToolbarChips({filters: stateFilters, rawFilters: stateRawFilters, omitFilters: [UnassignedFilterKeys.ActiveOffers]});

  const clearFilter = (filterKey: string) => {
    if (filterKey) {
      updateFilters({filters: {[filterKey]: ''}});
    } else {
      updateFilters({filters: {}, replace: true});
    }
  };

  const showAllOrders = !(UnassignedFilterKeys.ActiveOffers in (stateFilters || {}));
  const activeOfferIconName = showAllOrders ? 'eye-crossed' : 'eye'; // think opposite
  const activeOfferLabel = showAllOrders ? 'Hide jobs with active offers' : 'Show jobs with active offers';
  const toggleShowAllOrders = () => {
    if (showAllOrders) {
      // When showing all orders, then on click, we want to hide active offers
      updateFilters({filters: {[UnassignedFilterKeys.ActiveOffers]: false}});
    } else {
      // Reset to show all orders regardless of offer status (active/inactive)
      const newFilters = {...stateFilters};
      delete newFilters[UnassignedFilterKeys.ActiveOffers];
      updateFilters({filters: newFilters, replace: true});
    }
  };

  const onSearchInputChange = debounce((value: string) => {
    updateFilters({filters: {search: value}});
  }, 1500);

  const {search: searchValue} = stateFilters;

  const ToolbarLinks = (
    <>
      <TableToolbarLink label={activeOfferLabel} iconName={activeOfferIconName} onClick={toggleShowAllOrders} />
      <UnassignedTableToolbarColumnEditing />
    </>
  );

  return (
    <>
      <TableToolbar
        clearFilter={clearFilter}
        filtersChips={filtersChips}
        filtersCount={filtersCount}
        searchFilterKey="search"
        searchInputValue={searchValue as string}
        searchOnInputChange={onSearchInputChange}
        searchPlaceholderText="Search jobs"
        toggleFiltersModal={toggleFiltersModal}
        ToolbarLinks={ToolbarLinks}
      />
      <UnassignedFiltersModal isVisible={isFiltersModalVisible} toggleModal={toggleFiltersModal} />
    </>
  );
};

export default UnassignedTableToolbar;
