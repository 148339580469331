import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useMutation} from 'react-query';

/* Hooks */
import useApi from 'hooks/useApi';

/* Utils */
import {logger} from 'utils/logger';
import {handleResponseError, showErrorToast} from 'queries/query.utils';

import {MduUseParamsTypes, TGroupSelectSkuServices} from '../MDU.types';

/**
 * This adds 1 unit to a job
 */
type TAddUnitArgs = {
  project_group_id: string | number;
  project_group_name: string | null;
  unit_name: string | number;
  services?: TGroupSelectSkuServices[];
};

export const useAddUnitToJobMutation = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const {projectId} = useParams<MduUseParamsTypes>();

  return useMutation(
    async (units: TAddUnitArgs) => {
      api.toggleLoader(true);
      const response = await api.mdu.addUnitToJob(
        {project_id: projectId},
        {
          unit: units,
          async: false,
          forceCase: 'camel',
        }
      );
      api.toggleLoader(false);

      handleResponseError(response);

      return response?.data?.projectAction;
    },
    {
      onError: err => {
        logger('Error Approve Projects Action Mutation: ')(err as Error);
        showErrorToast(dispatch)({errors: `Error Add Unit Mutation: ${err}`});
      },
    }
  );
};
