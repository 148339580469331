import {checkOrderHasStarted, checkOrderHasClosed} from 'features/Orders/utils/status/order.status.utils';
import {TOrderDetails} from 'features/Orders/types/orders.types';
import {SERVICE_TASK_ITEM_PANEL_ID} from './orderTaskChecklist.constants';
import {TOrderTaskChecklist} from './types/orderTaskChecklist.types';

export const checkIfOrderUsesTaskFlow = (checklist: TOrderTaskChecklist | null | undefined) => (checklist ? checklist?.task_groups?.length > 0 : false);

export const checkCanModifyTask = (order: TOrderDetails | undefined | null) => checkOrderHasStarted({order});

export const checkCanShareChecklist = (order: TOrderDetails | undefined | null) => checkOrderHasClosed({order});

export const getServiceTasksCount = (checklist: TOrderTaskChecklist) => {
  if (checklist) {
    let total = 0;
    let completed = 0;
    checklist.task_groups.forEach(group => {
      group.tasks.forEach(task => {
        total += 1;
        if (task.answer?.complete) {
          completed += 1;
        }
      });
    });
    return {total, completed};
  }
  return null;
};

export const generateTaskPanelId = (taskId: number) => `${SERVICE_TASK_ITEM_PANEL_ID}${taskId}`;
