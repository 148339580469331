import {FlaggedEnums} from 'features/MultiDwellingUnits/Pages/Projects/Parts/MDUProjectsFilters/MDUProjectsFilters.types';
import {MDUUnitStatuses} from 'features/MultiDwellingUnits/MDU.types';
import {TColorVariant} from 'components/Elements/DataWidget/dataWidget.types';

type TStatAttribute = {
  [key: string]: {
    spread: {
      icon: string;
      colorVariant: TColorVariant;
      label: string;
    };
    filters: {
      [key: string]: any;
    };
  };
};
export const STAT_JOBS_PROPS: TStatAttribute = {
  flagged: {
    spread: {
      icon: 'flag-outline',
      colorVariant: 'red',
      label: 'Issues Flagged',
    },
    filters: {
      only_flagged: FlaggedEnums.TRUE,
    },
  },
  complete: {
    spread: {
      icon: 'checkmark-square',
      colorVariant: 'green',
      label: 'Completed',
    },
    filters: {
      statuses: [MDUUnitStatuses.COMPLETED],
    },
  },
  unserviceable: {
    spread: {
      icon: 'unserviceable',
      colorVariant: 'red',
      label: 'Unserviceable',
    },
    filters: {
      statuses: [MDUUnitStatuses.UNSERVICEABLE],
    },
  },
  pendingApproval: {
    spread: {
      icon: 'checkmark-rounded',
      colorVariant: 'yellow',
      label: 'Pending Approval',
    },
    filters: {
      statuses: [MDUUnitStatuses.PENDING_APPROVAL],
    },
  },
  projectProgress: {
    spread: {
      icon: 'briefcase',
      colorVariant: 'blue',
      label: 'Project Progress',
    },
    filters: {},
  },
};
