import React from 'react';
import cn from 'classnames';

import {ChildrenProp} from 'types/base.types';

import styles from './styles.scss';

const CardBase = ({children, classes}: {children?: ChildrenProp; classes: string}) => {
  if (!children) return null;

  return <div className={cn(styles.cardContainer, classes)}>{children}</div>;
};

export default CardBase;
