/* ------------- Booking Routes ---------------- */
const BookingRoutes = {
  BOOKING: () => '/booking',
  BOOKING_SERVICES: () => '/booking/services',
  BOOKING_SERVICES_ADD_SKU: () => '/booking/add-sku/:id',
  BOOKING_CUSTOMER_INFO: () => '/booking/customer-info',
  BOOKING_FOLLOWUP_ORDER: () => '/booking/followup',
  BOOKING_SCHEDULING: () => '/booking/scheduling',
  BOOKING_BILLING: () => '/booking/billing',
  BOOKING_ORDER_CONFIRMATION: () => '/booking/confirmation/:id',
  BOOKING_ORDER_BOOKINGTYPE_CONFIRMATION: () => '/booking/confirmation/:bookingType/:id',
  BOOKING_ORDER_MEMBERSHIP_CONFIRMATION: () => '/booking/confirmation/membership',
};

/* -------------  Membership Routes-------------  */
const MembershipRoutes = {
  MEMBERSHIP: () => '/membership',
};

/* -------------  Profile Routes-------------  */
const ProfileRoutes = {
  PROFILE: () => '/profile',
};

/* ------------- MDU Routes ---------------- */
const MDURoutes = {
  // Admin Pane
  MDU: () => '/mdu',
  MDU_PROJECT_PAGE: () => '/mdu/project/:projectId/:stage',
  MDU_PROJECT_PAGE_BY_STAGE: () => '/mdu/project/:projectId/:stage',
  MDU_PROJECTS_PAGE_GROUP: () => '/mdu/project/:projectId/:stage/:groupId',

  /* ------- Template/Jobs: Dealing with Template Jobs Flow ---------- */
  MDU_PROJECTS_PAGE_GROUP_SERVICES: () => '/mdu/project/:projectId/:stage/:groupId/sku',
  MDU_PROJECTS_PAGE_GROUP_SERVICES_SKU: () => '/mdu/project/:projectId/:stage/:groupId/sku/:skuId',
  MDU_PROJECTS_PAGE_GROUP_SERVICES_SKU_EDIT: () => '/mdu/project/:projectId/:stage/:groupId/sku/:skuId/service/:serviceId/edit',
  // post launch specific
  MDU_PROJECTS_PAGE_POSTLAUNCH_GROUP_SERVICES: () => '/mdu/project/:projectId/templates/postlaunch/:groupId/sku',
  MDU_PROJECTS_PAGE_POSTLAUNCH_GROUP_SERVICES_SKU: () => '/mdu/project/:projectId/templates/postlaunch/:groupId/sku/:skuId',
  MDU_PROJECTS_PAGE_POSTLAUNCH_GROUP_SERVICES_SKU_EDIT: () => '/mdu/project/:projectId/templates/postlaunch/:groupId/sku/:skuId/service/:serviceId/edit',

  /* ---- Job: Dealing with Bulk Flow ---- */
  MDU_PROJECTS_PAGE_JOB_BULK_BASE: () => '/mdu/project/:projectId/jobs/bulk-create',
  MDU_PROJECTS_PAGE_JOB_BULK_BASE_EXISTING: () => '/mdu/project/:projectId/jobs/bulk-create/existing-templates',
  MDU_PROJECTS_PAGE_JOB_BULK_BASE_PROCESSING: () => '/mdu/project/:projectId/jobs/bulk-create/processing',
  MDU_PROJECTS_PAGE_JOB_BULK_GROUP: () => '/mdu/project/:projectId/jobs/bulk-create/new-templates/:groupId/sku',
  MDU_PROJECTS_PAGE_JOB_BULK_GROUP_SKU: () => '/mdu/project/:projectId/jobs/bulk-create/new-templates/:groupId/sku/:skuId',
  MDU_PROJECTS_PAGE_JOB_BULK_SERVICES_SKU_EDIT: () => '/mdu/project/:projectId/jobs/bulk-create/new-templates/:groupId/sku/:skuId/service/:serviceId/edit',

  /* ---- Job: Dealing with Single Create Flow ---- */
  MDU_PROJECTS_PAGE_JOB_SINGLE_BASE: () => '/mdu/project/:projectId/jobs/create',
  MDU_PROJECTS_PAGE_JOB_SINGLE_GROUP_SKU: () => '/mdu/project/:projectId/jobs/create/sku/:skuId',
  MDU_PROJECTS_PAGE_JOB_SINGLE_SERVICES_SKU_EDIT: () => '/mdu/project/:projectId/jobs/create/sku/:skuId/service/:serviceId/edit',

  MDU_PROJECTS_PAGE: () => '/mdu/projects/:projectsType/:projectsPage',
};

/* -------------  Issues Routes -------------  */
const IssuesRoutes = {
  ISSUES_PATH_BASE: () => '/issues',
  ISSUES_NAV_TYPE: () => '/issues/:issuesNavType',
  ISSUES_NAV_TYPE_KEY: () => ':issuesNavType',
};

/* -------------  Dispatch Routes -------------  */
const DispatchRoutes = {
  DISPATCH_PATH_BASE: () => '/dispatch',
  DISPATCH_NAV_TYPE: () => '/dispatch/:dispatchNavType',
  DISPATCH_NAV_TYPE_KEY: () => ':dispatchNavType',
};

/* -------------  Products Routes -------------  */
const ProductsRoutes = {
  PRODUCTS_PATH_BASE: () => '/products',
  PRODUCTS_DETAILS: () => '/products/:id',
  PRODUCTS_TASK_CHECKLIST: () => '/products/:id/task_checklist',
};

/* -------------  Orders Routes -------------  */
const OrdersRoutes = {
  ORDERS_PATH_BASE: () => '/orders',
  ORDERS_DETAILS: () => '/orders/:id',
  ORDERS_TASK_CHECKLIST: () => '/orders/:id/task_checklist', // Loading page to redirect to ORDERS_TASK_CHECKLIST_BY_SERVICE_ID
  ORDERS_TASK_CHECKLIST_BY_SERVICE_ID: () => '/orders/:id/services/:serviceId/task_checklist',
};

/**
 * OVERALL ROUTE SCHEMA
 * example of param: (id: number) => `/sku/${id}`
 */
export const Routes = {
  HOME: () => '/',
  LOGIN: () => '/login',
  ...BookingRoutes,
  ...MembershipRoutes,
  ...ProfileRoutes,
  ...MDURoutes,
  ...IssuesRoutes,
  ...DispatchRoutes,
  ...ProductsRoutes,
  ...OrdersRoutes,
};
