import {createSelector, createSlice} from '@reduxjs/toolkit';

export type EditorState = {
  editorContents: {
    [key: string]: object;
  };
};

/*
 ********* INITIAL STATE
 */
const initialState: EditorState = {
  editorContents: {},
};

/*
 ************ SLICE
 */
const {actions, reducer} = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    setEditorContents: (state, action) => {
      const {editorNamespace, content} = action.payload;
      state.editorContents = {
        ...state.editorContents,
        [editorNamespace]: content,
      };
    },
  },
  extraReducers: () => {},
});

/*
 ************ EXPORTS
 */

/*
*******************************************************
  SELECTORS & SELECTOR METHODS
*******************************************************
*/
const getEditorState = (state: any) => state.editor;

/*
*******************************************************
  EXPORTS
*******************************************************
*/

const selectors = {
  getEditorContentState: (editorNamespace: string) => createSelector(getEditorState, editor => editor.editorContents?.[editorNamespace] ?? null),
};

export default {
  actions,
  reducer,
  initialState,
  selectors,
};
