// export const isFunction = <T>(functionToCheck: T) => {
//   return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
// };

/**
 * Create a promise sleeper method
 *
 * Usage: sleep(1000).then(action.doSomething())
 *        within an async method: await sleep(1000);
 */
export const sleep = (time: number) => new Promise(resolve => setTimeout(resolve, time));
