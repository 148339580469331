import React, {memo, useMemo} from 'react';
import cn from 'classnames';
import {Button, BUTTON_THEMES} from 'ht-styleguide';
import {ButtonPropsType, IPageStatusBar} from './pageStatusBar.types';
import styles from './pageStatusBar.styles.scss';

/**
 * Intended to be rendered at the top of a page.
 *
 * Displays header text on the left side and a CTA button on the right. Optional components
 * can be displayed to the left of the CTA.
 */
const PageStatusBar = ({ctaButtonLabel, ctaButtonOnClick, ctaButtonProps, headerText, linkText, linkOnClick, theme = 'tealGreen'}: IPageStatusBar) => {
  /**
   * Overrides on the button component based on this component's theme prop
   */
  const finalCTAButtonProps = useMemo(() => {
    const themePresets: Partial<Pick<ButtonPropsType, 'theme' | 'className'>> = (() => {
      switch (theme) {
        case 'neutral': {
          return {
            theme: BUTTON_THEMES.TRANSPARENTWHITE,
          };
        }
        case 'tealGreen': {
          return {
            theme: BUTTON_THEMES.INVERTED,
            className: styles.buttonInvertedOverride,
          };
        }
        default: {
          return {
            theme: BUTTON_THEMES.DEFAULT,
          };
        }
      }
    })();

    const {className, ...rest} = ctaButtonProps || {};
    const buttonProps: ButtonPropsType = {
      children: ctaButtonLabel,
      className: cn(styles.rightComponent, themePresets.className, className),
      medium: true,
      onClick: ctaButtonOnClick,
      theme: themePresets.theme,
      ...(rest || {}),
    };
    return buttonProps;
  }, [ctaButtonLabel, ctaButtonOnClick, ctaButtonProps, theme]);

  const shouldDisplayCTA = !!(ctaButtonLabel || ctaButtonProps);
  const linkStyles = cn('plainButton', styles.rightComponent);
  const linkTextStyles = cn('p1 n000 text-underline cursorPointer', styles.linkText);
  const containerStyles = cn(styles.container, styles[theme], 'flex align-items-center justify-content-space-between paddingY-small paddingX-small2');

  return (
    <div className={containerStyles}>
      <div>{headerText && <h5 className="n000">{headerText}</h5>}</div>
      <div className="flex align-items-center">
        {linkText && (
          <button type="button" onClick={linkOnClick} className={linkStyles}>
            <p className={linkTextStyles}>{linkText}</p>
          </button>
        )}
        {shouldDisplayCTA && <Button {...finalCTAButtonProps} />}
      </div>
    </div>
  );
};

export default memo(PageStatusBar);
