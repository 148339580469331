import React, {useCallback, useEffect} from 'react';
import {useParams} from 'react-router-dom';

// hooks and ducks
import {unwrapResult} from '@reduxjs/toolkit';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useSelector} from 'hooks/useAppSelector';
import {wholeHomeActions, profileSelectors} from 'features/Profile/profile.ducks';

/* Components */
import CustomerDetails from 'features/Profile/Parts/Blocks/profile.CustomerDetails';
import WholeHomeDashboard from 'features/Profile/Parts/Blocks/profile.Dashboard';

/* Styles */
import styles from 'features/Profile/profile.styles.scss';

const Main = () => {
  const {id = ''} = useParams<{id: string}>();
  const reduxDispatch = useAppDispatch();
  // selectors
  const profile = useSelector(profileSelectors.getProfileState);

  const runReducers = useCallback(async () => {
    const result = await reduxDispatch(wholeHomeActions.getClientInformation({id}));
    const {
      data: {client},
    } = unwrapResult(result);
    if (client?.subscription) reduxDispatch(wholeHomeActions.getMembershipInfo({id, subscriptionId: client?.subscription?.id}));
    reduxDispatch(wholeHomeActions.getDevicesList({id}));
    reduxDispatch(wholeHomeActions.getOrdersList({id}));
    reduxDispatch(wholeHomeActions.getClientStats({id}));
  }, [id, reduxDispatch]);

  useEffect(() => {
    if (id) {
      runReducers();
    }
  }, [id, runReducers]);

  return (
    <main className={styles.main}>
      <CustomerDetails client={profile.client} />
      <WholeHomeDashboard profile={profile} />
    </main>
  );
};

export default Main;
