/**
 ***************************************************************
 * Types that are core to an order object. Generally, these types
 * are directly derived from the DB schema.
 ***************************************************************
 */

/**
 * Represents `status_id` of `orders` table.
 */
export enum StatusId {
  PENDING = 0,
  SEARCHING = 1,
  ASSIGNED = 2,
  COMPLETED = 3,
  CANCELLED = 4,
  PAID = 5, // Tech Paid
  ABANDONED = 6,
  CANCELLED_BY_GEEK = 7, // deprecated
  MASTER_CLIENT_APPROVAL = 8, // deprecated
  UNSERVICEABLE = 9,
  PAUSED = 10, // deprecated
  READY_TO_RESUME = 11, // deprecated id. no active orders has this status - J.Ko 12/18/2020
  INCOMPLETE = 12,
  LATE_TECH = 13,
  PENDING_SHIPPING = 14,
  PENDING_APPROVAL = 15,
}

/**
 * Possible values for `orders.status`, which are mainly derived from `status_id` of `orders` table.
 * However, there are a few exceptions. Ask a BE team member about it.
 */
export enum OrderStatus {
  Pending = 'Pending',
  Searching = 'Searching',
  Assigned = 'Assigned',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  TechPaid = 'Tech Paid',
  Abandoned = 'Abandoned',
  Unserviceable = 'Unserviceable',
  Paused = 'Paused',
  ReadyToResume = 'Ready to Resume',
  Incomplete = 'Incomplete',
  LateTech = 'Late Tech',
  PendingShipping = 'Pending Shipping',
  PendingApproval = 'Pending Approval',
  Unredeemed = 'Unredeemed', // an abandoned API order
}

/**
 * Represents `progress_status_id` of `jobs` table.
 */
export enum ProgressStatusId {
  CLAIMED = 0,
  EN_ROUTE = 1,
  STARTED = 2,
  COMPLETED = 3,
  CANCELLED = 4,
  LEFT_FEEDBACK = 5,
  PAUSED = 6, // deprecated id, but used as a workaround for standby times
  READY_TO_RESUME = 7, // deprecated id.
  INCOMPLETE = 8,
}

/**
 * Represents `status_id` of `services` table.
 */
export enum ServiceStatusId {
  PENDING = 0,
  SEARCHING = 1,
  ASSIGNED = 2,
  COMPLETED = 3,
  CANCELLED = 4,
  PAID = 5,
  UNSERVICEABLE = 6,
}

/**
 * Possible values for `services.status`, which are mainly derived from `status_id` of `services` table.
 */
export enum ServiceStatus {
  Pending = 'Pending',
  Searching = 'Searching',
  Assigned = 'Assigned',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Paid = 'Paid',
  Unserviceable = 'Unserviceable',
}
