import React from 'react';
import {IBaseTask} from '../../serviceTasksList.types';
import NoInfoCalloutBox from './NoInfoCalloutBox';
import SectionWrapper from './BlockWrapper';
import styles from './serviceTask.styles.scss';

/**
 * For the device task type, display the device make and model if available.
 */
const DeviceBlock = ({task}: IBaseTask) => {
  const deviceResponse = task?.answer?.device_response;
  const {name: productMakeName} = deviceResponse?.product_make || {};
  const {id: productModelId, name: productModelName, image_url: productImageUrl} = deviceResponse?.product || {};
  const hasDeviceData = typeof productModelId === 'number';

  return hasDeviceData ? (
    <SectionWrapper title="Device">
      <div className="flex align-items-center">
        {productImageUrl && <img src={productImageUrl} alt={productModelName} className={styles.productImage} />}
        <p className="p2 n800">
          {productMakeName}
          <br />
          {productModelName}
        </p>
      </div>
    </SectionWrapper>
  ) : (
    <NoInfoCalloutBox />
  );
};

export default DeviceBlock;
