import React, {ComponentProps} from 'react';
import {SelectField, ELEMENT_SIZE} from 'ht-styleguide';
import {TSharedFormFieldProps} from '../../dataFormFields.types';

type TBaseFilterSelectField = TSharedFormFieldProps & {
  options: Array<{label: string; value: any}>;
} & Partial<ComponentProps<typeof SelectField>>;

const BaseFilterSelectField = ({dataTestId = 'DataBase-SelectField', fieldName, formik, label, multiple, onChangeCB, options, ...rest}: TBaseFilterSelectField) => {
  const onChange: ComponentProps<typeof SelectField>['onChange'] = selected => {
    let formattedValue;
    let onChangeCBValues = [];

    if (multiple) {
      formattedValue = selected?.map((val: {value: string | number}) => val.value) ?? [];
      onChangeCBValues = selected?.map((val: {label: string; value: string | number}) => ({label: val.label, value: val.value}));
    } else {
      formattedValue = selected?.value ?? '';
      const selectedLabel = selected?.label;
      onChangeCBValues.push({label: selectedLabel, value: formattedValue});
    }

    formik.handleChange({target: {name: fieldName, value: formattedValue}});
    onChangeCB?.({name: fieldName, label: label || fieldName, values: onChangeCBValues});
  };

  const value = formik.values[fieldName];
  const error = formik.errors[fieldName]?.toString() || undefined;

  return (
    <SelectField
      clearable
      dataTestId={dataTestId}
      elementSize={ELEMENT_SIZE.MEDIUM}
      error={error}
      label={label}
      multiple={multiple}
      onChange={onChange}
      options={options}
      searchable
      value={value}
      {...rest}
    />
  );
};

export default BaseFilterSelectField;
