import {VIDEO_FILE_EXTENSIONS} from '../../components/Elements/ThumbnailDisplay/thumbnail.constants';

export const IMAGE_ALLOWED_FILES = ['png', 'jpg', 'jpeg', 'gif'];
export const RAW_ALLOWED_FILES = ['pdf', 'xls', 'xlsx', 'csv', 'txt'];
export const ALLOWED_FILES = [...IMAGE_ALLOWED_FILES, ...RAW_ALLOWED_FILES, ...VIDEO_FILE_EXTENSIONS];
export const RESOURCE_TYPE_IMAGE = 'image';
export const RESOURCE_TYPE_RAW = 'raw';

export const FILE_UPLOAD_VALIDATIONS = {
  /**
   * Maximum file size in bytes. Default is 5MB,
   * Used as a default for all file upload components.
   */
  MAX_FILE_SIZE: 5 * 1024 * 1024,
  ALLOWED_FILE_EXTENSIONS: (addl: string[] = []) => [...ALLOWED_FILES, ...addl],
  ALLOWED_FILE_ACCEPT: (addl: string[] = []) => [...ALLOWED_FILES.map(file => `.${file}`), ...addl],
  READABLE_MAX_FILE_SIZE: '5MB',
};
