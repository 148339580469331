import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import cn from 'classnames';
import {stopPropagationOnDiv} from 'utils/event';
import PageHeader from 'components/Elements/PageHeader';
import {useMduApi} from 'features/MultiDwellingUnits/MDU.hooks';
import ModalChangeGroupName from 'features/MultiDwellingUnits/Parts/Modals/MDU.Modal.ChangeGroupName';

import {useUpdateJobTemplateDraftMutation} from 'features/MultiDwellingUnits/queries/mutation.jobs.drafts';

import {MduUseParamsTypes, ProjectGroup} from 'features/MultiDwellingUnits/MDU.types';

type TDefaultEditName = {
  id: string | number;
  name: string;
  currentName: string;
};

type TGroupEditNameProps = {
  group: ProjectGroup;
  titleStyles?: string;
  iconStyles?: string;
  draft?: boolean;
};

const defaultEditName: TDefaultEditName = {
  id: '',
  name: '',
  currentName: '',
};

const GroupNameEdit = ({group, titleStyles, iconStyles, draft}: TGroupEditNameProps) => {
  /* Local State */
  const [editName, setEditName] = useState<TDefaultEditName>(defaultEditName);

  /* Hooks */
  const api = useMduApi();
  const {projectId} = useParams<MduUseParamsTypes>();

  /* Queries / mutations */
  const {mutate} = useUpdateJobTemplateDraftMutation();

  /* ---------------- METHODS ------------------- */
  const onHandleEditName = (templateGroup: ProjectGroup) => (e: React.MouseEvent<HTMLButtonElement> | string) => {
    const rawName = typeof e === 'string' ? e : e.currentTarget.value;
    const name = rawName.trim();

    setEditName({
      name,
      id: templateGroup.id,
      currentName: templateGroup.name,
    });
  };

  /* Close out the modal. Reset the local state to empty defaults */
  const onHandleExitName = () => {
    setEditName(defaultEditName);
  };

  /* Dispatch to update the name via api */
  const onHandleDispatchUpdateGroupName = (name: string) => {
    onHandleExitName();

    /* If the user just entered nothing and tried to update, they ain't stupid. Leave it, other wise, update */
    if (name) {
      if (draft) {
        mutate({projectGroupId: group.id, projectGroups: {name}});
      } else {
        api.updateGroup({projectId, projectGroupId: group.id, projectGroups: {name}});
      }
    }
  };

  if (!group) return null;

  return (
    <div onClick={stopPropagationOnDiv} className="flex align-items-baseline">
      <PageHeader.TitleText title={group.name} className={cn('marginRight-tiny1', titleStyles)} />
      <PageHeader.Icon iconName="edit-plain" className={cn(iconStyles)} onClick={() => onHandleEditName(group)(group.name || '')} />
      <ModalChangeGroupName dispatchUpdateGroupName={onHandleDispatchUpdateGroupName} currentName={editName.currentName} onCloseCallback={onHandleExitName} isVisible={Boolean(editName.id)} />
    </div>
  );
};

export default GroupNameEdit;
