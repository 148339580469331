import React from 'react';
import {useParams} from 'react-router-dom';
import cn from 'classnames';
import {Button, BUTTON_THEMES, Panel, EmptyState} from 'ht-styleguide';

/* Hooks */
import {useCurrentProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/CurrentProject.hooks';
import {useDebounceUnitQty} from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/TemplateJobs.hooks';
import {useMduApi} from 'features/MultiDwellingUnits/MDU.hooks';

/* Components */
import PageHeader from 'components/Elements/PageHeader';
import ProjectLevelUnitUpdate from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/Parts/TemplateJobs.ProjectUnit';

/* Types */
import {MduUseParamsTypes} from 'features/MultiDwellingUnits/MDU.types';

import styles from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/templatejobs.styles.scss';

const EmptyStateProjectGroups = () => {
  const {unitsNumber} = useCurrentProject();
  const [inputValue, setInputValue] = React.useState(unitsNumber);
  const isValidInput = +inputValue > 0;

  /* Hooks */
  const {projectId} = useParams<MduUseParamsTypes>();
  const {updateUnitQtyProject} = useDebounceUnitQty();
  const api = useMduApi();

  /* Methods */
  const onHandleCreateTemplate = () => {
    api.addGroup(projectId, inputValue);
  };

  const onHandleInputTotal = (inputVal: string) => {
    setInputValue(inputVal);
    updateUnitQtyProject(inputVal, unitsNumber);
  };

  return (
    <div className="marginBottom-medium2">
      <PageHeader title="Templates & Jobs" HeaderRightContent={<ProjectLevelUnitUpdate inputValue={inputValue} text="Total Count" valueSetter={onHandleInputTotal} />} />
      <Panel noShadow largeBorderRadius className={cn(styles.panel, 'padding-large')}>
        <EmptyState
          iconName="layers"
          title="Create a template for this project"
          text="Templates are used to assign a set of SKUs to a unit. A project must have at least one template to be launched."
          showButtonContainer
        >
          <Button disabled={!isValidInput} theme={BUTTON_THEMES.SECONDARY} onClick={onHandleCreateTemplate}>
            Create Template
          </Button>
        </EmptyState>
      </Panel>
    </div>
  );
};

export default EmptyStateProjectGroups;
