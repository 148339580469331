import {TColorVariant} from 'components/Elements/DataWidget/dataWidget.types';
import {IssuesSearchFieldNames} from 'features/Issues/issues.types';

export const WIDGET_HELPER = {
  [IssuesSearchFieldNames.Open]: {
    label: 'Open Issues',
    icon: 'grid',
    filter: null,
    rawFilter: {},
    colorVariant: 'blue' as TColorVariant,
  },
  [IssuesSearchFieldNames.HighPriority]: {
    label: 'High Priority',
    filter: {
      [IssuesSearchFieldNames.HighPriority]: true,
    },
    rawFilter: {
      label: 'High Priority',
      name: IssuesSearchFieldNames.HighPriority,
      values: [{label: '', value: true}],
    },
    icon: 'arrow-up',
    colorVariant: 'red' as TColorVariant,
  },
  [IssuesSearchFieldNames.OlderThan24h]: {
    label: 'More than 24 hours old',
    filter: {
      [IssuesSearchFieldNames.OlderThan24h]: true,
    },
    rawFilter: {
      label: 'Older than 24 hours',
      name: IssuesSearchFieldNames.OlderThan24h,
      values: [{label: '', value: true}],
    },
    icon: 'clock',
    colorVariant: 'yellow' as TColorVariant,
  },
};
