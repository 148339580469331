import {NavPageTypes, NavTypes} from './MDU.types';
import {IMDUProjectsFilters} from './Pages/Projects/Parts/MDUProjectsFilters/MDUProjectsFilters.types';

/**
 * This is a key factory for the MDU queries within the projects
 */

const BASE_MDU = 'mdu';
export const BASE_PROJECTS_QUERY_KEY = [BASE_MDU, 'projects'];
export const BASE_CURRENT_PROJECT_KEY = [BASE_MDU, 'currentProject'];

export const PROJECTS_PAGINATION_KEY = 'pagination';
export const GET_PROJECT_DETAILS_KEY = 'getProjectDetails';

export const MDU_PROJECT_QUERY_KEYS = {
  paginationJobs: (filters?: any) => [...BASE_CURRENT_PROJECT_KEY, 'paginationJobs', ...filters],
  pagination: (filters: (NavTypes | NavPageTypes | IMDUProjectsFilters | string)[]) => [...BASE_PROJECTS_QUERY_KEY, PROJECTS_PAGINATION_KEY, ...filters],
  projectField: (fieldName = '') => [...BASE_PROJECTS_QUERY_KEY, 'projectField', fieldName],
  stats: (projectType: (NavPageTypes | NavTypes)[]) => [...BASE_PROJECTS_QUERY_KEY, 'stats', ...projectType],
  getDetailsByProjectId: (projectId: string) => [...BASE_CURRENT_PROJECT_KEY, GET_PROJECT_DETAILS_KEY, projectId],
  getNotesByProjectId: (projectId: string) => [...BASE_CURRENT_PROJECT_KEY, 'getNotesByProjectId', projectId],
  getAttachmentsByProjectId: (projectId: string) => [...BASE_CURRENT_PROJECT_KEY, 'getAttachmentsByProjectId', projectId],
  getProjectProgress: (projectId: string) => [...BASE_CURRENT_PROJECT_KEY, 'getProjectProgress', projectId],
  getNoteEntryTypes: () => [...BASE_CURRENT_PROJECT_KEY, 'getNoteEntryTypes'],
  getProjectPauseHistory: (projectId: string) => [...BASE_CURRENT_PROJECT_KEY, 'getProjectPauseHistory', projectId],
  partnerSkus: (partnerId: number) => [BASE_MDU, 'partnerSkus', partnerId],
  addSku: (id: string) => [BASE_MDU, 'addSku', id],
  unit: (arg: (number | string)[]) => [...BASE_CURRENT_PROJECT_KEY, 'unit', ...arg],
  jobsCannotComplete: () => [...BASE_CURRENT_PROJECT_KEY, 'jobsCannotComplete'],
  reasons: () => [BASE_MDU, 'reasons'],
};
