import React from 'react';
import {useSelector} from 'react-redux';
import cn from 'classnames';
import {Button, BUTTON_THEMES, Modal} from 'ht-styleguide';

/* Ducks */
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';

/* Hooks */
import {useJobsActionsItems} from 'features/MultiDwellingUnits/Pages/CurrentProject/hooks/Jobs.useActionItems';
import {useDeleteAndApproveWithInvalidate} from 'features/MultiDwellingUnits/Pages/Projects/hooks';

/* Constants */
import {DEFAULT_UNIT_NAME} from 'features/MultiDwellingUnits/MDU.constants';

/* styles */
import styles from './modals.styles.scss';

import {TActionItem, TSingleActionTypes, TActionModalTypes} from '../../MDU.types';

const DeleteActionModal = () => {
  /* Hooks */
  const {deleteUnitAction} = useJobsActionsItems();
  const {deleteProjectAction, onCloseActionItem} = useDeleteAndApproveWithInvalidate();
  const actionItem: TActionItem = useSelector(mduProjectsSlice.selectors.getActionItem);

  if (!actionItem) return null;

  /* Constants */
  const deleteConstants = () => {
    const type = {
      Project: {
        dispatchAction: deleteProjectAction,
        body: `Deleting a project will discard all of its data and cannot be undone.`,
      },
      Job: {dispatchAction: deleteUnitAction, body: `Deleting <strong>${actionItem?.entity?.unit_name || DEFAULT_UNIT_NAME}</strong> will discard all of its data and cannot be undone.`},
    };

    return type[actionItem?.modalType as TActionModalTypes] || type.Project;
  };
  const {body, dispatchAction} = deleteConstants();
  const actionText = `Delete ${actionItem?.modalType}`;

  return (
    <Modal
      isVisible={actionItem?.modalKey === TSingleActionTypes.DELETE}
      modalClassName={cn(styles.container, styles.modalContents)}
      hide={onCloseActionItem}
      onRequestClose={onCloseActionItem}
      header={actionText}
      footerElement2={
        <Button theme={BUTTON_THEMES.SECONDARY} dataTestId="button_cancel_job" className={styles.ctaButton} onClick={onCloseActionItem}>
          Cancel
        </Button>
      }
      footerElement3={
        <Button theme={BUTTON_THEMES.DANGER_SECONDARY} dataTestId="button_delete_job" className={styles.ctaButton} onClick={dispatchAction}>
          {actionText}
        </Button>
      }
    >
      <div className="position relative">
        <p className="p1 n900" dangerouslySetInnerHTML={{__html: `${body}`}} />
      </div>
    </Modal>
  );
};

export default DeleteActionModal;
