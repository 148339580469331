// sku remote attribute
export const REMOTE_TRUE = 'remote-true';
export const REMOTE_FALSE = 'remote-false';
export const REMOTE = 'remote';
export const REMOTE_MISMATCH = 'remote-mismatch';

// sku modals content/text
export const MODAL_REMOTE_MISMATCH_MESSAGE =
  'In-home services cannot be mingled with remote services on the same order. To add this service, please complete the current order and start a new one, or remove conflicting services from the cart.';
export const MODAL_REMOTE_MISMATCH_HEADER = 'Cannot add service';

// sku modals partner mismatch to add to cart
export const MODAL_PARTNER_MISMATCH_MESSAGE =
  'Partner services cannot be mingled with non-partner OR other partner’s services on the same order. To add this service, please complete this order and start a new one, or remove conflicting services from the cart.';
export const MODAL_PARTNER_MISMATCH_HEADER = 'Cannot add service';

// sku modals - remote cart and product sku mismatch
export const MODAL_PRODUCT_MISMATCH_MESSAGE =
  'Product skus cannot be mingled with remote services on the same order. To add this product, please complete the current order and start a new one, or remove conflicting services from the cart.';
export const MODAL_PRODUCT_MISMATCH_HEADER = 'Cannot add product';

// sku modals - cannot add skus to api orders regardless of partner
export const MODAL_API_ORDER_MESSAGE = 'Any services cannot be added to API orders.';
export const MODAL_API_ORDER_HEADER = 'Cannot add service';
