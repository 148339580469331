/**
 * Create the store with dynamic reducers
 */
import {configureStore, getDefaultMiddleware, ThunkAction, Action} from '@reduxjs/toolkit';
// custom middlewares
// eslint-disable-next-line import/no-cycle
import authenticationMiddleware from 'middleware/authenticationValidation';
import notificationMiddleware from 'middleware/notifications';
import bugsnagMiddleware from 'middleware/bugsnag';
// importing all reducers
import reducers from './reducers';

// we could look into redux-persist if we want to rehydrate on a refresh
const rootReducer = reducers();
export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: true,
  }).concat(authenticationMiddleware, notificationMiddleware, bugsnagMiddleware),
  // preloadedState: initialState,
  devTools: true,
});

/* when store is ready we will emit and let our listeners pick it up, if needed [.on('emit:store', store)] */
if (process.env.NODE_ENV === 'development' && 'Cypress' in window) {
  // @ts-ignore
  window.Cypress.emit('emit:store', store);
}

/* Export out our global store types */
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type BaseAppThunkAPI = {
  dispatch: AppDispatch;
  state: RootState;
};
