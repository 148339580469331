export const makePath = (path: string) => {
  if (!path.includes(':')) {
    // performance optimization
    return () => path;
  }
  return function replaceParams(...args: string[]) {
    const result: string = args.reduce((link, arg) => link.replace(/:\w+/, (arg || '0').toString()), path);
    if (result.includes(':')) {
      throw new Error(`Please provide all replacement for a path: ${path} - ${result}`);
    }
    return result;
  };
};
