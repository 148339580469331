import {useDispatch} from 'react-redux';
import {useMutation, useQueryClient} from 'react-query';
import {useParams} from 'react-router-dom';

import useApi from 'hooks/useApi';
import {logger} from 'utils/logger';

import {MDU_PROJECT_QUERY_KEYS} from '../MDU.query.keys';
import {showErrorToast} from 'queries/query.utils';
import {MduUseParamsTypes} from '../MDU.types';

/** MUTATION: Delete Project Attachment */
export const useDeleteProjectAttachmentMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const {projectId = ''} = useParams<MduUseParamsTypes>();
  const api = useApi();
  return useMutation(
    async (attachmentId: number) => {
      api.toggleLoader(true);
      const response = await api.mdu.deleteAttachment({id: projectId, attachmentId});
      api.toggleLoader(false);

      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Deleting Attachment'});
        throw new Error(`error deleting attachment: ${String(response.err)}`);
      }
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(MDU_PROJECT_QUERY_KEYS.getAttachmentsByProjectId(projectId));
      },
      onError: response => {
        logger('Delete Attachment: ')(response as Error);
      },
    }
  );
};
