import React from 'react';
import {Icon, Tooltip} from 'ht-styleguide';
import truncate from 'lodash/truncate';
import {mduProjectsPagePath} from 'global/paths';
import {FILE_UPLOAD_VALIDATIONS} from 'utils/files/constants';
import {PROJECT_TYPES} from './Parts/FormFields/FormFields.constants';
import {PROJECT_STARTED_TOOLTIP_TEXT, STATUSES_POST_LAUNCH, STATUSES_PRE_LAUNCH} from './MDU.constants';
import {MDUProjectType, MDUProjectTypeSlugs, MDUStatuses, MDUUnitStatuses, ProjectDetails, ProjectNoteAttachment, ProjectService, SelectOption, TReasonType} from './MDU.types';

import {UploadErrorStatusType} from './Parts/FileUploadErrors/FileUploadErrors.types';
import {TGroupService} from './Parts/GroupServicesBoxInfo';
/**
 * Simple helper to just centralize as common route theme.
 * @param projectsType
 * @param projectsPage
 */

export const buildProjectsPage = ({projectsType, projectsPage}: {projectsType: string; projectsPage: string}) => mduProjectsPagePath(projectsType, projectsPage);

/**
 * Displays status in readable form. Capitalized
 * @param text - status to remove non alpha and sub space, uppercase
 */
export const readableStatusDisplay = (text: string) => text.replace(/[\W_]+/g, ' ').toUpperCase();

/**
 * Displays status or project type slug into readable form. Camel Case Capitalized
 * @param text - status to remove non alpha and sub space, uppercase
 */
type TStatuses = MDUStatuses | MDUUnitStatuses | MDUProjectTypeSlugs;
type TStatusesArray = (MDUStatuses | MDUUnitStatuses | MDUProjectTypeSlugs)[];
export const convertSnakeToCamelAndCapitalize = (text: TStatuses | TStatusesArray, joiner: string = ',', isCapitalized = true) => {
  if (!text) return '';

  const status = ([] as TStatusesArray).concat(text).reduce((stringedStatus: string[], perStatus) => {
    const joinedStatus = perStatus
      .split('_')
      .reduce((s: string[], currentStatus) => {
        const display = isCapitalized ? currentStatus.charAt(0).toUpperCase() + currentStatus.slice(1) : currentStatus;
        return [...s, display];
      }, [])
      .join(` `);

    return stringedStatus.concat(joinedStatus);
  }, []);

  return status.join(`${joiner} `);
};

export const getProjectType = (projectType: MDUProjectType | undefined, projectTypes = PROJECT_TYPES as SelectOption[]) => projectTypes.find(({value}) => value === projectType?.slug)?.label ?? '';

/**
 * This function will probably be changed, but this will do for now. We are making the assumption
 * that the fullAddress coming from AddressAutoComplete will split into 4 segments. Any deviation from
 * this will cause unexpected results.
 * 0: address1, 1: city, 2: state, 3: zip
 */
export const formatAddressForRequest = (fullAddress: string) => {
  if (!fullAddress) return {};
  const splitAddress = fullAddress.split(',').map((elem: string) => elem.trim());
  return {address1: splitAddress[0], address2: '', city: splitAddress[1], state: splitAddress[2], zip: splitAddress[3]};
};

export const isPreLaunchStatus = (status: MDUStatuses) => STATUSES_PRE_LAUNCH.includes(status);
export const isPostLaunchStatus = (status: MDUStatuses) => STATUSES_POST_LAUNCH.includes(status);

// Design request was to truncate long file names, but keep the file extension. Remove any query params
export const truncateLongFilename = (filename: string, maxChars = 20) => {
  const nameWithoutQueryParams = filename?.split('?')[0] || '';
  if (nameWithoutQueryParams.length <= maxChars) {
    return nameWithoutQueryParams;
  }
  /*
    It is possible that a file may have more than one period in the name, so grab the
    last element in the array after split('.') to get the proper file extension.
    e.g., "Screen_Shot_2022-11-18_at_6.02.11_PM.png"
  */
  const fileExtension = nameWithoutQueryParams?.split('.').slice(-1)[0];
  return truncate(nameWithoutQueryParams, {length: maxChars, omission: `...${fileExtension}`});
};

export const isAttachmentImage = (attachment: ProjectNoteAttachment) => ['JPG', 'JPEG', 'PNG'].includes(attachment.file_type);

/** If invalid return UploadErrorStatusType to be consumed by FileUploadErros component */
export const validateFileBeforeUpload = (
  file: File,
  maxFileSize = FILE_UPLOAD_VALIDATIONS.MAX_FILE_SIZE,
  allowedFileExtensions = FILE_UPLOAD_VALIDATIONS.ALLOWED_FILE_EXTENSIONS()
): {isValid: boolean; errorStatusInfo?: UploadErrorStatusType} => {
  const {name, size} = file;
  const extension = name.split('.').slice(-1)[0];
  if (allowedFileExtensions.includes(extension) && size <= maxFileSize) return {isValid: true};
  if (!allowedFileExtensions.includes(extension))
    return {isValid: false, errorStatusInfo: {status: 400, statusText: 'Bad Request', fileAttachmentErrors: [{file: ['is invalid'], file_content_type: ['is invalid']}], fileName: name}};
  if (size > maxFileSize) return {isValid: false, errorStatusInfo: {status: 413, statusText: 'Entity Too Large', fileAttachmentErrors: null, fileName: name}};
  return {isValid: false};
};

export const getFileNameFromAttachmentUrl = (attachment: ProjectNoteAttachment, {withoutQueryParams = false} = {}) => {
  if (!attachment.url) return '';
  const splitUrl = attachment.url.split('/');
  if (withoutQueryParams) {
    return splitUrl[splitUrl.length - 1].split('?')[0];
  }
  return splitUrl[splitUrl.length - 1];
};
/**
 * Pass in the limit value, and get back select options for the limit select field
 */
export const getSelectOptions = (limit: number) => Array.from(Array(limit).keys()).map(i => ({label: i + 1, value: i + 1}));

/**
 * @file normalizeReasonsForSelect.ts
 *
 * This takes in an array of reasons and returns an array of objects that can be used in a select component
 */
export const normalizeReasonForSelect = (reasons: TReasonType[] = []) => reasons?.map(reason => ({value: reason.id, label: reason.text}));

/**
 * Converts a ProjectService[] to a TGroupService[].
 * See GroupServicesBoxInfo component for more info.
 */
export const convertProjectServicesToTGroupServices = (projectServices: ProjectService[]): TGroupService[] =>
  projectServices.map(projectService => {
    const {quantity, sku} = projectService;

    return {
      quantity: Number(quantity) || 1,
      name: sku ? sku.name : '',
      key: JSON.stringify(projectService),
      id: projectService.id,
    };
  });
/** Returns true if project.startedAt has a value. Indicates work on the project has been started. */
export const isProjectStartDateSet = (project: ProjectDetails) => Boolean(project.startedAt);

/** Get label for Start Date input field based on project status */
export const getStartDateLabel = ({project, isRequiredField}: {project: ProjectDetails; isRequiredField: boolean}) => {
  const hasStartDate = isProjectStartDateSet(project);
  let labelText = hasStartDate ? 'Start Date' : 'Planned Start Date';
  if (isRequiredField) labelText += '*';
  return hasStartDate ? (
    <div className="flex justify-content-space-between">
      <span>{labelText}</span>
      <Tooltip content={PROJECT_STARTED_TOOLTIP_TEXT} wrapperClass="marginLeft-tiny">
        <Icon name="info-outline" />
      </Tooltip>
    </div>
  ) : (
    labelText
  );
};
