import React, {useMemo, ComponentProps} from 'react';
import {pluralize} from 'utils/text';
import WidgetDashboard from 'components/Elements/WidgetDashboard';
import {useGetProjectsStats} from 'features/MultiDwellingUnits/queries/query.project.stats';
import {STAT_PROPS} from 'features/MultiDwellingUnits/Pages/Projects/Parts/MDUProjectsStats/MDUProjectsStats.constants';
import {IStatWidget} from 'features/MultiDwellingUnits/Pages/Projects/Parts/MDUProjectsStats/MDUProjectsStats.types';

const StatWidget = ({statKey, onFiltersChange, hideWidget = false, ...props}: IStatWidget) => {
  const {data} = useGetProjectsStats();
  const stats = data?.stats;
  const progressStats = data?.progress;
  const statProps = STAT_PROPS[statKey];

  const action = useMemo(() => {
    return statProps?.filters
      ? {
          actionIcon: 'filter',
          actionOnClick: () => onFiltersChange(statProps.filters || {}),
        }
      : {};
  }, [onFiltersChange, statProps?.filters]);

  const extraProps = useMemo(() => {
    if (statKey === 'total_unit_progress') {
      const {completed_units_total = 0, unserviceable_units_total = 0, open_units_total = 0, units_total = 0} = progressStats || {};
      const fulfilledPercentage = `${Math.round(((completed_units_total + unserviceable_units_total) / units_total) * 100)}%`;
      const totalUnitProgressProps: Partial<ComponentProps<typeof WidgetDashboard.Widget>> = {
        prominent: true,
        progress: {
          size: 56,
          barSize: 8,
          featuredLabelOverride: units_total ? fulfilledPercentage : '0%',
          segments: [
            {
              label: 'Completed',
              color: '#00cf7f', // $ht-success-green-500
              percentage: units_total ? (completed_units_total / units_total) * 100 : 0,
            },
            {
              label: 'Unserviceable',
              color: '#e64f49', // $ht-alert-red-700
              percentage: units_total ? (unserviceable_units_total / units_total) * 100 : 0,
            },
            {
              label: 'Open',
              color: '#edf1f7', // $ht-navy-200
              percentage: units_total ? (open_units_total / units_total) * 100 : 0,
            },
          ],
        },
      };
      return totalUnitProgressProps;
    }
    return {};
  }, [progressStats, statKey]);

  const statValue = useMemo(() => {
    // Custom stat value
    if (statKey === 'total_unit_progress') {
      const {completed_units_total = 0, unserviceable_units_total = 0, units_total = 0} = progressStats || {};
      const fulfilledUnitCount = completed_units_total + unserviceable_units_total;
      return `${fulfilledUnitCount} / ${units_total} ${pluralize('Unit', units_total)}`;
    }

    const statRef = STAT_PROPS[statKey]?.statRef;
    const projectCount = stats?.[statRef || (statKey as keyof typeof statRef)] || 0;
    return `${projectCount} ${pluralize('Project', projectCount)}`;
  }, [progressStats, statKey, stats]);

  if (!statKey || hideWidget) return null;

  return <WidgetDashboard.Widget {...props} {...statProps} {...action} {...extraProps} value={statValue} />;
};

export default StatWidget;
