import React from 'react';

// Utils
import cn from 'classnames';
import {Icon} from 'ht-styleguide';
import get from 'lodash/get';
import {useSelector} from 'hooks/useAppSelector';
import {profileSelectors} from 'features/Profile/profile.ducks';

// Constants
import {BENEFITS_HEADERS, MEMBERSHIP_BENEFIT_TYPES} from 'features/Profile/profile.constants';

// Styles
import styles from 'features/Profile/profile.styles.scss';

type BenefitLineProps = {
  benefit: string;
  used: boolean | undefined;
};

const BenefitLine = ({benefit, used}: BenefitLineProps) => {
  const subscription = useSelector(profileSelectors.getKeyInProfileState('subscription'));

  if (benefit === MEMBERSHIP_BENEFIT_TYPES.PRODUCT_DISCOUNT && !get(subscription, 'planProductDiscount')) return null;

  const icon = used ? 'round-check-fill' : 'round-x-fill';

  const benefitConstant = get(BENEFITS_HEADERS, benefit);

  const benefitText = typeof benefitConstant === 'function' ? benefitConstant(subscription) : benefitConstant;

  const iconStyles = cn(styles.icon, {
    [styles.active]: used,
  });

  return (
    <tr>
      <td>
        <p className="p1">{benefitText}</p>
      </td>
      <td className={styles.membershipIcons}>
        <Icon name={icon} className={iconStyles} />
      </td>
    </tr>
  );
};

export default BenefitLine;
