import React from 'react';
// Types && Ducks
import {TIssueModeTypes} from 'features/Issues/Parts/SideSheets/SideSheet.Issue.types';
// Components
import CreateIssueForm from './Parts/Create/SideSheet.Issue.CreateIssueForm';
import EditIssueForm from './Parts/Edit/SideSheet.Issue.EditIssueForm';

type TSideSheetProjectIssue = {
  issueMode: TIssueModeTypes;
  closeSideSheet: () => void;
  isOpenIssueSideSheet: boolean;
};

const SideSheetIssue = (props: TSideSheetProjectIssue) => {
  const {issueMode, closeSideSheet, isOpenIssueSideSheet} = props;

  if (!issueMode) return null;
  const views: {[key: string]: React.JSX.Element} = {
    issue_create: <CreateIssueForm closeSideSheet={closeSideSheet} isOpenIssueSideSheet={isOpenIssueSideSheet} />,
    issue_edit: <EditIssueForm closeSideSheet={closeSideSheet} isOpenIssueSideSheet={isOpenIssueSideSheet} />,
    issue_view: <EditIssueForm closeSideSheet={closeSideSheet} isOpenIssueSideSheet={isOpenIssueSideSheet} />,
  };

  return views[issueMode];
};

export default SideSheetIssue;
