import React, {ReactNode} from 'react';
import {Portal} from 'hooks/useCreatePortal';
import {LAYOUT_CONTAINER_ID} from 'components/Layouts/AdminHub/AdminHub.layout.constants';
import styles from 'features/MultiDwellingUnits/MDU.styles.scss';

type TJobsCreateTakeOverProps = {
  children: ReactNode;
};
const JobsCreateTakeOver = ({children}: TJobsCreateTakeOverProps) => {
  return (
    <Portal id={LAYOUT_CONTAINER_ID}>
      <div className={styles.fullPageTakeOver}>{children}</div>
    </Portal>
  );
};

export default JobsCreateTakeOver;
