import React from 'react';
import {SideSheetContentSection, TextArea, RadioGroup, Radio, ELEMENT_SIZE} from 'ht-styleguide';
import {UpdateTaskAnswerFieldName, CustomUpdateTaskAnswerFieldName} from '../../editTaskAnswerSideSheet.types';
import {IEditTaskAnswerSectionShareProps} from '../editTaskAnswerContent.types';
import AttachmentsFieldEditTaskAnswerSection from './AttachmentsField.EditTaskAnswerSection';

const EDIT_TASK_INCIDENT_NAMESPACE = 'editTaskIncident';

/**
 * For the incident task type.
 *
 * This component is a little different in that data is required when the user selects "Yes" for the radio button.
 * The BE will flag the task as having an incident if both response and attachments fields are filled.
 *
 * Upon saving/updating the task, util `createUpdateTaskAnswerPayload` before submitting is used to strip out the response
 * and attachments data if the user selected "No" for the radio button. The util is leveraged rather than modifying the
 * formik values directly because of the limitations of the Cloudinary attachments field. It's difficult to remove the
 * attachments data from the local state of the Cloudinary field.
 */
const IncidentEditTaskAnswerSection = ({formik, task}: IEditTaskAnswerSectionShareProps) => {
  const {hasIncidentToReport} = formik.values;
  const onRadioChange = (newValue: boolean) => formik.setFieldValue(CustomUpdateTaskAnswerFieldName.HasIncidentToReport, newValue);
  const attachmentsErrorMessage = formik.errors.attachments as string;

  return (
    <>
      <SideSheetContentSection title="Select One">
        <RadioGroup disableSort elementSize={ELEMENT_SIZE.M} onChange={onRadioChange} value={hasIncidentToReport}>
          <Radio value label="Yes" />
          <Radio value={false} label="No" />
        </RadioGroup>
      </SideSheetContentSection>
      {hasIncidentToReport && (
        <>
          <SideSheetContentSection title="Provide details about this incident">
            <TextArea id={UpdateTaskAnswerFieldName.Response} onChange={formik.handleChange} value={formik.values.response} error={formik.errors.response} />
          </SideSheetContentSection>
          <SideSheetContentSection title="Provide as many relevant photos as possible">
            <AttachmentsFieldEditTaskAnswerSection formik={formik} task={task} namespace={EDIT_TASK_INCIDENT_NAMESPACE} errorMessage={attachmentsErrorMessage} />
          </SideSheetContentSection>
        </>
      )}
    </>
  );
};

export default IncidentEditTaskAnswerSection;
