import {useDispatch} from 'react-redux';
import {actions as requestLoaderActions} from 'features/App/RequestLoader/requestLoader.ducks';
import APIS from 'global/apis';

export default () => {
  const dispatch = useDispatch();

  return {
    ...APIS,
    toggleLoader(state: boolean) {
      dispatch(requestLoaderActions.loading(state));
    },
  };
};

/*
  Sample Usage in React Components

  `toggleLoader`
    - Use this action to show a loader that'll block user interaction. For instance,
      when an admin agent completes an order, this loader should be used to prevent
      multiple clicks on the `Complete Order` button.
      This is similiar to toggling the loader via the `requestStarted` action in
      the client app.
      Use the component-level page loader in other cases.

  Calling Apis
    - Keep in mind that we have redux-thunk to handle async calls.

  -------

  import React, {useCallback} from 'react';
  import useApi from 'hooks/useApi';

  const SomeComponent = () => {
    const api = useApi();

    const submit1 = useCallback(async (e) => {
      e.preventDefault();
      api.toggleLoader(true);
      try {
        const {data, err} = await api.booking.createOrder();
        if (err) {
          // do something
        } else {
          // do something
        }
      } catch {
        // do something
      }
      api.toggleLoader(false);
    }, []);

    const submit2 = useCallback((e) => {
      api.toggleLoader(true);
      api.booking
        .createOrder()
        .then(({err, data}) => {
          if (err) {
            // do something
          } else {
            // do something
          }
        })
        .catch((e) => {
          // do something
        })
        .finally(() => {
          api.toggleLoader(false);
        });
    }, []);

    return (
      <div>
        <button type="button" onClick={submit1}>
          Create Order
        </button>
        <button type="button" onClick={submit2}>
          Create Order
        </button>
      </div>
    );
  };
*/
