import React from 'react';

// Utils
import cn from 'classnames';
import {customerProfilePath, bookingPreFill} from 'global/paths';
import {formatPhoneBasic} from 'utils/formatter/phone';

// Components
import {Grid, Button, BUTTON_THEMES, Icon} from 'ht-styleguide';

// Constants
import {CARD_HEADERS, SEGMENTS} from 'features/Profile/profile.constants';

// Types
import {Client} from 'features/Profile/profile.types';

// Styles
import styles from 'features/Profile/profile.styles.scss';

const CustomerDetails = ({client}: {client: Client}) => {
  const {firstName, lastName, id, segment, subscription, rating, address, email, phone, card} = client;

  const isMember = segment === SEGMENTS.MEMBER;

  const membershipStyles = cn('p2', 'capitalize', isMember ? 'lightBlue' : 'n900');

  return (
    <div className={styles.customerSection}>
      <Grid.Fluid classes="paddingY-medium1">
        <Grid.Row classes={styles.customerDetailsRow}>
          <Grid.Column lg={12}>
            <div className="flex align-items-center justify-content-space-between">
              <div>
                <div className="flex align-items-center marginBottom-tiny1">
                  <h4>
                    {firstName} {lastName}
                  </h4>
                  <p className="p2 n700 marginLeft-small">ID: {id}</p>
                </div>
                <div className={styles.textBottomRow}>
                  <p className={membershipStyles}>
                    {isMember && subscription?.planName} {segment}
                  </p>
                  <span className="p2 n300">|</span>
                  <p className="p2 n900">
                    Rating:
                    {!!rating && <Icon name="rating-star" className={cn('paddingLeft-tiny1', styles.starIcon)} />}
                    {rating ? rating.toFixed(1) : ' N/A'}
                  </p>
                </div>
              </div>
              <div className={styles.customerButtons}>
                <a href={customerProfilePath(id)} target="_blank" rel="noreferrer">
                  <Button medium theme={BUTTON_THEMES.SECONDARY} inlineBlock>
                    View Profile
                  </Button>
                </a>
                <Button isLink goTo={bookingPreFill(id)} medium theme={BUTTON_THEMES.PRIMARY} target="_blank" rel="noreferrer" inlineBlock>
                  Create New Order
                </Button>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row classes={styles.customerDetailsRow}>
          <Grid.Column lg={12}>
            <div className={cn(styles.informationCards)}>
              <div>
                <h6 className="marginBottom-tiny1">{CARD_HEADERS.ADDRESS}</h6>
                {address ? (
                  <>
                    <p className="p2 n700">{address.address1}</p>
                    <p className="p2 n700">{address.address2}</p>
                    <p className="p2 n700">
                      {address.city}, {address.state} {address.zip}
                    </p>
                  </>
                ) : (
                  <p className="p2 n700">N/A</p>
                )}
              </div>
              <div>
                <h6 className="marginBottom-tiny1">{CARD_HEADERS.EMAIL}</h6>
                <p className="p2 n700">{email || 'N/A'}</p>
              </div>
              <div>
                <h6 className="marginBottom-tiny1">{CARD_HEADERS.PHONE}</h6>
                <p className="p2 n700">{phone ? formatPhoneBasic(phone, '-') : 'N/A'}</p>
              </div>
              <div>
                <h6 className="marginBottom-tiny1">{CARD_HEADERS.BILLING}</h6>
                {card ? (
                  <>
                    <p className="p2 n700">
                      {card.brand} ending in {card.number.split('-').pop()}
                    </p>
                    <p className="p2 n700">
                      Exp {card.expMonth}/{card.expYear}
                    </p>
                  </>
                ) : (
                  <p className="p2 n700">N/A</p>
                )}
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid.Fluid>
    </div>
  );
};

export default CustomerDetails;
