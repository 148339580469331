import React, {useRef, useState} from 'react';
import cn from 'classnames';
import {Icon} from 'ht-styleguide';

/* Hooks */
import {useAppDispatch} from 'hooks/useAppDispatch';
import useOutsideClick from 'hooks/useOutsideClick';
import {useCurrentProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/CurrentProject.hooks';

/* Components */
import {MenuListItem, MenuPanel, MenuUnorderedList} from 'components/Elements/Menu';

/* Utils & Constants */
import {noop, stopImmediatePropagation} from 'utils/event';
import getCustomActionByStatus from 'features/MultiDwellingUnits/Pages/Projects/utils/utils.getCustomActionByStatus';

import styles from './currentProject.sidebarActionsMenu.scss';

const SidebarActionsMenu = () => {
  /* Local State */
  const [openMenu, setOpenMenu] = useState(false);

  /* Hooks */
  const dispatch = useAppDispatch();
  const menuRef: React.RefObject<HTMLDivElement> = useRef(null);
  const project = useCurrentProject();

  /* Constants */
  const {sidebarActionMenu: actions} = getCustomActionByStatus({project, dispatch});
  const hasActions = !!actions.length;

  const toggleMenu = (setMenu = !openMenu) => setOpenMenu(setMenu);

  useOutsideClick(menuRef, () => toggleMenu(false));

  return hasActions ? (
    <div ref={menuRef}>
      <Icon key="more" name="more" className={cn(styles.actionIcon, 'cursorPointer')} onClick={() => toggleMenu()} />
      {openMenu && (
        <div className={styles.menu}>
          <MenuPanel>
            <MenuUnorderedList>
              {actions.map(({key, onClick, text}) => (
                <MenuListItem
                  key={key}
                  dataTestId={`sidebar-header-menu-${key}`}
                  onClick={e => {
                    stopImmediatePropagation(noop)(e);
                    onClick();
                    toggleMenu();
                  }}
                >
                  {text}
                </MenuListItem>
              ))}
            </MenuUnorderedList>
          </MenuPanel>
        </div>
      )}
    </div>
  ) : null;
};

export default SidebarActionsMenu;
