import React, {useMemo, ComponentProps} from 'react';
import useGetSkusQuery from '../queries/query.dataSelect.getSkus';
import {TSharedFormFieldProps} from '../../dataFormFields.types';
import BaseFilterSelectField from '../../BaseFilter/SelectField';

type TSkuSelect = TSharedFormFieldProps &
  Partial<ComponentProps<typeof BaseFilterSelectField>> & {
    frontLoadData?: boolean;
  };

const SkuSelect = ({dataTestId = 'DataSelect-SkuSelect', fieldName, formik, frontLoadData = true, label, onChangeCB, options, ...rest}: TSkuSelect) => {
  const pagination: TBasePaginationQuery | undefined = frontLoadData ? {per_page: 9999, page: 1} : undefined;
  const {data, isFetching} = useGetSkusQuery({pagination});

  const skuOpts = useMemo(() => {
    return (data?.skus || []).map(sku => ({label: `${sku.name} (${sku.id})`, value: sku.id}));
  }, [data?.skus]);

  const disabled = isFetching;

  return <BaseFilterSelectField dataTestId={dataTestId} isDisabled={disabled} fieldName={fieldName} formik={formik} label={label} onChangeCB={onChangeCB} multiple options={skuOpts} {...rest} />;
};

export default SkuSelect;
