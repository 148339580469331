import React from 'react';
import cn from 'classnames';
import {LoaderComponent, Icon} from 'ht-styleguide';

/* Queries */
import {useProjectProgressQuery} from 'features/MultiDwellingUnits/queries/query.project.progress';

/* Components */
import ChipStatus from 'features/MultiDwellingUnits/Parts/Chip';

/* Types */
import {MDUStatuses} from 'features/MultiDwellingUnits/MDU.types';

/* Styles */
import styles from './MDUProjectsTable.styles.scss';

const WrapperEarlyReturn = ({children}: {children: React.ReactNode}) => {
  return <div className="flex align-items-center justify-content-center fullwidth paddingTop-medium paddingBottom-medium">{children}</div>;
};
/**
 * This component returns the tooltip view with the job status, per project.
 * Looks like we only need the project progress here. If other places, we should break out the useQuery hook to a
 * more centralized area.
 *
 * NOTE: The data is cached in the react-query cache, so we don't need to worry about re-fetching the data, per id.
 *
 * @param id
 */
export default ({id, propertyOwner}: {id: string | number; propertyOwner: string}) => {
  /* This caches so subsequent calls are retrieved from the cache. See QueryClient for cache time in App */
  const {data, isError, isSuccess, isLoading} = useProjectProgressQuery(id ? String(id) : '', {suppressLoader: true});

  /* Extract data */
  const stats = data || null;
  const {status, name, completedUnitsTotal = 0, unserviceableUnitsTotal = 0, unitsTotal = 0} = stats || {};
  const totalInteracted = completedUnitsTotal + unserviceableUnitsTotal;

  /**
   * isLoading is only true on first fetch. For each subsequent fetch we check if the current data id
   * matches the new requested id
   */
  const showLoader = isLoading || String(data?.id) !== String(id);
  return (
    <div className={styles.statsContainer}>
      {isError && (
        <WrapperEarlyReturn>
          <div className="alertRed700">Error retrieving project stats</div>
        </WrapperEarlyReturn>
      )}
      {showLoader && (
        <WrapperEarlyReturn>
          <LoaderComponent />
        </WrapperEarlyReturn>
      )}
      {isSuccess && !showLoader && (
        <>
          <div className={cn(styles.headerDisplay, 'paddingBottom-small1')}>
            <div className="flex flex-direction-column">
              <div className="font-weight-medium">{name}</div>
              <span className="n700 caption paddingTop-tiny">{propertyOwner}</span>
            </div>
            <ChipStatus status={status as MDUStatuses} />
          </div>
          <div className="border-top" />
          <div className="paddingTop-small1 flex flex-direction-row justify-content-space-between">
            <div className="h6 font-weight-medium n900">
              {totalInteracted}/{unitsTotal} Jobs
            </div>
            <div className="flex flex-direction-row font-weight-medium">
              <div className={cn(styles.completed, 'flex align-items-center')}>
                <Icon name="checkmark-broken-circle" />
                &nbsp;{completedUnitsTotal}
              </div>
              <div className={cn(styles.atIssue, 'flex align-items-center')}>
                &nbsp;&nbsp;
                <Icon name="unserviceable" />
                &nbsp;{unserviceableUnitsTotal}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
