import {useMutation, useQueryClient} from 'react-query';
import APIS from 'global/apis';
import {logger} from 'utils/logger';
import {ISSUES_QUERY_KEYS} from './Issues.query.keys';

/**
 * @description useMutationCreateEditorNote hook to submit notes, from the editor, to the backend.
 */
export type TCreateEditorNoteParams = {
  files: string;
  lexical: string;
  lexicalTextNodes: string;
  entityId: number;
  entityType: string;
};
export const useMutationCreateEditorNote = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: TCreateEditorNoteParams) => {
      const {lexicalTextNodes, lexical, files, entityId} = params;
      const createEditorNote = {
        note: {
          content: {
            text: lexicalTextNodes,
            format: 'lexical',
            content: lexical,
          },
          attachments: files,
        },
      };

      const response = await APIS.issues.createNote({ticket_id: entityId}, createEditorNote);

      if (response.err) {
        throw new Error(response);
      }

      return {...response?.data};
    },
    {
      onSuccess: (response, {entityId}) => {
        /* invalidate the cache of the current tickets comments */
        queryClient.invalidateQueries(ISSUES_QUERY_KEYS.getNotes(+entityId));
      },
      onError: response => {
        logger('Editor Create Note: ')(response as Error);
      },
    }
  );
};
