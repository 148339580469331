import {useMutation, useQueryClient} from 'react-query';
import useApi from 'hooks/useApi';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {showErrorToast} from 'queries/query.utils';
import {logger} from 'utils/logger';
import {TUpsertTaskChecklist, TSkuChecklist} from '../types/taskGroups.types';
import {PRODUCTS_QUERY_KEYS} from './products.query.keys';

type TMutateTaskChecklistParams = {
  skuId: number;
  taskChecklist: TUpsertTaskChecklist;
};

type TMutateTaskChecklistResponse = WithErrorResponse<TSkuChecklist>;

/**
 * Mutate the task checklist for a sku.
 * Mutating is done by upserting the entire task checklist. Modify or add its contents as needed
 *
 * https://docs.internal.hellotech.com/api-9680217
 */
const useMutateTaskChecklist = () => {
  const api = useApi();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    async ({skuId, taskChecklist}: TMutateTaskChecklistParams) => {
      api.toggleLoader(true);
      const response: TMutateTaskChecklistResponse = await api.skus.upsertTaskChecklist({id: skuId}, taskChecklist);
      api.toggleLoader(false);

      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error in upserting task checklist'});
        logger('mutateTaskChecklist')(`Error upserting task checklist: ${JSON.stringify(response.data?.errors ?? '')}`);
        throw new Error(`Error upserting task checklist`);
      }

      return response?.data;
    },
    {
      onSuccess: (response, {skuId}) => {
        queryClient.setQueryData(PRODUCTS_QUERY_KEYS.getTaskGroups(skuId), response);
      },
    }
  );
};

export default useMutateTaskChecklist;
