import React, {memo, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';

/* Hooks */
import {useDraftTable, useProjectDraft} from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/TemplateJobs.hooks';

/* Queries / Mutations */
import {useCreateServiceDraftMutation, useUpdateServiceDraftMutation} from 'features/MultiDwellingUnits/queries/mutation.jobs.drafts';

/* Components */
import CreateBulkJobsHeader, {HeaderTypes} from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/Parts/TemplateJobs.Draft.Header';
import SideSheetAddSku from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/Parts/TemplateJobs.Draft.SideSheetAddSku';
import EmptyAddSkus from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/Parts/TemplateJobs.Empty.AddSkus';
import JobsCreateTakeOver from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/Jobs/AddJobs/Multi/Jobs.Create.TakeOver';
import DataTable from 'components/Elements/DataTable';

/* Constants */
import {mduProjectBulkJobCreate, mduProjectBulkJobCreatePath, mduProjectBulkJobCreatePathSku, mduProjectBulkJobServicesSkuEditPath} from 'global/paths';
import {ModalGroupExistsType} from 'features/MultiDwellingUnits/Parts/Modals/MDU.Modal.GroupExistsAlert';

import {MduUseParamsTypes, ProjectService} from 'features/MultiDwellingUnits/MDU.types';
import {SelectedSku} from 'features/Questions/types';

/**
 * Use case:
 * A user wants to add jobs in bulk but needs to create a new template.
 * This component is used to create the new template and add skus/units to it.
 * The template is not created in the DB until the user decides to create
 * the jobs in the previous screen.
 */
const CreatingBulkNewTemplates = () => {
  /* Hooks */
  const navigate = useNavigate();
  const {groupId = '', projectId = ''} = useParams<MduUseParamsTypes>();
  const {projectGroup} = useProjectDraft({groupId});
  const {columns, data} = useDraftTable({projectGroup, projectId, isPreLaunchStatus: true, pathRedirectOnEdit: mduProjectBulkJobServicesSkuEditPath});

  /* Queries / mutations */
  const {mutate} = useCreateServiceDraftMutation();
  const {mutate: mutateUpdate} = useUpdateServiceDraftMutation();

  /* Constants */
  const hasData = data.length > 0;

  /* Methods */
  const closeSideSheet = () => {
    navigate(mduProjectBulkJobCreatePath(projectId, projectGroup.id as string));
  };

  const onHandleEditMutation = (selectedSku: ProjectService) => {
    mutateUpdate(selectedSku, {onSuccess: closeSideSheet});
  };

  const onHandleCreateDraft = (selectedSku: SelectedSku) => {
    mutate(selectedSku, {onSuccess: closeSideSheet});
  };

  /* If we don't have a projectGroup, redirect */
  useEffect(() => {
    if (!projectGroup) {
      navigate(mduProjectBulkJobCreate(projectId));
    }
  }, []);

  return (
    <>
      <JobsCreateTakeOver>
        <CreateBulkJobsHeader
          suppressSave={!hasData}
          headerType={HeaderTypes.sku}
          onHandleDiscardRedirect={() => navigate(mduProjectBulkJobCreate(projectId))}
          onHandleSaveRedirect={() => navigate(mduProjectBulkJobCreate(projectId))}
          onHandlePartnerSkuRedirect={sku => navigate(mduProjectBulkJobCreatePathSku(projectId, projectGroup.id as string, sku.vale as string))}
          modalGroupExistsType={ModalGroupExistsType.multiJob}
        />
        {hasData ? <DataTable tableKey="Jobs-Create-Multiple-New" columns={columns} data={data} isZebra enableSorting={false} /> : <EmptyAddSkus />}
      </JobsCreateTakeOver>
      <SideSheetAddSku isJobBulkDraftMode isJobDraftUUID onHandleEditMutation={onHandleEditMutation} onHandleCreateMutation={onHandleCreateDraft} onCloseCallback={closeSideSheet} />
    </>
  );
};

export default memo(CreatingBulkNewTemplates);
