import React, {ReactNode} from 'react';

type MenuLineItemCalloutGroupProps = {
  children: ReactNode;
  className?: string;
};

const MenuUL = ({children, className = ''}: MenuLineItemCalloutGroupProps) => <ul className={className}>{children}</ul>;

export default MenuUL;
