import React, {Fragment, useRef} from 'react';
import cn from 'classnames';
import EditTaskAnswerSideSheet from './EditTaskAnswerSideSheet';
import ServiceTaskItem from './ServiceTaskItem';
import {IServiceTasksList, IEditTaskAnswerSideSheetHandler} from './serviceTasksList.types';
import styles from './serviceTasksList.styles.scss';

/**
 * The right-hand side of the main content. This component is responsible for rendering
 * the list of tasks
 */
const ServiceTasksList = ({order, checklistData, serviceId, taskCompletionStatusModalRef}: IServiceTasksList) => {
  const {grouping, task_groups} = checklistData || {};
  const containerStyles = cn('paddingLeft-small paddingRight-large', styles.listContainer);

  // Controls the visibility of the edit task sidesheet
  const editTaskAnswerSideSheetRef = useRef<IEditTaskAnswerSideSheetHandler>(null);

  return (
    <>
      <div className={containerStyles}>
        {task_groups?.map(({id, group_name, tasks}) => (
          <Fragment key={id}>
            {grouping && <h6 className="marginBottom-small n700">{group_name}</h6>}
            {tasks.map(task => (
              <ServiceTaskItem
                key={`${serviceId}-${task.id}`}
                order={order}
                serviceId={serviceId}
                task={task}
                taskCompletionStatusModalRef={taskCompletionStatusModalRef}
                editTaskAnswerSideSheetRef={editTaskAnswerSideSheetRef}
              />
            ))}
          </Fragment>
        ))}
      </div>
      <EditTaskAnswerSideSheet ref={editTaskAnswerSideSheetRef} order={order} checklistData={checklistData} serviceId={serviceId} />
    </>
  );
};

export default ServiceTasksList;
