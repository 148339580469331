import React, {ReactNode, useState} from 'react';
import cn from 'classnames';
import {Icon} from 'ht-styleguide';
import BaseGrid from 'components/Grid';
import * as PageHeaderItems from './PageHeader.Items';
import {PageHeaderProps} from './pageHeader.types';
import styles from './pageHeader.styles.scss';

export const TEST_IDS = {HEADER_CONTAINER: 'HEADER_CONTAINER', PAGE_HEADER: 'PAGE_HEADER'};

const Wrapper = ({children, className = '', withGridMargins}: {children: ReactNode; className: string; withGridMargins?: boolean}) =>
  withGridMargins ? <BaseGrid.Fluid classes={className}>{children}</BaseGrid.Fluid> : <div className={className}>{children}</div>;

const PageHeader = ({
  children,
  collapsible = false,
  headerClassName = '',
  HeaderRightContent,
  outerClassName,
  sticky = false,
  title,
  TitleComponent,
  HeaderLeftContent,
  withGridMargins = false,
}: PageHeaderProps) => {
  const [showHeaderChildren, setShowHeaderChildren] = useState(true);

  const onCollapseButtonClick = () => setShowHeaderChildren(!showHeaderChildren);
  const collapseIconName = showHeaderChildren ? 'arrow-direct-up' : 'arrow-direct-bottom';

  const outerContainerStyles = cn(styles.outerContainer, {[styles.isSticky]: sticky}, outerClassName);
  const mainHeaderStyles = cn(styles.mainHeaderContainer, 'flex align-center-items justify-content-space-between', headerClassName);

  return (
    <div className={outerContainerStyles} data-testid={TEST_IDS.HEADER_CONTAINER}>
      {/* Header Section */}
      <div className={styles.outerHeaderContainer}>
        <Wrapper withGridMargins={withGridMargins} className={mainHeaderStyles}>
          {/* Title + Left Content */}
          <div className={cn(styles.titleContainer, 'flex align-items-center')} data-testid={TEST_IDS.PAGE_HEADER}>
            {title && <PageHeaderItems.PageHeaderTitleText title={title} />}
            {!title && TitleComponent}
            {HeaderLeftContent && HeaderLeftContent}
          </div>
          {/* Right Content */}
          <div className={cn('flex align-items-center', styles.mainHeaderRightContainer)}>
            {HeaderRightContent && HeaderRightContent}
            {collapsible && <Icon name={collapseIconName} className={styles.collapseIcon} onClick={onCollapseButtonClick} />}
          </div>
        </Wrapper>
      </div>
      {showHeaderChildren && children && (
        <div>
          {/* Children */}
          {children}
        </div>
      )}
    </div>
  );
};

PageHeader.TitleText = PageHeaderItems.PageHeaderTitleText;
PageHeader.Button = PageHeaderItems.PageHeaderButton;
PageHeader.TextLink = PageHeaderItems.PageHeaderTextLink;
PageHeader.Chip = PageHeaderItems.PageHeaderChip;
PageHeader.Icon = PageHeaderItems.PageHeaderIcon;
PageHeader.SelectField = PageHeaderItems.PageHeaderSelectField;
PageHeader.InputField = PageHeaderItems.PageHeaderInputField;

export default PageHeader;
