import React, {useCallback, useMemo} from 'react';
import cn from 'classnames';
// Utils
import {noop} from 'utils/event';
import {getTotalPayoutStyles} from './utils'; // eslint-disable-line import/order
import {sortAlphabeticallyOrNumerically} from 'components/Elements/DataTable/utils/sort.utils';
import {actionMenuColumn} from 'components/Elements/DataTable/utils/columns.utils';
// Types && Ducks
import {TDataTableColumnDef} from 'components/Elements/DataTable/dataTable.types';
import {ProjectDetails, ProjectPaymentAdjustment} from 'features/MultiDwellingUnits/MDU.types';
// Components && Styles
import {Button, BUTTON_THEMES, Icon} from 'ht-styleguide';
import DataTable from 'components/Elements/DataTable';
import styles from './paymentAdjustment.styles.scss';

type AdjustmentViewProps = {
  project: ProjectDetails;
  onButtonClick: BaseAnyFunction;
  onEditAdjustment: BaseAnyFunction;
  onDeleteAdjustment: BaseAnyFunction;
};

const AdjustmentView = ({project, onButtonClick = noop, onEditAdjustment, onDeleteAdjustment}: AdjustmentViewProps) => {
  const {payoutAdjustments = []} = project;
  const isUserAllowedToEdit = project.unitPaymentStatus === 'none';

  const getListItems = useCallback(
    (adjustment: ProjectPaymentAdjustment) => {
      return [
        {
          text: 'Edit',
          key: 0,
          onClick: () => onEditAdjustment(adjustment),
        },
        {
          text: 'Remove',
          key: 1,
          onClick: () => onDeleteAdjustment(adjustment),
        },
      ];
    },
    [onDeleteAdjustment, onEditAdjustment]
  );

  /**
   * For sorting purposes
   */
  const normalizedData = useMemo(
    () =>
      payoutAdjustments.map(a => ({
        tableDisplayName: a.name || a.payoutAdjustmentType?.name || 'Unknown',
        ...a,
      })),
    [payoutAdjustments]
  );

  type TNormalizedData = (typeof normalizedData)[number];

  const columns = useMemo(() => {
    const columnDefs: TDataTableColumnDef<TNormalizedData>[] = [
      {
        header: 'Type',
        accessorKey: 'tableDisplayName',
        Footer: () => <p className={cn('text-weight-med p2', styles.noWrap)}>TOTAL PAYOUT ADJUSTMENT</p>,
        sortingFn: sortAlphabeticallyOrNumerically,
        columnWidthMode: 'fill',
        columnWidthSize: 'sm',
      },
      {
        header: 'Per Unit Adjustment',
        accessorKey: 'valueFormatted',
        sortingFn: sortAlphabeticallyOrNumerically,
        columnWidthMode: 'fill',
        columnWidthSize: 'md',
        muiTableHeadCellProps: {
          className: 'DataTable-TableHeader_alignRight',
        },
        muiTableBodyCellProps: {
          className: 'text-right',
        },
      },
      {
        header: 'Total Adjustment',
        accessorKey: 'totalTechPayoutAdjustmentFormatted',
        muiTableHeadCellProps: {
          className: 'DataTable-TableHeader_alignRight',
        },
        muiTableBodyCellProps: {
          className: 'text-right',
        },
        Footer: () => <p className={cn(getTotalPayoutStyles(project), 'text-weight-med p2 text-right')}>{project.totalTechPayoutAdjustmentFormatted}</p>,
        sortingFn: sortAlphabeticallyOrNumerically,
        columnWidthMode: 'fill',
        columnWidthSize: 'md',
      },
    ];

    if (isUserAllowedToEdit) {
      columnDefs.push({
        ...actionMenuColumn<TNormalizedData>({getListItems}),
        Footer: () => <></>,
      });
    }

    return columnDefs;
  }, [getListItems, isUserAllowedToEdit, project]);

  if (!project?.id) return null;

  return (
    <div>
      <div className="flex justify-content-space-between align-items-center marginBottom-medium">
        <p className="h5 n700">Per-Unit Adjustments</p>
        <Button theme={BUTTON_THEMES.SECONDARY} className={styles.addPayoutsButton} onClick={onButtonClick} small>
          <Icon name="v2-plus-icon" />
          &nbsp;&nbsp;Add Payout Adjustment
        </Button>
      </div>
      <DataTable<TNormalizedData> tableKey="CurrentProject-PaymentAdjustment-AdjustmentView" columns={columns} data={normalizedData} isZebra />
      <div className="paddingBottom-huge1" />
    </div>
  );
};

export default AdjustmentView;
