import React from 'react';
import ReactModal from 'react-modal';
import cn from 'classnames';
import {Size} from 'types/base.types';
import styles from './modal.styles.scss';

/*
  TODO: Maybe we should pass in a config option to manipulate the position properties, (e.g. top, left, right, bottom)
  In client app top is 30%, but some modals, e.g. PlanUpsellModalProps, might be very tall and the top property
  may need to be adjusted -GH Jul 18, 2020
*/

/*
  TODO: We need to find a way to optionally close this modal onClick, which is a requirement for the upsell modal. Unsucessful in my attempts, need more research -GH Jul 18. 2020
*/
export type ModalProps = {
  isVisible?: boolean;
  hide?: (event: any) => void;
  header?: string;
  subHeader?: string;
  subHeaderComponent?: JSX.Element;
  footerElement1?: React.ReactNode;
  footerElement2?: React.ReactNode;
  footerElement3?: React.ReactNode;
  size?: string;
  modalClassName?: string;
  footerClasses?: string;
  footerElement1Classes?: string;
  footerElement2Classes?: string;
  footerElement3Classes?: string;
  style?: any;
};

/**
 * @deprecated Use <Modal /> from `ht-styleguide` instead
 */
const Modal = ({
  isVisible = false,
  size = Size.M,
  hide,
  subHeader,
  subHeaderComponent,
  header,
  children,
  modalClassName = '',
  footerClasses,
  footerElement1,
  footerElement2,
  footerElement3,
  footerElement1Classes,
  footerElement2Classes,
  footerElement3Classes,
  style,
}: React.PropsWithChildren<ModalProps>) => {
  const closeStyles = cn('ht-icon ht-v2-close-icon', styles.modalClose);
  const headerStyles = cn('h3', styles.header);
  const subHeaderStyles = cn('p1 n700', styles.subHeader);
  const footerElement1Styles = cn(footerElement1Classes, styles.footerElement1);
  const footerElement2Styles = cn(footerElement2Classes, styles.footerElement2);
  const footerElement3Styles = cn(footerElement3Classes, styles.footerElement3);
  const slideAnimation = isVisible ? styles.slideUp : styles.slideDown;
  const footerStyles = cn(footerClasses, {
    [styles.footerContainer]: footerElement1 || footerElement2 || footerElement3,
  });
  const className = cn(
    'site-v2',
    styles.commonModalStyles,
    slideAnimation,
    {
      [styles.modalLarge]: size === Size.L,
      [styles.modalMedium]: size === Size.M,
      [styles.modalSmall]: size === Size.S,
    },
    modalClassName
  );

  return (
    <ReactModal ariaHideApp={false} isOpen={isVisible} className={className} overlayClassName={styles.overlay} contentLabel={header} style={style}>
      <div className={styles.headerContainer}>
        {/* h3 needs to be inserted into DOM to keep the close button pushed to the right, so conditionally assign the testid */}
        <h3 data-testid={header ? 'modal-header' : null} className={headerStyles}>
          {header}
        </h3>
        <div className={closeStyles} onClick={hide} />
      </div>
      {subHeader && <div className={subHeaderStyles}>{subHeader}</div>}
      {subHeaderComponent && subHeaderComponent}
      <div className={styles.modal}>{children}</div>
      <div className={footerStyles}>
        <div className={footerElement1Styles}>{footerElement1}</div>
        <div className={styles.footerElementContainer}>
          {footerElement2 && <div className={footerElement2Styles}>{footerElement2}</div>}
          <div className={footerElement3Styles}>{footerElement3}</div>
        </div>
      </div>
    </ReactModal>
  );
};

export default React.memo(Modal);

/*
  Modal: Wrapper for modals

  Usage:
  import {Size} from 'types/base.types';
  import { Modal, useModalToggle, ModalProps } from 'components/Elements/Modal';

  If the footerItems are buttons:
  import {Button, BUTTON_THEMES} from 'ht-styleguide';
  or link:
  import { Link } from 'react-router-dom';

  inside the parent functional component:
  const { isVisible, toggle } = useModalToggle();

  Typically in a modal, the minimum would be one button right-justified. In the event there are 2 buttons and a link, the buttons are right-justified and link is left-justified and this is the usage:
  const footerElement1 = <Link>Footer element - this is a link <Link> (This will be a left-justified Link(or a button, if necessary) when used with the other elements below)
  const footerElement2 = <Button>Footer element - this is a button <Button> (This will be one of right-justified the buttons)
  const footerElement3 = <Button>Footer element - this is a button <Button> (This will be one of right-justified the buttons)

  In a class component, add state and, a method to toggle open/close modal.

  <button onClick={toggle}>Your Show Modal Button</button>

  <Modal
    isVisible={isVisible}
    hide={toggle}
    header="Custom Header"
    subHeader="Custom SubHeader"
    footerElement1={footerElement1}
    footerElement2={footerElement2}
    footerElement3={footerElement3}
    size={Size.L}
  >
    {children (any other component)}
  </Modal>
*/
