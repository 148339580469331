import React, {useRef} from 'react';
import cn from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import styles from './HeaderWithToolbar.styles.scss';
import {THeaderText} from './HeaderWithToolbar.types';

const HeaderText: React.FC<THeaderText> = ({className, headerText, subheaderText, isHeaderTextEditable, formik, isCollapsed}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const inputStyles = cn(styles.input, className, {
    [styles.error]: formik.errors.headerText,
  });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // Allow user to type shift + enter to add a new line
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      formik.handleSubmit();
      if (event.currentTarget instanceof HTMLInputElement) {
        inputRef.current?.blur();
      } else if (event.currentTarget instanceof HTMLTextAreaElement) {
        textareaRef.current?.blur();
      }
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.handleBlur(event);
    formik.handleSubmit();
    event.target.blur();
    inputRef.current?.blur();
  };

  const showSubheader = subheaderText && !isCollapsed;
  if (isHeaderTextEditable) {
    return (
      <div>
        {isCollapsed ? (
          <input type="text" name="headerText" value={formik.values.headerText} onChange={formik.handleChange} onBlur={handleBlur} className={inputStyles} onKeyDown={handleKeyDown} ref={inputRef} />
        ) : (
          <TextareaAutosize name="headerText" value={formik.values.headerText} onChange={formik.handleChange} onBlur={handleBlur} className={inputStyles} onKeyDown={handleKeyDown} ref={textareaRef} />
        )}
        {formik.errors.headerText && <p className={cn(styles.errorText, 'caption marginTop-tiny')}>{formik.errors.headerText}</p>}
        {showSubheader && <p className={cn('p2 n700 marginTop-small', styles.subheaderText)}>{subheaderText}</p>}
      </div>
    );
  }

  return (
    <>
      <p className={cn(className)}>{headerText}</p>
      {showSubheader && <p className={cn('p2 n700 marginTop-small', styles.subheaderText)}>{subheaderText}</p>}
    </>
  );
};

export default HeaderText;
