import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {BUTTON_THEMES, Button, EmptyState, Panel, SideSheet} from 'ht-styleguide';

/* Slice/Ducks */
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';

/* Hooks */
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useCurrentProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/CurrentProject.hooks';

/* Components */
import CreateBulkJobsHeader, {HeaderTypes} from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/Parts/TemplateJobs.Draft.Header';
import MenuWrapper from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/Jobs/Jobs.Component.MenuWrapper';
import CreatingBulkExistingTemplates from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/Jobs/AddJobs/Components/Jobs.Create.Existing';
import JobsCreateTakeOver from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/Jobs/AddJobs/Multi/Jobs.Create.TakeOver';
import JobsCreateTemplatesView from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/Jobs/AddJobs/Multi/Jobs.Create.Templates';

/* Utils / Constants */
import {mduProjectPathByStage, mduProjectBulkJobCreate} from 'global/paths';

import {MduUseParamsTypes, ProjectGroup} from 'features/MultiDwellingUnits/MDU.types';

import styles from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/Jobs/jobs.styles.scss';
import {useSelector} from 'react-redux';
import {JobQuantitySection} from './Jobs.Create.Components';

/**
 * Use case:
 * A user wants to add jobs in bulk. This component is the staging ground
 * for adding multiple jobs at once. The user can create/add templates via CTA
 * and view a list of templates/jobs they wish to add to the project.
 */
const CreateBulkJobs = () => {
  /* Local State */
  const [unitsNumber, setUnitCount] = useState<number>();
  const [jobTemplate, setJobTemplate] = useState<ProjectGroup>();

  /* Hooks */
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {projectId = '', createTemplateType = '', stage = ''} = useParams<MduUseParamsTypes>();
  const projectGroupsDraft = useSelector(mduProjectsSlice.selectors.getDraftProjectGroups);
  const totalJobCount = useSelector(mduProjectsSlice.selectors.getDraftTotalJobs);
  const currentProject = useCurrentProject();

  /* Constants */
  const showAddJobTemplate = createTemplateType === 'existing-templates';

  /* Methods */
  const redirectToBaseCreatePage = () => {
    navigate(mduProjectBulkJobCreate(projectId));
  };

  const onSubmitExistingTemplate = () => {
    // 1. Add Template and unit count to existing draft state */
    dispatch(mduProjectsSlice.actions.createTemplateCreationExistingDraft({...jobTemplate, unitsNumber}));
    // 2. redirect to base create page redirectToBaseCreatePage();
    return redirectToBaseCreatePage();
  };

  const FooterDisplay = () => {
    const isDisabled = !unitsNumber || unitsNumber < 1 || !jobTemplate;

    return (
      <Button disabled={isDisabled} theme={BUTTON_THEMES.PRIMARY} onClick={onSubmitExistingTemplate}>
        Add Template
      </Button>
    );
  };

  return (
    <>
      <JobsCreateTakeOver>
        <CreateBulkJobsHeader totalJobCount={totalJobCount} headerType={HeaderTypes.draft} onHandleCancelRedirect={() => navigate(mduProjectPathByStage(projectId, stage))} />
        <div className="flex flex-direction-row justify-content-space-between">
          <p className="p1">{totalJobCount} Additional Jobs</p>
          <div>
            <MenuWrapper plainButton icon="plus-circle" buttonText="Add Templates" />
          </div>
        </div>
        {projectGroupsDraft?.length > 0 ? (
          <JobsCreateTemplatesView projectGroupsDraft={projectGroupsDraft} />
        ) : (
          <Panel noShadow largeBorderRadius className="border padding-large marginTop-medium flex flex-direction-column align-items-center">
            <EmptyState iconName="layers" title="Allocate jobs via templates" text="Use job templates to set up additional skus" className={styles.emptyContainerWidth} showButtonContainer>
              <MenuWrapper icon="plus-circle" buttonText="Add Templates" />
            </EmptyState>
          </Panel>
        )}
      </JobsCreateTakeOver>

      {/* Slide Sheets */}
      <SideSheet subheaderText={currentProject.name} footerContent={<FooterDisplay />} headerText="Add Existing Template" hide={redirectToBaseCreatePage} isOpen={showAddJobTemplate}>
        <CreatingBulkExistingTemplates actions={{setJobTemplate}} />
        <JobQuantitySection setUnitCount={setUnitCount} />
      </SideSheet>
    </>
  );
};

export default CreateBulkJobs;
