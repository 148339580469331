import {useSelector} from 'react-redux';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';

export const useCurrentProject = () => {
  const currentProject = useSelector(mduProjectsSlice.selectors.getCurrentProject);

  return {
    ...currentProject,
  };
};
