import {createAsyncThunk, createSelector} from '@reduxjs/toolkit';
import get from 'lodash/get';
import {getTreatments} from '@splitsoftware/splitio-redux';
import {authToken} from 'utils/auth';

/* Types */
import {RootState} from 'store';
import {IGetTreatmentParams} from 'features/Splitio/splitio.types';

/*
 *******************************************************
    ASYNC ACTIONS
 *******************************************************
 */
const asyncActions = {
  getTreatments: createAsyncThunk<void, IGetTreatmentParams, {state: RootState}>('htSplit/getTreatments', async (params, {getState, dispatch}) => {
    /**
     * We've created a thunk to retrieve split data. We need to make sure
     * that split is ready otherwise the 'control' treatment will return.
     * This is not the desired outcome as the app may have persisted or retrieved
     * the data from a prior call, and the data should not be overwritten with
     * the 'control' treatment.
     */

    const {splitio} = getState();

    if (splitio.isReady) {
      // @ts-ignore
      await dispatch(getTreatments(params));
    }
  }),
};

/*
*******************************************************
  SELECTORS & SELECTOR METHODS
*******************************************************
*/
const getSplitState = (state: RootState) => state.splitio;

/*
*******************************************************
  EXPORTS
*******************************************************
*/
export const actions = asyncActions;

export const selectors = {
  getSplitState: createSelector(getSplitState, splitState => splitState),
  getSplitStatuses: createSelector(getSplitState, splitState => {
    const {isReady, hasTimedout} = splitState;
    return {isReady, hasTimedout};
  }),
  getTreatmentValueByKey: (splitName = '', key = null) => {
    /* We should ever have one user, but if we need anther id to get, we can pass it in */
    const splityByKeyId = key || authToken.getAuthTokenId();
    return createSelector(getSplitState, splitState => {
      const {treatment, config} = get(splitState, `treatments.${splitName}.${splityByKeyId}`, {});

      return {
        treatment,
        config: config ? JSON.parse(config) : null,
      };
    });
  },
};
