import React, {useEffect, useState} from 'react';
/* Hooks && Queries */
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useSelector} from 'hooks/useAppSelector';
import {useVerifyQAForm} from 'features/MultiDwellingUnits/MDU.hooks';
import {useGetProjectSkusByPartner} from 'features/MultiDwellingUnits/queries/query.project.skusByPartner';
/* Utils */
import {noop} from 'utils/event';
import {pluralize} from 'utils/text';
/* Ducks && Types */
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {SelectOption} from 'features/MultiDwellingUnits/Parts/FormFields/FormFields.types';
/* Components */
import AddSkuPage from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/AddSku/MDU.AddSku';
import {ELEMENT_SIZE, SelectField, SideSheet, EmptyState, Panel} from 'ht-styleguide';

export type TSideSheetBulkEditAddServicesProps = {
  isOpen: boolean;
  totalSelected: number;
  hide: () => void;
  onOpen?: () => void;
  onSuccess?: () => void;
  /** Escape hatch to avoid form validation */
  validate?: boolean;
};

const Label = ({text}: {text: string}) => <p className="h6 n800 marginBottom-small2">{text}</p>;

/**
 * Get questions/answers for the selected sku that we are going to bulk add
 */
const SideSheetBulkEditAddServices = ({isOpen, totalSelected, hide = noop, onOpen = noop, onSuccess = noop, validate = true}: TSideSheetBulkEditAddServicesProps) => {
  /* State */
  const defaultOption = {label: '', value: ''} as SelectOption;
  const [dropdownSku, setDropdownSku] = useState(defaultOption);
  const resetDropdownSku = () => setDropdownSku(defaultOption);
  const dropdownSkuId = dropdownSku.value;
  const isSkuSelected = Boolean(dropdownSkuId);

  const [errors, setErrors] = useState({});
  const resetErrors = () => setErrors({});

  /* Hooks */
  const dispatch = useAppDispatch();
  const verifyQaForm = useVerifyQAForm();
  const {name: currentProjectName} = useSelector(mduProjectsSlice.selectors.getCurrentProject) || {};
  const addSkuState = useSelector(mduProjectsSlice.selectors.getAddSkuState);
  const isSkuFetched = Boolean(addSkuState.skus.ids.includes(dropdownSkuId));

  /* Queries */
  const {data: partnerSkuData = []} = useGetProjectSkusByPartner();

  /* Handlers */
  const onSelectChange = (option: SelectOption) => {
    resetErrors();
    setDropdownSku(option);
  };

  const handleClose = () => {
    resetDropdownSku(); // Clear value in case user opens the side sheet again
    resetErrors();
    hide();
  };

  const handleButtonClick = () => {
    resetErrors();

    if (validate) {
      const formErrors = verifyQaForm();
      if (Object.keys(formErrors).length) {
        setErrors(formErrors);
        return;
      }
    }

    resetDropdownSku();
    onSuccess();
  };

  /* Sidesheet props */
  const jobs = pluralize('Job', totalSelected);
  const buttonProps = {
    ctaButtonText: `Add Services to ${totalSelected} ${jobs}`,
    ctaButtonProps: {
      ...(!isSkuSelected && {disabled: true}),
      onClick: handleButtonClick,
    },
  };

  /* Effects */
  useEffect(() => {
    /*
        <AddSkuPage /> fetches sku and questions and sets them in state by calling actions.setSelectedSku().
        We need to call actions.setSelectedSku() here to update local state and Redux, because the result of the
        fetch query is cached and only updates when the API is called again.
      */
    if (isSkuFetched) {
      dispatch(mduProjectsSlice.actions.setSelectedSku({skuId: String(dropdownSkuId), isEditMode: false, groupId: '', serviceId: ''}));
    }
  }, [dispatch, dropdownSkuId, isSkuFetched]);

  useEffect(() => {
    if (isOpen) {
      onOpen();
    }
  }, [isOpen, onOpen]);

  return (
    <SideSheet isOpen={isOpen} headerText="Add Service" subheaderText={currentProjectName} hide={handleClose} {...buttonProps}>
      <div className="marginTop-small marginBottom-medium1">
        <Label text="Choose Service" />
        <SelectField
          id="skusList"
          dataTestId="skusList"
          placeholder="Search by SKU ID or name"
          elementSize={ELEMENT_SIZE.MEDIUM}
          searchable
          onChange={onSelectChange}
          value={dropdownSku}
          options={partnerSkuData}
        />
      </div>
      <div>
        <Label text="Service Details" />
        {isSkuSelected ? (
          <AddSkuPage errors={errors} skuId={String(dropdownSkuId)} showSkuName={false} />
        ) : (
          <Panel noShadow largeBorderRadius className="border paddingY-large flex flex-direction-column align-items-center">
            <EmptyState title="No service selected" iconName="tool" className="marginBottom-medium" />
          </Panel>
        )}
      </div>
    </SideSheet>
  );
};

export default SideSheetBulkEditAddServices;
