type DetailsFormFieldsObjType = {
  [key: string]: {
    /** Label to be displayed over input/select field */
    label: string;
    /** Key used to get/set formik values */
    id: string;
    /** Key used to get values from currentProject obj in Redux */
    projectKey: string;
    /** Placeholder text to be displayed in input/select field */
    placeholder?: string;
    /** field id of a select field's "Other" option */
    otherId?: string;
  };
};
export const DETAILS_FORM_FIELDS: DetailsFormFieldsObjType = {
  // Project
  PROJECT_NAME: {label: 'Project Name*', id: 'name', projectKey: 'name'},
  PARTNER: {label: 'Partner*', id: 'partnerId', projectKey: 'partner.id'},
  PROJECT_MANAGER: {label: 'Project Manager*', id: 'projectManagerId', projectKey: 'projectManager.id'},
  PROPERTY_OWNER: {label: 'Property Owner*', id: 'propertyOwnerId', projectKey: 'propertyOwner.id', otherId: 'propertyOwnerName'},
  PROJECT_TYPE: {label: 'Project Type*', id: 'projectType', projectKey: 'projectType.slug'},
  START_DATE: {label: 'Start Date*', id: 'startDate', projectKey: 'startDate'},
  END_DATE: {label: 'Estimated End Date*', id: 'estimatedEndDate', projectKey: 'estimatedEndDate'},
  // Property
  ADDRESS: {label: 'Address*', id: 'address', projectKey: 'address'},
  NUM_FLOORS: {label: 'Number of Floors', id: 'floorsNumber', projectKey: 'floorsNumber', placeholder: 'Optional'},
  ACCESS_INSTRUCTIONS: {label: 'Access and Parking Instructions', id: 'accessInstructions', projectKey: 'accessInstructions'},
  //  On-site Contact
  CONTACT_NAME: {label: 'Name*', id: 'contactName', projectKey: 'contactName'},
  CONTACT_PHONE: {label: 'Phone Number*', id: 'contactPhone', projectKey: 'contactPhone'},
  CONTACT_TITLE: {label: 'Job Title*', id: 'contactTitle', projectKey: 'contactTitle'},
  // Other Notes
  OTHER: {label: 'Other Notes', id: 'notes', projectKey: 'notes'},
};
