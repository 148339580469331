import useApi from 'hooks/useApi';
import APIS from 'global/apis';
import {useMutation, useQueryClient} from 'react-query';
import {ISSUES_QUERY_KEYS} from './Issues.query.keys';
import {invalidateTableQueries} from './issues.helpers';

export const useDeleteIssueMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (ticket_id: number | string) => {
      api.toggleLoader(true);
      const response = await APIS.issues.deleteIssue({ticket_id});
      api.toggleLoader(false);
      if (response.status >= 400) throw new Error(`error deleteing issue: ${response.statusText}`);
      return response;
    },
    {
      onSuccess: (_, ticket_id) => {
        // clear cache for ticket_id
        queryClient.removeQueries(ISSUES_QUERY_KEYS.getTicket(+ticket_id));
        // invalidate table queries
        invalidateTableQueries(queryClient);
      },
    }
  );
};
