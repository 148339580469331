import React from 'react';
import dayjs from 'dayjs';
import {EmptyState, LoaderComponent} from 'ht-styleguide';
import {Comment} from 'features/Issues/Parts/CommentFeed/Comment';
import {useGetIssuesNotesQuery} from 'features/Issues/queries/query.issues.getNotes';
import {useSearchParams} from 'hooks/useSearchParam';

type TCommentFeed = {
  ticketId?: string | number;
  withAvatar?: boolean;
  withLightbox?: boolean;
};

/**
 * Comment Feed of a ticket. User can pass in the ticketId or we can derive it from the url.
 * Lets assume a user can put this is any ticket page flow. Avatar is optional.
 *
 * @param ticketId
 * @param withAvatar
 */
export const CommentFeed = ({ticketId, withAvatar = true, withLightbox = true}: TCommentFeed) => {
  const {getParam} = useSearchParams();
  const {data, isLoading} = useGetIssuesNotesQuery({ticketId: Number(ticketId || getParam('ticketId'))});
  const sortedData = data?.sort((a, b) => dayjs(b.created_at).unix() - dayjs(a.created_at).unix());

  const DisplayComment = () => {
    if (!data || data?.length === 0) {
      return (
        <div className="text-center">
          <EmptyState iconName="message" title="No comments added yet" text="All comments associated with the issue will be found here." />
        </div>
      );
    }

    return (
      <>
        <div className="paddingBottom-small1 h6 font-weight-bold">Comments</div>
        {sortedData?.map(comment => (
          <React.Fragment key={`{comment.updated_at}-${comment.id}`}>
            <Comment withLightbox={withLightbox} withAvatar={withAvatar} comment={comment} />
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <div className="fullwidth border-top-2 paddingBottom-medium">
      <div className="marginTop-medium">{isLoading ? <LoaderComponent /> : <DisplayComment />}</div>
    </div>
  );
};
