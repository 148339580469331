import React, {RefObject} from 'react';
import {MRT_Icons, MRT_TableInstance} from 'material-react-table';
import {IconCellComponent} from '../../CellComponent';

const ExpandAllIcon: <TData extends Record<string, any> = {}>({internalTableRef}: {internalTableRef: RefObject<MRT_TableInstance<TData>>}) => MRT_Icons['KeyboardDoubleArrowDownIcon'] =
  ({internalTableRef}) =>
  (props: Object) => {
    const table = internalTableRef?.current;
    return <IconCellComponent className="n900" name="double-chevron-down" {...props} table={table} />;
  };

export default ExpandAllIcon;
