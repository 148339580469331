import React, {memo, useState, ChangeEvent} from 'react';
import {IHash} from 'types/base.types';
import PreQuestionInput from 'features/Booking/Parts/QA/QA.Input';
import PreQuestionTextArea from 'features/Booking/Parts/QA/QA.TextArea';
import PreQuestionDropdown from 'features/Booking/Parts/QA/QA.Dropdown';
import PreQuestionCheckbox from 'features/Booking/Parts/QA/PreQuestionCheckbox';
import QuestionDevices from 'features/Booking/Parts/QA/QuestionDevices';
import {QuestionsAPIByQuestion, QuestionTypes, Sku, ErrorHashProp} from 'features/Questions/types';
import {isStringType} from 'features/Booking/Parts/QA/qa.utils';

type ActionsWithState = {
  inputChange: Function;
  inputValue: () => string;
  dropdownChange: Function;
  dropdownValue: () => IHash<number>;
  checkboxChange: Function;
  checkboxQuantityChange: Function;
  checkboxValue: Function;
  // Device Questions
  makeValue: any;
  modelValue: any;
  makeDropdownChange: Function;
  modelDropdownChange: Function;
  makeInputChange: Function;
  modelInputChange: Function;
  resetDeviceQuestion: Function;
};

type PreQuestion = {
  question: QuestionsAPIByQuestion;
  sku: Sku;
  error?: ErrorHashProp | string;
  actions: (makeInputValue: string, setMakeInputValue: React.Dispatch<React.SetStateAction<string>>) => ActionsWithState;
};

const PostQuestion: React.FC<PreQuestion> = props => {
  const [makeInputValue, setMakeInputValue] = useState('');

  const {question, error = '', sku, actions} = props;

  const actionsWithState = actions(makeInputValue, setMakeInputValue);
  const inputChange = () => {
    return (event: Event) => actionsWithState.inputChange(event);
  };

  const inputValue = () => {
    return actionsWithState.inputValue();
  };

  const dropdownChange = () => {
    return (value: number) => actionsWithState.dropdownChange(value);
  };

  const dropdownValue = () => {
    return actionsWithState.dropdownValue();
  };

  const checkboxChange = () => {
    return (value: number[]) => actionsWithState.checkboxChange(value);
  };

  const checkboxQuantityChange = () => {
    return ({id, quantity}: IHash<number>) => actionsWithState.checkboxQuantityChange({id, quantity});
  };

  const checkboxValue = () => {
    return actionsWithState.checkboxValue();
  };

  /*
  ----------------------------------------------
    START DEVICE CATALOGING onchange & values
  ----------------------------------------------
  */
  const {makeValue, modelValue} = actionsWithState;
  const isMakeValueString = isStringType(makeValue);
  const isModelValueString = isStringType(modelValue);

  const makeDropdownChange = (value: number) => {
    /*
      If any change to make dropdown is made,
      reset the local state makeInputValue to ''
    */
    return actionsWithState.makeDropdownChange(value);
  };

  const modelDropdownChange = (value: number) => {
    return actionsWithState.modelDropdownChange(value);
  };

  const makeInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    return actionsWithState.makeInputChange(event);
  };

  const modelInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    /*
      When on edit, check if make input is present.
      If yes, it will send makeInputValue to PreQuestionDevice component which renders inputfield
      If not, render dropdown with make value.
    */
    return actionsWithState.modelInputChange(event);
  };

  const resetDeviceQuestion = () => {
    /*
      This function triggers when X icon on make input is clicked.
      It resets all values to null.
    */
    return actionsWithState.resetDeviceQuestion();
  };
  /*
  ----------------------------------------------
    END DEVICE CATALOGING onchange & values
  ----------------------------------------------
  */

  const render = () => {
    let questionComponent;
    const err = error as string;

    switch (question.inputType) {
      case QuestionTypes.Input:
        questionComponent = <PreQuestionInput question={question} error={err} onChange={inputChange()} value={inputValue()} />;
        break;
      case QuestionTypes.Textarea:
        questionComponent = <PreQuestionTextArea question={question} error={err} onChange={inputChange()} value={inputValue()} />;
        break;
      case QuestionTypes.Dropdown:
        questionComponent = <PreQuestionDropdown question={question} error={err} sku={sku} onChange={dropdownChange()} value={dropdownValue()} />;
        break;
      case QuestionTypes.Checkbox:
        questionComponent = <PreQuestionCheckbox question={question} error={err} sku={sku} onChange={checkboxChange()} onQuantityChange={checkboxQuantityChange()} value={checkboxValue()} />;
        break;
      case QuestionTypes.Device:
        questionComponent = (
          <QuestionDevices
            question={question}
            error={error as ErrorHashProp}
            sku={sku}
            value={makeValue} // MAKE VALUE
            modelValue={modelValue} // MODEL VALUE
            makeInputValue={isMakeValueString ? makeValue : makeInputValue} // MAKE INPUT VALUE
            modelInputValue={isModelValueString ? modelValue : ''} // MODEL INPUT VALUE
            onMakeDropdownChange={makeDropdownChange} // MAKE ONCHANGE
            onModelDropdownChange={modelDropdownChange} // MODEL ONCHANGE
            onMakeInputChange={makeInputChange} // MAKE INPUT ONCHANGE
            onModelInputChange={modelInputChange} // MODEL INPUT ONCHANGE
            onIconClick={resetDeviceQuestion}
          />
        );
        break;
      default:
        throw new Error(`Unknown input type: ${question.inputType}`);
    }

    return questionComponent;
  };

  return <div id={`${question.id}`}>{render()}</div>;
};

export default memo(PostQuestion);

/*
  Inject your library of functions to this component as the actions prop.
  See mdu.postQuestionActions.ts for an example.
*/
