import React from 'react';
import {ELEMENT_SIZE, SelectField} from 'ht-styleguide';
import cn from 'classnames';
import {useParams} from 'react-router-dom';
import PageHeader from 'components/Elements/PageHeader';
/* Hooks / Queries */
import {useGetProjectSkusByPartner} from 'features/MultiDwellingUnits/queries/query.project.skusByPartner';

import {MduUseParamsTypes, SelectOption} from 'features/MultiDwellingUnits/MDU.types';
import styles from '../templatejobs.styles.scss';

type TPartnerSkusDropdownProps = {
  classes?: string;
  onHandleSelection?: (sku: SelectOption) => void;
  /**
   * Pass `true` if this is used `<PageHeader />` to apply the correct component (styling for PageHeader)
   */
  useInPageHeader?: boolean;
};
const TemplateJobsPartnerSkusDropdown = ({classes, onHandleSelection, useInPageHeader = false}: TPartnerSkusDropdownProps) => {
  /* Hooks */
  const {data: partnerSkuData = []} = useGetProjectSkusByPartner();
  const {skuId = ''} = useParams<MduUseParamsTypes>();

  /* Constants */
  const selectedSku = partnerSkuData.find((sku: SelectOption) => +skuId === +sku?.value) || {};

  /* Methods */
  /* User made a selection from the dropdown/select field */
  const onHandleSkuSelect = async (sku: SelectOption) => {
    if (sku?.value) {
      onHandleSelection?.(sku);
    }
  };

  const SelectComponent = useInPageHeader ? PageHeader.SelectField : SelectField;
  const specialProps = useInPageHeader ? {} : {elementSize: ELEMENT_SIZE.MEDIUM};

  return (
    <SelectComponent
      id="skusList"
      placeholder="Search by SKU ID or name"
      searchable
      onChange={onHandleSkuSelect}
      value={selectedSku}
      options={partnerSkuData}
      className={cn(classes, styles.skuSearchInput)}
      {...specialProps}
    />
  );
};

export default TemplateJobsPartnerSkusDropdown;
