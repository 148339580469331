import {MutableRefObject} from 'react';
import {FormikProps} from 'formik';
import * as Yup from 'yup';
import mergeWith from 'lodash/mergeWith';
import {ISSUE_FIELDS_ENTITY_NAMES} from 'features/Issues/Issues.constants';
import {TField, TIssueDynamicFields} from 'features/Issues/issues.types';

/**
 * @description Helper functions for the formik form
 *
 * Between the create/edit issue side sheets, there is a lot of shared functionality. Lets
 * abstract that out into a helper function, since it's really identical.
 *
 * @param formik - instance of formik
 */
type TUseFormikIssueHelpersProps = {
  formik: FormikProps<TIssueDynamicFields>;
  initialValues: MutableRefObject<FormikProps<TIssueDynamicFields>['initialValues']>;
  schema: MutableRefObject<Yup.Schema<TIssueDynamicFields> | null>;
};

type TMethodProps = {fields: TField[]} & Partial<TUseFormikIssueHelpersProps>;

export const useFormikIssueHelpers = ({formik: formikInstance, initialValues: initialValuesInstance, schema: schemaInstance}: TUseFormikIssueHelpersProps) => {
  /**
   * @description Shared value setter for formik values and initial values
   * @param field
   */
  const sharedValueSetter = (field: TField) => {
    return (() => {
      if (!field.default_values && !field.value) return '';

      const values = field.value || field.default_values;
      switch (field.type) {
        case ISSUE_FIELDS_ENTITY_NAMES.select:
        case ISSUE_FIELDS_ENTITY_NAMES.multi_select:
          return values?.map(v => v.value) || [];
        case ISSUE_FIELDS_ENTITY_NAMES.rich_content:
        case ISSUE_FIELDS_ENTITY_NAMES.numeric:
          return values?.reduce((all, v) => v.value, '') || '';
        case ISSUE_FIELDS_ENTITY_NAMES.text:
        case ISSUE_FIELDS_ENTITY_NAMES.long_text:
          return values?.reduce((all, v) => v.value, '') || '';
        case ISSUE_FIELDS_ENTITY_NAMES.datetime:
          return values?.reduce((all, v) => v.value, '') || '';
        default:
          return '';
      }
    })();
  };

  /**
   * @description Set the schema for the formik form
   */
  const setFormikSchema = ({fields, schema = schemaInstance}: TMethodProps) => {
    const formikSchema = fields.reduce((allFields, field) => {
      const isRequired = field.required;
      const validation = (() => {
        switch (field.type) {
          case ISSUE_FIELDS_ENTITY_NAMES.select:
          case ISSUE_FIELDS_ENTITY_NAMES.multi_select:
          case ISSUE_FIELDS_ENTITY_NAMES.rich_content:
          case ISSUE_FIELDS_ENTITY_NAMES.text:
          case ISSUE_FIELDS_ENTITY_NAMES.long_text:
          case ISSUE_FIELDS_ENTITY_NAMES.datetime:
            return isRequired ? Yup.string().required('Required') : Yup.string();
          case ISSUE_FIELDS_ENTITY_NAMES.numeric:
            return isRequired ? Yup.number().required('Required Numeric') : Yup.number();
          default: {
            return isRequired ? Yup.string().required('Required') : Yup.string();
          }
        }
      })();

      return {...schema.current, ...allFields, [field.name]: validation};
    }, {});

    /* Update the schema (this is also needed to compare w/update for updating api) */
    // @ts-ignore
    schema.current = formikSchema;
  };

  /**
   * @description Set the initial values for the formik form. This is used so we have a baseline to compare vs set values.
   */
  const setFormikInitialValues = ({initialValues = initialValuesInstance, fields}: TMethodProps) => {
    const formikInitialValues = fields.reduce((allFields, field) => {
      /* Initial Values: Default values need to be formatting diff depending on type of field. */
      const newValue = sharedValueSetter(field);

      return {...allFields, [field.name]: newValue};
    }, {});
    /* Update the initial values (this is also needed to compare w/update for updating api) */
    initialValues!.current = {...initialValues!.current, ...formikInitialValues};
  };

  /**
   * @description Set the values for the formik form
   *
   * This is very similiar to setFormikInitialValues, but we need to use formik.setValues instead of initialValues.current,
   * since the "intent" is different, we'll separate them out.
   */
  const setFormikValues = ({formik = formikInstance, fields}: TMethodProps) => {
    const formikValues = fields.reduce((allFields, field) => {
      /* Initial Values: Default values need to be formatting diff depending on type of field. */
      const newValue = sharedValueSetter(field);

      return {...allFields, [field.name]: newValue};
    }, {});

    const mergedValues = mergeWith({}, formikValues, formik.values, (a, b) => (b === null ? a : undefined));

    formik.setValues(mergedValues as TIssueDynamicFields);
  };

  return {
    setFormikSchema,
    setFormikInitialValues,
    setFormikValues,
  };
};

/**
 * @description Normalize values for fields with ticket response
 * @note This is necessary because the response from the create issue endpoint and fields work together so have to "merge them"
 *       as the field "sets it up" and the response from the create issue endpoint gives us only the values.
 * @param value
 * @param field
 */
export const normalizeValuesForFields = ({value: _v, field}: {value: any; field: TField}) => {
  /* Edge case, but 'value' could be null */
  const value = _v || '';

  const isArray = Array.isArray(value);
  /* "sometimes" select type can have a single value number, lord why.... */
  const isNumberOrString = {string: 1, number: 1}[typeof value as string];

  switch (field.type) {
    case ISSUE_FIELDS_ENTITY_NAMES.select:
    case ISSUE_FIELDS_ENTITY_NAMES.multi_select:
    case ISSUE_FIELDS_ENTITY_NAMES.datetime: {
      const normalizedValueNumberOrString = isNumberOrString ? [{value}] : [value];
      const normalizedValues = isArray ? value : normalizedValueNumberOrString;

      return normalizedValues.map(({value: val, label, name, id}) => {
        const l = label || name;
        const v = val || id;

        return {
          ...(l && {label: l}),
          ...(v && {value: v}),
        };
      });
    }
    case ISSUE_FIELDS_ENTITY_NAMES.rich_content:
    case ISSUE_FIELDS_ENTITY_NAMES.numeric:
    case ISSUE_FIELDS_ENTITY_NAMES.text:
    case ISSUE_FIELDS_ENTITY_NAMES.long_text:
      return [{value}];
    default:
      return '';
  }
};
