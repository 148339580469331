import React from 'react';
import cn from 'classnames';
import {LoaderComponent} from 'ht-styleguide';

// Utils && Hooks
import {useThumbnailTooltip} from './thumbnail.display.hooks';

// Types
import {TThumbnailProps} from './thumbnail.types';
import styles from './thumbnailDisplay.styles.scss';

export const TEST_IDS = {
  DOWNLOAD_BUTTON: 'thumbnail-download-button',
  DELETE_BUTTON: 'thumbnail-delete-button',
  THUMBNAIL: 'thumbnail',
  LOADING_INDICATOR: 'thumbnail-loading-indicator',
};

const ThumbnailPreload = ({className, thumbnailClassName}: Partial<TThumbnailProps>) => {
  const {ref} = useThumbnailTooltip();

  return (
    <div className={cn(styles.thumbnail, className, thumbnailClassName)} ref={ref} data-testid={TEST_IDS.THUMBNAIL}>
      <div className={styles.thumbnailContent}>
        <div data-testid={TEST_IDS.LOADING_INDICATOR} className="fullheight fullwidth">
          <LoaderComponent containerClassName="fullheight fullwidth" />
        </div>
        <div className={styles.thumbnailOverlay} />
        <div className={styles.thumbnailButtonContainer} />
      </div>
    </div>
  );
};

export default ThumbnailPreload;
