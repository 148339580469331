import {useMutation} from 'react-query';
import useApi from 'hooks/useApi';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {showErrorToast} from 'queries/query.utils';
import {logger} from 'utils/logger';

type TUseShareChecklistParams = {
  orderId: number;
  emails: string[];
};

type TUseShareChecklistResponse = WithErrorResponse<{}>;

const useShareChecklistMutation = () => {
  const api = useApi();
  const dispatch = useAppDispatch();

  return useMutation(
    async ({orderId, emails}: TUseShareChecklistParams) => {
      api.toggleLoader(true);
      const response: TUseShareChecklistResponse = await api.services.shareChecklist({id: orderId}, {emails});

      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error sharing checklist'});
        logger('markTaskCompleteMutation')(`Error sharing checklist: ${(response.data?.errors ?? []).join(' ')}`);
        throw new Error('Error sharing checklist');
      }
      return response?.data;
    },
    {
      onSettled: () => {
        api.toggleLoader(false);
      },
    }
  );
};

export default useShareChecklistMutation;
