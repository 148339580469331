import React, {useMemo} from 'react';
import {Icon, InputField, ELEMENT_SIZE, SelectField} from 'ht-styleguide';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import styles from './paginator.styles.scss';
import {TPaginator, TPaginatorAttr} from '../dataTable.types';
import {PAGE_SELECT_OPTIONS} from './paginator.constants';
import {TEST_IDS} from '../dataTable.constants';

const Paginator = ({showPaginator, pagination, internalPaginatorAttr}: TPaginator) => {
  const paginationAttr = useMemo<TPaginatorAttr | null>(() => {
    if (showPaginator) {
      if (pagination) {
        /**
         * Manual pagination. Pagination data is driven by the consumer - generally from the BE.
         */
        const {pageIndex, pageItemCount, totalPages, onPaginationChange, pageSelectOptions} = pagination || {};

        const canPreviousPage = pageIndex > 0;
        const previousPage = () => {
          if (canPreviousPage) {
            onPaginationChange?.({pageIndex: pageIndex - 1, itemCount: pageItemCount});
          }
        };

        const canNextPage = pageIndex < totalPages - 1;
        const nextPage = () => {
          if (canNextPage) {
            onPaginationChange?.({pageIndex: pageIndex + 1, itemCount: pageItemCount});
          }
        };

        // Set page count back to 0 for fewer complications.
        const setPageCount = (newPageItemCount: number) => {
          onPaginationChange?.({pageIndex: 0, itemCount: newPageItemCount});
        };

        const goToPage = debounce(
          (newPageIndex: number) => {
            let finalPageIndex = newPageIndex;
            finalPageIndex = finalPageIndex + 1 >= totalPages ? totalPages - 1 : finalPageIndex;
            finalPageIndex = finalPageIndex <= 0 ? 0 : finalPageIndex;
            onPaginationChange?.({pageIndex: finalPageIndex, itemCount: pageItemCount});
          },
          1000,
          {leading: false, trailing: true}
        );

        const pageCountSelectOptions = (pageSelectOptions || PAGE_SELECT_OPTIONS).map(ps => ({value: ps, label: ps}));
        const pageCountSelectValue = pageCountSelectOptions.find(option => option.value === pageItemCount) || pageCountSelectOptions[0];

        return {
          canPreviousPage,
          previousPage,
          canNextPage,
          nextPage,
          setPageCount,
          goToPage,
          pageIndex,
          totalPages,
          pageItemCount,
          pageCountSelectOptions,
          pageCountSelectValue,
        };
      }
    }

    return null;
  }, [showPaginator, pagination]);

  if (!showPaginator || !(paginationAttr || internalPaginatorAttr)) {
    return null;
  }

  const {canPreviousPage, previousPage, canNextPage, nextPage, setPageCount, goToPage, pageIndex, totalPages, pageCountSelectOptions, pageCountSelectValue, pageItemCount} =
    paginationAttr! || internalPaginatorAttr!;

  // Visuals
  const currentPage = pageIndex + 1;

  return (
    <div className="flex flex-direction-row align-items-center justify-content-space-between padding-small">
      <div className="flex flex-direction-row align-items-center">
        <div>
          <button onClick={previousPage} type="button" data-testid={TEST_IDS.PAGINATION_PREVIOUS_ARROW} className={cn('plainButton', styles.pageButtonWrapper, !canPreviousPage && styles.inactive)}>
            <Icon name="chevron-left" className={cn(styles.pageIcon, !canPreviousPage && styles.inactive, 'marginRight-tiny1')} />
          </button>
          <button onClick={nextPage} type="button" data-testid={TEST_IDS.PAGINATION_NEXT_ARROW} className={cn('plainButton', styles.pageButtonWrapper, !canNextPage && styles.inactive)}>
            <Icon name="chevron-right" className={cn(styles.pageIcon, !canNextPage && styles.inactive)} />
          </button>
        </div>
        <p className="p2 n700 paddingLeft-small" data-testid={TEST_IDS.PAGINATION_PAGE_GUIDE}>
          Page {currentPage} of {totalPages || 1}
        </p>
      </div>

      <div className="flex flex-direction-row">
        <div className="flex flex-direction-row align-items-center paddingRight-small2">
          <p className="p2 n700 paddingRight-tiny1">Go to page: </p>
          <InputField
            /* hack - using key to force update InputField since a change in goToPage isn't triggering a re-render */
            key={`${pageIndex}-${totalPages}-${pageItemCount}`}
            onKeyUp={e => {
              // @ts-ignore
              if (e.target.value < 0) {
                // @ts-ignore
                e.target.value = 0;
              }
            }}
            type="number"
            max={totalPages}
            min="1"
            defaultValue={currentPage}
            onChange={e => {
              const newPageIndex = e.target.value ? Number(e.target.value) - 1 : 0;
              // because of the re-render hack, we need to compare page indicies to prevent an infinite loop
              // of page changes.
              if (pageIndex !== newPageIndex) {
                goToPage(newPageIndex);
              }
            }}
            elementSize={ELEMENT_SIZE.SMALL}
            containerClass={styles.pageInput}
          />
        </div>
        <div className="flex flex-direction-row align-items-center">
          <p className="p2 n700 paddingRight-tiny1">Items per page: </p>
          <SelectField
            onChange={e => {
              setPageCount(Number(e.value));
            }}
            options={pageCountSelectOptions}
            value={pageCountSelectValue}
            elementSize={ELEMENT_SIZE.SMALL}
            className={styles.selectOuterWrapper}
          />
        </div>
      </div>
    </div>
  );
};

export default Paginator;
