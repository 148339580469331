import React, {forwardRef, ReactNode} from 'react';
import cn from 'classnames';
import {useSpring, animated} from '@react-spring/web';
import styles from './adminHub.layout.styles.scss';
import {CollapsibleProp} from './adminHub.types';
import {ADMIN_HUB_LAYOUT_ANIMATION_CONFIG, LAYOUT_CONTAINER_ID, RIGHT_CONTAINER_PORTAL_TOP_ID, RIGHT_CONTAINER_PORTAL_BOTTOM_ID} from './AdminHub.layout.constants';
import {SIDEBAR_PEEK_WIDTH, SIDEBAR_WIDTH} from './Sidebar/Sidebar.constants';
import {AdminHubLayoutStateProvider, useAdminHubLayoutState} from './AdminHub.layout.state.provider';

type AdminHubLayout = {
  ContentTwoColumnContainer: React.FC<ChildrenProps>;
  ContentLeftContainer: React.FC<ChildrenProps & CollapsibleProp>;
  ContentRightContainer: React.ForwardRefExoticComponent<ChildrenProps & React.RefAttributes<HTMLDivElement>>;
};

type ChildrenProps = {
  children: ReactNode | ReactNode[];
  className?: string;
};

const AdminHubLayout: React.FC<ChildrenProps> & AdminHubLayout = ({children}) => {
  return (
    <AdminHubLayoutStateProvider>
      <div id={LAYOUT_CONTAINER_ID} />
      <div className={cn(styles.adminHubContainer, styles.baseFeaturePage)}>{children}</div>
    </AdminHubLayoutStateProvider>
  );
};
const ContentTwoColumnContainerWithNav: React.FC<ChildrenProps> = ({children}) => <div className={cn(styles.ContentTwoColumnContainerWithNav)}>{children}</div>;

const ContentLeftContainer: React.FC<ChildrenProps & CollapsibleProp> = ({children}) => {
  const {isLeftColumnCollapsed} = useAdminHubLayoutState();

  /**
   * This animation works by setting the left margin of the Left Container which holds the sidebar.
   *
   * - If the sidebar is collapsed, the left margin is manipulated to hide the sidebar, but leave the hide/show button visible.
   * - If the sidebar is expanded, the left margin is set to 0.
   *
   * It is necessary to handle the margins here, because ContentRightContainer is expected to fill space vacated by the sidebar.
   */
  const springStyleProps = useSpring({
    marginLeft: isLeftColumnCollapsed ? `-${SIDEBAR_WIDTH - SIDEBAR_PEEK_WIDTH}px` : '0px',
    config: ADMIN_HUB_LAYOUT_ANIMATION_CONFIG,
  });

  return (
    <animated.div style={springStyleProps} className={styles.ContentTwoColumnContainerWithNav__left}>
      {children}
    </animated.div>
  );
};
const ContentRightContainer = forwardRef<HTMLDivElement, ChildrenProps>(({children}, ref) => {
  return (
    <div ref={ref} className={styles.ContentTwoColumnContainerWithNav__right}>
      <div id={RIGHT_CONTAINER_PORTAL_TOP_ID} className={styles.rightContainerPortalTop} />
      {children}
      <div id={RIGHT_CONTAINER_PORTAL_BOTTOM_ID} className={styles.rightContainerPortalBottom} />
    </div>
  );
});

AdminHubLayout.ContentTwoColumnContainer = ContentTwoColumnContainerWithNav;
AdminHubLayout.ContentLeftContainer = ContentLeftContainer;
AdminHubLayout.ContentRightContainer = ContentRightContainer;

export default AdminHubLayout;
