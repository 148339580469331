export const ActionType = {
  icon: 'icon',
  string: 'string',
  component: 'component',
} as const;

export type TActionIconProps = {
  type: typeof ActionType.icon;
  iconName?: string;
  action: () => void;
};

export type TActionStringProps = {
  type: typeof ActionType.string;
  text: string;
  action: () => void;
};

export type TActionComponentProps = {
  type: typeof ActionType.component;
  /* Too dependent on what people are typing components in Styleguide */
  Component?: any;
};

export type TBaseAction = {
  tooltipContent?: string;
  customClassName?: string;
  dataTestId?: string;
};

export type TActionType = TBaseAction & (TActionIconProps | TActionStringProps | TActionComponentProps);

export type TActionGroupProps = {
  actions: TActionType[];
  size: 'sm' | 'med' | 'lg';
  dataTestId?: string;
};

export type TActionBaseOmitType<T> = Omit<T, 'type'>;
