import React, {ComponentProps, forwardRef, useCallback, useState, useImperativeHandle, useMemo} from 'react';
import {useFormik} from 'formik';
import {SideSheet, Chip, CHIP_TYPES, Modal, ELEMENT_SIZE, Button, BUTTON_THEMES, htToast} from 'ht-styleguide';
import HeaderWithToolbar from 'components/SideSheets/HeaderWithToolbar';
import {RightContentCloseOnly} from 'components/SideSheets/HeaderWithToolbar/HeaderWithToolbar.Presets';
import useUpdateTaskAnswerMutation from 'features/Orders/Pages/OrderTaskChecklist/queries/mutation.updateTaskAnswer';
import useMarkTaskCompleteMutation from 'features/Orders/Pages/OrderTaskChecklist/queries/mutation.markTaskComplete';
import {IEditTaskAnswerSideSheet, IEditTaskAnswerSideSheetHandler} from '../serviceTasksList.types';
import {EDIT_TASK_ANSWER_YUP_SCHEMA, BASE_EDIT_TASK_SCHEMA} from './editTaskAnswerSideSheet.constants';
import {TUpdateTaskAnswerFormValues} from './editTaskAnswerSideSheet.types';
import {createFormInitialValues, createUpdateTaskAnswerPayload, checkShouldForceDataEntry, checkCanAutoUpdateTask} from './editTaskAnswerSideSheet.utils';
import EditTaskAnswerContent from './EditTaskAnswerContent/EditTaskAnswerContent';
import styles from './editTaskAnswerSideSheet.styles.scss';

/**
 * For creating the custom header for the side sheet
 */
const getEditTaskAnswerSideSheetHeader: ({isComplete}: {isComplete: boolean}) => ComponentProps<typeof SideSheet>['headerComponent'] =
  ({isComplete}) =>
  headerProps => {
    const getLeftContentComponent: ComponentProps<typeof HeaderWithToolbar>['LeftContent'] = ({isCollapsed}) => {
      let chipContent = <></>;
      if (!isCollapsed) {
        chipContent = isComplete ? <>Complete</> : <>Incomplete</>;
      }
      const chipProps: Partial<ComponentProps<typeof Chip>> = isComplete
        ? {
            type: CHIP_TYPES.success,
          }
        : {
            classes: styles.incompleteChip,
          };
      return <Chip {...chipProps}>{chipContent}</Chip>;
    };
    return <HeaderWithToolbar {...headerProps} isHeaderTextEditable={false} LeftContent={getLeftContentComponent} RightContent={RightContentCloseOnly} />;
  };

/**
 * A user can edit the contents of a task and mark it as complete.
 *
 * The user does not have to fill all required fields to mark the task as complete as admin users
 * should have overriding permissions. To that end, the form will not force the user to fill in answers
 * for required fields. Yup/formik in this component is primarily used to check whether the form
 * has all required fields filled. If the form is not valid, then throw up a warning modal that states
 * that not all required fields have been field. It'll be up to the admin user to decide whether to proceed.
 */
const EditTaskAnswerSideSheet = forwardRef<IEditTaskAnswerSideSheetHandler, IEditTaskAnswerSideSheet>(({checklistData, order, serviceId}, ref) => {
  // Task id to edit and also controls sidesheet visibility
  const [taskId, setTaskId] = useState<number | null>(null);
  const isVisible = typeof taskId === 'number';

  // Expose the openEditTaskSideSheet method to the parent component
  useImperativeHandle(
    ref,
    () => ({
      openEditTaskSideSheet: (taskIdToEdit: number) => setTaskId(taskIdToEdit),
    }),
    []
  );

  const closeSideSheet = useCallback(() => setTaskId(null), []);

  // Find the task to edit
  const taskToEdit = useMemo(() => checklistData?.task_groups?.flatMap(({tasks}) => tasks).find(({id}) => id === taskId), [checklistData, taskId]);

  // Shared data and states
  const {title = '', instructions = '', task_type} = taskToEdit || {};
  const isComplete = taskToEdit?.answer?.complete || false;
  const buttonCTACopy = isComplete ? 'Save Changes' : 'Mark as Complete';

  // Create side sheet header
  const getEditTaskAnswerSideSheetHeaderCB = useCallback(() => getEditTaskAnswerSideSheetHeader({isComplete}), [isComplete]);

  // Form's initial values
  const initialValues = useMemo(() => createFormInitialValues(taskToEdit), [taskToEdit]);

  /**
   * Formik is not used for submitting directly. Here we use it to validate the form and
   * collect the payload to send to the BE.
   */
  const formik = useFormik<TUpdateTaskAnswerFormValues>({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues,
    validationSchema: task_type ? EDIT_TASK_ANSWER_YUP_SCHEMA[task_type] : BASE_EDIT_TASK_SCHEMA,
    // Not using since we want to save without validation
    onSubmit: () => {},
  });

  // Set up mutations
  const {mutateAsync: updateTaskAnswerAsync} = useUpdateTaskAnswerMutation();
  const {mutateAsync: markTaskCompleteAsync} = useMarkTaskCompleteMutation();

  const [showRequiredFieldsModal, setShowRequiredFieldsModal] = useState(false);
  const toggleRequiredFieldsModal = useCallback(() => setShowRequiredFieldsModal(!showRequiredFieldsModal), [showRequiredFieldsModal]);

  const updateTaskAnswerAndMarkComplete = useCallback(async () => {
    const {values} = formik;
    const payload = createUpdateTaskAnswerPayload(taskToEdit!, values);
    try {
      await updateTaskAnswerAsync({orderId: order.id, taskId: taskId!, serviceId, taskAnswerPayload: payload});
      if (!isComplete) {
        await markTaskCompleteAsync({serviceId, taskIds: [taskId!]});
        htToast('Task marked as complete');
      } else {
        htToast('Task updated');
      }
      if (showRequiredFieldsModal) {
        toggleRequiredFieldsModal();
      }
      closeSideSheet();
    } catch {
      // do nothing
    }
  }, [closeSideSheet, formik, isComplete, markTaskCompleteAsync, order.id, serviceId, showRequiredFieldsModal, taskId, taskToEdit, toggleRequiredFieldsModal, updateTaskAnswerAsync]);

  /**
   * When the sidesheet's CTA buton is clicked, determine next steps. If the form has no errors or is complete,
   * then auto save. Otherwise, show a modal that asks the user if they want to proceed anyways.
   */
  const handleCTAButtonCallback = async () => {
    const errors = await formik.validateForm();
    const {values} = formik;

    if (!checkShouldForceDataEntry(taskToEdit!, values, errors)) {
      if (checkCanAutoUpdateTask(taskToEdit!, errors)) {
        updateTaskAnswerAndMarkComplete();
      } else {
        toggleRequiredFieldsModal();
      }
    }
  };

  return (
    <>
      <SideSheet
        ctaButtonText={buttonCTACopy}
        onCtaButtonClick={handleCTAButtonCallback}
        hide={closeSideSheet}
        isOpen={isVisible}
        headerText={title}
        subheaderText={instructions}
        headerComponent={getEditTaskAnswerSideSheetHeaderCB()}
      >
        <EditTaskAnswerContent task={taskToEdit} formik={formik} />
      </SideSheet>
      <Modal
        elementSize={ELEMENT_SIZE.MEDIUM}
        isVisible={showRequiredFieldsModal}
        hide={toggleRequiredFieldsModal}
        header="Some fields haven't been filled"
        footerElement2={
          <Button medium theme={BUTTON_THEMES.SECONDARY} onClick={toggleRequiredFieldsModal}>
            Cancel
          </Button>
        }
        footerElement3={
          <Button medium theme={BUTTON_THEMES.PRIMARY} onClick={updateTaskAnswerAndMarkComplete}>
            Mark as Complete
          </Button>
        }
      >
        <p className="p1">Do you want to mark the task as complete anyways?</p>
      </Modal>
    </>
  );
});

export default EditTaskAnswerSideSheet;
