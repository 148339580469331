import {createAsyncThunk, createSlice, PayloadAction, createSelector} from '@reduxjs/toolkit';
import APIS from 'global/apis';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
import assign from 'lodash/assign';
import unionBy from 'lodash/unionBy';
import get from 'lodash/get';
// utils/misc
import {REMOTE_TRUE, REMOTE_FALSE} from 'features/Booking/constants/sku';
import {RouterStateMode} from 'global/constants/router';
import createQuestionsDucks, {defaultSelectedSku, validateForm} from 'features/Questions/ducks';

// Types
import {IHash, ResponseError} from 'types/base.types';
import {RootState} from 'store';
import * as TS from 'features/Questions/types';
import {PARTNERS_SKU_CATEGORIES} from '../../constants/partners';

const ducks = createQuestionsDucks('QA');

/*
*******************************************************
  ASYNC ACTION THUNKS
*******************************************************
*/
export const asyncActions = {
  verifyQAForm: createAsyncThunk<TS.QAErrors, {mode?: RouterStateMode} | undefined, {state: RootState}>('qa/verifyQAForm', async ({mode} = {}, {getState}) => {
    const {addSku} = getState().booking.qa;
    const {cart} = getState().booking;

    return validateForm({
      addSku,
      cart,
      mode,
    }) as TS.QAErrors;
  }),
  setSelectedSku: createAsyncThunk<
    TS.SelectedSkuAsyncAction,
    {id: string | number; itemIndex?: number; forcedEditMode?: boolean; routeStateItemIndex?: number; routeStateMode?: RouterStateMode},
    {state: RootState}
  >('qa/setSelectedSku', async ({id, itemIndex, forcedEditMode, routeStateItemIndex, routeStateMode = ''}, {getState}) => {
    /* Router is already typed but adding "state" in outlier uses, just use any */
    const index = routeStateItemIndex ?? itemIndex;
    const cart = getState().booking.cart ?? {};

    return {mode: routeStateMode, cart, id, index: index!, forcedEditMode};
  }),
  fetchQuestionsById: createAsyncThunk<TS.QuestionsAPI, {id: string}, {rejectValue: ResponseError}>(
    'qa/fetchQuestionsById',
    async ({id}, {rejectWithValue}) => {
      const response = (await APIS.booking.skus.questions({id})) ?? {};

      try {
        return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.QuestionsAPI);
      } catch (e) {
        return rejectWithValue(response as ResponseError);
      }
    },
    {
      condition: ({id}, {getState}) => {
        const {booking} = getState() as RootState;
        const {addSku} = booking.qa as Pick<TS.State, 'addSku'>;
        const alreadyHaveQuestions = addSku.questions?.entities?.hasOwnProperty(id!);

        if (alreadyHaveQuestions) {
          // Already fetched this question
          return false;
        }
        return true;
      },
    }
  ),
  fetchSkuById: createAsyncThunk<TS.Sku, {id: string}, {rejectValue: ResponseError; state: RootState}>(
    'qa/fetchSkuById',
    async ({id}, {rejectWithValue}) => {
      const response = (await APIS.booking.skus.find({id})) ?? {};

      try {
        return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.Sku);
      } catch (e) {
        return rejectWithValue(response as ResponseError);
      }
    },
    {
      condition: ({id}, {getState}) => {
        const {skus} = getState().booking.qa.addSku ?? {};
        const alreadyHaveSku = skus?.entities?.hasOwnProperty(id!);

        if (alreadyHaveSku) {
          return false;
        }
        return true;
      },
    }
  ),
  postItemPrice: createAsyncThunk<TS.PostItem, void, {rejectValue: ResponseError; state: RootState}>('qa/postItemPrice', async (_, {rejectWithValue, getState}) => {
    const {booking} = getState();
    const item = booking.qa.addSku.selectedSku;
    const sku = booking.qa.addSku.skus.entities[item.skuId];
    const {cart} = booking;
    const inHome = !cart.remote && !!cart.items?.length && sku.remoteSavings;
    const response = (await APIS.booking.cart.itemPrice({item, inHome})) ?? {};
    try {
      return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.PostItem);
    } catch (e) {
      return rejectWithValue(response as ResponseError);
    }
  }),
};

/*
*******************************************************
  INITIAL STATE
*******************************************************
*/

const initialState: TS.State = {
  addSku: ducks.getAddSkuInitialState(),
};

/*
*******************************************************
  CREATE SLICE
*******************************************************
*/
export const QASlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    ...ducks.getReducers(),
  },
  extraReducers: builder => {
    builder
      .addCase(asyncActions.verifyQAForm.fulfilled, (state, action) => {
        state.addSku.errors = action.payload;
      })
      .addCase(asyncActions.setSelectedSku.fulfilled, (state, action) => {
        const {mode, id, cart, index, forcedEditMode} = action.payload;
        const sku = state.addSku.skus.entities[id!] ?? cart.items.find(item => +item.skuId === +id);
        if ((mode === RouterStateMode.EDIT_SKU && cart.items) || forcedEditMode) {
          /* Edit mode matches sku w/ previous answers in cart */
          /* We also need to be aware of same id skus in cart */
          const {questions} = state.addSku.questions.entities?.[sku?.id] ?? {questions: []};
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          const questionsBase = getNewQuestion(questions);
          const {questions: prevSelectedQuestions, quantity} = {...cart.items[index]!};

          state.addSku.selectedSku = pick({...sku, skuId: sku.id || sku.skuId, quantity}, keys(defaultSelectedSku)) as TS.SelectedSku;
          state.addSku.selectedSku.questions = {...questionsBase, ...prevSelectedQuestions};
        } else {
          /* New mode matches sku with empty set questions */
          state.addSku.selectedSku = assign({}, state.addSku.selectedSku, pick({...sku, skuId: sku.id, quantity: 1}, keys(state.addSku.selectedSku)));
          const {questions} = state.addSku.questions.entities[sku.id];
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          state.addSku.selectedSku.questions = getNewQuestion(questions);
        }
      })
      .addCase(asyncActions.fetchQuestionsById.fulfilled, (state, action) => {
        const {data} = action.payload;
        const {meta} = action;

        ducks.questionsAdapter.upsertOne(state.addSku.questions, {id: meta.arg.id, questions: data.questions});
      })
      .addCase(asyncActions.fetchSkuById.fulfilled, (state, action: PayloadAction<any>) => {
        const {sku} = action.payload?.data;
        try {
          ducks.questionsSkuAdapter.upsertOne(state.addSku.skus, sku);
        } catch (e) {
          // handle
        }
      })
      .addCase(asyncActions.postItemPrice.fulfilled, (state, action) => {
        const {price, questions} = action.payload?.data;
        /* update price */
        state.addSku.selectedSku.totalPrice = price;
        const item = state.addSku.selectedSku;
        const sku = state.addSku.skus.entities[item.skuId!];
        if (questions) {
          /*
           * Admin shows the dropdown always (for remote skus) even though the cart might not allow
           * remote (carted a non-remote item). But this is where we could "offer" potentially
           * offer an alternative. - cart.remote
           */
          if (sku.remote && !sku.remoteOnly) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            const unionQuestions = unionBy(questions, [REMOTE_QUESTION(sku)], 'id');

            ducks.questionsAdapter.upsertOne(state.addSku.questions, {id: item.skuId, questions: unionQuestions});
          }
        }
      });
  },
});
/*
 ************ Selectors
 */
const getAddSkuState = (state: RootState) => state.booking.qa.addSku;
const getQuestionStateByKey =
  (key: keyof TS.AddSku) =>
  (qaState: TS.AddSku): Pick<TS.State, any> =>
    qaState[key];
const {selectById: selectByQuestionId} = ducks.questionsAdapter.getSelectors<RootState>(state => state.booking.qa.addSku.questions);
const {selectById: selectBySkuId} = ducks.questionsSkuAdapter.getSelectors<RootState>(state => state.booking.qa.addSku.skus);

/**
 * At this initial stage, we really don't have "cart" info for prepaid etc..
 * Since our pins are hardcoded, we can do this, but I personally think this
 * un-needed and just conflates/creates unnecessary bloat.
 * Admin users KNOW these are subsidized. They see the "total"!
 */
const getSelectedSkuBasePrice = (basePrice: string) =>
  createSelector(
    (state: RootState) => state.booking.qa.addSku.selectedSku,
    (selectedSku: TS.SelectedSku) => {
      const {skuId} = selectedSku;
      const partnerId = get(selectedSku, 'partner.id');
      const pinRedemptionIds = get(PARTNERS_SKU_CATEGORIES, `${partnerId}.pinRedemptionIds`, []);
      if (pinRedemptionIds.includes(skuId)) {
        return 'Prepaid';
      }

      return basePrice;
    }
  );

/*
*******************************************************
  EXPORTS
*******************************************************
*/
export const selectors = {
  selectByQuestionId,
  selectBySkuId,
  getSelectedSkuBasePrice,
  getKeyInQuestionState: (key: keyof TS.AddSku) => createSelector(getAddSkuState, getQuestionStateByKey(key)),
};
export const actions = {...QASlice.actions, ...asyncActions};
export default QASlice.reducer;

/**
 * UTILITIES & METHODS
 */

/**
 * Utility that moves thru the questions and hands back a
 * readable version to pass back to backend. It also lays
 * out a schema for updates as a user interacts.
 *
 * @param qs
 */
export const getNewQuestion = (qs: TS.QuestionsAPIByQuestion[]) => {
  const newQuestions: IHash<IHash<string | number> | any[] | null> = {};
  qs.forEach(q => {
    switch (q.inputType) {
      case TS.QuestionTypes.Input:
        newQuestions[q.id.toString()] = {text: ''};
        break;
      case TS.QuestionTypes.Textarea:
        newQuestions[q.id.toString()] = {text: ''};
        break;
      case TS.QuestionTypes.Dropdown: {
        const defaultAnswer = q.answers.find(a => a.default);
        newQuestions[q.id.toString()] = defaultAnswer ? {id: defaultAnswer.id} : null;
        break;
      }
      case TS.QuestionTypes.Checkbox:
        newQuestions[q.id.toString()] = [];
        break;
      case TS.QuestionTypes.Device: {
        const defaultAnswer = q.answers.find(a => a.default);
        newQuestions[q.id.toString()] = defaultAnswer ? {id: defaultAnswer.id} : null;
        break;
      }
      default: {
        throw new Error(`Unknown inputType: ${q.inputType}`);
      }
    }
  });

  return newQuestions;
};

/* Remote Question if SKU has remote */
export const REMOTE_QUESTION = (sku: TS.Sku): TS.QuestionsAPIByQuestion => ({
  id: 'remote',
  textDirect: 'Do you want <i class="ht-icon ht-logo-HellotechNow -hellotech-now"></i>?',
  inputType: TS.QuestionTypes.Dropdown,
  required: true,
  hint: null,
  serviceId: null,
  answers: [
    {
      id: REMOTE_TRUE,
      text: `Yes (Save ${sku.remoteSavingsFormatted} with instant online support)`,
      adjAmount: 0,
      adjAmountFormatted: `- ${sku.remoteSavingsFormatted}`,
      adjDescription: 'HelloTech Now!',
      hasQuantity: false,
      default: true,
      warning: null,
    },
    {
      id: REMOTE_FALSE,
      text: 'No, I want in-home service',
      adjAmount: 0,
      adjAmountFormatted: null,
      adjDescription: 'HelloTech Now!',
      hasQuantity: false,
      default: true,
      warning: null,
    },
  ],
});
