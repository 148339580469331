import React, {ReactNode} from 'react';

interface IBlockWrapper {
  children: ReactNode;
  title: string;
  dataTestId?: string;
}

const BlockWrapper = ({children, title, dataTestId = 'BlockWrapper'}: IBlockWrapper) => (
  <div data-testid={`${dataTestId}-BlockWrapper-Container`} className="paddingY-small1 border-bottom-2">
    <p className="p2 n900 font-weight-bold marginBottom-tiny1">{title}</p>
    {children}
  </div>
);

export default BlockWrapper;
