import React, {useEffect} from 'react';
import noop from 'lodash/noop';
import cn from 'classnames';
import useHeaderWithToolbar from './useHeaderWithToolbar';
import styles from './HeaderWithToolbar.styles.scss';
import {THeaderWithToolbar} from './HeaderWithToolbar.types';
import HeaderText from './HeaderWithToolbar.HeaderText';

const HeaderWithToolbar: React.FC<THeaderWithToolbar> = props => {
  const {headerText, subheaderText, scrollPosition = 0, hide, LeftContent, RightContent, isHeaderTextEditable = true, textEditCallback = noop} = props;
  const {topElementHeight, bottomElementMetrics, isCollapsed, topElementRef, bottomElementRef, translateY, formik} = useHeaderWithToolbar(props);

  const bottomElementStyle: React.CSSProperties = {
    transform: `translateY(${translateY}px)`,
  };
  const combinedElementHeight = topElementHeight + bottomElementMetrics.height;

  const calculatedHeight = Math.max(topElementHeight, combinedElementHeight - scrollPosition);

  const headerContainerStyles: React.CSSProperties = {
    height: isCollapsed ? topElementHeight : `${calculatedHeight}px`,
  };

  const isCollapsedClass = isCollapsed ? styles.show : styles.hide;
  const isExpandedClass = !isCollapsed ? styles.show : styles.hide;
  const headerTextStyle = isHeaderTextEditable ? styles.headerText : styles.headerTextNonEditable;

  useEffect(() => {
    if (formik?.values) {
      textEditCallback(formik?.values.headerText);
    }
  }, [formik?.values]);

  const hasHeaderBottomContent = headerText || subheaderText;

  return (
    <div style={headerContainerStyles} className={styles.headerContainer}>
      {/* Collapsed View */}
      <div className={cn('flex justify-content-space-between align-items-center', styles.headerBase, styles.withBorder, isCollapsedClass)}>
        <div className={styles.leftContentCollapsed}>
          {LeftContent && (
            <>
              {LeftContent({isCollapsed, hide})}
              <div className="paddingLeft-tiny1" />
            </>
          )}
          <HeaderText
            className={cn(styles.headerText, 'n800 h6')}
            headerText={headerText}
            subheaderText={subheaderText}
            isHeaderTextEditable={isHeaderTextEditable}
            formik={formik}
            isCollapsed={isCollapsed}
          />
        </div>
        <div className={styles.rightContentCollapsed}>
          {RightContent && (
            <>
              <div className="paddingLeft-small1" />
              {RightContent({isCollapsed, hide})}
            </>
          )}
        </div>
      </div>

      {/* Expanded View */}
      <div className={isExpandedClass}>
        <div className={cn('flex justify-content-space-between align-items-center', styles.headerBase, styles.headerTopElement)} ref={topElementRef}>
          {LeftContent ? LeftContent({isCollapsed, hide}) : <div />}
          {RightContent && RightContent({isCollapsed, hide})}
        </div>
        <div style={bottomElementStyle} className={cn(styles.headerBottomElement, !hasHeaderBottomContent && styles.hideHeaderBase)} ref={bottomElementRef}>
          <HeaderText
            className={cn(headerTextStyle, 'n800 h4')}
            headerText={headerText}
            subheaderText={subheaderText}
            isHeaderTextEditable={isHeaderTextEditable}
            formik={formik}
            isCollapsed={isCollapsed}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderWithToolbar;

/*
  Example Usage:

  const HeaderComponent = (props: TSideSheetCustomHeaderProps) => {
      const menuItems = [
        {text: 'item 1', onClick: () => console.log('item 1 clicked'), key: 1},
        {text: 'item 2', onClick: () => console.log('item 2 clicked'), key: 2},
      ];
      return (
        <HeaderWithToolbar
          {...props}
          LeftContent={({isCollapsed}: any) => {
            return <ChipMenu showMinimized={isCollapsed} text="Project Status" menuList={menuItems} />;
          }}
          RightContent={({hide}) => {
            return (
              <div>
                <span>Menu Items Here</span>
                <button onClick={hide} type="button">
                  Hide
                </button>
              </div>
            );
          }}
        />
      );
    };

    <SideSheet
      {...props}
      headerComponent={<HeaderComponent />}
    />
 */
