import React, {useMemo} from 'react';
import {SelectField, SideSheetContentSection, ELEMENT_SIZE} from 'ht-styleguide';
import {UpdateTaskAnswerFieldName} from '../../editTaskAnswerSideSheet.types';
import {IEditTaskAnswerSectionShareProps} from '../editTaskAnswerContent.types';

const SingleSelectEditTaskAnswerSection = ({formik, task}: IEditTaskAnswerSectionShareProps) => {
  const {options} = task;

  const fieldOptions = useMemo(() => options.map(({id, description}) => ({value: id, label: description})), [options]);

  const selectedValue = useMemo(() => {
    const firstOption = formik.values.options?.[0];
    return firstOption?.id ?? null;
  }, [formik.values.options]);

  const onChange = (newValue: {value: number} | null) => formik.setFieldValue(UpdateTaskAnswerFieldName.Options, newValue ? [{id: newValue.value}] : []);

  return (
    <SideSheetContentSection title="Select One">
      <SelectField elementSize={ELEMENT_SIZE.M} onChange={onChange} options={fieldOptions} value={selectedValue} clearable />
    </SideSheetContentSection>
  );
};

export default SingleSelectEditTaskAnswerSection;
