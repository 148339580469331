import {createSlice, createAsyncThunk, AnyAction, createSelector} from '@reduxjs/toolkit';
import {ResponseError} from 'types/base.types';
import {RootState} from 'store';

/* Ducks */
import * as cartSlice from 'features/Booking/Parts/Cart/cart.ducks';
import * as bookingTypeSlice from 'features/Booking/Parts/BookingType/bookingtype.ducks';
import * as requestLoaderSlice from 'features/App/RequestLoader/requestLoader.ducks';

/* Apis */
import APIS from 'global/apis';

/* Constants */
import {RouterStateBookingTypeMode} from 'global/constants/router';
import {REMOTE_FALSE} from 'features/Booking/constants/sku';
import {COMP_STATUS} from './Details/followupDetails.constants';

/* Types */
import * as TS from './followup.types';
import {CartStateResponsePayload, FollowUpBreakDown, ItemAdjustments} from '../../Cart/cart.types';

import {applyCartClientIds} from '../../Cart/cart.ducks';

/*
 ****************************************
 ************ ASYNC ACTIONS *************
 ****************************************
 */

export const asyncActions = {
  /* Until we know the api, mock it */
  getOrder: createAsyncThunk<TS.OrderStateResponsePayload, {orderId?: number | string}, {rejectValue: ResponseError; state: RootState}>('followup/getOrder', async ({orderId}, {dispatch}) => {
    dispatch(requestLoaderSlice.actions.loading(true));
    const reqResponse = await APIS.booking.order.getOrder({orderId}, {full: true});
    const cartResponse = await APIS.booking.cart.initializeCart({parent_order_id: orderId});
    dispatch(cartSlice.actions.getClientCart({cart_id: cartResponse.data.cart.id as string}));
    /* We are in a bookingType of followup */
    dispatch(bookingTypeSlice.actions.setBookingType({bookingType: RouterStateBookingTypeMode.FOLLOWUP, bookingTypeId: orderId}));
    dispatch(requestLoaderSlice.actions.loading(false));

    return {...reqResponse, cartResponse};
  }),
  addSku: createAsyncThunk<CartStateResponsePayload, any, {rejectValue: ResponseError; state: RootState}>('followup/addSku', async (selectedSku, {rejectWithValue, getState, dispatch}) => {
    dispatch(requestLoaderSlice.actions.loading(true));
    // TODO;;;; Find out why bookingType and bookingTypeid are NOT being set when on add-sku page, refresh
    const response = await APIS.booking.cart.addSku(
      applyCartClientIds({
        params: {item: {...selectedSku}},
        state: getState,
      })
    );
    /* Update our cart */
    dispatch(cartSlice.actions.updateCart({...response?.data}));

    dispatch(requestLoaderSlice.actions.loading(false));
    return response.err ? rejectWithValue(response.err as ResponseError) : (response as CartStateResponsePayload);
  }),
  setFollowupReason: createAsyncThunk<any, {comped: string; details: string; id: number | undefined}, {rejectValue: ResponseError; state: RootState}>(
    'followup/setFollowupReason',
    async ({comped, details, id}, {dispatch, getState}) => {
      const response = await APIS.booking.followUp.setReason(
        {id},
        {
          follow_up: {
            discount_type: comped,
            details: details || '--',
          },
        }
      );
      const cartResp = await APIS.booking.cart.getClientCart({
        breakdown: true,
        cart_id: response.data.followUp.cartId,
        no_taxes: false,
        normalize: true,
      });
      const {client_id: clientID} = applyCartClientIds({
        params: {item: {}},
        state: getState,
      });
      dispatch(cartSlice.actions.updateCart({cart: {...cartResp?.data.cart, clientId: clientID}}));
      return response;
    }
  ),
  addBulkCloneServiceIds: createAsyncThunk<any, {followUpServiceId: number}[], {rejectValue: ResponseError; state: RootState}>(
    'followup/addBulkCloneServiceIds',
    async (serviceIds, {dispatch, getState}) => {
      const response = await APIS.booking.followUp.bulkAddClonedSkus({
        cart_id: getState().booking.cart?.id,
        items: serviceIds,
      });

      dispatch(cartSlice.actions.updateCart({cart: {...response?.data.cart}}));
      return response;
    }
  ),
  addSkuItem: createAsyncThunk<any, {comp: boolean; qty: number | undefined; index: number}, {rejectValue: ResponseError; state: RootState}>(
    'followup/addSkuItem',
    async ({comp, qty, index}, {dispatch, getState}) => {
      const {selectedSku} = getState().booking.qa.addSku;
      const {bookingTypeId} = getState().booking.bookingType;

      /* This is really janky and why conflating "real" questions with upsells is silly */
      /* SetSelectedSku auto applies the helloTechNow during edit mode. So, we need to reset if not 'actually' present. */
      /* 1. Grab the associated breakdown */
      const foundItem = getState().booking.cart?.breakdown?.items[+index];
      /* Do we have a helloTechNow adjustment */
      const hasHelloTechNow = foundItem?.adjustments?.find((adjustment: ItemAdjustments) => adjustment?.type === 'remote');
      const remoteOverride = !hasHelloTechNow ? {remote: {id: REMOTE_FALSE}} : {};

      const response = await APIS.booking.cart.updateSku(
        applyCartClientIds({
          params: {
            item: {
              ...selectedSku,
              questions: {
                ...selectedSku.questions,
                ...remoteOverride,
              },
              follow_up: {
                comp,
                serviceId: +bookingTypeId,
                quantity: Number(qty) ?? 1,
              },
            },
            index: +index,
          },
          state: getState,
        })
      );

      const {clientID} = response.data?.cart;

      dispatch(cartSlice.actions.updateCart({cart: {...response?.data.cart, clientId: clientID}}));
      return response;
    }
  ),
};

/*
 *****************************************
 ************ INITIAL STATE  *************
 *****************************************
 */

const initialState: TS.FollowupOrderState = {
  breakdown: {} as FollowUpBreakDown,
  id: '',
  token: '',
  partner: false,
  showServices: false,
  detailSelections: {},
} as TS.FollowupOrderState;

/*
 ****************************************
 ************ CREATE SLICE  *************
 *****************************************
 */
const followupOrderSlice = createSlice({
  name: 'followupOrder',
  initialState,
  reducers: {
    setShowServices: (state, action) => {
      return {...state, showServices: action.payload};
    },
    setDetailsRadioSelection: (state, action) => {
      const isItemizedComp = action.payload === COMP_STATUS.ITEMIZED_COMP;
      state.detailSelections = {
        ...state.detailSelections,
        radio: action.payload,
        ...(!isItemizedComp && {checkbox: []}),
      };
    },
    setDetailsTextArea: (state, action) => {
      state.detailSelections.textarea = action.payload;
    },
  },

  extraReducers: builder => {
    builder.addMatcher(
      (action): action is AnyAction => [asyncActions.getOrder.fulfilled].some(actionCreator => actionCreator.match(action)),
      (state, action) => {
        const followupOrder = {...action.payload.data.order, cart: action.payload.cartResponse.data.cart};

        return {...state, errors: {}, ...followupOrder};
      }
    );
  },
});

/*
*******************************************************
  SELECTORS & SELECTOR METHODS
*******************************************************
*/
const getFollowupOrderState = (state: RootState): TS.FollowupOrderState => state.booking.followupOrder;

/*
*******************************************************
  EXPORTS
*******************************************************
*/
export const selectors = {
  getFollowUpOrderState: createSelector(getFollowupOrderState, followupOrder => followupOrder),
  getFollowUpOrderIdState: createSelector(getFollowupOrderState, followupOrder => followupOrder?.id),
  getShowServicesState: createSelector(getFollowupOrderState, followupOrder => {
    return followupOrder?.showServices;
  }),
  getFollowUpOrderItemsState: createSelector(getFollowupOrderState, followupOrder => {
    const breakdown = followupOrder?.breakdown;

    return breakdown?.services || [];
  }),
  getFollowUpDetailSelections: createSelector(getFollowupOrderState, followupOrder => followupOrder?.detailSelections),
};

export const actions = {...asyncActions, ...followupOrderSlice.actions};

export default followupOrderSlice.reducer;
