import dayjs from 'dayjs';

/* Constants */
import {STATUS_PAGES_ATTRIBUTES} from 'features/MultiDwellingUnits/Pages/Projects/Projects.constants';
import {isoFormatSlash} from 'global/constants/common';

/* Types */
import {FlaggedEnums} from 'features/MultiDwellingUnits/Pages/Projects/Parts/MDUProjectsFilters/MDUProjectsFilters.types';
import {MDUStatuses, NavPageTypes, IStatProps, MDUUnitStatuses, PausedStatusTypes} from 'features/MultiDwellingUnits/MDU.types';
import {TStatusPageAttributes} from 'features/MultiDwellingUnits/Pages/Projects/Projects.types';

export const STAT_PROPS: IStatProps & TStatusPageAttributes = {
  total: {
    icon: 'hourglass',
    colorVariant: 'blue',
  },
  [NavPageTypes.ALL_STATUSES]: {
    ...STATUS_PAGES_ATTRIBUTES[NavPageTypes.ALL_STATUSES],
    statRef: 'total',
    filters: {
      statuses: [MDUStatuses.DRAFT, MDUStatuses.READY, MDUStatuses.UPCOMING, MDUStatuses.IN_PROGRESS, MDUStatuses.NEEDS_APPROVAL],
    },
  },
  [MDUStatuses.DRAFT]: {
    ...STATUS_PAGES_ATTRIBUTES[MDUStatuses.DRAFT],
    filters: {
      statuses: [MDUStatuses.DRAFT],
    },
  },
  [MDUStatuses.READY]: {
    ...STATUS_PAGES_ATTRIBUTES[MDUStatuses.READY],
    filters: {
      statuses: [MDUStatuses.READY],
    },
  },
  [MDUStatuses.UPCOMING]: {
    ...STATUS_PAGES_ATTRIBUTES[MDUStatuses.UPCOMING],
    filters: {
      statuses: [MDUStatuses.UPCOMING],
      paused: PausedStatusTypes.ONLY_NOT_PAUSED,
    },
  },
  [MDUStatuses.IN_PROGRESS]: {
    ...STATUS_PAGES_ATTRIBUTES[MDUStatuses.IN_PROGRESS],
    filters: {
      statuses: [MDUStatuses.IN_PROGRESS],
      paused: PausedStatusTypes.ONLY_NOT_PAUSED,
    },
  },
  [MDUStatuses.NEEDS_APPROVAL]: {
    ...STATUS_PAGES_ATTRIBUTES[MDUStatuses.NEEDS_APPROVAL],
    filters: {
      statuses: [MDUStatuses.NEEDS_APPROVAL],
      paused: PausedStatusTypes.ONLY_NOT_PAUSED,
    },
  },
  // @ts-ignore
  [NavPageTypes.APPROVED]: {},
  late: {
    icon: 'gauge',
    colorVariant: 'purple',
    label: 'Late',
    filters: {
      estimatedEndDate: {
        from: dayjs().subtract(90, 'day').format(isoFormatSlash),
        to: dayjs().subtract(1, 'day').format(isoFormatSlash),
      },
    },
  },
  starting_today: {
    icon: 'clock',
    colorVariant: 'light-blue',
    label: 'Starting Today',
    filters: {
      startDate: {
        from: dayjs().format(isoFormatSlash),
        to: dayjs().format(isoFormatSlash),
      },
    },
  },
  flagged: {
    icon: 'flag-outline',
    colorVariant: 'red',
    label: 'Issues Flagged',
    filters: {
      flagged: FlaggedEnums.ONLY_FLAGGED,
    },
  },
  complete: {
    icon: 'flag-filled',
    colorVariant: 'red',
    label: 'Complete',
    filters: {
      statuses: [MDUUnitStatuses.COMPLETED],
    },
  },
  total_unit_progress: {
    label: 'Total Unit Progress',
  },
  project_manager_projects_average: {
    icon: 'user',
    colorVariant: 'default',
    label: 'Avg Projects Per PM',
  },
};
