import {logger, LoggerErrorType} from 'utils/logger';

export const copyToClipboard = async (link: string) => {
  if (link) {
    try {
      await navigator.clipboard.writeText(link);
    } catch (err) {
      logger('copyToClipboard')(err as LoggerErrorType);
    }
  }
};
