import {useCallback, useMemo} from 'react';
import dayjs from 'dayjs';

import {useAppDispatch} from 'hooks/useAppDispatch';
import {useSelector} from 'hooks/useAppSelector';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {useProjectsRouteParams} from '../../hooks';

import {FlaggedEnums, IMDUProjectsFilters} from './MDUProjectsFilters.types';
import {CustomFilterTypes, MDUProjectTypeSlugs, MDUStatuses, NavTypes, WideNavTypeForFilter, PausedStatusTypes} from 'features/MultiDwellingUnits/MDU.types';

import {numericDayMonthYear} from 'global/constants/common';
import {convertSnakeToCamelAndCapitalize} from 'features/MultiDwellingUnits/MDU.utils';
import filterObjectByEmptyValues from 'utils/object/filterObjectOfEmptyValues';

import {CHIP_LABEL_PREFIX} from './MDUProjectsFilters.constants';

type TUseSetFiltersReturnType = {
  filters: IMDUProjectsFilters;
  setFilters: (filters: IMDUProjectsFilters) => void;
};

export const useSetFilters = (pageKey: WideNavTypeForFilter = NavTypes.ALL, customFilterKey?: CustomFilterTypes): TUseSetFiltersReturnType => {
  const dispatch = useAppDispatch();
  const {projectsType} = useProjectsRouteParams();
  /*
    Normally, we have filters under all/own which allows us to have unique filters between them.
    But, when we have "custom" filters - they reside at root level, ala "jobs". So the selectorRef and pageKey are the
    same.
  * */
  const {[pageKey]: pageKeyFilters = {}} = useSelector(mduProjectsSlice.selectors.getProjectsFilterState(projectsType));
  const customFilter = useSelector(mduProjectsSlice.selectors.getFilterByCustomKeyState(pageKey as CustomFilterTypes));
  const filters = customFilterKey ? customFilter : pageKeyFilters;

  const setFilters = useCallback<TUseSetFiltersReturnType['setFilters']>(
    value => {
      /* doing it base for typesrcript fencing reason */
      if (customFilterKey) {
        dispatch(mduProjectsSlice.actions.updateCustomFilter({key: customFilterKey, filters: value}));
      } else {
        dispatch(mduProjectsSlice.actions.updateProjectsFilter({key: pageKey, filters: value}));
      }
    },
    [customFilterKey, dispatch, pageKey]
  );

  return {filters, setFilters};
};

/**
 * Data for the filters chips
 */
export const useTableToolbarFilters = ({filters}: {filters: IMDUProjectsFilters}) => {
  const rawFilters = useSelector(mduProjectsSlice.selectors.getRawFilters);
  const filteredFilters = filterObjectByEmptyValues(filters, ['search']);
  const filtersCount = Object.values(filteredFilters ?? {}).filter(Boolean).length;

  const rawFilterDisplayName = useCallback(
    (key: keyof typeof filteredFilters) => {
      const filterValue = filteredFilters[key];
      /* @ts-ignore */
      const displayName = Array.isArray(filterValue) ? filterValue.map(id => rawFilters[key]?.[id]) : rawFilters[key]?.[filterValue!];
      const displayNameString = Array.isArray(displayName) ? displayName.join(', ') : displayName;
      /* @ts-ignore */
      return `${CHIP_LABEL_PREFIX[key]}: ${displayNameString || filterValue}`;
    },
    [filteredFilters, rawFilters]
  );

  const formatDate = (date: string | undefined) => {
    if (!date) return '';
    return dayjs(date).format(numericDayMonthYear);
  };

  const filtersChips = useMemo(() => {
    const chips: Array<{filterKey: keyof IMDUProjectsFilters; label: string}> = [];

    (Object.keys(filteredFilters) as Array<keyof IMDUProjectsFilters>).forEach(key => {
      if (key === 'statuses') {
        if ((filteredFilters[key] || []).length > 0) {
          const statuses = filteredFilters[key]! as unknown as MDUStatuses[];
          chips.push({
            filterKey: key,
            label: `${CHIP_LABEL_PREFIX[key]}: ${convertSnakeToCamelAndCapitalize(statuses, ',')}`,
          });
        }
      } else if (key === 'startDate' || key === 'estimatedEndDate' || key === 'approvedAt' || key === 'lastModifiedAt') {
        const {to, from} = filteredFilters[key] || {};

        if (to) {
          chips.push({
            filterKey: key,
            label: `${CHIP_LABEL_PREFIX[key]}: ${[formatDate(from), formatDate(to)].filter(value => value).join(' - ')}`,
          });
        }
      } else if (key === 'only_flagged' || key === 'flagged') {
        if (filteredFilters[key] === FlaggedEnums.ONLY_FLAGGED || filteredFilters[key] === FlaggedEnums.TRUE) {
          chips.push({
            filterKey: key,
            label: CHIP_LABEL_PREFIX[key],
          });
        }
      } else if (key === 'project_types') {
        const projectTypes = filteredFilters[key]! as unknown as MDUProjectTypeSlugs[];
        chips.push({
          filterKey: key,
          label: `${CHIP_LABEL_PREFIX[key]}: ${convertSnakeToCamelAndCapitalize(projectTypes, ',')}`,
        });
      } else if (key === 'paused') {
        let pausedCopy = '';
        pausedCopy = filteredFilters[key] === PausedStatusTypes.ONLY_PAUSED ? 'Yes' : pausedCopy;
        pausedCopy = filteredFilters[key] === PausedStatusTypes.ONLY_NOT_PAUSED ? 'No' : pausedCopy;
        if (pausedCopy) {
          chips.push({
            filterKey: key,
            label: `${CHIP_LABEL_PREFIX[key]}: ${pausedCopy}`,
          });
        }
      } else {
        chips.push({
          filterKey: key,
          label: rawFilterDisplayName(key),
        });
      }
    });

    return chips;
  }, [filteredFilters, rawFilterDisplayName]);

  return {
    filtersChips,
    filtersCount,
  };
};
