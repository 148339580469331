import React, {useRef, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {useAppDispatch} from 'hooks/useAppDispatch';

/* Components */
import ToolbarLink from 'components/Table/TableToolbar/TableToolbar.Link';
import {MenuListItem, MenuPanel, MenuUnorderedList} from 'components/Elements/Menu';

/* Hooks */
import useOutsideClick from 'hooks/useOutsideClick';

/* Ducks */
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';

/* Constants / Utils */
import {mduProjectPathByStage, mduProjectSingleJobCreate} from 'global/paths';

import {MduUseParamsTypes, TBulkActionTypes, TSingleActionTypes} from 'features/MultiDwellingUnits/MDU.types';

import styles from './MDUProjectsFilters.styles.scss';

const AddJobsLink = () => {
  /* Local State */
  const [menu, setMenu] = useState(false);
  const wrapperRef = useRef(null);

  /* Hooks */
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {projectId = '', stage = ''} = useParams<MduUseParamsTypes>();

  /* Methods */
  const handleClickOutside = () => {
    setMenu(false);
  };
  const onHandleSetMenu = () => {
    setMenu(!menu);
  };

  const onHandleDispatch = (key: TSingleActionTypes | TBulkActionTypes) => {
    dispatch(mduProjectsSlice.actions.setActionItemModalSlide({entity: {}, modalKey: key, modalType: 'Job'}));
    navigate(mduProjectSingleJobCreate(projectId));
  };

  useOutsideClick(wrapperRef, handleClickOutside);

  return (
    <span className="position relative" ref={wrapperRef}>
      <ToolbarLink onClick={onHandleSetMenu} iconName="plus-circle" label="Add Jobs" />
      {menu ? (
        <MenuPanel className={styles.menuPosition}>
          <MenuUnorderedList>
            <MenuListItem dataTestId="menu-approve-jobs" onClick={() => onHandleDispatch(TSingleActionTypes.ADD_SINGLE_JOB)}>
              Add Single Job
            </MenuListItem>
            <MenuListItem dataTestId="menu-reopen-jobs" onClick={() => navigate(`${mduProjectPathByStage(projectId, stage)}/bulk-create`)}>
              Add Multiple Jobs
            </MenuListItem>
          </MenuUnorderedList>
        </MenuPanel>
      ) : null}
    </span>
  );
};

export default AddJobsLink;
