/**
 * DRAFT MODEL
 *
 * This handles the cache for the bulk template/jobs behaviors
 * in regards to adding multiple jobs to templates.
 *
 * These are all drafts, so we dont need to refetch the project.
 *
 * NOTE: Because we are "reusing components" that are using hooks that are derived from redux state,
 *       we are sorta creating a dichotomy of how we approach the cache vs client state.
 *       Since this is "draft", we can get away with using client. But when we refactor, lets not forget this.
 */

/* Create template */
import {useMutation} from 'react-query';
import {useParams} from 'react-router-dom';

import useApi from 'hooks/useApi';
import APIS from 'global/apis';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {logger} from 'utils/logger';
import {notifications} from 'components/Notification/notification.ducks';
import {handleResponseError} from 'queries/query.utils';

import {MduUseParamsTypes, ProjectGroup, ProjectService, UpdateGroupParams} from 'features/MultiDwellingUnits/MDU.types';
import {SelectedSku} from '../../Questions/types';
import mduProjectsSlice from '../MDU.ducks';

/**
 * THIS FILE DEALS WITH THE DRAFT MODEL ON THE JOBS BULK "NEW" TEMPLATE CREATION
 */

/**
 *  ---------------------------------------------------------------------------------------------------------------
 *  DRAFT GROUPS
 *
 *  - save
 *  - get
 *    note: usually, this would be a query. But since we are offloading to redux, we are using a mutation.
 *  ---------------------------------------------------------------------------------------------------------------
 */

/* GETTER For New Drafted Groups */
export const useGetGroupDraftMutation = () => {
  const api = useApi();
  const dispatch = useAppDispatch();
  const {projectId, groupId} = useParams<MduUseParamsTypes>();
  return useMutation(
    async (): Promise<{projectGroup: ProjectGroup}> => {
      api.toggleLoader(true);
      const response = await APIS.mdu.getGroup({projectId, id: groupId}, {draft: true, forceCase: 'camel'});
      api.toggleLoader(false);

      handleResponseError(response);

      return response.data;
    },
    {
      onSuccess: data => {
        dispatch(mduProjectsSlice.actions.updateTemplateCreationNewDraft(data));
      },
      /* SHOULD MOVE ON ERROR TO THE GLOBAL SCOPE: REDUNDANCY HERE */
      onError: (error: Error) => {
        logger('getGroupDraft')(error);
        dispatch(notifications.actions.notificationApiError({source: 'Error Get Group Draft'}));
      },
    }
  );
};

/* (( SAVE BUTTON )) Save 'new' templates from draft */
export const useSaveNewDraftMutation = () => {
  const api = useApi();
  const dispatch = useAppDispatch();
  const {projectId, groupId} = useParams<MduUseParamsTypes>();
  return useMutation(
    async (): Promise<{projectGroup: ProjectGroup}> => {
      api.toggleLoader(true);
      const response = await APIS.mdu.confirmDraftNewTemplates({projectId, groupId}, {draft: true});
      api.toggleLoader(false);

      handleResponseError(response);
      return response.data;
    },
    {
      onError: (error: Error) => {
        logger('addingServiceDraft')(error);
        dispatch(notifications.actions.notificationApiError({source: `Error Adding Service: Draft: ${error}`}));
      },
    }
  );
};

/**
 *  -------------------------------------------------------------
 *  DRAFT TEMPLATES
 *
 *  - create
 *  - delete
 *  - update
 *  -------------------------------------------------------------
 */
export const useCreateJobTemplateDraftMutation = () => {
  const api = useApi();
  const dispatch = useAppDispatch();
  const {projectId} = useParams<MduUseParamsTypes>();
  return useMutation(
    async (): Promise<{projectGroup: ProjectGroup}> => {
      api.toggleLoader(true);
      const response = await APIS.mdu.createGroup({projectId}, {project_group: {unitsNumber: 1}, draft: true});
      api.toggleLoader(false);

      handleResponseError(response);

      return response.data;
    },
    {
      onError: (error: Error) => {
        logger('createTemplateDraft')(error);
        dispatch(notifications.actions.notificationApiError({source: 'Error Creating Template: Draft'}));
      },
    }
  );
};

/* Update Template */
export const useUpdateJobTemplateDraftMutation = () => {
  const api = useApi();
  const dispatch = useAppDispatch();
  const {mutate} = useGetGroupDraftMutation();
  const {projectId} = useParams<MduUseParamsTypes>();

  return useMutation(
    async ({projectGroupId, projectGroups}: {projectGroupId: string | number; projectGroups: UpdateGroupParams}): Promise<{projectGroup: ProjectGroup}> => {
      api.toggleLoader(true);
      const response = await APIS.mdu.updateGroup({projectId, id: projectGroupId}, {project_group: projectGroups, draft: true});
      api.toggleLoader(false);

      handleResponseError(response);

      return response.data;
    },
    {
      onSuccess: () => {
        mutate();
      },
      onError: (error: Error) => {
        logger('updateTemplateDraft')(error);
        dispatch(notifications.actions.notificationApiError({source: 'Error Update Template: Draft'}));
      },
    }
  );
};

/**
 *  -------------------------------------------------------------
 *  DRAFT SERVICES
 *
 *  - create
 *  - update
 *  - delete
 *  -------------------------------------------------------------
 */

/* Add service */
export const useCreateServiceDraftMutation = () => {
  const api = useApi();
  const {mutate} = useGetGroupDraftMutation();
  const dispatch = useAppDispatch();
  const {projectId, groupId} = useParams<MduUseParamsTypes>();
  return useMutation(
    async (selectedSku: SelectedSku): Promise<{projectGroup: ProjectGroup}> => {
      api.toggleLoader(true);
      const response = await APIS.mdu.createService({projectId, groupId}, {project_service: selectedSku, draft: true});
      api.toggleLoader(false);

      handleResponseError(response);

      return response.data;
    },
    {
      onSuccess: () => {
        mutate();
      },
      onError: (error: Error) => {
        logger('addingServiceDraft')(error);
        dispatch(notifications.actions.notificationApiError({source: 'Error Adding Service: Draft'}));
      },
    }
  );
};

/* Delete a Service */
export const useDeleteServiceDraftMutation = () => {
  const api = useApi();
  const {mutate} = useGetGroupDraftMutation();
  const dispatch = useAppDispatch();
  const {projectId, groupId} = useParams<MduUseParamsTypes>();
  return useMutation(
    async (serviceId: number): Promise<{projectGroup: ProjectGroup}> => {
      api.toggleLoader(true);
      const response = await APIS.mdu.deleteService({projectId, groupId, serviceId}, {draft: true});
      api.toggleLoader(false);

      handleResponseError(response);
      return response.data;
    },
    {
      onSuccess: () => {
        mutate();
      },
      onError: (error: Error) => {
        logger('deletingServiceDraft')(error);
        dispatch(notifications.actions.notificationApiError({source: 'Error Deleting Service: Draft'}));
      },
    }
  );
};

/* Update service */
export const useUpdateServiceDraftMutation = () => {
  const api = useApi();
  const {mutate} = useGetGroupDraftMutation();
  const dispatch = useAppDispatch();
  const {projectId, groupId, serviceId} = useParams<MduUseParamsTypes>();
  return useMutation(
    async (projectService: ProjectService): Promise<{projectGroup: ProjectGroup}> => {
      const sid = serviceId || projectService.id;

      api.toggleLoader(true);
      const response = await APIS.mdu.updateService({projectId, groupId, serviceId: sid}, {project_service: projectService, draft: true});
      api.toggleLoader(false);

      handleResponseError(response);
      return response.data;
    },
    {
      onSuccess: () => {
        mutate();
      },
      onError: (error: Error) => {
        logger('updateServiceDraft')(error);
        dispatch(notifications.actions.notificationApiError({source: 'Error Updating Service: Draft'}));
      },
    }
  );
};
