import {useQuery} from 'react-query';
import useApi from 'hooks/useApi';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {TOrderDetails} from 'features/Orders/types/orders.types';
import {showErrorToast, logResponseError} from 'queries/query.utils';
import ORDERS_QUERY_KEYS from './orders.query.keys';

type TUseGetOrderDetailsResponse = WithErrorResponse<{order: TOrderDetails}>;

type TUseGetOrderDetailsQueryParams = {
  /** order id */
  id: number;
  /**
   * Whether to fetch full order details
   */
  full: boolean;
  useLoader?: boolean;
  queryOpts?: Parameters<typeof useQuery<TUseGetOrderDetailsResponse['data']>>['2'];
};

/**
 * Fetch order details by id
 *
 * Note: The response isn't typed correctly as it can vary based on the
 * `full` parameter. Let's cross that bridge when there's a need to use `full` as `false`.
 */
const useGetOrderDetails = ({id, full = true, useLoader = false, queryOpts}: TUseGetOrderDetailsQueryParams) => {
  const api = useApi();
  const dispatch = useAppDispatch();

  return useQuery<TUseGetOrderDetailsResponse['data']>(
    ORDERS_QUERY_KEYS.getOrderDetails(id, full),
    async () => {
      if (useLoader) {
        api.toggleLoader(true);
      }
      const bodyParams = {
        ...(full && {full}),
      };
      const response: TUseGetOrderDetailsResponse = await api.orders.getDetails({id}, bodyParams);
      if (useLoader) {
        api.toggleLoader(false);
      }
      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error retrieving order details'});
        logResponseError('Order details')(response.err);
        throw new Error('Error retrieving order details');
      }
      return response?.data;
    },
    queryOpts
  );
};

export default useGetOrderDetails;
