import React from 'react';
import {ChildrenProp} from 'types/base.types';
import styles from './menu.styles.scss';

import MenuListItem from './Menu.LI';

type MenuLineItemCalloutGroupProps = {
  children: ChildrenProp | Element;
  dataTestId: string;
  onClick: BaseAnyFunction;
};

const MenuLICallout = ({children, onClick, dataTestId}: MenuLineItemCalloutGroupProps) => {
  return (
    <MenuListItem onClick={onClick} dataTestId={dataTestId} className={styles.callout_group}>
      {children}
    </MenuListItem>
  );
};

export default MenuLICallout;
