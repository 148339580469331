import React, {useMemo, useCallback, useState, useEffect} from 'react';
import dayjs from 'dayjs';
// Utils
import {getImgElementWithFileIcon} from 'features/MultiDwellingUnits/Parts/Table/Table.utils';
import {truncateLongFilename, isAttachmentImage, getFileNameFromAttachmentUrl} from 'features/MultiDwellingUnits/MDU.utils';
import {noop} from 'utils/event';
import {l} from 'global/constants/common';
import {sortAlphabeticallyOrNumerically, sortByDate} from 'components/Elements/DataTable/utils/sort.utils';
import {actionMenuColumn} from 'components/Elements/DataTable/utils/columns.utils';
// Components && Styles
import HTLightbox from 'components/Elements/HTLightbox';
import {ProjectNoteAttachment} from 'features/MultiDwellingUnits/MDU.types';
import DataTable from 'components/Elements/DataTable';
import {TDataTableColumnDef} from 'components/Elements/DataTable/dataTable.types';

type FileDisplayProps = {
  attachments: ProjectNoteAttachment[];
  onDeleteFile?: (id: number) => void;
  onDownloadFile?: (id: number) => void;
};

export const formatDateForTable = (date: string) => dayjs(date).format(l);

const FileDisplay = ({attachments, onDeleteFile = noop, onDownloadFile = noop}: FileDisplayProps) => {
  const [targetIndex, setTargetIndex] = useState(0); // Index of the image to open in the lightbox
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const toggleLightbox = useCallback(() => setIsLightboxOpen(!isLightboxOpen), [isLightboxOpen]);
  const carouselProps = {finite: true};

  const getAnchorId = useCallback((id: number) => `download-file-${id}`, []);

  const clickAnchorStartDownload = useCallback(
    (id: number) => {
      const anchor = document.getElementById(getAnchorId(id));
      if (anchor) anchor.click();
    },
    [getAnchorId]
  );

  const getListItems = useCallback(
    (attachment: ProjectNoteAttachment) => [
      {
        text: 'Delete File',
        key: 0,
        onClick: () => onDeleteFile(attachment.id),
      },
      {
        text: 'Download',
        key: 1,
        onClick: () => {
          clickAnchorStartDownload(attachment.id);
          onDownloadFile(attachment.id);
        },
      },
    ],
    [onDeleteFile, onDownloadFile, clickAnchorStartDownload]
  );

  const imagesOnlyAttachments = attachments.filter(isAttachmentImage);

  /**
   * Append some data for sorting purposes
   */
  const normalizedData = useMemo(() => {
    return attachments.map(a => {
      const formattedFileName = truncateLongFilename(getFileNameFromAttachmentUrl(a));
      const file = {name: formattedFileName, type: a.file_type};
      return {
        formattedFile: file,
        ...a,
      };
    });
  }, [attachments]);

  type TNormalizedData = (typeof normalizedData)[number];

  const columnData = useMemo(() => {
    const openImageInLightbox = (a: ProjectNoteAttachment) => {
      const index = imagesOnlyAttachments.findIndex(image => image.id === a.id);
      setTargetIndex(index);
      toggleLightbox();
    };
    const openAttachment = (a: ProjectNoteAttachment) => (isAttachmentImage(a) ? openImageInLightbox(a) : clickAnchorStartDownload(a.id));

    const columnDefs: TDataTableColumnDef<TNormalizedData>[] = [
      {
        header: 'File Name',
        accessorKey: 'formattedFile.name',
        Cell: ({row}) => {
          const {formattedFile} = row.original;
          return (
            <div className="flex align-items-center">
              {getImgElementWithFileIcon(formattedFile)}
              <span className="marginLeft-small">{formattedFile.name}</span>
            </div>
          );
        },
        muiTableBodyCellProps: ({row}) => ({
          onClick: () => openAttachment(row.original),
        }),
        sortingFn: sortAlphabeticallyOrNumerically,
        columnWidthMode: 'fill',
        columnWidthSize: 'md',
      },
      {
        header: 'File Type',
        accessorKey: 'file_type',
        muiTableBodyCellProps: ({row}) => ({
          onClick: () => openAttachment(row.original),
        }),
        sortingFn: sortAlphabeticallyOrNumerically,
        columnWidthMode: 'fill',
        columnWidthSize: 'sm',
      },
      {
        header: 'File Size',
        id: 'file_size',
        accessorFn: originalRow => originalRow.file_size_formatted,
        muiTableBodyCellProps: ({row}) => ({
          onClick: () => openAttachment(row.original),
        }),
        sortingFn: sortAlphabeticallyOrNumerically,
        columnWidthMode: 'fill',
        columnWidthSize: 'sm',
      },
      {
        header: 'Uploaded',
        accessorKey: 'created_at',
        Cell: ({row}) => {
          const a = row.original;
          return (
            <>
              <span>{formatDateForTable(a.created_at)}</span>
              {/* download attribute only works for same-origin URLs. See docs at  https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-download */}
              <a download href={a.url} id={getAnchorId(a.id)} style={{display: 'none'}} target="_blank" rel="noreferrer">
                download file
              </a>
            </>
          );
        },
        muiTableBodyCellProps: ({row}) => ({
          onClick: () => openAttachment(row.original),
        }),
        sortingFn: sortByDate,
        columnWidthMode: 'fill',
        columnWidthSize: 'sm',
      },
      actionMenuColumn<TNormalizedData>({getListItems}),
    ];
    return columnDefs;
  }, [clickAnchorStartDownload, getAnchorId, getListItems, imagesOnlyAttachments, toggleLightbox]);

  const slides = imagesOnlyAttachments.map(attachment => {
    const filename = getFileNameFromAttachmentUrl(attachment, {withoutQueryParams: true});
    return {
      src: attachment.url,
      alt: filename,
      title: filename,
    };
  });

  const stopPropagation = useCallback((e: Event) => e.stopPropagation(), []);
  useEffect(() => {
    // Stop event propagation on download links so that the lightbox doesn't open
    attachments.forEach(attachment => {
      const anchor = document.getElementById(getAnchorId(attachment.id));
      if (anchor) {
        anchor.addEventListener('click', stopPropagation);
      }
    });
    // on dismount remove the event listeners
    return () => {
      attachments.forEach(attachment => {
        const anchor = document.getElementById(getAnchorId(attachment.id));
        if (anchor) {
          anchor.removeEventListener('click', stopPropagation);
        }
      });
    };
  }, [attachments, getAnchorId, stopPropagation]);

  if (!attachments.length) return null;

  return (
    <>
      <DataTable tableKey="CurrentProject-FilesPage-FileDisplay" columns={columnData} data={normalizedData} isZebra hasActionableRows />
      {isLightboxOpen && <HTLightbox slides={slides} index={targetIndex} open={isLightboxOpen} onClose={toggleLightbox} showThumbnails={slides.length > 1} carousel={carouselProps} />}
    </>
  );
};

export default FileDisplay;
