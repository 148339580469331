import React, {useState, useEffect, ChangeEvent} from 'react';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import {Button, BUTTON_THEMES, Modal, ELEMENT_SIZE, InputField} from 'ht-styleguide';

/* Queries */
import {useValidateTemplateNameMutation} from 'features/MultiDwellingUnits/queries/mutation.project.validateTemplateName';

/* Utils */
import {noop} from 'utils/event';

/* styles */
import styles from './modals.styles.scss';

type UpdateNameModalProps = {
  isVisible: boolean;
  dispatchUpdateGroupName: BaseAnyFunction;
  onCloseCallback?: BaseAnyFunction;
  currentName?: string;
};

/**
 * @description - Modal to update a group name
 * @param isVisible
 * @param currentName
 * @param onCloseCallback
 * @param dispatchUpdateGroupName
 * @constructor
 */
const ChangeGroupName = ({isVisible, currentName = '', onCloseCallback = noop, dispatchUpdateGroupName}: UpdateNameModalProps) => {
  const [name, setName] = useState<string>('');
  const [inputTemplateNameError, setInputTemplateNameError] = useState(false);

  /* Queries */
  const validateTemplateNameMutation = useValidateTemplateNameMutation();

  /* TODO: break this out to a hook as we also use this in Jobs.Create.Single.tsx */
  const validateTemplateName = debounce(
    templateName => {
      if (!templateName) return;

      validateTemplateNameMutation.mutate(
        {templateName},
        {
          onSuccess: data => {
            if (!data.allowed) setInputTemplateNameError(true);
          },
        }
      );
    },
    400,
    {leading: false, trailing: true}
  );

  const setNameTemplate = (evt: ChangeEvent<HTMLInputElement>) => {
    validateTemplateName(evt?.target?.value);
    setInputTemplateNameError(false);
    setName(evt.currentTarget.value);
  };
  const templateNameError = inputTemplateNameError ? 'Template Name already exists' : '';

  useEffect(() => setName(currentName), [currentName]);

  return (
    <Modal
      isVisible={isVisible}
      elementSize={ELEMENT_SIZE.MEDIUM}
      modalClassName={cn(styles.container, styles.modalContents)}
      hide={onCloseCallback}
      onRequestClose={onCloseCallback}
      header="Edit Name"
      bodyContainerClasses={styles.flexInitial}
      footerElement2={
        <Button theme={BUTTON_THEMES.SECONDARY} dataTestId="button_cancel_change_name" className={styles.ctaButton} onClick={onCloseCallback}>
          Cancel
        </Button>
      }
      footerElement3={
        <Button disabled={Boolean(templateNameError)} theme={BUTTON_THEMES.PRIMARY} dataTestId="button_update_name" className={styles.ctaButton} onClick={() => dispatchUpdateGroupName(name)}>
          Edit Name
        </Button>
      }
    >
      <div className="position relative">
        <InputField error={templateNameError} value={name} onChange={setNameTemplate} />
      </div>
    </Modal>
  );
};

export default ChangeGroupName;
