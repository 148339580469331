import React from 'react';
import {MRT_DisplayColumnIds, MRT_ColumnOrderState, MRT_TableInstance, MRT_Column, MRT_DensityState} from 'material-react-table';
import cn from 'classnames';
import omit from 'lodash/omit';
import {Tooltip} from 'ht-styleguide';
import {sortAlphabeticallyOrNumerically} from './sort.utils';
import styles from '../dataTable.styles.scss';
import {TDataTable, TDataTableColumnDef, TTableInstance} from '../dataTable.types';
import {COLUMN_SIZE} from '../dataTable.constants';

/**
 * Return an array of column ids that should be pinned to the left side of the table.
 * This work around is needed to exclude certain build-in MRT columns from being pinned and to include
 * the checkbox column.
 */
export const getLeftPinnedColumns = <TData extends Record<string, any> = {}>({
  table,
  stateFromProp,
}: {
  table: MRT_TableInstance<TData>;
  stateFromProp: TDataTable<TData>['state'];
}): MRT_ColumnOrderState => {
  // Need to use the state from the prop to get the initial state of the table to avoid
  // adding onto the state when the table is re-rendered.
  const leftPinColumnsState = stateFromProp?.columnPinning?.left ?? [];

  if (Array.isArray(leftPinColumnsState) && !leftPinColumnsState.length) {
    return [];
  }

  let pinnedColumns = [];
  const shouldPinMRTColumns: MRT_DisplayColumnIds[] = ['mrt-row-expand', 'mrt-row-select'];
  // Keep in case of future use
  // const excludeMRTColumns: MRT_DisplayColumnIds[] = ['mrt-row-actions', 'mrt-row-drag', 'mrt-row-numbers'];
  const tableState = table.getState();

  // Place built-in MRT columns first
  pinnedColumns.push(...shouldPinMRTColumns);

  // Place the pinned columns defined in table state
  pinnedColumns.push(...leftPinColumnsState);
  pinnedColumns = pinnedColumns.filter(column => !!column);

  // Place grouped columns to the front of the list
  const grouping = tableState?.grouping ?? [];
  pinnedColumns.unshift(...grouping);

  return pinnedColumns;
};

export const determineIsLeftPinnedColumn = <TData extends Record<string, any> = {}>({table, column}: {table: MRT_TableInstance<TData>; column: MRT_Column<TData>}) => {
  const leftPinnedColumns = table.getState()?.columnPinning?.left ?? [];
  const {id: columnId} = column;
  return column.getIsPinned() && leftPinnedColumns.includes(columnId);
};

export const determineIsRightPinnedColumn = <TData extends Record<string, any> = {}>({table, column}: {table: MRT_TableInstance<TData>; column: MRT_Column<TData>}) => {
  const rightPinnedColumns = table.getState()?.columnPinning?.right ?? [];
  const {id: columnId} = column;
  return column.getIsPinned() && rightPinnedColumns.includes(columnId);
};

/**
 * https://www.material-react-table.com/docs/guides/customize-components
 *
 * MRT has built in components whose props can be defined on the table level or on the
 * column definition level. However, if both props are used, props from the column definition
 * and the table definition will be merged, and the column definition props will take precedence.
 * Due to the way MRT merges these props, this util will allow us to apply global props that would
 * normally have been defined on the table level.
 */
export const overrideMuiComponentsOnCol = <TData extends Record<string, any> = {}>({
  column,
  tableState,
}: {
  column: TDataTableColumnDef<TData>;
  tableState: ReturnType<MRT_TableInstance<TData>['getState']> | undefined | null;
}) => {
  const columnCopy = {...column}; // needed to prevent infinite loop

  // We're adding upon `muiTableBodyCellProps` since some columns call for extra classes
  column.muiTableBodyCellProps = props => {
    const {column: propsColumn, table, row, cell} = props;
    const {muiTableBodyCellProps} = columnCopy;
    const isLastLeftPinnedColumn = determineIsLeftPinnedColumn({column: propsColumn, table});
    const isRightRightPinnedColumn = determineIsRightPinnedColumn({column: propsColumn, table});
    const isGroupedRow = row.getIsGrouped(); // cell belongs to a grouped row
    const isGroupedCell = cell.getIsGrouped(); // the cell that is being grouped on.

    const columnDefinedProps = (muiTableBodyCellProps instanceof Function ? muiTableBodyCellProps(props) : muiTableBodyCellProps) || {};
    const finalColumnDefinedProps = omit(columnDefinedProps, ['className', 'style']);

    // For pinned columns, manually apply z-indices so that absolute positioned children (like actionMenu)
    // will not be hidden by following row due to position sticky
    const rowModel = table.getSortedRowModel().rows;
    const index = rowModel.findIndex(r => r.id === row.id);
    const style = propsColumn.getIsPinned() ? {zIndex: rowModel.length - index} : undefined;

    return {
      className: cn(
        !isGroupedCell ? 'p2' : styles.groupedCell,
        isGroupedRow && styles.groupedCellInRow,
        isLastLeftPinnedColumn && styles.leftPinnedColumn,
        isRightRightPinnedColumn && styles.rightPinnedColumn,
        columnDefinedProps.className
      ),
      style: {...style, ...columnDefinedProps.style},
      ...finalColumnDefinedProps,
    };
  };

  // If `tooltipContent` is defined, then inject the Tooltip component into the cell
  if (columnCopy.tooltipContent) {
    column.Cell = props => {
      const {renderedCellValue} = props;
      const baseCellContent = columnCopy.Cell ? columnCopy.Cell(props) : renderedCellValue;
      const tooltipContent = columnCopy.tooltipContent instanceof Function ? columnCopy.tooltipContent(props) : columnCopy.tooltipContent;

      return (
        tooltipContent && (
          <Tooltip content={tooltipContent} position="right">
            <>{baseCellContent}</>
          </Tooltip>
        )
      );
    };
  }

  // Override MRT's grouping copy. If the column specifies `GroupedCell`, then the its definition
  // will be used.
  if (!('GroupedCell' in column)) {
    column.GroupedCell = ({cell}) => <>{cell.getValue()}</>;
  }

  // Disable column ordering via dragging if it is the grouped column
  if (tableState?.grouping?.[0] === column.id) {
    column.enableColumnDragging = false;
  }

  // Disable column ordering via dragging if it is a pinned column
  const pinnedColumns = Object.values(tableState?.columnPinning ?? {}).flat();
  if (pinnedColumns.includes(column.id)) {
    column.enableColumnDragging = false;
  }

  // Set default column sort function
  column.sortingFn = column.sortingFn || sortAlphabeticallyOrNumerically;
};

/**
 * Set column width. This is a workaround for MRT's column width calculation.
 */
export const setColumnWidth = <TData extends Record<string, any> = {}>({newCol, density}: {newCol: TDataTableColumnDef<TData>; density: MRT_DensityState}) => {
  const {columnWidthMode = 'fill', columnWidthSize = 'sm', columnWidthSizeCollapse = 0, iconColumn = false} = newCol;

  if (iconColumn) {
    newCol.size = density === 'compact' ? 40 : 50;
    newCol.minSize = density === 'compact' ? 40 : 50;
  } else if (columnWidthMode === 'collapse') {
    newCol.size = columnWidthSizeCollapse;
    newCol.minSize = columnWidthSizeCollapse;
  } else {
    newCol.size = COLUMN_SIZE[columnWidthSize];
    newCol.minSize = 0;
  }
};

export const extractDensityState = <TData extends Record<string, any> = {}>({table}: TTableInstance<TData>) => {
  const tableState = table?.getState();
  return tableState?.density || 'comfortable';
};
