import qs from 'qs';
import {useQuery} from 'react-query';
import isPlainObject from 'lodash/isPlainObject';
import useApi from 'hooks/useApi';
import {TGetIssuesStatsDataResponse} from '../issues.types';
import {ISSUES_QUERY_KEYS} from './Issues.query.keys';

const useGetIssuesStatsQuery = ({filters}: {filters?: Object | null} = {}) => {
  const api = useApi();

  const filtersIsPlainObject = isPlainObject(filters);

  let queryString = '';
  if (filtersIsPlainObject) {
    queryString = qs.stringify({...filters}, {arrayFormat: 'brackets', encode: false, skipNulls: true});
  }
  const queryKeys = [queryString];

  const {data} = useQuery(
    ISSUES_QUERY_KEYS.getStats(queryKeys),
    async () => {
      const response: TGetIssuesStatsDataResponse = await api.issues.getStats({search: queryString});
      return response?.data;
    },
    {
      enabled: filtersIsPlainObject,
    }
  );

  const {stats} = data || {};

  return {
    data: stats,
  };
};

export default useGetIssuesStatsQuery;
