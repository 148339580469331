import {TBaseTaskGroup, TTaskOption, TReference, TBaseTask} from 'types/tasks.types';

export * from 'types/tasks.types';

/**
 * These are most of the fields of a `task` object. The field names are used across API responses,
 * forms, etc.
 */
export enum TaskFieldNames {
  CreatedAt = 'created_at',
  ID = 'id',
  IncludeOtherOption = 'include_other_option',
  Instructions = 'instructions',
  MultiplePhotos = 'multiple_photos',
  Options = 'options',
  Reference = 'reference',
  RequiredTask = 'required_task',
  ShowOptionalNote = 'show_optional_note',
  ShowOptionalPhoto = 'show_optional_photo',
  TaskType = 'task_type',
  Title = 'title',
  UpdatedAt = 'updated_at',
}

export type TTask = TBaseTask;

/**
 * All tasks will be placed in a group whether or not grouping is enabled. Even if there's
 * only one task, it will be placed in a group.
 */
export type TTaskGroup = TBaseTaskGroup<TTask>;

export type TSkuChecklist = {
  /**
   * Grouping mode is enabled for sku
   */
  grouping: boolean;
  task_groups: TTaskGroup[];
  pagination: TBasePagination;
};

/**
 *****************************************************************
 * Upserting task checklist
 * This is a near duplicate of `TSkuChecklist` but with some fields
 * set as optional or nullable.
 ******************************************************************
 */

export type TUpsertTaskOption = Omit<TTaskOption, 'created_at' | 'id' | 'updated_at'> & {
  created_at?: string | null;
  id?: number | null;
  updated_at?: string | null;
};

export type TUpsertTaskReference = Omit<TReference, 'id' | 'skill_guide'> & {
  id?: number | null;
  skill_guide?: TReference['skill_guide'] | null;
};

export type TUpsertTask = Omit<TTask, 'created_at' | 'id' | 'updated_at' | 'options' | 'reference'> & {
  created_at?: string | null;
  id?: number | null;
  options: TUpsertTaskOption[];
  reference: TUpsertTaskReference | null;
  updated_at?: string | null;
};

type TUpsertTaskGroup = Omit<TTaskGroup, 'created_at' | 'updated_at' | 'id' | 'tasks' | 'locked'> & {
  created_at?: string | null;
  id?: number | null;
  group_name: string;
  locked?: boolean;
  tasks: TUpsertTask[];
  updated_at?: string | null;
};

export type TUpsertTaskChecklist = {
  grouping: boolean;
  task_groups: TUpsertTaskGroup[];
};

/** Params and return type of the function that upserts a task */
export type TUpsertTaskFunction = (task: TUpsertTask, onSuccess: () => void) => Promise<void>;
/**
 *****************************************************************
 * END Upserting task checklist
 ******************************************************************
 */
