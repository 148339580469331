import {useMutation} from 'react-query';
import APIS from 'global/apis';
import {logger} from 'utils/logger';

/**
 * @description useGenerateCloudinaryToken hook to generate a token for cloudinary when we try to upload assets.
 * - We are allowing for it to be cached for 10 minutes
 */
export type TCloudinaryTokenParams = {
  access_control: string;
  context: string;
  type: string;
};
export const useMutationSignedToken = () => {
  return useMutation(
    async (params: TCloudinaryTokenParams) => {
      const response = await APIS.cloudinary.getAuthToken(params);

      if (response.err) {
        throw new Error(response);
      }

      return {...response?.data};
    },
    {
      onError: response => {
        logger('Cloudinary token: ')(response as Error);
      },
    }
  );
};
