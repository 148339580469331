import React from 'react';
import {Navigate} from 'react-router-dom';
import PrivatePageLayout from 'components/Layouts/PrivatePageLayout';
import {authToken} from 'utils/auth';
import {useLoadSplitio} from 'features/Splitio/useLoadSplitio';

/**
 * This is just a wrapper so we can do auth on the route proper.
 * If you need a private layout we can pass it in as a "layout" or just add if they all need it;
 * ie: <PrivatePageLayout><Component {...props} /></PrivatePageLayout>
 */
const PrivateRoute = ({children}: {children: React.ReactNode}) => {
  const {pathname = '', search = ''} = window.location;
  const redirect = `${pathname}${search}`;
  const postPendLoginRedirect = redirect ? `?redir=${redirect}` : '';

  useLoadSplitio();

  const authorized = authToken.isValidJWT();

  return authorized ? <PrivatePageLayout>{children}</PrivatePageLayout> : <Navigate to={`/login${postPendLoginRedirect}`} replace />;
};

export default PrivateRoute;
