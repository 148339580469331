import React from 'react';
import {Icon} from 'ht-styleguide';
import {BaseAnyFunction} from 'types/base.types';
import styles from './styles.scss';

const NotificationCloseIcon = ({closeToast}: {closeToast?: BaseAnyFunction}) => {
  if (!closeToast) return null;

  return <Icon name="v2-close-icon" className={styles.htToastClose} onClick={closeToast} />;
};

export default React.memo(NotificationCloseIcon);
