import React from 'react';
import {Helmet} from 'react-helmet';

const Meta = () => (
  <Helmet
    defaultTitle="Admin Dashboard | HelloTech"
    titleTemplate="%s | HelloTech"
  >
    <meta name="description" content="HelloTech Admin Application" />
    <meta name="robots" content="noindex" />
  </Helmet>
);

export default React.memo(Meta);
