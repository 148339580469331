import {useMemo} from 'react';
import {useMatch, useNavigate} from 'react-router-dom';
import {Routes} from 'global/routes/routes';
import {ISidebarEntryGroup} from 'components/Layouts/AdminHub/Sidebar/Sidebar.types';
import {issuesNavTypePath} from 'global/paths';
import {IssuesNavTypes, TIssuesUseParams} from 'features/Issues/issues.types';
import {ISSUES_PAGE_PARAMS} from '../Issues.constants';
import useGetIssuesStatsQuery from '../queries/query.issues.stats';

const useIssuesSidebarMenu = () => {
  const navigate = useNavigate();

  // Data for badges
  const {data: openData} = useGetIssuesStatsQuery({filters: ISSUES_PAGE_PARAMS[IssuesNavTypes.OPEN].statsFilters});
  const {data: ownData} = useGetIssuesStatsQuery({filters: ISSUES_PAGE_PARAMS[IssuesNavTypes.OWN].statsFilters});

  /**
   * Since the sidebar is rendered outside of the issues route structure, we need a different way
   * to get `issueNavType` instead of using `useIssuesRouteParams`.
   */
  const match = useMatch(Routes.ISSUES_NAV_TYPE());
  const {issuesNavType} = match?.params || ({} as TIssuesUseParams);

  const menu = useMemo<ISidebarEntryGroup[]>(() => {
    const onClick = (navTypePath: IssuesNavTypes) => () => {
      navigate(issuesNavTypePath(navTypePath));
    };

    const openCount = openData?.open || 0;
    const assignedToMeCount = ownData?.open || 0;

    return [
      {
        entries: [
          {
            key: IssuesNavTypes.OPEN,
            label: 'Open Issues',
            active: issuesNavType === IssuesNavTypes.OPEN,
            icon: 'grid',
            onClick: onClick(IssuesNavTypes.OPEN),
            badge: {
              value: openCount,
            },
          },
          {
            key: IssuesNavTypes.OWN,
            label: 'Assigned to Me',
            active: issuesNavType === IssuesNavTypes.OWN,
            icon: 'star-outline',
            onClick: onClick(IssuesNavTypes.OWN),
            badge: {
              value: assignedToMeCount,
            },
          },
          {
            key: IssuesNavTypes.DONE,
            label: 'Done',
            active: issuesNavType === IssuesNavTypes.DONE,
            icon: 'round-check-fill',
            onClick: onClick(IssuesNavTypes.DONE),
          },
        ],
      },
    ];
  }, [navigate, issuesNavType, openData?.open, ownData?.open]);

  return {
    menu,
  };
};

export default useIssuesSidebarMenu;
