import {TUpsertTaskReference, ReferenceTypes} from 'features/Products/types/taskGroups.types';

export const DEFAULT_REFERENCE_OBJ: TUpsertTaskReference = {
  reference_type: ReferenceTypes.File,
  skill_guide_id: null,
  title: '',
  url: '',
  attachments: [],
};

export const REFERENCE_TYPE_OPTS = [
  {
    value: ReferenceTypes.FromSkill,
    label: 'From Skill',
  },
  {
    value: ReferenceTypes.Link,
    label: 'Link',
  },
  {
    value: ReferenceTypes.File,
    label: 'Photo, Video, or PDF',
  },
];
