import {useEffect, useRef, useState} from 'react';
import {draggable, dropTargetForElements, monitorForElements} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import {attachClosestEdge, extractClosestEdge, Edge} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import {combine} from '@atlaskit/pragmatic-drag-and-drop/combine';
import {TTaskGroup, TTask} from 'features/Products/types/taskGroups.types';
import {TReorderTaskFunction, TReorderGroupFunction} from '../taskChecklist.types';

const DND_ITEM_TYPE = {
  GROUP: 'group',
  TASK: 'task',
};

/**
 * Monitors the drag and drop events for task groups and tasks.
 * This is where the reordering of tasks and task groups are handled.
 */
export const useMonitorDragAndDropElements = ({reorderTask, reorderGroup}: {reorderTask: TReorderTaskFunction; reorderGroup: TReorderGroupFunction}) => {
  useEffect(() => {
    return monitorForElements({
      onDrop: ({location, source}) => {
        // If the dropped item is a group, then reorder the group.
        if (source.data.type === DND_ITEM_TYPE.GROUP) {
          const dropTarget = location.current.dropTargets.find(t => t.data.type === DND_ITEM_TYPE.GROUP);
          if (!dropTarget) {
            return;
          }
          // The group that was dropped
          const droppedGroupId = source.data.groupId as number;

          // The group that the dragged group is being dropped on
          const dropTargetGroupId = dropTarget.data.groupId as number;

          // If dropped before or after the target group
          const closestEdgeOfTarget = extractClosestEdge(dropTarget.data);
          const isBefore = closestEdgeOfTarget === 'top';

          reorderGroup({groupIdToMove: droppedGroupId, targetGroupId: dropTargetGroupId, isBefore});
          return;
        }

        // If the dragged item is a task, then reorder the task.
        if (source.data.type === DND_ITEM_TYPE.TASK) {
          const dropTarget = location.current.dropTargets?.[0];
          if (!dropTarget) {
            return;
          }
          // The task that was dropped
          const droppedTaskId = source.data.taskId as number;
          const droppedTaskGroupId = source.data.groupId as number;

          // The element that the dragged task is being dropped on
          // The element can be a task or the group name. If it's the group name, then the target task id will be null.
          const dropTargetTaskId = dropTarget.data.taskId as number | null;
          const dropTargetGroupId = dropTarget.data.groupId as number;

          // If dropped before or after the target elem
          const closestEdgeOfTarget = extractClosestEdge(dropTarget.data);
          const isBefore = closestEdgeOfTarget === 'top';

          reorderTask({
            taskIdToMove: droppedTaskId,
            taskGroupIdToMove: droppedTaskGroupId,
            targetTaskId: dropTargetTaskId,
            targetTaskGroupId: dropTargetGroupId,
            isBefore,
          });
        }
      },
    });
  }, [reorderGroup, reorderTask]);
};

/**
 * Handles the drag and drop events for the task group.
 * - This will listen to events within the entire group element: the group name and the task list.
 * - Pass necessary data for `useMonitorDragAndDropElements` to handle the reordering of task groups.
 * - Determine the location of the "DropIndicator" when an item is dragged over the entire group element.
 * - A draggable element can be a group or a task item.
 * - A drop target can be the group name (since it's not covered by a task item) or a task item.
 */
export const useGroupItemDnDHandler = ({canEditOrDeleteItem, showGroupName, group}: {canEditOrDeleteItem: boolean; group: TTaskGroup; showGroupName: boolean}) => {
  const groupItemRef = useRef(null);
  const groupItemDragHandleRef = useRef(null);
  // Whether the dragged task/group is hovering above ("top" edge) or below ("bottom" edge) the target group.
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);

  useEffect(() => {
    const groupItemElem = groupItemRef.current;
    const groupItemDragHandleElem = groupItemDragHandleRef.current;

    if (groupItemDragHandleElem && groupItemElem) {
      return combine(
        draggable({
          element: groupItemElem,
          dragHandle: groupItemDragHandleElem,
          canDrag: () => canEditOrDeleteItem && showGroupName,
          getInitialData: () => ({type: DND_ITEM_TYPE.GROUP, groupId: group.id}),
        }),
        dropTargetForElements({
          element: groupItemElem,
          canDrop: () => canEditOrDeleteItem && showGroupName,
          getData: ({input, element}) => {
            const data = {type: DND_ITEM_TYPE.GROUP, groupId: group.id};
            return attachClosestEdge(data, {
              input,
              element,
              allowedEdges: ['top', 'bottom'],
            });
          },
          onDrag: ({self, source, location}) => {
            const targetData = self.data;
            const draggedElementData = source.data;
            if (targetData.type === DND_ITEM_TYPE.GROUP && draggedElementData.type === DND_ITEM_TYPE.GROUP) {
              const closestEdgeOfTarget = extractClosestEdge(self.data);
              setClosestEdge(closestEdgeOfTarget);
            }
            if (targetData.type === DND_ITEM_TYPE.GROUP && draggedElementData.type === DND_ITEM_TYPE.TASK) {
              // Only trigger if the dragged task is hovering above only the group name and not somewhere in the task list.
              // We can check this by seeing if there's only one drop target and if it's only of type `group`.
              const currentDropTargets = location.current.dropTargets;
              if (currentDropTargets.length === 1 && currentDropTargets[0].data.type === DND_ITEM_TYPE.GROUP) {
                const closestEdgeOfTarget = extractClosestEdge(self.data);
                setClosestEdge(closestEdgeOfTarget);
              }
            }
          },
          onDropTargetChange: ({location, source}) => {
            const draggedElementData = source.data;
            if (draggedElementData.type === DND_ITEM_TYPE.TASK) {
              const firstCurrentDropTargets = location.current.dropTargets?.[0];
              if (firstCurrentDropTargets && firstCurrentDropTargets.data.type === DND_ITEM_TYPE.TASK) {
                setClosestEdge(null);
              }
            }
          },
          onDragLeave: () => setClosestEdge(null),
          onDrop: () => setClosestEdge(null),
        })
      );
    }
    return () => {};
  }, [canEditOrDeleteItem, group, showGroupName]);

  return {
    groupItemRef,
    groupItemDragHandleRef,
    closestEdge,
  };
};

/**
 * Handles the drag and drop events for a task item.
 * - Pass necessary data for `useMonitorDragAndDropElements` to handle the reordering of tasks.
 * - Determine the location of the "DropIndicator" when an item is dragged over a task item.
 * - A task item can be a draggable element and can be a target to be dropped on.
 * - In regards to listening to the drag and drop events, we'll only care about a task item being dropped on
 *   a task item. `useTaskItemDnDHandler` will handle the events when the task item is dropped on a group name, if applicable.
 */
export const useTaskItemDnDHandler = ({canEditOrDeleteItem, groupId, task}: {canEditOrDeleteItem: boolean; groupId: number; task: TTask}) => {
  const taskItemRef = useRef(null);
  const taskItemDragHandleRef = useRef(null);
  // Whether the dragged task is hovering above ("top" edge) or below ("bottom" edge) the target task.
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);

  useEffect(() => {
    const taskItemElem = taskItemRef.current;
    const taskItemDragHandleElem = taskItemDragHandleRef.current;
    if (taskItemDragHandleElem && taskItemElem) {
      return combine(
        draggable({
          element: taskItemElem,
          dragHandle: taskItemDragHandleElem,
          canDrag: () => canEditOrDeleteItem,
          getInitialData: () => ({type: DND_ITEM_TYPE.TASK, taskId: task.id, groupId}),
        }),
        dropTargetForElements({
          element: taskItemElem,
          canDrop: () => canEditOrDeleteItem,
          getData: ({input, element}) => {
            const data = {type: DND_ITEM_TYPE.TASK, taskId: task.id, groupId};
            return attachClosestEdge(data, {
              input,
              element,
              allowedEdges: ['top', 'bottom'],
            });
          },
          onDrag: ({self, source}) => {
            const targetData = self.data;
            const draggedElementData = source.data;
            if (targetData.type === DND_ITEM_TYPE.TASK && draggedElementData.type === DND_ITEM_TYPE.TASK) {
              const closestEdgeOfTarget = extractClosestEdge(self.data);
              setClosestEdge(closestEdgeOfTarget);
            }
          },
          onDragLeave: () => setClosestEdge(null),
          onDrop: () => setClosestEdge(null),
        })
      );
    }
    return () => {};
  }, [canEditOrDeleteItem, groupId, task]);

  return {
    taskItemRef,
    taskItemDragHandleRef,
    closestEdge,
  };
};

export const useMouseInState = () => {
  const [mouseIn, setMouseIn] = useState(false);
  const onMouseEnter = () => setMouseIn(true);
  const onMouseLeave = () => setMouseIn(false);
  return {mouseIn, onMouseEnter, onMouseLeave};
};
