import {useEffect, useRef, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {TUseHeaderWithToolbar} from './HeaderWithToolbar.types';

const useHeaderWithToolbar = ({scrollPosition = 0, headerText = '', onSubmitCb, contentRef}: TUseHeaderWithToolbar) => {
  const [topElementHeight, setTopElementHeight] = useState(0);
  const [bottomElementMetrics, setBottomElementMetrics] = useState({height: 0, paddingBottom: 0});

  const topElementRef = useRef<HTMLDivElement>(null);
  const bottomElementRef = useRef<HTMLDivElement>(null);

  /** Eyeballed value to trigger animation */
  const collapseTriggerThreshold = 8;
  const isCollapsed = scrollPosition === 0 ? false : scrollPosition > bottomElementMetrics.height - bottomElementMetrics.paddingBottom - collapseTriggerThreshold;

  const translateY = Math.max(-topElementHeight, -scrollPosition);

  useEffect(() => {
    /**
     * With the previous implementation, the header will glitch at particular scroll positions at particular
     * the content heights and scrolling container heights. A loop in the logic will occur and the header will jump up and down.
     * Here, we are adding padding to the top of the content to replace the space that the `bottomElement` occupies.
     */
    if (contentRef?.current) {
      const padding = Math.min(bottomElementMetrics.height, scrollPosition);
      contentRef.current.style.paddingTop = `${padding}px`;
    }
  }, [scrollPosition, contentRef, bottomElementMetrics.height]);

  // Form validation
  const validationSchema = Yup.object().shape({
    headerText: Yup.string().required('Please enter a title'),
  });

  const formik = useFormik({
    initialValues: {
      headerText,
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: values => {
      if (onSubmitCb) {
        onSubmitCb(values);
      }
    },
  });

  useEffect(() => {
    if (topElementRef.current) {
      setTopElementHeight(topElementRef.current.offsetHeight);
    }
    if (bottomElementRef.current) {
      const style = window.getComputedStyle(bottomElementRef.current);
      const paddingBottom = parseFloat(style.paddingBottom);
      const height = bottomElementRef.current.offsetHeight;
      setBottomElementMetrics({height, paddingBottom});
    }
  }, [formik?.values.headerText]);

  return {
    /** Determines whether the element is collapsed based on the scroll position and the bottom element's metrics */
    isCollapsed,
    /** translateY applied to bottomElement  based on scrollPosition */
    translateY,
    /** topElementHeight is the height of the top element */
    topElementHeight,
    /** bottomElementMetrics is the height and paddingBottom of the bottom element */
    bottomElementMetrics,
    /** topElementRef is the ref of the top element */
    topElementRef,
    /** bottomElementRef is the ref of the bottom element */
    bottomElementRef,
    formik,
  };
};

export default useHeaderWithToolbar;
