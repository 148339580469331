// ComponentAction.tsx
import React from 'react';
import cn from 'classnames';
import {Tooltip} from 'ht-styleguide';

import {TActionBaseOmitType, TActionComponentProps, TBaseAction} from './actionGroup.types';

import styles from './styles.scss';

const ComponentAction = ({Component, tooltipContent, dataTestId}: TBaseAction & TActionBaseOmitType<TActionComponentProps>) => {
  const actionComponentClass = cn(styles.actionGroup, styles.componentAction);

  /* No tooltip info, do not show tooltip */
  if (!tooltipContent) {
    return (
      <div className={actionComponentClass} data-testid={dataTestId}>
        <Component />
      </div>
    );
  }

  return (
    <Tooltip content={tooltipContent}>
      <div className={actionComponentClass} data-testid={dataTestId}>
        <Component />
      </div>
    </Tooltip>
  );
};

export default ComponentAction;
