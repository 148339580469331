import React, {useEffect} from 'react';
import {Route, useParams, Routes, Navigate, Outlet} from 'react-router-dom';
// Hooks
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useSelector} from 'hooks/useAppSelector';
import {useCurrentProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/CurrentProject.hooks';

// Constants
import {MDU_ROUTES} from 'features/MultiDwellingUnits/MDU.constants';

// Ducks && Types
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {CurrentProjectStages, MduUseParamsTypes} from 'features/MultiDwellingUnits/MDU.types';
import uiSlice from 'features/ui/ui.ducks';

// Components
import ProjectPageStatusBar from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/ProjectPageStatusBar';
import MDUDeleteConfirmModal from 'features/MultiDwellingUnits/Parts/Modals/MDU.Modal.Delete';
import MDUApproveActionConfirmModal from 'features/MultiDwellingUnits/Parts/Modals/MDU.Modal.Approve';
import MDUReopenActionConfirmModal from 'features/MultiDwellingUnits/Parts/Modals/MDU.Modal.Reopen';
import MDUReasonsModal from 'features/MultiDwellingUnits/Parts/Modals/MDU.Modal.Reasons';
import MDUPauseProjectModal from 'features/MultiDwellingUnits/Parts/Modals/MDU.Modal.PauseProject';
import MDULinkIssueToPausedProjectModal from 'features/MultiDwellingUnits/Parts/Modals/MDU.Modal.LinkIssueToPausedProject';
import MDUResumeProjectModal from 'features/MultiDwellingUnits/Parts/Modals/MDU.Modal.ResumeProject';
import PauseHistorySideSheet from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/PauseHistorySideSheet';
import MDUGrid from 'components/Grid';
import Sidebar from 'components/Layouts/AdminHub/Sidebar';
import TopNav from 'features/MultiDwellingUnits/Parts/TopNav';
import AdminHubLayout from 'components/Layouts/AdminHub/AdminHub.layout';
import {NavigationItems} from 'features/MultiDwellingUnits/Pages/CurrentProject/CurrentProject.constants';
import {CurrentProjectDetailsEditPage, CurrentProjectDetailsReadOnlyPage} from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/ProjectDetails';
import LaunchChecklistPage from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/LaunchChecklist';
import LaunchReviewPage from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/LaunchReview';
import PaymentAdjustmentPage from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/PaymentAdjustment';
import TeamManagementPage from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TeamManagement';
import JobsPage from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/Jobs';
import NotesPage from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/Notes';
import FilesPage from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/Files';
import ProjectOverviewPage from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/ProjectOverview';
import TemplatesJobsTemplates from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/TemplateJobs.Templates';
import TemplatesJobsServices from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/TemplateJobs.Services';
import TemplatesJobsCreateNewTemplates from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/PostLaunch/TemplateJobs.PostLaunch.Create';
import CreateBulkJobs from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/Jobs/AddJobs/Multi/Jobs.Create.Multiple';
import CreatingBulkNewTemplates from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/Jobs/AddJobs/Multi/Jobs.Create.Multiple.New';
import JobsCreateProcessing from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/Jobs/AddJobs/Multi/Jobs.Create.Processing';
import Issues from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/Issues';
import {SearchInput, SearchResults} from 'features/MultiDwellingUnits/Parts/Search';

const MDUCurrentProjectRoutesLayout = () => {
  /* Redux State */
  const projectSearchIsVisible = useSelector(uiSlice.selectors.getSearchState).projects.visible;

  /* Hooks */
  const dispatch = useAppDispatch();
  const {projectId = '', stage} = useParams<{projectId: string; stage: CurrentProjectStages}>();
  const currentProject = useCurrentProject();

  /* Toggle Search Input */
  const setIsSearchActive = () => dispatch(uiSlice.actions.setSearchVisibility({search: 'projects'}));

  const isInLaunchReviewStage = stage === CurrentProjectStages.LAUNCH_REVIEW;

  const sidebarNavItems = NavigationItems({project: currentProject, stage: stage ?? ('' as CurrentProjectStages)});

  /* Effects */
  const isCurrentProjectStale = String(currentProject?.id) !== projectId;
  useEffect(() => {
    if (isCurrentProjectStale) {
      dispatch(mduProjectsSlice.actions.fetchProjectDetails({projectId}));
    }
  }, [dispatch, isCurrentProjectStale, projectId]);

  /* If we move across stages, we should close search if its open */
  useEffect(() => {
    dispatch(uiSlice.actions.setSearchVisibility({search: 'projects', visible: false}));
  }, [dispatch, stage]);

  if (isCurrentProjectStale) return null;

  return (
    <AdminHubLayout>
      <TopNav />
      {projectSearchIsVisible && (
        <>
          <SearchInput onSearchClose={setIsSearchActive} />
          <SearchResults toggleSearch={setIsSearchActive} />
        </>
      )}
      {isInLaunchReviewStage && <Outlet />}
      {!projectSearchIsVisible && !isInLaunchReviewStage && (
        <AdminHubLayout.ContentTwoColumnContainer>
          <AdminHubLayout.ContentLeftContainer>
            <Sidebar {...sidebarNavItems} isCollapsible />
          </AdminHubLayout.ContentLeftContainer>
          <AdminHubLayout.ContentRightContainer>
            <ProjectPageStatusBar />
            <MDUGrid.Fluid>
              <Outlet />
            </MDUGrid.Fluid>
          </AdminHubLayout.ContentRightContainer>
        </AdminHubLayout.ContentTwoColumnContainer>
      )}
      <MDUDeleteConfirmModal />
      <MDUApproveActionConfirmModal />
      <MDUReopenActionConfirmModal />
      <MDUReasonsModal />
      <MDUPauseProjectModal isSingleProjectView />
      <MDULinkIssueToPausedProjectModal />
      <MDUResumeProjectModal isSingleProjectView />
      <PauseHistorySideSheet />
    </AdminHubLayout>
  );
};

/**
 * Routes for templates.
 * `/mdu/projects/:projectId/templates/*
 */
const TemplatesRoutes = () => (
  <Routes>
    {/* --- template's content summary --- */}
    <Route path=":groupId/sku/:skuId?/service?/:serviceId?/:mode?" element={<TemplatesJobsServices />} />
    <Route path=":groupId?" element={<TemplatesJobsTemplates />} />
    {/* --- specific to post launch template/job creations */}
    <Route path="postlaunch/:groupId/sku/:skuId?" element={<TemplatesJobsCreateNewTemplates />} />
    <Route path="postlaunch/:groupId/sku/:skuId/service/:serviceId?/:mode?" element={<TemplatesJobsCreateNewTemplates />} />
  </Routes>
);

/**
 * `/mdu/projects/:projectId/jobs/:createType/:createTemplateType/*`
 */
const CreateTemplateTypeRoutes = () => {
  const {createTemplateType} = useParams<MduUseParamsTypes>();

  switch (createTemplateType) {
    // `mdu/projects/:projectId/jobs/bulk-create/<existing-templates|draft>/`
    case 'existing-templates':
    case 'draft':
      return <CreateBulkJobs />;
    // `mdu/projects/:projectId/jobs/bulk-create/new-templates/`
    case 'new-templates':
      return (
        <Routes>
          {[':groupId/sku/:skuId/service/:serviceId?/:mode', ':groupId/sku/:skuId/:mode', ':groupId/sku/:skuId?', ':groupId/sku'].map(path => (
            <Route key={path} path={path} element={<CreatingBulkNewTemplates />} />
          ))}
        </Routes>
      );
    default:
      return null;
  }
};

/**
 * `/mdu/projects/:projectId/jobs/:createType/*`
 */
const JobsCreateTypeRoutes = () => {
  const {createType} = useParams<MduUseParamsTypes>();

  switch (createType) {
    /**
     * ----------------------------------------------
     * -------- JOBS PAGE: MULTI create jobs --------
     * ----------------------------------------------
     */
    case 'bulk-create':
      // `mdu/projects/:projectId/jobs/bulk-create/`
      return (
        <Routes>
          <Route index element={<CreateBulkJobs />} />
          <Route path=":createTemplateType/*" element={<CreateTemplateTypeRoutes />} />
          <Route path="processing" element={<JobsCreateProcessing />} />
        </Routes>
      );
    /**
     * ----------------------------------------------
     * ----- JOBS PAGE: SINGLE create Jobs Page -----
     * ----------------------------------------------
     */
    case 'create':
      // `mdu/projects/:projectId/jobs/create/`
      return (
        <Routes>
          <Route index element={<JobsPage />} />
          {['/sku/:skuId/service/:serviceId/:mode', '/sku/:skuId/:mode', '/sku/:skuId?', '/success', '/sku'].map(path => (
            <Route key={path} path={path} element={<JobsPage />} />
          ))}
        </Routes>
      );
    default:
      return null;
  }
};

/**
 * `/mdu/projects/:projectId/jobs/*`
 */
const JobsRoutes = () => (
  <Routes>
    {/* ---  JOBS PAGE DEFAULT ----- */}
    <Route index element={<JobsPage />} />
    <Route path=":createType/*" element={<JobsCreateTypeRoutes />} />
  </Routes>
);

/**
 * Determine pages to show or extend routes based on the stage in `/mdu/projects/:projectId/:stage`
 */
const StagePages = () => {
  const {stage, projectId} = useParams<{stage: CurrentProjectStages; projectId: string}>();

  switch (stage) {
    case CurrentProjectStages.PAYMENT:
      return <PaymentAdjustmentPage />;
    case CurrentProjectStages.NOTES:
      return <NotesPage />;
    case CurrentProjectStages.FILES:
      return <FilesPage />;
    case CurrentProjectStages.PROJECT_OVERVIEW:
      return <ProjectOverviewPage />;
    case CurrentProjectStages.LAUNCH_REVIEW:
      return <LaunchReviewPage />;
    case CurrentProjectStages.CHECKLIST:
      return <LaunchChecklistPage />;
    case CurrentProjectStages.DETAILS:
      return <CurrentProjectDetailsReadOnlyPage />;
    case CurrentProjectStages.EDIT:
      return <CurrentProjectDetailsEditPage />;
    case CurrentProjectStages.TEAM_MANAGEMENT:
      return <TeamManagementPage />;
    case CurrentProjectStages.ISSUES:
      return <Issues />;
    case CurrentProjectStages.TEMPLATES:
      return <TemplatesRoutes />;
    case CurrentProjectStages.JOBS:
      return <JobsRoutes />;
    default:
      return <Navigate to={`${MDU_ROUTES.MDUPathProjectBase}/${projectId}/${CurrentProjectStages.DETAILS}`} replace />;
  }
};

const MDUCurrentProjectRoutesStructure = () => (
  <Routes>
    <Route element={<MDUCurrentProjectRoutesLayout />}>
      <Route index element={<StagePages />} />
      <Route path=":stage/*" element={<StagePages />} />
    </Route>
  </Routes>
);

export default MDUCurrentProjectRoutesStructure;
