import {useMutation, useQueryClient} from 'react-query';
import APIS from 'global/apis';
import {logger} from 'utils/logger';
import {CLOUDINARY_QUERY_KEYS} from '../queries/cloudinary.query.keys';

export const useGetTransformMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<any, unknown, string[]>(
    async (paths: string[]): Promise<any> => {
      const cachedResponse = queryClient.getQueryData(CLOUDINARY_QUERY_KEYS.tranformSignedUrls(paths));
      if (cachedResponse) {
        return cachedResponse;
      }
      const response = await APIS.cloudinary.getFileAssets({assets_paths: paths});

      if (response.err) {
        throw new Error(response.err);
      }

      /* We are assuming a single transform at one time. We can update to multi */
      return response.data?.signed_urls[0];
    },
    {
      onSuccess: (response, paths) => {
        queryClient.setQueryData(CLOUDINARY_QUERY_KEYS.tranformSignedUrls(paths), response);
      },
      onError: response => {
        logger('useCloudinary Transform')(response as Error);
      },
    }
  );
};
