import React, {ReactNode, memo} from 'react';
import cn from 'classnames';
import {Button} from 'ht-styleguide';
import styles from './tableToolbar.styles.scss';

export type TTableToolbarLink = {
  onClick: BaseAnyFunction;
  className?: string;
  iconName: string;
  label: ReactNode;
};

const TableToolbarLink = ({onClick, className, iconName, label}: TTableToolbarLink) => (
  <Button onClick={onClick} icon={iconName} theme="secondary" small inlineBlock className={cn(styles.tableToolbarLink, className)}>
    {label}
  </Button>
);

export default memo(TableToolbarLink);
