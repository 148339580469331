import React from 'react';

// Components
import StatBlock from 'features/Profile/components/StatBlock';

// Types
import {Stat} from 'features/Profile/profile.types';

// Styles
import styles from 'features/Profile/profile.styles.scss';

interface StatsSectionProps {
  stats: Stat;
}

const StatsSection = ({stats}: StatsSectionProps) => {
  return (
    <div className={styles.statsSection}>
      {stats &&
        Object.keys(stats).map(s => {
          const value = stats[s as keyof Stat];
          return <StatBlock key={s} stat={s} value={value} />;
        })}
    </div>
  );
};

export default StatsSection;
