import {useState} from 'react';

const useModalToggle = (): {isVisible: boolean; toggle: (event?: any) => void} => {
  const [isVisible, setIsVisible] = useState(false);

  function toggle(event?: any): void {
    event?.preventDefault?.();
    setIsVisible(!isVisible);
  }

  return {
    isVisible,
    toggle,
  };
};

export default useModalToggle;
