import React, {useMemo, ComponentProps} from 'react';
import {TSharedFormFieldProps} from '../../dataFormFields.types';
import BaseFilterSelectField from '../../BaseFilter/SelectField';
import useGetUsersByTypeQuery from '../queries/query.dataSelect.getUsersByType';
import {HELLOTECH_PARTNER_ID, HELLOTECH_PARTNER_LABEL} from '../dataSelect.constants';

type TUserSelect = TSharedFormFieldProps & {
  htPartnerLabel?: string;
  /**
   * Return only fulfillment agents.
   * Param only applies when `types` includes 'admin'. Otherwise, no users will return.
   */
  onlyFulfillmentAgents?: boolean;
  /**
   * As needed by BE
   */
  userType?: Array<'admin' | 'tech' | 'geek' | 'client' | 'partner' | 'recruit' | 'user'>;
} & Partial<ComponentProps<typeof BaseFilterSelectField>>;

const UserSelect = ({dataTestId = 'DataSelect-UserSelect', fieldName, formik, htPartnerLabel, onChangeCB, onlyFulfillmentAgents = false, label, userType = ['admin'], ...rest}: TUserSelect) => {
  const {data, isFetching} = useGetUsersByTypeQuery({userType, extraParams: {only_fulfillment_agents: onlyFulfillmentAgents}});

  const usersOpts = useMemo(() => {
    const baseUserOpts = (data?.users || []).map(user => ({label: user.name, value: user.id}));
    if (userType.includes('partner')) {
      baseUserOpts.unshift({label: htPartnerLabel || HELLOTECH_PARTNER_LABEL, value: HELLOTECH_PARTNER_ID});
    }
    return baseUserOpts;
  }, [data?.users, htPartnerLabel, userType]);

  const disabled = isFetching;

  return <BaseFilterSelectField dataTestId={dataTestId} isDisabled={disabled} label={label} onChangeCB={onChangeCB} options={usersOpts} formik={formik} fieldName={fieldName} {...rest} />;
};

export default UserSelect;
