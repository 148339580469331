import {useDispatch} from 'react-redux';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';

import APIS from 'global/apis';
import {logger} from 'utils/logger';

import {showErrorToast} from 'queries/query.utils';
import {MduUseParamsTypes, NavTypes, NavPageTypes, MduGetProjectsStatsResponse} from '../MDU.types';
import {IProjectPageParams, PROJECT_PAGE_PARAMS} from '../Pages/Projects/Projects.constants';
import {normalizeFiltersToParams} from '../MDU.ducks';
import {MDU_PROJECT_QUERY_KEYS} from '../MDU.query.keys';

/**
 * Get Projects Stats Query
 */
export const useGetProjectsStats = () => {
  const {projectsType, projectsPage} = useParams<MduUseParamsTypes>();
  const dispatch = useDispatch();
  const myProjects = projectsType === NavTypes.OWN;
  const additionalStatsParams = (PROJECT_PAGE_PARAMS as IProjectPageParams)[projectsPage!]?.statsFilters || {};
  const params = normalizeFiltersToParams({myProjects, ...additionalStatsParams});

  return useQuery(
    MDU_PROJECT_QUERY_KEYS.stats([projectsType!, projectsPage!]),
    async () => {
      let response: MduGetProjectsStatsResponse;

      if (projectsPage !== NavPageTypes.ALL_STATUSES) {
        response = await APIS.mdu.getProjectsStats(params);
      } else {
        /**
         * Since `paused` denotation is a flag in the BE and the FE is treating it as a status, we'll need
         * to do some data merging when presenting stats on the `All Statuses` view.
         *
         * `All Statuses` widget - should contain paused and non-paused orders
         * All other widgets - should contain non-paused orders
         */
        const allStatusesParams = normalizeFiltersToParams({myProjects, ...additionalStatsParams, paused: ''});
        const [otherStatusesResponse, allStatusResponse]: Array<MduGetProjectsStatsResponse> = await Promise.all([APIS.mdu.getProjectsStats(params), APIS.mdu.getProjectsStats(allStatusesParams)]);

        const otherStatusesStatsData = otherStatusesResponse.data.stats;
        const allStatusResponseStatsData = allStatusResponse.data?.stats;

        response = {
          ...otherStatusesResponse,
          data: {
            stats: {
              ...otherStatusesStatsData,
              total: allStatusResponseStatsData?.total ?? otherStatusesStatsData.total,
            },
            progress: otherStatusesResponse.data.progress,
          },
        };
      }

      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Retrieving Stats'});
        throw new Error(response.data?.errors?.[0]);
      }

      return response.data;
    },
    {
      onError: response => {
        logger('Projects Stats: ')(response as Error);
      },
    }
  );
};
