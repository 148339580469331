import React from 'react';
import {IBaseTask} from '../../serviceTasksList.types';
import NoInfoCalloutBox from './NoInfoCalloutBox';
import BlockWrapper from './BlockWrapper';
import ServiceTaskAttachmentField from './ServiceTaskAttachmentField';

const PHOTOS_BLOCK_NAMESPACE = 'taskItemPhotosBlock';

/**
 * For the photo task type, display the photos attached to the task.
 */
const PhotosBlock = ({serviceId, task}: IBaseTask) => {
  const attachments = task?.answer?.attachments || [];
  const hasAttachments = attachments.length > 0;

  return hasAttachments ? (
    <BlockWrapper title="Photos">
      <ServiceTaskAttachmentField serviceId={serviceId} namespace={PHOTOS_BLOCK_NAMESPACE} task={task} />
    </BlockWrapper>
  ) : (
    <NoInfoCalloutBox />
  );
};

export default PhotosBlock;
