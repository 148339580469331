import {useEffect} from 'react';
import {useQuery} from 'react-query';
import useApi from 'hooks/useApi';
import {useSelector} from 'hooks/useAppSelector';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {showErrorToast, logResponseError, stringifyQueryParams} from 'queries/query.utils';
import useIssuesRouteParams from '../hooks/useIssuesRouteParams';
import issuesSlice from '../Issues.ducks';
import {TGetIssuesQueryResponse, SortType} from '../issues.types';
import {ISSUES_QUERY_KEYS} from './Issues.query.keys';
import useIssuesTableSearchFieldsQuery from './query.issues.table.searchFields';

/**
 * Used to fetch issues for a given entity, filters, and pagination. Only used for the issues table view.
 *
 * https://hellotech.atlassian.net/wiki/spaces/ENGINEERIN/pages/1670905857/API+Docs+with+dynamic+fields+-+Issue+Ticketing+System#List%2Fsearch-tickets
 */
const useGetTableIssuesQuery = () => {
  const api = useApi();
  const dispatch = useAppDispatch();
  const {issuesNavType, pageFilters, issuesEntity, issuesEntityId} = useIssuesRouteParams();
  const {filters, columnSort, pagination: paginationState} = useSelector(issuesSlice.selectors.getPagesStateByKey(issuesNavType)) || {};
  const {data: searchFieldsData = []} = useIssuesTableSearchFieldsQuery();

  // Format for query params
  const normalizeToParams = () => {
    /**
     * Sort params
     * - BE expects query key `order` as an array of objects
     * - "Grouping" on the table is basically sorting. If there's grouping, the grouped key will should be the first item in the array
     */
    const formatColumnSort = columnSort
      ?.map(sort => {
        // BE expects different value for sorting. We're going to use the `sort` key
        const sortKey = searchFieldsData?.find((field: any) => field.name === sort.id)?.sort;
        return sortKey
          ? {
              by: sortKey,
              sort: sort.desc ? SortType.DESC : SortType.ASC,
            }
          : null;
      })
      .filter(Boolean);

    const sortParam = formatColumnSort?.length ? {order: formatColumnSort} : {};

    /**
     * Pagination params
     */
    const paginationParams = {
      per_page: paginationState.items_per_page,
      page: paginationState.current_page,
    };

    /**
     * Entity id from single entity view, not filters
     */
    const entityIdParm = issuesEntityId ? {entity_id: issuesEntityId} : {};

    /**
     * Merge params for query
     */
    const params = {...pageFilters, entity_type: issuesEntity, ...entityIdParm, ...sortParam, ...filters, pagination: paginationParams};

    return stringifyQueryParams(params);
  };

  const urlSearchQuery = normalizeToParams();
  const queryKeys = [issuesNavType, urlSearchQuery];

  const {data} = useQuery(ISSUES_QUERY_KEYS.getIssues(queryKeys), async () => {
    api.toggleLoader(true);
    const response: TGetIssuesQueryResponse = await api.issues.getTickets({search: urlSearchQuery});
    api.toggleLoader(false);

    if (response.err) {
      showErrorToast(dispatch)({errors: 'Error Fetching Issues'});
      logResponseError('Issues list for table')(response.err);
    }
    return response?.data;
  });

  const {tickets = [], pagination = null} = data || {};

  useEffect(() => {
    if (pagination) {
      dispatch(issuesSlice.actions.updatePagination({issuesNavType, pagination}));
    }
  }, [dispatch, issuesNavType, pagination]);

  return {
    data: tickets,
  };
};

export default useGetTableIssuesQuery;
