import React, {ComponentProps, useMemo} from 'react';
import TableToolbarLinkWithMenu from 'components/Table/TableToolbar/TableToolbar.LinkWithMenu';
import issuesSlice from 'features/Issues/Issues.ducks';
import useIssuesRouteParams from 'features/Issues/hooks/useIssuesRouteParams';
import useVisibleTableSearchFields from 'features/Issues/hooks/useVisibleTableSearchFields';
import {useSelector} from 'hooks/useAppSelector';
import {useAppDispatch} from 'hooks/useAppDispatch';

const GroupByLink = () => {
  const dispatch = useAppDispatch();
  const {issuesNavType} = useIssuesRouteParams();
  const {columnGrouping} = useSelector(issuesSlice.selectors.getPagesStateByKey(issuesNavType)) || {};
  const columnGroupingKey = columnGrouping[0];

  const {visibleSearchFieldsData} = useVisibleTableSearchFields();

  const groupableSearchFields = useMemo(() => {
    return visibleSearchFieldsData.filter(({search}) => search?.groupable ?? false);
  }, [visibleSearchFieldsData]);

  const menuItems: ComponentProps<typeof TableToolbarLinkWithMenu>['menuItems'] = useMemo(() => {
    return groupableSearchFields
      .map(({name, label}) => {
        return {
          children: label,
          onClick: () => {
            dispatch(issuesSlice.actions.updateColumnGrouping({columnGroupingKey: name, issuesNavType}));
          },
        };
      })
      .concat([
        {
          children: 'No grouping',
          onClick: () => {
            dispatch(issuesSlice.actions.updateColumnGrouping({columnGroupingKey: null, issuesNavType}));
          },
        },
      ]);
  }, [dispatch, groupableSearchFields, issuesNavType]);

  const groupedByLabel = useMemo(() => {
    const groupedSearchField = groupableSearchFields.find(({name}) => columnGroupingKey === name);
    return groupedSearchField?.label;
  }, [columnGroupingKey, groupableSearchFields]);

  const label = `Group${groupedByLabel ? 'ed' : ''} by ${groupedByLabel || ''}`;

  return <TableToolbarLinkWithMenu label={label} iconName="grid" menuItems={menuItems} />;
};

export default GroupByLink;
