import React, {memo} from 'react';
import cn from 'classnames';
import {ELEMENT_SIZE, Icon} from 'ht-styleguide';
import {MDUStatuses} from 'features/MultiDwellingUnits/MDU.types';
import ChipStatus from 'features/MultiDwellingUnits/Parts/Chip';
import {ISidebarHeader} from './SidebarHeader.types';
import styles from './Sidebarheader.scss';

const SidebarHeader = ({title, subtitle, chipText, onBackClick, dataTestId = 'SidebarHeader', classAdjust = '', SidebarActionsComponent}: ISidebarHeader) => {
  return (
    <div className={cn('padding-small2', styles.header, classAdjust)} data-testid={dataTestId}>
      {(onBackClick || SidebarActionsComponent) && (
        <div className={cn(styles.actions, 'flex align-items-center justify-content-space-between')} data-testid={`${dataTestId}-actions`}>
          <div>{onBackClick && <Icon name="v2-skinny-arrow" className={styles.backIcon} onClick={onBackClick} />}</div>
          <div>{SidebarActionsComponent}</div>
        </div>
      )}
      {title && (
        <h4 className="n000 ellipsis" data-testid={`${dataTestId}-title`}>
          {title}
        </h4>
      )}
      {subtitle && (
        <p className={cn('p2 n000 marginTop-tiny', styles.subtitle)} data-testid={`${dataTestId}-subtitle`}>
          {subtitle}
        </p>
      )}
      {chipText && (
        <div className="marginTop-tiny1" data-testid={`${dataTestId}-chip`}>
          <ChipStatus status={chipText as MDUStatuses} size={ELEMENT_SIZE.SMALL}>
            {chipText}
          </ChipStatus>
        </div>
      )}
    </div>
  );
};

export default memo(SidebarHeader);
