import {STATUS_PAGES_ATTRIBUTES} from '../Projects.constants';
import {TActionMenuProps} from '../Projects.types';

/**
 * Used with `getMenuItemsByStatus`
 */
const getCustomActionByStatus = (props: TActionMenuProps) => {
  const {project, dispatch} = props;

  return {
    actionMenu: STATUS_PAGES_ATTRIBUTES[project.status!]?.actionMenu?.({project, dispatch}) ?? [],
    sidebarActionMenu: STATUS_PAGES_ATTRIBUTES[project.status!]?.sidebarActionMenu?.({project, dispatch}) ?? [],
  };
};

export default getCustomActionByStatus;
