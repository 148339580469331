import {useState, useRef, useEffect, useCallback} from 'react';

export const useStickyHeader = ({defaultSticky = true, topValue = 0}) => {
  const [isSticky, setIsSticky] = useState(defaultSticky);
  const elemRef = useRef<HTMLElement | null>(null);

  const handleScroll = useCallback(
    ({top = 0}: {top?: number} = {}) => {
      if (top <= topValue) {
        if (!isSticky) {
          setIsSticky(true);
        }
      } else if (isSticky) {
        setIsSticky(false);
      }
    },
    [isSticky, topValue]
  );

  useEffect(() => {
    const handleScrollEvent = () => {
      handleScroll(elemRef.current?.getBoundingClientRect());
    };
    window.addEventListener('scroll', handleScrollEvent);

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, [handleScroll, topValue]);

  return {elemRef, isSticky};
};
