/* eslint-disable camelcase */
import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import {createFilter, FilterOptionOption} from 'react-select';
import {ELEMENT_SIZE, SelectField, Label, InputField} from 'ht-styleguide';
import {WarningHint, WarningHintIcon} from 'components/Elements/Messages';
import {SelectArrayOptions} from 'types/base.types';
import {CUSTOM_DROPDOWN_OPTIONS} from 'features/Questions/constants';
import {CustomDropdownValues, ModelAnswer, QuestionDeviceProps, QuestionsAPIByQuestion} from 'features/Questions/types';
import {getDeviceModelOptions, getDeviceMakeOptions, getSelectedModel, getModelQuestion, getAnswerWarning, isStringType} from './qa.utils';
import styles from './qa.styles.scss';

const DEFAULT_VALUES = {
  PLACEHOLDER: 'Select',
} as const;

type SelectArrayOptionsWithSearchable = SelectArrayOptions<{searchable?: boolean}>;

type InitialState = {
  hintVisible: boolean;
  isModelDisabled: boolean;
  showInput: boolean;
  showModelInput: boolean;
  /**
   * Dyanmic list of models. Can be based list of models or a filtered list that is based on user search input
   */
  models: SelectArrayOptionsWithSearchable | null;
  /**
   * List of models from parent component.
   */
  filteredModels: SelectArrayOptionsWithSearchable | null;
  placeholder: string;
  /**
   * Dyanmic list of makes. Can be based list of makes or a filtered list that is based on user search input
   */
  allOptions: SelectArrayOptionsWithSearchable;
};

const INITIAL_STATE: InitialState = {
  hintVisible: false,
  isModelDisabled: true,
  showInput: false,
  showModelInput: false,
  models: null,
  filteredModels: null,
  placeholder: DEFAULT_VALUES.PLACEHOLDER,
  allOptions: [],
};

const QuestionDevice = ({
  question,
  value,
  modelValue,
  onMakeDropdownChange,
  onModelDropdownChange,
  onMakeInputChange,
  onModelInputChange,
  makeInputValue,
  modelInputValue,
  error,
  sku,
  onIconClick,
}: QuestionDeviceProps) => {
  const filterOption = createFilter({});
  // get options
  const questionAnswers = question.answers as ModelAnswer[];
  const options = getDeviceMakeOptions(question, sku);
  const modelQuestion = getModelQuestion({answers: questionAnswers});
  const isHintVisible = !!question.hint;
  const answerWarning = getAnswerWarning(question, value);
  const [state, setState] = useState(INITIAL_STATE);
  const makeQuestion = question.textDirect;
  const {hintVisible, isModelDisabled, showInput, showModelInput, models, filteredModels, placeholder, allOptions} = state;

  // push custom options 'other' & 'idk'
  options.push(CUSTOM_DROPDOWN_OPTIONS.OTHER);
  options.push(CUSTOM_DROPDOWN_OPTIONS.I_DONT_KNOW);

  useEffect(() => {
    const selected = getSelectedModel({questionAnswers, valueId: value});
    const products = selected && selected.productQuestion;
    const modelOptions = getDeviceModelOptions({products});
    setState(prevState => {
      return {
        ...prevState,
        filteredModels: modelOptions,
        models: modelOptions,
        isModelDisabled: !value,
        allOptions: options,
        modelVal: modelValue,
      };
    });
    if (value === CustomDropdownValues.negOne) {
      setState(prevState => {
        return {
          ...prevState,
          isModelDisabled: true,
          showModelInput: false,
        };
      });
    }
    if (hintVisible) {
      setState(prevState => {
        return {
          ...prevState,
          hintVisible: !hintVisible,
        };
      });
    }
  }, [value]);

  useEffect(() => {
    if (isStringType(modelValue)) {
      setState(prevState => {
        return {
          ...prevState,
          showModelInput: true,
        };
      });
    }
    if (isStringType(value)) {
      setState(prevState => {
        return {
          ...prevState,
          showInput: true,
          showModelInput: true,
        };
      });
    }
    if (value === CustomDropdownValues.negOne) {
      setState(prevState => {
        return {
          ...prevState,
          isModelDisabled: true,
          placeholder: CUSTOM_DROPDOWN_OPTIONS.I_DONT_KNOW.label,
        };
      });
    } else {
      setState(prevState => {
        return {
          ...prevState,
          placeholder: DEFAULT_VALUES.PLACEHOLDER,
        };
      });
    }
  }, []);

  const onHintClick = (event: {preventDefault: () => void}) => {
    event.preventDefault();
    setState(prevState => {
      return {
        ...prevState,
        hintVisible: !hintVisible,
      };
    });
  };

  const warningBox = (message: string, visible: boolean) => {
    const hintStyles = classNames(styles.answerHint, styles.answerHintInput);
    return (
      <div className={hintStyles}>
        <WarningHint message={message} visible={visible} />
      </div>
    );
  };

  const questionHint = (q: QuestionsAPIByQuestion) => {
    if (!q?.hint) return null;
    return warningBox(q.hint, hintVisible);
  };

  const questionHintIcon = () => {
    const hintIconStyles = classNames(styles.answerHintIcon, styles.answerHintIconInput);
    return (
      <div className={hintIconStyles}>
        <WarningHintIcon onClick={onHintClick} />
      </div>
    );
  };

  const handleMakeDropdownChange = (v: CustomDropdownValues) => {
    const isOtherOption = v === CUSTOM_DROPDOWN_OPTIONS.OTHER.value;
    const isIDKOption = v === CUSTOM_DROPDOWN_OPTIONS.I_DONT_KNOW.value;

    if (isOtherOption || isIDKOption) {
      if (isOtherOption) {
        setState(prevState => {
          return {
            ...prevState,
            showModelInput: true,
            showInput: true,
            placeholder: CUSTOM_DROPDOWN_OPTIONS.OTHER.label,
          };
        });
      }
      if (isIDKOption) {
        setState(prevState => {
          return {
            ...prevState,
            placeholder: CUSTOM_DROPDOWN_OPTIONS.I_DONT_KNOW.label,
            isModelDisabled: true,
          };
        });
      }
    } else {
      setState(prevState => {
        return {
          ...prevState,
          isModelDisabled: false,
          showModelInput: false,
          showInput: false,
          placeholder: DEFAULT_VALUES.PLACEHOLDER,
        };
      });
    }
    const selected = getSelectedModel({questionAnswers, valueId: v});
    const products = selected && selected.productQuestion;
    const modelOptions = getDeviceModelOptions({products});
    setState(prevState => {
      return {
        ...prevState,
        models: modelOptions,
      };
    });
    onMakeDropdownChange(v);
  };

  const handleModelDropdownChange = (v: CustomDropdownValues) => {
    const isOtherOption = v === CUSTOM_DROPDOWN_OPTIONS.OTHER.value;
    if (isOtherOption) {
      setState(prevState => {
        return {
          ...prevState,
          showModelInput: true,
        };
      });
    }
    onModelDropdownChange(v);
  };

  const handleIconClick = () => {
    setState(prevState => {
      return {
        ...prevState,
        showModelInput: false,
        showInput: false,
        isModelDisabled: true,
        placeholder: DEFAULT_VALUES.PLACEHOLDER,
      };
    });
    onIconClick();
  };

  /*
    * filterOption is a prop in react-select. Setting it to true disables the custom no options message and enables us
      to customize the options to be shown when no search results are found. (in type ahead)
    * Note that the no options message is pushed as a custom 'option' object where we can also add an 'isDisabled' prop that will make this non-interactive.
  */
  const filterAllOptions = (input: string, optionList: SelectArrayOptionsWithSearchable | null, stateValue: string) => {
    // @ts-ignore
    const filteredOptions = (optionList ?? []).filter(o => {
      return !(o.searchable === false) && filterOption({...o, data: {} as any} as FilterOptionOption<SelectArrayOptionsWithSearchable[0]>, input);
    });
    const noOptions = filteredOptions.length === 0;
    if (noOptions) {
      filteredOptions.push(CUSTOM_DROPDOWN_OPTIONS.NO_OPTIONS);
    }
    filteredOptions.push(CUSTOM_DROPDOWN_OPTIONS.OTHER);
    filteredOptions.push(CUSTOM_DROPDOWN_OPTIONS.I_DONT_KNOW);

    setState(prevState => {
      return {
        ...prevState,
        [stateValue]: filteredOptions,
      };
    });
  };

  return (
    <div className={styles.answer}>
      <div>
        {showInput ? (
          <>
            <Label label={makeQuestion} elementSize={ELEMENT_SIZE.LARGE} />
            <InputField
              showError={false}
              type="text"
              value={makeInputValue}
              onChange={onMakeInputChange}
              error={error.make}
              iconName="v2-close-icon"
              iconOnClick={handleIconClick}
              autoFocus={showInput}
            />
          </>
        ) : (
          <SelectField
            placeholder="Select"
            options={allOptions}
            filterOption={() => true}
            onInputChange={(input: string) => filterAllOptions(input, options, 'allOptions')}
            label={makeQuestion}
            hint={hintVisible && questionHint(question)}
            value={value || ''}
            onChange={({value: v}) => handleMakeDropdownChange(v)}
            elementSize={ELEMENT_SIZE.MEDIUM}
            clearable={false}
            searchable
            error={error.make}
          />
        )}
      </div>
      <div className="marginTop-small1">
        {showModelInput ? (
          <>
            <Label label={modelQuestion} elementSize={ELEMENT_SIZE.LARGE} />
            <InputField type="text" value={modelInputValue as string} onChange={onModelInputChange} error={error.model} disabled={!value} />
          </>
        ) : (
          <SelectField
            placeholder={placeholder}
            options={models || undefined}
            filterOption={() => true}
            onInputChange={(input: string) => filterAllOptions(input, filteredModels, 'models')}
            label={modelQuestion}
            value={modelValue || ''}
            onChange={({value: v}) => handleModelDropdownChange(v)}
            elementSize={ELEMENT_SIZE.MEDIUM}
            searchable
            error={error.model}
            clearable={false}
            isDisabled={isModelDisabled}
          />
        )}
      </div>
      {isHintVisible && questionHintIcon()}
      {answerWarning && warningBox(answerWarning, !!answerWarning)}
    </div>
  );
};

export default QuestionDevice;

/*
  --------------------------------
  NOTES
  --------------------------------
  Context:
    * There are two questions (currently) that a user answers - make, and, model of their device.
    * The questions are presented as dropdowns initially and if user picks "other" in make question,
      we render input fields for both questions.
    * We need to send back {answerId, make, model} for the questions to the BE (see newItem reducer)
  The question object coming back from the api looks something like this:
  questions:[
    0: {
      ...
      id: 5428
      inputType: "device"
      textDirect: "What MAKE is your computer?"
      text: "Apple"
      answers: [
        0: {
          ...
          id: 13272
          productQuestion: {
            id: 9
            text: "What MODEL is your computer?"
            answers: [
              0: {id: 24, name: "Apple TV"}
              1: {id: 135, name: "HomePod"}
              2: {id: 166, name: "Laptop"}
              3: {id: 167, name: "Desktop"}
            ]
          }
        1: {id: 13278, text: "Google"........}
      ]
    }
    1: {.....}
    2: {.....}
  ]
  - MR
*/
