import React from 'react';
import cn from 'classnames';
import styles from './styles.scss';

export const TBadgeVariants = {
  SUCCESS: 'success',
  WARNING: 'warning',
  PRIMARY: 'primary',
  NEUTRAL: 'neutral',
  MUTED: 'muted',
} as const;

export type TBadgeVariants = (typeof TBadgeVariants)[keyof typeof TBadgeVariants];
type TBadgeProps = {
  value: string | number;
  variant?: TBadgeVariants;
  maxNumberSize?: number;
  className?: string;
};
const NotificationBadge = ({value, variant = TBadgeVariants.SUCCESS, maxNumberSize, className}: TBadgeProps) => {
  const isSingleChar = value && String(value).length === 1;
  let displayedValue = value;

  if (typeof value === 'number') {
    if (maxNumberSize && value > maxNumberSize) {
      displayedValue = `${maxNumberSize}+`;
    }
  }

  const badgeClasses = cn(
    styles.badge,
    {
      [styles[variant]]: variant,
      [styles.badgeMultipleChars]: !isSingleChar,
    },
    className
  );

  return <span className={badgeClasses}>{displayedValue}</span>;
};

export default NotificationBadge;
