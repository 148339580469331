import {Dispatch} from '@reduxjs/toolkit';

import {notifications} from 'components/Notification/notification.ducks';

import unWrapError from 'utils/request/requestError';

export const showErrorToast = (reduxDispatch: Dispatch<any>) => (error: any) => {
  const unwrappedError = unWrapError(error, {includeObjectKeys: true});

  // Need to manually call/remove the notification since react-query is not hooked up to our Redux middleware
  reduxDispatch(notifications.actions.notificationApiError({source: unwrappedError || error.statusText}));
  reduxDispatch(notifications.actions.notificationApiPending());
};
