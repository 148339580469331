import React from 'react';
import {noop} from 'utils/event';
import {ProjectDetails} from 'features/MultiDwellingUnits/MDU.types';
import {Button, BUTTON_THEMES, Modal, ELEMENT_SIZE} from 'ht-styleguide';
import styles from './modals.styles.scss';

type ModalLaunchProjectProps = {
  isVisible: boolean;
  project: ProjectDetails;
  toggleModal?: BaseAnyFunction;
  onConfirm?: BaseAnyFunction;
};

const ModalLaunchProject = ({isVisible, toggleModal = noop, onConfirm = noop, project}: ModalLaunchProjectProps) => {
  const {leadTech, unitsNumber} = project;
  return (
    <Modal
      isVisible={isVisible}
      elementSize={ELEMENT_SIZE.MEDIUM}
      bodyContainerClasses={styles.overflowInitial}
      hide={toggleModal}
      header="Launch Project"
      footerElement2={
        <Button theme={BUTTON_THEMES.SECONDARY} className={styles.ctaButton} onClick={toggleModal}>
          Go Back
        </Button>
      }
      footerElement3={
        <Button theme={BUTTON_THEMES.PRIMARY} className={styles.ctaButton} onClick={onConfirm}>
          Launch Project
        </Button>
      }
    >
      <p className="p1">
        {unitsNumber} jobs will be created and immediately dispatched to <span className="text-weight-med">{leadTech?.name}</span>.
      </p>
    </Modal>
  );
};

export default ModalLaunchProject;
