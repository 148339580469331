import React, {useState, useRef} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useQueryClient} from 'react-query';
import {Icon} from 'ht-styleguide';
import {MenuListItem, MenuPanel, MenuUnorderedList} from 'components/Elements/Menu';
import cn from 'classnames';
/* Hooks */
import {useAppDispatch} from 'hooks/useAppDispatch';
import useOutsideClick from 'hooks/useOutsideClick';
/* Utils */
import {noop} from 'utils/event';
/* Splits */
import {useExcludeLink} from 'features/Splitio/splitio.hooks';

/* Paths */
import {loginPath} from 'global/paths';

/* Constants */
import {TEST_IDS} from './adminHub.header.constants';
import {TOP_NAV_ITEMS_DEFAULT} from 'components/Layouts/AdminHub/AdminHub.layout.constants';

/* Ducks */
import {login} from 'features/Login/login.ducks';

/* Types */
import {LinkItemProps, ProfileIconProps} from 'components/Layouts/AdminHub/adminHub.types';

import {APP_LEVEL_QUERY_KEYS} from 'queries/User/app.query.keys';
import {NavItem, IconItem} from './AdminHub.Header.Parts';
import CreateButton from './CreateButton';
import styles from './adminHub.header.styles.scss';

export const ProfileIcon = ({onProfileClick = noop}: ProfileIconProps) => {
  const [isProfileIconActive, setIsProfileIconActive] = useState(false);
  const profileIconRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  useOutsideClick(profileIconRef, () => setIsProfileIconActive(false));

  /* Methods */
  const handleProfileClick = () => {
    setIsProfileIconActive(!isProfileIconActive);
    onProfileClick();
  };
  const handleLogoutClick = () => {
    dispatch(login.actions.logoutAdministrator());
    queryClient.invalidateQueries(APP_LEVEL_QUERY_KEYS.current);
    navigate(loginPath());
  };

  return (
    <IconItem ref={profileIconRef} isActive={isProfileIconActive} onClick={handleProfileClick} dataTestId={TEST_IDS.PROFILE} iconName="user" containerClassName="position relative" noRightMargin>
      {isProfileIconActive && (
        <MenuPanel className={styles.iconItemMenuPanel}>
          <MenuUnorderedList>
            <MenuListItem onClick={handleLogoutClick}>Logout</MenuListItem>
          </MenuUnorderedList>
        </MenuPanel>
      )}
    </IconItem>
  );
};

type AdminHubHeaderProps = {FeatureGroupComponent?: React.ReactNode};
const AdminHubHeader = ({FeatureGroupComponent = null}: AdminHubHeaderProps) => {
  /* Hooks */
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {isExcluded} = useExcludeLink('issues');

  /* Methods */
  const handleButtonClick = (actionItem: LinkItemProps) => () => {
    navigate(actionItem.link);
  };

  /**
   * This lets us know if the current pathname matches the active link. We determine the active link in adminHub.layout.constants.
   *
   * @param activeItem
   */
  const determineMatchedLink = (activeItem: string | string[]) => {
    if (!activeItem) return false;

    if (typeof activeItem === 'string') {
      const pattern = new RegExp(`^${activeItem}`);

      return pattern.test(pathname);
    }

    return activeItem.some((item: string) => {
      const pattern = new RegExp(`^${item}`);

      return pattern.test(pathname);
    });
  };

  /* Data */
  return (
    <header className={styles.header}>
      <div className="flex align-items-center">
        <div className={cn(styles.htIconContainer, 'paddingRight-small2 marginRight-small2')}>
          <Icon name="hellotech-logo" className={styles.icon} />
        </div>
        {/* Base intent. Not derived from individual features */}
        {TOP_NAV_ITEMS_DEFAULT.map(item => {
          if (isExcluded(item.text.toLowerCase())) return null;

          return <NavItem text={item.text} key={item.key} onClick={handleButtonClick(item)} isActive={determineMatchedLink(item.active)} />;
        })}
      </div>
      <div className="flex align-items-center">
        <CreateButton />
        {/* Feature intent. Derived individual features. Design should align with this. */}
        {FeatureGroupComponent && FeatureGroupComponent}
        {/* Base intent. Not derived from individual features */}
        <ProfileIcon />
      </div>
    </header>
  );
};

export default AdminHubHeader;
