import React from 'react';
import WidgetDashboard from 'components/Elements/WidgetDashboard';
import useIssuesRouteParams from 'features/Issues/hooks/useIssuesRouteParams';
import useSetIssuesFilters from 'features/Issues/hooks/useSetIssuesFilters';
import useGetIssuesStatsQuery from 'features/Issues/queries/query.issues.stats';
import {IssuesSearchFieldNames} from 'features/Issues/issues.types';
import {WIDGET_HELPER} from './issuesStats.constants';

const IssuesStats = () => {
  const {statsFilters, issuesEntityId, statsKeys = []} = useIssuesRouteParams();
  const finalFilters = {...statsFilters, [IssuesSearchFieldNames.EntityId]: issuesEntityId};
  const {data: statsData} = useGetIssuesStatsQuery({filters: finalFilters});
  const {updateFilters, updateRawFilters} = useSetIssuesFilters();

  if (!statsKeys.length) return null;

  return (
    <WidgetDashboard numCols={3} numRows={1}>
      {statsKeys.map(statKey => {
        const {label, filter, rawFilter, icon, colorVariant} = WIDGET_HELPER[statKey];
        const actionOnClickProp = filter
          ? {
              actionOnClick: () => {
                updateFilters({filters: filter});
                if (rawFilter) {
                  updateRawFilters(rawFilter);
                }
              },
            }
          : {};
        const totalItems = statsData?.[statKey];
        const totalCopy = Number.isInteger(totalItems) ? totalItems : '--';
        return <WidgetDashboard.Widget key={label} {...actionOnClickProp} colorVariant={colorVariant} icon={icon} label={label} value={`${totalCopy} Issues`} />;
      })}
    </WidgetDashboard>
  );
};

export default IssuesStats;
