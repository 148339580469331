import {useMemo} from 'react';
import {TOrderDetails} from 'features/Orders/types/orders.types';
import {dayjsWithTimezone} from 'utils/date';
import {shortMonthWithDayAndHourMinute} from 'global/constants/common';

const useOrderTaskChecklistMetaData = ({order}: {order: TOrderDetails | null | undefined}) => {
  const pageHeaderMetaGridData = useMemo(() => {
    const formattedAppointmentStart = (() => {
      const {appointmentStart, appointmentStartDateTime, timezone: orderTimezone} = order || {};
      if (!appointmentStartDateTime) {
        return '';
      }
      return orderTimezone ? dayjsWithTimezone(appointmentStartDateTime, orderTimezone).format(shortMonthWithDayAndHourMinute) : appointmentStart;
    })();
    return [
      {
        label: 'Customer Name',
        value: order?.clientName,
      },
      {
        label: 'Appointment',
        value: formattedAppointmentStart,
      },
      {
        label: 'Assigned Tech',
        value: order?.tech?.name,
      },
    ];
  }, [order]);

  return pageHeaderMetaGridData;
};

export default useOrderTaskChecklistMetaData;
