import {TaskTypes} from 'features/Products/types/taskGroups.types';
import {TaskTypesForChecklist} from '../taskChecklist.types';

/**
 * The namespace for the task side sheet in the redux store
 */
export const CLOUDINARY_TASK_SIDESHEET_NAMESPACE = 'taskSideSheet';

/**
 * Task type options for the task type select field
 */
export const TASK_TYPE_OPTS = [
  {
    label: 'Confirm',
    value: TaskTypes.Confirm,
  },
  {
    label: 'Photo',
    value: TaskTypes.Photo,
  },
  // {
  //   label: 'Video',
  //   value: TaskTypes.Video,
  // },
  {
    label: 'Binary',
    value: TaskTypes.Binary,
  },
  {
    label: 'Multiselect',
    value: TaskTypes.MultiSelect,
  },
  {
    label: 'Single Select',
    value: TaskTypes.SingleSelect,
  },
  {
    label: 'Text Entry',
    value: TaskTypes.Text,
  },
];

/**
 * Shared field errors
 */
export const SHARED_FIELD_ERRORS = {
  min2Options: 'Minimum of 2 options are required',
  descriptionCannotBeEmpty: 'Description cannot be empty',
};

/**
 * Minimum options count for multi and single select
 */
export const MINIMUM_OPTIONS_COUNT = 2;

/**
 * For `<ToggleSettingsSection />`
 */
export const TOGGLE_SETTINGS_PER_TASK: Record<TaskTypesForChecklist, {canHaveOptionalNoteField: boolean; canHaveOptionalPhotoField: boolean}> = {
  [TaskTypes.Confirm]: {
    canHaveOptionalNoteField: true,
    canHaveOptionalPhotoField: true,
  },
  [TaskTypes.Photo]: {
    canHaveOptionalNoteField: true,
    canHaveOptionalPhotoField: false,
  },
  [TaskTypes.Video]: {
    canHaveOptionalNoteField: true,
    canHaveOptionalPhotoField: false,
  },
  [TaskTypes.SingleSelect]: {
    canHaveOptionalNoteField: true,
    canHaveOptionalPhotoField: false,
  },
  [TaskTypes.MultiSelect]: {
    canHaveOptionalNoteField: true,
    canHaveOptionalPhotoField: false,
  },
  [TaskTypes.Text]: {
    canHaveOptionalNoteField: false,
    canHaveOptionalPhotoField: true,
  },
  [TaskTypes.Binary]: {
    canHaveOptionalNoteField: true,
    canHaveOptionalPhotoField: true,
  },
};
