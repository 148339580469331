import APIS from 'global/apis';

// https://github.com/smartystreets/jquery.liveaddress/blob/master/src/jquery.liveaddress.js
// https://smartystreets.com/docs/cloud/us-street-api

export default class Smarty {
  geocode = async (addressComponents: any) => {
    try {
      const response = await APIS.geo.geocode(addressComponents);
      if (response.err) throw new Error(response.err);
      return response.data;
    } catch (error) {
      //  TODO: Add logger for catching error
      return null;
    }
  };
}
