/** Toast notification messages for task checklist operations */
export const TASK_CHECKLIST_TOAST_MESSAGES = {
  TASK_UPDATED: 'Task updated',
  TASK_ADDED: 'New task added',
  TASK_DELETED: 'Task deleted',
  TASK_NOT_ORDERED: 'Task could not be ordered',
  GROUPING_ENABLED: 'Grouping enabled',
  GROUPING_DISABLED: 'Grouping disabled',
  NEW_GROUP_ADDED: 'New group added',
  GROUP_DELETED: 'Group deleted',
  GROUP_UPDATED: 'Group updated',
  GROUP_NOT_ORDERED: 'Group could not be ordered',
};

/** Defines the types of items in a task checklist */
export const TASK_CHECKLIST_ITEM_TYPES = {
  TASK: 'task',
  GROUP: 'group',
  TASKS_AND_GROUPS: 'tasks_and_groups',
} as const;

/** Defines the modal variants for task checklist feature */
export const TASK_CHECKLIST_MODAL_VARIANTS = {
  ADD_EDIT_GROUP: 'addEditGroup',
  DELETE_ITEM: 'deleteItem',
} as const;
