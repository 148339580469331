import React from 'react';
// Elements
import {Grid, Icon} from 'ht-styleguide';

// Features & Components
import GeneralLayout from 'components/Layouts/General/General.layout';
import styles from './header.styles.scss';

const LogoHeader = () => {
  return (
    <GeneralLayout.Header className={styles.header_container}>
      <Grid.Fluid classes={styles.headerGrid}>
        <Icon name="hellotech-logo" className={styles.logo} />
      </Grid.Fluid>
    </GeneralLayout.Header>
  );
};

export default LogoHeader;
