import React from 'react';
import {createBrowserRouter, createRoutesFromElements, Navigate, Route} from 'react-router-dom';

/* Components. Most of these are already wrapped in components/Loadable */
import OrderBookingPage from 'features/Booking';
import MembershipPage from 'features/Membership';
import ProductsPage from 'features/Products/Products.Page';
import ProfilePage from 'features/Profile/Profile.page';
import MDUTopLevelRoutesStructure from 'features/MultiDwellingUnits/MDU.routes';
import IssuesRoutesStructure from 'features/Issues/Issues.Page';
import DispatchRoutesStructure from 'features/Dispatch/Dispatch.Page';
import OrdersRoutesStructure from 'features/Orders/Orders.Page';

import PrivateRoute from 'global/routes/PrivateRoute';
import {PublicPageLayout} from 'components/Layouts';
import LoginPage from 'features/Login';
import {Routes} from './routes';

const BaseRoutes = (
  <Route path="/">
    <Route path={Routes.HOME()} element={<Navigate to={Routes.LOGIN()} replace />} />
    <Route
      path={Routes.LOGIN()}
      element={
        <PublicPageLayout>
          <LoginPage />
        </PublicPageLayout>
      }
    />
    <Route
      path={`${Routes.BOOKING()}/*`}
      element={
        <PrivateRoute>
          <OrderBookingPage />
        </PrivateRoute>
      }
    />
    <Route
      path={`${Routes.MDU()}/*`}
      element={
        <PrivateRoute>
          <MDUTopLevelRoutesStructure />
        </PrivateRoute>
      }
    />
    <Route
      path={`${Routes.ISSUES_PATH_BASE()}/*`}
      element={
        <PrivateRoute>
          <IssuesRoutesStructure />
        </PrivateRoute>
      }
    />
    <Route
      path={`${Routes.DISPATCH_PATH_BASE()}/*`}
      element={
        <PrivateRoute>
          <DispatchRoutesStructure />
        </PrivateRoute>
      }
    />
    <Route
      path={`${Routes.MEMBERSHIP()}/*`}
      element={
        <PrivateRoute>
          <MembershipPage />
        </PrivateRoute>
      }
    />
    <Route
      path={`${Routes.PROFILE()}/*`}
      element={
        <PrivateRoute>
          <ProfilePage />
        </PrivateRoute>
      }
    />
    <Route
      path={`${Routes.PRODUCTS_PATH_BASE()}/*`}
      element={
        <PrivateRoute>
          <ProductsPage />
        </PrivateRoute>
      }
    />
    <Route
      path={`${Routes.ORDERS_PATH_BASE()}/*`}
      element={
        <PrivateRoute>
          <OrdersRoutesStructure />
        </PrivateRoute>
      }
    />
  </Route>
);

const router = createBrowserRouter(createRoutesFromElements(BaseRoutes));

export default router;
