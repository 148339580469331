import React from 'react';
// Hooks
import {useSelector} from 'react-redux';
// Types && Ducks
import {ProjectDetails} from 'features/MultiDwellingUnits/MDU.types';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
// Components & Styles
import PageHeader from 'components/Elements/PageHeader';
import LaunchChecklistGroup, {ProjectTypesChecklistEnum} from './CurrentProject.LaunchChecklistGroup';

export const getNumLaunchRequirementsMet = (project: ProjectDetails) => {
  const {total, totalCompleted} = project.stats || {};
  if (total === undefined || totalCompleted === undefined) return '';
  return `${totalCompleted}/${total} launch requirements met`;
};

const LaunchChecklistPage = () => {
  const currentProject: ProjectDetails = useSelector(mduProjectsSlice.selectors.getCurrentProject);

  // This controls the order of the checklist groups
  const groupNames = [ProjectTypesChecklistEnum.projectDetails, ProjectTypesChecklistEnum.teamManagement, ProjectTypesChecklistEnum.templatesJobs, ProjectTypesChecklistEnum.payment];

  return (
    <div className="marginBottom-medium2">
      <PageHeader title="Launch Checklist" HeaderRightContent={<p className="h6 n700 text-weight-med">{getNumLaunchRequirementsMet(currentProject)}</p>} />
      {groupNames.map(groupName => (
        <LaunchChecklistGroup groupName={groupName as ProjectTypesChecklistEnum} project={currentProject} key={groupName} />
      ))}
    </div>
  );
};

export default LaunchChecklistPage;
