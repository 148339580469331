import React, {useState} from 'react';
// Hooks
import {useSelector} from 'react-redux';
import {useAppDispatch} from 'hooks/useAppDispatch';
import useApi from 'hooks/useApi';
// Utils
import {noop} from 'utils/event';
import {isPreLaunchStatus} from 'features/MultiDwellingUnits/MDU.utils';
// Types && Ducks
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {MDUStatuses, ProjectDetails, ProjectPaymentAdjustment} from 'features/MultiDwellingUnits/MDU.types';
// Constants
import {FULFILLED} from 'global/constants/common';
// Components && Styles
import PageHeader from 'components/Elements/PageHeader';
import LeadPanel from 'features/MultiDwellingUnits/Parts/LeadPanel';
import {Icon, htToast} from 'ht-styleguide';
import {ModalSetBillingLead} from 'features/MultiDwellingUnits/Parts/Modals';
import ModalPaymentAdjustment from 'features/MultiDwellingUnits/Parts/Modals/MDU.Modal.PaymentAdjustmentModal';
import PaymentAdjustmentEmptyState from './CurrentProject.PaymentAdjustment.EmptyState';
import AdjustmentView from './CurrentProject.PaymentAdjustment.AdjustmentView';
import AdjustmentSummary from './CurrentProject.PaymentAdjustment.AdjustmentSummary';

const BillingLeadPanel = ({project = {} as ProjectDetails, onAddBillingLeadClick = noop}) => {
  const hasBillingLead = Boolean(project.billingLead);
  const isUserAllowedToEdit = hasBillingLead;

  // Changing Billing Lead is not allowed for post-launch projects
  const changeButtonContent = isUserAllowedToEdit ? (
    <button type="button" className="plainButton p2 text-link text-weight-bold" onClick={onAddBillingLeadClick}>
      <Icon name="repeat" className="marginRight-tiny1" />
      <span>Change Payment Recipient</span>
    </button>
  ) : null;
  const onChangeLeadPerson = !isPreLaunchStatus(project.status as MDUStatuses) ? noop : onAddBillingLeadClick;
  return (
    <LeadPanel
      className="marginBottom-medium2"
      panelHeaderText="Payment Recipient"
      panelIcon="credit-card"
      changeButtonContent={changeButtonContent}
      leadPerson={project.billingLead}
      onChangeLeadPerson={onChangeLeadPerson}
      changeLeadPersonText="Choose Payment Recipient"
    />
  );
};

const PaymentAdjustmentPage = () => {
  const currentProject: ProjectDetails = useSelector(mduProjectsSlice.selectors.getCurrentProject);
  const {id: projectId} = currentProject;

  const api = useApi();
  const dispatch = useAppDispatch();

  const [idOfAdjustmentToEdit, setIdOfAdjustmentToEdit] = useState<number | null>(null);
  const [isVisibleBillingLeadModal, setIsVisibleBillingLeadModal] = useState(false);
  const [isVisiblePaymentAdjModal, setIsVisiblePaymentAdjModal] = useState(false);
  const toggleBillingLeadModal = () => setIsVisibleBillingLeadModal(!isVisibleBillingLeadModal);
  const togglePaymentAdjModal = () => {
    if (isVisiblePaymentAdjModal) setIdOfAdjustmentToEdit(null); // nullify the id on modal close
    setIsVisiblePaymentAdjModal(!isVisiblePaymentAdjModal);
  };

  const editItem = (adjustment: ProjectPaymentAdjustment) => {
    setIdOfAdjustmentToEdit(adjustment.id);
    setIsVisiblePaymentAdjModal(true);
  };

  const deleteAdjustment = async (adjustment: ProjectPaymentAdjustment) => {
    try {
      api.toggleLoader(true);
      const response = await dispatch(mduProjectsSlice.actions.deleteProjectPayoutAdjustment({projectId, payoutAdjustmentId: adjustment.id}));
      if (response.type.endsWith(FULFILLED)) {
        htToast('Payout adjustment removed');
        await dispatch(mduProjectsSlice.actions.fetchProjectDetails({projectId}));
      }
    } catch (error) {
      // handle error
    } finally {
      api.toggleLoader(false);
    }
  };

  const hasPaymentAdjustments = Boolean(currentProject.id && currentProject?.payoutAdjustments.length);

  return (
    <div className="marginBottom-medium2">
      <PageHeader title="Payment Settings" />
      <BillingLeadPanel project={currentProject} onAddBillingLeadClick={toggleBillingLeadModal} />
      <PageHeader title="Payout Adjustments" />
      {hasPaymentAdjustments ? (
        <>
          <AdjustmentSummary project={currentProject} className="marginBottom-large" />
          <AdjustmentView project={currentProject} onButtonClick={togglePaymentAdjModal} onEditAdjustment={editItem} onDeleteAdjustment={deleteAdjustment} />
        </>
      ) : (
        <PaymentAdjustmentEmptyState onButtonClick={togglePaymentAdjModal} className="marginBottom-medium2" project={currentProject} />
      )}
      <ModalSetBillingLead isVisible={isVisibleBillingLeadModal} toggleModal={toggleBillingLeadModal} project={currentProject} />
      <ModalPaymentAdjustment isVisible={isVisiblePaymentAdjModal} toggleModal={togglePaymentAdjModal} idOfAdjustmentToEdit={idOfAdjustmentToEdit} project={currentProject} />
    </div>
  );
};

export default PaymentAdjustmentPage;
