import React, {useEffect} from 'react';
import {RouterProvider} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import router from 'global/routes';
import {TIME_15_MINUTES, TIME_20_MINUTES} from 'global/constants/common';
import RequestLoader from 'features/App/RequestLoader';
import StripeProvider from 'features/Stripe/Stripe.Provider';

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('react-query/devtools/development').then(d => ({
    default: d.ReactQueryDevtools,
  }))
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      notifyOnChangeProps: 'tracked',
      keepPreviousData: true,
      retry: false,
      staleTime: TIME_15_MINUTES, // 3 minutes
      cacheTime: TIME_20_MINUTES, // 5 minute
    },
  },
});

const App = () => {
  const [showDevtools, setShowDevtools] = React.useState(false);

  useEffect(() => {
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools(old => !old);
  }, []);

  return (
    <StripeProvider>
      <QueryClientProvider client={queryClient}>
        <RequestLoader primary />
        <RouterProvider router={router} />
        <ReactQueryDevtools />
        {showDevtools ? (
          <React.Suspense fallback={null}>
            <ReactQueryDevtoolsProduction />
          </React.Suspense>
        ) : null}
      </QueryClientProvider>
    </StripeProvider>
  );
};

export default App;
