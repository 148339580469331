import React, {SyntheticEvent, useState} from 'react';
import cn from 'classnames';
import styles from './hellotechnow.styles.scss';

const HelloTechNowMessage = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const messageStyles = cn(styles.messageContent, {hide: !visible});

  const toggleMessage = (event: SyntheticEvent) => {
    event.preventDefault();
    setVisible(!visible);
  };

  return (
    <div className={styles.message}>
      <div className={styles.messageTitle}>
        <a data-testid="hellotech-now-title" href="/" className="-no-decoration" onClick={toggleMessage}>
          What is HelloTech NOW?
        </a>
      </div>
      <div data-testid="hellotech-now-message" className={messageStyles}>
        We offer HelloTech Now! for services that can be performed by a tech remotely. You can get support right away and we're available 24/7. Not only is HelloTech Now! convenient, it saves you
        cash!
      </div>
    </div>
  );
};

export default HelloTechNowMessage;
