import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from 'react-query';

/* Ducks */
import mduProjectsSlice, {normalizeFiltersToParams} from 'features/MultiDwellingUnits/MDU.ducks';

/* Hooks */
import useApi from 'hooks/useApi';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useSelector} from 'hooks/useAppSelector';

/* Constants/Utils */
import APIS from 'global/apis';
import {MDU_PROJECT_QUERY_KEYS} from '../MDU.query.keys';
import {CustomFilterTypes, MduGetAllJobsResponse, MduUseParamsTypes} from '../MDU.types';

export const usePaginatedQueryJobs = () => {
  /* Hooks */
  const {projectId} = useParams<MduUseParamsTypes>();
  const api = useApi();
  const dispatch = useAppDispatch();

  /* Selectors */
  const itemsPerPage = useSelector(mduProjectsSlice.selectors.getPaginationItemsPerPage);
  const currentPage = useSelector(mduProjectsSlice.selectors.getPaginationPage);
  const filters = useSelector(mduProjectsSlice.selectors.getFilterByCustomKeyState(CustomFilterTypes.JOBS));

  /* Constants */
  const paginationQueryKey = [projectId, filters, currentPage, itemsPerPage];

  /* Methods */
  const filterSearchObj = () => {
    const pagi = {pagination: {per_page: itemsPerPage, page: currentPage + 1}};
    const params = {...filters, ...pagi};

    return normalizeFiltersToParams(params);
  };

  const {data, isFetched, isPreviousData} = useQuery(MDU_PROJECT_QUERY_KEYS.paginationJobs(paginationQueryKey), () =>
    (async (): Promise<MduGetAllJobsResponse> => {
      const normalizedFilters = filterSearchObj();
      api.toggleLoader(true);
      const response = await APIS.mdu.searchJobs({id: projectId, ...normalizedFilters});
      api.toggleLoader(false);
      if (response.err) throw new Error(response.err);
      return response;
    })()
  );

  // @ts-ignore
  const {pagination, units} = data?.data || {};

  useEffect(() => {
    if (pagination) {
      dispatch(
        mduProjectsSlice.actions.updatePaginationAttributes({
          total_pages: pagination.total_pages,
          total_items: pagination.total_units,
          items_on_current_page: pagination.units_on_current_page,
        })
      );
    }
  }, [pagination]);

  return {
    isPreviousData,
    isFetched,
    results: units || [],
    pagination,
  };
};

export const useGetUnitFromPaginatedQueries = ({unitId}: {unitId: number | string}) => {
  const {results} = usePaginatedQueryJobs();

  const match = results.find((unit: {id: string | number}) => +unit.id === +unitId);
  return match;
};
