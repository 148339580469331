import React from 'react';
import {Modal} from 'components/Elements/Modal';
import {Size as MODAL_SIZES} from 'types/base.types';
import {noop} from 'utils/event';
import {Button, BUTTON_THEMES} from 'ht-styleguide';

interface IDeleteGroupModalProps {
  isVisible: boolean;
  hide: Function;
  deleteGroup: BaseAnyFunction;
  header: string;
}

/**
 * @description - Modal to delete a group
 * @param isVisible
 * @param hide
 * @param deleteGroup
 * @param header
 * @constructor
 */
const DeleteGroupModal = ({isVisible = false, hide = noop, deleteGroup = noop, header = ''}: IDeleteGroupModalProps) => {
  const closeModal = (e: React.ChangeEvent) => {
    hide(e);
  };
  const handleConfirmClick = (e: React.ChangeEvent) => {
    hide(e);
    deleteGroup();
  };
  return (
    <Modal
      size={MODAL_SIZES.M}
      isVisible={isVisible}
      hide={closeModal}
      header={header}
      footerElement2={
        <Button theme={BUTTON_THEMES.SECONDARY} onClick={closeModal}>
          Cancel
        </Button>
      }
      footerElement3={
        <Button theme={BUTTON_THEMES.DANGER_SECONDARY} onClick={handleConfirmClick}>
          Delete Template
        </Button>
      }
    >
      <div className="marginY-small2">
        <p className="p1">The template and its contents will be deleted, this can’t be undone.</p>
      </div>
    </Modal>
  );
};

export default DeleteGroupModal;
