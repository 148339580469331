import React from 'react';
import cn from 'classnames';
import {IBaseTask} from '../../serviceTasksList.types';
import NoInfoCalloutBox from './NoInfoCalloutBox';
import BlockWrapper from './BlockWrapper';
import ServiceTaskAttachmentField from './ServiceTaskAttachmentField';

const INCIDENT_BLOCK_NAMESPACE = 'taskItemIncidentBlock';

/**
 * For the incident task type, display the photos attached and incident details.
 */
const IncidentBlock = ({serviceId, task}: IBaseTask) => {
  const response = task?.answer?.response;
  const attachments = task?.answer?.attachments || [];
  const hasAttachments = attachments.length > 0;
  const hasNoIncidentToReport = !response && !hasAttachments;
  const hasAnswer = !!task?.answer; // someone has attempted to answer the task

  // Styles
  const responseStyles = cn('p2 n800', {
    'marginBottom-small': hasAttachments,
  });

  return hasAnswer ? (
    <BlockWrapper title="Details">
      {hasNoIncidentToReport ? (
        <p className="p2 n800">No damage or incident occurred.</p>
      ) : (
        <>
          <p className={responseStyles}>{response}</p>
          <ServiceTaskAttachmentField serviceId={serviceId} namespace={INCIDENT_BLOCK_NAMESPACE} task={task} />
        </>
      )}
    </BlockWrapper>
  ) : (
    <NoInfoCalloutBox />
  );
};

export default IncidentBlock;
