import React from 'react';
import {SideSheetContentSection} from 'ht-styleguide';
import {IEditTaskAnswerSectionShareProps} from '../editTaskAnswerContent.types';
import AttachmentsFieldEditTaskAnswerSection from './AttachmentsField.EditTaskAnswerSection';

const EDIT_TASK_PHOTO_NAMESPACE = 'editTaskPhoto';

const PhotoEditTaskAnswerSection = ({formik, task}: IEditTaskAnswerSectionShareProps) => {
  const {multiple_photos} = task;
  // For single photo upload, remove the dropzone after one attachment is uploaded
  const showDropzone = multiple_photos ? true : formik.values.attachments.length < 1;

  return (
    <SideSheetContentSection title="Photos">
      <AttachmentsFieldEditTaskAnswerSection formik={formik} task={task} namespace={EDIT_TASK_PHOTO_NAMESPACE} withDropzone={showDropzone} />
    </SideSheetContentSection>
  );
};

export default PhotoEditTaskAnswerSection;
