import {REMOTE_TRUE} from 'features/Booking/constants/sku';
import {CUSTOM_DROPDOWN_OPTIONS} from 'features/Questions/constants';
import {AdjAmountFormatted, Answer, CustomDropdownValues, ModelAnswer, ProductQuestion, QuestionsAPIByQuestion, Sku} from 'features/Questions/types';

/* This function answers the question, Does the partner pay for add-ons, or is it the customer's responsibility? */
export const isPartnerSubsidizedAddOn = ({answer, sku}: {answer: Answer; sku: Sku}) => answer.subsidizedToPartner && !sku.subsidizingBasePriceOnly;

// DEVICE CATALOGING utils
// Get device make options
export const getDeviceMakeOptions = (question: QuestionsAPIByQuestion, sku: Sku) => {
  return question.answers.map(a => {
    let label = a.text;
    const showAmount = a.adjAmountFormatted && !isPartnerSubsidizedAddOn({answer: a, sku});

    if (a.id === REMOTE_TRUE) {
      if (typeof sku.remoteSavingsFormatted === 'string') {
        label = label.replace('%{SAVING}%', sku.remoteSavingsFormatted);
      }
    }
    if (showAmount) {
      const amount = (a.adjAmountFormatted as AdjAmountFormatted)?.formatted ?? a.adjAmountFormatted;

      label += ` (+${amount})`;
    }
    return {value: a.id, label};
  });
};

// Get device model options
export const getDeviceModelOptions = ({products}: {products?: ProductQuestion}) => {
  if (products) {
    const modelOptions = products.answers.map(p => {
      return {value: p.id as CustomDropdownValues | number, label: p.name};
    });
    // push custom options 'other' & 'idk'
    modelOptions.push(CUSTOM_DROPDOWN_OPTIONS.OTHER);
    modelOptions.push(CUSTOM_DROPDOWN_OPTIONS.I_DONT_KNOW);
    return modelOptions;
  }
  return null;
};

export const getSelectedModel = ({questionAnswers, valueId}: {questionAnswers: ModelAnswer[]; valueId: number | string}) => {
  const selectedModel = questionAnswers.find(a => {
    if (!a.productQuestion) return null;
    return valueId === a.id && a.productQuestion.answers;
  });
  return selectedModel;
};

export const getModelQuestion = ({answers}: {answers: ModelAnswer[]}) => {
  return answers?.[0]?.productQuestion?.text;
};

export const getAnswerWarning = (q: QuestionsAPIByQuestion, val: number | string) => {
  if (!val || !q) return null;
  let hint = null;
  q.answers.forEach(answer => {
    if (answer.id === val) {
      hint = answer.warning;
    }
  });
  return hint;
};

/*
  function to check if value is string - if it is, that means it is an input field.
*/
export const isStringType = (value?: string | number) => {
  return typeof value === 'string';
};
