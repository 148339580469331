import React, {useMemo} from 'react';
import {useFormik} from 'formik';
import {Form} from 'ht-styleguide';
import chunk from 'lodash/chunk';
import FiltersModal from 'components/Table/TableToolbar/Parts/FiltersModal';
import BaseFilterInputField from 'components/FilterFormFields/BaseFilter/InputField';
import BaseFilterSelectField from 'components/FilterFormFields/BaseFilter/SelectField';
import BaseFilterRangeDatePicker from 'components/FilterFormFields/BaseFilter/RangeDatePicker';
import CBSASelect from 'components/FilterFormFields/DataSelect/CBSASelect';
import SkuCategorySelect from 'components/FilterFormFields/DataSelect/SkuCategorySelect';
import SkuSelect from 'components/FilterFormFields/DataSelect/SkuSelect';
import UserSelect from 'components/FilterFormFields/DataSelect/UserSelect';
import {UnassignedFilterKeys} from 'features/Dispatch/dispatch.types';
import useSetUnassignedFilters from 'features/Dispatch/Pages/UnassignedList/hooks/useSetUnassignedFilters';
import useUnassignedListRouteParams from 'features/Dispatch/hooks/useUnassignedListRouteParams';
import {UNASSIGNED_FILTER_LABELS} from 'features/Dispatch/dispatch.constants';
import useTempRawFilters from 'utils/table/useTempRawFilters';
import {transformFormikToFilterValues, transformDateRangeToFormikValue, generateSchemaForFilters} from 'utils/table/filterModal.utils';
import {TIER_OPTIONS, STATE_OPTIONS, YES_NO_OPTIONS} from './unassignedTableToolbar.constants';

interface IUnassignedFiltersModal {
  isVisible: boolean;
  toggleModal: () => void;
}

const YUP_SCHEMA = generateSchemaForFilters({
  filterKeyMap: {
    [UnassignedFilterKeys.AgentId]: 'multiSelect',
    [UnassignedFilterKeys.PartnerId]: 'multiSelect',
    [UnassignedFilterKeys.OrderId]: 'input',
    [UnassignedFilterKeys.Tier]: 'multiSelect',
    [UnassignedFilterKeys.State]: 'multiSelect',
    [UnassignedFilterKeys.OnHold]: 'singleSelect',
    [UnassignedFilterKeys.CBSAId]: 'multiSelect',
    [UnassignedFilterKeys.Standby]: 'singleSelect',
    [UnassignedFilterKeys.SkuCategoryIds]: 'multiSelect',
    [UnassignedFilterKeys.SkuIds]: 'multiSelect',
    [UnassignedFilterKeys.CreatedAt]: 'dateRange',
  },
});

const UnassignedFiltersModal = ({isVisible, toggleModal}: IUnassignedFiltersModal) => {
  const {filterKeys} = useUnassignedListRouteParams();
  const {filters: stateFilters, rawFilters: stateRawFilters, updateFilters, updateRawFiltersState} = useSetUnassignedFilters();
  const {tempRawFiltersRef, updateTempRawFilters} = useTempRawFilters(stateRawFilters);

  const formikInitialValues = useMemo(() => {
    return filterKeys.reduce((ret, filterKey) => {
      let fieldValue = stateFilters[filterKey] || '';
      fieldValue = transformDateRangeToFormikValue({value: fieldValue, isDateRange: filterKey === UnassignedFilterKeys.CreatedAt});
      return {
        ...ret,
        [filterKey]: fieldValue,
      };
    }, {});
  }, [filterKeys, stateFilters]);

  const formikClearAllValues = useMemo(() => {
    const clearAllValues = filterKeys.reduce((ret, filterKey) => {
      return {
        ...ret,
        [filterKey]: '',
      };
    }, {});
    return clearAllValues;
  }, [filterKeys]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: YUP_SCHEMA,
    initialValues: formikInitialValues,
    onSubmit: values => {
      const transformedValues = transformFormikToFilterValues({formikValues: values, isDateRangeCondition: ({fieldName}) => fieldName === UnassignedFilterKeys.CreatedAt});
      updateFilters({filters: transformedValues});
      updateRawFiltersState({newRawFiltersState: {...stateRawFilters, ...tempRawFiltersRef.current}});
      toggleModal();
    },
  });

  const fields = (() => {
    return filterKeys
      .map(filterKey => {
        switch (filterKey) {
          case UnassignedFilterKeys.AgentId: {
            return (
              <UserSelect
                multiple
                fieldName={UnassignedFilterKeys.AgentId}
                formik={formik}
                key={UnassignedFilterKeys.AgentId}
                label={UNASSIGNED_FILTER_LABELS.AgentAssigned}
                onChangeCB={updateTempRawFilters}
                onlyFulfillmentAgents
                userType={['admin']}
              />
            );
          }
          case UnassignedFilterKeys.PartnerId: {
            return (
              <UserSelect
                multiple
                fieldName={UnassignedFilterKeys.PartnerId}
                formik={formik}
                key={UnassignedFilterKeys.PartnerId}
                label={UNASSIGNED_FILTER_LABELS.PartnerId}
                onChangeCB={updateTempRawFilters}
                userType={['partner']}
              />
            );
          }
          case UnassignedFilterKeys.OrderId: {
            return (
              <BaseFilterInputField
                key={UnassignedFilterKeys.OrderId}
                fieldName={UnassignedFilterKeys.OrderId}
                formik={formik}
                label={UNASSIGNED_FILTER_LABELS.OrderId}
                onChangeCB={updateTempRawFilters}
              />
            );
          }
          case UnassignedFilterKeys.Tier: {
            return (
              <BaseFilterSelectField
                multiple
                key={UnassignedFilterKeys.Tier}
                fieldName={UnassignedFilterKeys.Tier}
                formik={formik}
                label={UNASSIGNED_FILTER_LABELS.Tier}
                onChangeCB={updateTempRawFilters}
                options={TIER_OPTIONS}
              />
            );
          }
          case UnassignedFilterKeys.State: {
            return (
              <BaseFilterSelectField
                multiple
                key={UnassignedFilterKeys.State}
                fieldName={UnassignedFilterKeys.State}
                formik={formik}
                label={UNASSIGNED_FILTER_LABELS.State}
                onChangeCB={updateTempRawFilters}
                options={STATE_OPTIONS}
              />
            );
          }
          case UnassignedFilterKeys.CBSAId: {
            return <CBSASelect multiple key={UnassignedFilterKeys.CBSAId} fieldName={UnassignedFilterKeys.CBSAId} formik={formik} label="CBSA" onChangeCB={updateTempRawFilters} />;
          }
          case UnassignedFilterKeys.Standby: {
            return (
              <BaseFilterSelectField
                key={UnassignedFilterKeys.Standby}
                fieldName={UnassignedFilterKeys.Standby}
                formik={formik}
                label={UNASSIGNED_FILTER_LABELS.Standby}
                onChangeCB={updateTempRawFilters}
                options={YES_NO_OPTIONS}
              />
            );
          }
          case UnassignedFilterKeys.OnHold: {
            return (
              <BaseFilterSelectField
                key={UnassignedFilterKeys.OnHold}
                fieldName={UnassignedFilterKeys.OnHold}
                formik={formik}
                label={UNASSIGNED_FILTER_LABELS.OnHold}
                onChangeCB={updateTempRawFilters}
                options={YES_NO_OPTIONS}
              />
            );
          }
          case UnassignedFilterKeys.SkuCategoryIds: {
            return (
              <SkuCategorySelect
                key={UnassignedFilterKeys.SkuCategoryIds}
                fieldName={UnassignedFilterKeys.SkuCategoryIds}
                formik={formik}
                label="Order Has SKU Category"
                onChangeCB={updateTempRawFilters}
              />
            );
          }
          case UnassignedFilterKeys.SkuIds: {
            return <SkuSelect key={UnassignedFilterKeys.SkuIds} fieldName={UnassignedFilterKeys.SkuIds} formik={formik} label="Order Has SKUs" onChangeCB={updateTempRawFilters} />;
          }
          case UnassignedFilterKeys.CreatedAt: {
            return (
              <BaseFilterRangeDatePicker
                key={UnassignedFilterKeys.CreatedAt}
                fieldName={UnassignedFilterKeys.CreatedAt}
                formik={formik}
                label={UNASSIGNED_FILTER_LABELS.CreatedAt}
                onChangeCB={updateTempRawFilters}
              />
            );
          }
          default:
            return null;
        }
      })
      .filter(Boolean);
  })();

  const onModalClose = () => formik.resetForm();
  const onClearAll = () => formik.resetForm({values: formikClearAllValues});
  const onApplyFilters = () => formik.submitForm();

  return (
    <FiltersModal isVisible={isVisible} toggleModal={toggleModal} onApplyFilters={onApplyFilters} onClearAll={onClearAll} onModalClose={onModalClose}>
      <Form withoutFormTag classes="paddingBottom-small1">
        {chunk(fields, 2).map(chunkedFields => {
          return (
            <Form.Row key={`${chunkedFields?.[0]?.key}-${chunkedFields?.[1]?.key}`}>
              {chunkedFields.map(field => {
                return (
                  <Form.Column key={field?.key} lg={6} md={8} sm={4}>
                    {field}
                  </Form.Column>
                );
              })}
            </Form.Row>
          );
        })}
      </Form>
    </FiltersModal>
  );
};

export default UnassignedFiltersModal;
