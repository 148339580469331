import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';
import environment from 'global/environments';
import {PRODUCTION, STAGING} from 'global/constants/env';

function AdminBugsnag() {
  const key = environment?.BUGSNAG_KEY ?? '2d0de8b77028ea9e2a0ad9a8695247ff';

  Bugsnag.start({
    apiKey: key,
    releaseStage: environment?.ENV || process.env.NODE_ENV,
    enabledReleaseStages: [PRODUCTION, STAGING],
    maxBreadcrumbs: 10,
    plugins: [new BugsnagPluginReact()],
  });

  return Bugsnag.getPlugin('react')!.createErrorBoundary(React);
}

export default AdminBugsnag;
