import {MaybeAttachments} from 'features/Cloudinary/cloudinary.types';
import {TUtilTableFiltersState, TUtilTableRawFiltersState, TUtilTableColumnOrder, TUtilTableColumnSort, TUtilTableColumnGrouping} from 'utils/table/table.types';
import {TComment} from './Parts/CommentFeed/commentFeed.types';
import {featureTypeIssues, FIELDS_READ_ONLY_TYPES, ISSUE_ENTITY_TYPES, ISSUE_FIELD_ENTITY_TYPE} from './Issues.constants';

/*
*******************************************************
  Sidebar navigation type
*******************************************************
*/
export enum IssuesNavTypes {
  // for /issues/:issuesNavType
  OPEN = 'open',
  OWN = 'own',
  DONE = 'done',
  // for single entity views based on id
  PROJECTS_SINGLE_VIEW = 'projectsSingleView',
}

export type TIssuesUseParams = {
  issuesNavType: IssuesNavTypes;
};

/*
*******************************************************
  General + UI driven
*******************************************************
*/

export enum SortType {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type TTableColumnState = {
  [fieldName: string]: {
    visible?: boolean;
    /**
     * It wasn't necessary to add this property state since reactivity is not a concern and storing
     * the data in localStorage was just fine, but it's here for consistency with the rest of column states.
     */
    columnWidth?: number;
  };
};

export type TPagesColumnStateLocalStorage = {
  [pageLocation in keyof IInitialState['pages']]: Pick<
    IInitialState['pages'][pageLocation],
    'columnState' | 'columnOrder' | 'columnSort' | 'columnGrouping' | 'filters' | 'rawFilters' | 'searchFieldKeys'
  >;
};

/**
 * Not a complete list of fields. Mainly for the ones that are used in the UI
 * or other hardcoded logic.
 */
export enum IssuesSearchFieldNames {
  AssignedToId = 'assigned_to_id',
  /**
   * Will not appear in search fields endpoint, but used in BE requests
   */
  AssignedToMe = 'assigned_to_me',
  CompletedAt = 'completed_at',
  CreatedAt = 'created_at',
  Description = 'description',
  EntityId = 'entity_id',
  EntityType = 'entity_type',
  EntityPartnerId = 'entity.partner_id',
  IssueType = 'issue_type',
  /**
   * Will not appear in search fields endpoint, but used in BE requests
   */
  HighPriority = 'high_priority',
  /**
   * Will not appear in search fields endpoint, but used in BE requests
   */
  OlderThan24h = 'older_than_24h',
  /**
   * Will not appear in search fields endpoint, but used in BE requests
   */
  Open = 'open',
  PriorityId = 'priority_id',
  Summary = 'summary',
  /**
   * Will not appear in search fields endpoint, but used in BE requests
   */
  Search = 'search',
  StatusId = 'status_id',
  TagIds = 'tag_ids',
  TemplateId = 'template_id',
}

export type IssuesStatsKeys = IssuesSearchFieldNames.Open | IssuesSearchFieldNames.HighPriority | IssuesSearchFieldNames.OlderThan24h;

/* Our Modals used in Issues */
export type TModalTypes = typeof featureTypeIssues; // typeof ISSUE_ENTITY_TYPES[keyof typeof ISSUE_ENTITY_TYPES];
export type TSingleActionTypes = 'delete';

/*
*******************************************************
  INITIAL STATE
*******************************************************
*/

export type TActionItem = {
  entity: TIssueTicket;
  modalKey: TModalTypes;
  modalType: TSingleActionTypes;
  onSuccessCallback?: BaseAnyFunction;
} | null;

export type TPageInitialState = {
  /**
   * Filters applied to the page via modal or widget.
   * These are used in api calls.
   */
  filters: TUtilTableFiltersState;
  /**
   * Extra data about the filters that are applied. The extra data
   * is primarily the label of the filter value so filter chips
   * can be populated with the label instead of the value.
   */
  rawFilters: TUtilTableRawFiltersState;
  /**
   * Stores table column visibility and width states.
   */
  columnState: TTableColumnState;
  /**
   * Stores the order of the columns in the table.
   */
  columnOrder: TUtilTableColumnOrder;
  /**
   * - Stores the sort state of the table.
   * - Values here are used directly in API calls.
   * - Grouping is a type of sort, so when a column is grouped,
   * sorting data is also added.
   * - Format is what `DataTable` expects
   */
  columnSort: TUtilTableColumnSort;
  /**
   * - Key of column that is grouped.
   * - Format is what `DataTable` expects
   */
  columnGrouping: TUtilTableColumnGrouping;
  /**
   * A little hack to know when reset table data. This holds the all search field keys used
   * for the table in string form. When this changes, we know to reset certain table states.
   */
  searchFieldKeys: string;
  pagination: TBasePagination;
};

export interface IInitialState {
  actionItem: TActionItem | null;
  pages: {
    [IssuesNavTypes.OPEN]: TPageInitialState;
    [IssuesNavTypes.OWN]: TPageInitialState;
    [IssuesNavTypes.DONE]: TPageInitialState;
    [IssuesNavTypes.PROJECTS_SINGLE_VIEW]: TPageInitialState;
  };
}

/*
*******************************************************
  Ticket and related types (needed its own section)
*******************************************************
*/

type TIssuesUserAttributes = {
  id: number;
  name: string;
  phone: string;
  email: string;
  profile_picture: {
    medium: string;
    retina: string;
    thumb: string;
    retina_thumb: string;
  };
};

type BaseIdName = {
  id: number;
  name: string;
};

type TEntity = {
  id: number;
  name: string;
  partner?: BaseIdName;
  lead_tech?: TIssuesUserAttributes;
  property_owner?: BaseIdName;
};

type TPriority = {
  id: number;
  name: string;
  criticality: number;
};

/* Issue Statuses */
export type TIssueStatus = {
  id: number;
  name: string;
};

type TTag = {
  id: number;
  name: string;
};

type TTemplate = {
  id: number;
  entity_type: string;
  base_template_id: number;
  name: string;
  code: string;
  // Add other properties as needed
};

/**
 * Ticket attributes are slightly different depending on the endpoint used.
 * These are the shared attributes.
 */
type TSharedTicketAttributes = {
  assigned_to: TIssuesUserAttributes;
  code: string;
  code_id: number;
  completed_at: string | null;
  created_at: string;
  deleted_at: string | null;
  description: string | null;
  entity: TEntity;
  entity_id: number;
  entity_type: (typeof ISSUE_ENTITY_TYPES)[keyof typeof ISSUE_ENTITY_TYPES];
  id: number;
  summary: string;
  updated_at: string;
  /* aliases */
  assigned_to_id: TIssuesUserAttributes;
  priority_id: TPriority;
  reported_by_id: TIssuesUserAttributes;
  requested_by_id: TIssuesUserAttributes;
  status_id: TIssueStatus;
  tag_id: TTag[];
  template_id: TTemplate;
  name: string;
  default: boolean;
};

export type TTemplateType = {
  available: boolean;
  base_template_id: number;
  code: string;
  default: boolean;
  entity_type: (typeof ISSUE_ENTITY_TYPES)[keyof typeof ISSUE_ENTITY_TYPES];
  id: number;
  name: string;
};

/**
 * Ticket attributes for fetching list of tickets (table/search)
 */
export type TIssuesTicketsData = TSharedTicketAttributes & {};

/**
 * Ticket attributes a single ticket
 */
export type TIssueTicket = TSharedTicketAttributes & {
  attachments: MaybeAttachments;
  priority: TPriority;
  status: TIssueStatus;
  tags: TTag[];
  template: TTemplate;
  reported_by: TIssuesUserAttributes;
  requested_by: TIssuesUserAttributes;
  /* aliases */
  completed_at_id: string | null;
  created_at_id: string;
};

/* Since any issue can have dynamic fields, too much upkeep - lets just keep it simple */
export type TIssueDynamicFields = Record<keyof TIssueTicket, any>;

/*
*******************************************************
  Others
*******************************************************
*/

/* Get Issues Entity Types: Response sans pagination */
export type TGetEntityTypesDataResponseGeneric = {
  label: string;
  value: (typeof ISSUE_ENTITY_TYPES)[keyof typeof ISSUE_ENTITY_TYPES];
};

/* Field API */
export type TField = {
  commonProps: {
    [key: string]: any;
  };
  default: Boolean;
  default_values: any[];
  display: keyof typeof FIELDS_READ_ONLY_TYPES;
  entity_type: (typeof ISSUE_FIELD_ENTITY_TYPE)[keyof typeof ISSUE_FIELD_ENTITY_TYPE];
  fetch: {
    params: object;
    url: string;
  };
  info_text: string | null;
  label: string;
  name: string;
  options: {value: string | number; label: string}[];
  position: number;
  read_only: keyof typeof FIELDS_READ_ONLY_TYPES;
  required: Boolean;
  reusable: Boolean;
  /**
   * The field type to render. The value corresponds to `issues_fields.field_type_name` in the BE.
   */
  type: 'select' | 'text' | 'multi_select' | 'long_text' | 'numeric' | 'datetime' | 'rich_content';
  value: any[];
  allowCustomEntry?: boolean;
  customizable?: boolean;
};

export type TFieldTableResponse = Pick<TField, 'info_text' | 'label' | 'name' | 'type'> &
  Partial<Pick<TField, 'fetch' | 'options' | 'entity_type'>> & {
    /**
     * For sorting, use this key instead of `name` for BE params
     */
    sort?: string;
    search: {
      /**
       * If the field should be displayed in the table.
       */
      table_allowed: boolean;
      /**
       * If the field should visible by default on first render. Values in localStorage will override this.
       */
      table_default: boolean;
      /**
       * If the user can control the visibility of the field.
       */
      table_user_control: boolean;
      /**
       * If the field can be grouped on.
       */
      groupable: boolean;
      /**
       * If the field can be sorted on.
       */
      sortable: boolean;
      /**
       * If the field can be filtered on in the filter modal.
       */
      filterable: boolean;
    };
  };

/**
 * To be used for `FieldGenerator` component...so it's also used in the query response to play
 * nicely with everything.
 */
export type TFieldForTable = TFieldTableResponse &
  Pick<TField, 'commonProps' | 'options'> & {
    /**
     * Not part of the BE response. Added to satisfy `FieldGenerator` component.
     */
    allowCustomEntry?: never;
    /**
     * Not part of the BE response. Added to satisfy `FieldGenerator` component.
     */
    display?: never;
    /**
     * Not part of the BE response. Added to satisfy `FieldGenerator` component.
     */
    read_only?: never;
    /**
     * Not part of the BE response. Added to satisfy `FieldGenerator` component.
     */
    required?: never;
  };

/*
*******************************************************
  Redux Action Payloads
*******************************************************
*/

export type TUpdateIssuesFiltersPayload = {
  issuesNavType: IssuesNavTypes;
  filters: TUtilTableFiltersState;
  replace?: boolean;
};

export type TUpdateIssuesRawFiltersPayload = {
  issuesNavType: IssuesNavTypes;
  name: string;
  label: string;
  values: Array<{value: any; label: any}>;
};

export type TRemoveIssuesRawFiltersPayload = {
  issuesNavType: IssuesNavTypes;
  name: string;
};

export type TUpdateRawFiltersStatePayload = {
  issuesNavType: IssuesNavTypes;
  newRawFiltersState: TUtilTableRawFiltersState;
};

export type TUpdateColumnStatePayload = {
  issuesNavType: IssuesNavTypes;
  columnState: TTableColumnState;
};

export type TUpdateColumnWidthsPayload = {
  issuesNavType: IssuesNavTypes;
  columnWidths: {
    [fieldName: string]: number;
  };
};

export type TUpdateColumnOrderPayload = {
  issuesNavType: IssuesNavTypes;
  columnOrder: TUtilTableColumnOrder;
};

export type TUpdateColumnSortPayload = {
  issuesNavType: IssuesNavTypes;
  columnSort: TUtilTableColumnSort;
};

export type TUpdateColumnGroupingPayload = {
  issuesNavType: IssuesNavTypes;
  columnGroupingKey: string | null;
};

export type TUpdateSearchFieldKeysPayload = {
  issuesNavType: IssuesNavTypes;
  searchFieldKeys: string;
};

export type TUpdatePaginationPayload = {
  issuesNavType: IssuesNavTypes;
  pagination: Partial<TBasePagination>;
};

/*
*******************************************************
  Queries/Responses
*******************************************************
*/

/* Get Issues Tickets Response */
export type TGetIssueTicketsDataResponse = {
  tickets: TIssueTicket[];
  pagination: TBasePagination;
};

/* Get Issue By ID Tickets Response */
export type TGetIssueTicketByIdDataResponse = {
  pagination: TBasePagination;
  tickets: TIssueTicket[];
};

/* Get Issues Tickets: Response sans pagination */
export type TGetIssueTicketsDataResponseGeneric = TIssueTicket[];

export type TGetIssuesQueryResponse = WithErrorResponse<{
  tickets: TIssuesTicketsData[];
  pagination: TBasePagination;
}>;

export type TGetIssuesTableSearchFieldsDataResponse = WithErrorResponse<{
  fields: TFieldForTable[];
}>;

export type TGetIssuesStatsDataResponse = WithErrorResponse<{
  stats: {
    open: number;
    high_priority: number;
    older_than_24h: number;
  };
}>;

export type TGetIssueTypesDataResponse = Array<{
  id: number;
  name: string;
  description: string;
  statuses: Array<{
    id: number;
    name: string;
    position: number;
  }>;
}>;

export type TGetIssueCategoriesDataResponse = Array<{
  id: number;
  name: string;
}>;

// Same as TTag?
export type TGetIssueTagsDataResponse = Array<{
  id: number;
  name: string;
  slug: string;
  description: string | null;
}>;

export type TGetIssueUsersParams = {
  /**
   * Enable query fetch
   */
  enabled?: boolean;
  /**
   * Search term. Searches name and email
   */
  search?: string;
  /**
   * `true` includes `TUIssuesUserAttributes` in response
   */
  full?: boolean;
  /**
   * 'user' === all users
   */
  types?: Array<'admin' | 'tech' | 'client' | 'partner' | 'recruit' | 'user'>;
};

export type TGetIssueUsersDataResponse = Array<
  {
    id: number;
    name: string;
  } & Partial<TIssuesUserAttributes>
>;

/* Get Issues Notes */
export type TGetIssueNotesDataResponse = TComment;
