import React from 'react';
import PageHeader from 'components/Elements/PageHeader';
import {TOrderDetails} from 'features/Orders/types/orders.types';
import {getOrderStatusLabel, getBadgePropsForLabel} from './pageHeaderOrderStatusBadge.utils';

interface PageHeaderOrderStatusBadgeProps {
  order: TOrderDetails | null | undefined;
}

const PageHeaderOrderStatusBadge = ({order}: PageHeaderOrderStatusBadgeProps) => {
  const label = getOrderStatusLabel({order});
  const badgeProps = getBadgePropsForLabel(label);
  return <PageHeader.Chip label={label} variant="outlined" {...badgeProps} />;
};

export default PageHeaderOrderStatusBadge;
