import React from 'react';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useSelector} from 'hooks/useAppSelector';
import uiSlice from 'features/ui/ui.ducks';
// Constants
import {TEST_IDS} from 'features/MultiDwellingUnits/Parts/TopNav/constants';
// Components && Styles
import Header from 'components/Layouts/AdminHub/Header';
import {IconItem} from 'components/Layouts/AdminHub/Header/AdminHub.Header.Parts';

const TopNav = () => {
  const dispatch = useAppDispatch();
  const projectSearchIsVisible = useSelector(uiSlice.selectors.getSearchState).projects.visible;
  const toggleSearch = () => dispatch(uiSlice.actions.setSearchVisibility({search: 'projects'}));

  return <Header FeatureGroupComponent={<IconItem iconName="search" onClick={toggleSearch} isActive={projectSearchIsVisible} dataTestId={TEST_IDS.SEARCH} />} />;
};

export default TopNav;
