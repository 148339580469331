import React, {useMemo, useEffect} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import chunk from 'lodash/chunk';
import {Form} from 'ht-styleguide';
import FiltersModal from 'components/Table/TableToolbar/Parts/FiltersModal';
import {ISSUE_FIELDS_ENTITY_NAMES} from 'features/Issues/Issues.constants';
import useIssuesTableSearchFieldsQuery from 'features/Issues/queries/query.issues.table.searchFields';
import FieldsGenerator from 'features/Issues/Parts/IssuesFieldsGenerator';
import usePrevious from 'hooks/usePrevious';
import {transformFormikToFilterValues, transformDateRangeToFormikValue} from 'utils/table/filterModal.utils';
import {IFiltersModal} from './IssuesTableToolbar.types';

/**
 * Select-type filters should all be multi-selects for better user experience.
 */
const IssuesFiltersModal = ({isVisible, hideModal, filters, updateFilters, updateRawFiltersState, updateTempRawFilters, resetTempNewRawFilters}: IFiltersModal) => {
  const {data: searchFieldsData = []} = useIssuesTableSearchFieldsQuery();
  const prevIsVisible = usePrevious(isVisible);

  const filterableFields = useMemo(() => {
    return searchFieldsData.filter(field => field?.search?.filterable);
  }, [searchFieldsData]);

  // Build yup schema
  const formikSchema = filterableFields.reduce((allFields, field) => {
    const validation = (() => {
      switch (field.type) {
        case ISSUE_FIELDS_ENTITY_NAMES.select:
        case ISSUE_FIELDS_ENTITY_NAMES.multi_select:
          // single selects should be treated as multi-selects for better UX
          return Yup.string();
        case ISSUE_FIELDS_ENTITY_NAMES.rich_content:
        case ISSUE_FIELDS_ENTITY_NAMES.text:
        case ISSUE_FIELDS_ENTITY_NAMES.long_text:
          return Yup.string();
        case ISSUE_FIELDS_ENTITY_NAMES.numeric:
          return Yup.number();
        case ISSUE_FIELDS_ENTITY_NAMES.datetime:
          // Date filters should always be a range.
          return Yup.array()
            .compact()
            .test('is-range', 'Invalid range', value => {
              if (!value) return true;
              return value.length === 0 || value.length === 2;
            });
        default: {
          return Yup.string();
        }
      }
    })();

    return {...allFields, [field.name]: validation};
  }, {});

  const formikInitialValues = useMemo(() => {
    return filterableFields.reduce((ret, field) => {
      let fieldValue = filters[field.name] || '';

      // Transform datetime fields to the correct format for the RangeDatePicker component.
      fieldValue = transformDateRangeToFormikValue({value: fieldValue, isDateRange: field.type === ISSUE_FIELDS_ENTITY_NAMES.datetime});

      return {
        ...ret,
        [field?.name]: fieldValue,
      };
    }, {});
  }, [filterableFields, filters]);

  const formikResetValues = useMemo(() => {
    return filterableFields.reduce((ret, field) => {
      return {
        ...ret,
        [field?.name]: '',
      };
    }, {});
  }, [filterableFields]);

  const formik = useFormik({
    validateOnChange: false,
    validationSchema: Yup.object(formikSchema),
    initialValues: formikInitialValues,
    onSubmit: values => {
      // Transform datetime fields to the correct format for redux state/query params - {from: string; to: string}
      const transformedValues = transformFormikToFilterValues({
        formikValues: values,
        isDateRangeCondition: ({fieldName}) => filterableFields.find(field => field.name === fieldName)?.type === ISSUE_FIELDS_ENTITY_NAMES.datetime,
      });

      updateFilters({filters: transformedValues});
      updateRawFiltersState();
      hideModal();
    },
  });

  useEffect(() => {
    if (isVisible && !prevIsVisible) {
      formik.setValues(formikInitialValues);
      resetTempNewRawFilters();
    }
  }, [isVisible, prevIsVisible, formikInitialValues, formik, resetTempNewRawFilters]);

  const clearForm = () => formik.resetForm({values: formikResetValues});
  const onApplyFilters = () => formik.submitForm();

  return (
    <FiltersModal toggleModal={hideModal} isVisible={isVisible} onClearAll={clearForm} onApplyFilters={onApplyFilters}>
      <Form withoutFormTag classes="paddingBottom-small1">
        {chunk(filterableFields, 2).map(chunkedFields => {
          return (
            <Form.Row key={`${chunkedFields?.[0]?.name}-${chunkedFields?.[1]?.name}`}>
              {chunkedFields.map(field => {
                return (
                  <FieldsGenerator
                    formik={formik}
                    key={field.name}
                    field={field}
                    forceMultiSelect // if the field is single select, force to be multi-select
                    consumer={C => (
                      <Form.Column lg={6} md={8} sm={4}>
                        {C}
                      </Form.Column>
                    )}
                    operationType="filter"
                    onChangeCallback={updateTempRawFilters}
                    id=""
                  />
                );
              })}
            </Form.Row>
          );
        })}
      </Form>
    </FiltersModal>
  );
};

export default IssuesFiltersModal;
