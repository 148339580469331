import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useMutation} from 'react-query';

/* Hooks */
import useApi from 'hooks/useApi';

/* Utils */
import {logger} from 'utils/logger';
import {handleResponseError, showErrorToast} from 'queries/query.utils';

import {MduUseParamsTypes} from '../MDU.types';

type TVerifyUnitNameArgs = {
  projectId?: string | number;
  unitName: string;
};

export const useValidateUnitNameMutation = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const {projectId: projectIdParam} = useParams<MduUseParamsTypes>();

  return useMutation(
    async ({unitName, projectId}: TVerifyUnitNameArgs) => {
      const response = await api.mdu.validateUnitName(
        {project_id: projectId || projectIdParam},
        {
          unit_name: unitName,
        }
      );

      handleResponseError(response);

      return response?.data;
    },
    {
      onError: err => {
        logger('Error validating unit name: ')(err as Error);
        showErrorToast(dispatch)({errors: `Error validating unit name: ${err}`});
      },
    }
  );
};
