export const TEST_IDS = {
  SORT_INDICATORS: 'SORT_INDICATORS',
  SORT_UP: 'SORT_UP',
  SORT_DOWN: 'SORT_DOWN',
  PAGINATION_PREVIOUS_ARROW: 'PAGINATION_PREVIOUS_ARROW',
  PAGINATION_NEXT_ARROW: 'PAGINATION_NEXT_ARROW',
  PAGINATION_PAGE_GUIDE: 'PAGINATION_PAGE_GUIDE',
};

export const COLUMN_SIZE = {
  sm: 152,
  md: 224,
  lg: 304,
};

export const MRT_COLUMN_WIDTH = 60;
export const EXPAND_COLUMN_WIDTH = 48;

export const ACTION_MENU_COL_ID = 'actionMenu';
export const CUSTOM_ROW_SELECT_COL_ID = 'ht-row-select';
