import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import get from 'lodash/get';
import {useFormik} from 'formik';
import {BUTTON_THEMES, Button, Form, Modal, SelectField, SideSheet, htToast, LoaderComponent, Checkbox, ELEMENT_SIZE} from 'ht-styleguide';

/* Hooks */
import {useUnsavedChangesWarning} from 'features/Issues/Parts/SideSheets/SideSheet.Issue.hooks';
import {useSearchParams} from 'hooks/useSearchParam';
import {useProjectEntityBehaviors} from './sideSheet.issue.createIssue.hooks';
// Queries
import {useGetEntityTypesQuery, useGetIssueTemplateFieldsQuery, useGetIssueTemplatesQuery} from 'features/Issues/queries/query.issues.fields';
import {useCreateIssueMutation} from 'features/Issues/queries/mutation.issues.createIssue';

// Types && Ducks
import {TIssueDynamicFields, TIssueTicket, IssuesSearchFieldNames} from 'features/Issues/issues.types';
import CloudinaryDuck from 'features/Cloudinary/state/cloudinary.ducks';
import {SelectOptions} from 'types/base.types';

// Constants & Utils
import {ISSUE_FIELDS_OPERATION_TYPE, ISSUE_PARAMS_TYPES, ISSUE_SLIDESHEET_TYPES, BASE_ISSUES_OPEN_PATH, ISSUE_ENTITY_TYPES} from 'features/Issues/Issues.constants';
import {logger, LoggerErrorType} from 'utils/logger';
import {buildSideSheetQueryParam} from 'features/Issues/Issues.utils';
// Actions
import {notifications} from 'components/Notification/notification.ducks';
// Components
import FieldsGenerator from 'features/Issues/Parts/IssuesFieldsGenerator';
import CloudinaryAttachmentsField from 'features/Cloudinary/components/CloudinaryAttachmentsField';
import {useFormikIssueHelpers} from '../SideSheet.Issue.dataFormatting.Helpers';

type TCreateIssueForm = {
  closeSideSheet: () => void;
  isOpenIssueSideSheet: boolean;
};

const cloudinaryNamespaceCreate = 'create-issue';

const CreateIssueForm = ({closeSideSheet, isOpenIssueSideSheet}: TCreateIssueForm) => {
  /* useRef */
  // these two refs need updating of types (same in the other file)
  const initialValues = useRef<any>({});
  const schema = useRef<any>({});

  /* Local State */
  const [template, setTemplate] = useState<{id: number | string}>({id: ''});
  const [pauseProject, setPauseProject] = useState(false);
  // const [defaultTemplate, set] = useState<number[]>([]);
  // TODO: Setting entity as 'Project' since we only have the one. Refactor when another entity type is introduced
  const TEMP_DEFAULT_ENTITY = ISSUE_ENTITY_TYPES.project;

  /* Hooks */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {getAllParams} = useSearchParams({[ISSUE_PARAMS_TYPES.issue_mode]: ISSUE_SLIDESHEET_TYPES.issue_create});
  const fileSuccessUploads = useSelector(CloudinaryDuck.selectors.getFileSuccessUploads(cloudinaryNamespaceCreate));

  /* Issues Params */
  const {[ISSUE_PARAMS_TYPES.entity_type]: issueEntityType, [ISSUE_PARAMS_TYPES.ticket_id]: issueTicketId} = getAllParams() as unknown as {
    [ISSUE_PARAMS_TYPES.entity_type]: (typeof ISSUE_ENTITY_TYPES)[keyof typeof ISSUE_ENTITY_TYPES];
    [ISSUE_PARAMS_TYPES.ticket_id]: string;
  };

  /* Queries */
  const {data: issueEntityTypesData} = useGetEntityTypesQuery();
  const {data: issueTemplateTypesData} = useGetIssueTemplatesQuery({entity_type: TEMP_DEFAULT_ENTITY});
  const {data: issueTemplateFieldsData} = useGetIssueTemplateFieldsQuery({template_id: get(template, 'id'), excludeFields: ['template_id'], operationType: ISSUE_FIELDS_OPERATION_TYPE.create});
  const {mutate: createIssue} = useCreateIssueMutation();

  /* Methods */
  const redirectToViewIssue = (ticket: TIssueTicket) => {
    navigate(`${BASE_ISSUES_OPEN_PATH}?${buildSideSheetQueryParam({issueMode: ISSUE_SLIDESHEET_TYPES.issue_edit, entityType: ticket.entity_type, ticketId: ticket.id})}`);
  };

  const fireLogger = (error: LoggerErrorType) => logger('Create issue error')(error);

  /* Form Validation */
  const formik = useFormik<TIssueDynamicFields>({
    validateOnChange: false,
    initialValues: initialValues.current as TIssueDynamicFields,
    validationSchema: Yup.object(schema.current),
    onSubmit: async values => {
      const submittedValues = {
        ...values,
        ...(fileSuccessUploads && {attachments: fileSuccessUploads}),
        ...(pauseProject && {pause_project: pauseProject}),
      };

      try {
        createIssue(
          {ticket: submittedValues, pauseProject},
          {
            onSuccess: ({data}) => {
              closeSideSheet();
              htToast(
                <div>
                  Issue created{pauseProject ? ' and project paused' : ''}.{' '}
                  <span className="plainButton teal" onClick={() => redirectToViewIssue(data.ticket)}>
                    View Issue
                  </span>
                </div>,
                {autoClose: false}
              );
            },
            onError: error => {
              fireLogger(error as LoggerErrorType);
              dispatch(notifications.actions.notificationApiError({source: `Could Not Create Issue: (${error})`}));
            },
          }
        );
      } catch (error) {
        fireLogger(error as LoggerErrorType);
      }
    },
  });

  /* Formik Issues Helpers */
  const {setFormikValues, setFormikInitialValues, setFormikSchema} = useFormikIssueHelpers({formik, initialValues, schema});

  const onUpdateTemplateSelection = (selectedTemplate: SelectOptions<string | number>) => {
    initialValues.current = {};
    schema.current = {};
    formik.handleChange({target: {name: 'template_id', value: selectedTemplate.value}});
    setTemplate({id: selectedTemplate.value as number});
  };

  /**
   * @description We need to bundle up and set the values. WE can't do it one by one, else a its max recursion error.
   *              Set the defaults for the formik form, intially. Updated state will be handled by the formik form.
   */
  useEffect(() => {
    if (issueTemplateFieldsData) {
      setFormikSchema({fields: issueTemplateFieldsData});
      setFormikInitialValues({fields: issueTemplateFieldsData});
      setFormikValues({fields: issueTemplateFieldsData});
    }
  }, [issueTemplateFieldsData]);

  /**
   * @description We need to determine the default template to select if we have a issueEntityType.
   *
   * Note: This allows the user to go straight to the create issue form.
   */
  useEffect(() => {
    if (issueTemplateTypesData) {
      const defaultTemplate = issueTemplateTypesData.find(t => t.entity_type === issueEntityType);
      if (defaultTemplate) {
        /* Auto select the default template */
        setTemplate({id: defaultTemplate.id});
      }
    }
  }, [issueEntityType, issueTemplateTypesData]);

  /**
   * Because "template_id" exists in the form, we need to update it when the user selects a template.
   * We need to make sure formik is aligned.
   *
   * @problem: It exists IN the form & is returned in fields. We filter it out. But, this fields
   *           exists outside of the fields, but we need to work it into the process. Its kinda goofy.
   *           Lets be dynamic, but still tethered to outside interests.... :-(
   */
  useEffect(() => {
    if (template?.id) {
      formik.setFieldValue('template_id', template?.id);
      /*
        Custom pre-seeds. Again, hard (to reason about) to incorporate with dynamic fields.
        1. set ticket_id issueTicketId. Currently for Projects, but other entities will be added and should also be captured here.
        2. issueTicketId should be derived from ticket_id in the url.
       */
      if (issueTicketId) {
        formik.setFieldValue('entity_id', [+issueTicketId]);
      }
    }

    /* Clear namespaced cloudinary files for 'create-issue' */
    return () => {
      dispatch(CloudinaryDuck.actions.deleteAllFilesByNamespace({editorNamespace: cloudinaryNamespaceCreate}));
    };
  }, [template?.id, issueTicketId]);

  useEffect(() => {
    if (issueTemplateTypesData) {
      const defaultTemplate = issueTemplateTypesData.find(t => t.default);

      if (defaultTemplate) {
        /* Auto select the default template */
        setTemplate({id: defaultTemplate.id});
      }
    }
  }, [issueTemplateTypesData]);

  const {handleCloseSideSheet, isUnsavedChangesModalVisible, onUnsavedChangesConfirmClick, onUnsavedChangesCancelClick} = useUnsavedChangesWarning(formik.dirty, closeSideSheet);

  /* Project Entity-specific behaviors */
  const {showPauseProjectOpt, disablePauseProjectOpt, isLoadingProjectDetails} = useProjectEntityBehaviors({issueEntity: TEMP_DEFAULT_ENTITY, entityId: formik.values?.entity_id});
  const onChangePauseProject = (value: boolean) => setPauseProject(!value);
  useEffect(() => {
    // reset state when switching between projects
    setPauseProject(false);
  }, [formik.values.entity_id]);

  const disableCreateButton = isLoadingProjectDetails;

  return (
    <SideSheet
      isOpen={isOpenIssueSideSheet}
      hide={handleCloseSideSheet}
      headerText="Create New Issue"
      footerContent={
        <div className="flex justify-content-space-between">
          {showPauseProjectOpt ? (
            <Checkbox
              value={pauseProject}
              checked={Boolean(pauseProject)}
              label="Pause Project"
              onCheckboxChange={onChangePauseProject}
              elementSize={ELEMENT_SIZE.XS}
              disabled={disablePauseProjectOpt}
            />
          ) : (
            <div />
          )}
          <div>
            <Button inlineBlock className="marginRight-tiny" theme={BUTTON_THEMES.SECONDARY} onClick={handleCloseSideSheet}>
              Cancel
            </Button>
            {issueTemplateFieldsData ? (
              <Button inlineBlock className="marginLeft-tiny" theme={BUTTON_THEMES.PRIMARY} disabled={disableCreateButton} onClick={() => formik.submitForm()}>
                Create Issue
              </Button>
            ) : null}
          </div>
        </div>
      }
    >
      <Form>
        <Form.Row>
          {issueTemplateTypesData && issueEntityTypesData ? (
            <>
              {/* These fields dictate the "field" view, so they sit outside yet might inter-ject into formik */}
              {issueEntityTypesData?.length > 1 ? (
                <Form.Column lg={12} md={8} sm={4}>
                  {/* Since we only have "Project", lets suppress it until we have other issues */}
                  <SelectField
                    key={`createIssue-entityType-${issueEntityTypesData?.length || 0}`}
                    label="What kind of issue?"
                    options={issueEntityTypesData}
                    value={TEMP_DEFAULT_ENTITY}
                    onChange={onUpdateTemplateSelection}
                  />
                </Form.Column>
              ) : null}
              {issueTemplateTypesData?.length > 1 ? (
                <Form.Column lg={12} md={8} sm={4}>
                  <SelectField
                    key={`createIssue-templateType-${issueTemplateTypesData?.length || 0}`}
                    label="Issue Category"
                    options={(issueTemplateTypesData || []).map(templateType => ({label: templateType.name, value: templateType.id}))}
                    value={template?.id}
                    onChange={onUpdateTemplateSelection}
                  />
                </Form.Column>
              ) : null}
            </>
          ) : (
            <Form.Column lg={12} md={8} sm={4}>
              <div className="paddingTop-medium">
                <LoaderComponent />
              </div>
            </Form.Column>
          )}
          {issueTemplateFieldsData &&
            issueTemplateFieldsData
              .sort((a, b) => (a.position < b.position ? -1 : 1))
              .map(field => {
                return (
                  <React.Fragment key={field.name}>
                    <FieldsGenerator
                      formik={formik}
                      key={field.name}
                      field={field}
                      consumer={C => (
                        <Form.Column lg={12} md={8} sm={4}>
                          {C}
                        </Form.Column>
                      )}
                      operationType={ISSUE_FIELDS_OPERATION_TYPE.create}
                      id={template?.id as number}
                    />
                    {field.name === IssuesSearchFieldNames.Description ? (
                      <Form.Column lg={12} md={8} sm={4} key={`${field.name}-container`}>
                        <CloudinaryAttachmentsField folder="issues" label="Attachments" namespace={cloudinaryNamespaceCreate} />
                      </Form.Column>
                    ) : null}
                  </React.Fragment>
                );
              })}
        </Form.Row>
      </Form>
      <Modal
        isVisible={isUnsavedChangesModalVisible}
        hide={onUnsavedChangesCancelClick}
        header="Your changes won't be saved"
        footerElement2={
          <Button theme={BUTTON_THEMES.SECONDARY} onClick={onUnsavedChangesCancelClick}>
            Go Back
          </Button>
        }
        footerElement3={
          <Button theme={BUTTON_THEMES.PRIMARY} onClick={onUnsavedChangesConfirmClick}>
            Discard Issue
          </Button>
        }
      >
        <div className="position relative">
          <p className="p1 n900">We won't be able to save your data if you move away from this page.</p>
        </div>
      </Modal>
    </SideSheet>
  );
};

export default CreateIssueForm;
