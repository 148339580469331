import React from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import cn from 'classnames';
import {mduProjectPathByStage} from 'global/paths';
import {Icon} from 'ht-styleguide';
import DataTable from 'components/Elements/DataTable';
import PageHeader from 'components/Elements/PageHeader';
import {useProject, useTable} from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/TemplateJobs.hooks';
import {MduUseParamsTypes} from 'features/MultiDwellingUnits/MDU.types';

import styles from '../templatejobs.styles.scss';

const TemplatesAndJobsPostLaunchServices = () => {
  /* Hooks */
  const navigate = useNavigate();
  const {groupId = '', stage = ''} = useParams<MduUseParamsTypes>();
  const {projectId, projectGroup} = useProject({groupId});
  const {columns, data} = useTable({projectGroup, projectId});

  /* Methods */
  /* Go to templates page */
  const onHandleGoToPostTemplatePage = () => {
    navigate(mduProjectPathByStage(projectId, stage));
  };

  /* Project Not Loaded Yet */
  if (!projectId || !projectGroup) return null;

  return (
    <div className="marginTop-small1 marginBottom-medium2">
      <div onClick={onHandleGoToPostTemplatePage}>
        <Icon className={cn('cursor', styles.arrowIcon)} name="v2-skinny-arrow" />
      </div>
      <PageHeader title={projectGroup.name} />
      <DataTable tableKey="TemplateJobs-PostLaunch-Services" columns={columns} data={data} isZebra enableSorting={false} />
    </div>
  );
};

export default TemplatesAndJobsPostLaunchServices;
