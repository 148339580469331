import {ProjectDetails} from 'features/MultiDwellingUnits/MDU.types';

export const CHECKLIST_GROUPS_CONFIG: {[key: string]: {icon: string; text: string; requirements: {key: number; text: string; subText: string; isComplete: (project: ProjectDetails) => boolean}[]}} = {
  projectDetails: {
    icon: 'list',
    text: 'Project Details',
    requirements: [
      {
        key: 0,
        text: 'Complete all required fields',
        subText: 'A project can not be dispatched until all key project details are filled out.',
        isComplete(project) {
          return project.stats ? project.stats.projectDetails.completed : false;
        },
      },
    ],
  },
  teamManagement: {
    icon: 'team',
    text: 'Team Management',
    requirements: [
      {
        key: 0,
        text: 'Assign a team lead',
        subText: 'The team lead will be responsible for managing team members on-site and through the field service app.',
        isComplete(project) {
          return project.stats ? project.stats.teamManagement.completed : false;
        },
      },
    ],
  },
  templatesJobs: {
    icon: 'layers',
    text: 'Templates & Jobs',
    requirements: [
      {
        key: 0,
        text: 'Determine total unit count',
        subText: 'Total unit count should be a fairly accurate estimate of how many units will be served on the project. Jobs will be generated per unit.',
        isComplete(project) {
          return project.stats ? project.stats.templatesJobs.unitCount.completed : false;
        },
      },
      {
        key: 1,
        text: 'Create a job template',
        subText: 'Every project needs at least one job template to generate jobs.',
        isComplete(project) {
          return project.stats ? project.stats.templatesJobs.jobTemplates.completed : false;
        },
      },
      {
        key: 2,
        text: 'All units assigned',
        subText: 'Units assigned to job templates should match the total unit count for the project.',
        isComplete(project) {
          return project.stats ? project.stats.templatesJobs.unitCountMatchTemplates.completed : false;
        },
      },
    ],
  },
  payment: {
    icon: 'dollar-outline',
    text: 'Payment Settings',
    requirements: [
      {
        key: 0,
        text: 'Choose a payment recipient',
        subText: 'The payment recipient will be billed for the project and will have access to payment history in the field service app.',
        isComplete(project) {
          return project.stats ? project.stats.payment.completed : false;
        },
      },
    ],
  },
};
