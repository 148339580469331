import React from 'react';
import {Button, BUTTON_THEMES, EmptyState} from 'ht-styleguide';

/*
 *  DISPLAY NO PROJECTS, EMPTY, STATE PER PAGE
 *
 *  We need to display a no projects display if the user has no projects within a given page.
 *  So, we'll use query key, by page/intent, and see if we have any cached projects.
 * */
import uiSlice from 'features/ui/ui.ducks';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {NavPageTypes} from 'features/MultiDwellingUnits/MDU.types';
import {useGetProjectsStats} from 'features/MultiDwellingUnits/queries/query.project.stats';
import styles from 'features/MultiDwellingUnits/Pages/Projects/Projects.styles.scss';

export const useDisplayNoProjectsByPage = (projectsPage: NavPageTypes) => {
  const dispatch = useAppDispatch();
  const {data, isFetched, isSuccess} = useGetProjectsStats();
  const noResultsDisplay = isFetched && isSuccess && data?.stats?.total === 0 && projectsPage === NavPageTypes.ALL_STATUSES;

  const EmptyStateNoProjects = () => {
    if (!noResultsDisplay) return null;

    const toggleCreateProjectModal = () => dispatch(uiSlice.actions.setModalVisibility({modal: 'createProject'}));

    return (
      <div className={styles.emptyStateContainer}>
        <EmptyState
          className="paddingTop-medium1"
          showButtonContainer
          title="You have no projects"
          text="Create a new project to get started. You will be able to access and manage your future projects from here."
          iconName="info-outline"
        >
          <Button theme={BUTTON_THEMES.SECONDARY} onClick={toggleCreateProjectModal}>
            New Project
          </Button>
        </EmptyState>
      </div>
    );
  };

  return {
    noResultsDisplay,
    EmptyStateNoProjects,
  };
};
