import React, {useState, useRef, memo} from 'react';
import {MenuListItem, MenuLineItemProps, MenuPanel, MenuUnorderedList} from 'components/Elements/Menu';
import TableToolbarLink, {TTableToolbarLink} from 'components/Table/TableToolbar/TableToolbar.Link';
import useOutsideClick from 'hooks/useOutsideClick';
import styles from './tableToolbar.styles.scss';

type TTableToolbarLinkWithMenu = Pick<TTableToolbarLink, 'label' | 'iconName'> & {
  menuItems: MenuLineItemProps[];
};

const TableToolbarLinkWithMenu = ({label, iconName = 'edit-plain', menuItems = []}: TTableToolbarLinkWithMenu) => {
  const [showMenu, setShowMenu] = useState(false);
  const wrapperRef = useRef(null);

  const closeMenu = () => setShowMenu(false);
  const onLinkClick = () => setShowMenu(!showMenu);

  useOutsideClick(wrapperRef, closeMenu);

  return (
    <span className="position relative" ref={wrapperRef}>
      <TableToolbarLink label={label} iconName={iconName} onClick={onLinkClick} />
      {showMenu && (
        <MenuPanel className={styles.menuPosition}>
          <MenuUnorderedList>
            {menuItems.map(item => {
              const {onClick} = item;
              const modiifedOnClick = () => {
                onClick?.();
                closeMenu();
              };

              return <MenuListItem key={item.children?.toString?.()} {...item} onClick={modiifedOnClick} />;
            })}
          </MenuUnorderedList>
        </MenuPanel>
      )}
    </span>
  );
};

export default memo(TableToolbarLinkWithMenu);
