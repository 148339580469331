import {ComponentProps} from 'react';
import StatWidget from './MDUProjectsStats.StatWidget';

export const buildInProgressStatWidgetProps = ({
  _statKey,
  isViewingOwn,
  defaultLabel,
  onFiltersChange,
}: {
  _statKey: ComponentProps<typeof StatWidget>['statKey'];
  isViewingOwn: boolean;
  onFiltersChange: BaseAnyFunction;
  defaultLabel: string;
}) => {
  const baseStatWidgetProps: ComponentProps<typeof StatWidget> = {
    statKey: _statKey,
    label: defaultLabel,
    onFiltersChange,
  };

  switch (_statKey) {
    case 'in_progress':
      baseStatWidgetProps.prominent = true;
      baseStatWidgetProps.suppressActions = true;
      baseStatWidgetProps.gridColumn = '1/2';
      baseStatWidgetProps.gridRow = '1/3';
      break;
    case 'total_unit_progress':
      baseStatWidgetProps.suppressActions = true;
      baseStatWidgetProps.gridColumn = '2/3';
      baseStatWidgetProps.gridRow = '1/3';
      break;
    case 'starting_today':
      baseStatWidgetProps.gridColumn = '3/4';
      baseStatWidgetProps.gridRow = '1/2';
      break;
    case 'flagged':
      baseStatWidgetProps.gridColumn = isViewingOwn ? '3/5' : '3/4';
      baseStatWidgetProps.gridRow = '2/3';
      break;
    case 'late':
      baseStatWidgetProps.gridColumn = '4/5';
      baseStatWidgetProps.gridRow = '1/2';
      break;
    case 'project_manager_projects_average':
      baseStatWidgetProps.suppressActions = true;
      baseStatWidgetProps.gridColumn = '4/5';
      baseStatWidgetProps.gridRow = '2/3';
      baseStatWidgetProps.hideWidget = isViewingOwn;
      break;
    default:
      break;
  }

  return baseStatWidgetProps;
};
