/* eslint-disable import/order */
import React from 'react';
import cn from 'classnames';
// Types
import {ProjectPayoutAdjustmentTypes, PaymentAdjustmentRadioOptions} from 'features/MultiDwellingUnits/MDU.types';
import {Size} from 'types/base.types';
// Utils
import createNumberMask from 'utils/currency/createNumberMask';
// Components && Styles
import {InputField} from 'ht-styleguide';
import styles from '../modals.styles.scss';

interface IPaymentAdjustmentControlsProps {
  setAdjustmentType: React.Dispatch<React.SetStateAction<ProjectPayoutAdjustmentTypes>>;
  formik: {[key: string]: any};
}

const PaymentAdjustmentControls = ({setAdjustmentType, formik}: IPaymentAdjustmentControlsProps) => {
  const isDollarAdj = formik.values.adjType === ProjectPayoutAdjustmentTypes.Fixed;
  const isPercentageAdj = formik.values.adjType === ProjectPayoutAdjustmentTypes.Percent;
  const isIncreaseMode = formik.values.radioValue === PaymentAdjustmentRadioOptions.Increase;
  const isDecreaseMode = formik.values.radioValue === PaymentAdjustmentRadioOptions.Decrease;

  const isDollarIncrease = isIncreaseMode && isDollarAdj;
  const isPercentageIncrease = isIncreaseMode && isPercentageAdj;
  const isDollarDecrease = isDecreaseMode && isDollarAdj;
  const isPercentageDecrease = isDecreaseMode && isPercentageAdj;

  const symbols = {
    dollarIncrease: '$',
    dollarDecrease: '-$',
    percentIncrease: '%',
    percentDecrease: '-%',
  };

  const dollarStyles = cn(styles.payAdjustButton, {[styles.increase]: isIncreaseMode, [styles.decrease]: isDecreaseMode, [styles.active]: isDollarAdj});
  const percentageStyles = cn(styles.payAdjustButton, {[styles.increase]: isIncreaseMode, [styles.decrease]: isDecreaseMode, [styles.active]: isPercentageAdj});

  /*
     Return value of createNumberMask() is an unexecuted function (e.g., named "function1"). The return value of function1 is a string[]. The mask property
     for <InputField/> will accept the function OR the array, but I found through testing that it will not work properly unless the function is provided.
  */
  const getMaskConfig = (prefix: string, integerLimit = 10) => createNumberMask({integerLimit, allowDecimal: true, prefix});

  const IconComponent = () => {
    const perUnitStyles = cn(styles.iconComponent, {
      n900: formik.values.adjValue,
      n300: !formik.values.adjValue,
    });
    return <span className={perUnitStyles}>per unit</span>;
  };

  const commonInputProps = {
    id: 'adjValue',
    value: formik.values.adjValue,
    error: formik.errors.adjValue,
    onChange: formik.handleChange,
    elementSize: Size.L,
    containerClass: cn(styles.payAdjustInput, 'marginLeft-small1'),
    iconComponent: <IconComponent />,
  };

  /*
    NOTE: Regarding the 4 instances of Input; I tried creating a single Input and updating the mask based on state. As mentioned above
          we pass an unexecuted function to the <InputField/>'s mask prop. The return value of that function does not update when component
          state is updated, and only displays the very first mask it's provided. Creating 4 instances solves this.
  */
  return (
    <div className="flex">
      <div className={styles.payAdjustButtonContainer}>
        <div className={dollarStyles} onClick={() => setAdjustmentType(ProjectPayoutAdjustmentTypes.Fixed)}>
          $
        </div>
        <div className={percentageStyles} onClick={() => setAdjustmentType(ProjectPayoutAdjustmentTypes.Percent)}>
          %
        </div>
      </div>
      {isDollarIncrease && (
        // @ts-expect-error - Styleguide types mask as string, but the underlying component also accepts string|RegExp[] and () => string|RegExp[]
        <InputField mask={getMaskConfig(symbols.dollarIncrease)} {...commonInputProps} placeholder={symbols.dollarIncrease} />
      )}
      {isPercentageIncrease && (
        // @ts-expect-error
        <InputField mask={getMaskConfig(symbols.percentIncrease, 3)} {...commonInputProps} placeholder={symbols.percentIncrease} />
      )}
      {isDollarDecrease && (
        // @ts-expect-error
        <InputField mask={getMaskConfig(symbols.dollarDecrease)} {...commonInputProps} placeholder={symbols.dollarDecrease} />
      )}
      {isPercentageDecrease && (
        // @ts-expect-error
        <InputField mask={getMaskConfig(symbols.percentDecrease, 3)} {...commonInputProps} placeholder={symbols.percentDecrease} />
      )}
    </div>
  );
};

export default PaymentAdjustmentControls;
