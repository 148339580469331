import React, {ComponentProps, useMemo} from 'react';
import ActionBarGroup from 'components/Elements/ActionBarGroup';
import HeaderWithToolbar from 'components/SideSheets/HeaderWithToolbar';
import {HEADER_TOOLBAR_CLOSE_ICON_PRESET} from 'components/SideSheets/HeaderWithToolbar/HeaderWithToolbar.constants';

/**
 * Use if the RightContent of the sidesheet should only have a close icon
 */
export const RightContentCloseOnly: ComponentProps<typeof HeaderWithToolbar>['RightContent'] = ({hide}) => {
  const actions: ComponentProps<typeof ActionBarGroup>['actions'] = useMemo(
    () => [
      {
        ...HEADER_TOOLBAR_CLOSE_ICON_PRESET,
        action: hide,
      },
    ],
    [hide]
  );

  return <ActionBarGroup actions={actions} size="lg" />;
};
