import React, {ComponentProps} from 'react';
import {Form} from 'ht-styleguide';
import {TaskFieldNames} from 'features/Products/types/taskGroups.types';
import {TaskTypesForChecklist, TTaskChecklistFormik} from '../taskChecklist.types';
import {TOGGLE_SETTINGS_PER_TASK} from '../constants/main';
import ToggleWithTooltip from '../ToggleWithTooltip';
import {TOOLTIP_CONTENT} from './toggleSettingsSection.contants';

interface IToggleSettingsSection {
  formik: TTaskChecklistFormik;
  onToggleChange: ComponentProps<typeof ToggleWithTooltip>['onToggleChange'];
  taskType: TaskTypesForChecklist;
}

const ToggleSettingsSection = ({formik, onToggleChange, taskType}: IToggleSettingsSection) => {
  const {canHaveOptionalNoteField, canHaveOptionalPhotoField} = TOGGLE_SETTINGS_PER_TASK[taskType];
  return (
    <>
      {/* Task is required */}
      <Form.Row>
        <Form.Column lg={12} md={8} sm={4}>
          <div className="border-top-3 paddingTop-small1" />
          <ToggleWithTooltip id={TaskFieldNames.RequiredTask} label="Task is required" toggleValue={formik.values.required_task} onToggleChange={onToggleChange} error={formik.errors.required_task} />
        </Form.Column>
      </Form.Row>
      {/* Note field */}
      {canHaveOptionalNoteField && (
        <Form.Row>
          <Form.Column lg={12} md={8} sm={4}>
            <ToggleWithTooltip
              id={TaskFieldNames.ShowOptionalNote}
              label="Show optional note field"
              toggleValue={formik.values.show_optional_note}
              onToggleChange={onToggleChange}
              error={formik.errors.show_optional_note}
              tooltipContent={TOOLTIP_CONTENT.note}
            />
          </Form.Column>
        </Form.Row>
      )}
      {/* Photo field */}
      {canHaveOptionalPhotoField && (
        <Form.Row>
          <Form.Column lg={12} md={8} sm={4}>
            <ToggleWithTooltip
              id={TaskFieldNames.ShowOptionalPhoto}
              label="Show optional photo field"
              toggleValue={formik.values.show_optional_photo}
              onToggleChange={onToggleChange}
              error={formik.errors.show_optional_photo}
              tooltipContent={TOOLTIP_CONTENT.photo}
            />
          </Form.Column>
        </Form.Row>
      )}
    </>
  );
};

export default ToggleSettingsSection;
