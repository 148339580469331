import React from 'react';

// Utils
import cn from 'classnames';
import {orderDetailsPath} from 'global/paths';

// Types
import {Order} from 'features/Profile/profile.types';
import {OrderStatus} from 'features/Orders/types/orders.types';

// Styles
import styles from 'features/Profile/profile.styles.scss';

type OrderLineProps = {
  order: Order;
};

const OrderLine = ({order: {id, status, scopeOfWork, assignedTech}}: OrderLineProps) => {
  const statusStyles = cn('p1', {
    n700: [OrderStatus.Cancelled, OrderStatus.Unserviceable].includes(status as OrderStatus),
    [styles.successGreen]: status === OrderStatus.Completed,
  });

  const formattedTechName = `${assignedTech?.firstName} ${assignedTech?.lastName.charAt(0)}.`;

  return (
    <tr>
      <td>
        <p className="p1 n900">#{id}</p>
      </td>
      <td>
        <p className={statusStyles}>
          {status === OrderStatus.Assigned && formattedTechName} {status}
        </p>
      </td>
      <td>
        <p className="p1 n900">{scopeOfWork}</p>
      </td>
      <td>
        <a href={orderDetailsPath(id)} target="_blank" rel="noreferrer" className="small-link">
          View Order
        </a>
      </td>
    </tr>
  );
};

export default OrderLine;
