/* Query single Unit */
import {useParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import {useDispatch} from 'react-redux';

import useApi from 'hooks/useApi';
import {logger} from 'utils/logger';

import {MDU_PROJECT_QUERY_KEYS} from '../MDU.query.keys';
import {showErrorToast} from 'queries/query.utils';

import {MduUseParamsTypes} from '../MDU.types';

export const useGetSingleUnit = ({unitId}: {unitId: number | string}) => {
  const {projectId = ''} = useParams<MduUseParamsTypes>();
  const dispatch = useDispatch();
  const api = useApi();

  return useQuery(
    MDU_PROJECT_QUERY_KEYS.unit([projectId, unitId]),
    async () => {
      const response = await api.mdu.unit({id: projectId, unitId});

      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Loading Unit Data'});
        throw new Error(response.err);
      }
      return response?.data?.unit;
    },
    {
      enabled: Boolean(unitId),
      onError: response => {
        logger('Side Panel Unit: ')(response as Error);
      },
    }
  );
};
