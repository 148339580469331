import {createAsyncThunk, createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import find from 'lodash/find';
import APIS from 'global/apis';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import {RootState} from 'store';
import {ResponseError} from 'types/base.types';
// data for partners
import {PARTNERS_SKU_CATEGORIES} from 'features/Booking/constants/partners';
import * as TS from './services.types';

/*
*******************************************************
  ASYNC ACTION THUNKS
*******************************************************
*/
export const asyncActions = {
  getAllCategoriesAndSkus: createAsyncThunk<TS.SkuCategoryPayload, TS.PartnersAPIArgs, {rejectValue: ResponseError}>(
    'services/getAllCategoriesAndSkus',
    async ({partner_id, remote}, {rejectWithValue}) => {
      const filterAttributes = pickBy({partner_id, remote}, identity);
      const partnerCustomSku = partner_id && get(PARTNERS_SKU_CATEGORIES, `${partner_id}.sku_categories`);
      const response = (await APIS.booking.skus.filter(filterAttributes)) ?? {};

      /* -------------- PARTNER ADDITION ------------------- */
      // Do we have any partner categories to insert?
      if (partnerCustomSku && get(response, 'data.sku_categories')) {
        // clone so we don't mess with the freeze
        const dupePartnerCustomSku = cloneDeep(partnerCustomSku);
        // this stuff drives me crazy
        // We have to introduce a base filtering, to mimic BE.
        if (remote) {
          dupePartnerCustomSku.forEach((partnerSkus: {products: TS.Sku[]; services: TS.Sku[]}) => {
            const comparator = String(remote) === 'true';
            partnerSkus.products = partnerSkus.products.filter(product => product.remote === comparator);
            partnerSkus.services = partnerSkus.services.filter(service => service.remote === comparator);
          });
        }
        response.data.sku_categories = response.data.sku_categories.concat(dupePartnerCustomSku);
      }
      /* -------------- END PARTNER ADDITION ------------------- */

      try {
        return response.err ? rejectWithValue(response as ResponseError) : (response as TS.SkuCategoryPayload);
      } catch (e) {
        return rejectWithValue(response as ResponseError);
      }
    }
  ),
  getAllPartners: createAsyncThunk<TS.PartnersPayload, void, {rejectValue: ResponseError}>(
    'services/getAllPartners',
    async (_, {rejectWithValue}) => {
      const response = (await APIS.partners.getAll()) ?? {};

      try {
        return response.err ? rejectWithValue(response as ResponseError) : (response as TS.PartnersPayload);
      } catch (e) {
        return rejectWithValue(response as ResponseError);
      }
    },
    {
      condition: (_, {getState}) => {
        const {booking} = getState() as RootState;
        const alreadyFetchedPartners = (booking?.services?.partners ?? []).length;

        return !alreadyFetchedPartners;
      },
    }
  ),
};

/*
*******************************************************
  INITIAL STATE
*******************************************************
*/
const initialState: TS.State = {
  categories: [],
  memberships: [],
  selectedCategory: {
    name: '',
    id: 0,
    products: [],
    services: [],
    type: 'services',
    typeDisplay: [],
  },
  categoryRequestStatus: '',
  partners: [],
  pinRedemptions: {},
  skuRequestStatus: '',
  loading: false,
};

/*
*******************************************************
  CREATE SLICE
*******************************************************
*/

const ServicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setPinRedemptions: (state, action) => {
      const pinRedemptionData = action.payload ?? {};
      state.pinRedemptions = {...state.pinRedemptions, ...pinRedemptionData};
    },
    removePinRedemptions: state => {
      state.pinRedemptions = {};
    },
    chooseSelectedCategory: (state, action) => {
      const {type = 'services', id} = action.payload;
      /*
         Lets run thru the cats and find a match.
         1. handed an id, lets find it
         2. If no id, lets use prev saved by user
         3. If no id, and no prev saved, default to id:1
         4. if no id, no prev saved, no id:1, grab first in array

         note: Order matters in 'matchedConditions'
     */
      const isPlansCategoryType = type === 'plans';
      const currentSelected = state.selectedCategory.id;
      const matchedConditions = isPlansCategoryType ? [id, currentSelected, 1] : [id, currentSelected, 'most-popular'];
      const categoryStateReference = isPlansCategoryType ? 'memberships' : 'categories';

      const [defaultSelectectedCategory] = state.categories;
      const matchedCategory = find<TS.Category | TS.MembershipCategory>(
        state[categoryStateReference],
        cat => cat.id === matchedConditions.find(condition => find<TS.Category | TS.MembershipCategory>(state[categoryStateReference], category => category.id === condition))
      );

      const category = matchedCategory || defaultSelectectedCategory;

      state.selectedCategory = {
        ...(matchedCategory || defaultSelectectedCategory),
        type,
        typeDisplay: get(category, type, []),
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(asyncActions.getAllCategoriesAndSkus.fulfilled, (state, action: PayloadAction<TS.SkuCategoryPayload>) => {
        state.memberships = action.payload.data?.memberships ?? [];
        state.categories = action.payload.data?.sku_categories ?? [];
      })
      .addCase(asyncActions.getAllPartners.fulfilled, (state, action: PayloadAction<TS.PartnersPayload>) => {
        state.partners = action.payload?.data?.partners ?? [];
      });
  },
});

/*
*******************************************************
  EXPORTS
*******************************************************
*/
const getServicesState = (state: RootState) => state.booking.services;
const getServiceStateByKey = (key: keyof TS.State) => (services: TS.State) => {
  return services[key];
};

export const selectors = {
  getKeyInServicesState: (key: keyof TS.State) => createSelector(getServicesState, getServiceStateByKey(key)),
};
export const actions = {...ServicesSlice.actions, ...asyncActions};
export default ServicesSlice.reducer;
