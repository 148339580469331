import React, {lazy, Suspense} from 'react';
import {LoaderComponent} from 'ht-styleguide';
import styles from './loadable.styles.scss';

type Props = {
  [key: string]: any;
};

type ImportFN = () => Promise<{default: React.ComponentType}>;

const Loading = (
  <div className={`paddingY-huge1 ${styles.loadable}`}>
    <LoaderComponent />
  </div>
);

const Loadable = (importFunc: ImportFN, {fallback = Loading} = {fallback: Loading}) => {
  const LazyComponent = lazy(importFunc);

  return (props: Props) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default Loadable;
