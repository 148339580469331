import React, {useState, useCallback, useEffect} from 'react';
import classNames from 'classnames';
import {REMOTE, REMOTE_TRUE} from 'features/Booking/constants/sku';
import {SelectField} from 'ht-styleguide';
import {HelloTechNowMessage, WarningHint, WarningHintIcon} from 'components/Elements/Messages';
import {AdjAmountFormatted, QuestionsAPIByQuestion, Sku} from 'features/Questions/types';
import {BaseAnyFunction, IHash, SelectArrayOptions} from 'types/base.types';
import styles from './qa.styles.scss';

type QADropdown = {
  question: QuestionsAPIByQuestion;
  sku: Sku;
  value: IHash<number>;
  error?: string;
  onChange: BaseAnyFunction;
};

const PreQuestionDropdown: React.FC<QADropdown> = props => {
  const [hintVisible, setHintVisible] = useState<boolean>(false);
  const {question, value, onChange, error, sku} = props;
  const isRemote = question.id === REMOTE;
  const isHintVisible = !!question.hint;
  const getAnswerWarning = () => {
    if (!value || !question) return null;
    let hint = null;
    question.answers.forEach(answer => {
      if (answer.id === value.id) {
        hint = answer.warning;
      }
    });
    return hint;
  };
  const answerWarning = getAnswerWarning();
  const options: SelectArrayOptions = question.answers.map(a => {
    let label = a.text;
    if (a.id === REMOTE_TRUE) {
      if (typeof sku.remoteSavingsFormatted === 'string') {
        label = label.replace('%{SAVING}%', sku.remoteSavingsFormatted);
      }
    }
    if (a.adjAmountFormatted && !a.subsidizedToPartner) {
      const amount = (a.adjAmountFormatted as AdjAmountFormatted)?.formatted ?? a.adjAmountFormatted;

      label += ` (${amount})`;
    }
    return {value: a.id, label};
  });

  useEffect(() => {
    if (hintVisible) {
      setHintVisible(!hintVisible);
    }
  }, [value]);

  const onHintClick = useCallback((event: Event) => {
    event.preventDefault();
    setHintVisible(!hintVisible);
  }, []);

  const renderWarningBox = (message: string | null, visible: boolean) => {
    return (
      <div>
        <WarningHint message={message} visible={visible} />
      </div>
    );
  };

  const renderQuestionHint = () => {
    return renderWarningBox(question.hint, hintVisible);
  };

  const renderQuestionHintIcon = () => {
    const hintIconStyles = classNames(styles.answerHintIcon, styles.answerHintIconInput);
    return (
      <div className={hintIconStyles}>
        <WarningHintIcon onClick={onHintClick} />
      </div>
    );
  };

  return (
    <div className={styles.answer}>
      <div>
        <SelectField
          placeholder="Select"
          options={options}
          label={question.textDirect}
          hint={renderQuestionHint()}
          value={value ? value.id : ''}
          onChange={({value: v}) => onChange(v)}
          clearable={false}
          searchable={false}
          error={error}
          dataTestId={`qa-dropdown-${question.id}`}
        />
        {isRemote && <HelloTechNowMessage />}
      </div>
      {isHintVisible && renderQuestionHintIcon()}
      {answerWarning && renderWarningBox(answerWarning, !!answerWarning)}
    </div>
  );
};

// eslint-disable-next-line react/display-name
export default (props: QADropdown) => {
  return <PreQuestionDropdown {...props} />;
};
