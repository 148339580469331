import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
// Hooks
import {useFormik} from 'formik';
import useApi from 'hooks/useApi';
import {useAppDispatch} from 'hooks/useAppDispatch';
// Utils
import {logger} from 'utils/logger';
import {noop} from 'utils/event';
// Types && Ducks
import {ProjectPaymentAdjRequestObj, ProjectPaymentAdjustment, ProjectPayoutAdjustmentTypes, PaymentAdjustmentRadioOptions, ProjectDetails} from 'features/MultiDwellingUnits/MDU.types';
import {SelectArrayOptions, Size} from 'types/base.types';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {FULFILLED} from 'global/constants/common';

// Components && Styles
import {Button, BUTTON_THEMES, RadioGroup, Radio, SelectField, InputField, Modal} from 'ht-styleguide';
import PaymentAdjustmentControls from 'features/MultiDwellingUnits/Parts/Modals/PaymentAdjustmentModal/mdu.PaymentAdjustmentControls';
import styles from './modals.styles.scss';

type InitialFormValues = {
  radioValue: PaymentAdjustmentRadioOptions;
  adjType: ProjectPayoutAdjustmentTypes;
  incDropdownReason: string | number;
  incOtherReason: string;
  decReason: string;
  adjValue: string;
};

interface IFormFields {
  radioValue: PaymentAdjustmentRadioOptions;
  adjType: ProjectPayoutAdjustmentTypes;
  incDropdownReason: string;
  incOtherReason: string;
  decReason: string;
  adjValue: string;
}
interface IPaymentAdjustmentModalProps {
  isVisible: boolean;
  toggleModal: Function;
  idOfAdjustmentToEdit: number | null;
  project: ProjectDetails;
}

const ADDITIONAL_DROPDOWN_OPTIONS = {OTHER: {label: 'Other', value: -1}};

/** If we're editing an adjustment then use this to initialize the Initial form values */
const getEditAdjustmentConfig = (adj: ProjectPaymentAdjustment): InitialFormValues => {
  if (!adj.id) return {} as InitialFormValues;
  const isIncrease = adj.value > 0;
  const isIncOtherReason = isIncrease && !adj.payoutAdjustmentType?.id && adj.name;
  const getIncDropdownReason = () => {
    if (!isIncrease) return '';
    if (isIncOtherReason) return ADDITIONAL_DROPDOWN_OPTIONS.OTHER.value;
    return adj.payoutAdjustmentType?.id || '';
  };
  return {
    radioValue: isIncrease ? PaymentAdjustmentRadioOptions.Increase : PaymentAdjustmentRadioOptions.Decrease,
    adjType: adj.kind,
    incDropdownReason: getIncDropdownReason(),
    incOtherReason: isIncOtherReason ? adj.name : '',
    decReason: !isIncrease ? adj.name : '',
    adjValue: String(Math.abs(adj.value / 100)),
  };
};

export const MODAL_COPY = 'Adjust tech payout per unit by a fixed dollar amount or percentage of current payout per unit. Any payout adjustment should be negotiated with the tech in advance.';

const ModalPaymentAdjustment = ({isVisible = false, toggleModal = noop, idOfAdjustmentToEdit, project}: IPaymentAdjustmentModalProps) => {
  const dispatch = useAppDispatch();
  const api = useApi(); // This for api requests that don't need context update
  const {id: projectId} = project;
  const [dropdownReasons, setDropdownReasons] = useState<SelectArrayOptions>([]);

  const closeModal = () => {
    toggleModal();
  };

  const {payoutAdjustments = []} = project;
  const adjustmentToEdit = payoutAdjustments.find(adj => adj.id === idOfAdjustmentToEdit) || ({} as ProjectPaymentAdjustment);
  const isEditMode = Boolean(adjustmentToEdit.id);

  const editAdjustmentConfig = getEditAdjustmentConfig(adjustmentToEdit);

  const isOtherReasonSelected = (value: number) => {
    return [ADDITIONAL_DROPDOWN_OPTIONS.OTHER.value].includes(value);
  };

  // TODO: Need to test this with an adjustment which is too much. There is a BE error that needs to be handled
  const submitPaymentAdjustment = async (params: IFormFields) => {
    const valueWithOnlyDigits = params.adjValue.replace(/[^\d.]/g, '');
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const value = isDecreaseMode ? -Number(valueWithOnlyDigits) : Number(valueWithOnlyDigits);
    const incDropdownReason = Number(params.incDropdownReason);
    const requestObj: ProjectPaymentAdjRequestObj = {
      project_payout_adjustment: {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        payout_adjustment_type_id: isIncreaseMode && !isOtherReasonSelected(incDropdownReason) ? incDropdownReason : null,
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        name: (isIncreaseMode && isOtherReasonSelected(incDropdownReason) && params.incOtherReason) || params.decReason,
        kind: params.adjType,
        value: value * 100,
        ...(isEditMode && {id: adjustmentToEdit.id}),
      },
    };
    const apiAction = isEditMode
      ? // ? async () => mduApi.updateProjectPayoutAdjustment({projectId, payoutAdjustmentId: adjustmentToEdit.id, requestObj})
        async () => dispatch(mduProjectsSlice.actions.updateProjectPayoutAdjustment({projectId, requestObj, payoutAdjustmentId: adjustmentToEdit.id}))
      : async () => dispatch(mduProjectsSlice.actions.createProjectPayoutAdjustment({projectId, requestObj}));
    try {
      api.toggleLoader(true);
      const response = await apiAction();
      if (response.type.endsWith(FULFILLED)) {
        toggleModal();
        await dispatch(mduProjectsSlice.actions.fetchProjectDetails({projectId}));
      }
    } catch (error) {
      // handle error
    } finally {
      api.toggleLoader(false);
    }
  };

  // Validation
  const paymentAdjustmentSchema = Yup.object().shape({
    radioValue: Yup.string(),
    adjType: Yup.string(),
    incDropdownReason: Yup.string().when('radioValue', {
      is: value => value === PaymentAdjustmentRadioOptions.Increase,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    incOtherReason: Yup.string().when('incDropdownReason', {
      is: (value: string) => isOtherReasonSelected(Number(value)),
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    decReason: Yup.string().when('radioValue', {
      is: value => value === PaymentAdjustmentRadioOptions.Decrease,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    adjValue: Yup.string().required('Required').matches(/[1-9]/, 'Value cannot be $0'),
  });

  const initialFormValues: InitialFormValues = {
    radioValue: editAdjustmentConfig.radioValue || PaymentAdjustmentRadioOptions.Increase,
    adjType: editAdjustmentConfig.adjType || ProjectPayoutAdjustmentTypes.Fixed,
    incDropdownReason: editAdjustmentConfig.incDropdownReason || '',
    incOtherReason: editAdjustmentConfig.incOtherReason || '',
    decReason: editAdjustmentConfig.decReason || '',
    adjValue: editAdjustmentConfig.adjValue || '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialFormValues,
    validateOnChange: false,
    validationSchema: paymentAdjustmentSchema,
    onSubmit: params => submitPaymentAdjustment(params as IFormFields),
  });

  const clearUserInputs = () => {
    formik.handleChange({target: {name: 'incDropdownReason', value: ''}});
    formik.handleChange({target: {name: 'incOtherReason', value: ''}});
    formik.handleChange({target: {name: 'decReason', value: ''}});
    formik.handleChange({target: {name: 'adjValue', value: ''}});
  };

  const handleRadioChange = (value: string) => {
    formik.handleChange({target: {name: 'radioValue', value}});
    clearUserInputs();
  };

  /* Data */
  const isIncreaseMode = formik.values.radioValue === PaymentAdjustmentRadioOptions.Increase;
  const isDecreaseMode = formik.values.radioValue === PaymentAdjustmentRadioOptions.Decrease;
  /** The SelectField and Input have a height difference of approx 2 pixels. Applying min-height avoids jitter when switching between Increase and Decrease. */
  const minHeightToAvoidJitter = '60px';
  const getButtonText = () => {
    if (isEditMode) return 'Save Changes';
    if (isIncreaseMode) return 'Apply Increase';
    return 'Apply Decrease';
  };

  useEffect(() => {
    (async () => {
      const alreadyFetchedDropdownReasons = Boolean(dropdownReasons.length);
      if (!isVisible || alreadyFetchedDropdownReasons) return;
      try {
        api.toggleLoader(true);
        const response = await api.mdu.getPaymentAdjustmentTypes();
        if (response.err) throw new Error(response.err);
        const {payoutAdjustmentTypes}: {payoutAdjustmentTypes: {name: string; id: number}[]} = response.data;
        const reasons = payoutAdjustmentTypes.map(adj => ({label: adj.name, value: adj.id})).concat(ADDITIONAL_DROPDOWN_OPTIONS.OTHER);
        setDropdownReasons(reasons);
      } catch (error) {
        logger('getPaymentAdjustmentReasons')(error as Error);
      } finally {
        api.toggleLoader(false);
      }
    })();
    return () => {
      if (isVisible) formik.resetForm();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, dropdownReasons]);

  return (
    <Modal
      modalClassName={styles.overflowInitial}
      bodyContainerClasses={styles.overflowInitial}
      elementSize={Size.M}
      isVisible={isVisible}
      hide={closeModal}
      header={`${isEditMode ? 'Edit ' : ''} Payout Adjustment`}
      footerElement2={
        <Button theme={BUTTON_THEMES.SECONDARY} onClick={closeModal}>
          Cancel
        </Button>
      }
      footerElement3={
        <Button theme={BUTTON_THEMES.PRIMARY} type="button" onClick={formik.handleSubmit}>
          {getButtonText()}
        </Button>
      }
    >
      <div className="paddingBottom-small2" />
      <p className="p1 marginBottom-small2">{MODAL_COPY}</p>
      <form>
        <RadioGroup value={formik.values.radioValue} onChange={handleRadioChange} columnClassName="flex marginBottom-small2">
          <Radio label="Increase Payout" value={PaymentAdjustmentRadioOptions.Increase} />
          <Radio label="Decrease Payout" value={PaymentAdjustmentRadioOptions.Decrease} wrapperClass="marginLeft-small" />
        </RadioGroup>
        <div className="marginBottom-small2" style={{minHeight: minHeightToAvoidJitter}}>
          {isIncreaseMode && (
            <>
              <SelectField
                placeholder="Select adjustment type"
                options={dropdownReasons}
                onChange={({value}) => formik.handleChange({target: {name: 'incDropdownReason', value}})}
                elementSize={Size.L}
                id="incDropdownReason"
                value={formik.values.incDropdownReason}
                error={formik.errors.incDropdownReason}
              />
              {isOtherReasonSelected(Number(formik.values.incDropdownReason)) && (
                <InputField
                  containerClass="marginTop-small2"
                  placeholder="Type Something"
                  elementSize={Size.L}
                  id="incOtherReason"
                  value={formik.values.incOtherReason}
                  error={formik.errors.incOtherReason}
                  onChange={formik.handleChange}
                />
              )}
            </>
          )}
          {isDecreaseMode && (
            <InputField placeholder="Enter adjustment type" elementSize={Size.L} value={formik.values.decReason} id="decReason" onChange={formik.handleChange} error={formik.errors.decReason} />
          )}
        </div>

        <PaymentAdjustmentControls setAdjustmentType={value => formik.handleChange({target: {name: 'adjType', value}})} formik={formik} />
      </form>
    </Modal>
  );
};

export default ModalPaymentAdjustment;
