import React from 'react';
import {Icon} from 'ht-styleguide';
import {notifications} from 'components/Notification/notification.ducks';
import {THTDropzoneFileRejectionError, THTDropzoneOnDropRejectedFunction} from 'components/Elements/HTDropzone/HTDropzone.types';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {shortenStringByNumber} from 'utils/string';

/** Custom notification component for Dropzone File rejection */
const Notification = ({concatErrors}: {concatErrors: THTDropzoneFileRejectionError[]}) => {
  const title = concatErrors.length > 1 ? `${concatErrors.length} files have issues and have not been uploaded` : 'Your file(s) could not be uploaded';
  return (
    <div>
      <div className="paddingBottom-tiny1 strong">
        <Icon name="attachment" /> {title}:{' '}
      </div>
      {concatErrors.map((error: {name: string; message: string}) => {
        const displayErrorMessage = `${error.name}: ${error.message}`;
        return (
          <div key={error.name} className="paddingLeft-small2 paddingTop-tiny1">
            {' '}
            &#183; {shortenStringByNumber(displayErrorMessage, 70, '...')}
          </div>
        );
      })}
    </div>
  );
};

type TUseHTDropzoneValidatorHelpersParams = {
  acceptedFileTypes: string[];
};

export const useHTDropzoneValidatorHelpers = ({acceptedFileTypes = []}: TUseHTDropzoneValidatorHelpersParams) => {
  const dispatch = useAppDispatch();

  const reactDropzoneValidator = (file: File): THTDropzoneFileRejectionError | null => {
    if (!acceptedFileTypes.some(type => file.type.startsWith(type))) {
      return {
        name: file.name,
        message: 'File type not accepted.',
        code: 'file-rejection',
      };
    }
    return null;
  };

  const onDropRejected: THTDropzoneOnDropRejectedFunction = rejectedFiles => {
    if (rejectedFiles.length > 0) {
      const concatErrors = rejectedFiles.map(file => file.errors).flat();
      const autoCloseMs = 5000 + concatErrors.length * 1500;
      dispatch(
        notifications.actions.notificationCustomError({
          source: <Notification concatErrors={concatErrors as THTDropzoneFileRejectionError[]} />,
          customizedProps: {autoClose: autoCloseMs, id: `react_dropzone_error${concatErrors.length}`},
        })
      );
    }
  };

  return {
    reactDropzoneValidator,
    onDropRejected,
  };
};
