import React, {ReactNode} from 'react';
import {useSelector} from 'react-redux';
import {HTToastContainer} from 'ht-styleguide';
import Notification, {notify, NotifyType} from 'components/Notification';
import notificationSelector from 'components/Notification/selectors';
import Meta from 'components/Meta';
import {useUserCurrentQuery} from 'queries/User/query.user.current';

interface PrivatePage {
  children: ReactNode;
}

const PrivatePageLayout: React.FC<PrivatePage> = ({children}) => {
  const {type, message, id = '', autoClose} = useSelector(notificationSelector);

  useUserCurrentQuery();

  if (type && message) {
    const t = type as NotifyType;
    if (!notify.isActive(id)) {
      notify[t](message, {
        toastId: id,
        ...(autoClose && {autoClose}),
      });
    }
  }

  return (
    <>
      <Meta />
      {children}

      {/* Error notification - trigger with notify() */}
      <Notification />

      {/* Style guide notification - trigger with htToast() */}
      <HTToastContainer />
    </>
  );
};

export default PrivatePageLayout;
