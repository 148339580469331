import React from 'react';
import {useNavigate} from 'react-router-dom';
import PageStatusBar from 'components/Elements/PageStatusBar';
import {useCurrentProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/CurrentProject.hooks';
import {CurrentProjectStages, ProjectDetails} from 'features/MultiDwellingUnits/MDU.types';
import {isPreLaunchStatus} from 'features/MultiDwellingUnits/MDU.utils';
import {mduProjectPathByStage} from 'global/paths';

export const HEADER_TEXT = "You're ready to launch!";

const ReadyToLaunchStatusBar = () => {
  const navigate = useNavigate();
  const currentProject: ProjectDetails = useCurrentProject();

  const isInPreLaunchStatus = isPreLaunchStatus(currentProject?.status);
  const isLaunchable = Boolean(currentProject?.stats?.completed);
  const shouldRenderComponent = isInPreLaunchStatus && isLaunchable;

  const onCTAClick = () => navigate(mduProjectPathByStage(String(currentProject.id), CurrentProjectStages.LAUNCH_REVIEW));

  return shouldRenderComponent ? <PageStatusBar headerText={HEADER_TEXT} ctaButtonLabel="Review & Launch" ctaButtonOnClick={onCTAClick} /> : null;
};

export default ReadyToLaunchStatusBar;
