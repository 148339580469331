import useApi from 'hooks/useApi';
import APIS from 'global/apis';
import {useMutation, useQueryClient} from 'react-query';
import {TCreateIssuePayload} from 'features/Issues/Parts/SideSheets/SideSheet.Issue.types';
import {ISSUE_ENTITY_TYPES} from 'features/Issues/Issues.constants';
import {useInvalidateProjectsQuery} from 'features/MultiDwellingUnits/MDU.hooks';
import {ISSUES_QUERY_KEYS} from './Issues.query.keys';
import {invalidateTableQueries} from './issues.helpers';

export const useCreateIssueMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const {invalidateProjectDataWithIssues} = useInvalidateProjectsQuery();

  return useMutation(
    async ({pauseProject: _, ...issue}: TCreateIssuePayload) => {
      api.toggleLoader(true);
      const response = await APIS.issues.createIssue(issue);

      if (response.status >= 400 || response.err) {
        throw new Error(`error creating issue: ${issue?.ticket?.summary}`);
      }

      return response;
    },
    {
      onSuccess: async ({data}, {pauseProject}) => {
        const {ticket} = data;
        const {id: ticketId, entity_type, entity_id} = ticket || {};

        if (ticketId) {
          // invalidate ticket fields data. We need to keep things in sync with our page load data
          queryClient.invalidateQueries(ISSUES_QUERY_KEYS.getTicketWithValues(ticketId));
          // invalidate table queries
          invalidateTableQueries(queryClient);
        }
        if (pauseProject && entity_type === ISSUE_ENTITY_TYPES.project && entity_id) {
          // refresh projects data as issue creation can affect project data
          await invalidateProjectDataWithIssues({projectId: entity_id});
        }
      },
      onSettled: () => {
        api.toggleLoader(false);
      },
    }
  );
};
