import {useRef} from 'react';
import {TUtilTableRawFiltersState, TUtilTableUpdateTempRawFilterArgs} from './table.types';

/**
 * Use a temp state to store raw filters before saving to redux.
 *
 * This assumes that `initialRawFilters` is a copy of the redux state for raw filters.
 * For this util to work across features, `initialRawFilters` must be in the same format
 * across the different slices (issues, dispatch, etc).
 */
const useTempRawFilters = (initialRawFilters: TUtilTableRawFiltersState) => {
  const tempRawFiltersRef = useRef({...initialRawFilters});

  /**
   * Generally used when there's a change of filters values in the modal.
   */
  const updateTempRawFilters = ({name, label, values}: TUtilTableUpdateTempRawFilterArgs) => {
    const currentTempRawFilters = {...tempRawFiltersRef.current}; // need to copy to avoid throwing a redux state error?
    const rawFilterByName = currentTempRawFilters[name];

    if (!values || !values.length) {
      delete currentTempRawFilters[name];
    } else if (!rawFilterByName) {
      currentTempRawFilters[name] = {name, label, values};
    } else {
      currentTempRawFilters[name] = {...rawFilterByName, values};
    }

    tempRawFiltersRef.current = currentTempRawFilters;
  };

  const resetTempRawFilters = () => {
    tempRawFiltersRef.current = {...initialRawFilters};
  };

  return {
    tempRawFiltersRef,
    updateTempRawFilters,
    resetTempRawFilters,
  };
};

export default useTempRawFilters;
