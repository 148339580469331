import {useQuery} from 'react-query';
import useApi from 'hooks/useApi';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {showErrorToast} from 'queries/query.utils';
import {logger} from 'utils/logger';
import {TProjectPauseHistoryResponse, ProjectPauseResponse} from '../MDU.types';
import {MDU_PROJECT_QUERY_KEYS} from '../MDU.query.keys';

type TUseGetProjectPauseHistory = {
  projectId: number | null | undefined;
  queryOpts?: Parameters<typeof useQuery<ProjectPauseResponse[], unknown, ProjectPauseResponse[], string[]>>['2'];
};

const useGetProjectPauseHistory = ({projectId, queryOpts = {}}: TUseGetProjectPauseHistory) => {
  const dispatch = useAppDispatch();
  const api = useApi();
  const {enabled, ...restQueryOpts} = queryOpts;

  return useQuery<ProjectPauseResponse[], unknown, ProjectPauseResponse[], string[]>(
    MDU_PROJECT_QUERY_KEYS.getProjectPauseHistory(`${projectId}`),
    async () => {
      const response: TProjectPauseHistoryResponse = await api.mdu.getProjectPauseHistory({project_id: projectId});

      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Retrieving Project Pause History'});
        logger('useGetProjectPauseHistory')(new Error(JSON.stringify(response.data.errors)));
        throw new Error('Error retrieving project pause history');
      }
      return response?.data?.project_pauses;
    },
    {
      enabled: !!projectId && enabled,
      ...restQueryOpts,
    }
  );
};

export default useGetProjectPauseHistory;
