import {useMutation} from 'react-query';
import {useDispatch} from 'react-redux';
import useApi from 'hooks/useApi';
import {showErrorToast} from 'queries/query.utils';
import {TCreateCreditPayload, TCreateCreditResponse} from '../storeCredits.types';

type TUseCreateCreditMutation = {
  mutationOpts?: Parameters<typeof useMutation>[2];
};

const useCreateCreditMutation = ({mutationOpts = {}}: TUseCreateCreditMutation = {}) => {
  const api = useApi();
  const dispatch = useDispatch();

  return useMutation<TCreateCreditResponse['data'], void, TCreateCreditPayload>(async creditParams => {
    api.toggleLoader(true);
    const response: TCreateCreditResponse = await api.credits.createCredit(creditParams);
    api.toggleLoader(false);

    if (response.err) {
      const message = `Error creating store credit: ${creditParams?.credit_name}`;
      showErrorToast(dispatch)({errors: message});
      throw new Error(message);
    }

    return response.data;
  }, mutationOpts);
};

export default useCreateCreditMutation;
