import React, {ReactNode, useMemo} from 'react';
// Utils
import {noop} from 'utils/event';
import {formatFileSize} from 'utils/files';
import {actionMenuColumn} from 'components/Elements/DataTable/utils/columns.utils';
// Components
import {getTableCellWithFileTypeIcon} from 'features/MultiDwellingUnits/Parts/Table/Table.utils';
import DataTable from 'components/Elements/DataTable';
import {TDataTableColumnDef} from 'components/Elements/DataTable/dataTable.types';
import TableActionMenu from 'components/Elements/DataTable/TableActionMenu';
import {sortAlphabeticallyOrNumerically} from 'components/Elements/DataTable/utils/sort.utils';
import {Button, BUTTON_THEMES, Icon} from 'ht-styleguide';

type FileDisplayProps = {
  children?: ReactNode;
  uploadedFiles?: FileList | null;
  onUploadButtonClick?: () => void;
  onRemoveFile?: (fileIndex: number) => void;
};

const FileDisplayTable = ({files, onRemove = noop}: {files: FileList; onRemove: (fileIndex: number) => void}) => {
  const fileData = useMemo(() => {
    return Array.from(files).map((file: File) => file);
  }, [files]);

  type TFile = (typeof fileData)[number];

  const columns = useMemo(() => {
    const columnDefs: TDataTableColumnDef<TFile>[] = [
      {
        accessorKey: 'name',
        header: 'File Name',
        Cell: ({row}) => getTableCellWithFileTypeIcon(row.original),
        sortingFn: sortAlphabeticallyOrNumerically,
        columnWidthMode: 'fill',
        columnWidthSize: 'sm',
      },
      {
        id: 'file.size',
        accessorFn: originalRow => formatFileSize(originalRow.size),
        header: 'File Size',
        sortingFn: sortAlphabeticallyOrNumerically,
        columnWidthMode: 'collapse',
      },
      {
        ...actionMenuColumn<File>(),
        Cell: ({row}) => <TableActionMenu items={[{text: 'Remove', key: row.original.name, onClick: () => onRemove(row.index)}]} />,
      },
    ];
    return columnDefs;
  }, [onRemove]);

  return <DataTable<TFile> tableKey="Mdu-SideSheet-AddNote-FileDisplay" columns={columns} data={fileData} />;
};

const FileDisplay = ({children, uploadedFiles, onUploadButtonClick = noop, onRemoveFile: onRemove = noop}: FileDisplayProps) => {
  return (
    <>
      <Button theme={BUTTON_THEMES.SECONDARY} type="button" onClick={onUploadButtonClick} className="marginBottom-small1" small>
        <Icon name="upload" />
        <span className="marginLeft-tiny1">Upload</span>
      </Button>
      {children}
      {uploadedFiles && <FileDisplayTable files={uploadedFiles} onRemove={onRemove} />}
    </>
  );
};

export default FileDisplay;
