import * as yup from 'yup';
import {TaskTypes} from 'types/tasks.types';
import {UpdateTaskAnswerFieldName, CustomUpdateTaskAnswerFieldName} from './editTaskAnswerSideSheet.types';

/**
 * The maximum file size for task attachments
 */
export const EDIT_TASK_MAX_FILE_SIZE = {
  maxSizeInBytes: 50 * 1014 * 1024, // 50MB
  maxSizeDisplayString: '50MB',
};

const NUMBER_OR_STRING_SCHEMA = (message: string = 'Must be a number or a string') =>
  yup.mixed().test('is-number-or-string', message, value => {
    return typeof value === 'number' || typeof value === 'string';
  });

export const BASE_EDIT_TASK_SCHEMA = yup.object({
  [UpdateTaskAnswerFieldName.Options]: yup.array(),
  [UpdateTaskAnswerFieldName.Response]: yup.string().nullable(),
  [UpdateTaskAnswerFieldName.Attachments]: yup.array(),
  [UpdateTaskAnswerFieldName.DeviceResponse]: yup.mixed().nullable(),
  [CustomUpdateTaskAnswerFieldName.Required]: yup.boolean().required(),
  [CustomUpdateTaskAnswerFieldName.HasIncidentToReport]: yup.boolean().required(),
});

const OPTIONS_TYPE_SCHEMA = BASE_EDIT_TASK_SCHEMA.clone().shape({
  [UpdateTaskAnswerFieldName.Options]: yup.array().when([CustomUpdateTaskAnswerFieldName.Required], (required, schema) => {
    return required ? schema.min(1, 'At least one option is required') : schema;
  }),
});

const RESPONSE_TYPE_SCHEMA = BASE_EDIT_TASK_SCHEMA.clone().shape({
  [UpdateTaskAnswerFieldName.Response]: yup.string().when([CustomUpdateTaskAnswerFieldName.Required], (required, schema) => {
    return required ? schema.required('Response is required') : schema;
  }),
});

const ATTACHMENTS_TYPE_SCHEMA = BASE_EDIT_TASK_SCHEMA.clone().shape({
  [UpdateTaskAnswerFieldName.Attachments]: yup.array().when([CustomUpdateTaskAnswerFieldName.Required], (required, schema) => {
    return required ? schema.min(1, 'At least one attachment is required') : schema;
  }),
});

const DEVICE_RESPONSE_TYPE_SCHEMA = BASE_EDIT_TASK_SCHEMA.clone().shape({
  [UpdateTaskAnswerFieldName.DeviceResponse]: yup.object().when([CustomUpdateTaskAnswerFieldName.Required], required => {
    const baseDeviceSchema = yup
      .object({
        [UpdateTaskAnswerFieldName.DeviceProductMakeId]: yup.mixed().nullable(),
        [UpdateTaskAnswerFieldName.DeviceProductId]: yup.mixed().when([UpdateTaskAnswerFieldName.DeviceProductMakeId], productMakeId => {
          return productMakeId ? NUMBER_OR_STRING_SCHEMA('Product model is required') : yup.mixed().nullable();
        }),
      })
      .nullable();
    return required ? baseDeviceSchema.required('Device is required') : baseDeviceSchema;
  }),
});

/**
 * Force user to enter data for both response and attachments if they have an incident to report
 */
const INCIDENT_TYPE_SCHEMA = BASE_EDIT_TASK_SCHEMA.clone().shape({
  [UpdateTaskAnswerFieldName.Response]: yup.string().when([CustomUpdateTaskAnswerFieldName.HasIncidentToReport], (hasIncidentToReport, schema) => {
    if (hasIncidentToReport) {
      return schema.required('Response is required');
    }
    return schema;
  }),
  [UpdateTaskAnswerFieldName.Attachments]: yup.array().when([CustomUpdateTaskAnswerFieldName.HasIncidentToReport], (hasIncidentToReport, schema) => {
    if (hasIncidentToReport) {
      return schema.min(1, 'At least one attachment is required');
    }
    return schema;
  }),
});

export const EDIT_TASK_ANSWER_YUP_SCHEMA: Record<TaskTypes, yup.ObjectSchema<any>> = {
  [TaskTypes.Confirm]: BASE_EDIT_TASK_SCHEMA,
  [TaskTypes.MultiSelect]: OPTIONS_TYPE_SCHEMA,
  [TaskTypes.SingleSelect]: OPTIONS_TYPE_SCHEMA,
  [TaskTypes.Binary]: OPTIONS_TYPE_SCHEMA,
  [TaskTypes.Photo]: ATTACHMENTS_TYPE_SCHEMA,
  [TaskTypes.Video]: ATTACHMENTS_TYPE_SCHEMA,
  [TaskTypes.Text]: RESPONSE_TYPE_SCHEMA,
  [TaskTypes.Device]: DEVICE_RESPONSE_TYPE_SCHEMA,
  [TaskTypes.Incident]: INCIDENT_TYPE_SCHEMA,
};
