import React from 'react';
import cn from 'classnames';
import {noop} from 'utils/event';
import {ProjectDetails} from 'features/MultiDwellingUnits/MDU.types';
import {Panel, EmptyState, Button, BUTTON_THEMES} from 'ht-styleguide';
import styles from './paymentAdjustment.styles.scss';

export const EMPTY_STATE_TITLE_PRELAUNCH = 'No Payout Adjustments Applied';
export const EMPTY_STATE_TITLE_POSTLAUNCH = 'You have no payout adjustments';
export const EMPTY_STATE_TEXT_PRELAUNCH = 'If the project requires any per unit payout adjustments, you can add them here.';
export const EMPTY_STATE_TEXT_POSTLAUNCH = 'Payout adjustments can be added until the first payout on a project has been made.';

type PaymentAdjustmentEmptyStateProps = {
  onButtonClick?: BaseAnyFunction;
  className?: string;
  project: ProjectDetails;
};

const PaymentAdjustmentEmptyState = ({onButtonClick = noop, className = '', project}: PaymentAdjustmentEmptyStateProps) => {
  const isUserAllowedToEdit = project.unitPaymentStatus === 'none';
  const title = isUserAllowedToEdit ? EMPTY_STATE_TITLE_PRELAUNCH : EMPTY_STATE_TITLE_POSTLAUNCH;
  const subtext = isUserAllowedToEdit ? EMPTY_STATE_TEXT_PRELAUNCH : EMPTY_STATE_TEXT_POSTLAUNCH;
  const iconName = isUserAllowedToEdit ? 'general-stats' : 'dollar-outline';

  return (
    <>
      <p className="h5 n700 marginBottom-medium">Per-Unit Adjustments</p>
      <Panel noShadow largeBorderRadius className={cn(styles.panel, 'padding-large', className)}>
        <EmptyState iconName={iconName} title={title} text={subtext} showButtonContainer={isUserAllowedToEdit}>
          <Button theme={BUTTON_THEMES.SECONDARY} onClick={onButtonClick}>
            Add Payout Adjustment
          </Button>
        </EmptyState>
      </Panel>
    </>
  );
};

export default PaymentAdjustmentEmptyState;
