import React from 'react';
import {Avatar, ELEMENT_SIZE} from 'ht-styleguide';
import cn from 'classnames';
import useDoesImageExist from 'hooks/useDoesImageExist';

import styles from './avataruser.styles.scss';

type AvatarUserProps = {
  isOwner?: boolean;
  name: string;
  size?: ELEMENT_SIZE;
  dataTestId?: string;
  image?: string;
  imageAlt?: string;
  // 36 x 36 custom
  small?: boolean;
  // 48 x 48 custom
  medium?: boolean;
  // This tells us to use the default avatar and not owner/non-owner colors
  defaultColors?: boolean;
};

/**
 * This is a wrapper for the Avatar component that adds some additional functionality.
 * 1. It allows us to pass in a boolean to determine if the avatar is for the owner or not.
 * 2. Custom sizes for small and medium syphoned from the removed MduAvatar component.
 * 3. A defaultColors prop that tells us to use the default avatar and not owner/non-owner colors.
 *
 * @param props
 * @constructor
 */
const AvatarUser = (props: AvatarUserProps) => {
  const {isOwner, small, medium, size, defaultColors, image} = props;
  const imageExists = useDoesImageExist(image);

  /* Determine color scheme. Size is convoluted because...everything does everything */
  const conditional = (owner?: boolean) => (defaultColors || image ? false : owner);
  const avatarClassName = cn({
    [styles.ownerAvatar]: conditional(isOwner),
    [styles.nonOwnerAvatar]: conditional(!isOwner),
    [styles.small]: small && !size,
    [styles.medium]: medium && !size,
  });

  return <Avatar {...props} className={avatarClassName} {...(size && {size})} {...(!imageExists && {image: ''})} />;
};

export default AvatarUser;
