import {useDispatch} from 'react-redux';
import {useMutation} from 'react-query';

/* Hooks */
import useApi from 'hooks/useApi';

/* Utils */
import {logger} from 'utils/logger';
import {handleResponseError, showErrorToast} from 'queries/query.utils';

/**
 * This Duplicates a template
 */

type TParamKeys = {
  projectId: string | number;
  groupId: string | number;
  name?: string;
  withDraft?: boolean;
};

export const useDuplicateTemplateMutation = () => {
  const api = useApi();
  const dispatch = useDispatch();

  return useMutation(
    async (params: TParamKeys) => {
      api.toggleLoader(true);
      const response = await api.mdu.duplicateTemplate(
        {project_id: params.projectId, group_id: params.groupId},
        {
          project_group: {
            ...(params.withDraft && {draft: true}),
          },
        }
      );
      api.toggleLoader(false);
      handleResponseError(response);

      return response?.data?.projectGroup;
    },
    {
      onError: err => {
        logger('Duplicate Template: ')(err as Error);
        showErrorToast(dispatch)({errors: `Error Duplicate Template: ${err}`});
      },
    }
  );
};
