import {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {MRT_TableInstance} from 'material-react-table';
import {useAppDispatch} from 'hooks/useAppDispatch';
import usePrevious from 'hooks/usePrevious';
import {TPagination} from 'components/Elements/DataTable/dataTable.types';
import {SEARCH_PAGINATION_COUNT} from 'features/MultiDwellingUnits/Pages/Projects/Projects.constants';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {NavPageTypes} from 'features/MultiDwellingUnits/MDU.types';

/**
 * This hook provides the necessary data and functions to the <DataTable /> component that also
 * utilizes redux state for storing pagination and bulk actions data.
 */
const useMDUProjectsTableUtil = <TTableData extends Record<string, any> = {}>({tableData, tableType, pageType}: {tableData: TTableData[]; tableType: 'projects' | 'jobs'; pageType?: NavPageTypes}) => {
  const tableInstanceRef = useRef<MRT_TableInstance<TTableData>>(null);
  const dispatch = useAppDispatch();
  /* Selectors */
  const currentPageIndex = useSelector(mduProjectsSlice.selectors.getPaginationPage);
  const itemsPerPage = useSelector(mduProjectsSlice.selectors.getPaginationItemsPerPage);
  const totalPages = useSelector(mduProjectsSlice.selectors.getPaginationTotalPages);
  const selectedRowsByPage = useSelector(mduProjectsSlice.selectors.getBulkActionSelectedFlatRowIdsByPage);
  const allSelectedRows = useSelector(mduProjectsSlice.selectors.getBulkActionSelectedFlatRowIds);
  const bulkSelectAll = useSelector(mduProjectsSlice.selectors.getBulkActionsSelectAll);

  const previousTableData = usePrevious(tableData);
  const previousBulkSelectAll = usePrevious(bulkSelectAll);
  const previousTableType = usePrevious(tableType);
  const previousPageType = usePrevious(pageType);

  /**
   * Used in the row updater function, which needs to know the currently selected rows for
   * the current page to accurately return the new selected state.
   */
  const rowSelectionByPage = selectedRowsByPage.reduce((acc, page) => {
    return {...acc, [page]: true};
  }, {});

  /**
   * Pass to table's state. We're passing all the selected rows (across all pages), so that DataTable's massSelectAll mode
   * works properly. We don't want to pass the selected rows for the current page only, because then the massSelectAll hook
   * will trigger on each pagination page change, which is not what we want.
   */
  const totalRowSelection = (Object.values(allSelectedRows) || []).flat().reduce((acc, id) => {
    return {...acc, [id]: true};
  }, {});

  const paginationData: TPagination = {
    pageIndex: currentPageIndex,
    pageItemCount: itemsPerPage,
    totalPages,
    onPaginationChange: ({pageIndex: _pageIndex, itemCount}) => {
      dispatch(
        mduProjectsSlice.actions.updatePaginationAttributes({
          current_page: _pageIndex,
          items_per_page: itemCount,
        })
      );
    },
    ...(NavPageTypes.SEARCH === pageType ? {pageSelectOptions: [SEARCH_PAGINATION_COUNT]} : {}),
  };

  /**
   * (Projects only) On route page change, empty out selected ids. We'll need to make a few changes
   * if we want to retain selected ids if the user visits the same table without visiting another one.
   * For pagination updates, see `useFilteredProjects` hook.
   */
  useEffect(() => {
    if (tableType !== previousTableType || pageType !== previousPageType) {
      dispatch(mduProjectsSlice.actions.updateClearBulkFlatRows({}));
    }
  }, [tableType, previousPageType, pageType, previousTableType, dispatch]);

  /**
   * (Jobs only) On route page change, empty out selected ids.
   */
  useEffect(() => {
    return () => {
      if (tableType === 'jobs') {
        dispatch(mduProjectsSlice.actions.updateClearAllBulkOperation({}));
      }
    };
  }, [dispatch, tableType]);

  /**
   * On pagination page change, if mass select all is true, then toggle checkboxes on the new page. This hook is
   * listening to changes in tableData data due to race conditions with the query.
   */
  useEffect(() => {
    if (tableData !== previousTableData && previousBulkSelectAll && bulkSelectAll) {
      tableInstanceRef.current?.toggleAllRowsSelected(true);
    }
  }, [tableData, previousTableData, previousBulkSelectAll, bulkSelectAll]);

  /**
   * When select all is turned off, all rows except for the current page should be deselected.
   */
  useEffect(() => {
    if (!bulkSelectAll && previousBulkSelectAll) {
      dispatch(mduProjectsSlice.actions.updateClearBulkFlatRows({retainCurrentPage: true}));
    }
  }, [bulkSelectAll, dispatch, previousBulkSelectAll]);

  /**
   * To be used internally by MRT. If there's a row selection, select all flag is toggled off.
   */
  const onSetMassSelectAll = (value: boolean) => {
    dispatch(mduProjectsSlice.actions.updateSelectAllBulkOperation({selectAll: value}));
  };

  /**
   * Update the selected rows for the current pagination page.
   */
  const onRowChange = (updater: any) => {
    const selectedValues = updater(rowSelectionByPage);
    const selectedIds = Object.keys(selectedValues).filter(key => selectedValues[key]);
    dispatch(mduProjectsSlice.actions.updateBulkOperationDirect({selectedFlatRows: {[currentPageIndex]: selectedIds}}));
  };

  return {
    bulkSelectAll,
    onRowChange,
    onSetMassSelectAll,
    paginationData,
    tableInstanceRef,
    totalRowSelection,
  };
};

export default useMDUProjectsTableUtil;
