import React, {useCallback, useEffect, useMemo, useState} from 'react';
import ReactTooltip from 'react-tooltip';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import dayjs from 'dayjs';
import cn from 'classnames';
import {ELEMENT_SIZE, EmptyState, Icon} from 'ht-styleguide';

/* Hooks */
import {useAppDispatch} from 'hooks/useAppDispatch';
import {usePaginatedQueryJobs} from 'features/MultiDwellingUnits/queries/query.jobs.paginated';

/* Ducks /Slices */
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';

/* Paths & Constants */
import {orderDetailsPath} from 'global/paths';

/* Components */
import PageHeader from 'components/Elements/PageHeader';
import JobsStats from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/Jobs/Jobs.Stats';
import ChipStatus from 'features/MultiDwellingUnits/Parts/Chip';
import UnitSideSheet from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/Jobs/Jobs.Sidebar';
import {CreateJobSideSheet} from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/Jobs/AddJobs/Single/Jobs.Create.Single';
import MDUProjectsFilters from 'features/MultiDwellingUnits/Pages/Projects/Parts/MDUProjectsFilters';
import JobsUnitToolTip from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/Jobs/Jobs.UnitToolTip';
import {convertSnakeToCamelAndCapitalize} from 'features/MultiDwellingUnits/MDU.utils';
import CalloutBoxTableInfo from 'features/MultiDwellingUnits/Parts/Table/CalloutBox';
import MDUBulkEditSideSheet from 'features/MultiDwellingUnits/Parts/SideSheets/BulkEdit';
import {useStickyContainer} from 'components/Elements/StickyContainer/useStickyContainer';
import DataTable from 'components/Elements/DataTable';

/* Utils/Constants */
import {numericDayMonthYear} from 'global/constants/common';
import {actionMenuColumn} from 'components/Elements/DataTable/utils/columns.utils';
import useMDUProjectsTableUtil from 'features/MultiDwellingUnits/Pages/Projects/Parts/MDUProjectsTable/MDUProjectsTable.useMDUProjectsTableUtil';

/* Types */
import {TFilterKey, CustomFilterTypes, MDUStatuses, MDUUnitStatuses, TSingleActionTypes, ProjectDetails, ProjectGroup, TUnit, MduUseParamsTypes} from 'features/MultiDwellingUnits/MDU.types';
import {TDataTableColumnDef} from 'components/Elements/DataTable/dataTable.types';

/* Styles */
import TableStyles from 'features/MultiDwellingUnits/Pages/Projects/Parts/MDUProjectsTable/MDUProjectsTable.styles.scss';
import {useJobsActionsItems} from 'features/MultiDwellingUnits/Pages/CurrentProject/hooks/Jobs.useActionItems';

const JobsPage = () => {
  /* Local State */
  const [selectedGroup, setSelectedGroup] = useState<ProjectGroup | TUnit | null>(null);

  /* Hooks */
  const {StickyContainer} = useStickyContainer({});
  const dispatch = useAppDispatch();
  const {createType = '', projectId = ''} = useParams<MduUseParamsTypes>();
  const currentProject: ProjectDetails = useSelector(mduProjectsSlice.selectors.getCurrentProject);
  const {results, pagination} = usePaginatedQueryJobs();
  const {reopenUnitAction} = useJobsActionsItems();

  /* Constants */
  const filterKeys: TFilterKey[] = ['statuses', 'project_group_ids', 'lastModifiedAt', 'only_flagged', 'search'];
  /* generate group status list for the selection dropdown in filters */
  const groupStatusList = Object.entries(MDUUnitStatuses).map(([, v]) => {
    return {value: v, label: convertSnakeToCamelAndCapitalize(v)};
  });

  /* Get menu list items */
  const getListItems = useCallback(
    (unit: TUnit) => {
      const orderLink = orderDetailsPath(unit.id);
      const actionItems = [];
      /* Menu Action Objects */
      actionItems.push({
        text: 'View in Admin',
        key: 0,
        onClick: () => window.open(orderLink),
      });

      if (unit.status !== MDUUnitStatuses.CANCELLED) {
        actionItems.push({
          text: 'Cannot Complete',
          key: 'cannotComplete',
          onClick: () => {
            dispatch(mduProjectsSlice.actions.setActionItemModalSlide({entity: unit, modalKey: TSingleActionTypes.CANNOT_COMPLETE, modalType: 'Job'}));
          },
        });
      }

      if (unit.status === MDUUnitStatuses.PENDING_APPROVAL) {
        actionItems.push({
          text: 'Approve Job',
          key: 'approveJob',
          onClick: () => {
            dispatch(mduProjectsSlice.actions.setActionItemModalSlide({entity: unit, modalKey: TSingleActionTypes.APPROVE, modalType: 'Job'}));
          },
        });
      }

      if (unit.billing_status !== 'paid' && [MDUUnitStatuses.CANCELLED, MDUUnitStatuses.COMPLETED, MDUUnitStatuses.UNSERVICEABLE].includes(unit.status)) {
        actionItems.push({
          text: 'Reopen Job',
          key: 'reopenJob',
          onClick: () => {
            if (unit.status === MDUUnitStatuses.COMPLETED) {
              // completed status (and not paid) does not need a confirmation
              dispatch(mduProjectsSlice.actions.setActionItemModalSlide({entity: unit, modalKey: TSingleActionTypes.REOPEN, modalType: 'Job'}));
            } else {
              reopenUnitAction({projectId, unitId: unit.id});
            }
          },
        });
      }

      if (unit.billing_status !== 'paid' && currentProject.status !== MDUStatuses.APPROVED) {
        actionItems.push({
          text: 'Delete Job',
          key: 'deleteJob',
          onClick: () => {
            dispatch(mduProjectsSlice.actions.setActionItemModalSlide({entity: unit, modalKey: TSingleActionTypes.DELETE, modalType: 'Job'}));
          },
        });
      }

      return actionItems;
    },
    [currentProject.status, dispatch, projectId, reopenUnitAction]
  );

  useEffect(() => {
    ReactTooltip.rebuild();
    ReactTooltip.hide();
  }, [selectedGroup, results]);

  useEffect(() => {
    /* Are we in 'create' type */
    if (createType === 'create') {
      dispatch(
        mduProjectsSlice.actions.setActionItemModalSlide({
          entity: {},
          modalKey: TSingleActionTypes.ADD_SINGLE_JOB,
          modalType: 'Job',
        })
      );
    }
  }, [createType]);

  type TResults = (typeof results)[number];

  const columns = useMemo(() => {
    const columnsDefs: TDataTableColumnDef<TResults>[] = [
      {
        accessorKey: 'unit_name',
        header: 'Unit',
        Cell: ({row}) => {
          const {unit_name} = row.original;
          return unit_name || <span className="paddingLeft-tiny1">--</span>;
        },
        muiTableBodyCellProps: ({row}) => ({
          onClick: () => setSelectedGroup(row.original),
        }),
        columnWidthMode: 'fill',
        columnWidthSize: 'sm',
      },
      {
        id: 'last_modified_at',
        accessorFn: originalRow => dayjs(originalRow.last_modified_at).format(numericDayMonthYear),
        header: 'Last Modified',
        muiTableBodyCellProps: ({row}) => ({
          onClick: () => setSelectedGroup(row.original),
        }),
        columnWidthMode: 'collapse',
      },
      {
        id: 'project_group.name',
        accessorFn: originalRow => originalRow.project_group?.name ?? '',
        header: 'Job Template',
        Cell: ({row}) => {
          const {id, project_group} = row.original;
          return (
            <div data-for="unitAttributes" data-tip={id}>
              <div className="ellipses cursorPointer">{project_group?.name}</div>
            </div>
          );
        },
        muiTableBodyCellProps: ({row}) => ({
          onClick: () => setSelectedGroup(row.original),
        }),
        columnWidthMode: 'fill',
        columnWidthSize: 'md',
      },
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({row}) => {
          const {status} = row.original;
          return (
            <ChipStatus statusType="job" status={status} size={ELEMENT_SIZE.SMALL}>
              {status}
            </ChipStatus>
          );
        },
        muiTableBodyCellProps: ({row}) => ({
          onClick: () => setSelectedGroup(row.original),
        }),
        columnWidthMode: 'fill',
        columnWidthSize: 'md',
      },
      {
        accessorKey: 'flagged',
        header: 'Flagged',
        Header: <Icon name="flag-outline" className={TableStyles.flag} />,
        Cell: ({row}) => {
          return row.original.flagged ? <Icon name="flag-filled" className={cn(TableStyles.flag, TableStyles.red)} /> : null;
        },
        muiTableBodyCellProps: ({row}) => ({
          className: TableStyles.flagIconTable,
          onClick: () => setSelectedGroup(row.original),
        }),
        columnWidthMode: 'collapse',
      },
      actionMenuColumn<TUnit>({getListItems}),
    ];
    return columnsDefs;
  }, [getListItems]);

  const {tableInstanceRef, bulkSelectAll, onRowChange, onSetMassSelectAll, paginationData, totalRowSelection} = useMDUProjectsTableUtil<TResults>({
    tableData: results,
    tableType: 'jobs',
  });

  /* Normal view with results that forks if no results */
  const BaseResultDisplay = useMemo(() => {
    return !results.length ? (
      <div className="paddingTop-medium">
        <EmptyState title="Your Search Returned No Results" text="Try expanding your search by using other keywords or removing filters." iconName="search" />
      </div>
    ) : (
      <>
        <CalloutBoxTableInfo pagination={pagination!} isJobs rowsLength={results.length} />
        <DataTable<TResults>
          tableKey={`jobs-${projectId}`}
          columns={columns}
          data={results}
          state={{
            rowSelection: totalRowSelection,
          }}
          isZebra
          tableInstanceRef={tableInstanceRef}
          enableRowSelection
          hasActionableRows
          massSelectAll={bulkSelectAll}
          setMassSelectAll={onSetMassSelectAll}
          onRowSelectionChange={onRowChange}
          getRowId={row => row.id.toString()}
          pagination={paginationData}
          showPaginator
        />
        <JobsUnitToolTip />
        <UnitSideSheet setSelectedGroup={setSelectedGroup} selectedGroup={selectedGroup} />
        <MDUBulkEditSideSheet />
      </>
    );
  }, [bulkSelectAll, columns, onRowChange, onSetMassSelectAll, pagination, paginationData, projectId, results, selectedGroup, tableInstanceRef, totalRowSelection]);

  return (
    <div>
      <PageHeader title="Jobs" collapsible>
        <JobsStats className="Components-Grid_escapeMargin" />
      </PageHeader>
      <StickyContainer>
        <MDUProjectsFilters isJobs statusSelectTypeMultiple customStatuses={groupStatusList} pageType={CustomFilterTypes.JOBS} filterKeys={filterKeys} customFilterKey={CustomFilterTypes.JOBS} />
      </StickyContainer>
      {BaseResultDisplay}
      <CreateJobSideSheet />
      <div className="marginBottom-huge" />
    </div>
  );
};

export default JobsPage;
