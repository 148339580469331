import {IHash} from 'types/base.types';

/* ERRORS & MESSAGING */
export const PIN_REDEMPTION_ERROR_REQUIRED = 'A Pin Redemption Code is required.';
export const PARTNER_VENDOR_SOURCES: IHash<string> = Object.freeze({
  INCOMM: 'incomm',
});

/**
 * Consolidate Partner specific information
 */
type Partners = {
  [key: string]: {
    name: string;
    vendor: string;
    skus: number[];
    pinSkus: number[];
    id: number;
  };
};
export const PARTNERS: Partners = {
  Target: {
    name: 'Target',
    vendor: PARTNER_VENDOR_SOURCES.INCOMM,
    skus: [1103, 1104, 1105],
    pinSkus: [1103, 1104, 1105],
    id: 431127,
  },
};

/**
 * This is needed because we need to inject into sku_categories which the Backdend doesn't return.
 */
export const PARTNERS_SKU_CATEGORIES = Object.freeze({
  [PARTNERS.Target.id]: {
    skuIds: PARTNERS.Target.skus,
    pinRedemptionIds: PARTNERS.Target.pinSkus,
    partnerName: PARTNERS.Target.name,
    vendor: PARTNER_VENDOR_SOURCES.INCOMM,
    sku_categories: [
      {
        id: 'most-popular',
        name: 'Most Popular',
        products: [],
        services: [
          {
            /* any valid partner sku can be used. We just need too test for "can_add_cart" */
            id: PARTNERS.Target.skus[0],
            lens: false,
            name: 'Easy Install Card',
            remote: false,
            skuBullets: null,
            startsAtPrice: 'Prepaid',
          },
        ],
      },
    ],
  },
});
