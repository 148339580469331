import React from 'react';

/* Hooks & Ducks */
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useCurrentProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/CurrentProject.hooks';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';

/* Components */
import WidgetDashboard from 'components/Elements/WidgetDashboard';

/* Constants & Utils */
import {pluralize} from 'utils/text';
import {STAT_JOBS_PROPS} from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/Jobs/Jobs.constants';

/* Types */
import {CustomFilterTypes} from 'features/MultiDwellingUnits/MDU.types';
import {IHash} from 'types/base.types';

/**
 * This component is borrowed on another page that does not want filters.
 * So, lets lets it tell us to apply no actions to the Widget.
 */
const JobsStats = ({
  suppressActions = false,
  className,
  noHorizontalPadding,
  noVerticalPadding,
}: {
  suppressActions?: boolean;
  className?: string;
  noHorizontalPadding?: boolean;
  noVerticalPadding?: boolean;
}) => {
  const {progress = {}} = useCurrentProject();
  const dispatch = useAppDispatch();
  const {flagged, complete, unserviceable, pendingApproval, projectProgress} = STAT_JOBS_PROPS;

  const {
    completedUnitsTotal = 0,
    readyUnitsPercent = 0,
    needsApprovalUnitsTotal = 0,
    unserviceableUnitsTotal = 0,
    unserviceableUnitsPercent = 0,
    flaggedUnitsTotal = 0,
    unitsTotal = 0,
    readyUnitsTotal = 0,
  } = progress || {};

  const action = (value: IHash<any>) => ({
    actionIcon: 'filter',
    actionOnClick: () => {
      dispatch(mduProjectsSlice.actions.updateCustomFilter({key: CustomFilterTypes.JOBS, filters: value}));
    },
  });
  const completedDisplay = `${completedUnitsTotal || 0}/${unitsTotal || 0}`;
  const overallCompletedDisplay = `${readyUnitsTotal || 0}/${unitsTotal || 0}`;

  const projProgress = {
    segments: [
      {
        label: 'Completed',
        percentage: readyUnitsPercent || 0,
        color: '#00cf7f',
      },
      {
        label: 'Unserviceable',
        percentage: unserviceableUnitsPercent || 0,
        color: '#e64f49',
      },
    ],
  };

  return (
    <WidgetDashboard className={className} data-testid="project_jobs_stats" numCols={3} numRows={2} noHorizontalPadding={noHorizontalPadding} noVerticalPadding={noVerticalPadding}>
      <WidgetDashboard.Widget suppressActions {...projectProgress.spread} progress={projProgress} value={overallCompletedDisplay} gridColumn="1/3" gridRow="1/2" />
      <WidgetDashboard.Widget
        suppressActions={suppressActions}
        {...complete.spread}
        {...action(complete.filters)}
        value={`${completedDisplay} ${pluralize('Job', unitsTotal)}`}
        gridColumn="3/4"
        gridRow="1/2"
      />
      <WidgetDashboard.Widget
        suppressActions={suppressActions}
        {...unserviceable.spread}
        {...action(unserviceable.filters)}
        value={`${unserviceableUnitsTotal} ${pluralize('Job', unserviceableUnitsTotal)}`}
        gridRow="2/3"
      />
      <WidgetDashboard.Widget suppressActions={suppressActions} {...flagged.spread} {...action(flagged.filters)} value={`${flaggedUnitsTotal} ${pluralize('Job', flaggedUnitsTotal)}`} gridRow="2/3" />
      <WidgetDashboard.Widget
        suppressActions={suppressActions}
        {...pendingApproval.spread}
        {...action(pendingApproval.filters)}
        value={`${needsApprovalUnitsTotal} ${pluralize('Job', needsApprovalUnitsTotal)}`}
        gridRow="2/3"
      />
    </WidgetDashboard>
  );
};

export default JobsStats;
