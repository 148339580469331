import {createAsyncThunk, createSlice, PayloadAction, createSelector} from '@reduxjs/toolkit';
import APIS from 'global/apis';
import {authToken} from 'utils/auth';
import {ADMIN} from 'global/constants/common';
import * as TS from './login.types';

/*
 ************ ACTIONS
 */
export const actions = {
  loginAdministrator: createAsyncThunk('auth/loginAdministrator', async ({email, password}: TS.AuthArguments, {rejectWithValue}) => {
    const response = (await APIS.users.login({email, password, type: ADMIN})) ?? {};

    try {
      return response.err ? rejectWithValue({errors: ['The email & password you entered did not match our records']} as ResponseError) : (response as TS.ResponseData);
    } catch (e) {
      return rejectWithValue(response as ResponseError);
    }
  }),
};

/*
 ********* INITIAL STATE
 */
const initialState: TS.State = {
  token: '',
  requestStatus: '',
  loading: false,
  error: null,
  redirect: '',
  user: null,
};
/*
 ************ SLICE
 */
const authAdministratorSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logoutAdministrator: () => {
      authToken.removeAuthToken();
      return initialState;
    },
    setTokenAdministrator: (state, action) => {
      state.token = action.payload?.token ?? '';
    },
    setUserInfo: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(actions.loginAdministrator.pending, state => {
      state.loading = true;
      state.requestStatus = 'pending';
    });
    builder.addCase(actions.loginAdministrator.fulfilled, (state, action: PayloadAction<TS.ResponseData>) => {
      const token = action.payload?.data?.auth_token;
      state.token = token;
      state.loading = false;
      state.requestStatus = 'fulfilled';
      authToken.setAuthToken(token);
    });
    builder.addCase(actions.loginAdministrator.rejected, state => {
      state.loading = false;
      state.requestStatus = 'rejected';
    });
  },
});

/*
 ************ SELECTORS
 */
const getAuthState = (state: any) => state.auth as TS.State;

const selectors = {
  getUser: createSelector(getAuthState, state => state.user),
};

/*
 ************ EXPORTS
 */
export const login = {
  actions: {...authAdministratorSlice.actions, ...actions},
  reducer: authAdministratorSlice.reducer,
  selectors,
};
