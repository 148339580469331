import {useEffect} from 'react';
import {useAppDispatch} from 'hooks/useAppDispatch';
import usePrevious from 'hooks/usePrevious';
import {useSelector} from 'hooks/useAppSelector';
import issuesSlice from '../Issues.ducks';
import useIssuesRouteParams from './useIssuesRouteParams';

/**
 * Reset some table states when filters/search fields change. Depending on filters, list of search fields
 * will change. To avoid issues (sorting on non-existing fields, etc), we're resetting some states.
 */
const useResetIssuesTableStates = () => {
  const dispatch = useAppDispatch();
  const searchFieldKeysPerPage = useSelector(issuesSlice.selectors.getPagesSearchFieldKeys);
  const {issuesNavType} = useIssuesRouteParams();

  const previousIssuesNavType = usePrevious(issuesNavType);
  const previousSearchFieldKeysPerPage = usePrevious(searchFieldKeysPerPage);

  useEffect(() => {
    if (previousIssuesNavType !== issuesNavType) {
      return;
    }

    const previousSearchFieldKeys = previousSearchFieldKeysPerPage?.[issuesNavType];
    const searchFieldKeys = searchFieldKeysPerPage[issuesNavType];

    if (!!previousSearchFieldKeys && previousSearchFieldKeys !== searchFieldKeys) {
      dispatch(issuesSlice.actions.updateColumnSort({issuesNavType, columnSort: []}));
      dispatch(issuesSlice.actions.updateColumnGrouping({issuesNavType, columnGroupingKey: null}));
    }
  }, [dispatch, issuesNavType, previousIssuesNavType, previousSearchFieldKeysPerPage, searchFieldKeysPerPage]);
};

export default useResetIssuesTableStates;
