import React, {useEffect, useRef, useMemo} from 'react';
import cn from 'classnames';
import {Form, Button, BUTTON_THEMES, EmptyState, InputField, Icon, CalloutBox, CalloutBoxThemes} from 'ht-styleguide';
import {TaskFieldNames} from 'features/Products/types/taskGroups.types';
import usePrevious from 'hooks/usePrevious';
import {TASK_TYPE_OPTS, SHARED_FIELD_ERRORS} from '../constants/main';
import ToggleWithTooltip from '../ToggleWithTooltip';
import {IMultiOptionsProps, IEmptyOptionsState} from './optionsBuilder.types';
import {BASE_OPTION} from './optionsBuilder.constants';
import styles from './optionsBuilder.styles.scss';

/**
 ************************************************************************
 * <EmptyOptionsState />
 ************************************************************************
 */
const EmptyOptionsState = ({onClick}: IEmptyOptionsState) => (
  <Form.Row>
    <Form.Column lg={12} md={8} sm={4}>
      <EmptyState iconName="list" showButtonContainer text="Add options for the user to select from" title="This selector has no content">
        <Button theme={BUTTON_THEMES.PRIMARY} onClick={onClick}>
          Add a selection
        </Button>
      </EmptyState>
    </Form.Column>
  </Form.Row>
);

/**
 ************************************************************************
 * <MultiOptionsBuilder />
 *
 * - The actual component that builds a list of options. The worker will be
 * presented with the list in the worker app as either checkboxes or a dropdown
 * depending on task type
 * - The user must provide at least two options. Check yup scehma for validation.
 * - The "other" option will count towards the minimum of 2 options.
 * - Focus input on the first empty option on initial render and when a new option is added.
 * - Note special behaviors on input's onBlur and onKeyDown events.
 ************************************************************************
 */
const MultiOptionsBuilder = ({formik, onToggleChange, taskType}: IMultiOptionsProps) => {
  const hasRenderedOnce = useRef(false); // check if the component has rendered once
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]); // store input refs of options

  // Doesn't do much but to remove eslint warnings on useEffect dependencies
  const options = useMemo(() => formik.values.options || [], [formik.values.options]);

  /**
   * - Focus on the first empty option on initial render.
   * - Focus on the last option when a new option is added.
   */
  const previousOptions = usePrevious(options);
  useEffect(() => {
    if (!hasRenderedOnce.current) {
      const firstEmptyOptionIndex = options.findIndex(option => !option.description);
      if (firstEmptyOptionIndex !== -1) {
        inputRefs.current[firstEmptyOptionIndex]?.focus();
      }
      hasRenderedOnce.current = true;
    } else if ((previousOptions || []).length < options.length) {
      inputRefs.current[options.length - 1]?.focus();
    }
  }, [options, previousOptions, hasRenderedOnce]);

  const addBlankOption = () => formik.setFieldValue(TaskFieldNames.Options, [...options, BASE_OPTION]);

  const removeOption = (index: number) => () => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    formik.setFieldValue(TaskFieldNames.Options, newOptions);
  };

  const onInputChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newOptions = [...options];
    newOptions[index] = {
      ...(newOptions[index] ?? {}),
      description: e.target.value,
    };
    formik.setFieldValue(TaskFieldNames.Options, newOptions);
  };

  // Remove the option if the user leaves the input field empty
  const onInputBlur = (index: number) => () => {
    if (!options[index].description) {
      removeOption(index)();
    }
  };

  // Add a new option when the user presses Enter
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      if (options.every(option => option.description)) {
        addBlankOption();
      }
    }
  };

  const addSelectionButtonIsDisabled = useMemo(() => options.some(option => !option.description), [options]);
  const taskTypeName = useMemo(() => TASK_TYPE_OPTS.find(taskTypeOpt => taskTypeOpt.value === taskType)?.label, [taskType]);
  const isMinimumOptionsError = formik.errors?.options === SHARED_FIELD_ERRORS.min2Options;

  return (
    <>
      {options.map((option, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Form.Row key={`${index}`}>
            <Form.Column lg={12} md={8} sm={4}>
              <div className="flex align-items-center justify-content-space-between">
                <InputField
                  ref={el => {
                    inputRefs.current[index] = el;
                  }}
                  containerClass="fullwidth"
                  value={option.description}
                  onBlur={onInputBlur(index)}
                  onChange={onInputChange(index)}
                  onKeyDown={onKeyDown}
                />
                <Icon onClick={removeOption(index)} className={cn('marginLeft-small', styles.closeIcon)} name="v2-close-icon" />
              </div>
            </Form.Column>
          </Form.Row>
        );
      })}
      <Form.Row>
        <Form.Column lg={12} md={8} sm={4}>
          <Button disabled={addSelectionButtonIsDisabled} onClick={addBlankOption} theme={BUTTON_THEMES.SECONDARY}>
            Add a selection
          </Button>
        </Form.Column>
      </Form.Row>
      <Form.Row>
        <Form.Column lg={12} md={8} sm={4}>
          <ToggleWithTooltip
            id={TaskFieldNames.IncludeOtherOption}
            label='Include "Other" option'
            onToggleChange={onToggleChange}
            toggleValue={formik.values.include_other_option}
            error={formik.errors.include_other_option}
          />
        </Form.Column>
      </Form.Row>
      {isMinimumOptionsError && (
        <Form.Row>
          <Form.Column lg={12} md={8} sm={4}>
            <CalloutBox text={`${taskTypeName} tasks require a minimum of 2 options.`} theme={CalloutBoxThemes.CRITICAL} />
          </Form.Column>
        </Form.Row>
      )}
    </>
  );
};

/**
 ************************************************************************
 * <MultiOptions />
 *
 * - Base component to determine which view to use based on the number of options.
 * - Used for multi-select and single-select task types.
 ************************************************************************
 */

const MultiOptions = ({formik, onToggleChange, taskType}: IMultiOptionsProps) => {
  const options = formik.values.options || [];
  /**
   * Seed the options array with 1 item to render <MultiOptionsBuilder />
   */
  const seedOptions = () => formik.setFieldValue(TaskFieldNames.Options, [BASE_OPTION]);

  return options.length ? <MultiOptionsBuilder formik={formik} onToggleChange={onToggleChange} taskType={taskType} /> : <EmptyOptionsState onClick={seedOptions} />;
};

export default MultiOptions;
