/* eslint-disable camelcase */
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import {AnyAction, createAsyncThunk, createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';

// Constants
import {USCS} from 'global/constants/common';
// APIS
import API from 'global/apis';
// Ducks
import * as requestLoaderSlice from 'features/App/RequestLoader/requestLoader.ducks';

// Types
import {RootState} from 'store';
import {IHash, ResponseError} from 'types/base.types';
import * as TS from './membership.types';

type CancelReasonParams = {
  id: string;
  subscription_cancellation_reason_id: number | string;
  amount?: number;
};

type AutoRenewCancelReasonParams = {
  id: string;
  should_renew: boolean;
  reason?: number | string;
  discount?: number;
};

type ExternalSupportParams = {
  id: string;
  external_enrollment: boolean;
};

/*
*******************************************************
  ASYNC ACTIONS
*******************************************************
*/
// Remove keys with no values
const sanitizedValues = (apiParams: IHash<any>) => omitBy({...apiParams}, isNil);

export const asyncActions = {
  /* load subscription data */
  getClientSubscription: createAsyncThunk<TS.ResponseMembershipState, {id: string}, {rejectValue: ResponseError; state: RootState}>(
    'membership/getClientSubscription',
    async ({id}, {rejectWithValue, dispatch}) => {
      dispatch(requestLoaderSlice.actions.loading(true));
      const response = (await API.subscriptions.client({id})) ?? {};
      dispatch(requestLoaderSlice.actions.loading(false));

      try {
        return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.ResponseMembershipState);
      } catch (e) {
        return rejectWithValue(response as ResponseError);
      }
    }
  ),

  /*
   * enable/disable auto renew,
   *  {params}
   *     should_renew: boolean;
   *     Partial<
   *       reason: ;
   *       discount: ;
   *
   *     Note: reason` and `discount` could be used in case we want to use upsell option for them
   * */
  setAutoRenewSubscription: createAsyncThunk<TS.ResponseMembershipState, AutoRenewCancelReasonParams, {rejectValue: ResponseError; state: RootState}>(
    'membership/setAutoRenewSubscription',
    async (params, {rejectWithValue, dispatch}) => {
      const {id} = params;
      dispatch(requestLoaderSlice.actions.loading(true));
      const response = (await API.subscriptions.setAutoRenew({id}, sanitizedValues(params))) ?? {};
      dispatch(requestLoaderSlice.actions.loading(false));

      try {
        return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.ResponseMembershipState);
      } catch (e) {
        return rejectWithValue(response as ResponseError);
      }
    }
  ),

  /*
    To refund
    {param}
       amount: number
  */
  setRefundSubscription: createAsyncThunk<TS.Subscription, {id: string; amount: string | number}, {rejectValue: ResponseError; state: RootState}>(
    'membership/setRefundSubscription',
    async (params, {rejectWithValue, dispatch}) => {
      dispatch(requestLoaderSlice.actions.loading(true));

      const response = (await API.subscriptions.refund({id: params.id}, sanitizedValues(params))) ?? {};
      dispatch(requestLoaderSlice.actions.loading(false));

      try {
        return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.Subscription);
      } catch (e) {
        return rejectWithValue(response as ResponseError);
      }
    }
  ),

  /*
  To charge
  {param}
     amount: number
*/
  setChargeSubscription: createAsyncThunk<TS.Subscription, {id: string; amount: number}, {rejectValue: ResponseError; state: RootState}>(
    'membership/setChargeSubscription',
    async (params, {rejectWithValue, dispatch}) => {
      dispatch(requestLoaderSlice.actions.loading(true));
      const response = (await API.subscriptions.charge({id: params.id}, {amount: params.amount})) ?? {};
      dispatch(requestLoaderSlice.actions.loading(false));

      try {
        return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.Subscription);
      } catch (e) {
        return rejectWithValue(response as ResponseError);
      }
    }
  ),

  /*
    {params}: Partial<
         amount?: number;
         subscription_cancellation_reason_id?: number;
         other_reason?: string;
       >
  */
  setCancelSubscription: createAsyncThunk<TS.Subscription, CancelReasonParams, {rejectValue: ResponseError; state: RootState}>(
    'membership/setCancelSubscription',
    async (params, {rejectWithValue}) => {
      const {id} = params;

      const response = (await API.subscriptions.cancel({id}, sanitizedValues(params))) ?? [];

      try {
        return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.Subscription);
      } catch (e) {
        return rejectWithValue(response as ResponseError);
      }
    }
  ),

  /*
   * Plan/Membership Cancel Reasons
   * */
  cancelReasons: createAsyncThunk<unknown, void, {rejectValue: ResponseError; state: RootState}>(
    'membership/cancelReasons',
    async (_, {rejectWithValue, dispatch}) => {
      dispatch(requestLoaderSlice.actions.loading(true));
      const response = (await API.subscriptions.cancelReasons()) ?? [];
      dispatch(requestLoaderSlice.actions.loading(false));

      try {
        return response.err ? rejectWithValue(response.err as ResponseError) : (response as unknown);
      } catch (e) {
        return rejectWithValue(response as ResponseError);
      }
    },
    {
      condition: (_, {getState}) => {
        const {
          memberships: {cancelAutoRenewReasons},
        } = getState() as RootState;

        return !cancelAutoRenewReasons?.length;
      },
    }
  ),

  /*
    Get User Info (admin info)
  */
  getUserCurrent: createAsyncThunk<unknown, void, {rejectValue: ResponseError; state: RootState}>('user/getUserCurrent', async (_params, {rejectWithValue, dispatch}) => {
    dispatch(requestLoaderSlice.actions.loading(true));
    const response = (await API.users.current()) ?? {};
    dispatch(requestLoaderSlice.actions.loading(false));

    try {
      return response.err ? rejectWithValue(response.err as ResponseError) : (response as unknown);
    } catch (e) {
      return rejectWithValue(response as ResponseError);
    }
  }),

  /**
   * Subscribe to Membership Support to third party: Support.com/Defense for offering remote tech support
   */
  setExternalEnrollment: createAsyncThunk<TS.ResponseMembershipState, ExternalSupportParams, {rejectValue: ResponseError; state: RootState}>(
    'membership/setExternalEnrollment',
    async (params, {rejectWithValue, dispatch}) => {
      const {id} = params;
      dispatch(requestLoaderSlice.actions.loading(true));
      const response = (await API.subscriptions.setExternalEnrollment({id}, sanitizedValues(params))) ?? {};
      dispatch(requestLoaderSlice.actions.loading(false));

      try {
        return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.ResponseMembershipState);
      } catch (e) {
        return rejectWithValue(response as ResponseError);
      }
    }
  ),
};

/*
 ********* INITIAL STATE
 */
const initialState: TS.MembershipState = {
  modal: null,
  cancelMembershipReasons: [],
  cancelAutoRenewReasons: [],
  uscsManager: false,
  totalPaymentsForTerm: {value: 0, formatted: '$0'},
  discountsAccured: {value: 0, formatted: '$0'},
  refundsForTerm: {value: 0, formatted: '$0'},
} as TS.MembershipState;

/*
 ************ SLICE
 */
const membershipSlice = createSlice({
  name: 'membershipContext',
  initialState,
  reducers: {
    closeModal: state => {
      state.modal = null;
    },

    openModal: (state, action) => {
      state.modal = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(asyncActions.cancelReasons.fulfilled, (state, action: PayloadAction<any>) => {
        const {cancellationReasons = [], renewCancellationReasons = []} = action?.payload?.data?.reasons;

        state.cancelAutoRenewReasons = renewCancellationReasons;
        state.cancelMembershipReasons = cancellationReasons;
      })
      .addCase(asyncActions.getUserCurrent.fulfilled, (state, action: PayloadAction<any>) => {
        const {user = {}} = action?.payload?.data;

        state.uscsManager = user.role === USCS;
      })
      .addMatcher(
        (action): action is AnyAction =>
          [
            asyncActions.getClientSubscription.fulfilled,
            asyncActions.setRefundSubscription.fulfilled,
            asyncActions.setChargeSubscription.fulfilled,
            asyncActions.setCancelSubscription.fulfilled,
            asyncActions.setAutoRenewSubscription.fulfilled,
            asyncActions.setExternalEnrollment.fulfilled,
          ].some(actionCreator => actionCreator.match(action)),
        (state, action: AnyAction) => {
          const {data: {subscription = {}} = {}} = action.payload;
          return {...state, ...subscription};
        }
      );
  },
});

/*
*******************************************************
  SELECTORS
*******************************************************
*/
const getMembershipState = (state: RootState) => state.memberships;
const getMembershipStateByKey = (key: keyof TS.MembershipState) => (memberships: TS.MembershipState) => {
  return memberships?.[key];
};

const getMembershipStateSelector = createSelector(getMembershipState, membership => membership);
const getKeyInUserStateSelector = (key: keyof TS.MembershipState) => createSelector(getMembershipStateSelector, getMembershipStateByKey(key));

/*
*******************************************************
  EXPORTS
*******************************************************
*/

export const membershipSelectors = {
  getMembershipState: getMembershipStateSelector,
  getKeyInMembershipState: getKeyInUserStateSelector,
};

const membershipActions = {...asyncActions, ...membershipSlice.actions};
const membershipInitialState = initialState;

export {membershipActions, membershipInitialState};
export default membershipSlice.reducer;
