import React, {ReactNode, useCallback, useMemo, useState, useEffect, ComponentProps} from 'react';
import {Button, Form, Modal, BUTTON_THEMES} from 'ht-styleguide';
import {useSelector} from 'react-redux';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import unset from 'lodash/unset';
import pick from 'lodash/pick';
import {useFormik} from 'formik';
import * as Yup from 'yup';

// Form Fields / Components
import RangeDatePicker from 'components/Elements/RangeDatePicker';
import TableToolbar from 'components/Table/TableToolbar';
import PropertyOwnerSelect from 'features/MultiDwellingUnits/Parts/FormFields/PropertyOwnerSelect';
import PartnerSelect from 'features/MultiDwellingUnits/Parts/FormFields/PartnerSelect';
import LeadTechSelect from 'features/MultiDwellingUnits/Parts/FormFields/LeadTechSelect';
import ProjectManager from 'features/MultiDwellingUnits/Parts/FormFields/ProjectManagerSelect';
import PausedSelect from 'features/MultiDwellingUnits/Parts/FormFields/PausedSelect';
import FlaggedCheckbox from 'features/MultiDwellingUnits/Parts/FormFields/FlaggedCheckbox';
import FlaggedCheckboxUnit from 'features/MultiDwellingUnits/Parts/FormFields/FlaggedCheckboxUnit';
import StatusesPreLaunchSelect from 'features/MultiDwellingUnits/Parts/FormFields/StatusesSelect';
import GroupTemplatesSelect from 'features/MultiDwellingUnits/Parts/FormFields/GroupTemplatesSelect';
import AddJobsLink from 'features/MultiDwellingUnits/Pages/Projects/Parts/MDUProjectsFilters/MDUProjectsFilters.AddJobsLink';
import EditJobsLink from 'features/MultiDwellingUnits/Pages/Projects/Parts/MDUProjectsFilters/MDUProjectsFilters.EditJobsLink';
import EditProjectsLink from 'features/MultiDwellingUnits/Pages/Projects/Parts/MDUProjectsFilters/MDUProjectsFilters.EditProjectsLink';
import ProjectTypeSelect from 'features/MultiDwellingUnits/Parts/FormFields/ProjectTypeSelect';

/* Utils & Constants */
import {isoFormatSlash} from 'global/constants/common';
import {FILTERS} from 'features/MultiDwellingUnits/Pages/Projects/Parts/MDUProjectsFilters/MDUProjectsFilters.constants';

/* Ducks */
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';

/* Hooks */
import {useSetFilters, useTableToolbarFilters} from 'features/MultiDwellingUnits/Pages/Projects/Parts/MDUProjectsFilters/MDUProjectsFilters.hooks';
import {useAppDispatch} from 'hooks/useAppDispatch';

/* Types */
import {IHash, Size as MODAL_SIZES} from 'types/base.types';
import {IMDUProjectsFiltersProps} from 'features/MultiDwellingUnits/Pages/Projects/Parts/MDUProjectsFilters/MDUProjectsFilters.types';

/* Styles */
import modalStyles from 'features/MultiDwellingUnits/Parts/Modals/modals.styles.scss';
import styles from './MDUProjectsFilters.styles.scss';

type RangeDatePickerOnChange = ComponentProps<typeof RangeDatePicker>['onChange'];

const MDUProjectsFilters = ({isJobs, customStatuses, statusSelectTypeMultiple, pageType, filterKeys = [], customFilterKey}: IMDUProjectsFiltersProps) => {
  const dispatch = useAppDispatch();
  const requestedItemsPerPage = useSelector(mduProjectsSlice.selectors.getPaginationItemsPerPage);
  const [isVisible, setIsVisible] = useState(false);
  const {filters, setFilters} = useSetFilters(pageType, customFilterKey);
  const {filtersCount, filtersChips} = useTableToolbarFilters({filters});

  const filtersSchema = useMemo(() => {
    const shape = filterKeys.reduce(
      (ret, key) => ({
        ...ret,
        [key]: FILTERS[key],
      }),
      {}
    );
    return Yup.object().shape(shape);
  }, [filterKeys]);

  /* Reset pagination and bulk operations */
  const clearBulkOperationsState = (paginationOverrides: IHash<number>) => {
    dispatch(mduProjectsSlice.actions.updateClearAllBulkOperation(paginationOverrides));
  };

  const initialValues = useMemo(() => pick(filters, filterKeys), [filterKeys, filters]);
  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    validationSchema: filtersSchema,
    enableReinitialize: true,
    onSubmit: inputs => {
      setFilters(pick(inputs, filterKeys));
      setIsVisible(false);
      clearBulkOperationsState({});
    },
  });

  /* Clear Chips Fields */
  const clearField = useCallback(
    (fieldName?: string) => {
      const cloned = fieldName ? cloneDeep(filters) : {};
      if (fieldName) {
        unset(cloned, fieldName);
      }

      setFilters(cloned);
      clearBulkOperationsState({});
    },
    [filters, setFilters]
  );

  const onStartDateChange = useCallback<RangeDatePickerOnChange>(
    ([startDate, endDate]) => {
      formik.handleChange({
        target: {
          name: 'startDate',
          value: {
            from: startDate,
            to: endDate,
          },
        },
      });
    },
    [formik]
  );

  const onEndDateChange = useCallback<RangeDatePickerOnChange>(
    ([startDate, endDate]) => {
      formik.handleChange({
        target: {
          name: 'estimatedEndDate',
          value: {
            from: startDate,
            to: endDate,
          },
        },
      });
    },
    [formik]
  );

  const onApprovedDateChange = useCallback<RangeDatePickerOnChange>(
    ([startDate, endDate]) => {
      formik.handleChange({
        target: {
          name: 'approvedAt',
          value: {
            from: startDate,
            to: endDate,
          },
        },
      });
    },
    [formik]
  );

  const onLastModifiedDateChange = useCallback<RangeDatePickerOnChange>(
    ([startDate, endDate]) => {
      formik.handleChange({
        target: {
          name: 'lastModifiedAt',
          value: {
            from: startDate,
            to: endDate,
          },
        },
      });
    },
    [formik]
  );

  /**
   * Trigger submit on search change.
   * Cannot call `formik.handleSubmit` within `onSearchInputChange()` because of race conditions.
   * Search value may not be updated when `formik.handleSubmit` is called.
   */
  const [shouldTriggerSubmitFromSearch, setShouldTriggerSubmitFromSearch] = useState(false);
  useEffect(() => {
    if (shouldTriggerSubmitFromSearch) {
      setShouldTriggerSubmitFromSearch(false);
      formik.handleSubmit();
    }
  }, [formik, shouldTriggerSubmitFromSearch]);

  /* Deal with inline search */
  const onSearchInputChange = debounce(
    (searchString: string) => {
      formik.handleChange({
        target: {
          name: 'search',
          value: searchString.trim(),
        },
      });
      setShouldTriggerSubmitFromSearch(true);
    },
    800,
    {
      trailing: true,
      leading: false,
    }
  );

  const fieldElements = useMemo(() => {
    const elements = [];
    if (filterKeys.includes('project_group_ids')) elements.push(<GroupTemplatesSelect key="jobTemplates" formik={formik} />);
    if (filterKeys.includes('propertyOwnerId')) elements.push(<PropertyOwnerSelect key="propertyOwnerId" project={undefined} formik={formik} multiple readOnly />);
    if (filterKeys.includes('partnerId')) elements.push(<PartnerSelect key="partnerId" formik={formik} multiple />);
    if (filterKeys.includes('leadTechId')) elements.push(<LeadTechSelect key="leadTechId" fieldName="leadTechId" formik={formik} multiple />);
    if (filterKeys.includes('projectManagerId')) elements.push(<ProjectManager key="projectManagerId" project={undefined} formik={formik} multiple />);
    if (!filterKeys.includes('projectManagerId') && filterKeys.includes('statuses') && !statusSelectTypeMultiple) elements.push(<StatusesPreLaunchSelect key="statusPreLaunch" formik={formik} />);
    if (filterKeys.includes('startDate'))
      elements.push(
        <RangeDatePicker
          key="startDateRange"
          format={isoFormatSlash}
          range={[formik.values.startDate?.from, formik.values.startDate?.to]}
          label="Start Date Range"
          placeholder="Type or select"
          isRangeSelect
          onChange={onStartDateChange}
          error={formik.errors.startDate}
        />
      );
    if (filterKeys.includes('estimatedEndDate'))
      elements.push(
        <RangeDatePicker
          key="endDateRange"
          format={isoFormatSlash}
          range={[formik.values.estimatedEndDate?.from, formik.values.estimatedEndDate?.to]}
          label="End Date Range"
          placeholder="Type or select"
          isRangeSelect
          onChange={onEndDateChange}
          error={formik.errors.estimatedEndDate}
        />
      );

    if (filterKeys.includes('approvedAt'))
      elements.push(
        <RangeDatePicker
          key="approvedAt"
          format={isoFormatSlash}
          range={[formik.values.approvedAt?.from, formik.values.approvedAt?.to]}
          label="Approved Date Range"
          placeholder="Type or select"
          isRangeSelect
          onChange={onApprovedDateChange}
          error={formik.errors.approvedAt}
        />
      );

    if (filterKeys.includes('lastModifiedAt'))
      elements.push(
        <RangeDatePicker
          key="lastModifiedDate"
          format={isoFormatSlash}
          range={[formik.values.lastModifiedAt?.from, formik.values.lastModifiedAt?.to]}
          label="Last Modified"
          placeholder="Type or select"
          isRangeSelect
          onChange={onLastModifiedDateChange}
          error={formik.errors.lastModifiedAt}
        />
      );

    if (filterKeys.includes('projectManagerId') && filterKeys.includes('statuses') && !statusSelectTypeMultiple) elements.push(<StatusesPreLaunchSelect key="statusPreLaunch" formik={formik} />);

    if (filterKeys.includes('paused')) {
      elements.push(<PausedSelect key="paused" formik={formik} />);
    }

    return elements;
  }, [filterKeys, formik, onApprovedDateChange, onEndDateChange, onStartDateChange, onLastModifiedDateChange, statusSelectTypeMultiple]);

  const preFormElements = useMemo(() => {
    const elements = [];
    if (filterKeys.includes('statuses') && statusSelectTypeMultiple) elements.push(<StatusesPreLaunchSelect key="statusPreLaunch" formik={formik} customStatuses={customStatuses} />);
    if (filterKeys.includes('project_types')) {
      elements.push(<ProjectTypeSelect fieldName="project_types" formik={formik} multiple />);
    }
    return elements;
  }, [customStatuses, filterKeys, formik, statusSelectTypeMultiple]);

  const postFormElements = useMemo(() => {
    const elements = [];
    if (filterKeys.includes('flagged')) elements.push(<FlaggedCheckbox key="flagged" formik={formik} />);
    if (filterKeys.includes('only_flagged')) elements.push(<FlaggedCheckboxUnit key="flagged" formik={formik} />);

    return elements;
  }, [filterKeys, formik]);

  /* Close Modal */
  const hideModal = () => {
    setIsVisible(false);
    formik.resetForm();
  };

  /*
   * If we get an update for items per page, reset the bulk data. We have to do this because
   * We also have to clear the current filters. We could try to do this in the ducks file.
   * */
  useEffect(() => {
    clearBulkOperationsState({items_per_page: requestedItemsPerPage});
  }, [requestedItemsPerPage]);

  const {search} = filters;

  const ToolbarLinks = useMemo(
    () => (
      <>
        {isJobs ? (
          <>
            <AddJobsLink />
            <EditJobsLink />
          </>
        ) : (
          <EditProjectsLink pageType={pageType} />
        )}
      </>
    ),
    [isJobs, pageType]
  );

  return (
    <div>
      <Modal
        elementSize={MODAL_SIZES.LARGE}
        isVisible={isVisible}
        hide={hideModal}
        header="Apply Filters"
        bodyContainerClasses={modalStyles.overflowInitial}
        modalClassName={modalStyles.overflowInitial}
        footerElement2={
          <Button theme={BUTTON_THEMES.SECONDARY} onClick={hideModal}>
            Cancel
          </Button>
        }
        footerElement3={<Button onClick={formik.submitForm}>Apply Filters</Button>}
      >
        <Form classes="paddingTop-small1" onSubmit={formik.submitForm}>
          {preFormElements.length > 0 && (
            <Form.Row>
              {preFormElements.map((element, index) => {
                const numColumnsLg = 12 / preFormElements.length;
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Form.Column key={index} lg={numColumnsLg} classes={styles.column}>
                    {element}
                  </Form.Column>
                );
              })}
            </Form.Row>
          )}
          <Form.Row>
            <Form.Column lg={6} classes={styles.column}>
              {fieldElements.filter((i: ReactNode, index: number) => index % 2 === 0)}
            </Form.Column>
            <Form.Column lg={6} classes={styles.column}>
              {fieldElements.filter((i: ReactNode, index: number) => index % 2 !== 0)}
            </Form.Column>
          </Form.Row>
          {postFormElements.length > 0 && (
            <Form.Row>
              <Form.Column lg={12} classes={styles.column}>
                {postFormElements}
              </Form.Column>
            </Form.Row>
          )}
        </Form>
      </Modal>
      <TableToolbar
        clearFilter={clearField}
        filtersCount={filtersCount}
        filtersChips={filtersChips}
        searchFilterKey="search"
        searchInputValue={search}
        searchOnInputChange={onSearchInputChange}
        searchPlaceholderText={`Search ${isJobs ? 'jobs' : 'projects'}`}
        toggleFiltersModal={setIsVisible}
        ToolbarLinks={ToolbarLinks}
      />
    </div>
  );
};

export default React.memo(MDUProjectsFilters);
