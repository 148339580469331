import React, {useState, useEffect, useCallback} from 'react';
// Hooks
import {useSelector} from 'react-redux';
import APIS from 'global/apis';
import useApi from 'hooks/useApi';
import {useAppDispatch} from 'hooks/useAppDispatch';
// Utils
import {noop} from 'utils/event';
import {showErrorToast} from 'queries/query.utils';
import {logger} from 'utils/logger';
import {isPreLaunchStatus} from 'features/MultiDwellingUnits/MDU.utils';
// Types && Ducks
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {MduFetchProjectTechsListResponse, TechProfileObject, ProjectDetails, MDUStatuses} from 'features/MultiDwellingUnits/MDU.types';
// Components && Styles
import PageHeader from 'components/Elements/PageHeader';
import LeadPanel from 'features/MultiDwellingUnits/Parts/LeadPanel';
import {Icon} from 'ht-styleguide';
import {ModalSetLeadTech, ModalAddTeamMembers} from 'features/MultiDwellingUnits/Parts/Modals';
import TeamManagementEmptyState from './CurrentProject.TeamManagement.EmptyState';
import TeamManagementMemberList from './CurrentProject.TeamManagement.MemberList';

const LeadTechPanel = ({project = {} as ProjectDetails, onChangeLeadPerson = noop}) => {
  const hasLeadTech = Boolean(project.leadTech);
  const isUserAllowedToEdit = hasLeadTech;
  const changeButtonContent = isUserAllowedToEdit ? (
    <button type="button" className="plainButton p2 text-link text-weight-bold" onClick={onChangeLeadPerson}>
      <Icon name="repeat" className="marginRight-tiny1" />
      <span>Change Team Lead</span>
    </button>
  ) : null;
  return (
    <LeadPanel
      className="marginBottom-medium2"
      panelHeaderText="Team Lead"
      panelIcon="crown-fill"
      changeButtonContent={changeButtonContent}
      leadPerson={project.leadTech}
      onChangeLeadPerson={onChangeLeadPerson}
      changeLeadPersonText="Assign Team Lead"
    />
  );
};

const TeamManagementPage = () => {
  /* Hooks */
  const api = useApi();
  const dispatch = useAppDispatch();
  const currentProject: ProjectDetails = useSelector(mduProjectsSlice.selectors.getCurrentProject);
  const {id: projectId} = currentProject;

  /* State */
  const [teamMembers, setTeamMembers] = useState<TechProfileObject[]>([]); // Cache data from BE
  const [isAddTeamMembersModalVisible, setIsAddTeamMembersModalVisible] = useState(false);
  const [isLeadTechModalVisible, setIsLeadTechModalVisible] = useState(false);

  const toggleAddMembersModal = () => setIsAddTeamMembersModalVisible(!isAddTeamMembersModalVisible);
  const toggleLeadTechModal = () => setIsLeadTechModalVisible(!isLeadTechModalVisible);

  const hasTeamMembers = Boolean(teamMembers.length);

  /* Methods */
  const fetchProjectTechs = useCallback(async () => {
    /** Only show inactive techs for launched status projects */
    const apiOptions = {hideInactive: isPreLaunchStatus(currentProject.status as MDUStatuses)};

    /** The BE adds billing leads to the members list, but Design doesn't want to show them in the team management page. */
    const filterTeamMembers = (members: TechProfileObject[]) => members.filter(member => !member.billing_lead);

    try {
      api.toggleLoader(true);
      if (projectId) {
        const response: MduFetchProjectTechsListResponse = await APIS.mdu.getTechsAssignedToProject({projectId}, apiOptions);
        if (response.err) {
          showErrorToast(dispatch)(response.err);
          throw new Error(`Error fetching project techs: ${String(response.err)}`);
        }
        setTeamMembers(filterTeamMembers(response.data.techs));
      }
    } catch (error) {
      logger('TeamManagementPage - fetch project techs')(String(error));
    } finally {
      api.toggleLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, currentProject.status]);

  const addMember = async (techIds: number[]) => {
    try {
      api.toggleLoader(true);
      const response = await APIS.mdu.addTechsToProject({projectId: currentProject.id}, {techIds});
      if (response.err) {
        showErrorToast(dispatch)(response.err);
        throw new Error(`Error adding team member: ${String(response.err)}`);
      }
      await fetchProjectTechs();
      await APIS.mdu.getProject({id: projectId});
    } catch (error) {
      logger('ModalAddTeamMembers - add team member')(String(error));
    } finally {
      api.toggleLoader(false);
    }
  };

  const deleteMember = async (member: TechProfileObject) => {
    try {
      api.toggleLoader(true);
      const response = await APIS.mdu.removeTechFromProject({id: member.id, projectId}, {hideInactive: true});
      if (response.err) {
        showErrorToast(dispatch)(response.err);
        throw new Error(`Error deleting team member: ${String(response.err)}`);
      }
      await fetchProjectTechs(); // After delete team member success
    } catch (error) {
      logger('TeamManagementPage - deactivate project tech')(String(error));
    } finally {
      api.toggleLoader(false);
    }
  };

  const modalType = currentProject.leadTech?.id ? 'EDIT' : 'NEW';

  useEffect(() => {
    fetchProjectTechs(); // Initial page load
  }, [fetchProjectTechs]);

  return (
    <div className="marginBottom-medium2">
      <PageHeader title="Team Management" />

      <LeadTechPanel project={currentProject} onChangeLeadPerson={toggleLeadTechModal} />

      {hasTeamMembers ? (
        <TeamManagementMemberList teamMembers={teamMembers} onDeleteMember={deleteMember} onAddMember={addMember} toggleModal={toggleAddMembersModal} />
      ) : (
        <TeamManagementEmptyState onButtonClick={toggleAddMembersModal} className="marginBottom-large" />
      )}

      <ModalSetLeadTech isVisible={isLeadTechModalVisible} toggleModal={toggleLeadTechModal} project={currentProject} onSubmitSuccess={fetchProjectTechs} type={modalType} />
      <ModalAddTeamMembers isVisible={isAddTeamMembersModalVisible} onAddMember={addMember} toggleModal={toggleAddMembersModal} />
    </div>
  );
};

export default TeamManagementPage;
