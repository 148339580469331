import {MDUProjectTypeSlugs} from 'features/MultiDwellingUnits/MDU.types';

export const FIELD_LABELS = {
  partner: 'Partner',
  leadTechId: 'Team Lead',
  propertyOwner: 'Property Owner',
  projectManager: 'Project Manager',
  flagged: 'Show ONLY flagged issues',
  projectType: 'Project Type',
  status: 'Status',
  groupTemplates: 'Job Template',
  unitStatus: 'Status',
  paused: 'Paused',
};

// Project Types
export const PROJECT_TYPES: {value: MDUProjectTypeSlugs; label: string}[] = [
  {value: 'accelerated_retrofit', label: 'Accelerated Retrofit'},
  {value: 'new_construction', label: 'New Construction'},
  {value: 'rolling_retrofit', label: 'Rolling Retrofit'},
  {value: 'property_inspection', label: 'Property Inspection'},
];
