import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams, useNavigate} from 'react-router-dom';
import {BUTTON_THEMES} from 'ht-styleguide';

/* Components */
import PageHeader from 'components/Elements/PageHeader';
import GroupNameEdit from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/Parts/TemplateJobs.EditName';
import TemplateJobsPartnerSkusDropdown from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/Parts/TemplateJobs.PartnerSkus';
import ModalGroupExists, {ModalGroupExistsType} from 'features/MultiDwellingUnits/Parts/Modals/MDU.Modal.GroupExistsAlert';

/* Queries / Mutations */
import {useSaveNewDraftMutation} from 'features/MultiDwellingUnits/queries/mutation.jobs.drafts';
import {useAddUnitsToJobMutation} from 'features/MultiDwellingUnits/queries/mutation.jobs.addUnits';
import {useCheckGroupCurrentlyExistsMutation} from 'features/MultiDwellingUnits/queries/mutation.jobs.checkGroupCurrentlyExists';

/* Hooks */
import {useProjectDraft} from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/TemplateJobs.hooks';
import {useAppDispatch} from 'hooks/useAppDispatch';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';

/* Constants */
import {mduProjectBulkJobCreateProcessing} from 'global/paths';

import {MduUseParamsTypes, ProjectDetails, TGroupSelectSkuServices, TGroupSelectSkuServicesRoot} from 'features/MultiDwellingUnits/MDU.types';
import {SelectedSku} from 'features/Questions/types';

/**
 * Returns two header types/
 * 1. Editable Name & actionable buttons with sku
 * 2. Non-editable name & actionable buttons without sku. Mostly draft and base entry bulk template
 * @constructor
 */
export const HeaderTypes = {
  sku: 'sku',
  draft: 'draft',
} as const;

type TCreateBulkJobsHeader = {
  headerType: keyof typeof HeaderTypes;
  suppressSave?: boolean;
  totalJobCount?: number;
  onHandleDiscardRedirect?: BaseAnyFunction;
  onHandleSaveRedirect?: BaseAnyFunction;
  onHandlePartnerSkuRedirect?: BaseAnyFunction;
  onHandleCancelRedirect?: BaseAnyFunction;
  modalGroupExistsType?: string;
};

type TCreateBulkJobsHeaderProps = TCreateBulkJobsHeader;

/**
 * Should this component be separated out? Two very different ideas are being handled here.
 */
const CreateBulkJobsHeader = ({
  headerType = HeaderTypes.sku,
  suppressSave = false,
  totalJobCount,
  onHandleDiscardRedirect,
  onHandleCancelRedirect,
  onHandlePartnerSkuRedirect,
  onHandleSaveRedirect,
  modalGroupExistsType,
}: TCreateBulkJobsHeaderProps) => {
  /* Local State */
  const [showDuplicateModal, setShowDuplicateModal] = useState('');

  /* Hooks */
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {groupId = ''} = useParams<MduUseParamsTypes>();
  const {projectGroup, projectId} = useProjectDraft({groupId});
  const currentProject: ProjectDetails = useSelector(mduProjectsSlice.selectors.getCurrentProject);
  const projectGroupsDraftAll = useSelector(mduProjectsSlice.selectors.getDraftProjectGroups);
  const allNewDrafts = useSelector(mduProjectsSlice.selectors.getDraftNewTemplateArray);
  const firstDraftSku = allNewDrafts.find(draft => +draft.id === +groupId);

  /* Queries / Muations */
  const saveNewDrafts = useSaveNewDraftMutation();
  const addDraftUnitsToJob = useAddUnitsToJobMutation();
  const checkGroupCurrentlyExistsMutation = useCheckGroupCurrentlyExistsMutation();

  /* Constants */
  const formattedDraftServices = firstDraftSku?.projectServices?.map(service => ({name: service?.sku!.name, quantity: service.quantity}));

  /* Methods */
  const onHandleDiscard = () => {
    // 1. throw away draft of bulk template/group
    dispatch(mduProjectsSlice.actions.deleteGroupFromList({id: groupId}));

    // 2. redirect to base create-bulk page
    onHandleDiscardRedirect?.();
  };

  const onHandleCancel = () => {
    // 1. throw away draft of bulk template/group
    dispatch(mduProjectsSlice.actions.deleteAllDraftGroups());
    // 2. redirect to project jobs page (close create bulk page)
    onHandleCancelRedirect?.();
  };

  const onHandleSave = () => {
    // 1. save draft of bulk template/group
    saveNewDrafts.mutate(undefined, {
      onSuccess: response => {
        onHandleSaveRedirect?.(response);
      },
    });
  };

  /* -------------------------------------------------- */
  /* ----------------- BASE METHODS ------------------- */
  /* -------------------------------------------------- */
  const getServicesQueryFormat = () => {
    return (firstDraftSku?.projectServices as TGroupSelectSkuServicesRoot[])?.reduce((all: TGroupSelectSkuServices[], draftSku) => {
      const sku = {
        id: draftSku.sku!.id,
        quantity: draftSku.quantity,
        questions: draftSku.questions,
      };

      return all.concat({sku});
    }, []);
  };

  /**
   * -----------------------------------------------------------------
   * CHECK EXISTENCE OF GROUP
   *
   * Check to verify the submitted group exists.
   *  - It exists: Show error'ish modal
   *  - If Not: Show confirm modal
   *  -----------------------------------------------------------------
   */
  const onConfirmExistingGroup = () => {
    checkGroupCurrentlyExistsMutation.mutate(getServicesQueryFormat(), {
      onSuccess: response => {
        const {data} = response;
        // 1. If project_group is null, we are good to proceed: https://github.com/HelloTech/hellotech/pull/5620
        if (!data?.project_group) {
          onHandleSave();
        } else {
          // 2. pop modal
          setShowDuplicateModal(data?.project_group?.name || '');
        }
      },
    });
  };
  const onHandleAddUnits = () => {
    // 1. bundle up id's/qty
    const unitsToAdd = projectGroupsDraftAll.map(group => {
      return {
        id: group.id!,
        units_number: group.unitsNumber!,
      };
    });
    // 1. submit drafted add units to job & redirect
    addDraftUnitsToJob.mutate(unitsToAdd);

    navigate(mduProjectBulkJobCreateProcessing(`${projectId}`));
  };

  /* I need to revisit this. The modals are now all different w/ diff button behaviors. This is sloppy. Last minute bandaid */
  const DispatchAction = modalGroupExistsType === ModalGroupExistsType.templateJobs ? onHandleDiscardRedirect : onHandleSave;

  return (
    <>
      {headerType === HeaderTypes.sku ? (
        <PageHeader
          TitleComponent={projectGroup?.id && <GroupNameEdit group={projectGroup} draft />}
          HeaderRightContent={
            <>
              <TemplateJobsPartnerSkusDropdown
                classes="paddingLeft-small"
                onHandleSelection={sku => {
                  onHandlePartnerSkuRedirect?.(sku);
                }}
                useInPageHeader
              />
              <PageHeader.Button disabled={suppressSave} iconName="save" theme={BUTTON_THEMES.SECONDARY} onClick={onConfirmExistingGroup} label="Save" />
              <PageHeader.Button iconName="trash" theme={BUTTON_THEMES.DANGER_SECONDARY} onClick={onHandleDiscard} label="Discard" />
            </>
          }
        />
      ) : (
        <PageHeader
          title={`Add Jobs to ${currentProject?.name}`}
          HeaderRightContent={
            <>
              <PageHeader.Button disabled={!totalJobCount} theme={BUTTON_THEMES.PRIMARY} onClick={onHandleAddUnits} label={`Add ${totalJobCount || ''} Jobs to Project`} />
              <PageHeader.Button theme={BUTTON_THEMES.SECONDARY} onClick={onHandleCancel} label="Cancel" />
            </>
          }
        />
      )}
      <ModalGroupExists
        isVisible={Boolean(showDuplicateModal)}
        services={formattedDraftServices! as SelectedSku[]}
        templateName={showDuplicateModal || ''}
        onCloseActionItem={() => setShowDuplicateModal('')}
        dispatchAction={DispatchAction as BaseAnyFunction}
        modalGroupExistsType={modalGroupExistsType || ''}
      />
    </>
  );
};

export default CreateBulkJobsHeader;
