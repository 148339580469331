import {useEffect} from 'react';
import {useQuery, useMutation, useQueryClient} from 'react-query';

/* Slice */
import {login as loginSlice} from 'features/Login/login.ducks';

/* Hooks */
import useApi from 'hooks/useApi';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useSelector} from 'hooks/useAppSelector';

/* Apis */
import APIS from 'global/apis';

/* Utils */
import {APP_LEVEL_QUERY_KEYS} from './app.query.keys';

/**
 * This will grab up the session and hand back the user.
 * The mechanism to legitimize the user already exists. This is just to
 * get the user object associated with the logged in user.
 *
 * We wil probably need to do something with this when we branch out with permissions and such.
 * Note:
 *    1. We need it know to identify some project or action(notes) to a user.
 *    2. Do we want to clear out the session if this fails?
 *    3. For now, this is just informational. Do we want to drive the auth status/login here, in future?
 */

export type TUserCurrent = {
  id: number;
  email: string;
  name: string;
  role: string;
  type: string;
};

type TUserCurrentProps<T> = {
  select?: (data: T) => T;
};

export const useUserCurrentQuery = <TData = TUserCurrent>(select?: (data: TData) => TData) => {
  const api = useApi();
  const dispatch = useAppDispatch();

  const queryProps = useQuery(
    APP_LEVEL_QUERY_KEYS.current,
    async () => {
      api.toggleLoader(true);
      const response = await APIS.users.current();
      api.toggleLoader(false);
      if (response.err) {
        return null;
      }

      return response.data.user;
    },
    {
      select,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
    }
  );

  const {data: userData} = queryProps;
  const userFromState = useSelector(loginSlice.selectors.getUser);

  useEffect(() => {
    if ((userData && !userFromState) || (userData as TUserCurrent)?.id !== userFromState?.id) {
      dispatch(loginSlice.actions.setUserInfo(userData));
    }
  }, [dispatch, userData, userFromState]);

  return queryProps;
};

/**
 * Lets get the user.
 * Note: We can cache out (helper) when/if, user selectors are a lot
 */
export const useUserCurrentQuerySelect = () => {
  const user = useUserCurrentQuery(data => data);

  return user?.data;
};

/* We have need for a mutation-esque behavior without conflating "enabled" everywhere */
export const useUserCurrentMutation = <TData = TUserCurrent>() => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const response = await APIS.users.current();
      if (response.err) {
        return null;
      }

      return response.data.user;
    },
    {
      onSuccess: response => {
        queryClient.setQueryData(APP_LEVEL_QUERY_KEYS.current, response);
      },
    }
  );
};
