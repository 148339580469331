import {createSlice, createSelector, PayloadAction} from '@reduxjs/toolkit';
import LocalStorageColumnState from 'utils/table/localStorageColumnState';
import {DEFAULT_UNASSIGNED_ORDERS_COLUMNS} from './dispatch.constants';
import * as Types from './dispatch.types';

/**
 * Storing table column data to local storage
 */
const saveToStorageFN = (newState: Types.IDispatchInitialState['pages']['unassignedList']) => {
  return Object.entries(newState).reduce((acc, [pageKey, {filters, rawFilters, columnState, columnOrder, columnSort}]) => {
    acc[pageKey as keyof Types.IDispatchInitialState['pages']['unassignedList']] = {
      filters,
      rawFilters,
      columnState,
      columnOrder,
      columnSort,
    };
    return acc;
  }, {} as Types.TUnassignedListLocalStorageState);
};

const unassignedListColumnStateUtil = new LocalStorageColumnState<Types.IDispatchInitialState['pages']['unassignedList'], Types.TUnassignedListLocalStorageState>({
  stateKey: '_ht_dispatch_unassigned_list_state',
  saveToStorageFN,
});

/*
*******************************************************
  INITIAL STATE
*******************************************************
*/

const paginationInitialState: TBasePagination = {
  current_page: 1,
  items_per_page: 100,
  items_on_current_page: 0,
  total_pages: 1,
  has_more: false,
  items_viewed: 0,
  total_items: 0,
};

const defaultColumnState = DEFAULT_UNASSIGNED_ORDERS_COLUMNS.reduce((acc, key) => {
  acc[key] = {
    visible: true,
    columnWidth: 0,
  };
  return acc;
}, {} as Types.TUnassignedListInitialState['columnState']);

export const unassigedListInitialState: Types.TUnassignedListInitialState = {
  filters: {},
  rawFilters: {},
  columnState: defaultColumnState,
  columnOrder: [
    Types.UnassignedOrdersColumnKeys.LastAvailabilityIn,
    Types.UnassignedOrdersColumnKeys.OfferCount,
    Types.UnassignedOrdersColumnKeys.Payout,
    Types.UnassignedOrdersColumnKeys.FNStatus,
    Types.UnassignedOrdersColumnKeys.Partner,
    Types.UnassignedOrdersColumnKeys.CreatedAt,
    Types.UnassignedOrdersColumnKeys.Tier,
    Types.UnassignedOrdersColumnKeys.State,
    Types.UnassignedOrdersColumnKeys.Zip,
  ],
  columnSort: [{id: Types.UnassignedOrdersColumnKeys.LastAvailabilityIn, desc: false}],
  pagination: paginationInitialState,
};

const initialState: Types.IDispatchInitialState = {
  pages: {
    unassignedList: unassignedListColumnStateUtil.populateInitialPagesState({
      initialState: {
        all: unassigedListInitialState,
        'assigned-to-me': unassigedListInitialState,
      },
    }),
  },
};

/*
*******************************************************
  Slice
*******************************************************
*/
const {actions: dispatchActions, reducer} = createSlice({
  name: 'dispatch',
  initialState,
  reducers: {
    updateUnassignedListFilters: (state, action: PayloadAction<Types.TUpdateUnassignedListFiltersPayload>) => {
      const {navType, filters, replace} = action.payload;

      if (replace) {
        // clear out all filters except for the filter keys that are in the initial state (those should always be present)
        const oldFilters = state.pages.unassignedList[navType].filters;
        const initialStateFilters = unassigedListInitialState.filters;
        const newFilters = Object.keys(initialStateFilters).reduce((acc, key) => {
          if (key in oldFilters) {
            acc[key] = oldFilters[key];
          }
          return acc;
        }, {} as Types.TUnassignedListInitialState['filters']);
        state.pages.unassignedList[navType].filters = newFilters;
      } else {
        state.pages.unassignedList[navType].filters = {
          ...state.pages.unassignedList[navType].filters,
          ...filters,
        };
      }

      // If a filter is removed, remove it from rawFilters
      const newFilters = state.pages.unassignedList[navType].filters;
      const currentFiltersKeys = Object.keys(newFilters).filter(key => {
        const filterValue = newFilters[key];
        if (Array.isArray(filterValue)) {
          return !!filterValue.length;
        }
        return !!filterValue;
      });

      const currentRawFilters = state.pages.unassignedList[navType].rawFilters;
      const currentRawFiltersKeys = Object.keys(currentRawFilters);
      const removedFiltersKeys = currentRawFiltersKeys.filter(key => !currentFiltersKeys.includes(key));
      removedFiltersKeys.forEach(key => {
        delete state.pages.unassignedList[navType].rawFilters[key];
      });
      // Update Logic in storage util if necessary
      unassignedListColumnStateUtil.saveStateToStorage(state.pages.unassignedList);
    },
    /**
     * Used to override the entire rawFilters state.
     */
    updateUnassignedListRawFiltersState: (state, action: PayloadAction<Types.TUpdateUnassignedListRawFiltersStatePayload>) => {
      const {navType, newRawFiltersState} = action.payload;
      state.pages.unassignedList[navType].rawFilters = newRawFiltersState;

      // Update Logic in storage util if necessary
      unassignedListColumnStateUtil.saveStateToStorage(state.pages.unassignedList);
    },
    updateUnassignedListColumnVisibility: (state, action: PayloadAction<Types.TUpdateUnassignedListColumnVisibilityPayload>) => {
      const {navType, visibilityState} = action.payload;
      const tempState = state.pages.unassignedList[navType].columnState;
      Object.keys(visibilityState).forEach(key => {
        if (!(key in tempState)) {
          tempState[key as Types.TUnassignedOrdersColumnKeysValues] = {
            visible: false,
            columnWidth: 0,
          };
        }
        tempState[key as Types.TUnassignedOrdersColumnKeysValues].visible = visibilityState[key as Types.TUnassignedOrdersColumnKeysValues]?.visible ?? false;
      });
      state.pages.unassignedList[navType].columnState = tempState;

      // Update Logic in storage util if necessary
      unassignedListColumnStateUtil.saveStateToStorage(state.pages.unassignedList);
    },
    updateUnassignedListColumnWidths: (state, action: PayloadAction<Types.TUpdateUnassignedListColumnWidthsPayload>) => {
      const {navType, columnWidths} = action.payload;
      const newColumnState = state.pages.unassignedList[navType].columnState;
      Object.entries(columnWidths).forEach(([key, value]) => {
        if (!newColumnState[key]) {
          newColumnState[key] = {
            visible: false,
            columnWidth: 0,
          };
        }
        newColumnState[key].columnWidth = value;
      });
      state.pages.unassignedList[navType].columnState = newColumnState;

      // Update Logic in storage util if necessary
      unassignedListColumnStateUtil.saveStateToStorage(state.pages.unassignedList);
    },
    updateUnassigenedListColumnOrder: (state, action: PayloadAction<Types.TUpdateUnassignedListColumnOrderPayload>) => {
      const {navType, columnOrder} = action.payload;
      state.pages.unassignedList[navType].columnOrder = columnOrder;

      // Update Logic in storage util if necessary
      unassignedListColumnStateUtil.saveStateToStorage(state.pages.unassignedList);
    },
    updateUnassignedListColumnSort: (state, action: PayloadAction<Types.TUpdateUnassignedListColumnSortPayload>) => {
      const {navType, columnSort} = action.payload;
      state.pages.unassignedList[navType].columnSort = columnSort;

      // Update Logic in storage util if necessary
      unassignedListColumnStateUtil.saveStateToStorage(state.pages.unassignedList);
    },
    updateUnassignedListPagination: (state, action: PayloadAction<Types.TUpdateUnassignedListPaginationPayload>) => {
      const {navType, pagination} = action.payload;

      // Since we're front-loading data, we cannot rely on the BE to provide pagination data as we did with the Issues table.
      // We'll need to update pagination props manually. Additionally, while `<DataTable />` can handle pagination with
      // data alone, we're saving pagination data to redux so each page can remember its own pagination state.
      const {current_page: currentPagePayload, items_per_page: itemsPerPagePayload, total_items: totalItemsPayload, total_pages: totalPagesPayload} = pagination;

      const oldPagination = state.pages.unassignedList[navType].pagination;
      const {current_page: currentPageOld, items_per_page: itemsPerPageOld, total_items: totalItemsOld} = oldPagination;
      let newPagination = {};

      const isValidNumber = (val: any) => !Number.isNaN(+val);

      // If the total items do not match (i.e. we have new data)
      if (isValidNumber(totalItemsPayload) && totalItemsPayload !== totalItemsOld) {
        newPagination = {
          total_items: totalItemsPayload,
          current_page: 1,
          total_pages: totalPagesPayload || Math.ceil((totalItemsPayload as number) / (itemsPerPagePayload ?? itemsPerPageOld ?? 100)),
          items_per_page: itemsPerPagePayload ?? itemsPerPageOld ?? 100,
        };
      } else if (isValidNumber(currentPagePayload) && currentPagePayload !== currentPageOld) {
        newPagination = {
          current_page: currentPagePayload,
        };
      } else if (isValidNumber(itemsPerPagePayload) && itemsPerPagePayload !== itemsPerPageOld) {
        // somehow need to separate out or else Math.ceil doesn't work?
        const calculatedTotalPages = ((totalItemsPayload || totalItemsOld) as number) / (itemsPerPagePayload ?? itemsPerPageOld ?? 100);
        newPagination = {
          current_page: 1,
          items_per_page: itemsPerPagePayload,
          total_pages: totalPagesPayload || Math.ceil(calculatedTotalPages),
        };
      }

      state.pages.unassignedList[navType].pagination = {...oldPagination, ...newPagination};
    },
  },
});

/*
*******************************************************
  Selectors
*******************************************************
*/

const getDispatchState = (state: any): Types.IDispatchInitialState => state.dispatch;

const selectors = {
  getUnassignedListPageStateByKey: (navType: Types.DispatchNavTypes) => createSelector(getDispatchState, dispatchState => dispatchState.pages.unassignedList[navType]),
};

/*
*******************************************************
  Export
*******************************************************
*/

export default {
  actions: {...dispatchActions},
  reducer,
  selectors,
};
