import React from 'react';
import cn from 'classnames';
import {Button, BUTTON_THEMES, InputField, Modal} from 'ht-styleguide';

/* Components */
import GroupServicesBoxInfo, {TGroupService} from 'features/MultiDwellingUnits/Parts/GroupServicesBoxInfo';

/* styles */
import styles from './modals.styles.scss';

export type TConfirmAddTemplateActionModalProps<T = TGroupService> = {
  services: T[];
  templateName: string | null;
  isVisible: boolean;
  onCloseActionItem: () => void;
  dispatchAction: () => void;
  onTemplateNameChange?: (e: React.ChangeEvent<HTMLInputElement>) => void | null;
  inputErrorBlankView?: boolean;
  nameError?: boolean;
  modalGroupExistsType?: string;
};

export const ConfirmAddTemplateModalType = 'confirmAddTemplateModalType';
const ConfirmAddTemplateActionModal = ({nameError, services, templateName, isVisible, onCloseActionItem, dispatchAction, onTemplateNameChange}: TConfirmAddTemplateActionModalProps) => {
  const error = nameError ? 'Template name already exists' : '';
  const suppressButton = !templateName || nameError;

  return (
    <Modal
      isVisible={isVisible}
      modalClassName={cn(styles.container, styles.modalContents)}
      hide={onCloseActionItem}
      header="Create New Template"
      footerElement2={
        <Button theme={BUTTON_THEMES.SECONDARY} dataTestId="button_cancl_job" className={styles.ctaButton} onClick={onCloseActionItem}>
          Discard Changes
        </Button>
      }
      footerElement3={
        <Button disabled={suppressButton} theme={BUTTON_THEMES.PRIMARY} dataTestId="button_confirm_template_add" className={styles.ctaButton} onClick={dispatchAction}>
          Confirm
        </Button>
      }
    >
      <div className="position relative">
        <p className="p1 n900">
          A new template will be created and applied to this job. It will be available for you to use on other jobs as well.
          <InputField error={error} containerClass="paddingTop-small2" value={templateName} onChange={onTemplateNameChange} label="Template Name" />
          <GroupServicesBoxInfo services={services} />
        </p>
      </div>
    </Modal>
  );
};

export default ConfirmAddTemplateActionModal;
