import React from 'react';
import {Form, RadioGroup, Radio} from 'ht-styleguide';
import {TaskTypes, TaskFieldNames} from 'features/Products/types/taskGroups.types';
import {TTaskChecklistFormik} from '../taskChecklist.types';

const RADIO_OPTIONS = [
  {
    label: 'Single photo',
    value: false,
  },
  {
    label: 'Multiple photos',
    value: true,
  },
];

export interface IPhotoLimitSectionProps {
  formik: TTaskChecklistFormik;
}

const PhotoLimitSection = ({formik}: IPhotoLimitSectionProps) => {
  const {task_type, multiple_photos} = formik.values;
  const isPhotoTaskType = task_type === TaskTypes.Photo;
  const onChange = (value: boolean) => formik.setFieldValue(TaskFieldNames.MultiplePhotos, value);

  return isPhotoTaskType ? (
    <Form.Row>
      <Form.Column lg={12} md={8} sm={4}>
        <RadioGroup label="How many photos can be uploaded?" onChange={onChange} value={multiple_photos}>
          {RADIO_OPTIONS.map(({label, value}) => (
            <Radio key={label} value={value} label={label} />
          ))}
        </RadioGroup>
      </Form.Column>
    </Form.Row>
  ) : null;
};

export default PhotoLimitSection;
