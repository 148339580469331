import {useQuery} from 'react-query';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import {SkuCategoryPayload} from 'features/Booking/Parts/Services/services.types';
import useApi from 'hooks/useApi';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {showErrorToast, logResponseError} from 'queries/query.utils';
import {SKUS_QUERY_KEYS} from './skus.query.keys';

// Connecting to src/features/Booking/Parts/Services/services.types.ts since it's a same endpoint
type TUseSkusFilterDataResponse = WithErrorResponse<SkuCategoryPayload['data']>;

type TUseSkusFilterQueryParams = {
  /**
   * If there's no value, then return non-partner skus
   */
  partnerId?: number | string | null;
  remote?: boolean | null;
  queryOpts?: Parameters<typeof useQuery<TUseSkusFilterDataResponse['data']>>['2'];
};

const useSkusFilterQuery = ({partnerId = null, remote = null, queryOpts = {}}: TUseSkusFilterQueryParams = {}) => {
  const api = useApi();
  const dispatch = useAppDispatch();
  const filterAttributes = pickBy({partner_id: partnerId, remote}, identity);

  return useQuery<TUseSkusFilterDataResponse['data']>(
    SKUS_QUERY_KEYS.filter([filterAttributes]),
    async () => {
      const response: TUseSkusFilterDataResponse = await api.booking.skus.filter(filterAttributes);
      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Retrieving Skus'});
        logResponseError('Skus - Filter')(response.err);
      }
      return response?.data;
    },
    queryOpts
  );
};

export default useSkusFilterQuery;
