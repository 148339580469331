import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {Form, SelectField} from 'ht-styleguide';
import {ReferenceTypes} from 'features/Products/types/taskGroups.types';
import useGetSkillGuidesQuery from 'queries/skus/query.sku.skillGuides';
import {TTaskChecklistFormik, ReferenceFieldNames} from '../../taskChecklist.types';
import {TOnSelectChangeByName} from '../referenceBuilder.types';

interface ISkillGuideSelectorProps {
  formik: TTaskChecklistFormik;
  onSelectChangeByName: TOnSelectChangeByName;
}

const SkillGuideSelector = ({formik, onSelectChangeByName}: ISkillGuideSelectorProps) => {
  const {id: skuId = ''} = useParams<{id: string}>();
  const {reference} = formik.values;
  const {skill_guide_id, reference_type} = reference || {};
  const isSkillGuideReference = reference_type === ReferenceTypes.FromSkill;

  const {data, isLoading} = useGetSkillGuidesQuery({id: +skuId, queryOpts: {enabled: isSkillGuideReference}});

  const opts = useMemo(() => data?.skill_guides.map(({id, name}) => ({value: id, label: name})) || [], [data]);

  return isSkillGuideReference ? (
    <Form.Row>
      <Form.Column lg={12} md={8} sm={4}>
        <SelectField
          isDisabled={isLoading}
          id={ReferenceFieldNames.SkillGuideId}
          label="Select reference from skill"
          options={opts}
          value={skill_guide_id as number | undefined}
          onChange={onSelectChangeByName(ReferenceFieldNames.SkillGuideId)}
          // @ts-ignore
          error={formik.errors.reference?.skill_guide_id}
        />
      </Form.Column>
    </Form.Row>
  ) : null;
};

export default SkillGuideSelector;
