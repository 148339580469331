import React, {useState, memo} from 'react';
import {useBlocker, useNavigate, useParams} from 'react-router-dom';

/* Hooks/Utils */
import {useMduApi} from 'features/MultiDwellingUnits/MDU.hooks';
import {useProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/TemplateJobs.hooks';

/* Components */
import NoTemplateServices from 'features/MultiDwellingUnits/Parts/Modals/MDU.Modal.NoServices';

import {MduUseParamsTypes} from 'features/MultiDwellingUnits/MDU.types';

import EmptyAddSkus from '../Parts/TemplateJobs.Empty.AddSkus';

let openGate = false;
const TemplatesAndJobsPreLaunchServicesEmpty = () => {
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [unblockPath, setUnblockPath] = useState('');

  /* Hooks */
  const navigate = useNavigate();
  const api = useMduApi();
  const {groupId = '', projectId: projectIdParam = ''} = useParams<MduUseParamsTypes>();
  const {projectId} = useProject({groupId});

  /* ------- METHODS --------- */
  const onHandleDispatchDeleteTemplate = () => {
    api.deleteGroup({projectId, projectGroupId: groupId}).then(() => {
      openGate = true;
      navigate(unblockPath);
    });
  };

  const onHandleCloseModal = () => {
    setShowAlertModal(false);
  };

  useBlocker(({nextLocation}) => {
    if (openGate) {
      openGate = false;
      return false;
    }

    /* We are either opening a service sku or being redirect on a new project creation */
    if (/sku(\/\d+)?/.test(nextLocation.pathname) || +projectIdParam !== +projectId) {
      return false;
    }
    setShowAlertModal(true);
    setUnblockPath(nextLocation.pathname);
    return true;
  });

  return (
    <EmptyAddSkus>
      <NoTemplateServices isVisible={showAlertModal} onCloseCallback={onHandleCloseModal} dispatchDeleteTemplate={onHandleDispatchDeleteTemplate} />
    </EmptyAddSkus>
  );
};

export default memo(TemplatesAndJobsPreLaunchServicesEmpty);
