import React from 'react';
import cn from 'classnames';

export const TEST_IDS = {HEADER: 'CurrentProject.Details.SectionHeader'};

const ProjectDetailsSectionHeader = ({headerText = '', className = '', dataTestId = TEST_IDS.HEADER}) => (
  <p className={cn('h5 n800', className)} data-testid={dataTestId}>
    {headerText}
  </p>
);

export default ProjectDetailsSectionHeader;
