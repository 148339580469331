import {FILE_UPLOAD_VALIDATIONS} from './constants';

export const formatFileSize = (bytes: number, decimalPoint = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1000;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  // eslint-disable-next-line no-restricted-properties -- Use Math.pow here for readability
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPoint))} ${sizes[i]}`;
};

// See FileList documentation here: https://developer.mozilla.org/en-US/docs/Web/API/FileList
export const getFilenamesFromFileList = (fileList: FileList) => {
  if (!fileList) return [];
  const filenames = [];
  for (let i = 0; i < fileList.length; i += 1) {
    filenames.push(fileList[i].name);
  }
  return filenames;
};

export const getFilesGroupedByMaxFileSize = (
  fileList: FileList | File | File[],
  maxFileSize: {
    maxSizeInBytes: number;
    maxSizeDisplayString: string;
  } = {
    maxSizeInBytes: FILE_UPLOAD_VALIDATIONS.MAX_FILE_SIZE,
    maxSizeDisplayString: FILE_UPLOAD_VALIDATIONS.READABLE_MAX_FILE_SIZE,
  }
) => {
  const seed = {error: [], success: []};
  const reducer = (acc: {success: File[]; error: File[]}, file: File) => {
    if (file.size > maxFileSize?.maxSizeInBytes) {
      return {
        ...acc,
        error: [...acc.error, {...file, status: 'rejected', reason: {message: `File ${file.name} exceeded ${maxFileSize?.maxSizeDisplayString} in size`}}],
      };
    }

    return {
      ...acc,
      success: [...acc.success, file],
    };
  };

  if (Array.isArray(fileList) && fileList.length > 0) {
    const grouped = fileList.reduce(reducer, seed);
    return grouped;
  }

  if (fileList instanceof FileList) {
    const grouped = Array.from(fileList).reduce(reducer, seed);
    return grouped;
  }

  return seed;
};
