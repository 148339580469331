import {useMemo} from 'react';
import {useParams} from 'react-router-dom';

import {NavPageTypes, NavTypes} from 'features/MultiDwellingUnits/MDU.types';
import {TProjectsRouteParams} from 'features/MultiDwellingUnits/Pages/Projects/Projects.types';
import {PROJECT_PAGE_PARAMS} from 'features/MultiDwellingUnits/Pages/Projects/Projects.constants';
import {IMDUProjectsFilters} from 'features/MultiDwellingUnits/Pages/Projects/Parts/MDUProjectsFilters/MDUProjectsFilters.types';
import {TColumnKey} from 'features/MultiDwellingUnits/Pages/Projects/Parts/MDUProjectsTable/MDUProjectsTable.types';

/**
 * Get Router Params
 *
 * This hook will return the router params for the projects page in addition to
 * project page "specific" data/params.
 */
export const useProjectsRouteParams = () => {
  const {projectsType = NavTypes.ALL, projectsPage = NavPageTypes.ALL_STATUSES} = useParams<TProjectsRouteParams>();
  const projectPageParams = PROJECT_PAGE_PARAMS[projectsPage];

  /**
   * Set default params for queries
   */
  const pageFilters = useMemo(
    () => ({
      ...projectPageParams.pageFilters,
      myProjects: projectsType === 'own',
    }),
    [projectPageParams.pageFilters, projectsType]
  );

  /**
   * Remove certain filter keys if the projectsType is "own"
   */
  const filterKeys = useMemo(() => {
    return (projectPageParams.filterKeys || []).filter((key: keyof IMDUProjectsFilters) => !(projectsType === NavTypes.OWN && key === 'projectManagerId'));
  }, [projectPageParams.filterKeys, projectsType]);

  /**
   * Remove certain column keys if the projectsType is "own"
   */
  const columnKeys = useMemo(() => {
    return (projectPageParams.columnKeys || []).filter((key: TColumnKey) => !(projectsType === NavTypes.OWN && key === 'pm'));
  }, [projectPageParams.columnKeys, projectsType]);

  return useMemo(
    () => ({
      /** Page header text */
      title: projectPageParams.title(projectsType),
      pageFilters,
      /** Filters that appear in the Apply Filters modal */
      filterKeys,
      /** Columns that appear in the table */
      columnKeys,
      defaultLabel: projectPageParams.defaultLabel,
      statKeys: projectPageParams.statKeys ?? [],
      projectsType,
      projectsPage,
    }),
    [projectPageParams.title, projectPageParams.defaultLabel, projectPageParams.statKeys, pageFilters, filterKeys, columnKeys]
  );
};
