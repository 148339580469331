import React, {useCallback, useEffect, useState} from 'react';
import {Icon} from 'ht-styleguide';

import {$isLinkNode, TOGGLE_LINK_COMMAND} from '@lexical/link';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {useBeautifulMentions} from 'lexical-beautiful-mentions';
import {$isParentElementRTL} from '@lexical/selection';
import {$isTableNode} from '@lexical/table';
import {$findMatchingParent, mergeRegister} from '@lexical/utils';
import {$getSelection, $isRangeSelection, $isRootOrShadowRoot, COMMAND_PRIORITY_CRITICAL, COMMAND_PRIORITY_NORMAL, KEY_MODIFIER_COMMAND, SELECTION_CHANGE_COMMAND} from 'lexical';

import {DestructiveActionsPlugin} from './DestructiveActions.Plugin';

/* utils */
import {getSelectedNode} from '../utils/getSelectedNode';
import {sanitizeUrl} from '../utils/url';

import styles from './plugin.styles.scss';

const rootTypeToRootName = {
  root: 'Root',
  table: 'Table',
};

type TToolbarPluginProps = {
  onAttachAsset: BaseAnyFunction;
};
/**
 * Toolbar at top of editor. Currently contains destruction actions and link insertion.
 *
 * @param uploadFiles
 * @constructor
 */

const ToolbarPlugin = (props: TToolbarPluginProps) => {
  const {onAttachAsset} = props;
  /* Hooks */
  const [editor, getters] = useLexicalComposerContext();
  const {openMentionMenu} = useBeautifulMentions();

  /* Local State */
  const [isLink, setIsLink] = useState(false);
  const [, setIsRTL] = useState(false);
  const [isEditable, setIsEditable] = useState(() => editor.isEditable());
  const [activeEditor, setActiveEditor] = useState(editor);
  const [, setRootType] = useState<keyof typeof rootTypeToRootName>('root');

  /* Constants */
  const editorAttributes = getters?.getTheme()?.attributes || {};

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, e => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      // Update text format
      setIsRTL($isParentElementRTL(selection));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      const tableNode = $findMatchingParent(node, $isTableNode);
      if ($isTableNode(tableNode)) {
        setRootType('table');
      } else {
        setRootType('root');
      }
    }
  }, [activeEditor]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        $updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, $updateToolbar]);

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener(editable => {
        setIsEditable(editable);
      }),
      activeEditor.registerUpdateListener(({editorState}) => {
        editorState.read(() => {
          $updateToolbar();
        });
      })
    );
  }, [$updateToolbar, activeEditor, editor]);

  useEffect(() => {
    return activeEditor.registerCommand(
      KEY_MODIFIER_COMMAND,
      payload => {
        const event: KeyboardEvent = payload;
        const {code, ctrlKey, metaKey} = event;

        if (code === 'KeyK' && (ctrlKey || metaKey)) {
          event.preventDefault();
          return activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl('https://'));
        }
        return false;
      },
      COMMAND_PRIORITY_NORMAL
    );
  }, [activeEditor, isLink]);

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl('https://'));
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  return (
    <div className={styles.editorToolbar}>
      <DestructiveActionsPlugin />
      <Icon
        name="at-sign"
        onClick={() => {
          openMentionMenu({trigger: '@'});
        }}
      />
      <Icon name="link" disabled={!isEditable} onClick={insertLink} aria-label="Insert link" title="Insert link" type="button" />
      {!editorAttributes?.toolbarOmissions?.includes('attachments') && <Icon name="attachment" id="icon-upload-toolbar" onClick={onAttachAsset} />}
    </div>
  );
};

export default ToolbarPlugin;
