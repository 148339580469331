import React from 'react';
import {Player} from '@lottiefiles/react-lottie-player';
import actionComplete from 'assets/lottie/action-completed.json';
import cn from 'classnames';

import styles from './components.styles.scss';

type TGenericSuccessDisplay = Partial<{
  classes: string;
  height: string;
  width: string;
}>;

/**
 * Generic success display

 * @param classes
 * @param height
 * @param width
 * @constructor
 */
const GenericSuccessDisplay = ({classes, height = "248px", width="248px"}: TGenericSuccessDisplay) => {
  return (
    <div data-testid="jobsCreateSuccessDisplay" className={cn(styles.successTakeOverContainer, classes)}>
      <Player autoplay keepLastFrame src={actionComplete} style={{width, height}} />
      <div className={cn("h4 paddingTop-med", styles.subtextSuccess)}>Job added to the project</div>
    </div>
  );
};

export default GenericSuccessDisplay;
