const BASE_ISSUES = 'cloudinary';
export const BASE_CLOUDINARY_QUERY_KEY = [BASE_ISSUES];

/**
 * This is a key factory for the MDU queries within cloudinary
 */
export const CLOUDINARY_QUERY_KEYS = {
  token: () => [...BASE_CLOUDINARY_QUERY_KEY, 'token'],
  tranformSignedUrls: (path: string[]) => [...BASE_CLOUDINARY_QUERY_KEY, 'tranformSignedUrls', path],
};
