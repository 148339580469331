/**
  Falsy values in JS are undefined, null, NaN, 0, "", and false. This function will return the proper type for these values.
 */
export const unwrapFalsyValueFromString = (str: string) => {
  switch (str) {
    case 'undefined':
      return undefined;
    case 'null':
      return null;
    case 'NaN':
      return NaN;
    case '0':
      return 0;
    case '':
      return '';
    case 'false':
      return false;
    default:
      return str;
  }
};

/**
 * Takes in a string and returns a shortened version of it.
 *
 * @param str string
 * @param cutLength number
 * @param appended string
 */
export const shortenStringByNumber = (str: string, cutLength: number, appended: string) => {
  const appendToString = appended || '';
  return str?.length > cutLength ? str.substring(0, cutLength) + appendToString : str;
};
