import React, {Fragment, useState} from 'react';
import cn from 'classnames';
import groupBy from 'lodash/groupBy';
import dayjs from 'dayjs';
// Utils
import {pluralize} from 'utils/text';
import {getTableCellWithFileTypeIcon} from 'features/MultiDwellingUnits/Parts/Table/Table.utils';
import {getFileNameFromAttachmentUrl, isAttachmentImage} from 'features/MultiDwellingUnits/MDU.utils';
import {LL} from 'global/constants/common';
/* Selectors */
import {TUserCurrent, useUserCurrentQuerySelect} from 'queries/User/query.user.current';

// Ducks && Types
import {ProjectNote, ProjectNoteAttachment} from 'features/MultiDwellingUnits/MDU.types';
// Components && Styles
import AvatarUser from 'components/Elements/AvatarUser';
import {Icon} from 'ht-styleguide';
import HTLightbox from 'components/Elements/HTLightbox';
import styles from './notesPage.styles.scss';

type NotesTableProps = {
  notes: ProjectNote[];
};

export const TEST_IDS = {DOWNLOAD_BUTTON: 'download-button'};

export const AttachmentsTable = ({attachments}: {attachments: ProjectNoteAttachment[]}) => {
  const [targetIndex, setTargetIndex] = useState(0); // Index of the image to open in the lightbox
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const toggleLightbox = () => setIsLightboxOpen(!isLightboxOpen);

  const slides = attachments.filter(isAttachmentImage).map(attachment => {
    const filename = getFileNameFromAttachmentUrl(attachment);
    return {
      src: attachment.url,
      alt: filename,
      title: filename,
    };
  });

  return (
    <>
      <table className={cn(styles.attachmentsTable, 'fullwidth marginTop-small2')}>
        <tbody>
          {attachments.map((attachment, i) => {
            const file = {name: getFileNameFromAttachmentUrl(attachment, {withoutQueryParams: true}), type: attachment.file_type};
            const openImageInLightbox = () => {
              setTargetIndex(i);
              toggleLightbox();
            };
            const openAttachment = isAttachmentImage(attachment) ? openImageInLightbox : () => window.open(attachment.url, '_blank');
            return (
              <tr key={attachment.id} className={styles.attachmentTableRow} onClick={openAttachment} data-testid={TEST_IDS.DOWNLOAD_BUTTON}>
                <td className="padding-small">{getTableCellWithFileTypeIcon(file)}</td>
                <td>
                  <Icon name="download" className={cn('n900', styles.downloadIcon)} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {isLightboxOpen && <HTLightbox slides={slides} index={targetIndex} open={isLightboxOpen} onClose={toggleLightbox} showThumbnails={slides.length > 1} carousel={{finite: true}} />}
    </>
  );
};

export const NoteLineItem = ({note, user}: {note: ProjectNote; user?: TUserCurrent}) => {
  const [isAttachmentsOpen, setIsAttachmentsOpen] = useState(false);
  const {author} = note;
  const userIsAuthor = user && author?.email === user?.email;
  const toggleAttachments = () => setIsAttachmentsOpen(!isAttachmentsOpen);
  const chevronIcon = isAttachmentsOpen ? 'chevron-up' : 'chevron';
  const creationTime = dayjs(note.created_at).format('h:mm A');
  return (
    <div className={cn('flex marginBottom-small2', styles.noteLineItem)}>
      <AvatarUser isOwner={Boolean(userIsAuthor)} name={note.author.name} medium />
      <div className="marginLeft-medium fullwidth">
        <div className="flex justify-content-space-between">
          <p className="h5 marginBottom-small n800">{note.entry_type_label}</p>
          <p className={cn(styles.smallText, 'n700')}>{creationTime}</p>
        </div>
        <div className={styles.innerNoteContainer}>
          <p className="p0 marginBottom-small n800">{note.content}</p>
          <p className={cn(styles.smallText, 'n700')}>{note.author.name}</p>
          {note.attachments.length > 0 ? (
            <button className="plainButton" type="button" onClick={toggleAttachments}>
              <p className={cn(styles.smallText, 'teal marginTop-small')}>
                <Icon name="attachment" className="marginRight-tiny1" />
                <span>{`${note.attachments.length} ${pluralize('Attachment', note.attachments.length)}`}</span>
                <Icon name={chevronIcon} className="marginLeft-tiny1" />
              </p>
            </button>
          ) : null}
          {isAttachmentsOpen ? <AttachmentsTable attachments={note.attachments} /> : null}
        </div>
      </div>
    </div>
  );
};

const DateDividerLine = () => (
  <div className="flex align-items-center fullwidth">
    <div className={styles.dividerLine} />
  </div>
);
const DateDivider = ({date}: {date: string}) => (
  <div className="flex align-items-center marginBottom-medium1 paddingX-huge">
    <DateDividerLine />
    <p className={cn('p1 n700 paddingX-small1', styles.dividerText)}>{date}</p>
    <DateDividerLine />
  </div>
);

const NotesDisplay = ({notes}: NotesTableProps) => {
  const user = useUserCurrentQuerySelect();

  if (notes.length === 0) return null;
  const groupedNotes = groupBy(notes, note => dayjs(note.created_at).format(LL));
  const datesInReverse = Object.keys(groupedNotes).reverse();
  return (
    <div className="paddingX-huge">
      {datesInReverse.map(date => (
        <Fragment key={date}>
          <DateDivider date={date} />
          <div className={styles.noteContainer}>
            {groupedNotes[date].reverse().map(note => (
              <NoteLineItem user={user} note={note} key={note.id} />
            ))}
          </div>
        </Fragment>
      ))}
    </div>
  );
};

export default NotesDisplay;
