import {ReactText} from 'react';
import {CHIP_TYPES} from 'ht-styleguide';
import set from 'lodash/set';
import stylesPriority from 'components/Elements/IconPriority/iconPriority.styles.scss';
import {ISSUE_FIELDS_ENTITY_NAMES, ISSUE_SLIDESHEET_TYPES, ISSUE_PARAMS_TYPES, ISSUE_ENTITY_TYPES} from './Issues.constants';

export const getValidIssueMode = (mode: string | null = '') =>
  Object.values(ISSUE_SLIDESHEET_TYPES).find(v => {
    return v === mode?.toLowerCase();
  }) || '';

/**
 * @description This returns the priority icon class based on the priority
 *
 * @param p (priority)
 *
 * Note: Seems we are finally seeing base priorities that are not changing.
 *       Going with a string over id, as I can't be sure the ids correspond.
 *
 *       Add attributes as you see fit. I can't imagine this will get too heavy.
 */
export const getAttributesByPriorityStatus = (p: string | ReactText = '') => {
  let priority = p;
  const typeofStatus = typeof priority;

  // @ts-ignore
  if (!{number: 1, string: 1}[typeofStatus]) {
    priority = 1;
  }
  const lowercaseStatus = typeofStatus === 'string' ? (priority as string).toLowerCase() : priority;

  /* Add your issue priority/status attributes below */
  switch (lowercaseStatus) {
    case 'critical':
    case 4:
      return {
        icon: {
          className: stylesPriority.high,
          iconDefault: 'alert-triangle-fill',
          name: 'high',
        },
        name: 'critical',
      };
    case 'high':
    case 3:
      return {
        icon: {
          className: stylesPriority.high,
          iconDefault: 'level-up',
          name: 'high',
        },
        name: 'high',
      };
    case 'medium':
    case 2:
      return {
        icon: {
          className: stylesPriority.medium,
          iconDefault: 'equals',
          name: 'high',
        },
        name: 'medium',
      };
    case 'low':
    case 1:
      return {
        icon: {
          className: stylesPriority.low,
          iconDefault: 'chevron',
          name: 'low',
        },
        name: 'low',
      };
    default:
      /**
       * `priority` is not a required field when an issue is created.
       */
      return null;
  }
};

/**
 * @description This returns the priority icon class based on the status
 *
 * @param s (status)
 *
 * Note: Seems we are finally seeing base statuses that are not changing.
 *       Going with a string over id, as I can't be sure the ids correspond.
 *
 *       Add attributes as you see fit. I can't imagine this will get too heavy.
 */

export const getAttributesByTicketStatus = ({key = '', omitIcon = true}: {key: string | ReactText; omitIcon?: boolean}) => {
  let status = key;
  const typeofStatus = typeof status;

  // @ts-ignore
  if (!{number: 1, string: 1}[typeofStatus]) {
    status = 1;
  }
  const lowercaseStatus = typeofStatus === 'string' ? (status as string).toLowerCase() : status;

  /* TEMP: Until BE returns the status mapping. Add your issue priority/status attributes below */
  const keyObject = (() => {
    switch (lowercaseStatus) {
      case 'resolved':
      case 4:
        return {
          icon: {
            iconDefault: 'chevron',
          },
          component: {
            chip: CHIP_TYPES.success,
          },
          name: 'Resolved',
          type: 'success',
        };
      case 'on hold':
      case 3:
        return {
          icon: {
            iconDefault: 'chevron',
          },
          component: {
            chip: CHIP_TYPES.neutral,
          },
          name: 'On Hold',
          type: 'warning',
        };
      case 'in progress':
      case 2:
        return {
          icon: {
            iconDefault: 'chevron',
          },
          component: {
            chip: CHIP_TYPES.warning,
          },
          name: 'In Progress',
          type: 'primary',
        };
      case 'open':
      case 1:
        return {
          icon: {
            iconDefault: 'chevron',
          },
          component: {
            chip: CHIP_TYPES.primary,
          },
          name: 'Open',
          type: 'neutral',
        };
      default:
        return {
          icon: {
            iconDefault: 'chevron',
          },
          component: {
            chip: CHIP_TYPES.neutral,
          },
          name: 'Status',
          type: 'neutral',
        };
    }
  })();

  if (omitIcon) {
    set(keyObject, 'icon.iconDefault', undefined);
  }

  return keyObject;
};

/**
 * Get debounced time by entity name
 *
 * @description This returns the debounced time for a given entity name.
 */
export const getDebouncedTimeByEntityName = (entityName: string = ''): number => {
  switch (entityName) {
    case ISSUE_FIELDS_ENTITY_NAMES.text:
    case ISSUE_FIELDS_ENTITY_NAMES.long_text:
    case ISSUE_FIELDS_ENTITY_NAMES.numeric:
      return 1500;
    default:
      return 0;
  }
};

export const buildSideSheetQueryParam = ({
  issueMode,
  entityType,
  ticketId,
}: {
  issueMode: (typeof ISSUE_SLIDESHEET_TYPES)[keyof typeof ISSUE_SLIDESHEET_TYPES];
  entityType: (typeof ISSUE_ENTITY_TYPES)[keyof typeof ISSUE_ENTITY_TYPES];
  ticketId: number;
}) => {
  return `${ISSUE_PARAMS_TYPES.issue_mode}=${issueMode}&${ISSUE_PARAMS_TYPES.entity_type}=${entityType}&${ISSUE_PARAMS_TYPES.ticket_id}=${ticketId}`;
};
