import React, {forwardRef, useCallback, useState, useMemo, useImperativeHandle, RefObject} from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {Modal, Button, BUTTON_THEMES, InputField, htToast} from 'ht-styleguide';
import useShareChecklistMutation from '../../queries/mutation.shareChecklist';

interface IShareChecklistModalProps {
  orderId: number | null | undefined;
  dataTestId?: string;
}

export interface IShareChecklistModalRef {
  toggleShareChecklistModal: () => void;
}

const EMAIL_ID = 'share-checklist-emails';

/**
 * The input field is a string where the emails are separated by commas. This schema will transform the string
 * into an array of emails and then validate each entry.
 */
const YUP_SCHEMA = yup.object({
  [EMAIL_ID]: yup
    .array()
    .of(yup.string().email('Please enter a valid email address'))
    .required('Email is required')
    .transform((_, originalValue) => {
      return originalValue.split(',').map((email: string) => email.trim());
    }),
});

const ShareChecklistModal = forwardRef<IShareChecklistModalRef, IShareChecklistModalProps>(({orderId, dataTestId = 'ShareChecklistModal'}, ref) => {
  /*
   *******************************************************
    Modal component controls
   *******************************************************
   */
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Expose a method to toggle the modal to the parent component
  useImperativeHandle(
    ref,
    () => ({
      toggleShareChecklistModal: () => {
        setIsModalOpen(!isModalOpen);
      },
    }),
    [isModalOpen]
  );

  const hideModal = useCallback(() => (ref as RefObject<IShareChecklistModalRef>)?.current?.toggleShareChecklistModal(), [ref]);

  /*
   *******************************************************
    Form related
   *******************************************************
   */
  const {mutateAsync} = useShareChecklistMutation();

  const formik = useFormik({
    initialValues: {
      [EMAIL_ID]: '',
    },
    validationSchema: YUP_SCHEMA,
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async values => {
      const emailValues = values[EMAIL_ID];
      const formattedEmailArray = emailValues.split(',').map((email: string) => email.trim());
      await mutateAsync(
        {orderId: orderId!, emails: formattedEmailArray},
        {
          onSuccess: () => {
            htToast('Checklist copy sent');
            hideModal();
          },
        }
      );
    },
  });

  const errorMessage = useMemo(() => {
    const errors = formik.errors[EMAIL_ID];
    if (Array.isArray(errors)) {
      return errors.find(error => !!error); // Let's just show the first one
    }
    return errors;
  }, [formik.errors]);

  const disableSubmitBtn = !formik.values[EMAIL_ID].length;

  return (
    <Modal
      header="Share checklist"
      hide={hideModal}
      isVisible={isModalOpen}
      footerElement2={
        <Button theme={BUTTON_THEMES.SECONDARY} onClick={hideModal}>
          Cancel
        </Button>
      }
      footerElement3={
        <Button theme={BUTTON_THEMES.PRIMARY} onClick={formik.handleSubmit} disabled={disableSubmitBtn}>
          Send Copy
        </Button>
      }
    >
      <p className="p1 marginBottom-small2">
        Send a PDF copy of this checklist to 1 or more email recipients.
        <br />
        Use a comma to separate multiple entries.
      </p>
      <InputField dataTestIdInput={`${dataTestId}-Input`} error={errorMessage} id={EMAIL_ID} onChange={formik.handleChange} placeholder="Enter email address" />
    </Modal>
  );
});

export default ShareChecklistModal;
