import React from 'react';
import * as Yup from 'yup';
// Hooks
import {useFormik} from 'formik';
import {useAppDispatch} from 'hooks/useAppDispatch';
// Utils
import {noop} from 'utils/event';
// Types && Ducks
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {ProjectDetails} from 'features/MultiDwellingUnits/MDU.types';
// Components && Styles
import {Modal, ELEMENT_SIZE, Form, Button, BUTTON_THEMES} from 'ht-styleguide';
import TechLeadSelect from 'features/MultiDwellingUnits/Parts/FormFields/LeadTechSelect';
import styles from './modals.styles.scss';

type ModalSetBillingLeadProps = {
  isVisible: boolean;
  toggleModal?: BaseAnyFunction;
  project: ProjectDetails;
};

export const FORM_FIELDS: {[key: string]: {id: string; label: string}} = {
  BILLING_LEAD: {id: 'billingLeadId', label: 'New Payment Recipient'},
};

const billingLeadSchema = Yup.object().shape({
  [FORM_FIELDS.BILLING_LEAD.id]: Yup.string().required('Required'),
});

export const HEADER_AND_BUTTON_TEXT = {
  HAS_LEAD: 'Change Payment Recipient',
  NO_LEAD: 'Choose Payment Recipient',
};

export const BODY_TEXT = {
  HAS_LEAD: 'Choose a new payment recipient to replace the current one.',
  NO_LEAD:
    'Choose one of our registered technicians to be the main payment recipient and point of contact for all matters related to billing. The payment recipient will have payment history access within the field service app.',
};

const ModalSetBillingLead = ({isVisible, toggleModal = noop, project}: ModalSetBillingLeadProps) => {
  const dispatch = useAppDispatch();

  const handleModalClose = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    formik.resetForm();
    toggleModal();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {[FORM_FIELDS.BILLING_LEAD.id]: ''},
    validationSchema: billingLeadSchema,
    onSubmit: async billingLeadParams => {
      await dispatch(mduProjectsSlice.actions.updateProject({projectId: project.id, projectDetails: {project: {...billingLeadParams}}}));
      handleModalClose();
    },
  });

  const hasBillingLead = Boolean(project.billingLead);
  const headerAndButtonText = hasBillingLead ? HEADER_AND_BUTTON_TEXT.HAS_LEAD : HEADER_AND_BUTTON_TEXT.NO_LEAD;
  const bodyText = hasBillingLead ? BODY_TEXT.HAS_LEAD : BODY_TEXT.NO_LEAD;

  const handleSubmit = () => {
    if (!formik.isSubmitting) {
      formik.submitForm();
    }
  };

  return (
    <Modal
      elementSize={ELEMENT_SIZE.MEDIUM}
      isVisible={isVisible}
      hide={handleModalClose}
      header={headerAndButtonText}
      bodyContainerClasses={styles.overflowInitial}
      modalClassName={styles.overflowInitial}
      footerElement3={
        <Button theme={BUTTON_THEMES.PRIMARY} onClick={handleSubmit}>
          {headerAndButtonText}
        </Button>
      }
    >
      <p className="p1 marginBottom-small2">{bodyText}</p>
      <Form>
        <Form.Row>
          <Form.Column lg={12} md={8} sm={4}>
            <TechLeadSelect key={FORM_FIELDS.BILLING_LEAD.id} fieldName={FORM_FIELDS.BILLING_LEAD.id} formik={formik} label={FORM_FIELDS.BILLING_LEAD.label} />
          </Form.Column>
        </Form.Row>
      </Form>
    </Modal>
  );
};

export default ModalSetBillingLead;
