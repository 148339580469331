import React from 'react';
import {TaskTypes} from 'features/Products/types/taskGroups.types';
import BinaryOptions from './OptionsBuilder.BinaryOptions';
import MultiOptions from './OptionsBuilder.MultiOptions';
import {IOptionsBuilderProps} from './optionsBuilder.types';

const ALLOWED_TASK_TYPES = [TaskTypes.Binary, TaskTypes.MultiSelect, TaskTypes.SingleSelect];

/**
 * Only for task types that require options - Binary, MultiSelect, SingleSelect
 */
const OptionsBuilder = ({formik, onToggleChange, taskType}: IOptionsBuilderProps) => {
  if (!ALLOWED_TASK_TYPES.includes(taskType)) return null;
  return taskType === TaskTypes.Binary ? <BinaryOptions formik={formik} /> : <MultiOptions formik={formik} onToggleChange={onToggleChange} taskType={taskType} />;
};

export default OptionsBuilder;
