import {useMemo} from 'react';
import {useMatch, useNavigate} from 'react-router-dom';
import {ISidebarEntryGroup} from 'components/Layouts/AdminHub/Sidebar/Sidebar.types';
import {Routes} from 'global/routes/routes';
import {dispatchNavTypePath} from 'global/paths';
import {DispatchNavTypes, TDispatchRouteParams} from '../dispatch.types';
import {DISPATCH_UNASSIGNED_LIST_PARAMS} from '../dispatch.constants';
import unassignedOrderStats from '../queries/query.dispatch.getUnassignedStats';

const useDispatchSidebarMenu = () => {
  const navigate = useNavigate();

  const match = useMatch(Routes.DISPATCH_NAV_TYPE());
  const {dispatchNavType} = match?.params || ({} as TDispatchRouteParams);

  const {data: dataAll} = unassignedOrderStats({filters: DISPATCH_UNASSIGNED_LIST_PARAMS[DispatchNavTypes.All].statsFilters});
  const {data: dataAssignedToMe} = unassignedOrderStats({filters: DISPATCH_UNASSIGNED_LIST_PARAMS[DispatchNavTypes.AssignedToMe].statsFilters});

  const menu = useMemo<ISidebarEntryGroup[]>(() => {
    const onClick = (navTypePath: string) => () => {
      navigate(navTypePath);
    };

    return [
      {
        entries: [
          {
            key: DispatchNavTypes.All,
            label: 'All unassigned jobs',
            active: dispatchNavType === DispatchNavTypes.All,
            onClick: onClick(dispatchNavTypePath(DispatchNavTypes.All)),
            badge: {
              value: dataAll?.total ?? 0,
            },
          },
          {
            key: DispatchNavTypes.AssignedToMe,
            label: 'Jobs assigned to me',
            active: dispatchNavType === DispatchNavTypes.AssignedToMe,
            onClick: onClick(dispatchNavTypePath(DispatchNavTypes.AssignedToMe)),
            badge: {
              value: dataAssignedToMe?.total ?? 0,
            },
          },
        ],
      },
    ];
  }, [dataAll?.total, dataAssignedToMe?.total, navigate, dispatchNavType]);

  return menu;
};

export default useDispatchSidebarMenu;
