/* Available transform file types */
export const CLOUDINARY_TRANSFORM_FILE_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
};

export const SHARED_CLOUDINARY_FOLDERS = {
  /** Admin and tech app use this folder. Make sure they are aligned */
  OrderTasks: 'order_tasks',
};
