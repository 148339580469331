import React, {useMemo, useRef} from 'react';
import {Button, BUTTON_THEMES} from 'ht-styleguide';
import {ISharedDataProps} from '../../orderTaskChecklistPage.types';
import {checkCanModifyTask, checkCanShareChecklist} from '../../orderTaskChecklist.utils';
import ShareChecklistModal, {IShareChecklistModalRef} from './SectionHeader.ShareChecklistModal';

interface ISectionHeaderProps extends Pick<ISharedDataProps, 'order' | 'checklistsForOrder' | 'taskCompletionStatusModalRef'> {}

const SectionHeader = ({order, checklistsForOrder, taskCompletionStatusModalRef}: ISectionHeaderProps) => {
  // Completing Tasks Button related
  const allChecklistsCompleted = useMemo(() => {
    return Object.values(checklistsForOrder)?.every(checklist => checklist.data?.all_tasks_passed);
  }, [checklistsForOrder]);
  const canUpdateTaskCompletionStatus = checkCanModifyTask(order) && !allChecklistsCompleted;
  const completeAllTaskCB = () => taskCompletionStatusModalRef.current?.toggleTaskCompletionStatusModal({type: 'allTasksOnOrderComplete'});

  // Sharing checklist related
  const shareChecklistModalRef = useRef<IShareChecklistModalRef>(null);
  const canShareChecklist = checkCanShareChecklist(order);
  const toggleShareChecklistModal = () => shareChecklistModalRef.current?.toggleShareChecklistModal();

  return (
    <div className="flex align-items-center justify-content-space-between paddingBottom-small1 paddingRight-large">
      <h5>Service Checklist</h5>
      <div>
        {canUpdateTaskCompletionStatus && (
          <Button onClick={completeAllTaskCB} small theme={BUTTON_THEMES.SECONDARY} icon="checkmark-plain">
            Complete All Tasks
          </Button>
        )}
        {canShareChecklist && (
          <>
            <Button onClick={toggleShareChecklistModal} small theme={BUTTON_THEMES.PRIMARY} icon="icon-send">
              Share
            </Button>
            <ShareChecklistModal ref={shareChecklistModalRef} orderId={order?.id} />
          </>
        )}
      </div>
    </div>
  );
};

export default SectionHeader;
