import mduProjectsSlice from '../../../MDU.ducks';
import {TSingleActionTypes} from '../../../MDU.types';
import {TGetMenuItemsByStatusFunction} from '../Projects.types';

/**
 * Used with `getCustomActionByStatus`
 */
const getMenuItemsByStatus: TGetMenuItemsByStatusFunction =
  actionItems =>
  ({project, dispatch}) => {
    const ACTIONS = {
      getPauseDisplay() {
        if (project.paused) {
          return {
            text: 'Resume Project',
            key: 'resumeProject',
            onClick: () => {
              dispatch(mduProjectsSlice.actions.setActionItemModalSlide({entity: project, modalKey: TSingleActionTypes.RESUME, modalType: 'Project'}));
            },
          };
        }

        return {
          text: 'Pause Project',
          key: 'pauseProject',
          onClick: () => {
            dispatch(mduProjectsSlice.actions.setActionItemModalSlide({entity: project, modalKey: TSingleActionTypes.PAUSE, modalType: 'Project'}));
          },
        };
      },

      pauseHistory() {
        return {
          text: 'View Pause History',
          key: 'pauseHistory',
          onClick: () => dispatch(mduProjectsSlice.actions.setSideSheetKey('pauseHistory')),
        };
      },

      deleteProject() {
        return {
          text: 'Delete Project',
          key: 'deleteProject',
          onClick: () => {
            dispatch(mduProjectsSlice.actions.setActionItemModalSlide({entity: project, modalKey: TSingleActionTypes.DELETE, modalType: 'Project'}));
          },
        };
      },

      approveProject() {
        return {
          text: 'Approve Project',
          key: 'approveProject',
          onClick: () => {
            dispatch(mduProjectsSlice.actions.setActionItemModalSlide({entity: project, modalKey: TSingleActionTypes.APPROVE, modalType: 'Project'}));
          },
        };
      },
    };

    return actionItems.map(actionItem => ACTIONS[actionItem]());
  };

export default getMenuItemsByStatus;
