import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';
import {useParams, useNavigate} from 'react-router-dom';
import {BUTTON_THEMES, Icon, Panel} from 'ht-styleguide';
import {mduProjectPagePostLaunchGroupServicesPath, mduProjectPageGroupServicesPath} from 'global/paths';
import {pluralize} from 'utils/text';

/* Queries */
import {useCreateJobTemplateDraftMutation} from 'features/MultiDwellingUnits/queries/mutation.jobs.drafts';
import {useDuplicateTemplateMutation} from 'features/MultiDwellingUnits/queries/mutation.jobs.duplicateTemplate';

/* Ducks */
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';

/* Hooks */
import {useCurrentProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/CurrentProject.hooks';
import useOutsideClick from 'hooks/useOutsideClick';

/* Utils */
import {noop, stopImmediatePropagation, stopPropagationOnDiv} from 'utils/event';

/* Components */
import PageHeader from 'components/Elements/PageHeader';
import TemplateJobsGroupServicesToolTip from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/Parts/TemplateJobs.GroupServicesToolTip';
import {MenuListItem, MenuPanel, MenuUnorderedList} from 'components/Elements/Menu';

import {MduUseParamsTypes, ProjectGroup} from 'features/MultiDwellingUnits/MDU.types';

import styles from '../templatejobs.styles.scss';

/* Inline Types */
type TGroupId = number | string | null;
const TemplatesAndJobsPostLaunchTemplates = () => {
  /* Local State */
  const [activeGroupMenuId, setActiveGroupMenuId] = useState<TGroupId>();

  /* Hooks */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {projectId = '', stage = ''} = useParams<MduUseParamsTypes>(); //
  const {projectGroups} = useCurrentProject();
  const {mutateAsync: createJobTemplate} = useCreateJobTemplateDraftMutation();
  const groupMenuRef: React.RefObject<HTMLDivElement> = useRef(null);

  /* Mutations/Queries */
  const duplicateTemplateMutation = useDuplicateTemplateMutation();

  /* ------- METHODS --------- */

  /* --------- Duplicate Template ------- */
  const duplicateTemplate = (group: ProjectGroup) => {
    duplicateTemplateMutation.mutate(
      {
        name: `Copy of ${group.name}`,
        groupId: group.id,
        withDraft: true,
        projectId,
      },
      {
        onSuccess: response => {
          // 1. Update the draft state, redux as we want to edit it.
          dispatch(mduProjectsSlice.actions.addTemplateCreationNewDraft(response));
          // 2. Redirect to the services view
          navigate(mduProjectPagePostLaunchGroupServicesPath(projectId, response.id));
        },
      }
    );
  };

  const onHandleClickTemplate = (group: ProjectGroup) => () => {
    navigate(mduProjectPageGroupServicesPath(projectId, stage, group.id as string));
  };

  /* Adds a draft template: until save is clicked */
  const onHandleCreateTemplate = async () => {
    const data = await createJobTemplate();
    /* 1. Update draft for "new" template */
    dispatch(mduProjectsSlice.actions.createTemplateCreationNewDraft(data.projectGroup));
    /* get group id and redirect */
    navigate(mduProjectPagePostLaunchGroupServicesPath(projectId, data.projectGroup?.id as string));
  };

  const handleClickOutside = () => {
    setActiveGroupMenuId(null);
  };

  useOutsideClick(groupMenuRef, handleClickOutside);

  useEffect(() => {
    ReactTooltip.rebuild();
    ReactTooltip.hide();
  }, [projectGroups]);

  return (
    <div className="marginBottom-medium2">
      <PageHeader title="Job Templates" HeaderRightContent={<PageHeader.Button theme={BUTTON_THEMES.SECONDARY} onClick={onHandleCreateTemplate} iconName="v2-plus-icon" label="New Template" />} />
      {projectGroups?.map((group: ProjectGroup) => {
        return (
          <Panel noShadow largeBorderRadius className={cn(styles.panel, 'padding-small marginBottom-small')} key={group.id}>
            <div>
              <div className="p1 paddingBottom-small font-weight-medium flex flex-direction-row justify-content-space-between cursorPointer" onClick={onHandleClickTemplate(group)}>
                <div>{group.name}</div>
                <div data-for="groupAttributes" data-tip={group.id} className={styles.hoverArea} />
                <div className="position relative">
                  <div ref={groupMenuRef} onClick={stopPropagationOnDiv}>
                    <Icon name="more" className={styles.icon} onClick={() => setActiveGroupMenuId(group.id)} />
                  </div>
                  {activeGroupMenuId === group.id && (
                    <MenuPanel>
                      <MenuUnorderedList>
                        <MenuListItem
                          dataTestId="menu-edit-job-info"
                          onClick={e => {
                            stopImmediatePropagation(noop)(e);
                            duplicateTemplate(group);
                          }}
                        >
                          Duplicate Template
                        </MenuListItem>
                      </MenuUnorderedList>
                    </MenuPanel>
                  )}
                </div>
              </div>
              <div className="p2 n800 flex flex-direction-row justify-content-space-between cursorPointer" onClick={onHandleClickTemplate(group)}>
                <div className="flex flex-direction-column">
                  <div>
                    <span>
                      {group.projectServices?.length}&nbsp;{pluralize('Service', group.projectServices?.length ?? 0)}
                    </span>
                  </div>
                </div>
                <div data-for="groupAttributes" data-tip={group.id} className={styles.hoverArea} />
                <div className="n200">{group.perUnitTotalWithoutSubsidyFormatted} per unit</div>
              </div>
            </div>
          </Panel>
        );
      })}
      <TemplateJobsGroupServicesToolTip />
    </div>
  );
};

export default TemplatesAndJobsPostLaunchTemplates;
