import React, {ComponentProps, useEffect} from 'react';
import {Form, SelectField} from 'ht-styleguide';
import {TaskFieldNames} from 'features/Products/types/taskGroups.types';
import usePrevious from 'hooks/usePrevious';
import {DEFAULT_REFERENCE_OBJ} from '../constants/form';
import {TTaskChecklistFormik, TaskChecklistCustomFieldNames, ReferenceFieldNames} from '../taskChecklist.types';
import ToggleWithTooltip from '../ToggleWithTooltip';
import AttachmentsBuilder from './AttachmentsBuilder';
import ReferenceLinkBuilder from './ReferenceLinkBuilder';
import SkillGuideSelector from './SkillGuideSelector';
import {REFERENCE_TYPE_OPTS} from './referenceBuilder.constants';
import {TOnSelectChangeByName} from './referenceBuilder.types';

export interface IReferenceBuilderProps {
  formik: TTaskChecklistFormik;
  onToggleChange: ComponentProps<typeof ToggleWithTooltip>['onToggleChange'];
}

/**
 * A task can have a reference attached for the worker's use.
 * A reference can be a file, link, or a skill guide. Depending on the type selected,
 * the user will be prompted to fill out the necessary fields.
 */
const ReferenceBuilder = ({formik, onToggleChange}: IReferenceBuilderProps) => {
  const {hasReference = false, reference} = formik.values;
  const referenceType = reference?.reference_type;

  // Set default reference when toggling on if no reference is set
  const prevHasReference = usePrevious(hasReference);
  useEffect(() => {
    if (hasReference && !prevHasReference && !reference) {
      formik.setFieldValue(TaskFieldNames.Reference, DEFAULT_REFERENCE_OBJ);
    }
  }, [formik, hasReference, prevHasReference, reference]);

  // Used on <SelectField /> that are shared across builders
  const onSelectChangeByName: TOnSelectChangeByName = (referenceFieldName: (typeof ReferenceFieldNames)[keyof typeof ReferenceFieldNames]) => {
    const onChange: ComponentProps<typeof SelectField>['onChange'] = selected => {
      const {value} = selected || {};
      formik.setFieldValue(`${TaskFieldNames.Reference}.${referenceFieldName}`, value);
    };
    return onChange;
  };

  return (
    <>
      <Form.Row>
        <Form.Column lg={12} md={8} sm={4}>
          <div className="border-top-3 paddingTop-small1" />
          <ToggleWithTooltip
            id={TaskChecklistCustomFieldNames.HasReference}
            label="Attach reference material"
            toggleValue={hasReference}
            onToggleChange={onToggleChange}
            error={formik.errors.hasReference}
            tooltipContent="Worker will have access to this while working"
          />
        </Form.Column>
      </Form.Row>
      {hasReference && (
        <>
          {referenceType && (
            <>
              <Form.Row>
                <Form.Column lg={12} md={8} sm={4}>
                  <SelectField
                    id={ReferenceFieldNames.ReferenceType}
                    label="Reference Type"
                    options={REFERENCE_TYPE_OPTS}
                    value={referenceType}
                    onChange={onSelectChangeByName(ReferenceFieldNames.ReferenceType)}
                    // @ts-ignore
                    error={formik.errors.reference?.reference_type}
                  />
                </Form.Column>
              </Form.Row>
              <SkillGuideSelector formik={formik} onSelectChangeByName={onSelectChangeByName} />
              <ReferenceLinkBuilder formik={formik} />
              <AttachmentsBuilder formik={formik} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default ReferenceBuilder;
