import {TaskTypes, TTask, TUpsertTask, ReferenceTypes, TaskFieldNames, TUpsertTaskChecklist} from 'features/Products/types/taskGroups.types';
import {TTaskChecklistFormValues, TaskChecklistCustomFieldNames, TaskTypesForChecklist} from './taskChecklist.types';
import {DEFAULT_INITIAL_VALUES, DEFAULT_REFERENCE_OBJ} from './constants/form';
import {TOGGLE_SETTINGS_PER_TASK} from './constants/main';

/**
 * Used to clean up payload before sending to the BE.
 * - Keep in mind that the endpoint is an upsert type, so we should try to send only the necessary fields.
 * - Additionally, tasks are expected to have default values for some fields. For instance, a task of type
 * `confirm` should not have `options` items as `options` are only reserved for `multi_select` and
 * `single_select` tasks.
 */
export const cleanValuesForApi = (formValues: TTaskChecklistFormValues): TUpsertTask => {
  const newValues = {...formValues};
  const {task_type}: TUpsertTask = newValues;

  // Modify `options` based on task type
  if (task_type === TaskTypes.Binary) {
    newValues.options = newValues.options.slice(0, 2);
  } else if (![TaskTypes.MultiSelect, TaskTypes.SingleSelect].includes(task_type)) {
    newValues.options = DEFAULT_INITIAL_VALUES.options;
  }

  // Reset `include_other_option` based on task type
  if (![TaskTypes.MultiSelect, TaskTypes.SingleSelect].includes(task_type)) {
    newValues.include_other_option = DEFAULT_INITIAL_VALUES.include_other_option;
  }

  // Reset `multiple_photos` based on task type
  if (task_type !== TaskTypes.Photo) {
    newValues.multiple_photos = DEFAULT_INITIAL_VALUES.multiple_photos;
  }

  // Reset `show_optional_note` and `show_optional_photo` based on task type
  const toggleSetting = TOGGLE_SETTINGS_PER_TASK[task_type];
  if (!toggleSetting.canHaveOptionalNoteField) {
    newValues.show_optional_note = DEFAULT_INITIAL_VALUES.show_optional_note;
  }
  if (!toggleSetting.canHaveOptionalPhotoField) {
    newValues.show_optional_photo = DEFAULT_INITIAL_VALUES.show_optional_photo;
  }

  // Remove certain reference object fields depending on reference_type
  const {reference} = newValues;
  switch (reference && reference.reference_type) {
    case ReferenceTypes.FromSkill:
      reference!.attachments = DEFAULT_REFERENCE_OBJ.attachments;
      reference!.url = DEFAULT_REFERENCE_OBJ.url;
      reference!.title = DEFAULT_REFERENCE_OBJ.title;
      break;
    case ReferenceTypes.Link:
      reference!.attachments = DEFAULT_REFERENCE_OBJ.attachments;
      reference!.skill_guide_id = DEFAULT_REFERENCE_OBJ.skill_guide_id;
      break;
    case ReferenceTypes.File:
      reference!.skill_guide_id = DEFAULT_REFERENCE_OBJ.skill_guide_id;
      reference!.url = DEFAULT_REFERENCE_OBJ.url;
      break;
    default:
      break;
  }

  // Clear reference object if `hasReference` is false
  if (!newValues[TaskChecklistCustomFieldNames.HasReference]) {
    newValues.reference = null;
  }

  // Remove custom fields as these are not expected by the BE
  Object.values(TaskChecklistCustomFieldNames).forEach(field => {
    delete newValues[field];
  });

  return newValues;
};

export const setInitialValues = (taskToEdit: TTask | null | undefined): TTaskChecklistFormValues => {
  let initialValues = {...DEFAULT_INITIAL_VALUES};

  if (taskToEdit) {
    const {reference, task_type, ...rest} = taskToEdit;
    initialValues[TaskFieldNames.Reference] = reference;
    initialValues[TaskChecklistCustomFieldNames.HasReference] = !!reference;
    // get away from type `TaskTypes` and use `TaskTypesForChecklist`
    initialValues[TaskFieldNames.TaskType] = task_type as TaskTypesForChecklist;
    initialValues = {...initialValues, ...rest};
  }

  return initialValues;
};

/**
 * Given a task checklist, returns a suggested group name for a new group.
 * The suggested name is "Group X", where X is the number of unlocked groups
 * plus one.
 */
export const getSuggestedGroupName = (taskCheclist: TUpsertTaskChecklist) => {
  const numUnlockedGroups = taskCheclist.task_groups.filter(group => !group.locked).length;
  return `Group ${numUnlockedGroups + 1}`;
};

/**
 * Given a task checklist, returns the position at which a new group should be inserted.
 *
 * The BE will enforce the insertion order, but to avoid jumps in the UI, we should
 * insert at the proper index.
 */
export const getInsertPositionForNewGroup = (taskChecklist: TUpsertTaskChecklist) => {
  if (!taskChecklist?.task_groups || taskChecklist.task_groups.length === 0) return 0;

  const isLastGroupLocked = taskChecklist.task_groups[taskChecklist.task_groups.length - 1].locked;
  return isLastGroupLocked ? taskChecklist.task_groups.length - 1 : taskChecklist.task_groups.length;
};
