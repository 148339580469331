import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';

/**
 * Components & Pages
 */
import MDUProjectsRoutesStructure from './Pages/Projects/Projects.Page';
import MDUCurrentProjectRoutesStructure from './Pages/CurrentProject/Parts/CurrentProject.Page';

/**
 * *******************************************************
 * BASE ROUTE DISPLAY.
 * *******************************************************
 * Within the "inner structure" of the feature, we can have multiple routes
 */
const MDUTopLevelRoutesStructure = () => {
  return (
    <Routes>
      <Route index element={<Navigate to="/mdu/projects/all/all_statuses" replace />} />
      <Route path="projects/:projectsType/*" element={<MDUProjectsRoutesStructure />} />
      <Route path="project/:projectId/*" element={<MDUCurrentProjectRoutesStructure />} />
    </Routes>
  );
};

export default MDUTopLevelRoutesStructure;
