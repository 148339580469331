import useUnassignedListRouteParams from 'features/Dispatch/hooks/useUnassignedListRouteParams';
import {useSelector} from 'hooks/useAppSelector';
import {useAppDispatch} from 'hooks/useAppDispatch';
import dispatchSlice from 'features/Dispatch/dispatch.ducks';
import {TUnassignedListInitialState} from 'features/Dispatch/dispatch.types';

type TUpdateFiltersArgs = {
  filters: TUnassignedListInitialState['filters'];
  replace?: boolean;
};

const useSetUnassignedFilters = () => {
  const dispatch = useAppDispatch();
  const {dispatchNavType} = useUnassignedListRouteParams();

  const {filters, rawFilters} = useSelector(dispatchSlice.selectors.getUnassignedListPageStateByKey(dispatchNavType)) || {};

  const updateFilters = ({filters: newFilters, replace = false}: TUpdateFiltersArgs) => {
    dispatch(dispatchSlice.actions.updateUnassignedListFilters({navType: dispatchNavType, filters: newFilters, replace}));
  };

  const updateRawFiltersState = ({newRawFiltersState}: {newRawFiltersState: TUnassignedListInitialState['rawFilters']}) => {
    dispatch(dispatchSlice.actions.updateUnassignedListRawFiltersState({navType: dispatchNavType, newRawFiltersState}));
  };

  return {
    filters,
    rawFilters,
    updateFilters,
    updateRawFiltersState,
  };
};

export default useSetUnassignedFilters;
