import React from 'react';
import cn from 'classnames';
import {Button, BUTTON_THEMES, Modal, ELEMENT_SIZE} from 'ht-styleguide';

/* Utils */
import {noop} from 'utils/event';

/* styles */
import styles from './modals.styles.scss';

type NoTemplateServicesProps = {
  isVisible: boolean;
  dispatchDeleteTemplate: BaseAnyFunction;
  onCloseCallback?: BaseAnyFunction;
};

/**
 * @description - Modal to notifiy user that there are no services in the template.
 * @param isVisible
 * @param onCloseCallback
 * @param dispatchDeleteTemplate
 * @constructor
 */
const NoTemplateServices = ({isVisible, onCloseCallback = noop, dispatchDeleteTemplate}: NoTemplateServicesProps) => {
  return (
    <Modal
      isVisible={isVisible}
      elementSize={ELEMENT_SIZE.MEDIUM}
      modalClassName={cn(styles.container, styles.modalContents)}
      hide={onCloseCallback}
      onRequestClose={onCloseCallback}
      header="Template is incomplete"
      bodyContainerClasses={styles.flexInitial}
      footerElement2={
        <Button theme={BUTTON_THEMES.SECONDARY} dataTestId="button_delete_job" className={styles.ctaButton} onClick={dispatchDeleteTemplate}>
          Discard Template
        </Button>
      }
      footerElement3={
        <Button theme={BUTTON_THEMES.PRIMARY} dataTestId="button_cancel_job" className={styles.ctaButton} onClick={onCloseCallback}>
          Edit Template
        </Button>
      }
    >
      <div className="position relative">
        <p className="p1 n900">A template needs at least one added SKU to be saved.</p>
      </div>
    </Modal>
  );
};

export default NoTemplateServices;
