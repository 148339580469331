import React from 'react';
import {Form, InputField} from 'ht-styleguide';
import {ReferenceTypes, TaskFieldNames} from 'features/Products/types/taskGroups.types';
import {TTaskChecklistFormik, ReferenceFieldNames} from '../../taskChecklist.types';

interface IReferenceLinkBuilderProps {
  formik: TTaskChecklistFormik;
}

const ReferenceLinkBuilder = ({formik}: IReferenceLinkBuilderProps) => {
  const {reference} = formik.values;
  const {reference_type, title, url} = reference || {};
  const isLinkReference = reference_type === ReferenceTypes.Link;

  const pathToReferenceTitle = `${TaskFieldNames.Reference}.${ReferenceFieldNames.Title}`;
  const pathToReferenceUrl = `${TaskFieldNames.Reference}.${ReferenceFieldNames.Url}`;

  return isLinkReference ? (
    <>
      <Form.Row>
        <Form.Column lg={12} md={8} sm={4}>
          <InputField
            id={pathToReferenceTitle}
            label="Display title"
            onChange={formik.handleChange}
            value={title}
            // @ts-ignore
            error={formik.errors.reference?.title}
          />
        </Form.Column>
      </Form.Row>
      <Form.Row>
        <Form.Column lg={12} md={8} sm={4}>
          <InputField
            id={pathToReferenceUrl}
            label="URL"
            placeholder="https://"
            onChange={formik.handleChange}
            value={url}
            // @ts-ignore
            error={formik.errors.reference?.url}
          />
        </Form.Column>
      </Form.Row>
    </>
  ) : null;
};

export default ReferenceLinkBuilder;
