// @ts-nocheck
import forEach from 'lodash/forEach';
import isPlainObject from 'lodash/isPlainObject';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

const filterObjectByEmptyValues = <T extends object>(obj: T, exclusions: string[] = []) => {
  const filteredObj = {} as T;

  forEach(obj, (value, key) => {
    if (isPlainObject(value)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const nestedObj = filterObjectByEmptyValues(value);
      if (!isEmpty(nestedObj) && !exclusions.includes(key)) {
        filteredObj[key] = nestedObj;
      }
    } else if (isArray(value)) {
      if (!isEmpty(value) && !exclusions.includes(key)) {
        filteredObj[key] = value;
      }
    } else if (!isNil(value) && !exclusions.includes(key)) {
      filteredObj[key] = value;
    }
  });

  return filteredObj;
};

export default filterObjectByEmptyValues;
