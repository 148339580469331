import React, {useEffect} from 'react';
import {noop} from 'utils/event';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {Modal, Button, BUTTON_THEMES, InputField} from 'ht-styleguide';

export const ADD_EDIT_GROUP_ACTION_TYPES = {
  ADD: 'add',
  EDIT: 'edit',
} as const;

type TAddEditGroupModalProps = {
  isVisible: boolean;
  onCancelClick?: BaseAnyFunction;
  onConfirmClick?: BaseAnyFunction;
  actionType: (typeof ADD_EDIT_GROUP_ACTION_TYPES)[keyof typeof ADD_EDIT_GROUP_ACTION_TYPES];
  /** Will appear in the input on first render */
  initialGroupName?: string;
};

/** UI text config for add and edit actions */
const CONFIG_BY_ACTION_TYPE = {
  [ADD_EDIT_GROUP_ACTION_TYPES.ADD]: {
    headerText: 'Add Group',
    labelText: 'Name of group',
    confirmButtonText: 'Add Group',
  },
  [ADD_EDIT_GROUP_ACTION_TYPES.EDIT]: {
    headerText: 'Rename Group',
    labelText: null,
    confirmButtonText: 'Save Changes',
  },
};
/** Modal for adding a new group or editing an existing group's name */
export const AddEditGroupModal = ({isVisible, onCancelClick = noop, onConfirmClick = noop, actionType, initialGroupName}: TAddEditGroupModalProps) => {
  const formik = useFormik({
    initialValues: {
      groupName: initialGroupName ?? '',
    },
    validationSchema: Yup.object({
      groupName: Yup.string().required('Group name is required'),
    }),
    onSubmit: ({groupName}) => onConfirmClick(groupName),
  });

  useEffect(() => {
    if (!isVisible) formik.resetForm();
  }, [isVisible]);

  if (!actionType) return null;

  const {headerText, labelText, confirmButtonText} = CONFIG_BY_ACTION_TYPE[actionType];
  return (
    <Modal
      isVisible={isVisible}
      header={headerText}
      hide={onCancelClick}
      footerElement2={
        <Button theme={BUTTON_THEMES.SECONDARY} onClick={onCancelClick}>
          Cancel
        </Button>
      }
      footerElement3={
        <Button theme={BUTTON_THEMES.PRIMARY} onClick={formik.handleSubmit}>
          {confirmButtonText}
        </Button>
      }
    >
      <InputField label={labelText} value={formik.values.groupName} onChange={formik.handleChange} id="groupName" error={formik.errors.groupName} />
    </Modal>
  );
};
