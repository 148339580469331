import {Dispatch} from 'react';
import debounce from 'lodash/debounce';

import APIS from 'global/apis';

import {notifications} from 'components/Notification/notification.ducks';

import unWrapError from 'utils/request/requestError';
import {logger} from 'utils/logger';

// TODO: Move this to a global utils file
export const showErrorToast = (reduxDispatch: Dispatch<any>) => (error: any) => {
  const unwrappedError = unWrapError(error, {includeObjectKeys: true});

  // Need to manually call/remove the notification since react-query is not hooked up to our Redux middleware
  reduxDispatch(notifications.actions.notificationApiError({source: unwrappedError || error.statusText}));
  reduxDispatch(notifications.actions.notificationApiPending());
};

export const logResponseError = (errorText: string) => (response: any) => {
  logger(errorText)(response as Error);
};

/*
 * There is an issue (maybe something else) with React-Router switch and rendering 3x. Even with Memo, it still renders 3x.
 * So, as such, we are fetching the data 3x. This is a workaround to prevent that.
 * */
export const debouncedPaginatedSearch = debounce(
  async normalizedFilters => {
    const response = await APIS.mdu.getAllProjects(normalizedFilters);
    return response;
  },
  500,
  {
    leading: true,
    trailing: false,
  }
);

/**
 * Handle response error
 * Simple helper function to handle response errors
 * @param response
 * @param api
 */
export const handleResponseError = <T extends {err: string; error: string; status: string | number}>(response: T) => {
  /* spreading causes a ts iterator error. */
  const resp = Array.isArray(response) ? response : [response];
  resp.forEach(r => {
    if (r.error || r.err || r.status === 'error') {
      const e = r.err || r.error;
      throw new Error(JSON.stringify(e ?? {}));
    }
  });
};
