import React from 'react';
import {SideSheetContentSection, TextArea} from 'ht-styleguide';
import {UpdateTaskAnswerFieldName} from '../../editTaskAnswerSideSheet.types';
import {IEditTaskAnswerSectionShareProps} from '../editTaskAnswerContent.types';

const TextEditTaskAnswerSection = ({formik}: IEditTaskAnswerSectionShareProps) => {
  return (
    <SideSheetContentSection title="Details">
      <TextArea id={UpdateTaskAnswerFieldName.Response} onChange={formik.handleChange} value={formik.values.response} />
    </SideSheetContentSection>
  );
};

export default TextEditTaskAnswerSection;
