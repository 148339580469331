import {useParams} from 'react-router-dom';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {SideSheet} from 'ht-styleguide';

/* Ducks */
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';

/* Hooks */
import {useVerifyQAForm} from 'features/MultiDwellingUnits/MDU.hooks';
import {useCurrentProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/CurrentProject.hooks';

/* Components */
import AddSkuPage from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/AddSku/MDU.AddSku';

/* Constants / Types */
import {CurrentProjectStages, MduUseParamsTypes, ProjectService} from 'features/MultiDwellingUnits/MDU.types';
import {SelectedSku} from 'features/Questions/types';

type TSideSheetAddSkuProps = {
  onCloseCallback?: BaseAnyFunction;
  onHandleCreateMutation?: (selectedSku: SelectedSku) => void;
  onHandleEditMutation?: (projectService: ProjectService) => void;
  selectedSkuDraftArray?: boolean;
  isJobBulkDraftMode?: boolean;
  isJobDraftUUID?: boolean;
};
const SideSheetAddSku = ({onCloseCallback, onHandleCreateMutation, onHandleEditMutation, selectedSkuDraftArray, isJobBulkDraftMode, isJobDraftUUID}: TSideSheetAddSkuProps) => {
  /* Hooks */
  const {skuId = '', mode = '', serviceId = ''} = useParams<MduUseParamsTypes>();
  const [errors, setErrors] = useState({});
  const verifyQaForm = useVerifyQAForm();
  const selectedSku = useSelector(mduProjectsSlice.selectors.getAddSkuSelectedSku);
  const currentProject = useCurrentProject();

  /* Constants */
  const isEditMode = mode === CurrentProjectStages.EDIT;
  const ctaTitle = `${isEditMode ? 'Edit' : 'Add'} Service`;
  const ctaButton = `${isEditMode ? 'Update' : 'Add'} Service`;

  /* Methods */
  const closeSideSheet = () => {
    onCloseCallback?.();
  };

  const createService = () => {
    const formErrors = verifyQaForm();

    if (Object.keys(formErrors).length) {
      setErrors(formErrors);
      return;
    }

    setErrors({});
    onHandleCreateMutation?.(selectedSku);
  };

  const editService = async () => {
    const formErrors = verifyQaForm();

    if (Object.keys(formErrors).length) {
      setErrors(formErrors);
      return;
    }

    setErrors({});
    const projectService = {id: serviceId, questions: selectedSku.questions, skuId};
    onHandleEditMutation?.(projectService);
  };

  // const actionFunction = isEditMode ? onHandleEdit?.() : onHandleCreate?.();
  const actionFunction = isEditMode ? editService : createService;

  return (
    <SideSheet
      subheaderText={currentProject.name}
      isOpen={Boolean(skuId)}
      hide={closeSideSheet}
      onRequestClose={closeSideSheet}
      headerText={ctaTitle}
      ctaButtonText={ctaButton}
      onCtaButtonClick={actionFunction}
    >
      <AddSkuPage isJobBulkDraftMode={isJobBulkDraftMode} selectedSkuDraftArray={selectedSkuDraftArray} errors={errors} isJobDraftUUID={isJobDraftUUID} />
    </SideSheet>
  );
};

export default SideSheetAddSku;
