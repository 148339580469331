import React, {ComponentProps} from 'react';
import {Form, CalloutBox, CalloutBoxThemes} from 'ht-styleguide';
import {TaskTypes} from 'features/Products/types/taskGroups.types';
import {TaskTypesForChecklist} from '../taskChecklist.types';

const CALLOUT_COPY_PROPS: Record<TaskTypesForChecklist, Pick<ComponentProps<typeof CalloutBox>, 'text'>> = {
  [TaskTypes.Confirm]: {
    text: 'The worker simply presses ‘Done’ when they have completed the task',
  },
  [TaskTypes.Photo]: {
    text: 'Worker must submit at least one photograph before continuing. Use the instructions to describe what photos are needed.',
  },
  [TaskTypes.Video]: {
    text: 'Worker must upload one video before continuing. Use the instructions to describe what is are needed.',
  },
  [TaskTypes.Binary]: {
    text: 'User must select between 2 options.',
  },
  [TaskTypes.MultiSelect]: {
    text: 'User can make one or more selections from a checklist.',
  },
  [TaskTypes.SingleSelect]: {
    text: 'User must select an option from a searchable dropdown menu',
  },
  [TaskTypes.Text]: {
    text: 'User must input a text response.',
  },
};

const TaskTypeCallout = ({taskType}: {taskType: TaskTypesForChecklist}) => {
  const calloutTextProps = CALLOUT_COPY_PROPS[taskType];

  if (!calloutTextProps) return null;

  return (
    <Form.Row>
      <Form.Column lg={12} md={8} sm={4}>
        <CalloutBox theme={CalloutBoxThemes.DEFAULT} {...calloutTextProps} />
      </Form.Column>
    </Form.Row>
  );
};

export default TaskTypeCallout;
