import React, {useState, useCallback} from 'react';
import debounce from 'lodash/debounce';
import TableToolbar from 'components/Table/TableToolbar';
import TableToolbarLink from 'components/Table/TableToolbar/TableToolbar.Link';
import issuesSlice from 'features/Issues/Issues.ducks';
import {IssuesSearchFieldNames} from 'features/Issues/issues.types';
import useSetIssuesFilters from 'features/Issues/hooks/useSetIssuesFilters';
import useIssuesRouteParams from 'features/Issues/hooks/useIssuesRouteParams';
import {useSelector} from 'hooks/useAppSelector';
import useTableToolbarChips from 'utils/table/useTableToolbarFilterChips';
import useTempRawFilters from 'utils/table/useTempRawFilters';
import ColumnsModal from './IssuesTableToolbar.ColumnsModal';
import FiltersModal from './IssuesTableToolbar.FiltersModal';
import GroupByLink from './IssuesTableToolbar.GroupByLink';

const IssuesTableToolbar = () => {
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [showColumnsModal, setShowColumnsModal] = useState(false);

  const {issuesNavType} = useIssuesRouteParams();
  const {filters: stateFilters, rawFilters: stateRawFilters} = useSelector(issuesSlice.selectors.getPagesStateByKey(issuesNavType)) || {};
  const {updateFilters, updateRawFiltersState: _updateRawFiltersState} = useSetIssuesFilters();
  const {filtersChips, filtersCount} = useTableToolbarChips({filters: stateFilters, rawFilters: stateRawFilters});

  /**
   * Need to apply rawFilters when filters are updated, and not when there's a change in each input/select field
   * Otherwise, there will be UI issues within the filter chips UI.
   */
  const {tempRawFiltersRef, updateTempRawFilters, resetTempRawFilters} = useTempRawFilters(stateRawFilters);

  const updateRawFiltersState = () => {
    _updateRawFiltersState({newRawFiltersState: tempRawFiltersRef.current});
  };

  /**
   * To play with the clearFilter behavior of `DataFilter`. If there's no key passed,
   * then all filters must be cleared.
   */
  const clearFilter = (filterKey?: keyof typeof stateFilters) => {
    if (filterKey) {
      updateFilters({filters: {[filterKey]: ''}});
    } else {
      updateFilters({filters: {}, replace: true});
    }
  };

  const onSearchInputChange = debounce((value: string) => {
    updateFilters({filters: {[IssuesSearchFieldNames.Search]: value}});
  }, 1500);

  const hideFiltersModal = () => {
    setShowFiltersModal(false);
  };

  const toggleColumnsModal = useCallback(() => setShowColumnsModal(!showColumnsModal), [showColumnsModal]);

  const {search: searchValue} = stateFilters;

  const ToolbarLinks = (
    <>
      <TableToolbarLink label="Edit Columns" iconName="edit-plain" onClick={toggleColumnsModal} />
      <GroupByLink />
    </>
  );

  return (
    <div>
      <TableToolbar
        clearFilter={clearFilter}
        filtersChips={filtersChips}
        filtersCount={filtersCount}
        searchFilterKey="search"
        searchInputValue={searchValue as string}
        searchOnInputChange={onSearchInputChange}
        searchPlaceholderText="Search issues"
        toggleFiltersModal={setShowFiltersModal}
        ToolbarLinks={ToolbarLinks}
      />
      <ColumnsModal isVisible={showColumnsModal} toggleModal={toggleColumnsModal} />
      <FiltersModal
        isVisible={showFiltersModal}
        hideModal={hideFiltersModal}
        filters={stateFilters}
        updateFilters={updateFilters}
        updateRawFiltersState={updateRawFiltersState}
        updateTempRawFilters={updateTempRawFilters}
        resetTempNewRawFilters={resetTempRawFilters}
      />
    </div>
  );
};

export default IssuesTableToolbar;
