import React from 'react';
import {useGetUnitFromPaginatedQueries} from 'features/MultiDwellingUnits/queries/query.jobs.paginated';
import {LoaderComponent, Panel} from 'ht-styleguide';
import cn from 'classnames';

import styles from '../TemplatesJobs/templatejobs.styles.scss';

const JobsServicesTooltip = ({unitId}: {unitId: string | number}) => {
  const unitData = useGetUnitFromPaginatedQueries({unitId});
  const {services_resume: services} = unitData || {};

  const generateKey = (idx: number, str: string) => {
    return `${idx}-${str}`;
  };

  if (!services || !services.length) {
    return (
      <Panel largeBorderRadius className={cn(styles.tooltipGroup, styles.panel, 'n800')}>
        N/A
      </Panel>
    );
  }
  return (
    <Panel largeBorderRadius className={cn(styles.tooltipGroup, styles.panel, 'n800')}>
      {!services ? (
        <div className="padding-medium">
          <LoaderComponent />
        </div>
      ) : (
        services?.map((item, idx: number) => {
          const serviceKey = `${item.sku?.id}-${generateKey(idx, 'service')}`;
          return (
            <div key={serviceKey} className="flex flex-direction-column">
              <div className="flex flex-direction-column">
                {item.sku?.name} ({item.sku?.id}) x{item.quantity}
              </div>

              {item.add_ons?.map((addon: string, i: number) => {
                const adjustmentKey = addon + generateKey(i, 'adjustment');
                return (
                  <div className="paddingTop-tiny1" key={adjustmentKey}>
                    +{addon}
                  </div>
                );
              })}
            </div>
          );
        })
      )}
    </Panel>
  );
};

export default JobsServicesTooltip;
