import {TGenericCloudinaryResponse} from 'features/Cloudinary/cloudinary.types';

/*
 ***************************************************************
   Types that are shared across features
 ***************************************************************
 */

/**
 * The types of tasks the worker must perform per service.
 */
export enum TaskTypes {
  /**
   * Simple confirmation that the task was completed
   */
  Confirm = 'confirm',
  /**
   * Upload one or more (depending on setting) photos
   */
  Photo = 'photo',
  /**
   * Upload one video
   */
  Video = 'video',
  /**
   * Dropdown
   */
  SingleSelect = 'single_select',
  /**
   * Checkbox
   */
  MultiSelect = 'multi_select',
  /**
   * Radio with only two options
   */
  Binary = 'binary',
  /**
   * Render a text area in the tech app
   */
  Text = 'text',
  /**
   * Derived from sku's device question if it exists
   */
  Device = 'device',
  /**
   * If the worker needs to report an incident or damage. Will always exist on each service
   */
  Incident = 'incident',
}

export enum ReferenceTypes {
  Link = 'link',
  File = 'file',
  FromSkill = 'from_skill',
}

export type TReference = {
  id: number;
  reference_type: ReferenceTypes;
  /**
   * Used with reference_type 'from_skill'
   */
  skill_guide_id: number | null;
  skill_guide: {
    created_at: string;
    id: number;
    link: string;
    name: string;
    updated_at: string;
  } | null;
  /**
   * Used with reference_type 'link'
   */
  title: string | null;
  /**
   * Used with reference_type 'link' and 'file'
   */
  url: string | null;
  /**
   * Used with reference_type 'file'
   */
  attachments: TGenericCloudinaryResponse[];
};

export type TTaskOption = {
  id: number;
  created_at: string;
  description: string;
  updated_at: string;
};

export type TBaseTask = {
  created_at: string;
  id: number;
  /**
   * Used for multi_select tasks. Display "Other" option.
   */
  include_other_option: boolean;
  /**
   * Description of what the worker needs to do. Shown in the tech app.
   */
  instructions: string;
  /**
   * For task type `photo`. If true, the worker can upload multiple photos.
   */
  multiple_photos: boolean;
  options: TTaskOption[];
  reference: TReference | null;
  required_task: boolean;
  show_optional_note: boolean;
  show_optional_photo: boolean;
  task_type: TaskTypes;
  title: string;
  updated_at: string;
};

export type TBaseTaskGroup<TaskType> = {
  created_at: string;
  group_name: string;
  id: number;
  /**
   * Cannot be edited
   */
  locked: boolean;
  tasks: TaskType[];
  updated_at: string;
};
