import React from 'react';
import {Route, Routes, Navigate, Outlet, useParams} from 'react-router-dom';
/* Components */
import AdminHubLayout from 'components/Layouts/AdminHub/AdminHub.layout';
import Sidebar from 'components/Layouts/AdminHub/Sidebar';
import IssuesTopNav from 'features/Issues/Parts/TopNav';
import IssuesPage from 'features/Issues/Pages/Issues';
/* Hooks */
import useIssuesSidebarMenu from './hooks/useIssuesSidebarMenu';
/* Constants */
import {Routes as HTRoutes} from 'global/routes/routes';
import {IssuesNavTypes, TIssuesUseParams} from 'features/Issues/issues.types';

/**
 * *******************************************************
 * BASE ROUTE DISPLAY.
 * *******************************************************
 */
const IssuesRoutesStructureLayout = () => {
  /* Hooks */
  const {menu} = useIssuesSidebarMenu();

  return (
    <AdminHubLayout>
      <IssuesTopNav />
      <AdminHubLayout.ContentTwoColumnContainer>
        <AdminHubLayout.ContentLeftContainer>
          <Sidebar menu={menu} headerProps={{title: 'Issues'}} isCollapsible />
        </AdminHubLayout.ContentLeftContainer>
        <AdminHubLayout.ContentRightContainer>
          <Outlet />
        </AdminHubLayout.ContentRightContainer>
      </AdminHubLayout.ContentTwoColumnContainer>
    </AdminHubLayout>
  );
};

/**
 * `/issues/:issuesNavType` routes.
 */
const IssuesNavTypeRoutes = () => {
  const {issuesNavType} = useParams<TIssuesUseParams>();

  switch (issuesNavType) {
    case IssuesNavTypes.OPEN:
    case IssuesNavTypes.OWN:
    case IssuesNavTypes.DONE:
      return <IssuesPage />;
    default:
      return null;
  }
};

/**
 * `/issues/*` routes structure.
 */
const IssuesRoutesStructure = () => (
  <Routes key="IssuesMDUProjectLevelRoutes">
    <Route element={<IssuesRoutesStructureLayout />}>
      <Route path={`${HTRoutes.ISSUES_NAV_TYPE_KEY()}`} element={<IssuesNavTypeRoutes />} />
      <Route path="*" element={<Navigate to={`${HTRoutes.ISSUES_PATH_BASE()}/${IssuesNavTypes.OPEN}`} replace />} />
    </Route>
  </Routes>
);

export default IssuesRoutesStructure;
