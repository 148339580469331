import {FormikErrors} from 'formik';
import isEmpty from 'lodash/isEmpty';
import {TUpdateTaskAnswerParams} from 'features/Orders/Pages/OrderTaskChecklist/queries/mutation.updateTaskAnswer';
import {TaskTypes} from 'types/tasks.types';
import {TOrderTask} from 'features/Orders/Pages/OrderTaskChecklist/types/orderTaskChecklist.types';
import {TUpdateTaskAnswerFormValues, UpdateTaskAnswerFieldName} from './editTaskAnswerSideSheet.types';

export const shouldShowOptionalNotesSection = (task: TOrderTask | null | undefined) => task?.task_type !== TaskTypes.Incident && (task?.show_optional_note || task?.show_optional_photo);

export const checkHasIncidentToReport = (task: TOrderTask | null | undefined) => task?.task_type === TaskTypes.Incident && !!task?.answer?.response && !!task?.answer?.attachments?.length;

export const createFormInitialValues = (task: TOrderTask | null | undefined): TUpdateTaskAnswerFormValues => {
  const {required_task} = task || {};
  const {response = '', attachments = [], options = [], device_response} = task?.answer || {};
  const values: TUpdateTaskAnswerFormValues = {
    response: response ?? '',
    attachments: attachments ?? [],
    options: options?.map(({id}) => ({id})) ?? [],
    device_response: device_response
      ? {
          product_make_id: device_response.product_make.id,
          product_make_name: device_response.product_make.name,
          product_id: device_response.product.id,
          product_model_name: device_response.product.name,
        }
      : null,
    required: required_task ?? false,
    hasIncidentToReport: checkHasIncidentToReport(task) ?? false,
  };
  return values;
};

/**
 * Modify the form values to match the BE payload
 */
export const createUpdateTaskAnswerPayload = (task: TOrderTask, values: TUpdateTaskAnswerFormValues): TUpdateTaskAnswerParams['taskAnswerPayload'] => {
  const {options, response, attachments, hasIncidentToReport, device_response} = values;

  const payload: TUpdateTaskAnswerParams['taskAnswerPayload'] = {
    options,
    response,
    attachments,
    device_response: device_response
      ? {
          product_make_id: device_response.product_make_id,
          product_id: device_response.product_id,
        }
      : null,
    // Incident task type - remove response and attachments if the user selected "No" for the radio button
    ...(task?.task_type === TaskTypes.Incident &&
      (() => {
        if (!hasIncidentToReport) {
          const partialPayload: Partial<TUpdateTaskAnswerParams['taskAnswerPayload']> = {};
          partialPayload.response = '';
          partialPayload.attachments = [];
          return partialPayload;
        }
        return {};
      })()),
    // Device task type - format correctly
    ...(task?.task_type === TaskTypes.Device &&
      (() => {
        const partialPayload: Partial<TUpdateTaskAnswerParams['taskAnswerPayload']> = {};
        if (device_response) {
          partialPayload.device_response = {
            product_make_id: device_response.product_make_id,
            product_id: device_response.product_id,
          };
        }
        return partialPayload;
      })()),
  };

  return payload;
};

/**
 * For special cases, we need to force the user to enter data before they can submit the form.
 * Returns true if the user should not be able to submit the form.
 */
export const checkShouldForceDataEntry = (task: TOrderTask, formValues: TUpdateTaskAnswerFormValues, formErrors: FormikErrors<TUpdateTaskAnswerFormValues>) => {
  /**
   * For the incident task type, if there is an incident to report, the user should not be able to submit unless
   * they have filled in the required fields (response and attachments)
   */
  if (task?.task_type === TaskTypes.Incident) {
    const {hasIncidentToReport} = formValues;
    return hasIncidentToReport && !isEmpty(formErrors);
  }
  /**
   * For the device task type, if the user has selected a make, then force the user to select a model
   */
  if (task.task_type === TaskTypes.Device) {
    const deviceResponseError = formErrors[UpdateTaskAnswerFieldName.DeviceResponse] as string | any;
    const productIdRequiredErrorMsg = typeof deviceResponseError === 'object' ? deviceResponseError[UpdateTaskAnswerFieldName.DeviceProductId] : false;
    return Boolean(productIdRequiredErrorMsg);
  }
  return false;
};

/**
 * Auto update (also don't show the warning modal) if the form is valid or the task is already complete
 */
export const checkCanAutoUpdateTask = (task: TOrderTask, formErrors: FormikErrors<TUpdateTaskAnswerFormValues>) => {
  return isEmpty(formErrors) || task?.answer?.complete;
};
