import React, {useMemo} from 'react';
import {TaskTypes} from 'types/tasks.types';
import {shouldShowOptionalNotesSection} from '../editTaskAnswerSideSheet.utils';
import BinaryEditTaskAnswerSection from './EditTaskAnswerSection/Binary.EditTaskAnswerSection';
import DeviceEditTaskAnswerSection from './EditTaskAnswerSection/Device.EditTaskAnswerSection';
import IncidentEditTaskAnswerSection from './EditTaskAnswerSection/Incident.EditTaskAnswerSection';
import MultiSelectEditTaskAnswerSection from './EditTaskAnswerSection/MultiSelect.EditTaskAnswerSection';
import OptionalEditTaskAnswerSection from './EditTaskAnswerSection/Optional.EditTaskAnswerSection';
import PhotoEditTaskAnswerSection from './EditTaskAnswerSection/Photo.EditTaskAnswerSection';
import SingleSelectEditTaskAnswerSection from './EditTaskAnswerSection/SingleSelect.EditTaskAnswerSection';
import TextEditTaskAnswerSection from './EditTaskAnswerSection/Text.EditTaskAnswerSection';
import {IEditTaskAnswerContent} from './editTaskAnswerContent.types';

const EDIT_SECTION_MAPPING: Record<TaskTypes, typeof OptionalEditTaskAnswerSection | null> = {
  [TaskTypes.Photo]: PhotoEditTaskAnswerSection,
  [TaskTypes.Video]: null,
  [TaskTypes.MultiSelect]: MultiSelectEditTaskAnswerSection,
  [TaskTypes.SingleSelect]: SingleSelectEditTaskAnswerSection,
  [TaskTypes.Binary]: BinaryEditTaskAnswerSection,
  [TaskTypes.Confirm]: null, // No section for confirm type
  [TaskTypes.Text]: TextEditTaskAnswerSection,
  [TaskTypes.Device]: DeviceEditTaskAnswerSection,
  [TaskTypes.Incident]: IncidentEditTaskAnswerSection,
};

const EditTaskAnswerContent = ({formik, task}: IEditTaskAnswerContent) => {
  const MainEditSection = useMemo(() => {
    if (!task) {
      return null;
    }
    return EDIT_SECTION_MAPPING[task.task_type];
  }, [task]);

  if (!task) {
    return null;
  }

  return (
    <div className="paddingTop-small1">
      {MainEditSection && <MainEditSection formik={formik} task={task} />}
      {shouldShowOptionalNotesSection(task) && <OptionalEditTaskAnswerSection formik={formik} task={task} />}
    </div>
  );
};

export default EditTaskAnswerContent;
