import React, {useState} from 'react';
import cn from 'classnames';
// Queries && Mutations
import {useGetProjectNotesQuery} from 'features/MultiDwellingUnits/queries/query.project.notes';
// Components && Styles
import PageHeader from 'components/Elements/PageHeader';
import SideSheetAddNote from 'features/MultiDwellingUnits/Parts/SideSheets';
import {EmptyState, Panel} from 'ht-styleguide';
import NotesDisplay from './CurrentProject.NotesPage.NotesDisplay';
import styles from './notesPage.styles.scss';

const NotesPageEmptyState = () => {
  return (
    <Panel className={cn(styles.panel, styles.withBorder, 'paddingY-large')} noShadow largeBorderRadius>
      <EmptyState title="No Notes Added Yet" text="All notes associated with the project will be found here. To add a note select New Note." iconName="clipboard-plain" />
    </Panel>
  );
};

const NotesPage = () => {
  const [isSideSheetOpen, setIsSideSheetOpen] = useState(false);
  const toggleSidesheet = () => setIsSideSheetOpen(!isSideSheetOpen);
  const {data: notes, isFetched} = useGetProjectNotesQuery();

  const HeaderRightContent = <PageHeader.Button onClick={toggleSidesheet} iconName="note" label="New Note" />;

  return (
    <div className="marginBottom-medium2">
      <PageHeader title="Notes" HeaderRightContent={HeaderRightContent} />

      {isFetched ? <div className="paddingTop-medium2">{notes?.length ? <NotesDisplay notes={notes} /> : <NotesPageEmptyState />}</div> : null}
      <SideSheetAddNote isOpen={isSideSheetOpen} hide={toggleSidesheet} />
    </div>
  );
};

export default NotesPage;
