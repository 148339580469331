import React, {memo, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';

/* Ducks */
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';

/* Hooks */
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useDraftTable, useProjectDraft} from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/TemplateJobs.hooks';

/* Queries / Mutations */
import {useCreateServiceDraftMutation, useUpdateServiceDraftMutation} from 'features/MultiDwellingUnits/queries/mutation.jobs.drafts';

/* Components */
import CreateBulkJobsHeader, {HeaderTypes} from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/Parts/TemplateJobs.Draft.Header';
import SideSheetAddSku from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/Parts/TemplateJobs.Draft.SideSheetAddSku';
import EmptyAddSkus from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/Parts/TemplateJobs.Empty.AddSkus';
import DataTable from 'components/Elements/DataTable';

/* Constants */
import {mduProjectPagePostLaunchGroupServicesPath, mduProjectPagePostLaunchGroupServicesSkuEditPath, mduProjectPagePostLaunchGroupServicesSkuPath, mduProjectPathByStage} from 'global/paths';
import {ModalGroupExistsType} from 'features/MultiDwellingUnits/Parts/Modals/MDU.Modal.GroupExistsAlert';

import {MduUseParamsTypes, ProjectGroup, ProjectService} from 'features/MultiDwellingUnits/MDU.types';
import {SelectedSku} from 'features/Questions/types';

/**
 * For post-launched projects, a user can add a new template through this component.
 * Currently accessed from sidebar "Jobs" tab -> `+ New Template` button.
 */
const CreatingBulkNewTemplates = () => {
  /* Hooks */
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {groupId = '', projectId = '', stage = ''} = useParams<MduUseParamsTypes>();
  const {projectGroup} = useProjectDraft({groupId});
  const {columns, data} = useDraftTable({projectGroup, projectId, isPreLaunchStatus: true, pathRedirectOnEdit: mduProjectPagePostLaunchGroupServicesSkuEditPath});

  /* Queries / mutations */
  const {mutate} = useCreateServiceDraftMutation();
  const {mutate: mutateUpdate} = useUpdateServiceDraftMutation();

  /* Constants */
  const hasData = data.length > 0;

  /* Methods */
  const closeSideSheet = () => {
    navigate(mduProjectPagePostLaunchGroupServicesPath(projectId, projectGroup.id as string));
  };

  const onHandleEditMutation = (selectedSku: ProjectService) => {
    mutateUpdate(selectedSku, {onSuccess: closeSideSheet});
  };

  const onHandleCreateDraft = (selectedSku: SelectedSku) => {
    mutate(selectedSku, {onSuccess: closeSideSheet});
  };

  const onHandleSaveRedirect = (response: {projectGroup: ProjectGroup}) => {
    // 1. dispatch to new page
    navigate(mduProjectPathByStage(projectId, 'templates'));
    // 2. Update the project group in currentProject: TODO: Need to remove the redux and update the cache
    dispatch(mduProjectsSlice.actions.addTemplateToCurrentProjects(response.projectGroup));
  };

  /* If we on a group route but there is no project group, redirct back to root to add one */
  useEffect(() => {
    if (!projectGroup) {
      navigate(mduProjectPathByStage(projectId, 'templates'));
    }
  }, [groupId, navigate, projectGroup, projectId]);

  return (
    <div>
      <CreateBulkJobsHeader
        suppressSave={!hasData}
        headerType={HeaderTypes.sku}
        onHandleDiscardRedirect={() => navigate(mduProjectPathByStage(projectId, stage))}
        onHandleSaveRedirect={onHandleSaveRedirect}
        onHandlePartnerSkuRedirect={sku => navigate(mduProjectPagePostLaunchGroupServicesSkuPath(projectId, projectGroup.id as string, sku.value as string))}
        modalGroupExistsType={ModalGroupExistsType.templateJobs}
      />
      {hasData ? <DataTable tableKey="TemplateJobs-PostLaunch-Create" columns={columns} data={data} isZebra enableSorting={false} /> : <EmptyAddSkus />}
      <SideSheetAddSku isJobBulkDraftMode isJobDraftUUID onHandleEditMutation={onHandleEditMutation} onHandleCreateMutation={onHandleCreateDraft} onCloseCallback={closeSideSheet} />
    </div>
  );
};

export default memo(CreatingBulkNewTemplates);
