import React, {ComponentProps, useState, useMemo} from 'react';
import cn from 'classnames';
import {Icon, Chip, CHIP_TYPES, ELEMENT_SIZE, InputField, htToast} from 'ht-styleguide';
import DataTable from 'components/Elements/DataTable';
import {TDataTableColumnDef} from 'components/Elements/DataTable/dataTable.types';
import DetailPanel from 'components/Elements/DataTable/DetailPanel';
import {TCreateStoreCredit} from 'features/StoreCredits/storeCredits.types';
import {copyToClipboard} from 'utils/clipboard';
import {formatPrice} from 'utils/currency';
import {HELLOTECH_PARTNER_LABEL} from './createCreditSideSheet.constants';
import {TNormalizedItem} from './createCreditSideSheet.types';
import {formatDateForDisplay, searchTableDataByValue} from './createCreditSideSheet.utils';
import styles from './createCreditSideSheet.styles.scss';

interface IFormSubmitted {
  newCreditData: TCreateStoreCredit | undefined;
}

const FormSubmitted = ({newCreditData}: IFormSubmitted) => {
  const {token, credit_name, expires_at, partner, amount, credit_items} = newCreditData || {};

  // Links that admin should use in marketing emails, etc
  const baseUrlParam = `email={{customer.email}}&creditToken=${token}`;
  const soleParam = `?${baseUrlParam}`;
  const additionalParam = `&${baseUrlParam}`;
  const copyLink = (link: string) => async () => {
    try {
      await copyToClipboard(link);
      htToast('Link copied');
    } catch {
      // do nothing
    }
    return () => {}; // hack around InputField typing
  };

  // Other data to display
  const formattedExpDate = expires_at ? formatDateForDisplay(expires_at) : '';
  const formattedAmount = formatPrice(amount);
  const partnerName = partner ? partner.name : HELLOTECH_PARTNER_LABEL;

  /**
   *************************************************************
   * Sku/Membership Table
   *************************************************************
   */

  /**
   * Since `credit_items` is a flat array, group data by item_category_name.
   * Feed this data to the main table.
   *
   * [{
   *   id: "sku_category_1"/"plan_product_1",
   *   name: 'Some SkuCategory/PlanProduct Name',
   *   items: [{id: 1, name: 'Some Sku/Plan Name'}, {id: 2, name: 'Another Sku/Plan Name'}]
   * }]
   */
  const groupedData = useMemo(() => {
    if (credit_items) {
      return credit_items.reduce((acc, item) => {
        const {item_id, item_name, item_type, item_category_name} = item;
        if (item_type === 'SkuCategory' || item_type === 'PlanProduct') {
          return acc;
        }

        const existingItem = acc.find(({name}) => name === item_category_name);

        if (existingItem) {
          existingItem.items.push({id: item_id, name: item_name});
        } else {
          acc.push({
            id: `${item_type}_${item_id}`,
            name: item_category_name as string,
            items: [{id: item_id, name: item_name}],
          });
        }

        return acc;
      }, [] as Array<TNormalizedItem>);
    }
    return [];
  }, [credit_items]);

  const mainTableColumns: Array<TDataTableColumnDef<TNormalizedItem>> = [
    {
      header: 'Name',
      accessorKey: 'name',
      columnWidthMode: 'collapse',
      columnWidthSizeCollapse: 438, // just eyeballing these values
    },
  ];

  const renderDetailPanel: ComponentProps<typeof DataTable<TNormalizedItem>>['renderDetailPanel'] = ({row}) => {
    const subRowColumns: Array<TDataTableColumnDef<{id: number; name: string}>> = [
      {
        header: 'Name',
        accessorKey: 'name',
        columnWidthMode: 'collapse',
        columnWidthSizeCollapse: 335,
      },
      {
        header: 'ID',
        accessorKey: 'id',
        columnWidthMode: 'collapse',
        columnWidthSizeCollapse: 70,
      },
    ];

    return (
      <DetailPanel>
        <DataTable data={row.original.items} columns={subRowColumns} enableTableHead={false} transparentBG state={{density: 'compact'}} />
      </DetailPanel>
    );
  };

  /**
   *************************************************************
   * Search Sku/Membership Table
   *************************************************************
   */
  const [searchValue, setSearchValue] = useState('');
  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);
  const searchInputProps = {
    iconName: searchValue ? 'v2-close-icon' : 'search',
    ...(searchValue ? {iconOnClick: () => setSearchValue('')} : {}),
  };

  /**
   * Flatten `groupData` for search for easier searching.
   */
  const searchFlatData = useMemo(() => {
    return groupedData.reduce((acc, {items}) => {
      return [...acc, ...items.map(({id: itemId, name}) => ({id: itemId, name}))];
    }, [] as Array<{id: number; name: string}>);
  }, [groupedData]);

  /**
   * Search the flat data for the search value.
   * Feed this data to the search table.
   */
  const searchResult = useMemo(() => {
    return searchValue ? searchTableDataByValue(searchFlatData, searchValue) : [];
  }, [searchFlatData, searchValue]);

  const searchTableColumns: Array<TDataTableColumnDef<{id: number; name: string}>> = [
    {
      header: 'Name',
      accessorKey: 'name',
      columnWidthMode: 'collapse',
      columnWidthSizeCollapse: 335,
    },
    {
      header: 'ID',
      accessorKey: 'id',
      columnWidthMode: 'collapse',
      columnWidthSizeCollapse: 70,
    },
  ];

  return (
    <div>
      <div className="flex align-items-center flex-direction-column">
        <Icon name="checkmark-rounded" className={cn(styles.successCheckmark, 'successGreen500 marginBottom-small1')} />
        <h4 className="n900">{credit_name} is live!</h4>
        <p className="p1 n700 marginBottom-tiny1 marginTop-tiny">
          {formattedAmount} Credit &bull; Valid until {formattedExpDate}
        </p>
        <Chip size={ELEMENT_SIZE.L} variant="solid" type={CHIP_TYPES.success}>
          {partnerName}
        </Chip>
      </div>

      <div className={cn(styles.horizontalRule, 'marginTop-medium1 marginBottom-medium')} />

      <h5 className="n900">Adding the credit to email and SMS links</h5>
      <p className="p1 n900 marginTop-small2 marginBottom-tiny1">Copy and paste this snippet to your CTA link:</p>
      <InputField value={soleParam} readOnly iconName="clipboard-plain" iconOnClick={copyLink(soleParam)} />
      <p className="p2 n700 marginTop-small">
        Example: hellotech.com<span className="font-weight-medium n900">{soleParam}</span>
      </p>

      <p className="p1 n900 marginTop-medium marginBottom-small">If there are other params in your URL, copy and paste this snippet to your CTA link:</p>
      <InputField value={additionalParam} readOnly iconName="clipboard-plain" iconOnClick={copyLink(additionalParam)} />
      <p className="p2 n700 marginTop-small">
        Example: hellotech.com?existingParam=value<span className="font-weight-medium n900">{additionalParam}</span>
      </p>

      <div className={cn(styles.horizontalRule, 'marginTop-medium2 marginBottom-medium')} />

      <h5>Eligible services or plans</h5>
      <InputField value={searchValue} onChange={onSearchChange} placeholder="Search Skus by name or ID" containerClass="marginTop-small marginBottom-small" {...searchInputProps} />

      {!searchValue ? (
        <DataTable key="mainTable" data={groupedData} columns={mainTableColumns} renderDetailPanel={renderDetailPanel} enableTableHead={false} enableExpanding state={{density: 'compact'}} />
      ) : (
        <DataTable key="searchTable" data={searchResult} columns={searchTableColumns} enableTableHead={false} enableExpanding={false} state={{density: 'compact'}} />
      )}
    </div>
  );
};

export default FormSubmitted;
