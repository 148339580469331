import React, {useEffect, useMemo, useState} from 'react';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {ClearEditorPlugin} from '@lexical/react/LexicalClearEditorPlugin';
import {$getRoot, $isParagraphNode} from 'lexical';
import {useSharedHistoryContext} from '../context/EditorHistoryState';

/**
 * These are actions that modify the editor state in a destructive way.
 * ie. delete, undo (note: undo currently turned off).
 */
export function DestructiveActionsPlugin() {
  const [editor] = useLexicalComposerContext();
  const {historyState} = useSharedHistoryContext();

  const [, /* isEditorEmpty */ setIsEditorEmpty] = useState(true);

  const {undoStack} = historyState ?? {};
  const [, setHasUndo] = useState(undoStack?.length !== 0);

  const MandatoryPlugins = useMemo(() => {
    return <ClearEditorPlugin />;
  }, []);

  useEffect(
    function checkEditorEmptyState() {
      return editor.registerUpdateListener(({editorState}) => {
        editorState.read(() => {
          const root = $getRoot();
          const children = root.getChildren();

          if (children.length > 1) {
            setIsEditorEmpty(false);
            return;
          }

          if ($isParagraphNode(children[0])) {
            setIsEditorEmpty(children[0].getChildren().length === 0);
          } else {
            setIsEditorEmpty(false);
          }
        });
      });
    },
    [editor]
  );

  useEffect(
    function checkEditorHistoryActions() {
      return editor.registerUpdateListener(() => {
        setHasUndo(undoStack?.length !== 0);
      });
    },
    [editor, undoStack]
  );

  return (
    <>
      {MandatoryPlugins}
      {/*
      <Icon
        name="trash"
        disabled={isEditorEmpty}
        onClick={() => {
          editor.dispatchCommand(TRASH_EDITOR_COMMAND, '');
        }}
      />
      <Icon
          name="refresh"
          disabled={!hasUndo}
          onClick={() => {
            editor.dispatchCommand(UNDO_COMMAND, undefined);
          }}
        />
      */}
    </>
  );
}
