import React from 'react';

// Utils
import dayjs from 'dayjs';

// Components
import {Panel} from 'ht-styleguide';
import BenefitLine from 'features/Profile/components/BenefitLine';
import EmptyState from 'features/Profile/components/EmptyState';

// Constants
import {MEMBERSHIP_TABLE_HEADERS, SECTION_HEADERS, EMPTY_STATES} from 'features/Profile/profile.constants';
import {LL} from 'global/constants/common';

// Types
import {Subscription} from 'features/Profile/profile.types';

// Styles
import styles from 'features/Profile/profile.styles.scss';

interface MembershipSectionProps {
  subscription: Subscription | null;
  name: string;
}

const MembershipSection = ({subscription, name}: MembershipSectionProps) => {
  if (!subscription) return <EmptyState header={SECTION_HEADERS.EMPTY_MEMBERSHIP} text={EMPTY_STATES.EMPTY_MEMBERSHIP} name={name} />;

  const {planName, createdAt, benefits} = subscription;

  const sortedBenefits = Object.entries(benefits).sort((x, y) => Number(x[1]) - Number(y[1]));

  return (
    <Panel className="padding-medium">
      <div className="paddingBottom-small2">
        <h4 className="paddingBottom-tiny1">{planName} Member</h4>
        <p className="p2 n700">Member since {dayjs(createdAt?.iso).format(LL)}</p>
      </div>

      <table className={styles.membershipBenefitsTable}>
        <thead>
          <tr className={styles.tableHeader}>
            {MEMBERSHIP_TABLE_HEADERS.map(header => (
              <th key={header} className={styles.membershipHeaders}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {benefits &&
            sortedBenefits.map(b => {
              const [benefit, isUsed] = b;
              return <BenefitLine key={benefit} benefit={benefit} used={isUsed} />;
            })}
        </tbody>
      </table>
    </Panel>
  );
};

export default MembershipSection;
