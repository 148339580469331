import React, {ComponentProps, useEffect, useMemo} from 'react';
import {useFormik} from 'formik';
import {Form, InputField, SelectField, SideSheet, TSideSheetCustomHeaderProps, TextArea} from 'ht-styleguide';
import HeaderWithToolbar from 'components/SideSheets/HeaderWithToolbar';
import {RightContentCloseOnly} from 'components/SideSheets/HeaderWithToolbar/HeaderWithToolbar.Presets';
import cloudinarySlice from 'features/Cloudinary/state/cloudinary.ducks';
import {TaskTypes, TaskFieldNames, TUpsertTask, TTask} from 'features/Products/types/taskGroups.types';
import {useAppDispatch} from 'hooks/useAppDispatch';
import usePrevious from 'hooks/usePrevious';
import LabelWithTooltip from './LabelWithTooltip';
import OptionsBuilder from './OptionsBuilder';
import PhotoLimitSection from './PhotoLimitSection';
import ReferenceBuilder from './ReferenceBuilder';
import TaskTypeCallout from './TaskTypeCallout';
import ToggleSettingsSection from './ToggleSettingsSection';
import {TTaskChecklistFormValues} from './taskChecklist.types';
import {cleanValuesForApi, setInitialValues} from './taskChecklist.utils';
import {TASK_TYPE_OPTS, CLOUDINARY_TASK_SIDESHEET_NAMESPACE} from './constants/main';
import {YUP_SCHEMA} from './constants/form';

interface ITaskSideSheetHeader {
  isEditMode: boolean;
}

const TaskSideSheetHeader =
  ({isEditMode = false}: ITaskSideSheetHeader) =>
  ({hide}: TSideSheetCustomHeaderProps) => {
    const headerText = isEditMode ? 'Edit Task' : 'New Task';
    return <HeaderWithToolbar headerText={headerText} hide={hide} isHeaderTextEditable={false} RightContent={RightContentCloseOnly} />;
  };

interface ITaskSideSheet {
  isOpen: boolean;
  taskToEdit: TTask | null | undefined;
  toggleIsOpen: () => void;
  upsertTask: (task: TUpsertTask, onSuccess: () => void) => void;
}

/**
 * A sidesheet that handles adding and editing a task
 */
const TaskSideSheet = ({isOpen, taskToEdit, toggleIsOpen, upsertTask}: ITaskSideSheet) => {
  const dispatch = useAppDispatch();
  const isEditMode = !!taskToEdit;

  /**
   * For the `<AttachementsBuilder />` component.
   * As part of the cloudinary integration, we need to remove the attachments from the redux store
   * when the sidesheet is closed.
   */
  const previousIsOpen = usePrevious(isOpen);
  useEffect(() => {
    if (previousIsOpen && !isOpen) {
      dispatch(cloudinarySlice.actions.deleteAllFilesByNamespace({editorNamespace: CLOUDINARY_TASK_SIDESHEET_NAMESPACE}));
    }
  }, [dispatch, isOpen, previousIsOpen]);

  const initialValues = useMemo(() => setInitialValues(taskToEdit), [taskToEdit]);

  const formik = useFormik<TTaskChecklistFormValues>({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues,
    validationSchema: YUP_SCHEMA,
    onSubmit: values => {
      const cleanedValues = cleanValuesForApi(values);
      const onSuccess = () => {
        formik.resetForm();
        toggleIsOpen();
      };
      upsertTask(cleanedValues, onSuccess);
    },
  });

  const hideSideSheet = () => {
    formik.resetForm();
    toggleIsOpen();
  };
  const onTaskTypeChange: ComponentProps<typeof SelectField>['onChange'] = selected => formik.handleChange({target: {name: TaskFieldNames.TaskType, value: selected?.value}});
  const onToggleChange = (fieldId: string) => (newToggleValue: boolean) => formik.handleChange({target: {name: fieldId, value: newToggleValue}});

  const taskTypeValue = formik.values.task_type;

  const isSaveButtonDisabled = (() => {
    const {options} = formik.values;
    if ([TaskTypes.MultiSelect, TaskTypes.SingleSelect].includes(taskTypeValue)) {
      return !options.length;
    }
    return false;
  })();

  return (
    <SideSheet
      ctaButtonText="Save"
      onCtaButtonClick={formik.handleSubmit}
      ctaButtonProps={{disabled: isSaveButtonDisabled}}
      headerComponent={TaskSideSheetHeader({isEditMode})}
      headerText=""
      isOpen={isOpen}
      hide={hideSideSheet}
    >
      <Form withoutFormTag>
        {/* Task Title */}
        <Form.Row>
          <Form.Column lg={12} md={8} sm={4}>
            <InputField
              id={TaskFieldNames.Title}
              labelComponent={<LabelWithTooltip id={TaskFieldNames.Title} label="Title" tooltipContent="Keep this concise" />}
              onChange={formik.handleChange}
              value={formik.values.title}
              error={formik.errors.title}
              dataTestIdInput="task-title"
            />
          </Form.Column>
        </Form.Row>
        {/* Instructions */}
        <Form.Row>
          <Form.Column lg={12} md={8} sm={4}>
            <TextArea
              id={TaskFieldNames.Instructions}
              labelComponent={<LabelWithTooltip id={TaskFieldNames.Instructions} label="Instructions" tooltipContent="Describe the task to be completed" />}
              onChange={formik.handleChange}
              value={formik.values.instructions}
              error={formik.errors.instructions}
              dataTestId="task-instructions"
            />
          </Form.Column>
        </Form.Row>
        {/* Task Type */}
        <Form.Row>
          <Form.Column lg={12} md={8} sm={4}>
            <SelectField id={TaskFieldNames.TaskType} label="Task Type" options={TASK_TYPE_OPTS} value={taskTypeValue} onChange={onTaskTypeChange} error={formik.errors.task_type} />
          </Form.Column>
        </Form.Row>
        {/* Photo Limit Section */}
        <PhotoLimitSection formik={formik} />
        {/* Task Callout */}
        <TaskTypeCallout taskType={taskTypeValue} />
        {/* Create Options for certain tasks */}
        <OptionsBuilder formik={formik} onToggleChange={onToggleChange} taskType={taskTypeValue} />
        {/* Toggle Settings */}
        <ToggleSettingsSection formik={formik} onToggleChange={onToggleChange} taskType={taskTypeValue} />
        {/* Attachments */}
        <ReferenceBuilder formik={formik} onToggleChange={onToggleChange} />
      </Form>
    </SideSheet>
  );
};

export default TaskSideSheet;
