/**
 * Not a thing really in admin, so just having a single file for more global/generic usage
 */
import Cookies from 'js-cookie';

/* Cookie Names */
const AJS_ANONYMOUS_ID = 'ajs_anonymous_id';


/* Cookie Getters */
export const getAjsAnonymousCookie = () => {
  const value = Cookies.get(AJS_ANONYMOUS_ID);
  return value ||  null;
};
