import React from 'react';
// Types
import {MduFormElementProps} from 'features/MultiDwellingUnits/Parts/FormFields/FormFields.types';
import {ProjectDetails, MDUStatuses} from 'features/MultiDwellingUnits/MDU.types';
// Utils
import {isPreLaunchStatus, getProjectType} from 'features/MultiDwellingUnits/MDU.utils';
import {noop} from 'utils/event';
import {useFetchProjectTypes} from 'features/MultiDwellingUnits/MDU.hooks';
// Components
import {InputField} from 'ht-styleguide';
import PartnerSelect from 'features/MultiDwellingUnits/Parts/FormFields/PartnerSelect';
import PropertyOwnerSelect from 'features/MultiDwellingUnits/Parts/FormFields/PropertyOwnerSelect';
import AddressAutocomplete from 'components/Elements/AddressAutoComplete';
import ProjectTypeSelect from 'features/MultiDwellingUnits/Parts/FormFields/ProjectTypeSelect';

export const TEST_IDS = {
  PARTNER: {pre: 'partner-dropdown', post: 'partner-input'},
  PROJECT_TYPE: {pre: 'projectType-dropdown', post: 'projectType-input'},
  PROPERTY_OWNER: {pre: 'propertyOwner-dropdown', post: 'propertyOwner-input'},
  ADDRESS: {pre: 'address-autocomplete', post: 'address-input'},
};

type FieldByProjectStatusProps = {onChange?: BaseAnyFunction; project: ProjectDetails};

const WrappedInputField = (props: {[key: string]: string | undefined}) => <InputField {...props} iconName="lock" disabled />;

export const PartnerFieldByProjectStatus = ({formik, label, fieldName, project}: MduFormElementProps & FieldByProjectStatusProps) => {
  if (!isPreLaunchStatus(project.status as MDUStatuses)) return <WrappedInputField id={fieldName} label={label} value={project.partner?.name} dataTestId={TEST_IDS.PARTNER.post} />;
  return <PartnerSelect formik={formik} label={label} fieldName={fieldName} dataTestId={TEST_IDS.PARTNER.pre} />;
};

export const ProjectTypeFieldByProjectStatus = ({formik, label, fieldName, project}: MduFormElementProps & FieldByProjectStatusProps) => {
  const allProjectTypes = useFetchProjectTypes();
  if (!isPreLaunchStatus(project.status as MDUStatuses))
    return <WrappedInputField id={fieldName} label={label} value={getProjectType(project.projectType, allProjectTypes)} dataTestId={TEST_IDS.PROJECT_TYPE.post} />;
  return <ProjectTypeSelect formik={formik} label={label} fieldName={fieldName} project={project} dataTestId={TEST_IDS.PROJECT_TYPE.pre} />;
};
export const PropertyOwnerFieldByProjectStatus = ({formik, label, fieldName, project}: MduFormElementProps & FieldByProjectStatusProps) => {
  if (!isPreLaunchStatus(project.status as MDUStatuses)) return <WrappedInputField id={fieldName} label={label} value={project.propertyOwner?.name} dataTestId={TEST_IDS.PROPERTY_OWNER.post} />;
  return <PropertyOwnerSelect project={project} formik={formik} label={label} fieldName={fieldName} dataTestId={TEST_IDS.PROPERTY_OWNER.pre} />;
};

export const AddressFieldByProjectStatus = ({formik, label, fieldName = '', onChange = noop, project}: MduFormElementProps & FieldByProjectStatusProps) => {
  if (!isPreLaunchStatus(project.status as MDUStatuses)) return <WrappedInputField id={fieldName} label={label} value={project.address || ''} dataTestId={TEST_IDS.ADDRESS.post} />;
  return (
    <AddressAutocomplete
      input={{
        label,
        id: fieldName,
        name: fieldName,
        error: formik.errors[fieldName],
      }}
      dataTestId={TEST_IDS.ADDRESS.pre}
      clearItemsOnError
      onChange={onChange}
      value={formik.values[fieldName]}
    />
  );
};
