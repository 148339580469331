import React from 'react';

/* Components */
import {ThumbnailDisplay} from 'components/Elements/ThumbnailDisplay';

/* Utils */
import {RESOURCE_TYPE_IMAGE} from 'utils/files/constants';
import {sortByMostRecentCloudinaryResponse} from 'features/Cloudinary/cloudinary.utils';

/* Types */
import {TGenericCloudinaryResponse} from 'features/Cloudinary/cloudinary.types';

import styles from './filedisplay.styles.scss';

type TFileSelectionDisplayProps = {
  uploadedFiles: TGenericCloudinaryResponse[];
  handleDeleteFile: BaseAnyFunction;
  totalSuccessFilesCount: number;
};

/**
 * Display the files that have been uploaded to cloudinary, at the bottom of the editor.
 *
 * @param uploadedFiles
 * @param handleDeleteFile
 * @param totalSuccessFilesCount
 * @constructor
 */

const FileSelectionDisplay = ({uploadedFiles, handleDeleteFile, totalSuccessFilesCount}: TFileSelectionDisplayProps) => {
  /* Normalize Data for Display */
  const files = (uploadedFiles ?? []).sort(sortByMostRecentCloudinaryResponse).map((file: TGenericCloudinaryResponse) => {
    return {
      name: file.original_filename,
      file_type: file.format?.toUpperCase(),
      showDownloadButton: false,
      showDeleteButton: true,
      suppressNameplate: true,
      transformedFile: file.resource_type === RESOURCE_TYPE_IMAGE && file.format !== 'pdf' ? file.transformedImage : '',
      onDownloadClick: () => {
        window.open(file.url, '_self');
      },
      onDeleteClick: () => {
        handleDeleteFile(file);
      },
      url: file.url,
    };
  });

  return (
    <div className="paddingX-small paddingY-small1">
      <ThumbnailDisplay className={styles.commentContainer} parentPadding={16} files={files} thumbnailClassName={styles.imageContainer} totalSuccessFilesCount={totalSuccessFilesCount} />
    </div>
  );
};

export default FileSelectionDisplay;
