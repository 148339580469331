import {ComponentProps, ReactNode} from 'react';
import {ELEMENT_SIZE, InputField, SelectField, SelectOptions} from 'ht-styleguide';

/**
 * A workaround because SelectField's types are a little off
 */
export type TDeviceSelectBaseSelectOption = (Omit<SelectOptions<TSelectedOptionValue>, 'value' | 'label'> & {value: TSelectedOptionValue; label: string}) | null;

/**
 * BE expects a -1 value for "I don't know"
 */
export const TIDKValue = -1 as const;
/**
 * BE will always use "I don't know" as the text for the -1 value
 */
export const TIDKText = "I don't know" as const;

export const CustomDropdownValues = {
  /**
   * Represents the "I don't know" option in a dropdown
   */
  negOne: TIDKValue,
  /**
   * Represents the "Other" option in a dropdown
   */
  empty: '',
  /**
   * When in search mode, represents the "Sorry, we were unable to find a match" option
   * in a dropdown
   */
  zero: 0,
} as const;

type CustomDropdownValuesTypes = (typeof CustomDropdownValues)[keyof typeof CustomDropdownValues];

export type TSelectedOptionValue = string | number | CustomDropdownValuesTypes;

export type TMakeOrModelOptions = Array<{
  value: TSelectedOptionValue;
  label: string;
  searchable?: boolean;
  isDisabled?: boolean;
}>;

export type TMakeAndModelSet = {
  /**
   * id of device make
   */
  id: number;
  /**
   * name of device make
   */
  name: string;
  products: Array<{
    /**
     * id of device model
     */
    id: number;
    /**
     * name of device model
     */
    name: string;
  }>;
};

type TValue = {
  /**
   * - If the value is a number greater than 0, then the make has an entry in the DB. This means
   *   it could be a known device make, "I don't know" make, or a known custom make.
   * - If the value is a string, then it's the custom make's name.
   * - If the value is -1, then the make is "I don't know" option that has not been saved to the DB.
   */
  makeValue: string | number | typeof TIDKValue | null | undefined;
  /**
   * Name of the make's name if known.
   *
   * **Must be filled out if the component is used where
   * the "I don't know" answer can have an id > 1, i.e. the IDK make was saved to the DB. This
   * is required as an id > 1 could be a known device, "I don't know" value saved in the DB, or
   * an "Other" options saved in the DB. The text name is leveraged to control certain UI pieces.**
   *
   * It's a bit of a hack, but it's necessary otherwise the booking flow/cart object will
   * need changes to include the make name.
   */
  makeText: string | null | undefined;
  modelValue: string | number | typeof TIDKValue | null | undefined;
  modelText: string | null | undefined;
};

export interface IDeviceSelectorProps {
  /**
   * A ReactNode that will render below the fields
   */
  BottomComponent?: ReactNode;
  /**
   * Apply to the outermost container
   */
  containerClassName?: string;
  /**
   * A list of known devices to choose from. No need to add "I don't know" or "Other" options as the component
   * will handle.
   */
  deviceList: TMakeAndModelSet[] | null;
  /**
   * Styleguide's element size to apply to all fields/labels
   */
  elementSize?: ELEMENT_SIZE;
  /**
   * Apply a className to add space between the fields (though it could be any className)
   */
  elementSpacerClassName?: string;
  /**
   * Props to pass to the make input field. Beware of the props used as they may override the component's behavior.
   */
  makeInputFieldProps?: ComponentProps<typeof InputField>;
  makeFieldErrorMessage?: string;
  makeFieldLabel?: string;
  makeSelectFieldPlaceholder?: string;
  /**
   * Props to pass to the make select field. Beware of the props used as they may override the component's behavior.
   */
  makeSelectFieldProps?: ComponentProps<typeof SelectField>;
  /**
   * Props to pass to the model input field. Beware of the props used as they may override the component's behavior.
   */
  modelInputFieldProps?: ComponentProps<typeof InputField>;
  modelFieldErrorMessage?: string;
  modelFieldLabel?: string;
  modelSelectFieldPlaceholder?: string;
  /**
   * Props to pass to the model select field. Beware of the props used as they may override the component's behavior.
   */
  modelSelectFieldProps?: ComponentProps<typeof SelectField>;
  /**
   * Callback when the make or model value changes.
   */
  onValueChange: (args: TValue) => void;
  /**
   * Values of the make and model data.
   */
  value: TValue;
}

export interface IDeviceSelectorState {
  /**
   * Whether the model select or input field is disabled
   */
  isModelFieldDisabled: boolean;
  /**
   * String to search within the make options
   */
  makeFilterInput: string;
  /**
   * String to search within the model options
   */
  modelFilterInput: string;
  /**
   * Whether to show the make input field for the "Other" option
   */
  showMakeInput: boolean;
  /**
   * Whether to show the model input field for the "Other" option
   */
  showModelInput: boolean;
}
