/*
*******************************************************
  SideSheet Types
*******************************************************
*/
export enum StoreCreditsSideSheetTypes {
  create = 'create',
}

/*
*******************************************************
  URL Query Params
  Query keys in the url that the side sheet listens for.
*******************************************************
*/
export enum StoreCreditsQueryParams {
  StoreCreditMode = 'store_credit_mode',
}

/*
*******************************************************
  Queries/Mutations (react-query)
*******************************************************
*/
export type TCreateCreditPayload = {
  created_by_id: number;
  credit_name: string;
  /**
   * YYYY/MM/DD
   */
  expires_at: string;
  /**
   * In cents: 7999
   */
  amount: number;
  /**
   * null for HelloTech items
   */
  partner_id: number | null;
  credit_items_attributes: Array<{
    item_type: 'Sku' | 'SkuCategory' | 'Plan' | 'PlanProduct';
    item_name: string;
    /**
     * null if item_type is not Sku or Plan
     */
    item_category_name: string | null;
    item_id: number;
  }>;
};

export type TCreateStoreCredit = {
  id: number;
  credit_name: string;
  /**
   * Datetime in UTC: "2024-06-30T00:00:00.000Z"
   * But, ignore the timezone and just use the date.
   */
  expires_at: string;
  /**
   * In cents
   */
  amount: number;
  token: string;
  partner: null | {
    name: string;
  };
  credit_items: Array<{
    id: number;
    store_credit_id: number;
    item_id: number;
    /**
     * null if item_type is not Sku or Plan
     */
    item_category_name: string | null;
    item_name: string;
    item_type: 'Sku' | 'Plan' | 'SkuCategory' | 'PlanProduct';
    created_at: string;
    updated_at: string;
  }>;
  client_credits: Array<{}>;
  created_at: string;
  updated_at: string;
};

export type TCreateCreditResponse = WithErrorResponse<{
  store_credit: TCreateStoreCredit;
}>;
