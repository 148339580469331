import React from 'react';
import {noop} from 'utils/event';
import {Modal, Button, BUTTON_THEMES} from 'ht-styleguide';
import {TASK_CHECKLIST_ITEM_TYPES} from '../TaskChecklist.constants';
import {TTaskChecklistItemType} from '../TaskSideSheet/taskChecklist.types';

type TDeleteItemModalProps = {
  isVisible: boolean;
  onCancelClick?: BaseAnyFunction;
  onConfirmClick?: BaseAnyFunction;
  itemType: TTaskChecklistItemType | null;
};

/** Modal content configurations for group and task deletion */
const CONFIG_BY_ITEM_TYPE = {
  [TASK_CHECKLIST_ITEM_TYPES.GROUP]: {
    header: 'Are you sure you want to delete this group?',
    bodyText: 'All of its tasks will also be deleted. This action cannot be undone.',
    confirmButton: {
      text: 'Delete Group and Tasks',
      theme: BUTTON_THEMES.DANGER_SECONDARY,
    },
  },
  [TASK_CHECKLIST_ITEM_TYPES.TASK]: {
    header: 'Are you sure you want to delete this task?',
    bodyText: 'This action cannot be undone.',
    confirmButton: {
      text: 'Delete Task',
      theme: BUTTON_THEMES.PRIMARY,
    },
  },
  [TASK_CHECKLIST_ITEM_TYPES.TASKS_AND_GROUPS]: {
    header: 'Are you sure you want to disable grouping?',
    bodyText: 'Turning off grouping will merge your tasks into one group, and any empty groups will be deleted. Do you want to proceed?',
    confirmButton: {
      text: 'Disable Grouping',
      theme: BUTTON_THEMES.DANGER_SECONDARY,
    },
  },
};

/** Confirmation modal used to delete an item; either a group or a task */
export const DeleteItemModal = ({isVisible, onCancelClick = noop, onConfirmClick = noop, itemType}: TDeleteItemModalProps) => {
  if (!itemType) return null;

  const {header, bodyText, confirmButton} = CONFIG_BY_ITEM_TYPE[itemType];
  return (
    <Modal
      header={header}
      isVisible={isVisible}
      hide={onCancelClick}
      footerElement2={
        <Button theme={BUTTON_THEMES.SECONDARY} onClick={onCancelClick}>
          Cancel
        </Button>
      }
      footerElement3={
        <Button theme={confirmButton.theme} onClick={onConfirmClick}>
          {confirmButton.text}
        </Button>
      }
    >
      <p className="p1 n900">{bodyText}</p>
    </Modal>
  );
};
