import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Button, BUTTON_THEMES, htToast, Modal, ELEMENT_SIZE} from 'ht-styleguide';

/* Ducks */
import issuesSlice from 'features/Issues/Issues.ducks';

/* Hooks */
import {useDeleteIssueMutation} from 'features/Issues/queries/mutation.issues.deleteIssues';
import {useSearchParams} from 'hooks/useSearchParam';

/* Constants */
import {featureTypeIssues, ISSUE_ENTITY_TYPES, ISSUE_PARAMS_TYPES, ISSUES_MODAL_TYPES} from 'features/Issues/Issues.constants';

import {TActionItem} from 'features/Issues/issues.types';

const DeleteActionModal = () => {
  /* Hooks */
  const dispatch = useDispatch();
  const {mutate} = useDeleteIssueMutation();
  const actionItem: TActionItem = useSelector(issuesSlice.selectors.getActionItem);
  const {getAllParams} = useSearchParams();

  /* Issues Params */
  const {[ISSUE_PARAMS_TYPES.ticket_id]: issueTicketId} = getAllParams();

  if (!actionItem) return null;

  /* Methods */
  const openDeleteModal = () => {
    const isDelete = actionItem?.modalType === ISSUES_MODAL_TYPES.delete;
    const isIssue = actionItem?.modalKey === featureTypeIssues;

    return isDelete && isIssue;
  };

  /* Remove the actionItem from redux */
  const onCloseActionItem = () => {
    dispatch(issuesSlice.actions.removeActionItemModalSlide());
  };

  const deleteProjectAction = () => {
    const ticketId = actionItem?.entity?.id || issueTicketId;
    if (ticketId) {
      mutate(ticketId, {
        onSuccess: () => {
          htToast('Issue Deleted');
          actionItem.onSuccessCallback?.();
          onCloseActionItem();
        },
      });
    }
  };

  /* Constants */
  const deleteConstants = () => {
    const type = {
      [ISSUE_ENTITY_TYPES.project]: {
        dispatchAction: deleteProjectAction,
        body: `This action cannot be undone.`,
        title: `Are you sure you want to delete this issue?`,
      },
    };

    return type[actionItem?.entity?.entity_type as unknown as (typeof ISSUE_ENTITY_TYPES)[keyof typeof ISSUE_ENTITY_TYPES]] || ISSUE_ENTITY_TYPES.project;
  };
  const {body, dispatchAction, title} = deleteConstants();

  return (
    <Modal
      isVisible={openDeleteModal()}
      hide={onCloseActionItem}
      onRequestClose={onCloseActionItem}
      elementSize={ELEMENT_SIZE.MEDIUM}
      header={title}
      footerElement2={
        <Button theme={BUTTON_THEMES.SECONDARY} dataTestId="button_cancel_job" onClick={onCloseActionItem}>
          Cancel
        </Button>
      }
      footerElement3={
        <Button theme={BUTTON_THEMES.DANGER_SECONDARY} dataTestId="button_delete_job" onClick={dispatchAction}>
          Delete Issue
        </Button>
      }
    >
      <div className="position relative">
        <p className="p1 n900" dangerouslySetInnerHTML={{__html: `${body}`}} />
      </div>
    </Modal>
  );
};

export default DeleteActionModal;
