import {useCallback, useEffect} from 'react';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import debounce from 'lodash/debounce';
import {useAppDispatch} from 'hooks/useAppDispatch';
import EditorDuck from '../state/editor.ducks';

type TStateSavePluginPluginProps = {
  editorNamespace: string;
};

/**
 * This plugin will save the editor state to Redux
 *   - Redux::editorDuck::editorContents
 *
 * @param editorNamespace
 * @constructor
 */
export function StateSavePlugin({editorNamespace}: TStateSavePluginPluginProps) {
  const [editor] = useLexicalComposerContext();
  const dispatch = useAppDispatch();

  const saveContent = useCallback(
    (content: string) => {
      dispatch(EditorDuck.actions.setEditorContents({editorNamespace, content}));
    },
    [editorNamespace]
  );

  const debouncedSaveContent = debounce(saveContent, 200, {trailing: true, leading: false});

  useEffect(() => {
    return editor.registerUpdateListener(({editorState, dirtyElements, dirtyLeaves}) => {
      // Don't update if nothing changed
      if (dirtyElements.size === 0 && dirtyLeaves.size === 0) return;

      const serializedState = JSON.stringify(editorState);
      debouncedSaveContent(serializedState);
    });
  }, [debouncedSaveContent, editor]);

  return null;
}
