import React, {ChangeEvent, useState} from 'react';
import {Modal, ELEMENT_SIZE, TextArea, SelectField, Button, BUTTON_THEMES} from 'ht-styleguide';

/* Ducks & Store */
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';

/* Hooks */
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useSelector} from 'hooks/useAppSelector';

/* Queries/Mutations */
import {TMutationReasonsParams} from 'features/MultiDwellingUnits/queries/mutation.reasons';
import {useGetReasons, TReasonTypes} from 'features/MultiDwellingUnits/queries/query.getReasons';

/* Utils & Constants */
import {normalizeReasonForSelect} from 'features/MultiDwellingUnits/MDU.utils';
import {DEFAULT_SELECT_OPTION} from 'global/constants/common';
import {DEFAULT_UNIT_NAME} from 'features/MultiDwellingUnits/MDU.constants';

import {TSingleActionTypes, SelectOption, TActionItem} from 'features/MultiDwellingUnits/MDU.types';

/* Styles */
import styles from './modals.styles.scss';
import {useJobsActionsItems} from '../../Pages/CurrentProject/hooks/Jobs.useActionItems';

const ReasonsModal = () => {
  /* Local */
  const [cancelReason, setCancelReason] = useState<SelectOption>(DEFAULT_SELECT_OPTION);
  const [cancelReasonText, setCancelReasonText] = useState<string>('');

  /* Hooks */
  const dispatch = useAppDispatch();
  const actionItem: TActionItem = useSelector(mduProjectsSlice.selectors.getActionItem);
  const {unserviceUnitAction} = useJobsActionsItems();

  /* Constants */
  const isVisible = [TSingleActionTypes.CANNOT_COMPLETE].includes(actionItem?.modalKey!);
  const reasonConstant = (() => {
    const key = actionItem?.modalKey!;
    const reason: Record<TSingleActionTypes, any> = {
      [TSingleActionTypes.CANNOT_COMPLETE]: {
        title: 'Cannot Complete',
        body: `Marking <strong>${actionItem?.entity?.unit_name || DEFAULT_UNIT_NAME}</strong> cannot complete will flag the job for future action.`,
        bodySecondary: ``,
        cta: 'Mark Cannot Complete',
        accessor: TReasonTypes.UNSERVICEABLE,
        dispatch: (params: TMutationReasonsParams) =>
          unserviceUnitAction({
            ...params,
            unitId: actionItem?.entity?.id!,
          }),
        reasonKey: 'unserviceable_reason_id',
      },
      [TSingleActionTypes.DELETE]: undefined,
      [TSingleActionTypes.APPROVE]: undefined,
      [TSingleActionTypes.REOPEN]: undefined,
      [TSingleActionTypes.ADD_SINGLE_JOB]: undefined,
      [TSingleActionTypes.PAUSE]: undefined,
      [TSingleActionTypes.LINK_ISSUE_PAUSED_PROJECT]: undefined,
      [TSingleActionTypes.RESUME]: undefined,
    };

    if (key && reason[key]) return reason[key];

    return {};
  })();

  /* Query */
  const {data, isLoading} = useGetReasons({options: {enabled: isVisible}});

  /* format the data for options display in dropdown */
  const reasonsOptions = data
    ? (() => {
        const derivedData = data[reasonConstant.accessor as TReasonTypes];
        return normalizeReasonForSelect(derivedData);
      })()
    : [];

  /* -------- methods -------- */
  const closeModal = () => {
    dispatch(mduProjectsSlice.actions.removeActionItemModalSlide());
  };

  /* dropdown selection */
  const onReasonChange = (reason: SelectOption) => {
    setCancelReason(reason);
  };

  /* Capture textbox updates */
  const onChangeTextBox = (e: ChangeEvent<HTMLInputElement>) => {
    const reason = e.target?.value || '';
    setCancelReasonText(reason);
  };

  /* Submit is good, lets cancel/unservice the job */
  const onSubmit = async () => {
    // Find the matching raw reason with users selection
    const params = {
      [reasonConstant.reasonKey]: cancelReason?.value,
      note: cancelReasonText,
    };

    reasonConstant.dispatch(params);
    closeModal();
  };

  const isSubmittable = cancelReason?.value && cancelReasonText;

  return (
    <Modal
      isVisible={isVisible}
      modalClassName={styles.container}
      elementSize={ELEMENT_SIZE.LARGE}
      header={reasonConstant.title}
      hide={closeModal}
      onRequestClose={closeModal}
      footerElement2={
        <Button theme={BUTTON_THEMES.SECONDARY} dataTestId="button_cancl_job" className={styles.ctaButton} onClick={closeModal}>
          Cancel
        </Button>
      }
      footerElement3={
        <Button disabled={!isSubmittable} theme={BUTTON_THEMES.DANGER_SECONDARY} dataTestId="button_approve_job" className={styles.ctaButton} onClick={onSubmit}>
          {reasonConstant.cta}
        </Button>
      }
    >
      <div className="container">
        <div className="border_bottom">
          <div className="flex flex-direction-column">
            <div>
              <div className="p2 paddingBottom-medium" dangerouslySetInnerHTML={{__html: reasonConstant.body}} />
              {reasonConstant.bodySecondary && <div className="p2 paddingTop-small paddingBottom-small" dangerouslySetInnerHTML={{__html: reasonConstant.bodySecondary}} />}
              {isLoading ? (
                <div className="p2">Generating reasons...</div>
              ) : (
                <SelectField
                  label="Why can't this job be completed?"
                  placeholder="Please select a reason"
                  value={cancelReason}
                  options={reasonsOptions}
                  onChange={onReasonChange}
                  clearable={false}
                  searchable={false}
                />
              )}
            </div>
            <div className="paddingTop-medium">
              <TextArea onChange={onChangeTextBox} label="Describe the issue" />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReasonsModal;
