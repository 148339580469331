import {CustomDropdownValues} from 'features/Questions/types';

export const CUSTOM_DROPDOWN_OPTIONS = {
  I_DONT_KNOW: {value: CustomDropdownValues.negOne, label: "I don't know", searchable: false},
  OTHER: {value: CustomDropdownValues.empty, label: 'Other', searchable: false},
  NO_OPTIONS: {value: CustomDropdownValues.zero, label: 'Sorry, we were unable to find a match', isDisabled: true, searchable: false},
} as const;

export const QUESTION_TYPES = {
  MAKE_INPUT: 'makeInput',
  MAKE_DROPDOWN: 'makeDropdown',
  MODEL_INPUT: 'modelInput',
  MODEL_DROPDOWN: 'modelDropdown',
};
