import {TUtilTableFiltersState, TUtilTableRawFiltersState, TUtilTableColumnOrder, TUtilTableColumnSort} from 'utils/table/table.types';

/*
*******************************************************
  Sidebar navigation type
*******************************************************
*/

export enum DispatchNavTypes {
  All = 'all',
  AssignedToMe = 'assigned-to-me',
}

export type TDispatchRouteParams = {
  dispatchNavType: DispatchNavTypes;
};

/*
*******************************************************
  Initial State
*******************************************************
*/

export type TUnassignedListInitialState = {
  filters: TUtilTableFiltersState;
  rawFilters: TUtilTableRawFiltersState;
  columnState: Record<string, {visible: boolean; columnWidth: number}>;
  columnOrder: TUtilTableColumnOrder;
  columnSort: TUtilTableColumnSort;
  pagination: TBasePagination;
};

export interface IDispatchInitialState {
  pages: {
    unassignedList: {
      [DispatchNavTypes.All]: TUnassignedListInitialState;
      [DispatchNavTypes.AssignedToMe]: TUnassignedListInitialState;
    };
  };
}

export type TUnassignedListLocalStorageState = {
  [pageLocation in keyof IDispatchInitialState['pages']['unassignedList']]: Omit<TUnassignedListInitialState, 'pagination'>;
};

/*
*******************************************************
  Base Types
*******************************************************
*/

/**
 * Field names found in response data
 */
export enum UnassignedOrderFields {
  Id = 'id',
  Priority = 'priority',
  LastAvailabilityIn = 'last_availability_in',
  LastAvailabilityAt = 'last_availability_at',
  OfferCount = 'offer_count',
  CurrentGeekCut = 'current_geek_cut',
  DefaultGeekCut = 'default_geek_cut',
  MaxGeekCut = 'max_geek_cut',
  Partner = 'partner',
  CreatedAt = 'created_at',
  State = 'state',
  Zip = 'zip',
  CBSA = 'cbsa',
  LastOfferSentAt = 'last_offer_sent_at',
  OnHold = 'on_hold',
  Tier = 'tier',
  FieldNation = 'field_nation',
  Timezone = 'timezone',
  HasActiveOffers = 'has_active_offers',
  HasStandby = 'has_standby',
  LateRelease = 'late_release',
  Agent = 'agent',
  Coordinates = 'coordinates',
  LastOrderNote = 'last_order_note',
  IncentivePayout = 'incentive_payout',
}

/**
 * Column keys used in the table. Also used as `id` in the column state.
 * These are also the keys to reference data that's passed to the table (for sorting behavior)
 * Cannot string interpolation with enums
 */
export const UnassignedOrdersColumnKeys = {
  Priority: UnassignedOrderFields.Priority,
  Id: UnassignedOrderFields.Id,
  LastAvailabilityIn: UnassignedOrderFields.LastAvailabilityIn,
  OfferCount: UnassignedOrderFields.OfferCount,
  Payout: UnassignedOrderFields.CurrentGeekCut,
  FNStatus: `${UnassignedOrderFields.FieldNation}.status`,
  FNOrderId: `${UnassignedOrderFields.FieldNation}.id`,
  Partner: `${UnassignedOrderFields.Partner}.name`,
  CreatedAt: UnassignedOrderFields.CreatedAt,
  Tier: UnassignedOrderFields.Tier,
  State: UnassignedOrderFields.State,
  Zip: UnassignedOrderFields.Zip,
  OfferStatus: 'offerStatus',
  PartnerId: `${UnassignedOrderFields.Partner}.id`,
  DefaultGeekCut: UnassignedOrderFields.DefaultGeekCut,
  CurrentIncentive: UnassignedOrderFields.IncentivePayout,
  CBSA: `${UnassignedOrderFields.CBSA}.name`,
  LastOfferSentAt: UnassignedOrderFields.LastOfferSentAt,
  Timezone: UnassignedOrderFields.Timezone,
  LastOrderNote: UnassignedOrderFields.LastOrderNote,
  LastAvailabilityAt: UnassignedOrderFields.LastAvailabilityAt,
  HasStandby: UnassignedOrderFields.HasStandby,
  LateTechReleased: UnassignedOrderFields.LateRelease,
  AgentAssigned: `${UnassignedOrderFields.Agent}.name`,
  Latitude: `${UnassignedOrderFields.Coordinates}.0`,
  Longitude: `${UnassignedOrderFields.Coordinates}.1`,
} as const;

export type TUnassignedOrdersColumnKeysValues = (typeof UnassignedOrdersColumnKeys)[keyof typeof UnassignedOrdersColumnKeys];

// Query keys used for api calls + keys to determine which filters to show
export enum UnassignedFilterKeys {
  AgentId = 'agent_id',
  PartnerId = 'partner_id',
  OrderId = 'order_id',
  Tier = 'tier',
  State = 'state',
  CBSAId = 'cbsa_id',
  Standby = 'standby',
  OnHold = 'on_hold',
  SkuCategoryIds = 'sku_category_ids',
  SkuIds = 'sku_ids',
  CreatedAt = 'created_at',
  ActiveOffers = 'active_offers',
  Priority = 'priority',
  LateRelease = 'late_release',
}

export enum TPriorityOptions {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export type TUserProfile = {
  id: number;
  phone: string | null;
  email: string;
  name: string;
  profile_picture: {
    medium: string;
    retina: string;
    thumb: string;
    retina_thumb: string;
  };
};

/*
*******************************************************
  Query/Mutation Responses
*******************************************************
*/

export type TUnassignedOrder = {
  /**
   * Order id
   */
  [UnassignedOrderFields.Id]: number;
  [UnassignedOrderFields.Priority]: 'low' | 'medium' | 'high';
  /**
   * In days
   */
  [UnassignedOrderFields.LastAvailabilityIn]: number;
  /**
   * UTC datetime format
   */
  [UnassignedOrderFields.LastAvailabilityAt]: string;
  [UnassignedOrderFields.OfferCount]: number;
  /**
   * In cents
   */
  [UnassignedOrderFields.CurrentGeekCut]: number;
  /**
   * In cents
   */
  [UnassignedOrderFields.DefaultGeekCut]: number;
  /**
   * In cents
   */
  [UnassignedOrderFields.MaxGeekCut]: number;
  [UnassignedOrderFields.Partner]: TUserProfile;
  /**
   * Booked on - UTC Datetime format
   */
  [UnassignedOrderFields.CreatedAt]: string;
  [UnassignedOrderFields.State]: string;
  [UnassignedOrderFields.Zip]: string;
  [UnassignedOrderFields.CBSA]?: {
    id: number;
    name: string;
  };
  /**
   * UTC Datetime format
   */
  [UnassignedOrderFields.LastOfferSentAt]: string;
  [UnassignedOrderFields.OnHold]: boolean;
  [UnassignedOrderFields.Tier]: number;
  [UnassignedOrderFields.FieldNation]?: {
    id: number;
    url: string;
    status: string;
  };
  [UnassignedOrderFields.Timezone]: string;
  [UnassignedOrderFields.LastOrderNote]?: {
    id: number;
    content: string;
    user: TUserProfile;
  };
  [UnassignedOrderFields.HasActiveOffers]: boolean;
  [UnassignedOrderFields.HasStandby]: boolean;
  [UnassignedOrderFields.LateRelease]: boolean;
  [UnassignedOrderFields.Agent]: TUserProfile;
  /**
   * [latitude, longitude]
   */
  [UnassignedOrderFields.Coordinates]: [number, number];
  [UnassignedOrderFields.IncentivePayout]: number;
};

export type TGetUnassignedOrdersQueryResponse = WithErrorResponse<{
  orders: Array<TUnassignedOrder>;
  pagination: TBasePagination;
}>;

export type TGetUnassignedStatsQueryResponse = WithErrorResponse<{
  stats: {
    late_release: number;
    on_hold: number;
    priority_high: number;
    priority_medium: number;
    tier1: number;
    tier2: number;
    tier3: number;
    tier4: number;
    total: number;
  };
}>;

/*
*******************************************************
  Redux Actions
*******************************************************
*/

export type TUpdateUnassignedListColumnVisibilityPayload = {
  navType: DispatchNavTypes;
  visibilityState: Record<TUnassignedOrdersColumnKeysValues, {visible: boolean}>;
};

export type TUpdateUnassignedListColumnWidthsPayload = {
  navType: DispatchNavTypes;
  columnWidths: {
    [fieldName: string]: number;
  };
};

export type TUpdateUnassignedListFiltersPayload = {
  navType: DispatchNavTypes;
  filters: TUnassignedListInitialState['filters'];
  replace?: boolean;
};

export type TUpdateUnassignedListRawFiltersStatePayload = {
  navType: DispatchNavTypes;
  newRawFiltersState: TUnassignedListInitialState['rawFilters'];
};

export type TUpdateUnassignedListPaginationPayload = {
  navType: DispatchNavTypes;
  pagination: Partial<TBasePagination>;
};

export type TUpdateUnassignedListColumnOrderPayload = {
  navType: DispatchNavTypes;
  columnOrder: TUtilTableColumnOrder;
};

export type TUpdateUnassignedListColumnSortPayload = {
  navType: DispatchNavTypes;
  columnSort: TUtilTableColumnSort;
};
