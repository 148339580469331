import React from 'react';
import {useNavigate} from 'react-router-dom';

// Hooks && Utils
import {useFilteredProjects} from 'features/MultiDwellingUnits/queries/query.project.paginatedFiltered';
import {routeToProjectPage} from './utils/utils.routeToProjectPage';

// Components
import {useStickyContainer} from 'components/Elements/StickyContainer/useStickyContainer';
import PageHeader from 'components/Elements/PageHeader';
import {MDUStatuses, ProjectDetailsShallow, NavTypes} from 'features/MultiDwellingUnits/MDU.types';
import MDUProjectsFilters from './Parts/MDUProjectsFilters';
import MDUProjectsTable from './Parts/MDUProjectsTable/MDUProjectsTable';
import {useProjectsRouteParams} from './hooks';
import MDUProjectsStats from './Parts/MDUProjectsStats';

const GenericPage = () => {
  const navigate = useNavigate();
  /* Hooks */
  const {columnKeys, filterKeys, pageFilters, statKeys, title, defaultLabel, projectsPage, projectsType} = useProjectsRouteParams();
  const {StickyContainer} = useStickyContainer({});

  const hasStats = statKeys.length > 1;
  const needsApproval = pageFilters?.statuses?.includes(MDUStatuses.NEEDS_APPROVAL);
  const lowercaseTitle = title.toLowerCase();
  const introTextNoResults = NavTypes.OWN !== projectsType ? 'There are ' : 'You have ';
  const emptyStateHeader = needsApproval ? `${introTextNoResults} no projects needing approval` : `${introTextNoResults} no ${lowercaseTitle}`;

  const handleRouteToProjectPage = (project: ProjectDetailsShallow) => routeToProjectPage({navigate, project});

  /* Queries */
  const {results, pagination} = useFilteredProjects({
    pageFilters,
    pageType: projectsPage,
  });

  return (
    <div>
      <PageHeader title={title} collapsible={hasStats}>
        {hasStats && <MDUProjectsStats pageType={projectsPage} pageFilters={pageFilters} statKeys={statKeys} defaultLabel={defaultLabel} projectsType={projectsType} />}
      </PageHeader>
      <StickyContainer>
        <MDUProjectsFilters pageType={projectsPage} filterKeys={filterKeys} />
      </StickyContainer>
      <MDUProjectsTable
        pageType={projectsPage}
        columnKeys={columnKeys}
        emptyStateHeader={emptyStateHeader}
        isActionableRow
        onRowClickCb={handleRouteToProjectPage}
        projectData={results}
        paginationDataProp={pagination}
      />
      <div className="marginBottom-huge" />
    </div>
  );
};

export default React.memo(GenericPage);
