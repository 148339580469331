import React, {forwardRef} from 'react';
import cn from 'classnames';
import {noop} from 'utils/event';
import {Icon, Tooltip} from 'ht-styleguide';
import styles from './Sidebar.scss';

interface ISidebarHideShowButtonProps {
  isParentCollapsed: boolean;
  onClick: () => void;
  dataTestId?: string;
}

export const SidebarHideShowButton = forwardRef<HTMLDivElement, ISidebarHideShowButtonProps>(({isParentCollapsed, onClick = noop, dataTestId}, ref) => {
  const iconName = isParentCollapsed ? 'chevron-right' : 'chevron-left';
  const tooltipLanguage = isParentCollapsed ? 'Expand' : 'Collapse';
  return (
    <div className={styles.hideShowButtonContainer} ref={ref}>
      <Tooltip content={tooltipLanguage} position="right">
        <button type="button" onClick={onClick} className={cn('plainButton white', styles.hideShowButton)} data-testid={dataTestId}>
          <Icon name={iconName} />
        </button>
      </Tooltip>
    </div>
  );
});
