import React, {useState, useEffect, useMemo} from 'react';
import TableToolbarLink from 'components/Table/TableToolbar/TableToolbar.Link';
import ColumnEditingModal from 'components/Table/TableToolbar/Parts/ColumnEditingModal';
import {UNASSIGNED_ORDERS_COLUMN_LABELS, UNASSIGNED_ORDERS_COLUMN_DISABLED_USER_CONTROL} from 'features/Dispatch/dispatch.constants';
import dispatchSlice from 'features/Dispatch/dispatch.ducks';
import {UnassignedOrdersColumnKeys, TUnassignedOrdersColumnKeysValues, TUpdateUnassignedListColumnVisibilityPayload} from 'features/Dispatch/dispatch.types';
import useUnassignedListRouteParams from 'features/Dispatch/hooks/useUnassignedListRouteParams';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useSelector} from 'hooks/useAppSelector';
import usePrevious from 'hooks/usePrevious';

const UnassignedTableToolbarColumnEditing = () => {
  const dispatch = useAppDispatch();

  // Toggle Modal States
  const [isModalVisible, setIsModalVisible] = useState(false);
  const toggleModal = () => setIsModalVisible(!isModalVisible);
  const previousIsModalVisible = usePrevious(isModalVisible);

  // Base States and Data
  const {dispatchNavType, omitColumnKeys} = useUnassignedListRouteParams();
  const {columnState} = useSelector(dispatchSlice.selectors.getUnassignedListPageStateByKey(dispatchNavType)) || {};

  // Checkbox States
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  // When the modal is opened, set the selected values to the visible columns
  useEffect(() => {
    if (isModalVisible && !previousIsModalVisible) {
      const visibleColumnsFromState = Object.keys(columnState).filter(key => columnState[key as TUnassignedOrdersColumnKeysValues].visible);
      setSelectedValues(visibleColumnsFromState);
    }
  }, [columnState, isModalVisible, omitColumnKeys, previousIsModalVisible]);

  const checkboxOptions = useMemo(() => {
    return Object.values(UnassignedOrdersColumnKeys)
      .filter(key => !omitColumnKeys.includes(key))
      .map(columnKey => {
        return {
          label: UNASSIGNED_ORDERS_COLUMN_LABELS[columnKey],
          value: columnKey,
          disabled: UNASSIGNED_ORDERS_COLUMN_DISABLED_USER_CONTROL.includes(columnKey),
        };
      });
  }, [omitColumnKeys]);

  const onSaveChange = () => {
    const visibilityColumnState = Object.values(UnassignedOrdersColumnKeys).reduce((acc, key) => {
      acc[key] = {
        visible: selectedValues.includes(key),
      };
      return acc;
    }, {} as TUpdateUnassignedListColumnVisibilityPayload['visibilityState']);
    dispatch(dispatchSlice.actions.updateUnassignedListColumnVisibility({navType: dispatchNavType, visibilityState: visibilityColumnState}));
    toggleModal();
  };

  const onSelectChange = (selectedCheckboxValues: string[]) => setSelectedValues(selectedCheckboxValues);

  return (
    <>
      <TableToolbarLink label="Edit Columns" iconName="edit-plain" onClick={toggleModal} />
      <ColumnEditingModal
        isVisible={isModalVisible}
        onSaveChange={onSaveChange}
        onSelectChange={onSelectChange}
        selectedValues={selectedValues}
        checkboxOptions={checkboxOptions}
        toggleModal={toggleModal}
      />
    </>
  );
};

export default UnassignedTableToolbarColumnEditing;
