import {createSlice} from '@reduxjs/toolkit';
import {RequestLoadingType} from './requestLoader.types';

const initialState: RequestLoadingType = {
  loading: false,
  loadingCount: 0,
};

export const {actions, reducer} = createSlice({
  name: 'requestLoading',
  initialState,
  reducers: {
    /*
       Noticed race conditions in which multiple api loadings are shut off because one returns earlier after all have been fired.
       This is a simple counter to keep track of how many api calls are loading.

       PREV issue:
       api1 - fired - loading
       api2 - fired - loading
       api3 - fired - loading
       api1 - returned - loading  ----> loading is turned off yet we still have two outstanding api calls.

       Note: we could use a key/loading strategy but this is a simple solution for now, w/o refactoring.
   */
    loading: (state, action) => {
      const count = state.loadingCount + (Number(action.payload) ? 1 : -1);
      state.loading = count > 0;
      state.loadingCount = count < 0 ? 0 : count;
    },
  },
});

const getLoadingState = (state: {requestLoading: RequestLoadingType}) => state.requestLoading.loading;

export const selectors = {
  getLoadingState,
};
