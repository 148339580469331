import {useQuery} from 'react-query';
import useApi from 'hooks/useApi';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {showErrorToast, logResponseError} from 'queries/query.utils';
import {TSkuChecklist} from '../types/taskGroups.types';
import {PRODUCTS_QUERY_KEYS} from './products.query.keys';

// https://docs.internal.hellotech.com/api-9679704
type TUseGetSkuTaskGroupsResponse = WithErrorResponse<TSkuChecklist>;

type TUseGetSkuTaskGroupsQueryParams = {
  /**
   * sku id to fetch on
   */
  id: number;
  useLoader?: boolean;
  queryOpts?: Parameters<typeof useQuery<TUseGetSkuTaskGroupsResponse['data']>>['2'];
};

/**
 * Fetch task checklist by sku id
 */
const useGetSkuTaskGroupsQuery = ({id, useLoader = false, queryOpts}: TUseGetSkuTaskGroupsQueryParams) => {
  const api = useApi();
  const dispatch = useAppDispatch();

  return useQuery<TUseGetSkuTaskGroupsResponse['data']>(
    PRODUCTS_QUERY_KEYS.getTaskGroups(id),
    async () => {
      if (useLoader) {
        api.toggleLoader(true);
      }
      const response: TUseGetSkuTaskGroupsResponse = await api.skus.getTaskGroups({id});
      if (useLoader) {
        api.toggleLoader(false);
      }
      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Retrieving Sku Task Groups'});
        logResponseError('Sku Task Groups')(response.err);
        throw new Error('Error Retrieving Sku Task Groups');
      }
      return response?.data;
    },
    queryOpts
  );
};

export default useGetSkuTaskGroupsQuery;
