import {useFormik} from 'formik';
import {TGenericCloudinaryResponse} from 'features/Cloudinary/cloudinary.types';

/**
 * Should be aligned with the update task mutation
 */
export enum UpdateTaskAnswerFieldName {
  Options = 'options',
  Response = 'response',
  Attachments = 'attachments',
  DeviceResponse = 'device_response',
  DeviceProductMakeId = 'product_make_id',
  DeviceProductId = 'product_id',
}

/**
 * Custom fields to help with form validation. These should not be included in the BE payload.
 */
export enum CustomUpdateTaskAnswerFieldName {
  Required = 'required',
  HasIncidentToReport = 'hasIncidentToReport',
  DeviceProductMakeName = 'product_make_name',
  DeviceProductModelName = 'product_model_name',
}

/**
 * Structure should be aligned with the update task mutation
 */
export type TUpdateTaskAnswerFormValues = {
  [UpdateTaskAnswerFieldName.Options]: Array<{id: number}>;
  [UpdateTaskAnswerFieldName.Response]: string;
  [UpdateTaskAnswerFieldName.Attachments]: TGenericCloudinaryResponse[];
  [UpdateTaskAnswerFieldName.DeviceResponse]: {
    [UpdateTaskAnswerFieldName.DeviceProductMakeId]: number | string;
    [UpdateTaskAnswerFieldName.DeviceProductId]: number | string;
    [CustomUpdateTaskAnswerFieldName.DeviceProductMakeName]: string | null | undefined;
    [CustomUpdateTaskAnswerFieldName.DeviceProductModelName]: string | null | undefined;
  } | null;
  [CustomUpdateTaskAnswerFieldName.Required]: boolean;
  [CustomUpdateTaskAnswerFieldName.HasIncidentToReport]: boolean;
};

export type TUpdateTaskAnswerFormik = ReturnType<typeof useFormik<TUpdateTaskAnswerFormValues>>;
