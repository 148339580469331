import React, {useEffect, useMemo, useState} from 'react';
import ColumnEditingModal from 'components/Table/TableToolbar/Parts/ColumnEditingModal';
import useIssuesRouteParams from 'features/Issues/hooks/useIssuesRouteParams';
import useIssuesTableSearchFieldsQuery from 'features/Issues/queries/query.issues.table.searchFields';
import issuesSlice from 'features/Issues/Issues.ducks';
import {TTableColumnState} from 'features/Issues/issues.types';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useSelector} from 'hooks/useAppSelector';
import usePrevious from 'hooks/usePrevious';
import {IColumnsModal, TRootColumnState} from './IssuesTableToolbar.types';

const ColumnsModal = ({isVisible, toggleModal}: IColumnsModal) => {
  const dispatch = useAppDispatch();
  const {data: searchFieldsData} = useIssuesTableSearchFieldsQuery();
  const {issuesNavType} = useIssuesRouteParams();
  /**
   * Retrieve selected/unselected columns from redux. This will be an empty object when first
   * visiting the modal. On first open, checkboxes are driven by the default values in the searchFieldsData
   */
  const {columnState} = useSelector(issuesSlice.selectors.getPagesStateByKey(issuesNavType)) || {};
  /**
   * Transformed query data for pushing new state to redux and for checkbox options
   */
  const [rootColumnState, setRootColumnState] = useState<TRootColumnState | null>(null);
  /**
   * Keep track of selected columns for checkbox group
   */
  const [selectedColumnValues, setSelectedColumnValues] = useState<Array<string>>([]);

  const prevIsVisible = usePrevious(isVisible);
  useEffect(() => {
    if (isVisible) {
      const tempSelectedColumnValues: Array<string> = [];

      const baseColumnState = searchFieldsData.reduce((acc: TRootColumnState, searchField: any = {}) => {
        const {label, name, search = {}} = searchField;
        const {table_allowed, table_default, table_user_control} = search;

        if (table_allowed) {
          const columnStateByName = columnState[name];

          const visible = columnStateByName && 'visible' in columnStateByName ? columnStateByName.visible : table_default;
          if (visible) {
            tempSelectedColumnValues.push(name);
          }

          acc[name] = {
            label,
            visible,
            userControl: table_user_control,
          };
        }
        return acc;
      }, {} as TRootColumnState);

      setSelectedColumnValues(tempSelectedColumnValues);
      setRootColumnState(baseColumnState);
    }
    if (!isVisible && prevIsVisible) {
      setRootColumnState(null);
      setSelectedColumnValues([]);
    }
  }, [isVisible, searchFieldsData, columnState, prevIsVisible]);

  const saveChanges = () => {
    if (rootColumnState) {
      let newColumnStates: TTableColumnState = {};
      Object.keys(rootColumnState).forEach((key: string) => {
        newColumnStates = {...newColumnStates, [key]: {...columnState[key], visible: selectedColumnValues.includes(key)}};
      });
      dispatch(issuesSlice.actions.updateColumnVisiblity({columnState: newColumnStates, issuesNavType}));
    }
    toggleModal();
  };

  const checkboxOptions = useMemo(() => {
    if (rootColumnState) {
      return Object.keys(rootColumnState).map((key: string) => {
        const {label, userControl} = rootColumnState[key];
        return {
          label,
          value: key,
          disabled: !userControl,
        };
      });
    }
    return [];
  }, [rootColumnState]);

  const onSelectChange = (values: Array<string>) => setSelectedColumnValues(values);

  return (
    <ColumnEditingModal
      checkboxOptions={checkboxOptions}
      isVisible={isVisible}
      onSaveChange={saveChanges}
      selectedValues={selectedColumnValues}
      onSelectChange={onSelectChange}
      toggleModal={toggleModal}
    />
  );
};

export default ColumnsModal;
