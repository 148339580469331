import React, {ComponentProps, useCallback, useState, useImperativeHandle, useMemo} from 'react';
import {Modal, ELEMENT_SIZE, Button, BUTTON_THEMES, htToast} from 'ht-styleguide';
import useMarkTaskCompleteMutation from '../../queries/mutation.markTaskComplete';
import useMarkTaskIncompleteMutation from '../../queries/mutation.markTaskIncomplete';
import {TTaskCompletionModalTypes, ITaskCompletionStatusModal, TModalData, TToggleTaskCompletionStatusModalParams} from './taskCompletionStatusModal.types';

/**
 * Modal for marking tasks as complete or incomplete.
 *
 * For marking tasks as complete, this modal can
 * - Mark a single task as complete
 * - Mark all tasks within a service as complete
 * - Mark all tasks within an order as complete
 *
 * For marking tasks as incomplete, this modal can
 * - Mark a single task as incomplete
 */
const TaskCompletionStatusModal = ({checklistsForOrder, modalRef, dataTestId = 'TaskCompletionStatusModal'}: ITaskCompletionStatusModal) => {
  const [showModalByType, setShowModalByType] = useState<TTaskCompletionModalTypes | null>(null);
  const [modalData, setModalData] = useState<TModalData>({serviceId: null, taskId: null});

  // Mutations
  const markTaskCompleteMutation = useMarkTaskCompleteMutation();
  const markTaskIncompleteMutation = useMarkTaskIncompleteMutation();

  /**
   * Expose a method to toggle the modal to the parent component
   */
  useImperativeHandle(
    modalRef,
    () => ({
      toggleTaskCompletionStatusModal: ({type, serviceId, taskId}: TToggleTaskCompletionStatusModalParams) => {
        if (type) {
          setShowModalByType(type);
          setModalData({serviceId, taskId});
        } else {
          setShowModalByType(null);
          setModalData({serviceId: null, taskId: null});
        }
      },
    }),
    []
  );

  /** Hide the modal as needed */
  const hideModal = useCallback(() => modalRef.current?.toggleTaskCompletionStatusModal({type: null}), [modalRef]);

  /**
   * Based on the params given, determine which tasks to mark
   */
  const taskIdsToMark = useMemo(() => {
    const {serviceId, taskId} = modalData;

    // If marking all checklists on the order
    if (!serviceId && !taskId) {
      return Object.keys(checklistsForOrder).reduce((acc, serviceIdKey) => {
        const taskIdsForService = checklistsForOrder[+serviceIdKey]?.data?.task_groups?.flatMap(({tasks}) => tasks.map(({id}) => id)) ?? [];
        return {
          ...acc,
          [serviceIdKey]: taskIdsForService,
        };
      }, {} as Record<number, number[]>);
    }

    // If marking all tasks within of a given service
    if (serviceId && !taskId) {
      const checklistDataForService = checklistsForOrder[serviceId]?.data;
      const taskIdsForService = checklistDataForService?.task_groups?.flatMap(({tasks}) => tasks.map(({id}) => id)) ?? [];
      return {
        [serviceId]: taskIdsForService,
      };
    }

    // If marking a single task within a service
    if (serviceId && taskId) {
      return {
        [serviceId]: [taskId],
      };
    }

    return {};
  }, [checklistsForOrder, modalData]);

  /**
   * Accumulate the number of tasks to mark
   */
  const numOfTasksToMark = useMemo(() => Object.values(taskIdsToMark).reduce((acc, taskIds) => acc + taskIds.length, 0), [taskIdsToMark]);

  /**
   * Show a toast after updating the task completion status
   */
  const showTaskUpdatedToast = useCallback(() => {
    switch (showModalByType) {
      case 'singleTaskComplete':
        htToast('Task marked as complete');
        break;
      case 'singleTaskIncomplete':
        htToast('Task marked as incomplete');
        break;
      case 'allTasksOnServiceComplete':
        htToast('Tasks marked as complete');
        break;
      case 'allTasksOnOrderComplete':
        htToast('All tasks marked as complete');
        break;
      default:
        break;
    }
  }, [showModalByType]);

  /**
   * Mark the tasks as complete or incomplete
   */
  const updateTaskCompletionStatus = useCallback(async () => {
    const mutation = showModalByType === 'singleTaskIncomplete' ? markTaskIncompleteMutation : markTaskCompleteMutation;
    const serviceIdAndTaskIds = Object.entries(taskIdsToMark);
    const resolved = await Promise.allSettled(serviceIdAndTaskIds.map(([serviceId, taskIds]) => mutation.mutateAsync({serviceId: +serviceId, taskIds})));
    if (resolved.every(({status}) => status === 'fulfilled')) {
      showTaskUpdatedToast();
    }
    hideModal();
  }, [hideModal, markTaskCompleteMutation, markTaskIncompleteMutation, showModalByType, showTaskUpdatedToast, taskIdsToMark]);

  /**
   * Modal props based on the type of modal to show
   */
  const modalProps = useMemo(() => {
    const props: Partial<ComponentProps<typeof Modal>> & Partial<{ctaButtonCopy: string; ctaButtonTheme: (typeof BUTTON_THEMES)[keyof typeof BUTTON_THEMES]; ctaButtonCB: () => void}> = {
      ctaButtonCopy: 'Mark as Complete',
      ctaButtonTheme: BUTTON_THEMES.PRIMARY,
      ctaButtonCB: updateTaskCompletionStatus,
    };

    switch (showModalByType) {
      case 'singleTaskComplete': {
        props.header = 'Mark the task as complete?';
        break;
      }
      case 'singleTaskIncomplete': {
        props.header = 'Mark the task as incomplete?';
        props.ctaButtonCopy = 'Mark as Incomplete';
        props.ctaButtonTheme = BUTTON_THEMES.DANGER_SECONDARY;
        break;
      }
      case 'allTasksOnServiceComplete': {
        props.header = `Mark all ${numOfTasksToMark} tasks of this service as complete?`;
        break;
      }
      case 'allTasksOnOrderComplete': {
        props.header = `Mark all ${numOfTasksToMark} tasks as complete?`;
        break;
      }
      default:
        break;
    }

    return props;
  }, [showModalByType, updateTaskCompletionStatus, numOfTasksToMark]);

  const shouldShowModal = !!showModalByType;
  const {ctaButtonCopy, ctaButtonTheme, ctaButtonCB, ...restModalProps} = modalProps;

  return (
    <Modal
      elementSize={ELEMENT_SIZE.MEDIUM}
      isVisible={shouldShowModal}
      hide={hideModal}
      {...restModalProps}
      footerElement2={
        <Button onClick={hideModal} theme={BUTTON_THEMES.SECONDARY}>
          Cancel
        </Button>
      }
      footerElement3={
        <Button onClick={ctaButtonCB} theme={ctaButtonTheme} dataTestId={`${dataTestId}-ConfirmButton`}>
          {ctaButtonCopy}
        </Button>
      }
    >
      <p className="p1 n900">
        {showModalByType === 'singleTaskIncomplete' ? (
          <>Please ensure that the on-site worker is aware of this update. They will not be able to complete the service unless all tasks are completed.</>
        ) : (
          <>
            Required fields should be filled out by the on-site worker before being able to complete a task. As admin, you can override this rule and mark the task complete, if absolutely needed.
            <br />
            <br />
            Please ensure that the on-site worker is aware of this update.
          </>
        )}
      </p>
    </Modal>
  );
};

export default TaskCompletionStatusModal;
