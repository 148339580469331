import {useQuery} from 'react-query';
import {login as loginSlice} from 'features/Login/login.ducks';
import useApi from 'hooks/useApi';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useSelector} from 'hooks/useAppSelector';
import {showErrorToast, logResponseError, stringifyQueryParams} from 'queries/query.utils';
import {TGetUnassignedStatsQueryResponse} from '../dispatch.types';
import {TDispatchUnassignedListParamsPerKey} from '../dispatch.constants';
import {DISPATCH_QUERY_KEYS} from './query.dispatch.keys';

const useGetDispatchUnassignedStats = ({filters}: {filters?: TDispatchUnassignedListParamsPerKey['statsFilters'] | null} = {}) => {
  const api = useApi();
  const dispatch = useAppDispatch();
  const user = useSelector(loginSlice.selectors.getUser);

  const createSearchQuery = () => {
    const {useCurrentUser} = filters || {};

    // Page level filter - user
    const userParams: {agent_id?: number} = {};
    if (useCurrentUser && user) {
      userParams.agent_id = user.id;
    }

    return stringifyQueryParams(userParams);
  };

  const urlSearchQuery = createSearchQuery();
  const queryKeys = [urlSearchQuery];

  const {data} = useQuery(DISPATCH_QUERY_KEYS.getUnassignedStats(queryKeys), async () => {
    const response: TGetUnassignedStatsQueryResponse = await api.dispatch.getUnassignedStats({search: urlSearchQuery});

    if (response.err) {
      showErrorToast(dispatch)(response.err);
      logResponseError('Unassigned orders - Stats')(response.err);
    }

    return response?.data;
  });

  const {stats} = data || {};

  return {
    data: stats,
  };
};

export default useGetDispatchUnassignedStats;
