import {Routes} from 'global/routes/routes';
import environment from 'global/environments';
import {makePath} from './pathBuilder';

/**
 * Define your paths here
 */
export const homePath = makePath(Routes.HOME());
export const loginPath = makePath(Routes.LOGIN());

/**
 * LEGACY ADMIN PATHS
 */
export const orderDetailsPath = (orderId: number) => `${environment.ADMIN_DASHBOARD}/orders/${orderId}`;
export const customerProfilePath = (clientId: number) => `${environment.ADMIN_DASHBOARD}/clients/${clientId}`;
export const orderFollowUpPath = (orderId: number) => `${environment.ADMIN_DASHBOARD}/orders/${orderId}`;
export const skuEditDetailsPath = (skuId: number | string) => `${environment.ADMIN_DASHBOARD}/skus/${skuId}/edit`;

/* BOOKING PATHS */
export const bookingPath = makePath(Routes.BOOKING());
export const orderConfirmationPath = makePath(Routes.BOOKING_ORDER_CONFIRMATION());
export const orderConfirmationBookingTypePath = makePath(Routes.BOOKING_ORDER_BOOKINGTYPE_CONFIRMATION());
export const bookingServicesSkuPath = makePath(Routes.BOOKING_SERVICES_ADD_SKU());
export const bookingServicesPath = makePath(Routes.BOOKING_SERVICES());
export const bookingCustomerInfoPath = makePath(Routes.BOOKING_CUSTOMER_INFO());
export const orderMembershipConfirmation = makePath(Routes.BOOKING_ORDER_MEMBERSHIP_CONFIRMATION());
export const bookingPreFill = (clientId: number) => `${Routes.BOOKING_SERVICES()}?client_id=${clientId}`;

/* Admin Pane MDU Routes */
export const mduProjectPagePath = makePath(Routes.MDU_PROJECT_PAGE());
export const mduProjectPathByStage = makePath(Routes.MDU_PROJECT_PAGE_BY_STAGE());
export const mduProjectsPagePath = makePath(Routes.MDU_PROJECTS_PAGE());
export const mduProjectGroupPagePath = makePath(Routes.MDU_PROJECTS_PAGE_GROUP());
export const mduProjectPageGroupServicesPath = makePath(Routes.MDU_PROJECTS_PAGE_GROUP_SERVICES());
export const mduProjectPageGroupServicesSkuPath = makePath(Routes.MDU_PROJECTS_PAGE_GROUP_SERVICES_SKU());
export const mduProjectPageGroupServicesSkuEditPath = makePath(Routes.MDU_PROJECTS_PAGE_GROUP_SERVICES_SKU_EDIT());

// post launch specific
export const mduProjectPagePostLaunchGroupServicesPath = makePath(Routes.MDU_PROJECTS_PAGE_POSTLAUNCH_GROUP_SERVICES());
export const mduProjectPagePostLaunchGroupServicesSkuPath = makePath(Routes.MDU_PROJECTS_PAGE_POSTLAUNCH_GROUP_SERVICES_SKU());
export const mduProjectPagePostLaunchGroupServicesSkuEditPath = makePath(Routes.MDU_PROJECTS_PAGE_POSTLAUNCH_GROUP_SERVICES_SKU_EDIT());

/* --------- MDU: Bulk Job Create Paths -------- */
export const mduProjectBulkJobCreatePath = makePath(Routes.MDU_PROJECTS_PAGE_JOB_BULK_GROUP());
export const mduProjectBulkJobCreatePathSku = makePath(Routes.MDU_PROJECTS_PAGE_JOB_BULK_GROUP_SKU());
export const mduProjectBulkJobCreate = makePath(Routes.MDU_PROJECTS_PAGE_JOB_BULK_BASE());
export const mduProjectBulkJobCreateExisting = makePath(Routes.MDU_PROJECTS_PAGE_JOB_BULK_BASE_EXISTING());
export const mduProjectBulkJobCreateProcessing = makePath(Routes.MDU_PROJECTS_PAGE_JOB_BULK_BASE_PROCESSING());
export const mduProjectBulkJobServicesSkuEditPath = makePath(Routes.MDU_PROJECTS_PAGE_JOB_BULK_SERVICES_SKU_EDIT());
/* -------- MDU: Single Job Create Paths --------- */
export const mduProjectSingleJobCreate = makePath(Routes.MDU_PROJECTS_PAGE_JOB_SINGLE_BASE());
export const mduProjectSingleJobCreatePathSku = makePath(Routes.MDU_PROJECTS_PAGE_JOB_SINGLE_GROUP_SKU());
export const mduProjectSingleJobServicesSkuEditPath = makePath(Routes.MDU_PROJECTS_PAGE_JOB_SINGLE_SERVICES_SKU_EDIT());
/* ------------- TEMPLATES & JOBS ----------------- */
export const mduProjectTemplateJobNewCreatePath = makePath(Routes.MDU_PROJECTS_PAGE_JOB_BULK_GROUP());

/* ------------- Issues ----------------- */
export const issuesNavTypePath = makePath(Routes.ISSUES_NAV_TYPE());

/* ------------- Dispatch ----------------- */
export const dispatchNavTypePath = makePath(Routes.DISPATCH_NAV_TYPE());

/* ------------- Orders ----------------- */
export const orderTaskChecklistByServicePath = makePath(Routes.ORDERS_TASK_CHECKLIST_BY_SERVICE_ID());
