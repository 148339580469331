import {IHash} from 'types/base.types';

const BASE_ISSUES = 'issues';
export const BASE_ISSUES_QUERY_KEY = [BASE_ISSUES];

/* Key Extensions */
export const types = 'types';
export const templates = 'templates';
export const templateFields = 'templateFields';
export const categories = 'categories';
export const tags = 'tags';
export const users = 'users';
export const notes = 'notes';
export const tickets = 'tickets';
export const ticket = 'ticket';
export const ticketFieldsWithValues = 'ticketFieldsWithValues';
export const dynamicIssueField = 'dynamicIssueField';
export const getIssues = 'getIssues';
export const getStats = 'getStats';

export const ISSUES_QUERY_KEYS = {
  getIssues: (keys: any[]) => [...BASE_ISSUES_QUERY_KEY, getIssues, ...keys],
  getTicketsGeneric: (keys: any[]) => [...BASE_ISSUES_QUERY_KEY, 'getTicketsGeneric', ...keys],
  getStats: (keys: any[]) => [...BASE_ISSUES_QUERY_KEY, getStats, ...keys],
  types: (searchTerm = '') => [...BASE_ISSUES_QUERY_KEY, types, searchTerm],
  categories: (searchTerm = '') => [...BASE_ISSUES_QUERY_KEY, categories, searchTerm],
  templates: (params?: object) => [...BASE_ISSUES_QUERY_KEY, templates, params],
  templateFields: (template_id: number) => [...BASE_ISSUES_QUERY_KEY, templateFields, template_id],
  tags: (searchTerm = '') => [...BASE_ISSUES_QUERY_KEY, tags, searchTerm],
  users: (searchTerm = '', full = true, typesArray: Array<string> = []) => [...BASE_ISSUES_QUERY_KEY, users, searchTerm, full, typesArray],
  getNotes: (id: number) => [...BASE_ISSUES_QUERY_KEY, notes, id],
  getTickets: (params: IHash<string> = {}) => [...BASE_ISSUES_QUERY_KEY, tickets, params],
  getTicket: (id: number) => [...BASE_ISSUES_QUERY_KEY, ticket, id],
  getSearchFields: (keys: any[] = []) => [...BASE_ISSUES_QUERY_KEY, 'searchFields', ...keys],
  getTicketWithValues: (id: number) => [...BASE_ISSUES_QUERY_KEY, ticketFieldsWithValues, id],
  dynamicIssueTicketFields: (name: string, id: number | string) => [...BASE_ISSUES_QUERY_KEY, dynamicIssueField, name, id],
  getStatuses: () => [...BASE_ISSUES_QUERY_KEY, 'statuses'],
  getEntityTypes: () => [...BASE_ISSUES_QUERY_KEY, 'entityTypes'],
};
