import React, {ReactNode} from 'react';
import cn from 'classnames';
import styles from './pageHeaderMetaGrid.styles.scss';

interface IPageHeaderMetaGrid {
  children: ReactNode;
  classes?: string;
}

const PageHeaderMetaGrid = ({children, classes = ''}: IPageHeaderMetaGrid) => {
  return <div className={cn(styles.container, classes)}>{children}</div>;
};

interface PageHeaderMetaGridRow {
  data: Array<{
    label: string;
    value: ReactNode;
  }>;
}

PageHeaderMetaGrid.Row = ({data = []}: PageHeaderMetaGridRow) => {
  const gridStyles = {
    gridTemplateColumns: `repeat(${data.length}, 1fr)`,
  };
  return (
    <div className={styles.row} style={gridStyles}>
      {data.map(props => {
        return (
          <div className={styles.item} key={props.label}>
            <p className="overline-small n700 marginBottom-tiny">{props.label}</p>
            <div className="caption n800">{props.value}</div>
          </div>
        );
      })}
    </div>
  );
};

/**
 * Example
 *
 *  <PageHeaderMetaGrid>
 *    <PageHeaderMetaGrid.Row data={metaDataItems1} />
 *    <PageHeaderMetaGrid.Row data={metaDataItems2} />
 *  </PageHeaderMetaGrid>
 */

export default PageHeaderMetaGrid;
