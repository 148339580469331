import {CUSTOM_DROPDOWN_OPTIONS} from './deviceSelector.constants';
import {IDeviceSelectorProps, TMakeOrModelOptions} from './deviceSelector.types';

export const getMakeOptions = (deviceList: IDeviceSelectorProps['deviceList']) => {
  const options: TMakeOrModelOptions = [];

  const deviceOptions =
    deviceList?.map(({id, name}) => ({
      value: id,
      label: name,
    })) || [];

  return options.concat(deviceOptions).concat([CUSTOM_DROPDOWN_OPTIONS.OTHER, CUSTOM_DROPDOWN_OPTIONS.I_DONT_KNOW]);
};

export const getModelOptions = (deviceList: IDeviceSelectorProps['deviceList'], makeValue: number | string | undefined | null) => {
  if (makeValue === CUSTOM_DROPDOWN_OPTIONS.I_DONT_KNOW.value) {
    return [CUSTOM_DROPDOWN_OPTIONS.I_DONT_KNOW] as TMakeOrModelOptions;
  }
  if (typeof makeValue === 'number') {
    const selectedMakeSet = deviceList?.find(({id}) => id === makeValue);
    if (selectedMakeSet) {
      const modelList: TMakeOrModelOptions =
        selectedMakeSet.products.map(({id, name}) => ({
          value: id,
          label: name,
        })) || [];
      return modelList.concat([CUSTOM_DROPDOWN_OPTIONS.OTHER, CUSTOM_DROPDOWN_OPTIONS.I_DONT_KNOW]);
    }
  }
  return [];
};
