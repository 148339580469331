import React, {useState, useEffect} from 'react';
import cn from 'classnames';
import {Label, Panel, ErrorMessage} from 'ht-styleguide';
import {HTDropzone} from 'components/Elements/HTDropzone';
import {ThumbnailDisplay} from 'components/Elements/ThumbnailDisplay';
import useCloudinaryHooks from 'features/Cloudinary/hooks/useCloudinary';
import {MaybeAttachments, TFileSignCloudinaryResponse, TGenericCloudinaryResponse} from 'features/Cloudinary/cloudinary.types';
import {FILE_UPLOAD_VALIDATIONS} from 'utils/files/constants';
import {sortByMostRecentCloudinaryResponse} from 'features/Cloudinary/cloudinary.utils';
import {TThumbnailFile} from 'components/Elements/ThumbnailDisplay/thumbnail.types';
import {THTDropzoneOnDropRejectedFunction, THTDropzoneOnDropFunction, THTDropzoneValidator} from 'components/Elements/HTDropzone/HTDropzone.types';
import {noop} from 'utils/event';
import styles from './cloudinaryAttachmentsField.styles.scss';
import {CLOUDINARY_TRANSFORM_FILE_TYPES} from '../../cloudinary.constants';

const DEFAULT_MAX_FILE_SIZE = {
  maxSizeInBytes: FILE_UPLOAD_VALIDATIONS.MAX_FILE_SIZE,
  maxSizeDisplayString: FILE_UPLOAD_VALIDATIONS.READABLE_MAX_FILE_SIZE,
};

type TCloudinaryAttachmentsField = {
  label?: string | null | undefined;
  error?: string | null | undefined;
  withBold?: boolean;
  withCount?: boolean;
  withLightbox?: boolean;
  withDropzone?: boolean;
  namespace?: string;
  showDownloadButton?: boolean;
  showDeleteButton?: boolean;
  suppressNameplate?: boolean;
  preseedAttachments?: MaybeAttachments;
  /**
   * If true, when `preseedAttachments` changes, reset the cloudinary hook's and redux's state to the
   * new `preseedAttachments`. Will not delete from Cloudinary itself.
   * Defaults to false. When false, a change in `preseedAttachments` will append to the existing states.
   */
  overrideStateOnPreseedChange?: boolean;
  /**
   * If true, the Cloudinary asset will be deleted when the file is removed from the carousel
   * via the delete button. Defaults to true. Otherwise, the asset will be removed from local states but
   * will remain in Cloudinary.
   */
  deleteCloudinaryAssetOnSingleRemove?: boolean;
  /**
   * Mirrors `HTDropzone`'s `maxFileSize` prop.
   */
  maxFileSize?: {
    /** The maximum file size in bytes */
    maxSizeInBytes: number;
    /** The maximum file size as a string to be displayed to the user */
    maxSizeDisplayString: string;
  };
  /** Validator function passed to react-dropzone */
  validator?: THTDropzoneValidator;
  /** Callback fired when files are rejected by the dropzone validator */
  onDropRejected?: THTDropzoneOnDropRejectedFunction;
  /* What folder we want the assets to go into */
  folder?: string;
};

const CloudinaryAttachmentsField = ({
  label,
  error,
  withBold = false,
  withCount = false,
  withLightbox = false,
  withDropzone = true,
  namespace = '',
  showDownloadButton = false,
  showDeleteButton = true,
  suppressNameplate = false,
  preseedAttachments = null,
  overrideStateOnPreseedChange = false,
  deleteCloudinaryAssetOnSingleRemove = true,
  maxFileSize = DEFAULT_MAX_FILE_SIZE,
  validator,
  onDropRejected = noop,
  folder,
}: TCloudinaryAttachmentsField) => {
  /* Local State: Hold the transformed files */
  const [files, setFiles] = useState<TThumbnailFile[]>([]);

  /* Hooks */
  const {uploadFiles, uploadedSuccessFiles, handleDeleteFile, totalSuccessFilesCount, handleTransformVideo} = useCloudinaryHooks({
    folder,
    namespace,
    preseedAttachments,
    overrideStateOnPreseedChange,
    maxFileSize,
    deleteCloudinaryAssetOnSingleRemove,
  });

  /* Constants & Methods */

  /**
   * Determine the file type and return the transformed image
   * Note: This is needed when an action is usually a view or edit type of scenario.
   *       When we "add" during an upload, we get back pertinent data during the creation
   *       of the Cloudinary asset & interactions. But when "viewing/edit" or just borrowing
   *       methods from the cloudinary hook, we don't get that pipeline of the asset creation.
   *       So, this method is needed to determine the file type and return the transformed image/thumbnail
   *       of a video asset. This is useful if we ever use this component in a view/edit scenario.
   *
   * @param file
   */
  const determineFileTypeAsset = async (file: TGenericCloudinaryResponse) => {
    if (file.resource_type === CLOUDINARY_TRANSFORM_FILE_TYPES.IMAGE && file.format !== 'pdf') {
      return file.transformedImage;
    }

    /* Handle Video Transformations */
    if (file.resource_type === CLOUDINARY_TRANSFORM_FILE_TYPES.VIDEO) {
      /* Video is already transformed */
      if (file?.transformedImage?.includes('f_jpg')) {
        return file.transformedImage;
      }
      /* Let's get the transformed or cached tranformed image */
      const transformedVideo = await handleTransformVideo(file as TFileSignCloudinaryResponse, true);
      return transformedVideo;
    }

    return '';
  };

  useEffect(() => {
    const processFiles = async () => {
      const processedFiles = await Promise.all(
        (uploadedSuccessFiles ?? []).sort(sortByMostRecentCloudinaryResponse).map(async (file: TGenericCloudinaryResponse): Promise<TThumbnailFile> => {
          const transformedFile = await determineFileTypeAsset(file);
          return {
            name: file.original_filename,
            file_type: file.format?.toUpperCase(),
            showDownloadButton,
            showDeleteButton,
            suppressNameplate,
            onDeleteClick: handleDeleteFile,
            transformedFile,
            onDownloadClick: () => {
              window.open(file.url, '_blank');
            },
            ...file,
          };
        })
      );
      setFiles(processedFiles);
    };

    // Trigger file processing on component mount/update
    processFiles();
  }, [uploadedSuccessFiles]);

  /* Some redraws are inconsistent in Thumbnails. Lets make sure we draw if a change happens */
  const thumbnailDisplayKeys = files.map(file => file.url).join('');
  const onDrop: THTDropzoneOnDropFunction = async (
    /** Files that pass react-dropzone validation */
    newFiles
  ) => {
    await uploadFiles(newFiles, true);
  };

  const LabelComponent = () => {
    const className = cn({
      'label-input-small font-weight-bold': withBold,
    });

    const WithCount = () => {
      return withCount ? <span>({files.length})</span> : null;
    };

    return label ? (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label className={className}>
        {label} <WithCount />
      </label>
    ) : null;
  };

  return (
    <>
      <Label labelComponent={<LabelComponent />} />
      <ThumbnailDisplay withLightbox={withLightbox} key={thumbnailDisplayKeys} files={files} totalSuccessFilesCount={totalSuccessFilesCount} className="marginBottom-tiny" />
      {withDropzone && (
        <HTDropzone noClick={false} onDrop={onDrop} maxFileSize={maxFileSize} validator={validator} onDropRejected={onDropRejected}>
          <Panel className={cn(styles.dragDropField, 'n800 text-center p2 marginTop-small')} noShadow>
            Drag & Drop or <span className={styles.underline}>browse files</span>
          </Panel>
        </HTDropzone>
      )}
      <ErrorMessage error={error} />
    </>
  );
};

export default CloudinaryAttachmentsField;
