import {NavigateFunction} from 'react-router-dom';
import {CurrentProjectStages, ProjectDetailsShallow} from 'features/MultiDwellingUnits/MDU.types';
import {isPostLaunchStatus, isPreLaunchStatus} from 'features/MultiDwellingUnits/MDU.utils';
import {mduProjectPathByStage} from 'global/paths';

/**
 * Navigates to the appropriate project page tab based on the project's status.
 */
export const routeToProjectPage = ({navigate, project}: {navigate: NavigateFunction; project: ProjectDetailsShallow}) => {
  if (isPreLaunchStatus(project.status)) {
    navigate(mduProjectPathByStage(String(project.id), CurrentProjectStages.CHECKLIST));
  }
  if (isPostLaunchStatus(project.status)) {
    navigate(mduProjectPathByStage(String(project.id), CurrentProjectStages.PROJECT_OVERVIEW));
  }
};
