import React, {ComponentProps, useCallback, useState, useEffect} from 'react';
import {useFormik} from 'formik';
import omit from 'lodash/omit';
import {SideSheet, TSideSheetCustomHeaderProps} from 'ht-styleguide';
import HeaderWithToolbar from 'components/SideSheets/HeaderWithToolbar';
import {HEADER_TOOLBAR_CLOSE_ICON_PRESET} from 'components/SideSheets/HeaderWithToolbar/HeaderWithToolbar.constants';
import ActionBarGroup from 'components/Elements/ActionBarGroup';
import {HELLOTECH_PARTNER_ID} from 'components/FilterFormFields/DataSelect/dataSelect.constants';
import {login} from 'features/Login/login.ducks';
import useCreateCreditMutation from 'features/StoreCredits/queries/mutation.storeCredits.createCredit';
import {TCreateCreditPayload, TCreateStoreCredit} from 'features/StoreCredits/storeCredits.types';
import {useSelector} from 'hooks/useAppSelector';
import usePrevious from 'hooks/usePrevious';
import CreateForm from './CreateCreditSideSheet.CreateForm';
import FormSubmitted from './CreateCreditSideSheet.FormSubmitted';
import {FORM_SCHEMA, FORM_INITIAL_VALUES} from './createCreditSideSheet.constants';
import {useCreateCreditSideSheetControl} from './createCreditSideSheet.hooks';
import {TCreateFormValues, CreateCreditSideSheetFormFields} from './createCreditSideSheet.types';
import {formatDateForRequest, formatDollarToCent} from './createCreditSideSheet.utils';

const getCreateCreditSideSheetHeader =
  ({hide, formSubmissionSuccessful}: {hide: () => void; formSubmissionSuccessful: boolean}) =>
  (props: TSideSheetCustomHeaderProps) => {
    const getRightContent = useCallback(() => {
      return <ActionBarGroup size="lg" actions={[{...HEADER_TOOLBAR_CLOSE_ICON_PRESET, action: hide}]} />;
    }, []);

    const toolbarProps: ComponentProps<typeof HeaderWithToolbar> = formSubmissionSuccessful
      ? {
          headerText: '',
          hide,
        }
      : {
          headerText: 'Create a store credit',
          hide,
          isHeaderTextEditable: false,
        };

    // Use `key` to trigger a re-render when `formSubmissionSuccessful` changes
    return <HeaderWithToolbar key={toolbarProps.headerText} {...props} {...toolbarProps} RightContent={getRightContent} />;
  };

/**
 * CreateCreditSideSheet
 */
const CreateCreditSideSheet = () => {
  const {closeSideSheet, sideSheetIsOpen} = useCreateCreditSideSheetControl();
  const [formSubmissionSuccessful, setFormSubmissionSuccessful] = useState(false);
  const [newCreditData, setNewCreditData] = useState<TCreateStoreCredit>();
  const currentUser = useSelector(login.selectors.getUser);

  const {mutateAsync} = useCreateCreditMutation();

  const createFormFormik = useFormik<TCreateFormValues>({
    initialValues: FORM_INITIAL_VALUES,
    validationSchema: FORM_SCHEMA,
    onSubmit: async values => {
      if (!currentUser) return null;
      const cleanedValues = omit(values, [CreateCreditSideSheetFormFields.SkuOrPlanSelected]);
      const payload: TCreateCreditPayload = {
        ...cleanedValues,
        created_by_id: currentUser.id,
        expires_at: formatDateForRequest(values.expiration_date),
        partner_id: values.partner_id === HELLOTECH_PARTNER_ID ? null : values.partner_id,
        amount: formatDollarToCent(values.amount),
      };
      try {
        const message = await mutateAsync(payload);
        setNewCreditData(message.store_credit);
        setFormSubmissionSuccessful(true);
      } catch {
        // Mutation function will handle
      }
      return null;
    },
  });

  const previousSideSheetIsOpen = usePrevious(sideSheetIsOpen);
  useEffect(() => {
    if (!sideSheetIsOpen && previousSideSheetIsOpen) {
      setFormSubmissionSuccessful(false);
      setNewCreditData(undefined);
      createFormFormik.resetForm();
    }
  }, [sideSheetIsOpen, previousSideSheetIsOpen, createFormFormik]);

  const {isValid} = createFormFormik;
  const disabled = !isValid;

  const sideSheetProps: Partial<ComponentProps<typeof SideSheet>> = formSubmissionSuccessful
    ? {showFooter: false}
    : {ctaButtonText: 'Generate Credit', onCtaButtonClick: createFormFormik.handleSubmit, ctaButtonProps: {disabled, huge: true}};

  return (
    <SideSheet headerText="" headerComponent={getCreateCreditSideSheetHeader({hide: closeSideSheet, formSubmissionSuccessful})} hide={closeSideSheet} isOpen={sideSheetIsOpen} {...sideSheetProps}>
      {!formSubmissionSuccessful ? <CreateForm formik={createFormFormik} /> : <FormSubmitted newCreditData={newCreditData} />}
    </SideSheet>
  );
};

export default CreateCreditSideSheet;
