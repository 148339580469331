import React from 'react';
import ReactTooltip from 'react-tooltip';
import {isPreLaunchStatus} from 'features/MultiDwellingUnits/MDU.utils';
import {MDUStatuses} from 'features/MultiDwellingUnits/MDU.types';
import StatsByProject from './MDUProjectsTable.Stats';

const StatsTooltip = () => {
  return (
    <ReactTooltip
      effect="float"
      backgroundColor="white"
      offset={{right: 20, top: -40}}
      getContent={joinedString => {
        const [status, id, propertyOwner] = (joinedString ?? '').split('|');
        /* Only post launch wants tooltip */
        if (isPreLaunchStatus(status as MDUStatuses)) return null;

        return <StatsByProject id={id} propertyOwner={propertyOwner} />;
      }}
      id="overridePosition"
      overridePosition={({left, top}, currentEvent, currentTarget, node) => {
        const d = document.documentElement;
        const left1 = Math.min(d.clientWidth - node!.clientWidth, left);
        const top1 = Math.min(d.clientHeight - node!.clientHeight, top);

        const left2 = Math.max(0, left1) + 210;
        let top2 = Math.max(0, top1) + 150;

        if (window.innerHeight - (currentTarget as HTMLElement).getBoundingClientRect().bottom < 150) {
          top2 = top - 40;
        }
        return {top: top2, left: left2};
      }}
    />
  );
};

export default StatsTooltip;
