import {useQuery} from 'react-query';
import useApi from 'hooks/useApi';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useSelector} from 'hooks/useAppSelector';
import {showErrorToast, logResponseError, stringifyQueryParams} from 'queries/query.utils';
import {TGetIssuesTableSearchFieldsDataResponse, IssuesSearchFieldNames} from '../issues.types';
import issuesSlice from '../Issues.ducks';
import useIssuesRouteParams from '../hooks/useIssuesRouteParams';
import {ISSUES_QUERY_KEYS} from './Issues.query.keys';

/**
 * Used to fetch issues search fields. This is mainly used to present columns in the issues table view
 * and UI related to the overall page. This is NOT used for adding/editing issues.
 *
 * https://hellotech.atlassian.net/wiki/spaces/ENGINEERIN/pages/1670905857/API+Docs+with+dynamic+fields+-+Issue+Ticketing+System#List-ticket-fields-for-search
 */
const useIssuesTableSearchFieldsQuery = () => {
  const dispatch = useAppDispatch();
  const api = useApi();

  const {issuesNavType, pageFilters, issuesEntity, omitFilterKeys} = useIssuesRouteParams();

  const {filters} = useSelector(issuesSlice.selectors.getPagesStateByKey(issuesNavType)) || {};

  const normalizeToParams = () => {
    // atm, `entity_type` and `template_id` the only valid params for search fields
    const searchFieldFilters = Object.keys(filters).reduce((acc, key) => {
      if (key === IssuesSearchFieldNames.EntityType || key === IssuesSearchFieldNames.TemplateId) {
        return {...acc, [key]: filters[key]};
      }
      return acc;
    }, {});

    return stringifyQueryParams({...pageFilters, ...searchFieldFilters, entity_type: issuesEntity});
  };

  const urlSearchQuery = normalizeToParams();

  const queryKeys = [issuesNavType, urlSearchQuery];

  const {data} = useQuery(ISSUES_QUERY_KEYS.getSearchFields(queryKeys), async () => {
    const response: TGetIssuesTableSearchFieldsDataResponse = await api.issues.getSearchFields({search: urlSearchQuery});

    if (!response.err) {
      const fields = response.data?.fields || [];

      const filterdFields = fields.filter((field: any) => {
        const {search = {}, name} = field;
        if (omitFilterKeys?.includes(name)) {
          return false;
        }
        return search?.table_allowed;
      });
      const stringifiedFieldNames = filterdFields
        .map(field => field.name)
        .sort()
        .join('');

      dispatch(issuesSlice.actions.updateSearchFieldKeys({issuesNavType, searchFieldKeys: stringifiedFieldNames}));

      return {...response.data, fields: filterdFields};
    }

    showErrorToast(dispatch)(response.err);
    logResponseError('Issues search fields')(response.err);
    return response?.data;
  });

  const {fields = []} = data || {};

  return {
    data: fields,
    issuesNavType,
  };
};

export default useIssuesTableSearchFieldsQuery;
