import {useMemo} from 'react';
import isEmpty from 'lodash/isEmpty';
import isObjectLike from 'lodash/isObjectLike';
import {TUtilTableRawFiltersState, TUtilTableFiltersState} from './table.types';

/**
 * Intended to be used across tables of different features.
 * This assumes that the structure of `filters` and `rawFilters` are the same across different features.
 */
const useTableToolbarChips = ({filters, rawFilters, omitFilters = []}: {filters: TUtilTableFiltersState; rawFilters: TUtilTableRawFiltersState; omitFilters?: Array<string>}) => {
  /**
   * Of the applied filters, cross reference `rawFilters` to get the label for the filter value.
   */
  const filtersChips = useMemo(() => {
    return Object.keys(filters).reduce((ret, filterKey) => {
      const filterValue = filters[filterKey];

      if (['search', ...omitFilters].includes(filterKey)) return ret;
      // lots of conditionals due to the different types of filter values
      if (isObjectLike(filterValue) && isEmpty(filterValue)) return ret;
      if (typeof filterValue === 'string' && !filterValue) return ret;

      const rawFilter = rawFilters[filterKey];

      const labelPrefix = rawFilter ? rawFilter.label : filterKey;

      const valueCopy = (() => {
        if (Array.isArray(filterValue)) {
          return filterValue.map(val => rawFilter?.values?.find(selectedVal => selectedVal.value === val)?.label).join(', ');
        }
        if (typeof filterValue === 'object' && 'to' in filterValue) {
          return `${rawFilter?.values?.find(selectedVal => selectedVal.value === filterValue.from)?.label} - ${rawFilter?.values?.find(selectedVal => selectedVal.value === filterValue.to)?.label}`;
        }
        return rawFilter?.values?.find(selectedVal => selectedVal.value === filterValue)?.label;
      })();

      const chipLabel = `${labelPrefix}${valueCopy ? `: ${valueCopy}` : ''}`;

      return [...ret, {filterKey, label: chipLabel}];
    }, [] as Array<{filterKey: string; label: string}>);
  }, [filters, omitFilters, rawFilters]);

  const filtersCount = filtersChips.length;

  return {
    filtersChips,
    filtersCount,
  };
};

export default useTableToolbarChips;
