import {useSelector} from 'react-redux';
import {unwrapResult} from '@reduxjs/toolkit';
import get from 'lodash/get';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {mduProjectPathByStage, mduProjectsPagePath} from 'global/paths';
import {CurrentProjectStages, NavPageTypes, NavTypes} from 'features/MultiDwellingUnits/MDU.types';

import {useHookHelpersForInvalidation} from './projects.useHookHelpersForInvalidation';
import {useInvalidateQueriesPagination} from './projects.useInvalidateQueriesPagination';

/**
 * Both projects and currentProject use these behaviors. Just hook it out.
 *
 * These methods interface with the ducks file for operations. Once complete, invalidates the queries.
 * deleteProject
 * approveProject
 * onCloseActionItem
 */
export const useDeleteAndApproveWithInvalidate = ({redirectDelete}: {redirectDelete?: boolean} = {}) => {
  const {dispatch, navigate} = useHookHelpersForInvalidation();
  const {invalidatePaginatedQueries} = useInvalidateQueriesPagination();
  const parentLocation = useSelector(mduProjectsSlice.selectors.getParentLocation);

  const onCloseActionItem = () => {
    dispatch(mduProjectsSlice.actions.removeActionItemModalSlide());
  };

  /* ------------- Site Wide Actions: Projects ------------- */
  const deleteProjectAction = async () => {
    const response = await dispatch(mduProjectsSlice.actions.deleteProject({}));
    invalidatePaginatedQueries(response.type);
    onCloseActionItem();

    if (redirectDelete) {
      if (parentLocation) {
        navigate(parentLocation);
      } else {
        navigate(mduProjectsPagePath(NavTypes.ALL, NavPageTypes.ALL_STATUSES));
      }
    }
  };

  const approveProjectAction = async () => {
    const response = await dispatch(mduProjectsSlice.actions.approveProject({}));
    if (invalidatePaginatedQueries(response.type)) {
      const unwrappedResponse = unwrapResult(response);
      const id = get(unwrappedResponse, 'data.project.id');
      onCloseActionItem();
      navigate(mduProjectPathByStage(String(id), CurrentProjectStages.PROJECT_OVERVIEW));
    }
  };

  return {
    deleteProjectAction,
    approveProjectAction,
    onCloseActionItem,
  };
};
