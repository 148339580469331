import React from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import styles from './thumbnailDisplay.styles.scss';
import {TThumbnailTooltipProps} from './thumbnail.types';

/**
 * The ThumbnailTooltip component is meant to show a tooltip for a thumbnail.
 * It uses a React portal to "teleport" the tooltip to a different place in the DOM.
 * This is needed because the thumbnail is in a container with `overflow: hidden`,
 * so a normal <Tooltip /> would be cut off at the bottom edges of the thumbnail.
 * By using a portal, we can render the tooltip outside of the hidden overflow container,
 * ensuring it's fully visible.
 */

const ThumbnailTooltip: React.FC<TThumbnailTooltipProps> = ({children, visible, position, portalElement}) => {
  if (!visible || !portalElement || !position) return null;
  return ReactDOM.createPortal(
    <div
      style={{
        top: position.top,
        left: position.left,
      }}
      className={cn(styles.thumbnailTooltip, 'caption')}
    >
      {children}
    </div>,
    portalElement // the DOM element to attach the portal to
  );
};

export default ThumbnailTooltip;
