import React, {ReactNode} from 'react';
import cn from 'classnames';
import styles from './general.layout.styles.scss';

type GeneralLayout = {
  InnerContainer: React.FC<ChildrenProps>;
  Header: React.FC<ChildrenProps>;
  InnerPlainContainer: React.FC<ChildrenProps>;
};

type ChildrenProps = {
  children: ReactNode | ReactNode[];
  className?: string;
};

const GeneralLayout: React.FC<ChildrenProps> & GeneralLayout = ({children}) => {
  return <div className={styles.container}>{children}</div>;
};

const InnerContainer: React.FC<ChildrenProps> = ({children, className = ''}) => {
  const innerContainerStyles = cn(styles.innerContainer, className);
  return <div className={innerContainerStyles}>{children}</div>;
};

const Header: React.FC<ChildrenProps> = ({children, className = ''}) => <div className={cn(styles.headerBase, className)}>{children}</div>;

const InnerPlainContainer: React.FC<ChildrenProps> = ({children, className = ''}) => {
  const innerPlainContainerStyles = cn(styles.innerPlainContainer, className);

  return <div className={innerPlainContainerStyles}>{children}</div>;
};

GeneralLayout.InnerPlainContainer = InnerPlainContainer;
GeneralLayout.InnerContainer = InnerContainer;
GeneralLayout.Header = Header;

export default GeneralLayout;
