import React, {useEffect, useMemo, useState} from 'react';
import get from 'lodash/get';
import compact from 'lodash/compact';

import {SelectField, ELEMENT_SIZE} from 'ht-styleguide';

import {useMduApi} from 'features/MultiDwellingUnits/MDU.hooks';
import useDebounce from 'hooks/useDebounce';
import usePrevious from 'hooks/usePrevious';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useSelector} from 'hooks/useAppSelector';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';

import {logger} from 'utils/logger';

import {TRawFilterKeys} from 'features/MultiDwellingUnits/MDU.types';
import {FIELD_LABELS} from './FormFields.constants';
import {MduFormElementProps, SelectOption, TechSearchResult} from './FormFields.types';
import styles from './FormFields.styles.scss';

const TechLeadSelect = ({formik, label = FIELD_LABELS.leadTechId, fieldName = 'leadTechId', multiple = false, onSelectFieldchange}: MduFormElementProps & {onSelectFieldchange?: BaseAnyFunction}) => {
  const formikValue = get(formik.values, fieldName);
  const previousFormikValue = usePrevious(formikValue);
  const [techsList, setTechsList] = useState<SelectOption[]>([{value: '', label: 'Type to search', isDisabled: true}]); // List we get from BE
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [techSearchString, setTechSearchString] = useState(''); // Send this string to BE for search
  const [selectedTechDetails, setSelectedTechDetails] = useState<SelectOption | SelectOption[]>();
  const rawFilters = useSelector(mduProjectsSlice.selectors.getRawFilters);
  const techLeadRawFilters = rawFilters[fieldName as TRawFilterKeys];

  const mduApi = useMduApi();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Seed the select with formik value if available on first render
    if (!selectedTechDetails && formikValue) {
      /**
       * Since formikValue does not carry the label of its value, extract the label from raw filters.
       * `selectedTechDetails` is populated through formikvalue to know which option(s) should be preselected.
       * `techsList` is populated with the same options as `selectedTechDetails` to ensure the options are available in the dropdown as
       * the normal list of techs is only populated through search, which does not occur on first render.
       */
      const preSelectedValues = Array.isArray(formikValue) ? formikValue : [formikValue];
      const preSelectedOptions = compact(
        preSelectedValues.map((value: string) => {
          const rawFilterForValue = techLeadRawFilters[value];
          return rawFilterForValue ? {value, label: rawFilterForValue} : null;
        })
      );
      setSelectedTechDetails(multiple ? preSelectedOptions : preSelectedOptions[0]);
      setTechsList(preSelectedOptions);
    }
    // When formik is cleared from parent container, clear the selectedTechDetails
    if (!formikValue && previousFormikValue) {
      setSelectedTechDetails(undefined);
    }
  }, [formikValue, techsList, selectedTechDetails, previousFormikValue, multiple, techLeadRawFilters]);

  const debouncedTechSearchString = useDebounce(String(techSearchString), 800, {
    trailing: true,
    leading: false,
  });

  const previousTechSearchString = usePrevious(debouncedTechSearchString);
  const shouldNotSearch = useMemo(
    () => isSearching || !`${debouncedTechSearchString}`.trim() || previousTechSearchString === debouncedTechSearchString,
    [debouncedTechSearchString, isSearching, previousTechSearchString]
  );

  /* Tech Search  */
  useEffect(() => {
    if (shouldNotSearch) return;
    const fetchTechs = async () => {
      setIsSearching(true);
      try {
        const techs = await mduApi.searchTechs(debouncedTechSearchString);
        const formattedTechs = techs.map((elem: TechSearchResult) => ({
          value: elem.id,
          label: `${elem.name} #${elem.id}`,
        }));
        /**
         * In order to maintain the list of select techs in `multiple` mode, we need to manually add the list of
         * already selected techs to `techsList` so they will populate. When searching, the already selected techs may not
         * appear in the response.
         * Filter out selected techs that are already in list from the fetch and then merge.
         */
        const selectedTechOptions = selectedTechDetails
          ? (Array.isArray(selectedTechDetails) ? selectedTechDetails : [selectedTechDetails]).filter(
              (selectedTech: SelectOption) => !formattedTechs.find((tech: SelectOption) => tech.value === selectedTech.value)
            )
          : [];
        setTechsList([...formattedTechs, ...selectedTechOptions]);
      } catch (error) {
        // @ts-ignore
        logger('TechleadSelect')(error);
      } finally {
        setIsSearching(false);
      }
    };
    if (debouncedTechSearchString.length) fetchTechs();
    if (!debouncedTechSearchString.length) setTechsList([]);
  }, [isSearching, shouldNotSearch, debouncedTechSearchString, mduApi, selectedTechDetails]);

  const resetTechDetails = () => setSelectedTechDetails(undefined);

  const handleTechDetailsChange = (tech: SelectOption | SelectOption[] | null) => {
    if (!tech) {
      formik.handleChange({target: {name: fieldName, value: ''}});
      return resetTechDetails();
    }
    setSelectedTechDetails(tech);
    dispatch(mduProjectsSlice.actions.setRawFilterValues({[fieldName]: tech}));
    return formik.handleChange({
      target: {name: fieldName, value: Array.isArray(tech) ? tech.map(t => t.value) : tech.value},
    });
  };

  return (
    <SelectField
      id={fieldName}
      placeholder="Search by Name or Tech ID"
      options={techsList}
      onInputChange={setTechSearchString}
      label={label}
      multiple={multiple}
      value={selectedTechDetails}
      onChange={onSelectFieldchange || handleTechDetailsChange}
      elementSize={ELEMENT_SIZE.MEDIUM}
      clearable
      searchable
      error={formik.errors[fieldName]}
      reactSelectClassName={styles.placeholder}
      dataTestId={FIELD_LABELS.leadTechId}
    />
  );
};

export default TechLeadSelect;
