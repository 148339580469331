import React, {ComponentProps, useMemo, useCallback, useEffect} from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';
import {useLocation, useNavigate} from 'react-router-dom';
import {SideSheet, LoaderComponent} from 'ht-styleguide';
import DataTable from 'components/Elements/DataTable';
import {sortAlphabeticallyOrNumerically, sortByDate} from 'components/Elements/DataTable/utils/sort.utils';
import HeaderWithToolbar from 'components/SideSheets/HeaderWithToolbar';
import {RightContentCloseOnly} from 'components/SideSheets/HeaderWithToolbar/HeaderWithToolbar.Presets';
import {buildSideSheetQueryParam} from 'features/Issues/Issues.utils';
import {ISSUE_ENTITY_TYPES, ISSUE_SLIDESHEET_TYPES} from 'features/Issues/Issues.constants';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {ProjectPauseResponse} from 'features/MultiDwellingUnits/MDU.types';
import {useCurrentProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/CurrentProject.hooks';
import useGetProjectPauseHistory from 'features/MultiDwellingUnits/queries/query.project.pauseHistory';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useSelector} from 'hooks/useAppSelector';
import usePrevious from 'hooks/usePrevious';
import styles from './pauseHistorySideSheet.styles.scss';

const SideSheetHeader: ComponentProps<typeof SideSheet>['headerComponent'] = ({hide}) => (
  <HeaderWithToolbar headerText="Pause History" hide={hide} isHeaderTextEditable={false} RightContent={RightContentCloseOnly} />
);

const PauseHistorySideSheet = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const sideSheetKey = useSelector(mduProjectsSlice.selectors.getSideSheetKey);
  const currentProject = useCurrentProject();
  const {id: projectId} = currentProject;

  const sideSheetIsVisible = sideSheetKey === 'pauseHistory';

  const {data = [], isLoading, isFetched, refetch} = useGetProjectPauseHistory({projectId, queryOpts: {enabled: sideSheetIsVisible, refetchOnMount: 'always'}});

  const closeSideSheet = useCallback(() => dispatch(mduProjectsSlice.actions.setSideSheetKey(null)), [dispatch]);

  // Refetch data for the same project if the user closes and opens the sidesheet
  const previousSideSheetIsVisible = usePrevious(sideSheetIsVisible);
  useEffect(() => {
    if (isFetched && sideSheetIsVisible && !previousSideSheetIsVisible) {
      refetch();
    }
  }, [isFetched, refetch, sideSheetIsVisible, previousSideSheetIsVisible]);

  const tableColumnDefinitions = useMemo(() => {
    const openIssueSideSheet =
      ({issueId}: {issueId: number}) =>
      () =>
        navigate(`${location.pathname}?${buildSideSheetQueryParam({issueMode: ISSUE_SLIDESHEET_TYPES.issue_edit, entityType: ISSUE_ENTITY_TYPES.project, ticketId: issueId})}`);

    const columns: ComponentProps<typeof DataTable<ProjectPauseResponse>>['columns'] = [
      {
        header: 'Paused',
        id: 'started_at',
        accessorFn: ({started_at}) => dayjs(started_at).format('M/D/YY'),
        sortingFn: sortByDate,
        columnWidthMode: 'collapse',
      },
      {
        header: 'Resumed',
        id: 'ended_at',
        accessorFn: ({ended_at}) => (ended_at ? dayjs(ended_at).format('M/D/YY') : ''),
        sortingFn: sortByDate,
        columnWidthMode: 'collapse',
      },
      {
        header: 'Duration',
        id: 'days_paused',
        accessorFn: ({days_paused}) => `${days_paused} day${days_paused > 1 ? 's' : ''}`,
        sortingFn: sortAlphabeticallyOrNumerically,
        columnWidthMode: 'collapse',
      },
      {
        header: 'Issue',
        id: 'ticket.summary',
        accessorFn: ({ticket}) => ticket,
        sortingFn: sortAlphabeticallyOrNumerically,
        Cell: ({row}) => {
          const {ticket} = row.original;
          const {id: issueId, summary: issueSummary} = ticket || {};

          return issueId ? (
            <button type="button" className={cn('plainButton n900 text-underline', styles.issueLink)} onClick={openIssueSideSheet({issueId})}>
              {issueSummary}
            </button>
          ) : (
            <>--</>
          );
        },
      },
    ];

    return columns;
  }, [location.pathname, navigate]);

  return (
    <SideSheet headerComponent={SideSheetHeader} headerText="" hide={closeSideSheet} isOpen={sideSheetIsVisible} showFooter={false}>
      {isLoading ? <LoaderComponent containerClassName="marginTop-large" /> : <DataTable data={data} columns={tableColumnDefinitions} isZebra />}
    </SideSheet>
  );
};

export default PauseHistorySideSheet;
