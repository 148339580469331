import React, {ReactNode} from 'react';
import cn from 'classnames';
import {Modal, ELEMENT_SIZE, Button, BUTTON_THEMES} from 'ht-styleguide';
import styles from './filtersModal.styles.scss';

interface IFiltersModal {
  children: ReactNode;
  header?: string;
  isVisible: boolean;
  onApplyFilters: () => void;
  onClearAll: () => void;
  onModalClose?: () => void;
  toggleModal: () => void;
}

const FiltersModal = ({children, header = 'Apply Filters', isVisible, onApplyFilters, onClearAll, onModalClose, toggleModal}: IFiltersModal) => {
  const close = () => {
    onModalClose?.();
    toggleModal();
  };

  return (
    <Modal
      bodyContainerClasses={styles.modalClass}
      elementSize={ELEMENT_SIZE.L}
      header={header}
      hide={close}
      isVisible={isVisible}
      footerElement1={
        <div className={cn('flex align-items-center', styles.clearAllWrapper)}>
          <button type="button" className={cn('plainButton text-link medium-link', styles.clearAll)} onClick={onClearAll}>
            Clear All
          </button>
        </div>
      }
      footerElement2={
        <Button theme={BUTTON_THEMES.SECONDARY} onClick={close}>
          Cancel
        </Button>
      }
      footerElement3={<Button onClick={onApplyFilters}>Apply Filters</Button>}
    >
      {children}
    </Modal>
  );
};

export default FiltersModal;
