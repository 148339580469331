import React, {useState} from 'react';
import {Checkbox} from 'ht-styleguide';
import {FIELD_LABELS} from './FormFields.constants';
import {MduFormElementProps} from './FormFields.types';
import {FlaggedEnums} from '../../Pages/Projects/Parts/MDUProjectsFilters/MDUProjectsFilters.types';

/* Used in flagged checkbox. Units take a different format */
const FlaggedCheckboxUnit = ({formik, fieldName = 'only_flagged'}: MduFormElementProps) => {
  const formikValue = formik.values[fieldName];
  const seededValue = Boolean(formikValue);
  const [checkBoxValue, setCheckBoxValues] = useState<boolean>(seededValue);

  const handleCheckboxChange = (value: boolean) => {
    const toggledValue = !value;
    setCheckBoxValues(toggledValue);
    formik.handleChange({
      target: {name: fieldName, value: toggledValue ? FlaggedEnums.TRUE : ''},
    });
  };
  return (
    <div className="inline">
      <Checkbox checked={Boolean(checkBoxValue)} onCheckboxChange={handleCheckboxChange} key={FIELD_LABELS.flagged} value={checkBoxValue} label={FIELD_LABELS.flagged} />
    </div>
  );
};

export default FlaggedCheckboxUnit;
