import React, {ComponentProps, useMemo} from 'react';
import BaseFilterSelectField from '../../BaseFilter/SelectField';
import useGetPartnerBookingQuery from '../queries/query.dataSelect.getPartnerBooking';
import {TSharedFormFieldProps} from '../../dataFormFields.types';
import {HELLOTECH_PARTNER_ID, HELLOTECH_PARTNER_LABEL} from '../dataSelect.constants';

type TPartnerBookingSelect = TSharedFormFieldProps &
  Parameters<typeof useGetPartnerBookingQuery>['0'] &
  Partial<ComponentProps<typeof BaseFilterSelectField>> & {
    withHTOption?: boolean;
    htPartnerLabel?: string;
    htPartnerId?: number | string;
  };

/**
 * A select field component with pre-populated options of active partners.
 * Pass a param to filter by project-only partners.
 */
const PartnerBookingSelect = ({allowProjects, search, queryOpts, withHTOption = true, htPartnerLabel, htPartnerId, ...rest}: TPartnerBookingSelect) => {
  const {data, isFetching} = useGetPartnerBookingQuery({allowProjects, search, queryOpts});

  const partnerOpts = useMemo(() => {
    const basePartners = (data?.partners || []).map(partner => ({label: partner.companyName, value: partner.id}));
    return withHTOption ? [{label: htPartnerLabel || HELLOTECH_PARTNER_LABEL, value: htPartnerId || HELLOTECH_PARTNER_ID}, ...basePartners] : basePartners;
  }, [data?.partners, htPartnerId, htPartnerLabel, withHTOption]);

  const disabled = isFetching;

  return <BaseFilterSelectField isDisabled={disabled} options={partnerOpts} {...rest} />;
};

export default PartnerBookingSelect;
