import React, {ComponentProps, useState, useCallback, useEffect, useMemo, ChangeEvent} from 'react';
import {createFilter, FilterOptionOption} from 'react-select';
import {ELEMENT_SIZE, SelectField, InputField} from 'ht-styleguide';
import {CUSTOM_DROPDOWN_OPTIONS, DEVICE_SELECTOR_INITIAL_STATE} from './deviceSelector.constants';
import {IDeviceSelectorProps, TDeviceSelectBaseSelectOption, TMakeOrModelOptions} from './deviceSelector.types';
import {getMakeOptions, getModelOptions} from './deviceSelector.utils';

/**
 * A data-agnostic component that allows the user to select the make and model of a device.
 *
 * TODO: Convert <QuestionDevices /> to use this component.
 */
const DeviceSelector = ({
  BottomComponent,
  containerClassName = '',
  deviceList,
  elementSize = ELEMENT_SIZE.MEDIUM,
  elementSpacerClassName = 'marginTop-small',
  onValueChange,
  makeInputFieldProps = {},
  makeFieldErrorMessage,
  makeFieldLabel,
  makeSelectFieldPlaceholder = 'Select',
  makeSelectFieldProps = {},
  modelInputFieldProps = {},
  modelFieldErrorMessage,
  modelFieldLabel,
  modelSelectFieldPlaceholder = 'Select',
  modelSelectFieldProps = {},
  value,
}: IDeviceSelectorProps) => {
  const [state, setState] = useState<typeof DEVICE_SELECTOR_INITIAL_STATE>();
  const {isModelFieldDisabled, makeFilterInput, modelFilterInput, showMakeInput, showModelInput} = state || {};

  const {makeValue, makeText, modelValue, modelText} = value;

  // Set component state on first render. Also look at `value` to properly set
  useEffect(() => {
    const canSetInitialState = !state && deviceList && value;
    if (canSetInitialState) {
      const isMakeIDKOption = makeValue && (makeValue === CUSTOM_DROPDOWN_OPTIONS.I_DONT_KNOW.value || makeText === CUSTOM_DROPDOWN_OPTIONS.I_DONT_KNOW.label);
      const isMakeOtherOption = makeValue && (makeValue === CUSTOM_DROPDOWN_OPTIONS.OTHER.value || (!deviceList.find(({id}) => id === makeValue) && !isMakeIDKOption));

      const modelFound = deviceList.find(({id}) => id === makeValue)?.products?.find(({id}) => id === modelValue);
      const isModelIDKOption = modelValue && (modelValue === CUSTOM_DROPDOWN_OPTIONS.I_DONT_KNOW.value || modelText === CUSTOM_DROPDOWN_OPTIONS.I_DONT_KNOW.label);
      const isModelOtherOption = modelValue && (modelValue === CUSTOM_DROPDOWN_OPTIONS.OTHER.value || (!modelFound && !isModelIDKOption));

      setState({
        ...DEVICE_SELECTOR_INITIAL_STATE,
        ...(isMakeOtherOption && {
          showMakeInput: true,
          showModelInput: true,
          isModelFieldDisabled: false,
        }),
        ...(isMakeIDKOption && {
          isModelFieldDisabled: true,
        }),
        ...(isModelOtherOption && {
          showModelInput: true,
          isModelFieldDisabled: false,
        }),
      });
    }
  }, [deviceList, makeText, makeValue, modelSelectFieldPlaceholder, modelText, modelValue, state, value]);

  // Helper function to set the state
  const setNewState = (newState: Partial<typeof DEVICE_SELECTOR_INITIAL_STATE>) => {
    setState(prevState => ({
      ...(prevState || DEVICE_SELECTOR_INITIAL_STATE),
      ...newState,
    }));
  };

  // Filter the list of select options by user's search input
  const filterOption = createFilter({});
  /**
   * Use `react-select`'s `createFilter` to filter the options by the user's search input + use our own
   * custom logic to not include the custom options within the search results.
   */
  const filterSelectOptionsBySearch = useCallback(
    (searchInput: string, options: TMakeOrModelOptions) => {
      const filteredOptions = (options ?? []).filter(o => !(o.searchable === false) && filterOption({...o, data: {} as any} as FilterOptionOption<TMakeOrModelOptions[0]>, searchInput));
      const noOptions = filteredOptions.length === 0;
      if (noOptions) {
        filteredOptions.push(CUSTOM_DROPDOWN_OPTIONS.NO_OPTIONS);
      }
      return filteredOptions;
    },
    [filterOption]
  );

  /*
   *******************************************************
     Product Make related
   *******************************************************
   */

  // Create the make options for the dropdown
  const makeBaseSelectOptions = useMemo(() => getMakeOptions(deviceList), [deviceList]);
  const makeSelectOptions = useMemo(() => {
    return makeFilterInput ? filterSelectOptionsBySearch(makeFilterInput, makeBaseSelectOptions) : makeBaseSelectOptions;
  }, [filterSelectOptionsBySearch, makeFilterInput, makeBaseSelectOptions]);

  const onMakeDropdownChange = (selectedOpt: TDeviceSelectBaseSelectOption) => {
    const {value: selectedOptValue, label: selectedOptLabel} = selectedOpt || {};
    const isOtherOption = selectedOptValue === CUSTOM_DROPDOWN_OPTIONS.OTHER.value;
    const isIDKOption = selectedOptValue === CUSTOM_DROPDOWN_OPTIONS.I_DONT_KNOW.value;

    if (isOtherOption) {
      setNewState({
        showMakeInput: true,
        showModelInput: true,
        isModelFieldDisabled: true,
      });
    } else if (isIDKOption) {
      setNewState({
        showModelInput: false,
        isModelFieldDisabled: true,
      });
    } else {
      setNewState({
        showMakeInput: false,
        showModelInput: false,
        isModelFieldDisabled: false,
      });
    }

    onValueChange({
      makeValue: selectedOptValue,
      makeText: selectedOptLabel,
      modelValue: null,
      modelText: null,
      ...(isIDKOption
        ? {
            modelValue: CUSTOM_DROPDOWN_OPTIONS.I_DONT_KNOW.value,
            modelText: CUSTOM_DROPDOWN_OPTIONS.I_DONT_KNOW.label,
          }
        : {}),
    });
  };

  const onMakeDropdownSearchChange: ComponentProps<typeof SelectField>['onInputChange'] = searchInput => {
    setNewState({
      makeFilterInput: searchInput,
    });
  };

  const onMakeInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value: inputValue} = e.target;
    setNewState({
      isModelFieldDisabled: !inputValue,
    });
    onValueChange({
      makeValue: inputValue,
      makeText: inputValue,
      modelValue: null,
      modelText: null,
    });
  };

  const onMakeInputIconClick = () => {
    setNewState({
      showMakeInput: false,
      showModelInput: false,
      isModelFieldDisabled: true,
    });
    onValueChange({
      makeValue: null,
      makeText: null,
      modelValue: null,
      modelText: null,
    });
  };

  /**
   * Special considerations are needed. For the "I don't know" value, the value can be -1 or an id that represents
   * a known make in the DB. However, the list of devices will always include the "I don't know" option with the -1 value. So
   * here, we'll need to map the "Idk" value to the -1 value. Another possibility is to replace the "I don't know" option with
   * the value of the known id, but since the BE can handle the -1 value even when "I don't know" is saved to the DB, we'll
   * keep it as is.
   */
  const selectedMakeValue = useMemo(() => {
    if (makeText === CUSTOM_DROPDOWN_OPTIONS.I_DONT_KNOW.label) {
      return CUSTOM_DROPDOWN_OPTIONS.I_DONT_KNOW.value;
    }

    // If other, then return the text value as the input field should already be rendered
    const makeValueIsOther = makeValue && !makeBaseSelectOptions.find(({value: optValue}) => optValue === makeValue);
    if (makeValueIsOther) {
      return makeText;
    }

    return makeValue;
  }, [makeValue, makeText, makeBaseSelectOptions]);

  /*
  *******************************************************
    Product Model related
  *******************************************************
  */

  // Create model options
  const modelBaseSelectOptions = useMemo(() => getModelOptions(deviceList, selectedMakeValue), [selectedMakeValue, deviceList]);
  const modelSelectOptions = useMemo(() => {
    return modelFilterInput ? filterSelectOptionsBySearch(modelFilterInput, modelBaseSelectOptions) : modelBaseSelectOptions;
  }, [filterSelectOptionsBySearch, modelFilterInput, modelBaseSelectOptions]);

  const onModelDropdownChange = (selectedOpt: TDeviceSelectBaseSelectOption) => {
    const {value: selectedOptValue, label: selectedOptLabel} = selectedOpt || {};
    const isOtherOption = selectedOptValue === CUSTOM_DROPDOWN_OPTIONS.OTHER.value;

    if (isOtherOption) {
      setNewState({
        showModelInput: true,
        isModelFieldDisabled: false,
      });
    }

    onValueChange({
      ...value,
      modelValue: selectedOptValue,
      modelText: selectedOptLabel,
    });
  };

  const onModelDropdownSearchChange: ComponentProps<typeof SelectField>['onInputChange'] = searchInput => {
    setNewState({
      modelFilterInput: searchInput,
    });
  };

  const onModelInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value: inputValue} = e.target;
    onValueChange({
      ...value,
      modelValue: inputValue,
      modelText: inputValue,
    });
  };

  const onModelIconClick = () => {
    setNewState({
      showModelInput: false,
    });
    onValueChange({
      ...value,
      modelValue: null,
      modelText: null,
    });
  };

  const selectedModelValue = useMemo(() => {
    if (modelText === CUSTOM_DROPDOWN_OPTIONS.I_DONT_KNOW.label) {
      return CUSTOM_DROPDOWN_OPTIONS.I_DONT_KNOW.value;
    }

    const modelValueIsOther = modelValue && !modelBaseSelectOptions.find(({value: optValue}) => optValue === modelValue);
    if (modelValueIsOther) {
      return modelText;
    }

    return modelValue;
  }, [modelValue, modelText, modelBaseSelectOptions]);

  // Only clearable if make is known
  const modelInputIcon = showMakeInput ? undefined : 'v2-close-icon';

  return state ? (
    <div className={containerClassName}>
      <div>
        {showMakeInput ? (
          <InputField
            autoFocus={showMakeInput}
            elementSize={elementSize}
            error={makeFieldErrorMessage}
            iconName="v2-close-icon"
            iconOnClick={onMakeInputIconClick}
            label={makeFieldLabel}
            onChange={onMakeInputChange}
            type="text"
            value={(selectedMakeValue || '').toString()}
            {...makeInputFieldProps}
          />
        ) : (
          <SelectField
            clearable={false}
            elementSize={elementSize}
            error={makeFieldErrorMessage}
            filterOption={() => true}
            label={makeFieldLabel}
            onChange={onMakeDropdownChange}
            onInputChange={onMakeDropdownSearchChange}
            options={makeSelectOptions}
            placeholder={makeSelectFieldPlaceholder}
            searchable
            value={selectedMakeValue}
            {...makeSelectFieldProps}
          />
        )}
      </div>
      <div className={elementSpacerClassName}>
        {showModelInput ? (
          <InputField
            disabled={isModelFieldDisabled}
            elementSize={elementSize}
            error={modelFieldErrorMessage}
            iconName={modelInputIcon}
            iconOnClick={onModelIconClick}
            label={modelFieldLabel}
            onChange={onModelInputChange}
            type="text"
            value={(selectedModelValue || '').toString()}
            {...modelInputFieldProps}
          />
        ) : (
          <SelectField
            clearable={false}
            elementSize={elementSize}
            error={modelFieldErrorMessage}
            filterOption={() => true}
            isDisabled={isModelFieldDisabled}
            label={modelFieldLabel}
            onChange={onModelDropdownChange}
            onInputChange={onModelDropdownSearchChange}
            options={modelSelectOptions}
            placeholder={modelSelectFieldPlaceholder}
            searchable
            value={selectedModelValue}
            {...modelSelectFieldProps}
          />
        )}
      </div>
      {BottomComponent}
    </div>
  ) : null;
};

export default DeviceSelector;
