import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {matchSorter} from 'match-sorter';
import {l, shortMonthDayYear} from 'global/constants/common';

dayjs.extend(utc);

export const formatDateForRequest = (date: string) => {
  // l is used in the date picker
  return dayjs(date, l).format('YYYY/MM/DD');
};

// Date is in UTC, but we only want the date
export const formatDateForDisplay = (date: string) => {
  return dayjs.utc(date).format(shortMonthDayYear);
};

/**
 * Receives a string of a price in dollar format and returns the price in cent format
 * $79.88 -> 7988
 * @param price in dollars: 79.99; $79.99; $79; $1,899.81
 * @returns {number}
 */
export const formatDollarToCent = (price: string | null | undefined = '') => {
  const cleanPrice = !price ? '0' : price;
  const [dollar = '', cent = ''] = cleanPrice
    .toString()
    .split('.')
    .map(v => v.replace(/[^\d]/g, ''));
  return (+dollar > 0 ? +dollar * 100 : 0) + +cent.padEnd(2, '0');
};

export const searchTableDataByValue = <T extends {id: number; name: string}>(data: T[] = [], searchValue: string = '') => {
  return matchSorter(data, searchValue, {
    keys: [
      {threshold: matchSorter.rankings.CONTAINS, key: 'name'},
      {threshold: matchSorter.rankings.CONTAINS, key: 'id'},
    ],
    baseSort: (a, b) => {
      const {rankedValue: aRankedValue, rank: aRank} = a;
      const {rankedValue: bRankedValue, rank: bRank} = b;
      if (aRank === bRank) {
        return String(aRankedValue).localeCompare(bRankedValue);
      }
      return aRank >= bRank ? 1 : -1;
    },
  });
};
