import {useParams, useNavigate} from 'react-router-dom';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {SideSheet} from 'ht-styleguide';
import {mduProjectPageGroupServicesPath} from 'global/paths';

/* Ducks */
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';

/* Hooks */
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useVerifyQAForm} from 'features/MultiDwellingUnits/MDU.hooks';
import {useProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/TemplateJobs.hooks';

/* Components */
import AddSkuPage from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/AddSku/MDU.AddSku';

/* Constants / Types */
import {FULFILLED} from 'global/constants/common';
import {CurrentProjectStages, MduUseParamsTypes} from 'features/MultiDwellingUnits/MDU.types';

const SideSheetAddSku = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {groupId = '', skuId = '', stage = '', mode = '', serviceId = ''} = useParams<MduUseParamsTypes>();
  const {projectId, projectGroup} = useProject({groupId});
  const [errors, setErrors] = useState({});
  const verifyQaForm = useVerifyQAForm();
  const selectedSku = useSelector(mduProjectsSlice.selectors.getAddSkuSelectedSku);

  /* Constants */
  const isEditMode = mode === CurrentProjectStages.EDIT;
  const ctaTitle = `${isEditMode ? 'Edit' : 'Add'} Service`;
  const ctaButton = `${isEditMode ? 'Update' : 'Add'} Service`;

  /* Methods */
  const closeSideSheet = () => {
    navigate(mduProjectPageGroupServicesPath(projectId, stage, projectGroup.id));
  };

  const createService = async () => {
    const formErrors = verifyQaForm();

    if (Object.keys(formErrors).length) {
      setErrors(formErrors);
      return;
    }

    setErrors({});
    const service = await dispatch(mduProjectsSlice.actions.createService({projectId, groupId, selectedSku}));
    if (service.type.endsWith(FULFILLED)) {
      closeSideSheet();
    }
  };

  const editService = async () => {
    const formErrors = verifyQaForm();

    if (Object.keys(formErrors).length) {
      setErrors(formErrors);
      return;
    }

    setErrors({});
    const projectService = {id: serviceId, questions: selectedSku.questions, skuId};
    const updatedService = await dispatch(mduProjectsSlice.actions.updateService({projectId, groupId, serviceId, projectService}));
    if (updatedService.type.endsWith(FULFILLED)) {
      closeSideSheet();
    }
  };

  const actionFunction = isEditMode ? editService : createService;

  return (
    <SideSheet isOpen={Boolean(skuId)} hide={closeSideSheet} onRequestClose={closeSideSheet} headerText={ctaTitle} ctaButtonText={ctaButton} onCtaButtonClick={actionFunction}>
      <AddSkuPage errors={errors} />
    </SideSheet>
  );
};

export default SideSheetAddSku;
