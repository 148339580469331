import {useQuery} from 'react-query';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import useApi from 'hooks/useApi';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {PartnersPayload} from 'features/Booking/Parts/Services/services.types';
import {showErrorToast, logResponseError} from 'queries/query.utils';
import {DATA_SELECT_QUERY_KEYS} from './query.keys';

type TUseGetPartnerBookingDataResponse = WithErrorResponse<PartnersPayload['data']>;

type TUseGetPartnerBookingQueryParams = {
  /**
   * If `true`, then return partners related to projects only
   */
  allowProjects?: boolean;
  /**
   * Search by company name or partner id
   */
  search?: string | null | undefined;
  queryOpts?: Parameters<typeof useQuery<TUseGetPartnerBookingDataResponse['data']>>['2'];
};

const useGetPartnerBookingQuery = ({allowProjects, search, queryOpts}: TUseGetPartnerBookingQueryParams) => {
  const dispatch = useAppDispatch();
  const api = useApi();
  const filterAttributes = pickBy({allow_projects: allowProjects, term: search}, identity);

  return useQuery<TUseGetPartnerBookingDataResponse['data']>(
    DATA_SELECT_QUERY_KEYS.getPartnerBooking([filterAttributes]),
    async () => {
      const response: TUseGetPartnerBookingDataResponse = await api.partners.getAll(filterAttributes);
      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Retrieving Partners'});
        logResponseError('DataSelect - Partners')(response.err);
      }
      return response?.data;
    },
    queryOpts
  );
};

export default useGetPartnerBookingQuery;
