import getValuesByKey from 'utils/object/getValuesByKey';
import {isPlainObjectWithKeys} from 'utils/object/isPlainObjectWithKeys';
import {IHash} from 'types/base.types';

/*
 * Seems every error is wrapped in a special key which is fine in some respects.
 * But for this app, its tedious and unwarrented. So, we will unwrap it, "if" it has
 * this key.
 *
 * recursive
 * */

type CustomError = IHash<any>;
const unWrapError = (data: CustomError, {includeObjectKeys = false} = {}): string => {
  const errors = getValuesByKey(data, ['errors'])?.[0] || '';
  return (function iter(e): string {
    if (!e || typeof e === 'string') return e;

    if (Array.isArray(e)) {
      return e[0];
    }

    if (isPlainObjectWithKeys(e)) {
      if (includeObjectKeys) {
        return Object.keys(e).reduce((accum, k) => `${k}: ${iter(e[k])} ${accum}`, '');
      }
      return Object.keys(e).reduce((accum, k) => iter(e[k]) + accum, '');
    }

    return '';
  })(errors);
};

export default unWrapError;
