import React from 'react';
import {SideSheetContentSection, TextArea} from 'ht-styleguide';
import {shouldShowOptionalNotesSection} from '../../editTaskAnswerSideSheet.utils';
import {UpdateTaskAnswerFieldName} from '../../editTaskAnswerSideSheet.types';
import {IEditTaskAnswerSectionShareProps} from '../editTaskAnswerContent.types';
import AttachmentsFieldEditTaskAnswerSection from './AttachmentsField.EditTaskAnswerSection';

const EDIT_TASK_OPTIONAL_NOTES_NAMESPACE = 'editTaskOptionalNotes';

const OptionalEditTaskAnswerSection = ({formik, task}: IEditTaskAnswerSectionShareProps) => {
  const {show_optional_note, show_optional_photo} = task;

  return shouldShowOptionalNotesSection(task) ? (
    <SideSheetContentSection title="On-site notes (optional)">
      {show_optional_note && <TextArea id={UpdateTaskAnswerFieldName.Response} onChange={formik.handleChange} value={formik.values.response} error={formik.errors.response} />}
      {show_optional_photo && <AttachmentsFieldEditTaskAnswerSection formik={formik} task={task} namespace={EDIT_TASK_OPTIONAL_NOTES_NAMESPACE} />}
    </SideSheetContentSection>
  ) : null;
};

export default OptionalEditTaskAnswerSection;
