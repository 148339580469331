import React from 'react';
import {Button, BUTTON_THEMES} from 'ht-styleguide';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';

import {CANCEL_EDITOR_CONTENTS, COMMAND_CANCEL_OPERATIONS, SAVE_EDITOR_CONTENTS} from './Command.Plugin';

import {TGenericCloudinaryResponse} from 'features/Cloudinary/cloudinary.types';

import styles from './plugin.styles.scss';
import {TEditorToJson} from '../editor.types';
import {getTextByNode} from '../utils/getTextByNode';

/**
 * This plugin represents the cancel/save. No editor should exist without this.
 *   Note: The command.plugin handles the subscriptions here. If any api's change depending on
 *         usage, we should define that uniqueness there.
 */
type TEditorFooterPlugin = {
  handleDeleteAllFromCloudinary: BaseAnyFunction;
  uploadedSuccessFiles: TGenericCloudinaryResponse[] | undefined;
};

export const EditorFooterPlugin = ({handleDeleteAllFromCloudinary, uploadedSuccessFiles}: TEditorFooterPlugin) => {
  const [editor] = useLexicalComposerContext();

  /* Lets see if the editor is empty or not */
  const editorState = editor.getEditorState();
  /* Convert to json for BE and js object */
  const lexical = editorState.toJSON() as TEditorToJson;
  /* Derive a stringed version of the input with mentions/links stringed out */
  const lexicalTextNodes = getTextByNode(lexical);

  const onCancelHandler = () => {
    handleDeleteAllFromCloudinary();
    editor.dispatchCommand(CANCEL_EDITOR_CONTENTS, COMMAND_CANCEL_OPERATIONS.CLEAR_ALL_CLOSE);
  };

  const onSaveHandler = () => {
    // @ts-ignore
    editor.dispatchCommand(SAVE_EDITOR_CONTENTS, uploadedSuccessFiles);
  };

  return (
    <div className={styles.footerPluginContainer}>
      <Button theme={BUTTON_THEMES.SECONDARY} onClick={onCancelHandler}>
        Cancel
      </Button>
      <Button disabled={!lexicalTextNodes} theme={BUTTON_THEMES.PRIMARY} onClick={onSaveHandler}>
        Save
      </Button>
    </div>
  );
};
