import React from 'react';
import cn from 'classnames';
import {Icon} from 'ht-styleguide';
import {BaseAnyFunction} from 'types/base.types';
import styles from './styles.scss';

type WarningHintIcon = {
  onClick: BaseAnyFunction;
};

export const WarningHintIcon: React.FC<WarningHintIcon> = props => {
  const iconStyles = cn(styles.hintIcon, 'text-link -no-decoration');

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href="#" className={iconStyles} {...props}>
      <Icon name="info" />
    </a>
  );
};

type WarningHint = {
  visible: boolean;
  message: string | null;
  className?: string;
};

const WarningHint: React.FC<WarningHint> = ({visible = true, message = '', className = ''}) => {
  const isVisible = visible && message;

  return isVisible ? <p className={cn('caption', styles.hint, className)}>{message}</p> : null;
};

export default WarningHint;
