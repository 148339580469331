import {useQuery} from 'react-query';
import qs from 'qs';
import useApi from 'hooks/useApi';
import {ISSUES_QUERY_KEYS} from './Issues.query.keys';
import {TGetIssuesQueryResponse} from '../issues.types';

export type TUseGetTicketsGenericQueryArgs = {
  filters: Object;
  queryOpts?: Parameters<typeof useQuery<TGetIssuesQueryResponse['data']>>['2'];
};

/**
 * Used to fetch tickets that is not tied to redux state. Manually pass in filters, etc.
 * Makes a single request to the API.
 */
export const useGetTicketsGenericQuery = ({filters, queryOpts = {}}: TUseGetTicketsGenericQueryArgs) => {
  const api = useApi();

  const normalizeToParams = () => {
    return qs.stringify({...filters}, {arrayFormat: 'brackets', encode: false});
  };

  const urlSearchQuery = normalizeToParams();
  const queryKeys = [urlSearchQuery];

  const {data, isLoading} = useQuery<TGetIssuesQueryResponse['data']>(
    ISSUES_QUERY_KEYS.getTicketsGeneric(queryKeys),
    async () => {
      const response: TGetIssuesQueryResponse = await api.issues.getTickets({search: urlSearchQuery});
      return response?.data;
    },
    {...queryOpts}
  );

  const {tickets = [], pagination = null} = data || {};

  return {
    data: tickets,
    pagination,
    isLoading,
  };
};

type TUseGetTicketsGenericMultiQueryArgs = {
  [key: string]: {
    [filterKey: string]: any;
  };
};

/**
 * Used to fetch tickets that is not tied to redux state. Manually pass in filters, etc.
 * Makes multiple requests to the API.
 */
export const useGetTicketsGenericMultiQuery = ({filters}: TUseGetTicketsGenericMultiQueryArgs) => {
  const api = useApi();

  /**
   * Based on filters, reduce to to the key and a sstringified version of the filters.
   */
  const normalizeToParams = () => {
    return Object.entries(filters).reduce((acc, [key, filtersForKey]) => {
      acc[key] = qs.stringify({...filtersForKey}, {arrayFormat: 'brackets', encode: false});
      return acc;
    }, {} as {[key: string]: string});
  };

  const keysToQueryUrl = normalizeToParams();

  const {data} = useQuery(ISSUES_QUERY_KEYS.getTicketsGeneric(Object.values(keysToQueryUrl)), async () => {
    // For each key, make a request to the API
    const responses = await Promise.all(Object.values(keysToQueryUrl).map(urlSearchQuery => api.issues.getTickets({search: urlSearchQuery})));

    // For each response, match up to corresponding key
    return Object.keys(keysToQueryUrl).reduce((acc, key, index) => {
      acc[key] = responses[index]?.data;
      return acc;
    }, {} as {[key: string]: any});
  });

  return {
    data,
  };
};
