import {useEffect} from 'react';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {sleep} from 'utils/core';

export const AutoFocusPlugin = ({autoFocus = false}: {autoFocus?: boolean} = {}) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (autoFocus) {
      editor.focus();
      (async () => {
        await sleep(1000);
        editor.focus();
      })();
    }
  }, [editor, autoFocus]);

  return null;
};
