import React from 'react';
import {TOrderTaskAnswerUpdatedBy} from 'features/Orders/Pages/OrderTaskChecklist/types/orderTaskChecklist.types';
import {shortMonthDayYear} from 'global/constants/common';
import {dayjsWithTimezone} from 'utils/date';
import {IBaseServiceTask} from '../../serviceTasksList.types';

interface IUpdatedByFooter extends Omit<IBaseServiceTask, 'serviceId'> {}

const getUserType = (userType: TOrderTaskAnswerUpdatedBy['user_type'] | undefined) => {
  switch (userType) {
    case 'Geek':
      return 'on-site worker';
    case 'Admin':
      return 'admin';
    default:
      return `unknown (${userType})`;
  }
};

/**
 * Show the last time the task was updated by a user
 */
const UpdatedByFooter = ({order, task, dataTestId = 'UpdatedByFooter'}: IUpdatedByFooter) => {
  const answerUpdatedAt = task?.answer?.updated_at;
  const {short_name, user_type} = task?.answer?.updated_by || {};

  const showFooter = !!answerUpdatedAt;
  const userTypeCopy = getUserType(user_type);
  const dayjsUpdatedAt = dayjsWithTimezone(answerUpdatedAt!, order?.timezone);
  const formattedUpdatedAt = dayjsUpdatedAt.format(shortMonthDayYear);

  return showFooter ? (
    <p className="caption n700 paddingTop-small1" data-testid={`${dataTestId}-UpdatedByFooter`}>
      Last updated by {short_name} ({userTypeCopy}) on {formattedUpdatedAt}
    </p>
  ) : null;
};

export default UpdatedByFooter;
