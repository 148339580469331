import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Button, BUTTON_THEMES, Modal, ELEMENT_SIZE} from 'ht-styleguide';
import IssuesSelect from 'features/Issues/Parts/SelectFields/IssuesSelect';
import {ISSUE_ENTITY_TYPES} from 'features/Issues//Issues.constants';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {TSingleActionTypes, ProjectDetails} from 'features/MultiDwellingUnits/MDU.types';
import {useUpdateProjectPauseMutation} from 'features/MultiDwellingUnits/queries/mutation.project.pauseActions';
import {useAppDispatch} from 'hooks/useAppDispatch';
import styles from './modals.styles.scss';

const ISSUE_FIELD_NAME = 'selectedIssue';

const yupSchema = Yup.object({
  [ISSUE_FIELD_NAME]: Yup.number().nullable(),
});

const LinkIssueToPausedProjectModal = () => {
  const dispatch = useAppDispatch();
  const actionItem = useSelector(mduProjectsSlice.selectors.getActionItem);

  const project = (actionItem?.entity || {}) as ProjectDetails;
  const {id: projectId, activePause} = project || {};
  const {id: projectPauseId} = activePause || {};
  const modalVisible = actionItem?.modalKey === TSingleActionTypes.LINK_ISSUE_PAUSED_PROJECT;

  const {mutate} = useUpdateProjectPauseMutation();

  const closeModal = () => dispatch(mduProjectsSlice.actions.removeActionItemModalSlide());

  const formik = useFormik({
    validationSchema: yupSchema,
    initialValues: {
      [ISSUE_FIELD_NAME]: null,
    },
    onSubmit: ({selectedIssue}) => {
      if (projectId && projectPauseId && selectedIssue) {
        mutate(
          {projectId: +projectId, issueId: selectedIssue, projectPauseId},
          {
            onSuccess: () => {
              closeModal();
            },
          }
        );
      }
    },
  });

  const queryFilters = useMemo(
    () => ({
      entity_id: +projectId,
      entity_type: ISSUE_ENTITY_TYPES.project,
      pagination: {
        per_page: 100,
        page: 1,
      },
    }),
    [projectId]
  );

  const queryOpts = useMemo(
    () => ({
      enabled: modalVisible && !!projectId,
    }),
    [modalVisible, projectId]
  );

  if (!modalVisible) return null;

  const disableCTA = !formik.values[ISSUE_FIELD_NAME];

  return (
    <Modal
      elementSize={ELEMENT_SIZE.MEDIUM}
      isVisible={modalVisible}
      hide={closeModal}
      bodyContainerClasses={styles.overflowInitial}
      modalClassName={styles.overflowInitial}
      header="Link an issue"
      footerElement2={
        <Button onClick={formik.submitForm} theme={BUTTON_THEMES.PRIMARY} disabled={disableCTA}>
          Link Issue
        </Button>
      }
    >
      <p className="p1 marginBottom-medium">Select the primary issue that is causing the pause in work</p>
      <IssuesSelect formik={formik} fieldName={ISSUE_FIELD_NAME} label="Link an issue" queryFilters={queryFilters} queryOpts={queryOpts} />
    </Modal>
  );
};

export default LinkIssueToPausedProjectModal;
