import merge from 'lodash/merge';

// To clear in dev console: localStorage.removeItem(key);

type TStateKey = '_ht_issues_column_state' | '_ht_dispatch_unassigned_list_state';

class LocalStorageColumnState<TInitialState, TLocalStorageState> {
  private stateKey: TStateKey;

  private saveToStorageFN: (state: TInitialState) => TLocalStorageState;

  constructor({stateKey, saveToStorageFN}: {stateKey: TStateKey; saveToStorageFN: (state: TInitialState) => TLocalStorageState}) {
    this.stateKey = stateKey;
    this.saveToStorageFN = saveToStorageFN;
  }

  private getColumnStateFromStorage = () => {
    const item = localStorage.getItem(this.stateKey);
    return item ? (JSON.parse(item) as TLocalStorageState) : null;
  };

  populateInitialPagesState = ({initialState}: {initialState: TInitialState}) => {
    const localStoragePagesState = this.getColumnStateFromStorage();
    return localStoragePagesState ? merge({}, initialState, localStoragePagesState) : initialState;
  };

  saveStateToStorage = (newState: TInitialState) => {
    const stateToSave = this.saveToStorageFN(newState);
    localStorage.setItem(this.stateKey, JSON.stringify(stateToSave));
  };
}

export default LocalStorageColumnState;
