import React from 'react';
import PageHeader from 'components/Elements/PageHeader';
import {filterTextFromNumberInputs} from 'utils/form';
import styles from '../templatejobs.styles.scss';

type ProjectLevelUnitUpdateProps = {
  inputValue?: string;
  text: React.ReactNode;
  valueSetter: BaseAnyFunction;
  withIcon?: boolean;
};

const ProjectLevelUnitUpdate = ({inputValue, text, valueSetter, withIcon = true}: ProjectLevelUnitUpdateProps) => {
  const onHandleInputTotal = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    valueSetter(value);
  };

  return (
    <div className="flex align-items-center">
      {withIcon ? <PageHeader.Icon iconName="layers" /> : null}
      &nbsp;<span className="font-weight-medium h6 n700">{text}</span>&nbsp;&nbsp;
      <PageHeader.InputField
        type="number"
        onKeyDown={filterTextFromNumberInputs}
        value={inputValue}
        containerClass={withIcon ? styles.inputContainer : styles.inputContainer_small}
        appendedNode={withIcon ? <div className="p2 n700">Units</div> : null}
        onChange={onHandleInputTotal}
        id="unitCountBulkTemplate"
      />
    </div>
  );
};

export default ProjectLevelUnitUpdate;
