import {useParams} from 'react-router-dom';
import {ISSUES_PAGE_PARAMS, ISSUE_ENTITY_TYPES} from '../Issues.constants';
import {TIssuesUseParams, IssuesNavTypes} from '../issues.types';

/**
 * Driver of the Issues page. Determines the identity of the page.
 */
const useIssuesRouteParams = () => {
  // Determines the identity of the page
  let issuesNavType: IssuesNavTypes | undefined;
  // TODO: Hardcoding this for the issues page. In the future, this should be coming from filters instead.
  const issuesEntity = ISSUE_ENTITY_TYPES.project;
  // For single view, this is the id of the entity
  let issuesEntityId;

  // Used by routes of /issues/:issuesNavType
  const {issuesNavType: issuesLevelNavType} = useParams<TIssuesUseParams>();
  issuesNavType = issuesLevelNavType;

  // Used by routes outside of /issues/:issuesNavType
  const {projectId} = useParams<{projectId?: string}>();

  // Used by project level view
  if (projectId) {
    issuesNavType = IssuesNavTypes.PROJECTS_SINGLE_VIEW;
    issuesEntityId = projectId;
  }

  const {
    // Filters that should not appear in the filters modal
    omitFilterKeys,
    // Default params to use for queries
    pageFilters,
    // Title of page
    pageTitle,
    // Filters to be used for stats endpoint
    statsFilters,
    // For stats header - which key in query data to use
    statsKeys,
  } = ISSUES_PAGE_PARAMS[issuesNavType!] || {};

  return {
    issuesNavType: issuesNavType!,
    issuesEntity,
    issuesEntityId,
    omitFilterKeys,
    pageFilters,
    pageTitle,
    statsFilters,
    statsKeys,
  };
};

export default useIssuesRouteParams;
