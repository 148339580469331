import {createSlice, createSelector, createAsyncThunk, PayloadAction, AnyAction} from '@reduxjs/toolkit';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import {ResponseError} from 'types/base.types';
import {RootState} from 'store';
import APIS from 'global/apis';
import * as requestLoaderSlice from 'features/App/RequestLoader/requestLoader.ducks';
import * as servicesSlice from 'features/Booking/Parts/Services/services.ducks';
import {UpsellReferrers} from 'utils/segment/plans';

import {getFilteredServiceAdjustments, getPricesAndDiscounts, validatePinRedemptionWithSku} from './cart.utils';
import * as TS from './cart.types';
import {PinRedemption} from '../Services/services.types';
import {TRecurrence} from '../Availability/AvailabilitySelector/availabilitySelector.types';

/*
 ****************************************
 ************ ASYNC ACTIONS *************
 ****************************************
 */

export const applyCartClientIds = ({params = {}, state}: {[key: string]: any; state: () => RootState}) => {
  const cartID = state().booking?.cart?.id ?? null;
  const clientID = state().booking?.client?.id ?? null;
  const sanitizedValues = pickBy({cart_id: cartID, client_id: clientID}, identity);

  return {...params, ...sanitizedValues};
};

export const asyncActions = {
  purchasePlanOnly: createAsyncThunk<TS.CartState, {planId: number}, {rejectValue: ResponseError; state: RootState}>(
    'cart/purchasePlanOnly',
    async ({planId}, {rejectWithValue, dispatch, getState}) => {
      dispatch(requestLoaderSlice.actions.loading(true));
      const responsePlan = (await APIS.booking.cart.completeBooking(applyCartClientIds({params: {planId}, state: getState}))) ?? {};
      const error = responsePlan.err;
      const mergedData: TS.CartState = {...(responsePlan?.data ?? {})};

      dispatch(requestLoaderSlice.actions.loading(false));

      return error ? rejectWithValue(error as ResponseError) : mergedData;
    }
  ),
  reEvaluateCouponCode: createAsyncThunk<{cart: TS.CartState}, void, {rejectValue: ResponseError; state: RootState}>(
    'cart/reEvaluateCouponCode',
    async (_, {rejectWithValue, getState}) => {
      let errors = {};
      const response = (await APIS.booking.cart.ensureCouponValid(applyCartClientIds({params: {}, state: getState}))) ?? {};
      const previousCouponId = getState().booking.cart?.coupon?.id ?? '';
      const returnedCouponId = response.data?.cart?.coupon?.id;
      // Lets compare ID's. If they are the same, we know the cart coupon is good
      if (+previousCouponId !== +returnedCouponId && !response.err) {
        errors = {
          previouslyUsedCoupon: true,
        };
      }

      return response.err ? rejectWithValue(response.err as ResponseError) : ({cart: response.data?.cart, errors} as {cart: TS.CartState});
    },
    {
      condition: (_, {getState}) => {
        const {booking} = getState() as RootState;

        if (!booking?.cart?.coupon?.id) {
          // Do not have a coupon in cart, no need to fire api
          return false;
        }
        return true;
      },
    }
  ),
  bookedBy: createAsyncThunk<TS.CartStateResponsePayload, {details?: string; booked_by_id: number}, {rejectValue: ResponseError; state: RootState}>(
    'cart/bookedBy',
    async ({details, booked_by_id}, {rejectWithValue, getState}) => {
      const response = (await APIS.booking.cart.bookedBy(applyCartClientIds({params: {details, booked_by_id}, state: getState}))) ?? {};

      return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.CartStateResponsePayload);
    }
  ),
  startBooking: createAsyncThunk<TS.CartStateResponsePayload, void, {rejectValue: ResponseError; state: RootState}>('cart/startBooking', async (_, {rejectWithValue, dispatch, getState}) => {
    dispatch(requestLoaderSlice.actions.loading(true));
    const response = (await APIS.booking.cart.startBooking(applyCartClientIds({params: {}, state: getState}))) ?? {};
    dispatch(requestLoaderSlice.actions.loading(false));

    return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.CartStateResponsePayload);
  }),
  removeClientFromCart: createAsyncThunk<TS.CartStateResponsePayload, void, {rejectValue: ResponseError; state: RootState}>('cart/removeClient', async (_, {rejectWithValue, dispatch, getState}) => {
    dispatch(requestLoaderSlice.actions.loading(true));
    const response = (await APIS.booking.client.removeClient(applyCartClientIds({params: {}, state: getState}))) ?? {};
    dispatch(requestLoaderSlice.actions.loading(false));

    return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.CartStateResponsePayload);
  }),
  addClientToCart: createAsyncThunk<TS.CartStateResponsePayload, {clientId: number}, {rejectValue: ResponseError; state: RootState}>(
    'cart/addClient',
    async ({clientId}, {rejectWithValue, dispatch, getState}) => {
      dispatch(requestLoaderSlice.actions.loading(true));
      const response = (await APIS.booking.client.addClient(applyCartClientIds({params: {client_id: clientId}, state: getState}))) ?? {};
      dispatch(requestLoaderSlice.actions.loading(false));

      return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.CartStateResponsePayload);
    }
  ),
  deleteSku: createAsyncThunk<TS.CartStateResponsePayload, {itemIndex: number}, {rejectValue: ResponseError; state: RootState}>('cart/deleteSku', async ({itemIndex}, {rejectWithValue, getState}) => {
    const response =
      (await APIS.booking.cart.deleteSku(
        applyCartClientIds({
          params: {itemIndex, no_taxes: false},
          state: getState,
        })
      )) ?? {};
    return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.CartStateResponsePayload);
  }),
  updateSku: createAsyncThunk<TS.CartStateResponsePayload, {index: number; followUpServiceId?: number}, {rejectValue: ResponseError; state: RootState}>(
    'cart/updateSku',
    async ({index, followUpServiceId}, {rejectWithValue, getState, dispatch}) => {
      const {selectedSku} = getState().booking.qa.addSku;
      dispatch(requestLoaderSlice.actions.loading(true));
      const response = await APIS.booking.cart.updateSku(
        applyCartClientIds({
          params: {item: {...selectedSku, followUpServiceId}, index},
          state: getState,
        })
      );
      dispatch(requestLoaderSlice.actions.loading(false));
      return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.CartStateResponsePayload);
    },
    {
      condition: (_, {getState}) => {
        const {booking} = getState() as RootState;
        const {errors} = booking.qa.addSku;

        if (Object.keys(errors).length) {
          // We have errors in QA Sku form. Dont run it
          return false;
        }
        return true;
      },
    }
  ),
  addSku: createAsyncThunk<TS.CartStateResponsePayload, void, {rejectValue: ResponseError; state: RootState}>(
    'cart/addSku',
    async (_, {rejectWithValue, getState, dispatch}) => {
      const {selectedSku} = getState().booking.qa.addSku;
      let additionalSkuData = {};
      const pinRedemptionData = getState().booking.services.pinRedemptions;
      // verify current sku matches a pin we have or throw
      const pinRedemptionValidations = validatePinRedemptionWithSku({
        skuId: selectedSku.skuId,
        pins: pinRedemptionData,
      });
      /* Don't attempt to add sku (via button click) if we have need for a Pin */
      if ((pinRedemptionValidations as PinRedemption).err) {
        return rejectWithValue({
          errors: [pinRedemptionValidations.err],
        } as ResponseError);
      }
      // Assign pin data object if we have it
      if ((pinRedemptionValidations as PinRedemption).pin) {
        additionalSkuData = {
          vendor: pinRedemptionValidations.vendor,
          pin: pinRedemptionValidations.pin,
        };
        // remove the pin redemption from redux
        dispatch(servicesSlice.actions.removePinRedemptions());
      }

      dispatch(requestLoaderSlice.actions.loading(true));
      const response = await APIS.booking.cart.addSku(
        applyCartClientIds({
          params: {item: {...selectedSku, ...additionalSkuData}},
          state: getState,
        })
      );
      dispatch(requestLoaderSlice.actions.loading(false));
      return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.CartStateResponsePayload);
    },
    {
      condition: (_, {getState}) => {
        const {booking} = getState() as RootState;
        const {errors} = booking.qa.addSku;

        if (Object.keys(errors).length) {
          // We have errors in QA Sku form. Dont run it
          return false;
        }
        return true;
      },
    }
  ),
  addProductSku: createAsyncThunk<TS.CartStateResponsePayload, {skuId: number}, {rejectValue: ResponseError; state: RootState}>(
    'cart/addProductSku',
    async ({skuId}, {rejectWithValue, getState, dispatch}) => {
      dispatch(requestLoaderSlice.actions.loading(true));
      const item = {skuId, questions: {}};
      const response = await APIS.booking.cart.addSku(applyCartClientIds({params: {item}, state: getState}));
      dispatch(requestLoaderSlice.actions.loading(false));
      return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.CartStateResponsePayload);
    }
  ),
  updateQuantity: createAsyncThunk<TS.CartStateResponsePayload, {itemIndex: number; quantity: number}, {rejectValue: ResponseError; state: RootState}>(
    'cart/updateQuantity',
    async ({itemIndex, quantity}, {rejectWithValue, getState, dispatch}) => {
      dispatch(requestLoaderSlice.actions.loading(true));
      // get item at index and update quantity
      const {items} = getState().booking.cart;
      const item = items[itemIndex];
      const updatedItem = {...item, quantity};

      const response = await APIS.booking.cart.updateQuantity(
        applyCartClientIds({
          params: {item: updatedItem, index: itemIndex},
          state: getState,
        })
      );
      dispatch(requestLoaderSlice.actions.loading(false));

      return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.CartStateResponsePayload);
    }
  ),
  getClientCart: createAsyncThunk<TS.CartStateResponsePayload, {cart_id?: number | string}, {rejectValue: ResponseError; state: RootState}>(
    'cart/getClientCart',
    async ({cart_id}, {rejectWithValue, dispatch}) => {
      dispatch(requestLoaderSlice.actions.loading(true));
      const response = await APIS.booking.cart.getClientCart({
        breakdown: true,
        cart_id,
        no_taxes: false,
        normalize: true,
      });
      dispatch(requestLoaderSlice.actions.loading(false));

      return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.CartStateResponsePayload);
    },
    {
      condition: ({cart_id}) => {
        if (!cart_id) {
          // We don't have a card id, don't bother.
          return false;
        }
        return true;
      },
    }
  ),
  addCoupon: createAsyncThunk<TS.CartStateResponsePayload, {code: string}, {rejectValue: ResponseError; state: RootState}>('cart/addCoupon', async ({code}, {rejectWithValue, getState}) => {
    const response = await APIS.booking.cart.addCoupon(applyCartClientIds({params: {code}, state: getState}));
    return response.err ? rejectWithValue(response.err) : (response as TS.CartStateResponsePayload);
  }),
  getZipCodeAdjustment: createAsyncThunk<any, {zipcode?: string}, {rejectValue: ResponseError; state: RootState}>('cart/getZipCodeDiscount', async ({zipcode = ''} = {}, {rejectWithValue}) => {
    const response = await APIS.booking.zipCodeLookup.getAdjustments({
      zipcode,
    });
    return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.CartState);
  }),
  addAvailability: createAsyncThunk<TS.CartStateResponsePayload, {availability: string[]; skipMinCount: boolean; recurrence: TRecurrence}, {rejectValue: ResponseError; state: RootState}>(
    'cart/addAvailability',
    async ({availability, skipMinCount, recurrence}, {rejectWithValue, getState, dispatch}) => {
      dispatch(requestLoaderSlice.actions.loading(true));
      const response =
        (await APIS.booking.cart.goToPayment(
          applyCartClientIds({
            params: {
              availability,
              skip_min_count: skipMinCount,
              ...(recurrence && {recurrence}),
            },
            state: getState,
          })
        )) ?? {};
      dispatch(requestLoaderSlice.actions.loading(false));
      try {
        return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.CartStateResponsePayload);
      } catch (e) {
        return rejectWithValue(response as ResponseError);
      }
    }
  ),
  addCard: createAsyncThunk<TS.CartStateResponsePayload, {card_token: string; token: string}, {rejectValue: ResponseError; state: RootState}>(
    'cart/addCard',
    async ({card_token, token}, {getState, rejectWithValue}) => {
      const response = (await APIS.booking.cart.addCard(applyCartClientIds({params: {card_token, token}, state: getState}))) ?? {};
      try {
        return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.CartStateResponsePayload);
      } catch (e) {
        return rejectWithValue(response as ResponseError);
      }
    }
  ),
  goToSummary: createAsyncThunk<TS.CartStateResponsePayload, void, {rejectValue: ResponseError; state: RootState}>('cart/goToSummary', async (_, {getState, rejectWithValue}) => {
    const response = (await APIS.booking.cart.goToSummary(applyCartClientIds({params: {}, state: getState}))) ?? {};
    try {
      return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.CartStateResponsePayload);
    } catch (e) {
      return rejectWithValue(response as ResponseError);
    }
  }),
  selectPlan: createAsyncThunk<TS.CartStateResponsePayload, {planId: number; isUpsell?: boolean}, {rejectValue: ResponseError; state: RootState}>(
    'cart/selectPlan',
    async ({planId, isUpsell}, {rejectWithValue, dispatch, getState}) => {
      const params = {
        plan_id: planId,
        plan_upsell_channel: isUpsell ? UpsellReferrers.ADMIN_UPSELL : UpsellReferrers.ADMIN_STANDALONE,
      };
      dispatch(requestLoaderSlice.actions.loading(true));
      const response = (await APIS.booking.cart.selectPlan(applyCartClientIds({params, state: getState}))) ?? {};
      dispatch(requestLoaderSlice.actions.loading(false));
      try {
        return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.CartStateResponsePayload);
      } catch (e) {
        return rejectWithValue(response as ResponseError);
      }
    }
  ),
  deletePlan: createAsyncThunk<TS.CartStateResponsePayload, void, {rejectValue: ResponseError; state: RootState}>('cart/deletePlan', async (_, {rejectWithValue, getState}) => {
    const response = (await APIS.booking.cart.deletePlan(applyCartClientIds({params: {no_taxes: true}, state: getState}))) ?? {};
    try {
      return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.CartStateResponsePayload);
    } catch (e) {
      return rejectWithValue(response as ResponseError);
    }
  }),
  removeAdjustment: createAsyncThunk<TS.CartStateResponsePayload, {type: string}, {rejectValue: ResponseError; state: RootState}>(
    'cart/removeAdjustment',
    async ({type}, {rejectWithValue, getState, dispatch}) => {
      dispatch(requestLoaderSlice.actions.loading(true));
      const response = await APIS.booking.cart.removeAdjustment(applyCartClientIds({params: {type}, state: getState}));
      dispatch(requestLoaderSlice.actions.loading(false));
      try {
        return response.err ? rejectWithValue(response.err as ResponseError) : (response as TS.CartStateResponsePayload);
      } catch (e) {
        return rejectWithValue(response as ResponseError);
      }
    }
  ),
};

/*
 *****************************************
 ************ INITIAL STATE  *************
 *****************************************
 */

const initialState: TS.CartState = {} as TS.CartState;

/*
 ****************************************
 ************ CREATE SLICE  *************
 *****************************************
 */
const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    updateCart: (_, action) => {
      return action.payload.cart || {};
    },
    addCartErrors: (state, action) => {
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    },
    clearErrors: state => {
      return {
        ...state,
        errors: {},
      };
    },
  },

  extraReducers: builder => {
    builder
      .addCase(asyncActions.getZipCodeAdjustment.fulfilled, (state, action: PayloadAction<any>) => {
        /* Not a real return, will just show updates cause of merge of hardcoded data */
        // return state; // return action.payload.data.cart;
        const discounts = action.payload;
        return {...state, breakdown: {...state.breakdown, ...discounts}};
      })
      .addCase(asyncActions.reEvaluateCouponCode.fulfilled, (state, action: PayloadAction<any>) => {
        /* We could have just added the "error onto the cart, but that conflates the response. Break it out. */
        const {cart, errors} = action.payload;

        return {
          ...cart,
          errors: {
            ...state.errors,
            ...errors,
          },
        };
      })
      .addMatcher(
        (action): action is AnyAction => [asyncActions.addCoupon.rejected].some(actionCreator => actionCreator.match(action)),
        (state, action) => {
          const {errors = [], formErrors = {}} = action.payload;

          return {
            ...state,
            errors: {
              error: errors,
              ...formErrors,
            },
          };
        }
      )
      .addMatcher(
        (action): action is AnyAction =>
          [
            asyncActions.bookedBy.fulfilled,
            asyncActions.startBooking.fulfilled,
            asyncActions.removeClientFromCart.fulfilled,
            asyncActions.addClientToCart.fulfilled,
            asyncActions.addSku.fulfilled,
            asyncActions.addProductSku.fulfilled,
            asyncActions.updateSku.fulfilled,
            asyncActions.updateQuantity.fulfilled,
            asyncActions.getClientCart.fulfilled,
            asyncActions.selectPlan.fulfilled,
            asyncActions.deletePlan.fulfilled,
            asyncActions.deleteSku.fulfilled,
            asyncActions.removeAdjustment.fulfilled,
            asyncActions.addAvailability.fulfilled,
            asyncActions.addCard.fulfilled,
            asyncActions.goToSummary.fulfilled,
            asyncActions.addCoupon.fulfilled,
          ].some(actionCreator => actionCreator.match(action)),
        (state, action) => {
          return {...state, errors: {}, ...action.payload.data.cart};
        }
      )
      .addMatcher(
        (action): action is AnyAction => [asyncActions.purchasePlanOnly.fulfilled].some(actionCreator => actionCreator.match(action)),
        (state, action) => {
          return {...state, errors: {}, ...action.payload};
        }
      );
  },
});

/*
*******************************************************
  SELECTORS & SELECTOR METHODS
*******************************************************
*/
const getCartState = (state: RootState): TS.CartState => state.booking.cart;
const getCartStateByKey =
  <U = unknown>(key: keyof TS.CartState) =>
  (cart: TS.CartState): U =>
    cart[key] as U;
const getBreakdownStateByKey =
  (key: keyof TS.BreakDown) =>
  ({breakdown}: {breakdown: TS.BreakDown}) =>
    breakdown[key];
const getBreakdownItemsLengthSelector = ({breakdown}: {breakdown: TS.BreakDown}) => breakdown?.items?.length || 0;
const getBreakdownItemsSelector = ({breakdown}: {breakdown: TS.BreakDown}) => {
  // returns { name:'service 1', amount: '$1', adjustments: [{ name:'adjustment 1', amount:'$2' }]};
  if (!breakdown?.items?.length) return null;
  return breakdown.items.map((item, i) => {
    const {name, adjustments, skuId, category, amountFormatted, isProduct, variantsIds, prepaid, quantity, skuAmount, followUp} = item;
    const pricesAndDiscounts = getPricesAndDiscounts(item);
    return {
      name,
      amountFormatted,
      category,
      index: i,
      skuId,
      adjustments: getFilteredServiceAdjustments({
        adjustments,
        prepaid,
        skuAmount,
      }),
      isProduct,
      variantsIds,
      quantity,
      followUp,
      ...pricesAndDiscounts,
    };
  });
};
const getFromApiSelector = (cart: TS.CartState): boolean => !!cart.order?.fromApi;
const getHasUnansweredQuestionsSelector = (cart: TS.CartState): boolean => (cart.items || []).some(item => item.hasUnansweredQuestions);

/*
*******************************************************
  EXPORTS
*******************************************************
*/
export const selectors = {
  getCartState: createSelector(getCartState, cart => cart),
  getKeyInCartState: (key: keyof TS.CartState) => createSelector(getCartState, getCartStateByKey(key)),
  getKeyInCartBreakdownState: (key: keyof TS.BreakDown) => createSelector(getCartState, getBreakdownStateByKey(key)),
  getBreakdownItemsSelector: createSelector(getCartState, getBreakdownItemsSelector),
  getBreakdownItemsLengthSelector: createSelector(getCartState, getBreakdownItemsLengthSelector),
  getFromApiSelector: createSelector(getCartState, getFromApiSelector),
  getHasUnansweredQuestionsSelector: createSelector(getCartState, getHasUnansweredQuestionsSelector),
  getFollowUpSelector: createSelector(getCartState, cart => cart.followUp),
  hasPlanInCartSelector: createSelector(getCartState, cart => Boolean(cart.plan?.id)),
};

export const actions = {...asyncActions, ...cartSlice.actions};

export default cartSlice.reducer;
