import React from 'react';
import cn from 'classnames';
import {Grid} from 'ht-styleguide';
import {ProjectDetails, ProjectGroup} from 'features/MultiDwellingUnits/MDU.types';
import ServiceDetails from './CurrentProject.LaunchReview.ServiceDetails';
import styles from './launchReviewPage.styles.scss';

const ServiceHeaders = () => (
  <tr className="border-bottom">
    <th className={cn('text-left paddingBottom-small1 paddingTop-tiny1', styles.largeTableHeader)}>
      <p className="n700 caption">TEMPLATE</p>
    </th>
    <th className={cn('text-left', styles.largeTableHeader)}>
      <p className="n700 caption">SKUS</p>
    </th>
    <th className={cn('text-right n700 caption', styles.medTableHeader)}>UNITS</th>
    <th className="text-right">
      <p className="n700 caption">PRICE PER UNIT</p>
    </th>
    <th className="text-right">
      <p className="n700 caption">PAYOUT PER UNIT</p>
    </th>
    <th className={cn('hidden', styles.smallTableHeader)} style={{width: '40px'}}>
      ...
    </th>
  </tr>
);

const OrderSummaryTable = ({project}: {project: ProjectDetails}) => {
  const {projectGroups} = project;
  return (
    <Grid.FullWidth classes="paddingTop-medium1">
      <Grid.Row>
        <Grid.Column lg={12}>
          <h2 className="h5 paddingBottom-small2">Jobs</h2>
          <table className={styles.servicesTable}>
            <thead>
              <ServiceHeaders />
            </thead>
            <tbody>
              {projectGroups?.map((projectGroup: ProjectGroup, index: number) => (
                <ServiceDetails key={`${projectGroup.id}`} projectGroup={projectGroup} groupId={`${index + 1}`} project={project} />
              ))}
            </tbody>
          </table>
        </Grid.Column>
      </Grid.Row>
    </Grid.FullWidth>
  );
};

export default OrderSummaryTable;
