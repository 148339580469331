import React from 'react';

type PartialEventFN = (fn: (...args: any[]) => any) => (event: MouseEvent) => void;

export const objectNoop = () => ({});
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const noop = (...args: any[]) => {};
export const prevent = (event: MouseEvent) => event.preventDefault();
export const prevented: PartialEventFN = fn => event => {
  event.preventDefault();
  fn();
};
export const stopImmediatePropagation: PartialEventFN = fn => event => {
  if (event.stopPropagation) event.stopPropagation();
  if (event.preventDefault) event.preventDefault();
  fn(event);
};

export const stopPropagationOnDiv = (e: React.MouseEvent<HTMLDivElement>) => {
  e.stopPropagation();
  e.preventDefault();
};

/* When passing ref down the chain to many children is no longer appropriate */
export const programmaticallySetFocus = (id: string) => {
  const element = document.getElementById(id);
  if (element) element.focus();
}
