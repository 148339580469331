import React from 'react';
import NeedsApprovalStatusBar from './ProjectPageStatusBar.NeedsApproval';
import ReadyToLaunchStatusBar from './ProjectPageStatusBar.ReadyToLaunch';
import PausedStatusBar from './ProjectPageStatusBar.Paused';

/**
 * Display a status bar at the top of the page.
 *
 * - This alerts the user of the status and high-priority actions to take on the project.
 * - Only available for certain project statuses.
 * - Logic to display which status bar is imbedded within the individual components as
 *   only one status bar should be displayed at a time.
 */
const ProjectPageStatusBar = () => {
  return (
    <>
      <ReadyToLaunchStatusBar />
      <NeedsApprovalStatusBar />
      <PausedStatusBar />
    </>
  );
};

export default ProjectPageStatusBar;
