import {useQuery} from 'react-query';
import useApi from 'hooks/useApi';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {showErrorToast, logResponseError, stringifyQueryParams} from 'queries/query.utils';
import {DATA_SELECT_QUERY_KEYS} from './query.keys';

interface IGetUsersByTypeQuery {
  extraParams?: {
    /**
     * Return only fulfillment agents.
     * Param only applies when `types` includes 'admin'. Otherwise, no users will return.
     */
    only_fulfillment_agents?: boolean;
  };
  /**
   * As needed by BE
   */
  userType: Array<'admin' | 'tech' | 'geek' | 'client' | 'partner' | 'recruit' | 'user'>;
}

type TUserResponse = WithErrorResponse<{users: Array<{id: number; name: string}>}>;

const useGetUsersByTypeQuery = ({userType = ['admin'], extraParams = {}}: IGetUsersByTypeQuery) => {
  const dispatch = useAppDispatch();
  const api = useApi();

  const searchQuery = stringifyQueryParams({types: userType, full: false, pagination: false, ...extraParams});

  return useQuery(DATA_SELECT_QUERY_KEYS.getUsersByType([searchQuery]), async () => {
    const response: TUserResponse = await api.users.getUsers({search: searchQuery});
    if (response.err) {
      showErrorToast(dispatch)({errors: 'Error Retrieving Users'});
      logResponseError('DataSelect - Users')(response.err);
    }
    return response?.data;
  });
};

export default useGetUsersByTypeQuery;
