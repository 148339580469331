import React, {useEffect} from 'react';
import {useHTDropzoneValidatorHelpers} from 'components/Elements/HTDropzone/htDropzone.hooks';
import CloudinaryAttachmentsField from 'features/Cloudinary/components/CloudinaryAttachmentsField';
import {SHARED_CLOUDINARY_FOLDERS} from 'features/Cloudinary/cloudinary.constants';
import {isCloudinaryResponseEqual} from 'features/Cloudinary/cloudinary.utils';
import cloudinarySlice from 'features/Cloudinary/state/cloudinary.ducks';
import {useSelector} from 'hooks/useAppSelector';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {EDIT_TASK_MAX_FILE_SIZE} from '../../editTaskAnswerSideSheet.constants';
import {UpdateTaskAnswerFieldName} from '../../editTaskAnswerSideSheet.types';
import {IEditTaskAnswerSectionShareProps} from '../editTaskAnswerContent.types';

interface IAttachmentsFieldsEditTaskAnswerSectionProps extends IEditTaskAnswerSectionShareProps {
  namespace: string;
  /**
   * Manaully pass an error message. Not all instances will show an error message.
   */
  errorMessage?: string;
  withDropzone?: boolean;
}

/**
 * A helper component for the Cloudinary attachments field that is shared across sections.
 */
const AttachmentsFieldsEditTaskAnswerSection = ({errorMessage, formik, namespace, task, withDropzone = true}: IAttachmentsFieldsEditTaskAnswerSectionProps) => {
  const dispatch = useAppDispatch();
  const cloudinaryNamespace = `${namespace}-${task.id}`;
  const cloudinaryAttachments = useSelector(cloudinarySlice.selectors.getFileSuccessUploads(cloudinaryNamespace));

  const {reactDropzoneValidator, onDropRejected} = useHTDropzoneValidatorHelpers({acceptedFileTypes: ['image/']});
  const formAttachments = formik.values.attachments;

  // When the user updates the cloudinary attachments, update the formik values
  useEffect(() => {
    if (Array.isArray(cloudinaryAttachments) && Array.isArray(formAttachments)) {
      if (!isCloudinaryResponseEqual(formAttachments, cloudinaryAttachments)) {
        formik.setFieldValue(`${UpdateTaskAnswerFieldName.Attachments}`, cloudinaryAttachments);
      }
    }
  }, [formAttachments, cloudinaryAttachments, formik]);

  useEffect(() => {
    return () => {
      // Clear out the cloudinary attachments when the component unmounts
      dispatch(cloudinarySlice.actions.deleteAllFilesByNamespace({editorNamespace: cloudinaryNamespace}));
    };
  }, [cloudinaryNamespace, dispatch]);

  return (
    <CloudinaryAttachmentsField
      deleteCloudinaryAssetOnSingleRemove={false}
      error={errorMessage}
      folder={SHARED_CLOUDINARY_FOLDERS.OrderTasks}
      maxFileSize={EDIT_TASK_MAX_FILE_SIZE}
      namespace={cloudinaryNamespace}
      onDropRejected={onDropRejected}
      preseedAttachments={formAttachments}
      validator={reactDropzoneValidator}
      withDropzone={withDropzone}
      withLightbox
    />
  );
};

export default AttachmentsFieldsEditTaskAnswerSection;
