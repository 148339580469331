import {useEffect, useState} from 'react';
import {useQueryClient} from 'react-query';
import {unwrapResult} from '@reduxjs/toolkit';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useSelector} from 'hooks/useAppSelector';
import useApi from 'hooks/useApi';
import {notifications} from 'components/Notification/notification.ducks';
import APIS from 'global/apis';
import {logger} from 'utils/logger';
import unWrapError from 'utils/request/requestError';
import {UploadErrorStatusType} from 'features/MultiDwellingUnits/Parts/FileUploadErrors/FileUploadErrors.types';
import {validateFileBeforeUpload} from 'features/MultiDwellingUnits/MDU.utils';
import {useCurrentProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/CurrentProject.hooks';
import {validateForm} from '../Questions/ducks';
import {MduApi, SelectOption, UpdateGroupParams, ProjectDetailsFormFields} from './MDU.types';
import mduProjectsSlice from './MDU.ducks';
import {MDU_PROJECT_QUERY_KEYS, BASE_PROJECTS_QUERY_KEY, PROJECTS_PAGINATION_KEY, BASE_CURRENT_PROJECT_KEY, GET_PROJECT_DETAILS_KEY} from './MDU.query.keys';

export const useMduApi = (): MduApi => {
  const api = useApi();
  const reduxDispatch = useAppDispatch();
  const showErrorToast = (error: String) => {
    reduxDispatch(notifications.actions.notificationApiError({source: error}));
    reduxDispatch(notifications.actions.notificationApiPending());
  };

  const searchTechs = async (name: string) => {
    try {
      const response = await APIS.mdu.searchTechs({name});
      if (response.err) throw new Error(`Error fetching Techs ${response.err}`);
      const {
        data: {techs},
      } = response;
      return techs;
    } catch (error) {
      // @ts-ignore
      logger('searchTechs')(error);
      showErrorToast(String(error));
      return false;
    }
  };

  /* -------------- PROJECT LEVEL ------------------ */
  const updateProject = async (projectId: string, projectDetails: ProjectDetailsFormFields) => {
    api.toggleLoader(true);
    try {
      const response = await APIS.mdu.updateProject({id: projectId}, {project: projectDetails});
      if (response.err) throw new Error('Error updating project'); // TODO: handle this
      const {
        data: {project},
      } = response;
      reduxDispatch(mduProjectsSlice.actions.updateCurrentProject(project));
      return project;
    } catch (error) {
      showErrorToast(String(error));
      // @ts-ignore
      logger('updateProject')(error);
      return false;
    } finally {
      api.toggleLoader(false);
    }
  };

  /* ---------- TEMPLATE / GROUP LEVEL ------------- */
  const deleteGroup = async ({projectId, projectGroupId}: {projectId: string; projectGroupId: string}) => {
    api.toggleLoader(true);
    try {
      const response = await APIS.mdu.deleteGroup({projectId, id: projectGroupId});
      if (response.err) throw new Error(`Error deleting group ${String(unWrapError(response.err))}`);
      await reduxDispatch(mduProjectsSlice.actions.fetchProjectDetails({projectId}));
      return true;
    } catch (error) {
      showErrorToast(String(error));
      return false;
    } finally {
      api.toggleLoader(false);
    }
  };

  const updateGroup = async ({projectId, projectGroupId, projectGroups: project_group}: {projectId: string; projectGroupId: string; projectGroups: UpdateGroupParams}) => {
    api.toggleLoader(true);
    try {
      const response = await APIS.mdu.updateGroup({projectId, id: projectGroupId}, {project_group});
      if (response.err) throw new Error(`Error updating group ${String(unWrapError(response.err))}`);
      await reduxDispatch(mduProjectsSlice.actions.fetchProjectDetails({projectId}));
      return true;
    } catch (error) {
      showErrorToast(String(error));
      return false;
    } finally {
      api.toggleLoader(false);
    }
  };

  const addGroup = async (projectId: string, unitsNumber: number) => {
    api.toggleLoader(true);
    try {
      const response = await APIS.mdu.createGroup({projectId}, {project_group: {unitsNumber}});
      if (response.err) throw new Error(unWrapError(response.err));
      await reduxDispatch(mduProjectsSlice.actions.fetchProjectDetails({projectId}));
      return true;
    } catch (error) {
      showErrorToast(String(error));
      // @ts-ignore
      logger('Create/Add Group')(error);
      return false;
    } finally {
      api.toggleLoader(false);
    }
  };

  return {
    // project level
    searchTechs,
    updateProject,

    // template / group level
    deleteGroup,
    addGroup,
    updateGroup,
  };
};

export const useVerifyQAForm = () => {
  const addSkuState = useSelector(mduProjectsSlice.selectors.getAddSkuState);
  const dispatch = useAppDispatch();

  return () => {
    const errors = validateForm({
      addSku: addSkuState,
    });
    dispatch(mduProjectsSlice.actions.setQaFormErrors(errors));
    return errors;
  };
};

/** This is a questionable add. We will use it various modal files */
export const useOnCloseActionableModals = () => {
  const dispatch = useAppDispatch();

  const removeActionItemModalSlide = () => {
    dispatch(mduProjectsSlice.actions.removeActionItemModalSlide());
  };

  return {
    removeActionItemModalSlide,
  };
};

export const useUploadFileValidation = ({skipValidation = false} = {}) => {
  const [uploadedFiles, setUploadedFiles] = useState<FileList | null>(null); // Stage files for upload
  const resetUploadedFiles = () => setUploadedFiles(null);

  const [errorStatusInfo, setErrorStatusInfo] = useState<UploadErrorStatusType[] | null>(null); // HTTP error status and file upload errors
  const resetErrorStatus = () => setErrorStatusInfo(null);

  /** This handles the ingestion of files after the user selects from their OS */
  const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {files} = e.target;

    if (files) {
      if (skipValidation) {
        setUploadedFiles(files);
        return;
      }
      const validFiles = [];
      const invalidFiles = [];
      for (let i = 0; i < files.length; i += 1) {
        const file = files[i];
        const validation = validateFileBeforeUpload(files[i]);
        if (validation.isValid) {
          validFiles.push(file);
        } else {
          invalidFiles.push(validation.errorStatusInfo as UploadErrorStatusType);
        }
      }

      // Create new FileList that contains the previously uploaded files and the newly uploaded valid files
      const newFileList = new DataTransfer();
      if (uploadedFiles) {
        for (let i = 0; i < uploadedFiles.length; i += 1) {
          newFileList.items.add(uploadedFiles[i]);
        }
      }
      validFiles.forEach(validFile => {
        /*
          - Only add the file if it doesn't already exist in the list.
          - Create array from uploaded files to use find() method.
        */
        const uploadFilesToArray = Array.from(uploadedFiles || []);
        if (!uploadFilesToArray?.find(file => file.name === validFile.name)) {
          newFileList.items.add(validFile);
        }
      });

      setErrorStatusInfo(invalidFiles);
      setUploadedFiles(newFileList.files);
    }
  };
  return {
    /** callback to handle file input click */
    onFileInputChange,
    /** files to be uploaded */
    uploadedFiles,
    /** manually update uploadedFiles */
    setUploadedFiles,
    /** reset uploadedFiles to original state */
    resetUploadedFiles,
    /** error status info */
    errorStatusInfo,
    /** directly set Error status info (e.g., error from server) */
    setErrorStatusInfo,
    /** reset error status info to original state */
    resetErrorStatus,
  };
};

export const useFetchProjectTypes = () => {
  const [projectTypes, setProjectTypes] = useState<SelectOption[]>([]);
  const dispatch = useAppDispatch();
  const allProjectTypes = useSelector(mduProjectsSlice.selectors.getProjectTypes);

  useEffect(() => {
    const formattedProjectTypes = allProjectTypes?.map(type => ({
      value: type.slug,
      label: type.name,
    }));
    if (!projectTypes.length) setProjectTypes(formattedProjectTypes);

    const fetchProjectTypes = async () => {
      try {
        const response = await dispatch(mduProjectsSlice.actions.getProjectTypes());
        const {
          data: {project_types: types},
        } = unwrapResult(response);
        const formattedTypes = types.map(type => ({
          value: type.slug,
          label: type.name,
        }));
        setProjectTypes(formattedTypes);
      } catch (error) {
        logger('fetchProjectTypes')(String(error));
      }
    };
    if (!allProjectTypes.length) fetchProjectTypes();
  }, [allProjectTypes, projectTypes.length, dispatch]);

  return projectTypes;
};

/**
 * Helpers to invalidate projects-related queries and refetch project details
 *
 * - The intent of this hook is to keep projects list data and project details data up-to-date, which is why
 *   there are some calls via redux-thunks to fetch data.
 * - This hook was created in response to the new project pause feature. Since issues can now be linked to projects,
 *   changes in an issues's details can have an affect on the data for projects. These helpers are used
 *   to invalidate project queries upon issue mutations.
 */
export const useInvalidateProjectsQuery = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const currentProject = useCurrentProject();

  /**
   * Invalidate all queries that fetch a list of projects (mainly the table views)
   */
  const invalidatePaginatedProjectsQuery = () => {
    queryClient.invalidateQueries({queryKey: [...BASE_PROJECTS_QUERY_KEY, PROJECTS_PAGINATION_KEY], exact: false});
  };

  /**
   * Invalidate all queries that fetch the details of a projects. If `projectId` is given, then
   * invalidate the query for that project only.
   *
   * Most components are pulling project details from redux. Since the intent of this hook is refresh
   * data, we're also going to make a dispatch call to refetch project details.
   *
   * Note: Fetch for project details can be triggered twice - by react-query or redux toolkit.
   *       Until the entire MDU feature is migrated to using react-query, this side effect will remain
   */
  const invalidateProjectDetailsQuery = async ({projectId}: {projectId?: string | number} = {}) => {
    const queryKey = [...BASE_CURRENT_PROJECT_KEY, GET_PROJECT_DETAILS_KEY];
    if (projectId) {
      queryKey.push(`${projectId}`);
    }
    await queryClient.invalidateQueries({queryKey, exact: false});

    if (projectId && currentProject?.id === projectId) {
      await dispatch(mduProjectsSlice.actions.fetchProjectDetails({projectId}));
    }
  };

  const invalidateProjectPauseHistoryQuery = async ({projectId}: {projectId?: string | number} = {}) => {
    if (projectId) {
      const queryKey = MDU_PROJECT_QUERY_KEYS.getProjectPauseHistory(`${projectId}`);
      await queryClient.invalidateQueries({queryKey});
    }
  };

  /**
   * Invalidate and refetch project data that may contain issues data. When an issue is created or
   * updated, these data sets need to update as well.
   */
  const invalidateProjectDataWithIssues = async ({projectId}: {projectId?: string | number} = {}) => {
    invalidatePaginatedProjectsQuery();

    await Promise.all([invalidateProjectDetailsQuery({projectId}), invalidateProjectPauseHistoryQuery({projectId})]);
  };

  return {
    invalidatePaginatedProjectsQuery,
    invalidateProjectDetailsQuery,
    invalidateProjectDataWithIssues,
  };
};
