import React, {useMemo} from 'react';
import {RadioGroup, Radio, SideSheetContentSection, ELEMENT_SIZE} from 'ht-styleguide';
import {UpdateTaskAnswerFieldName} from '../../editTaskAnswerSideSheet.types';
import {IEditTaskAnswerSectionShareProps} from '../editTaskAnswerContent.types';

const BinaryEditTaskAnswerSection = ({formik, task}: IEditTaskAnswerSectionShareProps) => {
  const {options} = task;

  const selectedValue = useMemo(() => {
    const firstOption = formik.values.options?.[0];
    return firstOption?.id ?? null;
  }, [formik.values.options]);

  const onChange = (newValue: number | null) => formik.setFieldValue(UpdateTaskAnswerFieldName.Options, newValue ? [{id: newValue}] : []);

  return (
    <SideSheetContentSection title="Select One">
      <RadioGroup value={selectedValue} elementSize={ELEMENT_SIZE.MEDIUM} onChange={onChange} enableDeselect disableSort>
        {options.map(({id, description}) => (
          <Radio key={id} value={id} label={description} />
        ))}
      </RadioGroup>
    </SideSheetContentSection>
  );
};

export default BinaryEditTaskAnswerSection;
