import React from 'react';
import cn from 'classnames';
import {Button, BUTTON_THEMES, Chip, ELEMENT_SIZE, Icon, SelectField, InputField} from 'ht-styleguide';
import * as T from './pageHeader.types';
import styles from './pageHeader.styles.scss';

/**
 * Used to keeps things consistent if using `TitleComponent`.
 */
export const PageHeaderTitleText = ({title, className}: T.PageHeaderTitleTextProps) => <h1 className={cn('h4 n800', className)}>{title}</h1>;

/**
 * Button component for PageHeader.
 * This is a wrapper around styleguide's `<Button />` but with a fixed size.
 */
export const PageHeaderButton = ({disabled, onClick, iconName, theme = BUTTON_THEMES.SECONDARY, label}: T.PageHeaderButtonProps) => (
  <Button disabled={disabled} icon={iconName} onClick={onClick} theme={theme} inlineBlock small>
    {label}
  </Button>
);

/**
 * Link component for PageHeader.
 * This is similar to the `<PageHeaderActionButton />` but with a different style.
 */
export const PageHeaderTextLink = ({label, iconName, onClick}: T.PageHeaderTextLinkProps) => (
  <Button className={styles.textLinkButton} icon={iconName} onClick={onClick} theme={BUTTON_THEMES.SECONDARY} inlineBlock small>
    {label}
  </Button>
);

/**
 * Chip component for PageHeader.
 * This is a wrapper around styleguide's `<Chip />` but with a fixed size of `ELEMENT_SIZE.S`.
 */
export const PageHeaderChip = ({label, iconName, type, variant, ...rest}: T.PageHeaderChipProps) => (
  <Chip size={ELEMENT_SIZE.S} iconName={iconName} type={type} variant={variant} {...rest}>
    {label}
  </Chip>
);

/**
 * Icon component for PageHeader.
 * This is a wrapper around styleguide's `<Icon />` but with a fixed icon size and a default color of variables.$ht-navy-700.
 */
export const PageHeaderIcon = ({className, iconName, onClick = () => {}, ...rest}: T.PageHeaderIconProps) => (
  <button onClick={onClick} className="plainButton pointer" type="button" aria-label={`Icon name: ${iconName}`}>
    <Icon className={cn(styles.icon, className)} name={iconName} {...rest} />
  </button>
);

export const PageHeaderSelectField = (props: T.PageHeaderSelectFieldProps) => <SelectField elementSize={ELEMENT_SIZE.S} {...props} />;

export const PageHeaderInputField = (props: T.PageHeaderInputFieldProps) => <InputField elementSize={ELEMENT_SIZE.S} {...props} />;
