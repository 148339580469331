import {FULFILLED} from 'global/constants/common';
import {useHookHelpersForInvalidation} from './projects.useHookHelpersForInvalidation';

/**
 * Invalidate pagination queries. For passthru scnenarios that don't use hooks.
 * We could use a contextapi, but for now, this will do.
 */
export const useInvalidateQueriesPagination = () => {
  const {queryClient, queryKeysPagi, queryKeysStats, queryKeysJobsPagi, dispatch} = useHookHelpersForInvalidation();

  const invalidatePaginatedQueries = (type: string) => {
    if (type.endsWith(FULFILLED)) {
      queryClient.invalidateQueries(queryKeysPagi);
      queryClient.invalidateQueries(queryKeysStats);

      return true;
    }

    if (type.endsWith('jobs')) {
      queryClient.invalidateQueries(queryKeysJobsPagi);

      return true;
    }

    return false;
  };

  return {
    invalidatePaginatedQueries,
    dispatch,
  };
};
