import React from 'react';
import cn from 'classnames';
import {useNavigate} from 'react-router-dom';

// Hooks & Utils
import {useProjectsRouteParams} from 'features/MultiDwellingUnits/Pages/Projects/hooks';
import {useFilteredProjects} from 'features/MultiDwellingUnits/queries/query.project.paginatedFiltered';
import {routeToProjectPage} from './utils/utils.routeToProjectPage';
import {useSetFilters} from 'features/MultiDwellingUnits/Pages/Projects/Parts/MDUProjectsFilters/MDUProjectsFilters.hooks';
import {convertSnakeToCamelAndCapitalize} from 'features/MultiDwellingUnits/MDU.utils';

// Components
import {useStickyContainer} from 'components/Elements/StickyContainer/useStickyContainer';
import PageHeader from 'components/Elements/PageHeader';
import {NavPageTypes, NavTypes, ProjectDetailsShallow} from 'features/MultiDwellingUnits/MDU.types';
import MDUProjectsFilters from './Parts/MDUProjectsFilters';
import MDUProjectsTable from './Parts/MDUProjectsTable/MDUProjectsTable';
import MDUProjectsStats from './Parts/MDUProjectsStats';
import {STATUS_PAGES_ATTRIBUTES} from './Projects.constants';

const AllStatusPage = () => {
  const navigate = useNavigate();

  /* Hooks */
  const {columnKeys, filterKeys, pageFilters, statKeys, title, defaultLabel, projectsPage, projectsType} = useProjectsRouteParams();
  const {StickyContainer} = useStickyContainer({});
  const {
    filters: {statuses = []},
  } = useSetFilters(projectsPage);

  const hasStats = statKeys.length > 1;

  const lowercaseTitle = title.toLowerCase();
  const allProjectsPageTitle = STATUS_PAGES_ATTRIBUTES[NavPageTypes.ALL_STATUSES].title(NavTypes.ALL);
  const allMyProjectsPageTitle = STATUS_PAGES_ATTRIBUTES[NavPageTypes.ALL_STATUSES].title(NavTypes.OWN);
  const showTitle = [allProjectsPageTitle, allMyProjectsPageTitle].includes(title) ? `${convertSnakeToCamelAndCapitalize(statuses, ',', false)} projects` : lowercaseTitle; // Handle the case where the title is "All Projects" or "All My Projects"
  const introTextNoResults = NavTypes.OWN !== projectsType ? 'There are ' : 'You have ';
  const emptyStateHeader = `${introTextNoResults} no ${statuses.length > 0 ? showTitle : 'projects that matched your search criteria'}`;
  const handleRouteToProjectPage = (project: ProjectDetailsShallow) => routeToProjectPage({navigate, project});

  /* Queries */
  const {results, pagination} = useFilteredProjects({
    pageFilters,
    pageType: projectsPage,
  });

  return (
    <div className={cn('marginBottom-huge')}>
      <PageHeader title={title} collapsible={hasStats}>
        {hasStats && <MDUProjectsStats pageType={projectsPage} pageFilters={pageFilters} statKeys={statKeys} defaultLabel={defaultLabel} />}
      </PageHeader>
      <StickyContainer>
        <MDUProjectsFilters statusSelectTypeMultiple pageType={projectsPage} filterKeys={filterKeys} />
      </StickyContainer>
      <MDUProjectsTable
        pageType={projectsPage}
        columnKeys={columnKeys}
        emptyStateHeader={emptyStateHeader}
        isActionableRow
        onRowClickCb={handleRouteToProjectPage}
        projectData={results}
        paginationDataProp={pagination}
      />
      <div className="marginBottom-huge" />
    </div>
  );
};

export default React.memo(AllStatusPage);
