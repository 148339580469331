import {useMemo} from 'react';
import issuesSlice from 'features/Issues/Issues.ducks';
import useIssuesRouteParams from 'features/Issues/hooks/useIssuesRouteParams';
import useIssuesTableSearchFieldsQuery from 'features/Issues/queries/query.issues.table.searchFields';
import {useSelector} from 'hooks/useAppSelector';

/**
 * Of searchFieldsData, only return fields that are allowed to be displayed in the table based on
 * redux state and inherent defaults.
 */
const useVisibleTableSearchFields = () => {
  const {issuesNavType} = useIssuesRouteParams();
  const {data: searchFieldsData = []} = useIssuesTableSearchFieldsQuery();
  const {columnState} = useSelector(issuesSlice.selectors.getPagesStateByKey(issuesNavType)) || {};

  const visibleSearchFieldsData = useMemo(() => {
    return searchFieldsData.filter(fieldData => {
      const {name, search} = fieldData;
      const {table_default} = search || {};
      return 'visible' in (columnState?.[name] || {}) ? columnState[name].visible : table_default;
    });
  }, [searchFieldsData, columnState]);

  return {
    visibleSearchFieldsData,
  };
};

export default useVisibleTableSearchFields;
