import {AnyAction, createAsyncThunk, createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'store';
import {htToast} from 'ht-styleguide';
import {v4 as uuidv4} from 'uuid';
import qs from 'qs';
import pick from 'lodash/pick';
import keys from 'lodash/keys';
import assign from 'lodash/assign';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {noop} from 'utils/event';

import APIS from 'global/apis';

import {IHash, ResponseError} from 'types/base.types';
import {
  IMduProjectsState,
  MduGetAllProjectsResponse,
  ProjectCreationFormFields,
  MduGenericReponse,
  MduFetchPayoutAdjustmentResponse,
  ProjectPaymentAdjRequestObj,
  MduFetchProjectResponse,
  ProjectDetailsFormFields,
  MduCreateProjectResponse,
  NavTypes,
  ProjectGroup,
  ProjectService,
  AddSkuDetails,
  SearchFilters,
  CustomFilterTypes,
  FormattedDateByUserTimezoneInputs,
  SelectOption,
  TRawFilterKeys,
  ProjectServiceResponse,
  MduGetProjectTypesResponse,
  TUnitApiParams,
  TMduFetchJobResponse,
} from 'features/MultiDwellingUnits/MDU.types';

import * as requestLoaderSlice from 'features/App/RequestLoader/requestLoader.ducks';
import {getNewQuestion} from 'features/Booking/Parts/QA/qa.ducks';

import * as TSQ from 'features/Questions/types';

import createQuestionsDucks, {defaultSelectedSku} from 'features/Questions/ducks';
import {SelectedSku, PostItem} from 'features/Questions/types';

/* Hooks into the global question set for add sku */
const questionsDucks = createQuestionsDucks('MDU');

/*
 ************ ACTIONS
 */
export const normalizeFiltersToParams = <T>(filters: T) => {
  return {search: filters ? qs.stringify(filters, {arrayFormat: 'brackets', encode: false}) : ''};
};

export const asyncActions = {
  getAllProjects: createAsyncThunk<MduGetAllProjectsResponse, IHash<any> | null, {rejectValue: ResponseError}>('mduProjects/getAllProjects', async (filters, {rejectWithValue}) => {
    const filterSearchObj = normalizeFiltersToParams<IHash<any> | null>(filters);
    const response = await APIS.mdu.getAllProjects(filterSearchObj);
    if (response.err) return rejectWithValue(response.err as ResponseError);
    return response;
  }),
  createProject: createAsyncThunk<MduCreateProjectResponse, {project: Partial<ProjectCreationFormFields>}, {rejectValue: ResponseError}>(
    'mduProjects/createProject',
    async (projectDetails, {dispatch, rejectWithValue}) => {
      // Initial project creation only requires partial data. Remaining data will be filled in later in the flow.
      dispatch(requestLoaderSlice.actions.loading(true));
      const response = await APIS.mdu.createProject(projectDetails);
      dispatch(requestLoaderSlice.actions.loading(false));
      if (response.err) return rejectWithValue(response.err as ResponseError);
      return response;
    }
  ),
  deleteProject: createAsyncThunk<MduGenericReponse, {id?: number}, any>('mduProjects/deleteProject', async ({id} = {id: undefined}, {rejectWithValue, dispatch, getState}) => {
    const {mdu} = getState() as {mdu: {projects: IMduProjectsState}};
    const {actionItem = null} = mdu.projects;
    /* If user passed in id, use it - otherwise get at from a set project actionItem */
    const deleteId = id || actionItem?.entity?.id;

    dispatch(requestLoaderSlice.actions.loading(true));
    const response = await APIS.mdu.deleteProject({id: deleteId});
    dispatch(requestLoaderSlice.actions.loading(false));

    if (response.err) return rejectWithValue(response.err as ResponseError);

    htToast('Project Deleted Successfully');

    return response as MduGenericReponse;
  }),
  approveProject: createAsyncThunk<MduGenericReponse, {id?: number}, any>('mduProjects/approveProject', async ({id} = {id: undefined}, {rejectWithValue, dispatch, getState}) => {
    const {mdu} = getState() as {mdu: {projects: IMduProjectsState}};
    const {actionItem = null} = mdu.projects;
    /* If user passed in id, use it - otherwise get at from a set project actionItem */
    const approveId = id || actionItem?.entity?.id;

    dispatch(requestLoaderSlice.actions.loading(true));
    const response = await APIS.mdu.approveProject({id: approveId});
    dispatch(requestLoaderSlice.actions.loading(false));

    if (response.err) return rejectWithValue(response.err as ResponseError);

    htToast('Project Approved Successfully');

    return response as MduGenericReponse;
  }),
  /* Getting the fabled redux toolkit circular rootstate issue. Had to use any here */
  searchProjects: createAsyncThunk<MduGetAllProjectsResponse, string, any>('mduProjects/searchProjects', async (term, {rejectWithValue, dispatch, getState}) => {
    const {mdu} = getState() as {mdu: {projects: IMduProjectsState}};
    const {searchTerm = ''} = mdu.projects;
    const filterSearchObj = normalizeFiltersToParams<IHash<any> | null>({search: searchTerm, pagination: {per_page: 100}});
    dispatch(requestLoaderSlice.actions.loading(true));
    const response = await APIS.mdu.getAllProjects(filterSearchObj);
    dispatch(requestLoaderSlice.actions.loading(false));

    if (response.err) return rejectWithValue(response.err as ResponseError);
    return response;
  }),
  fetchProjectDetails: createAsyncThunk<MduFetchProjectResponse, {projectId: number | string}, {rejectValue: ResponseError}>(
    'mduProjects/fetchProjectDetails',
    async ({projectId}, {rejectWithValue, dispatch}) => {
      dispatch(requestLoaderSlice.actions.loading(true));
      // Initial project creation only requires partial data. Remaining data will be filled in later in the flow.
      const response = await APIS.mdu.getProject({id: projectId});
      dispatch(requestLoaderSlice.actions.loading(false));

      if (response.err) return rejectWithValue(response.err as ResponseError);
      return response;
    }
  ),
  updateProject: createAsyncThunk<
    MduFetchProjectResponse,
    {projectId: number | string; projectDetails: {project: Partial<ProjectDetailsFormFields>}; onSuccess?: BaseAnyFunction},
    {rejectValue: ResponseError}
  >('mduProjects/updateProject', async ({projectId, projectDetails, onSuccess = noop}, {rejectWithValue, dispatch}) => {
    dispatch(requestLoaderSlice.actions.loading(true));
    const response = await APIS.mdu.updateProject({id: projectId}, projectDetails);
    dispatch(requestLoaderSlice.actions.loading(false));

    if (response.err) return rejectWithValue(response.err as ResponseError);

    await onSuccess();
    return response;
  }),
  createProjectPayoutAdjustment: createAsyncThunk<MduFetchPayoutAdjustmentResponse, {projectId: number | string; requestObj: ProjectPaymentAdjRequestObj}, {rejectValue: ResponseError}>(
    'mduProjects/createProjectPayoutAdjustment',
    async ({projectId, requestObj}, {rejectWithValue}) => {
      const response = await APIS.mdu.createProjectPayoutAdjustment({projectId}, requestObj);
      if (response.err) return rejectWithValue(response.err as ResponseError);
      return response;
    }
  ),
  updateProjectPayoutAdjustment: createAsyncThunk<
    MduFetchPayoutAdjustmentResponse,
    {projectId: number | string; payoutAdjustmentId: number | string; requestObj: ProjectPaymentAdjRequestObj},
    {rejectValue: ResponseError}
  >('mduProjects/updateProjectPayoutAdjustment', async ({projectId, payoutAdjustmentId, requestObj}, {rejectWithValue}) => {
    const response = await APIS.mdu.updateProjectPayoutAdjustment({projectId, payoutAdjustmentId}, requestObj);
    if (response.err) return rejectWithValue(response.err as ResponseError);
    return response;
  }),
  // TODO: Fix the response type
  deleteProjectPayoutAdjustment: createAsyncThunk<{status: string}, {projectId: number | string; payoutAdjustmentId: number | string}, {rejectValue: ResponseError}>(
    'mduProjects/deleteProjectPayoutAdjustment',
    async ({projectId, payoutAdjustmentId}, {rejectWithValue}) => {
      // Initial project creation only requires partial data. Remaining data will be filled in later in the flow.
      const response = await APIS.mdu.deleteProjectPayoutAdjustment({projectId, payoutAdjustmentId});
      if (response.err) return rejectWithValue(response.err as ResponseError);
      return response;
    }
  ),
  getProjectTypes: createAsyncThunk<MduGetProjectTypesResponse, void, {rejectValue: ResponseError}>(
    'mduProjects/getProjectTypes',
    async (_, {rejectWithValue}) => {
      const response = await APIS.mdu.getProjectTypes();
      if (response.err) return rejectWithValue(response.err as ResponseError);

      return response;
    },
    {
      condition: (_, {getState}) => {
        const {mdu} = getState() as RootState;
        if (mdu?.projects?.projectTypes?.length) {
          // Has projectTypes, no need to fire api
          return false;
        }
        return true;
      },
    }
  ),
  /*
    ----------------------------------------------------------------
    ------------------------- SERVICE LEVEL ------------------------
    ----------------------------------------------------------------
  */
  createService: createAsyncThunk<ProjectServiceResponse, {projectId: string; groupId: string; selectedSku: SelectedSku}, {rejectValue: ResponseError}>(
    'mduProjects/createService',
    async ({projectId, groupId, selectedSku}, {rejectWithValue, dispatch}) => {
      dispatch(requestLoaderSlice.actions.loading(true));
      const response = await APIS.mdu.createService({projectId, groupId}, {project_service: selectedSku});
      dispatch(requestLoaderSlice.actions.loading(false));

      if (response.err) return rejectWithValue(response.err as ResponseError);

      /* Get the updated project */
      dispatch(asyncActions.fetchProjectDetails({projectId}));

      return response;
    }
  ),

  updateService: createAsyncThunk<ProjectServiceResponse, {projectId: string; groupId: string | number; serviceId: string | number; projectService: ProjectService}, {rejectValue: ResponseError}>(
    'mduProjects/updateService',
    async ({projectId, groupId, serviceId, projectService}, {rejectWithValue, dispatch}) => {
      dispatch(requestLoaderSlice.actions.loading(true));
      const response = await APIS.mdu.updateService({projectId, groupId, serviceId}, {project_service: projectService});
      dispatch(requestLoaderSlice.actions.loading(false));

      if (response.err) return rejectWithValue(response.err as ResponseError);

      /* Get the updated project */
      dispatch(asyncActions.fetchProjectDetails({projectId}));

      return response;
    }
  ),

  deleteService: createAsyncThunk<MduFetchProjectResponse, {projectId: string; groupId: string; serviceId: string}, {rejectValue: ResponseError}>(
    'mduProjects/deleteService',
    async ({projectId, groupId, serviceId}, {rejectWithValue, dispatch}) => {
      dispatch(requestLoaderSlice.actions.loading(true));
      const response = await APIS.mdu.deleteService({projectId, groupId, serviceId});
      dispatch(requestLoaderSlice.actions.loading(false));

      if (response.err) return rejectWithValue(response.err as ResponseError);

      /* Get the updated project */
      dispatch(asyncActions.fetchProjectDetails({projectId}));

      return response;
    }
  ),
  /*
    ----------------------------------------------------------------
    ------------------------- UNIT/JOBS LEVEL ------------------------
    ----------------------------------------------------------------
  */
  approveUnit: createAsyncThunk<TMduFetchJobResponse, TUnitApiParams, {rejectValue: ResponseError}>('mduProjects/approveJob', async ({projectId, unitId}, {rejectWithValue, dispatch, getState}) => {
    const {mdu} = getState() as {mdu: {projects: IMduProjectsState}};
    const {actionItem = null} = mdu.projects;
    /* If user passed in id, use it - otherwise get at from a set project actionItem */
    const id = unitId || actionItem?.entity?.id;

    dispatch(requestLoaderSlice.actions.loading(true));
    const response = await APIS.mdu.approveUnit({project_id: projectId, unit_id: id});
    dispatch(requestLoaderSlice.actions.loading(false));

    if (response.err) return rejectWithValue(response.err as ResponseError);

    /* Get the updated project */
    dispatch(asyncActions.fetchProjectDetails({projectId}));

    htToast('Job Approved');
    return response;
  }),
  reopenUnit: createAsyncThunk<TMduFetchJobResponse, TUnitApiParams, {rejectValue: ResponseError}>('mduProjects/reopenJob', async ({projectId, unitId}, {rejectWithValue, dispatch, getState}) => {
    const {mdu} = getState() as {mdu: {projects: IMduProjectsState}};
    const {actionItem = null} = mdu.projects;
    /* If user passed in id, use it - otherwise get at from a set project actionItem */
    const id = unitId || actionItem?.entity?.id;

    dispatch(requestLoaderSlice.actions.loading(true));
    const response = await APIS.mdu.reopenUnit({project_id: projectId, unit_id: id});
    dispatch(requestLoaderSlice.actions.loading(false));

    if (response.err) return rejectWithValue(response.err as ResponseError);

    /* Get the updated project */
    dispatch(asyncActions.fetchProjectDetails({projectId}));

    htToast('Job reopened');
    return response;
  }),

  deleteUnit: createAsyncThunk<TMduFetchJobResponse, TUnitApiParams, {rejectValue: ResponseError}>('mduProjects/deleteJob', async ({projectId, unitId}, {rejectWithValue, dispatch, getState}) => {
    const {mdu} = getState() as {mdu: {projects: IMduProjectsState}};
    const {actionItem = null} = mdu.projects;
    /* If user passed in id, use it - otherwise get at from a set project actionItem */
    const id = unitId || actionItem?.entity?.id;

    dispatch(requestLoaderSlice.actions.loading(true));
    const response = await APIS.mdu.deleteUnit({project_id: projectId, unit_id: id});
    dispatch(requestLoaderSlice.actions.loading(false));

    if (response.err) return rejectWithValue(response.err as ResponseError);

    /* Get the updated project */
    dispatch(asyncActions.fetchProjectDetails({projectId}));

    htToast('Job deleted');
    return response;
  }),

  /* MISC */
  postItemPrice: createAsyncThunk<PostItem, {selectedSku: SelectedSku; selectedSkuDraftArray?: boolean}, {rejectValue: ResponseError}>(
    'mduProjects/postItemPrice',
    async ({selectedSku}, {rejectWithValue, dispatch}) => {
      dispatch(requestLoaderSlice.actions.loading(true));
      const response = await APIS.booking.cart.itemPrice({item: selectedSku, inHome: false});
      dispatch(requestLoaderSlice.actions.loading(false));

      if (response.err) return rejectWithValue(response.err as ResponseError);

      return response;
    }
  ),
};

/*
*******************************************************
  INITIAL STATE
*******************************************************
*/
export const PAGINATION_INITIAL_STATE = {
  current_page: 0,
  items_per_page: 25,
};

const BULK_ACTIONS_INITIAL_STATE = {
  selectAll: false,
  showBanner: false,
  selectedFlatRows: {},
  exclusionIds: {},
  totalRowsSelected: 0,
};

export const PROJECTS_INITIAL_STATE: IMduProjectsState = {
  rawFilters: {
    partnerId: {},
    propertyOwnerId: {},
    projectManagerId: {},
    leadTechId: {},
    project_group_ids: [],
  },
  filters: {
    [SearchFilters.OWN]: {
      all_statuses: {},
      draft: {},
      ready_to_launch: {},
      approved: {},
      upcoming: {},
    },
    [SearchFilters.ALL]: {
      all_statuses: {},
      draft: {},
      ready_to_launch: {},
      approved: {},
      upcoming: {},
    },
    [SearchFilters.ARCHIVE]: {},
    [SearchFilters.JOBS]: {},
  },
  bulkActions: {
    ...BULK_ACTIONS_INITIAL_STATE,
  },
  filteredProjects: [],
  pagination: {
    ...PAGINATION_INITIAL_STATE,
  },
  currentProject: {},
  searchedProjects: [],
  searchTerm: '',
  actionItem: null,
  actionItemBulk: null,
  addSku: {...questionsDucks.getAddSkuInitialState(), selectedSkuArray: []},
  parentLocation: null,
  projectTypes: null,
  templateCreationDraft: {
    existing: [],
    new: [],
  },
  /**
   * Toggle sidesheets within the MDU space
   */
  sideSheetKey: null,
};

export const initialState: IMduProjectsState = {
  ...PROJECTS_INITIAL_STATE,
};
/*
*******************************************************
  SLICE
*******************************************************
*/

const {actions: mduActions, reducer} = createSlice({
  name: 'mduProjects',
  initialState,
  reducers: {
    setRawFilterValues: (state, action: PayloadAction<Partial<{[key in TRawFilterKeys]: any}>>) => {
      /*
       * This lays across both verticals (own/all), as the ids are the same across both.
       * We also don't need to clear out as the "filters" will drive the accessing here.
       * */
      const [[key, values]] = Object.entries(action.payload) as Array<[TRawFilterKeys, any]>;
      const rawFiltersAtKey = values ? state.rawFilters[key as TRawFilterKeys] : {};

      /* when certain keys pass an array of values instead */
      if ((['project_group_ids', 'propertyOwnerId', 'partnerId', 'projectManagerId', 'leadTechId'] as TRawFilterKeys[]).includes(key) && Array.isArray(values)) {
        const normalizedRawObject = ((values as SelectOption[]) || []).reduce((obj, v) => ({...obj, ...{[v.value]: v.label}}), {});
        state.rawFilters = {
          ...state.rawFilters,
          [key]: {
            ...rawFiltersAtKey,
            ...normalizedRawObject,
          },
        };
      } else {
        /* Single Object values, non-array */
        state.rawFilters = {
          ...state.rawFilters,
          [key]: {...rawFiltersAtKey, [((values || {}) as SelectOption).value]: ((values || {}) as SelectOption).label},
        };
      }
    },
    setParentLocation: (state, action) => {
      const {location} = action.payload;
      state.parentLocation = location;
    },
    /* VERY SIMPLE IMPLEMENTATION. You'll have to appropriately merge/update etc.. */
    updateProjectsFilter: (state, action) => {
      const {key, filters} = action.payload;
      /* Not the most elegant solution */
      const {pathname} = window.location;
      const navType = Object.values(NavTypes).find(nType => pathname.includes(`/projects/${nType}`)) || NavTypes.OWN;
      const currentFilter = state.filters[navType];
      state.filters = {...state.filters, [navType]: {...currentFilter, [key]: filters}};
    },
    updateCustomFilter: (state, action: {payload: {filters: IHash<any>; key: CustomFilterTypes}}) => {
      const {key, filters} = action.payload;
      const hasSearch = state.filters[key]?.search;
      const currentSearchFilter = hasSearch ? {search: hasSearch} : {};

      state.filters = {...state.filters, [key]: {...currentSearchFilter, ...filters}};
    },
    updateFilteredProjects: (state, action) => {
      const {projects} = action.payload;
      state.filteredProjects = projects;
    },
    updateCurrentProject: (state, action) => {
      const {id} = action.payload;
      if (id) {
        state.currentProject = action.payload;
      }
    },
    clearPagination: state => {
      state.pagination = {};
    },
    clearFilteredProjects: state => {
      state.filteredProjects = [];
    },
    clearSearchedValued: state => {
      state.searchedProjects = [];
      state.searchTerm = '';
    },
    setSearchTerm: (state, action) => {
      const {searchTerm} = action.payload;
      state.searchTerm = searchTerm || '';
    },
    /* --- Set attributes to open a modal or slide: Used when concern is outside the parent  ----  */
    setActionItemModalSlide: (state, action) => {
      const {entity = null, modalKey, modalType = ''} = action.payload;
      state.actionItem = {
        entity,
        modalKey,
        modalType,
      };
    },
    removeActionItemModalSlide: state => {
      state.actionItem = null;
    },
    setActionItemBulkModalSlide: (state, action) => {
      const {key} = action.payload;
      state.actionItemBulk = {
        key,
      };
    },
    removeActionItemBulkModalSlide: state => {
      state.actionItemBulk = null;
    },
    /* --- End Set attributes to open a modal or slide ----  */
    /* ADD SKU SPECIFIC ITEMS */
    updateSkusAndQuestions: (state, action) => {
      const {sku, questions} = action.payload;
      questionsDucks.questionsSkuAdapter.upsertOne(state.addSku.skus, sku);
      questionsDucks.questionsAdapter.upsertOne(state.addSku.questions, {id: sku.id, questions});
    },
    setSelectedSku: (state, action) => {
      /* New mode matches sku with empty set questions */
      const {skuId, isEditMode, groupId, serviceId, isJobBulkDraftMode, isJobDraftUUID} = action.payload;
      const sku = state.addSku.skus.entities[skuId];

      if (skuId) {
        if (isEditMode) {
          const {questions} = state.addSku.questions.entities[sku.id];
          const questionsBase = getNewQuestion(questions);
          const groups = state.currentProject?.projectGroups || [];
          const group = groups.find((g: ProjectGroup) => Number(g.id) === Number(groupId)) || ({} as ProjectGroup);
          const service = (() => {
            /* DRAFT BULK JOBS */
            if (isJobBulkDraftMode) {
              return state.templateCreationDraft.new
                .filter(n => +n.id === +groupId)
                .reduce((all: ProjectService, n: ProjectGroup) => {
                  const tempService = (n.projectServices as ProjectService[])?.find(ps => ps.id === +serviceId);

                  return tempService!;
                }, {} as ProjectService);
            }

            /* DRAFT SINGLE JOB */
            if (isJobDraftUUID) {
              return (
                state.addSku.selectedSkuArray.find((s: TSQ.SelectedSku) => {
                  return [isJobDraftUUID, serviceId].includes(s.uuidDraft);
                }) || ({} as TSQ.SelectedSku)
              );
            }

            /* DEFAULT */
            return (
              (group.projectServices &&
                (group.projectServices as ProjectService[]).find(ps => {
                  return Number(ps.id) === Number(serviceId);
                })) ||
              ({} as ProjectService)
            );
          })();

          const {questions: prevSelectedQuestions, quantity, uuidDraft = ''} = service as any;

          state.addSku.selectedSku = pick(
            {
              ...sku,
              skuId: sku.id,
              quantity,
              uuidDraft,
            },
            keys(defaultSelectedSku)
          ) as TSQ.SelectedSku;
          state.addSku.selectedSku.questions = {...questionsBase, ...prevSelectedQuestions};
        } else {
          state.addSku.selectedSku = assign(
            {},
            state.addSku.selectedSku,
            pick(
              {
                ...sku,
                skuId: sku.id,
                quantity: 1,
              },
              keys(state.addSku.selectedSku)
            )
          );
          const {questions} = state.addSku.questions.entities[sku.id];
          state.addSku.selectedSku.questions = getNewQuestion(questions);
        }
      }
    },
    /* ------------------------------------------------------------------------ */
    /* ------------ BULK (Checkbox/Pagination) ACTION ACTIONS ----------------- */
    /* ------------------------------------------------------------------------ */
    updateBulkOperation: (state, action) => {
      const {selectedFlatRows} = action.payload;
      const [[key, arrayOfRows]] = Object.entries(selectedFlatRows as IHash<number[]>);
      const isSelectAll = state.bulkActions.selectAll;
      const currentPageRows = state.bulkActions.selectedFlatRows[key];

      // 1. SelectAll (( the exceptions are not working atm ))
      /* a. We have to lock the selections and allow for exclusions only. */
      if (isSelectAll && Array.isArray(currentPageRows) && currentPageRows.length > 0) {
        /* b. store the diff as an exclusion */
        state.bulkActions.showBanner = true;
        state.bulkActions.exclusionIds[key] = currentPageRows.filter((id: number) => !arrayOfRows.includes(id));
        state.bulkActions.selectedFlatRows = {
          ...state.bulkActions.selectedFlatRows,
          [key]: currentPageRows,
        };
      } else {
        /* c. do we show banner: see if number of selected is equal to number per page */
        if (((arrayOfRows as number[]) ?? []).length === state.pagination.items_on_current_page) {
          state.bulkActions.showBanner = true;
        } else {
          state.bulkActions.showBanner = false;
        }

        /* Initialize the rows or non selectAll selections */
        state.bulkActions.selectedFlatRows = {
          ...state.bulkActions.selectedFlatRows,
          [key]: arrayOfRows,
        };
      }
    },
    updateBulkOperationDirect: (state, action) => {
      // This operation doesn't take exclusions into consideration.
      const {selectedFlatRows} = action.payload;
      const [[key, arrayOfRows]] = Object.entries(selectedFlatRows as IHash<number[]>);
      state.bulkActions.selectedFlatRows = {
        ...state.bulkActions.selectedFlatRows,
        [key]: arrayOfRows,
      };
    },
    updateClearBulkFlatRows: (state, action) => {
      const retainCurrentPage = action.payload?.retainCurrentPage || false;
      const currentPageIndex = state.pagination.current_page;
      const currentPageRows = state.bulkActions.selectedFlatRows[currentPageIndex] || [];

      state.bulkActions.selectedFlatRows = {
        ...BULK_ACTIONS_INITIAL_STATE.selectedFlatRows,
        ...((retainCurrentPage && {[currentPageIndex]: currentPageRows}) || {}),
      };
    },
    updateClearAllBulkOperation: (state, action) => {
      state.bulkActions = BULK_ACTIONS_INITIAL_STATE;
      state.pagination = {
        // ...state.pagination,
        ...PAGINATION_INITIAL_STATE,
        ...(action.payload && action.payload),
      };
    },
    updateSelectAllBulkOperation: (state, action) => {
      const {selectAll} = action.payload;

      state.bulkActions.selectAll = selectAll;
      state.bulkActions.showBanner = selectAll;
    },
    updatePaginationAttributes: (state, action) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
    },
    /* --------------- END BULK ACTION ACTIONS ------------ */

    /* -------------------------------------------------------------- */
    /* ---------- DRAFT: TEMPLATE/JOBS BULK CREATION ACTIONS -------- */
    /* -------------------------------------------------------------- */
    // Adds an existing template to draft state
    createTemplateCreationExistingDraft: (state, action) => {
      /* Add an "existing" draftType because in the template view, they behave differently */
      const filteredExisting = state.templateCreationDraft.existing.filter(draft => draft.id !== action.payload.id);
      state.templateCreationDraft.existing = filteredExisting.concat({...action.payload, draftType: 'existing'});
    },
    // Removes a draft line item from the templates list
    deleteGroupFromList: (state, action) => {
      const {id} = action.payload;

      const updatedNewDrafts = state.templateCreationDraft.existing.filter(draft => draft.id !== id);
      const updatedExistingDrafts = (state.templateCreationDraft.new || []).filter(draft => +draft.id !== +id);

      state.templateCreationDraft.existing = updatedExistingDrafts;
      state.templateCreationDraft.new = updatedNewDrafts;
    },
    // We take the response project group and put it into draft state (new)
    createTemplateCreationNewDraft: (state, action) => {
      state.templateCreationDraft.new.push(action.payload);
    },
    // After every projectgroup update, we have to resaturate the draft state (new)
    updateTemplateCreationNewDraft: (state, action) => {
      const {projectGroup} = action.payload;

      const updatedDrafts = (state.templateCreationDraft.new || []).filter(draft => +draft.id !== +projectGroup.id);
      state.templateCreationDraft.new = updatedDrafts.concat(projectGroup);
    },
    // Remove all drafts, both new & existing
    removeAllTemplateCreationNewDraft: state => {
      state.templateCreationDraft.new = [];
      state.templateCreationDraft.existing = [];
    },
    // we do this when we want to duplicate a template, or preseed the new draft
    addTemplateCreationNewDraft: (state, action) => {
      state.templateCreationDraft.new = [action.payload];
    },
    // Allow to update qty in the draft projectgroups
    updateTemplateCreationQtyDraft: (state, action) => {
      const {id, unitsNumber} = action.payload;
      const isMatchHelper = (draft: ProjectGroup) => {
        if (+draft.id === +id) {
          draft.unitsNumber = unitsNumber || 1;
        }
        return draft;
      };
      const existingTemplates = state.templateCreationDraft.existing.map(draft => isMatchHelper(draft));
      const newTemplates = state.templateCreationDraft.new.map(draft => isMatchHelper(draft));

      state.templateCreationDraft.existing = existingTemplates;
      state.templateCreationDraft.new = newTemplates;
    },
    // Remove all drafts, both new & existing
    deleteAllDraftGroups: state => {
      state.templateCreationDraft.existing = [];
      state.templateCreationDraft.new = [];
    },
    // When user saves a template, we push it into the currentProjects templateGroups array so we don't have to refetch
    addTemplateToCurrentProjects: (state, action) => {
      const addedTemplate = action.payload;
      state.currentProject.projectGroups.push(addedTemplate);
    },
    /* ----------- END DRAFT: TEMPLATE/JOBS BULK CREATION ACTIONS  ------- */
    removeDraftedSelectSkuFromArray: (state, action) => {
      const {uuidDraft} = action.payload;
      const updatedSelectedSkuDrafts = state.addSku.selectedSkuArray.filter(draft => draft.uuidDraft !== uuidDraft);

      state.addSku.selectedSkuArray = updatedSelectedSkuDrafts;
    },
    removeAllDraftedSelectSkuFromArray: state => {
      state.addSku.selectedSkuArray = [];
    },
    /* ----------- JOBS: SINGLE JOB TEMPLATE/SERVICES  ------- */

    addSelectedSkuToDraftArray: state => {
      const {uuidDraft} = state.addSku.selectedSku || {};
      /*
       * We have a condition in which we need to save the selectedSkus so we can bulk add them.
       * This is different than "draft", as this assignment happens to a single template and bulk added at once,
       * without a template assocation, since its blank. /jobs/create. Also, the BE doesnt' have a concept of
       * draft of this "single" state, so we have to manage it here, partly.
       *
       * */
      /* 1. If no "uuidDraft", create one */
      let newSku = state.addSku.selectedSku;
      if (!uuidDraft) {
        newSku = {
          ...newSku,
          uuidDraft: uuidv4(),
        };
      }

      /* 2. Add sku to the selectedSkuArray draft  */
      state.addSku.selectedSkuArray = (state.addSku.selectedSkuArray || []).filter(sku => sku.uuidDraft !== newSku.uuidDraft).concat(newSku);
    },

    /* ----------- END JOBS: SINGLE JOB TEMPLATE/SERVICES  ------- */
    ...questionsDucks.getReducers(), // Ducks that are shared between this feature and the regular booking flow
    setSideSheetKey: (state, action: PayloadAction<IMduProjectsState['sideSheetKey']>) => {
      state.sideSheetKey = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(asyncActions.getAllProjects.fulfilled, (state, action) => {
      const {
        data: {projects, pagination},
      } = action.payload;
      state.pagination = pagination;
      state.filteredProjects = projects;
    });
    builder.addCase(asyncActions.searchProjects.fulfilled, (state, action) => {
      const {projects} = action.payload.data;
      state.searchedProjects = projects;
    });
    builder
      .addCase(asyncActions.createProject.fulfilled, (state, action) => {
        const {project} = action.payload.data;
        state.currentProject = project;
      })
      .addCase(asyncActions.fetchProjectDetails.fulfilled, (state, action) => {
        const {project} = action.payload.data;
        state.currentProject = project;
      })
      .addCase(asyncActions.updateProject.fulfilled, (state, action) => {
        const {project} = action.payload.data;
        state.currentProject = project;
      })
      .addCase(asyncActions.approveProject.fulfilled, (state, action) => {
        const {project} = action.payload.data;
        state.currentProject = project;
      })
      .addCase(asyncActions.postItemPrice.fulfilled, (state, action) => {
        const {selectedSku} = state.addSku;
        const {data} = action.payload;
        // const {selectedSkuDraftArray} = action.meta?.arg;
        const newSku = {...selectedSku, totalPrice: data?.breakdown?.amount_without_subsidy_formatted || 'error'};
        state.addSku.selectedSku = newSku;
      })
      .addCase(asyncActions.getProjectTypes.fulfilled, (state, action) => {
        const {
          data: {project_types: projectTypes},
        } = action.payload;
        state.projectTypes = projectTypes;
      })
      .addMatcher(
        (action): action is AnyAction =>
          [asyncActions.deleteProject.fulfilled, asyncActions.updateService.fulfilled, asyncActions.createService.fulfilled].some(actionCreator => actionCreator.match(action)),
        () => {}
      );
  },
});

/*
*******************************************************
  SELECTORS & SELECTOR METHODS
*******************************************************
*/
const getProjectsState = (state: any): IMduProjectsState => state.mdu.projects;
const getAddSkuState = (state: any): AddSkuDetails => state.mdu.projects.addSku;

/*
*******************************************************
  EXPORTS
*******************************************************
*/

dayjs.extend(utc);
dayjs.extend(timezone);

const selectors = {
  getProjectsState: createSelector(getProjectsState, projects => projects),
  getProjectsFilterState: (projectsType: NavTypes) => createSelector(getProjectsState, projects => projects?.filters[projectsType || NavTypes.ALL]),
  getFilterByCustomKeyState: (key: CustomFilterTypes) => createSelector(getProjectsState, projects => projects?.filters[key]),
  getFilteredProjects: createSelector(getProjectsState, projects => projects?.filteredProjects),
  getPagination: createSelector(getProjectsState, projects => projects?.pagination),
  getSearchResults: createSelector(getProjectsState, projects => projects?.searchedProjects),
  getSearchTerm: createSelector(getProjectsState, projects => projects?.searchTerm),
  getCurrentProject: createSelector(getProjectsState, projects => projects?.currentProject),
  getActionItem: createSelector(getProjectsState, projects => projects?.actionItem),
  getActionItemBulk: createSelector(getProjectsState, projects => projects?.actionItemBulk),
  getAddSkuState: createSelector(getAddSkuState, addSku => addSku),
  getAddSkuSelectedSku: createSelector(getAddSkuState, addSku => addSku.selectedSku),
  getAddSkuQuestions: createSelector(getAddSkuState, addSku => addSku.questions),
  getLeadTechInfo: createSelector(getProjectsState, projects => projects?.currentProject.lead_tech),
  getFormattedDateByUserTimezone: ({date, format}: FormattedDateByUserTimezoneInputs) => {
    return createSelector(getProjectsState, () => {
      return dayjs
        .utc(date || Date.now())
        .tz(/* user?.timezone || */ 'America/Los_Angeles')
        .format(format);
    });
  },
  getParentLocation: createSelector(getProjectsState, projects => projects?.parentLocation),
  getRawFilters: createSelector(getProjectsState, projects => projects?.rawFilters || {}),
  getProjectTypes: createSelector(getProjectsState, projects => projects?.projectTypes || []),
  /* Bulk Checkbox Actions */
  getBulkActionsShowBanner: createSelector(getProjectsState, projects => projects?.bulkActions.showBanner),
  getBulkActionsSelectAll: createSelector(getProjectsState, projects => projects?.bulkActions.selectAll),
  getBulkActions: createSelector(getProjectsState, projects => projects?.bulkActions),
  getBulkActionIds: createSelector(getProjectsState, projects => {
    /* Exclusion list is not working as selectAll is a best to manage. Return emtpy array */
    if (projects?.bulkActions.selectAll) {
      return [];
    }

    return Object.entries(projects?.bulkActions.selectedFlatRows).reduce((acc: number[], [, values]) => {
      const allIds = acc.concat(values);

      return allIds;
    }, [] as number[]);
  }),
  getBulkActionIdsByPage: createSelector(getProjectsState, projects => {
    // 1. if selectAll, get total projects minus exclusions
    const currentPaginationPage = projects?.pagination.current_page;
    const selectedIdsByPage = projects.bulkActions.selectedFlatRows[currentPaginationPage] ?? [];

    if (projects?.bulkActions.selectAll) {
      const exclusionIds = projects?.bulkActions.exclusionIds[currentPaginationPage] || [];
      const s = selectedIdsByPage.filter(id => !exclusionIds.includes(id));

      return s;
    }

    // 2. if not selectAll, return the ids by page
    return selectedIdsByPage;
  }),
  getBulkActionSelectedFlatRowIdsByPage: createSelector(getProjectsState, projects => {
    const currentPaginationPage = projects?.pagination.current_page;
    const selectedIdsByPage = projects.bulkActions.selectedFlatRows[currentPaginationPage] ?? [];
    return selectedIdsByPage;
  }),
  getBulkActionSelectedFlatRowIds: createSelector(getProjectsState, projects => {
    return projects.bulkActions.selectedFlatRows;
  }),
  /* Pagination Specific Items */
  getPaginationPage: createSelector(getProjectsState, projects => projects?.pagination.current_page),
  getPaginationItemsPerPage: createSelector(getProjectsState, projects => projects?.pagination.items_per_page),
  getPaginationTotalItems: createSelector(getProjectsState, projects => projects?.pagination.total_items),
  getPaginationTotalPages: createSelector(getProjectsState, projects => projects?.pagination.total_pages),
  getPaginationTotalSelected: createSelector(getProjectsState, projects => {
    // 1. if selectAll, get total projects minus exclusions
    if (projects?.bulkActions.selectAll) {
      const sumOfExclusions = Object.values(projects?.bulkActions.exclusionIds).reduce((acc, curr) => acc + curr.length, 0);
      return +projects?.pagination.total_items - +sumOfExclusions;
    }

    // 2. if not selectAll, get total selected
    return Object.values(projects?.bulkActions.selectedFlatRows).reduce((acc, curr) => acc + curr.length, 0);
  }),
  /* Draft State */
  getDraftProjectGroupsNew: createSelector(getProjectsState, projects => projects?.templateCreationDraft.new),
  getDraftProjectGroups: createSelector(getProjectsState, projects => {
    return [...(projects?.templateCreationDraft?.new ?? []), ...(projects.templateCreationDraft?.existing ?? [])].filter(Boolean);
  }),
  getDraftTotalJobs: createSelector(getProjectsState, projects => {
    const sum = [...projects?.templateCreationDraft.new, ...projects?.templateCreationDraft.existing].reduce((ttl, curr) => ttl + +curr.unitsNumber!, 0);

    return sum;
  }),
  /* This is a blank addition for a single */
  getSelectedSkuDraftArray: createSelector(getProjectsState, projects => projects?.addSku.selectedSkuArray),
  /* This is a draft state of multiple services to template */
  getDraftNewTemplateArray: createSelector(getProjectsState, projects => projects?.templateCreationDraft.new),
  /* Get the template from the draft state by groupId */
  getDraftNewTemplateByIdFromArray: (id: string) => createSelector(getProjectsState, projects => projects?.templateCreationDraft.new.find(draftTemplate => +draftTemplate.id === +id)),
  getSideSheetKey: createSelector(getProjectsState, projects => projects.sideSheetKey),
};

/*
 ************ EXPORTS
 */

export default {
  actions: {...mduActions, ...asyncActions},
  selectors,
  reducer,
  initialState,
};
