import React, {memo, useCallback, useRef, useState} from 'react';
import cn from 'classnames';
import {animated} from '@react-spring/web';
import {Icon, Accordion, IAccordionProps} from 'ht-styleguide';
import omit from 'lodash/omit';
import NotificationBadge from 'components/Elements/NotificationBadge';
import {ISidebar, ISidebarEntryProps, ISidebarEntry} from './Sidebar.types';
import styles from './Sidebar.scss';
import {useControlledState, useSidebarStateController} from './Sidebar.hooks';
import SidebarHeader from './Parts/SidebarHeader';
import {SidebarHideShowButton} from './SidebarHideShowButton';
import {useExcludeLink} from 'features/Splitio/splitio.hooks';
import {useAdminHubLayoutState} from '../AdminHub.layout.state.provider';

const SidebarEntry = ({icon, entry, active, onClick, dataTestId = 'SidebarEntry', useAccordion}: ISidebarEntryProps) => {
  const nonAccordionAdjustment = cn({
    'marginY-tiny1': !useAccordion,
  });

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li className={cn('p2 n000 font-weight-medium', nonAccordionAdjustment, styles.entry, active && styles.active)} onClick={onClick} data-testid={dataTestId}>
      {icon ? <Icon name={icon} className={cn('icon', styles.icon)} /> : null}
      {entry.label}
      {!!entry.badge?.value && <NotificationBadge value={entry.badge.value as string} className={styles.badge_base} variant={entry.badge?.variant} />}
    </li>
  );
};

/**
 * Sidebar component has fixed positioning on the left part of the screen.
 *
 * Make sure you leave space on the left in the component you are using so the content does not overlap.
 *
 * You can use the default header (`SidebarHeader`) component by setting `headerProps` prop,
 * but you can also use a custom one, by using the `before` prop.
 */
const Sidebar = ({
  active: controlled,
  after = null,
  before = null,
  callout,
  className,
  dataTestId = 'Sidebar',
  headerProps,
  menu,
  onActiveChange,
  useAccordion = false,
  isCollapsible = false,
}: ISidebar) => {
  const [, setActive] = useControlledState<string>(controlled, onActiveChange);
  const [topScrolled] = useState(false);
  const [bottomScrolled] = useState(false);
  const [accordionState, setAccordionState] = useState(() => {
    return menu?.reduce((acc, section) => {
      if (section.label) acc[section.label] = true;
      return acc;
    }, {} as {[key: string]: boolean});
  });

  const {isLeftColumnCollapsed: isParentCollapsed, toggleLeftColumnCollapsed: toggleParentCollapsed} = useAdminHubLayoutState();

  const buttonRef = useRef<HTMLDivElement>(null);

  const {isPeeking, setIsPeeking, showCollapseButton, onMouseEnter, onMouseLeave, onCollapseButtonClick, animationStyleProps} = useSidebarStateController({isParentCollapsed, toggleParentCollapsed});

  /* Splits */
  const {isExcluded} = useExcludeLink('issues');

  /* Methods */
  const onEntryClick = useCallback(
    (entry: ISidebarEntry) => {
      if (isParentCollapsed && isPeeking) {
        setIsPeeking(false);
      }
      setActive(entry.key);
      entry.onClick?.();
    },
    [setActive, isParentCollapsed, isPeeking, setIsPeeking]
  );

  /**
   * This is a wrapper component that will render the children inside an accordion if `useAccordion` is true.
   * @param props
   * @constructor
   */
  const WithAccordion = (props: IAccordionProps & {children: JSX.Element}): JSX.Element => {
    if (useAccordion) {
      return <Accordion {...omit(props, 'children')}>{props.children}</Accordion>;
    }

    return props.children;
  };

  /**
   * This function determines if the bottom border of the accordion should be rendered.
   * @param numberOfAccordions
   * @param sectionIndex
   */
  const determineBottomAccordionBorder = (numberOfAccordions: number, sectionIndex: number) => {
    if (sectionIndex < numberOfAccordions - 1) {
      return true;
    }

    return false;
  };

  /**
   * This callback allows us to get accordion state, and save it in the state.
   * @param key
   */
  const onToggle = (key: string) => (value: boolean) => {
    setAccordionState(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    // Do not trigger "peeking" behavior if it is collapsed and the user is hovering on the button
    const isHoveringOnButton = buttonRef.current?.contains(e.target as Node);
    if (isHoveringOnButton && isParentCollapsed) return;
    onMouseEnter();
  };

  return (
    <animated.div style={animationStyleProps} className={cn(styles.sidebar, className)} data-testid={dataTestId} onMouseEnter={handleMouseEnter} onMouseLeave={onMouseLeave}>
      {isCollapsible && showCollapseButton ? (
        <SidebarHideShowButton isParentCollapsed={isParentCollapsed} onClick={onCollapseButtonClick} dataTestId={`${dataTestId}-hideShowButton`} ref={buttonRef} />
      ) : null}
      {callout && (
        <div className={styles.callout} data-testid={`${dataTestId}-callout`}>
          {callout}
        </div>
      )}
      {headerProps && <SidebarHeader {...headerProps} dataTestId={`${dataTestId}-header`} />}
      {before}
      <div
        className={cn(styles.body, useAccordion ? 'paddingX-small2' : 'paddingX-small', {
          [styles.topScrolled]: topScrolled,
          [styles.bottomScrolled]: bottomScrolled,
          [styles.hideOverflowY]: isParentCollapsed && !isPeeking,
        })}
      >
        {menu?.map(({label, entries}, sectionIndex) => (
          <div key={`section-${sectionIndex}`} className={styles.section} data-testid={`${dataTestId}-section-${sectionIndex}`}>
            <WithAccordion
              opened={accordionState![label!]}
              onToggle={onToggle(label!)}
              noContentPadding
              noIndentPadding
              className={styles.accordion}
              key={label}
              title={label}
              iconVertical
              iconPosition="right"
            >
              <ul className={styles.entries}>
                {(entries || []).map(entry => {
                  if (isExcluded(entry.key)) return null;

                  return (
                    <SidebarEntry
                      useAccordion={useAccordion}
                      icon={entry.icon}
                      key={`entry-${entry.key}`}
                      entry={entry}
                      active={entry.active!}
                      onClick={() => onEntryClick(entry)}
                      dataTestId={`${dataTestId}-entry-${entry.key}`}
                    />
                  );
                })}
              </ul>
            </WithAccordion>
            {determineBottomAccordionBorder(menu.length, sectionIndex) && (
              <div className={cn(useAccordion ? 'paddingTop-small marginBottom-small' : 'paddingTop-small1 marginBottom-small1', styles.accordionBorder)} />
            )}
          </div>
        ))}
      </div>
      {after}
    </animated.div>
  );
};

export default memo(Sidebar);
