import React, {useEffect, useRef} from 'react';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import {useDispatch, useSelector} from 'react-redux';
import {Icon, Chip} from 'ht-styleguide';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {useFilteredProjects} from 'features/MultiDwellingUnits/queries/query.project.paginatedFiltered';

import styles from './search.styles.scss';

type SearchInputProps = {
  onSearchClose: () => void;
};

const SearchInput = ({onSearchClose}: SearchInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const searchTerm = useSelector(mduProjectsSlice.selectors.getSearchTerm) as string;
  /* This should be a bit more robust. It's a little haphazard. But I assumed they'd want pagi on search but not sure. */
  const {results} = useFilteredProjects({pageFilters: {search: searchTerm}, options: {enabled: Boolean(searchTerm)}});
  const focusInput = () => {
    if (inputRef.current) inputRef.current.focus();
  };

  useEffect(() => {
    const clearSearchedValue = () => {
      dispatch(mduProjectsSlice.actions.clearSearchedValued());
    };
    focusInput();

    return clearSearchedValue;
  }, []);

  const handleSearchClose = () => {
    dispatch(mduProjectsSlice.actions.clearSearchedValued());
    onSearchClose();
  };

  const handleSearchInput = debounce(
    async (value: string) => {
      await dispatch(mduProjectsSlice.actions.setSearchTerm({searchTerm: value}));
    },
    1000,
    {trailing: true, leading: false}
  );

  return (
    <div className={cn(styles.searchInputContainer, 'paddingLeft-huge1 paddingRight-huge1')} onClick={focusInput}>
      <input
        onChange={e => {
          const value = e?.target?.value;
          handleSearchInput(value);
        }}
        className={cn('p0 paddingLeft-small', styles.searchInput)}
        ref={inputRef}
        placeholder="Search for a project"
      />
      <div className="flex align-items-center marginLeft-small1">
        {Boolean(results?.length) && <Chip>{results.length} results</Chip>}
        <Icon name="v2-close-icon" onClick={handleSearchClose} className="cursorPointer marginLeft-small1 paddingRight-small" />
      </div>
    </div>
  );
};

export default React.memo(SearchInput);
