import {useQueryClient} from 'react-query';
import {BASE_DISPATCH, getUnassignedOrders, getUnassignedStats} from '../queries/query.dispatch.keys';

const useInvalidateUnassignedListQuery = () => {
  const queryClient = useQueryClient();

  const invalidateUnassignedListQuery = () => {
    queryClient.invalidateQueries({queryKey: [BASE_DISPATCH, getUnassignedOrders], exact: false});
    queryClient.invalidateQueries({queryKey: [BASE_DISPATCH, getUnassignedStats], exact: false});
  };

  return {invalidateUnassignedListQuery};
};

export default useInvalidateUnassignedListQuery;
