import React, {ChangeEvent, useState, useRef} from 'react';
import {useSelector} from 'react-redux';
import cn from 'classnames';
import {useParams, useNavigate} from 'react-router-dom';
import debounce from 'lodash/debounce';
import {SideSheet, InputField, Button, BUTTON_THEMES, Panel, EmptyState, Icon} from 'ht-styleguide';
import {useAppDispatch} from 'hooks/useAppDispatch';

/* Hooks / Ducks */
import {useOnCloseActionableModals} from 'features/MultiDwellingUnits/MDU.hooks';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {useCurrentProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/CurrentProject.hooks';
import useOutsideClick from 'hooks/useOutsideClick';

/* Queries / Mutations */
import {useAddUnitToJobMutation} from 'features/MultiDwellingUnits/queries/mutation.jobs.addUnit';
import {useCheckGroupCurrentlyExistsMutation} from 'features/MultiDwellingUnits/queries/mutation.jobs.checkGroupCurrentlyExists';
import {usePerformProjectActionMutation} from 'features/MultiDwellingUnits/queries/mutation.projects.bulkActions';
import {useValidateUnitNameMutation} from 'features/MultiDwellingUnits/queries/mutation.jobs.validateUnitName';
import {useValidateTemplateNameMutation} from 'features/MultiDwellingUnits/queries/mutation.project.validateTemplateName';

/* Components */
import CreatingBulkExistingTemplates from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/Jobs/AddJobs/Components/Jobs.Create.Existing';
import ModalCreateTemplateConfirm, {ConfirmAddTemplateModalType} from 'features/MultiDwellingUnits/Parts/Modals/MDU.Jobs.CreateSingleConfirm';
import ModalGroupExists, {ConfirmDuplicateTemplateModalType, ModalGroupExistsType} from 'features/MultiDwellingUnits/Parts/Modals/MDU.Modal.GroupExistsAlert';
import JobsCreateSuccessDisplay from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/Jobs/AddJobs/Components/Jobs.Create.Success';
import TemplateJobsPartnerSkusDropdown from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/Parts/TemplateJobs.PartnerSkus';
import SideSheetAddSku from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/Parts/TemplateJobs.Draft.SideSheetAddSku';
import {MenuListItem, MenuPanel, MenuUnorderedList} from 'components/Elements/Menu';

/* Constants / Utils */
import {noop, stopImmediatePropagation, stopPropagationOnDiv} from 'utils/event';
import {mduProjectPathByStage, mduProjectSingleJobCreate, mduProjectSingleJobCreatePathSku, mduProjectSingleJobServicesSkuEditPath} from 'global/paths';

import {MduUseParamsTypes, ProjectGroup, TActionItem, TGroupSelectSkuServices, TSingleActionTypes} from 'features/MultiDwellingUnits/MDU.types';
import {SelectedSku} from 'features/Questions/types';
import {TGroupService} from 'features/MultiDwellingUnits/Parts/GroupServicesBoxInfo/index';

const DefaultModalType = {
  type: '',
};

type TTemplateSelectedState = ProjectGroup | Record<string, never>;
type TUuidDraft = number | string | null;

const DEBOUNCE_TIMER = 500;
export const CreateJobSideSheet = () => {
  /* Local State */
  const [unitName, setUnitName] = useState('');
  const [templateName, setTemplateName] = useState<string | null>(null);
  const [templateSelected, setTemplateSelected] = useState<TTemplateSelectedState>({});
  const [blankTemplateSelected, setBlankTemplateSelected] = useState(false);
  const [modalType, setModalType] = useState(DefaultModalType);
  const [confirmationDisplay, setConfirmationDisplay] = useState(false);
  const [activeGroupMenuId, setActiveGroupMenuId] = useState<TUuidDraft>();
  const [inputErrorBlankView, setInputErrorBlankView] = useState(false);
  const [inputTemplateNameError, setInputTemplateNameError] = useState(false);

  /* Hooks */
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {removeActionItemModalSlide} = useOnCloseActionableModals();
  const currentProject = useCurrentProject();
  const {mode, skuId} = useParams<MduUseParamsTypes>();
  const skuMenuRef: React.RefObject<HTMLDivElement> = useRef(null);

  /* Queries / Mutations */
  const addDraftUnitToJob = useAddUnitToJobMutation();
  const performProjectAction = usePerformProjectActionMutation({entity: 'jobs', currentProjectId: currentProject?.id});
  const checkGroupCurrentlyExistsMutation = useCheckGroupCurrentlyExistsMutation();
  const validateUnitNameMutation = useValidateUnitNameMutation();
  const validateTemplateNameMutation = useValidateTemplateNameMutation();

  /* Selectors */
  const actionItem: TActionItem = useSelector(mduProjectsSlice.selectors.getActionItem);
  const selectedSku = useSelector(mduProjectsSlice.selectors.getAddSkuSelectedSku);
  const selectedDraftSkus = useSelector(mduProjectsSlice.selectors.getSelectedSkuDraftArray);

  /* Constants */
  const hasSelectedDraftSkus = Array.isArray(selectedDraftSkus) && selectedDraftSkus.length;
  const hasSelectedSku = Object.keys(templateSelected).length > 0;
  const lineItemServices = hasSelectedDraftSkus ? selectedDraftSkus : templateSelected.projectServices;
  const servicesDisplay = lineItemServices?.map(service => {
    if ('id' in service) {
      return {
        quantity: service.quantity || 1,
        name: service.sku?.name,
        key: JSON.stringify(service),
        id: service.id,
      };
    }

    return {
      quantity: service.quantity || 1,
      name: service.name,
      key: JSON.stringify(service),
      id: service.uuidDraft,
    };
  }) as TGroupService[];

  /* ------------------------------------------ */
  /* ------ ADD EXISTING TEMPLATE/GROUP ------- */
  /* ------------------------------------------ */
  /* User selects from existing job templates */
  const setJobTemplate = (templateSelection: ProjectGroup) => {
    /* 1. Set template selected && reset blank template */
    setTemplateSelected(templateSelection);
    setBlankTemplateSelected(false);
    /* 2. Remove any "blank" considerations. We are in another flow */
    dispatch(mduProjectsSlice.actions.removeAllDraftedSelectSkuFromArray());
  };

  const onSubmitExistingTemplate = async () => {
    /* 1. Submit template to draft state in ducks */
    await dispatch(mduProjectsSlice.actions.createTemplateCreationExistingDraft(templateSelected));
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    onConfirmAddTemplate();
  };

  const validateUnitName = debounce(
    name => {
      if (!name) return;

      validateUnitNameMutation.mutate(
        {unitName: name},
        {
          onSuccess: data => {
            if (!data.allowed) setInputErrorBlankView(true);
          },
        }
      );
    },
    DEBOUNCE_TIMER,
    {leading: false, trailing: true}
  );

  /* Unwrap the evt and set unit name user enters */
  const setName = (evt: ChangeEvent<HTMLInputElement>) => {
    const name = evt?.target?.value;
    setUnitName(name);
    setInputErrorBlankView(false);
    validateUnitName(name);
  };

  /* -------------------------------------------------- */
  /* ------ ADD BLANK TEMPLATE/GROUP METHODS ---------- */
  /* -------------------------------------------------- */

  const onHandleSetBlankTemplateSelected = () => {
    setBlankTemplateSelected(true);
    /* 1. Remove existing saves on a new template */
    setTemplateSelected({});
  };

  const validateTemplateName = debounce(
    name => {
      if (!name) return;

      validateTemplateNameMutation.mutate(
        {templateName: name},
        {
          onSuccess: data => {
            if (!data.allowed) setInputTemplateNameError(true);
          },
        }
      );
    },
    DEBOUNCE_TIMER,
    {leading: false, trailing: true}
  );

  const setNameTemplate = (evt: ChangeEvent<HTMLInputElement>) => {
    validateTemplateName(evt?.target?.value);
    setInputTemplateNameError(false);
    setTemplateName(evt?.target?.value);
  };

  /* -------------------------------------------------- */
  /* ----------------- BASE METHODS ------------------- */
  /* -------------------------------------------------- */
  const getServicesQueryFormat = () => {
    return selectedDraftSkus.reduce((all: TGroupSelectSkuServices[], draftSku) => {
      const sku = {
        id: draftSku.skuId,
        quantity: draftSku.quantity,
        questions: draftSku.questions,
      };

      return all.concat({sku});
    }, []);
  };

  const onHandleOutsideClick = () => {
    setActiveGroupMenuId(null);
  };

  /* Closing or otherwise want to start fresh, clear all user selections & assumptions */
  const onHandleCleanUpState = () => {
    /* Clean up state - reset everything: any more beefy, we can move to useReducer */
    setModalType(DefaultModalType);
    setConfirmationDisplay(false);
    setBlankTemplateSelected(false);
    setTemplateSelected({} as TTemplateSelectedState);
    setUnitName('');
    setTemplateName('');
    setInputErrorBlankView(false);
    dispatch(mduProjectsSlice.actions.removeAllDraftedSelectSkuFromArray());
  };

  /* Close slidesheet and return to base jobs page */
  const onHandleCloseSlideOutCreateModal = () => {
    onHandleCleanUpState();
    removeActionItemModalSlide();
    navigate(mduProjectPathByStage(currentProject.id, 'jobs'));
  };

  /*
   * -----------------------------------------------------------------
   *  SUBMIT Selected/Blank Template. Both operations will happen here.
   * -----------------------------------------------------------------
   */
  const onConfirmAddTemplate = () => {
    // 1. submit selection: sorta draft state
    addDraftUnitToJob.mutate(
      {
        unit_name: unitName,
        project_group_id: templateSelected.id,
        project_group_name: templateName,
        ...(!templateSelected.id && {
          services: getServicesQueryFormat(),
        }),
      },
      {
        onSuccess: ({id}) => {
          performProjectAction.mutate(
            {
              projectActionId: id,
              params: null,
            },
            {
              onSuccess: () => {
                // 1. Confirm display and "confirm" on the action
                setConfirmationDisplay(true);
                setModalType(DefaultModalType);
              },
              onError: () => {
                // 1. Error. Lets show an input display if blank modal view.
                setInputErrorBlankView(true);
              },
            }
          );
        },
      }
    );
  };
  /**
   * -----------------------------------------------------------------
   * CHECK EXISTENCE OF GROUP
   *
   * Check to verify the submitted group exists.
   *  - It exists: Show error'ish modal
   *  - If Not: Show confirm modal
   *  -----------------------------------------------------------------
   */
  const onConfirmExistingGroup = () => {
    checkGroupCurrentlyExistsMutation.mutate(getServicesQueryFormat(), {
      onSuccess: response => {
        const {data} = response;
        /* If project_group is null, we are good to proceed: https://github.com/HelloTech/hellotech/pull/5620 */
        if (!data?.project_group) {
          setModalType({type: ConfirmAddTemplateModalType});
        } else {
          setTemplateName(data.project_group.name);
          setModalType({type: ConfirmDuplicateTemplateModalType});
        }
      },
    });
  };

  /* Methods: SideSheet */
  const onHandleRedirectToBasePath = () => {
    /*
       If there is a skuId present, remove it from the selectedSkus Array. User didn't finish.
       Note: If edit mode, we don't want to "remove", just discard the updates. Keep original in place.
    */
    if (skuId && selectedSku.uuidDraft && mode !== 'edit') {
      /* we need that uuid to properly remove the selectedSku from Array */
      dispatch(mduProjectsSlice.actions.removeDraftedSelectSkuFromArray(selectedSku));
    }

    navigate(mduProjectSingleJobCreate(currentProject.id));
  };

  /* Methods SideSheet : AddSku */
  const onHandleCreateMutation = () => {
    /* 1. Update our selectedSku Array drafts */
    dispatch(mduProjectsSlice.actions.addSelectedSkuToDraftArray());

    /* 2. We save as we go, so we can just redirect */
    navigate(mduProjectSingleJobCreate(currentProject.id));
  };

  useOutsideClick(skuMenuRef, onHandleOutsideClick);

  if (!actionItem || !currentProject) return null;

  /* ------ Components ------- */
  const DisplaySelectedSkusDraft = () => {
    const sharedHeaderStyles = 'uppercase paddingRight-tiny n700 font-weight-medium overline-medium';
    const columnLeftClassHeader = cn(sharedHeaderStyles, 'paddingLeft-small n700');

    const columnRightClassContext = cn('p2 n700 font-weight-regular');
    const columnContent = 'p2 font-weight-regular n900 paddingLeft-small ellipses';

    return (
      <div className="flex flex-direction-column paddingTop-medium">
        <div className="flex justify-content-space-between">
          <p className={columnLeftClassHeader}>Service</p>
        </div>
        {selectedDraftSkus.map((sku: SelectedSku) => {
          return (
            <div key={sku.uuidDraft} className="flex justify-content-space-between border-bottom-2 paddingY-small1">
              <p className={columnContent}>{sku.name}</p>
              <div className={columnRightClassContext}>
                <div className="position relative">
                  <div ref={skuMenuRef} onClick={stopPropagationOnDiv}>
                    <Icon name="more" className="paddingRight-small cursorPointer" onClick={() => setActiveGroupMenuId(sku.uuidDraft)} />
                  </div>
                  {activeGroupMenuId === sku.uuidDraft && (
                    <MenuPanel>
                      <MenuUnorderedList>
                        <MenuListItem
                          dataTestId="menu-edit-job-info"
                          onClick={e => {
                            stopImmediatePropagation(noop)(e);
                            navigate(mduProjectSingleJobServicesSkuEditPath(currentProject.id, String(sku.skuId), sku.uuidDraft!));
                          }}
                        >
                          Edit Service
                        </MenuListItem>
                        <MenuListItem
                          dataTestId="menu-edit-job-info"
                          onClick={e => {
                            stopImmediatePropagation(noop)(e);
                            dispatch(mduProjectsSlice.actions.removeDraftedSelectSkuFromArray({uuidDraft: sku.uuidDraft}));
                          }}
                        >
                          Delete Service
                        </MenuListItem>
                      </MenuUnorderedList>
                    </MenuPanel>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const SkuTemplateSelectionView = () => {
    if (blankTemplateSelected) {
      return (
        <>
          <div className="p font-weight-medium paddingTop-medium1 paddingBottom-medium">Add Services to Job</div>
          <TemplateJobsPartnerSkusDropdown onHandleSelection={sku => navigate(mduProjectSingleJobCreatePathSku(currentProject.id, sku.value as string))} />

          {hasSelectedDraftSkus ? (
            <DisplaySelectedSkusDraft />
          ) : (
            <Panel noShadow largeBorderRadius className="border padding-large marginTop-small flex flex-direction-column align-items-center">
              <EmptyState iconName="layers" title="No services" text="Manually add services to this job" />
            </Panel>
          )}
        </>
      );
    }

    return null;
  };
  const FooterContent = () => {
    const hasValidBlankTemplate = hasSelectedDraftSkus;
    const submitMethod = hasValidBlankTemplate ? onConfirmExistingGroup : onSubmitExistingTemplate;
    const determineToDisable = (): boolean => {
      // template selected
      if (blankTemplateSelected) {
        return !hasValidBlankTemplate || inputErrorBlankView || !unitName;
      }

      // existing selected
      return !unitName || !templateSelected?.id || inputErrorBlankView;
    };

    if (confirmationDisplay) {
      return (
        <Button theme={BUTTON_THEMES.PRIMARY} onClick={onHandleCloseSlideOutCreateModal}>
          Close
        </Button>
      );
    }

    return (
      <Button disabled={determineToDisable()} theme={BUTTON_THEMES.PRIMARY} onClick={submitMethod}>
        Add Job to Project
      </Button>
    );
  };

  return (
    <>
      <SideSheet
        subheaderText={currentProject.name!}
        headerText="Add Single Job"
        footerContent={<FooterContent />}
        hide={onHandleCloseSlideOutCreateModal}
        onRequestClose={onHandleCloseSlideOutCreateModal}
        isOpen={actionItem.modalKey === TSingleActionTypes.ADD_SINGLE_JOB}
      >
        {confirmationDisplay ? (
          <JobsCreateSuccessDisplay />
        ) : (
          <>
            <InputField error={inputErrorBlankView ? 'Unit Name already exists' : ''} containerClass="paddingBottom-medium" value={unitName || ''} onChange={setName} label="Unit Name" />
            <CreatingBulkExistingTemplates
              blankTemplateSelected={blankTemplateSelected}
              reset={!hasSelectedDraftSkus && !hasSelectedSku}
              actions={{setJobTemplate, setBlankTemplate: onHandleSetBlankTemplateSelected}}
            />
            <SkuTemplateSelectionView />
            <ModalCreateTemplateConfirm
              services={servicesDisplay}
              isVisible={modalType.type === ConfirmAddTemplateModalType}
              onCloseActionItem={onHandleCleanUpState}
              dispatchAction={onConfirmAddTemplate}
              onTemplateNameChange={setNameTemplate}
              templateName={templateName}
              nameError={inputTemplateNameError}
            />
            <ModalGroupExists
              isVisible={modalType.type === ConfirmDuplicateTemplateModalType}
              services={selectedDraftSkus}
              templateName={templateName}
              onCloseActionItem={onHandleCleanUpState}
              dispatchAction={onConfirmAddTemplate}
              modalGroupExistsType={ModalGroupExistsType.singleJob}
            />
          </>
        )}
      </SideSheet>
      <SideSheetAddSku
        onHandleEditMutation={onHandleCreateMutation}
        onHandleCreateMutation={onHandleCreateMutation}
        onCloseCallback={onHandleRedirectToBasePath}
        selectedSkuDraftArray
        isJobDraftUUID
      />
    </>
  );
};
