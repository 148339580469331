import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import dayjsTimezone from 'dayjs/plugin/timezone';
import {TDataTableColumnDef, TTableActionMenuLineItem} from 'components/Elements/DataTable/dataTable.types';
import {actionMenuColumn} from 'components/Elements/DataTable/utils/columns.utils';
import {sortByDate, sortNumerically} from 'components/Elements/DataTable/utils/sort.utils';
import {IconCellComponent} from 'components/Elements/DataTable/CellComponent';
import {ACTION_MENU_COL_ID} from 'components/Elements/DataTable/dataTable.constants';
import {UNASSIGNED_ORDERS_COLUMN_LABELS} from 'features/Dispatch/dispatch.constants';
import {TUnassignedOrder, UnassignedOrdersColumnKeys, TUnassignedOrdersColumnKeysValues} from 'features/Dispatch/dispatch.types';
import {shortMonthWithDayAndHour, shortMonthWithDayAndHourMinute} from 'global/constants/common';
import {orderDetailsPath} from 'global/paths';
import {formatPriceTrimZero} from 'utils/currency';
import {openUrlInNewTab} from 'utils/window/window';
import styles from './unassignedTable.styles.scss';

dayjs.extend(utc);
dayjs.extend(dayjsTimezone);

const HELLOTECH_PARTNER_NAME = 'HelloTech';

/*
*******************************************************
  generateColumnsData
*******************************************************
*/

type TGenerateColumnsData = {
  omitKeys: Array<TUnassignedOrdersColumnKeysValues>;
};

type TColumnData =
  | {
      [key in TUnassignedOrdersColumnKeysValues]: TDataTableColumnDef<TUnassignedOrder>;
    }
  | {
      actionMenu: TDataTableColumnDef<TUnassignedOrder>;
    };

export const generateColumnsData = ({omitKeys = []}: TGenerateColumnsData) => {
  const muiTableBodyCellProps: Pick<TDataTableColumnDef<TUnassignedOrder>, 'muiTableBodyCellProps'> = {
    muiTableBodyCellProps: ({row}) => {
      return {
        onClick: () => openUrlInNewTab({url: orderDetailsPath(row.original.id)}),
      };
    },
  };

  const columnData: TColumnData = {
    [UnassignedOrdersColumnKeys.Priority]: {
      id: UnassignedOrdersColumnKeys.Priority,
      accessorKey: UnassignedOrdersColumnKeys.Priority,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.Priority], // something is needed to not throw a warning
      Header: <></>, // designs call for empty header
      Cell: ({table, row}) => {
        const priorityValue = row.original.priority;
        const iconProps = {name: '', className: ''};

        switch (priorityValue) {
          case 'high': {
            iconProps.name = 'high-priority-solid';
            iconProps.className = 'alertRed700';
            break;
          }
          case 'medium': {
            iconProps.name = 'medium-priority-solid';
            iconProps.className = 'deepYellow700';
            break;
          }
          default:
            break;
        }

        return iconProps.name && <IconCellComponent {...iconProps} table={table} />;
      },
      enableSorting: false,
      enableResizing: false,
      iconColumn: true,
      ...muiTableBodyCellProps,
    },
    [UnassignedOrdersColumnKeys.Id]: {
      accessorKey: UnassignedOrdersColumnKeys.Id,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.Id],
      ...muiTableBodyCellProps,
    },
    [UnassignedOrdersColumnKeys.LastAvailabilityIn]: {
      id: UnassignedOrdersColumnKeys.LastAvailabilityIn,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.LastAvailabilityIn],
      accessorFn: originalRow => originalRow.last_availability_in,
      Cell: ({cell}) => {
        const days = cell.getValue();
        const isValidNumber = typeof days === 'number' && !Number.isNaN(days);
        return (
          <>
            {cell.getValue()} {isValidNumber ? `days` : ''}
          </>
        );
      },
      tooltipContent: ({row}) => {
        const {timezone, last_availability_at} = row.original;
        return dayjs(last_availability_at).tz(timezone).format(shortMonthWithDayAndHour);
      },
      sortingFn: sortNumerically,
      ...muiTableBodyCellProps,
    },
    [UnassignedOrdersColumnKeys.OfferCount]: {
      id: UnassignedOrdersColumnKeys.OfferCount,
      accessorKey: UnassignedOrdersColumnKeys.OfferCount,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.OfferCount],
      enableColumnDragging: true,
      enableColumnOrdering: true,
      sortingFn: sortNumerically,
      ...muiTableBodyCellProps,
    },
    [UnassignedOrdersColumnKeys.Payout]: {
      id: UnassignedOrdersColumnKeys.Payout,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.Payout],
      accessorFn: () => null,
      Cell: ({row}) => {
        const {current_geek_cut = 0, max_geek_cut = 0} = row.original;
        let color = '';
        color = current_geek_cut < max_geek_cut ? 'successGreen700' : color;
        color = current_geek_cut > max_geek_cut ? 'alertRed700' : color;
        return (
          <>
            <span className={color}>{formatPriceTrimZero(current_geek_cut)}</span> / {formatPriceTrimZero(max_geek_cut)}
          </>
        );
      },
      ...muiTableBodyCellProps,
    },
    [UnassignedOrdersColumnKeys.FNStatus]: {
      id: UnassignedOrdersColumnKeys.FNStatus,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.FNStatus],
      accessorFn: () => null,
      Cell: ({row}) => {
        const {field_nation} = row.original;
        if (!field_nation) return null;
        const {status, url} = field_nation;
        return (
          <a href={url} className={styles.plainLink} target="_blank" rel="noopener noreferrer">
            {status}
          </a>
        );
      },
      ...muiTableBodyCellProps,
    },
    [UnassignedOrdersColumnKeys.Partner]: {
      id: UnassignedOrdersColumnKeys.Partner,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.Partner],
      accessorFn: originalRow => originalRow.partner?.name ?? HELLOTECH_PARTNER_NAME,
      // TODO - may need to move to separate function if reused again
      sortingFn: (rowA, rowB) => {
        const rowAData = rowA.original;
        const rowBData = rowB.original;
        const str1 = rowAData.partner?.name ?? HELLOTECH_PARTNER_NAME;
        const str2 = rowBData.partner?.name ?? HELLOTECH_PARTNER_NAME;
        return str1.localeCompare(str2);
      },
      ...muiTableBodyCellProps,
    },
    [UnassignedOrdersColumnKeys.CreatedAt]: {
      id: UnassignedOrdersColumnKeys.CreatedAt,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.CreatedAt],
      accessorFn: originalRow => {
        const {timezone, created_at} = originalRow;
        return dayjs(created_at).tz(timezone).format(shortMonthWithDayAndHour);
      },
      sortingFn: sortByDate,
      ...muiTableBodyCellProps,
    },
    [UnassignedOrdersColumnKeys.Tier]: {
      id: UnassignedOrdersColumnKeys.Tier,
      accessorKey: UnassignedOrdersColumnKeys.Tier,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.Tier],
      ...muiTableBodyCellProps,
    },
    [UnassignedOrdersColumnKeys.State]: {
      id: UnassignedOrdersColumnKeys.State,
      accessorKey: UnassignedOrdersColumnKeys.State,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.State],
      ...muiTableBodyCellProps,
    },
    [UnassignedOrdersColumnKeys.Zip]: {
      id: UnassignedOrdersColumnKeys.Zip,
      accessorKey: UnassignedOrdersColumnKeys.Zip,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.Zip],
      ...muiTableBodyCellProps,
    },
    [UnassignedOrdersColumnKeys.OfferStatus]: {
      id: UnassignedOrdersColumnKeys.OfferStatus,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.OfferStatus],
      Header: <></>,
      accessorFn: () => null,
      Cell: ({table, row}) => {
        const {has_active_offers, on_hold} = row.original;

        if (on_hold) {
          return <IconCellComponent name="pause" table={table} />;
        }
        if (has_active_offers) {
          return <IconCellComponent name="offer-progress-status" className="blue700" table={table} />;
        }

        return null;
      },
      enableSorting: false,
      enableResizing: false,
      iconColumn: true,
      ...muiTableBodyCellProps,
    },
    [UnassignedOrdersColumnKeys.FNOrderId]: {
      id: UnassignedOrdersColumnKeys.FNOrderId,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.FNOrderId],
      accessorFn: () => null,
      Cell: ({row}) => {
        const {field_nation} = row.original;
        if (!field_nation) return null;
        const {id, url} = field_nation;
        return (
          <a href={url} className={styles.plainLink} target="_blank" rel="noopener noreferrer">
            {id}
          </a>
        );
      },
      ...muiTableBodyCellProps,
    },
    [UnassignedOrdersColumnKeys.PartnerId]: {
      id: UnassignedOrdersColumnKeys.PartnerId,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.PartnerId],
      accessorFn: originalRow => originalRow.partner?.id ?? '',
      enableSorting: false,
      ...muiTableBodyCellProps,
    },
    [UnassignedOrdersColumnKeys.DefaultGeekCut]: {
      id: UnassignedOrdersColumnKeys.DefaultGeekCut,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.DefaultGeekCut],
      accessorFn: originalRow => formatPriceTrimZero(originalRow.default_geek_cut),
      ...muiTableBodyCellProps,
    },
    [UnassignedOrdersColumnKeys.CurrentIncentive]: {
      id: UnassignedOrdersColumnKeys.CurrentIncentive,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.CurrentIncentive],
      accessorFn: originalRow => formatPriceTrimZero(originalRow.incentive_payout),
      ...muiTableBodyCellProps,
    },
    [UnassignedOrdersColumnKeys.CBSA]: {
      id: UnassignedOrdersColumnKeys.CBSA,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.CBSA],
      accessorFn: originalRow => originalRow.cbsa?.name ?? '',
      ...muiTableBodyCellProps,
    },
    [UnassignedOrdersColumnKeys.LastOfferSentAt]: {
      id: UnassignedOrdersColumnKeys.LastOfferSentAt,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.LastOfferSentAt],
      accessorFn: originalRow => {
        const {timezone, last_offer_sent_at} = originalRow;
        return last_offer_sent_at ? dayjs(last_offer_sent_at).tz(timezone).format(shortMonthWithDayAndHour) : '';
      },
      sortingFn: sortByDate,
      ...muiTableBodyCellProps,
    },
    [UnassignedOrdersColumnKeys.Timezone]: {
      id: UnassignedOrdersColumnKeys.Timezone,
      accessorKey: UnassignedOrdersColumnKeys.Timezone,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.Timezone],
      ...muiTableBodyCellProps,
    },
    [UnassignedOrdersColumnKeys.LastOrderNote]: {
      id: UnassignedOrdersColumnKeys.LastOrderNote,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.LastOrderNote],
      accessorFn: originalRow => originalRow.last_order_note?.content ?? '',
      ...muiTableBodyCellProps,
    },
    [UnassignedOrdersColumnKeys.LastAvailabilityAt]: {
      id: UnassignedOrdersColumnKeys.LastAvailabilityAt,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.LastAvailabilityAt],
      accessorFn: originalRow => {
        const {timezone, last_availability_at} = originalRow;
        return last_availability_at ? dayjs(last_availability_at).tz(timezone).format(shortMonthWithDayAndHourMinute) : '';
      },
      sortingFn: sortByDate,
      ...muiTableBodyCellProps,
    },
    [UnassignedOrdersColumnKeys.HasStandby]: {
      id: UnassignedOrdersColumnKeys.HasStandby,
      accessorFn: originalRow => originalRow.has_standby,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.HasStandby],
      Cell: ({cell}) => (cell.getValue() ? 'Yes' : 'No'),
      ...muiTableBodyCellProps,
    },
    [UnassignedOrdersColumnKeys.LateTechReleased]: {
      id: UnassignedOrdersColumnKeys.LateTechReleased,
      accessorFn: originalRow => originalRow.late_release,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.LateTechReleased],
      Cell: ({cell}) => (cell.getValue() ? 'Yes' : 'No'),
      ...muiTableBodyCellProps,
    },
    [UnassignedOrdersColumnKeys.AgentAssigned]: {
      id: UnassignedOrdersColumnKeys.AgentAssigned,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.AgentAssigned],
      accessorFn: originalRow => originalRow.agent?.name ?? '',
      ...muiTableBodyCellProps,
    },
    [UnassignedOrdersColumnKeys.Latitude]: {
      id: UnassignedOrdersColumnKeys.Latitude,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.Latitude],
      accessorFn: originalRow => originalRow.coordinates?.[0] ?? '',
      sortingFn: sortNumerically,
      ...muiTableBodyCellProps,
    },
    [UnassignedOrdersColumnKeys.Longitude]: {
      id: UnassignedOrdersColumnKeys.Longitude,
      header: UNASSIGNED_ORDERS_COLUMN_LABELS[UnassignedOrdersColumnKeys.Longitude],
      accessorFn: originalRow => originalRow.coordinates?.[1] ?? '',
      sortingFn: sortNumerically,
      ...muiTableBodyCellProps,
    },
  };

  const columnDataArray = Object.keys(columnData).reduce((acc, key) => {
    const keyIsOmitted = omitKeys.includes(key as TUnassignedOrdersColumnKeysValues);

    if (!keyIsOmitted) {
      acc.push(columnData[key as TUnassignedOrdersColumnKeysValues & typeof ACTION_MENU_COL_ID]);
    }
    return acc;
  }, [] as TDataTableColumnDef<TUnassignedOrder>[]);

  columnDataArray.push(
    actionMenuColumn({
      getListItems: data => {
        const actionItems: TTableActionMenuLineItem[] = [];
        const {id: orderId, field_nation} = data;

        actionItems.push({
          text: 'Manage Order',
          key: 'manageOrder',
          onClick: () => {
            openUrlInNewTab({url: orderDetailsPath(orderId)});
          },
        });

        if (field_nation?.url) {
          actionItems.push({
            text: 'Field Nation Order',
            key: 'fieldNationOrder',
            onClick: () => {
              openUrlInNewTab({url: field_nation.url});
            },
          });
        }

        return actionItems;
      },
    })
  );

  return columnDataArray;
};
