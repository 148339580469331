import React, {ComponentProps} from 'react';
import {MRT_TableInstance} from 'material-react-table';
import cn from 'classnames';
import {Avatar, ELEMENT_SIZE, Chip, Icon, Checkbox} from 'ht-styleguide';
import {extractDensityState} from '../utils/dataTable.utils';
import styles from './cellComponent.styles.scss';

/**
 * Use these components to render custom cells in the table. These components will also
 * respond to the table's density setting.
 */
type TTableInstance<TData extends Record<string, any> = {}> = {table: MRT_TableInstance<TData> | null};

type TAvatarCellComponentProps<TData extends Record<string, any> = {}> = ComponentProps<typeof Avatar> & TTableInstance<TData>;
export const AvatarCellComponent = <TData extends Record<string, any> = {}>({table, ...rest}: TAvatarCellComponentProps<TData>) => {
  const density = extractDensityState<TData>({table});
  const size = density === 'compact' ? ELEMENT_SIZE.XSMALL : ELEMENT_SIZE.SMALL;
  return <Avatar {...rest} size={size} />;
};

type TChipCellComponentProps<TData extends Record<string, any> = {}> = ComponentProps<typeof Chip> & TTableInstance<TData>;
export const ChipCellComponent = <TData extends Record<string, any> = {}>({table, ...rest}: TChipCellComponentProps<TData>) => {
  const density = extractDensityState<TData>({table});
  const size = density === 'compact' ? ELEMENT_SIZE.SMALL : ELEMENT_SIZE.LARGE;
  return <Chip {...rest} size={size} />;
};

type TIconCellComponentProps<TData extends Record<string, any> = {}> = ComponentProps<typeof Icon> & TTableInstance<TData>;
export const IconCellComponent = <TData extends Record<string, any> = {}>({className, table, ...rest}: TIconCellComponentProps<TData>) => {
  const density = extractDensityState<TData>({table});
  const sizeStyles = density === 'compact' ? styles.iconCompact : styles.iconComfortable;
  return <Icon {...rest} className={cn(className, sizeStyles)} />;
};

type TIconImageCellComponentProps<TData extends Record<string, any> = {}> = {src: string; className?: string} & TTableInstance<TData>;
export const IconImageCellComponent = <TData extends Record<string, any> = {}>({src, className, table}: TIconImageCellComponentProps<TData>) => {
  const density = extractDensityState<TData>({table});
  const sizeStyles = density === 'compact' ? styles.iconImageCompact : styles.iconImageComfortable;
  return <img src={src} className={cn(className, sizeStyles)} alt="iconImage" />;
};

type TCheckboxCellComponentProps<TData extends Record<string, any> = {}> = ComponentProps<typeof Checkbox> & TTableInstance<TData>;
export const CheckboxCellComponent = <TData extends Record<string, any> = {}>({table, ...rest}: TCheckboxCellComponentProps<TData>) => {
  const density = extractDensityState<TData>({table});

  const boxStyles = cn(styles.checkboxBoxOverride, {
    [styles.compactCheckbox]: density === 'compact',
  });

  return <Checkbox classes={styles.checkboxClassesOverride} wrapperClass={styles.checkboxWrapperOverride} boxClass={boxStyles} {...rest} />;
};
