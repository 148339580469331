import React from 'react';
import {MRT_Icons} from 'material-react-table';
import cn from 'classnames';
import {TEST_IDS} from '../../dataTable.constants';
import styles from './sortIcon.styles.scss';

const SortIcon: MRT_Icons['ArrowDownwardIcon'] = ({className}: {className: string}) => {
  const isAsc = className?.includes('MuiTableSortLabel-iconDirectionAsc');
  const isDesc = className?.includes('MuiTableSortLabel-iconDirectionDesc');
  const arrowUpStyles = cn(styles.arrowUp, isAsc && styles.active);
  const arrowDownStyles = cn(styles.arrowDown, isDesc && styles.active);

  return (
    <div className={styles.arrowIconsContainer} data-testid={TEST_IDS.SORT_INDICATORS}>
      <div className={arrowUpStyles} data-testid={TEST_IDS.SORT_UP} />
      <div className="marginTop-tiny" />
      <div className={arrowDownStyles} data-testid={TEST_IDS.SORT_DOWN} />
    </div>
  );
};

export default SortIcon;
