import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useMutation, useQueryClient} from 'react-query';
import {htToast} from 'ht-styleguide';

import useApi from 'hooks/useApi';
import APIS from 'global/apis';
import {logger} from 'utils/logger';

import {showErrorToast} from 'queries/query.utils';
import {BASE_CURRENT_PROJECT_KEY} from '../MDU.query.keys';

export type TMutationReasonsParams = {
  unitId: number;
  note: string;
};
/** QUERY: Get Cannot Complete Options */
export const useCannotCompleteMutation = () => {
  const dispatch = useDispatch();
  const api = useApi();
  const queryClient = useQueryClient();
  const {projectId: idFromParams} = useParams<{projectId: string}>();

  return useMutation(
    async (params: TMutationReasonsParams) => {
      api.toggleLoader(true);
      const response = await APIS.mdu.cancelUnit({project_id: idFromParams, unit_id: params.unitId}, params);
      api.toggleLoader(false);

      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error marking cancelled'});
        throw new Error(response.err);
      }

      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...BASE_CURRENT_PROJECT_KEY, 'paginationJobs']);
        htToast('Job marked cannot complete');
      },
      onError: response => {
        logger('Job Cannot Complete: ')(response as Error);
      },
    }
  );
};

export const useUnserviceableMutation = () => {
  const dispatch = useDispatch();
  const api = useApi();
  const queryClient = useQueryClient();
  const {projectId: idFromParams} = useParams<{projectId: string}>();

  return useMutation(
    async (params: TMutationReasonsParams) => {
      api.toggleLoader(true);
      const response = await APIS.mdu.unserviceableUnit({project_id: idFromParams, unit_id: params.unitId}, params);
      api.toggleLoader(false);

      if (response.err) {
        showErrorToast(dispatch)({errors: 'Job marked cannot complete'});
        throw new Error(response.err);
      }

      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([...BASE_CURRENT_PROJECT_KEY, 'paginationJobs']);
        htToast('Job marked cannot complete');
      },
      onError: response => {
        logger('Job marked cannot complete: ')(response as Error);
      },
    }
  );
};
