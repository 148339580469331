import {useEffect} from 'react';
import {useSearchParams} from 'hooks/useSearchParam';
import {generateTaskPanelId} from '../orderTaskChecklist.utils';

type TUseOrderTaskChecklistURLParams = {
  /**
   * Object shape doesn't really matter. Just need to know if it's null or not.
   */
  checklistData: Object | null | undefined;
};

enum ELIGIBLE_QUERY_PARAMS {
  TASK_ID = 'task_id',
  ACTION = 'action',
}

/**
 * This hook is responsible for handling URL query params
 */
const useOrderTaskChecklistURL = ({checklistData}: TUseOrderTaskChecklistURLParams) => {
  const {getParam} = useSearchParams();

  /**
   * If `checklistData` is defined, we'll assume that the service task list rendered successfully.
   */
  useEffect(() => {
    if (checklistData) {
      // scroll to the task item panel if the task_id query param is present
      const taskId = getParam(ELIGIBLE_QUERY_PARAMS.TASK_ID);
      if (taskId) {
        const taskItemPanel = document.getElementById(generateTaskPanelId(+taskId));
        taskItemPanel?.scrollIntoView({behavior: 'smooth'});
      }
    }
  }, [checklistData, getParam]);
};

export default useOrderTaskChecklistURL;
