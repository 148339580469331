import {TPaginationData} from './ui.types';

export const PAGINATION_INITIAL_STATE: TPaginationData = {
  /**
   * 0-based index of the current page.
   */
  current_page: 0,
  items_per_page: 25,
  items_on_current_page: 0,
  total_pages: 1,
  has_more: false,
  items_viewed: 0,
  total_items: 0,
};
