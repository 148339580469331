import {PayloadAction, createSelector, createSlice} from '@reduxjs/toolkit';
import {isClientProdEnv} from 'utils/env';
import LocalStorageColumnState from 'utils/table/localStorageColumnState';
import {INITIAL_COLUMN_ORDER_STATE} from './Issues.constants';
import * as Types from './issues.types';

/**
 * Storing table column data to local storage
 */
const saveColumnStateToStorage = (newPagesState: Types.IInitialState['pages']) => {
  return Object.entries(newPagesState).reduce((acc, [key, {columnState, columnOrder, columnSort, columnGrouping, filters, rawFilters, searchFieldKeys}]) => {
    acc[key as keyof Types.IInitialState['pages']] = {
      filters,
      rawFilters,
      columnState,
      columnOrder,
      columnSort,
      columnGrouping,
      searchFieldKeys,
    };
    return acc;
  }, {} as Types.TPagesColumnStateLocalStorage);
};

const issuesColumnStateUtil = new LocalStorageColumnState<Types.IInitialState['pages'], Types.TPagesColumnStateLocalStorage>({
  stateKey: '_ht_issues_column_state',
  saveToStorageFN: saveColumnStateToStorage,
});

/*
*******************************************************
  INITIAL STATE
*******************************************************
*/

const paginationInitialState: TBasePagination = {
  current_page: 1,
  items_per_page: 25,
  items_on_current_page: 0,
  total_pages: 1,
  has_more: false,
  items_viewed: 0,
  total_items: 0,
};

export const pagesInitialState: Types.TPageInitialState = {
  filters: {},
  rawFilters: {},
  columnState: {},
  columnOrder: INITIAL_COLUMN_ORDER_STATE,
  columnSort: [],
  columnGrouping: [],
  searchFieldKeys: '',
  pagination: paginationInitialState,
};

const projectsSingleViewInitialState: Types.TPageInitialState = {
  ...pagesInitialState,
  filters: {
    [Types.IssuesSearchFieldNames.StatusId]: isClientProdEnv() ? [1] : [5],
  },
  rawFilters: {
    [Types.IssuesSearchFieldNames.StatusId]: {
      label: 'Status',
      name: Types.IssuesSearchFieldNames.StatusId,
      values: [{label: 'Open', value: isClientProdEnv() ? 1 : 5}],
    },
  },
};

export const initialState: Types.IInitialState = {
  actionItem: null,
  pages: issuesColumnStateUtil.populateInitialPagesState({
    initialState: {
      open: pagesInitialState,
      own: pagesInitialState,
      done: pagesInitialState,
      projectsSingleView: projectsSingleViewInitialState,
    },
  }),
};

/*
*******************************************************
  SLICE
*******************************************************
*/
const {actions: issueActions, reducer} = createSlice({
  name: 'issues',
  initialState,
  reducers: {
    /* --- Set attributes to open a modal or slide: Used when concern is outside the parent  ----  */
    setActionItemModalSlide: (state, action) => {
      const {entity = null, modalKey, modalType = '', onSuccessCallback} = action.payload;
      state.actionItem = {
        entity,
        modalKey,
        modalType,
        onSuccessCallback,
      };
    },
    removeActionItemModalSlide: state => {
      state.actionItem = null;
    },
    updateIssuesFilters: (state, action: PayloadAction<Types.TUpdateIssuesFiltersPayload>) => {
      const {issuesNavType, filters, replace = false} = action.payload;
      if (replace) {
        state.pages[issuesNavType].filters = filters;
      } else {
        state.pages[issuesNavType].filters = {...state.pages[issuesNavType].filters, ...filters};
      }

      // If a filter is removed, remove it from rawFilters
      const newFilters = state.pages[issuesNavType].filters;
      const currentFiltersKeys = Object.keys(newFilters).filter(key => {
        const filterValue = newFilters[key];
        if (Array.isArray(filterValue)) {
          return !!filterValue.length;
        }
        return !!filterValue;
      });

      const currentRawFilters = state.pages[issuesNavType].rawFilters;
      const currentRawFiltersKeys = Object.keys(currentRawFilters);
      const removedFiltersKeys = currentRawFiltersKeys.filter(key => !currentFiltersKeys.includes(key));
      removedFiltersKeys.forEach(key => {
        delete state.pages[issuesNavType].rawFilters[key];
      });

      // Update logic in storage util if necessary
      issuesColumnStateUtil.saveStateToStorage(state.pages);
    },
    /**
     * Used to update a single raw filter.
     */
    updateIssuesRawFilters: (state, action: PayloadAction<Types.TUpdateIssuesRawFiltersPayload>) => {
      const {issuesNavType, name, label, values} = action.payload;
      const currentRawFilters = state.pages[issuesNavType].rawFilters;

      const rawFilterByName = currentRawFilters[name];

      if (!values.length) {
        delete state.pages[issuesNavType].rawFilters[name];
        return;
      }

      if (!rawFilterByName) {
        state.pages[issuesNavType].rawFilters[name] = {name, label, values};
      } else {
        state.pages[issuesNavType].rawFilters[name] = {...rawFilterByName, values};
      }

      // Update logic in storage util if necessary
      issuesColumnStateUtil.saveStateToStorage(state.pages);
    },
    removeIssuesRawFilters: (state, action: PayloadAction<Types.TRemoveIssuesRawFiltersPayload>) => {
      const {issuesNavType, name} = action.payload;
      delete state.pages[issuesNavType].rawFilters[name];
      // Update logic in storage util if necessary
      issuesColumnStateUtil.saveStateToStorage(state.pages);
    },
    /**
     * Used to override the entire rawFilters state.
     */
    updateRawFiltersState: (state, action: PayloadAction<Types.TUpdateRawFiltersStatePayload>) => {
      const {issuesNavType, newRawFiltersState} = action.payload;
      state.pages[issuesNavType].rawFilters = newRawFiltersState;
      // Update logic in storage util if necessary
      issuesColumnStateUtil.saveStateToStorage(state.pages);
    },
    updateColumnVisiblity: (state, action: PayloadAction<Types.TUpdateColumnStatePayload>) => {
      const {issuesNavType, columnState} = action.payload;
      // Update visiblity state
      const oldColumnState = state.pages[issuesNavType].columnState;
      state.pages[issuesNavType].columnState = {...oldColumnState, ...columnState};
      // Remove column sort if column is hidden
      const {columnSort} = state.pages[issuesNavType];
      const newColumnSort = columnSort.filter(sort => columnState[sort.id]?.visible);
      state.pages[issuesNavType].columnSort = newColumnSort;
      // Reset column grouping if grouped column becomes hidden
      const {columnGrouping} = state.pages[issuesNavType];
      const groupedColumnVisible = state.pages[issuesNavType].columnState[columnGrouping[0]]?.visible;
      if (!groupedColumnVisible) {
        state.pages[issuesNavType].columnGrouping = [];
      }
      // Update logic in storage util if necessary
      issuesColumnStateUtil.saveStateToStorage(state.pages);
    },
    updateColumnWidths: (state, action: PayloadAction<Types.TUpdateColumnWidthsPayload>) => {
      const {issuesNavType, columnWidths} = action.payload;
      const newColumnState = state.pages[issuesNavType].columnState;
      Object.entries(columnWidths).forEach(([key, value]) => {
        if (!newColumnState[key]) {
          newColumnState[key] = {};
        }
        newColumnState[key].columnWidth = value;
      });
      state.pages[issuesNavType].columnState = newColumnState;
      // Update logic in storage util if necessary
      issuesColumnStateUtil.saveStateToStorage(state.pages);
    },
    updateColumnOrder: (state, action: PayloadAction<Types.TUpdateColumnOrderPayload>) => {
      const {issuesNavType, columnOrder} = action.payload;
      state.pages[issuesNavType].columnOrder = columnOrder;
      // Update logic in storage util if necessary
      issuesColumnStateUtil.saveStateToStorage(state.pages);
    },
    updateColumnSort: (state, action: PayloadAction<Types.TUpdateColumnSortPayload>) => {
      const {issuesNavType, columnSort} = action.payload;
      state.pages[issuesNavType].columnSort = columnSort;
      // Update logic in storage util if necessary
      issuesColumnStateUtil.saveStateToStorage(state.pages);
    },
    updateColumnGrouping: (state, action: PayloadAction<Types.TUpdateColumnGroupingPayload>) => {
      const {issuesNavType, columnGroupingKey} = action.payload;
      if (columnGroupingKey) {
        state.pages[issuesNavType].columnGrouping = [columnGroupingKey];
        // On the BE perspectiive, grouping is the same as sorting, so update columnSort too
        state.pages[issuesNavType].columnSort = [{id: columnGroupingKey, desc: false}];
      } else {
        state.pages[issuesNavType].columnGrouping = [];
        state.pages[issuesNavType].columnSort = [];
      }
      // Update logic in storage util if necessary
      issuesColumnStateUtil.saveStateToStorage(state.pages);
    },
    updateSearchFieldKeys: (state, action: PayloadAction<Types.TUpdateSearchFieldKeysPayload>) => {
      const {issuesNavType, searchFieldKeys} = action.payload;
      state.pages[issuesNavType].searchFieldKeys = searchFieldKeys;
      // Update logic in storage util if necessary
      issuesColumnStateUtil.saveStateToStorage(state.pages);
    },
    updatePagination: (state, action: PayloadAction<Types.TUpdatePaginationPayload>) => {
      const {issuesNavType, pagination} = action.payload;
      const oldPagination = state.pages[issuesNavType].pagination;
      state.pages[issuesNavType].pagination = {...oldPagination, ...pagination};
    },
  },
});

/*
*******************************************************
  Async Actions
*******************************************************
*/
export const asyncActions = {};

/*
*******************************************************
  SELECTORS & SELECTOR METHODS
*******************************************************
*/
const getIssuesState = (state: any): Types.IInitialState => state.issues;

const selectors = {
  getActionItem: createSelector(getIssuesState, issues => issues?.actionItem),
  getPagesStateByKey: (key: Types.IssuesNavTypes) => createSelector(getIssuesState, state => state.pages[key]),
  getPagesSearchFieldKeys: createSelector(getIssuesState, state => {
    return Object.entries(state.pages).reduce((acc, [key, {searchFieldKeys}]) => {
      acc[key as Types.IssuesNavTypes] = searchFieldKeys;
      return acc;
    }, {} as Record<Types.IssuesNavTypes, string>);
  }),
};

/*
*******************************************************
  Export
*******************************************************
*/

export default {
  actions: {...issueActions, ...asyncActions},
  selectors,
  reducer,
  initialState,
};
