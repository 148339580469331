import React from 'react';

// Utils
import dayjs from 'dayjs';

// Constants
import {LLL} from 'global/constants/common';

// Types
import {Device} from 'features/Profile/profile.types';

// Styles
import styles from 'features/Profile/profile.styles.scss';

type DeviceLineProps = {
  device: Device;
};

const DeviceLine = ({device: {imageUrl, make, model, category, createdAt}}: DeviceLineProps) => {
  return (
    <tr>
      <td>
        <img className={styles.deviceImage} src={imageUrl?.thumb} alt={model} />
      </td>
      <td className={styles.deviceRow}>{make}</td>
      <td className={styles.deviceRow}>{model}</td>
      <td className={styles.deviceRow}>{category}</td>
      <td className={styles.deviceRow}>{dayjs(createdAt?.iso).format(LLL)}</td>
    </tr>
  );
};

export default DeviceLine;
