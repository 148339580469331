import React, {useEffect, useLayoutEffect, useState, ReactElement, useMemo, useCallback} from 'react';
import {noop} from 'utils/event';
import {IStickyContainerProps} from './StickyContainer.types';
import {useStickyHeader} from './stickyContainer.hooks';
import {STICKY_CONTAINER_ID} from './stickyContainer.constants';
import styles from './StickyContainer.styles.scss';

const DefaultAttributes = {
  height: 0,
};

/**
 * Use this when you have a secondary sticky header
 *
 * DO NOT OBSERVE WIDTH
 *   - Observing width will eventually let to jankiness when the sidebar is opening/closing.
 *     This is evident on the Issues table view.
 *
 * @param headerShouldBeSticky
 * @param height
 * @param id
 */
export function useStickyContainer({headerShouldBeSticky = true, height = 0, id = STICKY_CONTAINER_ID}: IStickyContainerProps) {
  /* Local State */
  const [elemSizeAttributes, setElemSizeAttributes] = useState<{height: number}>(DefaultAttributes);
  const tempHeightAttribute = React.useRef(height);

  /* Hooks */
  const {elemRef: containerRef, isSticky} = useStickyHeader({defaultSticky: headerShouldBeSticky});
  const className = isSticky ? styles.sticky : '';

  const setHeight = useCallback((newHeight: number) => {
    if (newHeight !== tempHeightAttribute.current) {
      tempHeightAttribute.current = newHeight;
      setElemSizeAttributes({height: newHeight});
    }
  }, []);

  useEffect(() => {
    setHeight(height);
  }, [height, setHeight]);

  const observer = new ResizeObserver(entries => {
    const e = entries[0];
    const newHeight = e.contentRect.height;
    setHeight(newHeight);
  });

  useLayoutEffect(() => {
    const container = containerRef.current;
    if (container) {
      observer.observe(container);

      /* Initialize preseed height */
      const rect = container.getBoundingClientRect();
      setHeight(rect.height);

      return () => observer.unobserve(container);
    }

    return noop;
  }, []);

  const StickyContainer = useMemo(() => {
    const SC = ({children}: {children: ReactElement}) => {
      return (
        <section className={className} ref={containerRef} id={id}>
          {children}
        </section>
      );
    };

    return SC;
  }, [containerRef]);

  return {
    StickyContainer,
    elemSizeAttributes,
  };
}

/**
 * USAGE:
 * Wrap any components that need to be sticky inside this as children.
 * const {StickyContainer, elemSizeAttributes} = useStickyContainer({});
 *
 * eg:
 *    <StickyContainer>
 *      <p className={'p1 marginBottom-medium n700'}>
 *        Some component that should be sticky
 *      </p>
 *    </StickyContainer>
 *
 * If there is a table component under this, pass in the height value of this component to table as
 * we do this because there could be cases in which the sticky container expands. So, we observe for the
 * dimensions of the sticky container and pass it to the table component.
 *
 * <Table {...props} topValue={elemSizeAttributes.height} />
 * where stickyElementHeight = document.getElementById(STICKY_CONTAINER_ID)?.offsetHeight;
 */
