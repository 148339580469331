import {useCallback, useEffect} from 'react';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';

import debounce from 'lodash/debounce';

type LocalStoragePluginProps = {
  editorNamespace: string;
};

/**
 * This plugin will save the editor state to localStorage.
 *
 * We currently are not saving beyond the currently viewed page, so no need to worry about,
 * but the ability is here.
 * @param editorNamespace
 * @constructor
 */
export function LocalStoragePlugin({editorNamespace}: LocalStoragePluginProps) {
  const [editor] = useLexicalComposerContext();

  const saveContent = useCallback(
    (content: string) => {
      localStorage.setItem(editorNamespace, content);
    },
    [editorNamespace]
  );

  const debouncedSaveContent = debounce(saveContent, 200, {trailing: true, leading: false});

  useEffect(() => {
    return editor.registerUpdateListener(({editorState, dirtyElements, dirtyLeaves}) => {
      // Don't update if nothing changed
      if (dirtyElements.size === 0 && dirtyLeaves.size === 0) return;

      const serializedState = JSON.stringify(editorState);
      debouncedSaveContent(serializedState);
    });
  }, [debouncedSaveContent, editor]);

  return null;
}
