import React from 'react';
import cn from 'classnames';
import {DropIndicator} from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';
import {Icon} from 'ht-styleguide';
import {TTask} from 'features/Products/types/taskGroups.types';
import DragHandle from './TaskChecklist.DragHandle';
import {useTaskItemDnDHandler, useMouseInState} from './taskChecklistGroup.hooks';
import styles from '../taskChecklist.styles.scss';

const TaskItem = ({
  task,
  groupId,
  lineItemStyles,
  handleTaskClick,
  handleTaskDeleteClick,
  iconName,
  canEditOrDeleteItem,
}: {
  task: TTask;
  groupId: number;
  lineItemStyles: string;
  handleTaskClick: (taskId: number) => void;
  handleTaskDeleteClick: (taskId: number) => (event: React.MouseEvent) => void;
  iconName: string;
  canEditOrDeleteItem: boolean;
}) => {
  const {mouseIn, onMouseEnter, onMouseLeave} = useMouseInState();
  const {taskItemRef, taskItemDragHandleRef, closestEdge} = useTaskItemDnDHandler({canEditOrDeleteItem, task, groupId});

  return (
    <div ref={taskItemRef} key={task.id} className={lineItemStyles} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {canEditOrDeleteItem && <DragHandle mouseIn={mouseIn} dragHandleRef={taskItemDragHandleRef} />}
      <div onClick={() => handleTaskClick(task.id)}>
        <p className={cn('p2 n800', styles.underlineOnHover)}>{task.title}</p>
      </div>
      <div className="flex align-items-center">
        <div onClick={handleTaskDeleteClick(task.id)}>
          <Icon name={iconName} className={styles.checklistLineItemIcon} />
        </div>
      </div>
      {closestEdge && <DropIndicator edge={closestEdge} />}
    </div>
  );
};

export default TaskItem;
