import React, {useEffect} from 'react';
import CloudinaryAttachmentsField from 'features/Cloudinary/components/CloudinaryAttachmentsField';
import cloudinarySlice from 'features/Cloudinary/state/cloudinary.ducks';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {IBaseTask} from '../../serviceTasksList.types';

interface IServiceTaskAttachmentFieldProps extends IBaseTask {
  namespace: string;
}

/**
 * A wrapper around CloudinaryAttachmentsField that is shared across blocks.
 *
 * Note: On `<CloudinaryAttachmentsField />`, `overrideStateOnPreseedChange` is set to `true` to
 * ensure that the component reflects the correct state when preseeded attachments are changed.
 */
const ServiceTaskAttachmentField = ({namespace, serviceId, task}: IServiceTaskAttachmentFieldProps) => {
  const dispatch = useAppDispatch();
  const {answer} = task;
  const {attachments} = answer || {};
  const hasAttachments = (attachments || []).length > 0;
  const cloudinaryNamespace = `${namespace}-${serviceId}-${task.id}`;

  useEffect(() => {
    return () => {
      // Clear out the cloudinary attachments when the component unmounts
      dispatch(cloudinarySlice.actions.deleteAllFilesByNamespace({editorNamespace: cloudinaryNamespace}));
    };
  }, [cloudinaryNamespace, dispatch]);

  return hasAttachments ? (
    <CloudinaryAttachmentsField namespace={cloudinaryNamespace} preseedAttachments={attachments} showDeleteButton={false} withDropzone={false} overrideStateOnPreseedChange withLightbox />
  ) : null;
};

export default ServiceTaskAttachmentField;
