import React from 'react';
import cn from 'classnames';

/* Components */
import StringAction from './StringAction';
import IconAction from './IconAction';
import ComponentAction from './ComponentAction';

import {ActionType, TActionGroupProps} from './actionGroup.types';

import styles from './styles.scss';

/**
 * ActionGroup
 *
 * Usage:
 *  - pass in actions
 *
 * @param actions
 * @param size
 * @constructor
 */

export const ACTION_BAR_GROUP_DATA_ID = 'actionBarGroup';

const ActionGroup = ({actions, size, dataTestId = ACTION_BAR_GROUP_DATA_ID}: TActionGroupProps) => {
  const actionGroupClass = cn(styles.actionGroup, styles[size]);

  return (
    <div className={actionGroupClass} data-testid={dataTestId}>
      {actions.map((action, idx) => {
        switch (action.type) {
          case ActionType.string:
            return (
              <StringAction
                key={`${action.tooltipContent}${action.text}`}
                text={action.text}
                action={action.action}
                tooltipContent={action.tooltipContent}
                dataTestId={`${action.type}-action-${action.text}`}
              />
            );
          case ActionType.icon:
            return (
              <IconAction
                key={`${action.tooltipContent}${action.iconName}`}
                iconName={action.iconName}
                action={action.action}
                tooltipContent={action.tooltipContent}
                dataTestId={`${action.type}-action-${action.iconName}`}
                customClassName={action.customClassName}
              />
            );
          case ActionType.component:
            return <ComponentAction key={`${action.tooltipContent}-component`} Component={action.Component} tooltipContent={action.tooltipContent} dataTestId={`${action.type}-action-${idx}`} />;
          default:
            return null;
        }
      })}
    </div>
  );
};

export default ActionGroup;

/**
 * USAGE

 const actions = [
 {
      type: 'string' as const,
      text: 'Action 1',
      action: () => console.log('Action 1 clicked'),
      tooltipContent: 'Tooltip for Action 1',
    },
 {
      type: 'icon' as const,
      iconName: 'info',
      action: () => console.log('Action 2 clicked'),
      tooltipContent: 'Tooltip for Action 2',
    },
 {
      type: 'icon' as const,
      iconName: 'home',
      action: () => console.log('Action IUCON clicked'),
      tooltipContent: 'Tooltip for ICON home',
    },
 {
      type: 'component' as const,
      Component: () => <Toggle toggleStatus={false} elementSize={ELEMENT_SIZE.LARGE} onToggle={() => console.log('Action COMPONENT clicked')} name="T" />,
      tooltipContent: 'Tooltip for Action 3',
      transform: true,
    },
 ];

 <ActionBarGroup actions={actions} size="sm" />

 */
