import React from 'react';
import cn from 'classnames';

import styles from './groupservicesboxinfo.styles.scss';

export const SectionTitle = ({title, className}: {title?: string; className?: string}) => (title ? <div className={cn('p1 text-weight-med paddingBottom-tiny', className)}>{title}</div> : null);

/*
 Generic light blue box services display.
 Note: It's up to the caller to pass in the correct type for the services array.
       Otherwise, we'd have to pass generics everywhere and/or have type guards.
*/
export type TGroupService = {
  quantity: number;
  name: string;
  key: string;
  id: string | number;
};

export type TGroupServicesBoxInfoProps = {
  services: TGroupService[];
  title?: string;
};
const GroupServicesBoxInfo = ({services, title}: TGroupServicesBoxInfoProps) => {
  if (!services || (Array.isArray(services) && services.length === 0)) return null;

  return (
    <div className={cn(styles.groupContainer, 'flex flex-direction-column marginTop-small')}>
      <SectionTitle title={title} />
      {services.map(service => {
        const key = service.key || `${service.quantity}-${service.id}-${service.name}`;
        const qtyDisplay = `${service.quantity}x`;
        const spaceForQty = cn('p2 n700 paddingRight-tiny1', {quantity: qtyDisplay});
        const nameClass = cn('p2 n700', {[styles.templateName]: qtyDisplay, [styles.name]: !qtyDisplay});

        return (
          <div key={key} className="flex flex-direction-row paddingY-tiny n700" data-testid="group_template">
            <div className={spaceForQty}>{qtyDisplay}</div>
            <div className={nameClass}>{service.name}</div>
          </div>
        );
      })}
    </div>
  );
};

export default GroupServicesBoxInfo;
