import React, {useMemo, useRef} from 'react';
import {useParams} from 'react-router-dom';
import cn from 'classnames';
import PageHeader from 'components/Elements/PageHeader';
import PageHeaderMetaGrid from 'components/Elements/PageHeaderMetaGrid';
import PageHeaderOrderStatusBadge from 'features/Orders/Parts/PageHeaderOrderStatusBadge';
import useOrderTaskChecklistFlow from './hooks/useOrderTaskChecklistFlow.hooks';
import useOrderTaskChecklistMetaData from './hooks/useOrderTaskChecklistMetaData.hooks';
import useOrderTaskChecklistURL from './hooks/useOrderTaskChecklistURL.hooks';
import SectionHeader from './Parts/SectionHeader';
import ServicesList from './Parts/ServicesList';
import ServiceTasksList from './Parts/ServiceTasksList';
import TaskCompletionStatusModal from './Parts/TaskCompletionStatusModal';
import {TTaskCompletionStatusModalHandler} from './Parts/TaskCompletionStatusModal/taskCompletionStatusModal.types';
import {TOrderTaskChecklistPageParams} from './orderTaskChecklistPage.types';
import styles from './orderTaskChecklist.styles.scss';

/**
 * OrderTaskChecklistPage
 *
 * This page component is used for routes ORDERS_TASK_CHECKLIST and ORDERS_TASK_CHECKLIST_BY_SERVICE_ID
 * - ORDERS_TASK_CHECKLIST
 *   - In the event service id unknown from the entry point, this route can be used. At this route, this
 *     component will pull down order data and then redirect the user to ORDERS_TASK_CHECKLIST_BY_SERVICE_ID
 *     by using first service id in the order.
 * - ORDERS_TASK_CHECKLIST_BY_SERVICE_ID
 *   - In the event service id is known from the entry point, this route can be used. At this route,
 *     the component will render the task checklist for the service id.
 */
const OrderTaskChecklistPage = () => {
  const {id: orderId = '', serviceId} = useParams<TOrderTaskChecklistPageParams>();
  const serviceIdInt = serviceId ? +serviceId : undefined;
  const {order, orderUsesTaskFlow, checklistData, checklistsForOrder} = useOrderTaskChecklistFlow({orderId: +orderId, serviceId: serviceIdInt});
  useOrderTaskChecklistURL({checklistData});

  // PagerHeader Meta Data
  const metaDataItems = useOrderTaskChecklistMetaData({order});
  const PageHeaderTitleComponent = useMemo(() => {
    return (
      <>
        <PageHeader.TitleText title={`Order #${orderId}`} />
        <PageHeaderOrderStatusBadge order={order} />
      </>
    );
  }, [order, orderId]);

  // Task completion status modal ref - controls showing the modal
  const taskCompletionStatusModalRef = useRef<TTaskCompletionStatusModalHandler>(null);

  const showChecklistSection = !!order && !!checklistData && !!serviceIdInt && orderUsesTaskFlow;

  return (
    <>
      <PageHeader TitleComponent={PageHeaderTitleComponent} outerClassName="paddingX-large paddingBottom-small1">
        <PageHeaderMetaGrid>
          <PageHeaderMetaGrid.Row data={metaDataItems} />
        </PageHeaderMetaGrid>
      </PageHeader>
      <div className={cn('flex1 flex flex-direction-column bg-n100 paddingY-small2 paddingLeft-large', styles.mainContentWrapper)}>
        {showChecklistSection && (
          <>
            <SectionHeader checklistsForOrder={checklistsForOrder} order={order} taskCompletionStatusModalRef={taskCompletionStatusModalRef} />
            <div className={styles.scrollContentsWrapper}>
              <ServicesList
                order={order}
                checklistData={checklistData}
                checklistsForOrder={checklistsForOrder}
                currentServiceId={serviceIdInt}
                taskCompletionStatusModalRef={taskCompletionStatusModalRef}
              />
              <ServiceTasksList order={order} checklistData={checklistData} serviceId={serviceIdInt} taskCompletionStatusModalRef={taskCompletionStatusModalRef} />
            </div>
            <TaskCompletionStatusModal checklistsForOrder={checklistsForOrder} modalRef={taskCompletionStatusModalRef} />
          </>
        )}
      </div>
    </>
  );
};

export default OrderTaskChecklistPage;
