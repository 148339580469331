import {useDispatch} from 'react-redux';
import {useQuery} from 'react-query';

import APIS from 'global/apis';
import {logger} from 'utils/logger';

import {notifications} from 'components/Notification/notification.ducks';

import {useCurrentProject} from '../Pages/CurrentProject/CurrentProject.hooks';

import {MDU_PROJECT_QUERY_KEYS} from '../MDU.query.keys';
import {SkuSearchResult} from '../MDU.types';

/**
 * This feeds the display on the /serviceId/services display for the dropdown to select a service.
 *
 * Get Partner Skus by partner id. Specificallay for partners, we empty out the search param.
 */
export const useGetProjectSkusByPartner = () => {
  /* Hooks */
  const {partner = {}} = useCurrentProject();
  const dispatch = useDispatch();

  const fetchPartnerSkus = async () => {
    const response = await APIS.mdu.searchSkus({partnerId: partner?.id, search: ''});
    if (response.err) throw new Error(`${response.err.errors?.join(',')}`);
    const {
      data: {skus = []},
    } = response;

    const formattedSkus = skus.map((sku: SkuSearchResult) => ({
      value: sku.id,
      label: `${sku.name} #${sku.id}`,
    }));

    return formattedSkus;
  };

  return useQuery(
    MDU_PROJECT_QUERY_KEYS.partnerSkus(partner.id),
    async () => {
      return fetchPartnerSkus();
    },
    {
      enabled: Boolean(partner?.id),
      onError: response => {
        dispatch(notifications.actions.notificationApiError({source: 'Error Retrieving Partner Skus'}));
        logger('Partner Skus: ')(response as Error);
      },
    }
  );
};
