import {useMutation, useQueryClient} from 'react-query';
import {useParams} from 'react-router-dom';

import useApi from 'hooks/useApi';
import APIS from 'global/apis';
import {MDU_PROJECT_QUERY_KEYS} from '../MDU.query.keys';
import {MduUseParamsTypes} from '../MDU.types';

/** MUTATION: Create New Project Note */
export const useCreateProjectNoteMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const {projectId = ''} = useParams<MduUseParamsTypes>();
  return useMutation(
    async (note: FormData) => {
      api.toggleLoader(true);
      const response = await APIS.mdu.createNote({id: projectId}, note, {files: true});
      api.toggleLoader(false);

      if (response.status >= 400) throw new Error(`error creating note: ${response.statusText}`);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(MDU_PROJECT_QUERY_KEYS.getNotesByProjectId(projectId));
      },
    }
  );
};
