// IconAction.tsx
import React from 'react';
import cn from 'classnames';
import {Tooltip, Icon} from 'ht-styleguide';

import {TActionBaseOmitType, TActionIconProps, TBaseAction} from './actionGroup.types';

import styles from './styles.scss';

const IconAction = ({iconName, action, tooltipContent, dataTestId, customClassName = ''}: TBaseAction & TActionBaseOmitType<TActionIconProps>) => {
  if (!iconName) return null;

  const iconClassName = cn(styles.iconAction, customClassName);

  if (!tooltipContent) {
    return (
      <div className={iconClassName} onClick={action} data-testid={dataTestId}>
        <Icon name={iconName} className={customClassName} />
      </div>
    );
  }

  return (
    <Tooltip content={tooltipContent}>
      <div className={iconClassName} onClick={action} data-testid={dataTestId}>
        <Icon name={iconName} className={customClassName} />
      </div>
    </Tooltip>
  );
};

export default IconAction;
