import Bugsnag from '@bugsnag/js';
import isString from 'lodash/isString';

const log = (...args: Array<string>) => window.console.log(...args);
const info = (...args: Array<string>) => window.console.info(...args);

export type LoggerErrorType = Error | string;

export const logger =
  (source = '') =>
  (error: LoggerErrorType) => {
    let err;

    switch (true) {
      case error instanceof Error:
        err = error;
        break;
      case isString(error):
        err = new Error(`${source}: ${error}`);
        break;
      default:
        err = new Error(`${source}: ${JSON.stringify(error)}`);
    }

    Bugsnag.notify(err);
  };

export default {log, info, logger};
