import {useQuery} from 'react-query';
import {useDispatch} from 'react-redux';
import qs from 'qs';

import APIS from 'global/apis';
import {logger} from 'utils/logger';
import {showErrorToast} from 'queries/query.utils';
import {EDITOR_QUERY_KEYS} from './query.keys';

/**
 * These are the users that are used in the mentions capabilities of the Editor
 *
 * There is no need for any other types but "admin" for now.
 *
 * Note: for now, hardcoded. Not sure this will ever be expanded out, so doing minimum expansion work.
 */
type TMentionsUsersType = 'admin' | 'tech';
type TMentionsParams = {
  userType: TMentionsUsersType[];
};

const seededUserType = ['admin'];
export const useGetUsersForMentions = (userType = seededUserType) => {
  const dispatch = useDispatch();

  return useQuery(
    EDITOR_QUERY_KEYS.mentions(userType),
    async () => {
      const response = await APIS.users.getUsers({search: qs.stringify({search: '', types: userType, full: false, per_page: 500}, {arrayFormat: 'brackets', encode: false})});
      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Retrieving Stats'});
        throw new Error(response);
      }

      return {
        mentionNameArray: [...new Set(response?.data?.users?.map((user: any) => user.name))],
        mentions: response?.data?.users.map((user: any) => ({value: user.name, id: user.id})),
      };
    },
    {
      onError: response => {
        logger('Projects Stats: ')(response as Error);
      },
    }
  );
};
