import React, {ReactNode} from 'react';
import cn from 'classnames';
import {Panel} from 'ht-styleguide';
import styles from './menu.styles.scss';

const MenuPanel = ({children, className, ...rest}: {children: ReactNode; className?: string}) => (
  <Panel largeBorderRadius className={cn(styles.editMenu, className)} {...rest}>
    {children}
  </Panel>
);

export default MenuPanel;
