import React, {useEffect, useState, useMemo} from 'react';
import {ELEMENT_SIZE, SelectField} from 'ht-styleguide';
import get from 'lodash/get';

// utils & constants
import {useGetProjectTypesQuery} from 'features/MultiDwellingUnits/queries/query.project.fields';
import {getProjectType} from 'features/MultiDwellingUnits/MDU.utils';
import {FIELD_LABELS} from 'features/MultiDwellingUnits/Parts/FormFields/FormFields.constants';
import {useFetchProjectTypes} from 'features/MultiDwellingUnits/MDU.hooks';
import usePrevious from 'hooks/usePrevious';

// types
import {SelectOption} from 'features/MultiDwellingUnits/MDU.types';
import {MduFormElementProps} from 'features/MultiDwellingUnits/Parts/FormFields/FormFields.types';

// styles
import styles from 'features/MultiDwellingUnits/Parts/FormFields/FormFields.styles.scss';

const ProjectType = ({formik, project, fieldName = 'projectType', label = FIELD_LABELS.projectType, dataTestId = FIELD_LABELS.projectType, multiple, customProjectTypes}: MduFormElementProps) => {
  const {data: projectTypesData} = useGetProjectTypesQuery();
  const [selectedDetails, setSelectedDetails] = useState<SelectOption | SelectOption[] | string>('');
  const allProjectsTypes = useFetchProjectTypes();

  const handleDetailsChange = (pt: any) => {
    if (!pt) {
      setSelectedDetails('');
      return formik.handleChange({
        target: {name: fieldName, value: multiple ? [] : ''},
      });
    }
    setSelectedDetails(pt);
    if (Array.isArray(pt)) {
      return formik.handleChange({
        target: {name: fieldName, value: pt.map((p: any) => p.value)},
      });
    }
    return formik.handleChange({
      target: {name: fieldName, value: pt.value},
    });
  };

  const PROJECT_TYPES = useMemo<SelectOption[]>(() => {
    if (projectTypesData) {
      return projectTypesData.map(pt => ({
        value: pt.slug,
        label: pt.name,
      }));
    }
    return [];
  }, [projectTypesData]);

  const selectOptions = customProjectTypes || PROJECT_TYPES;

  useEffect(() => {
    /* Seed project type */
    if (project?.projectType) {
      setSelectedDetails({
        value: String(project.projectType.slug),
        label: getProjectType(project.projectType, allProjectsTypes),
      });
    }
  }, [project, allProjectsTypes]);

  const formikValue = get(formik.values, fieldName);
  const previousFormikValue = usePrevious(formikValue);

  useEffect(() => {
    // Preseed select if parent already has formik value
    if (!selectedDetails && formikValue) {
      const found = selectOptions.filter(entry => (multiple ? (formikValue || []).includes(entry.value) : entry.value === formikValue));
      if (found) {
        setSelectedDetails(multiple ? found : found[0]);
      }
    }
    // When formik is clear from parent container
    if (!formikValue && previousFormikValue) {
      setSelectedDetails('');
    }
  }, [PROJECT_TYPES, formikValue, multiple, previousFormikValue, selectOptions, selectedDetails]);

  return (
    <SelectField
      id="projectType"
      placeholder="Select Project Type"
      options={selectOptions}
      label={label}
      value={selectedDetails}
      onChange={type => handleDetailsChange(type)}
      elementSize={ELEMENT_SIZE.MEDIUM}
      error={formik.errors[fieldName]}
      reactSelectClassName={styles.placeholder}
      dataTestId={dataTestId}
      multiple={multiple}
    />
  );
};

export default ProjectType;
