import {ComponentProps} from 'react';
import {CHIP_TYPES} from 'ht-styleguide';
import PageHeader from 'components/Elements/PageHeader';
import {TOrderDetails, OrderStatus, ProgressStatusId} from 'features/Orders/types/orders.types';

export const ORDER_STATUS_LABELS = {
  Inactive: 'Inactive',
  SearchingRemote: 'In Progress: Searching',
  Unredeemed: 'Unredeemed: Order Received',
  Abandoned: 'Abandoned: Order Started',
  Pending: 'Pending: Order Created',
  PendingShipping: 'In Progress: Pending Shipping',
  CompletedShipped: 'Complete: Shipping',
  ADISync: 'In Progress: Waiting on ADI sync',
  Searching: 'Searching',
  Incomplete: 'Incomplete',
  JobEnRoute: 'In Progress: En Route',
  JobStarted: 'In Progress: Started',
  JobPaused: 'In Progress: Paused',
  JobClaimed: 'In Progress: Claimed',
  JobCompleted: 'In Progress: Completed',
  JobCancelled: 'In Progress: Cancelled',
  JobAssigned: 'In Progress: Assigned',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
  Paused: 'Paused',
  ReadyToResume: 'Ready to Resume',
  Unserviceable: 'Unserviceable',
  LateTech: 'In Progress: Tech Late Order Pending',
  /** Not derived from BE */
  Unknown: 'Unknown Status',
} as const;

/**
 * For now, mimic `order_format_label` function in the rails project.
 */
export const getOrderStatusLabel = ({order}: {order: TOrderDetails | null | undefined}) => {
  if (!order) {
    return '';
  }

  const {active, remote, status, hasOnlyProducts, progressStatusId, cancellationSource} = order;

  switch (true) {
    case !active:
      return ORDER_STATUS_LABELS.Inactive;
    case remote && status === OrderStatus.Searching:
      return ORDER_STATUS_LABELS.SearchingRemote;
    case status === OrderStatus.Unredeemed:
      return ORDER_STATUS_LABELS.Unredeemed;
    case status === OrderStatus.Abandoned:
      return ORDER_STATUS_LABELS.Abandoned;
    case status === OrderStatus.Pending:
      return ORDER_STATUS_LABELS.Pending;
    case hasOnlyProducts && status === OrderStatus.PendingShipping:
      return ORDER_STATUS_LABELS.PendingShipping;
    case hasOnlyProducts && status === OrderStatus.Completed:
      return ORDER_STATUS_LABELS.CompletedShipped;
    case hasOnlyProducts && status === OrderStatus.Searching:
      return ORDER_STATUS_LABELS.ADISync;
    case status === OrderStatus.Searching:
      return ORDER_STATUS_LABELS.Searching;
    case status === OrderStatus.Incomplete:
      return ORDER_STATUS_LABELS.Incomplete;
    case status === OrderStatus.Assigned: {
      switch (progressStatusId) {
        case ProgressStatusId.EN_ROUTE:
          return ORDER_STATUS_LABELS.JobEnRoute;
        case ProgressStatusId.STARTED:
          return ORDER_STATUS_LABELS.JobStarted;
        case ProgressStatusId.PAUSED:
          return ORDER_STATUS_LABELS.JobPaused;
        case ProgressStatusId.CLAIMED:
          return ORDER_STATUS_LABELS.JobClaimed;
        case ProgressStatusId.COMPLETED:
          return ORDER_STATUS_LABELS.JobCompleted;
        case ProgressStatusId.CANCELLED:
          return ORDER_STATUS_LABELS.JobCancelled;
        default:
          return ORDER_STATUS_LABELS.JobAssigned;
      }
    }
    case status === OrderStatus.Completed || status === OrderStatus.TechPaid:
      return ORDER_STATUS_LABELS.Completed;
    case status === OrderStatus.Cancelled: {
      const cancelledVia = cancellationSource;
      return cancelledVia ? `${ORDER_STATUS_LABELS.Cancelled} (${cancelledVia})` : ORDER_STATUS_LABELS.Cancelled;
    }
    case status === OrderStatus.ReadyToResume:
      return ORDER_STATUS_LABELS.ReadyToResume;
    case status === OrderStatus.Paused:
      return ORDER_STATUS_LABELS.Paused;
    case status === OrderStatus.Unserviceable:
      return ORDER_STATUS_LABELS.Unserviceable;
    case status === OrderStatus.LateTech:
      return ORDER_STATUS_LABELS.LateTech;
    default:
      return ORDER_STATUS_LABELS.Unknown;
  }
};

export const getBadgePropsForLabel = (label: string) => {
  let badgeProps: Partial<ComponentProps<typeof PageHeader.Chip>> = {};

  switch (label) {
    case ORDER_STATUS_LABELS.JobAssigned:
    case ORDER_STATUS_LABELS.JobEnRoute:
    case ORDER_STATUS_LABELS.JobStarted:
    case ORDER_STATUS_LABELS.JobPaused:
    case ORDER_STATUS_LABELS.JobClaimed:
    case ORDER_STATUS_LABELS.JobCompleted:
    case ORDER_STATUS_LABELS.JobCancelled:
    case ORDER_STATUS_LABELS.ADISync:
    case ORDER_STATUS_LABELS.SearchingRemote:
    case ORDER_STATUS_LABELS.PendingShipping:
    case ORDER_STATUS_LABELS.LateTech:
    case ORDER_STATUS_LABELS.Searching:
    case ORDER_STATUS_LABELS.ReadyToResume:
      badgeProps = {type: CHIP_TYPES.primary};
      break;
    case ORDER_STATUS_LABELS.Completed:
    case ORDER_STATUS_LABELS.CompletedShipped:
      badgeProps = {type: CHIP_TYPES.success};
      break;
    default: {
      badgeProps = {type: CHIP_TYPES.default};
    }
  }

  return badgeProps;
};
