import React, {useMemo} from 'react';
import cn from 'classnames';
// Types && Ducks
import {TActionLoader, TEntityTable, TCannotCompleteLog, TTemplateSwapLog} from './mdu.sideSheet.bulkEdit.types';
import {ProjectDetails, SelectOption} from 'features/MultiDwellingUnits/MDU.types';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {TDataTableColumnDef} from 'components/Elements/DataTable/dataTable.types';
// Hooks && Utils
import {useSelector} from 'hooks/useAppSelector';
import {useBulkEditHook} from './mdu.sideSheet.bulkEdit.hooks';
import {convertProjectServicesToTGroupServices} from 'features/MultiDwellingUnits/MDU.utils';
import {sortAlphabeticallyOrNumerically} from 'components/Elements/DataTable/utils/sort.utils';
// Queries
import {useGetReasons} from 'features/MultiDwellingUnits/queries/query.getReasons';
// Components
import {Player} from '@lottiefiles/react-lottie-player';
import {ELEMENT_SIZE, SideSheet, CalloutBox, Form, SelectField, TextArea} from 'ht-styleguide';
import DataTable from 'components/Elements/DataTable';
import actionInProgress from 'assets/lottie/action-in-progress.json';
import actionComplete from 'assets/lottie/action-completed.json';
import ChipStatus from 'features/MultiDwellingUnits/Parts/Chip';
import GroupServicesBoxInfo from '../../GroupServicesBoxInfo';
import styles from './mdu.sideSheet.bulkEdit.styles.scss';

const ActionLoader = ({actionLoaderSubtextCopy, bulkEditSuccessful}: TActionLoader) => {
  const lottieSrc = bulkEditSuccessful ? actionComplete : actionInProgress;
  const subtextColor = bulkEditSuccessful ? styles.subtextSuccess : 'n700';
  const loop = !bulkEditSuccessful;

  return (
    <div data-testid="MDUSideSheetBulkEdit-ActionLoader" className={cn('marginTop-huge1', 'flex', 'align-items-center', 'flex-direction-column')}>
      <Player autoplay loop={loop} keepLastFrame src={lottieSrc} style={{width: '248px', height: '248px'}} />
      <h3 className={cn('marginTop-medium1', 'text-center', subtextColor)}>{actionLoaderSubtextCopy}</h3>
    </div>
  );
};

const CannotCompleteLog = ({showCannotCompleteLog, cannotCompleteReasonId, cannotCompleteReasonText, onReasonChange, onReasonTextChange}: TCannotCompleteLog) => {
  const {data, isLoading} = useGetReasons({options: {enabled: showCannotCompleteLog}});

  const options = useMemo(() => {
    if (data) {
      return data.unserviceable.map(us => ({
        label: us.text,
        value: us.id,
      }));
    }
    return [];
  }, [data]);

  return showCannotCompleteLog ? (
    <section className="marginTop-medium1">
      <h6 className="marginBottom-medium">Issue Log</h6>
      {isLoading ? (
        <p className="p1 n700">Loading reasons...</p>
      ) : (
        <Form withoutFormTag>
          <Form.Row>
            <Form.Column lg={12} md={8} sm={4}>
              <SelectField label="Why can't these jobs be completed?" placeholder="Please select a reason" options={options} value={cannotCompleteReasonId} onChange={onReasonChange} />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column lg={12} md={8} sm={4}>
              <TextArea label="Describe the issue" value={cannotCompleteReasonText} onChange={onReasonTextChange} />
            </Form.Column>
          </Form.Row>
        </Form>
      )}
    </section>
  ) : null;
};

const TemplateSwapLog = ({isVisible, setSwapTemplateId, swapTemplateId}: TTemplateSwapLog) => {
  const currentProject: ProjectDetails = useSelector(mduProjectsSlice.selectors.getCurrentProject);
  const templates = currentProject?.projectGroups || [];
  const templateSelectOptions = templates.map(template => ({
    label: template.name,
    value: template.id,
  }));

  const selectedTemplate = templates.find(template => template.id === swapTemplateId);
  const title = selectedTemplate?.name || '';
  const services = convertProjectServicesToTGroupServices(selectedTemplate?.projectServices || []);
  const onChange = (e: SelectOption) => {
    setSwapTemplateId(e.value as number);
  };
  if (!isVisible) return null;
  return (
    <div className="marginTop-medium1">
      <SelectField label="New Job Template" placeholder="Select template" options={templateSelectOptions} value={swapTemplateId} onChange={onChange} />
      <GroupServicesBoxInfo services={services} title={title} />
    </div>
  );
};

const EntityTable = ({nonApplicableEntityList = [], failedEntityList = [], entity}: TEntityTable) => {
  const singularEntity = entity.replace(/s$/, '') as 'job' | 'project';
  const nonApplicableCount = nonApplicableEntityList.length;
  const failedCount = failedEntityList.length;
  const hasFailures = !!failedCount;
  const listToShow = hasFailures ? failedEntityList : nonApplicableEntityList;
  const countToShow = hasFailures ? failedCount : nonApplicableCount;
  const actionText = hasFailures ? 'Failed' : 'Exceptions';

  /**
   * Normalize data for DataTable. Since there are two entity types to work with, it's easier
   * to deal with normalized data for the table, especially when it comes to sorting.
   */
  const normalizedList = useMemo(() => {
    return listToShow.map(item => {
      const {name: projectName, status: projectStatus = '', partner_name: partnerName = ''} = item.project || {};
      const {unit_name: unitName, status: unitStatus = ''} = item.unit || {};

      const name = entity === 'projects' ? projectName || '' : unitName || '--';
      const status = entity === 'projects' ? projectStatus : unitStatus;

      return {
        name,
        partnerName,
        status,
      };
    });
  }, [entity, listToShow]);

  type TNormalizedData = (typeof normalizedList)[number];

  const columns = useMemo(() => {
    const nameHeader = entity === 'projects' ? 'Name' : 'Unit';
    const colDefs: TDataTableColumnDef<TNormalizedData>[] = [
      {
        header: nameHeader,
        accessorKey: 'name',
        Cell: ({row}) => {
          const {name, partnerName} = row.original;
          return (
            <>
              <p className="p2">{name}</p>
              {partnerName && <p className="caption n700">{partnerName}</p>}
            </>
          );
        },
        enableSorting: true,
        sortingFn: sortAlphabeticallyOrNumerically,
        columnWidthMode: 'fill',
        columnWidthSize: 'sm',
      },
      {
        header: 'Status',
        accessorKey: 'status',
        Cell: ({row}) => {
          const {status} = row.original;
          return (
            <ChipStatus statusType={singularEntity} status={status} size={ELEMENT_SIZE.SMALL}>
              {status}
            </ChipStatus>
          );
        },
        sortingFn: sortAlphabeticallyOrNumerically,
        columnWidthMode: 'collapse',
      },
    ];
    return colDefs;
  }, [entity, singularEntity]);

  if (!nonApplicableCount && !hasFailures) {
    return null;
  }

  return (
    <section className="marginTop-medium1">
      <div data-testid="MDUSideSheetBulkEdit-EntityTable-headerBlock" className="flex flex-direction-row justify-content-space-between">
        <h6 className="n700 marginBottom-small1">
          {actionText} ({countToShow})
        </h6>
      </div>
      <DataTable<TNormalizedData> tableKey={entity} data={normalizedList} columns={columns} />
    </section>
  );
};

/**
 * <SideSheetBulkEdit />
 */
const SideSheetBulkEdit = () => {
  const {
    actionLoaderSubtextCopy,
    bulkEditSuccessful,
    buttonProps,
    calloutBoxProps,
    cannotCompleteReasonId,
    cannotCompleteReasonText,
    closeSideSheet,
    entity,
    failedEntityList,
    headerText,
    isLoading,
    isPerformingBulkAction,
    nonApplicableEntityList,
    onReasonChange,
    onReasonTextChange,
    showCannotCompleteLog,
    showTemplateSwapLog,
    sideSheetIsOpen,
    subheaderText,
    swapTemplateId,
    setSwapTemplateId,
  } = useBulkEditHook();

  const showActionLoader = isPerformingBulkAction || bulkEditSuccessful;
  const removeSideSheetButtons = isLoading || isPerformingBulkAction;

  const content = (() => {
    if (isLoading) {
      return null;
    }
    if (showActionLoader) {
      return <ActionLoader bulkEditSuccessful={bulkEditSuccessful} actionLoaderSubtextCopy={actionLoaderSubtextCopy} />;
    }
    return (
      <>
        <CalloutBox {...calloutBoxProps} />
        <CannotCompleteLog
          showCannotCompleteLog={showCannotCompleteLog}
          cannotCompleteReasonId={cannotCompleteReasonId}
          cannotCompleteReasonText={cannotCompleteReasonText}
          onReasonChange={onReasonChange}
          onReasonTextChange={onReasonTextChange}
        />
        <TemplateSwapLog isVisible={showTemplateSwapLog} swapTemplateId={swapTemplateId} setSwapTemplateId={setSwapTemplateId} />
        <EntityTable entity={entity} nonApplicableEntityList={nonApplicableEntityList} failedEntityList={failedEntityList} />
      </>
    );
  })();

  return (
    <SideSheet
      hide={closeSideSheet}
      headerText={headerText}
      subheaderText={subheaderText}
      isOpen={sideSheetIsOpen}
      {...buttonProps}
      showCloseButton={!removeSideSheetButtons}
      showFooter={!removeSideSheetButtons}
    >
      {content}
    </SideSheet>
  );
};

export default SideSheetBulkEdit;
