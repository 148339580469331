import {useFormik} from 'formik';

export enum CreateCreditSideSheetFormFields {
  CreditName = 'credit_name',
  ExpirationDate = 'expiration_date',
  Amount = 'amount',
  PartnerId = 'partner_id',
  CreditItemsAttributes = 'credit_items_attributes',
  // Only for form validation purposes - not to be passed to API
  SkuOrPlanSelected = 'skuOrPlanSelected',
}

export type TCreditItemAttribute = {
  item_type: 'Sku' | 'SkuCategory' | 'Plan' | 'PlanProduct';
  item_name: string;
  item_category_name: string | null;
  item_id: number;
};

export type TCreateFormValues = {
  [CreateCreditSideSheetFormFields.CreditName]: string;
  [CreateCreditSideSheetFormFields.ExpirationDate]: string;
  /**
   * Represented in dollars: 79.99
   */
  [CreateCreditSideSheetFormFields.Amount]: string;
  [CreateCreditSideSheetFormFields.PartnerId]: number | null;
  [CreateCreditSideSheetFormFields.CreditItemsAttributes]: Array<TCreditItemAttribute>;
  /**
   * Only for form validation
   */
  [CreateCreditSideSheetFormFields.SkuOrPlanSelected]: boolean;
};

export type TUseFormikReturn = ReturnType<typeof useFormik<TCreateFormValues>>;

/**
 * Data format that the main table expects
 */
export type TNormalizedItem = {
  id: string | number;
  name: string;
  items: Array<{id: number; name: string}>;
};

/**
 * Data format that the search table expects. The data passed is a
 * derivative of the normalized data.
 */
export type TSearchTableNormalizedItem = TNormalizedItem['items'][number] & {
  parentId: TNormalizedItem['id'];
};
