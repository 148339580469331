import {DispatchNavTypes, UnassignedOrdersColumnKeys, TUnassignedOrdersColumnKeysValues, UnassignedFilterKeys} from './dispatch.types';

/*
*******************************************************
  Page Params for Unassigned List
*******************************************************
*/

export type TDispatchUnassignedListParamsPerKey = {
  /**
   * Columns that should not appear on the page
   */
  omitColumnKeys: Array<TUnassignedOrdersColumnKeysValues>;
  filterKeys: Array<UnassignedFilterKeys>;
  pageTitle: string;
  /**
   * Perisitent filters that should always be applied to the page.
   */
  pageFilters: {
    useCurrentUser?: boolean;
  };
  /**
   * Filters that are applied to the stats endpoint
   */
  statsFilters: {
    useCurrentUser?: boolean;
  };
};

type TDispatchUnassignedListParams = {
  [key in DispatchNavTypes]: TDispatchUnassignedListParamsPerKey;
};

export const DISPATCH_UNASSIGNED_LIST_PARAMS: TDispatchUnassignedListParams = {
  [DispatchNavTypes.All]: {
    omitColumnKeys: [],
    filterKeys: [
      UnassignedFilterKeys.AgentId,
      UnassignedFilterKeys.PartnerId,
      UnassignedFilterKeys.OrderId,
      UnassignedFilterKeys.Tier,
      UnassignedFilterKeys.State,
      UnassignedFilterKeys.OnHold,
      UnassignedFilterKeys.Standby,
      UnassignedFilterKeys.CBSAId,
      UnassignedFilterKeys.SkuCategoryIds,
      UnassignedFilterKeys.SkuIds,
      UnassignedFilterKeys.CreatedAt,
    ],
    pageTitle: 'All unassigned jobs',
    pageFilters: {},
    statsFilters: {},
  },
  [DispatchNavTypes.AssignedToMe]: {
    omitColumnKeys: [UnassignedOrdersColumnKeys.AgentAssigned],
    filterKeys: [
      UnassignedFilterKeys.PartnerId,
      UnassignedFilterKeys.OrderId,
      UnassignedFilterKeys.Tier,
      UnassignedFilterKeys.State,
      UnassignedFilterKeys.OnHold,
      UnassignedFilterKeys.Standby,
      UnassignedFilterKeys.CBSAId,
      UnassignedFilterKeys.SkuCategoryIds,
      UnassignedFilterKeys.SkuIds,
      UnassignedFilterKeys.CreatedAt,
    ],
    pageTitle: 'Jobs assigned to me',
    pageFilters: {
      useCurrentUser: true,
    },
    statsFilters: {
      useCurrentUser: true,
    },
  },
};

/*
*******************************************************
  Unassigned Orders Table and Columns
*******************************************************
*/

export const UNASSIGNED_ORDERS_COLUMN_LABELS = {
  [UnassignedOrdersColumnKeys.Priority]: 'Priority',
  [UnassignedOrdersColumnKeys.Id]: 'ID',
  [UnassignedOrdersColumnKeys.LastAvailabilityIn]: 'Last Availability',
  [UnassignedOrdersColumnKeys.OfferCount]: 'Techs Offered',
  [UnassignedOrdersColumnKeys.Payout]: 'Payout',
  [UnassignedOrdersColumnKeys.FNStatus]: 'FN Status',
  [UnassignedOrdersColumnKeys.Partner]: 'Partner',
  [UnassignedOrdersColumnKeys.CreatedAt]: 'Booked On',
  [UnassignedOrdersColumnKeys.Tier]: 'Order Tier',
  [UnassignedOrdersColumnKeys.OfferStatus]: 'Offer Status',
  [UnassignedOrdersColumnKeys.State]: 'State',
  [UnassignedOrdersColumnKeys.Zip]: 'Zip',
  [UnassignedOrdersColumnKeys.FNOrderId]: 'FN Order',
  [UnassignedOrdersColumnKeys.PartnerId]: 'Partner ID',
  [UnassignedOrdersColumnKeys.DefaultGeekCut]: 'Default Payout',
  [UnassignedOrdersColumnKeys.CurrentIncentive]: 'Current Incentive',
  [UnassignedOrdersColumnKeys.CBSA]: 'CBSA',
  [UnassignedOrdersColumnKeys.LastOfferSentAt]: 'Last Offer Sent',
  [UnassignedOrdersColumnKeys.Timezone]: 'Timezone',
  [UnassignedOrdersColumnKeys.LastOrderNote]: 'Last Order Note',
  [UnassignedOrdersColumnKeys.LastAvailabilityAt]: 'Last Availability Date',
  [UnassignedOrdersColumnKeys.HasStandby]: 'Has Standby',
  [UnassignedOrdersColumnKeys.LateTechReleased]: 'Late Tech Released',
  [UnassignedOrdersColumnKeys.AgentAssigned]: 'Agent Assigned',
  [UnassignedOrdersColumnKeys.Latitude]: 'Latitude',
  [UnassignedOrdersColumnKeys.Longitude]: 'Longitude',
};

export const DEFAULT_UNASSIGNED_ORDERS_COLUMNS = [
  UnassignedOrdersColumnKeys.Priority,
  UnassignedOrdersColumnKeys.Id,
  UnassignedOrdersColumnKeys.LastAvailabilityIn,
  UnassignedOrdersColumnKeys.OfferCount,
  UnassignedOrdersColumnKeys.Payout,
  UnassignedOrdersColumnKeys.FNStatus,
  UnassignedOrdersColumnKeys.Partner,
  UnassignedOrdersColumnKeys.CreatedAt,
  UnassignedOrdersColumnKeys.Tier,
  UnassignedOrdersColumnKeys.State,
  UnassignedOrdersColumnKeys.Zip,
  UnassignedOrdersColumnKeys.OfferStatus,
];

export const UNASSIGNED_ORDERS_COLUMN_DISABLED_USER_CONTROL: Array<TUnassignedOrdersColumnKeysValues> = [
  UnassignedOrdersColumnKeys.Id,
  UnassignedOrdersColumnKeys.Priority,
  UnassignedOrdersColumnKeys.OfferStatus,
];

// For filters modal and stats widget
export const UNASSIGNED_FILTER_LABELS = {
  AgentAssigned: 'Agent Assigned',
  PartnerId: 'Partner Name',
  OrderId: 'Order ID',
  Tier: 'Order Tier',
  State: 'State',
  CBSAId: 'CBSA',
  Standby: 'Order Has Standby',
  OnHold: 'Order On Hold',
  SkuCategoryIds: 'Order has SKU Category',
  SkuIds: 'Order has SKUs',
  CreatedAt: 'Date Created',
  AtRisk: 'At Risk',
  NeedsAttention: 'Needs Attention',
  LateTechRelease: 'Late Tech Release',
};
