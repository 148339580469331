import React, {useCallback, useEffect, useRef, useState} from 'react';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import set from 'lodash/set';
import get from 'lodash/get';
// Hooks
import {useQueryClient} from 'react-query';
import {useSelector} from 'react-redux';
import {useBlocker, useNavigate, useParams} from 'react-router-dom';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useFormik} from 'formik';
// Utils
import {noop} from 'utils/event';
import {formatAddressForRequest, getStartDateLabel, isProjectStartDateSet} from 'features/MultiDwellingUnits/MDU.utils';
import {generatePropertyOwnerKey} from 'features/MultiDwellingUnits/queries/query.project.fields';
import {isValidPhoneNumber} from 'utils/phone';
// Routes
import {mduProjectPagePath} from 'global/paths';
// Types
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {CurrentProjectStages, ProjectCreationFormFields, ProjectDetails, MduUseParamsTypes} from 'features/MultiDwellingUnits/MDU.types';
import {FormCategories, ProjectDetailsPageHeaderTypes} from './CurrentProject.Details.types'; // eslint-disable-line import/order
// Components
import {Form, InputField, TextArea, Modal, Button, BUTTON_THEMES} from 'ht-styleguide';
import ProjectManagerSelect from 'features/MultiDwellingUnits/Parts/FormFields/ProjectManagerSelect';
import ProjectDetailsPageHeader from './CurrentProject.Details.PageHeader';
import {EditSection} from './CurrentProject.Details.EditSection';
import {AddressFieldByProjectStatus, PartnerFieldByProjectStatus, ProjectTypeFieldByProjectStatus, PropertyOwnerFieldByProjectStatus} from './FieldVariants';
// Constants
import {DETAILS_FORM_FIELDS} from './currentProjectDetails.constants';
import {isoFormat} from 'global/constants/common';

const {PROJECT_NAME, PARTNER, PROJECT_MANAGER, PROPERTY_OWNER, PROJECT_TYPE, START_DATE, END_DATE, ADDRESS, NUM_FLOORS, ACCESS_INSTRUCTIONS, CONTACT_NAME, CONTACT_PHONE, CONTACT_TITLE, OTHER} =
  DETAILS_FORM_FIELDS;

/**
 * Pre-launch the BE allows for a project to be updated incrementally. Field validation occurs on the BE when
 * the client requests the project move to a post-launch status. The job of this form is to allow the incremental
 * updating of the project.
 */
const updateCurrentProjectSchema = Yup.object().shape({
  // Project
  [PROJECT_NAME.id]: Yup.string().required('Required'),
  [PARTNER.id]: Yup.string().required('Required'),
  [PROJECT_MANAGER.id]: Yup.string().required('Required'),
  [PROPERTY_OWNER.id]: Yup.string().when(PROPERTY_OWNER.otherId!, {
    is: value => Boolean(value),
    then: Yup.string().nullable(),
    otherwise: Yup.string().required('Required'),
  }),
  [PROPERTY_OWNER.otherId!]: Yup.string().nullable(),
  [PROJECT_TYPE.id]: Yup.string().required('Required'),
  [START_DATE.id]: Yup.string(),
  [END_DATE.id]: Yup.string(),
  // Property
  [ADDRESS.id]: Yup.string(),
  [NUM_FLOORS.id]: Yup.number().typeError('Must be a number'),
  [ACCESS_INSTRUCTIONS.id]: Yup.string(),
  // On-Site Contact
  [CONTACT_NAME.id]: Yup.string(),
  [CONTACT_PHONE.id]: Yup.string().test({
    /** Allow the client to save a blank phone. BE will validate phone presence when it tries to migrate to 'launched' status */
    test: (val: string | undefined) => (val ? isValidPhoneNumber(val) : true),
    message: 'Please enter a valid 10 digit phone number',
  }),
  [CONTACT_TITLE.id]: Yup.string(),
  // Other
  [OTHER.id]: Yup.string(),
});

/** Grab data from currentProject in Redux and turn it into a format formik can use */
export const getInitialFormValues = (project: ProjectDetails) => {
  return Object.values(DETAILS_FORM_FIELDS).reduce((formikObj, field) => {
    let dataFromProject = get(project, field.projectKey);

    if (field.id === START_DATE.id) {
      // If the project has been started, use the startedAt date. Otherwise, use the startDate
      dataFromProject = isProjectStartDateSet(project) ? dayjs(project.startedAt).format(isoFormat) : project.startDate;
    }
    set(formikObj, field.id, dataFromProject || '');
    return formikObj;
  }, {} as ProjectCreationFormFields);
};

/* Form that updates Project Details */
const CurrentProjectDetailsEditPage = ({onSubmitSuccess}: {onSubmitSuccess?: BaseAnyFunction}) => {
  /* Hooks */
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const partnerChangeCallback = useRef(noop);
  const [exitGuard, setExitGuard] = useState({isOk: false, path: ''});
  const [showPartnerChangeModal, setShowPartnerChangeModal] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);

  /* Data */
  const currentProject: ProjectDetails = useSelector(mduProjectsSlice.selectors.getCurrentProject);
  const {projectId = ''} = useParams<MduUseParamsTypes>();
  const isProjectStarted = isProjectStartDateSet(currentProject);

  /* Form Validation */
  const updateProject = async ({projectDetails, path}: {projectDetails: ProjectCreationFormFields; path: string; callback?: BaseAnyFunction}) => {
    const successCallback = () => {
      setExitGuard(obj => ({...obj, path, isOk: true}));
      // invalidate project fields query to force a refetch
      queryClient.invalidateQueries(generatePropertyOwnerKey(true));
    };

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (!formik.dirty) return successCallback();

    const addressObj = formatAddressForRequest(projectDetails[ADDRESS.id]);
    const response = await dispatch(
      mduProjectsSlice.actions.updateProject({
        projectId,
        projectDetails: {
          // @ts-ignore
          project: {...projectDetails, addressObj},
        },
        onSuccess: successCallback,
      })
    );
    return response;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialFormValues(currentProject),
    validationSchema: updateCurrentProjectSchema,
    validateOnChange: true,
    onSubmit: async projectParams => {
      /* Update name. Trim it */
      projectParams.name = projectParams.name.trim();

      await updateProject({
        projectDetails: projectParams,
        path: mduProjectPagePath(projectId, CurrentProjectStages.DETAILS),
        callback: onSubmitSuccess,
      });
    },
  });
  const onAddressChange = ({address}: {address: string}) => {
    // The AddressAutocomplete component hijacks the native onChange event, so we have to manually call formik.handleChange
    formik.handleChange({target: {name: ADDRESS.id, value: address}});
  };

  const pushToNextPath = useCallback((location: string) => navigate(location), [navigate]);

  const isPartnerChanged = String(formik.values.partnerId) !== String(currentProject.partner?.id);

  /** Guard against user changing partner. The callback should fire the API update request */
  const attemptToChangePartner = (callback: BaseAnyFunction) => {
    partnerChangeCallback.current = callback;
    if (isPartnerChanged) {
      setShowPartnerChangeModal(true);
      return;
    }
    partnerChangeCallback.current();
  };

  const handleSubmit = () => {
    if (!formik.isSubmitting) {
      formik.submitForm();
    }
  };

  const handleSaveButtonClick = () => {
    attemptToChangePartner(() => handleSubmit());
  };

  /** Accept button callback for Unsaved Changes modal */
  const handleAcceptChanges = () => {
    setShowUnsavedChangesModal(false);
    attemptToChangePartner(() => updateProject({projectDetails: formik.values, path: exitGuard.path, callback: onSubmitSuccess}));
  };

  /** Cancel button callback for Unsaved Changes modal */
  const handleDiscardChanges = () => {
    setShowUnsavedChangesModal(false);
    setExitGuard(obj => ({...obj, isOk: true}));
  };

  useEffect(() => {
    if (exitGuard.isOk) {
      pushToNextPath(exitGuard.path);
    }
  }, [pushToNextPath, exitGuard]);

  // Prevent user from navigating away based on conditions
  useBlocker(({currentLocation, nextLocation}) => {
    if (!formik.isValid) return true; // Stop and make client fix issue
    if (!formik.dirty) return false; // Allow client to proceed to next path
    if (nextLocation.pathname !== String(currentLocation.pathname)) {
      setShowUnsavedChangesModal(true);
    }
    setExitGuard(obj => ({...obj, path: nextLocation.pathname}));
    if (exitGuard.isOk) {
      return false;
    }
    return true;
  });

  return (
    <div className="paddingBottom-medium1">
      <ProjectDetailsPageHeader onClick={handleSaveButtonClick} type={ProjectDetailsPageHeaderTypes.Save} />

      <EditSection headerText={FormCategories.Project}>
        <Form>
          <Form.Row classes="marginTop-small2">
            <Form.Column>
              <InputField label={PROJECT_NAME.label} id={PROJECT_NAME.id} error={formik.errors[PROJECT_NAME.id]} onChange={formik.handleChange} value={formik.values[PROJECT_NAME.id] as string} />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column lg={6} md={8} sm={4}>
              <PartnerFieldByProjectStatus formik={formik} label={PARTNER.label} fieldName={PARTNER.id} project={currentProject} />
            </Form.Column>
            <Form.Column lg={6} md={8} sm={4}>
              <ProjectManagerSelect formik={formik} label={PROJECT_MANAGER.label} fieldName={PROJECT_MANAGER.id} project={currentProject} />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column lg={6} md={8} sm={4}>
              <PropertyOwnerFieldByProjectStatus formik={formik} label={PROPERTY_OWNER.label} fieldName={PROPERTY_OWNER.id} project={currentProject} />
            </Form.Column>
            <Form.Column lg={6} md={8} sm={4}>
              <ProjectTypeFieldByProjectStatus formik={formik} label={PROJECT_TYPE.label} fieldName={PROJECT_TYPE.id} project={currentProject} />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column lg={6} md={8} sm={4}>
              <InputField
                type="date"
                id={START_DATE.id}
                label={getStartDateLabel({project: currentProject, isRequiredField: true})}
                value={formik.values[START_DATE.id] as string}
                error={formik.errors[START_DATE.id]}
                onChange={formik.handleChange}
                disabled={isProjectStarted}
                iconName={isProjectStarted ? 'lock' : undefined}
              />
            </Form.Column>
            <Form.Column lg={6} md={8} sm={4}>
              <InputField type="date" id={END_DATE.id} label={END_DATE.label} value={formik.values[END_DATE.id] as string} error={formik.errors[END_DATE.id]} onChange={formik.handleChange} />
            </Form.Column>
          </Form.Row>
        </Form>
      </EditSection>

      <EditSection headerText={FormCategories.Property}>
        <Form>
          <Form.Row>
            <Form.Column lg={6} md={8} sm={4}>
              <AddressFieldByProjectStatus label={ADDRESS.label} project={currentProject} formik={formik} onChange={onAddressChange} fieldName={ADDRESS.id} />
            </Form.Column>
            <Form.Column lg={6} md={8} sm={4}>
              <InputField
                label={NUM_FLOORS.label}
                id={NUM_FLOORS.id}
                value={formik.values[NUM_FLOORS.id] as string}
                error={formik.errors[NUM_FLOORS.id]}
                onChange={formik.handleChange}
                placeholder={NUM_FLOORS.placeholder}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextArea
                label={ACCESS_INSTRUCTIONS.label}
                name={ACCESS_INSTRUCTIONS.id}
                value={formik.values[ACCESS_INSTRUCTIONS.id] as string}
                error={formik.errors[ACCESS_INSTRUCTIONS.id]}
                onChange={formik.handleChange}
                rows={5}
              />
            </Form.Column>
          </Form.Row>
        </Form>
      </EditSection>

      <EditSection headerText={FormCategories.Contact}>
        <Form>
          <Form.Row>
            <Form.Column lg={6} md={8} sm={4}>
              <InputField label={CONTACT_NAME.label} id={CONTACT_NAME.id} value={formik.values[CONTACT_NAME.id] as string} error={formik.errors[CONTACT_NAME.id]} onChange={formik.handleChange} />
            </Form.Column>
            <Form.Column lg={6} md={8} sm={4}>
              <InputField
                label={CONTACT_PHONE.label}
                id={CONTACT_PHONE.id}
                value={formik.values[CONTACT_PHONE.id] as string}
                error={formik.errors[CONTACT_PHONE.id]}
                onChange={formik.handleChange}
                mask="phone"
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column lg={6} md={8} sm={4}>
              <InputField label={CONTACT_TITLE.label} id={CONTACT_TITLE.id} value={formik.values[CONTACT_TITLE.id] as string} error={formik.errors[CONTACT_TITLE.id]} onChange={formik.handleChange} />
            </Form.Column>
          </Form.Row>
        </Form>
      </EditSection>

      <EditSection headerText={FormCategories.Other}>
        <Form>
          <Form.Row>
            <Form.Column lg={12} md={8} sm={4}>
              <TextArea name={OTHER.id} error={formik.errors[OTHER.id]} onChange={formik.handleChange} rows={5} value={formik.values[OTHER.id] as string} />
            </Form.Column>
          </Form.Row>
        </Form>
      </EditSection>

      <Modal
        header="Changing project partner"
        isVisible={showPartnerChangeModal}
        hide={() => setShowPartnerChangeModal(false)}
        footerElement2={
          <Button theme={BUTTON_THEMES.SECONDARY} onClick={() => setShowPartnerChangeModal(false)}>
            Cancel
          </Button>
        }
        footerElement3={
          <Button theme={BUTTON_THEMES.PRIMARY} onClick={partnerChangeCallback.current}>
            Proceed
          </Button>
        }
      >
        All jobs and templates will be reset for this project.
      </Modal>
      <Modal
        header="You have unsaved changes"
        isVisible={showUnsavedChangesModal}
        hide={() => setShowUnsavedChangesModal(false)}
        footerElement2={
          <Button theme={BUTTON_THEMES.SECONDARY} onClick={handleDiscardChanges}>
            Discard Changes
          </Button>
        }
        footerElement3={
          <Button theme={BUTTON_THEMES.PRIMARY} onClick={handleAcceptChanges}>
            Save Changes
          </Button>
        }
      >
        Save or discard your project detail changes to continue.
      </Modal>
    </div>
  );
};

export default CurrentProjectDetailsEditPage;
