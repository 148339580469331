import {createSlice} from '@reduxjs/toolkit';
import {Notify} from './Notification';

/**
 * @param level: level affected. page | component?
 * @param type: error | info etc.
 * @param message: message to display
 * @type {{notifications: {level: string, type: string, message: string}}}
 */
const initialState: Notify = {
  type: '',
  message: '',
  level: 'page',
  id: '',
};

const {actions, reducer} = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    notificationApiPending: state => {
      state.type = '';
      state.message = '';
      state.level = 'page';
    },
    notificationApiError: (state, action) => {
      const {source = 'Api Error'} = action.payload;
      state.type = 'error';
      state.message = `Request Failed: ${source}`;
      state.level = 'page';
      state.id = source.replace(/\s/g, '');
    },
    notificationCustomError: (state, action) => {
      const {source = 'Api Error', customizedProps = {}} = action.payload;
      state.type = 'error';
      state.message = source;
      state.level = 'page';
      state.id = 'custom'; // source.replace(/\s/g, '');

      Object.keys(customizedProps).forEach(key => {
        // @ts-ignore
        state[key] = customizedProps[key];
      });
    },
  },
});

export const notifications = {
  actions,
  reducer,
};
