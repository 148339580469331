import {ReactNode} from 'react';

export enum FormCategories {
  Project = 'Project',
  Property = 'Property',
  Contact = 'On-Site Contact',
  Other = 'Other Notes',
}

export enum ProjectDetailsPageHeaderTypes {
  Edit = 'edit',
  Save = 'save',
}

export type EditSectionProps = {children: ReactNode; headerText: string};

export type ReadOnlyPanelProps = {
  headerText: string;
  children: ReactNode;
  hasRequiredFields: boolean;
};

export type ReadOnlyFieldProps = {
  headerText?: string;
  valueText?: string;
  valueIconName?: string;
  missingValueText?: string;
  isAvatarField?: boolean;
};
