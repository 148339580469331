/* eslint-disable @typescript-eslint/naming-convention */
import {ComponentProps, useState, useEffect, useCallback, ChangeEvent, useMemo} from 'react';
import capitalize from 'lodash/capitalize';
import {BUTTON_THEMES, CalloutBox, CalloutBoxThemes, SideSheet, SelectOptions} from 'ht-styleguide';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {
  useApproveProjectActionMutation,
  useDeleteProjectActionMutation,
  useApproveUnitActionMutation,
  useDeleteUnitActionMutation,
  useCannotCompleteUnitActionMutation,
  useReopenUnitActionMutation,
  useAddServiceToUnitsMutation,
  usePerformProjectActionMutation,
  useSwapTemplateActionMutation,
} from 'features/MultiDwellingUnits/queries/mutation.projects.bulkActions';
import {CustomFilterTypes, TBulkActionTypes, TProjectActionResponse, TServiceBulkAddQAFormat} from 'features/MultiDwellingUnits/MDU.types';
import {useProjectsRouteParams} from 'features/MultiDwellingUnits/Pages/Projects/hooks';
import {useSetFilters} from 'features/MultiDwellingUnits/Pages/Projects/Parts/MDUProjectsFilters/MDUProjectsFilters.hooks';
import {QuestionsAPIByQuestion} from 'features/Questions/types';
import {useSelector} from 'hooks/useAppSelector';
import usePrevious from 'hooks/usePrevious';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {pluralize} from 'utils/text';
import {TEntity, TActions} from './mdu.sideSheet.bulkEdit.types';

export const extractEntityAction = (actionItemBulkKey: TBulkActionTypes | null | undefined): {entity: TEntity | ''; action: TActions | ''} => {
  switch (actionItemBulkKey) {
    case TBulkActionTypes.APPROVE_BULK_PROJECTS: {
      return {entity: 'projects', action: 'approve'};
    }
    case TBulkActionTypes.DELETE_BULK_PROJECTS: {
      return {entity: 'projects', action: 'delete'};
    }
    case TBulkActionTypes.APPROVE_BULK_JOBS: {
      return {entity: 'jobs', action: 'approve'};
    }
    case TBulkActionTypes.DELETE_BULK_JOBS: {
      return {entity: 'jobs', action: 'delete'};
    }
    case TBulkActionTypes.REOPEN_BULK_JOBS: {
      return {entity: 'jobs', action: 'reopen'};
    }
    case TBulkActionTypes.CANNOT_COMPLETE_BULK_JOBS: {
      return {entity: 'jobs', action: 'cannotComplete'};
    }
    case TBulkActionTypes.SWAP_BULK_TEMPLATES: {
      return {entity: 'templates', action: 'swap'};
    }
    case TBulkActionTypes.ADD_BULK_SERVICES: {
      return {entity: 'services', action: 'addService'};
    }
    default:
      return {entity: '', action: ''};
  }
};

/**
 * For project pages, we need to match the filters used on the MDU projects table. This hook is recreating
 * the logic used in `query.project.paginatedFiltered`.
 *
 * Note: Since this component is not being used on the projects search UI, so
 * pulling `pageFilters` directly from `useProjectsRouteParams` is fine for now.
 */
const useProjectPageFilters = () => {
  const {pageFilters, projectsPage} = useProjectsRouteParams();
  const {filters} = useSetFilters(projectsPage);
  return {projectAPIFilters: {...pageFilters, ...filters}};
};

export const useBulkEditHook = () => {
  const dispatch = useAppDispatch();
  const selectedSku = useSelector(mduProjectsSlice.selectors.getAddSkuSelectedSku);
  const skuQuestions = useSelector(mduProjectsSlice.selectors.getAddSkuQuestions);
  const skuQuestionsForSelectedSku: QuestionsAPIByQuestion[] = useMemo(() => {
    if (!selectedSku?.skuId) return [];
    return skuQuestions.entities[selectedSku.skuId]?.questions || [];
  }, [skuQuestions, selectedSku?.skuId]);

  const actionItemBulkKey = useSelector(mduProjectsSlice.selectors.getActionItemBulk)?.key;
  const bulkActionEntityIds = useSelector(mduProjectsSlice.selectors.getBulkActionIds);
  const bulkActionsSelectAll = useSelector(mduProjectsSlice.selectors.getBulkActionsSelectAll);
  // For bulk editing projects only
  let {projectAPIFilters} = useProjectPageFilters();
  projectAPIFilters = bulkActionsSelectAll ? projectAPIFilters : ({} as typeof projectAPIFilters);
  // For bulk editing jobs only
  const {id: currentProjectId, name: currentProjectName} = useSelector(mduProjectsSlice.selectors.getCurrentProject) || {};
  const jobsAPIFilters = useSelector(mduProjectsSlice.selectors.getFilterByCustomKeyState(CustomFilterTypes.JOBS)) || {};

  const {entity, action} = extractEntityAction(actionItemBulkKey);
  const sideSheetIsOpen = !!actionItemBulkKey;
  const previousSideSheetIsOpen = usePrevious(sideSheetIsOpen);

  // Collecting initial project action data + performing bulk action
  const [projectActionData, setProjectActionData] = useState<TProjectActionResponse>();
  const {mutateAsync: fetchEligibleApprovedProjects, isLoading: isFetchingEligibleApprovedProjects} = useApproveProjectActionMutation();
  const {mutateAsync: fetchEligibleDeletableProjects, isLoading: isFetchingEligibleDeletableProjects} = useDeleteProjectActionMutation();
  const {mutateAsync: fetchEligibleApprovedUnits, isLoading: isFetchingEligibleApprovedUnits} = useApproveUnitActionMutation();
  const {mutateAsync: fetchEligibleDeletableUnits, isLoading: isFetchingEligibleDeletableUnits} = useDeleteUnitActionMutation();
  const {mutateAsync: fetchEligibleCannotCompleteUnits, isLoading: isFetchingEligibleCannotCompleteUnits} = useCannotCompleteUnitActionMutation();
  const {mutateAsync: fetchEligibleReopenUnits, isLoading: isFetchingEligibleReopenUnits} = useReopenUnitActionMutation();
  const {mutateAsync: fetchEligibleTemplateSwaps, isLoading: isFetchingEligibleTemplateSwaps} = useSwapTemplateActionMutation();
  const {mutateAsync: fetchEligibleAddServiceToUnits, isLoading: isFetchingEligibleAddServiceToUnits} = useAddServiceToUnitsMutation();
  const {mutateAsync: performBulkAction, isLoading: isPerformingBulkAction} = usePerformProjectActionMutation({entity, currentProjectId});

  // For 'cannotComplete' action only
  const [cannotCompleteReasonId, setCannotCompleteReasonId] = useState<number>();
  const [cannotCompleteReasonText, setCannotCompleteReasonText] = useState('');
  const disableCannotCompleteButton = !cannotCompleteReasonId || !cannotCompleteReasonText;
  const onReasonChange = useCallback((selectedOption: SelectOptions<number>) => {
    const value = selectedOption?.value as number;
    setCannotCompleteReasonId(value);
  }, []);
  const onReasonTextChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCannotCompleteReasonText(e.target?.value || '');
  }, []);

  const [swapTemplateId, setSwapTemplateId] = useState<number>();

  // Make final call to perform bulk action, i.e. approve/delete/reopen/mark cannot complete
  const callPerformBulkAction = useCallback(
    async ({projectActionId: _projectActionId}: {projectActionId?: number | null | undefined} = {}) => {
      const projectActionId = _projectActionId || (projectActionData?.id ?? null);

      let params = null;
      switch (action) {
        case 'cannotComplete': {
          params = {unserviceable_reason_id: cannotCompleteReasonId, note: cannotCompleteReasonText};
          break;
        }
        case 'swap': {
          params = {project_group_id: swapTemplateId};
          break;
        }
        default:
          break;
      }

      const response = await performBulkAction({projectActionId, params});
      setProjectActionData(response);
    },
    [action, cannotCompleteReasonId, cannotCompleteReasonText, performBulkAction, projectActionData?.id, swapTemplateId]
  );

  const closeSideSheet = useCallback(() => {
    dispatch(mduProjectsSlice.actions.removeActionItemBulkModalSlide());
    setCannotCompleteReasonId(undefined);
    setCannotCompleteReasonText('');
    setProjectActionData(undefined);
  }, [dispatch]);

  /** Format sku id and sku questions into format desired by BE */
  const formatBulkAddSkuToService = useCallback(() => {
    const pre_questions: TServiceBulkAddQAFormat[] = [];

    if (!selectedSku) return {id: 0, pre_questions};

    skuQuestionsForSelectedSku.forEach(question => {
      const base: TServiceBulkAddQAFormat = {id: question.id, input_type: question.inputType};
      const rawAnswers = selectedSku.questions[question.id];
      let formattedAnswers = {};
      switch (question.inputType) {
        case 'textarea':
        case 'input':
          formattedAnswers = {answer: rawAnswers?.text};
          break;
        case 'device':
          formattedAnswers = {
            answer: {
              product_make_id: rawAnswers?.make,
              product_id: rawAnswers?.model,
            },
          };
          break;
        case 'checkbox':
          formattedAnswers = {answers: rawAnswers?.map((answer: {id: number}) => ({preAnswerId: answer.id}))};
          break;
        case 'dropdown':
          formattedAnswers = {answers: [{preAnswerId: rawAnswers?.id}]};
          break;
        default:
          break;
      }
      pre_questions.push({...base, ...formattedAnswers});
    });

    return {
      id: selectedSku.skuId,
      pre_questions,
    };
  }, [selectedSku, skuQuestionsForSelectedSku]);

  // On open, determine how many entity items are eligible for the action
  useEffect(() => {
    (async () => {
      if (sideSheetIsOpen && !previousSideSheetIsOpen) {
        const selectAll = bulkActionsSelectAll;
        const entityIds = bulkActionEntityIds;
        let data = {} as TProjectActionResponse;

        if (entity === 'projects') {
          switch (true) {
            case action === 'approve': {
              data = await fetchEligibleApprovedProjects({selectAll, projectIds: entityIds, filters: projectAPIFilters});
              break;
            }
            case action === 'delete': {
              data = await fetchEligibleDeletableProjects({selectAll, projectIds: entityIds, filters: projectAPIFilters});
              break;
            }
            default:
              break;
          }
        } else if (entity === 'jobs') {
          switch (true) {
            case action === 'approve': {
              data = await fetchEligibleApprovedUnits({selectAll, unitIds: entityIds, projectId: currentProjectId, filters: jobsAPIFilters});
              break;
            }
            case action === 'delete': {
              data = await fetchEligibleDeletableUnits({selectAll, unitIds: entityIds, projectId: currentProjectId, filters: jobsAPIFilters});
              break;
            }
            case action === 'cannotComplete': {
              data = await fetchEligibleCannotCompleteUnits({selectAll, unitIds: entityIds, projectId: currentProjectId, filters: jobsAPIFilters});
              break;
            }
            case action === 'reopen': {
              data = await fetchEligibleReopenUnits({selectAll, unitIds: entityIds, projectId: currentProjectId, filters: jobsAPIFilters});
              break;
            }
            default:
              break;
          }
        } else if (entity === 'templates') {
          switch (action) {
            case 'swap': {
              data = await fetchEligibleTemplateSwaps({selectAll, unitIds: entityIds, projectId: currentProjectId, filters: jobsAPIFilters});
              break;
            }
            default:
              break;
          }
        } else if (entity === 'services') {
          switch (true) {
            case action === 'addService': {
              data = await fetchEligibleAddServiceToUnits({selectAll, unitIds: entityIds, projectId: currentProjectId, sku: formatBulkAddSkuToService(), filters: jobsAPIFilters});
              break;
            }
            default:
              break;
          }
        }

        const {total_count, applicable_count, id} = data;

        // Call bulk action if all items are applicable. 'cannotComplete' action needs extra data from the project manager.
        const isEveryItemApplicable = Number.isInteger(total_count) && total_count === applicable_count;
        const requiresAdditionalData = ['cannotComplete', 'swap'].includes(action);
        if (isEveryItemApplicable && !requiresAdditionalData) {
          callPerformBulkAction({projectActionId: id});
        }

        setProjectActionData(data);
      }
    })();
  }, [
    sideSheetIsOpen,
    previousSideSheetIsOpen,
    entity,
    action,
    fetchEligibleApprovedProjects,
    bulkActionsSelectAll,
    bulkActionEntityIds,
    fetchEligibleDeletableProjects,
    callPerformBulkAction,
    fetchEligibleApprovedUnits,
    currentProjectId,
    fetchEligibleDeletableUnits,
    fetchEligibleCannotCompleteUnits,
    fetchEligibleReopenUnits,
    fetchEligibleTemplateSwaps,
    fetchEligibleAddServiceToUnits,
    selectedSku,
    formatBulkAddSkuToService,
    projectAPIFilters,
  ]);

  const capEntity = capitalize(entity);
  // Action can be taken on x number of applicable items
  const applicableEntityCount = projectActionData?.applicable_count ?? 0;
  // Action was performed on x number of applicable items
  const executedCount = projectActionData?.executed_count ?? 0;
  // Action was successfully performed on x number of applicable items
  const successfulCount = projectActionData?.success_count ?? 0;
  // Action failed on x number of applicable items
  const errorCount = projectActionData?.error_count ?? 0;
  // x number of items included in this action. Includes "not_applicable" items
  const totalEntityCount = projectActionData?.total_count ?? 0;

  // Loading state for initial load of data
  const isLoading =
    isFetchingEligibleApprovedProjects ||
    isFetchingEligibleDeletableProjects ||
    isFetchingEligibleApprovedUnits ||
    isFetchingEligibleDeletableUnits ||
    isFetchingEligibleCannotCompleteUnits ||
    isFetchingEligibleReopenUnits ||
    isFetchingEligibleTemplateSwaps ||
    isFetchingEligibleAddServiceToUnits;
  // Items that cannot be performed on
  const nonApplicableEntityList = projectActionData?.items?.filter(i => i.status === 'not_applicable');
  // Items that were performed on but failed
  const failedEntityList = projectActionData?.items?.filter(i => i.status === 'error');
  // Action cannot be performed due to lack of applicable items
  const cannotPerformAction = !applicableEntityCount;
  const hasErrors = !!errorCount;
  // Action can be performed multiple times in this flow. Users can try again on failed items.
  const performingActionCount = hasErrors ? errorCount : applicableEntityCount;
  // Action successfully performed on all applicable items
  const bulkEditSuccessful = !!applicableEntityCount && !!successfulCount && applicableEntityCount === successfulCount;
  // Show cannot complete log
  const showCannotCompleteLog = action === 'cannotComplete' && !cannotPerformAction && !hasErrors;
  // Show Template Swap log
  const showTemplateSwapLog = action === 'swap' && !cannotPerformAction && !hasErrors;

  // Header Text
  const headerText = useMemo(() => {
    switch (true) {
      case action === 'approve':
        return `Approve ${capEntity}`;
      case action === 'delete':
        return `Delete ${capEntity}`;
      case action === 'cannotComplete':
        return 'Cannot Complete';
      case action === 'reopen':
        return `Reopen ${capEntity}`;
      case action === 'swap':
        return 'Swap Template';
      case action === 'addService':
        return `Add ${capEntity}`;
      default:
        return '';
    }
  }, [action, capEntity]);

  // Subtext Copy
  const subheaderText = useMemo(() => {
    switch (entity) {
      case 'jobs':
      case 'templates':
      case 'services':
        return currentProjectName;
      default:
        return '';
    }
  }, [entity, currentProjectName]);

  // ActionLoader Copy
  const actionLoaderSubtextCopy = useMemo(() => {
    if (bulkEditSuccessful) {
      let endCopy = '';
      switch (true) {
        case action === 'approve':
          endCopy = 'approved';
          break;
        case action === 'delete':
          endCopy = 'deleted';
          break;
        case action === 'cannotComplete':
          endCopy = 'marked cannot complete';
          break;
        case action === 'reopen':
          endCopy = 'reopened';
          break;
        case action === 'swap':
          return `Template swapped in ${applicableEntityCount} jobs!`;
        case action === 'addService':
          return `Service added to ${applicableEntityCount} jobs!`;
        default:
          break;
      }
      return `${applicableEntityCount} ${entity} ${endCopy}!`;
    }

    let startCopy = '';
    switch (true) {
      case action === 'approve':
        startCopy = 'Approving';
        break;
      case action === 'delete':
        startCopy = 'Deleting';
        break;
      case action === 'cannotComplete':
        return `Marking ${performingActionCount} ${entity} cannot complete...`;
      case action === 'reopen':
        startCopy = 'Reopening';
        break;
      case action === 'swap':
        return `Swapping template in ${performingActionCount} jobs...`;
      case action === 'addService':
        return `Adding Service to ${performingActionCount} jobs...`;
      default:
        break;
    }
    return `${startCopy} ${performingActionCount} ${entity}...`;
  }, [action, applicableEntityCount, bulkEditSuccessful, entity, performingActionCount]);

  // CalloutBox props
  const calloutBoxProps = useMemo(() => {
    const props: ComponentProps<typeof CalloutBox> = {
      text: '',
    };

    // Defaults
    if (cannotPerformAction) {
      props.text = `None of the selected ${entity} meet the required conditions`;
      props.theme = CalloutBoxThemes.CRITICAL;
    } else if (hasErrors) {
      props.text = "Your request for the remaining selections couldn't be processed.";
      props.theme = CalloutBoxThemes.WARNING;
    }
    const cannotPerformHeader = `No ${entity} will be`;
    const hasErrorsHeader = `${successfulCount} of ${executedCount} ${entity}`;
    const initialApplicableCountHeader = `${applicableEntityCount} of ${totalEntityCount} ${entity} will be`;
    const addServicesPreapprovalHeader = `Service will be added to ${applicableEntityCount} of ${totalEntityCount} jobs`;

    switch (true) {
      case action === 'approve':
        if (cannotPerformAction) {
          props.header = `${cannotPerformHeader} approved`;
        } else if (hasErrors) {
          props.header = `${hasErrorsHeader} approved`;
        } else {
          props.header = `${initialApplicableCountHeader} approved`;
          props.theme = CalloutBoxThemes.PRIMARY;
        }
        break;
      case action === 'delete':
        if (cannotPerformAction) {
          props.header = `${cannotPerformHeader} deleted`;
        } else if (hasErrors) {
          props.header = `${hasErrorsHeader} deleted`;
        } else {
          props.header = `${initialApplicableCountHeader} deleted`;
          props.theme = CalloutBoxThemes.CRITICAL;
        }
        break;
      case action === 'cannotComplete':
        if (cannotPerformAction) {
          props.header = `${cannotPerformHeader} marked cannot complete`;
        } else if (hasErrors) {
          props.header = `${hasErrorsHeader} marked cannot complete`;
        } else {
          props.header = `${initialApplicableCountHeader} marked cannot complete`;
          props.theme = CalloutBoxThemes.CRITICAL;
        }
        break;
      case action === 'reopen':
        if (cannotPerformAction) {
          props.header = `${cannotPerformHeader} reopened`;
        } else if (hasErrors) {
          props.header = `${hasErrorsHeader} reopened`;
        } else {
          props.header = `${initialApplicableCountHeader} reopened`;
          props.theme = CalloutBoxThemes.PRIMARY;
        }
        break;
      case action === 'swap':
        if (cannotPerformAction) {
          props.header = 'No jobs will have template swapped';
          props.text = 'None of the selected jobs meet the required conditions.';
        } else if (hasErrors) {
          props.header = `Templates swapped in ${successfulCount} of ${executedCount} jobs`;
          props.text = "Your request for the remaining selections couldn't be processed.";
        } else {
          props.header = `Template will be swapped in ${applicableEntityCount} of ${totalEntityCount} jobs`;
          props.text = "Templates can only be swapped in jobs that haven't been started.";
          props.theme = CalloutBoxThemes.PRIMARY;
        }
        break;
      case action === 'addService':
        if (cannotPerformAction) {
          props.header = 'Service will not be added to any job';
          props.theme = CalloutBoxThemes.CRITICAL;
        } else if (hasErrors) {
          props.header = addServicesPreapprovalHeader;
          props.text = "Your request for the remaining selections couldn't be processed.";
          props.theme = CalloutBoxThemes.WARNING;
        } else {
          props.header = addServicesPreapprovalHeader;
          props.theme = CalloutBoxThemes.PRIMARY;
        }
        break;
      default:
        break;
    }
    return props;
  }, [action, applicableEntityCount, cannotPerformAction, entity, executedCount, hasErrors, successfulCount, totalEntityCount]);

  // Button Props
  const buttonProps = useMemo(() => {
    const props: Pick<ComponentProps<typeof SideSheet>, 'ctaButtonText' | 'onCtaButtonClick' | 'ctaButtonTheme' | 'ctaButtonProps'> = {};

    // Defaults
    props.onCtaButtonClick = callPerformBulkAction;
    const applicableCount = `${applicableEntityCount} ${capEntity}`;

    switch (true) {
      case cannotPerformAction:
      case hasErrors:
      case bulkEditSuccessful:
        props.ctaButtonText = 'Close';
        props.onCtaButtonClick = closeSideSheet;
        break;
      case action === 'approve':
        props.ctaButtonText = `Approve ${applicableCount}`;
        break;
      case action === 'delete':
        props.ctaButtonText = `Delete ${applicableCount}`;
        props.ctaButtonTheme = BUTTON_THEMES.DANGER_SECONDARY;
        break;
      case action === 'cannotComplete':
        props.ctaButtonText = `Mark ${applicableCount} Cannot Complete`;
        props.ctaButtonTheme = BUTTON_THEMES.DANGER_SECONDARY;
        props.ctaButtonProps = {
          disabled: disableCannotCompleteButton,
        };
        break;
      case action === 'reopen':
        props.ctaButtonText = `Reopen ${applicableCount}`;
        break;
      case action === 'swap':
        props.ctaButtonText = `Swap Templates in ${applicableEntityCount} ${pluralize('Job', applicableEntityCount)}`;
        break;
      case action === 'addService':
        props.ctaButtonText = `Add Service to ${applicableEntityCount} Jobs`;
        break;
      default:
        break;
    }
    return props;
  }, [action, applicableEntityCount, bulkEditSuccessful, callPerformBulkAction, cannotPerformAction, capEntity, closeSideSheet, disableCannotCompleteButton, hasErrors]);

  return {
    actionLoaderSubtextCopy,
    bulkEditSuccessful,
    buttonProps,
    calloutBoxProps,
    cannotCompleteReasonId,
    cannotCompleteReasonText,
    closeSideSheet,
    entity,
    failedEntityList,
    headerText,
    isLoading,
    isPerformingBulkAction,
    nonApplicableEntityList,
    onReasonChange,
    onReasonTextChange,
    showCannotCompleteLog,
    showTemplateSwapLog,
    sideSheetIsOpen,
    subheaderText,
    swapTemplateId,
    setSwapTemplateId,
  };
};
