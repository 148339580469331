import React, {useCallback, useMemo, ComponentProps} from 'react';
import WidgetDashboard from 'components/Elements/WidgetDashboard';
import useGetDispatchUnassignedStats from 'features/Dispatch/queries/query.dispatch.getUnassignedStats';
import useUnassignedListRouteParams from 'features/Dispatch/hooks/useUnassignedListRouteParams';
import useSetUnassignedFilters from 'features/Dispatch/Pages/UnassignedList/hooks/useSetUnassignedFilters';
import {TUnassignedListInitialState, UnassignedFilterKeys, TPriorityOptions} from 'features/Dispatch/dispatch.types';
import {UNASSIGNED_FILTER_LABELS} from 'features/Dispatch/dispatch.constants';
import {TIER_OPTIONS} from 'features/Dispatch/Pages/UnassignedList/Parts/UnassignedTableToolbar/unassignedTableToolbar.constants';

const UnassignedStats = () => {
  const {rawFilters, updateFilters, updateRawFiltersState} = useSetUnassignedFilters();
  const {statsFilters} = useUnassignedListRouteParams();
  const {data} = useGetDispatchUnassignedStats({filters: statsFilters});

  const onWidgetClick = useCallback(
    ({filter, rawFilter}: {filter: TUnassignedListInitialState['filters']; rawFilter: TUnassignedListInitialState['rawFilters']}) => {
      updateFilters({filters: filter});
      updateRawFiltersState({newRawFiltersState: {...rawFilters, ...rawFilter}});
    },
    [rawFilters, updateFilters, updateRawFiltersState]
  );

  const widgetProps = useMemo(() => {
    const props: Array<ComponentProps<typeof WidgetDashboard.Widget>> = [
      {
        label: 'Total unassigned jobs',
        value: data?.total ?? '--',
        icon: 'briefcase',
        colorVariant: 'blue',
        prominent: true,
        gridColumn: '1/3',
        gridRow: '1/3',
      },
      {
        label: 'At Risk',
        value: data?.priority_high ?? '--',
        icon: 'level-up',
        colorVariant: 'red',
        gridColumn: '3/5',
        gridRow: '1/2',
        actionOnClick: () =>
          onWidgetClick({
            filter: {[UnassignedFilterKeys.Priority]: TPriorityOptions.High},
            rawFilter: {[UnassignedFilterKeys.Priority]: {label: UNASSIGNED_FILTER_LABELS.AtRisk, name: UnassignedFilterKeys.Priority, values: [{value: TPriorityOptions.High, label: ''}]}},
          }),
      },
      {
        label: 'Needs Attention',
        value: data?.priority_medium ?? '--',
        icon: 'equals',
        colorVariant: 'yellow',
        gridColumn: '3/5',
        gridRow: '2/3',
        actionOnClick: () =>
          onWidgetClick({
            filter: {[UnassignedFilterKeys.Priority]: TPriorityOptions.Medium},
            rawFilter: {[UnassignedFilterKeys.Priority]: {label: UNASSIGNED_FILTER_LABELS.NeedsAttention, name: UnassignedFilterKeys.Priority, values: [{value: TPriorityOptions.Medium, label: ''}]}},
          }),
      },
      {
        label: 'Jobs on Hold',
        value: data?.on_hold ?? '--',
        icon: 'pause',
        colorVariant: 'default',
        gridColumn: '5/7',
        gridRow: '1/2',
        actionOnClick: () =>
          onWidgetClick({
            filter: {[UnassignedFilterKeys.OnHold]: 'true'},
            rawFilter: {[UnassignedFilterKeys.OnHold]: {label: UNASSIGNED_FILTER_LABELS.OnHold, name: UnassignedFilterKeys.OnHold, values: [{value: 'true', label: 'Yes'}]}},
          }),
      },
      {
        label: 'Late Tech Cancels',
        value: data?.late_release ?? '--',
        icon: 'no-show',
        colorVariant: 'deep-purple',
        gridColumn: '5/7',
        gridRow: '2/3',
        actionOnClick: () =>
          onWidgetClick({
            filter: {[UnassignedFilterKeys.LateRelease]: 'true'},
            rawFilter: {[UnassignedFilterKeys.LateRelease]: {label: UNASSIGNED_FILTER_LABELS.LateTechRelease, name: UnassignedFilterKeys.LateRelease, values: [{value: 'true', label: 'Yes'}]}},
          }),
      },
      {
        label: 'Tier 1',
        value: data?.tier1 ?? '--',
        noImage: true,
        gridColumn: '7/8',
        gridRow: '1/2',
        actionOnClick: () =>
          onWidgetClick({
            filter: {[UnassignedFilterKeys.Tier]: [TIER_OPTIONS[0].value]},
            rawFilter: {[UnassignedFilterKeys.Tier]: {label: UNASSIGNED_FILTER_LABELS.Tier, name: UnassignedFilterKeys.Tier, values: [{value: TIER_OPTIONS[0].value, label: TIER_OPTIONS[0].label}]}},
          }),
      },
      {
        label: 'Tier 2',
        value: data?.tier2 ?? '--',
        noImage: true,
        gridColumn: '8/9',
        gridRow: '1/2',
        actionOnClick: () =>
          onWidgetClick({
            filter: {[UnassignedFilterKeys.Tier]: [TIER_OPTIONS[1].value]},
            rawFilter: {[UnassignedFilterKeys.Tier]: {label: UNASSIGNED_FILTER_LABELS.Tier, name: UnassignedFilterKeys.Tier, values: [{value: TIER_OPTIONS[1].value, label: TIER_OPTIONS[1].label}]}},
          }),
      },
      {
        label: 'Tier 3',
        value: data?.tier3 ?? '--',
        noImage: true,
        gridColumn: '7/8',
        gridRow: '2/3',
        actionOnClick: () =>
          onWidgetClick({
            filter: {[UnassignedFilterKeys.Tier]: [TIER_OPTIONS[2].value]},
            rawFilter: {[UnassignedFilterKeys.Tier]: {label: UNASSIGNED_FILTER_LABELS.Tier, name: UnassignedFilterKeys.Tier, values: [{value: TIER_OPTIONS[2].value, label: TIER_OPTIONS[2].label}]}},
          }),
      },
      {
        label: 'Tier 4',
        value: data?.tier4 ?? '--',
        noImage: true,
        gridColumn: '8/9',
        gridRow: '2/3',
        actionOnClick: () =>
          onWidgetClick({
            filter: {[UnassignedFilterKeys.Tier]: [TIER_OPTIONS[3].value]},
            rawFilter: {[UnassignedFilterKeys.Tier]: {label: UNASSIGNED_FILTER_LABELS.Tier, name: UnassignedFilterKeys.Tier, values: [{value: TIER_OPTIONS[3].value, label: TIER_OPTIONS[3].label}]}},
          }),
      },
    ];

    return props;
  }, [data, onWidgetClick]);

  return (
    <WidgetDashboard numCols={8} numRows={2}>
      {widgetProps.map(widget => (
        <WidgetDashboard.Widget key={widget.label} {...widget} />
      ))}
    </WidgetDashboard>
  );
};

export default UnassignedStats;
