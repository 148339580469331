import {useQuery} from 'react-query';
import useApi from 'hooks/useApi';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {showErrorToast, logResponseError} from 'queries/query.utils';
import {TDevices} from './types/devices.types';
import {SKUS_QUERY_KEYS} from './skus.query.keys';

type TUseGetDevicesQueryResponse = WithErrorResponse<{makes: TDevices}>;

type TUseGetDevicesQueryParams = {
  productCategoryId: number | null | undefined;
  useLoader?: boolean;
  queryOpts?: Parameters<typeof useQuery<TUseGetDevicesQueryResponse['data']>>['2'];
};

/**
 * Fetch a list of devices makes and their models by id
 */
const useGetDevicesQuery = ({productCategoryId, useLoader = false, queryOpts}: TUseGetDevicesQueryParams) => {
  const api = useApi();
  const dispatch = useAppDispatch();

  return useQuery<TUseGetDevicesQueryResponse['data']>(
    SKUS_QUERY_KEYS.getDevices(productCategoryId),
    async () => {
      if (!productCategoryId) {
        return {makes: []};
      }
      if (useLoader) {
        api.toggleLoader(true);
      }
      const response: TUseGetDevicesQueryResponse = await api.skus.getDevices({product_category_id: productCategoryId});
      if (useLoader) {
        api.toggleLoader(false);
      }
      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error retrieving devices'});
        logResponseError('Devices')(response.err);
        throw new Error('Error retrieving devices');
      }
      return response?.data;
    },
    queryOpts
  );
};

export default useGetDevicesQuery;
