import React, {useCallback, useEffect, useState} from 'react';
import {ELEMENT_SIZE, SelectField} from 'ht-styleguide';

// hooks & constants
import {useCurrentProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/CurrentProject.hooks';
import {FIELD_LABELS} from 'features/MultiDwellingUnits/Parts/FormFields/FormFields.constants';
import {useAppDispatch} from 'hooks/useAppDispatch';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';

// Types
import {MduFormElementProps, SelectOption} from 'features/MultiDwellingUnits/Parts/FormFields/FormFields.types';
import {ProjectGroup} from 'features/MultiDwellingUnits/MDU.types';

// styles
import styles from './FormFields.styles.scss';

const GroupTemplatesSelect = ({formik, label = FIELD_LABELS.groupTemplates, fieldName = 'project_group_ids', dataTestId = FIELD_LABELS.groupTemplates}: MduFormElementProps) => {
  /* Hooks */
  const project = useCurrentProject();
  const [selectedDetails, setSelectedDetails] = useState<SelectOption | string>('');
  const dispatch = useAppDispatch();

  /* generate group templates list */
  const groupTemplateList =
    project?.projectGroups?.map((group: ProjectGroup) => {
      return {
        value: group.id,
        label: group.name,
      };
    }) || [];

  /* Restore state from formikValue */
  const formikValue = formik.values[fieldName];
  useEffect(() => {
    /* Seed project groups */
    if (formikValue) {
      const findMatchingStatus = groupTemplateList?.reduce((all: SelectOption[], status: SelectOption) => {
        if (formikValue.includes(status.value)) {
          return all.concat({
            value: status.value,
            label: status.label,
          });
        }

        return all;
      }, []);

      if (findMatchingStatus && findMatchingStatus.length) {
        setSelectedDetails(findMatchingStatus);
      }
    } else {
      // clear it
      setSelectedDetails('');
    }
  }, [formikValue]);

  // helper since use it 2x+
  const setFormikValue = useCallback(
    (name: string, value: number | string) => {
      formik.handleChange({
        target: {name, value},
      });
    },
    [formik]
  );

  /* tg: templateGroup */
  const handleDetailsChange = (tg: any) => {
    if (!tg) {
      return setFormikValue(fieldName, '');
    }
    setSelectedDetails(tg);

    const getValues = tg.reduce((all: string[], current: SelectOption) => {
      return [...all, current.value];
    }, []);

    return setFormikValue(fieldName, getValues);
  };

  const getDisplay = () => {
    return (
      <SelectField
        id={fieldName}
        placeholder="Select Job Template"
        options={[...groupTemplateList]}
        filterOption={() => true}
        label={label}
        value={selectedDetails}
        onChange={po => {
          handleDetailsChange(po);
          dispatch(mduProjectsSlice.actions.setRawFilterValues({[fieldName]: po}));
        }}
        elementSize={ELEMENT_SIZE.MEDIUM}
        reactSelectClassName={styles.placeholder}
        dataTestId={dataTestId}
        error={formik.errors.project_group_ids}
        multiple
      />
    );
  };

  return getDisplay();
};

export default GroupTemplatesSelect;
