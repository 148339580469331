import React, {ErrorInfo} from 'react';
import truncate from 'lodash/truncate';
import {Icon} from 'ht-styleguide';
import styles from './error.styles.scss';

type ErrorPageProps = {
  error?: Error;
  info?: ErrorInfo;
};

const TRUNCATE_LENGTH = 500;

const ErrorPage: React.FC<ErrorPageProps> = ({error, info}) => {
  return (
    <div className={styles.error_page}>
      <div className={styles.error_container}>
        <h1>
          <Icon name="ht-toolkit" /> Oops!
        </h1>
        <h3>Something went wrong.</h3>
        <div className={styles.error_container_display}>
          <div className={styles.error_title}>Error</div>
          <div className={styles.error_message}>{truncate(error?.toString(), {length: TRUNCATE_LENGTH})}</div>
          <div className={styles.error_title}>Stacktrace:</div>
          <div className={styles.error_message}>{truncate(info?.componentStack || '', {length: TRUNCATE_LENGTH})}</div>
        </div>
        <p>
          This issue/error has been logged. But please follow up with engineering,
          <br />
          by copying error in the "details" and forwarding to engineering. Thanks!
        </p>
        <p>
          <a href="/booking">Try another booking</a>
        </p>
      </div>
    </div>
  );
};

export default ErrorPage;
