import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useQuery} from 'react-query';

import useApi from 'hooks/useApi';
import APIS from 'global/apis';
import {logger} from 'utils/logger';

import {showErrorToast} from 'queries/query.utils';
import {MDU_PROJECT_QUERY_KEYS} from '../MDU.query.keys';
import {MduUseParamsTypes} from '../MDU.types';

/** QUERY: Get Notes By Project id */
export const useGetProjectNotesQuery = () => {
  const {projectId = ''} = useParams<MduUseParamsTypes>();
  const dispatch = useDispatch();
  const api = useApi();

  return useQuery(
    MDU_PROJECT_QUERY_KEYS.getNotesByProjectId(projectId),
    async () => {
      api.toggleLoader(true);
      const response = await APIS.mdu.getNotesByProjectId({id: projectId});
      api.toggleLoader(false);
      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error Retrieving Notes'});
        throw new Error(response.err);
      }
      return response?.data?.notes;
    },
    {
      onError: response => {
        logger('Projects Notes: ')(response as Error);
      },
    }
  );
};
