import methods from '../buildMethods';

/**
 * API for orders and their related endpoints
 */
const apiOrders = (m: ReturnType<typeof methods>) => ({
  orders: {
    getDetails: m.get('/api/admin/orders/:id'),
  },
  services: {
    /** Task checklist related */
    getTaskChecklist: m.get('/api/admin/orders/:id/services/:serviceId/tasks'),
    markTaskComplete: m.post('/admin/services/:serviceId/checklist_tasks/mark_complete'),
    markTaskIncomplete: m.post('/admin/services/:serviceId/checklist_tasks/mark_incomplete'),
    updateTaskAnswer: m.put('/api/admin/orders/:id/services/:serviceId/tasks/:taskId'),
    shareChecklist: m.post('/api/admin/orders/:id/share_checklist'),
    /** -- */
  },
});

export default apiOrders;
