import {useEffect} from 'react';
import {useQuery} from 'react-query';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {useSelector} from 'hooks/useAppSelector';
import usePrevious from 'hooks/usePrevious';

/*
 * Query: useInfiniteQuery
 * ------------------------
 * This fetches, pagination, the response for the filtered results.
 *
 * */
import useApi from 'hooks/useApi';

import {IUseFilteredProjects} from '../Pages/Projects/Projects.types';
import {useProjectsRouteParams} from '../Pages/Projects/hooks';
import {useSetFilters} from '../Pages/Projects/Parts/MDUProjectsFilters/MDUProjectsFilters.hooks';
import {SEARCH_PAGINATION_COUNT} from '../Pages/Projects/Projects.constants';
import {MduGetAllProjectsResponse} from '../MDU.types';
import mduProjectsSlice, {normalizeFiltersToParams, PAGINATION_INITIAL_STATE} from '../MDU.ducks';
import {MDU_PROJECT_QUERY_KEYS} from '../MDU.query.keys';
import {debouncedPaginatedSearch} from './query.utils';

export const useFilteredProjects = ({pageFilters, options}: IUseFilteredProjects) => {
  /* Hooks */
  const api = useApi();
  const dispatch = useAppDispatch();
  const {projectsType, projectsPage} = useProjectsRouteParams();
  const {filters} = useSetFilters(projectsPage);
  const isSearchType = 'search' in pageFilters;

  /* Selectors */
  const itemsPerPage = useSelector(mduProjectsSlice.selectors.getPaginationItemsPerPage);
  const currentPage = useSelector(mduProjectsSlice.selectors.getPaginationPage);

  /**
   * Reset itemsPerPage value when there's a change in projectsType/projectsPage
   * Due to race conditions, it was difficult to reset itemPerPage value outside of this hook.
   */
  const previousProjectsType = usePrevious(projectsType);
  const previousProjectsPage = usePrevious(projectsPage);
  const shouldResetItemsPerPage = previousProjectsType !== projectsType || previousProjectsPage !== projectsPage;
  const finalItemsPerPage = shouldResetItemsPerPage ? PAGINATION_INITIAL_STATE.items_per_page : itemsPerPage;

  /* Search will override filters */
  const paginationQueryKey = isSearchType ? [pageFilters] : [projectsType, projectsPage, filters, currentPage, finalItemsPerPage];
  const pagiPerPage = isSearchType ? SEARCH_PAGINATION_COUNT : finalItemsPerPage;

  /* Methods */
  const filterSearchObj = () => {
    const pagi = {pagination: {per_page: pagiPerPage, page: currentPage + 1}};
    const params = isSearchType ? {...pageFilters, ...pagi} : {...pageFilters, ...filters, ...pagi};

    return normalizeFiltersToParams(params);
  };

  const {data, isFetched, isPreviousData} = useQuery(
    MDU_PROJECT_QUERY_KEYS.pagination(paginationQueryKey),
    () =>
      (async (): Promise<MduGetAllProjectsResponse> => {
        const normalizedFilters = filterSearchObj();
        api.toggleLoader(true);
        const response = await debouncedPaginatedSearch(normalizedFilters);
        api.toggleLoader(false);
        if (response.err) throw new Error(response.err);
        return response;
      })(),
    {
      ...options,
    }
  );

  const {pagination, projects} = data?.data ?? {};

  useEffect(() => {
    if (pagination) {
      dispatch(
        mduProjectsSlice.actions.updatePaginationAttributes({
          total_pages: pagination.total_pages,
          total_items: pagination.total_projects,
          items_on_current_page: pagination.projects_on_current_page,
          items_per_page: pagination.projects_per_page,
        })
      );
    }
  }, [pagination]);

  return {
    isFetched,
    isPreviousData,
    results: projects ?? [],
    pagination,
  };
};
