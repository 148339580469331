import {useCallback, useEffect, useState, useRef} from 'react';
import debounce from 'lodash/debounce';
/*
  TODO -> Should we bring in the entire lodash library? Or individual modules? I saw some modules were imported and not
  the entire library. The linter complains about lodash not being in the project dependencies
*/
import {Cancelable, DebounceSettings} from 'lodash';

export function useDebouncedCB<T extends (...args: any[]) => any>(callback: T, delay: number = 0, options?: DebounceSettings): T & Cancelable {
  return useCallback(debounce(callback, delay, options), [callback, delay, options]);
}

function useDebounce<T>(value: T, delay: number = 0, options?: DebounceSettings): T {
  const prevValue = useRef(value);
  const [current, setCurrent] = useState(value);
  const debouncedCallback = useDebouncedCB((val: T) => setCurrent(val), delay, options);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // does trigger the debounce timer initially
    if (value !== prevValue.current) {
      debouncedCallback(value);
      prevValue.current = value;
      // cancel the debounced callback on clean up
      return debouncedCallback.cancel;
    }
  }, [value]);

  return current;
}

export default useDebounce;
