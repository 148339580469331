import useIssuesRouteParams from 'features/Issues/hooks/useIssuesRouteParams';
import issuesSlice from 'features/Issues/Issues.ducks';
import {TUpdateIssuesRawFiltersPayload} from 'features/Issues/issues.types';
import {useSelector} from 'hooks/useAppSelector';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {TUtilTableFiltersState, TUtilTableRawFiltersState} from 'utils/table/table.types';

/**
 * Returns convenient filter data and helpers based on the current issuesNavType.
 */
const useSetIssuesFilters = () => {
  const dispatch = useAppDispatch();
  const {issuesNavType} = useIssuesRouteParams();

  const {filters} = useSelector(issuesSlice.selectors.getPagesStateByKey(issuesNavType)) || {};

  const updateFilters = ({filters: newFilters, replace = false}: {filters: TUtilTableFiltersState; replace?: boolean}) => {
    dispatch(issuesSlice.actions.updateIssuesFilters({issuesNavType, filters: newFilters, replace}));
  };

  const updateRawFilters = ({name, label, values}: Omit<TUpdateIssuesRawFiltersPayload, 'issuesNavType'>) => {
    dispatch(issuesSlice.actions.updateIssuesRawFilters({issuesNavType, name, label, values}));
  };

  const removeRawFilters = ({name}: {name: any}) => {
    dispatch(issuesSlice.actions.removeIssuesRawFilters({issuesNavType, name}));
  };

  /**
   * To completely override the rawFilters state. Needed within the filters modal.
   */
  const updateRawFiltersState = ({newRawFiltersState}: {newRawFiltersState: TUtilTableRawFiltersState}) => {
    dispatch(issuesSlice.actions.updateRawFiltersState({issuesNavType, newRawFiltersState}));
  };

  return {
    // the currently applied filters for the page
    filters,
    // to update filters in correct space in redux
    updateFilters,
    // to remove a rawFilter based on name
    removeRawFilters,
    // to update raw filters in redux
    updateRawFilters,
    // to update raw filters state
    updateRawFiltersState,
  };
};

export default useSetIssuesFilters;
