import {useQuery, UseQueryResult} from 'react-query';
import {useDispatch} from 'react-redux';

import useApi from 'hooks/useApi';
import APIS from 'global/apis';
import {logger} from 'utils/logger';

import {notifications} from 'components/Notification/notification.ducks';

import {SkuQuestionQuery} from '../MDU.types';
import mduProjectsSlice from '../MDU.ducks';
import {MDU_PROJECT_QUERY_KEYS} from '../MDU.query.keys';

type FetchSkuAndQuestionsProps = {
  skuId: string;
  isEditMode: boolean;
  groupId: string;
  serviceId: string;
};
export const useFetchSkuAndQuestions = ({skuId: id, isEditMode, groupId, serviceId}: FetchSkuAndQuestionsProps): UseQueryResult<SkuQuestionQuery> => {
  /* Hooks */
  const api = useApi();
  const dispatch = useDispatch();

  const fetchSkuAndQuestions = async () => {
    const [skuResponse, questionResponse] = await Promise.all([APIS.booking.skus.find({id}), APIS.booking.skus.questions({id})]);

    if (skuResponse.err) {
      const submissionErrors = skuResponse.err?.errors ? Object.values(skuResponse.err.errors).join(', ') : String(skuResponse.err);
      throw new Error(`Error fetching sku ${String(submissionErrors)}`);
    }
    if (questionResponse.err) throw new Error(`Error fetching sku questions ${String(questionResponse.err)}`);

    /* Set into redux: get this into react-query cache */
    dispatch(
      mduProjectsSlice.actions.updateSkusAndQuestions({
        sku: skuResponse.data.sku,
        questions: questionResponse.data.questions,
      })
    );

    dispatch(mduProjectsSlice.actions.setSelectedSku({skuId: id, isEditMode, groupId, serviceId}));

    return {sku: skuResponse.data.sku, questions: questionResponse.data.questions};
  };

  return useQuery(
    MDU_PROJECT_QUERY_KEYS.addSku(id),
    async () => {
      api.toggleLoader(true);
      return fetchSkuAndQuestions();
    },
    {
      enabled: Boolean(id),
      onError: response => {
        dispatch(notifications.actions.notificationApiError({source: 'Error Retrieving Sku & Questions'}));
        logger('Add Sku Retrieval: ')(response as Error);
      },
      onSettled: () => {
        api.toggleLoader(false);
      },
    }
  );
};
