import {Display, ISSUE_FIELDS_OPERATION_TYPE, operationMapBy, ReadOnly} from 'features/Issues/Issues.constants';
import {TField, TFieldForTable} from 'features/Issues/issues.types';

/**
 * @description Determine if a field should be disabled based on the operation
 *
 * Note: This is not how I would have set up the field. I'd have the operation key exist on the field with its properties.
 *       Otherwise, root level keys are maintenance nightmare.
 *
 * @param operation
 * @param field
 */

export const determineFieldDisableStatusByOperation = ({
  operationType,
  field,
  key,
}: {
  operationType: keyof typeof ISSUE_FIELDS_OPERATION_TYPE | undefined;
  field: TField | TFieldForTable;
  key: typeof ReadOnly | typeof Display;
}) => {
  if (!operationType || operationType === 'filter') return false;

  const keyValue = field[key];

  if (key === ReadOnly) return operationMapBy[operationType][ReadOnly].includes(keyValue!);
  if (key === Display) return operationMapBy[operationType][Display].includes(keyValue!);

  return false;
};
