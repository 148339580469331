import React, {useEffect} from 'react';

import useCloudinary from 'features/Cloudinary/hooks/useCloudinary';
import {ThumbnailDisplay} from 'components/Elements/ThumbnailDisplay';
import {RESOURCE_TYPE_IMAGE} from 'utils/files/constants';
import {sortByMostRecentCloudinaryResponse} from 'features/Cloudinary/cloudinary.utils';

import {TGenericCloudinaryResponse} from 'features/Cloudinary/cloudinary.types';
import {TCommentProp} from './commentFeed.types';

import styles from './commentFeed.styles.scss';

export const CommentThumbnail = ({comment, withLightbox}: TCommentProp) => {
  /* Normalize Data for Display */
  /* Hooks */
  const {handleTransformImage, uploadedSuccessFiles, totalSuccessFilesCount} = useCloudinary({namespace: `comment-${comment.id}`, height: 112, width: 160});

  /* Constants */
  const files = (uploadedSuccessFiles ?? []).sort(sortByMostRecentCloudinaryResponse).map((file: TGenericCloudinaryResponse) => {
    return {
      name: file.original_filename,
      file_type: file.format?.toUpperCase(),
      showDownloadButton: true,
      showDeleteButton: false,
      suppressNameplate: true,
      transformedFile: file.resource_type === RESOURCE_TYPE_IMAGE && file.format !== 'pdf' ? file.transformedImage : '',
      onDownloadClick: () => {
        window.open(file.url, '_blank');
      },
      url: file.url,
    };
  });

  useEffect(() => {
    if (comment.attachments.length > 0) {
      handleTransformImage(comment.attachments);
    }
  }, [comment.id]);

  return (
    <div className="flex justify-content-right paddingY-small1">
      <ThumbnailDisplay withLightbox={withLightbox} className={styles.commentResourceContainer} parentPadding={16} files={files} totalSuccessFilesCount={totalSuccessFilesCount} />
    </div>
  );
};
