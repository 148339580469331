import React, {useState, useRef} from 'react';
import cn from 'classnames';
import {Panel, Icon} from 'ht-styleguide';
import useOutsideClick from 'hooks/useOutsideClick';
import {noop} from 'utils/event';
import {TTableActionMenuProps, TTableActionMenuLineItem} from '../dataTable.types';
import {extractDensityState} from '../utils/dataTable.utils';
import styles from './tableActionMenu.styles.scss';

const TableActionMenu = <TData extends Record<string, any> = {}>({items = [], isDisabled, editMenuContainerStyles, table}: TTableActionMenuProps<TData>) => {
  // Menu state
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => {
    setShowMenu(show => !show);
  };
  const hasMenuItems = Boolean(items.length);
  const editMenuRef: React.RefObject<HTMLDivElement> = useRef(null);

  // Event Handlers
  const handleLineItemClick = (item: TTableActionMenuLineItem) => (e: React.MouseEvent) => {
    if (e) e.stopPropagation();
    if (item.onClick) item.onClick();
    toggleMenu();
  };
  useOutsideClick(
    editMenuRef,
    () => {
      if (showMenu) setShowMenu(false);
    },
    [showMenu]
  );

  const densityState = extractDensityState({table});
  const isCompact = densityState === 'compact';

  const editIconStyles = cn(styles.editIcon, {[styles.disabled]: isDisabled, [styles.compact]: isCompact});
  const editMenuStyles = cn(styles.editMenu, {[styles.compact]: isCompact});

  return (
    <div role="menubar" tabIndex={-1} onClick={isDisabled ? noop : toggleMenu} data-testid="TableActionMenu">
      <div className={cn(styles.editMenuContainer, editMenuContainerStyles)}>
        <div className="flex align-items-center justify-content-right" ref={editMenuRef}>
          <button type="button" className="plainButton" aria-label="more">
            <Icon name="more" className={editIconStyles} />
          </button>
        </div>

        {showMenu && hasMenuItems && (
          <Panel largeBorderRadius className={editMenuStyles}>
            <ul>
              {items.map(item => (
                <li key={item.key} role="presentation" className="p2" onClick={handleLineItemClick(item)}>
                  {item.text}
                </li>
              ))}
            </ul>
          </Panel>
        )}
      </div>
    </div>
  );
};

export default TableActionMenu;
