import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import {noop} from 'utils/event';
import {Icon, Panel} from 'ht-styleguide';
import {UPLOAD_ERROR_TYPES, ERROR_HEADERS} from './constants';
import {UploadErrorStatusType, FileUploadErrorsProps} from './FileUploadErrors.types';
import styles from './fileUploadErrors.styles.scss';
import getValuesByKey from '../../../../utils/object/getValuesByKey';

/**
 * BE API will return a 413 status code and a statusText of 'Request Entity Too Large' when a file is too large.
 * Found an issue in which this is not the case. Looks like we can hook into the key "file_file_size"
 * */
export const isFileTooLargeError = (errorStatus: UploadErrorStatusType) => {
  const errorStatus403 = errorStatus.status === 413;
  const hasKeyToDetermineInvalid = getValuesByKey(errorStatus, ['file_file_size']).length > 0;

  return errorStatus403 || hasKeyToDetermineInvalid;
};

/**
 * BE API will return a 400 status code and a statusText of 'Bad Request' when a file is an invalid type.
 * The specific errors are nested inside of the response.data.errors object.
 */
export const isInvalidFileTypeError = (errorStatus: UploadErrorStatusType) => {
  const {fileAttachmentErrors} = errorStatus;
  if (!fileAttachmentErrors || !fileAttachmentErrors?.length) return false;
  const invalidFileTypeError = fileAttachmentErrors.find(error => Object.values(error).some(value => value.includes('is invalid')));
  return Boolean(invalidFileTypeError);
};

const FileUploadErrorState = ({errorText = '', onClose = noop, fileNames = [] as string[], className = ''}) => {
  const hasFileNames = fileNames.length > 0;
  return (
    <Panel className={cn(styles.panel, 'padding-small', className)} largeBorderRadius noShadow>
      <div className="flex justify-content-space-between align-items-flex-start">
        <div className="flex">
          <Icon name="alert-triangle-fill" className={styles.darkRedText} />
          <div className="marginLeft-tiny1">
            <p className={cn('h6', styles.darkRedText, {'marginBottom-small': hasFileNames})}>{errorText}</p>
            {hasFileNames && (
              <ul>
                {fileNames.map(fileName => (
                  <li key={fileName} className={cn('caption marginBottom-tiny', styles.lightRedText)}>
                    {fileName}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <button className="plainButton" type="button" onClick={onClose}>
          <Icon name="v2-close-icon" className={styles.lightRedText} />
        </button>
      </div>
    </Panel>
  );
};

/** Ingest file upload errors and return an error state for each error type */
const FileUploadErrors = ({errorStatusInfo, onClose, className}: FileUploadErrorsProps) => {
  const {FILE_TOO_LARGE, FILE_TYPE_NOT_ALLOWED} = UPLOAD_ERROR_TYPES;
  const [fileUploadErrors, setFileUploadErrors] = useState<{[key: string]: string[]}>({
    FILE_TOO_LARGE: [] as string[], // array of each file name that is too large
    FILE_TYPE_NOT_ALLOWED: [] as string[], // array of each file name that is an invalid type
  });
  const showTooLargeError = fileUploadErrors.FILE_TOO_LARGE.length > 0;
  const showInvalidFileTypeError = fileUploadErrors.FILE_TYPE_NOT_ALLOWED.length > 0;

  useEffect(() => {
    if (!errorStatusInfo) setFileUploadErrors({FILE_TOO_LARGE: [], FILE_TYPE_NOT_ALLOWED: []});

    if (errorStatusInfo) {
      const filesTooLarge = errorStatusInfo.filter(isFileTooLargeError);
      const filesTypeNotAllowed = errorStatusInfo.filter(isInvalidFileTypeError);
      const newFilesTooLarge = filesTooLarge.map(file => file.fileName || '');
      const newFilesTypeNotAllowed = filesTypeNotAllowed.map(file => file.fileName || '');
      setFileUploadErrors({FILE_TOO_LARGE: newFilesTooLarge, FILE_TYPE_NOT_ALLOWED: newFilesTypeNotAllowed});
    }
  }, [FILE_TOO_LARGE, FILE_TYPE_NOT_ALLOWED, errorStatusInfo]);

  const tooLargeStyles = cn({'marginBottom-small1': showInvalidFileTypeError});

  return (
    <div className={className}>
      {showTooLargeError ? <FileUploadErrorState errorText={ERROR_HEADERS.FILE_TOO_LARGE} className={tooLargeStyles} onClose={onClose} fileNames={fileUploadErrors[FILE_TOO_LARGE]} /> : null}
      {showInvalidFileTypeError ? <FileUploadErrorState errorText={ERROR_HEADERS.FILE_TYPE_NOT_ALLOWED} onClose={onClose} fileNames={fileUploadErrors[FILE_TYPE_NOT_ALLOWED]} /> : null}
    </div>
  );
};

export default FileUploadErrors;
