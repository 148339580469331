import React, {useCallback, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import cn from 'classnames';
import {Icon, Panel, Tooltip, Toggle} from 'ht-styleguide';
import {skuEditDetailsPath} from 'global/paths';
import PageHeader from 'components/Elements/PageHeader';
import PageHeaderMetaGrid from 'components/Elements/PageHeaderMetaGrid';
import useTaskChecklistPage, {useTaskChecklistModals} from './hooks/useTaskChecklistPage.hooks';
import {ADD_EDIT_GROUP_ACTION_TYPES, AddEditGroupModal} from './Modals/TaskCheclist.AddEditGroup.Modal';
import {DeleteItemModal} from './Modals/TaskChecklist.DeleteItem.Modal';
import TaskSideSheet from './TaskSideSheet';
import TaskChecklistGroup from './TaskChecklistGroup';
import {useMonitorDragAndDropElements} from './TaskChecklistGroup/taskChecklistGroup.hooks';
import {TASK_CHECKLIST_ITEM_TYPES} from './TaskChecklist.constants';
import styles from './taskChecklist.styles.scss';

/**
 * Manage a task checklist for a sku.
 *
 * Notes:
 * - Create/modifying the checklist -> This is done by upserting the entire checklist via one endpoint. The
 *   BE will determine whether to modify/create by checking if the task group/task/etc has an id. Deleting a task group/task/etc is done
 *   by omitting the content from the upserted checklist.
 */
const TaskChecklist = () => {
  const {id: skuId = ''} = useParams<{id: string}>();
  const skuIdInt = +skuId;

  const {isDeleteItemModalVisible, isAddEditGroupModalVisible, openAddEditGroupModal, openDeleteItemModal, closeModals} = useTaskChecklistModals();

  const {
    skuData,
    taskChecklistData,
    isErrorGetTaskGroup,
    sideSheetIsVisible,
    openAddTaskSideSheet,
    closeTaskSideSheet,
    openEditTaskSideSheet,
    taskToEdit,
    upsertTask,
    toggleGrouping,
    addGroup,
    reorderGroup,
    reorderTask,
  } = useTaskChecklistPage({
    skuId: skuIdInt,
  });

  const {name: skuName, category, active} = skuData?.sku || {};

  // For Page header meta data items
  const metaDataItems = useMemo(
    () => [
      {
        label: 'Sku Id',
        value: (
          <a href={skuEditDetailsPath(skuId)} target="_blank" rel="noreferrer" className="text-underline n800">
            {skuId}
          </a>
        ),
      },
      {
        label: 'Category',
        value: category,
      },
      {
        label: 'Status',
        value: active ? <span className="successGreen500">Active</span> : 'Inactive',
      },
    ],
    [active, category, skuId]
  );

  // Other UI
  const showPanel = !isErrorGetTaskGroup;
  const isGroupingEnabled = Boolean(taskChecklistData?.grouping);
  const hasUnlockedGroups = useMemo(() => {
    if (!taskChecklistData) return false;
    return taskChecklistData.task_groups.some(group => !group.locked);
  }, [taskChecklistData]);

  // Styles
  const captionBaseStyles = 'caption text-weight-med';
  const actionButtonStyles = cn('plainButton teal', captionBaseStyles);
  const groupingLabelStyles = cn('n900 marginRight-tiny1', captionBaseStyles);

  // Handlers
  const handleAddGroupClick = useCallback(() => {
    openAddEditGroupModal();
  }, [openAddEditGroupModal]);

  const handleGroupToggleClick = useCallback(() => {
    const isTogglingFromOnToOff = taskChecklistData?.grouping;
    const showConfirmModal = isTogglingFromOnToOff && hasUnlockedGroups;

    if (showConfirmModal) {
      openDeleteItemModal();
    } else {
      toggleGrouping({shouldMergeTasks: false});
    }
  }, [toggleGrouping, taskChecklistData?.grouping, hasUnlockedGroups, openDeleteItemModal]);

  const onAddGroupConfirmClick = useCallback(
    (newGroupName = '') => {
      openAddEditGroupModal();
      addGroup({newGroupName, onSuccess: closeModals});
    },
    [addGroup, openAddEditGroupModal, closeModals]
  );

  /** The modal is confirming their choice to merge all unlocked items */
  const onDeleteItemConfirmClick = useCallback(() => {
    closeModals();
    toggleGrouping({shouldMergeTasks: true});
  }, [toggleGrouping, closeModals]);

  // Drag and Drop functionality
  useMonitorDragAndDropElements({reorderTask, reorderGroup});

  return (
    <>
      <PageHeader title={skuName} outerClassName="paddingX-large paddingBottom-small1">
        <PageHeaderMetaGrid>
          <PageHeaderMetaGrid.Row data={metaDataItems} />
        </PageHeaderMetaGrid>
      </PageHeader>
      <div className="bg-n100 fullheight paddingY-small2 paddingX-large">
        {showPanel && (
          <Panel lowShadow largeBorderRadius className="padding-small2">
            <div className="flex justify-content-space-between marginBottom-small1">
              <div className="flex align-items-center">
                <h5>Task Checklist</h5>
                <Tooltip content="Workers must complete these for a service to be considered complete">
                  <Icon name="info-outline" className={cn('marginLeft-tiny n700', styles.checklistTitleIcon)} />
                </Tooltip>
              </div>
              <div className="flex align-items-center">
                <p className={groupingLabelStyles}>Grouping</p>
                <Toggle onToggle={handleGroupToggleClick} toggleStatus={taskChecklistData?.grouping || false} id="grouping" />

                {isGroupingEnabled && (
                  <div className="marginLeft-small1">
                    <button onClick={handleAddGroupClick} className={actionButtonStyles} type="button">
                      Add Group
                    </button>
                  </div>
                )}
                <div className="marginLeft-small1">
                  <button onClick={openAddTaskSideSheet} className={actionButtonStyles} type="button">
                    Add task
                  </button>
                </div>
              </div>
            </div>
            <div>
              {taskChecklistData?.task_groups.map(group => {
                return <TaskChecklistGroup key={group.id} skuId={skuIdInt} group={group} editTask={openEditTaskSideSheet} isGroupingEnabled={isGroupingEnabled} />;
              })}
            </div>
          </Panel>
        )}
      </div>
      <TaskSideSheet isOpen={sideSheetIsVisible} taskToEdit={taskToEdit} toggleIsOpen={closeTaskSideSheet} upsertTask={upsertTask} />
      <AddEditGroupModal isVisible={isAddEditGroupModalVisible} onConfirmClick={onAddGroupConfirmClick} onCancelClick={closeModals} actionType={ADD_EDIT_GROUP_ACTION_TYPES.ADD} />
      <DeleteItemModal isVisible={isDeleteItemModalVisible} onConfirmClick={onDeleteItemConfirmClick} onCancelClick={closeModals} itemType={TASK_CHECKLIST_ITEM_TYPES.TASKS_AND_GROUPS} />
    </>
  );
};

export default TaskChecklist;
