import React from 'react';
import cdnImages from 'global/constants/cdnImages';
import styles from './tableUtils.styles.scss';

export const getImgElementWithFileIcon = (file: File | {name: string; type: string}) => {
  const fileType = file.type.toLowerCase();
  const altText = 'file icon';
  switch (true) {
    case ['jpg', 'jpeg', 'png', 'image/jpeg', 'image/png'].includes(fileType):
      return <img src={cdnImages.imageIcon} alt={altText} className={styles.image} />;
    case ['csv', 'xls', 'xlsx', 'text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(fileType):
      return <img src={cdnImages.spreadsheetIcon} alt={altText} className={styles.image} />;
    default:
      return <img src={cdnImages.fileIcon} alt={altText} className={styles.image} />;
  }
};

/**
 * Common helper
 */
export const getTableCellWithFileTypeIcon = (file: File | {name: string; type: string}) => {
  return (
    <div className="flex align-items-center">
      {getImgElementWithFileIcon(file)}
      <span className="marginLeft-small">{file.name}</span>
    </div>
  );
};
