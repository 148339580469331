import React, {useCallback, useMemo} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {SelectField} from 'ht-styleguide';
import debounce from 'lodash/debounce';

/* Ducks */
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';

/* Queries/Mutations */
import {useDeleteServiceDraftMutation, useUpdateServiceDraftMutation} from 'features/MultiDwellingUnits/queries/mutation.jobs.drafts';

/* Hooks */
import {useAppDispatch} from 'hooks/useAppDispatch';
import {mduProjectPageGroupServicesSkuEditPath} from 'global/paths';
import {useMduApi} from 'features/MultiDwellingUnits/MDU.hooks';
import {useCurrentProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/CurrentProject.hooks';

/* Utils */
import {formatPrice} from 'utils/currency';
import {noop} from 'utils/event';
import {getSelectOptions} from 'features/MultiDwellingUnits/MDU.utils';
import {actionMenuColumn} from 'components/Elements/DataTable/utils/columns.utils';

/* Types */
import {MduUseParamsTypes, ProjectGroup, ProjectService, SelectOption} from 'features/MultiDwellingUnits/MDU.types';
import {TDataTableColumnDef} from 'components/Elements/DataTable/dataTable.types';

import styles from './templatejobs.styles.scss';

type TUseProject = {
  groupId: string;
};

/**
 * @description - Hook to get the current project and project group
 * @param groupId
 */
export const useProject = ({groupId}: TUseProject) => {
  const project = useCurrentProject();
  const projectGroup = (project?.projectGroups ?? []).find((group: ProjectGroup) => +group.id === +groupId);

  return {
    projectGroups: project.projectGroups,
    projectGroup,
    projectId: project.id,
  };
};

/**
 * @description - Hook to get the current project and DRAFT: project group
 * @param groupId
 */
export const useProjectDraft = ({groupId}: TUseProject) => {
  const project = useCurrentProject();
  const projectGroupsDraft = useSelector(mduProjectsSlice.selectors.getDraftProjectGroupsNew);
  const projectGroup = (projectGroupsDraft ?? []).find((group: ProjectGroup) => +group.id === +groupId) as ProjectGroup;

  return {
    projectGroups: projectGroupsDraft,
    projectGroup,
    projectId: project.id,
  };
};

/**
 * @description - Hook to get the services to a group and boolean to note if there is a service
 * @param groupId
 */
export const useProjectGroupServices = ({groupId}: TUseProject) => {
  const {projectGroups} = useCurrentProject();
  const groupServices = (projectGroups?.find((group: ProjectGroup) => group.id === groupId) || {}).breakdown?.items;

  return {
    hasServices: groupServices.length > 0,
    groupServices,
  };
};

/**
 * @description - Hook to get the table data and actions for services display
 * @param projectGroup
 * @param projectId
 * @param isPreLaunchStatus
 */
type TUseTable = {
  projectGroup: ProjectGroup;
  projectId: string;
  isPreLaunchStatus?: boolean;
  /* Caller determines the path for edit as it can be used in diff layouts */
  pathRedirectOnEdit: BaseAnyFunction;
};

export const useTable = ({projectGroup, projectId, isPreLaunchStatus}: Omit<TUseTable, 'pathRedirectOnEdit'>) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {stage = ''} = useParams<MduUseParamsTypes>();
  const getListItems = useCallback((service: TData) => {
    return [
      {
        text: 'Edit Service',
        key: 'editService',
        onClick: () => {
          navigate(mduProjectPageGroupServicesSkuEditPath(projectId, stage, projectGroup.id as string, service.skuId.toString(), service.projectServiceId));
        },
      },
      {
        text: 'Delete Service',
        key: 'deleteService',
        onClick: () => {
          dispatch(mduProjectsSlice.actions.deleteService({projectId, serviceId: service.projectServiceId, groupId: projectGroup.id as string}));
        },
      },
    ];
  }, []);

  /* Bundles up the project service */
  const handleQuantityChange = useCallback(
    (service: ProjectService) => async (selection: SelectOption) => {
      const serviceWithUpdatedQuantity = {...service, quantity: selection.value};

      dispatch(mduProjectsSlice.actions.updateService({projectId, groupId: projectGroup.id, serviceId: service.id, projectService: {...serviceWithUpdatedQuantity}}));
    },
    [dispatch, projectGroup, projectId]
  );

  // Services
  const data = projectGroup?.breakdown?.items ?? [];

  type TData = (typeof data)[number];

  const columns = useMemo(() => {
    const columnDefs: TDataTableColumnDef<TData>[] = [
      {
        accessorKey: 'skuId',
        header: 'Sku Id',
        Footer: () => <p className="font-weight-medium p1 n900">Total</p>,
        columnWidthMode: 'collapse',
      },
      {
        accessorKey: 'name',
        header: 'Name',
        columnWidthMode: 'fill',
        columnWidthSize: 'sm',
      },
      {
        accessorKey: 'quantity',
        header: 'QTY',
        Cell: ({row}) => {
          const {quantity, projectServiceId} = row.original;
          const matchedProjectService = (projectGroup?.projectServices as ProjectService[])!.find(projectService => projectService.id === projectServiceId);
          return isPreLaunchStatus ? (
            <SelectField className={styles.qtyInputLineItem} options={getSelectOptions(8)} value={{value: quantity, label: quantity}} onChange={handleQuantityChange(matchedProjectService!)} />
          ) : (
            quantity
          );
        },
        columnWidthMode: 'collapse',
        muiTableHeadCellProps: {
          className: 'DataTable-TableHeader_alignRight',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
      },
      {
        id: 'price',
        accessorFn: originalRow => formatPrice(originalRow.amountWithoutSubsidy),
        header: 'Price Per Unit',
        Footer: () => <p className="font-weight-medium p1 n900">{projectGroup?.perUnitTotalWithoutSubsidyFormatted ?? '$0'}</p>,
        columnWidthMode: 'collapse',
        muiTableHeadCellProps: {
          className: 'DataTable-TableHeader_alignRight',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        id: 'techPayout',
        accessorFn: originalRow => originalRow.techPayoutFormatted,
        header: 'Payout Per Unit',
        Footer: () => <p className="font-weight-medium p1 n900">{projectGroup?.perUnitTotalTechPayoutFormatted ?? '$0'}</p>,
        columnWidthMode: 'collapse',
        muiTableHeadCellProps: {
          className: 'DataTable-TableHeader_alignRight',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
    ];

    if (isPreLaunchStatus) {
      columnDefs.push(actionMenuColumn<TData>({getListItems}));
    }

    return columnDefs;
  }, [getListItems, handleQuantityChange, isPreLaunchStatus, projectGroup]);

  return {
    data,
    columns,
  };
};

/**
 * Using a Draft Table - which means, we aren't using legitimate data, but rather a mockup of what the data will look like once saved.
 * The apis are different, so we need to handle the data differently.
 * Note: WE broke out the apis as to not conflate intent of the apis. if else then etc..
 *
 * @param projectGroup
 * @param projectId
 */
export const useDraftTable = ({projectGroup, projectId, pathRedirectOnEdit}: TUseTable) => {
  const navigate = useNavigate();
  const deleteService = useDeleteServiceDraftMutation();
  const updateService = useUpdateServiceDraftMutation();

  const getListItems = useCallback(
    (service: TData) => {
      return [
        {
          text: 'Edit Service',
          key: 'editService',
          onClick: () => {
            navigate(pathRedirectOnEdit(projectId, projectGroup.id as string, service.skuId, service.projectServiceId));
          },
        },
        {
          text: 'Delete Service',
          key: 'deleteService',
          onClick: () => {
            deleteService.mutate(service.projectServiceId);
          },
        },
      ];
    },
    [deleteService, navigate, pathRedirectOnEdit, projectGroup?.id, projectId]
  );

  /* Bundles up the project service */
  const handleQuantityChange = useCallback(
    (service: ProjectService) => async (selection: SelectOption) => {
      const serviceWithUpdatedQuantity = {...service, quantity: selection.value};
      updateService.mutate(serviceWithUpdatedQuantity);
    },
    [updateService]
  );

  // Services
  const data = projectGroup?.breakdown?.items ?? [];

  type TData = (typeof data)[number];

  const columns = useMemo(() => {
    const columnDefs: TDataTableColumnDef<TData>[] = [
      {
        accessorKey: 'skuId',
        header: 'Sku Id',
        Footer: () => <p className="font-weight-medium p1 n900">Total</p>,
        columnWidthMode: 'collapse',
      },
      {
        accessorKey: 'name',
        header: 'Name',
        columnWidthMode: 'fill',
        columnWidthSize: 'sm',
      },
      {
        accessorKey: 'quantity',
        header: 'QTY',
        Cell: ({row}) => {
          const {projectServiceId, quantity} = row.original;
          // CHANGE THIS TO DRAFT
          const matchedProjectService = (projectGroup?.projectServices as ProjectService[])!.find(projectService => projectService.id === projectServiceId);
          return <SelectField className={styles.qtyInputLineItem} options={getSelectOptions(8)} value={{value: quantity, label: quantity}} onChange={handleQuantityChange(matchedProjectService!)} />;
        },
        columnWidthMode: 'collapse',
        muiTableHeadCellProps: {
          className: 'DataTable-TableHeader_alignRight',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
      },
      {
        id: 'price',
        accessorFn: originalRow => formatPrice(originalRow.amountWithoutSubsidy),
        header: 'Price Per Unit',
        Footer: () => <p className="font-weight-medium p1 n900">{projectGroup?.perUnitTotalWithoutSubsidyFormatted ?? '$0'}</p>,
        columnWidthMode: 'collapse',
        muiTableHeadCellProps: {
          className: 'DataTable-TableHeader_alignRight',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      {
        id: 'techPayout',
        accessorFn: originalRow => originalRow.techPayoutFormatted,
        header: 'Payout Per Unit',
        Footer: () => <p className="font-weight-medium p1 n900">{projectGroup?.perUnitTotalTechPayoutFormatted ?? '$0'}</p>,
        columnWidthMode: 'collapse',
        muiTableHeadCellProps: {
          className: 'DataTable-TableHeader_alignRight',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        muiTableFooterCellProps: {
          align: 'right',
        },
      },
      actionMenuColumn<TData>({getListItems}),
    ];
    return columnDefs;
  }, [getListItems, handleQuantityChange, projectGroup?.perUnitTotalTechPayoutFormatted, projectGroup?.perUnitTotalWithoutSubsidyFormatted, projectGroup?.projectServices]);

  return {
    data,
    columns,
  };
};

/**
 * Same unit qty updater in various pages. So, moved it to here.
 * Will only fire if the current value is different from the value being changed to.
 */
export const useDebounceUnitQty = () => {
  /* Hooks */
  const {projectId} = useParams<MduUseParamsTypes>();
  const api = useMduApi();

  const updateUnitQtyTemplate = debounce(
    ({quantity = 0, currentQty, groupId}) => {
      if (+quantity !== +currentQty) {
        const response = api.updateGroup({projectId, projectGroupId: groupId, projectGroups: {units_number: quantity}});
        return response as Promise<any>;
      }
      return {success: true};
    },
    800,
    {
      trailing: true,
      leading: false,
    }
  );

  const updateUnitQtyProject = debounce(
    async (quantity = 0, currentQty: number, callback?: BaseAnyFunction) => {
      const cb = callback || noop;
      if (+quantity !== +currentQty) {
        const response = await api.updateProject(projectId, {units_number: quantity});
        if (response.id) cb();
      }

      /* User hit 'save' but user didn't update, no reason to hit api. Its a validation nonetheless */
      if (+quantity === +currentQty) cb();
    },
    800,
    {
      trailing: true,
      leading: false,
    }
  );

  return {
    updateUnitQtyTemplate,
    updateUnitQtyProject,
  };
};
