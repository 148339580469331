import React from 'react';
import {Toggle, ErrorMessage} from 'ht-styleguide';
import {TooltipWrapper} from '../LabelWithTooltip';

const Label = ({label, inputId}: {label: string; inputId: string}) => (
  <label htmlFor={inputId} className="input-label-medium n800">
    {label}
  </label>
);

interface IToggleWithTooltip {
  error: string | undefined;
  /**
   * Form id
   */
  id: string;
  label: string;
  toggleValue: boolean;
  onToggleChange: (id: string) => (newValue: boolean) => void;
  tooltipContent?: string;
}

const ToggleWithTooltip = ({error, id, label, onToggleChange, toggleValue, tooltipContent}: IToggleWithTooltip) => {
  const LabelComponent = <Label label={label} inputId={id} />;

  const onToggle = (newValue: boolean) => onToggleChange(id)(newValue);

  return (
    <div>
      <div className="flex justify-content-space-between align-items-center">
        {tooltipContent ? <TooltipWrapper tooltipContent={tooltipContent}>{LabelComponent}</TooltipWrapper> : LabelComponent}
        <Toggle id={id} toggleStatus={toggleValue} onToggle={onToggle} />
      </div>
      <ErrorMessage error={error} />
    </div>
  );
};

export default ToggleWithTooltip;
