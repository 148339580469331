import React from 'react';
import {Route, Routes, Navigate, Outlet} from 'react-router-dom';
import {Routes as HTRoutes} from 'global/routes/routes';

// Components
import LogoHeader from 'components/Headers/LogoHeader';
import GeneralLayout from 'components/Layouts/General/General.layout';
import Main from './Parts/Main';

/**
 * *******************************************************
 * BASE ROUTE DISPLAY.
 * *******************************************************
 */
const ProfileFlowStructureLayout = () => (
  <>
    <LogoHeader />
    <GeneralLayout.InnerContainer>
      <Outlet />
    </GeneralLayout.InnerContainer>
  </>
);

const ProfileFlowStructure = () => {
  return (
    <Routes>
      <Route element={<ProfileFlowStructureLayout />}>
        <Route index element={<Main />} />
        <Route path=":id" element={<Main />} />
      </Route>
      <Route path="*" element={<Navigate to={HTRoutes.LOGIN()} replace />} />
    </Routes>
  );
};

export default ProfileFlowStructure;
