// Split names
export const SPLIT_NAMES = {
  SUBSCRIPTION_OPT_IN_DISCOUNT: 'subscription_opt_in_discount',
  NEW_UPSELLING_PLANS: 'hth_admin_upsell_new_plans',
  HTH_ADMIN_FEATURE_EXCLUSION_LIST: 'hth_admin_feature_exclusion_list',
  TIME_WINDOW_DROPDOWN_SELECT_BOOKING_ADMIN: 'time_window_dropdown_select_booking_admin',
};

// Treatment Types
export const TREATMENTS = {
  ON: 'on',
  OFF: 'off',
  CONTROL: 'control', // reserved by split
};

// Splits to load on user load
export const SPLITS_ON_USER_LOAD = [
  SPLIT_NAMES.SUBSCRIPTION_OPT_IN_DISCOUNT,
  SPLIT_NAMES.NEW_UPSELLING_PLANS,
  SPLIT_NAMES.HTH_ADMIN_FEATURE_EXCLUSION_LIST,
  SPLIT_NAMES.TIME_WINDOW_DROPDOWN_SELECT_BOOKING_ADMIN,
];
