import {formatter as currencyFormmater} from 'utils/currency';
import {PARTNERS_SKU_CATEGORIES, PIN_REDEMPTION_ERROR_REQUIRED} from 'features/Booking/constants/partners';
import {ItemAdjustments, BreakdownItem, Prepaid, AdjustmentType, BreakDown, GlobalAdjustment} from './cart.types';
import {PinRedemption} from '../Services/services.types';

/*
*******************************************************
  Helpers to Modify Presentation of Breakdown Items
*******************************************************
*/
export const getFilteredServiceAdjustments = ({adjustments, prepaid, skuAmount}: {adjustments?: ItemAdjustments[]; prepaid: Prepaid; skuAmount: number}) => {
  if (!adjustments) return [];

  return adjustments
    .filter(adj => {
      const excludeSubscriptionDiscount = !(adj.name || '').trim().toLowerCase().includes('subscription discount');

      // Include the subsidy adj depending on the prepaid status and how much the subsidy covers
      let excludeSubsidy = true;
      if (adj.type === AdjustmentType.SUBSIDY) {
        if (prepaid === Prepaid.Full) {
          excludeSubsidy = false;
        } else if (prepaid === Prepaid.Base) {
          excludeSubsidy = true;
        }
      }

      return excludeSubscriptionDiscount && excludeSubsidy;
    })
    .map(item => {
      const {name, amountFormatted, amount, type} = item;

      if (type === AdjustmentType.SUBSIDY) {
        const absoluteAdjustAmount = Math.abs((amount as number) || 0);
        const adjustedAmount = absoluteAdjustAmount > skuAmount ? absoluteAdjustAmount - skuAmount : absoluteAdjustAmount;
        return {name, amount: `-${currencyFormmater(0).format(adjustedAmount / 100)}`};
      }

      return {name, amount: amountFormatted};
    });
};

export const skuPriceAfterSubscriptionDiscount = (item: BreakdownItem) => {
  // right now `savings` is the subscription discount
  if (item.savings) {
    return item.skuAmount! - item.savings;
  }

  return item.skuAmount;
};

export const getPricesAndDiscounts = (item: BreakdownItem) => {
  if (!item) return {};
  const {prepaid, savings, skuAmountFormatted, skuAmount, adjustments} = item;
  if ([Prepaid.Full, Prepaid.Base].includes(prepaid)) {
    const subsidyAdj = adjustments.find(adj => adj.type === AdjustmentType.SUBSIDY);
    const subsidyCoversSku = subsidyAdj ? Math.abs((subsidyAdj.amount as number) || 0) > skuAmount : false;

    if (skuAmount === 0 || prepaid === Prepaid.Full || subsidyCoversSku) {
      return {amount: 'Prepaid'};
    }

    return {amount: skuAmountFormatted};
  }
  if (savings) {
    const skuPriceAfterDiscount = skuPriceAfterSubscriptionDiscount(item)!;
    return {amount: `$${skuPriceAfterDiscount / 100}`, lineThroughText: skuAmountFormatted};
  }
  return {amount: skuAmountFormatted};
};

/*
*******************************************************
  Other Utils
*******************************************************
*/
export const validatePinRedemptionWithSku = ({skuId, pins}: {skuId: number; pins: PinRedemption}): Partial<PinRedemption> => {
  /* base validation pin object */
  const pinRedemptionValidation: Partial<PinRedemption> = {
    err: '',
  };
  /* If we found a sku that needs a pin, verify and exit */
  const matchedPinSku = Object.entries(PARTNERS_SKU_CATEGORIES).some(([, {pinRedemptionIds}]) => pinRedemptionIds.includes(skuId));
  /* No pin required. All good */
  if (!matchedPinSku) return pinRedemptionValidation;

  /* We have a sku that needs pin validation, but failed */
  if (pins.sku_id !== skuId) pinRedemptionValidation.err = PIN_REDEMPTION_ERROR_REQUIRED;

  return {...pinRedemptionValidation, ...pins};
};

export const getGlobalAdjustmentsByType = ({adjustments, type}: Pick<BreakDown, 'adjustments'> & {type: GlobalAdjustment['type']}) => {
  return Array.isArray(adjustments) ? adjustments.filter(adj => adj.type === type) : [];
};

export const getPlanAdjustmentsByType = ({subscriptionAdjustments, type}: Pick<BreakDown, 'subscriptionAdjustments'> & {type: GlobalAdjustment['type']}) => {
  return Array.isArray(subscriptionAdjustments) ? subscriptionAdjustments.filter(adj => adj.type === type) : [];
};

export const aggregateAdjustmentsAmount = (adjustments: BreakDown['adjustments'] | BreakDown['subscriptionAdjustments']) => {
  const totalAmount = Array.isArray(adjustments) ? adjustments.reduce((total, adj) => total + adj.amount, 0) : 0;
  return {
    totalAmount,
    totalAmountFormatted: currencyFormmater(0).format(totalAmount / 100),
    totalAmountFormattedAbs: currencyFormmater(0).format(Math.abs(totalAmount) / 100),
  };
};
