import React from 'react';
import {useSelector} from 'react-redux';
import cn from 'classnames';
import {Button, BUTTON_THEMES, Modal} from 'ht-styleguide';

/* Ducks */
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';

/* Hooks */
import {useJobsActionsItems} from '../../Pages/CurrentProject/hooks/Jobs.useActionItems';
import {useDeleteAndApproveWithInvalidate} from '../../Pages/Projects/hooks';

/* styles */
import styles from './modals.styles.scss';

import {TActionItem, TSingleActionTypes, TActionModalTypes} from '../../MDU.types';

const ApproveActionModal = () => {
  /* Hooks */
  const {approveUnitAction} = useJobsActionsItems();
  const {approveProjectAction, onCloseActionItem} = useDeleteAndApproveWithInvalidate();
  const actionItem: TActionItem = useSelector(mduProjectsSlice.selectors.getActionItem);

  /* Constants */
  const approveConstants = () => {
    const type = {
      Project: {
        dispatchAction: approveProjectAction,
        body: `By approving <strong>${actionItem?.entity?.name}</strong>, you acknowledge that all completed work has been accounted for and that any errors have been corrected. Once approved, this project will be billable to the partner.`,
      },
      Job: {
        dispatchAction: approveUnitAction,
        body: `By approving <strong>${actionItem?.entity?.unit_name}</strong>, you acknowledge that all completed work has been accounted for and that any errors have been corrected.`,
      },
    };

    return type[actionItem?.modalType as TActionModalTypes] || type.Project;
  };

  if (!actionItem) return null;

  /* Constants */
  const {body, dispatchAction} = approveConstants();
  const actionText = `Approve ${actionItem?.modalType}`;

  return (
    <Modal
      isVisible={actionItem?.modalKey === TSingleActionTypes.APPROVE}
      modalClassName={cn(styles.container, styles.modalContents)}
      hide={onCloseActionItem}
      onRequestClose={onCloseActionItem}
      header={actionText}
      footerElement2={
        <Button theme={BUTTON_THEMES.SECONDARY} dataTestId="button_cancl_job" className={styles.ctaButton} onClick={onCloseActionItem}>
          Cancel
        </Button>
      }
      footerElement3={
        <Button theme={BUTTON_THEMES.PRIMARY} dataTestId="button_approve_job" className={styles.ctaButton} onClick={dispatchAction}>
          {actionText}
        </Button>
      }
    >
      <div className="position relative">
        <p className="p1 n900" dangerouslySetInnerHTML={{__html: `${body}`}} />
      </div>
    </Modal>
  );
};

export default ApproveActionModal;
