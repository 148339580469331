import {TOrderDetails, StatusId, ProgressStatusId} from 'features/Orders/types/orders.types';

type TBaseOrderUtilsParams = {
  order: TOrderDetails | null | undefined;
};

/**
 * Check if the worker has marked the job as started
 */
export const checkOrderHasStarted = ({order}: TBaseOrderUtilsParams) => order?.statusId === StatusId.ASSIGNED && order?.progressStatusId === ProgressStatusId.STARTED;

/**
 * Check if the order has been closed. A closed order is one where work had begun and the worker/admin marked
 * the job as closed to some capacity.
 */
export const checkOrderHasClosed = ({order}: TBaseOrderUtilsParams) =>
  order?.statusId ? [StatusId.COMPLETED, StatusId.CANCELLED, StatusId.PAID, StatusId.CANCELLED_BY_GEEK, StatusId.UNSERVICEABLE, StatusId.INCOMPLETE].includes(order.statusId) : false;
