import React, {useEffect, useRef, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {ELEMENT_SIZE, htToast, SideSheet, TSideSheetCustomHeaderProps} from 'ht-styleguide';
import debounce from 'lodash/debounce';
import {Dictionary, NumericDictionary} from 'lodash';
import {FormikProps, useFormik} from 'formik';
import * as Yup from 'yup';

/* Components */
import HeaderWithToolbar from 'components/SideSheets/HeaderWithToolbar';
import {HEADER_TOOLBAR_CLOSE_ICON_PRESET} from 'components/SideSheets/HeaderWithToolbar/HeaderWithToolbar.constants';
import ChipMenu from 'components/SideSheets/ChipMenu';
import {CommentFeed} from 'features/Issues/Parts/CommentFeed';
import ActionBarGroup from 'components/Elements/ActionBarGroup';
import AvatarUser from 'components/Elements/AvatarUser';
import Editor from 'components/Editor/Editor';
import DeleteActionIssueModal from 'features/Issues/Modals/Issues.Delete';

/* Constants */
import IssueProperties, {cloudinaryNamespaceEdit} from './SideSheet.Issue.EditIssueForm.IssueProperties';
import {TActionType} from 'components/Elements/ActionBarGroup/actionGroup.types';
import {featureTypeIssues, ISSUE_ENTITY_TYPES, ISSUE_FIELDS_OPERATION_TYPE, ISSUE_PARAMS_TYPES, ISSUE_SLIDESHEET_TYPES, ISSUES_MODAL_TYPES} from 'features/Issues/Issues.constants';

/* Hooks / Queries */
import {useSearchParams} from 'hooks/useSearchParam';
import {useUpdateIssueMutation} from 'features/Issues/queries/mutation.issues.updateIssues';
import {useGetIssueByIdQuery, useGetIssueFieldsWithValuesQuery, useGetStatusesQuery} from 'features/Issues/queries/query.issues.fields';
import {useUserCurrentQuerySelect} from 'queries/User/query.user.current';
import {TCreateEditorNoteParams, useMutationCreateEditorNote} from 'features/Issues/queries/mutation.createNote';
import {useFormikIssueHelpers} from '../SideSheet.Issue.dataFormatting.Helpers';

/* Ducks/Slices */
import CloudinaryDuck from 'features/Cloudinary/state/cloudinary.ducks';
import issuesSlice from 'features/Issues/Issues.ducks';

/* Utils/Types */
import {noop} from 'utils/event';
import {logger, LoggerErrorType} from 'utils/logger';
import {getAttributesByTicketStatus, getDebouncedTimeByEntityName} from 'features/Issues/Issues.utils';
import useCloudinaryHooks from 'features/Cloudinary/hooks/useCloudinary';
import {FILE_UPLOAD_VALIDATIONS} from 'utils/files/constants';
import {editorSchemas} from 'components/Editor/editor.schemas';
import {TIME_15_MINUTES} from 'global/constants/common';
import {equateNullUndefinedArray, getDifferenceBetweenTwoObjects} from 'utils/object/getDifferenceBetweenTwoObjects';

import {TGenericCloudinaryResponse} from 'features/Cloudinary/cloudinary.types';
import {TIssueDynamicFields, TIssueStatus, TIssueTicket} from 'features/Issues/issues.types';
import {TChipMenuItem} from 'components/SideSheets/ChipMenu/ChipMenu';

import styles from '../../SideSheet.Issue.styles.scss';

type TEditIssueForm = {
  closeSideSheet: () => void;
  isOpenIssueSideSheet: boolean;
  issueId?: number;
};

type TWrapperProps = {
  issue: TIssueTicket;
  toolbarMenuItems: TActionType[];
  issueStatuses?: TIssueStatus[];
  formik: FormikProps<TIssueDynamicFields>;
};

const getHeaderComponent = (wrapperProps: TWrapperProps) => (props: TSideSheetCustomHeaderProps) => {
  /* Left Component */
  const {toolbarMenuItems, issue, formik} = wrapperProps;
  const selectedChipAttributes = getAttributesByTicketStatus({key: issue.status_id?.name, omitIcon: false});
  /* This represents the current saved status */
  const currentStatusChipProps = {
    text: selectedChipAttributes?.name,
    chipProps: {
      type: selectedChipAttributes.component.chip,
      iconName: selectedChipAttributes?.icon.iconDefault,
    },
  };

  /* Queries */
  const {data: statusData = []} = useGetStatusesQuery();

  /* Constants */
  const [savedValue] = formik.values?.status_id || [];
  const chipMenuListItems = statusData.reduce((acc: TChipMenuItem[], status: TIssueStatus) => {
    if (status.id !== savedValue) {
      return acc.concat([
        {
          text: status.name,
          onClick: () => formik.setFieldValue('status_id', [status.id]),
          key: status.id,
        },
      ]);
    }
    return acc;
  }, []);

  /* Right Component */
  const getActionBarMenuItems = (hide = noop) => {
    const actions = [
      ...toolbarMenuItems,
      {
        ...HEADER_TOOLBAR_CLOSE_ICON_PRESET,
        action: hide,
        tooltipContent: 'Close details',
      },
    ];
    return <ActionBarGroup actions={actions} size="lg" />;
  };

  return (
    <HeaderWithToolbar
      {...props}
      isHeaderTextEditable
      textEditCallback={(val: string) => formik.setFieldValue('summary', val)}
      LeftContent={({isCollapsed}: any) => {
        return <ChipMenu showMinimized={isCollapsed} menuList={chipMenuListItems} {...currentStatusChipProps} />;
      }}
      RightContent={({hide}) => getActionBarMenuItems(hide)}
    />
  );
};

const EditIssueForm = ({closeSideSheet, isOpenIssueSideSheet}: TEditIssueForm) => {
  const dispatch = useDispatch();
  const initialValues = useRef<TIssueDynamicFields>({} as TIssueDynamicFields);
  const schema = useRef<Yup.Schema<TIssueDynamicFields>>({} as Yup.Schema<TIssueDynamicFields>);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const debouncedRef = useRef<number>(0);

  /* Hooks || Selectors */
  const {getAllParams, deleteAllParams} = useSearchParams({[ISSUE_PARAMS_TYPES.issue_mode]: ISSUE_SLIDESHEET_TYPES.issue_create});
  const fileUploads = useSelector(CloudinaryDuck.selectors.getFileSuccessUploads(cloudinaryNamespaceEdit));
  const {uploadFiles} = useCloudinaryHooks({namespace: cloudinaryNamespaceEdit});

  /* Issues Params */
  const {[ISSUE_PARAMS_TYPES.ticket_id]: issueTicketId} = getAllParams();

  /* Queries */
  /* We need to get the view & fields, so we can match them up */
  const {data: issueTicketFieldData, isError} = useGetIssueFieldsWithValuesQuery({ticket_id: +issueTicketId, operationType: ISSUE_FIELDS_OPERATION_TYPE.update});
  /* No separate attachments getter yet. So, get it from the ticket */
  const {data: issueTicketData} = useGetIssueByIdQuery({ticket_id: +issueTicketId});
  const {mutate: updateIssue} = useUpdateIssueMutation();
  const {data: issueStatuses} = useGetStatusesQuery();
  const currentUser = useUserCurrentQuerySelect();

  /* FORMIK FORM VALIDATIONS */
  const formik = useFormik<TIssueDynamicFields>({
    validateOnChange: false,
    initialValues: initialValues.current as TIssueDynamicFields,
    validationSchema: Yup.object(schema.current as unknown as Yup.ObjectSchemaDefinition<TIssueDynamicFields>),
    onSubmit: async values => {
      try {
        // 1. Compare the incoming values with the initial values.  If they are the same, then we don't need to update.
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const {diff} = getChangedElements(values);

        // 2. If they are different, then we need to update.
        if (Object.keys(diff).length > 0) {
          updateIssue(
            {ticket_id: issueTicketData!.id, ticket: diff},
            {
              onError: error => {
                htToast(`Something went wrong.(ticket id: ${issueTicketData!.id})`);
                logger('Create issue error')(error as LoggerErrorType);
              },
            }
          );
        }
      } catch (error) {
        logger('Update issue error')(error as LoggerErrorType);
      }
    },
  });

  /* Formik Issues Helpers */
  const {setFormikValues, setFormikInitialValues, setFormikSchema} = useFormikIssueHelpers({formik, schema, initialValues});

  /* Editor Component For Footer */
  const EditorDisplay = () => {
    const entityAttributes = {
      useSaveMutation: useMutationCreateEditorNote,
      entityType: ISSUE_ENTITY_TYPES.project,
      entityId: issueTicketData!.id,
    };

    return (
      <div className="flex justify-content-space-between align-items-start">
        <div className="paddingRight-small">
          <AvatarUser size={ELEMENT_SIZE.SMALL} name={`${currentUser?.name}`} isOwner />
        </div>
        <Editor<TCreateEditorNoteParams> editorType={editorSchemas.NOTE} entityAttributes={entityAttributes} />
      </div>
    );
  };
  /* METHODS */

  /*
    This hands back our "difference/changed" elements. Will also hand back its associated debounced attribute
  */
  type TChangedObject = Dictionary<any> | NumericDictionary<any>;
  const getChangedElements = (values: TChangedObject) => {
    const diff = getDifferenceBetweenTwoObjects<TChangedObject>(values, initialValues.current, equateNullUndefinedArray);
    debouncedRef.current = getDebouncedTimeByEntityName('text');

    return {
      diff,
    };
  };

  /**
   * @description We need to be able to get the changeset of the formik value that has changed and be able to
   *              to the debounced function with the proper time. We don't want to wait 2 seconds for select fields,
   *              but we do want to wait 2 seconds for text fields. (or whatever the ms we decide) on. This takes the
   *              changeset and matches it with the "field" type and returns the proper debounced time from the utility,
   *              getDebouncedTimeByEntityName.
   *
   * @returns number
   *          [0, 1500] -> 1500
   *
   */
  const getDebouncedTimeByEntityType = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const {diff} = getChangedElements(formik.values);
    if (!diff || Object.keys(diff).length < 1) return 0;
    // 1. Get the keys of the diff
    const diffKeys = Object.keys(diff);
    // 2. Get the field type of the diff keys
    const diffFieldTypes = diffKeys.map(key => {
      const field = issueTicketFieldData?.find(f => f.name === key);
      return field?.type;
    });
    // 3. Get the debounced time by the field type
    const debouncedTimes = diffFieldTypes.map(type => getDebouncedTimeByEntityName(type));
    // 4. Return the debounced time. If we have multiple (array) of field types, then we need to get the max.
    return Math.max(...debouncedTimes);
  };

  /**
   * @description We need to debounce the formik submit, so we don't have a ton of requests going out for each entry of
   *              certain fields, ie text, long_text, etc...
   *              We can customize the debounce time by the field type.
   */
  const submitDebouncedForm = useCallback(
    debounce(
      () => {
        formik.submitForm();
      },
      debouncedRef.current,
      {leading: false, trailing: true}
    ),
    [debouncedRef.current]
  );

  /* ------- TOP NAV BEHAVIORS -------- */
  const openFileInput = () => {
    if (fileInputRef.current) {
      // Trigger a click event on the file input to open the file dialog
      fileInputRef.current.click();
    }
  };

  /*
     Delete Ticket
     @description this sets the redux for the modal type to perform a delete action
  */
  const deleteIssue = () => {
    dispatch(
      issuesSlice.actions.setActionItemModalSlide({
        entity: issueTicketData,
        modalKey: featureTypeIssues,
        modalType: ISSUES_MODAL_TYPES.delete,
        onSuccessCallback: deleteAllParams,
      })
    );
  };

  /*
     Copy's to clipboard
     @description Puts the current url with search in the users clipboard
  */
  const copyIssueLink = () => {
    const issueLink = window?.location.href;
    navigator.clipboard.writeText(issueLink);
    htToast('Link copied');
  };
  const toolbarMenuItems = [
    {
      type: 'icon' as const,
      iconName: 'attachment',
      action: openFileInput,
      tooltipContent: 'Attach a file.',
      customClassName: 'n700',
    },
    {
      type: 'icon' as const,
      iconName: 'link1',
      action: copyIssueLink,
      tooltipContent: 'Copy issue link',
      customClassName: 'n700',
    },
    {
      type: 'icon' as const,
      iconName: 'trash',
      action: deleteIssue,
      tooltipContent: 'Delete issue',
      customClassName: 'n700',
    },
  ];

  /**
   * SETTING THE FORMIK VALUES FOR EDIT VIEW
   *
   * @description We need to bundle up and set the values. WE can't do it one by one, else it's max recursion error.
   *              Set the defaults for the formik form, initially. Updated state will be handled by the formik form.
   *
   *              This is redundant but this handles the actual setting of the values, rather than seeding.
   *              And we need this so we can know what changed between initial vs the update.
   */
  useEffect(() => {
    if (issueTicketFieldData) {
      setFormikSchema({fields: issueTicketFieldData});
      setFormikInitialValues({fields: issueTicketFieldData});
      setFormikValues({fields: issueTicketFieldData});
    }
  }, [issueTicketFieldData]);

  /*
      FORM UPDATE ON CHANGE

      Submit form on value change.
  */
  useEffect(() => {
    if (issueTicketFieldData) {
      debouncedRef.current = getDebouncedTimeByEntityType();
      const greatestDebounceTime = getDebouncedTimeByEntityType();
      if (greatestDebounceTime > 0) {
        submitDebouncedForm();
      } else {
        formik.submitForm();
      }
    }
  }, [formik.values]);

  /*
    ATTACHMENT HANDLING UPDATE

    If our attachments change, update mutation.
    Admittedly, this is haphazard. Its not a formik field, so we can't use the formik submit
    when dealing with "onChange" events.  So, we have to do it here.
  */
  useEffect(() => {
    if (issueTicketData) {
      if (fileUploads?.length !== (issueTicketData?.attachments as TGenericCloudinaryResponse[])?.length) {
        updateIssue({ticket_id: issueTicketData.id, ticket: {attachments: fileUploads}}, {});
      }
    }
  }, [fileUploads]);

  /**
   * 1. Lets clear out our cloudinary uploads when we close the side sheet.
   *
   * 2. Also, set the timer to self-close the side sheet if no interaction within cache-time. This is a stop gap till
   * I fix it. fe-171
   */
  useEffect(() => {
    const timer = TIME_15_MINUTES - 20000;
    let t = setTimeout(closeSideSheet, timer);
    window.addEventListener('click', () => {
      clearTimeout(t);
      t = setTimeout(closeSideSheet, timer);
    });

    return () => {
      dispatch(CloudinaryDuck.actions.deleteAllFilesByNamespace({editorNamespace: cloudinaryNamespaceEdit}));
    };
  }, []);

  if (!issueTicketFieldData || !issueTicketData) return null;
  if (isError) {
    return (
      <div>
        <p>Something went wrong.</p>
      </div>
    );
  }

  return (
    <>
      <SideSheet
        isOpen={isOpenIssueSideSheet}
        hide={closeSideSheet}
        headerText={issueTicketData.summary}
        footerContent={issueTicketData?.id ? <EditorDisplay /> : null}
        scrollThrottleMs={100}
        headerComponent={getHeaderComponent({issueStatuses, issue: issueTicketData, toolbarMenuItems, formik})}
      >
        <div className={styles.sideSheetEditContainer}>
          <IssueProperties id={issueTicketId} preseedAttachments={issueTicketData.attachments} issue={issueTicketFieldData} formik={formik} />
        </div>
        <CommentFeed withLightbox ticketId={issueTicketId} />
      </SideSheet>
      <DeleteActionIssueModal />
      <input
        type="file"
        ref={fileInputRef}
        style={{display: 'none'}}
        accept={FILE_UPLOAD_VALIDATIONS.ALLOWED_FILE_ACCEPT().join(',')}
        multiple
        onChange={e => {
          const {files} = e.target;
          if (!files) return;
          uploadFiles(files, true);
        }}
      />
    </>
  );
};

export default EditIssueForm;
