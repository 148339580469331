import React, {useMemo, memo} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Button, BUTTON_THEMES, Modal, htToast, ELEMENT_SIZE} from 'ht-styleguide';
import IssuesSelect from 'features/Issues/Parts/SelectFields/IssuesSelect';
import {ISSUE_ENTITY_TYPES} from 'features/Issues//Issues.constants';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {TSingleActionTypes, CurrentProjectStages} from 'features/MultiDwellingUnits/MDU.types';
import {usePauseProjectMutation} from 'features/MultiDwellingUnits/queries/mutation.project.pauseActions';
import {mduProjectPathByStage} from 'global/paths';
import {useAppDispatch} from 'hooks/useAppDispatch';
import styles from './modals.styles.scss';

const ISSUE_FIELD_NAME = 'selectedIssue';

const yupSchema = Yup.object({
  [ISSUE_FIELD_NAME]: Yup.number().nullable(),
});

interface IPauseProjectModal {
  /**
   * Used for slight variations in UI. Another alternative is to parse the url, but
   * the boolean was more convenient atm.
   */
  isSingleProjectView?: boolean;
}

const PauseProjectModal = ({isSingleProjectView = false}: IPauseProjectModal) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const actionItem = useSelector(mduProjectsSlice.selectors.getActionItem);

  const {id: projectId, name: projectName} = actionItem?.entity || {};
  const modalVisible = actionItem?.modalKey === TSingleActionTypes.PAUSE;

  const {mutate} = usePauseProjectMutation();

  const closeModal = () => dispatch(mduProjectsSlice.actions.removeActionItemModalSlide());

  const redirectToProjectView = () => navigate(mduProjectPathByStage(String(projectId), CurrentProjectStages.PROJECT_OVERVIEW));

  const formik = useFormik({
    validationSchema: yupSchema,
    initialValues: {
      [ISSUE_FIELD_NAME]: null,
    },
    onSubmit: ({selectedIssue}) => {
      mutate(
        {projectId: projectId!, issueId: selectedIssue},
        {
          onSuccess: () => {
            closeModal();
            htToast(
              <div className="h6 n000">
                {projectName} Paused.{' '}
                {!isSingleProjectView && (
                  <>
                    {' '}
                    <span className="text-underline" onClick={redirectToProjectView}>
                      View Project
                    </span>
                  </>
                )}
              </div>,
              {autoClose: false}
            );
          },
        }
      );
    },
  });

  const queryFilters = useMemo(
    () => ({
      entity_id: projectId,
      entity_type: ISSUE_ENTITY_TYPES.project,
      pagination: {
        per_page: 100,
        page: 1,
      },
    }),
    [projectId]
  );

  const queryOpts = useMemo(
    () => ({
      enabled: modalVisible && !!projectId,
    }),
    [modalVisible, projectId]
  );

  if (!modalVisible) return null;

  return (
    <Modal
      elementSize={ELEMENT_SIZE.MEDIUM}
      isVisible={modalVisible}
      hide={closeModal}
      bodyContainerClasses={styles.overflowInitial}
      modalClassName={styles.overflowInitial}
      header="Pause project"
      footerElement2={
        <Button onClick={formik.submitForm} theme={BUTTON_THEMES.PRIMARY}>
          Pause Project
        </Button>
      }
    >
      <p className="p1 marginBottom-medium">Select the primary issue that is causing the pause in work</p>
      <IssuesSelect formik={formik} fieldName={ISSUE_FIELD_NAME} label="Link an issue (optional)" queryFilters={queryFilters} queryOpts={queryOpts} />
    </Modal>
  );
};

export default memo(PauseProjectModal);
