import React, {useState, useCallback} from 'react';
import cn from 'classnames';
// Hooks
import useTaskChecklistPage, {useTaskChecklistModals} from '../hooks/useTaskChecklistPage.hooks';
import {useGroupItemDnDHandler, useMouseInState} from './taskChecklistGroup.hooks';
// Constants
import {TASK_CHECKLIST_ITEM_TYPES} from '../TaskChecklist.constants';
// Types
import {TTaskGroup} from 'features/Products/types/taskGroups.types';
import {TTaskChecklistItemType} from '../TaskSideSheet/taskChecklist.types';
import {TOpenEditTaskSideSheetFunction} from '../taskChecklist.types';
// Components
import {Icon} from 'ht-styleguide';
import {DropIndicator} from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';
import {DeleteItemModal} from '../Modals/TaskChecklist.DeleteItem.Modal';
import {AddEditGroupModal, ADD_EDIT_GROUP_ACTION_TYPES} from '../Modals/TaskCheclist.AddEditGroup.Modal';
import DragHandle from './TaskChecklist.DragHandle';
import TaskItem from './TaskChecklist.TaskItem';
import styles from '../taskChecklist.styles.scss';

const NO_TASK_WARNING = 'A group should have at least 1 task';

type TTaskChecklistGroupProps = {
  group: TTaskGroup;
  isGroupingEnabled: boolean;
  editTask: TOpenEditTaskSideSheetFunction;
  skuId: number;
};

/** Renders a group of tasks with options to edit/delete the group and its tasks */
const TaskChecklistGroup = ({group, isGroupingEnabled, editTask, skuId}: TTaskChecklistGroupProps) => {
  const {deleteTask, deleteGroup, editGroup} = useTaskChecklistPage({skuId});

  const {isDeleteItemModalVisible, isAddEditGroupModalVisible, openAddEditGroupModal, openDeleteItemModal, closeModals} = useTaskChecklistModals();

  const [selectedTask, setSelectedTask] = useState<{taskGroupId: number; taskId: number} | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<{taskGroupId: number} | null>(null);
  const [itemType, setItemType] = useState<TTaskChecklistItemType | null>(null);

  const showGroupName = isGroupingEnabled;
  const iconName = group.locked ? 'lock' : 'v2-close-icon';

  const canEditOrDeleteItem = !group.locked;

  const groupNameStyles = cn(styles.checklistLineItem, 'p1 n800 text-weight-bold position relative', {
    [styles.editable]: canEditOrDeleteItem,
  });

  const lineItemStyles = cn(styles.checklistLineItem, 'position relative', {
    [styles.editable]: canEditOrDeleteItem,
  });

  // ######################
  // GROUP & TASK HANDLERS
  // ######################
  const handleGroupClick = useCallback(
    (taskGroupId: number) => (event: React.MouseEvent) => {
      event.stopPropagation();
      if (canEditOrDeleteItem) {
        setSelectedGroup({taskGroupId});
        openAddEditGroupModal();
      }
    },
    [canEditOrDeleteItem, openAddEditGroupModal]
  );

  const handleGroupDeleteClick = useCallback(
    (taskGroupId: number) => (event: React.MouseEvent) => {
      event.stopPropagation();
      if (canEditOrDeleteItem) {
        if (group.tasks.length === 0) {
          // Delete group immediately if it has no tasks
          deleteGroup({taskGroupId});
          return;
        }
        setSelectedGroup({taskGroupId});
        setItemType(TASK_CHECKLIST_ITEM_TYPES.GROUP);
        openDeleteItemModal();
      }
    },
    [canEditOrDeleteItem, group.tasks.length, deleteGroup, openDeleteItemModal]
  );

  const handleTaskClick = useCallback(
    (taskId: number) => {
      if (canEditOrDeleteItem) {
        editTask({taskGroupId: group.id, taskId});
      }
    },
    [canEditOrDeleteItem, group.id, editTask]
  );

  const handleTaskDeleteClick = useCallback(
    (taskId: number) => (event: React.MouseEvent) => {
      event.stopPropagation();
      if (canEditOrDeleteItem) {
        setSelectedTask({taskGroupId: group.id, taskId});
        setItemType(TASK_CHECKLIST_ITEM_TYPES.TASK);
        openDeleteItemModal();
      }
    },
    [canEditOrDeleteItem, group.id, openDeleteItemModal]
  );

  // ###############
  // STATE HANDLERS
  // ###############
  const resetState = useCallback(() => {
    setSelectedTask(null);
    setSelectedGroup(null);
    setItemType(null);
    closeModals();
  }, [closeModals]);

  // ################
  // MODAL CALLBACKS
  // ################
  const confirmTaskDelete = useCallback(() => {
    if (selectedTask) {
      deleteTask({...selectedTask, onSuccess: resetState});
    }
  }, [selectedTask, deleteTask, resetState]);

  const confirmGroupDelete = useCallback(() => {
    if (selectedGroup) {
      deleteGroup({taskGroupId: selectedGroup.taskGroupId, onSuccess: resetState});
    }
  }, [selectedGroup, deleteGroup, resetState]);

  const confirmGroupEdit = useCallback(
    (newGroupName: string) => {
      if (selectedGroup && newGroupName) {
        editGroup({
          taskGroupId: selectedGroup.taskGroupId,
          newGroupName,
          onSuccess: resetState,
        });
      }
    },
    [selectedGroup, editGroup, resetState]
  );

  /** Returns modal callback functions based on the item type being deleted */
  const getModalCallbackConfig = () => {
    const isUserDeletingTask = itemType === TASK_CHECKLIST_ITEM_TYPES.TASK;
    return {
      onCancelClick: resetState,
      onConfirmClick: isUserDeletingTask ? confirmTaskDelete : confirmGroupDelete,
    };
  };
  const {onConfirmClick, onCancelClick} = getModalCallbackConfig();

  // ######################
  // Drag and Drop
  // ######################
  const {mouseIn, onMouseEnter, onMouseLeave} = useMouseInState();
  const {groupItemRef, groupItemDragHandleRef, closestEdge} = useGroupItemDnDHandler({canEditOrDeleteItem, showGroupName, group});

  if (!group) return null;
  return (
    <>
      <div ref={groupItemRef} className="position relative">
        {showGroupName && (
          <div className={groupNameStyles} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {canEditOrDeleteItem && <DragHandle mouseIn={mouseIn} dragHandleRef={groupItemDragHandleRef} />}
            <div className="flex1" onClick={handleGroupClick(group.id)}>
              <span className={styles.underlineOnHover}>{group.group_name}</span>
              {group.tasks.length === 0 && <p className="caption blue700 marginTop-tiny">{NO_TASK_WARNING}</p>}
            </div>
            <div className="flex align-items-center">
              <div onClick={handleGroupDeleteClick(group.id)}>
                <Icon name={iconName} className={styles.checklistLineItemIcon} />
              </div>
            </div>
          </div>
        )}
        {group.tasks.map(task => {
          return (
            <TaskItem
              key={task.id}
              task={task}
              groupId={group.id}
              lineItemStyles={lineItemStyles}
              handleTaskClick={handleTaskClick}
              handleTaskDeleteClick={handleTaskDeleteClick}
              iconName={iconName}
              canEditOrDeleteItem={canEditOrDeleteItem}
            />
          );
        })}
        {closestEdge && <DropIndicator edge={closestEdge} />}
      </div>
      <DeleteItemModal isVisible={isDeleteItemModalVisible} onConfirmClick={onConfirmClick} onCancelClick={onCancelClick} itemType={itemType} />
      <AddEditGroupModal
        isVisible={isAddEditGroupModalVisible}
        onConfirmClick={confirmGroupEdit}
        onCancelClick={resetState}
        actionType={ADD_EDIT_GROUP_ACTION_TYPES.EDIT}
        initialGroupName={group.group_name}
      />
    </>
  );
};

export default TaskChecklistGroup;
