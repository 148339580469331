import React, {ComponentProps, useMemo} from 'react';
import DataTable from 'components/Elements/DataTable';
import {ACTION_MENU_COL_ID} from 'components/Elements/DataTable/dataTable.constants';
import {onColumnSizingChangeUtil, onColumnOrderChangeUtil, onColumnSortingChangeUtil} from 'components/Elements/DataTable/utils/columns.utils';
import dispatchSlice from 'features/Dispatch/dispatch.ducks';
import useUnassignedListRouteParams from 'features/Dispatch/hooks/useUnassignedListRouteParams';
import unassignedOrdersQuery from 'features/Dispatch/queries/query.dispatch.getUnassignedOrders';
import {UnassignedOrdersColumnKeys, TUnassignedOrder} from 'features/Dispatch/dispatch.types';
import {useSelector} from 'hooks/useAppSelector';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {generateColumnsData} from './unassignedTable.utils';

const UnassignedTable = () => {
  const dispatch = useAppDispatch();
  const {dispatchNavType, omitColumnKeys} = useUnassignedListRouteParams();
  const {columnState, columnSort, columnOrder, pagination: paginationState} = useSelector(dispatchSlice.selectors.getUnassignedListPageStateByKey(dispatchNavType)) || {};
  const columnData = generateColumnsData({omitKeys: omitColumnKeys});

  const {orders = []} = unassignedOrdersQuery();

  /**
   ********************************************************
   * Column Visibility
   *
   * Slightly different implementation to get away from a table error
   *   - "Column with id <blah> does not exist"
   * TODO: maybe refactor other tables to use this same pattern
   ********************************************************
   */
  const columnVisibility = useMemo(() => {
    return Object.keys(columnState).reduce((acc, key: string) => {
      const {visible} = columnState[key];
      acc[key] = visible;
      return acc;
    }, {} as {[key: string]: boolean});
  }, [columnState]);

  /**
   ********************************************************
   * Column Width Sizing
   ********************************************************
   */
  const columnsWidth = columnState
    ? Object.keys(columnState).reduce((acc, key: string) => {
        const {columnWidth} = columnState[key];
        if (columnWidth) {
          acc[key] = columnWidth;
        }
        return acc;
      }, {} as {[key: string]: number})
    : {};

  const onColumnSizingChange = onColumnSizingChangeUtil({
    currentColumnsWidthState: columnsWidth,
    onChangeCB: newColumnWidths => dispatch(dispatchSlice.actions.updateUnassignedListColumnWidths({navType: dispatchNavType, columnWidths: newColumnWidths})),
  });

  /**
   ********************************************************
   * Column Ordering via Drag and Drop
   ********************************************************
   */
  const tableColumnOrdering = useMemo(() => {
    const ordered = [...columnOrder];
    const leftoverColumns = Object.values(UnassignedOrdersColumnKeys).filter(colKey => !ordered.includes(colKey));
    return [...ordered, ...leftoverColumns].filter(Boolean);
  }, [columnOrder]);

  const onColumnOrderChange = onColumnOrderChangeUtil({
    onChangeCB: newColumnOrderArr => dispatch(dispatchSlice.actions.updateUnassigenedListColumnOrder({navType: dispatchNavType, columnOrder: newColumnOrderArr})),
  });

  /**
   ********************************************************
   * Sorting
   ********************************************************
   */
  const onSortingChange = onColumnSortingChangeUtil({
    currentColumnSortState: columnSort,
    onChangeCB: newSort => dispatch(dispatchSlice.actions.updateUnassignedListColumnSort({navType: dispatchNavType, columnSort: newSort})),
  });

  /**
   ********************************************************
   * Pagination
   ********************************************************
   */

  const {current_page, items_per_page} = paginationState;
  const tablePagination: ComponentProps<typeof DataTable>['pagination'] = {
    pageIndex: paginationState.current_page - 1,
    pageItemCount: paginationState.items_per_page,
    totalPages: paginationState.total_pages,
    onPaginationChange: ({pageIndex, itemCount}) => {
      dispatch(dispatchSlice.actions.updateUnassignedListPagination({navType: dispatchNavType, pagination: {current_page: pageIndex + 1, items_per_page: itemCount}}));
    },
  };

  return (
    <DataTable<TUnassignedOrder>
      columns={columnData}
      data={orders}
      state={{
        density: 'compact',
        pagination: {pageIndex: current_page - 1, pageSize: items_per_page},
        columnPinning: {left: [UnassignedOrdersColumnKeys.Priority, UnassignedOrdersColumnKeys.Id], right: [UnassignedOrdersColumnKeys.OfferStatus, ACTION_MENU_COL_ID]},
        sorting: columnSort,
        columnOrder: tableColumnOrdering,
        columnSizing: columnsWidth,
        columnVisibility,
      }}
      enableColumnResizing
      enableColumnDragging
      enableColumnOrdering
      enablePagination
      hasActionableRows
      manualPagination={false}
      onColumnOrderChange={onColumnOrderChange}
      onColumnSizingChange={onColumnSizingChange}
      onSortingChange={onSortingChange}
      pagination={tablePagination}
      showPaginator
    />
  );
};

export default UnassignedTable;
