import React from 'react';
import {useSelector} from 'react-redux';
import cn from 'classnames';
import {useParams} from 'react-router-dom';

/* Ducks */
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';

/* Hooks & Utils */
import {isPlainObjectWithKeys} from 'utils/object/isPlainObjectWithKeys';

/* Components */
import LineItemBreakdown from 'features/Booking/Parts/OrderLineItem/LineItemBreakdown';
import QANotifications from 'features/Booking/Parts/QA/QA.Notifications';

/* Types */
import {AdjAmountFormatted, Answer, QuestionsAPIByQuestion} from 'features/Questions/types';

/* Styles */
import styles from './addsku.styles.scss';

const RunningTotal = ({skuId: skuIdProp}: {skuId: string}) => {
  const {skuId = ''} = useParams<{skuId: string}>();
  const skuIdToUse = skuIdProp || skuId;
  const addSku = useSelector(mduProjectsSlice.selectors.getAddSkuState);

  const skusInContext = addSku.skus;
  const questionsInContext = addSku.questions;
  const {selectedSku} = addSku;
  const fullSkuById = skusInContext.entities[skuIdToUse] ?? {};
  const skuQuestions = questionsInContext.entities[skuIdToUse]?.questions ?? [];
  const skuMessages = fullSkuById?.skuMessagings ?? {};
  const notificationBullets = skuMessages.bullets;
  const notificationNote = skuMessages.banner;
  const notificationDisclaimer = skuMessages.disclaimer;
  const basePrice = fullSkuById.remoteSavings ? `$${(fullSkuById.remoteSavings + fullSkuById.basePrice) / 100}` : selectedSku.startsAtPrice;

  /*
    TODO: This is a dupe of QA Cart. Ideally we should have one component that does this, however there were errors encountered in QA.tsx's useEffect function
    when I tried to use a reusable component in QACart
    "Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all
    subscriptions and asynchronous tasks in a useEffect cleanup function."
  */
  return (
    <div className={cn(styles.breakdownContainer, 'marginTop-medium')}>
      <div className="overline-medium paddingBottom-small1 n700">Summary</div>
      <LineItemBreakdown name="Base Price" amount={basePrice} />
      {Object.keys(selectedSku.questions).map((q: string) => {
        const selectedSkuQuestions = selectedSku.questions[q];
        const usersAnswersByQuestion = Array.isArray(selectedSkuQuestions) ? selectedSkuQuestions : [].concat(selectedSkuQuestions?.id ?? []);

        return usersAnswersByQuestion.map(userAnswer => {
          const userAnswersId = userAnswer?.id ?? userAnswer;
          return (skuQuestions ?? []).map((questionBySku: QuestionsAPIByQuestion) => {
            // eslint-disable-next-line
            if (q == questionBySku.id) {
              return questionBySku.answers.map((answer: Answer) => {
                // eslint-disable-next-line
                if (answer.id == userAnswersId && answer.adjAmountFormatted) {
                  const answerAdjAmountFormatted = answer.adjAmountFormatted;
                  /**
                   * There seems to be an issue during a rails release in which 'formatted', in the object
                   * proper is null. So, the previous operation returned the object as conditional fallback,
                   * thus creating an react encoder error. Lets now see if its an object and get at the value,
                   * else note there is an "error" in retrieval. The "total" value should stilll be good.
                   *
                   * The real issue is that 'adjAmountFormatted' seems to change 'type' from the BE.
                   */
                  const amount = isPlainObjectWithKeys(answerAdjAmountFormatted)
                    ? (answerAdjAmountFormatted as AdjAmountFormatted)?.formatted || 'Error retrieving price'
                    : (answerAdjAmountFormatted as string);

                  return <LineItemBreakdown key={answer.id} name={answer.text} amount={amount} className="marginTop-small" />;
                }
                return null;
              });
            }
            return null;
          });
        });
      })}
      <LineItemBreakdown boldName name="Total" amount={selectedSku.totalPrice} boldAmount className="paddingTop-small marginTop-small border-top-2" />
      <QANotifications title="IMPORTANT" notifications={notificationBullets} />
      <QANotifications notifications={[notificationNote]} />
      <QANotifications title="Disclaimer" notifications={[notificationDisclaimer]} />
    </div>
  );
};

export default RunningTotal;
