import React from 'react';
import {noop} from 'utils/event';
import PageHeader from 'components/Elements/PageHeader';
import {ProjectDetailsPageHeaderTypes} from './CurrentProject.Details.types';

/** Wrapper around PageHeader component that controls button language and icon */
const ProjectDetailsPageHeader = ({onClick = noop, type = ProjectDetailsPageHeaderTypes.Save}) => {
  const getButtonConfig = () => {
    if (type === ProjectDetailsPageHeaderTypes.Save) return {label: 'Save', iconName: 'save'};
    return {label: 'Edit', iconName: 'edit-plain'};
  };
  const HeaderRightContent = <PageHeader.Button onClick={onClick} {...getButtonConfig()} />;

  return <PageHeader title="Project Details" {...getButtonConfig()} HeaderRightContent={HeaderRightContent} />;
};

export default ProjectDetailsPageHeader;
