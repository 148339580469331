import React from 'react';
import PageStatusBar from 'components/Elements/PageStatusBar';
import {useCurrentProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/CurrentProject.hooks';
import mduProjectsSlice from 'features/MultiDwellingUnits/MDU.ducks';
import {MDUStatuses, TSingleActionTypes, ProjectDetails} from 'features/MultiDwellingUnits/MDU.types';
import {useAppDispatch} from 'hooks/useAppDispatch';

export const HEADER_TEXT = 'Project needs approval';

const NeedsApprovalStatusBar = () => {
  const dispatch = useAppDispatch();
  const currentProject: ProjectDetails = useCurrentProject();

  const shouldRenderComponent = currentProject?.status === MDUStatuses.NEEDS_APPROVAL;

  const onCTAClick = () => {
    dispatch(mduProjectsSlice.actions.setActionItemModalSlide({entity: currentProject, modalKey: TSingleActionTypes.APPROVE, modalType: 'Project'}));
  };

  return shouldRenderComponent ? <PageStatusBar headerText={HEADER_TEXT} ctaButtonLabel="Approve Project" ctaButtonOnClick={onCTAClick} /> : null;
};

export default NeedsApprovalStatusBar;
