import qs from 'qs';
import {Dispatch} from '@reduxjs/toolkit';

import {notifications} from 'components/Notification/notification.ducks';

import unWrapError from 'utils/request/requestError';
import {logger} from 'utils/logger';

export const showErrorToast = (reduxDispatch: Dispatch<any>) => (error: any) => {
  const unwrappedError = unWrapError(error, {includeObjectKeys: true});

  // Need to manually call/remove the notification since react-query is not hooked up to our Redux middleware
  reduxDispatch(notifications.actions.notificationApiError({source: unwrappedError || error.statusText}));
  reduxDispatch(notifications.actions.notificationApiPending());
};

export const logResponseError = (errorText: string) => (response: any) => {
  logger(errorText)(response as Error);
};

/**
 * Handle response error
 * Simple helper function to handle response errors
 * @param response
 * @param api
 */
export const handleResponseError = <T extends {err: string; error: string; status: string | number}>(response: T) => {
  /* spreading causes a ts iterator error. */
  const resp = Array.isArray(response) ? response : [response];
  resp.forEach(r => {
    if (r.error || r.err || r.status === 'error') {
      const e = r.err || r.error;
      throw new Error(JSON.stringify(e ?? {}));
    }
  });
};

const filterFn = (_: any, value: any) => {
  // still want 0 and false to get through
  return value === null || value === undefined || value === '' ? undefined : value;
};

export const stringifyQueryParams = (params: Object) => {
  return qs.stringify(params, {arrayFormat: 'brackets', encode: false, skipNulls: true, filter: filterFn});
};
