import React, {useMemo} from 'react';
import {TaskTypes} from 'types/tasks.types';
import {IBaseTask} from '../../serviceTasksList.types';
import NoInfoCalloutBox from './NoInfoCalloutBox';
import BlockWrapper from './BlockWrapper';
import styles from './serviceTask.styles.scss';

/**
 * For task types confirm, binary, single_select, multi_select, and text, show the answer.
 *
 * Note:
 * `complete` is not used for text, binary, single_select, and multi_select task types since
 * a task can be optional.
 */
const DetailsBlock = ({task, dataTestId = 'DetailsBlock'}: IBaseTask) => {
  const {answer, task_type} = task;

  const Details = useMemo(() => {
    switch (task_type) {
      case TaskTypes.Confirm:
        return answer?.complete ? 'Confirmed' : null;
      case TaskTypes.Text:
        return answer?.response;
      case TaskTypes.Binary:
      case TaskTypes.SingleSelect: {
        const options = answer?.options || [];
        const selectedOption = options[0];
        return selectedOption?.description;
      }
      case TaskTypes.MultiSelect: {
        const options = answer?.options || [];
        if (options.length > 0) {
          return (
            <ul className={styles.list}>
              {options.map(option => (
                <li key={option.id}>{option.description}</li>
              ))}
            </ul>
          );
        }
        return null;
      }
      default:
        return null;
    }
  }, [answer?.complete, answer?.options, answer?.response, task_type]);

  return Details ? (
    <BlockWrapper title="Details" dataTestId={dataTestId}>
      <span className="p2 n900">{Details}</span>
    </BlockWrapper>
  ) : (
    <NoInfoCalloutBox />
  );
};

export default DetailsBlock;
