import differenceWith from 'lodash/differenceWith';
import toPairs from 'lodash/toPairs';
import isEqualWith from 'lodash/isEqualWith';
import isEqual from 'lodash/isEqual';
import fromPairs from 'lodash/fromPairs';

/**
 * @description Custom isEqualWith function to treat null and undefined as equal within an array
 *              Note: Not common, but for the use case in issues feature, [null], [undefined] is seen
 *                    as different. We need to remedy. We could just normalize the data coming in. But
 *                    can't be sure of any downstream effects. So, if starting from scratch, we wouldn't
 *                    need this, we'd just call getDifferenceBetweenTwoObjects and be done with it.
 * @param a
 * @param b
 */
type TAnyObject = {[key: string]: any};

export const equateNullUndefinedArray = (a: TAnyObject, b: TAnyObject) => {
  if (Array.isArray(a) && Array.isArray(b) && a.length === 1 && b.length === 1) {
    const aValue = a[0];
    const bValue = b[0];
    if ((aValue === null || aValue === undefined) && (bValue === null || bValue === undefined)) {
      return true; // Treat them as equal
    }
  }
  return undefined;
};

export const getDifferenceBetweenTwoObjects = <T extends TAnyObject = TAnyObject>(object1: T, object2: T, comparator: (a: TAnyObject, b: TAnyObject) => boolean | undefined = isEqual) => {
  const diff = fromPairs(differenceWith(toPairs(object1), toPairs(object2 || {}), (a, b) => isEqualWith(a, b, comparator)));

  return diff;
};
