import cn from 'classnames';
import {ProjectDetails} from 'features/MultiDwellingUnits/MDU.types';
import styles from './paymentAdjustment.styles.scss';

// Blue for positive dollar amounts and red for negative
export const getTotalPayoutStyles = (project = {totalTechPayoutAdjustment: 0} as Partial<ProjectDetails>) =>
  cn(styles.totalPayoutAmount, {
    [styles.increase]: (project.totalTechPayoutAdjustment || 0) >= 0,
    [styles.decrease]: (project.totalTechPayoutAdjustment || 0) < 0,
  });
