import * as React from 'react';
import {createPortal} from 'react-dom';
import cn from 'classnames';
import {Icon} from 'ht-styleguide';
import {$isAutoLinkNode, $isLinkNode, TOGGLE_LINK_COMMAND} from '@lexical/link';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$findMatchingParent, mergeRegister} from '@lexical/utils';
import {
  $getSelection,
  $isRangeSelection,
  BaseSelection,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  GridSelection,
  KEY_ESCAPE_COMMAND,
  LexicalEditor,
  NodeSelection,
  RangeSelection,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import {Dispatch, useCallback, useEffect, useRef, useState} from 'react';

/* Utils */
import {shortenStringByNumber} from 'utils/string';
import {getSelectedNode} from '../utils/getSelectedNode';
import {setFloatingElemPositionForLinkEditor} from '../utils/setFloatingElemPositionForLinkEditor';
import {sanitizeUrl, validateUrl} from '../utils/url';

import styles from './plugin.styles.scss';

type TFloatingLinkEditor = {
  editor: LexicalEditor;
  isLink: boolean;
  setIsLink: Dispatch<boolean>;
  anchorElem: HTMLElement;
};

/**
 * This is the floating link view for both preview and edit mode.
 *    1. Preview
 *        a. edit icon
 *        b. unlink icon
 *    2.Edit
 *        b. save link
 *        b. cancel link
 *
 * @param editor
 * @param isLink
 * @param setIsLink
 * @param anchorElem
 * @constructor
 */
function FloatingLinkEditor({editor, isLink, setIsLink, anchorElem}: TFloatingLinkEditor) {
  const editorRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [linkUrl, setLinkUrl] = useState('');
  const [error, setIsError] = useState(false);
  const [editedLinkUrl, setEditedLinkUrl] = useState('');
  const [isEditMode, setEditMode] = useState(false);
  const [lastSelection, setLastSelection] = useState<BaseSelection | RangeSelection | GridSelection | NodeSelection | null>(null);

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl('');
      }
    }
    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();

    if (selection !== null && nativeSelection !== null && rootElement !== null && rootElement.contains(nativeSelection.anchorNode) && editor.isEditable()) {
      const domRect: DOMRect | undefined = nativeSelection.focusNode?.parentElement?.getBoundingClientRect();
      if (domRect) {
        domRect.y += 40;
        setFloatingElemPositionForLinkEditor(domRect, editorElem, anchorElem);
      }
      // @ts-ignore
      setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== 'link-input') {
      if (rootElement !== null) {
        setFloatingElemPositionForLinkEditor(null, editorElem, anchorElem);
      }
      setLastSelection(null);
      setEditMode(false);
      setLinkUrl('');
    }
  }, [anchorElem, editor]);

  useEffect(() => {
    const scrollerElem = anchorElem.parentElement;

    const update = () => {
      editor.getEditorState().read(() => {
        updateLinkEditor();
      });
    };

    window.addEventListener('resize', update);

    if (scrollerElem) {
      scrollerElem.addEventListener('scroll', update);
    }

    return () => {
      window.removeEventListener('resize', update);

      if (scrollerElem) {
        scrollerElem.removeEventListener('scroll', update);
      }
    };
  }, [anchorElem.parentElement, editor, updateLinkEditor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({editorState}) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_ESCAPE_COMMAND,
        () => {
          if (isLink) {
            setIsLink(false);
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_HIGH
      )
    );
  }, [editor, updateLinkEditor, setIsLink, isLink]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode]);

  useEffect(() => {
    if (isLink) {
      setIsError(false);
    }
  }, [isLink]);

  const monitorInputInteraction = (event: React.KeyboardEvent<HTMLInputElement>) => {
    /* Lets ignore this for now, see how it 'feels' */
    if (event.key === 'Enter') {
      event.preventDefault();
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleLinkSubmission();
    } else if (event.key === 'Escape' || event.key === 'Enter') {
      event.preventDefault();
      setEditMode(false);
    }
  };

  const handleLinkSubmission = () => {
    if (!validateUrl(editedLinkUrl)) {
      setIsError(true);
      return;
    }

    if (lastSelection !== null) {
      if (linkUrl !== '') {
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl(editedLinkUrl));
      }
      setEditMode(false);
    }
  };

  const inputClass = cn(styles.link_input, 'link-input', {
    [styles.link_input_error]: error,
    [styles.link_input_clean]: !error,
  });

  return (
    <div ref={editorRef} className={cn(styles.link_editor, 'link-editor')}>
      {/* eslint-disable-next-line no-nested-ternary */}
      {!isLink ? null : isEditMode ? (
        <div className={cn(styles.link_view, 'link-view')}>
          <div className="paddingRight-small">
            <input
              ref={inputRef}
              className={inputClass}
              value={editedLinkUrl}
              onChange={event => {
                const url = event.target.value.trim();
                setEditedLinkUrl(url);
              }}
              onKeyDown={event => {
                monitorInputInteraction(event);
              }}
            />
          </div>
          <div>
            <Icon
              name="v2-close-icon"
              className={cn(styles.link_cancel, 'cursorPointer paddingRight-tiny1 link-cancel')}
              role="button"
              tabIndex={0}
              onMouseDown={event => event.preventDefault()}
              onClick={() => {
                setEditMode(false);
              }}
            />

            <Icon
              name="checkmark-plain"
              className={cn(styles.link_confirm, 'cursorPointer link-confirm')}
              role="button"
              tabIndex={0}
              onMouseDown={event => event.preventDefault()}
              onClick={handleLinkSubmission}
            />
          </div>
        </div>
      ) : (
        <div className={cn(styles.link_view, 'link-view')}>
          <div>
            {validateUrl(linkUrl) ? (
              <a href={sanitizeUrl(linkUrl)} target="_blank" rel="noopener noreferrer">
                {shortenStringByNumber(linkUrl, 22, '...')}
              </a>
            ) : (
              <span className="paddingLeft-tiny">{shortenStringByNumber(linkUrl, 22, '...')}</span>
            )}
          </div>
          <div>
            <Icon
              name="edit-plain"
              className={cn(styles.link_edit, 'cursorPointer paddingRight-tiny1 link-edit')}
              role="button"
              tabIndex={0}
              onMouseDown={event => event.preventDefault()}
              onClick={() => {
                setEditedLinkUrl(linkUrl);
                setEditMode(true);
              }}
            />
            <Icon
              name="unlink"
              className={cn(styles.link_trash, 'cursorPointer link-trash')}
              role="button"
              tabIndex={0}
              onMouseDown={event => event.preventDefault()}
              onClick={() => {
                editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function useFloatingLinkEditorToolbar(editor: LexicalEditor, anchorElem: HTMLElement) {
  const [activeEditor, setActiveEditor] = useState(editor);
  const [isLink, setIsLink] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const linkParent = $findMatchingParent(node, $isLinkNode);
      const autoLinkParent = $findMatchingParent(node, $isAutoLinkNode);

      // We don't want this menu to open for auto links.
      if (linkParent != null && autoLinkParent == null) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, []);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({editorState}) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          setActiveEditor(newEditor);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [editor, updateToolbar]);

  return createPortal(<FloatingLinkEditor editor={activeEditor} isLink={isLink} anchorElem={anchorElem} setIsLink={setIsLink} />, anchorElem);
}

export default function FloatingLinkEditorPlugin({anchorElem = document.body}: {anchorElem?: HTMLElement}) {
  const [editor] = useLexicalComposerContext();
  return useFloatingLinkEditorToolbar(editor, anchorElem);
}
