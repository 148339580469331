import React from 'react';
import cn from 'classnames';
import {ReadOnlyFieldProps, ReadOnlyPanelProps} from './CurrentProject.Details.types';
import {Avatar, CalloutBox, CalloutBoxThemes, ELEMENT_SIZE, Icon, Panel} from 'ht-styleguide'; // eslint-disable-line import/order
import ProjectDetailsSectionHeader from './CurrentProject.Details.SectionHeader';
import styles from './currentProjectDetails.styles.scss';

export const ReadOnlyField = ({headerText = '', valueText = '', isAvatarField, missingValueText, valueIconName}: ReadOnlyFieldProps) => {
  const showMissingValueText = missingValueText && !valueText;
  const showAvatar = isAvatarField && valueText;
  return (
    <>
      <p className="overline-small n700 marginBottom-tiny">{headerText}</p>
      <div className="flex align-items-center">
        {showAvatar && <Avatar size={ELEMENT_SIZE.XS} name={valueText} className="marginRight-tiny1" />}
        <p className={cn('p1 n900', {n300: showMissingValueText})}>
          {valueIconName && <Icon name={valueIconName} className={cn('marginRight-tiny1 n700', styles.valueIcon)} />}
          {showMissingValueText ? missingValueText : valueText}
        </p>
      </div>
    </>
  );
};

export const MISSING_FIELDS_TEXT = 'Please fill the required fields';

const ReadOnlySection = ({children, headerText, hasRequiredFields}: ReadOnlyPanelProps) => {
  return (
    <Panel noShadow largeBorderRadius className={cn(styles.panel, 'marginTop-small1 paddingY-medium paddingX-small2')}>
      <ProjectDetailsSectionHeader headerText={headerText} />
      {hasRequiredFields && <CalloutBox text={MISSING_FIELDS_TEXT} textStyles="text-weight-med" className="marginTop-small2" theme={CalloutBoxThemes.PRIMARY} />}
      {children}
    </Panel>
  );
};

export default ReadOnlySection;
