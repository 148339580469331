import React, {ChangeEvent, ComponentProps, useMemo, useState, useCallback, useEffect, useRef} from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import DataTable from 'components/Elements/DataTable';
import {TDataTableColumnDef} from 'components/Elements/DataTable/dataTable.types';
import {useCustomRowSelection} from 'components/Elements/DataTable/dataTable.hooks';
import DetailPanel from 'components/Elements/DataTable/DetailPanel';
import {customRowSelectColumn} from 'components/Elements/DataTable/utils/columns.utils';
import {CheckboxCellComponent} from 'components/Elements/DataTable/CellComponent';
import {StoreCreditsSideSheetTypes, StoreCreditsQueryParams} from 'features/StoreCredits/storeCredits.types';
import {useSearchParams} from 'hooks/useSearchParam';
import usePrevious from 'hooks/usePrevious';
import useSkusFilterQuery from 'queries/skus/query.skus.filter';
import flatten from 'utils/object/flatten';
import {CreateCreditSideSheetFormFields, TCreditItemAttribute, TNormalizedItem, TSearchTableNormalizedItem, TUseFormikReturn} from './createCreditSideSheet.types';
import {searchTableDataByValue} from './createCreditSideSheet.utils';

export const useCreateCreditSideSheetControl = () => {
  const {deleteParam, setParam, getParam} = useSearchParams({
    [StoreCreditsQueryParams.StoreCreditMode]: StoreCreditsSideSheetTypes.create,
  });

  const createCreditSideSheetIsOpen = getParam(StoreCreditsQueryParams.StoreCreditMode) === StoreCreditsSideSheetTypes.create;

  return {
    closeSideSheet: deleteParam,
    openSideSheet: setParam,
    sideSheetIsOpen: createCreditSideSheetIsOpen,
  };
};

/**
 *************************************************************
 * useCreateCreditTable
 *************************************************************
 */
type TUseCreateCreditTableParams = {
  partnerId: number | null | undefined;
  formik: TUseFormikReturn;
};

const SKU_CATEGORIES_KEY = 'sku_categories_';
const MEMBERSHIPS_KEY = 'memberships_';

export const useCreateCreditTable = ({partnerId, formik}: TUseCreateCreditTableParams) => {
  // Gather the skus/plans needed for the table - atm, it's using the same api on the booking tool
  const {data} = useSkusFilterQuery({partnerId});
  /**
   * Helpers to control which rows are selected and which states (checked/indeterminate) to show
   *
   * selectedRows should look like this:
   * {
   *   memberships_1: {
   *     1: true,
   *     2: false,
   *   },
   *   sku_categories_1: {
   *     1: true,
   *     2: false,
   *   }
   * }
   */
  const {determineCheckboxState, onSelectChange, selectedRows, setSelectedRows} = useCustomRowSelection();

  /**
   * Flatten the query data into an array where sku_categories and memberships are the parent items.
   * This data is fed into the main table.
   *
   * [{
   *   id: 'memberships_1',
   *   name: 'Membership Name',
   *   items: [{
   *     id: 1,
   *     name: 'Membership Plan Name'
   *   }]
   * }, {
   *   id: 'sku_categories_1',
   *   name: 'Sku Category Name',
   *   items: [{
   *     id: 1,
   *     name: 'Sku Name'
   *   }]
   * }]
   */
  const normalizedItemsData = useMemo(() => {
    if (!data || isEmpty(data)) {
      return [];
    }

    // Normalize membership data
    const membershipItems = data.memberships.reduce((acc, membership) => {
      if (membership.plans.length) {
        acc.push({
          id: `${MEMBERSHIPS_KEY}${membership.id}`,
          name: membership.name,
          items: membership.plans.map(({id, name}) => ({
            id,
            name,
          })),
        });
      }
      return acc;
    }, [] as Array<TNormalizedItem>);

    // Normalize sku_categories data
    const skuItems = data.sku_categories
      .filter(skuCategory => `${skuCategory.id}` !== 'most-popular')
      .reduce((acc, skuCategory) => {
        if (skuCategory.services.length) {
          acc.push({
            id: `${SKU_CATEGORIES_KEY}${skuCategory.id}`,
            name: skuCategory.name,
            items: skuCategory.services.map(({id, name}) => ({
              id,
              name,
            })),
          });
        }
        return acc;
      }, [] as Array<TNormalizedItem>);

    return [...skuItems, ...membershipItems];
  }, [data]);

  /**
   * When the normalizedItemsData changes, we need to seed/update selectedRows with the new data.
   * `selectedRows` is an object that keeps track of which rows are selected.
   */
  const previousNormalizedItemsData = usePrevious(normalizedItemsData);
  useEffect(() => {
    if (normalizedItemsData !== previousNormalizedItemsData) {
      const newSelectedRows = normalizedItemsData.reduce((acc, parentItem) => {
        const {id} = parentItem;
        let preseedValue: boolean | Record<string, boolean> = false;

        if (parentItem.items) {
          preseedValue = parentItem.items.reduce((childAcc, childItem) => {
            const childObj = selectedRows?.[`${parentItem.id}`];
            const childPreseedValue = typeof childObj === 'object' ? childObj?.[`${childItem.id}`] : childObj;

            return {
              ...childAcc,
              [childItem.id]: childPreseedValue || false,
            };
          }, {} as Record<string, boolean>);
        }
        return {
          ...acc,
          [id]: preseedValue,
        };
      }, {});

      setSelectedRows(newSelectedRows);
    }
  }, [normalizedItemsData, previousNormalizedItemsData, selectedRows, setSelectedRows]);

  // Define columns
  const mainColumns = useMemo(() => {
    const columns: Array<TDataTableColumnDef<TNormalizedItem>> = [
      {
        ...customRowSelectColumn(),
        Cell: ({table, row}) => {
          const id = `${row.original.id}`;
          return <CheckboxCellComponent table={table} value={id} {...determineCheckboxState(id)} onCheckboxChange={onSelectChange(id)} label="" />;
        },
      },
      {
        accessorKey: 'name',
        header: '',
        columnWidthMode: 'collapse',
        columnWidthSizeCollapse: 400, // eyeballing all the columns widths
        muiTableBodyCellProps: {
          className: 'font-weight-medium',
        },
      },
    ];

    return columns;
  }, [determineCheckboxState, onSelectChange]);

  // Render the nested table for each row
  const renderDetailPanel: ComponentProps<typeof DataTable<TNormalizedItem>>['renderDetailPanel'] = ({row}) => {
    const {id: parentId} = row.original;
    const subRowColumns: Array<TDataTableColumnDef<TNormalizedItem['items'][number]>> = [
      {
        ...customRowSelectColumn(),
        Cell: ({row: cRow, table}) => {
          const {id} = cRow.original;
          const path = `${parentId}.${id}`;
          return <CheckboxCellComponent table={table} value={id} {...determineCheckboxState(path)} onCheckboxChange={onSelectChange(path)} label="" />;
        },
      },
      {
        header: 'Name',
        accessorKey: 'name',
        columnWidthMode: 'collapse',
        columnWidthSizeCollapse: 335,
      },
      {
        header: 'ID',
        accessorKey: 'id',
        columnWidthMode: 'collapse',
        columnWidthSizeCollapse: 70,
      },
    ];

    return (
      <DetailPanel>
        <DataTable data={row.original.items} columns={subRowColumns} enableTableHead={false} transparentBG state={{density: 'compact'}} />
      </DetailPanel>
    );
  };

  /**
   * Get the selected rows for either sku_categories or memberships
   */
  const getEntityRows = useCallback(
    (type: typeof SKU_CATEGORIES_KEY | typeof MEMBERSHIPS_KEY) => {
      if (selectedRows) {
        return Object.keys(selectedRows).reduce((acc, key) => {
          if (key.includes(type)) {
            acc[key] = selectedRows[key];
          }
          return acc;
        }, {} as Record<string, boolean | Record<string, boolean>>);
      }
      return {};
    },
    [selectedRows]
  );

  /**
   * If the user selects `All Services` checkbox, then update the selected rows for all services
   */
  const onAllServicesChange = (_: any, onChangeEvent: ChangeEvent<HTMLInputElement>) => {
    if (selectedRows) {
      const onlyServicesRows = getEntityRows(SKU_CATEGORIES_KEY);
      const checkedState = onChangeEvent.target.checked;
      const flattenedRows = flatten(onlyServicesRows);
      Object.keys(flattenedRows).forEach(key => {
        set(onlyServicesRows, key, checkedState);
      });
      setSelectedRows({...selectedRows, ...onlyServicesRows});
    }
  };

  /**
   * Determine the checked/indeterminate state for the `All Services` checkbox
   */
  const allServicesCheckboxState = useMemo(() => {
    if (selectedRows) {
      const onlyServicesRows = getEntityRows(SKU_CATEGORIES_KEY);
      return {
        visible: !isEmpty(onlyServicesRows),
        props: determineCheckboxState(undefined, onlyServicesRows),
      };
    }
    return {
      visible: true,
      props: {
        checked: false,
      },
    };
  }, [selectedRows, getEntityRows, determineCheckboxState]);

  /**
   * If the user selects `All Memberships` checkbox, then update the selected rows for all memberships
   */
  const onAllMembershipsChange = (_: any, onChangeEvent: ChangeEvent<HTMLInputElement>) => {
    if (selectedRows) {
      const onlyMembershipsRows = getEntityRows(MEMBERSHIPS_KEY);
      const checkedState = onChangeEvent.target.checked;
      const flattenedRows = flatten(onlyMembershipsRows);
      Object.keys(flattenedRows).forEach(key => {
        set(onlyMembershipsRows, key, checkedState);
      });
      setSelectedRows({...selectedRows, ...onlyMembershipsRows});
    }
  };

  /**
   * Determine the checked/indeterminate state for the `All Memberships` checkbox
   */
  const allMembershipsCheckboxState = useMemo(() => {
    if (selectedRows) {
      const onlyMembershipsRows = getEntityRows(MEMBERSHIPS_KEY);
      return {
        visible: !isEmpty(onlyMembershipsRows),
        props: determineCheckboxState(undefined, onlyMembershipsRows),
      };
    }
    return {
      visible: true,
      props: {
        checked: false,
      },
    };
  }, [selectedRows, getEntityRows, determineCheckboxState]);

  /**
   * Clear all selected rows
   */
  const onClearAllRows = () => {
    // Iterate through selectedRows and set all values to false
    if (selectedRows) {
      const newSelectedRows = Object.keys(selectedRows).reduce((parentAcc, key) => {
        const value = selectedRows![key];
        if (typeof value === 'object') {
          const newSubRow = Object.keys(value).reduce((childAcc, subKey) => {
            return {
              ...childAcc,
              [subKey]: false,
            };
          }, {});
          parentAcc[key] = newSubRow;
        } else {
          parentAcc[key] = false;
        }
        return parentAcc;
      }, {} as Record<string, boolean | Record<string, boolean>>);

      setSelectedRows(newSelectedRows);
    }
  };

  /**
   * Upon changes in `selectedRows`, update formik values for skus, plans, sku_categories, and plan_products.
   */
  const {setFieldValue} = formik; // using formik.setFieldValue in the hook will cause an infinite loop
  const previousRef = useRef({...selectedRows});
  useEffect(() => {
    // for catching a weird scenario
    if (isEmpty(selectedRows) && isEmpty(previousRef.current)) {
      return;
    }

    if (selectedRows && !isEqual(selectedRows, previousRef.current)) {
      const creditItemAttributes: Array<TCreditItemAttribute> = [];

      normalizedItemsData.forEach(parentItem => {
        const selectedRowParent = selectedRows[parentItem.id];

        const setAttributeForParent = ({parentItemAttr}: {parentItemAttr: TNormalizedItem}) => {
          const isSkuCategory = `${parentItemAttr.id}`.includes(SKU_CATEGORIES_KEY);
          creditItemAttributes.push({
            item_type: isSkuCategory ? 'SkuCategory' : 'PlanProduct',
            item_name: parentItemAttr.name,
            item_category_name: null,
            item_id: +`${parentItemAttr.id}`.replace(isSkuCategory ? SKU_CATEGORIES_KEY : MEMBERSHIPS_KEY, ''),
          });
        };

        if (selectedRowParent) {
          if (typeof selectedRowParent === 'object') {
            // Could've used an array of the childrenRow values, but since `normalizedItemsData` and `selectedRows` are mapped 1-to-1,
            // we can just iterate through the parentItem.items and check if they're all selected.
            let childrenAllSelected = true;
            parentItem.items.forEach(childItem => {
              if (selectedRowParent[childItem.id]) {
                creditItemAttributes.push({
                  item_type: `${parentItem.id}`.includes(SKU_CATEGORIES_KEY) ? 'Sku' : 'Plan',
                  item_name: childItem.name,
                  item_category_name: parentItem.name,
                  item_id: childItem.id,
                });
              } else {
                childrenAllSelected = false;
              }
            });
            if (childrenAllSelected) {
              setAttributeForParent({parentItemAttr: parentItem});
            }
          } else {
            setAttributeForParent({parentItemAttr: parentItem});
          }
        }
      });

      setFieldValue(CreateCreditSideSheetFormFields.CreditItemsAttributes, creditItemAttributes);

      if (creditItemAttributes.length) {
        setFieldValue('skuOrPlanSelected', true);
      } else {
        setFieldValue('skuOrPlanSelected', false);
      }
    }
  }, [getEntityRows, normalizedItemsData, selectedRows, setFieldValue]);

  /**
   *************************************************************
   * Search Sku/Membership Table
   *************************************************************
   */
  const [searchValue, setSearchValue] = useState('');
  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);

  /**
   * Create a flatter data set for search since the search table is only 1 level deep.
   *
   * [{
   *  id: 1,
   *  name: 'Membership Plan Name',
   *  parentId: 'memberships_1'
   * }]
   */
  const normalizedSearchTableData = useMemo(() => {
    return normalizedItemsData.reduce((acc, parentItem) => {
      if (parentItem.items) {
        parentItem.items.forEach(item => {
          acc.push({...item, parentId: parentItem.id});
        });
      }
      return acc;
    }, [] as Array<TSearchTableNormalizedItem>);
  }, [normalizedItemsData]);

  /**
   * Filter the search table data based on the search value.
   * This is fed directly into the search table.
   */
  const searchTableDataResult = useMemo(() => {
    return searchValue ? searchTableDataByValue(normalizedSearchTableData, searchValue) : [];
  }, [normalizedSearchTableData, searchValue]);

  const searchTableColumns: Array<TDataTableColumnDef<TSearchTableNormalizedItem>> = [
    {
      ...customRowSelectColumn(),
      Cell: ({table, row}) => {
        const id = `${row.original.parentId}.${row.original.id}`;
        return <CheckboxCellComponent table={table} value={id} {...determineCheckboxState(id)} onCheckboxChange={onSelectChange(id)} label="" />;
      },
    },
    {
      accessorKey: 'name',
      columnWidthMode: 'collapse',
      columnWidthSizeCollapse: 335,
      header: '',
    },
    {
      accessorKey: 'id',
      columnWidthMode: 'collapse',
      header: '',
    },
  ];

  // Icon/Onclick
  const searchInputProps = {
    iconName: searchValue ? 'v2-close-icon' : 'search',
    ...(searchValue ? {iconOnClick: () => setSearchValue('')} : {}),
  };

  return {
    normalizedItemsData,
    mainColumns,
    renderDetailPanel,
    onAllServicesChange,
    allServicesCheckboxState,
    onAllMembershipsChange,
    allMembershipsCheckboxState,
    onClearAllRows,
    searchValue,
    onSearchChange,
    searchTableDataResult,
    searchTableColumns,
    searchInputProps,
  };
};
