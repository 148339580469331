import {useSearchParams as _useSearchParams} from 'react-router-dom';
import {noop} from 'utils/event';

/**
 * This just hands back easy functions (hook for when location changes) to manipulate for situations in which you want to
 * open/close or do something based on URL w/o messing with state. An example would be for
 * deep linking for slideSheets or modals, if the url structure is not condusive to a directory format.
 *
 * Can't Do: /projects/1/issues/1
 * Can do: /projects/1/issues?issue=create
 *         /projects/1/create-bulk?issue=create
 *         /projects/all/templates?issue=create
 *
 * @param paramKey
 * @param paramValue
 *
 * @returns {deleteParam, setParam, getParam, queryParams}
 */

type TUseSearchParams = {[key: string]: string};
export function useSearchParams(params?: TUseSearchParams) {
  const [searchParams, setSearchParams] = _useSearchParams();

  const deleteParam = ({cb = noop, keys = null} = {}) => {
    /* Get the keys to remove. Either an array of specific keys, or object params keys */
    const deleteParams = keys || Object.keys(params || {});

    deleteParams.forEach(key => {
      const k = key;

      if (searchParams.has(k)) {
        searchParams.delete(k);
      }
    });

    /* Update query without refreshing the page */
    setSearchParams(searchParams);

    cb?.();
  };

  /**
   * Delete All Params
   *
   * @param cb
   * @param onlySeed: If true, only delete the params that were seeded in the hook. If false, delete all params.
   */
  const deleteAllParams = ({cb = noop, onlySeed = false} = {}) => {
    [...searchParams.keys()].forEach(key => {
      if (onlySeed && !Object.keys(params || {}).includes(key)) {
        return;
      }
      searchParams.delete(key);
    });

    /* Update query without refreshing the page */
    setSearchParams(searchParams);

    cb?.();
  };

  /**
   * Set Param
   *
   * This updates the query params and replaces the url with the new params.
   *
   * @param cb
   * @param kv
   */
  const setParam = ({cb, kv} = {cb: noop, kv: null}) => {
    const paramsToSet = kv || params || {};

    setSearchParams(currentParams => {
      Object.keys(paramsToSet).forEach(key => {
        const k = key;
        currentParams.set(k, paramsToSet[key as keyof typeof params]);
      });
      return currentParams;
    });

    cb?.();
  };

  /**
   * Get Param
   *
   * Returns the value of the param key. If no key is passed, it will return empty string.
   *
   * @param key
   */
  const getParam = (key?: string) => {
    if (!key || !searchParams.has(key)) return '';

    if (searchParams.has(key)) {
      return searchParams.get(key);
    }

    return '';
  };

  /**
   * Get All Params in the url
   */
  const getAllParams = () => {
    return Object.fromEntries(searchParams || '');
  };

  return {
    deleteParam,
    setParam,
    getParam,
    queryParams: searchParams,
    deleteAllParams,
    getAllParams,
  };
}
