import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
/**
 *  styles/global/main.scss needs to be above `store` and `features/App` to ensure main is loaded first
 * in MiniCssExtractPlugin.loader. With the changes in react-router-dom v6, `store` will generate routes.
 */
import 'styles/global/main.scss';
import {store} from 'store';
import App from 'features/App';
import {ErrorPage} from 'features/App/Error';
import AdminBugsnag from 'utils/bugsnag';

const ErrorBoundary = AdminBugsnag();

const root = createRoot(document.getElementById('app')!);

const render = () => {
  root.render(
    <Provider store={store}>
      <ErrorBoundary FallbackComponent={props => <ErrorPage {...props} />}>
        <App />
      </ErrorBoundary>
    </Provider>
  );
};

render();

/* used for when we are running cypress tests so we can instantiate the mocking */
if (process.env.NODE_ENV === 'development' && 'Cypress' in window) {
  const {worker} = require('mocks/browser');
  worker.start();
}
