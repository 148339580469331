import {notifications} from 'components/Notification/notification.ducks';
import identity from 'lodash/identity';
import {Middleware} from 'redux';
import {logger} from 'utils/logger';
import unWrapError from 'utils/request/requestError';

const notificationMiddleware: Middleware = api => next => action => {
  if (action.type.endsWith('/rejected')) {
    const {payload = {}} = action;
    /*
       Lets create a better flow of informational errors
       These are the ways I've seen ways to get at an error.
    */
    const primaryError = payload?.errors?.[0] ?? payload?.err?.message;
    const unwrappedError = unWrapError(payload, {includeObjectKeys: true});
    const secondaryError = payload?.err?.errors ?? action.error?.message;
    const tertiaryError = payload?.data?.errors ?? payload?.err?.message;
    const defaultError = 'Generic Error (cannot derive error)';
    const displayError = [primaryError, unwrappedError, secondaryError, tertiaryError, defaultError].find(identity);
    const errorMessage = [].concat(displayError).join(' ');

    logger(action.type)(errorMessage);
    api.dispatch(notifications.actions.notificationApiError({source: `${action.type} (${errorMessage})`}));
  }

  if (action.type.endsWith('/pending')) {
    api.dispatch(notifications.actions.notificationApiPending());
  }
  return next(action);
};

export default notificationMiddleware;
