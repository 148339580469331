import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import cn from 'classnames';
import {Panel, Button, BUTTON_THEMES} from 'ht-styleguide';

/* Hooks */
import {useCurrentProject} from 'features/MultiDwellingUnits/Pages/CurrentProject/CurrentProject.hooks';
import {useDebounceUnitQty} from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/TemplateJobs.hooks';

/* Constants / Utils */
import {mduProjectPathByStage} from 'global/paths';
import {InitialTemplateStages} from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/TemplateJobs.constants';
import {setProjectUnitValidatedCookie, getProjectUnitValidatedCookie} from 'features/MultiDwellingUnits/MDU.cookies';

/* Components */
import PageHeader from 'components/Elements/PageHeader';
import ProjectLevelUnitUpdate from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/Parts/TemplateJobs.ProjectUnit';

/* Types */
import {MduUseParamsTypes} from 'features/MultiDwellingUnits/MDU.types';

import styles from 'features/MultiDwellingUnits/Pages/CurrentProject/Parts/TemplatesJobs/templatejobs.styles.scss';
import stylesBase from 'features/MultiDwellingUnits/MDU.styles.scss';

const TemplatesAndJobsPreLaunchTemplatesInitial = () => {
  /* Local State */
  const {unitsNumber} = useCurrentProject();
  const [units, setUnits] = useState(unitsNumber);

  /* Hooks */
  const navigate = useNavigate();
  const {projectId = '', stage = ''} = useParams<MduUseParamsTypes>();
  const {updateUnitQtyProject} = useDebounceUnitQty();

  /* Constants */
  const hasUnitsValidated = getProjectUnitValidatedCookie(projectId as string);

  /* ------- METHODS --------- */
  const onHandleUnitNumberUpdate = (inputValue: string) => {
    setUnits(inputValue);
  };

  const onHandleUnitNumberSave = async () => {
    const callback = () => {
      navigate(`${mduProjectPathByStage(projectId, stage)}?stage=${InitialTemplateStages.EMPTY}`);
      /* Response is a success. Set cookie */
      setProjectUnitValidatedCookie(projectId, '1');
    };
    updateUnitQtyProject(units, unitsNumber, callback);
  };

  /*
    If this project has already been verified by unit count, we can skip this step. So, auto push them.
    Check the cookie.
  */
  useEffect(() => {
    if (hasUnitsValidated) {
      navigate(`${mduProjectPathByStage(projectId, stage)}?stage=${InitialTemplateStages.EMPTY}`);
    }
  }, [hasUnitsValidated, navigate, projectId, stage]);

  return (
    <div className="marginBottom-medium2">
      <PageHeader title="Templates & Jobs" />
      <Panel noShadow largeBorderRadius className={cn(styles.panel, 'padding-large flex align-items-center flex-direction-column')}>
        <h4 className="h4 marginBottom-medium1 n800">How many jobs will be in this project?</h4>
        <ProjectLevelUnitUpdate inputValue={units} text="Unit Count" valueSetter={onHandleUnitNumberUpdate} />
        <div className={cn(stylesBase.emptyStateButtonContainer, 'paddingTop-small1')}>
          <Button disabled={units < 1} theme={BUTTON_THEMES.PRIMARY} onClick={onHandleUnitNumberSave}>
            Save
          </Button>
        </div>
      </Panel>
    </div>
  );
};

export default React.memo(TemplatesAndJobsPreLaunchTemplatesInitial);
