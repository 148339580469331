import {useMutation} from 'react-query';
import useApi from 'hooks/useApi';
import {useAppDispatch} from 'hooks/useAppDispatch';
import {showErrorToast} from 'queries/query.utils';
import {logger} from 'utils/logger';
import {useInvalidateProjectsQuery} from '../MDU.hooks';
import {TPauseProjectResponse, TUpdateProjectPauseResponse, TResumeProjectResponse} from '../MDU.types';

type TPauseProjectParams = {
  projectId: number;
  issueId?: number | null;
};
// Pause a project
export const usePauseProjectMutation = () => {
  const api = useApi();
  const dispatch = useAppDispatch();
  const {invalidateProjectDataWithIssues} = useInvalidateProjectsQuery();

  return useMutation(
    async ({projectId, issueId}: TPauseProjectParams) => {
      api.toggleLoader(true);
      const response: TPauseProjectResponse = await api.mdu.pauseProject({project_id: projectId}, {ticket_id: issueId});

      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error in pausing project'});
        logger('pauseProjectMutation')(`Error pausing project: ${(response.data?.errors ?? []).join(' ')}`);
        throw new Error(`Error Pausing Project`);
      }
      return response?.data;
    },
    {
      onSuccess: async (_, {projectId}) => {
        await invalidateProjectDataWithIssues({projectId});
      },
      onSettled: () => {
        api.toggleLoader(false);
      },
    }
  );
};

type TUpdateProjectPauseParams = {
  projectId: number;
  projectPauseId: number;
  issueId: number;
};

// Update the pause data on a project
export const useUpdateProjectPauseMutation = () => {
  const api = useApi();
  const dispatch = useAppDispatch();
  const {invalidateProjectDataWithIssues} = useInvalidateProjectsQuery();

  return useMutation(
    async ({projectId, projectPauseId, issueId}: TUpdateProjectPauseParams) => {
      api.toggleLoader(true);
      const response: TUpdateProjectPauseResponse = await api.mdu.updateProjectPause({project_id: projectId, project_pause_id: projectPauseId}, {ticket_id: issueId});

      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error in updating project pause'});
        logger('updateProjectPauseMutation')(`Error updating project pause: ${(response.data?.errors ?? []).join(' ')}`);
        throw new Error(`Error Updating Project Pause`);
      }
      return response?.data;
    },
    {
      onSuccess: async (_, {projectId}) => {
        await invalidateProjectDataWithIssues({projectId});
      },
      onSettled: () => {
        api.toggleLoader(false);
      },
    }
  );
};

type TResumeProjectParams = {
  projectId: number;
};

// Resume (unpause) a project
export const useResumeProjectMutation = () => {
  const api = useApi();
  const dispatch = useAppDispatch();
  const {invalidateProjectDataWithIssues} = useInvalidateProjectsQuery();

  return useMutation(
    async ({projectId}: TResumeProjectParams) => {
      api.toggleLoader(true);
      const response: TResumeProjectResponse = await api.mdu.resumeProject({project_id: projectId});

      if (response.err) {
        showErrorToast(dispatch)({errors: 'Error in resuming project'});
        logger('useResumeProjectMutation')(`Error resuming project: ${(response.data?.errors ?? []).join(' ')}`);
        throw new Error(`Error Resuming Project`);
      }
      return response?.data;
    },
    {
      onSuccess: async (_, {projectId}) => {
        await invalidateProjectDataWithIssues({projectId});
      },
      onSettled: () => {
        api.toggleLoader(false);
      },
    }
  );
};
